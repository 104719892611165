import {
    InspectionDamage,
    InventoryInspection,
    ModelListMap,
} from '../../models'
import {
    InspectionActionTypes,
    InspectionState,
    SET_INSPECTION_LOADING,
    SET_INSPECTION_DAMAGE_LIST,
    ADD_INSPECTION_DAMAGE,
    SET_INSPECTION_LIST,
    ADD_INSPECTION,
    UPDATE_INSPECTION,
    SET_INSPECTION_DETAIL,
    SET_INVENTORY_INSPECTION_LIST,
    REMOVE_INSPECTION_DAMAGE,
    ADD_UNIT_INSPECTIONS,
    UPDATE_INVENTORY_INSPECTION,
    SET_UNIT_INSPECTION_LIST,
    UPDATE_UNIT_INSPECTION,
    SET_INSPECTION_ANALYTICS,
} from './types'

const initialState: InspectionState = {
    isLoading: {
        GET_INSPECTION_DAMAGE_LIST_REQUEST: false,
        CREATE_INSPECTION_DAMAGE_REQUEST: false,
        GET_INSPECTION_LIST: false,
        CREATE_INSPECTION_REQUEST: false,
        UPDATE_INSPECTION_REQUEST: false,
        GET_INSPECTION_DETAIL: false,
        GET_INVENTORY_INSPECTION_REQUEST: false,
        DELETE_INSPECTION_DAMAGE_REQUEST: false,
        GET_UNIT_INSPECTION_LIST_REQUEST: false,
        CREATE_INVENTORY_INSPECTION_CHANNEL: false,
        UPDATE_INVENTORY_INSPECTION_REQUEST: false,
        BULK_HANDLE_INVENTORY_INSPECTION_REQ: false,
        GET_INSPECTION_ANALYTICS_REQUEST: false,
        ASSIGN_UNIT_INSPECTION_REQUEST: false,
    },
}

export const inspectionReducer = (
    state = initialState,
    action: InspectionActionTypes,
): InspectionState => {
    switch (action.type) {
        case SET_INSPECTION_DAMAGE_LIST:
            return {
                ...state,
                inspectionDamageList: action.inspectionDamageList,
            }

        case SET_INSPECTION_LIST:
            return {
                ...state,
                inspectionList: action.inspectionList,
            }

        case ADD_INSPECTION:
            return {
                ...state,
                inspectionList: state.inspectionList
                    ? [...state.inspectionList, action.inspection]
                    : [action.inspection],
            }

        case UPDATE_INSPECTION:
            return {
                ...state,
                inspectionList: state.inspectionList
                    ? state.inspectionList.map((ins) => {
                          if (ins.id === action.id) {
                              return action.updatedInspection
                          }
                          return ins
                      })
                    : undefined,
            }

        case SET_INSPECTION_DETAIL:
            return {
                ...state,
                inspectionDetail: action.inspection,
            }

        case SET_INVENTORY_INSPECTION_LIST:
            return {
                ...state,
                inventoryInspectionList: action.inventoryInspectionList,
                inventoryInspectionMap: action.inventoryInspectionList
                    ? getInventoryInspectionMap(action.inventoryInspectionList)
                    : undefined,
            }

        case SET_INSPECTION_LOADING:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.request]: action.isLoading,
                },
            }
        case ADD_INSPECTION_DAMAGE:
            return {
                ...state,
                inspectionDamageList: state.inspectionDamageList
                    ? [...state.inspectionDamageList, action.inspectionDamage]
                    : [action.inspectionDamage],
            }
        case REMOVE_INSPECTION_DAMAGE:
            return {
                ...state,
                inspectionDamageList: state.inspectionDamageList?.reduce<
                    InspectionDamage[]
                >((prev, dmg) => {
                    if (dmg.id === action.inspectionDamageId) {
                        return prev
                    }
                    return prev.concat(dmg)
                }, []),
            }

        case ADD_UNIT_INSPECTIONS:
            return {
                ...state,
                unitInspectionList: [
                    ...(state.unitInspectionList ?? []),
                    ...(action.unitInspectionList ?? []),
                ],
            }
        case SET_UNIT_INSPECTION_LIST:
            return {
                ...state,
                unitInspectionList: action.unitInspectionList,
            }
        case UPDATE_INVENTORY_INSPECTION:
            return handleUpdate(state, action.inv_ins)

        case UPDATE_UNIT_INSPECTION:
            return {
                ...state,
                unitInspectionList: state.unitInspectionList?.map((unitIns) => {
                    if (unitIns.id === action.unitIns.id) {
                        return action.unitIns
                    } else {
                        return unitIns
                    }
                }),
            }
        case SET_INSPECTION_ANALYTICS:
            return {
                ...state,
                inspectionAnalytics: action.analytics,
            }
        default:
            return state
    }
}

const handleUpdate = (
    state: InspectionState,
    inventoryInspections: InventoryInspection[] | InventoryInspection,
): InspectionState => {
    let safeInventoryInspections: InventoryInspection[]
    if (!Array.isArray(inventoryInspections)) {
        safeInventoryInspections = [inventoryInspections]
    } else {
        safeInventoryInspections = inventoryInspections
    }

    let curUpdateIdx = 0

    const newInventoryInspectionList = state.inventoryInspectionList?.map(
        (inv_ins) => {
            if (curUpdateIdx >= safeInventoryInspections.length) {
                // The udpate array is out of bounds
                return inv_ins
            }

            const curUpdateInvIns = safeInventoryInspections[curUpdateIdx]

            if (inv_ins.id === curUpdateInvIns.id) {
                curUpdateIdx += 1
                return curUpdateInvIns
            }
            return inv_ins
        },
    )

    return {
        ...state,
        inventoryInspectionList: newInventoryInspectionList,
        inventoryInspectionMap: getInventoryInspectionMap(
            newInventoryInspectionList ?? [],
        ),
    }
}

const getInventoryInspectionMap = (
    newInvInsList: InventoryInspection[],
): ModelListMap<InventoryInspection> => {
    return newInvInsList.reduce<{
        [id: number]: InventoryInspection[]
    }>((prev, invIns) => {
        return {
            ...prev,
            [invIns.area]: prev[invIns.area]
                ? [...prev[invIns.area], invIns]
                : [invIns],
        }
    }, {})
}
