import { Theme } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import { Container } from '../Container'

interface ActionableProps {
    theme: Theme
    interactionComponent: JSX.Element
}

export const Actionable = (props: PropsWithChildren<ActionableProps>) => {
    return (
        <Container style={{ alignItems: 'center', flex: 1 }}>
            {props.children}

            <div style={{ marginLeft: props.theme.spacing(1) }}>
                {props.interactionComponent}
            </div>
        </Container>
    )
}
