import { Paper, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from '../../components'
import { axiosInstance } from '../../helpers'
import {
    EndOfTurnReport,
    ListVendor,
    ModelListMap,
    RatingInterface,
    Schedule,
    VendorScheduleRating,
} from '../../models'
import { ArchivedScheduleVendorCard } from './ArchivedScheduleVendorCard'

interface Props {
    vendorList: ListVendor[]
    schedule?: Schedule
    endOfTurnReportList: EndOfTurnReport[]
}

export interface RatingMap {
    [venId: number]: RatingInterface
}

export const ArchivedScheduleBody = (props: Props) => {
    const { vendorList, schedule, endOfTurnReportList } = props
    const theme = useTheme()

    const [
        vendorScheduleRatings,
        setVendorScheduleRatings,
    ] = useState<RatingMap>([])

    const endOfTurnReportMap: ModelListMap<EndOfTurnReport> = {}

    vendorList.forEach((vendor) => (endOfTurnReportMap[vendor.id] = []))
    endOfTurnReportList.forEach((eotReport) =>
        endOfTurnReportMap[eotReport.vendor.id]?.push(eotReport),
    )

    const updateVendorScheduleRatings = (
        vendorId: number,
        vendorScheduleRating: VendorScheduleRating,
    ) => {
        const newVSR = {
            ...vendorScheduleRatings,
        }
        newVSR[vendorId] = vendorScheduleRating
        setVendorScheduleRatings(newVSR)
    }

    useEffect(() => {
        if (schedule) {
            axiosInstance
                .get('company/vendor_schedule_rating/', {
                    params: { schedule: schedule.id },
                })
                .then((res) => {
                    const vendorRatingMap: {
                        [venId: number]: RatingInterface
                    } = {}
                    vendorList.forEach((ven) => {
                        vendorRatingMap[ven.id] = {
                            professionalism: 0,
                            work_quality: 0,
                        }
                    })
                    res.data.forEach((vsr: VendorScheduleRating) => {
                        vendorRatingMap[vsr.vendor] = vsr
                    })

                    setVendorScheduleRatings(vendorRatingMap)
                })
        }
    }, [schedule])

    return (
        <Container style={{ flex: 1 }} scrollY>
            <Paper
                style={{
                    boxShadow: theme.shadows[2],
                    margin: theme.spacing(1),
                    flex: 1,
                }}
            >
                <Container
                    style={{
                        borderRadius: `${theme.shape.borderRadius} 0 0 ${theme.shape.borderRadius}`,
                        backgroundColor: theme.palette.grey[200],
                        boxShadow: `3px 0px 3px -1px ${theme.palette.grey[500]}`,
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        flex: 1,
                    }}
                >
                    {vendorList.map((ven, idx) => {
                        return (
                            <ArchivedScheduleVendorCard
                                key={`VENDOR_RATING_COMPONENT_${ven.id}_${idx}`}
                                vendor={ven}
                                schedule={schedule}
                                updateVendorScheduleRatings={
                                    updateVendorScheduleRatings
                                }
                                vendorScheduleRating={
                                    vendorScheduleRatings[ven.id]
                                }
                                reports={endOfTurnReportMap[ven.id]!}
                            />
                        )
                    })}
                </Container>
            </Paper>
        </Container>
    )
}
