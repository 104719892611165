import { useTheme } from '@material-ui/core'
import React from 'react'
import { Service, getServiceImageDetails } from '../models'
import { Container } from './Container'

interface DisplayServiceProps {
    service: Service
    border: boolean
    noPadding?: boolean
}

export const DisplayService = (props: DisplayServiceProps) => {
    const { service, border, noPadding } = props

    const theme = useTheme()

    return (
        <Container
            style={{
                border: border ? `1px solid ${service.color}` : '',
                borderRadius: '5px',
                padding: noPadding ? '' : theme.spacing(1),
                margin: theme.spacing(1, 1, 1, 0),
                alignItems: 'center',
            }}
        >
            <Container
                style={{
                    ...theme.typography.body2,
                    fontWeight: 600,
                    marginRight: theme.spacing(1),
                }}
            >
                {service.name}
            </Container>
            <img
                src={getServiceImageDetails(service.id).icon}
                style={{
                    maxHeight: 25,
                    maxWidth: 27,
                }}
            />
        </Container>
    )
}
