import React from 'react'
import { Button, Theme, useTheme } from '@material-ui/core'
import {
    Container,
    NumberInput,
    SideDrawer,
    StatusBadge,
} from '../../components'
import { CustomStatus, IdCost, InventoryConfig } from '../../models'
import { useState } from 'react'
import { useEffect } from 'react'
import {
    setInventoryStatusCost,
    SetInventoryStatusCostRequest,
} from '../../store'
import {
    InventoryStatusScore,
    useAppDispatch,
    useInventoryConfig,
} from '../../hooks'
import { toast } from 'react-toastify'

interface Props {
    open: boolean
    onClose: () => void
    invConf?: InventoryConfig
}

export const InvConfigStatusCostDrawer = (props: Props) => {
    const { open, onClose, invConf } = props

    const theme = useTheme()
    const dispatch = useAppDispatch()

    const [costArray, setCostArray] = useState<IdCost[]>([])

    const {
        inventoryStatusScores,
        fetchInventoryStatusScores,
        updateInventoryStatusScore,
    } = useInventoryConfig()

    useEffect(() => {
        if (invConf) {
            fetchInventoryStatusScores(invConf.id)
        }
        setCostArray(invConf?.custom_status_list ?? [])
    }, [invConf])

    const saveClick = () => {
        //convert the cost array to where all the strings are numbers
        const costArrayNums: IdCost[] = costArray.map((cost) => {
            return {
                id: cost.id,
                cost: Number(cost.cost) ?? 0,
            }
        })
        const req: SetInventoryStatusCostRequest = {
            params: {
                id: invConf?.id ?? -1,
            },
            body: {
                status_cost_list: costArrayNums,
            },
        }
        dispatch(setInventoryStatusCost(req)).then(() => {
            toast.success('Costs successfully updated!')
            onClose()
        })
    }
    if (invConf === undefined) {
        return null
    }

    return (
        <SideDrawer open={open} handleClose={onClose} title={'Configure Costs'}>
            <Container direction="column" scrollY flex={1}>
                {/* make sure the lengths are the same to eliminate race condition of clicking and then seeing weather the useeffect resolves or this renders first */}
                {costArray.length === invConf.custom_status_list?.length &&
                    costArray.map((cS, index) => {
                        const customStatus = invConf.custom_status_list?.[index]
                        if (customStatus === undefined) {
                            return null
                        }
                        return (
                            <CustomStatusCost
                                key={`CUSTOM_STATUS_${invConf.id}_${cS.id}`}
                                invConf={invConf}
                                index={index}
                                theme={theme}
                                costArray={costArray}
                                setCostArray={setCostArray}
                                customStatus={customStatus}
                                updateInventoryStatusScore={
                                    updateInventoryStatusScore
                                }
                                inventoryStatusScores={inventoryStatusScores}
                            />
                        )
                    })}
            </Container>
            {/* footer */}

            <Container
                justifyContent={'flex-end'}
                style={{
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    marginTop: theme.spacing(1),
                }}
            >
                <Button
                    color="secondary"
                    variant="outlined"
                    onClick={onClose}
                    style={{ marginRight: theme.spacing(1) }}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => saveClick()}
                >
                    Save
                </Button>
            </Container>
        </SideDrawer>
    )
}

const CustomStatusCost = (props: {
    invConf: InventoryConfig
    index: number
    theme: Theme
    customStatus: CustomStatus
    costArray: IdCost[]
    inventoryStatusScores: InventoryStatusScore[]
    setCostArray: (costArray: IdCost[]) => void
    updateInventoryStatusScore: (
        inventoryId: number,
        statusId: number,
        score: number,
    ) => void
}) => {
    const {
        invConf,
        index,
        theme,
        customStatus,
        costArray,
        setCostArray,
        updateInventoryStatusScore,
        inventoryStatusScores,
    } = props

    useEffect(() => {
        const score =
            inventoryStatusScores.find(
                (s) => s.custom_status === customStatus.id,
            )?.score ?? 0
        console.log(score, inventoryStatusScores)
        setScore(score)
    }, [inventoryStatusScores])

    const [score, setScore] = useState<number>(0)

    return (
        <Container
            key={`CUSTOM_STATUS_${invConf.id}_${customStatus.id}`}
            style={{ margin: theme.spacing(1) }}
        >
            <StatusBadge
                text={customStatus.name}
                customStyle={{
                    container: {
                        backgroundColor: customStatus.color,
                        width: 140,
                    },
                }}
            />
            <NumberInput
                prefix={'$'}
                style={{ marginLeft: theme.spacing(1) }}
                variant="outlined"
                label={'Cost'}
                value={costArray[index].cost ?? ''}
                onChange={(event) => {
                    const newCostArray = costArray.map((c, idx) => {
                        if (index === idx) {
                            return {
                                id: c.id,
                                cost: event.target.value,
                            }
                        }
                        return c
                    })
                    setCostArray(newCostArray)
                }}
            />

            <NumberInput
                value={score}
                style={{ marginLeft: theme.spacing(1) }}
                variant="outlined"
                label={'Score'}
                onBlur={() => {
                    updateInventoryStatusScore(
                        invConf.id,
                        customStatus.id,
                        score,
                    )
                }}
                onChange={(event) => {
                    setScore(Number(event.target.value))
                }}
            />
        </Container>
    )
}
