import React from 'react'

import { Divider, Button, useTheme } from '@material-ui/core'
import { Check } from '@material-ui/icons'

import { Container, LocationForm } from '../../components'
import { useAppDispatch, _useFinderSelection } from '../../hooks'
import { Folder, Unit } from '../../models'
import {
    FinderLocationSelection,
    FinderSelectionMode,
    setFinderSelection,
} from '../../store'

interface Props {
    prunedTree: Folder
    setAssignModalOpen: (open: boolean) => void
    onClose: () => void
    getUnitAdornment?: (unit: Unit) => JSX.Element | null
    modalMode?: boolean
}

export const AssignInspectionForm = (props: Props) => {
    const { prunedTree, onClose, setAssignModalOpen, modalMode } = props

    const dispatch = useAppDispatch()

    const assignmentFinderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.PrunedSelection,
        selectionMode: FinderSelectionMode.Multi,
    })

    const theme = useTheme()

    return (
        <Container
            flex={1}
            direction="column"
            style={{ marginTop: theme.spacing(2) }}
        >
            <LocationForm
                root={prunedTree}
                locationSelection={assignmentFinderSelection.selection}
                setLocationSelection={
                    assignmentFinderSelection.setFinderSelection
                }
                selectedIcon={<Check />}
                getUnitAdornment={props.getUnitAdornment}
            />
            {/* Footer */}
            {modalMode ? (
                <Container
                    justifyContent={'flex-end'}
                    style={{
                        marginTop: theme.spacing(1),
                    }}
                >
                    <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        style={{
                            textTransform: 'none',
                            margin: theme.spacing(1, 0, 1, 2),
                        }}
                        onClick={() => {
                            // setStartDate(new Date())
                            dispatch(
                                setFinderSelection(
                                    FinderLocationSelection.PrunedSelection,
                                    FinderSelectionMode.Multi,
                                    undefined,
                                ),
                            )
                            onClose()
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(1, 0, 1, 2),
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => setAssignModalOpen(true)}
                    >
                        Next
                    </Button>
                </Container>
            ) : (
                <>
                    <Divider />
                    <Container
                        style={{
                            padding: theme.spacing(2),
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            color="secondary"
                            variant="outlined"
                            style={{ marginRight: theme.spacing(2) }}
                            onClick={() => {
                                // setStartDate(new Date())
                                dispatch(
                                    setFinderSelection(
                                        FinderLocationSelection.PrunedSelection,
                                        FinderSelectionMode.Multi,
                                        undefined,
                                    ),
                                )
                                onClose()
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setAssignModalOpen(true)}
                        >
                            Save
                        </Button>
                    </Container>
                </>
            )}
        </Container>
    )
}
