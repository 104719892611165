import React, { useState } from 'react'
import { Container } from '../../../../components'
import {
    StepVendorDetail,
    StepVendorPrices,
    StepSelectServices,
} from '../Components'
import { Tabs, Tab, Theme } from '@material-ui/core'
import {
    AreaConfig,
    IdBoolMap,
    ListVendor,
    Service,
    UnitConfig,
    parseScCompoundKeyStr,
} from '../../../../models'
import {
    CompanyDetailActionThunk,
    UpdateVendorRequest,
    UpdateVendorServicesRequest,
    VendorAndContractThunk,
} from '../../../../store'
import { useServiceContract } from '../../../../hooks/useServiceContract'
import { toast } from 'react-toastify'

interface Props {
    theme: Theme
    vendor: ListVendor
    serviceList: Service[]
    unitConfigs: UnitConfig[]
    areaConfigs: AreaConfig[]
    vendorCanSeeUnitNotesMap: IdBoolMap
    setVendorCanSeeUnitNotesMap: (map: IdBoolMap) => void
    updateVendor: (req: UpdateVendorRequest) => VendorAndContractThunk
    updateVendorServices: (
        req: UpdateVendorServicesRequest,
    ) => CompanyDetailActionThunk
}

export const ExistingVendor = (props: Props) => {
    const {
        theme,
        vendor,
        serviceList,
        updateVendor,
        updateVendorServices,
        setVendorCanSeeUnitNotesMap,
        vendorCanSeeUnitNotesMap,
    } = props

    const [currentTab, setCurrentTab] = useState<AddVendorStep>(
        STEP_VENDOR_DETAILS,
    )
    const { setServiceContracts } = useServiceContract()

    return (
        <Container
            style={{
                flexDirection: 'column',
            }}
        >
            <Container style={{ padding: theme.spacing(1) }}>
                <Tabs
                    value={currentTab}
                    onChange={(_, v) => {
                        setCurrentTab(v)
                    }}
                >
                    <Tab
                        label="Vendor Details"
                        style={{ textTransform: 'none' }}
                    />
                    <Tab
                        label="Select Services"
                        style={{ textTransform: 'none' }}
                    />
                    <Tab
                        label="Edit Prices"
                        style={{ textTransform: 'none' }}
                    />
                </Tabs>
            </Container>

            {currentTab === STEP_VENDOR_DETAILS && (
                <StepVendorDetail
                    vendor={vendor}
                    theme={theme}
                    onSubmit={(body) => {
                        updateVendor({
                            params: {
                                id: vendor.id,
                            },
                            body: {
                                name: body.name,
                                address:
                                    body.address.addressLine1 +
                                    ' ' +
                                    body.address.addressLine2,
                                city: body.address.city,
                                state: body.address.state,
                                zip_code: body.address.zip,
                                phone: body.phone,
                                is_skillcat: vendor.is_skillcat,
                                in_house: body.inHouse,
                                unit_notes: body.unitNotes,
                            },
                        }).then(() => {
                            toast.success('Success')
                            const newMap = { ...vendorCanSeeUnitNotesMap }
                            newMap[vendor.id] = body.unitNotes
                            setVendorCanSeeUnitNotesMap(newMap)
                        })
                    }}
                    submitTitle={'Edit Vendor'}
                    title={`Edit ${vendor.name}`}
                    vendorCanSeeUnitNotesMap={vendorCanSeeUnitNotesMap}
                />
            )}

            {currentTab === STEP_SELECT_SERVICES && (
                <StepSelectServices
                    onPressNext={() => setCurrentTab(STEP_EDIT_PRICES)}
                    serviceList={serviceList}
                    vendor={vendor}
                    theme={theme}
                    onAddService={(vendor, service) => {
                        const newServices = vendor.services.map((srv) => ({
                            id: srv.id,
                            offers: true,
                        }))

                        newServices.push({ id: service.id, offers: true })

                        updateVendorServices({
                            params: { id: vendor.id },
                            body: {
                                services: newServices,
                            },
                        })
                    }}
                    onRemoveService={(vendor, service) => {
                        const newServices = vendor.services.map((srv) => {
                            return {
                                id: srv.id,
                                offers: srv.id !== service.id,
                            }
                        })

                        updateVendorServices({
                            params: { id: vendor.id },
                            body: {
                                services: newServices,
                            },
                        })
                    }}
                />
            )}

            {currentTab === STEP_EDIT_PRICES && (
                <StepVendorPrices
                    {...props}
                    onSubmit={(serviceContractMap) => {
                        const serviceContracts: any[] = []
                        Object.keys(serviceContractMap).forEach(
                            (compoundKeyStr) => {
                                const compoundKey = parseScCompoundKeyStr(
                                    compoundKeyStr,
                                )
                                if (compoundKey === null) {
                                    return
                                }

                                serviceContracts.push({
                                    vendor: compoundKey.vendorId,
                                    area_config: compoundKey.areaConfigId,
                                    unit_config: compoundKey.unitConfigId,
                                    service: compoundKey.serviceId,
                                    price: serviceContractMap[compoundKeyStr],
                                })
                            },
                        )

                        setServiceContracts({
                            body: {
                                vendor: vendor.id,
                                service_contracts: serviceContracts,
                            },
                        }).then(() => {
                            toast.success('Service Contracts Saved')
                        })
                    }}
                />
            )}
        </Container>
    )
}

const STEP_VENDOR_DETAILS = 0
const STEP_SELECT_SERVICES = 1
const STEP_EDIT_PRICES = 2

type AddVendorStep =
    | typeof STEP_VENDOR_DETAILS
    | typeof STEP_SELECT_SERVICES
    | typeof STEP_EDIT_PRICES
