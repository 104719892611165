import React, { useEffect } from 'react'
import {
    TableCell,
    TableRow,
    useTheme,
    IconButton,
    Tooltip,
    Switch,
    FormControlLabel,
    CircularProgress,
} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { AREA_STEP, CreateLeaseStep } from '.'
import {
    CellData,
    Container,
    HeadCell,
    RowData,
    SimpleTable,
} from '../../../../components'
import { _useLease } from '../../../../hooks'
import { Area, AreaConfig, ModelMap, Unit } from '../../../../models'

interface Props {
    activeStep: CreateLeaseStep
    unitMap: ModelMap<Unit>
    areaConfigMap: ModelMap<AreaConfig>
    startDate?: Date
    endDate?: Date
    onSelectArea: (area: Area) => void
}

export const CreateLeaseLocationSelect = (props: Props) => {
    const { activeStep, startDate, endDate, areaConfigMap, unitMap } = props

    const theme = useTheme()
    const { vacantAreas, getVacantAreas } = _useLease()

    const [ignoreVacancy, setIgnoreVacancy] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    interface AreaRow extends RowData {
        location: CellData<Area>
        area: CellData<Area>
        select_button: CellData<any>
    }

    const headCells: HeadCell<AreaRow>[] = [
        {
            id: 'location',
            label: 'Location',
        },
        {
            id: 'area',
            label: 'Area',
        },
        {
            id: 'select_button',
            label: '',
        },
    ]

    const createRow = (area: Area): AreaRow => {
        const areaConfig: AreaConfig | undefined =
            areaConfigMap[area.area_config]

        const unit: Unit | undefined = unitMap[area.unit]

        return {
            location: {
                value: area,
                sortValue: `${unit?.folder.path}${unit?.folder.name}/${unit?.name}`,
            },
            area: {
                value: area,
                sortValue: `${areaConfig?.name}${area.area_label}`,
            },
            select_button: {
                value: null,
                sortValue: -1,
            },
        }
    }

    useEffect(() => {
        if (activeStep === AREA_STEP && startDate && endDate) {
            setLoading(true)
            if (ignoreVacancy) {
                getVacantAreas({
                    params: {
                        occupiable: true,
                    },
                }).finally(() => setLoading(false))
            } else {
                getVacantAreas({
                    params: {
                        occupiable: true,
                        vacant_start: startDate.toISOString(),
                        vacant_end: endDate.toISOString(),
                    },
                }).finally(() => setLoading(false))
            }
        }
    }, [activeStep, ignoreVacancy])

    return (
        <Container
            style={{
                flex: 1,
                padding: theme.spacing(1),
                maxHeight: 600,
                flexDirection: 'column',
            }}
        >
            <Container style={{ marginRight: theme.spacing(1) }}>
                <CircularProgress
                    size={50}
                    style={{
                        marginLeft: theme.spacing(1),
                        visibility: loading ? 'visible' : 'hidden',
                    }}
                />
                <div style={{ flex: 1 }} />
                <FormControlLabel
                    control={
                        <Switch
                            checked={ignoreVacancy}
                            color="primary"
                            onChange={(e) => setIgnoreVacancy(!ignoreVacancy)}
                        />
                    }
                    labelPlacement="start"
                    label="Show All Areas"
                    style={{ marginTop: theme.spacing(2) }}
                />
            </Container>
            <SimpleTable<AreaRow>
                stickyHeader
                rows={vacantAreas?.map(createRow) ?? []}
                render={(row) => {
                    const area = row.area.value

                    const areaConfig: AreaConfig | undefined =
                        areaConfigMap[area.area_config]

                    const unit: Unit | undefined = unitMap[area.unit]

                    return (
                        <TableRow key={area.id}>
                            <TableCell
                                style={{ ...theme.typography.subtitle1 }}
                            >
                                <span>
                                    {unit?.folder.path}
                                    {unit?.folder.name}
                                </span>
                                <span
                                    style={{
                                        marginLeft: theme.spacing(1),
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    {unit?.name}
                                </span>
                            </TableCell>
                            <TableCell>
                                {areaConfig?.name} {area.area_label}
                            </TableCell>
                            <TableCell
                                style={{
                                    justifyContent: 'flex-end',
                                    display: 'flex',
                                }}
                            >
                                <Tooltip title={`Select ${unit?.name}`}>
                                    <IconButton
                                        onClick={() => props.onSelectArea(area)}
                                    >
                                        <ArrowForwardIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    )
                }}
                orderByDefault={'location'}
                headCells={headCells}
            />
        </Container>
    )
}
