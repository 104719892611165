export * from './axios'
export * from './permissions'
export * from './stringHelpers'
export * from './Routes'
export * from './LocalStorage'
export * from './date'
export * from './colorMixer'
export * from './CurrencyFormatter'
export * from './arrayServices'
export * from './passwordValidator'

export function enumKeys<O extends object, K extends keyof O = keyof O>(
    obj: O,
): K[] {
    return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[]
}
