import React, { useEffect, useState } from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    FormControlLabel,
    IconButton,
    Switch,
    TableCell,
    TableRow,
    useTheme,
    Checkbox,
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import {
    CellData,
    Container,
    HeadCell,
    RowData,
    SimpleTable,
} from '../../components'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import {
    axiosInstance,
    getNumFromURLSegment,
    isAccountManager,
    toMMDDYYYY,
} from '../../helpers'
import { useAppDispatch, useCompany, useUser } from '../../hooks'
import { Invoice, ListVendor, ModelMap } from '../../models'
import {
    getCompanyDetail,
    getInvoiceList,
    RootState,
    updateInvoice,
} from '../../store'
import { toast } from 'react-toastify'
import { useStyles } from '../../styles'
import {
    ConfigureEntrataDetails,
    EntrataGLInfo,
    EntrataVendor,
} from './ConfigureEntrataDetails'
import { EntrataInvoiceModal } from './EntrataInvoiceModal'
import { ApartmentVendor } from '../NationalPropertyList'

interface InvoiceRow extends RowData {
    id: CellData<number>
    date: CellData<Date>
    total_cost: CellData<number>
    invoice: CellData<Invoice>
}

export const InvoiceList = () => {
    const location = useLocation()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const classes = useStyles()
    const { workspaceUser } = useUser()
    const history = useHistory()

    const [invoiceRegenerationId, setInvoiceRegenerationId] = useState(-1)
    const [financialConfirm, setFinancialConfirm] = useState(false)

    const [selectedInvoice, setSelectedInvoice] = useState<
        Invoice | undefined
    >()
    const [isLoading, setIsLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)

    const [openManualSyncModal, setOpenManualSyncModal] = useState(false)

    const [apartmentVendors, setApartmentVendors] = useState<ApartmentVendor[]>(
        [],
    )
    const [selectedApartmentVendor, setSelectedApartmentVendor] = useState<
        ApartmentVendor | undefined
    >()

    const [entrataVendors, setEntrataVendors] = useState<EntrataVendor[]>([])
    const [entrataGLInfo, setEntrataGLInfo] = useState<EntrataGLInfo[]>([])

    const companyDetail = useSelector(
        (state: RootState) => state.company.companyDetail,
    )

    const invoiceList = useSelector(
        (state: RootState) => state.invoice.invoiceList,
    )

    const { vendorList, getVendorList } = useCompany()

    // url.pathname is the part after ezturn.co, for ex: /invoice/company/1
    const urlSegment = location.pathname.substring(17) // the characters where the number is and beyond
    const companyId = getNumFromURLSegment(urlSegment).number

    useEffect(() => {
        const companyDetailPromise = dispatch(
            getCompanyDetail({ company: companyId }),
        )

        let key: 'apartment' | 'vendor' = 'apartment'
        if (workspaceUser) {
            if (workspaceUser.active_workspace.company_type === 'APARTMENT') {
                key = 'vendor'
            }
        }

        const invoicePromise = dispatch(
            getInvoiceList({ params: { [key]: companyId } }),
        )

        const vendorPromise = getVendorList({ params: { my_team: true } })

        const apartmentVendorPromise = axiosInstance.get(
            `company/apartment_vendors/`,
        )

        const EntrataVendorPromise = axiosInstance.post(
            `company/vendor/get_entrata_vendor_info_for_apartment/`,
        )

        const EntrataGLPromise = axiosInstance.post(
            `company/vendor/get_entrata_gl_accounts/`,
        )

        Promise.all([
            companyDetailPromise,
            invoicePromise,
            vendorPromise,
            apartmentVendorPromise,
            EntrataVendorPromise,
            EntrataGLPromise,
        ])
            .then((values) => {
                setApartmentVendors(values[3].data)
                const tempEntrataVendors: EntrataVendor[] = []
                values[4].data.vendors.map((vendor: EntrataVendor) => {
                    tempEntrataVendors.push({
                        id: vendor.vendorID,
                        vendorName: vendor.vendorName,
                        vendorID: vendor.vendorID,
                    })
                })
                tempEntrataVendors.sort((vendorA, vendorB) =>
                    vendorA.vendorName.localeCompare(vendorB.vendorName),
                )
                setEntrataVendors(tempEntrataVendors)

                const tempEntrataGLInfo: EntrataGLInfo[] = []
                values[5].data.gl_accounts.map((gl_info: EntrataGLInfo) => {
                    tempEntrataGLInfo.push({
                        id: gl_info.gl_account_id,
                        gl_account_id: gl_info.gl_account_id,
                        account_name: gl_info.account_name,
                    })
                })
                tempEntrataGLInfo.sort((gl_infoA, gl_infoB) =>
                    gl_infoA.account_name.localeCompare(gl_infoB.account_name),
                )
                setEntrataGLInfo(tempEntrataGLInfo)
            })
            .finally(() => setPageLoading(false))
            .catch((e) => toast.error(e.response.data.message))
    }, [])

    // Table setup
    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    const cellStyle: React.CSSProperties = {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightRegular,
    }

    const headCells: HeadCell<InvoiceRow>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'id',
            label: 'Invoice #',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'date',
            label: 'Date',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'total_cost',
            label: 'Total Cost',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'invoice',
            label: '',
            style: headCellStyle,
        },
    ]

    if (workspaceUser?.active_workspace.company_type === 'APARTMENT') {
        headCells.push({
            disablePadding: false,
            align: 'left',
            id: 'entrata',
            label: '',
            style: headCellStyle,
        })
    }

    const createRow = (row: Invoice): InvoiceRow => ({
        id: {
            value: row.id,
            sortValue: row.id,
        },
        date: {
            value: new Date(row.created_date),
            sortValue: new Date(row.created_date).valueOf(),
        },
        total_cost: {
            value: row.total_cost,
            sortValue: row.total_cost,
        },
        invoice: {
            value: row,
            sortValue: -1,
        },
    })
    // End Table Setup

    const regenerateInvoice = async (
        recalculateFinance: boolean,
        invoiceId: number,
    ) => {
        axiosInstance
            .post(`invoice/${invoiceId}/regenerate_invoice/`, {
                recalculate_finances: recalculateFinance,
            })
            .then((res) => {
                toast.success(`Invoice ${invoiceId} Successfully Regenerated!`)
                dispatch(updateInvoice(res.data))
            })
            .catch(() => {
                toast.error('There was an error regenerating the invoice')
            })
    }

    const vendorMap: ModelMap<ListVendor> = {}
    vendorList.map((vendor) => (vendorMap[vendor.id] = vendor))

    if (pageLoading) {
        return (
            <Container alignItems="center" justifyContent="center" flex={1}>
                <CircularProgress
                    size={60}
                    style={{ marginTop: theme.spacing(10) }}
                />
            </Container>
        )
    }

    return (
        <Container
            direction="column"
            style={{
                flex: 1,
                height: 'calc(100vh - 104px)',
                width: 'calc(100vw - 184px)',
            }}
        >
            {/* Header */}
            <Container
                style={{ marginBottom: theme.spacing(1), alignItems: 'center' }}
            >
                <ArrowBackIosIcon
                    onClick={() => {
                        history.goBack()
                    }}
                    className={`${classes.hoverGrey300} ${classes.circle}`}
                    style={{
                        margin: theme.spacing(0, 0, 0, 1),
                        paddingLeft: theme.spacing(1),
                    }}
                    fontSize="large"
                />

                <span
                    style={{
                        ...theme.typography.h4,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {companyDetail?.name}
                </span>

                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightLight,
                        marginLeft: theme.spacing(1),
                    }}
                >
                    {invoiceList?.length} Invoices
                </span>
            </Container>

            <SimpleTable<InvoiceRow>
                rows={invoiceList?.map(createRow) ?? []}
                render={(row) => {
                    let entrataMessage = ''
                    if (row.invoice.value.is_entrata) {
                        entrataMessage = 'Synced to Entrata'
                    }
                    const DisplayButton = true
                    // vendorMap[row.invoice.value.vendor.id]
                    //     ?.entrata_sync_enabled
                    return (
                        <TableRow
                            key={`INVOICE__${row.id.value}`}
                            style={{ cursor: 'pointer' }}
                        >
                            <TableCell>
                                <span>INV #{row.id.value}</span>
                            </TableCell>
                            <TableCell style={cellStyle}>
                                <span>{toMMDDYYYY(row.date.value)}</span>
                            </TableCell>
                            <TableCell style={cellStyle}>
                                <NumberFormat
                                    displayType="text"
                                    value={row.total_cost.value}
                                    prefix="$"
                                    thousandSeparator
                                    fixedDecimalScale
                                    decimalScale={2}
                                />
                            </TableCell>
                            <TableCell align="center" style={cellStyle}>
                                <IconButton
                                    onClick={() => {
                                        if (row.invoice.value.invoice) {
                                            window.open(
                                                row.invoice.value.invoice,
                                            )
                                        } else {
                                            toast.error(
                                                `Invoice # ${row.invoice.value.id} was not found.  Please contact the support team.`,
                                            )
                                        }
                                    }}
                                >
                                    <PictureAsPdfIcon />
                                </IconButton>
                                {isAccountManager(workspaceUser) && (
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => {
                                            setInvoiceRegenerationId(
                                                row.invoice.value.id,
                                            )
                                        }}
                                    >
                                        Regenerate
                                    </Button>
                                )}
                            </TableCell>
                            {workspaceUser?.active_workspace.company_type ===
                                'APARTMENT' && (
                                <TableCell align="center" style={cellStyle}>
                                    {DisplayButton &&
                                    row.invoice.value.entrata_id === null ? (
                                        isLoading &&
                                        row.invoice.value.id ===
                                            selectedInvoice?.id ? (
                                            <CircularProgress />
                                        ) : (
                                            <Button
                                                variant="contained"
                                                style={{
                                                    margin: theme.spacing(2),
                                                    backgroundColor: '#008C85',
                                                    color: 'white',
                                                    textTransform: 'none',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setSelectedInvoice(
                                                        row.invoice.value,
                                                    )
                                                    apartmentVendors.map(
                                                        (aptVendor) => {
                                                            if (
                                                                aptVendor.vendor
                                                                    .id ===
                                                                row.invoice
                                                                    .value
                                                                    .vendor.id
                                                            ) {
                                                                setSelectedApartmentVendor(
                                                                    aptVendor,
                                                                )
                                                            }
                                                        },
                                                    )
                                                    setOpenManualSyncModal(true)
                                                }}
                                            >
                                                Send Invoice
                                            </Button>
                                        )
                                    ) : (
                                        entrataMessage
                                    )}
                                </TableCell>
                            )}
                        </TableRow>
                    )
                }}
                orderByDefault="date"
                headCells={headCells}
            />

            {selectedInvoice && (
                <EntrataInvoiceModal
                    invoice={selectedInvoice}
                    open={openManualSyncModal}
                    apartmentVendor={selectedApartmentVendor}
                    entrataVendors={entrataVendors}
                    entrataGLInfo={entrataGLInfo}
                    companyId={companyId}
                    handleClose={() => setOpenManualSyncModal(false)}
                    setSelectedInvoice={setSelectedInvoice}
                    setIsLoading={setIsLoading}
                />
            )}

            <Dialog
                open={invoiceRegenerationId !== -1}
                onClose={() => setInvoiceRegenerationId(-1)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Please use caution
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        A financial recalculation will delete all the invoice
                        items attached to the invoice, and recreate them using
                        the workorders current financial information. Any
                        changes to service contracts, workorder add / custom
                        price, or change order financials will be reflected on
                        the new pdf.
                        <br />
                        <br />A superficial regeneration will recreate the pdf
                        leaving the financial calculations intact. This is
                        useful if an address has changed, or if the invoice pdf
                        failed to generate initially.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setInvoiceRegenerationId(-1)}>
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Checkbox
                        checked={financialConfirm}
                        onChange={() => setFinancialConfirm((c) => !c)}
                    />
                    <Button
                        onClick={() => {
                            setInvoiceRegenerationId(-1)
                            regenerateInvoice(true, invoiceRegenerationId)
                        }}
                        color="secondary"
                        disabled={!financialConfirm}
                    >
                        Financial Recalculation
                    </Button>
                    <Button
                        onClick={() => {
                            setInvoiceRegenerationId(-1)
                            regenerateInvoice(false, invoiceRegenerationId)
                        }}
                        color="primary"
                        autoFocus
                    >
                        Superficial
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
