import React from 'react'

import { Theme } from '@material-ui/core'
import { Container, StatusBadge } from '../../components'
import { BaseWorkorder, getUiStatus } from '../../models'
import { usePopover } from '../../hooks/usePopover'
import { WorkorderTransitionLogPopver } from './WorkorderTransitionLogPopover'

interface Props {
    theme: Theme
    style?: { container?: React.CSSProperties; text?: React.CSSProperties }
    workorder: BaseWorkorder
}

export const WorkorderStatusBadge = (props: Props) => {
    const { workorder, theme, style } = props

    const status = workorder.status

    const text = getUiStatus(status)

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    return (
        <Container>
            <StatusBadge
                onClick={(e) => {
                    e.stopPropagation()
                    handleOpen(e)
                }}
                text={text}
                customStyle={{
                    container: {
                        backgroundColor: theme.palette[status].main,
                        //min width or else in progress gets put on two lines
                        minWidth: 80,
                        ...style?.container,
                    },
                    text: {
                        ...theme.typography.caption,
                        ...style?.text,
                    },
                }}
            />

            <WorkorderTransitionLogPopver
                open={isOpen}
                anchorEl={anchorEl}
                closePopover={(e) => {
                    const event = e as any
                    event.stopPropagation()
                    handleClose()
                }}
                transitionLogs={workorder.transition_logs}
                workorder={workorder}
            />
        </Container>
    )
}
