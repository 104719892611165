import { DamageConfig } from '../../models'
import {
    AptConfigState,
    AptConfigActionTypes,
    GET_AREA_CONFIG_LIST_REQUEST,
    GET_AREA_CONFIG_LIST_RESPONSE,
    GET_UNIT_CONFIG_LIST_REQUEST,
    GET_UNIT_CONFIG_LIST_RESPONSE,
    SET_APT_CONFIG_LOADING,
    SET_DAMAGE_CONFIG_LIST,
    ADD_DAMAGE_CONFIG,
    UPDATE_DAMAGE_CONFIG,
    SET_INVENTORY_CONFIG_LIST,
    ADD_INVENTORY_CONFIG,
    SET_STATUS_GROUP_LIST,
    ADD_STATUS_GROUP,
    SET_STATUS_LIST,
    UPDATE_INVENTORY_CONFIG,
    UPDATE_STATUS_GROUP,
    ADD_AREA_CONFIG,
    UPDATE_AREA_CONFIG,
    SET_INSPECTION_TYPE_LIST,
    SET_AREA_STATUS_CONFIG_LIST,
    ADD_INSPECTION_TYPE,
    EDIT_INSPECTION_TYPE,
    ADD_CHANGE_ORDER_CONFIG,
    SET_CHANGE_ORDER_CONFIG_LIST,
    UPDATE_CHANGE_ORDER_CONFIG,
    UPDATE_UNIT_CONFIG,
} from './types'

const initialState: AptConfigState = {
    isLoading: {
        GET_AREA_CONFIG_LIST_REQUEST: false,
        GET_UNIT_CONFIG_LIST_REQUEST: false,
        GET_DAMAGE_CONFIG_LIST_REQUEST: false,
        ADD_DAMAGE_CONFIG: false,
        UPDATE_DAMAGE_CONFIG_REQUET: false,
        GET_INVENTORY_CONFIG_LIST: false,
        CREATE_INVENTORY_CONFIG_REQUEST: false,
        GET_STATUS_GROUP_LIST_REQUEST: false,
        CREATE_STATUS_GROUP_REQUEST: false,
        GET_STATUS_LIST_REQUEST: false,
        UPDATE_INVENTORY_CONFIG_REQUEST: false,
        UPDATE_STATUS_GROUP_REQUEST: false,
        CREATE_AREA_CONFIG_REQUEST: false,
        UPDATE_AREA_CONFIG_REQUEST: false,
        SET_INVENTORY_STATUS_COST_REQUEST: false,
        GET_INSPECTION_TYPE_LIST: false,
        CREATE_INSPECTION_TYPE: false,
        EDIT_INSPECTION_TYPE: false,
        GET_AREA_STATUS_CONFIG_REQUEST: false,
        GET_CHANGE_ORDER_CONFIG_LIST_REQUEST: false,
        CREATE_CHANGE_ORDER_CONFIG_REQUEST: false,
        EDIT_CHANGE_ORDER_CONFIG_REQUEST: false,
        UPDATE_UNIT_CONFIG_REQUEST: false,
    },
    areaConfigList: [],
    unitConfigList: [],
}

export const aptConfigReducer = (
    state = initialState,
    action: AptConfigActionTypes,
): AptConfigState => {
    switch (action.type) {
        case SET_AREA_STATUS_CONFIG_LIST:
            return {
                ...state,
                areaStatusConfigList: action.areaStatusConfigList,
            }

        case GET_AREA_CONFIG_LIST_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_AREA_CONFIG_LIST_REQUEST: true,
                },
            }
        case GET_AREA_CONFIG_LIST_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_AREA_CONFIG_LIST_REQUEST: false,
                },
                areaConfigList: action.payload,
            }
        case GET_UNIT_CONFIG_LIST_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_UNIT_CONFIG_LIST_REQUEST: true,
                },
            }
        case GET_UNIT_CONFIG_LIST_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_UNIT_CONFIG_LIST_REQUEST: false,
                },
                unitConfigList: action.payload,
            }

        case SET_DAMAGE_CONFIG_LIST:
            return {
                ...state,
                damageConfigList: action.damageConfigList,
            }

        case ADD_DAMAGE_CONFIG:
            return {
                ...state,
                damageConfigList: [
                    ...(state.damageConfigList ?? []),
                    action.damageConfig,
                ],
            }

        case UPDATE_DAMAGE_CONFIG:
            return {
                ...state,
                damageConfigList: state.damageConfigList?.reduce<
                    DamageConfig[]
                >((prev, dc) => {
                    if (action.id === dc.id) {
                        // update found
                        if (action.damageConfig) {
                            return prev.concat(action.damageConfig)
                        }

                        // Remove
                        return prev
                    }
                    return prev.concat(dc)
                }, []),
            }

        case SET_APT_CONFIG_LOADING:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.request]: action.isLoading,
                },
            }
        case SET_INVENTORY_CONFIG_LIST:
            return {
                ...state,
                inventoryConfigList: action.inventoryConfigList,
            }
        case ADD_INVENTORY_CONFIG:
            return {
                ...state,
                inventoryConfigList: state.inventoryConfigList
                    ? [...state.inventoryConfigList, action.invConfig]
                    : [action.invConfig],
            }
        case SET_STATUS_GROUP_LIST:
            return {
                ...state,
                statusGroupList: action.statusGroupList,
            }
        case ADD_STATUS_GROUP:
            return {
                ...state,
                statusGroupList: state.statusGroupList
                    ? [...state.statusGroupList, action.statusGroup]
                    : [action.statusGroup],
            }
        case SET_STATUS_LIST:
            return {
                ...state,
                statusList: action.statusList,
            }
        case UPDATE_INVENTORY_CONFIG:
            return {
                ...state,
                inventoryConfigList: state.inventoryConfigList
                    ? state.inventoryConfigList.map((ic) => {
                          if (ic.id === action.inventoryConfig.id) {
                              return action.inventoryConfig
                          }
                          return ic
                      })
                    : [action.inventoryConfig],
            }
        case UPDATE_STATUS_GROUP:
            return {
                ...state,
                statusGroupList: state.statusGroupList
                    ? state.statusGroupList?.map((s) => {
                          if (s.id === action.statusGroup.id) {
                              return action.statusGroup
                          }
                          return s
                      })
                    : [action.statusGroup],
            }
        case ADD_AREA_CONFIG:
            return {
                ...state,
                areaConfigList: [
                    ...(state.areaConfigList ?? []),
                    action.areaConfig,
                ],
            }
        case UPDATE_AREA_CONFIG:
            return {
                ...state,
                areaConfigList: state.areaConfigList.map((aC) => {
                    if (aC.id === action.areaConfig.id) {
                        return action.areaConfig
                    }
                    return aC
                }),
            }
        case UPDATE_UNIT_CONFIG:
            return {
                ...state,
                unitConfigList: state.unitConfigList.map((unitConfig) => {
                    if (unitConfig.id === action.unitConfig.id) {
                        return action.unitConfig
                    }
                    return unitConfig
                }),
            }
        case SET_INSPECTION_TYPE_LIST:
            return {
                ...state,
                inspectionTypeList: action.inspectionTypeList,
            }
        case ADD_INSPECTION_TYPE:
            return {
                ...state,
                inspectionTypeList: [
                    ...(state.inspectionTypeList ?? []),
                    action.inspectionType,
                ],
            }
        case EDIT_INSPECTION_TYPE:
            return {
                ...state,
                inspectionTypeList: state.inspectionTypeList?.map((insType) => {
                    if (action.inspectionType.id === insType.id) {
                        return action.inspectionType
                    }
                    return insType
                }),
            }
        case ADD_CHANGE_ORDER_CONFIG:
            return {
                ...state,
                changeOrderConfigList: [
                    ...(state.changeOrderConfigList ?? []),
                    action.changeOrderConfig,
                ],
            }
        case SET_CHANGE_ORDER_CONFIG_LIST:
            return {
                ...state,
                changeOrderConfigList: action.changeOrderConfigList,
            }
        case UPDATE_CHANGE_ORDER_CONFIG:
            return {
                ...state,
                changeOrderConfigList: state.changeOrderConfigList?.map(
                    (changeOrderConfig) => {
                        if (
                            action.changeOrderConfig.id === changeOrderConfig.id
                        ) {
                            return action.changeOrderConfig
                        }
                        return changeOrderConfig
                    },
                ),
            }
        default:
            return state
    }
}
