import React from 'react'
import { WorkorderResponse } from '../../../store'
import { PopoverState } from '../../../hooks/usePopover'
import {
    ChangeOrder,
    getChangeOrderPrice,
    getServiceContractPrice,
} from '../../../models'
import { Divider, Popover, Theme } from '@material-ui/core'
import { Container } from '../../../components'
import { currencyFormatter } from '../../../helpers'

interface Props {
    workorder: WorkorderResponse
    popoverState: PopoverState<any>
    approvedChangeOrders: ChangeOrder[]
    theme: Theme
}

export const WorkorderCostPopover = (props: Props) => {
    const { workorder, popoverState, approvedChangeOrders, theme } = props

    let usingContract = true
    let workorderPrice = 0
    if (
        workorder.custom_price !== null &&
        workorder.custom_price !== undefined
    ) {
        // Custom Price is set.  Always use custom price instead of service contracts
        workorderPrice = workorder.custom_price
        usingContract = false
    } else {
        // Workorder does not have a custom price.  Use pricing from service contracts
        workorderPrice = getServiceContractPrice(workorder)
    }

    const addPrice = workorder.add_price

    const totalChangeOrderPrice = getChangeOrderPrice(workorder)
    const totalPrice = workorderPrice + addPrice + totalChangeOrderPrice

    const subHeadStyle: React.CSSProperties = {
        ...theme.typography.subtitle2,
        fontWeight: theme.typography.fontWeightBold,
    }

    const infoStyle: React.CSSProperties = {
        ...theme.typography.subtitle2,
        color: theme.palette.grey[700],
    }

    return (
        <Popover
            anchorEl={popoverState.anchorEl}
            open={popoverState.isOpen}
            onClose={() => {
                popoverState.handleClose()
            }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    padding: theme.spacing(1),
                    minWidth: '250px',
                    flex: 1,
                }}
            >
                <Container style={{ width: '100%' }}>
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            marginBottom: theme.spacing(1),
                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                            width: '100%',
                        }}
                    >
                        Cost Breakdown
                    </span>
                </Container>
                <Container style={{ flexDirection: 'column', width: '100%' }}>
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: theme.typography.fontWeightMedium,
                            width: '',
                        }}
                    >
                        Workorder
                    </span>
                    <Container>
                        <Container style={{ flexDirection: 'column', flex: 1 }}>
                            <span style={subHeadStyle}>
                                {usingContract
                                    ? 'Contract Price'
                                    : 'Custom Price'}
                            </span>
                            <span style={subHeadStyle}>Add Price</span>
                        </Container>
                        <Container style={{ flexDirection: 'column', flex: 1 }}>
                            <span style={infoStyle}>
                                {currencyFormatter.format(workorderPrice)}
                            </span>
                            <span style={infoStyle}>
                                {currencyFormatter.format(addPrice)}
                            </span>
                        </Container>
                    </Container>
                    {approvedChangeOrders.length > 0 && (
                        <Container
                            style={{ flexDirection: 'column', width: '100%' }}
                        >
                            <Divider style={{ marginTop: theme.spacing(1) }} />
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight:
                                        theme.typography.fontWeightMedium,
                                    width: '',
                                }}
                            >
                                Change Orders
                            </span>
                            <Container>
                                <Container
                                    style={{
                                        flexDirection: 'column',
                                        marginRight: theme.spacing(2),
                                    }}
                                >
                                    <span style={{ ...subHeadStyle, flex: 1 }}>
                                        Description
                                    </span>
                                    {approvedChangeOrders.map((co) => {
                                        return (
                                            <span
                                                key={`CHANGEORDER_${co.id}_DESCRIPTION`}
                                                style={infoStyle}
                                            >
                                                {co.description}
                                            </span>
                                        )
                                    })}
                                </Container>
                                <Container
                                    style={{
                                        flexDirection: 'column',
                                        marginRight: theme.spacing(2),
                                    }}
                                >
                                    <span style={{ ...subHeadStyle, flex: 1 }}>
                                        Quantity
                                    </span>
                                    {approvedChangeOrders.map((co) => {
                                        return (
                                            <span
                                                key={`CHANGEORDER_${co.id}_QUANTITY`}
                                                style={infoStyle}
                                            >
                                                {co.quantity}
                                            </span>
                                        )
                                    })}
                                </Container>
                                <Container
                                    style={{
                                        flexDirection: 'column',
                                        marginRight: theme.spacing(2),
                                    }}
                                >
                                    <span style={{ ...subHeadStyle, flex: 1 }}>
                                        Price / Qty
                                    </span>
                                    {approvedChangeOrders.map((co) => {
                                        return (
                                            <span
                                                key={`CHANGEORDER_${co.id}_PRICE_QUANTITY`}
                                                style={infoStyle}
                                            >
                                                {currencyFormatter.format(
                                                    co.price,
                                                )}
                                            </span>
                                        )
                                    })}
                                </Container>
                                <Container
                                    style={{
                                        flexDirection: 'column',
                                        marginRight: theme.spacing(2),
                                    }}
                                >
                                    <span style={{ ...subHeadStyle, flex: 1 }}>
                                        Total
                                    </span>
                                    {approvedChangeOrders.map((co) => {
                                        return (
                                            <span
                                                key={`CHANGEORDER_${co.id}_TOTAL`}
                                                style={infoStyle}
                                            >
                                                {currencyFormatter.format(
                                                    co.price * co.quantity,
                                                )}
                                            </span>
                                        )
                                    })}
                                </Container>
                            </Container>
                        </Container>
                    )}
                    <Divider style={{ marginTop: theme.spacing(1) }} />
                    <Container>
                        <Container style={{ flexDirection: 'column', flex: 1 }}>
                            <span style={subHeadStyle}>Total Price</span>
                        </Container>
                        <Container style={{ flexDirection: 'column', flex: 1 }}>
                            <span style={infoStyle}>
                                {currencyFormatter.format(totalPrice)}
                            </span>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </Popover>
    )
}
