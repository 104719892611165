import React from 'react'
import { Tooltip, useTheme } from '@material-ui/core'
import { Container } from '..'
import { BaseWorkorder } from '../../models'

interface Props {
    workorders: BaseWorkorder[]
    popOverAnchror: HTMLElement | null
    openPopover: PopoverHandler
}

export const ServiceDots = (props: Props) => {
    const { workorders } = props

    const theme = useTheme()

    const serviceDots: JSX.Element[] = []

    for (let i = 0; i < 3 && i < workorders.length; i++) {
        const wo = workorders[i]

        let extraText = ''
        if (i == 2 && workorders.length > 3) {
            extraText = `+${workorders.length - 3}`
        }

        if (wo.service_name) {
            serviceDots.push(
                <Tooltip title={wo.service_name}>
                    <div
                        onClick={(e) => {
                            props.openPopover(e, props.workorders)
                        }}
                        key={`SRV_DOT_WO_${wo.id}`}
                        style={{
                            height: 22,
                            width: 22,
                            borderRadius: '100%',
                            backgroundColor:
                                wo.service_color ??
                                theme.palette.noService.main,
                            transform: `translateX(-${i * 6}px)`,
                            boxShadow: theme.shadows[2],
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontWeight: 600,
                        }}
                    >
                        {extraText}
                    </div>
                </Tooltip>,
            )
        }
    }

    return (
        <Container flex={1} alignItems="center">
            {serviceDots}
        </Container>
    )
}

type PopoverHandler = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    workorders: BaseWorkorder[],
) => void
