import React, { useRef, useEffect } from 'react'
import Moment from 'react-moment'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
    Avatar,
    Button,
    FormControl,
    Icon,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    useTheme,
} from '@material-ui/core'
import ImageIcon from '@material-ui/icons/Image'

import { Attachment } from './attachment'
const json2html = require('json-to-html')
import { stateToHTML } from 'draft-js-export-html'
import { convertToHTML } from 'draft-convert'
import { convertFromRaw } from 'draft-js'
import { Message, WorkSpaceUser } from '../../../models'
import { AvatarCircle, Container } from '../../../components'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        senderMsgDate: {
            fontWeight: 500,
            fontSize: '0.625rem',
            color: '#000',
            paddingLeft: '15px',
        },
        loggedinUserMsgDate: {
            fontWeight: 500,
            fontSize: '0.625rem',
            color: '#000',
            paddingRight: '15px',
        },
        senderUserMsg: {
            width: '90%',
            paddingTop: 0,
            paddingBottom: 0,
            '& .MuiListItemText-primary': {
                color: '#008C85',
                fontSize: '0.813rem',
                fontWeight: 600,
            },
            '& .MuiListItem-gutters': {
                // paddingLeft: 0,
                // paddingRight: 0,
            },
            '& .MuiListItemText-secondary': {
                fontSize: '0.813rem',
                color: '#000',
                letterSpacing: '-0.24px',
            },
        },
        loggedinUserMsg: {
            paddingLeft: '10%',
            paddingTop: 0,
            paddingBottom: 0,
            '& .MuiListItemText-primary': {
                color: '#008C85',
                fontSize: '0.813rem',
                fontWeight: 600,
            },
            '& .MuiListItem-root': {
                textAlign: 'right',
            },
            '& .MuiAvatar-root': {
                float: 'right',
            },
            '& .MuiListItem-gutters': {
                // paddingLeft: 0,
                // paddingRight: 0,
            },
            '& .MuiListItemText-secondary': {
                fontSize: '0.813rem',
                color: '#000',
                letterSpacing: '-0.24px',
            },
        },
    }),
)

function isJsonString(str: string) {
    try {
        JSON.parse(str)
        return true
    } catch (e) {
        return false
    }
    return false
}

const getHtmlFromMsgContent = (content: any) => {
    if (isJsonString(content)) {
        console.log(content)
        const json = JSON.parse(content)
        const contentState = convertFromRaw(json)

        /********** Trial 1 [start]********** */

        // const options = {
        //     entityStyleFn: (entity: any) => {
        //         const entityType = entity.get('type').toLowerCase()

        //         if (entityType === 'mention') {
        //             const { mention } = entity.get('data')
        //             return {
        //                 element: 'button',
        //                 attributes: {
        //                     target: console.log('hello'),
        //                 },
        //                 style: {
        //                     color: 'blue',
        //                     background: 'inherit',
        //                     border: 'none',
        //                     cursor: 'pointer',
        //                 },
        //             }
        //         }
        //     },
        // }

        // const html = stateToHTML(contentState, options)
        // console.log('html is...', html)
        /*********** Trial 1 [end] ********* */

        /********** Trial 2 [start]********** */

        const html = convertToHTML({
            styleToHTML: (style) => {
                if (style === 'BOLD') {
                    return <span style={{ color: 'blue' }} />
                }
            },
            blockToHTML: (block) => {
                if (block.type === 'PARAGRAPH') {
                    return <p />
                }
            },
            entityToHTML: (entity, originalText) => {
                console.log('enity to html is...', entity)
                if (entity.type === 'mention') {
                    return (
                        <button
                            onClick={() => alert('hello')}
                            data-mention={originalText}
                            style={{
                                color: 'blue',
                                background: 'inherit',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            {originalText}
                        </button>
                    )
                }
                return originalText
            },
        })(contentState)

        console.log('new html is...', html)
        /********** Trial 2 [end]********** */
        return html
    } else {
        return content
    }
}

interface Props {
    messageList: Message[] | undefined
    workspaceUser: WorkSpaceUser | undefined
}

export const ChatHistory = (props: Props) => {
    const classes = useStyles()
    const theme = useTheme()
    const bottomRef = useRef<null | HTMLDivElement>(null)

    if (bottomRef.current) {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const wrapperRef = useRef(null)
    const useUserTaggingHandler = (ref: any) => {
        useEffect(() => {
            function handleUserTagging(event: any) {
                if (
                    ref.current &&
                    ref.current.contains(event.target) &&
                    event.target.dataset &&
                    event.target.dataset.mention
                ) {
                    // props.toggleProfileDrawer(null) //COMMENTED FOR NOW. WILL ADDED IT LATER
                }
            }

            document.addEventListener('click', handleUserTagging) // Bind the event listener
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('click', handleUserTagging)
            }
        }, [ref])
    }
    useUserTaggingHandler(wrapperRef)
    return (
        <>
            <div ref={wrapperRef}>
                {props.messageList && props.messageList.length > 0 ? (
                    props.messageList.map((msg: any, index: number) =>
                        props.workspaceUser?.id === msg.sender ? (
                            <List
                                key={`LIST-${msg.id}`}
                                className={classes.loggedinUserMsg}
                            >
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <>
                                                <span
                                                    className={
                                                        classes.loggedinUserMsgDate
                                                    }
                                                >
                                                    <Moment format="MM/DD/YYYY">
                                                        {msg.created_date}
                                                    </Moment>
                                                </span>
                                                <span>{msg.sender_name}</span>
                                            </>
                                        }
                                        secondary={
                                            <Container
                                                direction="column"
                                                alignItems="flex-end"
                                            >
                                                {
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: getHtmlFromMsgContent(
                                                                msg.content,
                                                            ),
                                                        }}
                                                    ></span>
                                                }
                                                <Attachment
                                                    attachments={
                                                        msg.attachments
                                                    }
                                                />
                                            </Container>
                                        }
                                        style={{
                                            marginRight: theme.spacing(1),
                                        }}
                                    />
                                    <AvatarCircle
                                        image={msg.sender_profile_picture}
                                        text={msg.sender_name}
                                        initials
                                        styles={{
                                            container: {
                                                backgroundColor:
                                                    theme.palette.primary.dark,
                                            },
                                        }}
                                    />
                                </ListItem>
                            </List>
                        ) : (
                            <List
                                className={classes.senderUserMsg}
                                key={`msg-${index}`}
                            >
                                <ListItem>
                                    <AvatarCircle
                                        image={msg.sender_profile_picture}
                                        text={msg.sender_name}
                                        initials
                                        styles={{
                                            container: {
                                                backgroundColor:
                                                    theme.palette.primary.dark,
                                            },
                                        }}
                                    />
                                    <ListItemText
                                        primary={
                                            <>
                                                <span>{msg.sender_name}</span>
                                                <span
                                                    className={
                                                        classes.senderMsgDate
                                                    }
                                                >
                                                    <Moment format="MM/DD/YYYY">
                                                        {msg.created_date}
                                                    </Moment>
                                                </span>
                                            </>
                                        }
                                        secondary={
                                            <Container direction="column">
                                                <span>{msg.content}</span>
                                                <Attachment
                                                    attachments={
                                                        msg.attachments
                                                    }
                                                />
                                            </Container>
                                        }
                                        style={{
                                            marginLeft: theme.spacing(1),
                                        }}
                                    />
                                </ListItem>
                            </List>
                        ),
                    )
                ) : (
                    <></>
                )}
                <div ref={bottomRef} />
            </div>
        </>
    )
}
