import { LeaseStatus, WorkorderStatus } from '../../models'
import {
    AssignmentFilter,
    InspectionCompletionFilter,
    InfrastructureFilterActionTypes,
    InfrastructureFilterOptions,
    InfrastructureFilterState,
    SET_INFRASTRUCTURE_FILTER,
    InspectionHandledFilter,
    CalendarFilterMode,
    ChangeOrderFilterMode,
    PartialFilterMode,
    KeysFilterMode,
} from './types'

export const defaultFilterState: InfrastructureFilterOptions = {
    serviceFilter: [],
    vendorFilter: [],
    unitConfigFilter: [],
    invConfigFilter: [],
    vacantFilter: false,
    priorityFilter: false,
    unreadFilter: false,
    assignmentFilter: AssignmentFilter.All,
    inspectionCompletionFilter: InspectionCompletionFilter.All,
    hasDamages: false,
    hasMessages: false,
    manualServiceAreas: false,
    isGhost: false,
    hasGoBack: false,
    hasFlaggedItem: false,
    hasCostDriver: false,
    behindWorkorders: false,
    areaStatusFilter: {
        filterLength: 0,
    },
    statusFilter: {
        ASSIGNED: false,
        IN_PROGRESS: false,
        PAUSE: false,
        COMPLETE: false,
        GO_BACK: false,
        APPROVED: false,
        PRE_APPROVED: false,
        INVOICED: false,
    },
    customStatusFilter: {},
    dateFilter: {
        enable: false,
        filterMode: CalendarFilterMode.Overlap,
        filterLeases: false,
        filterServices: true,
        range: {
            startDate: new Date(),
            endDate: new Date(),
            key: 'date-range',
        },
    },
    unitSearch: '',
    handled: InspectionHandledFilter.All,
    changeOrder: ChangeOrderFilterMode.All,
    readyUnitFilter: {
        enabled: false,
        value: 'APPROVED' as WorkorderStatus,
    },
    partialUnitFilter: PartialFilterMode.All,
    displayVacantAreas: false,
    keysFilter: KeysFilterMode.All,
}

const initialState: InfrastructureFilterState = {
    filter: defaultFilterState,
}

export const infrastructureFilterReducer = (
    state = initialState,
    action: InfrastructureFilterActionTypes,
): InfrastructureFilterState => {
    switch (action.type) {
        case SET_INFRASTRUCTURE_FILTER:
            return {
                ...state,
                filter: action.newFilter ?? defaultFilterState,
            }

        default:
            return state
    }
}
