import React from 'react'
import NumberFormat from 'react-number-format'
import { Container } from '../Container'
import { Progress } from './Progress'

interface ProgressConfig {
    progress: number
    size: number
    barKey: string
    strokeWidth?: number
    backdropStroke?: string
    primaryStroke?: string
}

interface Props {
    data: ProgressConfig[]
    centerText?: string | number
    suffix?: string
    prefix?: string
}

export const NestedProgress = (props: Props) => {
    return (
        <Container
            style={{
                position: 'relative',
                height: '100%',
                width: '100%',
            }}
            justifyContent="center"
            alignItems="center"
        >
            <NumberFormat
                suffix={props.suffix}
                prefix={props.prefix}
                value={props.centerText}
                displayType="text"
                decimalScale={1}
            />
            {props.data.map((conf) => (
                <Progress
                    key={conf.barKey}
                    progress={conf.progress}
                    strokeWidth={conf.strokeWidth ?? STROKE_WIDTH}
                    circleOneStroke={conf.backdropStroke}
                    circleTwoStroke={conf.primaryStroke}
                    size={conf.size}
                    containerStyle={PROGRESS_STYLE}
                />
            ))}
        </Container>
    )
}

const PROGRESS_STYLE: React.CSSProperties = {
    position: 'absolute',
}

const STROKE_WIDTH = 5
