import {
    Avatar,
    Button,
    CircularProgress,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    Slide,
    TextField,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container, Selector } from '../../components'
import { RFP, RFPStatus, RFPType } from '../../models'
import { getInitials, toMMDDYYYY } from '../../helpers'
import { ListPopover } from '../../components/ListPopover/ListPopover'
import { usePopover } from '../../hooks/usePopover'
import SearchIcon from '@material-ui/icons/Search'
import { useService } from '../../hooks'
import { VendorViewRFPDetail } from './VendorViewRFPDetail'
import { useMultiSelectStyles } from '../../styles'
import { rfpApi } from '../../contexts'
import { DisplayService } from '../../components/DisplayService'

interface Props {
    notInvitedJobs?: boolean
}

export const OpenJobsPage = (props: Props) => {
    const { notInvitedJobs } = props

    const [openRFPDetail, setOpenRFPDetail] = useState(false)

    const {
        filteredRFPList,
        rfpFilterState,
        loadingState,
        selectedRFP,
        rfpList,
        radius,
        createBid,
        setRFPFilterState,
        getRFPList,
        setSelectedRFP,
        setRadius,
        getRFPAndBidCounts,
    } = rfpApi()

    const { serviceList } = useService({ getServiceList: true, cleanUp: true })

    const theme = useTheme()

    const radiusChoices: number[] = [50, 100, 150, 200, 500, 1000, 3000]

    return (
        <Container
            flex={1}
            style={{
                margin: theme.spacing(1),
            }}
            direction="column"
        >
            {!notInvitedJobs ? (
                <Container direction="column">
                    <Container
                        style={{ ...theme.typography.h6, fontWeight: 700 }}
                    >
                        Ready for your next job?
                    </Container>
                    <Container
                        style={{
                            ...theme.typography.subtitle2,
                            fontWeight: 400,
                            marginTop: theme.spacing(1),
                        }}
                    >
                        Browse our latest job openings down below to find your
                        next job today.
                    </Container>
                </Container>
            ) : (
                <Container direction="column">
                    <Container
                        style={{ ...theme.typography.h6, fontWeight: 700 }}
                    >
                        Unsolicited Jobs
                    </Container>
                    <Container
                        style={{
                            ...theme.typography.subtitle2,
                            fontWeight: 400,
                            marginTop: theme.spacing(1),
                        }}
                    >
                        Browse the jobs that you were not invited to and send an
                        unsolicited bid for the job.
                    </Container>
                </Container>
            )}
            <Container>
                <TextField
                    size="small"
                    variant="outlined"
                    placeholder="Search"
                    value={rfpFilterState.searchText}
                    onChange={(e) => {
                        setRFPFilterState({
                            ...rfpFilterState,
                            searchText: e.target.value,
                        })
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    style={{
                        margin: theme.spacing(2, 2, 0, 0),
                    }}
                />
                <FormControl
                    variant="outlined"
                    style={{ marginTop: theme.spacing(2) }}
                >
                    <InputLabel id="RADIUS_FILTER_LABEL">Radius</InputLabel>
                    <Select
                        labelId="RADIUS_FILTER_LABEL"
                        id="radius_filter_label"
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            const tempRadius = event.target.value as number
                            setRadius(tempRadius)
                            getRFPList({
                                params: {
                                    type: !notInvitedJobs
                                        ? RFPType.PUBLIC
                                        : RFPType.INVITE_ONLY,
                                    open_jobs: true,
                                    radius: tempRadius,
                                    statuses: RFPStatus.PUBLISHED,
                                    unsolicited_jobs: notInvitedJobs,
                                },
                            })
                            getRFPAndBidCounts(tempRadius)
                        }}
                        value={radius}
                        label="radius"
                        style={{
                            width: '200px',
                            height: '40px',
                            marginRight: theme.spacing(2),
                            alignSelf: 'center',
                        }}
                    >
                        {radiusChoices.map((radiusChoice) => {
                            if (radiusChoice === 3000) {
                                return (
                                    <MenuItem
                                        key={`RADIUS_CHOICE_${radiusChoice}`}
                                        value={radiusChoice}
                                    >
                                        National
                                    </MenuItem>
                                )
                            }
                            return (
                                <MenuItem
                                    key={`RADIUS_CHOICE_${radiusChoice}`}
                                    value={radiusChoice}
                                >
                                    {radiusChoice} miles
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <Selector
                    data={serviceList}
                    size="small"
                    customStyle={{
                        formControl: {
                            margin: theme.spacing(2, 0, 0, 0),
                            minWidth: '200px',
                        },
                    }}
                    label="Services"
                    maxItems={10}
                    onChange={(e) => {
                        const selection: number[] = e.target.value as any

                        setRFPFilterState({
                            ...rfpFilterState,
                            serviceIds: selection,
                        })
                    }}
                    currentValue={rfpFilterState.serviceIds}
                    getDisplayString={(v) => v.name}
                    searchable
                    multiple
                />
            </Container>
            {loadingState.getRFPList ? (
                <Container
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={100} />
                </Container>
            ) : (
                <Container direction="column">
                    <Container
                        style={{
                            ...theme.typography.h6,
                            fontWeight: 600,
                            marginTop: theme.spacing(1),
                        }}
                    >
                        Showing {filteredRFPList?.length ?? 0} jobs
                    </Container>
                    {rfpList?.length === 0 && (
                        <Container
                            style={{
                                ...theme.typography.subtitle1,
                                color: theme.palette.grey[600],
                                fontWeight: theme.typography.fontWeightMedium,
                            }}
                        >
                            Expand your radius to see more jobs!
                        </Container>
                    )}
                    <Container
                        flexWrap="wrap"
                        style={{
                            maxHeight: 'calc(100vh - 300px)',
                        }}
                        scrollY
                    >
                        {filteredRFPList?.map((rfp) => {
                            return (
                                <GeneralRFPPosting
                                    key={`GENERAL_RFP_POSTING_${rfp.id}`}
                                    rfp={rfp}
                                    setSelectedRFP={setSelectedRFP}
                                    setViewRFPDetail={setOpenRFPDetail}
                                />
                            )
                        })}
                    </Container>
                </Container>
            )}
            <Modal
                open={openRFPDetail}
                onClose={() => setOpenRFPDetail(false)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="up" in={openRFPDetail}>
                    <Paper
                        style={{
                            width: 1500,
                            maxWidth: 'calc(100vw - 100px)',
                            height: 1100,
                            maxHeight: 'calc(100vh - 100px)',
                        }}
                    >
                        <VendorViewRFPDetail
                            rfp={selectedRFP}
                            handleClose={() => setOpenRFPDetail(false)}
                            createBid={createBid}
                        />
                    </Paper>
                </Slide>
            </Modal>
        </Container>
    )
}

interface RFPPostingProps {
    rfp: RFP
    setSelectedRFP: (rfp: RFP) => void
    setViewRFPDetail: (view: boolean) => void
}

const GeneralRFPPosting = (props: RFPPostingProps) => {
    const { rfp, setSelectedRFP, setViewRFPDetail } = props

    const theme = useTheme()

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    let dateRange = ''
    if (rfp.project_start_date && rfp.project_end_date) {
        dateRange = `${toMMDDYYYY(
            new Date(rfp.project_start_date),
        )} - ${toMMDDYYYY(new Date(rfp.project_end_date))}`
    } else if (rfp.project_start_date) {
        dateRange = `${toMMDDYYYY(new Date(rfp.project_start_date))}`
    }

    return (
        <Container
            style={{
                width: '400px',
                height: '380px',
                border: `1px solid ${theme.palette.grey[300]}`,
                padding: theme.spacing(2),
                margin: theme.spacing(1, 2, 1, 0),
                borderRadius: theme.shape.borderRadius,
            }}
            direction="column"
        >
            <Container alignItems="center">
                <Container style={{ marginRight: theme.spacing(2) }}>
                    {rfp.apartment.profile_picture !== null ? (
                        <img
                            src={rfp.apartment.profile_picture}
                            style={{
                                width: '45px',
                                height: '45px',
                            }}
                        />
                    ) : (
                        <Avatar
                            variant="square"
                            style={{
                                backgroundColor: theme.palette.primary.dark,
                                width: '45px',
                                height: '45px',
                                fontSize: 20,
                            }}
                        >
                            {getInitials(rfp.apartment.name)}
                        </Avatar>
                    )}
                </Container>
                <Container direction="column">
                    <Container
                        style={{ ...theme.typography.h6, fontWeight: 600 }}
                    >
                        {rfp.apartment.name}
                    </Container>
                    <Container
                        style={{
                            ...theme.typography.subtitle2,
                            color: theme.palette.primary.dark,
                        }}
                    >
                        {rfp.apartment.primary_address.city},{' '}
                        {rfp.apartment.primary_address.state}
                    </Container>
                </Container>
            </Container>
            <Container
                style={{
                    ...theme.typography.h6,
                    fontWeight: 500,
                    margin: theme.spacing(1, 0),
                }}
            >
                {rfp.name}
            </Container>
            <Container alignItems="center">
                {rfp.rfp_services[0] && (
                    <DisplayService
                        service={rfp.rfp_services[0].service}
                        border={false}
                        noPadding
                    />
                )}
                {rfp.rfp_services.length > 1 && (
                    <>
                        <Container>
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleOpen(e)
                                }}
                                size="small"
                                style={{
                                    margin: theme.spacing(1, 0, 1, 0),
                                }}
                            >
                                +{rfp.rfp_services.length - 1}
                            </Button>
                        </Container>
                        <ListPopover
                            open={isOpen}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                horizontal: -50,
                                vertical: 'bottom',
                            }}
                            style={{ paddingLeft: theme.spacing(1) }}
                        >
                            {rfp.rfp_services.slice(1).map((rfpService) => {
                                return (
                                    <DisplayService
                                        service={rfpService.service}
                                        border={false}
                                        key={`RFP_SERVICE_DISPLAY_${rfpService.id}`}
                                    />
                                )
                            })}
                        </ListPopover>
                    </>
                )}
                <Container flex={1} />
                <Container
                    style={{
                        ...theme.typography.subtitle2,
                        fontWeight: 500,
                    }}
                >
                    {dateRange}
                </Container>
            </Container>
            <Container
                style={{
                    ...theme.typography.body1,
                    color: theme.palette.darkGreen.main,
                    margin: theme.spacing(1, 0),
                    height: '140px',
                    overflow: 'hidden',
                }}
            >
                {rfp.description}
            </Container>
            <Container flex={1} />
            <Container>
                <Button
                    variant="outlined"
                    style={{
                        textTransform: 'none',
                        backgroundColor: theme.palette.primary.dark,
                        color: 'white',
                        width: '100%',
                    }}
                    onClick={() => {
                        setSelectedRFP(rfp)
                        setViewRFPDetail(true)
                    }}
                >
                    See More
                </Button>
            </Container>
        </Container>
    )
}
