import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

interface Props {
    colors: string[] | undefined
    categories: string[][] | undefined
    series: Array<any>
    height: number
    chartId: number | String | null
}

const ColumnChart = (props: Props) => {
    const options: ApexOptions = {
        chart: {
            height: 250,
            type: 'bar',
            events: {
                click: function (chart, w, e) {
                    // console.log(chart, w, e)
                },
            },
        },
        colors: props.colors,
        plotOptions: {
            bar: {
                columnWidth: '45%',
                distributed: true,
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
            position: 'top',
            // showForSingleSeries: false,
            // showForNullSeries: true,
            // showForZeroSeries: true,
            horizontalAlign: 'left',
            // floating: false,
            fontSize: '14px',
            // fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            // formatter: undefined,
            // inverseOrder: false,
            width: undefined,
            height: undefined,
            // tooltipHoverFormatter: undefined,
            customLegendItems: [],
            // offsetX: 0,
            // offsetY: 0,
        },
        xaxis: {
            categories: props.categories,
            tickAmount: 'dataPoints',
            labels: {
                style: {
                    colors: props.colors,
                    fontSize: '12px',
                },
                // formatter: function (val: any, tickAmount: any, opt: any) {
                //     let total = 0
                //     if (opt) {
                //         const series = opt.w.config.series[0].data
                //         const idx = opt.i
                //         total = series[idx]
                //     }

                //     // const total = series.reduce((total:any, self:any) => total + self.data[idx], 0)
                //     return val + ' : ' + total
                // },
            },
        },
    }

    return (
        <ReactApexChart
            options={options}
            series={props.series}
            type="bar"
            height={props.height}
            id={props.chartId}
        />
    )
}

export default ColumnChart
