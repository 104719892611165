import React, { useEffect } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import { Divider } from '@material-ui/core'

import MyVendorList from './MyVendorList'
import VendorsFallingBehind from './VendorsFallingBehind'
import { useCompany } from '../../../../../hooks'
import { ListVendor } from '../../../../../models'

const useStyles = makeStyles((theme: Theme) => createStyles({}))

interface Props {
    vendorList: ListVendor[] | undefined | null
}

const MyVendors = (props: Props) => {
    const classes = useStyles()

    return (
        <>
            <MyVendorList vendorList={props.vendorList} />
            <Divider style={{ marginTop: '20px' }} />
            <VendorsFallingBehind />
        </>
    )
}

export default MyVendors
