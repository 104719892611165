import { Grid, IconButton, Popover, useTheme } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import React, { useState } from 'react'
import { AvatarCircle, Container } from '../../components'
import { User } from '../../models'
import { useStyles } from '../../styles'
import { TeamRowType } from './types'
import { Share } from '@material-ui/icons'
import { ShareModal } from './ShareModal'
import { APARTMENT, axiosInstance, isAccountManager } from '../../helpers'
import { useAppDispatch, useUser } from '../../hooks'
import { toast } from 'react-toastify'
import { setWorkSpaceUser } from '../../store'

interface Props {
    cellStyle: React.CSSProperties
    setEditUser: (user: User) => void
    setOpenDrawer: (open: boolean) => void
    rows: TeamRowType
    propertyManager: User | null | undefined
    setPropertyManager: (user: User | null | undefined) => void
}

export const TeamRow = (props: Props) => {
    const {
        cellStyle,
        setEditUser,
        setOpenDrawer,
        rows,
        propertyManager,
        setPropertyManager,
    } = props

    const { workspaceUser } = useUser()

    const dispatch = useAppDispatch()

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const popOverOpen = Boolean(anchorEl)

    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closePopover = () => {
        setAnchorEl(null)
    }

    const [openShareModal, setOpenShareModal] = useState(false)
    const [body, setBody] = useState('')
    const [subject, setSubject] = useState('')

    const theme = useTheme()
    const classes = useStyles()

    return (
        <Grid
            container={true}
            key={rows.name}
            style={{
                cursor: 'pointer',
                margin: theme.spacing(1, 0, 1, 0),
                border: '1px solid',
                borderColor: theme.palette.grey[400],
                borderRadius: '20px 20px 20px 20px',
                flexDirection: 'row',
                backgroundColor: '#E5E5E5',
            }}
            onClick={() => {
                if (!popOverOpen) {
                    setOpenDrawer(true)
                    setEditUser(rows.user)
                }
            }}
            className={classes.hoverGrey300}
        >
            <Container style={{ flex: 1, justifyContent: 'center' }}>
                {(rows.picture && (
                    <AvatarCircle
                        image={rows.picture}
                        styles={{ container: { margin: theme.spacing(1) } }}
                    />
                )) || (
                    <AvatarCircle
                        text={rows.name}
                        initials={true}
                        styles={{ container: { margin: theme.spacing(1) } }}
                    />
                )}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                direction="column"
            >
                <Container>{rows.name}</Container>
                {rows.user.id === propertyManager?.id && (
                    <Container
                        style={{
                            fontSize: '13px',
                            color: theme.palette.darkGreen.main,
                        }}
                    >
                        Property Manager
                    </Container>
                )}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                    alignItems: 'center',
                }}
            >
                {rows.date}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                    alignItems: 'center',
                }}
            >
                {rows.permission}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                    alignItems: 'center',
                }}
            >
                {rows.email}
            </Container>
            <Container
                direction="column"
                alignItems="flex-start"
                style={{
                    ...cellStyle,
                    flex: 2,
                    justifyContent: 'center',
                }}
            >
                {rows.phone}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation()
                        openPopover(e)
                    }}
                    disableRipple={true}
                    size="small"
                >
                    <MoreVert />
                </IconButton>
                <Popover
                    open={popOverOpen}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                    }}
                >
                    <Container direction="column">
                        <IconButton
                            onClick={() => {
                                let tempBody = `${rows.name}%0D${rows.email}%0D`
                                tempBody += `Joining Date: ${rows.date}%0D${rows.phone}%0D`
                                tempBody += `Permissions: ${rows.permission}`
                                setSubject(
                                    `Here is the information about ${rows.name}`,
                                )
                                setBody(tempBody)
                                setOpenShareModal(true)
                            }}
                        >
                            <Share style={{ fontSize: '17px' }} />
                            <Container
                                style={{
                                    margin: theme.spacing(0, 3, 0, 1),
                                    fontSize: '14px',
                                }}
                            >
                                Share
                            </Container>
                        </IconButton>
                        {isAccountManager(workspaceUser) &&
                            workspaceUser?.active_workspace.company_type ===
                                APARTMENT && (
                                <IconButton
                                    onClick={() => {
                                        const body = {
                                            user: rows.user.id,
                                        }
                                        axiosInstance
                                            .post(
                                                `company/apartment/set_property_manager/`,
                                                body,
                                            )
                                            .then((res) => {
                                                toast.success(
                                                    `${rows.user.name} set  to property manager`,
                                                )
                                                setPropertyManager(
                                                    res.data.property_manager,
                                                )
                                                const updatedUser = workspaceUser
                                                if (updatedUser) {
                                                    updatedUser.active_workspace.property_manager =
                                                        res.data.property_manager
                                                    dispatch(
                                                        setWorkSpaceUser(
                                                            updatedUser,
                                                        ),
                                                    )
                                                }
                                            })
                                            .catch((e) => {
                                                toast.error(
                                                    e.response.data.message,
                                                )
                                            })
                                    }}
                                    disabled={!isAccountManager(workspaceUser)}
                                >
                                    <Container
                                        style={{
                                            margin: theme.spacing(0, 3, 0, 1),
                                            fontSize: '14px',
                                        }}
                                    >
                                        Set As Property Manager
                                    </Container>
                                </IconButton>
                            )}
                    </Container>
                    <ShareModal
                        open={openShareModal}
                        handleClose={() => setOpenShareModal(false)}
                        body={body}
                        subject={subject}
                    />
                </Popover>
            </Container>
        </Grid>
    )
}
