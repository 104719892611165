import { BaseFolder } from '../Infrastructure'
import { Service } from '../Services'
import { IdentifiableNamedObject, IdentifiableObject } from '../types'
import { BaseUser } from '../Users'
import { WorkorderStatus } from '../WorkOrder'

type BF1 = Omit<BaseFolder, 'pathIds'>
type BF2 = Omit<BF1, 'parent'>

interface FolderAnalytic extends BF2 {
    parent: number
    path_ids: string
    count: number
}

interface StatusAnalytic {
    count: number
    folders: FolderAnalytic[]
    units: {
        count: number
        id: number
        path: string
        folder_name: string
        name: string
    }[]
}

export interface ApartmentServiceAnalytic extends Service {
    count: number
    provider_track: {
        behind_start: number
        behind_end: number
        start_today: number
        end_today: number
    }
    [WorkorderStatus.ASSIGNED]: StatusAnalytic
    [WorkorderStatus.IN_PROGRESS]: StatusAnalytic
    [WorkorderStatus.PAUSE]: StatusAnalytic
    [WorkorderStatus.GO_BACK]: StatusAnalytic
    [WorkorderStatus.COMPLETE]: StatusAnalytic
    [WorkorderStatus.PRE_APPROVED]: StatusAnalytic
    [WorkorderStatus.APPROVED]: StatusAnalytic
    [WorkorderStatus.INVOICED]: StatusAnalytic
}

export interface WorkorderAnalyticDict {
    workorderCount: number
    ghostCount: number
    notStarted: number
    behindTrack: number
    unitCount: number
    serviceAreaCount: number
    ghostServiceAreaCount: number
    forecastedBudget: number
    serviceForecastedBudget: number
    lineItemForecastedBudget: number
    invoicedBudget: number
    incomeForecast: number
    serviceBudgets: {
        [serviceId: number]: {
            serviceName: string
            totalBudget: number
            invoicedBudget: number
        }
    }
    changeOrderAnalytics: {
        pendingCount: number
        pendingCost: number
        pendingIncome: number
        approvedCount: number
        approvedCost: number
        approvedIncome: number
    }
    [WorkorderStatus.ASSIGNED]: number
    [WorkorderStatus.IN_PROGRESS]: number
    [WorkorderStatus.PAUSE]: number
    [WorkorderStatus.GO_BACK]: number
    [WorkorderStatus.COMPLETE]: number
    [WorkorderStatus.PRE_APPROVED]: number
    [WorkorderStatus.APPROVED]: number
    [WorkorderStatus.INVOICED]: number
}

export interface ServiceAnalytic extends WorkorderAnalyticDict {
    serviceName: string
    serviceId: number
    color: string
}

export interface VendorServiceAnalytic extends IdentifiableNamedObject {
    // a service id and name, as well as various stats about that service for this vendor+apt combo
    assign_count: number
    approved_count: number
    app_1st_attempt: number
    complete_count: number
    go_back_count: number
    go_back_2nd_count: number
    go_back_max: number
}

export interface ReportFile extends IdentifiableObject {
    report_page: number
    file: string
    file_type: string
}

export interface ReportPage extends IdentifiableObject {
    report: number
    unit: number
    report_files: ReportFile[]
}

export interface Report extends IdentifiableNamedObject {
    report?: string
    company: number
    report_type: string
    generated_by: BaseUser
    creation_time: string
    schedule?: number
    inspection?: number
    report_pages: ReportPage[]
    xlsx_report?: string
}

export enum ReportTypes {
    WORKORDER_REPORT = 'WORKORDER_REPORT',
    INSPECTION_REPORT = 'INSPECTION',
    PORTFOLIO_SCHEDULE_REPORT = 'PORTFOLIO_SCHEDULE',
    LEASE_SYNC_REPORT = 'LEASE_SYNC_REPORT',
    BED_STATUS_SYNC_REPORT = 'BED_STATUS_SYNC_REPORT',
    DAMAGE_AND_TRANSACTION_REPORT = 'DAMAGE_AND_TRANSACTION_REPORT',
}
