import React, { useEffect, useState } from 'react'

import { Button, Divider, TextField, useTheme } from '@material-ui/core'

import { DamageConfig } from '../../models'

import { Container, SideDrawer, NumberInput } from '../../components'
import { useAppDispatch } from '../../hooks'
import { createDamageConfig, updateDamageConfig } from '../../store'
import { toast } from 'react-toastify'

interface Props {
    open: boolean
    damageConfig?: DamageConfig
    handleClose: () => void
}

export const DamageConfigForm = (props: Props) => {
    const theme = useTheme()
    const dispatch = useAppDispatch()

    const [description, setDescription] = useState('')
    const [cost, setCost] = useState('')

    const [integrationCode, setIntegrationCode] = useState<string>('')
    const [chargeItem, setChargeItem] = useState('')

    useEffect(() => {
        if (props.damageConfig) {
            setDescription(props.damageConfig.description)
            setCost(props.damageConfig.cost.toString())
            setIntegrationCode(props.damageConfig.integration_id ?? '')
            setChargeItem(props.damageConfig.charge_item_description ?? '')
        } else {
            setDescription('')
            setCost('')
            setIntegrationCode('')
            setChargeItem('')
        }
    }, [props.damageConfig, props.open])

    const inputStyle: React.CSSProperties = {
        margin: theme.spacing(1),
    }

    return (
        <SideDrawer
            open={props.open}
            handleClose={props.handleClose}
            width={330}
            title={
                props.damageConfig
                    ? `Edit Damage Config`
                    : 'Create Damage Config'
            }
        >
            {/* Main Content  */}
            <Container flex={1} scrollY direction="column">
                <TextField
                    variant="outlined"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    label="Description"
                    style={inputStyle}
                />

                <NumberInput
                    prefix={'$'}
                    variant="outlined"
                    label="Cost"
                    style={inputStyle}
                    value={cost}
                    onChange={(e) => setCost(e.target.value)}
                />

                <TextField
                    variant="outlined"
                    value={integrationCode}
                    onChange={(e) => setIntegrationCode(e.target.value)}
                    label="Integration Code"
                    style={inputStyle}
                />

                <TextField
                    variant="outlined"
                    value={chargeItem}
                    onChange={(e) => setChargeItem(e.target.value)}
                    label="Charge Item Description"
                    style={inputStyle}
                />

                <div style={{ flex: 1 }} />

                {/* Footer */}
                <Divider />
                <Container style={{ padding: theme.spacing(1) }}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{ marginRight: theme.spacing(2) }}
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="primary"
                        variant="outlined"
                        style={{ marginRight: theme.spacing(2) }}
                        onClick={() => {
                            if (props.damageConfig) {
                                // Update
                                dispatch(
                                    updateDamageConfig({
                                        id: props.damageConfig.id,
                                        body: {
                                            description: description,
                                            cost: Number(cost),
                                            integration_id:
                                                integrationCode !== ''
                                                    ? integrationCode
                                                    : null,
                                            charge_item_description:
                                                chargeItem !== ''
                                                    ? chargeItem
                                                    : null,
                                        },
                                    }),
                                ).then(() => {
                                    toast.success('Damage Config Updated')
                                    props.handleClose()
                                })
                            } else {
                                // Create
                                dispatch(
                                    createDamageConfig({
                                        body: {
                                            description: description,
                                            cost: Number(cost),
                                            integration_id:
                                                integrationCode !== ''
                                                    ? integrationCode
                                                    : null,
                                            charge_item_description:
                                                chargeItem !== ''
                                                    ? chargeItem
                                                    : null,
                                        },
                                    }),
                                ).then(() => {
                                    toast.success('Damage Config Created')
                                    props.handleClose()
                                })
                            }
                        }}
                    >
                        Save
                    </Button>
                </Container>
            </Container>
        </SideDrawer>
    )
}
