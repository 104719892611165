import React, { useEffect, useState } from 'react'
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme,
} from '@material-ui/core/styles'
import {
    Box,
    Breadcrumbs,
    Button,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core'
import PhotoCamera from '@material-ui/icons/PhotoCamera'

import { useUser } from '../../../../hooks'
import { Link } from 'react-router-dom'
// import { uploadFile } from '../../../helper/s3upload'
import { SubmitHandler, useForm } from 'react-hook-form'
import LoopIcon from '@material-ui/icons/Loop'
import { axiosInstance, utcOffsetString } from '../../../../helpers'
import { UpdateUserRequest, UpdateUserRequestFormData } from '../../../../store'

import { toast } from 'react-toastify'
import axios from 'axios'
import { AddressForm, AddressState, Container } from '../../../../components'
import { Address } from '../../../../models'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        closeBtn: {
            background: '#FFF',
            color: '#E9190F',
            border: '1px solid #E9190F',
            fontSize: '0.875rem',
            textTransform: 'inherit',
            fontWeight: 500,
            borderRadius: '5px',
            marginLeft: '10px',
        },
        saveBtn: {
            background: '#008C85',
            color: '#FFF',
            border: '1px solid #008C85',
            fontSize: '0.875rem',
            textTransform: 'inherit',
            fontWeight: 500,
            borderRadius: '5px',
        },
        getQuoteForm: {
            padding: '10px 0',
            '& .MuiFormControl-root': {
                margin: '5px 0',
            },
            '& .MuiFormLabel-root': {
                fontSize: '0.75rem',
                color: '#000',
                fontWeight: 700,
            },
            '& .MuiInputBase-input': {
                fontSize: '0.813rem',
            },
        },
        uploadInput: {
            display: 'none',
        },
        fileUploadBtn: {
            position: 'absolute',
            right: '-5%',
            bottom: '-10%',
            color: 'rgb(255, 255, 255)',
            background: 'rgb(44, 128, 255)',
            padding: '4px',
            border: '5px solid #fafafa',
            fontSize: '0.5rem',
            '& .MuiSvgIcon-root': {
                height: '1.2em',
                width: '1.2em',
                '& path': {
                    stroke: '#000',
                },
                '& circle': {
                    stroke: '#FFF',
                    strokeWidth: '10px',
                },
            },
        },
        rotateIcon: {
            animation: 'spin 01s linear infinite',
        },
    }),
)

export const GeneralSetting = () => {
    const classes = useStyles()

    const theme = useTheme()

    const { workspaceUser, updateUser } = useUser()

    const [addressState, setAddressState] = useState<AddressState>({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    })

    const [companyTzOffset, setCompanyTzOffset] = useState({
        utcOffset: 0,
        dstOffset: 0,
    })

    const [profilePic, setProfilePic] = useState<File | null>(null)

    const [profilePicString, setProfilePicString] = useState(
        workspaceUser?.profile_picture,
    )

    const uploadProfile = (file: File | null) => {
        if (file) {
            const formData = new FormData()
            formData.append('name', file.name)
            axiosInstance
                .post('user/presigned_url/', formData)
                .then(async (res: any) => {
                    if (res && res.data) {
                        const signedUrl = res.data.url
                        console.log('signed url is....', signedUrl)
                        axios.put(signedUrl)
                        const formDataUpload = new FormData()
                        formData.append('name', file)
                        const result = await fetch(signedUrl, {
                            method: 'PUT',
                            body: formDataUpload,
                            headers: {
                                'Content-Type': 'image/jpeg',
                                'x-amz-acl': 'public-read',
                                'x-amz-meta-key': '12345',
                                'x-amz-meta-otherInfo': 'other_data',
                            },
                        })
                        console.log('result is....', result)
                    }
                    console.log('response is...', res)
                })
                .catch((err: any) => {
                    console.log('error is....', err)
                })
        }
    }

    let filePreview =
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQ5srpTZoAXWINllDurGscx_Pqg_foehqAkQ&usqp=CAU'

    if (profilePic) {
        filePreview = URL.createObjectURL(profilePic)
    } else if (profilePicString) {
        filePreview = profilePicString
    }

    useEffect(() => {
        getFormDataValues()
        console.log('workspace user....', workspaceUser)

        if (workspaceUser?.active_workspace) {
            axiosInstance
                .get(`/company/${workspaceUser.active_workspace.id}/`)
                .then((res) => {
                    console.log('res is...', res)

                    if (res.data && res.data.primary_address) {
                        const pimaryAddress: Address | null =
                            res.data.primary_address
                        if (pimaryAddress) {
                            setAddressState({
                                addressLine1: pimaryAddress.address1,
                                addressLine2: pimaryAddress.address2,
                                city: pimaryAddress.city,
                                state: pimaryAddress.state,
                                zip: pimaryAddress.zip_code,
                                country: pimaryAddress.country,
                            })
                        }

                        setCompanyTzOffset({
                            utcOffset: res.data.primary_address.utc_offset,
                            dstOffset: res.data.primary_address.dst_offset,
                        })
                    }
                })
                .catch((e) => {
                    console.log('error is...', e)
                })
        }
        console.log(workspaceUser)
    }, [workspaceUser])

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        mode: 'all',
    })
    console.log('erros are...', errors)
    const getFormDataValues = () => {
        reset({
            name: workspaceUser?.name || '',
            profile_color: workspaceUser?.profile_color || '',
            phone: workspaceUser?.phone || '',
            cell_phone: workspaceUser?.cell_phone || '',
            profile_picture: workspaceUser?.profile_picture || '',
            email: workspaceUser?.email || '',
        })
    }

    const [addressLoading, setAddressLoading] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = (data: any, event: any) => {
        console.log('submit data....', data)
        event.preventDefault()
        setIsLoading(true)

        const body = new FormData()

        if (data.name) {
            body.append('name', data.name)
        }

        if (data.email) {
            body.append('email', data.email)
        }

        if (data.phone) {
            body.append('phone', data.phone)
        }

        if (data.cell_phone) {
            body.append('cell_phone', data.cell_phone)
        }

        if (data.profile_color) {
            body.append('profile_color', data.profile_color)
        }

        if (profilePic) {
            body.append('profile_picture', profilePic)
        }

        const req: UpdateUserRequestFormData = {
            isCurrentUser: true,
            params: {
                id: workspaceUser?.id || 0,
            },
            body: body,
        }

        updateUser(req)
            .then((res: any) => {
                console.log('response data is...', res)
                toast.success(`Profile updated!`)
                setIsLoading(false)
            })
            .catch((e) => {
                setIsLoading(false)
            })
    }

    const utcOffsetSeconds = companyTzOffset.utcOffset

    const offsetStr = utcOffsetString(
        utcOffsetSeconds,
        companyTzOffset.dstOffset,
    )

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    to="/settings"
                    style={{
                        color: '#8A8A8A',
                        fontSize: '15px',
                        textDecoration: 'none',
                    }}
                >
                    Settings
                </Link>
                <Typography
                    color="textPrimary"
                    style={{
                        color: '#8A8A8A',
                        fontSize: '15px',
                        textDecoration: 'none',
                    }}
                >
                    General Settings
                </Typography>
            </Breadcrumbs>
            <h2
                style={{
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '40px',
                    lineHeight: '37px',
                    marginBottom: '15px',
                    marginTop: '5px',
                    color: '#2F2E41',
                }}
            >
                General Settings
            </h2>
            <Grid container>
                <Grid item xs={12} md={12} sm={12} lg={12} container>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        <Typography
                            style={{ ...theme.typography.h6, fontWeight: 700 }}
                        >
                            Workspace Settings
                        </Typography>
                    </Grid>

                    <Container
                        style={{
                            paddingTop: theme.spacing(2),
                            paddingBottom: theme.spacing(2),
                            flex: 1,
                        }}
                    >
                        {/* Address Form */}
                        <Container
                            style={{
                                flexDirection: 'column',
                                flex: 1,
                                height: '100%',
                            }}
                        >
                            <AddressForm
                                addressState={addressState}
                                setAddressState={setAddressState}
                                inputStyle={{
                                    marginBottom: theme.spacing(2),
                                }}
                            />

                            <span
                                style={{
                                    ...theme.typography.body1,
                                    fontWeight: 700,
                                    color: theme.palette.text.primary,
                                }}
                            >
                                Timezone Offset
                            </span>
                            <span
                                style={{
                                    ...theme.typography.body2,
                                    fontWeight: 400,
                                    color: theme.palette.text.secondary,
                                }}
                            >
                                {offsetStr}
                            </span>
                        </Container>
                        {/* Display Map */}
                        <Container
                            style={{
                                flex: 1,
                                height: '100%',
                                padding: theme.spacing(2),
                                flexDirection: 'column',
                            }}
                        >
                            <iframe
                                width="100%"
                                height="100%"
                                loading="lazy"
                                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&q=${addressState.city}_${addressState.state}`}
                            />
                        </Container>
                    </Container>

                    <Grid container>
                        <Grid item xs={12} style={{ padding: '8px' }}>
                            <Button
                                variant="contained"
                                style={{
                                    background: '#008C85',
                                    color: '#FFF',
                                    fontSize: '0.875rem',
                                    textTransform: 'inherit',
                                    fontWeight: 500,
                                    borderRadius: '5px',
                                    boxShadow: 'none',
                                }}
                                disabled={addressLoading}
                                onClick={() => {
                                    setAddressLoading(true)

                                    axiosInstance
                                        .post(
                                            `/company/update-company-address/`,
                                            {
                                                address: addressState,
                                            },
                                        )
                                        .then((res) => {
                                            console.log('res is...', res)
                                            toast.success(`Address updated!`)

                                            if (
                                                res.data &&
                                                res.data.primary_address
                                            ) {
                                                const pimaryAddress: Address | null =
                                                    res.data.primary_address
                                                if (pimaryAddress) {
                                                    setAddressState({
                                                        addressLine1:
                                                            pimaryAddress.address1,
                                                        addressLine2:
                                                            pimaryAddress.address2,
                                                        city:
                                                            pimaryAddress.city,
                                                        state:
                                                            pimaryAddress.state,
                                                        zip:
                                                            pimaryAddress.zip_code,
                                                        country:
                                                            pimaryAddress.country,
                                                    })
                                                }

                                                setCompanyTzOffset({
                                                    utcOffset:
                                                        res.data.primary_address
                                                            .utc_offset,
                                                    dstOffset:
                                                        res.data.primary_address
                                                            .dst_offset,
                                                })
                                            }
                                        })
                                        .catch((e) => {
                                            console.log('error is...', e)
                                            toast.error(
                                                `Address update failed!`,
                                            )
                                        })
                                        .finally(() => {
                                            setAddressLoading(false)
                                        })
                                }}
                            >
                                {addressLoading ? (
                                    <LoopIcon className={classes.rotateIcon} />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={6}
                    sm={12}
                    lg={6}
                    style={{ paddingTop: theme.spacing(8) }}
                >
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        <Typography
                            style={{ ...theme.typography.h6, fontWeight: 700 }}
                        >
                            User Settings
                        </Typography>
                    </Grid>
                    <form
                        noValidate
                        autoComplete="off"
                        className={classes.getQuoteForm}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '8px',
                                }}
                            >
                                <div
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        display: 'inline-flex',
                                        position: 'relative',
                                    }}
                                >
                                    <img
                                        src={filePreview}
                                        alt=""
                                        width={'100%'}
                                    />
                                    <input
                                        accept="image/png, image/jpeg, image/jpg"
                                        className={classes.uploadInput}
                                        id="icon-button-file"
                                        type="file"
                                        onChange={(event) => {
                                            if (event.target.files) {
                                                setProfilePic(
                                                    event.target.files[0],
                                                )
                                            }
                                        }}
                                    />
                                    <label htmlFor="icon-button-file">
                                        <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="span"
                                            className={classes.fileUploadBtn}
                                            style={{
                                                color: '#FFF',
                                                background: '#2C80FF',
                                                padding: '4px',
                                            }}
                                        >
                                            <PhotoCamera />
                                        </IconButton>
                                    </label>
                                </div>
                                <div
                                    style={{
                                        width: '60%',
                                        display: 'flex',
                                        paddingLeft: '20px',
                                        alignItems: 'end',
                                    }}
                                >
                                    <span>
                                        <span
                                            style={{
                                                fontStyle: 'normal',
                                                fontWeight: 600,
                                                fontSize: '20px',
                                                lineHeight: '23px',
                                                display: 'block',
                                            }}
                                        >
                                            {workspaceUser?.name}
                                        </span>
                                        <span
                                            style={{
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '15px',
                                                lineHeight: '18px',
                                                color: '#8A8A8A',
                                                display: 'block',
                                            }}
                                        ></span>
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6} style={{ padding: '8px' }}>
                                <FormLabel component="legend">Name:</FormLabel>
                                <FormControl fullWidth>
                                    <TextField
                                        id="name"
                                        placeholder="Name"
                                        variant="outlined"
                                        size="small"
                                        {...register('name', {
                                            required: true,
                                        })}
                                    />
                                    <Box>
                                        {errors.name &&
                                            errors.name.type === 'required' && (
                                                <span className="error-message">
                                                    This is required field
                                                </span>
                                            )}
                                    </Box>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} style={{ padding: '8px' }}>
                                <FormLabel component="legend">Email:</FormLabel>
                                <FormControl fullWidth>
                                    <TextField
                                        id="email"
                                        placeholder="Email"
                                        variant="outlined"
                                        size="small"
                                        {...register('email', {
                                            required: true,
                                        })}
                                        disabled
                                    />
                                    <Box>
                                        {errors.email &&
                                            errors.email.type ===
                                                'required' && (
                                                <span className="error-message">
                                                    This is required field
                                                </span>
                                            )}
                                    </Box>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={6} style={{ padding: '8px' }}>
                                <FormLabel component="legend">
                                    Phone Number:
                                </FormLabel>
                                <FormControl fullWidth>
                                    <TextField
                                        id="phone"
                                        placeholder="Phone Number:"
                                        variant="outlined"
                                        size="small"
                                        {...register('phone', {
                                            required: true,
                                        })}
                                    />
                                    <Box>
                                        {errors.phone &&
                                            errors.phone.type ===
                                                'required' && (
                                                <span className="error-message">
                                                    This is required field
                                                </span>
                                            )}
                                    </Box>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} style={{ padding: '8px' }}>
                                <FormLabel component="legend">
                                    Cell Phone:
                                </FormLabel>
                                <FormControl fullWidth>
                                    <TextField
                                        id="phone"
                                        placeholder="Cell Phone:"
                                        variant="outlined"
                                        size="small"
                                        {...register('cell_phone', {
                                            required: true,
                                        })}
                                    />
                                    <Box>
                                        {errors.cell_phone &&
                                            errors.cell_phone.type ===
                                                'required' && (
                                                <span className="error-message">
                                                    This is required field
                                                </span>
                                            )}
                                    </Box>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} style={{ padding: '8px' }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        background: '#008C85',
                                        color: '#FFF',
                                        fontSize: '0.875rem',
                                        textTransform: 'inherit',
                                        fontWeight: 500,
                                        borderRadius: '5px',
                                        boxShadow: 'none',
                                    }}
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <LoopIcon
                                            className={classes.rotateIcon}
                                        />
                                    ) : (
                                        'Save'
                                    )}
                                </Button>
                                <Button className={classes.closeBtn}>
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </>
    )
}
