import { useEffect, useState } from 'react'
import { Range } from 'react-date-range'

export const useDateRange = (
    rangeKey: string,
    color?: string,
    useNoon?: boolean,
) => {
    const now = new Date()
    if (useNoon) {
        now.setHours(12, 0, 0, 0)
    }

    const [dateRange, setDateRange] = useState<Range>({
        startDate: now,
        endDate: now,
        key: rangeKey,
        color: color,
    })

    const setStartDate = (startDate: Date) => {
        let endDate = dateRange.endDate ?? startDate
        if (startDate > endDate) {
            endDate = startDate
        }

        setRange({ startDate: startDate, endDate: endDate })
    }

    const setEndDate = (endDate: Date) => {
        let startDate = dateRange.startDate ?? endDate
        if (endDate < startDate) {
            startDate = endDate
        }

        setRange({ startDate: startDate, endDate: endDate })
    }

    const setRange = (range: Range) => {
        if (useNoon) {
            range.startDate?.setHours(12, 0, 0, 0)
            range.endDate?.setHours(12, 0, 0, 0)
        }

        setDateRange({
            ...dateRange,
            startDate: range.startDate,
            endDate: range.endDate,
        })
    }

    return {
        dateRange,
        setStartDate,
        setEndDate,
        setRange,
    }
}
