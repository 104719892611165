import React, { useState } from 'react'
import {
    AreaConfig,
    IdentifiableNamedObject,
    ListInventoryAreaConfig,
} from '../../../models'
import { useStyles } from '../../../styles'
import { Button, useTheme } from '@material-ui/core'
import { Container, NumberInput } from '../../../components'
import { ChevronRight, Delete, ExpandMore } from '@material-ui/icons'

interface Props {
    configOptions: IdentifiableNamedObject[]
    amountStyle?: React.CSSProperties
    remove?: () => void
    iac: ListInventoryAreaConfig
    updateIAC: (iac: ListInventoryAreaConfig) => void
    onClick: () => void
}

export const AreaSelection = (props: Props) => {
    const {
        configOptions,
        remove,
        iac,
        updateIAC,
        amountStyle,
        onClick,
    } = props

    const [chooseArea, setChooseArea] = useState(false)

    const classes = useStyles()
    const theme = useTheme()

    const AreaSelector = (
        <Container
            direction="column"
            style={{
                border: `1px solid ${theme.palette.lightGrey.dark}`,
                borderRadius: '5px',
                margin: theme.spacing(1, 0, 1, 0),
            }}
        >
            <Container
                direction="column"
                style={{
                    height: '200px',
                    overflowY: 'auto',
                }}
            >
                {configOptions.map((config) => {
                    return (
                        <Container
                            key={`CHOOSE_AREA_CONFIG_${config.id}`}
                            style={{
                                margin: theme.spacing(0.5, 0, 0.5, 1),
                                fontWeight: 500,
                                fontSize: '16px',
                                cursor: 'pointer',
                            }}
                            className={classes.hoverGrey300}
                            onClick={() => {
                                const areaConfig = configOptions.find(
                                    (conf) => conf.id === config.id,
                                ) as AreaConfig
                                updateIAC({ ...iac, area_config: areaConfig })
                                setChooseArea(false)
                            }}
                        >
                            {config.name}
                        </Container>
                    )
                })}
            </Container>

            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.lightGrey.dark}`,
                }}
            >
                <Button
                    variant="contained"
                    style={{
                        margin: theme.spacing(1, 0, 1, 2),
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={onClick}
                >
                    + Add New Area
                </Button>
            </Container>
        </Container>
    )

    return (
        <Container direction="column">
            <Container style={{ alignItems: 'center' }}>
                <Container
                    onClick={() => setChooseArea(!chooseArea)}
                    style={{
                        border: `1px solid ${theme.palette.lightGrey.dark}`,
                        borderRadius: '5px',
                        flex: 1,
                        padding: theme.spacing(1),
                        cursor: 'pointer',
                    }}
                >
                    <Container
                        style={{
                            fontWeight: 500,
                            fontSize: '16px',
                        }}
                    >
                        {iac.area_config ? iac.area_config.name : `Choose Area`}
                    </Container>

                    <Container flex={1} />

                    <Container alignItems="flex-end" justifyContent="flex-end">
                        {chooseArea ? <ExpandMore /> : <ChevronRight />}
                    </Container>
                </Container>

                <NumberInput
                    value={iac.amount}
                    onChange={(event) => {
                        updateIAC({
                            ...iac,
                            amount: Number(event.target.value),
                        })
                    }}
                    label="Amount"
                    style={{
                        ...amountStyle,
                        maxWidth: 50,
                        margin: theme.spacing(0, 1, 0, 1),
                    }}
                />
                <Delete
                    color="secondary"
                    className={classes.highlightCircleOnHover}
                    onClick={remove}
                    style={{ marginLeft: theme.spacing(1) }}
                />
            </Container>

            {chooseArea && AreaSelector}
        </Container>
    )
}
