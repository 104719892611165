import React, { useState } from 'react'
import {
    Button,
    TextField,
    Box,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Paper,
} from '@mui/material'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import { AuditPromptConfig, DDLease } from '../../../../../models'
import { useTheme } from '@material-ui/core'
import { formatCurrency, formatDate } from '../Modals/TableModal/CellStyle'
import { format } from 'date-fns'
import { Container } from '../../../../../components'
import { UpdateLeaseAuditRequest } from '../../../Hooks'

interface Props {
    promptAnswers: AuditPromptConfig[]
    lease: DDLease
}
const formatValue = (format: string, value: any) => {
    if (format === 'text') {
        return value
    } else if (format === 'date') {
        return formatDate(value)
    } else if (format === 'currency') {
        return formatCurrency(value)
    } else if (format === 'bool') {
        if (value) {
            return 'Yes'
        } else {
            return 'No'
        }
    }
}

const answerValue = (lease: DDLease, prompt: AuditPromptConfig) => {
    const name = prompt.dd_lease_name as keyof DDLease
    const value = lease[name]
    console.log(value)
    if (value === null || value === undefined) {
        return formatValue('text', 'Not Found')
    } else {
        return formatValue(prompt.format, value)
    }
}

export const PromptSpinner: React.FC<Props> = (props: Props) => {
    const { promptAnswers, lease } = props
    const theme = useTheme()
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const [isEditing, setIsEditing] = useState<boolean>(false)

    const [values, setValues] = useState(
        promptAnswers.map((pa) => answerValue(lease, pa)),
    )
    const [confirmedIndices, setConfirmedIndices] = useState<Set<number>>(
        new Set(),
    )
    const [editedIndices, setEditedIndices] = useState<Set<number>>(new Set())

    const handlePrev = () => {
        if (currentIndex > 0) setCurrentIndex(currentIndex - 1)
    }

    const handleNext = () => {
        if (currentIndex < promptAnswers.length - 1)
            setCurrentIndex(currentIndex + 1)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value
        setValues(
            values.map((value, index) =>
                index === currentIndex ? newValue : value,
            ),
        )
        setIsEditing(true)
    }

    const handleHighlight = (index: number) => {
        if (confirmedIndices.has(index)) {
            return theme.palette.primary.light
        } else if (editedIndices.has(index)) {
            return theme.palette.secondary.main
        } else if (index === currentIndex) {
            return theme.palette.grey[300]
        }
        return 'transparent'
    }

    const handleSave = () => {
        if (confirmedIndices.has(currentIndex)) {
            confirmedIndices.delete(currentIndex)
            setConfirmedIndices(confirmedIndices)
        }
        setEditedIndices(new Set(editedIndices).add(currentIndex))
        setIsEditing(false)
        handleNext()
    }

    const handleConfirm = () => {
        setConfirmedIndices(new Set(confirmedIndices).add(currentIndex))
        handleNext()
    }

    return (
        <Container
            style={{
                flex: 1,
                display: 'flex',
            }}
        >
            {/* scroll bar of features */}
            <Box
                style={{
                    display: 'flex',
                    flex: 1,
                    height: '80vh',
                }}
            >
                <Box
                    style={{
                        width: '40%',
                        overflow: 'auto',
                        border: `1px solid ${theme.palette.grey[400]}`,
                    }}
                >
                    <List>
                        {promptAnswers.map((prompt, index) => (
                            <ListItem
                                key={index}
                                onClick={() => setCurrentIndex(index)}
                                style={{
                                    backgroundColor: handleHighlight(index),
                                }}
                            >
                                <ListItemText primary={prompt.display_name} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
                {/* action portion */}
                <Box
                    style={{
                        display: 'flex',
                        padding: theme.spacing(2),
                        flex: 1,
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        // backgroundColor: 'yellow',
                    }}
                >
                    {/* summary data */}
                    <Container
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxHeight: '150px',
                            flex: 1,
                            ...theme.typography.subtitle1,
                        }}
                    >
                        {`Complete: ${
                            confirmedIndices.size + editedIndices.size
                        }/${promptAnswers.length}`}
                        <br></br>
                        {`Confirmed: ${confirmedIndices.size}`}
                        <br></br>
                        {`Edited: ${editedIndices.size}`}
                    </Container>

                    {/* edit/confirm */}
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <Box
                            style={{
                                padding: theme.spacing(2),
                                flex: 1,
                            }}
                        >
                            <Typography
                                style={{
                                    ...theme.typography.h6,
                                }}
                            >
                                {promptAnswers[currentIndex].display_name}
                            </Typography>
                            <TextField
                                fullWidth
                                value={values[currentIndex]}
                                onChange={handleChange}
                                variant="outlined"
                                margin="normal"
                            />
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <IconButton
                                    onClick={handlePrev}
                                    disabled={currentIndex === 0}
                                >
                                    <ArrowBack />
                                </IconButton>
                                {/* conditionally show confirm or save */}
                                {isEditing ? (
                                    <Button
                                        style={{
                                            color: '#fff',
                                            backgroundColor:
                                                theme.palette.primary.dark,
                                            height: '33px',
                                            maxWidth: '100px',
                                            minWidth: '100px',
                                            borderRadius: '5px',
                                            borderWidth: 0,
                                            fontSize: '14px',
                                            lineHeight: '17px',
                                            fontWeight: 500,
                                            cursor: 'pointer',
                                            textTransform: 'none',
                                        }}
                                        onClick={handleSave}
                                    >
                                        Save
                                    </Button>
                                ) : (
                                    <Button
                                        style={{
                                            color: '#fff',
                                            backgroundColor:
                                                theme.palette.primary.dark,
                                            height: '33px',
                                            maxWidth: '100px',
                                            minWidth: '100px',
                                            borderRadius: '5px',
                                            borderWidth: 0,
                                            fontSize: '14px',
                                            lineHeight: '17px',
                                            fontWeight: 500,
                                            cursor: 'pointer',
                                            textTransform: 'none',
                                        }}
                                        onClick={handleConfirm}
                                    >
                                        Confirm
                                    </Button>
                                )}

                                <Button
                                    style={{
                                        color: '#fff',
                                        backgroundColor:
                                            theme.palette.primary.dark,
                                        height: '33px',
                                        maxWidth: '100px',
                                        minWidth: '100px',
                                        borderRadius: '5px',
                                        borderWidth: 0,
                                        fontSize: '14px',
                                        lineHeight: '17px',
                                        fontWeight: 500,
                                        cursor: 'pointer',
                                        textTransform: 'none',
                                    }}
                                    onClick={() => {
                                        setValues(
                                            promptAnswers.map((pa) =>
                                                answerValue(lease, pa),
                                            ),
                                        )
                                        setIsEditing(false)
                                    }}
                                >
                                    Reset
                                </Button>
                                <IconButton
                                    onClick={handleNext}
                                    disabled={
                                        currentIndex ===
                                        promptAnswers.length - 1
                                    }
                                >
                                    <ArrowForward />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}
