import { Button, Theme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from '../../Container'
import { AddressForm, AddressState } from '../../AddressForm'
import { Address, BaseCompany } from '../../../models'
import { axiosInstance, utcOffsetString } from '../../../helpers'
import { toast } from 'react-toastify'

interface Props {
    theme: Theme
    workspace: BaseCompany
    updateWorkspaceAddress: (address: Address) => void
}

export const WorkspaceAddress = (props: Props) => {
    const { theme } = props

    const [addressState, setAddressState] = useState<AddressState>({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    })

    useEffect(() => {
        if (props.workspace.primary_address) {
            setAddressState({
                addressLine1: props.workspace.primary_address.address1,
                addressLine2: props.workspace.primary_address.address2,
                city: props.workspace.primary_address.city,
                state: props.workspace.primary_address.state,
                zip: props.workspace.primary_address.zip_code,
                country: props.workspace.primary_address.country,
            })
        } else {
            setAddressState({
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                zip: '',
                country: '',
            })
        }
    }, [props.workspace])

    const utcOffsetSeconds = props.workspace.primary_address?.utc_offset ?? 0

    const offsetStr = utcOffsetString(
        utcOffsetSeconds,
        props.workspace.primary_address?.dst_offset ?? 0,
    )

    return (
        <Container style={{ flexDirection: 'column' }}>
            <AddressForm
                addressState={addressState}
                setAddressState={setAddressState}
                inputStyle={{ margin: theme.spacing(2) }}
            />

            <span
                style={{
                    ...theme.typography.body1,
                    fontWeight: 700,
                    color: theme.palette.text.primary,
                }}
            >
                Timezone Offset
            </span>
            <span
                style={{
                    ...theme.typography.body2,
                    fontWeight: 400,
                    color: theme.palette.text.secondary,
                }}
            >
                {offsetStr}
            </span>

            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    axiosInstance
                        .post(`/company/update-company-address/`, {
                            address: addressState,
                        })
                        .then((res) => {
                            console.log('res is...', res)
                            toast.success(`Address updated!`)

                            if (res.data && res.data.primary_address) {
                                const pimaryAddress: Address | null =
                                    res.data.primary_address
                                if (pimaryAddress) {
                                    setAddressState({
                                        addressLine1: pimaryAddress.address1,
                                        addressLine2: pimaryAddress.address2,
                                        city: pimaryAddress.city,
                                        state: pimaryAddress.state,
                                        zip: pimaryAddress.zip_code,
                                        country: pimaryAddress.country,
                                    })
                                    props.updateWorkspaceAddress(pimaryAddress)
                                }
                            }
                        })
                        .catch((e) => {
                            console.log('error is...', e)
                            toast.error(`Address update failed!`)
                        })
                }}
            >
                Save
            </Button>
        </Container>
    )
}
