import { AxiosResponse } from 'axios'
import { Action } from 'redux'
import { ActionItem, ActionItemStatus, UserOrCompanyString } from '../../models'

//requests
export interface ActionItemListRequest {
    params?: {}
}

export interface CreateActionItemRequest {
    body: {
        title: string
        folder?: number
        unit?: number
        area?: number
        inventory?: number
        assigned_to: number
        who_type: UserOrCompanyString
        priority?: boolean
        service?: number
        start_date?: string
    }
}

export interface UpdateActionItemRequest {
    params: {
        id: number
    }
    body: {
        title?: string
        folder?: number
        unit?: number
        area?: number
        inventory?: number
        service?: number
        status?: ActionItemStatus
        priority?: boolean
        start_date?: string
        assign_to_user?: number
        assign_to_company?: number
    }
}

//actions

export const SET_ACTION_ITEM_LOADING = 'SET_ACTION_ITEM_LOADING'
export const SET_ACTION_ITEM_LIST = 'SET_ACTION_ITEM_LIST'
export const ADD_ACTION_ITEM = 'ADD_ACTION_ITEM'
export const UPDATE_ACTION_ITEM = 'UPDATE_ACTION_ITEM'

export const GET_ACTION_ITEM_LIST_REQUEST = 'GET_ACTION_ITEM_LIST_REQUEST'
export const CREATE_ACTION_ITEM_REQUEST = 'CREATE_ACTION_ITEM_REQUEST'
export const UPDATE_ACTION_ITEM_REQUEST = 'UPDATE_ACTION_ITEM_REQUEST'

export type ActionItemRequest =
    | typeof GET_ACTION_ITEM_LIST_REQUEST
    | typeof CREATE_ACTION_ITEM_REQUEST
    | typeof UPDATE_ACTION_ITEM_REQUEST

interface SetActionItemLoadingAction extends Action {
    type: typeof SET_ACTION_ITEM_LOADING
    request: ActionItemRequest
    loading: boolean
}

interface SetActionItemListAction extends Action {
    type: typeof SET_ACTION_ITEM_LIST
    actionItemList?: ActionItem[]
}

interface AddActionItemAction extends Action {
    type: typeof ADD_ACTION_ITEM
    actionItem: ActionItem
}

interface UpdateActionItemAction extends Action {
    type: typeof UPDATE_ACTION_ITEM
    actionItem?: ActionItem
}

export type ActionItemActionTypes =
    | SetActionItemLoadingAction
    | SetActionItemListAction
    | AddActionItemAction
    | UpdateActionItemAction

//thunks

export type ActionItemListThunk = Promise<AxiosResponse<ActionItem[]>>
export type ActionItemThunk = Promise<AxiosResponse<ActionItem>>

//reducer
export interface ActionItemState {
    actionItemList?: ActionItem[]
    isLoading: {
        [GET_ACTION_ITEM_LIST_REQUEST]: boolean
        [CREATE_ACTION_ITEM_REQUEST]: boolean
        [UPDATE_ACTION_ITEM_REQUEST]: boolean
    }
}
