import { useSelector } from 'react-redux'
import { ListChannel, CurrentChannel, Message } from '../models'
import { RootState } from '../store'
import {
    getChannelDetail,
    ChannelDetailActionThunk,
    GetChannelDetailRequest,
    getChannelList,
    GetChannelListActionThunk,
    GetChannelListRequest,
    CreateChannelRequest,
    createChannel,
    AddMemberRequest,
    addMember,
    removeMember,
    clearChannelUnread,
    RemoveMemberRequest,
} from '../store/messaging'
import { useAppDispatch } from './useAppDispatch'
import { axiosInstance } from '../helpers'
import { useEffect, useState } from 'react'

type UseChannel = {
    channelList: ListChannel[]
    currentChannel?: CurrentChannel
    secondaryChannel?: CurrentChannel
    getChannelList: (req: GetChannelListRequest) => GetChannelListActionThunk
    getChannelDetail: (req: GetChannelDetailRequest) => ChannelDetailActionThunk
    createChannel: (req: CreateChannelRequest) => ChannelDetailActionThunk
    addMember: (req: AddMemberRequest) => ChannelDetailActionThunk
    removeMember: (req: RemoveMemberRequest) => ChannelDetailActionThunk
    clearChannelUnread: (channelId: number) => void
}

export const useChannelList = (): UseChannel => {
    const dispatch = useAppDispatch()
    const channelList = useSelector(
        (state: RootState) => state.messaging.channelList,
    )
    const currentChannel = useSelector(
        (state: RootState) => state.messaging.currentChannel,
    )

    return {
        channelList: channelList,
        currentChannel: currentChannel,
        getChannelList: (req) => dispatch(getChannelList(req)),
        getChannelDetail: (req) => dispatch(getChannelDetail(req)),
        createChannel: (req) => dispatch(createChannel(req)),
        addMember: (req) => dispatch(addMember(req)),
        removeMember: (req) => dispatch(removeMember(req)),
        clearChannelUnread: (channelId) =>
            dispatch(clearChannelUnread(channelId)),
    }
}

export const useChannel = (
    channelId?: number,
    secondaryChannelId?: number | null,
) => {
    const [messageList, setMessageList] = useState<Message[] | null>(null)
    const [channelDetail, setChannelDetail] = useState<CurrentChannel | null>(
        null,
    )

    const [secondaryMessageList, setSecondaryMessageList] = useState<
        Message[] | null
    >(null)

    const [loadingState, setLoadingState] = useState({
        messageList: false,
        createMessage: false,
        getChannelDetail: false,
    })

    useEffect(() => {
        if (channelId !== -1 && channelId !== undefined) {
            getChannelDetail(channelId)
            getMessages(channelId)
        }

        if (secondaryChannelId) {
            getSecondaryMessages(secondaryChannelId)
        }

        return () => {
            setMessageList(null)
            setChannelDetail(null)
            setLoadingState({
                messageList: false,
                createMessage: false,
                getChannelDetail: false,
            })
            setSecondaryMessageList(null)
        }
    }, [channelId])

    const getChannelDetail = async (cId: number) => {
        setLoadingState((prev) => {
            return {
                ...prev,
                getChannelDetail: true,
            }
        })

        try {
            const res = await axiosInstance.get(`messaging/channel/${cId}/`)

            setLoadingState((prev) => {
                return {
                    ...prev,
                    getChannelDetail: false,
                }
            })

            setChannelDetail(res.data)

            return res.data
        } catch (e) {
            setLoadingState((prev) => {
                return {
                    ...prev,
                    getChannelDetail: false,
                }
            })

            setChannelDetail(null)
            return Promise.reject(e)
        }
    }

    const getMessages = async (cId: number) => {
        setLoadingState((prev) => {
            return {
                ...prev,
                messageList: true,
            }
        })

        try {
            const res = await axiosInstance.get(
                `messaging/channel/${cId}/messages/`,
            )

            setLoadingState((prev) => {
                return {
                    ...prev,
                    messageList: false,
                }
            })

            setMessageList(res.data)

            return res.data
        } catch (e) {
            setLoadingState((prev) => {
                return {
                    ...prev,
                    messageList: false,
                }
            })
            return Promise.reject(e)
        }
    }

    const getSecondaryMessages = async (cId: number) => {
        setLoadingState((prev) => {
            return {
                ...prev,
                messageList: true,
            }
        })

        try {
            const res = await axiosInstance.get(
                `messaging/channel/${cId}/messages/`,
            )

            setLoadingState((prev) => {
                return {
                    ...prev,
                    messageList: false,
                }
            })

            setSecondaryMessageList(res.data)

            return res.data
        } catch (e) {
            setLoadingState((prev) => {
                return {
                    ...prev,
                    messageList: false,
                }
            })
            return Promise.reject(e)
        }
    }

    const createMessage = async (request: FormData) => {
        setLoadingState((prev) => {
            return {
                ...prev,
                createMessage: true,
            }
        })

        try {
            const res = await axiosInstance.post(
                'messaging/create-message/',
                request,
            )

            setLoadingState((prev) => {
                return {
                    ...prev,
                    messageList: false,
                }
            })

            const oldMessageList = messageList ? messageList : []

            setMessageList([...oldMessageList, res.data])

            return res
        } catch (e) {
            setLoadingState((prev) => {
                return {
                    ...prev,
                    messageList: false,
                }
            })
            return Promise.reject(e)
        }
    }

    return {
        channelDetail,
        messageList,
        loadingState,
        secondaryMessageList,
        createMessage,
    }
}
