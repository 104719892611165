import React, { useState } from 'react'
import {
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    TextField,
    Theme,
} from '@material-ui/core'
import EzosLogo from '../../../../../assets/ezos-logo-primary-dark.png'
import SkillcatLogo from '../../../../../assets/SkillCatLogo.png'
import LoopIcon from '@material-ui/icons/Loop'
import { useUser } from '../../../../../hooks'
import { Container, PasswordRequirements } from '../../../../../components'
import {
    axiosInstance,
    isEmailValid,
    passwordValidator,
} from '../../../../../helpers'
import { PasswordField } from '../../../../../components/PasswordField'
import { toast } from 'react-toastify'
import { setLoading } from '../../../../../store'

interface State {
    name: string
    address?: string
    city?: string
    state?: string
    zip_code?: string
    phone: string
    email: string
    password: string
    confirmPassword: string
    showPassword: boolean
    showConfirmPassword: boolean
    is_skillcat: boolean
}

interface Props {
    type: string
    theme: Theme
    onNextStep: () => void
}

export const StepQuestionnaireDetails = (props: Props) => {
    const { type, theme } = props

    const { login } = useUser()
    const [values, setValues] = React.useState<State>({
        name: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        phone: '',
        email: '',
        password: '',
        showPassword: false,
        showConfirmPassword: false,
        confirmPassword: '',
        is_skillcat: type === 'Vendor' ? false : true,
    })

    const [duplicateEmail, setDuplicateEmail] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const trimmedEmail = values.email.trim().toLocaleLowerCase()

    const passwordValidResult = passwordValidator(
        values.password,
        values.confirmPassword,
    )

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword })
    }

    const handleClickShowConfirmPassword = () => {
        setValues({
            ...values,
            showConfirmPassword: !values.showConfirmPassword,
        })
    }

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault()
    }

    const handleMouseDownConfirmPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault()
    }

    const validVendorEmail = async () => {
        if (!isEmailValid(trimmedEmail)) {
            return { valid: false, error: 'Email format not valid.' }
        }

        try {
            await axiosInstance.post('company/vendor/find_vendor_by_email/', {
                email: values.email,
            })
            return { valid: false, error: 'Email already in use.' }
        } catch (e) {
            return { valid: true, error: null }
        }
    }

    const vendorSignup = async () => {
        const vendor_body = {
            name: values.name,
            phone: values.phone,
            email: values.email,
            password: values.password,
            address: '',
            city: '',
            state: '',
            zip_code: '',
            is_skillcat: values.is_skillcat,
        }

        try {
            const res = await axiosInstance.post(
                'company/vendor_signup/',
                vendor_body,
            )
            console.log(res)
            return true
        } catch (e) {
            toast.error(`${e} Unable to register.`)
            return false
        }
    }

    const onSubmit = async () => {
        setIsLoading(true)

        const isEmailValid = await validVendorEmail()

        if (!isEmailValid.valid) {
            setIsLoading(false)
            toast.error(isEmailValid.error)
            return
        }

        if (!passwordValidResult.passwordValid) {
            setIsLoading(false)
            toast.error('Password does not meet the requirements.')
            return
        }

        const vendorSignupSuccess = await vendorSignup()

        if (!vendorSignupSuccess) {
            setIsLoading(false)
            toast.error('Unable to signup user.')
            return
        }

        login({ email: values.email, password: values.password })
            .then(() => {
                location.reload()
            })
            .catch(() => {
                toast.error('Unable to login.')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    width: '70%',
                }}
            >
                {/* Header */}
                <Container
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: theme.spacing(3),
                    }}
                >
                    <img
                        src={EzosLogo}
                        style={{
                            height: 50,
                        }}
                    />
                    {type === 'Skillcat' && (
                        <img src={SkillcatLogo} style={{ height: 50 }} />
                    )}
                    <span
                        style={{
                            ...theme.typography.h4,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.grey[700],
                            margin: theme.spacing(2, 0, 2, 0),
                            textAlign: 'center',
                        }}
                    >
                        {`Sign up to become an EZOS ${type}!`}
                    </span>
                </Container>
                <Divider />
                <Container
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                    <Container
                        style={{
                            flexDirection: 'column',
                            marginTop: theme.spacing(2),
                        }}
                    >
                        <Container
                            style={{
                                flex: 1,
                                flexDirection: 'column',
                                justifyContent: 'center',
                                width: 300,
                                margin: theme.spacing(2, 0, 2, 0),
                            }}
                        >
                            <form autoComplete="off">
                                <FormControl fullWidth>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        value={values.name}
                                        variant="outlined"
                                        type="text"
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                name: e.target.value,
                                            })
                                        }}
                                        required={true}
                                        style={{
                                            marginBottom: theme.spacing(2),
                                        }}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        id="phone"
                                        label="Phone"
                                        value={values.phone}
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                phone: e.target.value,
                                            })
                                        }}
                                        variant="outlined"
                                        type="text"
                                        required={true}
                                        style={{
                                            marginBottom: theme.spacing(2),
                                        }}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <TextField
                                        id="email"
                                        label="Email"
                                        value={values.email}
                                        variant="outlined"
                                        type="email"
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                email: e.target.value,
                                            })
                                        }}
                                        required={true}
                                        style={{
                                            marginBottom: theme.spacing(2),
                                        }}
                                    />
                                </FormControl>

                                <FormControl fullWidth>
                                    <PasswordField
                                        value={values.password}
                                        label="Password"
                                        variant="outlined"
                                        showPassword={values.showPassword}
                                        handleClickShowPassword={
                                            handleClickShowPassword
                                        }
                                        handleMouseDownPassword={
                                            handleMouseDownPassword
                                        }
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                password: e.target.value,
                                            })
                                        }}
                                        required={true}
                                        style={{
                                            marginBottom: theme.spacing(2),
                                        }}
                                    />
                                </FormControl>

                                <FormControl fullWidth>
                                    <PasswordField
                                        value={values.confirmPassword}
                                        label="Confirm Password"
                                        variant="outlined"
                                        showPassword={
                                            values.showConfirmPassword
                                        }
                                        handleClickShowPassword={
                                            handleClickShowConfirmPassword
                                        }
                                        handleMouseDownPassword={
                                            handleMouseDownConfirmPassword
                                        }
                                        onChange={(e) => {
                                            setValues({
                                                ...values,
                                                confirmPassword: e.target.value,
                                            })
                                        }}
                                        required={true}
                                        error={
                                            passwordValidResult.passwordValid
                                        }
                                        helperText={'Passwords do not match'}
                                        style={{
                                            marginBottom: theme.spacing(2),
                                        }}
                                    />
                                </FormControl>
                                <PasswordRequirements
                                    passwordRequirementResult={
                                        passwordValidResult
                                    }
                                    containerStyle={{
                                        justifyContent: 'center',
                                        margin: theme.spacing(1, 0),
                                    }}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Remember Password?"
                                />
                                <Button
                                    variant="contained"
                                    style={{
                                        background: theme.palette.primary.dark,
                                        color: 'white',
                                        textTransform: 'none',
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        borderRadius: theme.shape.borderRadius,
                                        boxShadow: 'none',
                                        width: '100%',
                                        height: '50px',
                                    }}
                                    onClick={onSubmit}
                                >
                                    {isLoading ? (
                                        <LoopIcon
                                            style={{
                                                animation:
                                                    'spin 01s linear infinite',
                                            }}
                                        />
                                    ) : (
                                        'Sign Up'
                                    )}
                                </Button>
                            </form>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </Container>
    )
}
