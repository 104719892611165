import { AxiosError } from 'axios'
import {
    AssignUnitInspectionRequest,
    ASSIGN_UNIT_INSPECTION_REQUEST,
    BulkHandleInventoryInspectionReqeust,
    BULK_HANDLE_INVENTORY_INSPECTION_REQ,
    CREATE_INVENTORY_INSPECTION_CHANNEL,
    InventoryInspectionThunk,
    UnitInspectionThunk,
    UpdateInventoryInspectionReqeust,
    UPDATE_INVENTORY_INSPECTION,
    UPDATE_INVENTORY_INSPECTION_REQUEST,
} from '.'
import { AppThunk, ErrorResponse, setCurrentChannel } from '..'
import { axiosInstance } from '../../helpers'
import {
    Inspection,
    InspectionDamage,
    InspectionDetail,
    InventoryInspection,
    UnitInspection,
} from '../../models'
import { setNetworkError } from '../error'
import { setPaginationNextPrev } from '../pagination/action'
import { UndefinedThunk } from '../types'

import {
    InspectionActionTypes,
    InspectionRequest,
    SET_INSPECTION_LOADING,
    InspectionDamageRequest,
    GET_INSPECTION_DAMAGE_LIST_REQUEST,
    SET_INSPECTION_DAMAGE_LIST,
    InspectionDamageListThunk,
    CreateInspectionDamageRequest,
    InspectionDamageThunk,
    CREATE_INSPECTION_DAMAGE_REQUEST,
    ADD_INSPECTION_DAMAGE,
    GetInspectionListAxiosReq,
    InspectionListThunk,
    GET_INSPECTION_LIST,
    SET_INSPECTION_LIST,
    CreateInspectionAxiosReq,
    InspectionThunk,
    CREATE_INSPECTION_REQUEST,
    ADD_INSPECTION,
    UpdateInspectionAxiosReq,
    UPDATE_INSPECTION,
    UPDATE_INSPECTION_REQUEST,
    GET_INSPECTION_DETAIL,
    SET_INSPECTION_DETAIL,
    GET_INVENTORY_INSPECTION_REQUEST,
    SET_INVENTORY_INSPECTION_LIST,
    InventoryInspectionListThunk,
    InventoryInspectionListAxiosReq,
    DeleteInspectionDamageRequest,
    DELETE_INSPECTION_DAMAGE_REQUEST,
    REMOVE_INSPECTION_DAMAGE,
    UnitInspecitonListAxiosReq,
    UnitInspectionListActionThunk,
    GET_UNIT_INSPECTION_LIST_REQUEST,
    ADD_UNIT_INSPECTIONS,
    SET_UNIT_INSPECTION_LIST,
    UPDATE_UNIT_INSPECTION,
    GET_INSPECTION_ANALYTICS_REQUEST,
    InspectionAnalyticsResponse,
    SET_INSPECTION_ANALYTICS,
    UpdateUnitInspectionRequest,
    UnitInspectionActionThunk,
} from './types'

export const getInspectionDamageList = (
    req: InspectionDamageRequest,
): AppThunk<InspectionDamageListThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInspectionLoading(GET_INSPECTION_DAMAGE_LIST_REQUEST, true))

        const url = `inspection/inspection-damage/`

        try {
            // success
            const response = await axiosInstance.get(url, req)

            dispatch(setInspectionDamageList(response.data))
            dispatch(
                setInspectionLoading(GET_INSPECTION_DAMAGE_LIST_REQUEST, false),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(
                setInspectionLoading(GET_INSPECTION_DAMAGE_LIST_REQUEST, false),
            )
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createInspectionDamage = (
    req: CreateInspectionDamageRequest,
): AppThunk<InspectionDamageThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInspectionLoading(CREATE_INSPECTION_DAMAGE_REQUEST, true))

        const url = `inspection/inspection-damage/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch(addInspectionDamage(response.data))
            dispatch(
                setInspectionLoading(CREATE_INSPECTION_DAMAGE_REQUEST, false),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(
                setInspectionLoading(CREATE_INSPECTION_DAMAGE_REQUEST, false),
            )
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const deleteInspectionDamage = (
    req: DeleteInspectionDamageRequest,
): AppThunk<UndefinedThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInspectionLoading(DELETE_INSPECTION_DAMAGE_REQUEST, true))

        const url = `inspection/inspection-damage/${req.params.id}/`

        try {
            // success
            const response = await axiosInstance.delete(url)

            dispatch(removeInspectionDamage(req.params.id))
            dispatch(
                setInspectionLoading(DELETE_INSPECTION_DAMAGE_REQUEST, false),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(
                setInspectionLoading(DELETE_INSPECTION_DAMAGE_REQUEST, false),
            )
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getInspectionAnalytics = (
    req: UnitInspecitonListAxiosReq,
): AppThunk<InspectionListThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInspectionLoading(GET_INSPECTION_ANALYTICS_REQUEST, true))

        const url = `inspection/${req.inspection_id}/get_analytics`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch(setInspectionAnalytics(response.data))
            dispatch(
                setInspectionLoading(GET_INSPECTION_ANALYTICS_REQUEST, false),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(
                setInspectionLoading(GET_INSPECTION_ANALYTICS_REQUEST, false),
            )
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}
export const getInspectionList = (
    req: GetInspectionListAxiosReq,
): AppThunk<InspectionListThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInspectionLoading(GET_INSPECTION_LIST, true))

        const url = `inspection/`

        try {
            // success
            const response = await axiosInstance.get(url, req)

            dispatch(setInspectionList(response.data))
            dispatch(setInspectionLoading(GET_INSPECTION_LIST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(setInspectionLoading(GET_INSPECTION_LIST, false))
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createInspection = (
    req: CreateInspectionAxiosReq,
): AppThunk<InspectionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInspectionLoading(CREATE_INSPECTION_REQUEST, true))

        const url = `inspection/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch(addInspection(response.data))
            dispatch(setInspectionLoading(CREATE_INSPECTION_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(setInspectionLoading(CREATE_INSPECTION_REQUEST, false))
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const patchInspection = (
    req: UpdateInspectionAxiosReq,
): AppThunk<InspectionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInspectionLoading(UPDATE_INSPECTION_REQUEST, true))

        const url = `inspection/${req.id}/`

        try {
            // success
            const response = await axiosInstance.patch(url, req.body)

            dispatch(updateInspection(req.id, response.data))
            dispatch(setInspectionLoading(UPDATE_INSPECTION_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(setInspectionLoading(UPDATE_INSPECTION_REQUEST, false))
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getInspectionDetail = (id: number): AppThunk<InspectionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInspectionLoading(GET_INSPECTION_DETAIL, true))

        const url = `inspection/${id}/`

        try {
            // success
            const response = await axiosInstance.get(url)

            dispatch(setInspectionDetail(response.data))
            dispatch(setInspectionLoading(GET_INSPECTION_DETAIL, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(setInspectionLoading(GET_INSPECTION_DETAIL, false))
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getInventoryInspectionList = (
    req: InventoryInspectionListAxiosReq,
): AppThunk<InventoryInspectionListThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInspectionLoading(GET_INVENTORY_INSPECTION_REQUEST, true))

        const url = `inspection/inventory-inspection/`

        try {
            // success
            const response = await axiosInstance.get(url, req)

            dispatch(setInventoryInspectionList(response.data))
            dispatch(
                setInspectionLoading(GET_INVENTORY_INSPECTION_REQUEST, false),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(
                setInspectionLoading(GET_INVENTORY_INSPECTION_REQUEST, false),
            )
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getUnitInspectionList = (
    req: UnitInspecitonListAxiosReq,
): AppThunk<UnitInspectionListActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInspectionLoading(GET_UNIT_INSPECTION_LIST_REQUEST, true))

        const url =
            req.url ?? `inspection/web-unit-inspection/${req.inspection_id}/`

        try {
            // success
            const response = await axiosInstance.get(url, req)

            if (req.url) {
                dispatch(addUnitInspections(response.data.results))
            } else {
                dispatch(setUnitInspectionList(response.data.results))
            }
            dispatch(
                setPaginationNextPrev(
                    response.data.next,
                    response.data.prev,
                    response.data.count,
                    GET_UNIT_INSPECTION_LIST_REQUEST,
                ),
            )
            dispatch(
                setInspectionLoading(GET_UNIT_INSPECTION_LIST_REQUEST, false),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(
                setInspectionLoading(GET_UNIT_INSPECTION_LIST_REQUEST, false),
            )
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const assignUnitInspections = (
    req: AssignUnitInspectionRequest,
): AppThunk<UnitInspectionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInspectionLoading(ASSIGN_UNIT_INSPECTION_REQUEST, true))

        const url = `inspection/unit-inspection/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)
            dispatch(
                setInspectionLoading(ASSIGN_UNIT_INSPECTION_REQUEST, false),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(
                setInspectionLoading(ASSIGN_UNIT_INSPECTION_REQUEST, false),
            )
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createInventoryInspectionChannel = (
    inv_ins_id: number,
): AppThunk<InventoryInspectionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(
            setInspectionLoading(CREATE_INVENTORY_INSPECTION_CHANNEL, true),
        )

        const url = `inspection/inventory-inspection/${inv_ins_id}/create_channel/`

        try {
            // success
            const response = await axiosInstance.post(url)
            const invIns: InventoryInspection = response.data
            dispatch(updateInventoryInspection(invIns))
            dispatch(
                setInspectionLoading(
                    CREATE_INVENTORY_INSPECTION_CHANNEL,
                    false,
                ),
            )
            dispatch(
                setCurrentChannel({
                    id: invIns.channel ?? -1,
                    members: [],
                    pair: false,
                    name: 'inv_ins_channel',
                    pair_user: '',
                }),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(
                setInspectionLoading(
                    CREATE_INVENTORY_INSPECTION_CHANNEL,
                    false,
                ),
            )
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const patchInventoryInspection = (
    req: UpdateInventoryInspectionReqeust,
): AppThunk<InventoryInspectionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(
            setInspectionLoading(UPDATE_INVENTORY_INSPECTION_REQUEST, true),
        )

        const url = `inspection/inventory-inspection/${req.inventoryInspectionId}/`

        try {
            // success
            const response = await axiosInstance.patch(url, req.body)
            response.data.config_index = req.body.config_index
            dispatch(updateInventoryInspection(response.data))
            dispatch(
                setInspectionLoading(
                    UPDATE_INVENTORY_INSPECTION_REQUEST,
                    false,
                ),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(
                setInspectionLoading(
                    UPDATE_INVENTORY_INSPECTION_REQUEST,
                    false,
                ),
            )
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const bulkHandleInventoryInspections = (
    req: BulkHandleInventoryInspectionReqeust,
): AppThunk<InventoryInspectionListThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(
            setInspectionLoading(BULK_HANDLE_INVENTORY_INSPECTION_REQ, true),
        )

        const url = `inspection/inventory-inspection/bulk_handle/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch(updateInventoryInspection(response.data))
            dispatch(
                setInspectionLoading(
                    BULK_HANDLE_INVENTORY_INSPECTION_REQ,
                    false,
                ),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(
                setInspectionLoading(
                    BULK_HANDLE_INVENTORY_INSPECTION_REQ,
                    false,
                ),
            )
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const updateUnitInspectionStatus = (
    req: UpdateUnitInspectionRequest,
): AppThunk<UnitInspectionActionThunk> => {
    return async (dispatch) => {
        const url = `inspection/unit-inspection/${req.unitInspection.id}/`

        try {
            // success
            const response = await axiosInstance.patch(url, req.body)

            dispatch(
                updateUnitInspection({
                    ...req.unitInspection,
                    complete_status: response.data.complete_status,
                }),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const setInspectionDamageList = (
    inspectionDamageList?: InspectionDamage[],
): InspectionActionTypes => {
    return {
        type: SET_INSPECTION_DAMAGE_LIST,
        inspectionDamageList: inspectionDamageList,
    }
}

export const setInspectionList = (
    inspectionList?: Inspection[],
): InspectionActionTypes => {
    return {
        type: SET_INSPECTION_LIST,
        inspectionList: inspectionList,
    }
}

export const addInspection = (
    inspection: Inspection,
): InspectionActionTypes => {
    return {
        type: ADD_INSPECTION,
        inspection: inspection,
    }
}

export const updateInspection = (
    id: number,
    inspection: Inspection,
): InspectionActionTypes => {
    return {
        type: UPDATE_INSPECTION,
        id: id,
        updatedInspection: inspection,
    }
}

export const setInspectionDetail = (
    inspection?: InspectionDetail,
): InspectionActionTypes => {
    return {
        type: SET_INSPECTION_DETAIL,
        inspection: inspection,
    }
}

export const setInspectionLoading = (
    request: InspectionRequest,
    isLoading: boolean,
): InspectionActionTypes => {
    return {
        type: SET_INSPECTION_LOADING,
        request: request,
        isLoading: isLoading,
    }
}

export const setInventoryInspectionList = (
    inventoryInspections?: InventoryInspection[],
): InspectionActionTypes => {
    return {
        type: SET_INVENTORY_INSPECTION_LIST,
        inventoryInspectionList: inventoryInspections,
    }
}

export const addInspectionDamage = (
    inspectionDamage: InspectionDamage,
): InspectionActionTypes => {
    return {
        type: ADD_INSPECTION_DAMAGE,
        inspectionDamage: inspectionDamage,
    }
}

export const removeInspectionDamage = (
    inspectionDamageID: number,
): InspectionActionTypes => {
    return {
        type: REMOVE_INSPECTION_DAMAGE,
        inspectionDamageId: inspectionDamageID,
    }
}

export const addUnitInspections = (
    unitInspections?: UnitInspection[],
): InspectionActionTypes => {
    return {
        type: ADD_UNIT_INSPECTIONS,
        unitInspectionList: unitInspections,
    }
}

export const setUnitInspectionList = (
    unitInspections?: UnitInspection[],
): InspectionActionTypes => {
    return {
        type: SET_UNIT_INSPECTION_LIST,
        unitInspectionList: unitInspections,
    }
}

export const updateInventoryInspection = (
    invIns: InventoryInspection | InventoryInspection[],
): InspectionActionTypes => {
    return {
        type: UPDATE_INVENTORY_INSPECTION,
        inv_ins: invIns,
    }
}

export const updateUnitInspection = (
    unitIns: UnitInspection,
): InspectionActionTypes => {
    return {
        type: UPDATE_UNIT_INSPECTION,
        unitIns: unitIns,
    }
}

export const setInspectionAnalytics = (
    analytics?: InspectionAnalyticsResponse,
): InspectionActionTypes => {
    return {
        type: SET_INSPECTION_ANALYTICS,
        analytics: analytics,
    }
}
