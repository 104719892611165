import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    FinderLocationSelection,
    FinderSelectionMode,
    LocationSelection,
    RootState,
    Location,
    setFinderSelection as _setFinderSelection,
    copyFinderSelection,
} from '../store'
import { useAppDispatch } from './useAppDispatch'
import { Folder, IdBoolMap, Unit } from '../models'

interface Options {
    whichSelection: FinderLocationSelection
    selectionMode: FinderSelectionMode
}

type SetFinderSelectionSig = (
    location?: Location,
    excluder?: (location: Location) => boolean,
) => void

type GetResultCallbackInjectedFinderSelection = (
    resultCallback: (result: LocationSelection) => void,
) => FinderSelection

export interface FinderSelection {
    selection: LocationSelection
    setFinderSelection: SetFinderSelectionSig
    copySelection: (selection: LocationSelection) => void
    getCustomSelection?: GetResultCallbackInjectedFinderSelection
    getUnits: () => Unit[]
}

export const _useFinderSelection = (options: Options): FinderSelection => {
    const dispatch = useAppDispatch()

    const selection = useSelector(
        (state: RootState) => state.finder[options.whichSelection],
    )

    const setFinderSelection: SetFinderSelectionSig = (location, excluder) => {
        dispatch(
            _setFinderSelection(
                options.whichSelection,
                options.selectionMode,
                location,
                excluder,
            ),
        )
    }

    const copySelection = (selection: LocationSelection) => {
        dispatch(copyFinderSelection(options.whichSelection, selection))
    }

    const getCustomSelection: GetResultCallbackInjectedFinderSelection = (
        resultCallback,
    ) => {
        const customSelection: FinderSelection = {
            selection,
            copySelection,
            getUnits,
            setFinderSelection: (location, excluder) => {
                dispatch(
                    _setFinderSelection(
                        options.whichSelection,
                        options.selectionMode,
                        location,
                        excluder,
                        resultCallback,
                    ),
                )
            },
        }

        return customSelection
    }

    const getUnits = () => {
        const unitList: Unit[] = []

        Object.keys(selection.unit).forEach((unitKeyStr) => {
            if (unitKeyStr === 'length') {
                return
            }

            const unitKey = Number(unitKeyStr)
            const unit = selection.unit[unitKey]
            if (unit !== undefined) {
                unitList.push(unit)
            }
        })
        return unitList
    }

    useEffect(() => {
        // On cleanup clear this hooks selection so that it does not effect
        // other parts of the application
        return () => {
            dispatch(
                _setFinderSelection(
                    options.whichSelection,
                    options.selectionMode,
                ),
            )
        }
    }, [])

    return {
        selection,
        setFinderSelection,
        copySelection,
        getCustomSelection,
        getUnits,
    }
}

export const useFinderSelection = () => {
    const [finderSelection, setFinderSelection] = useState<LocationSelection>({
        unit: { length: 0 },
        folder: { length: 0 },
    })

    const isLocationSelected = (
        selection: LocationSelection,
        location: Location,
    ) => {
        return selection[location.type][location.location.id] !== undefined
    }

    const addLocation: AddRemoveHandler = (selection, location) => {
        // This location did not previously exist.  it must be added.
        if (!isLocationSelected(selection, location)) {
            return {
                ...selection,
                [location.type]: {
                    ...selection[location.type],
                    [location.location.id]: location.location,
                    length: selection[location.type].length + 1,
                },
            }
        }

        return selection

        // The location did previously exist, move on
    }

    const removeLocation: AddRemoveHandler = (selection, location) => {
        // This location did previously exist.  it must be removed.

        if (isLocationSelected(selection, location)) {
            const newLen = Math.max(0, selection[location.type].length - 1)

            return {
                ...selection,
                [location.type]: {
                    ...selection[location.type],
                    [location.location.id]: undefined,
                    length: newLen,
                },
            }
        }

        // The location did not previously exist, move on
        return selection
    }

    const makeSelection = (
        selection: LocationSelection,
        location: Location,
        handler?: AddRemoveHandler,
    ) => {
        if (handler === undefined) {
            // this is the top level call.  We are recursively adding the sub tree
            // if this branch was not included previously.  We will remove the branch
            // if it was included previously.
            if (isLocationSelected(selection, location)) {
                handler = removeLocation
            } else {
                handler = addLocation
            }
        }

        // Base condition
        if (location.type === 'unit') {
            return handler(selection, {
                type: 'unit',
                location: location.location,
            })
        }

        const folder = location.location as Folder
        selection = handler(selection, location)

        // Recursively handle the folders children fodlers
        folder.children.forEach((child) => {
            const childFolderLocation: Location = {
                type: 'folder',
                location: child,
            }
            selection = handler!(selection, childFolderLocation)
            selection = makeSelection(selection, childFolderLocation, handler)
        })
        folder.units.forEach((unit) => {
            const childUnitLocation: Location = { type: 'unit', location: unit }
            selection = handler!(selection, childUnitLocation)
        })

        return selection
    }

    const selectLocation = (location: Location) => {
        setFinderSelection(makeSelection(finderSelection, location))
    }

    const setSelection = (units: Unit[]) => {
        const newSelection: LocationSelection = {
            unit: { length: units.length },
            folder: { length: 0 },
        }

        if (units.length > 0) {
            newSelection.folder[-1] = true as any
        }

        units.forEach((u) => {
            newSelection.unit[u.id] = u

            const folder: any = u.folder
            const folderId = folder.id
            newSelection.folder[folderId] = true as any

            const pathIds = folder.path_ids.split('/')
            pathIds.forEach((idStr: string) => {
                const pathId = Number(idStr)
                newSelection.folder[pathId] = true as any
            })
        })

        setFinderSelection(newSelection)
    }

    return {
        finderSelection,
        selectLocation,
        setSelection,
    }
}

type AddRemoveHandler = (
    selection: LocationSelection,
    location: Location,
) => LocationSelection
