import { axiosInstance } from '../helpers'
import { toast } from 'react-toastify'

export const useUnitConfigOccMapping = () => {
    const updateUnitConfigOccMapping = async (
        request: UpdateOccMappingRequest,
    ) => {
        try {
            const res = await axiosInstance.post(
                `infrastructure/set-non-occ-map/`,
                request,
            )

            return res
        } catch (e: any) {
            toast.error(e.response.data.message)
            return Promise.reject(e)
        }
    }

    return {
        updateUnitConfigOccMapping,
    }
}

export interface UpdateOccMappingRequest {
    unit_config: number
    mappings: {
        occ_area_config: number
        occ_area_label: string
        non_occ_area_config: number
        non_occ_area_label: string
    }[]
    to_email?: string
}
