import { useState, useEffect } from 'react'

export const useDragMouse = () => {
    const [mouseDown, setMouseDown] = useState(false)

    useEffect(() => {
        const mouseDownEvent = () => {
            setMouseDown(true)
        }

        const mouseUpEvent = () => {
            setMouseDown(false)
        }

        document.addEventListener('mousedown', mouseDownEvent)
        document.addEventListener('mouseup', mouseUpEvent)

        return () => {
            document.removeEventListener('mousedown', mouseDownEvent)
            document.removeEventListener('mouseup', mouseUpEvent)
        }
    }, [])

    return {
        mouseDown,
    }
}
