import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@material-ui/core'
import { ListChannel } from '../../../../models'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            tableLayout: 'fixed',
            // minWidth: 650,
            '& .MuiTableRow-root .MuiTableCell-root': {
                fontWeight: 400,
                fontSize: '0.625rem',
                /* line-height: 12px; */
                color: '#000',
            },
            '& .MuiTableRow-root': {
                cursor: 'pointer',
                '&:hover': {
                    background: '#E4E3E3',
                },
            },
            '& .MuiTableRow-root .MuiTableCell-root.elipse-text': {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '100%',
            },
            '& .MuiTableHead-root .MuiTableCell-root': {
                fontWeight: 400,
                fontSize: '0.75rem',
                /* line-height: 12px; */
                color: '#8A8A8A',
                backgroundColor: '#FFF',
            },
        },
    }),
)

interface Props {
    channelList: ListChannel[] | undefined
    openMsgDrawer: (channelId: number) => void
}

const MessageList = (props: Props) => {
    const classes = useStyles()

    return (
        <>
            <TableContainer
                className="ez-custom-scroll"
                style={{ maxHeight: '250px' }}
            >
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Channel</TableCell>
                            <TableCell>Message</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.channelList && props.channelList.length > 0
                            ? props.channelList.map(
                                  (row: ListChannel, index: number) =>
                                      !row.pair ? (
                                          <TableRow
                                              key={`msg-${index}`}
                                              onClick={() => {
                                                  props.openMsgDrawer(row.id)
                                              }}
                                          >
                                              <TableCell className="elipse-text">
                                                  {row.name}
                                              </TableCell>
                                              <TableCell className="elipse-text">
                                                  {row.unread_count > 0
                                                      ? `${row.unread_count} Messages`
                                                      : 'No new messages'}
                                              </TableCell>
                                          </TableRow>
                                      ) : (
                                          <></>
                                      ),
                              )
                            : null}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default MessageList
