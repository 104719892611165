import React from 'react'
import { Service, getServiceImageDetails } from '../models'
import { IconButton, Tooltip, useTheme } from '@material-ui/core'
import { Container } from './Container'

interface ServiceFilterProps {
    serviceList: Service[]
    selectedServiceIds: number[]
    onSelectService: (serviceId: number) => void
}

export const ServiceFilter = (props: ServiceFilterProps) => {
    const { serviceList, selectedServiceIds, onSelectService } = props

    const theme = useTheme()

    return (
        <Container style={{ maxWidth: '300px' }}>
            {serviceList.map((service) => {
                if (getServiceImageDetails(service.id).icon === null) {
                    return <Container key={`SERVICE_FILTER_${service.id}`} />
                }
                return (
                    <Tooltip
                        title={service.name}
                        key={`SERVICE_FILTER_${service.id}`}
                    >
                        <IconButton
                            style={{
                                marginRight: theme.spacing(1),
                                backgroundColor: selectedServiceIds.find(
                                    (servId) => servId === service.id,
                                )
                                    ? theme.palette.grey[300]
                                    : '',
                            }}
                            size="small"
                            onClick={() => {
                                onSelectService(service.id)
                            }}
                        >
                            <img
                                src={getServiceImageDetails(service.id).icon}
                                style={{
                                    maxHeight: 25,
                                    maxWidth: 27,
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                )
            })}
        </Container>
    )
}
