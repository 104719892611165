import { toMMDDYYYY, toUiTime } from '../../helpers'

export const getMessageTime = (date: Date) => {
    const now = new Date()
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()
    if (
        now.getDate() === day &&
        now.getMonth() === month &&
        now.getFullYear() === year
    ) {
        return toUiTime(date)
    } else {
        return toUiTime(date) + ' ' + toMMDDYYYY(date)
    }
}
