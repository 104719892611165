import React, { useState } from 'react'
import { DamageConfig, IdBoolMap, convertListToMap } from '../../models'

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    IconButton,
    Switch,
    TableCell,
    TableRow,
    useTheme,
} from '@material-ui/core'

import {
    CellData,
    Container,
    HeadCell,
    RowData,
    SimpleTable,
} from '../../components'

import AddIcon from '@material-ui/icons/Add'

import NumberFormat from 'react-number-format'
import { useStyles } from '../../styles'
import { DamageDrawerState, DAMAGE_CONFIG } from './DamageHome'
import { useAppDispatch } from '../../hooks'
import {
    ArchiveDamageConfigsRequest,
    archiveDamageConfigs,
    getDamageConfigList,
} from '../../store'
import { toast } from 'react-toastify'
import { Check } from '@material-ui/icons'

interface Props {
    damageConfigList?: DamageConfig[]
    drawerState: DamageDrawerState
    onSelectDamage: (damageConfig?: DamageConfig) => void
}

export const DamageConfigContainer = (props: Props) => {
    const { damageConfigList, onSelectDamage } = props

    const [showArchived, setShowArchived] = useState(false)
    const [openDialogState, setOpenDialogState] = useState({
        archive: true,
        open: false,
    })
    const [selectedMap, setSelectedMap] = useState<IdBoolMap>({})

    const theme = useTheme()
    const classes = useStyles()

    const dispatch = useAppDispatch()

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    interface DamageConfigRow extends RowData {
        description: CellData<DamageConfig>
        cost: CellData<DamageConfig>
        damageConfig: CellData<DamageConfig>
    }

    const headCells: HeadCell<DamageConfigRow>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'select',
            label: '',
            style: { width: 45 },
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'description',
            label: 'Description',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'cost',
            label: 'Cost',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'integration_id',
            label: 'Integration Code',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'charge_item_description',
            label: 'Charge Item Description',
            style: headCellStyle,
        },
    ]

    if (showArchived) {
        headCells.push({
            disablePadding: false,
            align: 'left',
            id: 'archived',
            label: 'Archived',
            style: headCellStyle,
        })
    }

    const rows: DamageConfigRow[] | undefined = damageConfigList?.map((dc) => {
        return {
            description: {
                value: dc,
                sortValue: dc.description.toLocaleLowerCase(),
            },
            cost: { value: dc, sortValue: dc.cost },
            damageConfig: { value: dc, sortValue: dc.description },
        }
    })

    const selectedCount = Object.keys(selectedMap).reduce(
        (checkedCount, current) => {
            return (checkedCount +=
                selectedMap[Number(current)] === true ? 1 : 0)
        },
        0,
    )

    const damageConfigMap = convertListToMap(damageConfigList ?? [])

    const selectedArchived = Object.keys(selectedMap).reduce(
        (checkedCount, current) => {
            return (checkedCount +=
                damageConfigMap[Number(current)]?.archived === true ? 1 : 0)
        },
        0,
    )

    let isChecked = selectedCount > 0
    damageConfigList?.forEach((dc) => {
        isChecked = isChecked || selectedMap[dc.id]
    })

    const handleGetDamageConfigs = (archive: boolean) => {
        setShowArchived(archive)
        if (archive) {
            dispatch(getDamageConfigList({ params: { archived: true } }))
        } else {
            dispatch(getDamageConfigList({}))
        }
    }

    return (
        <Container
            style={{
                margin: theme.spacing(2),
            }}
            direction="column"
            flex={1}
            scrollY
        >
            {/* Header */}
            <Container style={{ alignItems: 'center' }}>
                {selectedCount > 0 && (
                    <Checkbox
                        checked={isChecked === true}
                        onChange={() => {
                            setSelectedMap({})
                        }}
                        indeterminate={
                            selectedCount !== damageConfigList?.length &&
                            selectedCount !== 0
                        }
                    />
                )}

                <span
                    style={{
                        ...theme.typography.body1,
                        marginLeft: theme.spacing(1),
                        marginRight: theme.spacing(1),
                    }}
                >
                    {selectedCount > 0 ? `${selectedCount} Selected` : ''}
                </span>

                {selectedCount > 0 && (
                    <Button
                        onClick={() => {
                            setOpenDialogState({
                                archive: true,
                                open: true,
                            })
                        }}
                        variant="contained"
                        style={{
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        size="small"
                    >
                        Archive
                    </Button>
                )}

                {selectedArchived > 0 && showArchived && (
                    <Button
                        onClick={() => {
                            setOpenDialogState({
                                archive: false,
                                open: true,
                            })
                        }}
                        variant="contained"
                        style={{
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            marginLeft: theme.spacing(1),
                        }}
                        size="small"
                    >
                        Unarchive ({selectedArchived})
                    </Button>
                )}

                <FormControlLabel
                    control={
                        <Switch
                            checked={showArchived}
                            color="primary"
                            onChange={(e) =>
                                handleGetDamageConfigs(!showArchived)
                            }
                        />
                    }
                    labelPlacement="start"
                    label="Show Archived"
                />
                <div style={{ flex: 1 }} />
                <IconButton onClick={() => onSelectDamage()}>
                    <AddIcon color="primary" fontSize="large" />
                </IconButton>
            </Container>

            <Container style={{ flex: 1 }} scrollY>
                <SimpleTable<DamageConfigRow>
                    rows={rows ?? []}
                    render={(row, idx) => {
                        const isSelected =
                            props.drawerState.selected?.id ===
                                row.damageConfig.value.id &&
                            props.drawerState.open === DAMAGE_CONFIG

                        const damageConfig = row.damageConfig.value

                        return (
                            <TableRow
                                className={classes.hoverGrey200}
                                onClick={() => {
                                    onSelectDamage(row.damageConfig.value)
                                }}
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: isSelected
                                        ? theme.palette.primary.light
                                        : undefined,
                                }}
                            >
                                <TableCell style={{ width: 45 }}>
                                    <Checkbox
                                        style={{ width: 42 }}
                                        checked={
                                            selectedMap[damageConfig.id] ===
                                            true
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            e.preventDefault()

                                            const newSelectedMap = {
                                                ...selectedMap,
                                            }

                                            if (
                                                newSelectedMap[
                                                    damageConfig.id
                                                ] === true
                                            ) {
                                                newSelectedMap[
                                                    damageConfig.id
                                                ] = false
                                            } else {
                                                newSelectedMap[
                                                    damageConfig.id
                                                ] = true
                                            }

                                            setSelectedMap(newSelectedMap)
                                        }}
                                    />
                                </TableCell>

                                <TableCell>
                                    {row.damageConfig.value.description}
                                </TableCell>

                                <TableCell style={{ textAlign: 'left' }}>
                                    <NumberFormat
                                        displayType="text"
                                        value={row.damageConfig.value.cost}
                                        prefix="$"
                                        thousandSeparator
                                        fixedDecimalScale
                                        decimalScale={2}
                                    />
                                </TableCell>
                                <TableCell style={{ textAlign: 'left' }}>
                                    {row.damageConfig.value.integration_id}
                                </TableCell>
                                <TableCell>
                                    {
                                        row.damageConfig.value
                                            .charge_item_description
                                    }
                                </TableCell>
                                {showArchived && (
                                    <TableCell>
                                        {damageConfig.archived && <Check />}
                                    </TableCell>
                                )}
                            </TableRow>
                        )
                    }}
                    orderByDefault="description"
                    headCells={headCells}
                />
            </Container>

            <Dialog
                open={openDialogState.open}
                onClose={() =>
                    setOpenDialogState({ archive: true, open: false })
                }
            >
                <DialogTitle>
                    {openDialogState.archive
                        ? 'Archive Damage Types'
                        : 'Unarchive Damage Types'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to{' '}
                        {openDialogState.archive ? 'archive' : 'unarchive'} the
                        following Damage Types?
                    </DialogContentText>
                    {openDialogState.archive
                        ? damageConfigList?.map((dc) => {
                              if (selectedMap[dc.id] === true) {
                                  return (
                                      <DialogContentText
                                          key={`DAMAGE_CONFIG_DIALOG_${dc.id}`}
                                      >
                                          {' '}
                                          {dc.description}{' '}
                                      </DialogContentText>
                                  )
                              }
                          })
                        : damageConfigList
                              ?.filter((config) => config.archived)
                              .map((dc) => {
                                  if (selectedMap[dc.id] === true) {
                                      return (
                                          <DialogContentText
                                              key={`DAMAGE_CONFIG_DIALOG_${dc.id}`}
                                          >
                                              {' '}
                                              {dc.description}{' '}
                                          </DialogContentText>
                                      )
                                  }
                              })}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{
                            marginRight: theme.spacing(2),
                            textTransform: 'none',
                        }}
                        onClick={() =>
                            setOpenDialogState({ archive: true, open: false })
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            const damageConfigIDs: number[] = []
                            damageConfigList?.forEach((dc) => {
                                if (selectedMap[dc.id] === true) {
                                    damageConfigIDs.push(dc.id)
                                }
                            })

                            const body: ArchiveDamageConfigsRequest = {
                                damage_configs: damageConfigIDs,
                            }

                            if (!openDialogState.archive) {
                                body.unarchive = true
                            }

                            dispatch(archiveDamageConfigs(body)).then(() => {
                                {
                                    openDialogState.archive
                                        ? toast.success(`Damage Types Archived`)
                                        : toast.success(
                                              `Damage Types Unarchived`,
                                          )
                                }
                                setSelectedMap({})
                                setOpenDialogState({
                                    archive: true,
                                    open: false,
                                })
                            })
                        }}
                    >
                        {openDialogState.archive ? 'Archive' : 'Unarchive'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
