import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../hooks'

import { RootState, setWorkorderStatusFilter } from '../../store'
import { filterOptions, WorkorderStatusFilters } from './types'

interface StatusFilterProps {
    style?: {
        formControl?: React.CSSProperties
        select?: React.CSSProperties
        menuItem?: React.CSSProperties
    }
    subWo?: {
        subWoStatus: WorkorderStatusFilters
        subWoOnChange: (val: WorkorderStatusFilters) => void
    }
}

export const StatusFilter = (props: StatusFilterProps) => {
    const dispatch = useAppDispatch()
    const statusFilter = props.subWo
        ? props.subWo.subWoStatus
        : useSelector((state: RootState) => state.jobsite.workorderStatusFilter)

    return (
        <FormControl style={{ ...props.style?.formControl }}>
            <InputLabel id="STATUS_FILTER_LABEL">
                {props.subWo ? 'Sub Status' : 'Status'}
            </InputLabel>
            <Select
                labelId="STATUS_FILTER_LABEL"
                style={{ ...props.style?.select }}
                variant="standard"
                onChange={(
                    event: React.ChangeEvent<{
                        value: unknown
                    }>,
                ) => {
                    if (props.subWo) {
                        props.subWo.subWoOnChange(
                            event.target.value as WorkorderStatusFilters,
                        )
                    } else {
                        dispatch(
                            setWorkorderStatusFilter(
                                event.target.value as WorkorderStatusFilters,
                            ),
                        )
                    }
                }}
                value={statusFilter}
            >
                {filterOptions.map((option) => {
                    return (
                        <MenuItem
                            key={`DATE_FILTER_${option.be}`}
                            value={option.be}
                            style={{ ...props.style?.menuItem }}
                        >
                            {option.d}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}
