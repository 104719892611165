import React, { useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControlLabel,
    Switch,
    useTheme,
} from '@material-ui/core'
import { DateRange, Range } from 'react-date-range'
import { Container } from '../../../../components'

import {
    CalendarFilterMode,
    InfrastructureFilterOptions,
} from '../../../../store'

interface Props {
    filter: InfrastructureFilterOptions
    setInfrastructureFilter: (
        key: keyof InfrastructureFilterOptions,
        value: unknown,
    ) => void
}

export const ScheduleDateFilter = (props: Props) => {
    const { filter, setInfrastructureFilter } = props

    const [gapMode, setGapMode] = useState(false)

    const theme = useTheme()

    const calendarOpen = filter.dateFilter.enable

    return (
        <Accordion expanded={calendarOpen}>
            <AccordionSummary>
                <Container style={{ alignItems: 'center', flex: 1 }}>
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        Date Filter
                    </span>
                    <div style={{ flex: 1 }} />
                    <FormControlLabel
                        labelPlacement="start"
                        label="Enable"
                        style={{ marginRight: theme.spacing(2) }}
                        control={
                            <Switch
                                checked={calendarOpen}
                                color="primary"
                                onClick={(e) => {
                                    e.stopPropagation()

                                    setInfrastructureFilter('dateFilter', {
                                        ...filter.dateFilter,
                                        enable: !filter.dateFilter.enable,
                                    })
                                }}
                            />
                        }
                    />
                </Container>
            </AccordionSummary>

            <AccordionDetails>
                <Container
                    style={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <FormControlLabel
                        labelPlacement="start"
                        label="Gaps"
                        style={{ margin: 0 }}
                        control={
                            <Switch
                                checked={gapMode}
                                color="primary"
                                onClick={() => {
                                    setGapMode(!gapMode)

                                    if (!gapMode) {
                                        setInfrastructureFilter('dateFilter', {
                                            ...filter.dateFilter,
                                            filterMode: CalendarFilterMode.Gaps,
                                            filterLeases: true,
                                            filterServices: true,
                                        })
                                    } else {
                                        setInfrastructureFilter('dateFilter', {
                                            ...filter.dateFilter,
                                            filterMode:
                                                CalendarFilterMode.Overlap,
                                            filterLeases: false,
                                            filterServices: true,
                                        })
                                    }
                                }}
                            />
                        }
                    />

                    <DateRange
                        ranges={[filter.dateFilter.range]}
                        fixedHeight
                        onChange={(range) => {
                            const key = 'date-range' as keyof typeof range
                            const filterRange = range[key] as Range
                            setInfrastructureFilter('dateFilter', {
                                ...filter.dateFilter,
                                range: filterRange,
                                showDateDisplay: true,
                            })
                        }}
                    />
                </Container>
            </AccordionDetails>
        </Accordion>
    )
}
