import {
    Badge,
    Button,
    IconButton,
    TableCell,
    TableRow,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { Close, FileCopy, Message } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import {
    Blanket,
    BlanketRect,
    CellData,
    Container,
    HeadCell,
    MessageDrawer,
    RowData,
    SimpleTable,
} from '../../components'
import { axiosInstance, toMMDDYYYY } from '../../helpers'
import {
    InspectionDetail,
    ListVendor,
    ModelMap,
    Schedule,
    Service,
    User,
    convertListToMap,
    convertMapToList,
} from '../../models'
import {
    createInventoryInspectionChannel,
    CreateWorkOrderRequest,
    UnitInspectionParams,
    WorkorderResponse,
} from '../../store'
import FileDownload from 'js-file-download'
import { useAppDispatch } from '../../hooks'
import { InvInsAnalyticsBlanketData } from './InspectionDetail'
import EditIcon from '@material-ui/icons/Edit'
import { InventoryInspectionWorkorderModal } from './InventoryInspectionWorkorderModal'
import { toast } from 'react-toastify'
import { ReactComponent as ActionItemIcon } from '../../assets/icons/action-item.svg'

interface Props {
    open: boolean
    setOpen: (open: boolean) => void
    getFilteredParams: () => UnitInspectionParams
    inspection?: InspectionDetail
    rect: BlanketRect
    openMessages: (channelId: number) => void
    currentInvInsAnalytic?: InventoryInspectionAnalytic
    setCurrentInvInsAnalytic: (analytic?: InventoryInspectionAnalytic) => void
    makeInventoryInspectionAnalyticRequest: () => void
    inventoryInspectionAnalyticList: InventoryInspectionAnalytic[]
    setInventoryInspectionAnalyticList: (
        analyticList: InventoryInspectionAnalytic[],
    ) => void
    blanketData: InvInsAnalyticsBlanketData
    serviceList: Service[]
    vendorList: ListVendor[]
    userList: User[]
    scheduleList: Schedule[]
}

export interface InventoryInspectionAnalytic {
    id: number
    ins_name: string
    channel: null | number
    ins_start_date: string
    ins_end_date: string
    folder_name: string
    path: string
    unit_id: number
    unit_name: string
    area_id: number
    area_label: string
    area_name: string
    inv_name: string
    status: string
    inv_ins_id: number
    unread_count: number
    channel_message_count: number
    status_color: string
    wo_count: number
}

export const InvInsAnalyticsBlanket = (props: Props) => {
    const {
        open,
        getFilteredParams,
        inspection,
        rect,
        setOpen,
        openMessages,
        setCurrentInvInsAnalytic,
        currentInvInsAnalytic,
        inventoryInspectionAnalyticList,
        makeInventoryInspectionAnalyticRequest,
        setInventoryInspectionAnalyticList,
        blanketData,
        serviceList,
        vendorList,
        userList,
        scheduleList,
    } = props

    const {
        inventoryConfig,
        status,
        statusId,
        sumPrice,
        statusCount,
    } = blanketData

    const [selectionMode, setSelectionMode] = useState(false)
    const [selectedInvInspectionMap, setSelectedInvInspectionMap] = useState<
        ModelMap<InventoryInspectionAnalytic>
    >({})
    const [openWorkorderModal, setOpenWorkorderModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const theme = useTheme()

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (open) {
            makeInventoryInspectionAnalyticRequest()
        }
        setSelectionMode(false)
        setSelectedInvInspectionMap({})
    }, [open])

    useEffect(() => {
        if (currentInvInsAnalytic) {
            const newInventoryInspectionAnalyticList: InventoryInspectionAnalytic[] = inventoryInspectionAnalyticList.map(
                (analytic) => {
                    if (
                        analytic.inv_ins_id === currentInvInsAnalytic.inv_ins_id
                    ) {
                        return currentInvInsAnalytic
                    }
                    return analytic
                },
            )
            setInventoryInspectionAnalyticList(
                newInventoryInspectionAnalyticList,
            )
        }
    }, [currentInvInsAnalytic])

    interface InspectionRow extends RowData {
        name: CellData<string>
        startDate: CellData<string>
        endDate: CellData<string>
        location: CellData<string>
        area: CellData<string>
        status: CellData<string>
        channel: CellData<JSX.Element>
        invInsAnalytic: CellData<InventoryInspectionAnalytic>
    }

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    const cellStyle: React.CSSProperties = {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightRegular,
        width: '1px',
    }

    const headCells: HeadCell<InspectionRow>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'location',
            label: 'Location',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'area',
            label: 'Area',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'name',
            label: 'Name',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'status',
            label: 'Status',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'startDate',
            label: 'Start Date',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'endDate',
            label: 'End Date',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: '',
            label: '',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: '',
            label: '',
            style: headCellStyle,
        },
    ]

    const createInspectionRow = (
        inventoryInspectionAnalytic: InventoryInspectionAnalytic,
    ): InspectionRow => {
        const startDate = new Date(inventoryInspectionAnalytic.ins_start_date)
        const startDateStr = toMMDDYYYY(startDate)

        const endDate = new Date(inventoryInspectionAnalytic.ins_end_date)
        const endDateStr = toMMDDYYYY(endDate)

        const location =
            inventoryInspectionAnalytic.path +
            inventoryInspectionAnalytic.folder_name +
            '/' +
            inventoryInspectionAnalytic.unit_name

        const area =
            inventoryInspectionAnalytic.area_name +
            ' ' +
            inventoryInspectionAnalytic.area_label

        const messageBadgeCount =
            inventoryInspectionAnalytic.unread_count > 0
                ? inventoryInspectionAnalytic.unread_count
                : inventoryInspectionAnalytic.channel_message_count

        const channel = (
            <IconButton
                onClick={() => {
                    if (!inventoryInspectionAnalytic.channel) {
                        dispatch(
                            createInventoryInspectionChannel(
                                inventoryInspectionAnalytic.inv_ins_id,
                            ),
                        ).then((res) => {
                            openMessages(res.data.channel as number)
                            const newInventoryInspectionAnalyticList: InventoryInspectionAnalytic[] = inventoryInspectionAnalyticList.map(
                                (analytic) => {
                                    if (
                                        analytic.inv_ins_id ===
                                        inventoryInspectionAnalytic.inv_ins_id
                                    ) {
                                        return {
                                            ...inventoryInspectionAnalytic,
                                            channel: res.data.channel as number,
                                        }
                                    }
                                    return analytic
                                },
                            )
                            setInventoryInspectionAnalyticList(
                                newInventoryInspectionAnalyticList,
                            )
                        })
                    } else {
                        openMessages(inventoryInspectionAnalytic.channel)
                    }

                    setCurrentInvInsAnalytic({
                        ...inventoryInspectionAnalytic,
                        unread_count: 0,
                    })
                }}
            >
                <Badge
                    badgeContent={messageBadgeCount}
                    color={
                        inventoryInspectionAnalytic.unread_count > 0
                            ? 'secondary'
                            : 'primary'
                    }
                >
                    <Message fontSize="large" />
                </Badge>
            </IconButton>
        )

        return {
            location: {
                value: location,
                sortValue: location,
            },
            area: {
                value: area,
                sortValue: area,
            },
            name: {
                value: inventoryInspectionAnalytic.inv_name,
                sortValue: inventoryInspectionAnalytic.inv_name,
            },
            status: {
                value: inventoryInspectionAnalytic.status,
                sortValue: inventoryInspectionAnalytic.status,
            },
            startDate: {
                value: startDateStr,
                sortValue: startDate.getTime(),
            },
            endDate: {
                value: endDateStr,
                sortValue: endDate.getTime(),
            },
            channel: {
                value: channel,
                sortValue: messageBadgeCount,
            },
            invInsAnalytic: {
                value: inventoryInspectionAnalytic,
                sortValue: 1,
            },
        }
    }

    const onCreateWorkorders = (request: CreateWorkOrderRequest) => {
        setLoading(true)
        axiosInstance
            .post('workorder/', request.body)
            .then((res) => {
                toast.success('Workorders created successfully!')
                const tempInvInsListMap = convertListToMap(
                    inventoryInspectionAnalyticList,
                )
                const workorders: WorkorderResponse[] = res.data['workorders']
                workorders.forEach((wo) => {
                    const invInspection = tempInvInsListMap[wo.inv_ins_id ?? -1]
                    if (invInspection) {
                        tempInvInsListMap[wo.inv_ins_id ?? -1] = {
                            ...invInspection,
                            wo_count: invInspection.wo_count + 1,
                        }
                    }
                })
                setInventoryInspectionAnalyticList(
                    convertMapToList(tempInvInsListMap),
                )
                setSelectedInvInspectionMap({})
                setOpenWorkorderModal(false)
            })
            .catch((e) => {
                toast.error(e.response.data.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    let numberSelected = 0
    let inventoryInspectionList: InventoryInspectionAnalytic[] = []
    if (selectionMode) {
        numberSelected = Object.keys(selectedInvInspectionMap).length
        inventoryInspectionList = convertMapToList(selectedInvInspectionMap)
    }

    return (
        <Blanket open={open} rect={rect}>
            <Container
                style={{
                    backgroundColor: theme.palette.grey[100],
                    flex: 1,
                    flexDirection: 'column',
                }}
                scrollY
            >
                {open && (
                    <Container
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                        }}
                    >
                        {/* Header */}
                        <Container>
                            {/* Left */}
                            <Container
                                style={{
                                    flex: 1,
                                }}
                            >
                                <span
                                    style={{
                                        ...theme.typography.h4,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        margin: theme.spacing(2, 0, 0, 2),
                                    }}
                                >
                                    {`${inventoryConfig?.name} - ${status}`}
                                </span>
                            </Container>
                            {/* Middle */}
                            <Container
                                style={{
                                    flex: 1,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <span
                                    style={{
                                        ...theme.typography.body1,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        marginRight: theme.spacing(1),
                                    }}
                                >
                                    {status}:
                                </span>
                                <span
                                    style={{
                                        marginRight: theme.spacing(2),
                                        ...theme.typography.body1,
                                    }}
                                >
                                    {statusCount}
                                </span>
                                {sumPrice > 0 && (
                                    <Container>
                                        <span
                                            style={{
                                                ...theme.typography.body1,
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                            }}
                                        >
                                            Price:
                                        </span>
                                        <NumberFormat
                                            thousandSeparator
                                            prefix="$"
                                            value={sumPrice}
                                            displayType="text"
                                            style={{
                                                ...theme.typography.body1,
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightLight,
                                                marginLeft: theme.spacing(1),
                                            }}
                                        />
                                    </Container>
                                )}
                            </Container>
                            {/* Right */}
                            <Container
                                style={{
                                    flex: 1,
                                    justifyContent: 'flex-end',
                                    margin: theme.spacing(1, 1, 0, 0),
                                    alignItems: 'center',
                                }}
                            >
                                {selectionMode && (
                                    <Container style={{ alignItems: 'center' }}>
                                        <Container
                                            style={{
                                                ...theme.typography.h6,
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                                marginRight: theme.spacing(2),
                                            }}
                                        >
                                            {numberSelected} Selected
                                        </Container>
                                        <Button
                                            disabled={numberSelected === 0}
                                            variant="contained"
                                            style={{
                                                margin: theme.spacing(
                                                    2,
                                                    2,
                                                    2,
                                                    0,
                                                ),
                                                backgroundColor:
                                                    theme.palette.primary.dark,
                                                color: 'white',
                                                textTransform: 'none',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                setOpenWorkorderModal(true)
                                            }}
                                            size="small"
                                        >
                                            Create Workorders
                                        </Button>
                                    </Container>
                                )}
                                <IconButton
                                    style={{
                                        marginRight: theme.spacing(1),
                                    }}
                                    onClick={() => {
                                        setSelectionMode(!selectionMode)
                                    }}
                                    color={
                                        selectionMode ? 'primary' : 'default'
                                    }
                                >
                                    <Tooltip title="Select Inventory Inspections">
                                        <EditIcon fontSize="large" />
                                    </Tooltip>
                                </IconButton>
                                <Tooltip title="Download Excel">
                                    <IconButton
                                        onClick={() => {
                                            const params: UnitInspectionParams = getFilteredParams()
                                            if (inspection) {
                                                params.inspection =
                                                    inspection.id
                                            }
                                            if (inventoryConfig) {
                                                params.inventory_configs = inventoryConfig.id.toString()
                                            }
                                            if (statusId) {
                                                params.statuses = statusId
                                            }
                                            axiosInstance
                                                .get(
                                                    'inspection/inventory-inspection/get_analytics_list/',
                                                    {
                                                        params: {
                                                            ...params,
                                                            excel: true,
                                                        },
                                                        responseType: 'blob',
                                                    },
                                                )
                                                .then((res) => {
                                                    FileDownload(
                                                        new Blob([res.data]),
                                                        'inventoryInspectionList.xlsx',
                                                    )
                                                })
                                        }}
                                    >
                                        <FileCopy fontSize="large" />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Close">
                                    <IconButton
                                        onClick={() => {
                                            setOpen(false)
                                            setCurrentInvInsAnalytic(undefined)
                                        }}
                                    >
                                        <Close
                                            color="secondary"
                                            fontSize="large"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Container>
                        </Container>
                        {/* Body */}

                        <Container
                            style={{
                                margin: theme.spacing(2),
                                overflowY: 'auto',
                            }}
                        >
                            <SimpleTable<InspectionRow>
                                headCells={headCells}
                                orderByDefault={'name'}
                                rows={inventoryInspectionAnalyticList.map(
                                    (inventoryInspectionAnalytic) => {
                                        return createInspectionRow(
                                            inventoryInspectionAnalytic,
                                        )
                                    },
                                )}
                                render={(row) => {
                                    const invIns = row.invInsAnalytic.value
                                    const isSelected = selectionMode
                                        ? selectedInvInspectionMap[
                                              invIns.id
                                          ] !== undefined
                                        : currentInvInsAnalytic?.inv_ins_id ===
                                          invIns.id

                                    return (
                                        <TableRow
                                            key={invIns.inv_ins_id}
                                            style={{
                                                backgroundColor: isSelected
                                                    ? theme.palette.grey[300]
                                                    : undefined,
                                                cursor: selectionMode
                                                    ? 'pointer'
                                                    : '',
                                            }}
                                            onClick={() => {
                                                if (selectionMode) {
                                                    const newEditSelection = {
                                                        ...selectedInvInspectionMap,
                                                    }
                                                    if (
                                                        newEditSelection[
                                                            invIns.id
                                                        ] !== undefined
                                                    ) {
                                                        delete newEditSelection[
                                                            invIns.id
                                                        ]
                                                    } else {
                                                        newEditSelection[
                                                            invIns.id
                                                        ] = invIns
                                                    }
                                                    setSelectedInvInspectionMap(
                                                        newEditSelection,
                                                    )
                                                }
                                            }}
                                        >
                                            <TableCell style={cellStyle}>
                                                {row.location.value}
                                            </TableCell>
                                            <TableCell style={cellStyle}>
                                                {row.area.value}
                                            </TableCell>
                                            <TableCell style={cellStyle}>
                                                {row.name.value}
                                            </TableCell>
                                            <TableCell style={cellStyle}>
                                                <span
                                                    style={{
                                                        padding: theme.spacing(
                                                            1,
                                                        ),
                                                        borderRadius:
                                                            theme.shape
                                                                .borderRadius,
                                                        border: `1px solid ${row.invInsAnalytic.value.status_color}`,
                                                    }}
                                                >
                                                    {row.status.value}
                                                </span>
                                            </TableCell>
                                            <TableCell style={cellStyle}>
                                                {row.startDate.value}
                                            </TableCell>
                                            <TableCell style={cellStyle}>
                                                {row.endDate.value}
                                            </TableCell>
                                            <TableCell style={cellStyle}>
                                                {row.invInsAnalytic.value
                                                    .wo_count > 0 && (
                                                    <Tooltip
                                                        title={
                                                            'Workorder Created'
                                                        }
                                                    >
                                                        <ActionItemIcon
                                                            height={30}
                                                            width={30}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                            <TableCell style={cellStyle}>
                                                {row.channel.value}
                                            </TableCell>
                                        </TableRow>
                                    )
                                }}
                            />
                        </Container>
                    </Container>
                )}
            </Container>
            <InventoryInspectionWorkorderModal
                open={openWorkorderModal}
                userList={userList}
                serviceList={serviceList}
                vendorList={vendorList}
                inventoryInspections={inventoryInspectionList}
                initialTitle={`${inventoryConfig?.name} - ${status}`}
                loading={loading}
                scheduleList={scheduleList}
                handleClose={() => setOpenWorkorderModal(false)}
                onClickCreate={onCreateWorkorders}
            />
        </Blanket>
    )
}
