import React from 'react'
import { Container } from '../../components'
import { Button, Divider, useTheme } from '@material-ui/core'
import { CHOOSE_VENDOR_STEP } from './CreateAndEditRFP'
import { ModelMap, RFP, SimpleVendor } from '../../models'
import { toMMDDYYYY } from '../../helpers'
import { ReactComponent as DollarSignIcon } from '../../assets/icons/dollar-sign.svg'
import { ReactComponent as BriefcaseIcon } from '../../assets/icons/briefcase.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { NVLVendor } from '../../main/containers/national-vendor-list'
import { RFPVendorRow } from './RFPVendorList'
import { CreateOrUpdateRFPRequest, rfpApi } from '../../contexts'
import { toast } from 'react-toastify'

interface Props {
    selectedVendorsMap: ModelMap<NVLVendor | SimpleVendor>
    viewMode?: boolean
    vendorViewMode?: boolean
    setSelectedVendorsMap: (map: ModelMap<NVLVendor | SimpleVendor>) => void
    setActiveStep: (step: number) => void
    updateRFPBody: (submit?: boolean) => CreateOrUpdateRFPRequest
    onExit: () => void
}

export const ReviewAndSubmitForm = (props: Props) => {
    const {
        setActiveStep,
        setSelectedVendorsMap,
        selectedVendorsMap,
        updateRFPBody,
        onExit,
        viewMode,
    } = props

    const { selectedRFP, updateRFP } = rfpApi()

    const theme = useTheme()

    const subHeaderStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        fontWeight: 600,
    }

    const infoStyle: React.CSSProperties = {
        fontWeight: 500,
        ...theme.typography.body1,
        marginTop: theme.spacing(1),
    }

    const subContainerStyle: React.CSSProperties = {
        paddingRight: theme.spacing(3),
    }

    const iconStyle: React.CSSProperties = {
        height: 20,
        width: 20,
        margin: theme.spacing(0.5, 1, 0, 0),
    }

    const selectedVendorKeys = Object.keys(selectedVendorsMap)

    return (
        <Container
            direction="column"
            style={{
                padding: theme.spacing(0, 2, 2, 2),
                width: 'calc(100vw - 550px)',
            }}
        >
            <Container
                style={{
                    ...theme.typography.h5,
                    fontWeight: 600,
                    marginBottom: theme.spacing(3),
                }}
            >
                Review and Submit
            </Container>
            <Container
                direction="column"
                style={{ overflow: 'auto', height: 'calc(100vh - 280px)' }}
            >
                <Container
                    style={{
                        ...theme.typography.h5,
                        fontWeight: 600,
                        marginBottom: theme.spacing(1),
                    }}
                >
                    Main Details:
                </Container>
                <Container direction="column">
                    <Container>
                        <Container flex={1}>
                            <BriefcaseIcon style={{ ...iconStyle }} />
                            <Container
                                direction="column"
                                style={{ ...subContainerStyle }}
                            >
                                <span style={{ ...subHeaderStyle }}>
                                    RFP Name
                                </span>
                                <span style={{ ...infoStyle }}>
                                    {selectedRFP?.name}
                                </span>
                            </Container>
                        </Container>
                        <Container flex={1}>
                            <DollarSignIcon style={{ ...iconStyle }} />
                            <Container
                                direction="column"
                                style={{ ...subContainerStyle }}
                            >
                                <span style={{ ...subHeaderStyle }}>
                                    Budget
                                </span>
                                <span style={{ ...infoStyle }}>
                                    {selectedRFP?.budget}
                                </span>
                                <span style={{ ...infoStyle }}>
                                    (Hidden from Vendors)
                                </span>
                            </Container>
                        </Container>
                        <Container flex={1}>
                            {selectedRFP?.project_start_date && (
                                <>
                                    <CalendarIcon style={{ ...iconStyle }} />
                                    <Container
                                        direction="column"
                                        style={{ ...subContainerStyle }}
                                    >
                                        <span style={{ ...subHeaderStyle }}>
                                            Start Date
                                        </span>
                                        <span style={{ ...infoStyle }}>
                                            {toMMDDYYYY(
                                                new Date(
                                                    selectedRFP.project_start_date,
                                                ),
                                            )}
                                        </span>
                                    </Container>
                                </>
                            )}
                        </Container>
                    </Container>
                    <Container style={{ marginTop: theme.spacing(3) }}>
                        <Container flex={1}>
                            <BriefcaseIcon style={{ ...iconStyle }} />
                            <Container
                                direction="column"
                                style={{ ...subContainerStyle }}
                            >
                                <span style={{ ...subHeaderStyle }}>
                                    Services
                                </span>
                                <span style={{ ...infoStyle }}>
                                    {selectedRFP &&
                                        createRFPServiceString(selectedRFP)}
                                </span>
                            </Container>
                        </Container>
                        <Container flex={1}>
                            {selectedRFP?.project_submission_deadline && (
                                <>
                                    <CalendarIcon style={{ ...iconStyle }} />
                                    <Container
                                        direction="column"
                                        style={{ ...subContainerStyle }}
                                    >
                                        <span style={{ ...subHeaderStyle }}>
                                            Submission Deadline
                                        </span>
                                        <span style={{ ...infoStyle }}>
                                            {toMMDDYYYY(
                                                new Date(
                                                    selectedRFP.project_submission_deadline,
                                                ),
                                            )}
                                        </span>
                                    </Container>
                                </>
                            )}
                        </Container>
                        <Container flex={1}>
                            {selectedRFP?.project_end_date && (
                                <>
                                    <CalendarIcon style={{ ...iconStyle }} />
                                    <Container
                                        direction="column"
                                        style={{ ...subContainerStyle }}
                                    >
                                        <span style={{ ...subHeaderStyle }}>
                                            End Date
                                        </span>
                                        <span style={{ ...infoStyle }}>
                                            {toMMDDYYYY(
                                                new Date(
                                                    selectedRFP.project_end_date,
                                                ),
                                            )}
                                        </span>
                                    </Container>
                                </>
                            )}
                        </Container>
                    </Container>
                </Container>
                <Divider style={{ margin: theme.spacing(3, 0) }} />
                <Container
                    style={{
                        ...theme.typography.h5,
                        fontWeight: 600,
                        marginBottom: theme.spacing(1),
                    }}
                >
                    Scope of Work:
                </Container>
                <Container direction="column">
                    <span style={{ ...subHeaderStyle }}>
                        Description of Work
                    </span>
                    <Container
                        style={{
                            border: `1px solid ${theme.palette.lightGrey.light}`,
                            borderRadius: '3px',
                            width: '100%',
                            minHeight: '50px',
                            maxHeight: '100px',
                            flexWrap: 'wrap',
                            padding: theme.spacing(1),
                            overflow: 'auto',
                        }}
                    >
                        {selectedRFP?.description}
                    </Container>
                </Container>
                <Divider style={{ margin: theme.spacing(3, 0) }} />
                <Container>
                    <Container
                        style={{
                            ...theme.typography.h5,
                            fontWeight: 600,
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        Selected Vendors:
                    </Container>
                    <Container flex={1} />
                    {selectedVendorKeys.length > 0 && (
                        <Container
                            style={{ ...theme.typography.h6, fontWeight: 600 }}
                        >
                            {selectedVendorKeys.length} Vendors Selected
                        </Container>
                    )}
                </Container>
                <Container
                    style={{
                        overflow: 'auto',
                    }}
                    direction="column"
                >
                    {selectedVendorKeys.map((vendorKeyStr) => {
                        const vendorId = Number(vendorKeyStr)
                        const vendor = selectedVendorsMap[vendorId]
                        if (vendor) {
                            return (
                                <RFPVendorRow
                                    vendor={vendor}
                                    selectedVendorsMap={selectedVendorsMap}
                                    setSelectedVendorsMap={
                                        setSelectedVendorsMap
                                    }
                                    key={`REVIEW_RFP_VENDOR_${vendor.id}`}
                                    viewMode={viewMode}
                                />
                            )
                        }
                    })}
                </Container>
            </Container>
            <Container flex={1} />
            <Container style={{ marginTop: theme.spacing(2) }}>
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: 'white',
                        color: theme.palette.primary.dark,
                        borderColor: theme.palette.primary.dark,
                        textTransform: 'none',
                        cursor: 'pointer',
                        alignSelf: 'flex-end',
                    }}
                    onClick={() => {
                        setActiveStep(CHOOSE_VENDOR_STEP)
                    }}
                >
                    Previous: Select Vendors
                </Button>
                <Container flex={1} />
                {!viewMode && (
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            alignSelf: 'flex-end',
                        }}
                        onClick={() => {
                            const body = updateRFPBody(true)
                            updateRFP(body).then(() =>
                                toast.success(
                                    `${body.name} created successfully`,
                                ),
                            )
                            onExit()
                        }}
                    >
                        Submit
                    </Button>
                )}
            </Container>
        </Container>
    )
}

export const createRFPServiceString = (selectedRFP: RFP) => {
    const length = selectedRFP.rfp_services.length

    if (length === 0) {
        return ''
    } else if (length === 1) {
        return selectedRFP.rfp_services[0].service.name
    }

    let serviceString = ''
    selectedRFP.rfp_services.forEach((rfpService, idx) => {
        serviceString = serviceString + ` ${rfpService.service.name}`
        if (idx !== length - 1) {
            serviceString = serviceString + ','
        }
    })
    return serviceString
}
