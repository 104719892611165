import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { PropsFunc } from '@material-ui/styles'

export interface ListPopoverStyleArgs {
    color?: string | PropsFunc<{}, string | undefined>
    width?: string | number | PropsFunc<{}, string | number | undefined>
    height?: string | number | PropsFunc<{}, string | number | undefined>
}

export const ListPopoverStyles = (args?: ListPopoverStyleArgs) => {
    const styles = makeStyles((theme: Theme) =>
        createStyles({
            serviceMenu: {
                '&.MuiList-root': {
                    width: args?.width ? args.width : 150,
                    maxHeight: args?.height ? args.height : '200px !important',
                    overflow: 'auto',
                },
                '& .MuiListItemText-primary ': {
                    fontSize: '0.75rem',
                    fontWeight: 400,
                    color: args?.color ? args.color : '#000',
                },
            },
            paper: {
                background: '#FFFFFF',
                border: '1px solid #000000',
                borderRadius: '5px',
                boxShadow:
                    '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
            },
        }),
    )
    const useStyles = styles()
    return useStyles
}
