import React, { useEffect, useMemo, useState } from 'react'
import { Container, LeaseCard } from '../../../components'
import {
    Button,
    Checkbox,
    Divider,
    Modal,
    Paper,
    Slide,
    useTheme,
} from '@material-ui/core'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { Area, AreaConfig, Lease, ModelMap, Unit } from '../../../models'
import { axiosInstance } from '../../../helpers'
import { toast } from 'react-toastify'

import LoadingGIF from '../../../assets/Loading_Animation_2.gif'
import { SearchField } from '../../../components/SearchField'

interface Props {
    lease?: Lease
    open: boolean
    unitMap: ModelMap<Unit>
    areaConfigMap: ModelMap<AreaConfig>
    onTransferLease: (lease: Lease) => void
    onClose: () => void
}

export const LeaseTransferModal = (props: Props) => {
    const {
        open,
        onClose,
        lease,
        unitMap,
        areaConfigMap,
        onTransferLease,
    } = props

    const theme = useTheme()

    const [areas, setAreas] = useState<Area[] | null>(null)

    const [selectedAreaId, setSelectedAreaId] = useState(-1)
    const [searchText, setSearchText] = useState('')

    const getAreaOptions = async () => {
        try {
            const areaRes = await axiosInstance.get('infrastructure/area/', {
                params: { occupiable: true },
            })
            setAreas(areaRes.data)
        } catch (e: any) {
            toast.error(e.response)
        }
    }

    useEffect(() => {
        getAreaOptions()
    }, [])

    useEffect(() => {
        setSelectedAreaId(lease?.area.id ?? -1)

        return () => setSelectedAreaId(-1)
    }, [lease])

    const filteredAreas = useMemo(() => {
        return (
            areas?.filter((area) => {
                const unit = unitMap[area.unit]

                const folder = unit?.folder
                const path = `${folder?.path.toLocaleLowerCase()}${folder?.name.toLocaleLowerCase()}`

                const areaConfig = areaConfigMap[area.area_config]

                const searchValLC = searchText.toLocaleLowerCase()

                const unitValid = unit?.name
                    .toLocaleLowerCase()
                    .includes(searchValLC)

                const pathValid = path.includes(searchValLC)

                return unitValid || pathValid
            }) ?? []
        )
    }, [searchText, areas])

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open} style={{}}>
                <Paper
                    style={{
                        maxHeight: '100%',
                        height: HEIGHT,
                        maxWidth: '100%',
                        width: WIDTH,
                        display: 'flex',
                    }}
                >
                    <Container
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                        }}
                    >
                        {/* Header */}
                        <Container
                            style={{
                                height: HEADER_HEIGHT,
                                padding: theme.spacing(2),
                                flexDirection: 'column',
                            }}
                        >
                            <span style={{ ...theme.typography.h5 }}>
                                Transfer lease
                            </span>

                            <SearchField
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                placeholder="Search"
                                style={{ marginTop: theme.spacing(1) }}
                            />
                        </Container>
                        <Divider />

                        {/* Content */}
                        <Container
                            style={{
                                flexDirection: 'column',
                                flex: 1,
                                overflowY: 'hidden',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {lease && areas ? (
                                <Body
                                    lease={lease}
                                    unitMap={unitMap}
                                    areaConfigMap={areaConfigMap}
                                    areaList={filteredAreas}
                                    selectedAreaId={selectedAreaId}
                                    onSelectArea={setSelectedAreaId}
                                />
                            ) : (
                                <img
                                    src={LoadingGIF}
                                    style={{
                                        backgroundColor: 'red',
                                        maxHeight: 500,
                                        maxWidth: 500,
                                    }}
                                />
                            )}
                        </Container>

                        {/* Footer */}
                        <Divider />
                        <Container
                            style={{
                                height: FOOTER_HEIGHT,
                                padding: theme.spacing(2),
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                style={{ marginRight: theme.spacing(1) }}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    if (lease) {
                                        axiosInstance
                                            .post(
                                                `lease/${lease.id}/transfer/`,
                                                { area: selectedAreaId },
                                            )
                                            .then((res) => {
                                                onTransferLease(res.data)
                                                toast.success(
                                                    'Lease transfered',
                                                )
                                                onClose()
                                            })
                                            .catch((e) => {
                                                toast.error(
                                                    `${e.response.data.message}`,
                                                )
                                            })
                                    }
                                }}
                            >
                                Submit
                            </Button>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

interface BodyProps {
    lease: Lease
    unitMap: ModelMap<Unit>
    areaConfigMap: ModelMap<AreaConfig>
    areaList: Area[]
    selectedAreaId: number
    onSelectArea: (id: number) => void
}

const Body = (props: BodyProps) => {
    const {
        lease,
        unitMap,
        areaConfigMap,
        areaList,
        selectedAreaId,
        onSelectArea,
    } = props
    const theme = useTheme()

    return (
        <Container
            style={{
                flexDirection: 'column',
                flex: 1,
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                overflowY: 'scroll',
            }}
        >
            {/* Lease cards that show current lease and the changes that will be made */}
            <Container
                style={{
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: theme.spacing(1),
                }}
            >
                <LeaseCard
                    lease={lease}
                    containerStyle={{ marginTop: theme.spacing(2) }}
                    unitMap={unitMap}
                    areaConfigMap={areaConfigMap}
                />

                <ArrowRightAltIcon
                    fontSize="large"
                    style={{
                        marginRight: theme.spacing(2),
                        marginLeft: theme.spacing(2),
                    }}
                />

                <LeaseCard
                    lease={{
                        ...lease,
                        area:
                            areaList.find((a) => a.id === selectedAreaId) ??
                            lease.area,
                    }}
                    containerStyle={{ marginTop: theme.spacing(2) }}
                    unitMap={unitMap}
                    areaConfigMap={areaConfigMap}
                    highLightArea
                />
            </Container>
            <Divider />
            <Container
                style={{
                    flexDirection: 'column',
                    overflowY: 'scroll',
                }}
            >
                {areaList.map((area) => {
                    const unit = unitMap[area.unit]
                    const areaConfig = areaConfigMap[area.area_config]
                    return (
                        <Container
                            key={`AREA_${area.id}`}
                            style={{
                                margin: theme.spacing(1),
                                marginLeft: theme.spacing(0),
                                borderRadius: theme.shape.borderRadius,
                                border: `1px solid ${theme.palette.grey[500]}`,
                            }}
                        >
                            <Checkbox
                                checked={selectedAreaId === area.id}
                                onChange={() => onSelectArea(area.id)}
                            />

                            <Container
                                style={{
                                    flexDirection: 'column',
                                    flex: 1,
                                }}
                            >
                                <span>
                                    {unit?.folder.path}
                                    {unit?.folder.name}
                                </span>

                                <Container>
                                    <span
                                        style={{
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {unit?.name}
                                    </span>
                                    <span
                                        style={{
                                            marginLeft: theme.spacing(1),
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {areaConfig?.name} {area.area_label}
                                    </span>
                                </Container>
                            </Container>
                        </Container>
                    )
                })}
            </Container>
        </Container>
    )
}

const HEIGHT = 800
const WIDTH = 800
const FOOTER_HEIGHT = 75
const HEADER_HEIGHT = 100
