import React, { useEffect, useRef, useState } from 'react'
import { Container, SideDrawerContainer } from '../../components'
import {
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { CreateAndEditRFP } from './CreateAndEditRFP'
import { RFP, RFPStatus, RFPType } from '../../models'
import { axiosInstance, getDifferenceInDays } from '../../helpers'
import { useStyles } from '../../styles'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useBlanket, usePagination, useService, useUser } from '../../hooks'
import { Tune, BarChart } from '@material-ui/icons'
import SearchIcon from '@material-ui/icons/Search'
import { RFPFilterDrawer } from './RFPFilterDrawer'
import { Pagination } from '@material-ui/lab'
import { RFPFilterChips } from './RFPFilterChips'
import { rfpApi } from '../../contexts'
import { MarketAnalysisTool } from '../MarketAnalysisTool/MarketAnalysisTool'
import { ApartmentRFPDetail } from './ApartmentRFPDetail'
import { RFPCard } from './RFPCard'

const PUBLISHED_RFP_LIST = 0
const RFP_DRAFTS = 1
const CLOSED_RFPS = 2

export const RFPDashboard = () => {
    const rfpState = rfpApi()

    const { workspaceUser } = useUser()

    const [createOrEditRFP, setCreateOrEditRFP] = useState(false)
    const [selectedTab, setSelectedTab] = useState<number>(PUBLISHED_RFP_LIST)
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false)
    const [viewMode, setViewMode] = useState(false)

    const [openRFPDetail, setOpenRFPDetail] = useState(false)

    const contentRef = useRef<HTMLDivElement>(null)
    const [openMarketAnalysis, setOpenMarketAnalysis] = useState(false)
    const analyticRect = useBlanket(openMarketAnalysis, contentRef)

    const serviceList = useSelector(
        (state: RootState) => state.service.serviceList,
    )

    const theme = useTheme()
    const classes = useStyles()

    const { getServiceList } = useService()

    const onMount = async () => {
        await rfpState.getRFPList({
            params: {
                type: `${RFPType.INVITE_ONLY},${RFPType.PUBLIC}`,
            },
        })
        await getServiceList({})
    }

    const cleanUp = () => {
        rfpState.clearState()
    }

    useEffect(() => {
        onMount()
        return cleanUp
    }, [])

    useEffect(() => {
        if (selectedTab === PUBLISHED_RFP_LIST) {
            rfpState.setRFPFilterState({
                ...rfpState.rfpFilterState,
                statusFilter: RFPStatus.PUBLISHED,
            })
        } else if (selectedTab === RFP_DRAFTS) {
            rfpState.setRFPFilterState({
                ...rfpState.rfpFilterState,
                statusFilter: RFPStatus.DRAFT,
            })
        } else if (selectedTab === CLOSED_RFPS) {
            rfpState.setRFPFilterState({
                ...rfpState.rfpFilterState,
                statusFilter: RFPStatus.CLOSED,
            })
        }
    }, [selectedTab])

    const sortRFPs = (rfpA: RFP, rfpB: RFP) => {
        let rfpATimeLeft = 0
        let rfpBTimeLeft = 0
        const today = new Date()

        if (rfpA.project_submission_deadline) {
            rfpATimeLeft = getDifferenceInDays(
                new Date(rfpA.project_submission_deadline),
                today,
            )
        }
        if (rfpB.project_submission_deadline) {
            rfpBTimeLeft = getDifferenceInDays(
                new Date(rfpB.project_submission_deadline),
                today,
            )
        }

        if (rfpATimeLeft > rfpBTimeLeft) {
            return 1
        }
        return -1
    }

    const tabStyle: React.CSSProperties = {
        fontWeight: 800,
        fontSize: '15px',
        textTransform: 'none',
    }

    const { page, setPage, pageData, numPages, start, end } = usePagination(
        rfpState.filteredRFPList.sort(sortRFPs) ?? [],
    )

    if (
        !workspaceUser?.active_workspace.has_infrastructure &&
        workspaceUser?.active_workspace.stripe_subscription?.plan !== 'EZVENDOR'
    ) {
        return <Container />
    }

    if (createOrEditRFP || viewMode) {
        return (
            <CreateAndEditRFP
                onExit={() => {
                    rfpState.setSelectedRFP(null)
                    setCreateOrEditRFP(false)
                    setViewMode(false)
                }}
                serviceList={serviceList}
                viewMode={viewMode}
            />
        )
    }

    if (rfpState.selectedRFP && openRFPDetail) {
        return (
            <ApartmentRFPDetail
                selectedRFP={rfpState.selectedRFP}
                setOpenRFPDetail={setOpenRFPDetail}
            />
        )
    }

    return (
        <SideDrawerContainer open={openFilterDrawer}>
            <Container
                flex={1}
                direction="column"
                style={{ height: 'calc(100vh - 104px)' }}
                ref={contentRef}
            >
                <Container
                    style={{
                        fontWeight: 600,
                        fontSize: '32px',
                    }}
                >
                    Managing Your RFPs
                </Container>
                <Container
                    alignItems="center"
                    style={{ margin: theme.spacing(2, 0) }}
                >
                    <TextField
                        variant="outlined"
                        placeholder="Search"
                        value={rfpState.rfpFilterState.searchText}
                        onChange={(e) => {
                            rfpState.setRFPFilterState({
                                ...rfpState.rfpFilterState,
                                searchText: e.target.value,
                            })
                        }}
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Container flex={1} />
                    <Tooltip
                        title="Market Rate Analysis"
                        style={{ marginRight: theme.spacing(2) }}
                    >
                        <IconButton
                            onClick={() =>
                                setOpenMarketAnalysis(!openMarketAnalysis)
                            }
                            color={openMarketAnalysis ? 'primary' : 'default'}
                            size="small"
                        >
                            <BarChart fontSize="large" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Filter">
                        <IconButton onClick={() => setOpenFilterDrawer(true)}>
                            <Tune fontSize="large" color={'primary'} />
                        </IconButton>
                    </Tooltip>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            marginLeft: theme.spacing(1),
                        }}
                        onClick={() => {
                            rfpState.setSelectedRFP(null)
                            setCreateOrEditRFP(true)
                        }}
                    >
                        Create RFP
                    </Button>
                </Container>
                <RFPFilterChips
                    serviceList={serviceList}
                    rfpFilterState={rfpState.rfpFilterState}
                    setRFPFilterState={rfpState.setRFPFilterState}
                />
                <Container>
                    <Tabs
                        style={{
                            color: 'black',
                            borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
                            flex: 1,
                            textTransform: 'none',
                        }}
                        value={selectedTab}
                        onChange={(_, v) => {
                            if (v !== selectedTab) {
                                setSelectedTab(v)
                                setPage(1)
                            }
                        }}
                        classes={{
                            indicator: classes.indicator,
                        }}
                    >
                        <Tab
                            label="Your Published RFPs"
                            style={{ ...tabStyle }}
                        />
                        <Tab label={`Your Drafts`} style={{ ...tabStyle }} />
                        <Tab label={`Closed RFPs`} style={{ ...tabStyle }} />
                    </Tabs>
                </Container>
                <Container direction="column">
                    {rfpState.loadingState.getRFPList ? (
                        <Container
                            alignItems="center"
                            justifyContent="center"
                            flex={1}
                            style={{ minHeight: 'calc(100vh - 370px)' }}
                        >
                            <CircularProgress size={100} />
                        </Container>
                    ) : (
                        <Container
                            flexWrap="wrap"
                            style={{
                                overflowY: 'auto',
                                maxHeight: 'calc(100vh - 370px)',
                                minHeight: 'calc(100vh - 370px)',
                            }}
                        >
                            {pageData.map((rfp) => {
                                return (
                                    <RFPCard
                                        rfp={rfp}
                                        theme={theme}
                                        key={`RFP_CARD_${rfp.id}`}
                                        onClick={() => {
                                            if (
                                                rfp.status === RFPStatus.DRAFT
                                            ) {
                                                setViewMode(false)
                                                setCreateOrEditRFP(true)
                                            } else {
                                                setOpenRFPDetail(true)
                                            }
                                            rfpState.setSelectedRFP(rfp)
                                        }}
                                    />
                                )
                            })}
                        </Container>
                    )}
                    <Container
                        style={{
                            borderTop: `1px solid ${theme.palette.grey[400]}`,
                            padding: theme.spacing(1),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Pagination
                            count={numPages}
                            page={page}
                            onChange={(_: unknown, newPage: number) => {
                                setPage(newPage)
                            }}
                        />
                        <span>
                            {start} - {end} of{' '}
                            {rfpState.filteredRFPList.length ?? 0}
                        </span>
                    </Container>
                </Container>
                <RFPFilterDrawer
                    open={openFilterDrawer}
                    serviceList={serviceList}
                    onClose={() => setOpenFilterDrawer(false)}
                />
                <MarketAnalysisTool
                    open={openMarketAnalysis}
                    onClose={() => setOpenMarketAnalysis(false)}
                    rect={analyticRect}
                    serviceList={serviceList}
                />
            </Container>
        </SideDrawerContainer>
    )
}
