import React from 'react'
import { Button, Divider, useTheme } from '@material-ui/core'
import { Container } from '../../components'
import { resolveRoute, Routes, toMMDDYYYY } from '../../helpers'
import { EndOfTurnReport, Schedule } from '../../models'
import { Scheduler } from '../Scheduler_v2/Scheduler'
import { useHistory } from 'react-router'

interface Props {
    scheduleDetail?: Schedule
    endOfTurnReportList: EndOfTurnReport[]
    id?: string
}

const HEADER_HEIGHT = 100

export const ArchivedScheduleHeader = (props: Props) => {
    const { scheduleDetail, endOfTurnReportList, id } = props

    const theme = useTheme()
    const history = useHistory()

    const startDateStr = scheduleDetail?.start_date
    const endDateStr = scheduleDetail?.end_date

    let goBacks = 0
    let doubleGoBacks = 0
    let goBackMax = 0
    let assignedCount = 0
    let completeCount = 0
    let approvedCount = 0

    endOfTurnReportList.forEach((currentEndOfTurnReport) => {
        currentEndOfTurnReport.unit_config_data.forEach((ucData) => {
            goBacks += ucData.go_back_count
            goBackMax += ucData.go_back_max
            doubleGoBacks += ucData.double_go_back
            assignedCount += ucData.assigned_count
            completeCount += ucData.complete_count
            approvedCount += ucData.approved_count
        })
    })

    const startDate = startDateStr ? new Date(startDateStr) : undefined
    const endDate = endDateStr ? new Date(endDateStr) : undefined

    const textTheme: React.CSSProperties = {
        ...theme.typography.body2,
        marginRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightLight,
    }
    const numberTheme: React.CSSProperties = {
        ...textTheme,
        fontWeight: theme.typography.fontWeightBold,
    }
    return (
        <Container style={{ flexDirection: 'column' }}>
            <Container
                style={{
                    minHeight: HEADER_HEIGHT,
                    maxHeight: HEADER_HEIGHT,
                    justifyContent: 'center',
                    padding: theme.spacing(1, 2),
                }}
            >
                <Container style={{ flexDirection: 'column', flex: 1 }}>
                    <Container>
                        <Container
                            style={{
                                flexDirection: 'column',
                                marginRight: theme.spacing(2),
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {scheduleDetail?.name}
                            </span>

                            <span style={{ ...theme.typography.subtitle2 }}>
                                {scheduleDetail?.schedule_id}
                            </span>
                        </Container>

                        <Container
                            style={{
                                ...theme.typography.subtitle1,
                                flexDirection: 'column',
                            }}
                        >
                            <span style={textTheme}>
                                {toMMDDYYYY(startDate)}
                            </span>
                            <span style={textTheme}>{toMMDDYYYY(endDate)}</span>
                        </Container>
                        <Container
                            style={{ marginLeft: theme.spacing(3), flex: 1 }}
                        >
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeightBold,
                                    marginRight: theme.spacing(2),
                                }}
                            >
                                Turn Statistics:
                            </span>
                            <Container
                                style={{
                                    flexDirection: 'column',
                                    marginTop: theme.spacing(0.5),
                                }}
                            >
                                <Container
                                    style={{ marginRight: theme.spacing(1) }}
                                >
                                    <span style={textTheme}>
                                        Assigned Count:{' '}
                                    </span>
                                    <span style={numberTheme}>
                                        {assignedCount}
                                    </span>
                                </Container>
                                <Container
                                    style={{ marginRight: theme.spacing(1) }}
                                >
                                    <span style={textTheme}>
                                        Complete Count:{' '}
                                    </span>
                                    <span style={numberTheme}>
                                        {completeCount}
                                    </span>
                                </Container>
                                <Container
                                    style={{ marginRight: theme.spacing(1) }}
                                >
                                    <span style={textTheme}>
                                        Approved Count:{' '}
                                    </span>
                                    <span style={numberTheme}>
                                        {approvedCount}
                                    </span>
                                </Container>
                            </Container>
                            <Container
                                style={{
                                    flexDirection: 'column',
                                    marginTop: theme.spacing(0.5),
                                }}
                            >
                                <Container
                                    style={{ marginRight: theme.spacing(1) }}
                                >
                                    <span style={textTheme}>Go Backs: </span>
                                    <span style={numberTheme}>{goBacks}</span>
                                </Container>
                                <Container
                                    style={{ marginRight: theme.spacing(1) }}
                                >
                                    <span style={textTheme}>
                                        Double Go Backs:{' '}
                                    </span>
                                    <span style={numberTheme}>
                                        {doubleGoBacks}
                                    </span>
                                </Container>
                                <Container
                                    style={{ marginRight: theme.spacing(1) }}
                                >
                                    <span style={textTheme}>3+ Go Backs: </span>
                                    <span style={numberTheme}>{goBackMax}</span>
                                </Container>
                            </Container>
                            <Container
                                style={{
                                    flexDirection: 'column',
                                    marginTop: theme.spacing(0.5),
                                }}
                            >
                                <Container
                                    style={{ marginRight: theme.spacing(1) }}
                                >
                                    <span style={textTheme}>
                                        Approved First Time:{' '}
                                    </span>
                                    <span style={numberTheme}>
                                        {approvedCount - goBacks}
                                    </span>
                                </Container>
                            </Container>
                        </Container>
                        <Container
                            style={{
                                padding: theme.spacing(2),
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    if (id) {
                                        const route =
                                            Routes.apartmentScheduleDetail
                                        history.push(
                                            resolveRoute(route, ':id', id),
                                        )
                                    }
                                }}
                            >
                                View Schedule
                            </Button>
                        </Container>
                    </Container>
                </Container>
            </Container>
            <Divider />
        </Container>
    )
}
