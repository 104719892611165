import React from 'react'

import { Container } from '../../../../components'
import { Modal, Paper, Slide, Theme } from '@material-ui/core'
import { ApartmentDeadline, DeadlineDetail } from '../../../../models'
import { DeadlineTransitionCard } from './DeadlineTransitionCard'
import { DeadlineStatusButton } from './DeadlineStatusButton'

interface Props {
    theme: Theme
    open: boolean
    deadlines: ApartmentDeadline[]
    transitionDeadline: (
        deadlineId: number,
        complete: boolean,
    ) => Promise<DeadlineDetail>
    onClose: () => void
}

export const DeadlineListModal = (props: Props) => {
    const { theme, open, onClose, deadlines, transitionDeadline } = props

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper style={{}}>
                    <Container
                        style={{
                            borderRadius: theme.shape.borderRadius,
                            width: MODAL_W,
                            height: MODAL_H,
                            flexDirection: 'column',
                        }}
                    >
                        {/* Header */}
                        <Container
                            style={{
                                borderBottom: `1px solid ${theme.palette.grey[800]}`,
                                height: HEADER_H,
                                padding: theme.spacing(0, 1),
                                alignItems: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h5,
                                    fontWeight: theme.typography.fontWeightBold,
                                    color: theme.palette.grey[800],
                                }}
                            >
                                Deadline List
                            </span>
                        </Container>
                        {/* Content */}

                        <Container
                            style={{
                                flexDirection: 'column',
                                overflowY: 'scroll',
                            }}
                        >
                            {deadlines.map((dl) => {
                                return (
                                    <DeadlineRow
                                        key={`DEADLINE-${dl.id}`}
                                        theme={theme}
                                        deadline={dl}
                                        transitionDeadline={transitionDeadline}
                                    />
                                )
                            })}
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

interface DeadlineRowProps {
    deadline: ApartmentDeadline
    theme: Theme
    transitionDeadline: (
        deadlineId: number,
        complete: boolean,
    ) => Promise<DeadlineDetail>
}

const DeadlineRow = (props: DeadlineRowProps) => {
    const { theme, deadline, transitionDeadline } = props

    return (
        <Container
            style={{
                padding: theme.spacing(2),
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                flexDirection: 'column',
            }}
        >
            {/* First row, complete status + transition card */}
            <Container>
                <Container style={{ flexDirection: 'column' }}>
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            color: theme.palette.grey[800],
                            fontWeight: 'bold',
                        }}
                    >
                        {deadline.deadline.name}
                    </span>
                    <DeadlineStatusButton
                        theme={theme}
                        onClick={() => {
                            transitionDeadline(
                                deadline.id,
                                !deadline.complete_status,
                            )
                        }}
                        complete={deadline.complete_status}
                    />
                </Container>
                <div style={{ flex: 1 }} />
                <DeadlineTransitionCard
                    theme={theme}
                    transitions={deadline.transitions}
                />
            </Container>

            {/* Second Row, description */}

            <span
                style={{
                    ...theme.typography.body1,
                    color: theme.palette.grey[700],
                    fontWeight: theme.typography.fontWeightRegular,
                    marginTop: theme.spacing(1),
                }}
            >
                {deadline.deadline.description}
            </span>
        </Container>
    )
}

const MODAL_H = window.innerHeight * 0.8
const MODAL_W = MODAL_H * 0.8

const HEADER_H = 50
