import {
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TableCell,
    TableRow,
    useTheme,
} from '@material-ui/core'
import { Refresh, Save } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { toast } from 'react-toastify'
import {
    CellData,
    Container,
    HeadCell,
    NumberInput,
    RowData,
    Selector,
    SimpleTable,
} from '../../components'
import { axiosInstance, toMMDDYYYY } from '../../helpers'
import { Company, IdentifiableNamedObject, Inspection } from '../../models'

interface InspectionWithAnalytics extends Inspection {
    complete_percent: number
    assigned_percent: number
    handled_percent: number
}

interface ApartmentWithInspection extends Company {
    inspection?: InspectionWithAnalytics
}

export const PortfolioInspectionTab = () => {
    const [apartmentInspectionList, setApartmentInsepctionList] = useState<
        ApartmentWithInspection[]
    >([])
    const [insTypeNameList, setInsTypeNameList] = useState<string[]>([])
    const [insType, setInsType] = useState<string | undefined>(undefined)
    const [isApartmentListLoading, setIsApartmentListLoading] = useState(false)
    const [minimumCompletion, setMinimumCompletion] = useState('50')
    const theme = useTheme()

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    interface ApartmentInspectionRow extends RowData {
        locName: CellData<string>
        insName: CellData<string>
        startDate: CellData<string>
        complete: CellData<string>
    }

    useEffect(() => {
        getApartmentInspectionList()
    }, [insType])

    const getApartmentInspectionList = () => {
        setIsApartmentListLoading(true)
        const params: {
            completion: string
            inspection_type?: string
        } = { completion: minimumCompletion }
        if (insType) {
            params.inspection_type = insType
        }
        const ins_list_promise = axiosInstance.get(
            'workorder/portfolio-schedule/get_portfolio_ins_list',
            {
                params: params,
            },
        )

        const ins_type_names_promise = axiosInstance.get(
            'workorder/portfolio-schedule/get_inspection_type_list',
        )

        Promise.all([ins_type_names_promise, ins_list_promise]).then(
            (values) => {
                setInsTypeNameList(values[0].data)
                setApartmentInsepctionList(values[1].data)
                setIsApartmentListLoading(false)
            },
        )
    }

    const headCells: HeadCell<ApartmentInspectionRow>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'locName',
            label: 'Location',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'insName',
            label: 'Inspection',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'startDate',
            label: 'Start Date',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'complete',
            label: 'Complete %',
            style: headCellStyle,
        },
    ]

    const rows: ApartmentInspectionRow[] =
        apartmentInspectionList?.map((aptIns) => {
            let insName = 'None'
            let insSort = ''
            let insComplete = ''
            let insCompleteSort = 0
            let start = ''
            if (aptIns.inspection) {
                insName = aptIns.inspection.name
                insSort = aptIns.inspection.name
                insComplete = aptIns.inspection.complete_percent.toFixed(2)
                insCompleteSort = Number(insComplete)
                start = toMMDDYYYY(new Date(aptIns.inspection.start_date))
            }
            return {
                locName: {
                    value: aptIns.name,
                    sortValue: aptIns.name,
                },

                insName: { value: insName, sortValue: insSort },
                startDate: { value: start, sortValue: start },
                complete: { value: insComplete, sortValue: insCompleteSort },
            }
        }) ?? []

    return (
        <Container style={{ flexDirection: 'column', height: '100%' }}>
            <Container
                style={{
                    flexDirection: 'row',
                    width: '100%',
                    alignItems: 'center',
                    marginTop: theme.spacing(2),
                }}
            >
                <NumberInput
                    label={'Minimum Completion Percent'}
                    value={minimumCompletion}
                    onChange={(e) => {
                        setMinimumCompletion(e.target.value)
                    }}
                    variant="outlined"
                    style={{ flex: 1 }}
                />
                <FormControl
                    variant={'outlined'}
                    style={{ margin: theme.spacing(2), flex: 1 }}
                >
                    <InputLabel id={`selector-label`}>
                        {'Inspection Type'}
                    </InputLabel>
                    <Select
                        labelId={`selector-label`}
                        id={`assign-select`}
                        value={insType}
                        onChange={(e) => {
                            const newFilter = e.target.value as string
                            setInsType(newFilter)
                        }}
                        label={'Inspection Type'}
                    >
                        {insTypeNameList.map((type) => (
                            <MenuItem key={`MODAL_SELECT_${type}`} value={type}>
                                <span>{type}</span>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <IconButton
                    onClick={() => {
                        if (minimumCompletion !== '') {
                            getApartmentInspectionList()
                        } else {
                            toast.error(
                                'Please Input a Minimum Completion Percent',
                            )
                        }
                    }}
                >
                    <Refresh color="primary" fontSize="large" />
                </IconButton>
                {isApartmentListLoading && (
                    <CircularProgress
                        size={50}
                        style={{ alignSelf: 'center', justifySelf: 'center' }}
                    />
                )}
            </Container>
            <Container
                style={{
                    height: 'calc(100vh - 330px)',
                }}
                scrollY
            >
                <SimpleTable<ApartmentInspectionRow>
                    rows={rows ?? []}
                    render={(row) => {
                        return (
                            <TableRow>
                                <TableCell>{row.locName.value}</TableCell>
                                <TableCell>{row.insName.value}</TableCell>
                                <TableCell>{row.startDate.value}</TableCell>
                                <TableCell>{row.complete.value}</TableCell>
                            </TableRow>
                        )
                    }}
                    orderByDefault="locName"
                    headCells={headCells}
                />
            </Container>
        </Container>
    )
}
