import {
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    Slide,
    TextField,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container, Selector, Swiper, TimelinePicker } from '../../components'
import { useDateRange, useSelectVendorService } from '../../hooks'
import {
    ListVendor,
    Schedule,
    Service,
    User,
    WorkorderType,
} from '../../models'
import { InventoryInspectionAnalytic } from './InventoryInspectionAnalyticsBlanket'
import { AssignUnitDict, CreateWorkOrderRequest } from '../../store'

interface Props {
    open: boolean
    serviceList: Service[]
    vendorList: ListVendor[]
    userList: User[]
    inventoryInspections: InventoryInspectionAnalytic[]
    loading: boolean
    scheduleList: Schedule[]
    initialTitle?: string
    handleClose: () => void
    onClickCreate: (request: CreateWorkOrderRequest) => void
}

export const InventoryInspectionWorkorderModal = (props: Props) => {
    const {
        open,
        serviceList,
        vendorList,
        userList,
        inventoryInspections,
        initialTitle,
        scheduleList,
        loading,
        handleClose,
        onClickCreate,
    } = props

    const [title, setTitle] = useState(initialTitle ? initialTitle : '')
    const [swiperState, setSwiperState] = useState({
        position: ASSIGN_TO_COMPANY,
        companyId: -1,
        userId: -1,
    })

    const [priority, setPriority] = useState(false)
    const [doValidate, setDoValidate] = useState(false)

    const {
        selectService,
        selectVendor,
        selectedServiceId,
        selectedVendorId,
    } = useSelectVendorService(vendorList)

    const { dateRange, setEndDate, setStartDate, setRange } = useDateRange('')

    const [selectedScheduleId, setSelectedScheduleId] = useState(-1)

    const theme = useTheme()

    useEffect(() => {
        setTitle(initialTitle ? initialTitle : '')
        setPriority(false)
        setSwiperState({
            position: ASSIGN_TO_COMPANY,
            companyId: -1,
            userId: -1,
        })
        setDoValidate(false)
        selectService(-1)
        setStartDate(new Date())
        setEndDate(new Date())
        setSelectedScheduleId(-1)
    }, [open])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        width: 600,
                    }}
                >
                    <Container
                        style={{
                            flexDirection: 'column',
                            flex: 1,
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                                color: theme.palette.grey[800],
                                margin: theme.spacing(1),
                            }}
                        >
                            Create Workorders
                        </span>
                        <TextField
                            label="Workorder Title"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value)
                            }}
                            required
                            style={{ margin: theme.spacing(1) }}
                            variant="outlined"
                            error={doValidate && title === ''}
                        />
                        <div style={{ margin: theme.spacing(1) }}>
                            <TimelinePicker
                                startDate={dateRange.startDate}
                                endDate={dateRange.endDate}
                                onChangeStartDate={setStartDate}
                                onChangeEndDate={setEndDate}
                            />
                        </div>
                        <Selector
                            label="Service"
                            data={serviceList}
                            searchable
                            currentValue={selectedServiceId}
                            onChange={(e) => {
                                selectService(e.target.value as number)
                            }}
                            getDisplayString={(service) => service.name}
                            customStyle={{
                                formControl: { margin: theme.spacing(1) },
                            }}
                        />
                        <Selector
                            label="Project"
                            data={scheduleList}
                            searchable
                            currentValue={selectedScheduleId}
                            onChange={(e) => {
                                setSelectedScheduleId(e.target.value as number)
                            }}
                            getDisplayString={(schedule) => schedule.name}
                            customStyle={{
                                formControl: { margin: theme.spacing(1) },
                            }}
                        />
                        <Container
                            style={{
                                flexDirection: 'column',
                                margin: theme.spacing(1),
                                border: `1px solid ${theme.palette.grey[500]}`,
                                borderRadius: theme.shape.borderRadius,
                            }}
                        >
                            <Swiper
                                width={575}
                                style={{ overflow: 'hidden', margin: 4 }}
                                position={swiperState.position}
                                onChangePosition={() => {
                                    setSwiperState({
                                        ...swiperState,
                                        position: !swiperState.position,
                                    })
                                }}
                                leftItem={
                                    <FormControl fullWidth>
                                        <span
                                            style={{
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                            }}
                                        >
                                            My Vendors
                                        </span>
                                        <Select
                                            value={swiperState.companyId}
                                            placeholder="My Vendors"
                                            label="My Vendors"
                                            onChange={(e) => {
                                                setSwiperState({
                                                    ...swiperState,
                                                    companyId: Number(
                                                        e.target.value,
                                                    ),
                                                })
                                            }}
                                        >
                                            <MenuItem
                                                key={`NO_VENDOR_SELECTED`}
                                                value={-1}
                                            >
                                                <span
                                                    style={{
                                                        color:
                                                            theme.palette
                                                                .grey[700],
                                                    }}
                                                >
                                                    Not Assigned (My Vendors)
                                                </span>
                                            </MenuItem>
                                            {props.vendorList.map((vnd) => {
                                                return (
                                                    <MenuItem
                                                        key={`SELECT_VENDOR_${vnd.id}`}
                                                        value={vnd.id}
                                                    >
                                                        {vnd.name}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                }
                                rightItem={
                                    <FormControl fullWidth>
                                        <span
                                            style={{
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                            }}
                                        >
                                            My Team
                                        </span>
                                        <Select
                                            value={swiperState.userId}
                                            label="My Team"
                                            placeholder="My Team"
                                            onChange={(e) => {
                                                setSwiperState({
                                                    ...swiperState,
                                                    userId: Number(
                                                        e.target.value,
                                                    ),
                                                })
                                            }}
                                        >
                                            <MenuItem
                                                key={`NO_USER_SELECTED`}
                                                value={-1}
                                            >
                                                <span
                                                    style={{
                                                        color:
                                                            theme.palette
                                                                .grey[700],
                                                    }}
                                                >
                                                    Not Assigned (My Team)
                                                </span>
                                            </MenuItem>
                                            {userList.map((user) => {
                                                return (
                                                    <MenuItem
                                                        key={`SELECT_USER_${user.id}`}
                                                        value={user.id}
                                                    >
                                                        {user.name}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                }
                            />
                        </Container>
                        <Container
                            style={{
                                marginLeft: theme.spacing(1),
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={priority}
                                        onChange={() => setPriority(!priority)}
                                    />
                                }
                                label="Priority"
                            />
                        </Container>
                        <Divider style={{ marginTop: theme.spacing(1) }} />
                        <Container
                            style={{
                                alignItems: 'center',
                                padding: theme.spacing(1),
                            }}
                        >
                            <Container style={{ flex: 1 }} />
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                style={{
                                    textTransform: 'none',
                                    width: '100px',
                                }}
                                color="secondary"
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={loading}
                                variant="contained"
                                style={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: loading
                                        ? '#fff'
                                        : theme.palette.primary.contrastText,
                                    textTransform: 'none',
                                    marginLeft: theme.spacing(1),
                                    width: '100px',
                                }}
                                onClick={() => {
                                    if (title === '') {
                                        setDoValidate(true)
                                        return
                                    }
                                    const request: CreateWorkOrderRequest = {
                                        body: {
                                            title: title,
                                            workorder_type:
                                                selectedScheduleId === -1
                                                    ? WorkorderType.ACTION_ITEM
                                                    : WorkorderType.SERVICE_ORDER,
                                            start_date: dateRange.startDate?.toISOString(),
                                            end_date: dateRange.endDate?.toISOString(),
                                            assign_to_type: swiperState.position
                                                ? 'user'
                                                : 'vendor',
                                            assign_to_id: swiperState.position
                                                ? swiperState.userId
                                                : swiperState.companyId,
                                        },
                                    }

                                    if (priority) {
                                        request.body.priority = true
                                    }

                                    if (selectedServiceId !== -1) {
                                        request.body.service = selectedServiceId
                                    }

                                    if (selectedScheduleId !== -1) {
                                        request.body.schedule = selectedScheduleId
                                    }

                                    const units: AssignUnitDict[] = []
                                    const invInsIds: number[] = []

                                    inventoryInspections.forEach((invIns) => {
                                        units.push({
                                            unit_id: invIns.unit_id,
                                            service_areas: [invIns.area_id],
                                        })
                                        invInsIds.push(invIns.id)
                                    })

                                    request.body.units = units
                                    request.body.inv_ins_ids = invInsIds

                                    onClickCreate(request)
                                }}
                            >
                                {loading ? (
                                    <CircularProgress
                                        size={25}
                                        style={{ color: 'white' }}
                                    />
                                ) : (
                                    <span
                                        style={{
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {`Create (${inventoryInspections.length})`}
                                    </span>
                                )}
                            </Button>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

const ASSIGN_TO_USER = false
const ASSIGN_TO_COMPANY = true
