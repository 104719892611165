import React, { useState } from 'react'
import clsx from 'clsx'
import { useTheme } from '@material-ui/core'
import { ServiceAnalytic as ServiceAnalyticModel } from '../../../models'
import {
    APPROVED_ANALYTIC,
    ASSIGNED_ANALYTIC,
    COMPLETE_ANALYTIC,
    StatusSelection,
} from './AnalyticsBlanket'
import { useStyles } from '../../../styles'
import { Container, NestedProgress } from '../../../components'

interface Props {
    analytic: ServiceAnalyticModel
    unitCount: number
    selectedServiceId: number
    selectedStatus: StatusSelection
    setAnalytic: (analytic: StatusSelection, serviceId: number) => void
}

export const ServiceAnalytic = (props: Props) => {
    const {
        unitCount,
        analytic,
        setAnalytic,
        selectedServiceId,
        selectedStatus,
    } = props

    const {
        serviceName,
        serviceId,
        workorderCount,
        notStarted,
        behindTrack,
        COMPLETE,
        APPROVED,
        INVOICED,
    } = analytic

    const [hoveredStatus, setHoveredStatus] = useState<StatusSelection | -1>(-1)

    const theme = useTheme()

    const classes = useStyles()

    const noMouseEvents: React.CSSProperties = {
        pointerEvents: 'none',
    }

    const analyticTitle: React.CSSProperties = {
        ...noMouseEvents,
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightBold,
    }

    let providerTrackText = 'On Track'
    if (behindTrack > 0) {
        providerTrackText = `${behindTrack} Behind`
    }

    const assignedPercent =
        (workorderCount / (unitCount > 0 ? unitCount : 1)) * 100

    const denominator = workorderCount > 0 ? workorderCount : 1

    const completeNumerator = COMPLETE + APPROVED + INVOICED
    const completePercent = (completeNumerator / denominator) * 100

    const approvedNumerator = APPROVED + INVOICED
    const approvedPercent = (approvedNumerator / denominator) * 100

    const hoverColor = theme.palette.primary.dark

    const assignBarColor =
        hoveredStatus === ASSIGNED_ANALYTIC
            ? hoverColor
            : theme.palette.primary.main
    const completeBarColor =
        hoveredStatus === COMPLETE_ANALYTIC
            ? hoverColor
            : theme.palette.secondary.main
    const approvedBarColor =
        hoveredStatus === APPROVED_ANALYTIC
            ? hoverColor
            : theme.palette.brand1.main

    return (
        <Container
            style={{
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.grey[200],
                margin: theme.spacing(1),
                padding: theme.spacing(1),
            }}
        >
            <Container style={{ flexDirection: 'column' }}>
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {serviceName}
                </span>

                <span>Provider Track: {providerTrackText}</span>

                <span>Not started: {notStarted}</span>

                <Container
                    onMouseOver={() => setHoveredStatus(ASSIGNED_ANALYTIC)}
                    onMouseOut={() => setHoveredStatus(-1)}
                    className={clsx(classes.fontHoverSecondaryMain, {
                        [classes.fontSecondaryMain]:
                            serviceId === selectedServiceId &&
                            ASSIGNED_ANALYTIC === selectedStatus,
                    })}
                    onClick={() => setAnalytic(ASSIGNED_ANALYTIC, serviceId)}
                    style={{
                        flexDirection: 'column',
                        marginTop: theme.spacing(1),
                        cursor: 'pointer',
                    }}
                >
                    <span style={analyticTitle}>Assigned / Units</span>
                    <span style={noMouseEvents}>
                        {workorderCount} / {unitCount} (
                        {assignedPercent.toFixed(1)}%)
                    </span>
                </Container>

                <Container
                    onMouseOver={() => setHoveredStatus(COMPLETE_ANALYTIC)}
                    onMouseOut={() => setHoveredStatus(-1)}
                    className={clsx(classes.fontHoverSecondaryMain, {
                        [classes.fontSecondaryMain]:
                            serviceId === selectedServiceId &&
                            COMPLETE_ANALYTIC === selectedStatus,
                    })}
                    onClick={() => setAnalytic(COMPLETE_ANALYTIC, serviceId)}
                    style={{
                        flexDirection: 'column',
                        marginTop: theme.spacing(1),
                        cursor: 'pointer',
                    }}
                >
                    <span style={analyticTitle}>Complete / Assigned</span>
                    <span style={noMouseEvents}>
                        {completeNumerator} / {workorderCount} (
                        {completePercent.toFixed(1)}%)
                    </span>
                </Container>

                <Container
                    onMouseOver={() => setHoveredStatus(APPROVED_ANALYTIC)}
                    onMouseOut={() => setHoveredStatus(-1)}
                    className={clsx(classes.fontHoverSecondaryMain, {
                        [classes.fontSecondaryMain]:
                            serviceId === selectedServiceId &&
                            APPROVED_ANALYTIC === selectedStatus,
                    })}
                    onClick={() => setAnalytic(APPROVED_ANALYTIC, serviceId)}
                    style={{
                        flexDirection: 'column',
                        marginTop: theme.spacing(1),
                        cursor: 'pointer',
                    }}
                >
                    <span style={analyticTitle}>Approved / Assigned</span>
                    <span style={noMouseEvents}>
                        {approvedNumerator} / {workorderCount} (
                        {approvedPercent.toFixed(1)}%)
                    </span>
                </Container>
            </Container>
            <div style={{ flex: 1 }} />
            <Container style={{ flex: 3 }}>
                <NestedProgress
                    data={[
                        {
                            progress: Math.min(assignedPercent, 100),
                            size: 80,
                            barKey: `ASSIGN_BAR_${serviceName}`,
                            strokeWidth: STROKE_WIDTH,
                            primaryStroke: assignBarColor,
                            backdropStroke: theme.palette.grey[400],
                        },
                        {
                            progress: Math.min(completePercent, 100),
                            size: 100,
                            barKey: `COMPLETE_BAR_${serviceName}`,
                            strokeWidth: STROKE_WIDTH,
                            primaryStroke: completeBarColor,
                            backdropStroke: theme.palette.grey[400],
                        },
                        {
                            progress: Math.min(approvedPercent, 100),
                            size: 120,
                            barKey: `APPROVED_BAR_${serviceName}`,
                            strokeWidth: STROKE_WIDTH,
                            primaryStroke: approvedBarColor,
                            backdropStroke: theme.palette.grey[400],
                        },
                    ]}
                />
            </Container>
        </Container>
    )
}

const STROKE_WIDTH = 5
