import React, { useState } from 'react'

import { Button, TextField, Theme, Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

import { Container, Selector } from '../../../components'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { InspectionType, Schedule } from '../../../models'
import { useDateRange } from '../../../hooks'
import { dateValid } from '../../../helpers'

interface Props {
    theme: Theme
    scheduleList: Schedule[]
    inspectionTypeList: InspectionType[]
    completeStep: (
        name: string,
        startDate: Date,
        endDate: Date,
        inspectionTypeId: number,
        scheduleId: number,
    ) => void
}

export const InspectionDetailStep = (props: Props) => {
    const { theme, scheduleList, inspectionTypeList, completeStep } = props

    const [doValidate, setDoValidate] = useState(false)
    const [name, setName] = useState('')

    const { dateRange, setStartDate, setEndDate } = useDateRange('')

    const [inspectionTypeId, setInspectionTypeId] = useState(-1)
    const [scheduleId, setScheduleId] = useState(UNDEFNIED_SCHEDULE_ID)
    const [userFriendlyScheduleId, setUserFriendlyScheduleId] = useState('')

    const SubHeaderCellStyle: React.CSSProperties = {
        fontWeight: 500,
        fontSize: '15px',
        marginBottom: theme.spacing(2),
    }

    const inputStyle: React.CSSProperties = {
        marginBottom: theme.spacing(4),
    }

    const isValid = () => {
        const nameValid = name !== ''
        const inspectionTypeValid = inspectionTypeId !== -1
        const startValid = dateValid(dateRange.startDate)
        const endValid = dateValid(dateRange.endDate)

        return nameValid && inspectionTypeValid && startValid && endValid
    }

    return (
        <Container
            style={{
                flexDirection: 'column',
                padding: theme.spacing(2),
                flex: 1,
            }}
        >
            <Container style={{ ...SubHeaderCellStyle }}>Name:</Container>

            <TextField
                error={doValidate && name === ''}
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={inputStyle}
                size="small"
            />

            <Selector
                size="small"
                label={'Inspection Type'}
                error={doValidate && inspectionTypeId === -1}
                currentValue={inspectionTypeId}
                customStyle={{
                    formControl: {
                        margin: 0,
                        ...inputStyle,
                    },
                }}
                onChange={(
                    event: React.ChangeEvent<{
                        value: unknown
                    }>,
                ) => {
                    setInspectionTypeId(event.target.value as number)
                }}
                data={inspectionTypeList}
                getDisplayString={(insType) => insType.name}
                searchable
            />
            <Container>
                <Selector
                    size="small"
                    label={'Schedule'}
                    currentValue={scheduleId}
                    customStyle={{
                        formControl: {
                            margin: 0,
                            width: 300,
                            ...inputStyle,
                        },
                    }}
                    onChange={(
                        event: React.ChangeEvent<{
                            value: unknown
                        }>,
                    ) => {
                        setScheduleId(event.target.value as number)
                    }}
                    data={[
                        {
                            id: UNDEFNIED_SCHEDULE_ID,
                            name: 'None',
                        },
                        ...scheduleList,
                    ]}
                    getDisplayString={(s) => s.name}
                    searchable
                />
                <Container
                    style={{
                        marginTop: theme.spacing(2),
                        marginLeft: theme.spacing(1),
                    }}
                >
                    <Tooltip title="Only select a schedule when wanting to walk vacant units, otherwise select None">
                        <InfoIcon fontSize="small" color="action" />
                    </Tooltip>
                </Container>
            </Container>
            {scheduleId === NEW_SCHEDULE_ID && (
                <TextField
                    error={
                        doValidate &&
                        (userFriendlyScheduleId === '' ||
                            userFriendlyScheduleId.length > 8)
                    }
                    variant="outlined"
                    value={userFriendlyScheduleId}
                    onChange={(e) => setUserFriendlyScheduleId(e.target.value)}
                    label="Schedule ID (Required)"
                    style={inputStyle}
                    size="small"
                />
            )}
            <KeyboardDatePicker
                error={!dateValid(dateRange.startDate)}
                value={dateRange.startDate}
                onChange={(date) => {
                    if (date) {
                        setStartDate(date)
                    }
                }}
                format="MM/dd/yyyy"
                inputVariant="outlined"
                label="Start Date"
                style={inputStyle}
                size="small"
            />
            <KeyboardDatePicker
                error={!dateValid(dateRange.endDate)}
                value={dateRange.endDate}
                onChange={(date) => {
                    if (date) {
                        setEndDate(date)
                    }
                }}
                format="MM/dd/yyyy"
                inputVariant="outlined"
                label="End Date"
                style={inputStyle}
                size="small"
            />

            <div style={{ flex: 1 }} />

            <Button
                onClick={() => {
                    setDoValidate(true)

                    if (isValid()) {
                        completeStep(
                            name,
                            dateRange.startDate!,
                            dateRange.endDate!,
                            inspectionTypeId,
                            scheduleId,
                        )
                    }
                }}
                variant="outlined"
                color="primary"
                style={{ textTransform: 'none' }}
            >
                Create & begin assigning
            </Button>
        </Container>
    )
}

const UNDEFNIED_SCHEDULE_ID = -1
const NEW_SCHEDULE_ID = 0
