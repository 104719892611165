import { useTheme } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import React from 'react'
import { ColorPicker, Container } from '../../components'
import { RequestCustomStatus } from '../../store'

interface Props {
    error?: boolean
    status: RequestCustomStatus
    updateStatus: (s: RequestCustomStatus) => void
    label?: string
    customTopPlacement?: number
    customLeftPlacement?: number
}

export const StatusField = (props: Props) => {
    const {
        error,
        status,
        updateStatus,
        label,
        customTopPlacement,
        customLeftPlacement,
    } = props
    const theme = useTheme()
    const textLabel = label ?? 'Name'
    return (
        <Container style={{ alignItems: 'center', width: '100%' }}>
            <TextField
                variant="outlined"
                label={textLabel}
                error={error}
                value={status.name}
                onChange={(e) => {
                    const name = e.target.value.slice(0, 20)
                    updateStatus({
                        ...status,
                        name: name,
                    })
                }}
                style={{
                    marginRight: theme.spacing(1),
                    width: '100%',
                }}
                size="small"
            />
            {/* color picker */}
            <ColorPicker
                size={40}
                setColor={(c) => updateStatus({ ...status, color: c })}
                color={status.color}
                customLeftPlacement={customLeftPlacement}
                customTopPlacement={customTopPlacement}
            />
        </Container>
    )
}
