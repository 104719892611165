import React from 'react'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

import { Container, StatusBadge } from '../../../components'

import { toMMDDYYYY, toUiTime } from '../../../helpers'
import {
    DwollaTransferTransitionLog,
    Invoice,
    WorkSpaceUser,
    getTransferUIColor,
    getTransferUIStatus,
} from '../../../models'
import { useTheme, IconButton, Popover, Theme } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import NumberFormat from 'react-number-format'
import { usePopover } from '../../../hooks/usePopover'

interface InvoiceRowProps {
    invoice: Invoice
    user: WorkSpaceUser
    openPopover: (
        e: React.MouseEvent<HTMLButtonElement>,
        invoice: Invoice,
    ) => void
}

export const InvoiceRow = (props: InvoiceRowProps) => {
    const { invoice, openPopover, user } = props
    const theme = useTheme()

    const createdDate = new Date(invoice.created_date)

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    const statusBadgeColor = getTransferUIColor(
        theme,
        invoice.dwolla_transfer?.status,
    )

    let doingBusinessWithName = invoice.vendor.name
    if (user.active_workspace.company_type === 'VENDOR') {
        doingBusinessWithName = invoice.apartment.name
    }

    return (
        <Container
            style={{
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.palette.grey[300]}`,
                margin: theme.spacing(1),
                padding: theme.spacing(1),
                marginBottom: 0,
                minHeight: 100,
                maxHeight: 100,
            }}
        >
            <Container style={{ flexDirection: 'column', width: 200 }}>
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    EZ#{invoice.id}
                </span>
                <span>{doingBusinessWithName}</span>
            </Container>
            <Container
                style={{
                    flexDirection: 'column',
                    width: 200,
                }}
            >
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {toMMDDYYYY(createdDate)}
                </span>
                <NumberFormat
                    housandSeparator
                    isNumericString
                    prefix="$"
                    value={invoice.total_cost}
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                />
            </Container>

            <Container style={{ flexDirection: 'column', width: 200 }}>
                <StatusBadge
                    onClick={handleOpen}
                    text={getTransferUIStatus(invoice.dwolla_transfer?.status)}
                    customStyle={{
                        container: {
                            backgroundColor: statusBadgeColor.backgroundColor,
                        },
                        text: {
                            color: statusBadgeColor.color,
                            fontWeight: theme.typography.fontWeightBold,
                        },
                    }}
                />
            </Container>
            <div style={{ flex: 1 }} />

            {user.active_workspace.company_type === 'APARTMENT' && (
                <Container
                    style={{
                        alignItems: 'center',
                    }}
                >
                    <div>
                        <IconButton
                            onClick={(e) => {
                                openPopover(e, invoice)
                            }}
                        >
                            <MoreVertIcon fontSize="large" />
                        </IconButton>
                    </div>
                </Container>
            )}

            <Popover
                open={isOpen}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            >
                <Container style={{ flexDirection: 'column', width: 250 }}>
                    {invoice.dwolla_transfer?.transfer_logs.map((log, idx) => {
                        return (
                            <TransitionLogRow
                                key={`LOG_${log.id}`}
                                log={log}
                                theme={theme}
                                showBorderBottom={
                                    idx <
                                    (invoice.dwolla_transfer?.transfer_logs
                                        .length ?? 0) -
                                        1
                                }
                            />
                        )
                    })}
                </Container>
            </Popover>
        </Container>
    )
}

interface TLProps {
    log: DwollaTransferTransitionLog
    theme: Theme
    showBorderBottom: boolean
}

const TransitionLogRow = (props: TLProps) => {
    const { log, theme, showBorderBottom } = props

    const createdDate = new Date(log.created_date)
    const dateLabel = toMMDDYYYY(createdDate)
    const timeLabel = toUiTime(createdDate)

    const fromColor = getTransferUIColor(theme, log.from_status)
    const toColor = getTransferUIColor(theme, log.to_status)

    const initiatedLabel = log.initiated_by?.name ?? 'Bank Event'

    return (
        <Container
            key={`LOG_${log.id}`}
            style={{
                borderBottom: showBorderBottom ? '1px solid black' : undefined,
                padding: theme.spacing(1),
                flexDirection: 'column',
            }}
        >
            <Container style={{ alignItems: 'center' }}>
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {initiatedLabel}
                </span>

                <div style={{ flex: 1 }} />

                <Container style={{ flexDirection: 'column' }}>
                    <span
                        style={{
                            ...theme.typography.caption,
                            fontWeight: theme.typography.fontWeightLight,
                        }}
                    >
                        {dateLabel}
                    </span>
                    <span
                        style={{
                            ...theme.typography.caption,
                            fontWeight: theme.typography.fontWeightLight,
                        }}
                    >
                        {timeLabel}
                    </span>
                </Container>
            </Container>

            <Container
                style={{
                    marginTop: theme.spacing(1),
                    justifyContent: 'space-around',
                }}
            >
                <StatusBadge
                    text={getTransferUIStatus(log.from_status)}
                    customStyle={{
                        container: {
                            backgroundColor: fromColor.backgroundColor,
                            width: 100,
                        },
                        text: {
                            color: fromColor.color,
                            fontWeight: theme.typography.fontWeightBold,
                        },
                    }}
                />

                <ArrowRightAltIcon />

                <StatusBadge
                    text={getTransferUIStatus(log.to_status)}
                    customStyle={{
                        container: {
                            backgroundColor: toColor.backgroundColor,
                            width: 100,
                        },
                        text: {
                            color: toColor.color,
                            fontWeight: theme.typography.fontWeightBold,
                        },
                    }}
                />
            </Container>
        </Container>
    )
}
