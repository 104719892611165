import { CircularProgress, IconButton, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { FileCopy } from '@material-ui/icons'
import PDFMerger from 'pdf-merger-js/browser'
import { Container } from '../../components'
import { toMMDDYYYY } from '../../helpers'
import { Report } from '../../models'

interface Props {
    report: Report
}

export const ReportsDrawerItem = (props: Props) => {
    const { report } = props

    const [reportGenerating, setReportGenerating] = useState(false)

    const theme = useTheme()

    const createdDate = toMMDDYYYY(new Date(report.creation_time))
    return (
        <Container
            style={{
                borderBottom: `1px solid ${theme.palette.grey[400]}`,
                padding: theme.spacing(2),

                flexDirection: 'column',
            }}
        >
            <Container style={{ alignItems: 'center' }}>
                <Container style={{ flexDirection: 'column' }}>
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {report.name}
                    </span>
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {report.generated_by.name}
                    </span>
                </Container>
                {reportGenerating ? (
                    <CircularProgress
                        size={30}
                        style={{ margin: theme.spacing(2) }}
                    />
                ) : (
                    <IconButton
                        disabled={reportGenerating}
                        onClick={async () => {
                            if (report.report) {
                                window.open(report.report)
                                return false
                            } else {
                                setReportGenerating(true)
                                const merger = new PDFMerger()

                                // using traditional for structure to make awaits function properly
                                for (const page of report.report_pages) {
                                    for (const file of page.report_files) {
                                        await fetch(file.file)
                                            .then((res) => {
                                                return res.blob()
                                            })
                                            .then(async (arrayBuffer) => {
                                                await merger.add(arrayBuffer)
                                            })
                                    }
                                }

                                const mergedPDF = await merger.saveAsBlob()
                                const url = URL.createObjectURL(mergedPDF)
                                window.open(url)
                                setReportGenerating(false)
                                return false
                            }
                        }}
                    >
                        <FileCopy />
                    </IconButton>
                )}
            </Container>
            <span
                style={{
                    ...theme.typography.body2,
                }}
            >
                {createdDate}
            </span>
        </Container>
    )
}
