import React from 'react'
import { InvoiceCode } from './InvoiceCode'
import {
    INVOICE_CODE_STEP,
    SEND_INVOICE_STEP,
    useVendorInvoice,
} from '../../../contexts/useVendorInvoice'
import { SendInvoice } from './SendInvoice'
import { Container } from '../../../components'
import {
    Divider,
    IconButton,
    Modal,
    Paper,
    Slide,
    Theme,
    Tooltip,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

interface Props {
    open: boolean
    theme: Theme
    handleClose: () => void
    refreshData: () => void
}

export const VendorSendInvoiceModal = (props: Props) => {
    const { open, theme, handleClose, refreshData } = props

    const vendorInvoiceController = useVendorInvoice()

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        height: 'calc(100vh - 100px)',
                        width: 'calc(100vw - 100px)',
                        maxWidth: '800px',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <Container
                        style={{
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h6,
                                flex: 1,
                                color: theme.palette.grey[800],
                            }}
                        >
                            Send Invoice
                        </span>
                        <Tooltip title="Cancel">
                            <IconButton
                                onClick={() => {
                                    handleClose()
                                }}
                            >
                                <CloseIcon
                                    fontSize="inherit"
                                    style={{
                                        marginRight: theme.spacing(1),
                                        color: theme.palette.darkGreen.main,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Container>
                    <Divider style={{ marginBottom: theme.spacing(2) }} />
                    {vendorInvoiceController.step === INVOICE_CODE_STEP && (
                        <InvoiceCode theme={theme} />
                    )}

                    {vendorInvoiceController.step === SEND_INVOICE_STEP && (
                        <SendInvoice theme={theme} refreshData={refreshData} />
                    )}
                </Paper>
            </Slide>
        </Modal>
    )
}
