import React, { useEffect, useRef, useState } from 'react'

import {
    Container,
    MessageDrawer,
    DamageDrawer,
    SideDrawer,
    MessageForm,
} from '../../components'
import { SchedulerHeader } from './Header'
import {
    _useFinderSelection,
    usePrunedInfrastructure,
    useRootInfrastructure,
    _useWorkorder,
    useBlanket,
    useMessageDrawer,
    useUpstreamServiceContract,
    useAppDispatch,
    AreaStatusTagController,
    _LeaseController,
    AptConfigController,
    useSmartSchedule,
    useUser,
} from '../../hooks'
import {
    FinderLocationSelection,
    FinderSelectionMode,
    RootState,
    getChangeOrderConfigListRequest,
    setUnits,
} from '../../store'

import { ScheduleFilterDrawer, WorkorderDetailDrawer } from './Drawers'
import { SchedulerBody } from './Body'

import {
    ChangeOrderModal,
    SchedulerAssignModal,
    SmartScheduleModal,
} from './Modals'
import {
    addWorkorderToAnalytics,
    Area,
    AreaConfig,
    BaseWorkorder,
    createWorkorderAnalyticDict,
    Damage,
    getTotalPriceExpense,
    getTotalPriceIncome,
    IdBoolMap,
    ListVendor,
    ModelListMap,
    ModelMap,
    Schedule,
    Service,
    traverse,
    Unit,
    User,
    WorkorderStatus,
} from '../../models'
import { CircularProgress, Theme } from '@material-ui/core'
import { useScheduleFilter } from '../../hooks/useFilter'

import { BulkEditSelectionList } from '../Scheduler/Forms'
import { BudgetModal } from './Modals/BudgetModal/BudgetModal'
import { useSelector } from 'react-redux'
import { KeyTrackingModal } from './Modals/KeyTrackingModal'
import { ScheduleBlanketRoot } from './Blankets/ScheduleBlanketRoot'
import { ChangeOrderListModal } from '../ChangeOrder/ChangeOrderListModal'
import { isAccountManager } from '../../helpers'

const DRAWER_ANIMATION_DELAY_MS = 250

interface Props {
    theme: Theme
    scheduleDetail: Schedule
    aptConfigController: AptConfigController
    astController: AreaStatusTagController
    leaseController: _LeaseController
    serviceList: Service[]
    vendorList: ListVendor[]
    areaConfigMap: ModelMap<AreaConfig>
    rootUser: User
}

export const Scheduler = (props: Props) => {
    const {
        theme,
        astController,
        areaConfigMap,
        scheduleDetail,
        leaseController,
        serviceList,
        vendorList,
        aptConfigController,
    } = props

    const {
        areaStatusTagList,
        editAreaStatusTag,
        areaStatusTagMap,
    } = astController

    const { damageList, createDamage } = leaseController
    const { unitConfigList, getUnitConfigMap } = aptConfigController
    const unitConfigMap = getUnitConfigMap()

    // Forms
    const [smartSchedulerOpen, setSmartSchedulerOpen] = useState(false)
    const [assignModalOpen, setAssignModalOpen] = useState(false)
    const [workorderDetail, setWorkorderDetail] = useState<
        { workorder: BaseWorkorder; unit: Unit } | undefined
    >(undefined)

    const [changeOrderModalOpen, setChangeOrderModalOpen] = useState<
        BaseWorkorder | undefined
    >(undefined)

    const [analyticsModalOpen, setAnalyticsModalOpen] = useState(false)

    const [openKeyTracking, setOpenKeyTracking] = useState(false)

    const [filterOpen, setFilterOpen] = useState(true)
    const [damageUnit, setDamageUnit] = useState<Unit | undefined>()
    const [messageDrawerOpen, setMessageDrawerOpen] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [serviceBudgetModalOpen, setServiceBudgetModalOpen] = useState(false)

    const [editWorkorderSelection, setEditWorkorderSelection] = useState<
        ModelMap<BaseWorkorder>
    >({})

    const [vacantAreas, setVacantAreas] = useState<Area[]>([])

    const [openProjectNotes, setOpenProjectNotes] = useState(false)

    const [openChangeOrderListModal, setOpenChangeOrderListModal] = useState(
        false,
    )

    const contentRef = useRef<HTMLDivElement>(null)

    const dispatch = useAppDispatch()

    const { workspaceUser } = useUser()

    const {
        unitWorkorderMap,
        createWorkorder,
        updateWorkorder,
        incrementWorkorderMessageCount,
        workorderList,
        getWorkorderList,
        loadingList: workordersLoading,
    } = _useWorkorder(scheduleDetail.id)

    const { setChannel } = useMessageDrawer()

    const scheduleController = useSmartSchedule(scheduleDetail.id)

    const scheduleBudgetItemList = useSelector(
        (state: RootState) => state.workorder.schedule.scheduleBudgetItems,
    )

    const changeOrderConfigList = useSelector(
        (state: RootState) => state.aptConfig.changeOrderConfigList,
    )

    const locationFinderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Recursive,
    })

    const assignmentFinderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.PrunedSelection,
        selectionMode: FinderSelectionMode.Recursive,
    })

    const { tree, unitList } = useRootInfrastructure(true)

    const [serviceAreaMap, setServiceAreaMap] = useState<IdBoolMap>({})
    const [areaSelection, setAreaSelection] = useState<IdBoolMap>({})

    const root = tree.root

    // Build required Model maps
    const filterController = useScheduleFilter(
        unitWorkorderMap,
        {},
        areaStatusTagMap,
        serviceAreaMap,
        areaConfigMap,
        unitConfigMap,
        vacantAreas,
    )

    const prunedTree = usePrunedInfrastructure(
        tree,
        locationFinderSelection.selection,
        filterController.filter,
        filterController.isUnitInFilter,
    )

    const analyticRect = useBlanket(analyticsModalOpen, contentRef)

    const [workordersInFilter, setWorkordersInFilter] = useState<
        BaseWorkorder[]
    >([])
    const [analyticDict, setAnalyticDict] = useState(
        createWorkorderAnalyticDict(),
    )

    const [unitDamageMap, setUnitDamageMap] = useState<ModelListMap<Damage>>({})

    const networkRequestsComplete =
        scheduleDetail !== undefined &&
        damageList !== undefined &&
        unitWorkorderMap.readyForUse === true

    const upstreamContractMap = useUpstreamServiceContract()

    const [openUnitNotes, setOpenUnitNotes] = useState(false)
    const [selectedUnit, setSelectedUnit] = useState<Unit | undefined>()

    useEffect(() => {
        if (!changeOrderConfigList) {
            dispatch(getChangeOrderConfigListRequest({}))
        }
    }, [])

    useEffect(() => {
        // when a workorder is updated, if there is a selected workorder, update it
        if (workorderDetail && unitWorkorderMap.readyForUse) {
            const updatedWo = unitWorkorderMap[workorderDetail.unit.id]?.find(
                (wo) => wo.id === workorderDetail.workorder.id,
            )
            if (updatedWo) {
                setWorkorderDetail({
                    unit: workorderDetail.unit,
                    workorder: updatedWo,
                })
            }
        }
    }, [unitWorkorderMap])

    useEffect(() => {
        if (networkRequestsComplete) {
            let curDamageIdx = 0
            const mutableAnalyticDict = createWorkorderAnalyticDict()
            const mutableWorkordersInFilter: BaseWorkorder[] = []
            const mutableDamageMap: ModelListMap<Damage> = {}

            traverse(prunedTree.root, (folder) => {
                folder.units.forEach((unit) => {
                    if (!filterController.isUnitInFilter(unit)) {
                        return
                    }

                    if (damageList && damageList.length > 0) {
                        while (
                            damageList[curDamageIdx] &&
                            damageList[curDamageIdx].unit === unit.id
                        ) {
                            if (mutableDamageMap[unit.id] === undefined) {
                                mutableDamageMap[unit.id] = []
                            }
                            mutableDamageMap[unit.id]!.push(
                                damageList[curDamageIdx],
                            )
                            curDamageIdx += 1
                        }
                    }

                    mutableAnalyticDict.unitCount++
                    if (unit.id in unitWorkorderMap) {
                        const unitWorkorders = unitWorkorderMap[unit.id]
                        unitWorkorders?.forEach((wo) => {
                            if (
                                filterController.isWorkorderInFilter(wo, unit)
                            ) {
                                addWorkorderToAnalytics(mutableAnalyticDict, wo)
                                mutableWorkordersInFilter.push(wo)
                                const woPrice = getTotalPriceExpense(wo)

                                if (wo.service_id) {
                                    if (
                                        !(
                                            wo.service_id in
                                            mutableAnalyticDict.serviceBudgets
                                        )
                                    ) {
                                        mutableAnalyticDict.serviceBudgets[
                                            wo.service_id
                                        ] = {
                                            serviceName: wo.service_name ?? '',
                                            totalBudget: 0,
                                            invoicedBudget: 0,
                                        }
                                    }

                                    mutableAnalyticDict.incomeForecast += getTotalPriceIncome(
                                        wo,
                                        upstreamContractMap,
                                    )
                                    mutableAnalyticDict.forecastedBudget += woPrice
                                    mutableAnalyticDict.serviceForecastedBudget += woPrice
                                    mutableAnalyticDict.serviceBudgets[
                                        wo.service_id
                                    ].totalBudget += woPrice
                                    if (
                                        wo.status === WorkorderStatus.INVOICED
                                    ) {
                                        mutableAnalyticDict.serviceBudgets[
                                            wo.service_id
                                        ].invoicedBudget += woPrice
                                        mutableAnalyticDict.invoicedBudget += woPrice
                                    }
                                }
                            }
                        })
                    }
                })
            })

            // for each schedule budget item, add its cost to forecasted cost
            scheduleBudgetItemList?.forEach((item) => {
                mutableAnalyticDict.forecastedBudget += item.cost
                mutableAnalyticDict.lineItemForecastedBudget += item.cost
            })

            setWorkordersInFilter(mutableWorkordersInFilter)
            setAnalyticDict(mutableAnalyticDict)
            setUnitDamageMap(mutableDamageMap)
        }
    }, [
        scheduleDetail,
        scheduleBudgetItemList,
        damageList,
        unitWorkorderMap,
        filterController.filter,
        prunedTree,
    ])

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                width: '100%',
                height: 'calc(100vh - 104px)',
            }}
        >
            {/* Header */}
            <SchedulerHeader
                refreshWorkorders={() => {
                    getWorkorderList({
                        params: { schedule: scheduleDetail.id },
                    })
                }}
                clickSmartScheduler={() => {
                    if (isAccountManager(props.rootUser)) {
                        setSmartSchedulerOpen(!smartSchedulerOpen)
                    } else {
                        window.alert(
                            'Please reach out to your account manager to help with the smart scheduler.',
                        )
                    }
                }}
                schedule={scheduleDetail}
                filterOpen={filterOpen}
                clickAssign={() => setAssignModalOpen(true)}
                clickFilter={() => {
                    setFilterOpen(!filterOpen)
                    setOpenUnitNotes(false)
                }}
                clickBudget={() =>
                    setServiceBudgetModalOpen(!serviceBudgetModalOpen)
                }
                clickAnalytics={() =>
                    setAnalyticsModalOpen(!analyticsModalOpen)
                }
                filterController={filterController}
                locationSelection={locationFinderSelection.selection}
                setLocationSelection={
                    locationFinderSelection.setFinderSelection
                }
                serviceList={serviceList}
                vendorList={vendorList}
                unitConfigList={unitConfigList}
                analyticDict={analyticDict}
                analyticsOpen={analyticsModalOpen}
                editMode={editMode}
                setEditMode={(editMode) => {
                    setFilterOpen(false)
                    setWorkorderDetail(undefined)
                    setAssignModalOpen(false)
                    setEditMode(editMode)
                    setOpenUnitNotes(false)
                }}
                workorderList={workordersInFilter}
                clickKeyTracking={() => setOpenKeyTracking(true)}
                areaStatusTagList={areaStatusTagList}
                openProjectNotes={openProjectNotes}
                clickProjectNotes={() => {
                    setOpenProjectNotes(!openProjectNotes)
                    setFilterOpen(false)
                    setWorkorderDetail(undefined)
                    setAssignModalOpen(false)
                    setOpenUnitNotes(false)
                }}
                clickChangeOrder={() => {
                    setOpenChangeOrderListModal(!openChangeOrderListModal)
                }}
            />

            {/* Body */}
            {workordersLoading ? (
                <Container
                    style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress size={100} />
                </Container>
            ) : (
                <SchedulerBody
                    theme={theme}
                    workspaceUser={workspaceUser}
                    prunedTree={prunedTree.root}
                    unitConfigMap={unitConfigMap}
                    areaConfigMap={areaConfigMap}
                    unitWorkorderMap={unitWorkorderMap}
                    schedule={scheduleDetail}
                    unitDamageMap={unitDamageMap}
                    serviceAreaMap={serviceAreaMap}
                    selectedWorkorder={workorderDetail?.workorder}
                    editMode={editMode}
                    editWorkorderSelection={editWorkorderSelection}
                    areaStatusTagMap={areaStatusTagMap}
                    areaStatusConfigList={
                        aptConfigController.areaStatusConfigList ?? []
                    }
                    selectedUnit={damageUnit}
                    upstreamContractMap={upstreamContractMap}
                    vacantAreas={vacantAreas}
                    filterController={filterController}
                    onClickChangeOrder={(workorder) => {
                        if (workorder.id === changeOrderModalOpen?.id) {
                            setChangeOrderModalOpen(undefined)
                        } else {
                            setChangeOrderModalOpen(workorder)
                        }
                    }}
                    clickDamage={(unit) => {
                        setWorkorderDetail(undefined)
                        setDamageUnit(unit)
                        setOpenUnitNotes(false)
                    }}
                    onSelectWorkorder={(workorder, unit) => {
                        setDamageUnit(undefined)
                        if (editMode) {
                            const newEditSelection = {
                                ...editWorkorderSelection,
                            }
                            const tempSelectionMap = { ...areaSelection }

                            if (newEditSelection[workorder.id] !== undefined) {
                                delete newEditSelection[workorder.id]
                                workorder.service_area_list.forEach((area) => {
                                    delete tempSelectionMap[area.area_id]
                                })
                            } else {
                                newEditSelection[workorder.id] = workorder
                                workorder.service_area_list.forEach((area) => {
                                    tempSelectionMap[area.area_id] = true
                                })
                            }

                            setAreaSelection(tempSelectionMap)
                            setEditWorkorderSelection(newEditSelection)
                        } else {
                            // If the user selects the already open workorder, close the drawer
                            if (
                                workorderDetail?.workorder.id === workorder.id
                            ) {
                                setWorkorderDetail(undefined)
                                return
                            }

                            setWorkorderDetail({
                                workorder: workorder,
                                unit: unit,
                            })
                            setOpenUnitNotes(false)
                        }
                    }}
                    clickAssign={(unit) => {
                        // Clear the old selection
                        assignmentFinderSelection.setFinderSelection()
                        // Add the new selection
                        assignmentFinderSelection.setFinderSelection({
                            location: unit,
                            type: 'unit',
                        })
                        setAssignModalOpen(true)
                        setOpenUnitNotes(false)
                    }}
                    contentRef={contentRef}
                    clickUnitNotes={(selectedUnit) => {
                        setMessageDrawerOpen(false)
                        setOpenUnitNotes(false)
                        if (selectedUnit.channel_id) {
                            setChannel(selectedUnit.channel_id)
                        }
                        setTimeout(() => {
                            setSelectedUnit(selectedUnit)
                            setOpenUnitNotes(true)
                        }, DRAWER_ANIMATION_DELAY_MS)
                        if (selectedUnit.unread_unit_notes !== 0) {
                            const newUnitList = unitList?.map((unit) => {
                                if (selectedUnit.id === unit.id) {
                                    return {
                                        ...unit,
                                        unread_unit_notes: 0,
                                    }
                                }
                                return unit
                            })
                            dispatch(setUnits(newUnitList ?? []))
                        }
                    }}
                />
            )}

            {/* Drawers */}

            {scheduleDetail.channel && (
                <SideDrawer
                    open={openProjectNotes}
                    width={420}
                    handleClose={() => {
                        setOpenProjectNotes(false)
                    }}
                    title={'Project Notes'}
                >
                    <MessageForm
                        theme={theme}
                        channelId={scheduleDetail.channel}
                    />
                </SideDrawer>
            )}

            <ChangeOrderListModal
                open={openChangeOrderListModal}
                theme={theme}
                changeOrderConfigList={changeOrderConfigList ?? []}
                workspaceUser={workspaceUser}
                onClose={() => setOpenChangeOrderListModal(false)}
            />

            <ScheduleFilterDrawer
                open={filterOpen}
                filter={filterController.filter}
                setInfrastructureFilter={
                    filterController.setInfrastructureFilter
                }
                onClose={() => setFilterOpen(false)}
                vendorList={vendorList}
                serviceList={serviceList}
                unitConfigList={unitConfigList}
                areaStatusConfigList={
                    aptConfigController.areaStatusConfigList ?? []
                }
                workorderList={workorderList ?? []}
                areaConfigMap={areaConfigMap}
                areaStatusTagMap={areaStatusTagMap}
                unitWorkorderMap={unitWorkorderMap}
                locationFinderSelection={locationFinderSelection}
                root={root}
                vacantAreas={vacantAreas}
                setVacantAreas={setVacantAreas}
            />

            <WorkorderDetailDrawer
                workorder={workorderDetail?.workorder}
                onClose={() => setWorkorderDetail(undefined)}
                openMessages={() => setMessageDrawerOpen(true)}
                updateWorkorder={updateWorkorder}
                vendorList={vendorList}
                unit={workorderDetail?.unit}
                unitConfig={
                    unitConfigMap[workorderDetail?.unit.unit_config ?? -1]
                }
                areaConfigMap={areaConfigMap}
                schedule={scheduleDetail}
            />

            <DamageDrawer
                unit={damageUnit}
                openMessages={(damage: Damage) => {
                    setChannel(damage.channel)
                    setMessageDrawerOpen(true)
                }}
                handleClose={() => setDamageUnit(undefined)}
                areaConfigMap={areaConfigMap}
                createDamage={createDamage}
                inspection={scheduleDetail?.inspection}
                unitDamages={unitDamageMap[damageUnit?.id ?? -1]}
            />

            <MessageDrawer
                open={messageDrawerOpen}
                onClose={() => setMessageDrawerOpen(false)}
                onCreateMessage={() => {
                    if (workorderDetail?.workorder) {
                        incrementWorkorderMessageCount(
                            workorderDetail?.workorder,
                        )
                    }
                }}
                secondaryChannelId={workorderDetail?.workorder?.inv_ins_channel}
                secondaryTitle="From Inventory Inspection"
            />

            {selectedUnit && (
                <MessageDrawer
                    open={openUnitNotes}
                    onClose={() => {
                        if (workorderDetail?.workorder) {
                            setChannel(workorderDetail.workorder.channel)
                        }
                        setOpenUnitNotes(false)
                        setSelectedUnit(undefined)
                    }}
                    onCreateMessage={() => {
                        const newUnitList = unitList?.map((unit) => {
                            if (unit.id === selectedUnit.id) {
                                return {
                                    ...unit,
                                    unit_notes_msg_count:
                                        unit.unit_notes_msg_count + 1,
                                }
                            }
                            return unit
                        })
                        dispatch(setUnits(newUnitList ?? []))
                    }}
                    title={`Unit Notes ${selectedUnit.name}`}
                />
            )}

            <BulkEditSelectionList
                open={editMode}
                selectedWorkorders={editWorkorderSelection}
                workordersInFilter={workordersInFilter}
                vendorList={vendorList}
                handleClose={() => setEditMode(false)}
                removeWorkorder={(id) => {
                    const newEditSelection = {
                        ...editWorkorderSelection,
                    }
                    delete newEditSelection[id]
                    setEditWorkorderSelection(newEditSelection)
                }}
                setSelectedWorkorders={setEditWorkorderSelection}
                upStreamContractMap={upstreamContractMap}
                areaConfigMap={areaConfigMap}
                unitList={unitList ?? []}
                areaSelection={areaSelection}
                setAreaSelection={setAreaSelection}
            />

            {/* Modals */}
            {assignModalOpen && (
                <SchedulerAssignModal
                    open={assignModalOpen}
                    onClose={() => setAssignModalOpen(false)}
                    filteredTree={prunedTree}
                    serviceList={serviceList}
                    unitConfigMap={unitConfigMap}
                    unitWorkorderMap={unitWorkorderMap}
                    // TODO: EZTK-280
                    // areaLeaseMap={areaLeaseMap}
                    areaLeaseMap={{}}
                    schedule={scheduleDetail}
                    areaConfigMap={areaConfigMap}
                    vendorList={vendorList}
                    serviceAreaMap={serviceAreaMap}
                    createWorkorder={createWorkorder}
                    assignmentFinderSelection={assignmentFinderSelection}
                    isAreaInFilter={filterController.isAreaInFilter}
                    areaStatusTagMap={areaStatusTagMap}
                    areaStatusConfigList={
                        aptConfigController.areaStatusConfigList ?? []
                    }
                />
            )}

            <BudgetModal
                open={serviceBudgetModalOpen}
                onClose={() => setServiceBudgetModalOpen(false)}
                workorders={workorderList}
                serviceList={serviceList}
                scheduleDetail={scheduleDetail}
            />

            <ChangeOrderModal
                workorder={changeOrderModalOpen}
                serviceList={serviceList}
                changeOrderConfigList={
                    aptConfigController.changeOrderConfigList
                }
                onClose={() => setChangeOrderModalOpen(undefined)}
                areaConfigMap={areaConfigMap}
                vendorList={vendorList}
                schedule={scheduleDetail}
            />

            <ScheduleBlanketRoot
                open={analyticsModalOpen}
                theme={theme}
                rect={analyticRect}
                infrastructure={prunedTree.root}
                onClose={() => setAnalyticsModalOpen(false)}
                unitWorkorderMap={unitWorkorderMap}
                isWorkorderInFilter={filterController.isWorkorderInFilter}
                workorders={workorderList}
            />

            <KeyTrackingModal
                schedule={scheduleDetail}
                open={openKeyTracking}
                areaConfigMap={areaConfigMap}
                areaStatusTagList={areaStatusTagList}
                updateAreaStatusTag={editAreaStatusTag}
                handleClose={() => setOpenKeyTracking(false)}
            />

            <SmartScheduleModal
                open={smartSchedulerOpen}
                serviceList={serviceList}
                vendorList={vendorList}
                infrastructure={tree.root}
                scheduleController={scheduleController}
                onClose={() => setSmartSchedulerOpen(false)}
                reloadScheduleWorkorders={() => {
                    getWorkorderList({
                        params: { schedule: scheduleDetail.id },
                    })
                }}
            />
        </Container>
    )
}
