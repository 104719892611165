import { Address } from '../types'

export const convertAddressToParams = (address: Address) => {
    let params = ''
    if (address.address1) {
        params = params + address.address1.replace(/ /g, '_') + ','
    }
    if (address.city) {
        params = params + address.city.replace(/ /g, '_') + ','
    }
    if (address.state) {
        params = params + address.state.replace(/ /g, '_') + ','
    }
    if (address.zip_code) {
        params = params + address.zip_code.replace(/ /g, '_')
    }

    return params
}
