import * as React from 'react'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepConnector, {
    stepConnectorClasses,
} from '@mui/material/StepConnector'
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel'
import { StepIconProps } from '@mui/material/StepIcon'
import ContentPasteIcon from '@material-ui/icons/PeopleAlt'
import MapIcon from '@material-ui/icons/Map'
import PaintIcon from '@material-ui/icons/FormatPaint'
import CheckIcon from '@material-ui/icons/CheckCircle'

const RegisterStepLabel = styled(StepLabel)(() => ({
    [`& .${stepLabelClasses.label}`]: {
        [`&.${stepLabelClasses.completed}`]: {
            color: 'rgba(255, 255, 255, 0.3)',
        },
        [`&.${stepLabelClasses.active}`]: {
            color: 'white',
            fontWeight: 'bold',
            textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
        },
        color: 'white',
        fontWeight: 'bold',
        textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
    },
}))

const RegisterConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 27,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#00544F',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#00544F',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}))

const RegisterStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean }
}>(({ theme, ownerState }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: theme.palette.common.white,
    width: 60,
    height: 60,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    ...(ownerState.active && {
        backgroundColor: '#00544F',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.4)',
    }),
    ...(ownerState.completed && {
        backgroundColor: '#00544F',
    }),
}))

const RegisterStepIcon = (props: StepIconProps & { onClick?: () => void }) => {
    const { active, completed, className, onClick } = props

    const icons: { [index: string]: React.ReactElement } = {
        1: <ContentPasteIcon />,
        2: <MapIcon />,
        3: <PaintIcon />,
        4: <CheckIcon />,
    }

    return (
        <RegisterStepIconRoot
            ownerState={{ completed, active }}
            className={className}
            onClick={onClick}
        >
            {icons[String(props.icon)]}
        </RegisterStepIconRoot>
    )
}

const steps = ['Details', 'Service Area', 'Select Services', 'Agreement']

interface Props {
    activeStep: number
}

export const RegisterStepper = (props: Props) => {
    const { activeStep } = props

    return (
        <Stack sx={{ width: '70%' }} spacing={4}>
            <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<RegisterConnector />}
            >
                {steps.map((label, index) => (
                    <Step key={label} completed={index < activeStep}>
                        <RegisterStepLabel StepIconComponent={RegisterStepIcon}>
                            {label}
                        </RegisterStepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    )
}
