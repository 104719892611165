import React, { useState } from 'react'

import {
    Badge,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Theme,
    Tooltip,
} from '@material-ui/core'
import { ChatBubbleOutline, ReportProblem } from '@material-ui/icons'

import { Container } from '../../Container'
import { BaseProps } from '../types'

import { WorkorderStatusBadge } from '../../WorkorderStatusBadge'
import { TimelinePicker } from '../../TimelinePicker'
import { WorkorderResponse } from '../../../store'
import {
    getChangeOrderPrice,
    getServiceContractPrice,
    getTotalPriceExpense,
    getServiceImageDetails,
    DELETE_WORKORDERS,
    WorkSpaceUser,
} from '../../../models'
import NumberFormat from 'react-number-format'
import { hasPermission } from '../../../models/Users/services'
import { toast } from 'react-toastify'

interface Props extends BaseProps {
    workspaceUser?: WorkSpaceUser
    workorder?: WorkorderResponse
    showFinances?: boolean
    openMessageDrawer?: () => void
    handleClose: () => void
    onClickEdit: () => void
    deleteWorkorder: (workorder: WorkorderResponse) => Promise<void>
}

export const WorkorderDetailForm = (props: Props) => {
    const {
        workorder,
        theme,
        openMessageDrawer,
        showFinances,
        handleClose,
        onClickEdit,
        workspaceUser,
        deleteWorkorder,
    } = props

    if (workorder === undefined) {
        return null
    }

    const [openDialog, setOpenDialog] = useState(false)

    const startDate = workorder.start_date
        ? new Date(workorder.start_date)
        : undefined
    const endDate = workorder.end_date
        ? new Date(workorder.end_date)
        : undefined

    const priorityIcon =
        workorder.priority === 0 ? (
            <Tooltip title="Priority">
                <ReportProblem color="secondary" fontSize="large" />
            </Tooltip>
        ) : null

    const displayLocation =
        workorder.unit_name && workorder.folder_name && workorder.path

    const assignmentLabel =
        workorder.vendor_name ?? workorder.user_name ?? 'No one assigned'

    const serviceIcon = getServiceImageDetails(workorder.service_id ?? -1)
    let serviceComponent: JSX.Element | null = <span>No Service</span>
    if (serviceIcon.src !== null) {
        serviceComponent = (
            <Container>
                <img
                    src={serviceIcon.icon}
                    style={{
                        maxHeight: 25,
                        maxWidth: 27,
                        minHeight: 25,
                        minWidth: 27,
                        marginRight: theme.spacing(1),
                    }}
                />
                <span>{workorder.service_name ?? ''}</span>
            </Container>
        )
    }

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                ...theme.typography.subtitle1,
            }}
        >
            <Container
                style={{
                    padding: theme.spacing(1),
                }}
            >
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Workorder (#{workorder.id})
                </span>

                <div style={{ flex: 1 }} />

                <WorkorderStatusBadge workorder={workorder} theme={theme} />
            </Container>

            {/* Badges */}
            <Container
                style={{
                    marginTop: theme.spacing(2),
                    alignItems: 'center',
                    padding: theme.spacing(1),
                }}
            >
                <IconButton
                    style={{ marginRight: theme.spacing(2) }}
                    onClick={(e) => {
                        e.stopPropagation()
                        openMessageDrawer && openMessageDrawer()
                    }}
                >
                    <Badge
                        badgeContent={workorder.message_count}
                        color={
                            (workorder.unread_count ?? 0) > 0
                                ? 'primary'
                                : 'secondary'
                        }
                    >
                        <ChatBubbleOutline fontSize="large" />
                    </Badge>
                </IconButton>

                {priorityIcon}
            </Container>

            {/* Timeline */}
            <Container
                style={{ flexDirection: 'column', padding: theme.spacing(1) }}
            >
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        marginTop: theme.spacing(4),
                    }}
                >
                    Timeline
                </span>
                <TimelinePicker
                    startDate={startDate}
                    endDate={endDate}
                    disabled
                    onChangeStartDate={() => {}}
                    onChangeEndDate={() => {}}
                />
            </Container>

            {/* Location */}

            <Container
                style={{ flexDirection: 'column', padding: theme.spacing(1) }}
            >
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        marginTop: theme.spacing(4),
                    }}
                >
                    Location
                </span>
                <Container>
                    {displayLocation && (
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightLight,
                                color: theme.palette.grey[700],
                            }}
                        >
                            {workorder.path}
                            {workorder.folder_name}{' '}
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                    color: 'black',
                                }}
                            >
                                {workorder.unit_name}
                            </span>
                        </span>
                    )}
                </Container>
                <span style={{ fontWeight: theme.typography.fontWeightLight }}>
                    {workorder?.unit_config_name}
                </span>
            </Container>

            {/* Job Details */}
            <Container
                style={{ flexDirection: 'column', padding: theme.spacing(1) }}
            >
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        marginTop: theme.spacing(4),
                    }}
                >
                    Job Details
                </span>
                <span
                    style={{
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {assignmentLabel}
                </span>
                {serviceComponent}
            </Container>

            {/* Service Areas */}
            <Container
                style={{
                    flexDirection: 'column',
                    marginTop: theme.spacing(4),
                    padding: theme.spacing(1),
                }}
            >
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Service Areas
                </span>
                {workorder.service_area_list.map((sa) => {
                    return (
                        <Container key={`SERVICE-AREA-${sa.sa_id}`}>
                            <span>
                                {sa.area_name} {sa.area_label}
                            </span>
                        </Container>
                    )
                })}

                {workorder.service_area_list.length === 0 && (
                    <span style={{}}>No service areas </span>
                )}
            </Container>

            {/* Finances */}
            {showFinances && (
                <Container
                    style={{
                        flexDirection: 'column',
                        marginTop: theme.spacing(4),
                        padding: theme.spacing(1),
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        Finances
                    </span>

                    <PriceLabel
                        theme={theme}
                        label="Service Contract Price"
                        value={getServiceContractPrice(workorder)}
                    />

                    <PriceLabel
                        theme={theme}
                        label="Add Price"
                        value={workorder.add_price}
                    />

                    <PriceLabel
                        theme={theme}
                        label="Custom Price"
                        value={workorder.custom_price}
                    />

                    <PriceLabel
                        theme={theme}
                        label="Change Order Total"
                        value={getChangeOrderPrice(workorder)}
                    />

                    <PriceLabel
                        theme={theme}
                        label="Total"
                        value={getTotalPriceExpense(workorder)}
                    />
                </Container>
            )}

            <div style={{ flex: 1 }} />

            {/* Footer */}
            <Container
                style={{
                    padding: theme.spacing(2),
                    paddingTop: theme.spacing(3),
                    paddingBottom: theme.spacing(3),
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    justifyContent: 'space-between',
                }}
            >
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.primary.contrastText,
                        textTransform: 'none',
                        width: 125,
                    }}
                    onClick={handleClose}
                >
                    Close
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.primary.contrastText,
                        textTransform: 'none',
                        width: 125,
                    }}
                    disabled={!hasPermission(workspaceUser, DELETE_WORKORDERS)}
                    onClick={() => setOpenDialog(true)}
                >
                    Delete
                </Button>
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        textTransform: 'none',
                        width: 125,
                    }}
                    onClick={onClickEdit}
                >
                    Edit
                </Button>
            </Container>
            <Dialog open={openDialog} onClose={handleClose}>
                <DialogTitle id="alert-dialog-title">
                    Delete Action Item
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this Action Item?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => setOpenDialog(false)}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            deleteWorkorder(workorder).then(() => {
                                toast.success(
                                    'Action Item deleted successfully',
                                )
                                setOpenDialog(false)
                                handleClose()
                            })
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

interface PriceLabelProps {
    theme: Theme
    label: string
    value?: number | string | null
}

const PriceLabel = (props: PriceLabelProps) => {
    const { theme, value, label } = props

    let valueComponent = (
        <span
            style={{
                fontWeight: theme.typography.fontWeightBold,
            }}
        >
            N/A
        </span>
    )

    if (value !== null && value !== undefined) {
        valueComponent = (
            <NumberFormat
                style={{
                    fontWeight: theme.typography.fontWeightBold,
                }}
                displayType="text"
                value={value}
                prefix="$"
                thousandSeparator
                fixedDecimalScale
                decimalScale={2}
            />
        )
    }

    return (
        <Container
            style={{
                borderBottom: `1px solid ${theme.palette.grey[400]}`,
            }}
        >
            <span
                style={{
                    fontWeight: theme.typography.fontWeightLight,
                    color: theme.palette.grey[700],
                    width: 200,
                }}
            >
                {label}
            </span>
            {valueComponent}
        </Container>
    )
}
