import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    IconButton,
    Switch,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import {
    Add,
    FileCopy,
    KeyboardArrowDown,
    KeyboardArrowRight,
    PriorityHigh,
} from '@material-ui/icons'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Container, ScheduleReportPopOver } from '../../components'
import { axiosInstance } from '../../helpers'
import { useAppDispatch } from '../../hooks'
import { PreTurnConfigItem, generateUUID } from '../../models'
import {
    APARTMENT_PARAM,
    getPortfolioBudget,
    getPortfolioScheduleList,
    GetPortfolioScheduleListParams,
    GET_PORTFOLIO_SCHEDULE_LIST_REQUEST,
    RootState,
} from '../../store'
import {
    NameValueDict,
    PortfolioAnalyticsFilterRow,
} from './PortfolioAnalyticsFilterRow'
import { PortfolioScheduleList } from './PortfolioScheduleList'

interface Props {
    checkListItemConfigs: PreTurnConfigItem[]
}

export interface Filter {
    param?: keyof GetPortfolioScheduleListParams
    operatorOptions?: NameValueDict[]
    operatorParam?: keyof GetPortfolioScheduleListParams
    operatorValue?: string
    value?: string
    uuid: string
}

export const PortfolioScheduleTab = (props: Props) => {
    const { checkListItemConfigs } = props

    const theme = useTheme()
    const dispatch = useAppDispatch()

    const isPortfolioScheduleLoading = useSelector(
        (state: RootState) =>
            state.workorder.schedule.isLoading[
                GET_PORTFOLIO_SCHEDULE_LIST_REQUEST
            ],
    )

    const [filterOpen, setFilterOpen] = useState(false)
    const [filters, setFilters] = useState<Filter[]>([])
    const [filtersApplied, setFiltersApplied] = useState(true)
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
    const [showActiveSchedules, setShowActiveSchedules] = useState(false)

    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closePopover = () => {
        setAnchorEl(null)
    }

    const generateParams = () => {
        const params: GetPortfolioScheduleListParams = {}
        filters.forEach((filter) => {
            if (filter.value && filter.param) {
                let value: string | number = filter.value
                if (filter.param === APARTMENT_PARAM) {
                    value = Number(filter.value)
                }
                // using as because the only shared type between all keys of params is undefined,
                // without doing this typescript will complain because it doesnt know specifically what the type of
                // params[filter.param] should be becuase it doesnt know specifically what filter.param is
                // this doesn't modify the value so has no actual consequences
                params[filter.param] = (value as unknown) as undefined
                if (filter.operatorValue && filter.operatorParam) {
                    params[
                        filter.operatorParam
                    ] = (filter.operatorValue as unknown) as undefined
                }
            }
        })

        return params
    }

    const toggleFiltersOpen = () => {
        if (filters.length === 0 && !filterOpen) {
            setFilters([{ uuid: generateUUID() }])
        }
        setFilterOpen(!filterOpen)
    }

    const popOverOpen = Boolean(anchorEl)

    const handleSetFilters = (pushValue: Filter, remove?: boolean) => {
        const newFilters: Filter[] = []
        filters.forEach((filter) => {
            if (filter.uuid === pushValue.uuid) {
                if (!remove) {
                    newFilters.push(pushValue)
                }
            } else {
                newFilters.push(filter)
            }
        })

        setFilters(newFilters)
        setFiltersApplied(false)
    }

    return (
        <Container style={{ flexDirection: 'column', flex: 1 }} scrollY>
            <Accordion>
                <AccordionSummary
                    onClick={toggleFiltersOpen}
                    style={{
                        paddingBottom: theme.spacing(1),
                        justifyContent: 'center',
                    }}
                >
                    <IconButton
                        onClick={toggleFiltersOpen}
                        size="medium"
                        edge="start"
                        style={{ marginRight: theme.spacing(1) }}
                    >
                        {filterOpen ? (
                            <KeyboardArrowDown />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </IconButton>
                    <Container style={{ alignItems: 'center' }}>
                        <span
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            Filters ({filters.length})
                        </span>
                    </Container>
                    {!filtersApplied && (
                        <Tooltip title="You have unsaved filter changes">
                            <IconButton>
                                <PriorityHigh color="error" />
                            </IconButton>
                        </Tooltip>
                    )}
                    {isPortfolioScheduleLoading && (
                        <CircularProgress
                            size={50}
                            style={{ marginLeft: theme.spacing(1) }}
                        />
                    )}

                    <div style={{ flex: 1 }} />
                    <Container
                        style={{
                            fontSize: 16,
                            fontWeight: 500,
                            alignItems: 'center',
                        }}
                    >
                        All
                        <Switch
                            checked={showActiveSchedules}
                            color="primary"
                            onClick={(e) => {
                                e.stopPropagation()
                                setShowActiveSchedules(!showActiveSchedules)
                            }}
                        />
                        Active
                    </Container>
                    <Tooltip title="Reports">
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                openPopover(e)
                            }}
                        >
                            <FileCopy fontSize="large" />
                        </IconButton>
                    </Tooltip>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={(e) => {
                            e.stopPropagation()
                            setFilters([])
                            dispatch(getPortfolioBudget({}))
                            dispatch(
                                getPortfolioScheduleList({
                                    params: { page_size: 15 },
                                }),
                            ).then(() => {
                                setFiltersApplied(true)
                            })
                        }}
                        style={{
                            marginRight: theme.spacing(2),
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        Clear All Filters
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={(e) => {
                            e.stopPropagation()
                            const params: GetPortfolioScheduleListParams = generateParams()
                            dispatch(getPortfolioBudget({ params: params }))
                            dispatch(
                                getPortfolioScheduleList({
                                    params: { ...params, page_size: 15 },
                                }),
                            ).then(() => {
                                setFiltersApplied(true)
                            })
                        }}
                    >
                        Apply
                    </Button>
                    <Tooltip title="Add New Filter">
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                setFilters([
                                    ...filters,
                                    { uuid: generateUUID() },
                                ])
                            }}
                        >
                            <Add />
                        </IconButton>
                    </Tooltip>
                </AccordionSummary>
                <AccordionDetails
                    style={{
                        maxHeight: '20%',
                        flex: 1,
                        flexDirection: 'column',
                        overflowY: 'auto',
                    }}
                >
                    {filters.map((filter) => {
                        return (
                            <PortfolioAnalyticsFilterRow
                                key={`PORTFOLIO_ANALYTICS_FILTER_ROW_${filter.uuid}`}
                                filters={filters}
                                removeFilter={() => {
                                    handleSetFilters(filter, true)
                                }}
                                filterParam={filter.param}
                                setFilterParam={(
                                    param: keyof GetPortfolioScheduleListParams,
                                    operator?: NameValueDict[],
                                    operatorParam?: keyof GetPortfolioScheduleListParams,
                                ) => {
                                    handleSetFilters({
                                        ...filter,
                                        param: param,
                                        operatorOptions: operator,
                                        operatorParam: operatorParam,
                                        operatorValue: undefined,
                                        value: undefined,
                                    })
                                }}
                                filterOperatorOptions={filter.operatorOptions}
                                filterOperatorValue={filter.operatorValue}
                                setFilterOperatorValue={(
                                    operatorValue?: string,
                                ) => {
                                    handleSetFilters({
                                        ...filter,
                                        operatorValue: operatorValue,
                                    })
                                }}
                                filterValue={filter.value}
                                setFilterValue={(value?: string) => {
                                    handleSetFilters({
                                        ...filter,
                                        value: value,
                                    })
                                }}
                                checkListItemConfigs={checkListItemConfigs}
                            />
                        )
                    })}
                </AccordionDetails>
            </Accordion>
            <PortfolioScheduleList
                filterOpen={filterOpen}
                filterCount={filters.length}
                showActiveSchedules={showActiveSchedules}
            />
            <ScheduleReportPopOver
                open={popOverOpen}
                onClose={closePopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                reportItems={[
                    {
                        title: 'Schedule Report',
                        downloadLink: {
                            title: 'Download',
                            onClick: () => {
                                const params: GetPortfolioScheduleListParams = generateParams()
                                axiosInstance.get(
                                    'workorder/portfolio-schedule/create_excel_report/',
                                    { params },
                                )
                                toast.success(
                                    'You will recieve your report via email shortly',
                                )
                            },
                        },
                    },
                ]}
            />
        </Container>
    )
}
