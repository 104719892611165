import React, { useMemo } from 'react'
import { MatchedLease } from '../../../../../models'
import { useTheme } from '@material-ui/core'
import { Container } from '../../../../../components'
import { currencyFormatter } from '../../../../../helpers/CurrencyFormatter'

interface Props {
    matchedLeases: MatchedLease[]
}

export const LeaseAuditRentVariance = (props: Props) => {
    const { matchedLeases } = props

    const theme = useTheme()

    const totalRentRollRent = useMemo(() => {
        let total = 0
        matchedLeases.forEach((matchedLease) => {
            total = total + Number(matchedLease.rent_roll_lease.rent ?? 0)
        })
        return total
    }, [matchedLeases])

    const totalMatchedPDFRent = useMemo(() => {
        let total = 0
        matchedLeases.forEach((matchedLease) => {
            if (matchedLease.pdf_lease) {
                total = total + Number(matchedLease.pdf_lease.rent ?? 0)
            }
        })
        return total
    }, [matchedLeases])

    const rentVariance = totalRentRollRent - totalMatchedPDFRent

    const textStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[600],
    }
    const infoStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey[700],
    }

    return (
        <Container
            style={{
                alignItems: 'center',
                flex: 1,
                justifyContent: 'center',
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(1),
            }}
        >
            <Container
                style={{ flexDirection: 'column', alignItems: 'center' }}
            >
                <span style={{ ...textStyle }}>Rent Roll Rent</span>
                <span style={{ ...infoStyle }}>
                    {currencyFormatter.format(
                        Number(totalRentRollRent.toFixed(2)),
                    )}
                </span>
            </Container>
            <Container style={{ margin: theme.spacing(0, 2) }}>
                <span
                    style={{
                        ...theme.typography.h5,
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.grey[700],
                    }}
                >
                    -
                </span>
            </Container>
            <Container
                style={{ flexDirection: 'column', alignItems: 'center' }}
            >
                <span style={{ ...textStyle }}>Matched PDF Rent</span>
                <span style={{ ...infoStyle }}>
                    {currencyFormatter.format(
                        Number(totalMatchedPDFRent.toFixed(2)),
                    )}
                </span>
            </Container>
            <Container style={{ margin: theme.spacing(0, 2) }}>
                <span
                    style={{
                        ...theme.typography.h5,
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.grey[700],
                    }}
                >
                    =
                </span>
            </Container>
            <Container
                style={{ flexDirection: 'column', alignItems: 'center' }}
            >
                <span style={{ ...textStyle }}>Rent Variance</span>
                {rentVariance >= 0 ? (
                    <span style={{ ...infoStyle }}>
                        {currencyFormatter.format(
                            Number(rentVariance.toFixed(2)),
                        )}
                    </span>
                ) : (
                    <span style={{ ...infoStyle }}>
                        -
                        {currencyFormatter.format(
                            Number(Math.abs(rentVariance).toFixed(2)),
                        )}
                    </span>
                )}
            </Container>
        </Container>
    )
}
