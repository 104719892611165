import {
    Button,
    CircularProgress,
    MenuItem,
    TextField,
    Theme,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../hooks'
import { ListVendor, VendorServiceAnalytic } from '../../models'
import { getVendorAnalyticsRequest, RootState } from '../../store'
import { Container, SideDrawer } from '../../components'
import { ReactComponent as ApprovedFullShield } from '../../assets/Approved_Full_Shield.svg'
import { ReactComponent as ApprovedOutlinedShield } from '../../assets/Approved_Outlined_Shield.svg'
import { ReactComponent as CompletedCheck } from '../../assets/Completed_Filled.svg'
import { ReactComponent as GoBackOrange } from '../../assets/Go_Back_Orange.svg'
import { ReactComponent as GoBackRed } from '../../assets/Go_Back_Red.svg'
import { ReactComponent as GoBackYellow } from '../../assets/Go_Back_Yellow.svg'
import { WorkforceFormState } from './Workforce'

interface Props {
    formState: WorkforceFormState
    vendorList: ListVendor[]
    width: number
    handleClose: () => void
}

export const VendorAnalyticsDrawer = (props: Props) => {
    const { vendorList, handleClose, formState, width } = props

    const { open, vendorId } = formState.drawer.vendorAnalyticsDrawer

    const vendor = vendorList.find((vnd) => vnd.id === vendorId)

    const theme = useTheme()
    const dispatch = useAppDispatch()

    const analytics = useSelector(
        (state: RootState) => state.analytics.vendorAnalytics,
    )

    const [
        selectedService,
        setSelectedService,
    ] = useState<VendorServiceAnalytic>()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (vendor) {
            const vendorAnalyticsPromise = dispatch(
                getVendorAnalyticsRequest({ params: { vendor: vendor.id } }),
            )
            const later = (delay: number) => {
                return new Promise(function (resolve) {
                    setTimeout(resolve, delay)
                })
            }
            Promise.all([vendorAnalyticsPromise, later(500)]).then((res) => {
                // if statement to make sure loading state is correct if user closes drawer before promise succeeds
                if (open) {
                    setLoading(false)
                }
            })
        }
    }, [vendor])

    useEffect(() => {
        if (analytics && analytics.all_time.length > 0) {
            setSelectedService(analytics.all_time[0])
        } else {
            setSelectedService(undefined)
        }
    }, [analytics])

    const APPROVED = '#93D7E1'
    const APPROVED_ONE = '#B8BEFF'
    const COMPLETE = '#ACE5CE'
    const GO_BACK = '#FFE29B'
    const GO_BACK_2ND = '#FFAB81'
    const GO_BACK_MAX = '#FDCCC9'

    const close = () => {
        handleClose()
        setSelectedService(undefined)
        setLoading(true)
    }

    return (
        <SideDrawer
            open={open}
            handleClose={close}
            title={'Analytics'}
            width={width}
        >
            {loading ? (
                <Container
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                    }}
                >
                    <CircularProgress size={100} />
                </Container>
            ) : (
                <Container
                    direction="column"
                    style={{ margin: theme.spacing(0, 5, 0, 5), flex: 1 }}
                >
                    <Container>
                        <span
                            style={{
                                color: theme.palette.primary.dark,
                                fontSize: '15px',
                            }}
                        >
                            {vendor?.name}
                        </span>

                        <span
                            style={{
                                margin: theme.spacing(0, 0, 0, 1),
                                fontSize: '12px',
                                paddingTop: theme.spacing(0.5),
                            }}
                        >
                            All Time
                        </span>
                    </Container>

                    {(analytics?.all_time.length ?? -1) > 0 ? (
                        <Container
                            style={{ margin: theme.spacing(3, 0, 0, 0) }}
                        >
                            <TextField
                                variant="outlined"
                                value={selectedService?.id}
                                label="Select Service"
                                size="small"
                                onChange={(e) => {
                                    setSelectedService(
                                        analytics?.all_time.find((service) => {
                                            return (
                                                service.id ===
                                                Number(e.target.value)
                                            )
                                        }),
                                    )
                                }}
                                style={{ flex: 1 }}
                                select
                                fullWidth
                            >
                                {analytics?.all_time.map((service) => {
                                    return (
                                        <MenuItem
                                            value={service.id}
                                            key={service.id}
                                        >
                                            {service.name}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </Container>
                    ) : (
                        <Container
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ ...theme.typography.h3 }}>
                                {" This vendor's not done any work yet."}
                            </span>
                        </Container>
                    )}

                    {selectedService && (
                        <Container direction="column">
                            <AnalyticBox
                                borderColor={APPROVED}
                                icon={<ApprovedFullShield />}
                                numerator={selectedService.approved_count}
                                denominator={selectedService.assign_count}
                                theme={theme}
                                title="Approved"
                            />

                            <AnalyticBox
                                borderColor={APPROVED_ONE}
                                icon={<ApprovedOutlinedShield />}
                                numerator={selectedService.app_1st_attempt}
                                denominator={selectedService.assign_count}
                                theme={theme}
                                title="Approved (1)"
                            />

                            <AnalyticBox
                                borderColor={COMPLETE}
                                icon={<CompletedCheck />}
                                numerator={selectedService.complete_count}
                                denominator={selectedService.assign_count}
                                theme={theme}
                                title="Completed"
                            />

                            <AnalyticBox
                                borderColor={GO_BACK}
                                icon={<GoBackYellow />}
                                numerator={selectedService.go_back_count}
                                denominator={selectedService.assign_count}
                                theme={theme}
                                title="Go Back"
                            />

                            <AnalyticBox
                                borderColor={GO_BACK_2ND}
                                icon={<GoBackOrange />}
                                numerator={selectedService.go_back_2nd_count}
                                denominator={selectedService.assign_count}
                                theme={theme}
                                title="Go Back (2nd)"
                            />

                            <AnalyticBox
                                borderColor={GO_BACK_MAX}
                                icon={<GoBackRed />}
                                numerator={selectedService.go_back_max}
                                denominator={selectedService.assign_count}
                                theme={theme}
                                title="Go Back (Max)"
                            />
                        </Container>
                    )}
                    <span style={{ flex: 1 }} />
                </Container>
            )}
            <Button
                color="secondary"
                variant="outlined"
                onClick={close}
                style={{ margin: theme.spacing(0, 2, 5, 2) }}
            >
                Close
            </Button>
        </SideDrawer>
    )
}

const AnalyticBox: React.FC<{
    borderColor: string
    icon: JSX.Element
    numerator: number
    denominator: number
    theme: Theme
    title: string
}> = ({ borderColor, numerator, denominator, theme, icon, title }) => {
    const iconStyle: React.CSSProperties = {
        flex: 2 / 10,
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(1, 0, 1, 1),
    }
    const analyticStyle: React.CSSProperties = {
        flex: 1,
        border: `1px solid `,
        borderRadius: '5px',
        marginTop: theme.spacing(3),
    }

    const serviceInfoStyle: React.CSSProperties = {
        flex: 4 / 10,
        fontWeight: 400,
        fontSize: '14px',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: theme.spacing(3),
    }

    const percentageStyle: React.CSSProperties = {
        flex: 4 / 10,
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 700,
        fontSize: '15px',
    }

    return (
        <Container
            style={{
                ...analyticStyle,
                borderColor: borderColor,
            }}
        >
            <Container style={{ ...iconStyle }}>{icon}</Container>
            <Container style={{ ...serviceInfoStyle }}>
                <span>{title}</span>
                <span>
                    {numerator}/{denominator}
                </span>
            </Container>
            <Container style={percentageStyle}>
                {((100 * numerator) / denominator).toFixed(2)}%
            </Container>
        </Container>
    )
}
