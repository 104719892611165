import React, { useEffect, useState } from 'react'
import { ButtonPopover, Container } from '../../components'
import { axiosInstance, toMMDDYYYY } from '../../helpers'
import { toast } from 'react-toastify'
import { AreaConfig, InventoryConfig, InventoryGroup } from '../../models'
import {
    Button,
    CircularProgress,
    Divider,
    Modal,
    Slide,
    Paper,
    useTheme,
    TextField,
    Tooltip,
    IconButton,
} from '@material-ui/core'
import { useInfrastructureConfigStyles } from './useInfrastructureConfigStyles'
import { InventoryGroupForm } from './InventoryGroupForm'
import { usePopover } from '../../hooks/usePopover'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { InventoryGroupRequest, useInventoryGroup } from '../../hooks'

interface Props {
    inventoryConfigs?: InventoryConfig[]
    areaConfigs?: AreaConfig[]
}

export const InventoryGroupTabPanel = (props: Props) => {
    const {
        inventoryGroups,
        createInventoryGroup,
        updateInventoryGroup,
        deleteInventoryGroup,
    } = useInventoryGroup()

    return (
        <Container style={{ flex: 1 }}>
            {inventoryGroups === null ? (
                <Container
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress size={100} />
                </Container>
            ) : (
                <Content
                    {...props}
                    inventoryGroups={inventoryGroups}
                    createInventoryGroup={createInventoryGroup}
                    updateInventoryGroup={updateInventoryGroup}
                    deleteInventoryGroup={deleteInventoryGroup}
                />
            )}
        </Container>
    )
}

interface ContentProps extends Props {
    inventoryGroups: InventoryGroup[]
    createInventoryGroup: (request: InventoryGroupRequest) => Promise<void>
    updateInventoryGroup: (
        request: InventoryGroupRequest,
        groupId: number,
    ) => Promise<void>
    deleteInventoryGroup: (inventoryGroup: InventoryGroup) => Promise<void>
}

const Content = (props: ContentProps) => {
    const theme = useTheme()

    const {
        inventoryGroups,
        inventoryConfigs,
        createInventoryGroup,
        updateInventoryGroup,
        deleteInventoryGroup,
    } = props
    const { anchorEl, isOpen, handleClose, handleOpen } = usePopover()

    const [modalState, setModalState] = useState<ModalState>({
        open: false,
        group: null,
    })

    const styles = useInfrastructureConfigStyles()

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                ...styles.columnStyle,
                ...styles.panelBorder,
            }}
        >
            {/* Header */}
            <Container style={{ padding: theme.spacing(1) }}>
                <div style={{ flex: 1 }} />
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                    }}
                    onClick={() => setModalState({ open: true, group: null })}
                >
                    Create New Group
                </Button>
            </Container>

            <Divider />

            <Container style={{ flexWrap: 'wrap' }}>
                {inventoryGroups.map((group) => {
                    const createdDate = new Date(group.created_date)
                    const dateLabel = toMMDDYYYY(createdDate)

                    return (
                        <Container
                            key={`GROUP_${group.id}`}
                            style={{
                                padding: theme.spacing(2),
                                border: `1px solid ${theme.palette.grey[700]}`,
                                borderRadius: theme.shape.borderRadius,
                                width: 200,
                                margin: theme.spacing(1),
                            }}
                        >
                            <Container
                                style={{ flex: 1, flexDirection: 'column' }}
                            >
                                <Tooltip
                                    title={`${group.inventory_configs.length} Inventory configs`}
                                >
                                    <span
                                        style={{
                                            ...theme.typography.body1,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {group.name} (
                                        {group.inventory_configs.length})
                                    </span>
                                </Tooltip>

                                <span
                                    style={{
                                        ...theme.typography.caption,
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                    }}
                                >
                                    Created {dateLabel}
                                </span>
                            </Container>

                            <Container>
                                <IconButton
                                    onClick={(e) => {
                                        setModalState((prev) => {
                                            return {
                                                ...prev,
                                                group: group,
                                            }
                                        })
                                        handleOpen(e)
                                    }}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            </Container>
                        </Container>
                    )
                })}
            </Container>

            <Modal
                open={modalState.open}
                onClose={() => setModalState({ open: false, group: null })}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="up" in={modalState.open}>
                    <Paper
                        style={{
                            display: 'flex',
                        }}
                    >
                        <InventoryGroupForm
                            inventoryConfigs={inventoryConfigs}
                            handleSave={async (req) => {
                                if (modalState.group) {
                                    await updateInventoryGroup(
                                        req,
                                        modalState.group!.id,
                                    )
                                } else {
                                    await createInventoryGroup(req)
                                }

                                setModalState({ open: false, group: null })
                            }}
                            group={modalState.group}
                            handleClose={() =>
                                setModalState({ open: false, group: null })
                            }
                        />
                    </Paper>
                </Slide>
            </Modal>

            <ButtonPopover
                options={[
                    {
                        title: 'Edit',
                        onClick: () => {
                            handleClose()
                            setModalState((prev) => {
                                return {
                                    ...prev,
                                    open: true,
                                }
                            })
                        },
                        color: 'secondary',
                    },
                    {
                        title: 'Delete',
                        onClick: () => {
                            handleClose()
                            if (confirm('Are you sure?')) {
                                if (modalState.group) {
                                    deleteInventoryGroup(modalState.group)
                                }
                            }
                        },
                        color: 'danger',
                    },
                ]}
                open={isOpen}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            />
        </Container>
    )
}

interface ModalState {
    open: boolean
    group: InventoryGroup | null
}
