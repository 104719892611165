import React, { useState } from 'react'
import { Grid, useTheme } from '@material-ui/core'
import { CustomStatus, InventoryDetail } from '../../models'
import { AvatarCircle, Container, StatusBadge } from '../../components'
import { getInitials, toMMDDYYYY, toUiTime } from '../../helpers'

interface Props {
    inventory: InventoryDetail
}

export const InventoryRow = (props: Props) => {
    const { inventory } = props

    const theme = useTheme()

    const [expanded, setExpanded] = useState(false)

    const containerStyle: React.CSSProperties = {
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(1),
        cursor: 'pointer',
    }

    let latestDate: Date | null = null
    let latestInspectionName = ''
    let inspectedBy = 'No Activity'
    let latestStatus: any = null

    inventory.inspection_history.forEach((invIns) => {
        invIns.transition_logs.forEach((invInsLog) => {
            const logDate = new Date(invInsLog.created_date)
            if (latestDate === null || latestDate < logDate) {
                latestDate = logDate
                inspectedBy = invInsLog.user.name
                latestStatus = invInsLog.to_status
                latestInspectionName = invIns.inspection.name
            }
        })

        // It is possible that this inventory inspection was not changed
        // while the unit inspection was marked complete.  If the unit inspection
        // was marked complete we should use that transition log to mark the time / user
        // TODO: OS-136
        // if (latestDate === null) {
        //     invIns.unit_inspection?.transition_logs?.forEach((unitInsLog) => {
        //         const logDate = new Date(unitInsLog.created_date)
        //         if (latestDate === null || latestDate < logDate) {
        //             latestDate = logDate
        //             inspectedBy = unitInsLog.user.name
        //             latestStatus = invIns.status
        //             latestInspectionName = invIns.inspection.name
        //         }
        //     })
        // }
    })

    return (
        <Grid
            container
            onClick={() => setExpanded(!expanded)}
            style={{ margin: theme.spacing(1, 0) }}
        >
            <Grid
                container
                item
                style={{
                    ...containerStyle,
                    borderRadius: theme.shape.borderRadius,
                    border: `2px solid ${theme.palette.grey[900]}`,
                    alignItems: 'center',
                }}
            >
                <Grid item container xs={8} style={{ alignItems: 'center' }}>
                    <AvatarCircle
                        image={inventory.inventory_config.picture}
                        text={getInitials(inventory.inventory_config.name)}
                    />
                    <Container
                        style={{
                            flexDirection: 'column',
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        <Container style={{ alignItems: 'center' }}>
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {inventory.inventory_config.name}
                            </span>
                            <span
                                style={{
                                    ...theme.typography.subtitle2,
                                    fontWeight:
                                        theme.typography.fontWeightLight,
                                    marginLeft: theme.spacing(1),
                                }}
                            >
                                (#{inventory.id})
                            </span>
                        </Container>

                        <span
                            style={{
                                ...theme.typography.subtitle2,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {inventory.inventory_config.sku}
                        </span>
                    </Container>
                </Grid>
                <Grid item xs={4} container>
                    <Container style={{ flex: 1 }}>
                        <Container
                            style={{
                                alignItems: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.subtitle2,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {latestInspectionName}
                            </span>

                            {latestStatus !== undefined &&
                                latestStatus !== null && (
                                    <StatusBadge
                                        text={latestStatus.name}
                                        customStyle={{
                                            container: {
                                                backgroundColor:
                                                    latestStatus.color,
                                                margin: theme.spacing(0, 1),
                                            },
                                            text: {
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                            },
                                        }}
                                    />
                                )}
                        </Container>

                        <div style={{ flex: 1 }} />
                        <Container
                            style={{
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.subtitle2,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {inspectedBy}
                            </span>

                            <span
                                style={{
                                    ...theme.typography.subtitle2,
                                    fontWeight:
                                        theme.typography.fontWeightLight,
                                }}
                            >
                                {toMMDDYYYY(latestDate ?? undefined)}{' '}
                                {toUiTime(latestDate ?? undefined)}
                            </span>
                        </Container>
                    </Container>
                </Grid>
            </Grid>

            {expanded && (
                <Grid
                    container
                    style={{
                        ...containerStyle,
                        padding: 0,
                        margin: theme.spacing(0, 2, 0, 4),
                        borderBottomLeftRadius: theme.shape.borderRadius,
                        borderBottomRightRadius: theme.shape.borderRadius,
                        border: `1px solid ${theme.palette.grey[900]}`,
                        borderTop: 0,
                    }}
                >
                    {inventory.inspection_history.map((ins, idx) => {
                        const isLastItem =
                            idx === inventory.inspection_history.length - 1

                        let latestDate: Date | null = null
                        let inspectedBy = 'No Activity'
                        let latestStatus: CustomStatus = ins.status

                        // Look for
                        ins.transition_logs.forEach((invInsLog) => {
                            const logDate = new Date(invInsLog.created_date)
                            if (latestDate === null || latestDate < logDate) {
                                latestDate = logDate
                                inspectedBy = invInsLog.user.name
                                latestStatus = invInsLog.to_status
                            }
                        })

                        // It is possible that this inventory inspection was not changed
                        // while the unit inspection was marked complete.  If the unit inspection
                        // was marked complete we should use that transition log to mark the time / user
                        // TODO: OS-136
                        // if (latestDate === null) {
                        //     ins.unit_inspection?.transition_logs?.forEach(
                        //         (unitInsLog) => {
                        //             const logDate = new Date(
                        //                 unitInsLog.created_date,
                        //             )
                        //             if (
                        //                 latestDate === null ||
                        //                 latestDate < logDate
                        //             ) {
                        //                 latestDate = logDate
                        //                 inspectedBy = unitInsLog.user.name
                        //             }
                        //         },
                        //     )
                        // }

                        return (
                            <Grid
                                key={`INVENTORY_INS_HISTORY_${ins.id}`}
                                container
                                style={{
                                    padding: theme.spacing(1),
                                    alignItems: 'center',
                                    borderBottom: !isLastItem
                                        ? `1px solid ${theme.palette.grey[900]}`
                                        : undefined,
                                }}
                            >
                                <span
                                    style={{
                                        ...theme.typography.subtitle2,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    {ins.inspection.name}
                                </span>

                                <StatusBadge
                                    text={latestStatus.name}
                                    customStyle={{
                                        container: {
                                            backgroundColor: ins.status.color,
                                            margin: theme.spacing(0, 1),
                                        },
                                        text: {
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        },
                                    }}
                                />

                                <div style={{ flex: 1 }} />

                                <Container
                                    style={{
                                        flexDirection: 'column',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <span
                                        style={{
                                            ...theme.typography.subtitle2,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {inspectedBy}
                                    </span>

                                    <span
                                        style={{
                                            ...theme.typography.subtitle2,
                                            fontWeight:
                                                theme.typography
                                                    .fontWeightLight,
                                        }}
                                    >
                                        {toMMDDYYYY(latestDate ?? undefined)}{' '}
                                        {toUiTime(latestDate ?? undefined)}
                                    </span>
                                </Container>
                            </Grid>
                        )
                    })}
                </Grid>
            )}
        </Grid>
    )
}
