import { Card, useTheme } from '@material-ui/core'
import { Pagination, Rating } from '@material-ui/lab'
import React, { useState } from 'react'
import { PieChart } from 'react-minimal-pie-chart'
import { Container, EllipsisSpan } from '../../components'
import { axiosInstance } from '../../helpers'
import {
    EndOfTurnReport,
    EndOfTurnReportUnitConfigData,
    ListVendor,
    RatingInterface,
    Schedule,
    VendorScheduleRating,
} from '../../models'

interface Props {
    vendor: ListVendor
    vendorScheduleRating?: RatingInterface
    schedule?: Schedule
    updateVendorScheduleRatings: (
        vendorId: number,
        vendorScheduleRating: VendorScheduleRating,
    ) => void
    reports: EndOfTurnReport[]
}

const _ArchivedScheduleVendorCard = (props: Props) => {
    const {
        vendor,
        vendorScheduleRating,
        schedule,
        updateVendorScheduleRatings,
        reports,
    } = props

    const theme = useTheme()

    let professionalism = 0
    let workQuality = 0
    if (vendorScheduleRating) {
        professionalism = vendorScheduleRating.professionalism
        workQuality = vendorScheduleRating.work_quality
    }

    const textStyle: React.CSSProperties = {
        fontWeight: theme.typography.fontWeightLight,
        marginRight: theme.spacing(1),
    }
    const colorKeyStyle: React.CSSProperties = {
        height: 18,
        width: 18,
        borderRadius: theme.shape.borderRadius,
        marginRight: theme.spacing(1),
    }

    const [page, setPage] = useState(1)
    const vendorRated = professionalism > 0 && workQuality > 0
    const key = `VENDOR_RATING_COMPONENT_${vendor.id}`

    const pageComponents: JSX.Element[] = []

    const totals: EndOfTurnReportUnitConfigData = {
        complete_count: 0,
        assigned_count: 0,
        go_back_count: 0,
        double_go_back: 0,
        go_back_max: 0,
        approved_count: 0,
    }

    const SharedAnalytics = (data: EndOfTurnReportUnitConfigData) => {
        return (
            <Container style={{ flexDirection: 'column' }}>
                <Container
                    style={{
                        marginRight: theme.spacing(1),
                        marginTop: theme.spacing(1),
                    }}
                >
                    <span style={textStyle}>Total: </span>
                    <span>{data.assigned_count}</span>
                </Container>
                <Container style={{ marginRight: theme.spacing(1) }}>
                    <span style={textStyle}>Complete Count: </span>
                    <span>{data.complete_count}</span>
                </Container>
                <Container style={{ marginRight: theme.spacing(1) }}>
                    <span style={textStyle}>Approved Count: </span>
                    <span>{data.approved_count}</span>
                </Container>
                <Container
                    style={{
                        marginRight: theme.spacing(1),
                        marginTop: theme.spacing(1),
                    }}
                >
                    <div
                        style={{
                            ...colorKeyStyle,
                            backgroundColor: theme.palette.primary.main,
                        }}
                    />
                    <span style={textStyle}>Approved First Time: </span>
                    <span>{data.approved_count - data.go_back_count}</span>
                </Container>
                <Container style={{ marginRight: theme.spacing(1) }}>
                    <div
                        style={{
                            ...colorKeyStyle,
                            backgroundColor: theme.palette.secondary.main,
                        }}
                    />
                    <span style={textStyle}>Go Backs: </span>
                    <span>{data.go_back_count}</span>
                </Container>
                <Container style={{ marginRight: theme.spacing(1) }}>
                    <div
                        style={{
                            ...colorKeyStyle,
                            backgroundColor: theme.palette.error.main,
                        }}
                    />
                    <span style={textStyle}>Double Go Backs: </span>
                    <span>{data.double_go_back}</span>
                </Container>
                <Container style={{ marginRight: theme.spacing(1) }}>
                    <div
                        style={{
                            ...colorKeyStyle,
                            backgroundColor: theme.palette.warning.main,
                        }}
                    />
                    <span style={textStyle}>3+ Go Backs: </span>
                    <span>{data.go_back_max}</span>
                </Container>
                <PieChart
                    data={[
                        {
                            title: 'Approved 1st',
                            value: data.approved_count - data.go_back_count,
                            color: theme.palette.primary.main,
                        },
                        {
                            title: 'Go Back',
                            value: data.go_back_count,
                            color: theme.palette.secondary.main,
                        },
                        {
                            title: 'Double Go Back',
                            value: data.double_go_back,
                            color: theme.palette.error.main,
                        },
                        {
                            title: '3+ Go Backs',
                            value: data.go_back_max,
                            color: theme.palette.warning.main,
                        },
                    ]}
                    style={{
                        marginTop: theme.spacing(1),
                        marginBottom: theme.spacing(1),
                    }}
                />
            </Container>
        )
    }

    reports.forEach((report) => {
        let goBacks = 0
        let doubleGoBacks = 0
        let goBackMax = 0
        let assignedCount = 0
        let completeCount = 0
        let approvedCount = 0

        report.unit_config_data.forEach((ucData) => {
            goBacks += ucData.go_back_count
            goBackMax += ucData.go_back_max
            doubleGoBacks += ucData.double_go_back
            assignedCount += ucData.assigned_count
            completeCount += ucData.complete_count
            approvedCount += ucData.approved_count
        })

        totals.approved_count += approvedCount
        totals.assigned_count += assignedCount
        totals.complete_count += completeCount
        totals.go_back_count += goBacks
        totals.double_go_back += doubleGoBacks
        totals.go_back_max += goBackMax

        const data: EndOfTurnReportUnitConfigData = {
            complete_count: completeCount,
            assigned_count: assignedCount,
            go_back_count: goBacks,
            approved_count: approvedCount,
            double_go_back: doubleGoBacks,
            go_back_max: goBackMax,
        }

        const newPage = (
            <Container style={{ flexDirection: 'column' }}>
                <span
                    style={{
                        ...theme.typography.body1,
                        marginBottom: theme.spacing(1),
                    }}
                >
                    {report.service.name}
                </span>
                <Container style={{ marginRight: theme.spacing(1) }}>
                    <span style={textStyle}>Avg. Assignments/Day: </span>
                    <span>{report.averave_assignments_in_day}</span>
                </Container>
                <Container style={{ marginRight: theme.spacing(1) }}>
                    <span style={textStyle}>Max Assignments/Day: </span>
                    <span>{report.max_assignments_in_day}</span>
                </Container>
                <Container style={{ marginRight: theme.spacing(1) }}>
                    <span style={textStyle}>Avg. Complete/Day: </span>
                    <span>{report.average_complete_per_day}</span>
                </Container>
                <Container style={{ marginRight: theme.spacing(1) }}>
                    <span style={textStyle}>Max Complete Per/Day: </span>
                    <span>{report.max_complete_per_day}</span>
                </Container>
                {SharedAnalytics(data)}
            </Container>
        )

        pageComponents.push(newPage)
    })

    const updateVSR = (
        professionalism?: number | null,
        work_quality?: number | null,
    ) => {
        interface BodyType {
            vendor: number
            schedule?: number
            professionalism?: number
            work_quality?: number
        }
        const body: BodyType = {
            vendor: vendor.id,
            schedule: schedule?.id,
        }
        if (professionalism) {
            body.professionalism = professionalism
        }
        if (work_quality) {
            body.work_quality = work_quality
        }
        axiosInstance
            .post(`company/vendor_schedule_rating/create_or_update/`, body)
            .then((res) => {
                updateVendorScheduleRatings(vendor.id, res.data)
            })
    }

    const pageOne = (
        <Container style={{ flexDirection: 'column' }}>
            <Container style={{ flexDirection: 'column', flex: 1 }}>
                <span style={{ ...theme.typography.body1 }}>
                    Professionalism
                </span>
                <Rating
                    disabled={professionalism > 0}
                    precision={0.5}
                    name={`${key}_PROFESSIONALISM_RATING`}
                    value={professionalism}
                    onChange={(event, newValue) => {
                        updateVSR(newValue)
                    }}
                    style={{
                        marginBottom: theme.spacing(1),
                    }}
                />
                <span style={{ ...theme.typography.body1 }}>
                    Quality Of Work
                </span>
                <Rating
                    disabled={workQuality > 0}
                    precision={0.5}
                    name={`${key}_QUALITY_RATING`}
                    value={workQuality}
                    onChange={(event, newValue) => {
                        updateVSR(undefined, newValue)
                    }}
                />
            </Container>
            {vendorRated ? (
                <Container>{SharedAnalytics(totals)}</Container>
            ) : (
                <Container style={{ flex: 1, marginTop: theme.spacing(1) }}>
                    {' '}
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        Please rate your vendor to see analytics
                    </span>
                </Container>
            )}
        </Container>
    )

    pageComponents.unshift(pageOne)

    const currentCompenent = pageComponents[page - 1]

    return (
        <Card
            style={{
                minHeight: 600,
                maxHeight: 600,
                border: `2px solid ${theme.palette.primary.main}`,
                boxShadow: `2px 2px 6px ${theme.palette.grey[500]}`,
                padding: theme.spacing(1),
                margin: theme.spacing(1),
                minWidth: 250,
                maxWidth: 250,
                flex: 1,
                flexDirection: 'column',
            }}
            component={Container}
        >
            <EllipsisSpan
                style={{
                    ...theme.typography.h6,
                    marginBottom: theme.spacing(1),
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                }}
            >
                {vendor.name}
            </EllipsisSpan>
            {currentCompenent}
            <div style={{ flex: 1 }} />
            <Container style={{ justifyContent: 'center' }}>
                <Pagination
                    page={page}
                    onChange={(e, value) => {
                        setPage(value)
                    }}
                    count={pageComponents.length}
                    disabled={!vendorRated}
                    size={'small'}
                    variant={'outlined'}
                    siblingCount={0}
                />
            </Container>
        </Card>
    )
}

export const ArchivedScheduleVendorCard = React.memo(
    _ArchivedScheduleVendorCard,
)
