import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { APARTMENT, axiosInstance, Routes } from '../../helpers'

import { useStyles } from '../../styles'
import { useTheme } from '@material-ui/core/styles'

// components
import {
    CalendarTodayOutlined,
    AssignmentTurnedInOutlined,
    People,
    StarBorderOutlined,
    Build,
    ReceiptOutlined,
    BarChart,
    Apartment,
    FormatListNumbered,
    MeetingRoom,
    ThumbsUpDown,
    Description,
    Link,
    Receipt,
} from '@material-ui/icons'
import { Container } from '../../components'
import { useService, useUser } from '../../hooks'

import { hasPermission } from '../../models/Users/services'
import {
    Bid,
    ListVendor,
    PlanStatus,
    SEE_ACTION_ITEMS,
    SEE_INSPECTION,
    VIEW_INVOICE,
    VIEW_NATIONAL_VENDOR_LIST,
} from '../../models'
import { ReactComponent as Monitor } from '../../assets/icons/monitor.svg'
import { Badge } from '@material-ui/core'
import { useServiceContract } from '../../hooks/useServiceContract'
import { VendorStepModal } from './VendorStepModal'

const COMPLETE = 'COMPLETE'

export const Home = () => {
    const theme = useTheme()
    const classes = useStyles()
    const { workspaceUser } = useUser()
    const [vendorPlan, setVendorPlan] = useState(false)
    const [open, setOpen] = useState(false)
    const [vendor, setVendor] = useState<ListVendor | undefined>()

    const { getServiceList } = useService()

    const linkStyle: React.CSSProperties = {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[900],
        flexDirection: 'column',
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        padding: theme.spacing(1),
    }

    const subheaderStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightBold,
        marginBottom: theme.spacing(1),
    }

    const linkTextStyle: React.CSSProperties = {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightMedium,
    }

    const workspaceType =
        workspaceUser?.active_workspace.company_type ?? 'APARTMENT'

    useEffect(() => {
        if (workspaceUser?.active_workspace.company_type === 'VENDOR') {
            axiosInstance
                .get(`company/vendor/${workspaceUser?.active_workspace.id}`)
                .then((res) => {
                    if (res.data.plan == PlanStatus.PREMIUM) {
                        setVendorPlan(true)
                    } else {
                        setVendorPlan(false)
                    }
                    setVendor(res.data)
                    if (res.data.step !== COMPLETE) {
                        setOpen(true)
                    }
                })
            if (vendor?.step !== COMPLETE) {
                getServiceList({})
            }
        }
    }, [])

    return (
        <Container
            direction="column"
            flex={1}
            style={{ padding: theme.spacing(2) }}
            scrollY
        >
            {/* <Calendly minWidth={400} height={800} url="https://calendly.com/ezturn?primary_color=46b083"/> */}

            <span style={subheaderStyle}>General</span>

            <Container className={classes.wrap}>
                <NavLink
                    to={
                        workspaceUser?.active_workspace.company_type ===
                        APARTMENT
                            ? Routes.apartmentSchedule
                            : Routes.vendorSchedule
                    }
                    className={`${classes.box120} ${classes.centerBox} ${classes.boxShadow}`}
                    style={linkStyle}
                >
                    <CalendarTodayOutlined fontSize="large" />
                    <span style={linkTextStyle}>Schedule</span>
                </NavLink>

                {workspaceType === 'APARTMENT' &&
                    hasPermission(workspaceUser, SEE_INSPECTION) && (
                        <NavLink
                            to={Routes.inspect}
                            className={`${classes.box120} ${classes.centerBox} ${classes.boxShadow}`}
                            style={linkStyle}
                        >
                            <AssignmentTurnedInOutlined fontSize="large" />
                            <span style={linkTextStyle}>Inspect</span>
                        </NavLink>
                    )}

                <NavLink
                    to={Routes.workforce}
                    className={`${classes.box120} ${classes.centerBox} ${classes.boxShadow}`}
                    style={linkStyle}
                >
                    <People fontSize="large" />
                    <span style={linkTextStyle}>Workforce</span>
                </NavLink>

                {hasPermission(workspaceUser, VIEW_NATIONAL_VENDOR_LIST) &&
                    workspaceType === 'APARTMENT' && (
                        <NavLink
                            to={Routes.nvl}
                            className={`${classes.box120} ${classes.centerBox} ${classes.boxShadow}`}
                            style={linkStyle}
                        >
                            <StarBorderOutlined fontSize="large" />
                            <span style={linkTextStyle}>
                                National Vendor List
                            </span>
                        </NavLink>
                    )}

                {workspaceType === 'VENDOR' && vendorPlan && (
                    <NavLink
                        to={Routes.nationalPropertyList}
                        className={`${classes.box120} ${classes.centerBox} ${classes.boxShadow}`}
                        style={linkStyle}
                    >
                        <Monitor />
                        <span style={linkTextStyle}>
                            National Property List
                        </span>
                    </NavLink>
                )}

                {workspaceType === 'APARTMENT' &&
                    hasPermission(workspaceUser, SEE_ACTION_ITEMS) && (
                        <NavLink
                            to={Routes.actionItem}
                            className={`${classes.box120} ${classes.centerBox} ${classes.boxShadow}`}
                            style={linkStyle}
                        >
                            <FormatListNumbered fontSize="large" />
                            <span style={linkTextStyle}>Action Items</span>
                        </NavLink>
                    )}

                {workspaceType === 'APARTMENT' && (
                    <NavLink
                        to={Routes.leases}
                        className={`${classes.box120} ${classes.centerBox} ${classes.boxShadow}`}
                        style={linkStyle}
                    >
                        <Description fontSize="large" />
                        <span style={linkTextStyle}>Leases</span>
                    </NavLink>
                )}
            </Container>

            <span style={subheaderStyle}>Analyze</span>
            <Container className={classes.wrap}>
                {hasPermission(workspaceUser, VIEW_INVOICE) && (
                    <NavLink
                        to={Routes.invoiceHome}
                        className={`${classes.box120} ${classes.centerBox} ${classes.boxShadow}`}
                        style={linkStyle}
                    >
                        <ReceiptOutlined fontSize="large" />
                        <span style={linkTextStyle}>Invoice</span>
                    </NavLink>
                )}

                <NavLink
                    to={Routes.analytics}
                    className={`${classes.box120} ${classes.centerBox} ${classes.boxShadow}`}
                    style={linkStyle}
                >
                    <BarChart fontSize="large" />
                    <span style={linkTextStyle}>Analytics</span>
                </NavLink>

                {workspaceType === 'APARTMENT' && (
                    <NavLink
                        to={Routes.infrastructureManager}
                        className={`${classes.box120} ${classes.centerBox} ${classes.boxShadow}`}
                        style={linkStyle}
                    >
                        <Apartment fontSize="large" />
                        <span style={linkTextStyle}>Infrastructure</span>
                    </NavLink>
                )}
                <NavLink
                    to={Routes.reports}
                    className={`${classes.box120} ${classes.centerBox} ${classes.boxShadow}`}
                    style={linkStyle}
                >
                    <Receipt fontSize="large" />
                    <span style={linkTextStyle}>Reports</span>
                </NavLink>
            </Container>

            {workspaceType === 'APARTMENT' && (
                <>
                    <span style={subheaderStyle}>Configure</span>
                    <Container className={classes.wrap}>
                        <NavLink
                            to={Routes.infrastructureConfig}
                            className={`${classes.box120} ${classes.centerBox} ${classes.boxShadow}`}
                            style={linkStyle}
                        >
                            <MeetingRoom fontSize="large" />
                            <span style={linkTextStyle}>
                                Infrastructure Config
                            </span>
                        </NavLink>

                        <NavLink
                            to={Routes.damageConf}
                            className={`${classes.box120} ${classes.centerBox} ${classes.boxShadow}`}
                            style={linkStyle}
                        >
                            <Build fontSize="large" />
                            <span style={linkTextStyle}>Damages</span>
                        </NavLink>
                        <NavLink
                            to={Routes.statusGroups}
                            className={`${classes.box120} ${classes.centerBox} ${classes.boxShadow}`}
                            style={linkStyle}
                        >
                            <ThumbsUpDown fontSize="large" />
                            <span style={linkTextStyle}>Status Groups</span>
                        </NavLink>
                        <NavLink
                            to={Routes.changeOrderConfig}
                            className={`${classes.box120} ${classes.centerBox} ${classes.boxShadow}`}
                            style={linkStyle}
                        >
                            <Link fontSize="large" />
                            <span style={linkTextStyle}>
                                Change Order Config
                            </span>
                        </NavLink>
                    </Container>
                </>
            )}

            <div style={{ flex: 1 }} />
        </Container>
    )
}
