import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import {
    InspectionStatus,
    InspectionStatusType,
    Lease,
    LeaseStatus,
    ModelMap,
    Unit,
    getLeaseTimeline,
} from '../models'
import { _useFinderSelection } from './useFinderSelection'
import { useState } from 'react'
import { FinderLocationSelection, FinderSelectionMode } from '../store'

export const useLeaseFilter = () => {
    const locationFinderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Recursive,
    })

    const [statusFilter, setStatusFilter] = useState<LeaseStatusFilterState>({
        [LeaseStatus.APPLICANT]: false,
        [LeaseStatus.CANCELLED]: false,
        [LeaseStatus.CURRENT]: true,
        [LeaseStatus.FUTURE]: false,
        [LeaseStatus.NOTICE]: false,
        [LeaseStatus.PAST]: false,
    })

    const [dateFilter, setDateFilter] = useState<DateFilterState>({
        startDate: {
            date: null,
            operator: 'gte',
        },
        endDate: {
            date: null,
            operator: 'lte',
        },
    })

    const [filterDrawerOpen, setFilterDrawerOpen] = useState(true)

    const [searchValue, setSearchValue] = useState('')

    const [issueFilter, setIssueFilter] = useState(IssueFilterValue.NA)
    const [autoCompleteFilter, setAutoCompleteFilter] = useState(
        AutoCompleteFilterValue.NA,
    )

    const [
        tenantInspectionStatusFilter,
        setTenantInspectionStatusFilter,
    ] = useState<TenantInspectionStatusFilterState>({
        [InspectionStatus.ARCHIVED]: false,
        [InspectionStatus.COMPLETE]: false,
        [InspectionStatus.LOCKED]: false,
        [InspectionStatus.NA]: false,
        [InspectionStatus.READY]: false,
        [InspectionStatus.STAGED]: false,
    })

    const getFilteredLeases = (leaseList: Lease[], unitMap: ModelMap<Unit>) => {
        const caseNormalizedSearchValue = searchValue.toLocaleLowerCase()

        let applyTenantInspectionStatusFilter = false
        Object.keys(tenantInspectionStatusFilter).forEach((key) => {
            const insStatus = key as InspectionStatusType
            if (
                key in tenantInspectionStatusFilter &&
                tenantInspectionStatusFilter[insStatus]
            ) {
                applyTenantInspectionStatusFilter = true
            }
        })
        return leaseList.filter((l) => {
            // Validate timeline
            let endDateValid = true
            const leaseTimeline = getLeaseTimeline(l)
            if (dateFilter.endDate.date) {
                if (dateFilter.endDate.operator === 'gte') {
                    endDateValid =
                        leaseTimeline.endDate >= dateFilter.endDate.date
                } else if (dateFilter.endDate.operator === 'lte') {
                    endDateValid =
                        leaseTimeline.endDate <= dateFilter.endDate.date
                } else {
                    const filterDate = dateFilter.endDate.date as Date
                    endDateValid =
                        leaseTimeline.endDate.getFullYear() ===
                            filterDate.getFullYear() &&
                        leaseTimeline.endDate.getMonth() ===
                            filterDate.getMonth() &&
                        leaseTimeline.endDate.getDate() === filterDate.getDate()
                }
            }

            let startDateValid = true

            if (dateFilter.startDate.date) {
                if (dateFilter.startDate.operator === 'gte') {
                    startDateValid =
                        leaseTimeline.startDate >= dateFilter.startDate.date
                } else if (dateFilter.startDate.operator === 'lte') {
                    startDateValid =
                        leaseTimeline.startDate <= dateFilter.startDate.date
                } else {
                    const filterDate = dateFilter.startDate.date as Date
                    startDateValid =
                        leaseTimeline.startDate.getFullYear() ===
                            filterDate.getFullYear() &&
                        leaseTimeline.startDate.getMonth() ===
                            filterDate.getMonth() &&
                        leaseTimeline.startDate.getDate() ===
                            filterDate.getDate()
                }
            }

            let inspectionStatusValid = true
            if (applyTenantInspectionStatusFilter) {
                inspectionStatusValid =
                    (l.tenant_move_in_inspection &&
                        tenantInspectionStatusFilter[
                            l.tenant_move_in_inspection?.status
                        ]) ??
                    false
            }

            // Validate search string
            const unit = unitMap[l.area.unit]
            const locationString = `${unit?.folder.path}${unit?.folder.name}`
            const emailValid = l.user.email
                .toLocaleLowerCase()
                .includes(caseNormalizedSearchValue)
            const nameValid = l.user.name
                .toLocaleLowerCase()
                .includes(caseNormalizedSearchValue)
            const locationValid = locationString
                .toLocaleLowerCase()
                .startsWith(caseNormalizedSearchValue)
            const unitNameValid = unit?.name
                .toLocaleLowerCase()
                .startsWith(caseNormalizedSearchValue)
            const searchValid =
                emailValid || nameValid || locationValid || unitNameValid

            // Validate location selection
            const unitValid =
                locationFinderSelection.selection.unit.length === 0 ||
                locationFinderSelection.selection.unit[l.area.unit] !==
                    undefined

            let issueValid = issueFilter === IssueFilterValue.NA

            if (issueFilter === IssueFilterValue.HAS_ISSUE) {
                l.tenant_move_in_inspection?.inventory_inspection?.every(
                    (invIns) => {
                        if (invIns.issue?.length ?? 0 > 0) {
                            issueValid = true
                            // when using .every, return false to exit the loop
                            return false
                        }
                        // when using .every, return true to continue the loop
                        return true
                    },
                )
            } else if (issueFilter === IssueFilterValue.NO_ISSUE) {
                issueValid = true
                l.tenant_move_in_inspection?.inventory_inspection?.forEach(
                    (invIns) => {
                        if (invIns.issue?.length ?? 0 >= 0) {
                            issueValid = false
                        }
                    },
                )
            }

            let autoCompleteStatusValid =
                autoCompleteFilter === AutoCompleteFilterValue.NA
            if (autoCompleteFilter === AutoCompleteFilterValue.AUTO_COMPLETE) {
                autoCompleteStatusValid =
                    l.tenant_move_in_inspection?.auto_completed === 1
            } else if (
                autoCompleteFilter === AutoCompleteFilterValue.TENANT_COMPLETE
            ) {
                autoCompleteStatusValid =
                    l.tenant_move_in_inspection?.auto_completed === 0
            }

            return (
                startDateValid &&
                endDateValid &&
                searchValid &&
                unitValid &&
                issueValid &&
                autoCompleteStatusValid &&
                inspectionStatusValid
            )
        })
    }

    return {
        dateFilter,
        setDateFilter,
        filterDrawerOpen,
        setFilterDrawerOpen,
        searchValue,
        setSearchValue,
        getFilteredLeases,
        statusFilter,
        setStatusFilter,
        locationFinderSelection,
        issueFilter,
        setIssueFilter,
        tenantInspectionStatusFilter,
        setTenantInspectionStatusFilter,
        autoCompleteFilter,
        setAutoCompleteFilter,
    }
}

export interface LeaseStatusFilterState {
    [LeaseStatus.APPLICANT]: boolean
    [LeaseStatus.CANCELLED]: boolean
    [LeaseStatus.CURRENT]: boolean
    [LeaseStatus.FUTURE]: boolean
    [LeaseStatus.NOTICE]: boolean
    [LeaseStatus.PAST]: boolean
}

export interface TenantInspectionStatusFilterState {
    [InspectionStatus.ARCHIVED]: boolean
    [InspectionStatus.COMPLETE]: boolean
    [InspectionStatus.LOCKED]: boolean
    [InspectionStatus.NA]: boolean
    [InspectionStatus.READY]: boolean
    [InspectionStatus.STAGED]: boolean
}

export type DateFilterOperator = 'lte' | 'gte' | 'e'

export interface DateFilterOption {
    date: ParsableDate
    operator: DateFilterOperator
}

export interface DateFilterState {
    startDate: DateFilterOption
    endDate: DateFilterOption
}

export enum AutoCompleteFilterValue {
    NA = 0,
    AUTO_COMPLETE = 1,
    TENANT_COMPLETE = 2,
}

export enum IssueFilterValue {
    NA = 0,
    HAS_ISSUE = 1,
    NO_ISSUE = 2,
}
