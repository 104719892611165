import {
    Button,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    useTheme,
} from '@material-ui/core'
import { TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { AvatarCircle, Container, Selector, SideDrawer } from '../../components'
import { useAppDispatch } from '../../hooks'
import { InventoryConfig, StatusGroup } from '../../models'
import {
    createInventoryConfig,
    RootState,
    updateInventoryConfigReq,
} from '../../store'
import { InvConfigStatusCostDrawer } from './InvConfigStatusCostDrawer'

interface Props {
    drawerOpen: boolean
    handleClose: () => void
    inventoryConfig?: InventoryConfig
}

export const InventoryConfigDrawer = (props: Props) => {
    const { drawerOpen, handleClose, inventoryConfig } = props

    const theme = useTheme()
    const dispatch = useAppDispatch()

    const statusGroupList = useSelector(
        (state: RootState) => state.aptConfig.statusGroupList,
    )

    const [name, setName] = useState('')
    const [sku, setSku] = useState('')
    const [description, setDescription] = useState('')
    const [picture, setPicture] = useState<File>()
    const [validate, setValidate] = useState(false)
    const [defaultStausGroupId, setDefaultStatusGroupId] = useState(-1)

    const [openCostDrawer, setOpenCostDrawer] = useState(false)

    useEffect(() => {
        if (inventoryConfig) {
            setName(inventoryConfig.name)
            setSku(inventoryConfig.sku)
            setDescription(inventoryConfig.description)
            setDefaultStatusGroupId(inventoryConfig.status_group?.id ?? -1)
            // picture is undefined because if there is an inventory config we will directly use its link when picture is undefined
            // when the user changes the picture, we will start using what is in picture, and if they save that will be sent to the server
        } else {
            setName('')
            setSku('')
            setDescription('')
            setDefaultStatusGroupId(-1)
        }
        // do for edit and create
        setValidate(false)
        setPicture(undefined)
        setOpenCostDrawer(false)
    }, [inventoryConfig])

    const urlCreator = window.URL || window.webkitURL
    const handleCloseAndClear = () => {
        handleClose()
        setPicture(undefined)
        setName('')
        setSku('')
        setDescription('')
        setValidate(false)
    }

    const saveClick = () => {
        // make sure that the data is valid
        if (name !== '') {
            const invConfigFormData = new FormData()
            invConfigFormData.append('name', name)
            if (picture) {
                invConfigFormData.append('picture', picture)
            }
            invConfigFormData.append('sku', sku)
            if (defaultStausGroupId !== -1) {
                invConfigFormData.append(
                    'status_group',
                    // form data can't send integers
                    (defaultStausGroupId as unknown) as string,
                )
            }
            invConfigFormData.append('description', description)

            if (inventoryConfig) {
                // edit request
                dispatch(
                    updateInventoryConfigReq({
                        id: inventoryConfig.id,
                        req: invConfigFormData,
                    }),
                ).then(() => {
                    toast.success('Inventory Config successfully updated!')
                    handleCloseAndClear()
                })
            } else {
                // create request

                dispatch(createInventoryConfig(invConfigFormData)).then(() => {
                    toast.success('Inventory Config successfully created!')
                    handleCloseAndClear()
                })
            }
        } else {
            setValidate(true)
        }
    }

    return (
        <SideDrawer
            open={drawerOpen}
            handleClose={() => handleCloseAndClear()}
            title={
                inventoryConfig === undefined
                    ? 'Create Inventory Config'
                    : 'Edit Inventory Config'
            }
        >
            {/*  img */}
            <Container>
                <AvatarCircle
                    image={
                        // if a picture is stored, display that.
                        // otherwise, if an inv config is selected and it has a picture display that
                        picture
                            ? urlCreator.createObjectURL(picture)
                            : inventoryConfig?.file
                    }
                    addImageIcon
                    changePicMode
                    onInputChange={(e) => {
                        e.stopPropagation()
                        e.preventDefault()

                        const files = e.target.files
                        if (files === null) {
                            return
                        }
                        setPicture(files[0])
                    }}
                    styles={{
                        container: { margin: theme.spacing(2) },
                    }}
                />
                <TextField
                    variant="outlined"
                    label="Name (Required)"
                    error={validate && name === ''}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    style={{
                        margin: theme.spacing(2, 2, 0, 0),
                        width: '100%',
                    }}
                />
            </Container>

            {/* set Status Group */}
            <Selector
                label="Status Group"
                currentValue={defaultStausGroupId}
                data={statusGroupList ?? []}
                onChange={(
                    event: React.ChangeEvent<{
                        value: unknown
                    }>,
                ) => {
                    const newDefaultGroup = statusGroupList?.find(
                        (s) => s.id === (event.target.value as number),
                    )
                    setDefaultStatusGroupId(newDefaultGroup?.id ?? -1)
                }}
                getDisplayString={(s: StatusGroup) => `${s.name}`}
            />
            {/* button to go to cost drawer */}
            <Button
                color="primary"
                variant="outlined"
                disabled={inventoryConfig === undefined}
                onClick={() => setOpenCostDrawer(true)}
                style={{ margin: theme.spacing(0, 2) }}
            >
                Configure Costs
            </Button>
            {/* sku */}
            <TextField
                variant="outlined"
                label={'SKU'}
                value={sku}
                onChange={(e) => {
                    setSku(e.target.value)
                }}
                style={{ margin: theme.spacing(2) }}
            />

            {/*  description */}
            <TextField
                variant="outlined"
                label={'Description'}
                value={description}
                onChange={(e) => {
                    setDescription(e.target.value)
                }}
                style={{ margin: theme.spacing(2) }}
            />
            <div style={{ flex: 1 }} />
            {/* footer */}
            <Container
                justifyContent={'flex-end'}
                style={{
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    marginTop: theme.spacing(1),
                }}
            >
                <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleCloseAndClear}
                    style={{ marginRight: theme.spacing(1) }}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => saveClick()}
                >
                    Save
                </Button>
            </Container>
            {inventoryConfig !== undefined && (
                <InvConfigStatusCostDrawer
                    open={openCostDrawer && drawerOpen}
                    onClose={() => setOpenCostDrawer(false)}
                    invConf={inventoryConfig}
                />
            )}
        </SideDrawer>
    )
}
