import React from 'react'
import {
    IconButton,
    Popover,
    Theme,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

import { BaseWorkorder, WorkorderTransitionLog } from '../../models'
import { Container } from '../Container'
import { AvatarCircle } from '../AvatarCircle'
import { WoStatusBadge } from '../../containers/VendorScheduler'
import { toMMDDYYYY, toUiTime } from '../../helpers'

interface Props {
    open: boolean
    anchorEl: HTMLElement | null
    closePopover?: (
        event: {},
        reason: 'backdropClick' | 'escapeKeyDown',
    ) => void
    transitionLogs: WorkorderTransitionLog[] | null
    workorder: BaseWorkorder
}

export const WorkorderTransitionLogPopver = (props: Props) => {
    const { open, anchorEl, closePopover, transitionLogs, workorder } = props

    const theme = useTheme()

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
            }}
            onClose={closePopover}
        >
            <Container
                style={{
                    padding: theme.spacing(1),
                    flexDirection: 'column',
                    maxHeight: 500,
                }}
            >
                <Container style={{ alignItems: 'center' }}>
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        All Transitions
                    </span>
                    <div style={{ flex: 1 }} />
                    <Tooltip title="Cancel">
                        <IconButton
                            onClick={(e) =>
                                closePopover && closePopover(e, 'backdropClick')
                            }
                        >
                            <CloseIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Container>

                <Container style={{ flexDirection: 'column' }} scrollY>
                    <Container>
                        <span
                            style={{
                                ...theme.typography.subtitle2,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            Created:{' '}
                            {toMMDDYYYY(new Date(workorder.created_date))}
                        </span>
                    </Container>
                    {transitionLogs?.map((log, idx) => (
                        <TransitionLog
                            key={`WORKORDER_LOG_${log.log_id}`}
                            log={log}
                            theme={theme}
                            marginBottom={
                                idx < transitionLogs.length - 1
                                    ? theme.spacing(1)
                                    : 0
                            }
                        />
                    ))}
                </Container>
            </Container>
        </Popover>
    )
}

interface TLProps {
    log: WorkorderTransitionLog
    theme: Theme
    marginBottom?: number
}

export const TransitionLog = (props: TLProps) => {
    const { log, theme } = props

    return (
        <Container
            style={{
                backgroundColor: theme.palette.grey[200],
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(0.5),
                marginBottom: props.marginBottom,
            }}
        >
            <AvatarCircle
                initials
                text={log.transition_user_name}
                tooltip={log.transition_user_name}
            />

            <Container style={{ flexDirection: 'column' }}>
                <Container style={{ marginLeft: theme.spacing(1) }}>
                    <WoStatusBadge
                        theme={theme}
                        status={log.from_status}
                        style={{
                            text: {
                                fontWeight: theme.typography.fontWeightBold,
                            },
                        }}
                    />

                    <ArrowRightAltIcon fontSize="large" />

                    <WoStatusBadge
                        theme={theme}
                        status={log.to_status}
                        style={{
                            text: {
                                fontWeight: theme.typography.fontWeightBold,
                            },
                            container: {
                                marginLeft: theme.spacing(1),
                            },
                        }}
                    />
                </Container>

                <Container style={{ marginLeft: theme.spacing(1) }}>
                    <span
                        style={{
                            ...theme.typography.subtitle2,
                            fontWeight: theme.typography.fontWeightLight,
                        }}
                    >
                        {toMMDDYYYY(new Date(log.date))}
                    </span>

                    <span
                        style={{
                            ...theme.typography.subtitle2,
                            fontWeight: theme.typography.fontWeightLight,
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        {toUiTime(new Date(log.date))}
                    </span>
                </Container>
            </Container>
        </Container>
    )
}
