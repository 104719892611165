import React from 'react'
import { RFPContext, useRFPState } from '../../contexts'
import { RFPDashboard } from './RFPDashboard'

export const RFPRoot = () => {
    const rfpState = useRFPState()

    return (
        <RFPContext.Provider value={rfpState}>
            <RFPDashboard />
        </RFPContext.Provider>
    )
}
