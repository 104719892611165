import { IconButton, Popover, Tooltip, useTheme } from '@material-ui/core'
import {
    ChartData,
    PropertyGradeResponseData,
} from '../../../hooks/useAnalyticData'
import React, { useRef, useState } from 'react'
import { ApexOptions } from 'apexcharts'
import { Container } from '../../../components'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'
import ReactApexChart from 'react-apexcharts'
import { Range } from 'react-date-range'
import {
    ALL,
    CUSTOM,
    DateFilter,
    LAST_MONTH,
    THIS_MONTH,
} from '../../../components/DateFilterButtons'
import { getPreviousMonthDates } from '../../../helpers'
import { useStyles } from '../../../styles'

interface PropertyGradeRowProps {
    propertyGrade: PropertyGradeResponseData
    timeline: Range
    dateFilter: DateFilter
    chartData: ChartData | null
    isSelected: boolean
    getChartData: (
        apartmentId: number,
        timeline?: { startDate?: Date; endDate?: Date },
    ) => void
    getInspectionTypeGrades: (
        apartmentId: number,
        timeline?: { startDate?: Date; endDate?: Date },
    ) => void
    setOpenInspectionTypeModal: (open: boolean) => void
    setSelectedPropertyGrade: (propertyGrade: PropertyGradeResponseData) => void
}

export const PropertyGradeRow = (props: PropertyGradeRowProps) => {
    const {
        propertyGrade,
        timeline,
        dateFilter,
        chartData,
        isSelected,
        setSelectedPropertyGrade,
        getChartData,
        getInspectionTypeGrades,
        setOpenInspectionTypeModal,
    } = props

    const theme = useTheme()
    const classes = useStyles()
    const [popoverOpen, setPopoverOpen] = useState(false)
    const popoverRef = useRef<HTMLDivElement>(null)

    const options: ApexOptions = {
        chart: {
            id: 'line-chart',
        },
        xaxis: {
            type: 'datetime',
        },
        yaxis: {
            seriesName: 'BIPS',
            decimalsInFloat: 0,
        },
        dataLabels: {
            formatter: (value: number) => value,
        },
        title: {
            text: 'Building and Inspector Performance Score',
            align: 'center',
            style: {
                fontSize: '20px',
            },
        },
        colors: [
            theme.palette.purple.main,
            theme.palette.darkGreen.light,
            theme.palette.darkBlue.dark,
        ],
        markers: {
            size: [5, 0, 0],
        },
        stroke: {
            width: [0, 3, 2],
            dashArray: [0, 0, 10],
        },
    }

    const chartLines = [
        {
            name: 'Bips',
            type: 'line',
            data: chartData?.bips ?? [],
        },
        {
            name: 'Trend',
            type: 'line',
            data: chartData?.trendline ?? [],
        },
        {
            name: 'Average',
            type: 'line',
            data: chartData?.average ?? [],
        },
    ]

    const getTimeline = () => {
        if (dateFilter === ALL) {
            return null
        } else if (dateFilter === LAST_MONTH) {
            return getPreviousMonthDates()
        } else if (dateFilter === THIS_MONTH) {
            const today = new Date()
            const startOfMonth = new Date(
                today.getFullYear(),
                today.getMonth(),
                1,
            )
            const endOfMonth = new Date(
                today.getFullYear(),
                today.getMonth() + 1,
                0,
            )
            return { startDate: startOfMonth, endDate: endOfMonth }
        } else if (dateFilter === CUSTOM) {
            return timeline
        }
        return null
    }

    const cellStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        fontWeight: 400,
        flex: 1,
        justifyContent: 'center',
    }

    return (
        <Container
            style={{
                border: '1px solid',
                borderColor: theme.palette.grey[400],
                borderRadius: '10px 10px 10px 10px',
                flexDirection: 'row',
                padding: theme.spacing(2),
                marginTop: theme.spacing(1),
                cursor: 'pointer',
                backgroundColor: isSelected ? theme.palette.grey[300] : '',
            }}
            alignItems="center"
            className={classes.hoverGrey300}
            onClick={() => {
                if (!popoverOpen) {
                    const dateRange = getTimeline()
                    if (dateRange) {
                        getInspectionTypeGrades(
                            propertyGrade.apartment_id,
                            dateRange,
                        )
                    } else {
                        getInspectionTypeGrades(propertyGrade.apartment_id)
                    }
                    setOpenInspectionTypeModal(true)
                    setSelectedPropertyGrade(propertyGrade)
                }
            }}
        >
            {/* location column */}
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {propertyGrade.name}
            </Container>
            {/* bips column */}
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {propertyGrade.bips.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                })}
            </Container>
            {/* building health column */}
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {propertyGrade.building_score.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                })}
            </Container>
            {/* inspector performance column */}
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {propertyGrade.inspector_score.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                })}
            </Container>

            {/* trend column */}
            <Container style={{ ...cellStyle }}>
                <Tooltip title="View Chart">
                    <IconButton
                        size="small"
                        style={{
                            border: `1px solid ${theme.palette.grey[400]}`,
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            const dateRange = getTimeline()
                            if (dateRange) {
                                getChartData(propertyGrade.id, dateRange)
                            } else {
                                getChartData(propertyGrade.id)
                            }

                            setPopoverOpen(true)
                        }}
                    >
                        {propertyGrade.trend_value >= 3 && (
                            <TrendingUpIcon color="primary" fontSize="large" />
                        )}
                        {propertyGrade.trend_value <= -3 && (
                            <TrendingDownIcon
                                color="secondary"
                                fontSize="large"
                            />
                        )}
                        {propertyGrade.trend_value < 3 &&
                            propertyGrade.trend_value > -3 && (
                                <TrendingFlatIcon fontSize="large" />
                            )}
                    </IconButton>
                </Tooltip>
            </Container>
            <Container>
                <Popover
                    id="Trend Plot"
                    open={popoverOpen}
                    anchorEl={popoverRef.current}
                    onClose={() => setPopoverOpen(false)}
                >
                    <Container>
                        <ReactApexChart
                            options={options}
                            series={chartLines}
                            type="line"
                            height="500px"
                            id="1"
                            width="1400px"
                        />
                    </Container>
                </Popover>
            </Container>
        </Container>
    )
}
