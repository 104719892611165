import React, { useEffect, useState } from 'react'
import { Container } from '../../../../components'
import {
    Button,
    Divider,
    FormControlLabel,
    IconButton,
    Switch,
    TextField,
    Theme,
    Tooltip,
} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { IdBoolMap, ListVendor } from '../../../../models'

import { AddressForm, AddressState } from '../../../../components'
import { VENDOR_MODAL_H, VENDOR_MODAL_W } from './contants'

interface Props {
    theme: Theme
    title: string
    submitTitle: string
    vendor: ListVendor | null
    searchEmail?: string
    vendorCanSeeUnitNotesMap: IdBoolMap
    onSubmit: (body: {
        name: string
        email: string
        phone: string
        address: AddressState
        inHouse: boolean
        unitNotes: boolean
    }) => void
}

export const StepVendorDetail = (props: Props) => {
    const {
        theme,
        vendor,
        title,
        submitTitle,
        onSubmit,
        vendorCanSeeUnitNotesMap,
    } = props

    const [doValidate, setDoValidate] = useState(false)

    const [addressState, setAddressState] = useState<AddressState>(
        defaultAddressState,
    )

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [inHouse, setInHouse] = useState(false)
    const [unitNotes, setUnitNotes] = useState(false)

    useEffect(() => {
        if (vendor === null) {
            clearForm()
        } else {
            matchFormToVendor(vendor)
        }
    }, [vendor, vendorCanSeeUnitNotesMap])

    useEffect(() => {
        if (props.searchEmail) {
            setEmail(props.searchEmail)
        }
    }, [props.searchEmail])

    const clearForm = () => {
        setName('')
        setEmail('')
        setPhone('')
        setUnitNotes(false)
        setAddressState(defaultAddressState)
    }

    const matchFormToVendor = (vendor: ListVendor) => {
        setName(vendor.name)
        setEmail(vendor.email ?? '')
        setPhone(vendor.phone ?? '')
        setInHouse(vendor.in_house !== null)
        setUnitNotes(vendorCanSeeUnitNotesMap[vendor.id] ?? false)

        // TODO: Serialize the company address model and use the correct address values
        setAddressState({
            addressLine1: vendor.address,
            addressLine2: '',
            city: vendor.city,
            state: vendor.state,
            zip: vendor.zip_code,
            country: 'US',
        })
    }

    const inputStyle: React.CSSProperties = {
        marginBottom: theme.spacing(2),
    }

    return (
        <Container
            style={{
                flexDirection: 'column',
                maxHeight: VENDOR_MODAL_H,
                minHeight: VENDOR_MODAL_H,
                minWidth: VENDOR_MODAL_W,
                maxWidth: VENDOR_MODAL_W,
            }}
        >
            {/* Heading */}
            <Container
                style={{
                    padding: theme.spacing(1),
                    borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    minHeight: HEADER_H,
                    maxHeight: HEADER_H,
                }}
            >
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {title}
                </span>
            </Container>

            {/* Body */}
            <Container
                style={{
                    maxHeight: VENDOR_MODAL_H - HEADER_H - FOOTER_H,
                    minHeight: VENDOR_MODAL_H - HEADER_H - FOOTER_H,
                }}
            >
                {/* Column 1, input fields */}
                <Container
                    style={{
                        flexDirection: 'column',
                        padding: theme.spacing(1),
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.darkGreen.main,
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        Please enter the vendors details below!
                    </span>

                    <TextField
                        error={doValidate && name === ''}
                        helperText={doValidate && name === '' ? 'Required' : ''}
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        label="Vendor Name"
                        style={inputStyle}
                    />

                    {/* <TextField
                                error={doValidate && vendorType === ''}
                                helperText={
                                    doValidate && vendorType === ''
                                        ? 'Required'
                                        : ''
                                }
                                variant="outlined"
                                value={vendorType}
                                onChange={(e) => setVendorType(e.target.value)}
                                label="Vendor Type"
                                style={inputStyle}
                                size="small"
                                select
                            >
                                {vendorTypeList.map((type) => {
                                    return (
                                        <MenuItem key={type} value={type}>
                                            {type}
                                        </MenuItem>
                                    )
                                })}
                            </TextField> */}

                    <TextField
                        // error={
                        //     doValidate &&
                        //     (email === '' ||
                        //         !isEmailValid(email) ||
                        //         duplicateEmail)
                        // }
                        // disabled={vendor !== undefined}
                        // helperText={
                        //     doValidate && duplicateEmail
                        //         ? 'Another vendor already has this email, please check the National Vendor List for this vendor'
                        //         : emailHelperText(doValidate, email)
                        // }
                        variant="outlined"
                        value={email}
                        onChange={(e) => {
                            // setDuplicateEmail(false)
                            setEmail(e.target.value)
                        }}
                        label="Email"
                        style={inputStyle}
                    />
                    <Container>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={inHouse}
                                    color="primary"
                                    onChange={(e) => setInHouse(!inHouse)}
                                />
                            }
                            labelPlacement="end"
                            label="In House"
                            style={{ marginBottom: theme.spacing(1) }}
                        />
                        {vendor && (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={unitNotes}
                                        color="primary"
                                        onChange={(e) =>
                                            setUnitNotes(!unitNotes)
                                        }
                                    />
                                }
                                labelPlacement="end"
                                label="Unit Notes"
                                style={{ marginBottom: theme.spacing(1) }}
                            />
                        )}
                    </Container>
                    <TextField
                        variant="outlined"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        label="Phone"
                        style={inputStyle}
                    />

                    <AddressForm
                        doValidate={doValidate}
                        addressState={addressState}
                        setAddressState={setAddressState}
                        inputStyle={inputStyle}
                    />
                </Container>

                {/* Column 2 map */}

                <Container style={{ flex: 1, padding: theme.spacing(2) }}>
                    <iframe
                        width="100%"
                        loading="lazy"
                        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                        &q=${addressState.city}_${addressState.state}`}
                    />
                </Container>
            </Container>

            {/* Footer */}
            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    maxHeight: FOOTER_H,
                    minHeight: FOOTER_H,
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    padding: theme.spacing(1),
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    style={{ textTransform: 'none' }}
                    onClick={() => {
                        onSubmit({
                            name: name,
                            email: email,
                            phone: phone,
                            address: addressState,
                            inHouse: inHouse,
                            unitNotes: unitNotes,
                        })
                    }}
                >
                    {submitTitle}
                </Button>
            </Container>
        </Container>
    )
}

const defaultAddressState: AddressState = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
}

const FOOTER_H = 60
const HEADER_H = 60
