import React from 'react'
import { SwipeableDrawer } from '@material-ui/core'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import SideBarNavigationItems from './SideBarNavigationItems'

import Logo from '../../../../assets/icons/logo.png'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            '& .MuiDrawer-paperAnchorLeft': {
                width: '136px',
                background: '#2F2E41',
            },
        },
    }),
)

interface Props {
    openSwipeDrawer: boolean | any
    toggleSwipeDrawer: () => void
}

const SwipeableSideBar = (props: Props) => {
    const classes = useStyles()
    return (
        <SwipeableDrawer
            anchor="left"
            className={classes.drawer}
            open={props.openSwipeDrawer}
            onClose={props.toggleSwipeDrawer}
            onOpen={props.toggleSwipeDrawer}
        >
            <img
                src={Logo}
                alt="EZ Turn"
                style={{
                    margin: '20px auto',
                    width: '126px',
                }}
            />
            <SideBarNavigationItems open={false} />
        </SwipeableDrawer>
    )
}

export default SwipeableSideBar
