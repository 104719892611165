import React from 'react'

import {
    useTheme,
    Button,
    TextField,
    Switch,
    FormLabel,
    Tooltip,
} from '@material-ui/core'

import {
    Container,
    ModularList,
    NumberInput,
    Selector,
    SideDrawer,
} from '../../components'
import { AreaConfig, InventoryConfig, StatusGroup } from '../../models'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
    createAreaConfigReq,
    CreateAreaConfigRequest,
    InvConfigAreaRequest,
    RootState,
    updateAreaConfigReq,
    UpdateAreaConfigRequest,
} from '../../store'
import { Add } from '@material-ui/icons'
import { useStyles } from '../../styles'
import { useAppDispatch } from '../../hooks'
import { toast } from 'react-toastify'
import { useEffect } from 'react'

interface ConfigWIthAmount {
    invConfig: InventoryConfig
    amount?: string
}
interface Props {
    open: boolean
    areaConfig?: AreaConfig
    handleClose: () => void
}

const defaultStatusGroup: StatusGroup = {
    id: -1,
    name: '',
    apartment: -1,
    default_status: {
        id: -1,
        name: '',
        color: '',
        apartment: -1,
        causes_flag: false,
    },
}

const defaultInventoryConfig: InventoryConfig = {
    id: -1,
    name: '',
    sku: '',
    picture: null,
    description: '',
    status_group: defaultStatusGroup,
    file: '',
    custom_status_list: [],
    ins_has_note: false,
    inspection_score: 0,
}

export const AreaConfigDrawer = (props: Props) => {
    const { open, areaConfig, handleClose } = props

    const theme = useTheme()
    const classes = useStyles()
    const dispatch = useAppDispatch()

    const inventoryConfigList = useSelector(
        (state: RootState) => state.aptConfig.inventoryConfigList,
    )

    // useEffect for change area selected
    useEffect(() => {
        if (areaConfig) {
            setName(areaConfig.name)

            setSelectedInvConfigs(
                areaConfig.inventory_area_configs?.map((aC) => {
                    return {
                        amount: aC.amount.toString(),
                        invConfig: aC.inventory_config,
                    }
                }) ?? [],
            )
        } else {
            setName('')
            setSelectedInvConfigs([])
        }
    }, [areaConfig])

    const [name, setName] = useState('')
    const [validate, setValidate] = useState(false)
    const [selectedInvConfigs, setSelectedInvConfigs] = useState<
        ConfigWIthAmount[]
    >([])

    const saveClick = () => {
        if (name === '') {
            setValidate(true)
        } else {
            // create request body

            const invConfReq = selectedInvConfigs.reduce<
                InvConfigAreaRequest[]
            >((prev, sIC) => {
                // see if this selection is a real inv conf and
                // see if theres an amount set that is 1 or higher
                const numAmount = Number(sIC.amount)

                if (
                    sIC.invConfig.id === -1 ||
                    numAmount < 1 ||
                    isNaN(numAmount)
                ) {
                    return prev
                }
                return prev.concat({ id: sIC.invConfig.id, amount: numAmount })
            }, [])

            const req: CreateAreaConfigRequest = {
                body: {
                    name: name,
                    inventory_configs: invConfReq,
                },
            }

            if (areaConfig) {
                const updateReq: UpdateAreaConfigRequest = {
                    ...req,
                    params: {
                        id: areaConfig.id,
                    },
                }

                dispatch(updateAreaConfigReq(updateReq)).then(() => {
                    toast.success(`${name} successfully updated!`)
                    handleDrawerClose()
                })
            } else {
                dispatch(createAreaConfigReq(req)).then(() => {
                    toast.success(`${name} successfully created!`)
                    handleDrawerClose()
                })
            }
        }
    }

    const handleDrawerClose = () => {
        handleClose()
        setName('')
        setValidate(false)
        setSelectedInvConfigs([])
    }

    const invConfigSelectors =
        selectedInvConfigs?.map((sIC, idx) => {
            // drop down selectors where the options are
            // also has an amount field

            //the selection options for each are the inventoryConfigList - selectedInvConfigs + sIC if sIC is a real config
            const selectionOptionsList = inventoryConfigList?.reduce<
                InventoryConfig[]
            >((prev, iC) => {
                // see if this config has already been selected
                const selectedConfig = selectedInvConfigs.find(
                    (s) => s.invConfig.id === iC.id,
                )
                if (selectedConfig !== undefined) {
                    // it has alrady been selected
                    // see if it is the current one, sIC
                    if (sIC.invConfig.id !== selectedConfig.invConfig.id) {
                        // it is not the current one and it has been selected
                        // do not list it as a selection option
                        return prev
                    }
                }
                // list it in the selection options
                return prev.concat(iC)
            }, [])
            return (
                <Container
                    key={`INV_CONFIG_SELECTOR_${idx}_${sIC.invConfig.id}`}
                    alignItems={'center'}
                >
                    <Selector
                        customStyle={{ formControl: { width: '100%' } }}
                        label={'Inventory Config'}
                        currentValue={sIC.invConfig.id}
                        data={selectionOptionsList ?? []}
                        getDisplayString={(iC: InventoryConfig) => iC.name}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            //find the invConfig with the correct ID
                            const newConfig = inventoryConfigList?.find(
                                (iC) =>
                                    iC.id === (event.target.value as number),
                            )
                            // rebuild the selectedInvConfigs, replacing the old selected with this one
                            if (newConfig) {
                                const newSelectedInvConfigs = selectedInvConfigs.map(
                                    (sC, index) => {
                                        if (index !== idx) {
                                            return sC
                                        }
                                        return {
                                            invConfig: newConfig,
                                            // if amount is undefined, set it to 1 now that it is garunteed to be a real ic
                                            amount: sC.amount ?? '1',
                                        }
                                    },
                                )
                                setSelectedInvConfigs(newSelectedInvConfigs)
                            }
                        }}
                    />
                    <NumberInput
                        prefix={''}
                        variant="outlined"
                        label={'Amount'}
                        value={sIC.amount}
                        onChange={(e) => {
                            const newSelectedInvConfigs = selectedInvConfigs.map(
                                (sC, index) => {
                                    if (index !== idx) {
                                        return sC
                                    }
                                    return {
                                        invConfig: sC.invConfig,

                                        amount: e.target.value,
                                    }
                                },
                            )
                            setSelectedInvConfigs(newSelectedInvConfigs)
                        }}
                    />
                </Container>
            )
        }) ?? []

    return (
        <SideDrawer
            open={open}
            handleClose={handleDrawerClose}
            title={areaConfig ? 'New Area Config' : 'Edit Area Config'}
        >
            {/* content */}
            <Container
                direction={'column'}
                style={{ padding: theme.spacing(2) }}
                scrollY
            >
                <TextField
                    variant="outlined"
                    label="Name (Required)"
                    error={validate && name === ''}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    style={{
                        marginBottom: theme.spacing(2),
                        width: '100%',
                    }}
                />

                {/* Inventory Configs */}

                {/* Title */}
                <Container
                    alignItems="center"
                    style={{ marginTop: theme.spacing(2) }}
                >
                    <span style={{ ...theme.typography.body1 }}>
                        Inventory Configs
                    </span>
                    <div style={{ flex: 1 }} />
                    <Tooltip
                        title={'New Inventory Config'}
                        style={{ marginRight: theme.spacing(2) }}
                    >
                        <Add
                            color="primary"
                            className={classes.highlightCircleOnHover}
                            onClick={() => {
                                setSelectedInvConfigs([
                                    ...(selectedInvConfigs ?? []),
                                    { invConfig: defaultInventoryConfig },
                                ])
                            }}
                            fontSize="large"
                        />
                    </Tooltip>
                </Container>
                <ModularList
                    elements={invConfigSelectors}
                    remove={(idx: number) => {
                        const newSelectedInvConfigs = selectedInvConfigs?.reduce<
                            ConfigWIthAmount[]
                        >((prev, sC, index) => {
                            if (idx === index) {
                                return prev
                            }
                            return prev.concat(sC)
                        }, [])
                        setSelectedInvConfigs(newSelectedInvConfigs)
                    }}
                />
            </Container>
            {/* footer */}
            <div style={{ flex: 1 }} />
            <Container
                justifyContent={'flex-end'}
                style={{
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                }}
            >
                <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleDrawerClose}
                    style={{ marginRight: theme.spacing(1) }}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => saveClick()}
                >
                    Save
                </Button>
            </Container>
        </SideDrawer>
    )
}
