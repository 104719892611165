import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'

import ReactDOM from 'react-dom'
import {
    EditorState,
    ContentState,
    convertToRaw,
    Modifier,
    convertFromRaw,
} from 'draft-js'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { ReactComponent as AddSignIcon } from '../../../assets/icons/at-sign.svg'
import { ReactComponent as AttachIcon } from '../../../assets/icons/paperclip.svg'
import { ReactComponent as SendMessageIcon } from '../../../assets/icons/send.svg'
import '@draft-js-plugins/mention/lib/plugin.css'
import {
    Avatar,
    Button,
    CircularProgress,
    FormControl,
    Icon,
    IconButton,
    InputAdornment,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { useMessage } from '../../../hooks/useMessage'
import { AttachmentContainer } from './attachment-container'

import Editor from '@draft-js-plugins/editor'
import createMentionPlugin, {
    defaultSuggestionsFilter,
} from '@draft-js-plugins/mention'
import editorStyles from './SimpleMentionEditor.module.css'
import { MentionData } from '@draft-js-plugins/mention'
import LoopIcon from '@material-ui/icons/Loop'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import ImageIcon from '@material-ui/icons/Image'
import { useUser } from '../../../hooks'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // position: 'absolute',
            width: '100%',
            // left: 0,
            // bottom: 0,
            padding: '16px',
        },
        messageTextDiv: {
            // border: '1px solid #ACB3BF',
            // borderRadius: '5px',
            position: 'relative',
            maxHeight: '90px',
            height: '90px',
            minHeight: '90px',
            // padding: '0 10px',
        },
        textBottomBar: {
            // position: 'absolute',
            // bottom: 0,
            // left: 0,
            width: '100%',
            padding: 0,
        },
        editorDiv: {
            height: '80px',
            overflowY: 'scroll',
        },
        fileInput: {
            display: 'none',
        },
        attachFilePreviewDiv: {},
        sendBtnActive: {
            background: '#46b083',
            '& path': {
                stroke: '#FFF',
            },
            '&:hover': {
                backgroundColor: '#46b083 !important',
            },
        },
        attchMentionBtn: {
            borderRadius: '5px',
            width: '30px',
            height: '24px',
            padding: 0,
            '&:hover': {
                backgroundColor: '#46b083 !important',
                '& path': {
                    stroke: '#FFF',
                },
            },
        },
        editorBtnToolTip: {
            background: theme.palette.common.white,
            border: '1px solid #000000',
            color: '#000',
            fontSize: '0.625rem',
            fontWeight: 400,
            maxWidth: '104px',
            textAlign: 'center',
        },
        rotateIcon: {
            animation: 'spin 01s linear infinite',
        },
        mentionList: {
            '& .MuiSvgIcon-root': {
                height: '0.5em',
            },
            '& .MuiListItemAvatar-root': {
                minWidth: '30px',
            },
            '& .MuiAvatar-root': {
                height: '20px',
                width: '20px',
            },
            '& .MuiListItemText-primary': {
                fontSize: '0.75rem',
            },
        },
    }),
)

interface Props {
    channelId: number | null
}

export const MessageTextEditor = (props: Props) => {
    const classes = useStyles()
    const theme = useTheme()

    const { createMessage } = useMessage()

    const [editorState, setEditorState] = React.useState(
        EditorState.createEmpty(),
    )
    const [attachments, setAttachments]: any = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const editorRef = useRef<any>()

    const onFileAttach = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileArray: File[] = []
        if (event.target.files) {
            const files = event.target.files
            Array.from(event.target.files).forEach((f: File) => {
                fileArray.push(f)
            })
            console.log('attachment is....', attachments)

            const fileObj = [...attachments, ...fileArray]
            console.log('file object is...', fileObj)
            console.log('file array is...', fileArray)
            setAttachments(fileObj)
        }
    }

    const onRemoveFile = (file: any) => {
        const newFiles = attachments.filter(
            (item: any) => item.name !== file.name,
        )
        setAttachments(newFiles)
    }

    /************** Mention Code [start] ****************** */

    const ref = useRef<Editor>(null)

    const [open, setOpen] = useState(false)
    const [suggestions, setSuggestions] = useState([])
    const [mentionsList, setMentionsList] = useState([])

    const { getUserList, userList } = useUser()

    useEffect(() => {
        getUserList({ params: { my_team: true } }).then((res: any) => {
            if (res.status === 200 && res.data.length > 0) {
                updateSuggestionList(res.data)
            }
        })
    }, [])

    const updateSuggestionList = (userData: any) => {
        console.log('user list....', userData)
        const mentions: any =
            userData.length > 0
                ? userData.map((user: any, index: number) => {
                      return {
                          id: user.id,
                          name: user.name,
                          profile_color: user.profile_color,
                          profile_picture: user.profile_picture,
                          link: '',
                          avatar:
                              'https://pbs.twimg.com/profile_images/517863945/mattsailing_400x400.jpg',
                      }
                  })
                : []

        setMentionsList(mentions)
    }

    console.log('suggestions list is....', suggestions)

    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            mentionComponent(mentionProps) {
                return (
                    <span
                        className={mentionProps.className}
                        // eslint-disable-next-line no-alert
                        // onClick={() => props.toggleProfileDrawer(mentionProps)} //COMMENTED FOR NOW. WILL ADD LATER ON
                    >
                        {mentionProps.children}
                    </span>
                )
            },
        })
        // eslint-disable-next-line no-shadow
        const { MentionSuggestions } = mentionPlugin
        // eslint-disable-next-line no-shadow
        const plugins = [mentionPlugin]
        return { plugins, MentionSuggestions }
    }, [])

    const onOpenChange = useCallback((_open: boolean) => {
        setOpen(_open)
    }, [])
    const onSearchChange = useCallback(({ value }: { value: string }) => {
        console.log('value is....', value)
        console.log('mention list is...', userList)
        const filteredUser: any = mentionsList.filter((user: any) =>
            user.name.includes(value),
        )

        getUserList({ params: { my_team: true } }).then((res: any) => {
            if (res.status === 200 && res.data.length > 0) {
                const filteredData: any = []
                if (res.data.length > 0) {
                    res.data.forEach((user: any, index: number) => {
                        if (
                            user.name
                                .toLowerCase()
                                .includes(value.toLowerCase())
                        ) {
                            filteredData.push({
                                id: user.id,
                                name: user.name,
                                profile_color: user.profile_color,
                                profile_picture: user.profile_picture,
                                link: '',
                                avatar:
                                    'https://pbs.twimg.com/profile_images/517863945/mattsailing_400x400.jpg',
                            })
                        }
                    })
                    setSuggestions(filteredData)
                }
            }
        })
    }, [])

    /***************** Mention Code [end]******************* */

    const sendMessage = () => {
        setIsLoading(true)
        const message = new FormData()
        message.append('channel', `${props.channelId ? props.channelId : null}`)
        console.log(
            'content is...',
            editorState.getCurrentContent().getPlainText(),
        )

        const content = JSON.stringify(
            convertToRaw(editorState.getCurrentContent()),
        )
        console.log('send content is....', content)
        if (editorState.getCurrentContent().getPlainText().length > 0) {
            message.append(
                'content',
                editorState.getCurrentContent().getPlainText(),
                // stateToHTML(editorState.getCurrentContent())
                // JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            )
        }

        if (attachments.length > 0) {
            for (let i = 0; i < attachments.length; i++) {
                const attachmentNum = `attachment${i}`
                message.append(attachmentNum, attachments[i])
            }
            message.append('attachments_len', attachments.length)
        }

        createMessage(message).then((res) => {
            setIsLoading(false)
            if (res?.status === 201) {
                setEditorState(EditorState.createEmpty())
                setAttachments([])
                const objDiv: any = document.getElementById('chatMessageArea')
                objDiv.scrollTop = objDiv.scrollHeight
            }
        })
    }

    const addPersonToMention = (mentionObj: any) => {
        const stateWithEntity = editorState
            .getCurrentContent()
            .createEntity('mention', 'IMMUTABLE', {
                mention: mentionObj,
            })
        const entityKey = stateWithEntity.getLastCreatedEntityKey()
        const stateWithText = Modifier.insertText(
            stateWithEntity,
            editorState.getSelection(),
            mentionObj.name,
            undefined,
            entityKey,
        )
        const newEditorState = EditorState.push(
            editorState,
            stateWithText,
            'insert-characters',
        )

        Promise.resolve()
            .then(() => {
                setEditorState(newEditorState)
            })
            .then(() => {
                ref.current!.focus()

                // const selection = editorState.getSelection()

                // console.log("geet current  content...", editorState.getCurrentContent().getPlainText())
                // const cs = Modifier.insertText(
                //     editorState.getCurrentContent(),
                //     selection,
                //     ' ',
                // )

                // const newEditorState = EditorState.push(
                //     editorState,
                //     cs,
                //     'insert-characters',
                // )

                // Promise.resolve()
                //     .then(() => {
                //         setEditorState(newEditorState)
                //     })
                //     .then(() => {
                //         ref.current!.focus()
                //     })
            })
        // setEditorState(newEditorState)
        // setTimeout(() => {
        //     ref.current!.focus()
        // }, 5000)
    }

    return (
        <div className={classes.root}>
            <div
                style={{
                    border: '1px solid #ACB3BF',
                    borderRadius: '5px',
                    padding: '10px',
                }}
            >
                <div className={classes.messageTextDiv}>
                    <div className={classes.editorDiv}>
                        <Editor
                            editorKey={'editor'}
                            editorState={editorState}
                            onChange={setEditorState}
                            plugins={plugins}
                            ref={ref}
                        />
                        <MentionSuggestions
                            open={open}
                            onOpenChange={onOpenChange}
                            suggestions={suggestions}
                            onSearchChange={onSearchChange}
                            onAddMention={(data: any) => {
                                console.log('Add mention is.....', data)
                            }}
                        />

                        {/* <Editor
                        ref={editorRef}
                        editorState={editorState}
                        onChange={(editorState) => setEditorState(editorState)}
                    /> */}
                    </div>
                </div>
                <AttachmentContainer
                    attachments={attachments}
                    onRemoveFile={onRemoveFile}
                />
                <div className={classes.textBottomBar}>
                    <PopupState variant="popover" popupId="demoMenu">
                        {(popupState) => (
                            <React.Fragment>
                                <Tooltip
                                    title="Mention Someone"
                                    // classes={{ tooltip: classes.editorBtnToolTip }}
                                    arrow
                                >
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="span"
                                        className={classes.attchMentionBtn}
                                        {...bindTrigger(popupState)}
                                    >
                                        <AddSignIcon />
                                    </IconButton>
                                </Tooltip>

                                <Menu {...bindMenu(popupState)}>
                                    <MenuList
                                        dense
                                        className={classes.mentionList}
                                    >
                                        {mentionsList.length > 0 ? (
                                            mentionsList.map(
                                                (value: any, index: number) => (
                                                    <MenuItem
                                                        key={`mention-${index}`}
                                                        onClick={() => {
                                                            popupState.close()
                                                            addPersonToMention(
                                                                value,
                                                            )
                                                        }}
                                                    >
                                                        <ListItemAvatar>
                                                            <Avatar>
                                                                <ImageIcon />
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={value.name}
                                                        />
                                                    </MenuItem>
                                                ),
                                            )
                                        ) : (
                                            <MenuItem
                                                onClick={() => {
                                                    popupState.close()
                                                }}
                                            >
                                                No Suggestion
                                            </MenuItem>
                                        )}
                                    </MenuList>
                                </Menu>
                            </React.Fragment>
                        )}
                    </PopupState>

                    <span
                        style={{
                            color: '#D9D9D9',
                            fontWeight: 'bold',
                        }}
                    >
                        |
                    </span>

                    <input
                        // accept="image/*, .pdf, .doc, audio/mp3,audio/*;capture=microphone, video/mp4,video/x-m4v,video/*"
                        accept="image/png, image/jpeg, image/jpg"
                        id="icon-button-file"
                        type="file"
                        className={classes.fileInput}
                        multiple
                        onChange={(event) => onFileAttach(event)}
                    />
                    <label htmlFor="icon-button-file">
                        <Tooltip
                            title="Attach"
                            arrow
                            // classes={{ tooltip: classes.editorBtnToolTip }}
                        >
                            <IconButton
                                aria-label="upload picture"
                                component="span"
                                className={classes.attchMentionBtn}
                            >
                                <AttachIcon />
                            </IconButton>
                        </Tooltip>
                    </label>

                    <Tooltip
                        title="Send"
                        // classes={{ tooltip: classes.editorBtnToolTip }}
                        arrow
                    >
                        <IconButton
                            component="span"
                            onClick={() => sendMessage()}
                            style={{
                                width: '30px',
                                height: '24px',
                                padding: 0,
                                borderRadius: '5px',
                                float: 'right',
                            }}
                            disabled={isLoading}
                            className={
                                editorState.getCurrentContent().getPlainText()
                                    .length > 0 || attachments.length > 0
                                    ? classes.sendBtnActive
                                    : ''
                            }
                        >
                            {isLoading ? (
                                <LoopIcon className={classes.rotateIcon} />
                            ) : (
                                <SendMessageIcon />
                            )}
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}
