import React, { useMemo, useState } from 'react'
import {
    Checkbox,
    FormControl,
    InputAdornment,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    TextField,
    useTheme,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { PopoverState } from '../../../../../hooks/usePopover'
import { AuditPromptConfig } from '../../../../../models/Lease/types'

interface Props {
    popoverState: PopoverState<any>
    promptConfigs: AuditPromptConfig[]
    selectedConfigs: number[]
    setSelectedConfigs: (selectedConfigs: number[]) => void
}

export const FeatureSelectorPopover = (props: Props) => {
    const {
        popoverState,
        promptConfigs,
        selectedConfigs,
        setSelectedConfigs,
    } = props

    // signature audit config is a special case where it is a value stored on dd_lease but we need the user
    // to always select it since it handles the button that allows the user to perform the signature audit.
    // removing from this list, disallows the user from selecting it, but it is created in the table modal
    // so that it will be included
    const signatureAuditconfig = 68
    const promptConfigsRem = promptConfigs.filter(
        (item) => item.dd_lease_col_id !== signatureAuditconfig,
    )

    const theme = useTheme()

    const [searchText, setSearchText] = useState('')
    const [selectAllChecked, setSelectAllChecked] = useState(false)

    const filteredData = useMemo(() => {
        return promptConfigsRem.filter((item) =>
            item.display_name
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase()),
        )
    }, [searchText, promptConfigsRem])

    const handleSelection = (id: number) => {
        const currentIndex = selectedConfigs.indexOf(id)
        const newSelectedConfigs = [...selectedConfigs]

        if (currentIndex === -1) {
            newSelectedConfigs.push(id)
        } else {
            newSelectedConfigs.splice(currentIndex, 1)
        }

        setSelectedConfigs(newSelectedConfigs)
    }
    const allConfigs: number[] = []
    promptConfigsRem.forEach((item) => {
        allConfigs.push(item.dd_lease_col_id)
    })

    const handleSelectAll = () => {
        if (selectAllChecked) {
            setSelectedConfigs([])
            setSelectAllChecked(false)
        } else {
            setSelectedConfigs(allConfigs)
            setSelectAllChecked(true)
        }
    }

    let indeterminate: boolean = false
    if (
        selectedConfigs.length > 0 &&
        selectedConfigs.length < allConfigs.length
    ) {
        indeterminate = true
    }

    return (
        <Menu
            open={popoverState.isOpen}
            anchorEl={popoverState.anchorEl}
            onClose={() => {
                popoverState.handleClose()
                setTimeout(() => {
                    setSearchText('')
                }, 300)
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <ListSubheader style={{ backgroundColor: 'white' }}>
                <TextField
                    size="small"
                    autoFocus
                    value={searchText}
                    placeholder="Type to search..."
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key !== 'Escape') {
                            // Prevents autoselecting item while typing (default Select behaviour)
                            e.stopPropagation()
                        }
                    }}
                />
            </ListSubheader>
            <ListSubheader
                style={{
                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                }}
            >
                <FormControl onChange={handleSelectAll}>
                    <Checkbox
                        style={{
                            justifyContent: 'flex-start',
                        }}
                        checked={selectAllChecked}
                        indeterminate={indeterminate}
                    />
                </FormControl>
            </ListSubheader>

            {filteredData.map((item) => {
                return (
                    <MenuItem
                        key={`ITEM-${item.id}`}
                        onClick={() => {
                            handleSelection(item.dd_lease_col_id)
                        }}
                    >
                        <Checkbox
                            checked={
                                selectedConfigs.indexOf(item.dd_lease_col_id) >
                                -1
                            }
                        />
                        <ListItemText primary={item.display_name} />
                    </MenuItem>
                )
            })}
        </Menu>
    )
}
