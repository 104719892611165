import React, { useState } from 'react'
import { DDLease } from '../../../../../models'
import { Button, TextField, useTheme } from '@material-ui/core'
import { Container, NumberInput } from '../../../../../components'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { UpdateDDLeaseRequest } from '../../../Hooks'

interface Props {
    lease: DDLease
    updateDDLease: (request: UpdateDDLeaseRequest) => Promise<DDLease>
    handleClose: () => void
}

export const DDLeaseEditForm = (props: Props) => {
    const { lease, updateDDLease, handleClose } = props

    const [tenant, setTenant] = useState(lease.tenant ?? '')
    const [startDate, setStartDate] = useState(
        new Date(lease.lease_start ?? ''),
    )
    const [endDate, setEndDate] = useState(new Date(lease.lease_end ?? ''))
    const [location, setLocation] = useState(lease.unit ?? '')
    const [rent, setRent] = useState(Number(lease.rent ?? 0))

    const theme = useTheme()

    const textfieldStyle: React.CSSProperties = {
        marginTop: theme.spacing(2),
        width: '100%',
    }

    return (
        <Container
            style={{
                flexDirection: 'column',
                flex: 1,
                padding: theme.spacing(1),
            }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                }}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    value={tenant}
                    onChange={(e) => {
                        setTenant(e.target.value)
                    }}
                    label="Tenant"
                    style={{ ...textfieldStyle, marginTop: theme.spacing(0) }}
                />

                <TextField
                    fullWidth
                    variant="outlined"
                    value={location}
                    onChange={(e) => {
                        setLocation(e.target.value)
                    }}
                    label="Location"
                    style={{ ...textfieldStyle }}
                />

                <NumberInput
                    prefix={'$'}
                    label="Rent"
                    variant="outlined"
                    value={rent}
                    onChange={(e) => {
                        setRent(Number(e.target.value))
                    }}
                    style={{ ...textfieldStyle }}
                />

                <KeyboardDatePicker
                    value={startDate}
                    onChange={(date) => {
                        if (date) {
                            date.setHours(0, 0, 0, 0)
                            setStartDate(date)
                        }
                    }}
                    format="MM/dd/yyyy"
                    inputVariant="outlined"
                    style={{ ...textfieldStyle }}
                    label="Start Date"
                />

                <KeyboardDatePicker
                    value={endDate}
                    onChange={(date) => {
                        if (date) {
                            date.setHours(0, 0, 0, 0)
                            setEndDate(date)
                        }
                    }}
                    format="MM/dd/yyyy"
                    inputVariant="outlined"
                    style={{ ...textfieldStyle }}
                    label="End Date"
                />
            </Container>
            <Container
                style={{
                    margin: theme.spacing(1, 1, 1, 'auto'),
                }}
            >
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    style={{
                        textTransform: 'none',
                        margin: theme.spacing(1),
                        minWidth: 100,
                    }}
                    onClick={handleClose}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    style={{
                        margin: theme.spacing(1),
                        minWidth: 100,
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        const request: UpdateDDLeaseRequest = {
                            id: lease.id,
                        }
                        updateDDLease(request).then(() => {
                            handleClose()
                        })
                    }}
                >
                    Save
                </Button>
            </Container>
        </Container>
    )
}
