import React from 'react'
import {
    Company,
    EDIT_VENDOR_SUBSCRIPTION_STATUS,
    IdBoolMap,
} from '../../models'
import {
    Button,
    Divider,
    IconButton,
    Modal,
    Paper,
    Slide,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { Container } from '../../components'
import CloseIcon from '@material-ui/icons/Close'
import { axiosInstance } from '../../helpers'
import { toast } from 'react-toastify'
import { useUser } from '../../hooks'
import { hasPermission } from '../../models/Users/services'

interface Props {
    open: boolean
    property: Company
    availableTokens: number
    unlockedPropertyMap: IdBoolMap
    setUnlockedPropertyMap: (map: IdBoolMap) => void
    setAvailableTokens: (tokens: number) => void
    handleClose: () => void
}

export const BuyPropertyModal = (props: Props) => {
    const {
        open,
        property,
        handleClose,
        availableTokens,
        unlockedPropertyMap,
        setUnlockedPropertyMap,
        setAvailableTokens,
    } = props

    const theme = useTheme()

    const { workspaceUser } = useUser()

    const disabled = !hasPermission(
        workspaceUser,
        EDIT_VENDOR_SUBSCRIPTION_STATUS,
    )

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'auto',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: '600px',
                        height: '450px',
                        maxHeight: 'calc(100vh - 100px)',
                        maxWidth: 'calc(100vh - 100px)',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <Container
                        style={{
                            alignItems: 'center',
                            marginBottom: theme.spacing(1),
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Tooltip title="Cancel">
                            <IconButton
                                onClick={() => {
                                    handleClose()
                                }}
                            >
                                <CloseIcon
                                    fontSize="inherit"
                                    style={{
                                        marginRight: theme.spacing(1),
                                        color: theme.palette.darkGreen.main,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Container>
                    <Container flex={1} direction="column">
                        <Container
                            style={{
                                fontSize: '20px',
                                fontWeight: 400,
                                color: theme.palette.primary.dark,
                                justifyContent: 'center',
                            }}
                        >
                            You are buying
                        </Container>
                        <Container
                            style={{
                                fontWeight: 600,
                                fontSize: '30px',
                                justifyContent: 'center',
                                margin: theme.spacing(3, 0, 3, 0),
                            }}
                        >
                            {property.name}
                        </Container>
                        <Divider />
                        <Container
                            style={{
                                margin: theme.spacing(3, 6, 3, 6),
                            }}
                        >
                            <Container
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 400,
                                    color: theme.palette.primary.dark,
                                }}
                            >
                                Available EZ Tokens:
                            </Container>
                            <Container flex={1} />
                            <Container
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 500,
                                    justifyContent: 'flex-end',
                                }}
                            >
                                {availableTokens}
                            </Container>
                        </Container>
                        <Divider />
                        <Container
                            style={{
                                fontSize: '20px',
                                fontWeight: 500,
                                margin: theme.spacing(3, 6, 3, 6),
                            }}
                        >
                            <Container>Total:</Container>
                            <Container flex={1} />
                            <Container>1</Container>
                        </Container>
                    </Container>
                    <Container justifyContent="center">
                        <Button
                            variant="contained"
                            style={{
                                margin: theme.spacing(2),
                                backgroundColor: '#008C85',
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                                fontSize: '20px',
                                width: '200px',
                            }}
                            disabled={disabled || availableTokens < 1}
                            onClick={() => {
                                const body = {
                                    property: property.id,
                                }
                                axiosInstance
                                    .post(
                                        `company/vendor/unlock_property/`,
                                        body,
                                    )
                                    .then(() => {
                                        const tempUnlockedPropertyMap = unlockedPropertyMap
                                        tempUnlockedPropertyMap[
                                            property.id
                                        ] = true
                                        setUnlockedPropertyMap(
                                            tempUnlockedPropertyMap,
                                        )
                                        toast.success(
                                            `${property.name} unlocked`,
                                        )
                                        setAvailableTokens(availableTokens - 1)
                                        handleClose()
                                    })
                                    .catch((e) => {
                                        toast.error(e.response.data.message)
                                    })
                            }}
                        >
                            Purchase
                        </Button>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
