import { TableSortLabel, Theme } from '@material-ui/core'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { LeaseAudit, LeaseAuditStep } from '../../../../../models'
import { Container } from '../../../../../components'
import { usePagination } from '../../../../../hooks'
import { Pagination } from '@material-ui/lab'
import { useStyles } from '../../../../../styles'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { getDifferenceInDays, toMMDDYYYY } from '../../../../../helpers'
import { LeaseAuditSortMethods } from '../../../Hooks'

interface Props {
    leaseAuditList: LeaseAudit[]
    theme: Theme
    leaseAuditSortMethod: LeaseAuditSortMethods | null
    getLeaseAudit: (leaseAuditID: number) => void
    setLeaseAuditSortMethod: Dispatch<
        SetStateAction<LeaseAuditSortMethods | null>
    >
}

export const LeaseAuditTable = (props: Props) => {
    const {
        leaseAuditList,
        theme,
        leaseAuditSortMethod,
        getLeaseAudit,
        setLeaseAuditSortMethod,
    } = props

    const rowsPerPage = 10
    const { page, setPage, pageData, numPages, start, end } = usePagination(
        leaseAuditList,
        rowsPerPage,
    )

    const classes = useStyles()

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: 600,
        color: theme.palette.darkGreen.main,
        justifyContent: 'flex-start',
        flex: 1,
    }
    setLeaseAuditSortMethod(LeaseAuditSortMethods.CREATED_DATE_DESC)
    return (
        <Container
            style={{
                flexDirection: 'column',
                marginTop: theme.spacing(2),
                flex: 1,
            }}
        >
            <Container style={{ padding: theme.spacing(0, 2) }}>
                <Container
                    style={headCellStyle}
                    onClick={() => {
                        if (
                            leaseAuditSortMethod !==
                            LeaseAuditSortMethods.CREATED_DATE_ASC
                        ) {
                            setLeaseAuditSortMethod(
                                LeaseAuditSortMethods.CREATED_DATE_ASC,
                            )
                        } else {
                            setLeaseAuditSortMethod(
                                LeaseAuditSortMethods.CREATED_DATE_DESC,
                            )
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            leaseAuditSortMethod ===
                                LeaseAuditSortMethods.CREATED_DATE_ASC ||
                            leaseAuditSortMethod ===
                                LeaseAuditSortMethods.CREATED_DATE_DESC
                        }
                        direction={
                            leaseAuditSortMethod ===
                            LeaseAuditSortMethods.CREATED_DATE_ASC
                                ? 'asc'
                                : 'desc'
                        }
                    >
                        Created Date
                    </TableSortLabel>
                </Container>
                <Container style={headCellStyle}>Name</Container>
                <Container style={headCellStyle}>Step</Container>
                <Container style={headCellStyle}>Days Remaining</Container>
                <Container
                    style={headCellStyle}
                    onClick={() => {
                        if (
                            leaseAuditSortMethod !==
                            LeaseAuditSortMethods.RENT_ROLL_ASC
                        ) {
                            setLeaseAuditSortMethod(
                                LeaseAuditSortMethods.RENT_ROLL_ASC,
                            )
                        } else {
                            setLeaseAuditSortMethod(
                                LeaseAuditSortMethods.RENT_ROLL_DESC,
                            )
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            leaseAuditSortMethod ===
                                LeaseAuditSortMethods.RENT_ROLL_ASC ||
                            leaseAuditSortMethod ===
                                LeaseAuditSortMethods.RENT_ROLL_DESC
                        }
                        direction={
                            leaseAuditSortMethod ===
                            LeaseAuditSortMethods.RENT_ROLL_ASC
                                ? 'asc'
                                : 'desc'
                        }
                    >
                        # Rent Roll
                    </TableSortLabel>
                </Container>
                <Container style={headCellStyle}># Leases Uploaded</Container>
                <Container
                    style={headCellStyle}
                    onClick={() => {
                        if (
                            leaseAuditSortMethod !==
                            LeaseAuditSortMethods.MATCHED_COUNT_ASC
                        ) {
                            setLeaseAuditSortMethod(
                                LeaseAuditSortMethods.MATCHED_COUNT_ASC,
                            )
                        } else {
                            setLeaseAuditSortMethod(
                                LeaseAuditSortMethods.MATCHED_COUNT_DESC,
                            )
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            leaseAuditSortMethod ===
                                LeaseAuditSortMethods.MATCHED_COUNT_ASC ||
                            leaseAuditSortMethod ===
                                LeaseAuditSortMethods.MATCHED_COUNT_DESC
                        }
                        direction={
                            leaseAuditSortMethod ===
                            LeaseAuditSortMethods.MATCHED_COUNT_ASC
                                ? 'asc'
                                : 'desc'
                        }
                    >
                        # Matched Leases
                    </TableSortLabel>
                </Container>
                <Container
                    style={headCellStyle}
                    onClick={() => {
                        if (
                            leaseAuditSortMethod !==
                            LeaseAuditSortMethods.UNMATCHED_PDF_COUNT_ASC
                        ) {
                            setLeaseAuditSortMethod(
                                LeaseAuditSortMethods.UNMATCHED_PDF_COUNT_ASC,
                            )
                        } else {
                            setLeaseAuditSortMethod(
                                LeaseAuditSortMethods.UNMATCHED_PDF_COUNT_DESC,
                            )
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            leaseAuditSortMethod ===
                                LeaseAuditSortMethods.UNMATCHED_PDF_COUNT_ASC ||
                            leaseAuditSortMethod ===
                                LeaseAuditSortMethods.UNMATCHED_PDF_COUNT_DESC
                        }
                        direction={
                            leaseAuditSortMethod ===
                            LeaseAuditSortMethods.UNMATCHED_PDF_COUNT_ASC
                                ? 'asc'
                                : 'desc'
                        }
                    >
                        # Unmatched Leases
                    </TableSortLabel>
                </Container>
            </Container>
            <Container
                style={{
                    overflow: 'auto',
                    minHeight: 'calc(100vh  - 350px)',
                    maxHeight: 'calc(100vh  - 350px)',
                    flexDirection: 'column',
                }}
            >
                {pageData.map((leaseAudit) => {
                    return (
                        <LeaseAuditRow
                            key={`LEASE_AUDIT_ROW_${leaseAudit.id}`}
                            leaseAudit={leaseAudit}
                            theme={theme}
                            classes={classes}
                            getLeaseAudit={getLeaseAudit}
                        />
                    )
                })}
            </Container>
            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    minHeight: '40px',
                    maxHeight: '40px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: theme.spacing(1),
                }}
            >
                <Pagination
                    count={numPages}
                    page={page}
                    onChange={(_: unknown, newPage: number) => {
                        setPage(newPage)
                    }}
                />
                <span>
                    {start} - {end} of {leaseAuditList.length ?? 0}
                </span>
            </Container>
        </Container>
    )
}

interface LeaseAuditRowProps {
    leaseAudit: LeaseAudit
    theme: Theme
    classes: ClassNameMap
    getLeaseAudit: (leaseAuditID: number) => void
}

const LeaseAuditRow = (props: LeaseAuditRowProps) => {
    const { leaseAudit, theme, classes, getLeaseAudit } = props

    const cellStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        fontWeight: theme.typography.fontWeightMedium,
        flex: 1,
        justifyContent: 'flex-start',
        color: theme.palette.grey[700],
    }

    let stepString = 'Pay'
    if (leaseAudit.steps === LeaseAuditStep.UPLOAD_RENT_ROLL) {
        stepString = 'Upload Rent Roll'
    } else if (leaseAudit.steps === LeaseAuditStep.BASELINE) {
        stepString = 'Baseline'
    } else if (leaseAudit.steps === LeaseAuditStep.UPLOAD_LEASES) {
        stepString = 'Upload Lease PDFs'
    } else if (leaseAudit.steps === LeaseAuditStep.PROCESSING_LEASES) {
        stepString = 'Processing Leases'
    } else if (leaseAudit.steps === LeaseAuditStep.MATCHING) {
        stepString = 'Matching Leases'
    }

    const numberLeases =
        leaseAudit.matched_pdf_count + leaseAudit.unmatched_pdf_count

    let daysRemaining: string | number = ''
    if (
        leaseAudit.pdf_upload_deadline &&
        leaseAudit.pdf_upload_deadline !== ''
    ) {
        const today = new Date()
        daysRemaining = getDifferenceInDays(
            new Date(leaseAudit.pdf_upload_deadline),
            today,
        )
    }

    return (
        <Container
            style={{
                border: '1px solid',
                borderColor: theme.palette.grey[400],
                borderRadius: '10px 10px 10px 10px',
                flexDirection: 'row',
                padding: theme.spacing(2),
                marginTop: theme.spacing(1),
                cursor: 'pointer',
                flex: 1,
                alignItems: 'center',
                minHeight: '80px',
                maxHeight: '80px',
            }}
            className={classes.hoverGrey300}
            onClick={() => {
                getLeaseAudit(leaseAudit.id)
            }}
        >
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {toMMDDYYYY(new Date(leaseAudit.created_date))}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {leaseAudit.name}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {stepString}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {daysRemaining}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {leaseAudit.rent_roll_count}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {numberLeases}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {leaseAudit.matched_pdf_count}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {leaseAudit.unmatched_pdf_count}
            </Container>
        </Container>
    )
}
