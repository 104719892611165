import { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { useAppDispatch } from '.'
import { BaseWorkorder, ModelListMap } from '../models'

import {
    BulkUpdateWorkOrderRequest,
    bulkUpdateWorkorders,
    createWorkorder,
    CreateWorkorderActionThunk,
    CreateWorkOrderRequest,
    getWorkOrderList,
    GetWorkorderListActionThunk,
    _GetWorkOrderListRequest,
    GET_WORKORDER_LIST_REQUEST,
    RootState,
    setWorkOrderList,
    updateWorkorder,
    UpdateWorkOrderRequest,
    UPDATE_WORKORDER,
    UPDATE_WORKORDER_REQUEST,
    WorkorderActionTypes,
    WorkorderDetailActionThunk,
} from '../store'

type UseWorkorder = {
    workorderList: BaseWorkorder[]
    unitWorkorderMap: ModelListMap<BaseWorkorder>
    loadingList: boolean
    workordersLoaded: boolean
    getWorkorderList: (
        req: _GetWorkOrderListRequest,
    ) => GetWorkorderListActionThunk
    setWorkOrderList: (workorderList: BaseWorkorder[]) => WorkorderActionTypes
    createWorkorder: (req: CreateWorkOrderRequest) => CreateWorkorderActionThunk
    updateWorkorder: (req: UpdateWorkOrderRequest) => WorkorderDetailActionThunk
    bulkUpdateWorkorders: (
        req: BulkUpdateWorkOrderRequest,
    ) => GetWorkorderListActionThunk
    incrementWorkorderMessageCount: (workorder: BaseWorkorder) => void
    incrementUnitNotesMessageCount: (workorder: BaseWorkorder) => void
    resetUnitNotesUnreadCount: (workorder: BaseWorkorder) => void
}

export const _useWorkorder = (scheduleId?: number): UseWorkorder => {
    const dispatch = useAppDispatch()

    const workorderList = useSelector(
        (state: RootState) => state.workorder.workorder.workorderList,
        shallowEqual,
    )

    const loadingList = useSelector(
        (state: RootState) =>
            state.workorder.workorder.isLoading[GET_WORKORDER_LIST_REQUEST],
    )

    // This is a side effect of the workorder reducer never storing undefined for the workorder list
    const [requestFinished, setRequestFinished] = useState(false)

    const [unitWorkorderMap, setUnitWorkorderMap] = useState<
        ModelListMap<BaseWorkorder>
    >({})

    useEffect(() => {
        if (scheduleId) {
            dispatch(
                getWorkOrderList({ params: { schedule: scheduleId } }),
            ).finally(() => setRequestFinished(true))
        }
    }, [scheduleId])

    useEffect(() => {
        const unitWorkorderMap: ModelListMap<BaseWorkorder> = {
            readyForUse: true,
        }
        workorderList.forEach((wo) => {
            if (wo.unit_id) {
                if (unitWorkorderMap[wo.unit_id] === undefined) {
                    unitWorkorderMap[wo.unit_id] = []
                }
                unitWorkorderMap[wo.unit_id]!.push(wo)
            }
        })

        setUnitWorkorderMap(unitWorkorderMap)
    }, [workorderList])

    const incrementWorkorderMessageCount = (workorder: BaseWorkorder) => {
        dispatch({
            type: UPDATE_WORKORDER,
            workorder: {
                ...workorder,
                message_count: workorder.message_count + 1,
            },
            workorderId: workorder.id,
            endLoading: UPDATE_WORKORDER_REQUEST,
        })
    }

    const incrementUnitNotesMessageCount = (workorder: BaseWorkorder) => {
        dispatch({
            type: UPDATE_WORKORDER,
            workorder: {
                ...workorder,
                unit_notes_msg_count: (workorder.unit_notes_msg_count ?? 0) + 1,
            },
            workorderId: workorder.id,
            endLoading: UPDATE_WORKORDER_REQUEST,
        })
    }

    const resetUnitNotesUnreadCount = (workorder: BaseWorkorder) => {
        dispatch({
            type: UPDATE_WORKORDER,
            workorder: {
                ...workorder,
                unread_unit_notes: 0,
            },
            workorderId: workorder.id,
            endLoading: UPDATE_WORKORDER_REQUEST,
        })
    }

    return {
        workorderList: workorderList,
        loadingList: loadingList,
        workordersLoaded: requestFinished,
        unitWorkorderMap: unitWorkorderMap,
        getWorkorderList: (req) => dispatch(getWorkOrderList(req)),
        setWorkOrderList: (woList) => dispatch(setWorkOrderList(woList)),
        createWorkorder: (req) => dispatch(createWorkorder(req)),
        updateWorkorder: (req) => dispatch(updateWorkorder(req)),
        bulkUpdateWorkorders: (req) => dispatch(bulkUpdateWorkorders(req)),
        incrementWorkorderMessageCount: incrementWorkorderMessageCount,
        incrementUnitNotesMessageCount: incrementUnitNotesMessageCount,
        resetUnitNotesUnreadCount: resetUnitNotesUnreadCount,
    }
}

export type _WorkorderController = ReturnType<typeof _useWorkorder>
