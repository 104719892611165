import {
    InfrastructureFilterActionTypes,
    InfrastructureFilterOptions,
    SET_INFRASTRUCTURE_FILTER,
} from './types'

export const setInfrastructureFilter = (
    newFilter?: InfrastructureFilterOptions,
): InfrastructureFilterActionTypes => {
    return {
        type: SET_INFRASTRUCTURE_FILTER,
        newFilter: newFilter,
    }
}
