import { AppRequests } from '..'
import { Action } from 'redux'

export const SET_NEXT_PREV = 'SET_NEXT_PREV'

interface UpdatePrevNextAction extends Action {
    type: typeof SET_NEXT_PREV
    request: AppRequests
    next: string
    prev: string
    count: number
}

export type PaginationActionTypes = UpdatePrevNextAction

export type PaginationState = {
    [key in AppRequests]?: {
        next?: string
        prev?: string
        count: number
    }
}
