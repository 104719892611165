import React from 'react'

import {
    LinearProgress,
    LinearProgressProps,
    makeStyles,
    createStyles,
    Theme,
} from '@material-ui/core'

interface BarProps {
    barColor: string
    customStyle?: React.CSSProperties
}

export const StyledProgressBar = (props: LinearProgressProps & BarProps) => {
    const { barColor, customStyle, ...barProps } = props

    const classes = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                height: 10,
                borderRadius: 5,
            },
            colorPrimary: {
                backgroundColor:
                    theme.palette.grey[
                        theme.palette.type === 'light' ? 300 : 700
                    ],
            },
            bar: {
                borderRadius: 5,
                backgroundColor: barColor,
            },
        }),
    )()

    return (
        <LinearProgress
            style={{ width: '100%', ...customStyle }}
            {...barProps}
            classes={classes}
        />
    )
}
