import { AxiosError } from 'axios'
import { AppThunk, ErrorResponse, setNetworkError } from '..'
import { axiosInstance } from '../../helpers'
import { User, CurrentChannel, Message } from '../../models'
import { BaseUserListThunk } from '../user'
import {
    AddMemberRequest,
    ADD_MEMBER_REQUEST,
    ADD_MEMBER_RESPONSE,
    ChannelDetailActionThunk,
    CreateChannelRequest,
    CREATE_CHANNEL_REQUEST,
    CREATE_CHANNEL_RESPONSE,
    GetChannelDetailRequest,
    GetChannelListActionThunk,
    GetChannelListRequest,
    GET_CHANNEL_DETAIL_REQUEST,
    GET_CHANNEL_DETAIL_RESPONSE,
    GET_CHANNEL_LIST_REQUEST,
    GET_CHANNEL_LIST_RESPONSE,
    REMOVE_MEMBER_REQUEST,
    REMOVE_MEMBER_RESPONSE,
    GetMessageListRequest,
    GetMessageListActionThunk,
    GET_MESSAGE_LIST_REQUEST,
    GET_MESSAGE_LIST_RESPONSE,
    MessageDetailActionThunk,
    CREATE_MESSAGE_REQUEST,
    CREATE_MESSAGE_RESPONSE,
    RESET_MESSAGE_STATE,
    CLEAR_CHANNEL_UNREAD,
    MessagingActionTypes,
    RemoveMemberRequest,
    SET_CURRENT_CHANNEL,
    MessagingRequest,
    SET_MESSAGING_LOADING,
    GET_MESSAGING_USER_OPTION_LIST,
    SET_MESSAGING_USER_OPTION_LIST,
} from './types'

export const getChannelList = (
    req: GetChannelListRequest,
): AppThunk<GetChannelListActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: GET_CHANNEL_LIST_REQUEST,
        })

        const url = 'messaging/channel/'

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch({
                type: GET_CHANNEL_LIST_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getChannelDetail = (
    req: GetChannelDetailRequest,
): AppThunk<ChannelDetailActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: GET_CHANNEL_DETAIL_REQUEST,
        })

        const url = `messaging/channel/${req.channelId}/`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch({
                type: GET_CHANNEL_DETAIL_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createChannel = (
    req: CreateChannelRequest,
): AppThunk<ChannelDetailActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: CREATE_CHANNEL_REQUEST,
        })

        const url = `messaging/channel/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch({
                type: CREATE_CHANNEL_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const addMember = (
    req: AddMemberRequest,
): AppThunk<ChannelDetailActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: ADD_MEMBER_REQUEST,
        })

        const url = `messaging/channel/${req.channelId}/add_member/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch({
                type: ADD_MEMBER_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const removeMember = (
    req: RemoveMemberRequest,
): AppThunk<ChannelDetailActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: REMOVE_MEMBER_REQUEST,
        })

        const url = `messaging/channel/${req.channelId}/remove_member/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch({
                type: REMOVE_MEMBER_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getMessageList = (
    req: GetMessageListRequest,
): AppThunk<GetMessageListActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: GET_MESSAGE_LIST_REQUEST,
        })

        const url = `messaging/channel/${req.channelId}/messages/`

        try {
            // success
            const response = await axiosInstance.get(url)
            dispatch(setMessageList(response.data))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            const displayError = error.response?.data.key !== undefined
            dispatch(setNetworkError(error, displayError))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createMessage = (
    req: FormData,
): AppThunk<MessageDetailActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: CREATE_MESSAGE_REQUEST,
        })

        const url = `messaging/create-message/`

        try {
            // success
            const response = await axiosInstance.post(url, req)

            dispatch({
                type: CREATE_MESSAGE_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getMessagingUserOptionList = (): AppThunk<BaseUserListThunk> => {
    return async (dispatch) => {
        dispatch(setMessagingLoading(GET_MESSAGING_USER_OPTION_LIST, true))

        const url = `user/workforce/`

        try {
            const response = await axiosInstance.get(url)

            dispatch(setMessagingUserOptionList(response.data))
            dispatch(setMessagingLoading(GET_MESSAGING_USER_OPTION_LIST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setMessagingLoading(GET_MESSAGING_USER_OPTION_LIST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const setMessagingLoading = (
    request: MessagingRequest,
    loading: boolean,
): MessagingActionTypes => {
    return {
        type: SET_MESSAGING_LOADING,
        loading: loading,
        request: request,
    }
}

export const setMessagingUserOptionList = (
    userList?: User[],
): MessagingActionTypes => {
    return {
        type: SET_MESSAGING_USER_OPTION_LIST,
        messagingUserOptionList: userList,
    }
}

export const resetMessageState = (): MessagingActionTypes => {
    return {
        type: RESET_MESSAGE_STATE,
    }
}

export const clearChannelUnread = (channelId: number): MessagingActionTypes => {
    return {
        type: CLEAR_CHANNEL_UNREAD,
        channelId: channelId,
    }
}

export const setCurrentChannel = (
    channel?: CurrentChannel,
    endLoading?: MessagingRequest,
): MessagingActionTypes => {
    return {
        type: SET_CURRENT_CHANNEL,
        channel: channel,
        endLoading: endLoading,
    }
}

export const setMessageList = (
    messageList: Message[],
): MessagingActionTypes => {
    return {
        type: GET_MESSAGE_LIST_RESPONSE,
        payload: messageList,
    }
}
