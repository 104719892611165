import { AxiosResponse } from 'axios'
import { Service } from '../../models'
import { Action } from 'redux'

// Requests
export interface GetServiceListRequest {}

// Responses

// actions
export const GET_SERVICE_LIST_REQUEST = 'GET_SERVICE_LIST_REQUEST'
export const GET_SERVICE_LIST_RESPONSE = 'GET_SERVICE_LIST_RESPONSE'
export const SET_SERVICE_LIST = 'SET_SERVICE_LIST'

interface GetServiceListRequestAction extends Action {
    type: typeof GET_SERVICE_LIST_REQUEST
}

interface GetServiceListResponseAction extends Action {
    type: typeof GET_SERVICE_LIST_RESPONSE
    payload: Service[]
}

interface SetServiceListAction extends Action {
    type: typeof SET_SERVICE_LIST
    payload: Service[]
}

type GetServiceListActionTypes =
    | GetServiceListRequestAction
    | GetServiceListResponseAction

export type ServiceActionTypes =
    | GetServiceListActionTypes
    | SetServiceListAction

// thunks
export type GetServiceListActionThunk = Promise<AxiosResponse<Service[]>>

// reducers
export interface ServiceState {
    serviceList: Service[]
    isLoading: {
        [GET_SERVICE_LIST_REQUEST]: boolean
    }
}
