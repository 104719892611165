import React, { useState } from 'react'
import { IconButton, TextField } from '@material-ui/core'
import { Container } from '../Container'

import SaveIcon from '@material-ui/icons/Save'
import ClearIcon from '@material-ui/icons/Clear'
import { Folder } from '../../models'

interface Props {
    folder: Folder
    onSave: (name: string) => void
    onCancel: () => void
}

export const FinderEditFolder = (props: Props) => {
    const [newName, setNewName] = useState(props.folder.name)

    return (
        <Container style={{ flex: 1 }}>
            <TextField
                value={newName}
                onChange={(e) => {
                    setNewName(e.target.value)
                }}
            />

            <IconButton size="small" onClick={props.onCancel}>
                <ClearIcon fontSize="small" />
            </IconButton>

            <div style={{ flex: 1 }} />
            <IconButton size="small" onClick={() => props.onSave(newName)}>
                <SaveIcon fontSize="small" />
            </IconButton>
        </Container>
    )
}
