import React, { useState, useMemo } from 'react'
import {
    Chip,
    FormControl,
    InputAdornment,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core'
import { IdentifiableObject } from '../../models'
import SearchIcon from '@material-ui/icons/Search'
import { Pagination } from '@material-ui/lab'
import { usePagination } from '../../hooks'
import { Container } from '../Container'
import { useMultiSelectStyles } from './styles'

interface Style {
    formControl?: React.CSSProperties
    inputLabel?: React.CSSProperties
    select?: React.CSSProperties
    menuItem?: React.CSSProperties
    text?: React.CSSProperties
}

interface Props<T extends IdentifiableObject> {
    label?: string
    currentValue: number | number[] | string
    variant?: 'outlined' | 'standard' | 'filled' | undefined
    size?: 'small' | 'medium' | undefined
    onChange: (
        event: React.ChangeEvent<{
            value: unknown
        }>,
    ) => void
    data: T[]
    getDisplayString: (d: T) => string
    getOrnament?: (d: T) => JSX.Element
    customStyle?: Style
    disabled?: boolean
    error?: boolean
    noSelectionLabel?: string
    searchable?: boolean
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    maxItems?: number
    multiple?: boolean
}

export const Selector = <T extends IdentifiableObject>(props: Props<T>) => {
    const {
        label,
        currentValue,
        onChange,
        data,
        getDisplayString,
        customStyle,
        disabled,
        error,
        noSelectionLabel,
        searchable,
        onClick,
        size,
    } = props

    const [searchText, setSearchText] = useState('')

    const classes = useMultiSelectStyles()

    let sanitizedData = data
    if (noSelectionLabel) {
        const placeHolder: any = { id: -1, name: noSelectionLabel }
        sanitizedData = [placeHolder, ...data]
    }

    let searchTextBox: JSX.Element | null = null
    if (searchable) {
        searchTextBox = (
            <ListSubheader style={{ backgroundColor: 'white' }}>
                <TextField
                    size="small"
                    // Autofocus on textfield
                    autoFocus
                    placeholder="Type to search..."
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key !== 'Escape') {
                            // Prevents autoselecting item while typing (default Select behaviour)
                            e.stopPropagation()
                        }
                    }}
                />
            </ListSubheader>
        )
    }

    const containsText = (text: string, searchText: string) => {
        return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    }

    const allOptions = useMemo(
        () =>
            sanitizedData.filter((option) =>
                containsText(getDisplayString(option), searchText),
            ),
        [searchText, data],
    )

    const { page, setPage, pageData, start, end } = usePagination(
        allOptions,
        props.maxItems,
    )

    let displayedOptions = allOptions

    let pagination: JSX.Element | null = null
    if (props.maxItems !== undefined) {
        displayedOptions = pageData
        pagination = (
            <Container style={{ padding: 8, alignItems: 'center' }}>
                <Pagination
                    page={page}
                    onChange={(e, value) => {
                        e.stopPropagation()
                        setPage(value)
                    }}
                    count={Math.floor(allOptions.length / props.maxItems)}
                    size={'small'}
                />
                <span>
                    {start} - {end} of {allOptions.length}
                </span>
            </Container>
        )
    }

    const renderValue = props.multiple
        ? (selected: unknown) => {
              return (
                  <div className={classes.chips}>
                      {(selected as number[]).map((value) => {
                          const item = data.find((s) => s.id === value)

                          if (item) {
                              const label = getDisplayString(item)
                              return (
                                  <Chip
                                      key={`SELECTION-${label}-${item.id}`}
                                      label={label}
                                      className={classes.chip}
                                  />
                              )
                          }
                      })}
                  </div>
              )
          }
        : undefined

    return (
        <FormControl
            variant={props.variant ?? 'outlined'}
            style={{ ...customStyle?.formControl }}
            size={size ?? undefined}
        >
            {label && (
                <InputLabel
                    id={`assign-${label}-label`}
                    style={customStyle?.inputLabel}
                >
                    {label}
                </InputLabel>
            )}
            <Select
                labelId={`assign-${label}-label`}
                id={`assign-${label}-select`}
                value={currentValue}
                disabled={disabled}
                onChange={onChange}
                label={label}
                style={customStyle?.select}
                error={error}
                onAnimationEndCapture={() => setSearchText('')}
                onClick={onClick}
                multiple={props.multiple}
                renderValue={renderValue}
            >
                {searchTextBox}
                {pagination}
                {displayedOptions.map((d) => {
                    let ornament: JSX.Element | null = null
                    if (props.getOrnament) {
                        ornament = props.getOrnament(d)
                    }

                    return (
                        <MenuItem
                            key={`MODAL_SELECT_${label}_${d.id}`}
                            value={d.id}
                        >
                            <Container style={customStyle?.menuItem}>
                                {ornament}
                                <span style={customStyle?.text}>
                                    {getDisplayString(d)}
                                </span>
                            </Container>
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}
