import { useState, useEffect } from 'react'
import { axiosInstance } from '../helpers'
import { toast } from 'react-toastify'
import { string } from 'yargs'

export const useAnalyticData = () => {
    const [loading, setLoading] = useState(false)
    const [propertyGradeList, setPropertyGradeList] = useState<
        PropertyGradeResponseData[] | null
    >(null)

    const [chartData, setChartData] = useState<ChartData | null>(null)

    const [inspectionTypeGrades, setInspectionTypeGrades] = useState<
        InspectionTypeGrades[] | null
    >(null)

    const [inspectionDetailGrades, setInspectionDetailGrades] = useState<
        InspectionDetailGrades[] | null
    >(null)

    const getPropertyGradeList = (timeline?: {
        startDate?: Date
        endDate?: Date
    }) => {
        setLoading(true)
        axiosInstance
            .get('inspection/property-grade/', {
                params: {
                    end_date: timeline?.endDate,
                    start_date: timeline?.startDate,
                },
            })
            .then((res) => {
                setPropertyGradeList(res.data)
            })
            .catch((reason) => {
                toast.error('Sorry the network request failed.')
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const getChartData = (
        apartmentId: number,
        timeline?: { startDate?: Date; endDate?: Date },
    ) => {
        axiosInstance
            .get('inspection/trend-chart-grade/', {
                params: {
                    apartment_id: apartmentId,
                    end_date: timeline?.endDate,
                    start_date: timeline?.startDate,
                },
            })
            .then((res) => {
                setChartData(res.data)
            })
            .catch((reason) => {
                toast.error('Sorry the network request failed.')
            })
    }
    const getInspectionTypeGrades = (
        apartmentId: number,
        timeline?: { startDate?: Date; endDate?: Date },
    ) => {
        axiosInstance
            .get('inspection/inspection-type-grade/', {
                params: {
                    apartment_id: apartmentId,
                    end_date: timeline?.endDate,
                    start_date: timeline?.startDate,
                },
            })
            .then((res) => {
                setInspectionTypeGrades(res.data)
            })
            .catch((reason) => {
                toast.error('Sorry the network request failed.')
            })
    }
    const getInspectionDetailGrades = (
        apartmentId: number,
        inspectionTypeId: number,
        timeline?: { startDate?: Date; endDate?: Date },
    ) => {
        axiosInstance
            .get('inspection/inspection-detail-grade/', {
                params: {
                    apartment_id: apartmentId,
                    end_date: timeline?.endDate,
                    start_date: timeline?.startDate,
                    inspection_type_id: inspectionTypeId,
                },
            })
            .then((res) => {
                setInspectionDetailGrades(res.data)
            })
            .catch((reason) => {
                toast.error('Sorry the network request failed.')
            })
    }

    useEffect(getPropertyGradeList, [])
    return {
        loading,
        propertyGradeList,
        setPropertyGradeList,
        getPropertyGradeList,
        chartData,
        getChartData,
        inspectionTypeGrades,
        setInspectionTypeGrades,
        getInspectionTypeGrades,
        inspectionDetailGrades,
        setInspectionDetailGrades,
        getInspectionDetailGrades,
    }
}

export interface PropertyGradeResponseData {
    id: number
    apartment_id: number
    name: string
    building_score: number
    inspector_score: number
    bips: number
    trend_value: number
}

export interface Coordinate {
    x: string
    y: number
}

export interface ChartData {
    id: number
    bips: Coordinate[]
    trendline: Coordinate[]
    average: Coordinate[]
}
export interface InspectionTypeGrades {
    id: number
    apartment_id: number
    inspection_type_name: string
    inspection_type_id: number
    flags: number
    potential_flags: number
    units_inspected: number
    units_requiring_inspection: number
    building_score: number
    inspector_score: number
    type_score: number
    type_weight: number
}

export interface InspectionDetailGrades {
    id: number
    name: string
    end_date: string
    flags: number
    potential_flags: number
    units_inspected: number
    units_requiring_inspection: number
}
