import React from 'react'
import {
    Checkbox,
    Tooltip,
    useTheme,
    FormControlLabel,
} from '@material-ui/core'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import NumberFormat from 'react-number-format'
import { DateRange, Range } from 'react-date-range'

import { Container, StatusBadge, NumberInput } from '../../../../components'
import { useUser } from '../../../../hooks'
import {
    BaseWorkorder,
    getTotalPriceExpense,
    getUiString,
    isWorkorderSafe,
    SEE_VENDOR_FINANCES,
} from '../../../../models'
import { hasPermission } from '../../../../models/Users/services'
import { ServiceAreaChangeState } from './ChangeOrderModal'

interface Props {
    workorder?: BaseWorkorder
    serviceAreaState: ServiceAreaChangeState
    headline?: string
}

export const ChangeOrderViewWorkorderPanel = (props: Props) => {
    const { workorder, serviceAreaState } = props

    const theme = useTheme()

    const { workspaceUser } = useUser()

    const columnStyle: React.CSSProperties = {
        flex: 2,
        margin: theme.spacing(1),
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.shape.borderRadius,
        flexDirection: 'column',
    }

    const titleStyle: React.CSSProperties = {
        ...theme.typography.h5,
        fontWeight: 'bold',
        color: theme.palette.grey[700],
    }

    const subTitleStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: 'bold',
        color: theme.palette.grey[700],
    }

    const bodyStyle: React.CSSProperties = {
        ...theme.typography.body1,
        color: theme.palette.grey[700],
    }

    let body = <span style={titleStyle}>Workorder Not Found</span>
    if (workorder) {
        const dateRange: Range[] = []
        let priority = false
        if (isWorkorderSafe(workorder)) {
            priority = workorder.priority
            dateRange.push({
                startDate: workorder.startDate,
                endDate: workorder.endDate,
            })
        }

        body = (
            <>
                {/* Service + status */}
                <Container style={{ alignItems: 'center', height: 35 }}>
                    <span style={subTitleStyle}>
                        {workorder.service_name ?? ''}
                    </span>

                    {hasPermission(workspaceUser, SEE_VENDOR_FINANCES) && (
                        <NumberFormat
                            prefix="$"
                            thousandSeparator
                            value={getTotalPriceExpense(workorder)}
                            displayType="text"
                            style={{
                                ...subTitleStyle,
                                marginLeft: theme.spacing(1),
                            }}
                        />
                    )}
                    <div style={{ flex: 1 }} />
                    <StatusBadge
                        text={getUiString(workorder.status)}
                        customStyle={{
                            text: {
                                fontWeight: theme.typography.fontWeightBold,
                                color:
                                    theme.palette[workorder.status]
                                        .contrastText,
                            },
                            container: {
                                backgroundColor:
                                    theme.palette[workorder.status].main,
                            },
                        }}
                    />
                </Container>

                {/* Location */}
                <Container style={{ height: 35 }}>
                    <span style={bodyStyle}>
                        {workorder.path ?? ''}
                        {workorder.folder_name ?? ''}
                    </span>
                    <span
                        style={{
                            ...bodyStyle,
                            fontWeight: theme.typography.fontWeightBold,
                            marginLeft: theme.spacing(2),
                        }}
                    >
                        {workorder.unit_name ?? ''}
                    </span>
                </Container>

                {/* Date + vendor + add / custom price */}
                <Container style={{ marginTop: theme.spacing(1) }}>
                    <DateRange
                        ranges={dateRange}
                        fixedHeight
                        onChange={() => {}}
                    />

                    <Container
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        <span style={subTitleStyle}>
                            {workorder.vendor_name}
                        </span>

                        <Container>
                            {priority && (
                                <Tooltip title="Priority">
                                    <ReportProblemIcon
                                        color="secondary"
                                        fontSize="large"
                                    />
                                </Tooltip>
                            )}
                        </Container>

                        <NumberInput
                            prefix={'$'}
                            variant="outlined"
                            label="Add Price"
                            value={workorder.add_price}
                            disabled
                            onChange={() => {}}
                            style={{ marginTop: theme.spacing(2) }}
                        />

                        <NumberInput
                            prefix={'$'}
                            variant="outlined"
                            label="Custom Price"
                            value={workorder.custom_price}
                            disabled
                            onChange={() => {}}
                            style={{ marginTop: theme.spacing(2) }}
                        />
                    </Container>
                </Container>

                <Container>
                    <Container
                        style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <span style={subTitleStyle}>Service Areas</span>
                        {/* Initial Values */}
                        {Object.keys(serviceAreaState).map((strKey) => {
                            const areaId = Number(strKey)
                            return (
                                <FormControlLabel
                                    key={`EXISTING_SA_${strKey}`}
                                    control={
                                        <Checkbox
                                            checked={
                                                serviceAreaState[areaId].initial
                                            }
                                            disabled
                                        />
                                    }
                                    labelPlacement="start"
                                    label={serviceAreaState[areaId].label}
                                    style={{ marginLeft: 0 }}
                                />
                            )
                        })}
                    </Container>
                </Container>
            </>
        )
    }

    return (
        <Container
            style={{
                flex: 2,
                flexDirection: 'column',
                margin: theme.spacing(1),
            }}
        >
            <span style={titleStyle}>
                {props.headline ?? 'Existing Workorder'}
            </span>
            <Container
                style={{
                    ...columnStyle,
                    margin: 0,
                    padding: theme.spacing(1),
                }}
            >
                {body}
            </Container>
        </Container>
    )
}
