import { Chip, Tooltip, useTheme } from '@material-ui/core'
import React from 'react'
import { toMMDDYYYY } from '../../../../helpers'
import {
    AutoCompleteFilterValue,
    DateFilterOption,
    DateFilterState,
    IssueFilterValue,
    LeaseStatusFilterState,
    TenantInspectionStatusFilterState,
} from '../../../../hooks/useLeaseFilter'
import {
    InspectionStatusType,
    LeaseStatusType,
    Unit,
    getInspectionStatusLabel,
    getLeaseStatusString,
} from '../../../../models'
import { Container, getFinderChips } from '../../../../components'
import { FinderSelection } from '../../../../hooks'

interface Props {
    dateFilter: DateFilterState
    setDateFilter: (dateFilter: DateFilterState) => void
    locationFinderSelection: FinderSelection
    statusFilter: LeaseStatusFilterState
    setStatusFilter: (sf: LeaseStatusFilterState) => void
    hasIssue: IssueFilterValue
    setHasIssue: (hasIssue: IssueFilterValue) => void
    tenantInspectionStatusFilter: TenantInspectionStatusFilterState
    setTenantInspectionStatusFilter: (
        newFilter: TenantInspectionStatusFilterState,
    ) => void
    autoCompleteFilter: AutoCompleteFilterValue
    setAutoCompleteFilter: (val: AutoCompleteFilterValue) => void
}

export const LeaseHomeChips = (props: Props) => {
    const {
        dateFilter,
        setDateFilter,
        locationFinderSelection,
        statusFilter,
        setStatusFilter,
        hasIssue,
        setHasIssue,
        tenantInspectionStatusFilter,
        setTenantInspectionStatusFilter,
        autoCompleteFilter,
        setAutoCompleteFilter,
    } = props

    const theme = useTheme()

    return (
        <Container
            style={{
                flex: 1,
                minHeight: 80,
                maxHeight: 80,
                marginTop: 8,
                flexWrap: 'wrap',
                overflowY: 'auto',
            }}
        >
            {Object.keys(statusFilter).map((status) => {
                const numStatus: LeaseStatusType = Number(status) as any
                if (statusFilter[numStatus]) {
                    return (
                        <StatusChip
                            key={status}
                            status={numStatus}
                            statusFilter={statusFilter}
                            setStatusFilter={setStatusFilter}
                        />
                    )
                }
                return null
            })}

            {dateFilter.startDate.date && (
                <DateChip
                    prefix="Start"
                    dateFilter={dateFilter.startDate}
                    onClick={() => {
                        setDateFilter({
                            ...dateFilter,
                            startDate: {
                                ...dateFilter.startDate,
                                date: null,
                            },
                        })
                    }}
                />
            )}

            {dateFilter.endDate.date && (
                <DateChip
                    prefix="End"
                    dateFilter={dateFilter.endDate}
                    onClick={() => {
                        setDateFilter({
                            ...dateFilter,
                            endDate: {
                                ...dateFilter.endDate,
                                date: null,
                            },
                        })
                    }}
                />
            )}

            {getFinderChips(
                locationFinderSelection.selection,
                'unit',
                (location) => {
                    const unit = location.location as Unit
                    return (
                        <Tooltip
                            title={`${unit.folder.path}${unit.folder.name}`}
                            key={`unit_CHIP_${unit.id}`}
                        >
                            <Chip
                                clickable
                                style={{
                                    ...chipStyle,
                                    backgroundColor:
                                        theme.palette.secondary.main,
                                    color: '#FFF',
                                }}
                                onClick={() => {
                                    locationFinderSelection.setFinderSelection(
                                        location,
                                    )
                                }}
                                label={unit.name}
                            />
                        </Tooltip>
                    )
                },
            )}
            {/* 
            {hasIssue !== IssueFilterValue.NA && (
                <Chip
                    clickable
                    style={chipStyle}
                    label="Has Issue"
                    onClick={() => {
                        setHasIssue(IssueFilterValue.NA)
                    }}
                />
            )} */}

            {hasIssue === IssueFilterValue.HAS_ISSUE && (
                <Chip
                    clickable
                    style={chipStyle}
                    label="Has Issue"
                    onClick={() => {
                        setHasIssue(IssueFilterValue.NA)
                    }}
                />
            )}

            {hasIssue === IssueFilterValue.NO_ISSUE && (
                <Chip
                    clickable
                    style={chipStyle}
                    label="No Issues"
                    onClick={() => {
                        setHasIssue(IssueFilterValue.NA)
                    }}
                />
            )}

            {autoCompleteFilter === AutoCompleteFilterValue.AUTO_COMPLETE && (
                <Chip
                    clickable
                    style={chipStyle}
                    label="Auto Complete"
                    onClick={() => {
                        setAutoCompleteFilter(AutoCompleteFilterValue.NA)
                    }}
                />
            )}

            {autoCompleteFilter === AutoCompleteFilterValue.TENANT_COMPLETE && (
                <Chip
                    clickable
                    style={chipStyle}
                    label="Tenant Complete"
                    onClick={() => {
                        setAutoCompleteFilter(AutoCompleteFilterValue.NA)
                    }}
                />
            )}

            {Object.keys(tenantInspectionStatusFilter).map((key) => {
                const insStatus = key as InspectionStatusType
                if (
                    key in tenantInspectionStatusFilter &&
                    tenantInspectionStatusFilter[insStatus]
                ) {
                    return (
                        <Chip
                            clickable
                            key={insStatus}
                            style={chipStyle}
                            label={getInspectionStatusLabel(insStatus)}
                            onClick={() => {
                                setTenantInspectionStatusFilter({
                                    ...tenantInspectionStatusFilter,
                                    [insStatus]: false,
                                })
                            }}
                        />
                    )
                }
                return null
            })}
        </Container>
    )
}

const StatusChip = (props: {
    status: LeaseStatusType
    statusFilter: LeaseStatusFilterState
    setStatusFilter: (sf: LeaseStatusFilterState) => void
}) => {
    const label = getLeaseStatusString(props.status)

    return (
        <Chip
            clickable
            style={chipStyle}
            label={label}
            onClick={() => {
                props.setStatusFilter({
                    ...props.statusFilter,
                    [props.status]: false,
                })
            }}
        />
    )
}

const DateChip = (props: {
    prefix: string
    dateFilter: DateFilterOption
    onClick: () => void
}) => {
    const dateParseable: string | number = props.dateFilter.date as any
    const date = new Date(dateParseable)
    const dateLabel = toMMDDYYYY(date)
    const operator = props.dateFilter.operator === 'gte' ? '>' : '<'

    const label = `${props.prefix} ${operator} ${dateLabel}`

    return (
        <Chip
            clickable
            style={chipStyle}
            label={label}
            onClick={props.onClick}
        />
    )
}

const chipStyle: React.CSSProperties = {
    marginRight: 4,
    marginBottom: 8,
}
