import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, MenuItem, useTheme } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import EzosLogo from '../../../../assets/ezos-logo-primary-dark.png'
import RegisterBackground from '../../../../assets/register_background.png'
import { Container } from '../../../../components'
import { Routes } from '../../../../helpers'
import { RegisterCard } from '../Components'

export const LoginRouter = () => {
    const theme = useTheme()

    const history = useHistory()

    return (
        <Container
            style={{
                height: '100vh',
                width: '100vw',
                backgroundImage: `url(${RegisterBackground})`,
                backgroundSize: 'cover',
            }}
        >
            {/* Header */}
            <Container
                style={{
                    width: '100%',
                    position: 'fixed',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    zIndex: 99999,
                    top: 0,
                }}
            >
                <Container
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 800,
                    }}
                >
                    {/* Logo */}
                    <img
                        src={EzosLogo}
                        style={{
                            padding: theme.spacing(1),
                            height: 61,
                        }}
                    />
                    {/* Menu Items */}
                    <Container
                        style={{
                            justifyContent: 'flex-end',
                            marginRight: theme.spacing(2),
                            color: theme.palette.primary.dark,
                            fontWeight: theme.typography.fontWeightBold,
                            position: 'relative',
                        }}
                    >
                        <MenuItem
                            style={{
                                backgroundColor: 'transparent',
                                justifyContent: 'center',
                            }}
                            onClick={() => {
                                history.push(Routes.nationalVendorListFinal)
                            }}
                            onMouseEnter={() => {
                                const nvlLine = document.querySelector<HTMLSpanElement>(
                                    '#nvlLine',
                                )
                                if (nvlLine) {
                                    nvlLine.style.width = '80%'
                                }
                            }}
                            onMouseLeave={() => {
                                const nvlLine = document.querySelector<HTMLSpanElement>(
                                    '#nvlLine',
                                )
                                if (nvlLine) {
                                    nvlLine.style.width = '0'
                                }
                            }}
                        >
                            National Vendor List
                            <span
                                id="nvlLine"
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: 0,
                                    height: '2px',
                                    backgroundColor: theme.palette.primary.dark,
                                    transition: 'width 0.3s ease',
                                }}
                            />
                        </MenuItem>
                        {/* Login Button */}
                        <Button
                            style={{
                                color: 'white',
                                backgroundColor: theme.palette.primary.dark,
                                borderRadius: theme.shape.borderRadius,
                                fontWeight: theme.typography.fontWeightBold,
                                marginLeft: theme.spacing(2),
                                textTransform: 'none',
                                transition:
                                    'background-color 0.3s, color 0.3s, border-color 0.3s',
                                border: `2px solid ${theme.palette.primary.dark}`,
                            }}
                            onClick={() => {
                                history.push(Routes.register)
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = 'white'
                                e.currentTarget.style.color =
                                    theme.palette.primary.dark
                                e.currentTarget.style.borderColor =
                                    theme.palette.primary.dark
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor =
                                    theme.palette.primary.dark
                                e.currentTarget.style.color = 'white'
                            }}
                        >
                            Get started
                            <ArrowForwardIcon />
                        </Button>
                    </Container>
                </Container>
            </Container>

            {/* Website Body */}
            <Container
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: 100,
                    width: '100%',
                    margin: 'auto',
                }}
            >
                {/* Website Greeting */}
                <Container
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: theme.spacing(1),
                        color: 'white',
                        textShadow: '0 0 5px rgba(0, 0, 0, 0.7)',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.h3,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        Login to your EZOS account
                    </span>
                    <span style={{ ...theme.typography.h6 }}>
                        Let&apos;s get working!
                    </span>
                </Container>
                {/* Login Cards */}
                <Container
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        marginTop: theme.spacing(2),
                    }}
                >
                    {/* Vendor Register */}
                    <RegisterCard
                        routerType="login"
                        registerType="Service Provider"
                        theme={theme}
                    />
                    {/* Apartment Register */}
                    <RegisterCard
                        routerType="login"
                        registerType="Property Manager"
                        theme={theme}
                    />
                </Container>
                <Container>
                    <Button
                        style={{
                            color: 'white',
                            textTransform: 'none',
                            textShadow: '0 0 5px rgba(0, 0, 0, 0.7)',
                            fontWeight: theme.typography.fontWeightBold,
                            position: 'relative',
                            backgroundColor: 'transparent',
                        }}
                        onClick={() => {
                            history.push(Routes.register)
                        }}
                        onMouseEnter={() => {
                            const line = document.querySelector<HTMLSpanElement>(
                                '#line',
                            )
                            if (line) {
                                line.style.width = '90%'
                            }
                        }}
                        onMouseLeave={() => {
                            const line = document.querySelector<HTMLSpanElement>(
                                '#line',
                            )
                            if (line) {
                                line.style.width = '0'
                            }
                        }}
                    >
                        Don&apos;t have an account?
                        <span
                            id="line"
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                width: 0,
                                height: '2px',
                                backgroundColor: 'white',
                                transition: 'width 0.3s ease',
                            }}
                        />
                    </Button>
                </Container>
            </Container>
        </Container>
    )
}
