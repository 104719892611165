import React from 'react'

interface Props {
    options: string[]
    selectedItem: number
    onChange: (index: number) => void
    delimiter?: string
}

export const BreadCrumbs: React.FC<Props> = ({
    options,
    selectedItem,
    onChange,
    delimiter = ' / ',
}) => {
    return (
        <div>
            {options.map((bc, idx) => (
                <BreadCrumb
                    key={bc}
                    title={bc}
                    selected={idx === selectedItem}
                    onSelect={() => onChange(idx)}
                    delimiter={idx < options.length - 1 ? delimiter : undefined}
                />
            ))}
        </div>
    )
}

const BreadCrumb: React.FC<{
    title: string
    selected: boolean
    onSelect: () => void
    delimiter?: string
}> = ({ title, delimiter, onSelect, selected }) => {
    let delimiterSpan: JSX.Element | null = null
    if (delimiter) {
        delimiterSpan = <span>{delimiter}</span>
    }
    return (
        <>
            <span
                onClick={onSelect}
                style={{
                    cursor: 'pointer',
                    color: selected ? 'black' : '#8a8a8a',
                    fontStyle: 'normal',
                    fontSize: '18px',
                    lineHeight: '18px',
                }}
            >
                {title}
            </span>
            {delimiterSpan}
        </>
    )
}
