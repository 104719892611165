import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core'
import { InspectionType } from '../../../../models'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            tableLayout: 'fixed',
            // minWidth: 650,
            '& .MuiTableRow-root .MuiTableCell-root': {
                fontWeight: 400,
                fontSize: '0.625rem',
                /* line-height: 12px; */
                color: '#8A8A8A',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '100%',
            },
            '& .MuiTableHead-root .MuiTableCell-root': {
                fontWeight: 400,
                fontSize: '0.75rem',
                /* line-height: 12px; */
                color: '#000',
                backgroundColor: '#FFF',
            },
        },
    }),
)

interface Props {
    inspectionTypeList: InspectionType[] | undefined
}

const InspectionTypesList = (props: Props) => {
    const classes = useStyles()

    return (
        <>
            <TableContainer
                className="ez-custom-scroll"
                style={{ maxHeight: '250px' }}
            >
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Inventory Items</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.inspectionTypeList &&
                        props.inspectionTypeList.length > 0 ? (
                            props.inspectionTypeList.map(
                                (row: InspectionType, index: number) => (
                                    <TableRow key={`ins-types-${index}`}>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>
                                            {row.inventory_configs.length}
                                        </TableCell>
                                    </TableRow>
                                ),
                            )
                        ) : (
                            <></>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default InspectionTypesList
