import React from 'react'
import { Folder, traverse, Unit } from '../../models'

interface Props {
    infrastructure: Folder
    renderFolder: (folder: Folder, renderedUnits: JSX.Element[]) => JSX.Element
    renderUnit: (unit: Unit) => JSX.Element
    isUnitVisible: (unit: Unit) => boolean
    isFolderVisible: (folder: Folder, renderedUnitCount: number) => boolean
}

export const InfrastructureHoc = (props: Props) => {
    const {
        infrastructure,
        renderFolder,
        renderUnit,
        isUnitVisible,
        isFolderVisible,
    } = props

    const folders: JSX.Element[] = []

    traverse(infrastructure, (folder) => {
        const renderedUnits = folder.units.reduce<JSX.Element[]>(
            (prev, unit) => {
                if (isUnitVisible(unit)) {
                    return prev.concat(renderUnit(unit))
                }
                return prev
            },
            [],
        )

        if (isFolderVisible(folder, renderedUnits.length)) {
            folders.push(renderFolder(folder, renderedUnits))
        }
    })

    return <>{folders}</>
}
