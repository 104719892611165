import { Avatar, Button, useTheme } from '@material-ui/core'
import React from 'react'
import { Container } from '../../components'
import { BidStatus, RFP } from '../../models'
import { useStyles } from '../../styles'
import { getInitials, toMMDDYYYY } from '../../helpers'
import { ListPopover } from '../../components/ListPopover/ListPopover'
import { usePopover } from '../../hooks/usePopover'
import { useUser } from '../../hooks'
import { DisplayService } from '../../components/DisplayService'

interface Props {
    rfp: RFP
    isSelectedRFP: boolean
    onClickRFP: (rfp: RFP) => void
}

export const VendorEZNowRow = (props: Props) => {
    const { rfp, isSelectedRFP, onClickRFP } = props

    const theme = useTheme()
    const classes = useStyles()

    const startDate = toMMDDYYYY(new Date(rfp.project_start_date ?? ''))

    const { workspaceUser } = useUser()

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    const bid = rfp.bids.find(
        (bid) => bid.vendor.id === workspaceUser?.active_workspace.id,
    )

    let bidColor = theme.palette.grey[300]
    let bidInfo = ''

    if (bid?.status === BidStatus.DECLINED) {
        bidColor = 'red'
        bidInfo = 'Job Declined'
    } else if (
        bid?.status === BidStatus.SUBMITTED ||
        bid?.status === BidStatus.REVIEWED
    ) {
        bidColor = theme.palette.darkBlue.main
        bidInfo = 'Bid Submitted'
    } else if (bid?.status === BidStatus.ACCEPTED) {
        bidColor = theme.palette.primary.dark
        bidInfo = 'Job Rewarded'
    }

    return (
        <Container
            style={{
                border: `1px solid ${bidColor}`,
                width: '600px',
                marginBottom: theme.spacing(2),
                cursor: 'pointer',
                backgroundColor: isSelectedRFP ? theme.palette.grey[200] : '',
                borderRadius: theme.shape.borderRadius,
                height: '125px',
                position: 'relative',
            }}
            onClick={() => onClickRFP(rfp)}
            className={classes.hoverGrey200}
        >
            <Container
                style={{
                    ...theme.typography.h4,
                    position: 'absolute',
                    left: 180,
                    top: 30,
                    color: bidColor,
                    fontWeight: 600,
                }}
            >
                {bidInfo}
            </Container>
            <Container flex={1}>
                <Container
                    style={{
                        margin: theme.spacing(0, 2, 0, 2),
                        alignSelf: 'center',
                    }}
                >
                    {rfp.apartment.profile_picture !== null ? (
                        <img
                            src={rfp.apartment.profile_picture}
                            style={{
                                width: '70px',
                                height: '70px',
                            }}
                        />
                    ) : (
                        <Avatar
                            variant="square"
                            style={{
                                backgroundColor: theme.palette.primary.dark,
                                width: '70px',
                                height: '70px',
                                fontSize: 20,
                            }}
                        >
                            {getInitials(rfp.apartment.name)}
                        </Avatar>
                    )}
                </Container>
                <Container
                    direction="column"
                    style={{
                        marginLeft: theme.spacing(3),
                        justifyContent: 'center',
                    }}
                    flex={1}
                >
                    <Container style={{ ...theme.typography.h6 }}>
                        {rfp.apartment.name}
                    </Container>
                    <Container
                        style={{
                            ...theme.typography.subtitle1,
                            margin: theme.spacing(0.5, 0, 0.5, 0),
                        }}
                    >
                        {rfp.name}
                    </Container>
                    <Container
                        direction="column"
                        style={{ ...theme.typography.body2 }}
                    >
                        <Container>
                            {rfp.apartment.primary_address.address1}
                        </Container>
                        <Container>
                            {rfp.apartment.primary_address.city},{' '}
                            {rfp.apartment.primary_address.state}{' '}
                            {rfp.apartment.primary_address.zip_code}
                        </Container>
                    </Container>
                </Container>
                <Container direction="column" flex={1}>
                    <Container
                        style={{
                            ...theme.typography.body1,
                            fontWeight: 600,
                            alignSelf: 'flex-start',
                            marginTop: theme.spacing(1),
                        }}
                    >
                        {`Work Needed ${startDate}`}
                    </Container>
                    {rfp.budget > 0 ? (
                        <Container
                            style={{
                                ...theme.typography.body2,
                                marginTop: theme.spacing(1),
                            }}
                        >
                            {`Pay: $${rfp.budget}`}
                        </Container>
                    ) : (
                        <Container
                            style={{ margin: theme.spacing(1, 0, 2, 0) }}
                        />
                    )}
                    <Container flexWrap="wrap">
                        {rfp.rfp_services[0] && (
                            <DisplayService
                                service={rfp.rfp_services[0].service}
                                border
                            />
                        )}
                        {rfp.rfp_services.length > 1 && (
                            <>
                                <Container>
                                    <Button
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            handleOpen(e)
                                        }}
                                        size="small"
                                        style={{
                                            margin: theme.spacing(1, 0, 1, 0),
                                        }}
                                    >
                                        +{rfp.rfp_services.length - 1}
                                    </Button>
                                </Container>
                                <ListPopover
                                    open={isOpen}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        horizontal: -50,
                                        vertical: 'bottom',
                                    }}
                                    style={{ paddingLeft: theme.spacing(1) }}
                                >
                                    {rfp.rfp_services
                                        .slice(1)
                                        .map((rfpService) => {
                                            return (
                                                <DisplayService
                                                    service={rfpService.service}
                                                    border={false}
                                                    key={`RFP_SERVICE_DISPLAY_${rfpService.id}`}
                                                />
                                            )
                                        })}
                                </ListPopover>
                            </>
                        )}
                    </Container>
                </Container>
            </Container>
        </Container>
    )
}
