import React, { useEffect, useState } from 'react'
import {
    Badge,
    Button,
    Divider,
    FormControl,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    useTheme,
    Chip,
    ListSubheader,
    TextField,
    Popover,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core'

import { Container, NumberInput, SideDrawer } from '../../components'
import { useAppDispatch, useAptConfig } from '../../hooks'
import {
    Area,
    AreaConfig,
    BaseUser,
    Damage,
    DamageConfig,
    Lease,
    ModelListMap,
    ModelMap,
    Schedule,
    Tenant,
    Unit,
} from '../../models'

import MessageIcon from '@material-ui/icons/Message'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EmailIcon from '@material-ui/icons/Email'

import { axiosInstance } from '../../helpers/axios'

import { currencyFormatter } from '../../helpers'
import { toast } from 'react-toastify'
import { Add } from '@material-ui/icons'

import { useMultiSelectStyles } from '../../styles'
import {
    CreateDamageReqeust,
    DamageThunk,
    deleteDamageRequest,
    getLeaseList,
    removeDamage,
    RootState,
} from '../../store'
import { ItemProps } from '../ScheduleReportPopOver'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { useSelector } from 'react-redux'

interface Props {
    unit: Unit | undefined
    // areaLeaseMap: ModelListMap<Lease>
    areaConfigMap: ModelMap<AreaConfig>
    inspection?: number | null
    unitDamages?: Damage[]
    openMessages: (damage: Damage) => void
    handleClose: () => void
    createDamage: (req: CreateDamageReqeust) => DamageThunk
    disabled?: boolean
}

const _DamageDrawer = (props: Props) => {
    const {
        unit,
        areaConfigMap,
        createDamage,
        inspection,
        unitDamages,
        openMessages,
        disabled,
    } = props

    const theme = useTheme()

    const dispatch = useAppDispatch()

    const [damageArea, setDamageArea] = useState<Area | undefined>()
    const leaseList = useSelector((state: RootState) => state.lease.leaseList)
    useEffect(() => {
        if (damageArea?.id !== -1) {
            // If the area is valid get its tenants from the server
            getLeaseList({
                params: {
                    area: damageArea?.id,
                    lte_end_date: new Date().toISOString(),
                },
            })
        }
    }, [damageArea])

    const CUSTOM_DAMAGE_ID = -1

    const customDamageConfig: DamageConfig = {
        id: CUSTOM_DAMAGE_ID,
        description: '',
        cost: 0,
        apartment: -1,
        charge_item_description: null,
        integration_id: null,
        archived: false,
    }

    const [damageConfigToAdd, setDamageConfigToAdd] = useState<
        DamageConfig | undefined
    >(undefined)

    interface AreaSelectionDict {
        area: Area
        selected: boolean
    }

    const [selectedTenantIds, setSelectedTenantIds] = useState<number[]>([])
    const [createQuantity, setCreateQuantity] = useState('1')
    const [cost, setCost] = useState('')
    const [description, setDescription] = useState('')
    const [doValidate, setDoValidate] = useState(false)
    const [pdfReport, setPdfReport] = useState(true)
    const [reportAreaSelectedList, setReportAreaSelectedList] = useState<
        AreaSelectionDict[]
    >([])

    useEffect(() => {
        if (unit?.areas) {
            const newSelectionArray: AreaSelectionDict[] = []
            unit.areas.forEach((area) => {
                newSelectionArray.push({ area: area, selected: false })
            })

            setReportAreaSelectedList(newSelectionArray)
        }
    }, [unit])

    const { damageConfigList } = useAptConfig({
        minimizeReqeusts: true,
        damageConfigList: true,
    })

    const damageAreaConfig = areaConfigMap[damageArea?.area_config ?? -1]

    const classes = useMultiSelectStyles()

    const inputStyle: React.CSSProperties = {
        margin: theme.spacing(2),
    }

    const openCreateForm = (area: Area) => {
        setSelectedTenantIds([])
        setDamageArea(area)
    }

    const closeCreateForm = () => {
        setSelectedTenantIds([])
        setDamageArea(undefined)
    }

    const textStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        marginLeft: theme.spacing(1),
    }

    const areaDamageMap =
        unitDamages?.reduce<ModelListMap<Damage>>((prev, damage) => {
            if (prev[damage.area] === undefined) {
                prev[damage.area] = []
            }

            prev[damage.area]?.push(damage)

            return prev
        }, {}) ?? {}

    const areaComponents =
        unit?.areas.map((area) => {
            const areaConfig = areaConfigMap[area.area_config]

            let totalAreaCost = 0
            const damageComponents = areaDamageMap[area.id]?.map((damage) => {
                const damageCost = damage.amount * damage.cost
                totalAreaCost += damageCost

                return (
                    <Container
                        key={`AREA_${area.id}_DAMAGE_${damage.id}`}
                        style={{
                            flex: 1,
                            padding: theme.spacing(1),
                            margin: theme.spacing(1),
                            backgroundColor: theme.palette.grey[200],
                            borderRadius: theme.shape.borderRadius,
                            alignItems: 'center',
                        }}
                    >
                        <Container style={{ flexDirection: 'column' }}>
                            <span
                                style={{
                                    ...textStyle,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {damage.damage_config
                                    ? damage.damage_config.description
                                    : damage.description}
                            </span>

                            <Container>
                                <span style={textStyle}>
                                    {currencyFormatter.format(damage.cost)}
                                </span>
                                <span style={textStyle}>x{damage.amount}</span>
                                <span style={textStyle}>
                                    = {currencyFormatter.format(damageCost)}
                                </span>
                            </Container>
                        </Container>

                        <div style={{ flex: 1 }} />

                        <IconButton onClick={() => openMessages(damage)}>
                            <Badge
                                badgeContent={damage.message_count}
                                color="secondary"
                            >
                                <MessageIcon />
                            </Badge>
                        </IconButton>

                        <IconButton
                            onClick={() => {
                                if (
                                    confirm(
                                        `Are you sure you want to delete ${damage.description}?`,
                                    )
                                ) {
                                    dispatch(deleteDamageRequest(damage.id))
                                }
                            }}
                            disabled={disabled}
                        >
                            <DeleteForeverIcon
                                color={disabled ? 'disabled' : 'error'}
                            />
                        </IconButton>
                    </Container>
                )
            })

            return (
                <Container
                    key={`AREA_${area.id}_DMG_CONTAINER`}
                    style={{
                        flexDirection: 'column',
                    }}
                >
                    {/* Area Title */}
                    <Container
                        style={{
                            padding: theme.spacing(1),
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {areaConfig?.name} {area.area_label}
                        </span>

                        <span style={textStyle}>
                            {currencyFormatter.format(totalAreaCost)}
                        </span>

                        <div style={{ flex: 1 }} />
                        <Tooltip title="Add Damage">
                            <IconButton
                                onClick={() => openCreateForm(area)}
                                disabled={disabled}
                            >
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </Container>

                    <Divider />

                    {/*  */}
                    <Container
                        style={{
                            padding: theme.spacing(1),
                            flexDirection: 'column',
                        }}
                    >
                        {damageComponents}
                    </Container>
                </Container>
            )
        }) ?? []

    const createDamageTenantOptionList: BaseUser[] = []

    leaseList?.forEach((lease) => {
        if (lease.user) {
            createDamageTenantOptionList.push(lease.user)
        }
    })

    const getDamageReport = (areas: Area[]) => {
        const damageIds: number[] = []

        areas.forEach((area) => {
            areaDamageMap[area.id]?.forEach((damage) =>
                damageIds.push(damage.id),
            )
        })

        axiosInstance.post('lease/damage/email_report/', {
            damage_list: damageIds,
        })

        toast.success('You will receive an email shortly.')
    }

    const getDamagePDF = (areas: Area[]) => {
        const area_id_list = areas.map((area) => area.id)
        axiosInstance.post('lease/damage/email_pdf_report/', {
            inspection: inspection,
            area_list: area_id_list,
        })
        toast.success('You will receive your report via email shortly!')
    }

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closePopover = () => {
        setAnchorEl(null)
    }

    const popOverOpen = Boolean(anchorEl)

    return (
        <>
            {/* Begin Damage List Drawer */}
            <SideDrawer
                open={unit !== undefined}
                handleClose={props.handleClose}
                title={
                    <Container
                        style={{
                            alignItems: 'center',
                            width: '100%',
                            padding: theme.spacing(1),
                        }}
                    >
                        <span
                            style={{ ...theme.typography.h6 }}
                        >{`${unit?.name} Damages`}</span>
                        <div style={{ flex: 1 }} />

                        <Tooltip title={`${unit?.name} Damage Report`}>
                            <IconButton
                                onClick={(e) => {
                                    openPopover(e)
                                }}
                            >
                                <EmailIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            open={popOverOpen}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                horizontal: 'left',
                                vertical: 'bottom',
                            }}
                            onClose={closePopover}
                        >
                            <Container
                                style={{
                                    width: 300,
                                    backgroundColor: theme.palette.grey[100],
                                    flexDirection: 'column',
                                    padding: theme.spacing(1),
                                }}
                            >
                                <Container style={{ flexDirection: 'row' }}>
                                    <span
                                        style={{
                                            ...theme.typography.h6,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                            alignSelf: 'center',
                                        }}
                                    >
                                        Reports
                                    </span>
                                    <div style={{ flex: 1 }} />
                                    <ToggleButtonGroup
                                        value={pdfReport}
                                        onChange={(_, value) =>
                                            setPdfReport(value)
                                        }
                                        aria-label="Report Type"
                                        exclusive
                                        style={{
                                            margin: theme.spacing(1, 0, 1, 0),
                                        }}
                                    >
                                        <ToggleButton
                                            value={true}
                                            aria-label="PDF"
                                        >
                                            PDF
                                        </ToggleButton>
                                        <ToggleButton
                                            value={false}
                                            aria-label="Excel"
                                        >
                                            Excel
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Container>

                                <Divider />
                                {reportAreaSelectedList.map((areaDict) => (
                                    <FormControlLabel
                                        key={`REPORT_AREA_CHECKBOX_${areaDict.area.id}`}
                                        control={
                                            <Checkbox
                                                checked={areaDict.selected}
                                                style={{
                                                    color:
                                                        theme.palette.primary
                                                            .main,
                                                }}
                                                onClick={() => {
                                                    setReportAreaSelectedList(
                                                        reportAreaSelectedList.map(
                                                            (old) => {
                                                                if (
                                                                    old.area
                                                                        .id ===
                                                                    areaDict
                                                                        .area.id
                                                                ) {
                                                                    return {
                                                                        ...old,
                                                                        selected: !old.selected,
                                                                    }
                                                                }
                                                                return old
                                                            },
                                                        ),
                                                    )
                                                }}
                                            />
                                        }
                                        label={`${
                                            areaConfigMap[
                                                areaDict.area.area_config
                                            ]?.name
                                        } ${areaDict.area.area_label}`}
                                        labelPlacement="end"
                                        style={{ fontWeight: 'bold' }}
                                    />
                                ))}
                                <Divider />
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    style={{
                                        margin: theme.spacing(2, 2, 0, 0),
                                    }}
                                    onClick={() => {
                                        const selectedAreas: Area[] = []
                                        reportAreaSelectedList.forEach(
                                            (areaDict) => {
                                                if (areaDict.selected) {
                                                    return selectedAreas.push(
                                                        areaDict.area,
                                                    )
                                                }
                                            },
                                        )

                                        if (selectedAreas.length === 0) {
                                            toast.error(
                                                'Please select at least 1 area to generate a report',
                                            )
                                        } else {
                                            if (pdfReport) {
                                                getDamagePDF(selectedAreas)
                                            } else {
                                                getDamageReport(selectedAreas)
                                            }
                                        }
                                    }}
                                >
                                    Create
                                </Button>
                            </Container>
                        </Popover>
                    </Container>
                }
            >
                <Container style={{ flex: 1, flexDirection: 'column' }} scrollY>
                    {areaComponents}
                </Container>
            </SideDrawer>
            {/* End Damage List Drawer */}

            {/* Begin Create Drawer */}
            <SideDrawer
                open={damageArea !== undefined && unit !== undefined}
                handleClose={closeCreateForm}
                title={`${unit?.name} ${damageAreaConfig?.name} ${damageArea?.area_label}`}
            >
                <Container style={{ flex: 1, flexDirection: 'column' }} scrollY>
                    <FormControl style={inputStyle}>
                        <InputLabel id={'damage-config-label'}>
                            Damage Config
                        </InputLabel>
                        <Select
                            labelId="damage-config-label"
                            id="damage-config-selector"
                            value={damageConfigToAdd}
                            onChange={(
                                event: React.ChangeEvent<{
                                    value: unknown
                                }>,
                            ) => {
                                const dcId = event.target.value as number
                                const newDC =
                                    damageConfigList?.find(
                                        (dc) => dc.id === dcId,
                                    ) ?? customDamageConfig
                                setDamageConfigToAdd(newDC)
                            }}
                            MenuProps={{
                                getContentAnchorEl: () => {
                                    return (null as unknown) as Element
                                },
                            }}
                        >
                            <ListSubheader key={'ADD_NO_CONFIG_DAMAGE'}>
                                Custom Damage
                            </ListSubheader>
                            <MenuItem
                                key={`CUSTOM_DAMAGE`}
                                value={CUSTOM_DAMAGE_ID}
                            >
                                Custom Damage
                            </MenuItem>
                            <ListSubheader key={'DAMAGE_CONFIG_HEADER'}>
                                Damage Configs
                            </ListSubheader>
                            {damageConfigList?.map((damageConfig) => {
                                return (
                                    <MenuItem
                                        key={`DAMAGE_CONFIG_KEY_${damageConfig.id}`}
                                        value={damageConfig.id}
                                    >
                                        {damageConfig.description}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <FormControl style={inputStyle}>
                        <InputLabel id="lease-chip-label">
                            Leases (Optional)
                        </InputLabel>

                        <Select
                            labelId="lease-chip-label"
                            id="mutiple-chip-lease"
                            multiple
                            value={selectedTenantIds}
                            onChange={(
                                event: React.ChangeEvent<{
                                    value: unknown
                                }>,
                            ) => {
                                const selectedTenantIds = event.target
                                    .value as number[]
                                setSelectedTenantIds(selectedTenantIds)
                            }}
                            MenuProps={{
                                getContentAnchorEl: () => {
                                    return (null as unknown) as Element
                                },
                            }}
                            input={
                                <Input id="select-multiple-chip-lease-tenant" />
                            }
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {(selected as number[]).map((value) => {
                                        const tenant = leaseList?.find(
                                            (lease) => lease.user.id === value,
                                        )

                                        let label = ''
                                        if (tenant) {
                                            label = tenant.user.name
                                        }

                                        return (
                                            <Chip
                                                key={`TENANT_CHIP_${
                                                    tenant ? tenant.id : -1
                                                }`}
                                                label={label}
                                                className={classes.chip}
                                            />
                                        )
                                    })}
                                </div>
                            )}
                        >
                            {createDamageTenantOptionList?.map((tenant) => {
                                return (
                                    <MenuItem
                                        key={`TENANT_MENU_ITEM_${tenant.id}`}
                                        value={tenant.id}
                                    >
                                        {tenant.name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <NumberInput
                        value={createQuantity}
                        style={inputStyle}
                        onChange={(event) => {
                            setCreateQuantity(event.target.value)
                        }}
                        label="Quantity"
                    />

                    {damageConfigToAdd?.id === CUSTOM_DAMAGE_ID && (
                        <NumberInput
                            value={cost}
                            style={inputStyle}
                            error={doValidate && cost === ''}
                            helperText={
                                doValidate &&
                                cost === '' &&
                                'Required for Custom Damages'
                            }
                            onChange={(event) => {
                                setCost(event.target.value)
                            }}
                            label="Cost"
                        />
                    )}
                    {damageConfigToAdd?.id === CUSTOM_DAMAGE_ID && (
                        <TextField
                            inputProps={{ maxLength: 50 }}
                            variant="outlined"
                            value={description}
                            error={doValidate && description === ''}
                            helperText={
                                doValidate &&
                                description === '' &&
                                'Required for Custom Damages'
                            }
                            onChange={(e) => setDescription(e.target.value)}
                            label="Description"
                            style={inputStyle}
                        />
                    )}
                </Container>

                <Divider />
                <Container
                    style={{
                        padding: theme.spacing(2),
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{ marginRight: theme.spacing(2) }}
                        onClick={closeCreateForm}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        disabled={
                            damageConfigToAdd === undefined ||
                            damageArea === undefined ||
                            inspection === undefined ||
                            inspection === null
                        }
                        onClick={() => {
                            if (damageConfigToAdd && damageArea && inspection) {
                                if (
                                    damageConfigToAdd.id === CUSTOM_DAMAGE_ID &&
                                    (cost === '' || description === '')
                                ) {
                                    setDoValidate(true)
                                    return
                                }

                                const req: CreateDamageReqeust = {
                                    body: {
                                        amount: Number(createQuantity),
                                        tenants: selectedTenantIds,
                                        area: damageArea.id,
                                        inspection: inspection,
                                    },
                                }

                                if (damageConfigToAdd.id !== CUSTOM_DAMAGE_ID) {
                                    req.body.damage_config =
                                        damageConfigToAdd.id
                                } else {
                                    req.body.cost = Number(cost)
                                    req.body.description = description
                                }
                                createDamage(req).then((res) => {
                                    toast.success('Damage Created')
                                    setDoValidate(false)
                                    setDamageArea(undefined)
                                })
                            }
                        }}
                    >
                        Create
                    </Button>
                </Container>
            </SideDrawer>
            {/* End Create Drawer */}
        </>
    )
}

export const DamageDrawer = React.memo(_DamageDrawer)
