import React, { useEffect, useState } from 'react'
import { Container } from '../../components'
import { CircularProgress, useTheme } from '@material-ui/core'
import { EZPayRegister } from './EZPayRegister'
import { useUser } from '../../hooks'
import { APARTMENT, VENDOR } from '../../helpers'
import { isPropertyManager } from '../../models/Users/services'
import { EZPayHome } from './Home'
import { dwollaApi } from '../../contexts'
import LockIcon from '@material-ui/icons/Lock'

export const EZPayRouter = () => {
    const [isLoading, setIsLoading] = useState(true)

    const dwollaState = dwollaApi()

    const { workspaceUser } = useUser()

    const theme = useTheme()

    const onMount = async () => {
        setIsLoading(true)
        await dwollaState.getDwollaCustomer()
        setIsLoading(false)
    }

    const cleanUp = () => {
        setIsLoading(true)
        dwollaState.clearState()
    }

    useEffect(() => {
        onMount()

        return cleanUp
    }, [])

    let content: JSX.Element | null = null

    if (workspaceUser === undefined) {
        return null
    }

    if (dwollaState.dwollaCustomer === null) {
        // Registration flows

        // Apartment Registration
        if (workspaceUser.active_workspace.company_type === APARTMENT) {
            if (isPropertyManager(workspaceUser)) {
                content = <EZPayRegister user={workspaceUser!} />
            } else {
                content = (
                    <Container
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            Please have your property manager set up EZ Pay
                        </span>

                        <LockIcon fontSize="large" />
                    </Container>
                )
            }
        }
        // Vendor registration
        else if (workspaceUser.active_workspace.company_type === VENDOR) {
            content = <EZPayRegister user={workspaceUser!} />
        }
    }
    // Already registred
    // Send user to the EZPay Home
    else if (dwollaState.dwollaCustomer) {
        content = <EZPayHome user={workspaceUser} />
    }

    return (
        <Container
            style={{
                position: 'fixed',
                left: 136,
                top: 56,
                right: 0,
                bottom: 0,
            }}
        >
            {isLoading ? <Loader /> : content}
        </Container>
    )
}

const Loader = () => {
    return (
        <Container
            style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
            <CircularProgress size={100} />
        </Container>
    )
}
