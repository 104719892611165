import React from 'react'
import { useTheme } from '@material-ui/core'

export const Mention: React.FC = (props) => {
    const theme = useTheme()

    return (
        <span style={{ color: theme.palette.info.main }}>{props.children}</span>
    )
}
