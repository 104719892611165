import React from 'react'

// components
import { Cancel } from '@material-ui/icons'
import { useStyles } from '../../styles'
import { useTheme } from '@material-ui/core'

interface IStagedAttachmentProps {
    attachment: File
    onRemove: () => void
}

export const StagedAttachment: React.FC<IStagedAttachmentProps> = ({
    attachment,
    onRemove,
}) => {
    const classes = useStyles()
    const theme = useTheme()

    const urlCreator = window.URL || window.webkitURL

    const mimeType = attachment.type
    const endPrefix = mimeType.indexOf('/')
    const prefix = mimeType.substring(0, endPrefix)

    return (
        <div
            style={{
                height: 80,
                width: 80,
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: theme.palette.grey[300],
                borderRadius: theme.shape.borderRadius,
            }}
        >
            <Cancel
                onClick={onRemove}
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    color: theme.palette.primary.main,
                }}
                className={classes.showOnHover}
            />
            {prefix === 'image' ? (
                <img
                    src={urlCreator.createObjectURL(attachment)}
                    style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain',
                    }}
                />
            ) : (
                <span
                    style={{
                        border: `1px solid ${theme.palette.common.black}`,
                        borderRadius: '4px',
                        height: 40,
                        width: 80,
                        backgroundColor: theme.palette.grey[200],
                        fontWeight: theme.typography.fontWeightBold,
                        ...theme.typography.body1,
                    }}
                >
                    {attachment.name}
                </span>
            )}
        </div>
    )
}
