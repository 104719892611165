import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import {
    userReducer,
    errorReducer,
    infrastructureReducer,
    combinedWorkorderReducer,
    companyReducer,
    serviceReducer,
    serviceContractReducer,
    aptConfigReducer,
    messagingReducer,
    JobsiteReducer,
    inspectionReducer,
    actionItemReducer,
    analyticReducer,
    invoiceReducer,
    infrastructureFilterReducer,
    finderReducer,
    leaseReducer,
    permissionReducer,
} from './'
import { ActionItemRequest } from './actionItem'
import { AnalyticsRequest } from './analytics'
import { AptConfigRequest } from './aptConfig'
import { CompanyRequests } from './company'
import { InfrastructureRequest } from './infrastructure'
import { InspectionRequest } from './inspection'
import { InvoiceRequest } from './invoice'
import { JobsiteRequest } from './jobsite'
import { LeaseRequest } from './lease'
import { MessagingRequest } from './messaging'
import { paginationReducer } from './pagination'
import { PermissionRequest } from './permissions'
import { ServiceContractRequests } from './serviceContract'
import { UserRequests } from './user'
import { ScheduleRequest, WorkorderRequest } from './workorder'

const rootReducer = combineReducers({
    user: userReducer,
    company: companyReducer,
    error: errorReducer,
    infrastructure: infrastructureReducer,
    workorder: combinedWorkorderReducer,
    service: serviceReducer,
    serviceContract: serviceContractReducer,
    aptConfig: aptConfigReducer,
    messaging: messagingReducer,
    jobsite: JobsiteReducer,
    inspection: inspectionReducer,
    invoice: invoiceReducer,
    analytics: analyticReducer,
    actionItem: actionItemReducer,
    infrastructureFilter: infrastructureFilterReducer,
    finder: finderReducer,
    lease: leaseReducer,
    pagination: paginationReducer,
    permission: permissionReducer,
})

export type AppRequests =
    | UserRequests
    | CompanyRequests
    | InfrastructureRequest
    | WorkorderRequest
    | ScheduleRequest
    | ServiceContractRequests
    | AptConfigRequest
    | MessagingRequest
    | JobsiteRequest
    | InspectionRequest
    | InvoiceRequest
    | AnalyticsRequest
    | ActionItemRequest
    | LeaseRequest
    | PermissionRequest

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
    }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk)),
)

export * from './user'
export * from './error'
export * from './types'
export * from './infrastructure'
export * from './workorder'
export * from './company'
export * from './service'
export * from './serviceContract'
export * from './aptConfig'
export * from './messaging'
export * from './jobsite'
export * from './inspection'
export * from './invoice'
export * from './analytics'
export * from './actionItem'
export * from './finder'
export * from './infrastructureFilter'
export * from './lease'
export * from './permissions'

export type RootState = ReturnType<typeof rootReducer>
