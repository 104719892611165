import React from 'react'
import {
    IconButton,
    InputAdornment,
    OutlinedTextFieldProps,
    TextField,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

interface PasswordFieldProps extends OutlinedTextFieldProps {
    showPassword: boolean
    handleClickShowPassword: () => void
    handleMouseDownPassword: (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => void
}

export const PasswordField = (props: PasswordFieldProps) => {
    const {
        showPassword,
        handleClickShowPassword,
        handleMouseDownPassword,
    } = props

    return (
        <TextField
            {...props}
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
                ...props.InputProps,
            }}
        />
    )
}
