import {
    InvoiceActionTypes,
    InvoiceState,
    SET_INVOICE_LIST,
    SET_INVOICE_LOADING,
    UPDATE_INVOICE,
} from './types'

const initialState: InvoiceState = {
    isLoading: {
        GET_INVOICE_LIST_REQUEST: false,
    },
}

export const invoiceReducer = (
    state = initialState,
    action: InvoiceActionTypes,
): InvoiceState => {
    switch (action.type) {
        case SET_INVOICE_LIST:
            return {
                ...state,
                invoiceList: action.invoiceList,
            }

        case SET_INVOICE_LOADING:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.request]: action.isLoading,
                },
            }
        case UPDATE_INVOICE:
            return {
                ...state,
                invoiceList: state.invoiceList?.map((invoice) => {
                    if (invoice.id === action.invoice.id) {
                        return action.invoice
                    }
                    return invoice
                }),
            }
        default:
            return state
    }
}
