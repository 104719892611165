import React, { useRef } from 'react'
import { EditorState, convertToRaw } from 'draft-js'

import { Container } from '../Container'
import { RichTextEditor } from '../RichTextEditor'
import { AttachFile as AttachFileIcon } from '@material-ui/icons'
import { Button, useTheme } from '@material-ui/core'
import MentionOption from '../RichTextEditor/ContextMenu/MentionOption'
import {
    Company,
    CurrentChannel,
    InventoryInspection,
    MessageDetail,
} from '../../models'
import { compositeDecorator } from './decorators'
import { toast } from 'react-toastify'
import { useMessage } from '../../hooks/useMessage'
import { AttachmentContainer } from '../AttachmentContainer'
import { useAppDispatch } from '../../hooks'
import { setCurrentChannel, updateInventoryInspection } from '../../store'
import { AxiosResponse } from 'axios'

interface Props {
    editorState: EditorState
    currentChannel?: CurrentChannel | null
    fileList?: File[]
    setFileList: (fileList?: File[]) => void
    setEditorState: (editorState: EditorState) => void
    inv_ins?: InventoryInspection
    createMessage?: (
        formData: FormData,
    ) => Promise<AxiosResponse<MessageDetail>>
    onCreateMessage?: (messageDetail: MessageDetail) => void
}

export const RichTextInput = (props: Props) => {
    const {
        editorState,
        setEditorState,
        currentChannel,
        fileList,
        setFileList,
        inv_ins,
    } = props

    const dispatch = useAppDispatch()
    const { createMessage } = useMessage()

    const _createMessage = props.createMessage
        ? props.createMessage
        : createMessage

    const attachmentRef = useRef<HTMLInputElement>(null)

    const theme = useTheme()

    const onChangeEditorState = (editorState: EditorState) => {
        setEditorState(editorState)
    }

    const mentionOptions: { id: number; name: string }[] = []

    currentChannel?.members.forEach((mem) => {
        if (mem.member_type === 'user') {
            if (
                mentionOptions.find((opt) => opt.id === mem.member.id) ===
                undefined
            ) {
                mentionOptions.push({
                    id: mem.member.id,
                    name: mem.member.name,
                })
            }
        } else {
            const comp = mem.member as Company
            comp.employees?.forEach((user) => {
                if (
                    mentionOptions.find((opt) => opt.id === user.id) ===
                    undefined
                ) {
                    mentionOptions.push({
                        id: user.id,
                        name: user.name,
                    })
                }
            })
        }
    })

    return (
        <Container flex={1} direction="column">
            <AttachmentContainer
                ref={attachmentRef}
                fileList={fileList}
                setFileList={setFileList}
                theme={theme}
            />

            <RichTextEditor
                editorState={editorState}
                onChange={onChangeEditorState}
                right={() => {
                    return (
                        <Container alignItems="center">
                            <div
                                onClick={() =>
                                    attachmentRef.current &&
                                    attachmentRef.current.click()
                                }
                                style={{ cursor: 'pointer' }}
                            >
                                <AttachFileIcon fontSize="large" />
                            </div>
                            <Button
                                color="primary"
                                variant="outlined"
                                style={{
                                    marginRight: theme.spacing(1),
                                }}
                                onClick={() => {
                                    let canMakePost = false
                                    const message = new FormData()
                                    if (
                                        currentChannel === undefined ||
                                        currentChannel === null
                                    ) {
                                        // use inv ins to make new message in new channel
                                        if (inv_ins !== undefined) {
                                            message.append(
                                                'inv_ins',
                                                String(inv_ins.id),
                                            )
                                        } else {
                                            // no channel or inv ins
                                            return
                                        }
                                    } else {
                                        message.append(
                                            'channel',
                                            String(currentChannel.id),
                                        )
                                    }

                                    if (
                                        editorState
                                            .getCurrentContent()
                                            .getPlainText().length > 0
                                    ) {
                                        message.append(
                                            'content',
                                            editorState
                                                .getCurrentContent()
                                                .getPlainText(),
                                        )
                                        canMakePost = true
                                    }
                                    if (fileList && fileList.length > 0) {
                                        canMakePost = true
                                        message.append(
                                            'attachments_len',
                                            String(fileList.length),
                                        )
                                        for (
                                            let i = 0;
                                            i <= fileList.length;
                                            i++
                                        ) {
                                            const attachmentNum = `attachment${i}`
                                            message.append(
                                                attachmentNum,
                                                fileList[i],
                                            )
                                        }
                                    }
                                    //now handle mentions
                                    const entityMap = convertToRaw(
                                        editorState.getCurrentContent(),
                                    ).entityMap
                                    let tags_user_len = 0

                                    for (const key in entityMap) {
                                        message.append(
                                            `tag_user${tags_user_len}`,
                                            entityMap[key].data.mention.id,
                                        )
                                        tags_user_len++
                                    }
                                    if (tags_user_len > 0) {
                                        message.append(
                                            `tags_user_len`,
                                            String(tags_user_len),
                                        )
                                    }

                                    if (canMakePost) {
                                        //send the message
                                        _createMessage(message).then((res) => {
                                            props.onCreateMessage &&
                                                props.onCreateMessage(res.data)

                                            if (inv_ins !== undefined) {
                                                dispatch(
                                                    setCurrentChannel(
                                                        res.data.channel,
                                                    ),
                                                )
                                                if (
                                                    inv_ins.channel ===
                                                    undefined
                                                ) {
                                                    // update the inv ins
                                                    const updatedInvIns: InventoryInspection = {
                                                        ...inv_ins,
                                                        channel:
                                                            res.data.channel.id,
                                                    }
                                                    dispatch(
                                                        updateInventoryInspection(
                                                            updatedInvIns,
                                                        ),
                                                    )
                                                }
                                            }
                                        })
                                        onChangeEditorState(
                                            EditorState.createEmpty(
                                                compositeDecorator,
                                            ),
                                        )
                                        setFileList(undefined)
                                    } else {
                                        toast.error('No Message Content')
                                    }
                                }}
                            >
                                Post
                            </Button>
                        </Container>
                    )
                }}
                customStyle={{
                    editor: {
                        padding: theme.spacing(1.5, 0.5),
                    },
                }}
                mentionOptions={mentionOptions}
                renderMentionOption={(opt, idx) => {
                    return (
                        <MentionOption
                            key={`MENTION_OPT_${idx}`}
                            option={opt}
                        />
                    )
                }}
            />
        </Container>
    )
}
