import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Service } from '../models'
import { RootState } from '../store'
import {
    GET_SERVICE_LIST_REQUEST,
    getServiceList,
    GetServiceListActionThunk,
    GetServiceListRequest,
    setServiceList,
} from '../store/service'
import { useAppDispatch } from './useAppDispatch'

type UseService = {
    serviceList: Service[]
    isLoading: boolean
    getServiceList: (req: GetServiceListRequest) => GetServiceListActionThunk
    setServiceList: (newServiceList: Service[]) => void
}

interface Options {
    getServiceList?: boolean
    cleanUp?: boolean
}

export const useService = (options?: Options): UseService => {
    const dispatch = useAppDispatch()

    const serviceList = useSelector(
        (state: RootState) => state.service.serviceList,
    )

    const isLoading = useSelector(
        (state: RootState) => state.service.isLoading[GET_SERVICE_LIST_REQUEST],
    )

    useEffect(() => {
        if (options?.getServiceList) {
            dispatch(getServiceList({}))
        }

        return () => {
            if (options?.cleanUp) {
                dispatch(setServiceList([]))
            }
        }
    }, [])

    return {
        serviceList,
        isLoading,
        getServiceList: (req) => dispatch(getServiceList(req)),
        setServiceList: (newServiceList) =>
            dispatch(setServiceList(newServiceList)),
    }
}
