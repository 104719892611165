import React, { useMemo, useState } from 'react'
import { Container } from '../../Container'
import {
    Checkbox,
    CircularProgress,
    IconButton,
    InputAdornment,
    Menu,
    Theme,
    MenuItem,
    ListItemText,
    ListItemIcon,
    Tooltip,
} from '@material-ui/core'
import { SimpleUser } from '../../../models'
import { UserState } from './SelectedWorkspacePanel'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { SearchField } from '../../SearchField'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { toast } from 'react-toastify'
import { usePopover } from '../../../hooks/usePopover'
import { Mail } from '@material-ui/icons'

interface Props {
    theme: Theme
    userState: UserState
    handleArchive: (userId: number) => void
    handleCreate: (email: string) => void
    handleResendWelcomeEmail: (email: string) => Promise<void>
}

export const WorkspaceUserList = (props: Props) => {
    const { userState, theme } = props

    if (userState.loading) {
        return <CircularProgress />
    }

    const menuState = usePopover<SimpleUser>()

    const [searchText, setSearchText] = useState('')

    const filteredUsers = useMemo(() => {
        const lcSearchText = searchText.toLocaleLowerCase()
        return userState.userList.filter((usr) => {
            const userName = usr.name.toLocaleLowerCase()
            const email = usr.email.toLocaleLowerCase()
            return (
                userName.includes(lcSearchText) || email.includes(lcSearchText)
            )
        })
    }, [userState.userList, searchText])

    const userList = filteredUsers.map((user) => {
        return (
            <Container
                key={`USER-${user.id}`}
                style={{ marginBottom: theme.spacing(1) }}
            >
                <Container style={{ flexDirection: 'column', flex: 1 }}>
                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {user.name}
                    </span>
                    <span
                        style={{
                            ...theme.typography.body2,
                            fontWeight: theme.typography.fontWeightLight,
                            color: theme.palette.grey[800],
                        }}
                    >
                        {user.email}
                    </span>
                </Container>

                <IconButton
                    onClick={(e) => {
                        menuState.handleOpen(e, user)
                    }}
                >
                    <MoreVertIcon />
                </IconButton>
            </Container>
        )
    })

    let endAdornment: JSX.Element | null = null
    if (filteredUsers.length === 0) {
        endAdornment = (
            <InputAdornment position="end">
                <IconButton
                    size="small"
                    onClick={() => {
                        props.handleCreate(searchText)
                        setSearchText('')
                    }}
                >
                    <AddBoxIcon color="primary" fontSize="small" />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={() => {
                        props.handleResendWelcomeEmail(searchText)
                        setSearchText('')
                    }}
                >
                    <Tooltip title="Resend Welcome Email">
                        <Mail color="primary" fontSize="small" />
                    </Tooltip>
                </IconButton>
            </InputAdornment>
        )
    }

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                overflow: 'hidden',
                margin: theme.spacing(1),
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: 8,
            }}
        >
            {/* Header */}
            <Container
                style={{
                    padding: theme.spacing(1),
                }}
            >
                <SearchField
                    InputProps={{
                        endAdornment: endAdornment,
                    }}
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e.target.value)
                    }}
                    placeholder="Search / Add"
                    fullWidth
                />
            </Container>

            {/* Body */}
            <Container
                style={{
                    padding: theme.spacing(1),
                    flexDirection: 'column',
                    overflowY: 'scroll',
                    flex: 1,
                }}
            >
                {userList}
            </Container>

            <Menu
                open={menuState.isOpen}
                onClose={menuState.handleClose}
                anchorEl={menuState.anchorEl}
            >
                <MenuItem
                    onClick={() => {
                        menuState.handleClose()
                        const selectedUser = menuState.context
                        if (selectedUser) {
                            props.handleArchive(selectedUser.id)
                        }
                    }}
                >
                    <ListItemIcon>
                        <DeleteForeverIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        menuState.handleClose()
                        const selectedUser = menuState.context
                        if (selectedUser) {
                            props.handleResendWelcomeEmail(selectedUser.email)
                        }
                    }}
                >
                    <ListItemText>Resend Welcome Email</ListItemText>
                </MenuItem>
            </Menu>
        </Container>
    )
}
