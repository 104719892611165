import { User } from '.'
import {
    APARTMENT_ONLY_PERMISSIONS,
    EDIT_PERMISSION_ROLES,
    EDIT_USER_PERMISSIONS,
    Permissible,
    PERMISSION_SUPER_FALSE,
    PERMISSION_TRUE,
    VENDOR_ONLY_PERMISSIONS,
} from '..'
import { isAccountManager } from '../../helpers'
import {
    APPROVED_TO_INVOICED,
    ASSIGNED_TO_IN_PROGRESS,
    GO_BACK_TO_IN_PROGRESS,
    IN_PROGRESS_TO_ASSIGNED,
    IN_PROGRESS_TO_COMPLETE,
    IN_PROGRESS_TO_PAUSE,
    PAUSE_TO_IN_PROGRESS,
    USE_WEB,
} from '../Permissions'

export const hasPermission = (
    user: User | undefined,
    permissibleAction: keyof Permissible,
) => {
    // takes in a user and returns a boolean value that states wether they can preform permissibleAction

    // first check if the user is in the right type of workspace to preform this action
    if (user === undefined) {
        return false
    }

    if (isAccountManager(user)) {
        return true
    }

    if (permissibleAction in APARTMENT_ONLY_PERMISSIONS) {
        if (user.active_workspace?.company_type === 'VENDOR') {
            return false
        }
    }

    if (permissibleAction in VENDOR_ONLY_PERMISSIONS) {
        if (user.active_workspace?.company_type === 'APARTMENT') {
            return false
        }
    }

    // next, see if it is an action that a workspace_owner can always do no matter what
    if (
        user.workspace_owner &&
        (permissibleAction === EDIT_PERMISSION_ROLES ||
            permissibleAction === EDIT_USER_PERMISSIONS ||
            permissibleAction === USE_WEB)
    ) {
        return true
    }

    // vendors currently don't have a way to edit permissions, so always let them do vendor workorder transitions
    if (user.active_workspace?.company_type === 'VENDOR') {
        if (
            permissibleAction in
            {
                [ASSIGNED_TO_IN_PROGRESS]: true,
                [IN_PROGRESS_TO_PAUSE]: true,
                [IN_PROGRESS_TO_ASSIGNED]: true,
                [IN_PROGRESS_TO_COMPLETE]: true,
                [GO_BACK_TO_IN_PROGRESS]: true,
                [PAUSE_TO_IN_PROGRESS]: true,
                [APPROVED_TO_INVOICED]: true,
            }
        ) {
            return true
        }
    }

    const userPermission = user[permissibleAction]

    // see if the user has an override to make them explicitly able or unable to preform this action
    if (userPermission === PERMISSION_SUPER_FALSE) {
        return false
    }
    if (userPermission === PERMISSION_TRUE) {
        return true
    }

    let hasRolePermission = false
    // check the roles to see if any of the users roles give them permission to do the action
    user.permission_roles.forEach((role) => {
        const rolePermission = role[permissibleAction]
        if (rolePermission === PERMISSION_TRUE) {
            hasRolePermission = true
        }
    })

    // return wether a role gives the user permission to preform this action
    return hasRolePermission
}

export const isPropertyManager = (user?: User) => {
    if (user === undefined) {
        return false
    }
    return user.active_workspace?.property_manager?.id === user.id
}
