import React, { useContext, useEffect } from 'react'
import {
    Button,
    Divider,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    Input,
    MenuItem,
    Card,
    useTheme,
    IconButton,
} from '@material-ui/core'

import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { useMultiSelectStyles } from '../../styles'
import { Container, Selector } from '../../components'
import {
    IdentifiableNamedObject,
    IncidentStatus,
    IncidentStatusNumbers,
    ListVendor,
    Service,
    TenantNegligibleFilter,
    getUiString,
} from '../../models'
import { SideDrawer } from '../../main/components/SideDrawer'
import { IncidentReportContext } from './IncidentReportRoot'
import { DeleteForever } from '@material-ui/icons'

export interface IncidentReportFilterType {
    status: number
    incidentType: number
    tenantNegligible: number
}

interface Props {
    open: boolean
    handleClose: () => void
    filters: IncidentReportFilterType
    setFilters: (filters: IncidentReportFilterType) => void
}

export const IncidentReportFilters = (props: Props) => {
    const { handleClose, open, filters, setFilters } = props

    const { incidentTypes } = useContext(IncidentReportContext)

    const theme = useTheme()
    const classes = useMultiSelectStyles()

    return (
        <SideDrawer open={open} handleClose={handleClose} title={'Filters'}>
            <Container
                flex={1}
                direction="column"
                style={{ overflow: 'hidden' }}
            >
                <Container
                    flex={1}
                    direction="column"
                    style={{ height: '100%', overflowX: 'hidden' }}
                    scrollY
                >
                    <Card
                        style={{
                            padding: theme.spacing(2),
                            borderRadius: 0,
                            flexShrink: 0,
                        }}
                    >
                        <FormGroup>
                            {/* Incident Type Filter*/}
                            <Container>
                                <FormControl
                                    className={classes.formControl}
                                    style={{ flex: 1 }}
                                >
                                    <InputLabel id="vendor-chip-label">
                                        Incident Types
                                    </InputLabel>
                                    <Select
                                        labelId="vendor-chip-label"
                                        id="incident-chip"
                                        value={filters.incidentType}
                                        onChange={(e) => {
                                            setFilters({
                                                ...filters,
                                                incidentType: e.target
                                                    .value as number,
                                            })
                                        }}
                                        MenuProps={{
                                            getContentAnchorEl: () => {
                                                return (null as unknown) as Element
                                            },
                                        }}
                                        input={<Input id="incident-chip" />}
                                    >
                                        {incidentTypes.map((incType) => {
                                            return (
                                                <MenuItem
                                                    key={`SERVICE_MENU_ITEM_${incType.id}`}
                                                    value={incType.id}
                                                >
                                                    {incType.name}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                <IconButton
                                    onClick={() => {
                                        props.setFilters({
                                            ...filters,
                                            incidentType: -1,
                                        })
                                    }}
                                    color="secondary"
                                >
                                    <DeleteForever />
                                </IconButton>
                            </Container>

                            {/* Status Filter */}
                            <Container>
                                <FormControl
                                    className={classes.formControl}
                                    style={{ flex: 1 }}
                                >
                                    <Selector
                                        variant="standard"
                                        label={'Status'}
                                        currentValue={filters.status}
                                        onChange={(
                                            event: React.ChangeEvent<{
                                                value: unknown
                                            }>,
                                        ) => {
                                            event.stopPropagation()
                                            setFilters({
                                                ...filters,
                                                status: event.target
                                                    .value as number,
                                            })
                                        }}
                                        data={[
                                            {
                                                id:
                                                    IncidentStatusNumbers.PENDING,
                                                name: getUiString(
                                                    IncidentStatus.PENDING,
                                                ),
                                            },
                                            {
                                                id:
                                                    IncidentStatusNumbers.REVIEWED,
                                                name: getUiString(
                                                    IncidentStatus.REVIEWED,
                                                ),
                                            },
                                            {
                                                id:
                                                    IncidentStatusNumbers.SUBMITTED,
                                                name: getUiString(
                                                    IncidentStatus.SUBMITTED,
                                                ),
                                            },
                                            {
                                                id:
                                                    IncidentStatusNumbers.NOT_SUBMITTED,
                                                name: getUiString(
                                                    IncidentStatus.NOT_SUBMITTED,
                                                ),
                                            },
                                        ]}
                                        getDisplayString={(
                                            v: IdentifiableNamedObject,
                                        ) => v.name}
                                        size="small"
                                    />
                                </FormControl>
                                <IconButton
                                    onClick={() => {
                                        props.setFilters({
                                            ...filters,
                                            status: -1,
                                        })
                                    }}
                                    color="secondary"
                                >
                                    <DeleteForever />
                                </IconButton>
                            </Container>
                        </FormGroup>
                        <span
                            style={{
                                ...theme.typography.body1,
                                marginRight: theme.spacing(1),
                            }}
                        >
                            Tenant Negligible{' '}
                        </span>
                        <ToggleButtonGroup
                            value={filters.tenantNegligible}
                            onChange={(_, tenantNegliglble) => {
                                setFilters({
                                    ...filters,
                                    tenantNegligible: tenantNegliglble,
                                })
                            }}
                            aria-label="Tenant Negligible"
                            exclusive
                            title="Tenant Negligible"
                        >
                            <ToggleButton
                                value={TenantNegligibleFilter.NO_FILTER}
                            >
                                No Filter
                            </ToggleButton>
                            <ToggleButton value={TenantNegligibleFilter.YES}>
                                Yes
                            </ToggleButton>
                            <ToggleButton value={TenantNegligibleFilter.NO}>
                                No
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Card>

                    <Divider />
                </Container>
                {/* Footer */}
                <Divider />
                <Container
                    style={{
                        padding: theme.spacing(2),
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{ marginRight: theme.spacing(2) }}
                        onClick={() => {
                            setFilters({
                                status: -1,
                                incidentType: -1,
                                tenantNegligible:
                                    TenantNegligibleFilter.NO_FILTER,
                            })
                        }}
                    >
                        Clear Filters
                    </Button>
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{ marginRight: theme.spacing(2) }}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Container>
            </Container>
        </SideDrawer>
    )
}
