import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { Card, CardContent, Grid, Typography } from '@material-ui/core'

import { ReactComponent as BuildingCardIcon } from '../../../../assets/icons/fluent_building-multiple-20-regular.svg'
import { ReactComponent as TimeCardIcon } from '../../../../assets/icons/pie-chart.svg'
import { ReactComponent as TransferCardIcon } from '../../../../assets/icons/tabler_transfer-in.svg'
import { ReactComponent as CalenderCardIcon } from '../../../../assets/icons/group_39676.svg'

import TodaysItems from './TodaysItems'
import TodaysItemDialogBox from '../../../components/dialog-box/DialogBox'

import { axiosInstance } from '../../../../helpers/axios'
import Moment from 'react-moment'
import { RootState } from '../../../../store'
import { useSelector } from 'react-redux'
import { VacantUnitsModal } from './VacantUnitsModal'
import { Area, LeaseStatus } from '../../../../models'

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        borderRadius: '15px',
        border: '1px solid #C2C2C2',
        boxShadow: '0px 6px 15px rgb(138 138 138 / 25%)',
        cursor: 'pointer',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '1.1rem',
        lineHeight: '23px',
        color: '#008C85',
    },
    counts: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '36px',
        lineHeight: '42px',
    },
})

const CounterCards = () => {
    const classes = useStyles()
    const [openTodayItemDialog, setOpenTodayItemDialog] = useState(false)
    const [totalVacancyCount, setTotalVacancyCount] = useState(0)
    const [totalOccupiedCount, setTotalOccupiedCount] = useState(0)

    const [openVacantAreas, setOpenVacantAreas] = useState(false)
    const [vacantAreas, setVacantAreas] = useState<Area[]>([])

    const units = useSelector(
        (state: RootState) => state.infrastructure.infrastructure?.units,
    )

    useEffect(() => {
        getTotalVacancyCount()
        getTotalOccupiedCount()
    }, [])

    const getTotalVacancyCount = () => {
        axiosInstance
            .get('infrastructure/area/', {
                params: {
                    occupiable: true,
                    vacant_start: new Date().toISOString(),
                    vacant_end: new Date().toISOString(),
                },
            })
            .then((res: any) => {
                if (res && res.data) {
                    setTotalVacancyCount(res.data.length)
                    setVacantAreas(res.data)
                }
                console.log('response is....', res)
            })
    }

    const getTotalOccupiedCount = () => {
        axiosInstance
            .get('infrastructure/area/', {
                params: {
                    occupiable: true,
                    occupied_start: new Date().toISOString(),
                    occupied_end: new Date().toISOString(),
                },
            })
            .then((res: any) => {
                if (res && res.data) {
                    setTotalOccupiedCount(res.data.length)
                }
                console.log('response is....', res)
            })
    }

    const handleToggleTodayItemDialog = () => {
        setOpenTodayItemDialog(!openTodayItemDialog)
    }

    return (
        <React.Fragment>
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Card
                        className={`ez-card-glow ${classes.root}`}
                        onClick={() => handleToggleTodayItemDialog()}
                    >
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                <CalenderCardIcon />
                            </Typography>
                            <Typography
                                className={classes.counts}
                                color="textPrimary"
                            >
                                <Moment format="MM/DD">{new Date()}</Moment>
                            </Typography>
                            <Typography className={classes.title}>
                                Todays Date
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={3}
                    onClick={() => setOpenVacantAreas(true)}
                >
                    <Card className={`ez-card-glow ${classes.root}`}>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                <BuildingCardIcon />
                            </Typography>
                            <Typography
                                className={classes.counts}
                                color="textPrimary"
                            >
                                {totalVacancyCount}
                            </Typography>
                            <Typography className={classes.title}>
                                Total Vacancies
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Card className={`ez-card-glow ${classes.root}`}>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                <TimeCardIcon />
                            </Typography>
                            <Typography
                                className={classes.counts}
                                color="textPrimary"
                            >
                                {totalOccupiedCount}
                            </Typography>
                            <Typography className={classes.title}>
                                Total Occupied
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Card className={`ez-card-glow ${classes.root}`}>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                <TransferCardIcon />
                            </Typography>
                            <Typography
                                className={classes.counts}
                                color="textPrimary"
                            >
                                {units ? units.length : 0}
                            </Typography>
                            <Typography className={classes.title}>
                                Total Units
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <TodaysItemDialogBox
                open={openTodayItemDialog}
                handleClose={handleToggleTodayItemDialog}
                dialogContent={<TodaysItems />}
                dialogHeader={
                    <Moment format="MMMM DD YYYY">{new Date()}</Moment>
                }
                fullWidth={true}
            />
            <VacantUnitsModal
                open={openVacantAreas}
                handleClose={() => setOpenVacantAreas(false)}
                vacantAreas={vacantAreas}
            />
        </React.Fragment>
    )
}

export default CounterCards
