import {
    Button,
    CircularProgress,
    Divider,
    IconButton,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { Container, MessageForm, SideDrawer } from '../../components'
import { workorderContext } from '../../contexts'
import { toast } from 'react-toastify'
import { WorkorderResponse } from '../../store'
import { usePagination, useService, useUser } from '../../hooks'
import { Pagination } from '@material-ui/lab'
import { AI_LIST_FOOTER_H } from '../ActionItem/contants'
import { EZWorkWorkorderFilters } from './EZWorkWorkorderFilters'
import { Section, SectionList } from '../../components/SectionList'
import {
    IdBoolMap,
    ModelMap,
    convertMapToList,
    getDateRange,
    BULK_TRANSITION,
    BaseWorkorder,
} from '../../models'
import { AiDrawerId, AiFormState, closedAiFormState } from '../ActionItem/types'
import { WorkorderDetail } from './WorkorderDetail'
import { CreateChangeOrderModal } from './CreateChangeOrderModal'
import { useMessage } from '../../hooks/useMessage'
import { WorkorderRow } from '../../components/WorkorderLib/WorkorderRow'
import { axiosInstance } from '../../helpers'
import { ApartmentVendor } from '../NationalPropertyList'
import EditIcon from '@material-ui/icons/Edit'
import { BulkTransitionEditor } from '../Scheduler_v2/Drawers/BulkTransitionEditor'
import { hasPermission } from '../../models/Users/services'

export const EZWorkWorkorderList = () => {
    const [formState, setFormState] = useState<AiFormState>(closedAiFormState)
    const [openCreateChangeOrder, setOpenCreateChangeOrder] = useState(false)
    const [transitionModalOpen, setTransitionModalOpen] = useState(false)

    const openDetailDrawer = (selectionId: number, drawerId: AiDrawerId) => {
        setFormState({
            ...closedAiFormState,
            detailFormOpen: {
                index: -1,
                id: selectionId,
                drawerId: drawerId,
            },
        })
    }

    const {
        filteredWorkorderList,
        updateWorkorderState,
        loadingState,
        transitionWorkorder,
        workorderSelectionMap,
        setWorkorderSelectionMap,
        superTransitionWorkorders,
    } = workorderContext()

    const sortedWorkorders = filteredWorkorderList.sort((woA, woB) => {
        if (woA.apartment_id > woB.apartment_id) {
            return 1
        } else if (woA.apartment_id < woB.apartment_id) {
            return -1
        }
        return 0
    })

    const { page, setPage, pageData, numPages, start, end } = usePagination(
        sortedWorkorders ?? [],
    )

    const { createMessage } = useMessage()

    const { getServiceList } = useService()

    const { workspaceUser } = useUser()

    const [canSeeUnitNotesMap, setCanSeeUnitNotesMap] = useState<IdBoolMap>({})

    const selectedWo: WorkorderResponse | undefined = sortedWorkorders.find(
        (workorder) => workorder.id === formState.detailFormOpen.id,
    )

    const [selectionMode, setSelectionMode] = useState(false)

    useEffect(() => {
        getServiceList({})
        axiosInstance.get('company/apartment_vendors/').then((res) => {
            const newUnitNoteMap: IdBoolMap = {}
            res.data.forEach((aptVendor: ApartmentVendor) => {
                newUnitNoteMap[aptVendor.apartment.id] =
                    aptVendor.vendor_see_unit_notes
            })
            setCanSeeUnitNotesMap(newUnitNoteMap)
        })
    }, [])

    const theme = useTheme()

    const sections = useMemo(() => {
        return createWorkorderApartmentSections(pageData)
    }, [pageData])

    const workorders = selectionMode
        ? convertMapToList(workorderSelectionMap)
        : []

    let numberSelected = 0
    if (selectionMode) {
        numberSelected = Object.keys(workorderSelectionMap).length
    }

    const today = new Date()

    return (
        <Container
            flex={1}
            style={{
                margin: theme.spacing(1),
            }}
        >
            <Container style={{ width: '650px' }} direction="column">
                {selectionMode && (
                    <Container style={{ alignItems: 'center' }}>
                        <Container
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                                marginRight: theme.spacing(2),
                            }}
                        >
                            {numberSelected} Selected
                        </Container>
                        <Button
                            variant="contained"
                            style={{
                                margin: theme.spacing(2, 0, 2, 0),
                                backgroundColor: theme.palette.primary.dark,
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setTransitionModalOpen(true)
                            }}
                            size="small"
                        >
                            Bulk Transition
                        </Button>

                        <Button
                            size="small"
                            variant="contained"
                            style={{
                                marginLeft: theme.spacing(2),
                                backgroundColor: 'white',
                                color: theme.palette.primary.dark,
                                textTransform: 'none',
                                cursor: 'pointer',
                                marginRight: theme.spacing(2),
                            }}
                            onClick={() => setWorkorderSelectionMap({})}
                        >
                            {`Remove All (${workorders.length})`}
                        </Button>
                    </Container>
                )}
                {loadingState.getWorkorderList ? (
                    <Container
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: 'calc(100vh - 200px)',
                        }}
                    >
                        <CircularProgress size={100} />
                    </Container>
                ) : (
                    <Container
                        direction="column"
                        scrollY
                        style={{
                            maxHeight: selectionMode
                                ? 'calc(100vh - 250px)'
                                : 'calc(100vh - 200px)',
                            minHeight: selectionMode
                                ? 'calc(100vh - 250px)'
                                : 'calc(100vh - 200px)',
                        }}
                        flex={1}
                    >
                        <SectionList
                            sections={sections}
                            theme={theme}
                            renderItem={(workorder: WorkorderResponse) => {
                                return (
                                    <WorkorderRow
                                        key={`EZWORK_WORKORDER_ROW_${workorder.id}`}
                                        workorder={workorder}
                                        canSeeUnitNotes={
                                            canSeeUnitNotesMap[
                                                workorder.apartment_id
                                            ]
                                        }
                                        openMessageDrawer={() => {
                                            openDetailDrawer(
                                                workorder.id,
                                                AiDrawerId.message,
                                            )
                                        }}
                                        openUnitNotes={() => {
                                            openDetailDrawer(
                                                workorder.id,
                                                AiDrawerId.unitNotes,
                                            )
                                        }}
                                        transitionWorkorder={
                                            transitionWorkorder
                                        }
                                        user={workspaceUser}
                                        onClickWorkorder={(
                                            wo: WorkorderResponse,
                                        ) => {
                                            if (selectionMode) {
                                                const newEditSelection = {
                                                    ...workorderSelectionMap,
                                                }

                                                if (
                                                    newEditSelection[
                                                        workorder.id
                                                    ] !== undefined
                                                ) {
                                                    delete newEditSelection[
                                                        workorder.id
                                                    ]
                                                } else {
                                                    newEditSelection[
                                                        workorder.id
                                                    ] = workorder
                                                }

                                                setWorkorderSelectionMap(
                                                    newEditSelection,
                                                )
                                            } else {
                                                setFormState({
                                                    ...formState,
                                                    detailFormOpen: {
                                                        ...formState.detailFormOpen,
                                                        id: wo.id,
                                                        drawerId: null,
                                                    },
                                                })
                                            }
                                        }}
                                        isSelectedWorkorder={
                                            selectionMode
                                                ? workorderSelectionMap[
                                                      workorder.id
                                                  ] !== undefined
                                                : workorder.id ===
                                                  selectedWo?.id
                                        }
                                        updateWorkorderState={
                                            updateWorkorderState
                                        }
                                        createMessage={createMessage}
                                        selectionMode={selectionMode}
                                        width={650}
                                        disabled={
                                            new Date(workorder.start_date) >
                                            today
                                        }
                                    />
                                )
                            }}
                            renderSectionHeader={(section) => {
                                return (
                                    <Container
                                        style={{
                                            ...theme.typography.h5,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                            width: '100%',
                                            height: 60,
                                            padding: theme.spacing(1),
                                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                            borderTop: `1px solid ${theme.palette.grey[400]}`,
                                            backgroundColor:
                                                theme.palette.grey[200],

                                            alignItems: 'center',
                                        }}
                                    >
                                        {section.title}
                                    </Container>
                                )
                            }}
                        />
                    </Container>
                )}
                <Container
                    style={{
                        borderTop: `1px solid ${theme.palette.grey[400]}`,
                        padding: theme.spacing(1),
                        minHeight: AI_LIST_FOOTER_H,
                        maxHeight: AI_LIST_FOOTER_H,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Pagination
                        count={numPages}
                        page={page}
                        onChange={(_: unknown, newPage: number) => {
                            setPage(newPage)
                        }}
                    />
                    <span>
                        {start} - {end} of {sortedWorkorders.length}
                    </span>
                </Container>
            </Container>
            <Divider orientation="vertical" flexItem />
            <Container flex={1} style={{ width: '100%' }} direction="column">
                <Container>
                    <EZWorkWorkorderFilters />
                    <Container style={{ flex: 1 }} />
                    <IconButton
                        style={{
                            alignSelf: 'flex-start',
                        }}
                        onClick={() => {
                            setSelectionMode(!selectionMode)
                        }}
                        color={selectionMode ? 'primary' : 'default'}
                        disabled={
                            !hasPermission(workspaceUser, BULK_TRANSITION)
                        }
                    >
                        <Tooltip title="Select Workorders">
                            <EditIcon />
                        </Tooltip>
                    </IconButton>
                </Container>
                <Divider style={{ margin: theme.spacing(1, 0, 1, 0) }} />
                <Container style={{ padding: theme.spacing(2) }}>
                    {selectedWo && (
                        <WorkorderDetail
                            workorder={selectedWo}
                            canSeeUnitNotes={
                                canSeeUnitNotesMap[selectedWo.apartment_id]
                            }
                            setOpenCreateChangeOrder={setOpenCreateChangeOrder}
                        />
                    )}
                </Container>
            </Container>
            <SideDrawer
                open={
                    selectedWo?.id === formState.detailFormOpen.id &&
                    formState.detailFormOpen.drawerId === AiDrawerId.message
                }
                width={400}
                handleClose={() =>
                    setFormState({
                        ...formState,
                        detailFormOpen: {
                            ...formState.detailFormOpen,
                            drawerId: null,
                        },
                    })
                }
            >
                <MessageForm
                    theme={theme}
                    channelId={selectedWo?.channel}
                    secondaryChannelId={selectedWo?.inv_ins_channel}
                    afterCreateMessage={() => {
                        if (selectedWo) {
                            updateWorkorderState({
                                ...selectedWo,
                                message_count:
                                    (selectedWo.message_count ?? 0) + 1,
                            })
                        }
                    }}
                    secondaryTitle="From Inventory Inspection"
                />
            </SideDrawer>
            <SideDrawer
                open={
                    selectedWo?.id === formState.detailFormOpen.id &&
                    formState.detailFormOpen.drawerId === AiDrawerId.unitNotes
                }
                width={400}
                handleClose={() =>
                    setFormState({
                        ...formState,
                        detailFormOpen: {
                            ...formState.detailFormOpen,
                            drawerId: null,
                        },
                    })
                }
                title={`Unit Notes ${selectedWo?.unit_name ?? ''}`}
            >
                <MessageForm
                    theme={theme}
                    channelId={selectedWo?.unit_channel_id ?? undefined}
                    afterCreateMessage={() => {
                        if (selectedWo) {
                            updateWorkorderState({
                                ...selectedWo,
                                unit_notes_msg_count:
                                    (selectedWo.unit_notes_msg_count ?? 0) + 1,
                            })
                        }
                    }}
                />
            </SideDrawer>
            {selectedWo && (
                <CreateChangeOrderModal
                    workorder={selectedWo}
                    open={openCreateChangeOrder}
                    handleClose={() => setOpenCreateChangeOrder(false)}
                />
            )}
            <BulkTransitionEditor
                workorders={workorders}
                isOpen={transitionModalOpen}
                handleBulkTransitionClose={() => setTransitionModalOpen(false)}
                setSelectedWorkorders={(
                    selectedWorkorders: ModelMap<
                        WorkorderResponse | BaseWorkorder
                    >,
                ) =>
                    setWorkorderSelectionMap(
                        selectedWorkorders as ModelMap<WorkorderResponse>,
                    )
                }
                handleBulkEditClose={() => setSelectionMode(false)}
                superTransitionWorkorders={superTransitionWorkorders}
                isVendor
            />
        </Container>
    )
}

const createWorkorderApartmentSections = (workorders: WorkorderResponse[]) => {
    const sections: Section<WorkorderResponse>[] = []
    const apartments: ModelMap<{
        name: string
        id: number
    }> = {}

    if (workorders.length > 0) {
        apartments[workorders[0].apartment_id] = {
            name: workorders[0].apartment_name ?? '',
            id: workorders[0].apartment_id,
        }
    }

    workorders.forEach((workorder) => {
        if (apartments[workorder.apartment_id] === undefined) {
            apartments[workorder.apartment_id] = {
                name: workorder.apartment_name ?? '',
                id: workorder.apartment_id,
            }
        }
    })

    const apartmentList = convertMapToList(apartments)

    apartmentList.forEach((apartment) => {
        sections.push({
            title: apartment.name,
            data: workorders.filter(
                (workorder) => workorder.apartment_id === apartment.id,
            ),
            identifier: apartment.id,
        })
    })

    return sections
}
