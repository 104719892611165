import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, useTheme } from '@material-ui/core'
import BackgroundImage from '../../../../../assets/register_background.png'
import SkillcatLogo from '../../../../../assets/SkillCatLogo.png'
import { useUser } from '../../../../../hooks'
import { Routes } from '../../../../../helpers'
import {
    AddressState,
    Container,
    RegisterStepper,
} from '../../../../../components'
import { StepQuestionnaireAgreement } from './StepQuestionnaireAgreement'
import { StepQuestionnaireServices } from './StepQuestionnaireServices'
import { StepQuestionnaireArea } from './StepQuestionnaireArea'
import { StepQuestionnaireDetails } from './StepQuestionnaireDetails'
import {
    ListVendor,
    RegisterStep,
    Service,
    WorkSpaceUser,
} from '../../../../../models'

interface Props {
    type: string
    workspaceUser: WorkSpaceUser | undefined
    vendor: ListVendor | undefined
    activeStep: RegisterStep
    addressState: AddressState
    setAddressState: React.Dispatch<React.SetStateAction<AddressState>>
    selectedServices: Service[]
    setSelectedServices: Dispatch<SetStateAction<Service[]>>
    handleNextStep: () => void
}

export const Questionnaire = (props: Props) => {
    const {
        type,
        workspaceUser,
        vendor,
        activeStep,
        addressState,
        setAddressState,
        selectedServices,
        setSelectedServices,
        handleNextStep,
    } = props

    const theme = useTheme()
    const history = useHistory()
    const { logout } = useUser()

    return (
        <Container>
            <Container
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: 200,
                    width: '100%',
                    backgroundImage: `url(${BackgroundImage})`,
                    zIndex: 999,
                }}
            >
                <Container
                    style={{
                        flexDirection: 'row',
                        margin: theme.spacing(2, 3, 0, 'auto'),
                    }}
                >
                    {workspaceUser && (
                        <Container
                            style={{
                                flexDirection: 'column',
                                position: 'fixed',
                                top: 20,
                                right: 20,
                            }}
                        >
                            {type === 'Skillcat' && (
                                <img src={SkillcatLogo} height={50} />
                            )}

                            <Container>
                                <span
                                    style={{
                                        color: theme.palette.primary.light,
                                        ...theme.typography.body1,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        textShadow:
                                            '1px 1px 2px rgba(0,0,0,0.3)',
                                        margin: 'auto',
                                    }}
                                >
                                    Hi {workspaceUser?.active_workspace.name},{' '}
                                </span>
                                <Button
                                    onClick={() => {
                                        logout()
                                        history.push(Routes.login)
                                    }}
                                    style={{
                                        color: 'white',
                                        ...theme.typography.body1,
                                        textTransform: 'none',
                                        textShadow:
                                            '1px 1px 2px rgba(0,0,0,0.3)',
                                    }}
                                >
                                    Log-out
                                </Button>
                            </Container>
                        </Container>
                    )}
                </Container>
                <RegisterStepper activeStep={activeStep} />
            </Container>
            <Container
                style={{
                    flex: 1,
                    width: '100%',
                    paddingTop: 200,
                }}
            >
                {activeStep === RegisterStep.DETAIL_STEP && (
                    <StepQuestionnaireDetails
                        type={type}
                        theme={theme}
                        onNextStep={handleNextStep}
                    />
                )}
                {activeStep === RegisterStep.SERVICE_AREA_STEP && (
                    <StepQuestionnaireArea
                        type={type}
                        vendor={vendor}
                        theme={theme}
                        onNextStep={handleNextStep}
                        addressState={addressState}
                        setAddressState={setAddressState}
                    />
                )}
                {activeStep === RegisterStep.SELECT_SERVICES_STEP && (
                    <StepQuestionnaireServices
                        theme={theme}
                        vendor={vendor}
                        onNextStep={handleNextStep}
                        selectedServices={selectedServices}
                        setSelectedServices={setSelectedServices}
                    />
                )}
                {activeStep === RegisterStep.AGREEMENTS_STEP && (
                    <StepQuestionnaireAgreement theme={theme} vendor={vendor} />
                )}
            </Container>
        </Container>
    )
}
