import React from 'react'
import { Container } from '../Container'
import { Chip, Theme } from '@material-ui/core'

export interface FilterChipData {
    id: string | number
    title: string
    onClick: () => void
}

interface Props {
    theme: Theme
    chips: FilterChipData[]
}

export const FilterChips = (props: Props) => {
    const { chips, theme } = props

    return (
        <Container style={{ flex: 1, minHeight: 32 }}>
            {chips.map((chip) => (
                <FilterChip
                    key={`CHIP-${chip.title}-${chip.id}`}
                    theme={theme}
                    filterChip={chip}
                />
            ))}
        </Container>
    )
}

interface FilterChipProps {
    theme: Theme
    filterChip: FilterChipData
}

const FilterChip = (props: FilterChipProps) => {
    const { theme, filterChip } = props

    const chipStyle: React.CSSProperties = {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        marginRight: theme.spacing(1),
    }

    return (
        <Chip
            clickable
            style={chipStyle}
            onClick={filterChip.onClick}
            label={filterChip.title}
        />
    )
}
