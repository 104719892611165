import React, { useState } from 'react'

import clsx from 'clsx'

import styles from './ConfirmationButton.module.css'

import { Container } from '../Container'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CloseIcon from '@material-ui/icons/Close'
import { useAnimationDeadline } from '../../hooks'
import { TimerProgress } from '../TimerProgress'

type AnimationType = 'slide-over' | 'slide-through'

const START = 0
const COUNTING_DOWN = 1
const FINISHED = 2

type ButtonStatusOptions = typeof START | typeof COUNTING_DOWN | typeof FINISHED

type ConfirmationState = {
    status: ButtonStatusOptions
    ellapsedTime: number
}

interface Props {
    actionLabel: string
    onConfirm: () => void
    windowWidth?: number
    indicatorWidth?: number
    animationType?: AnimationType
    actionBgColor?: string
    actionHighlightColor?: string
    indicatorColor?: string
    confirmationBgColor?: string
    confirmationHighlightColor?: string
    cancelBackgroundColor?: string
    tickSpeedMs?: number
    confirmationDelayMs?: number
}

export const ConfirmationButton: React.FC<Props> = ({
    actionLabel,
    onConfirm,
    windowWidth = 82,
    indicatorWidth = 24,
    animationType = 'slide-over',
    actionBgColor = '#DBE2F6',
    actionHighlightColor = '#2C80FF',
    indicatorColor = '#FFFFFF',
    confirmationBgColor = '#DEF6ED',
    confirmationHighlightColor = '#008C85',
    cancelBackgroundColor = '#ff7459',
    tickSpeedMs = 1000,
    confirmationDelayMs = 3000,
}) => {
    const totalWidth = windowWidth + indicatorWidth

    const [
        confirmationState,
        setConfirmationState,
    ] = useState<ConfirmationState>({
        status: START,
        ellapsedTime: confirmationDelayMs,
    })

    const activated = confirmationState.status !== START
    const chevronRotation = activated ? '180deg' : 0
    const highLightColor = activated
        ? confirmationHighlightColor
        : actionHighlightColor

    const windowStyle: React.CSSProperties = {
        width: windowWidth,
        justifyContent: 'center',
        alignItems: 'center',
    }

    const indicatorContainerStyle: React.CSSProperties = {
        width: indicatorWidth,
        backgroundColor: highLightColor,
        justifyContent: 'center',
        alignItems: 'center',
    }

    const actionWindowStyle: React.CSSProperties = {
        ...windowStyle,
        backgroundColor: actionBgColor,
    }

    const confirmWindowStyle: React.CSSProperties = {
        ...windowStyle,
        backgroundColor:
            confirmationState.status !== START
                ? confirmationBgColor
                : cancelBackgroundColor,
    }

    const indicatorRotationStyle: React.CSSProperties = {
        transform: `rotate(${chevronRotation})`,
    }

    const timerProgress = confirmationDelayMs - confirmationState.ellapsedTime

    const actionWindow = (
        <Container style={actionWindowStyle}>
            <span
                style={{
                    color: actionHighlightColor,
                    fontWeight: 700,
                    fontSize: 12,
                    lineHeight: 15,
                }}
            >
                {actionLabel}
            </span>
        </Container>
    )

    const confirmationWindow = (
        <Container style={confirmWindowStyle}>
            {confirmationState.status !== START ? (
                <TimerProgress
                    timerDurationMs={confirmationDelayMs}
                    progressMs={timerProgress}
                    progressColor="#2C80FF"
                    completeColor="#008C85"
                    minProgress={15}
                    size={28}
                />
            ) : (
                <CloseIcon htmlColor={indicatorColor} />
            )}
        </Container>
    )

    const slideLength = windowWidth

    const { startTimer, cancelTimer } = useAnimationDeadline(
        (timeRemainingMs: number) => {
            if (timeRemainingMs >= 0) {
                setConfirmationState({
                    status: COUNTING_DOWN,
                    ellapsedTime: timeRemainingMs,
                })
            } else {
                setConfirmationState({
                    status: FINISHED,
                    ellapsedTime: 0,
                })
                onConfirm()
            }
        },
    )

    return (
        <Container
            className={clsx(styles.Container)}
            style={{
                height: 33,
                minWidth: totalWidth,
                maxWidth: totalWidth,
                border: `1px solid ${highLightColor}`,
            }}
            onClick={(e) => {
                e.stopPropagation()
                if (confirmationState.status === START) {
                    startTimer(confirmationDelayMs, tickSpeedMs)
                } else if (confirmationState.status === COUNTING_DOWN) {
                    setConfirmationState({
                        status: START,
                        ellapsedTime: 0,
                    })
                    cancelTimer()
                }
            }}
        >
            {animationType === 'slide-over' && actionWindow}

            <Slider
                animationConfig={{
                    animationType: animationType,
                    slideLength: slideLength,
                    activated: activated,
                }}
            >
                {animationType === 'slide-through' && actionWindow}
                <Container
                    style={{
                        ...indicatorContainerStyle,
                    }}
                >
                    <Container
                        className={clsx(styles.Rotates)}
                        style={{
                            ...indicatorRotationStyle,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <ChevronLeftIcon htmlColor={indicatorColor} />
                    </Container>
                </Container>

                {confirmationWindow}
            </Slider>
        </Container>
    )
}

const Slider: React.FC<{ animationConfig: AnimationConfig }> = ({
    animationConfig,
    children,
}) => {
    const sliderStyle: React.CSSProperties = {
        left: getSliderPosition(animationConfig),
    }

    return (
        <Container className={clsx(styles.Slides)} style={sliderStyle}>
            {children}
        </Container>
    )
}

const getSliderPosition = (config: AnimationConfig) => {
    const animationTypeModifier = config.animationType === 'slide-over' ? 1 : -1
    return config.activated ? 0 : config.slideLength * animationTypeModifier
}

type AnimationConfig = {
    animationType: AnimationType
    activated: boolean
    slideLength: number
}
