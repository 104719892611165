import React, { useEffect, useState } from 'react'

import { Container, Selector } from '../../../components'
import {
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    Slide,
    TextField,
    useTheme,
} from '@material-ui/core'
import { isStringNumeric } from '../../../helpers'
import { DwollaBankAccountType } from '../../../models'
import { CreateFundingSourceBody } from '../../../contexts'

interface Props {
    open: boolean
    onClose: () => void
    onSubmit: (request: CreateFundingSourceBody) => void
}

export const FundingSourceForm = (props: Props) => {
    const { open, onClose, onSubmit } = props

    const theme = useTheme()

    const [accountNumber, setAccountNumber] = useState('')
    const [accountNumberVerify, setAccountNumberVerify] = useState('')
    const [routingNumber, setRoutingNumber] = useState('')
    const [accountType, setAccountType] = useState(
        DwollaBankAccountType.CHECKING,
    )

    const [accountName, setAccountName] = useState('')

    const [doValidate, setDoValidate] = useState(false)

    const routingNumValid = () => {
        // Routing numbers must have 9 characters
        const lenValid = routingNumber.length === 9

        // Validate that  the routing number has only integers
        const digitsValid = isStringNumeric(routingNumber)

        // Return early if the routing number is not the correct length or if the string contains invalid characters
        if (!digitsValid || !lenValid) {
            return false
        }

        // https://developers.dwolla.com/api-reference/funding-sources/create-funding-source-for-customer#request-parameters---bank-funding-source
        // Note: Validation of the routing number includes: a checksum,
        // the first two digits of the routing number must fall within the range "01" through "12",
        // or "21" through "32", and the string value must consist of nine digits.
        const first2DigitsStr = routingNumber.substring(0, 2)
        const first2DigitsNum = Number(first2DigitsStr)

        if (
            !(
                (first2DigitsNum >= 1 && first2DigitsNum <= 12) ||
                (first2DigitsNum >= 21 && first2DigitsNum <= 32)
            )
        ) {
            return false
        }

        const ints = routingNumber
            .split('')
            .map((digitChar) => Number(digitChar))

        const sum =
            7 * (ints[0] + ints[3] + ints[6]) +
            3 * (ints[1] + ints[4] + ints[7]) +
            9 * (ints[2] + ints[5])

        console.log(sum, sum % 10, ints[8])

        return sum % 10 === ints[8]
    }

    const accountNumValid = (value: string) => {
        // Bank account numbers are not very strictly defined
        // ACH transfers require between 4 and 17 digits
        const lenValid = value.length >= 4 && value.length <= 17

        return lenValid && isStringNumeric(value)
    }

    const accountNameValid = () => {
        return accountName.length > 2 && accountName.length < 50
    }

    const formValid = () => {
        return (
            routingNumValid() &&
            accountNumValid(accountNumber) &&
            accountNameValid() &&
            accountNumber === accountNumberVerify
        )
    }

    const setNumericStrState = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        setStateAction: (value: React.SetStateAction<string>) => void,
    ) => {
        const newVal = e.target.value
        if (isStringNumeric(newVal)) {
            setStateAction(newVal)
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper style={{ width: 500 }}>
                    <Container
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                            border: `1px solid ${theme.palette.grey[700]}`,
                            borderRadius: theme.shape.borderRadius,
                            padding: theme.spacing(2),
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            Add a bank account
                        </span>

                        <TextField
                            label="Account Name"
                            value={accountName}
                            style={inputStyle}
                            onChange={(e) => setAccountName(e.target.value)}
                            required
                            error={doValidate && !accountNameValid()}
                        />

                        <FormControl style={inputStyle}>
                            <InputLabel>Account Type</InputLabel>
                            <Select
                                value={accountType}
                                label="Account Type"
                                onChange={(e) =>
                                    setAccountType(
                                        e.target.value as DwollaBankAccountType,
                                    )
                                }
                            >
                                <MenuItem
                                    value={DwollaBankAccountType.CHECKING}
                                >
                                    <span>Checking</span>
                                </MenuItem>

                                <MenuItem value={DwollaBankAccountType.SAVINGS}>
                                    <span>Savings</span>
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <TextField
                            label="Routing Number"
                            value={routingNumber}
                            onChange={(e) =>
                                setNumericStrState(e, setRoutingNumber)
                            }
                            required
                            inputProps={{ maxLength: 9 }}
                            style={inputStyle}
                            error={!routingNumValid()}
                        />
                        <TextField
                            label="Account Number"
                            style={inputStyle}
                            value={accountNumber}
                            onChange={(e) =>
                                setNumericStrState(e, setAccountNumber)
                            }
                            required
                            error={
                                doValidate && !accountNumValid(accountNumber)
                            }
                            inputProps={{ maxLength: 17 }}
                        />
                        <TextField
                            label="Re-enter Account Number"
                            style={inputStyle}
                            value={accountNumberVerify}
                            onChange={(e) =>
                                setNumericStrState(e, setAccountNumberVerify)
                            }
                            required
                            error={
                                doValidate &&
                                accountNumberVerify !== accountNumber
                            }
                            inputProps={{ maxLength: 17 }}
                        />

                        <Container style={{ justifyContent: 'space-between' }}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={onClose}
                                style={{ marginTop: theme.spacing(2) }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    if (formValid()) {
                                        onSubmit({
                                            account_number: accountNumber,
                                            routing_number: routingNumber,
                                            account_name: accountName,
                                            account_type: accountType,
                                        })
                                    } else {
                                        setDoValidate(true)
                                    }
                                }}
                                style={{ marginTop: theme.spacing(2) }}
                            >
                                Submit
                            </Button>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

const inputStyle: React.CSSProperties = {
    marginBottom: 24,
}
