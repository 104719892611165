import React, { useState, useEffect } from 'react'
import {
    Button,
    CircularProgress,
    Divider,
    Modal,
    Paper,
    Slide,
    TableCell,
    TableRow,
    useTheme,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import {
    getLineageUnits,
    Unit,
    IdBoolMap,
    InspectionDetail,
    ModelMap,
    AreaConfig,
    BaseUnitInspection,
    AreaStatusTag,
    Frequency,
    traverse,
    UnitInspectFreqConfig,
    IdentifiableObject,
} from '../../models'
import {
    CellData,
    Container,
    HeadCell,
    NumberInput,
    RowData,
    Selector,
    SimpleTable,
} from '../../components'

import {
    assignUnitInspections,
    ASSIGN_UNIT_INSPECTION_REQUEST,
    FinderLocationSelection,
    FinderSelectionMode,
    RootState,
} from '../../store'
import { toast } from 'react-toastify'
import {
    useAppDispatch,
    useAptConfig,
    _useFinderSelection,
    useRootInfrastructure,
    useUser,
} from '../../hooks'
import { InspectionAreaCard } from './InspectionAreaCard'
import { useSelector } from 'react-redux'
import { axiosInstance, BASE_URL, containsOneOf } from '../../helpers'
import { AreaSelectorCollection } from '../../components/AreaSelector'

interface Props {
    open: boolean
    makeFilteredRequest: () => void
    inspectionDetail?: InspectionDetail
    unitCompletionMap?: ModelMap<BaseUnitInspection>
    areaConfigMap: ModelMap<AreaConfig>
    areaStatusMap?: ModelMap<AreaStatusTag>
    onClose: () => void
    onFrequencyCreate?: (frequency: Frequency) => void
    onFrequencyDelete?: (frequency: Frequency) => void
    viewOnlyMode?: Frequency
}

interface Row extends RowData {
    location: CellData<string>
    unit: CellData<Unit>
}
interface PeriodOptions extends IdentifiableObject {
    name: string
    substring: string
}

export const PERIOD_OPTIONS: PeriodOptions[] = [
    {
        id: 1,
        name: 'Daily',
        substring: 'DY',
    },
    {
        id: 2,
        name: 'Weekly',
        substring: 'WK',
    },
    {
        id: 3,
        name: 'Bi-Weekly',
        substring: 'BW',
    },
    {
        id: 4,
        name: 'Monthly',
        substring: 'MN',
    },
    {
        id: 5,
        name: 'Bi-Yearly',
        substring: 'BY',
    },
    {
        id: 6,
        name: 'Yearly',
        substring: 'YR',
    },
    {
        id: 7,
        name: 'Day Count',
        substring: 'DC',
    },
    {
        id: 8,
        name: 'Week Days',
        substring: 'WD',
    },
]

const ASSIGN_TO_MY_TEAM_ID = -1

export const InspectionAssignModal = (props: Props) => {
    const {
        open,
        inspectionDetail,
        onClose,
        unitCompletionMap: unitInspectionMap,
        areaConfigMap,
        areaStatusMap,
        viewOnlyMode,
    } = props

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        height: '95%',
                        width: '95%',
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                    }}
                >
                    <InspectionAssignComponent
                        open={open}
                        inspectionDetail={inspectionDetail}
                        onClose={onClose}
                        unitCompletionMap={unitInspectionMap}
                        areaConfigMap={areaConfigMap}
                        areaStatusMap={areaStatusMap}
                        viewOnlyMode={viewOnlyMode}
                        onFrequencyCreate={props.onFrequencyCreate}
                        onFrequencyDelete={props.onFrequencyDelete}
                        makeFilteredRequest={props.makeFilteredRequest}
                    />
                </Paper>
            </Slide>
        </Modal>
    )
}

export const InspectionAssignComponent = (props: Props) => {
    const { inspectionTypeList } = useAptConfig({})

    const {
        open,
        inspectionDetail,
        onClose,
        unitCompletionMap: unitInspectionMap,
        areaConfigMap,
        areaStatusMap,
        viewOnlyMode,
    } = props

    const theme = useTheme()

    const dispatch = useAppDispatch()

    const { userList, getUserList } = useUser()
    const [assignToId, setAssignToId] = useState(-1)
    const [periodId, setPeriodId] = useState(-1)
    const [daysCount, setDaysCount] = useState<string | undefined>()
    const [startDate, setStartDate] = useState<MaterialUiPickersDate>(
        inspectionDetail ? new Date(inspectionDetail.start_date) : null,
    )
    const [endDate, setEndDate] = useState<MaterialUiPickersDate>(
        inspectionDetail ? new Date(inspectionDetail.end_date) : null,
    )
    const [inspectionType, setInspectionType] = useState<number>(-1)
    const [areaSelection, setAreaSelection] = useState<IdBoolMap>({})
    const [unitsToAssign, setUnitsToAssign] = useState<Unit[]>([])

    const assignmentFinderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.PrunedSelection,
        selectionMode: FinderSelectionMode.Multi,
    })

    const createUnitInspectionLoading = useSelector(
        (state: RootState) =>
            state.inspection.isLoading[ASSIGN_UNIT_INSPECTION_REQUEST],
    )
    const { tree } = useRootInfrastructure(true)

    // Table setup
    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    const cellStyle: React.CSSProperties = {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightRegular,
    }

    const headCells: HeadCell<Row>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'location',
            label: 'Location',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'unit',
            label: 'Unit',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'areas',
            label: 'Inspection Areas',
            style: headCellStyle,
        },
    ]

    const createRow = (unit: Unit): Row => {
        return {
            location: {
                sortValue: `${unit.folder.path}${unit.folder.name}`,
                value: `${unit.folder.path}${unit.folder.name}`,
            },
            unit: {
                sortValue: -1,
                value: unit,
            },
        }
    }
    // End Table Setup

    const buildAreaStateForUnit = (
        unit: Unit,
        mutableAreaState: IdBoolMap,
        unitFreq?: UnitInspectFreqConfig,
    ) => {
        const areaMap: IdBoolMap = {}

        if (unitFreq) {
            unitFreq.area_ins.forEach((areaFreq) => {
                areaMap[areaFreq.area] = true
            })
        }

        unit.areas.forEach((area) => {
            if (unitFreq) {
                mutableAreaState[area.id] = area.id in areaMap
            } else if (!areaStatusMap || areaStatusMap[area.id] === undefined) {
                mutableAreaState[area.id] = true
            } else {
                mutableAreaState[area.id] = areaStatusMap[
                    area.id
                ]!.area_status_config.should_service
            }
        })
    }

    useEffect(() => {
        const mutableAreaState: IdBoolMap = {}
        const mutableUnitsToAssign: Unit[] = []
        if (open) {
            if (viewOnlyMode) {
                // create a unitFreq map to reduce O(n)
                const unitFreqBoolMap: ModelMap<UnitInspectFreqConfig> = {}
                viewOnlyMode.unit_ins?.forEach((unitFreq) => {
                    unitFreqBoolMap[unitFreq.unit] = unitFreq
                })
                traverse(tree.root, (folder) => {
                    folder.units.forEach((unit) => {
                        if (unit.id in unitFreqBoolMap) {
                            mutableUnitsToAssign.push(unit)
                            // handle areas
                            buildAreaStateForUnit(
                                unit,
                                mutableAreaState,
                                unitFreqBoolMap[unit.id],
                            )
                        }
                    })
                })
                // set other view only fields
                setInspectionType(viewOnlyMode.inspection_type.id)
                setPeriodId(
                    PERIOD_OPTIONS.find(
                        (freq) => freq.substring === viewOnlyMode.period,
                    )?.id ?? -1,
                )
                setStartDate(new Date(viewOnlyMode.start_point))
                setDaysCount(String(viewOnlyMode.days))
            } else {
                const handledUnits: IdBoolMap = {}

                getUserList({ params: { my_team: true } })

                // Recursively get the units of the selected folders
                Object.keys(assignmentFinderSelection.selection.folder).forEach(
                    (key) => {
                        if (key === 'length') return

                        const folder =
                            assignmentFinderSelection.selection.folder[
                                parseInt(key)
                            ]
                        if (folder === undefined) return

                        const folderUnits = getLineageUnits(folder)

                        // It is possible that folderUnits are already in handledUnits because
                        // The user selected both ParentFolder and ChildFolder
                        // Prevent units from being duplicated

                        folderUnits.forEach((unit) => {
                            if (handledUnits[unit.id]) return
                            handledUnits[unit.id] = true

                            // If the unit is already inspected, exclude it
                            if (
                                !unitInspectionMap ||
                                unitInspectionMap[unit.id] === undefined
                            ) {
                                mutableUnitsToAssign.push(unit)
                                buildAreaStateForUnit(unit, mutableAreaState)
                            }
                        })
                    },
                )

                // Add in the individual units the user selected
                Object.keys(assignmentFinderSelection.selection.unit).forEach(
                    (key) => {
                        if (key === 'length') return
                        const unit =
                            assignmentFinderSelection.selection.unit[
                                parseInt(key)
                            ]
                        if (unit === undefined) return
                        if (handledUnits[unit.id]) return
                        handledUnits[unit.id] = true
                        // If the unit is already inspected, exclude it
                        if (unitInspectionMap) {
                            if (unitInspectionMap[unit.id] === undefined) {
                                mutableUnitsToAssign.push(unit)
                                buildAreaStateForUnit(unit, mutableAreaState)
                            }
                        } else {
                            mutableUnitsToAssign.push(unit)
                            buildAreaStateForUnit(unit, mutableAreaState)
                        }
                    },
                )
            }
        }

        setAreaSelection(mutableAreaState)
        setUnitsToAssign(mutableUnitsToAssign)
    }, [open])

    const handleAssign = () => {
        const areaList = Object.keys(areaSelection).reduce<number[]>(
            (prev, areaIdStr) => {
                const areaId = Number(areaIdStr)
                if (areaSelection[areaId]) {
                    return prev.concat(areaId)
                }
                return prev
            },
            [],
        )
        if (inspectionDetail) {
            dispatch(
                assignUnitInspections({
                    body: {
                        inspection: inspectionDetail.id,
                        areas: areaList,
                        assign_to: assignToId !== -1 ? assignToId : undefined,
                        start_date:
                            startDate !== null
                                ? startDate.toISOString()
                                : undefined,
                        end_date:
                            endDate !== null
                                ? endDate.toISOString()
                                : undefined,
                    },
                }),
            ).then(() => {
                toast.success('Created!')
                props.makeFilteredRequest()
                onClose()
            })
        } else {
            const frequency = PERIOD_OPTIONS.find(
                (freq) => freq.id === periodId,
            )
            // we are creating an ins frequency
            axiosInstance
                .post('frequency/', {
                    frequency_type: 'IN',
                    areas: areaList,
                    units: unitsToAssign.map((unit) => unit.id),
                    start_date:
                        startDate !== null
                            ? startDate.toISOString()
                            : undefined,
                    period: frequency?.substring,
                    inspection_type: inspectionType,
                    assign_to: assignToId !== -1 ? assignToId : undefined,
                    days: daysCount,
                })
                .then((res) => {
                    toast.success(`Inspection Frequency Created!`)
                    if (props.onFrequencyCreate) {
                        props.onFrequencyCreate(res.data)
                    }
                    setInspectionType(-1)
                    setPeriodId(-1)
                    setStartDate(new Date())
                    setDaysCount('1')
                    onClose()
                })
        }
    }

    return (
        <Container direction="column" style={{ height: '100%', width: '100%' }}>
            {/* Header */}
            <Container
                style={{ padding: theme.spacing(2) }}
                alignItems="center"
            >
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {unitInspectionMap
                        ? 'Assign Inspections'
                        : 'Assign Inspection Frequency'}
                </span>

                <div style={{ flex: 1 }} />
                {!unitInspectionMap && periodId === 7 && (
                    <NumberInput
                        value={daysCount}
                        style={{ margin: theme.spacing(2) }}
                        disabled={viewOnlyMode !== undefined}
                        onChange={(event) => {
                            setDaysCount(event.target.value)
                        }}
                        label="Number of Days"
                        variant="outlined"
                    />
                )}
                {!unitInspectionMap && (
                    <Selector
                        label="Frequency"
                        currentValue={periodId}
                        disabled={viewOnlyMode !== undefined}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            setPeriodId(event.target.value as number)
                        }}
                        data={PERIOD_OPTIONS}
                        getDisplayString={(frequency) => frequency.name}
                        customStyle={{ formControl: { minWidth: 200 } }}
                    />
                )}
                {!unitInspectionMap && (
                    <Selector
                        label="Inspection Type"
                        currentValue={inspectionType}
                        disabled={viewOnlyMode !== undefined}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            setInspectionType(event.target.value as number)
                        }}
                        data={inspectionTypeList ?? []}
                        getDisplayString={(inspectionType) =>
                            inspectionType.name
                        }
                        customStyle={{ formControl: { minWidth: 200 } }}
                    />
                )}
                <KeyboardDatePicker
                    clearable
                    disabled={viewOnlyMode !== undefined}
                    value={startDate}
                    onChange={(date) => {
                        setStartDate(date)
                        if (endDate === null || (date && endDate < date)) {
                            //if the end date is before the start date, set the end date to the same as the start date
                            setEndDate(date)
                        }
                    }}
                    format="MM/dd/yyyy"
                    inputVariant="outlined"
                    style={{
                        margin: theme.spacing(0, 2),
                        width: 180,
                        marginLeft: theme.spacing(2),
                    }}
                    label="Start Date"
                />
                {unitInspectionMap && (
                    <KeyboardDatePicker
                        value={endDate}
                        clearable
                        onChange={(date) => {
                            if (date === null) {
                                return setEndDate(null)
                            }
                            if (startDate && date >= startDate) {
                                // if date is LATER than the start date
                                return setEndDate(date)
                            }
                            return (
                                startDate &&
                                toast.error(
                                    'End Date must be the day of or after the Start Date',
                                )
                            )
                        }}
                        format="MM/dd/yyyy"
                        inputVariant="outlined"
                        style={{
                            margin: theme.spacing(0, 2),
                            width: 180,
                        }}
                        label="End Date"
                    />
                )}
                <Selector
                    label="Assign To"
                    disabled={viewOnlyMode !== undefined}
                    customStyle={{ formControl: { minWidth: 200 } }}
                    getDisplayString={(user) => user.name}
                    data={[
                        { id: ASSIGN_TO_MY_TEAM_ID, name: 'My Team' },
                        ...userList,
                    ]}
                    currentValue={assignToId}
                    onChange={(
                        event: React.ChangeEvent<{
                            value: unknown
                        }>,
                    ) => {
                        setAssignToId(event.target.value as number)
                    }}
                />
                <AreaSelectorCollection
                    areaConfigMap={areaConfigMap}
                    areaSelectionMap={areaSelection}
                    setAreaSelectionMap={setAreaSelection}
                    units={unitsToAssign}
                />
            </Container>

            <Divider />

            <Container
                flex={1}
                scrollY
                style={{
                    padding: theme.spacing(2, 3),
                    backgroundColor: theme.palette.grey[100],
                }}
            >
                <SimpleTable<Row>
                    rows={unitsToAssign.map((unit) => createRow(unit))}
                    render={(row, index) => {
                        return (
                            <TableRow key={`ASSIGN_UNIT_ROW_${index}`}>
                                <TableCell
                                    style={{
                                        ...cellStyle,
                                        width: '20%',
                                    }}
                                >
                                    {row.location.value}
                                </TableCell>

                                <TableCell
                                    style={{
                                        ...cellStyle,
                                        width: '20%',
                                    }}
                                >
                                    <Container style={{ alignItems: 'center' }}>
                                        <span>{row.unit.value.name}</span>
                                    </Container>
                                </TableCell>

                                <TableCell
                                    style={{
                                        ...cellStyle,
                                    }}
                                >
                                    <Container style={{ flexWrap: 'wrap' }}>
                                        {row.unit.value.areas.map((area) => {
                                            return (
                                                <InspectionAreaCard
                                                    key={`ASSIGN_INSPECT_AREA_${area.id}`}
                                                    area={area}
                                                    areaConfig={
                                                        areaConfigMap[
                                                            area.area_config
                                                        ]
                                                    }
                                                    setSelectionState={() =>
                                                        setAreaSelection({
                                                            ...areaSelection,
                                                            [area.id]: !areaSelection[
                                                                area.id
                                                            ],
                                                        })
                                                    }
                                                    selectionState={
                                                        areaSelection
                                                    }
                                                />
                                            )
                                        })}
                                    </Container>
                                </TableCell>
                            </TableRow>
                        )
                    }}
                    orderByDefault="location"
                    headCells={headCells}
                />
            </Container>
            <Divider />
            <Container
                style={{
                    padding: theme.spacing(2),
                    justifyContent: 'flex-end',
                }}
            >
                {createUnitInspectionLoading ? (
                    <CircularProgress size={20} />
                ) : (
                    <>
                        {viewOnlyMode && (
                            <Button
                                variant="outlined"
                                color="default"
                                style={{
                                    marginRight: theme.spacing(1),
                                }}
                                onClick={() => {
                                    axiosInstance.delete(
                                        `frequency/${viewOnlyMode.id}/`,
                                    )
                                    if (props.onFrequencyDelete) {
                                        props.onFrequencyDelete(viewOnlyMode)
                                    }
                                    onClose()
                                }}
                            >
                                Delete
                            </Button>
                        )}

                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={props.onClose}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ marginLeft: theme.spacing(1) }}
                            disabled={viewOnlyMode !== undefined}
                            onClick={handleAssign}
                        >
                            Create
                        </Button>
                    </>
                )}
            </Container>
        </Container>
    )
}
