import React from 'react'
import { CircularProgress, useTheme } from '@material-ui/core'
import { Container } from '../Container'
import CheckIcon from '@material-ui/icons/Check'

interface Props {
    timerDurationMs: number
    progressMs: number
    size: number
    progressColor?: string
    completeColor?: string
    minProgress?: number
}

export const TimerProgress = (props: Props) => {
    const theme = useTheme()

    const {
        timerDurationMs,
        progressMs,
        size,
        progressColor = theme.palette.secondary.main,
        completeColor = theme.palette.secondary.main,
        minProgress = 0,
    } = props

    const progress = (progressMs / timerDurationMs) * 100

    const displayColor = progress >= 100 ? completeColor : progressColor

    return (
        <Container
            style={{
                position: 'relative',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CircularProgress
                value={Math.max(progress, minProgress)}
                size={size}
                variant="determinate"
                style={{ position: 'absolute', color: displayColor }}
            />
            {progress === 100 && (
                <CheckIcon fontSize="small" htmlColor={displayColor} />
            )}
        </Container>
    )
}
