import React, { useState } from 'react'

import NumberFormat from 'react-number-format'
import { toast } from 'react-toastify'

import {
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PersonAddIcon from '@material-ui/icons/PersonAdd'

import { BaseProps } from '../../types'
import { ListVendor, TemplateService } from '../../../../models'

import { TemplateVendorRow } from './TemplateVendorRow'
import { VENDOR_BAR_H } from '../../constants'
import { SelectorPopover } from '../SelectorPopover'
import { usePopover } from '../../../../hooks/usePopover'

import {
    getDifferenceInDays,
    setEndOfDay,
    setMidnight,
} from '../../../../helpers'
import { Container } from '../../../Container'

interface Props extends BaseProps {
    templateService: TemplateService
    vendorList: ListVendor[]
    projectDuration: number
    lastRow?: boolean
}

export const TemplateServiceDetailRow = (props: Props) => {
    const { theme, templateService, scheduleController } = props

    const vendorPopoverState = usePopover()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const containerHeight =
        (templateService.template_service_vendors.length + 1) * VENDOR_BAR_H

    const vendorOptions = props.vendorList.filter((vnd) => {
        const serviceOffered =
            vnd.services.find(
                (srv) => templateService.service.id === srv.id,
            ) !== undefined

        const alreadyIncluded =
            templateService.template_service_vendors.find(
                (tsv) => tsv.vendor.id === vnd.id,
            ) !== undefined

        return serviceOffered && !alreadyIncluded
    })

    const dayOffMap = scheduleController.getDayOffMap()

    const totalSerivceAreas =
        scheduleController.schedule?.total_service_areas ?? 0

    let totalThroughput = 0

    let totalWorkDays = 0
    const vendorRows = templateService.template_service_vendors.map((tsv) => {
        const workDayCount = scheduleController.getVendorWorkdayCount(
            tsv,
            dayOffMap,
        )

        totalWorkDays += workDayCount

        const startDate = new Date(tsv.start_date)
        const endDate = new Date(tsv.end_date)
        setMidnight(startDate)
        setEndOfDay(endDate)

        const timlineDays = getDifferenceInDays(endDate, startDate)

        const expectedThroughput =
            (tsv.capacity * workDayCount) / (timlineDays + 1)
        totalThroughput += expectedThroughput
        return (
            <TemplateVendorRow
                key={`TEMPLATE-VENDOR-${tsv.id}`}
                {...props}
                templateVendor={tsv}
                expectedThroughput={expectedThroughput}
            />
        )
    })

    const safeWorkDays = Math.max(1, totalWorkDays)

    const dailyCapacity = totalSerivceAreas / safeWorkDays

    return (
        <Grid
            container
            item
            xs={12}
            style={{
                boxSizing: 'border-box',
                borderBottom: props.lastRow
                    ? undefined
                    : `1px solid ${theme.palette.grey[400]}`,
                borderRight: `1px solid ${theme.palette.grey[200]}`,
                maxHeight: containerHeight,
            }}
        >
            {/* Top Row: Service Name, Required Capcity & add button */}
            <Grid
                xs={12}
                container
                item
                style={{
                    display: 'flex',
                    boxSizing: 'border-box',
                    alignItems: 'center',
                    padding: theme.spacing(1),
                    minHeight: VENDOR_BAR_H,
                    maxHeight: VENDOR_BAR_H,
                }}
            >
                <Grid
                    xs={4}
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        overflow: 'hidden',
                    }}
                >
                    {totalThroughput >= dailyCapacity && (
                        <CheckCircleOutlineIcon
                            htmlColor={theme.palette.primary.main}
                            fontSize="large"
                        />
                    )}

                    {totalThroughput < dailyCapacity && (
                        <ErrorOutlineIcon htmlColor={'red'} fontSize="large" />
                    )}

                    <span
                        style={{
                            ...theme.typography.h5,
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        {templateService.service.name}
                    </span>
                </Grid>

                <Grid xs={3} container>
                    <Container
                        style={{
                            flexDirection: 'column',
                            // alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.caption,
                                fontWeight: theme.typography.fontWeightLight,
                            }}
                        >
                            Work days
                        </span>
                        <span
                            style={{
                                ...theme.typography.subtitle2,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {totalWorkDays}
                        </span>
                    </Container>
                </Grid>

                <Grid xs={3} container>
                    <Container
                        style={{
                            flexDirection: 'column',
                            // alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.caption,
                                fontWeight: theme.typography.fontWeightLight,
                            }}
                        >
                            Avg Capacity
                        </span>
                        <NumberFormat
                            value={dailyCapacity}
                            readOnly
                            decimalScale={1}
                            displayType="text"
                            style={{
                                ...theme.typography.subtitle2,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        />
                    </Container>
                </Grid>

                <Grid xs={1} container>
                    <Tooltip title="Add Vendor">
                        <IconButton
                            size="small"
                            onClick={vendorPopoverState.handleOpen}
                        >
                            <PersonAddIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>

                <Grid xs={1} container>
                    <IconButton
                        size="small"
                        style={{ marginLeft: theme.spacing(1) }}
                        onClick={openMenu}
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Grid>
            </Grid>

            {/* Vendor Rows */}
            {vendorRows}
            <SelectorPopover
                {...props}
                popoverState={vendorPopoverState}
                label="Select Vendor"
                data={vendorOptions}
                onSubmit={(vndId) => {
                    scheduleController.addTemplateServiceVendor(
                        templateService.id,
                        vndId,
                    )
                }}
            />

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        scheduleController
                            .deleteTemplateService(templateService.id)
                            .then(() => {
                                toast.success('Deleted!')
                            })
                            .catch(() => {
                                toast.error(
                                    'There was a problem with your request',
                                )
                            })
                            .finally(() => {
                                handleClose()
                            })
                    }}
                >
                    <ListItemIcon>
                        <DeleteForeverIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        Remove {templateService.service.name}
                    </ListItemText>
                </MenuItem>
            </Menu>
        </Grid>
    )
}
