import React, { useState } from 'react'
import { RFP, Service, WorkorderGroup } from '../../models'
import { Container, MessageForm } from '../../components'
import { useTheme } from '@material-ui/core'
import { toMMDDYYYY } from '../../helpers'
import { RFPStatusBadge } from '../RFP/RFPStatusBadge'
import { rfpApi, workorderContext } from '../../contexts'
import { useMessage } from '../../hooks/useMessage'
import { WorkorderRow } from '../../components/WorkorderLib/WorkorderRow/WorkorderRow'
import { useUser } from '../../hooks'
import { DisplayService } from '../../components/DisplayService'

interface Props {
    ezNowRequest: RFP
}

export const ApartmentEZNowDetail = (props: Props) => {
    const { ezNowRequest } = props

    const [openMessageChannel, setOpenMessageChannel] = useState(-1)

    const theme = useTheme()

    const {
        transitionWorkorder,
        updateWorkorderState,
        workorderList,
    } = workorderContext()

    const { getRFPDetail } = rfpApi()

    const { createMessage } = useMessage()

    const { workspaceUser } = useUser()

    const serviceList: Service[] = []
    ezNowRequest.rfp_services.forEach((rfp_service) => {
        if (
            rfp_service.service.id &&
            !serviceList.find(
                (service) => service.id === rfp_service.service.id,
            )
        ) {
            serviceList.push(rfp_service.service)
        }
    })

    let startDate = ''
    if (ezNowRequest.project_start_date) {
        startDate = toMMDDYYYY(new Date(ezNowRequest.project_start_date))
    }

    let price = ''
    if (ezNowRequest.budget) {
        price = `$${ezNowRequest.budget}`
    } else if (ezNowRequest.accepted_bid?.group_bid_price) {
        price = `$${ezNowRequest.accepted_bid.group_bid_price}`
    }

    return (
        <Container
            style={{ margin: theme.spacing(1) }}
            flex={1}
            direction="column"
        >
            <Container
                direction="column"
                style={{ marginRight: theme.spacing(2) }}
            >
                <Container>
                    <Container direction="column">
                        <Container style={{ ...theme.typography.h5 }}>
                            {ezNowRequest.name}
                        </Container>
                        <Container flexWrap="wrap">
                            {serviceList.map((service) => {
                                return (
                                    <DisplayService
                                        service={service}
                                        border
                                        key={`SERVICE_${service.id}`}
                                    />
                                )
                            })}
                        </Container>
                    </Container>
                    <Container flex={1} />
                    <Container direction="column">
                        <Container style={{ ...theme.typography.subtitle1 }}>
                            Start Date: {startDate}
                        </Container>
                        <Container style={{ ...theme.typography.subtitle1 }}>
                            Price: {price}
                        </Container>
                    </Container>
                    <Container flex={1} />
                    <Container direction="column">
                        <Container>
                            <RFPStatusBadge
                                theme={theme}
                                status={ezNowRequest.status}
                                style={{
                                    container: {
                                        maxWidth: '100px',
                                    },
                                }}
                            />
                        </Container>
                        <Container style={{ ...theme.typography.h6 }}>
                            {ezNowRequest.accepted_bid?.vendor.name ?? ''}
                        </Container>
                    </Container>
                </Container>
                <Container direction="column" flex={1}>
                    <Container
                        style={{
                            ...theme.typography.h6,
                            fontWeight: 500,
                            marginTop: theme.spacing(1),
                        }}
                    >
                        Scope of Work:
                    </Container>
                    <Container
                        style={{
                            margin: theme.spacing(1),
                            padding: theme.spacing(1),
                            border: `1px solid ${theme.palette.grey[300]}`,
                            borderRadius: theme.shape.borderRadius,
                        }}
                    >
                        {ezNowRequest.description}
                    </Container>
                </Container>
            </Container>

            <Container>
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        overflowX: 'hidden',
                        maxHeight: '450px',
                    }}
                    scrollY
                >
                    <Container
                        style={{ ...theme.typography.h6, fontWeight: 500 }}
                    >
                        Workorders:
                    </Container>
                    {ezNowRequest.workorder_group?.workorders.map((wo, idx) => {
                        return (
                            <WorkorderRow
                                key={`EZNOW_WORKORDER_${wo.id}`}
                                workorder={wo}
                                isSelectedWorkorder={false}
                                canSeeUnitNotes
                                openUnitNotes={() => {}}
                                transitionWorkorder={transitionWorkorder}
                                onClickWorkorder={() => {}}
                                createMessage={createMessage}
                                updateWorkorderState={(wo) => {
                                    updateWorkorderState(wo)
                                    getRFPDetail(ezNowRequest)
                                }}
                                user={workspaceUser}
                                getRFPDetail={() => getRFPDetail(ezNowRequest)}
                                openMessageDrawer={() => {
                                    const cId =
                                        openMessageChannel === wo.channel
                                            ? -1
                                            : wo.channel
                                    setOpenMessageChannel(cId)
                                }}
                            />
                        )
                    })}
                </Container>
                {openMessageChannel !== -1 && (
                    <Container
                        style={{
                            width: 400,
                            borderLeft: `1px solid ${theme.palette.grey[700]}`,
                        }}
                    >
                        <MessageForm
                            theme={theme}
                            channelId={openMessageChannel}
                            afterCreateMessage={() => {
                                const wo = workorderList?.find(
                                    (wo) => wo.channel === openMessageChannel,
                                )
                                if (wo) {
                                    updateWorkorderState({
                                        ...wo,
                                        message_count: wo.message_count + 1,
                                    })
                                }
                            }}
                        />
                    </Container>
                )}
            </Container>
        </Container>
    )
}
