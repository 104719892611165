import { Grid, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { Container } from '../../components'
import { ActionItem } from '../../models'
import { Pagination } from '@material-ui/lab'
import { WoStatusBadge } from '../VendorScheduler'
import { ReportProblem } from '@material-ui/icons'
import { toMMDDYYYY } from '../../helpers'

interface Props {
    actionItems: ActionItem[]
    showProperty?: boolean
}

export const AnalyticsActionItemsTable = (props: Props) => {
    const { actionItems, showProperty } = props

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(1)

    const theme = useTheme()

    const HeadCellStyle: React.CSSProperties = {
        fontWeight: 600,
        color: theme.palette.darkGreen.main,
        fontSize: '15px',
        flex: 2,
    }

    return (
        <Container scrollY style={{ marginTop: theme.spacing(2) }}>
            <Grid
                container={true}
                style={{
                    padding: theme.spacing(2, 0, 1, 0),
                }}
            >
                <Grid
                    container={true}
                    style={{ paddingLeft: theme.spacing(2) }}
                >
                    <Container style={{ ...HeadCellStyle }}>Title</Container>
                    <Container style={{ ...HeadCellStyle }}>Service</Container>
                    <Container style={{ ...HeadCellStyle }}>Status</Container>
                    <Container style={{ ...HeadCellStyle }}>Priority</Container>
                    <Container style={{ ...HeadCellStyle }}>Date</Container>
                    {showProperty && (
                        <Container style={{ ...HeadCellStyle }}>
                            Property
                        </Container>
                    )}
                    <Container style={{ ...HeadCellStyle }}>Location</Container>
                </Grid>
                <Grid
                    container={true}
                    style={{
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 450px)',
                    }}
                >
                    {actionItems
                        .map((actionItem) => {
                            return (
                                <ActionItemRow
                                    actionItem={actionItem}
                                    showProperty={showProperty}
                                    key={`ACTION_ITEM_ROW_${actionItem.id}`}
                                />
                            )
                        })
                        .slice(
                            (page - 1) * rowsPerPage,
                            (page - 1) * rowsPerPage + rowsPerPage,
                        )}
                </Grid>
                <Container style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <span
                        style={{
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.lightGrey.main,
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '13px',
                            paddingTop: theme.spacing(1),
                        }}
                    >
                        {actionItems.length ?? 0} Rows
                    </span>
                    <Pagination
                        count={Math.ceil(actionItems.length / rowsPerPage)}
                        page={page}
                        onChange={(_: unknown, newPage: number) => {
                            setPage(newPage)
                        }}
                        variant="outlined"
                        shape="rounded"
                        style={{ margin: theme.spacing(1, 0, 0, 0) }}
                    />
                </Container>
            </Grid>
        </Container>
    )
}

interface ActionItemRowProps {
    actionItem: ActionItem
    showProperty?: boolean
}

const ActionItemRow = (props: ActionItemRowProps) => {
    const { actionItem, showProperty } = props

    const theme = useTheme()

    const CellStyle: React.CSSProperties = {
        fontWeight: 400,
        fontSize: '15px',
        flex: 1,
    }

    const priorityIcon = actionItem.priority ? (
        <ReportProblem color="secondary" fontSize="small" />
    ) : null

    const date = actionItem.start_date
        ? toMMDDYYYY(new Date(actionItem.start_date))
        : ''

    let location = actionItem.folder_location
        ? `${actionItem.folder_location.path}${actionItem.folder_location.name}`
        : ''
    if (actionItem.unit_location) {
        location = location + `/${actionItem.unit_location.name}`
    }

    return (
        <Grid
            container
            style={{
                margin: theme.spacing(1, 0, 1, 0),
                border: '1px solid',
                borderColor: theme.palette.grey[400],
                borderRadius: '20px 20px 20px 20px',
                flexDirection: 'row',
                padding: theme.spacing(3, 0, 3, 2),
            }}
        >
            <Container style={{ ...CellStyle }}>{actionItem.title}</Container>
            <Container style={{ ...CellStyle }}>
                {actionItem.service?.name}
            </Container>
            <Container style={{ ...CellStyle }}>
                <WoStatusBadge status={actionItem.status} theme={theme} />
            </Container>
            <Container style={{ ...CellStyle }}>{priorityIcon}</Container>
            <Container style={{ ...CellStyle }}>{date}</Container>
            {showProperty && (
                <Container style={{ ...CellStyle }}>
                    {actionItem.assigned_by.active_workspace?.name}
                </Container>
            )}
            <Container style={{ ...CellStyle }}>{location}</Container>
        </Grid>
    )
}
