import React, { PropsWithChildren } from 'react'
import { Container } from '..'
import { Autocomplete, LoadScript } from '@react-google-maps/api'
import { TextField, MenuItem } from '@material-ui/core'
import { statesList } from '../../helpers'

interface Props {
    doValidate?: boolean
    addressState: AddressState
    setAddressState: React.Dispatch<React.SetStateAction<AddressState>>
    inputStyle?: React.CSSProperties
}

export interface AddressState {
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    zip: string
    country: string
}

export const AddressForm = (props: PropsWithChildren<Props>) => {
    const { doValidate, addressState, setAddressState, inputStyle } = props

    return (
        <LoadScript
            googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
            libraries={['places']}
        >
            <Autocomplete
                onLoad={(autocomplete) => {
                    autocomplete.addListener('place_changed', () => {
                        const place = autocomplete.getPlace()
                        if (place && place.formatted_address) {
                            const addressComponents =
                                place.address_components || []
                            const streetNumber =
                                addressComponents.find((component) =>
                                    component.types.includes('street_number'),
                                )?.long_name || ''
                            const route =
                                addressComponents.find((component) =>
                                    component.types.includes('route'),
                                )?.long_name || ''
                            const streetAddress = `${streetNumber} ${route}`.trim()
                            const city =
                                addressComponents.find((component) =>
                                    component.types.includes('locality'),
                                )?.long_name || ''
                            const state =
                                addressComponents.find((component) =>
                                    component.types.includes(
                                        'administrative_area_level_1',
                                    ),
                                )?.short_name || ''
                            const country =
                                addressComponents.find((component) =>
                                    component.types.includes('country'),
                                )?.short_name || ''
                            const zipBeggining =
                                addressComponents.find((component) =>
                                    component.types.includes('postal_code'),
                                )?.short_name || ''
                            const zipSuffix =
                                addressComponents.find((component) =>
                                    component.types.includes(
                                        'postal_code_suffix',
                                    ),
                                )?.short_name || ''
                            const zipcode = `${zipBeggining} ${zipSuffix}`.trim()
                            setAddressState({
                                ...addressState,
                                addressLine1: streetAddress,
                                city: city,
                                state: state,
                                country: country,
                                zip: zipcode,
                            })

                            // setAutoValueChanged(true)
                        }
                    })
                }}
            >
                <TextField
                    fullWidth
                    error={doValidate && addressState.addressLine1 === ''}
                    helperText={
                        doValidate && addressState.addressLine1 === ''
                            ? 'Required'
                            : ''
                    }
                    variant="outlined"
                    value={addressState.addressLine1}
                    onChange={(e) => {
                        setAddressState({
                            ...addressState,
                            addressLine1: e.target.value,
                        })
                    }}
                    label="Address Line 1"
                    style={inputStyle}
                />
            </Autocomplete>

            <TextField
                variant="outlined"
                value={addressState.addressLine2}
                onChange={(e) => {
                    setAddressState({
                        ...addressState,
                        addressLine2: e.target.value,
                    })
                }}
                label="Address Line 2"
                style={inputStyle}
            />

            <TextField
                error={doValidate && addressState.city === ''}
                helperText={
                    doValidate && addressState.city === '' ? 'Required' : ''
                }
                variant="outlined"
                value={addressState.city}
                onChange={(e) => {
                    setAddressState({
                        ...addressState,
                        city: e.target.value,
                    })
                }}
                label="City"
                style={inputStyle}
            />

            <TextField
                error={doValidate && addressState.state === ''}
                helperText={
                    doValidate && addressState.state === '' ? 'Required' : ''
                }
                variant="outlined"
                value={addressState.state}
                onChange={(e) => {
                    setAddressState({
                        ...addressState,
                        state: e.target.value,
                    })
                }}
                label="State"
                style={inputStyle}
                select
            >
                {statesList.map((s) => {
                    return (
                        <MenuItem key={s} value={s}>
                            {s}
                        </MenuItem>
                    )
                })}
            </TextField>

            <TextField
                // error={doValidate && country === ''}
                // helperText={doValidate && country === '' ? 'Required' : ''}
                variant="outlined"
                value={addressState.country}
                onChange={(e) => {
                    setAddressState({
                        ...addressState,
                        country: e.target.value,
                    })
                }}
                label="Country"
                style={inputStyle}
            />

            <TextField
                error={doValidate && addressState.zip === ''}
                helperText={
                    doValidate && addressState.zip === '' ? 'Required' : ''
                }
                variant="outlined"
                value={addressState.zip}
                onChange={(e) => {
                    setAddressState({
                        ...addressState,
                        zip: e.target.value,
                    })
                }}
                label="Zip Code"
                style={inputStyle}
            />
        </LoadScript>
    )
}
