import { Chip, useTheme } from '@material-ui/core'
import React, { Dispatch, SetStateAction } from 'react'
import { Container } from '../../components'
import { Service, convertListToMap } from '../../models'
import { toMMDDYYYY } from '../../helpers'
import { RFPFilterState, rfpApi } from '../../contexts'

interface Props {
    serviceList: Service[]
    rfpFilterState: RFPFilterState
    setRFPFilterState: Dispatch<SetStateAction<RFPFilterState>>
}

export const RFPFilterChips = (props: Props) => {
    const { serviceList, rfpFilterState, setRFPFilterState } = props

    const theme = useTheme()

    const chipStyle: React.CSSProperties = {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    }

    const serviceMap = convertListToMap(serviceList)

    return (
        <Container style={{ flexWrap: 'wrap' }} scrollY>
            {rfpFilterState.serviceIds.map((serviceId) => {
                return (
                    <Chip
                        key={`RFP_SERVICE_FILTER_${serviceId}`}
                        clickable
                        style={chipStyle}
                        label={serviceMap[serviceId]?.name}
                        onClick={() => {
                            const tempServiceIdList = rfpFilterState.serviceIds.filter(
                                (servId) => servId !== serviceId,
                            )
                            setRFPFilterState({
                                ...rfpFilterState,
                                serviceIds: tempServiceIdList,
                            })
                        }}
                    />
                )
            })}
            {rfpFilterState.timeline.enabled && (
                <Chip
                    clickable
                    style={chipStyle}
                    label={`${toMMDDYYYY(
                        rfpFilterState.timeline.startDate,
                    )} - ${toMMDDYYYY(rfpFilterState.timeline.endDate)}`}
                    onClick={() => {
                        setRFPFilterState({
                            ...rfpFilterState,
                            timeline: {
                                enabled: false,
                                startDate: new Date(),
                                endDate: new Date(),
                            },
                        })
                    }}
                />
            )}
        </Container>
    )
}
