import React from 'react'
import { DwollaContext, useDwollaState } from '../../../contexts'

import { EZPayRouter } from '../EZPayRouter'

export const EZPayRoot = () => {
    const dwollaState = useDwollaState()

    return (
        <DwollaContext.Provider value={dwollaState}>
            <EZPayRouter />
        </DwollaContext.Provider>
    )
}
