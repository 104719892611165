import { BaseUser, User } from '../models'

export const getInitials = (name: string = '') => {
    const names = name.split(' ')
    const initials = names.map((n) => n.charAt(0).toUpperCase())

    if (initials.length > 1) {
        return `${initials[0]}${initials[initials.length - 1]}`
    } else {
        return initials[0]
    }
}

export const getUiCamelCase = (str: string) => {
    //takes in a string of format 'IN_PROGRESS' and outputs a string of format 'In Progress'

    //split the string into an array of each word
    const words = str.split('_')

    let ret: string = ''
    //for each word, make the first letter capital and the rest lowercase
    words.forEach((word) => {
        const lower = word.toLocaleLowerCase()
        ret += word.charAt(0).toLocaleUpperCase() + lower.slice(1) + ' '
    })

    //remove trailing space
    return ret.slice(0, -1)
}

export const isEmailValid = (email: string) => {
    //check against a regular expression for emails
    const emailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

    return emailformat.test(email)
}

export const emailHelperText = (doValidate: boolean, email: string) => {
    if (doValidate) {
        if (email === '') return 'Email Required'
        if (!isEmailValid(email)) return 'Invalid Email Format'
    }
    return ''
}

export const getNumFromURLSegment = (urlSegment: string) => {
    // takes in a partial url that starts at a number, returns the number and the rest of the url

    for (let i = 0; i < urlSegment.length; i++) {
        if (isNaN(Number(urlSegment.charAt(i)))) {
            //our current character is not part of the number
            // everything before it is the number
            return {
                urlSegment: urlSegment.substring(i),
                number: Number(urlSegment.substring(0, i)),
            }
        }
    }
    /// the entire string is the number
    return {
        urlSegment: '',
        number: Number(urlSegment),
    }
}

export const statesList = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
    'PR',
    'VI',
    'FM',
    'MP',
    'MH',
    'AS',
    'PW',
    'GU',
]

export const stateAbbreviationMap = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District Of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',

    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
    PR: 'Puerto Rico',
    VI: 'Virgin Islands',
    FM: 'Federated States Of Micronesia',
    MP: 'Northern Mariana Islands',
    MH: 'Marshall Islands',
    AS: 'American Samoa',
    PW: 'Palau',
    GU: 'Guam',
}

export const isAccountManager = (user?: BaseUser) => {
    return (
        (user?.email.includes('@ezturn.net') ||
            user?.email.includes('@ezos.co')) ??
        false
    )
}

export const lastCharacterIsNumber = (str: string) => {
    if (str.length === 0) {
        return true
    }
    // check ascii of character to ensure only numbers
    const newCharASCII = str.charCodeAt(str.length - 1)
    return newCharASCII >= 48 && newCharASCII <= 57
}

export const isStringNumeric = (str: string) => {
    let digitsValid = true
    for (let i = 0; i < str.length; i++) {
        const asciiCode = str.charCodeAt(i)
        if (asciiCode < 48 || asciiCode > 57) {
            digitsValid = false
            break
        }
    }

    return digitsValid
}
