import { ActionItemStatus, ActionItemSyncStatus } from './types'

export const getUiSyncStatus = (syncStatus: ActionItemSyncStatus) => {
    switch (syncStatus) {
        case ActionItemSyncStatus.FAILED:
            return 'Sync Failed'
        case ActionItemSyncStatus.PENDING:
            return 'Sync Pending'
        case ActionItemSyncStatus.SUCCESS:
            return 'Synced'
    }
    return 'Not Synced'
}

export const getUiSyncStatusColor = (syncStatus: ActionItemSyncStatus) => {
    switch (syncStatus) {
        case ActionItemSyncStatus.FAILED:
            return 'red'
        case ActionItemSyncStatus.PENDING:
            return 'yellow'
        case ActionItemSyncStatus.SUCCESS:
            return 'green'
    }
    return '#9e9e9e'
}

export const getActionItemStatusName = (actionItemStatus: ActionItemStatus) => {
    switch (actionItemStatus) {
        case ActionItemStatus.ASSIGNED:
            return 'Assigned'
        case ActionItemStatus.COMPLETE:
            return 'Complete'
        case ActionItemStatus.PAUSE:
            return 'Pause'
        case ActionItemStatus.IN_PROGRESS:
            return 'In Progress'
        case ActionItemStatus.REJECTED:
            return 'Rejected'
        case ActionItemStatus.STAGED:
            return 'Staged'
    }
}
