import React from 'react'
import { Container } from '../Container'
import {
    LeaseStatusType,
    getLeaseStatusColor,
    getLeaseStatusString,
} from '../../models'
import { useTheme } from '@material-ui/core'

interface Props {
    status: LeaseStatusType
}

export const LeaseStatusBadge = (props: Props) => {
    const { status } = props

    const theme = useTheme()

    const statusColor = getLeaseStatusColor(status)

    return (
        <Container
            style={{
                border: `2px solid ${statusColor.color}`,
                borderRadius: theme.shape.borderRadius,
                padding: 4,
                paddingTop: 2,
                paddingBottom: 2,
                width: 80,
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <span
                style={{
                    ...theme.typography.caption,
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >
                {getLeaseStatusString(status)}
            </span>
        </Container>
    )
}
