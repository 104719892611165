import React, { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core'
import {
    addWorkorderToAnalytics,
    BaseWorkorder,
    createServiceAnalyticDict,
    Folder,
    ModelListMap,
    ServiceAnalytic as ServiceAnalyticModel,
    traverse,
    Unit,
} from '../../../models'
import { Container } from '../../../components'
import { ServiceAnalytic } from './ServiceAnalytic'
import { FolderAnalytics } from './FolderAnalytics'
import { UnitAnalytics } from './UnitAnalytics'

interface Props {
    open: boolean
    infrastructure: Folder
    onClose: () => void
    unitWorkorderMap: ModelListMap<BaseWorkorder>
    isWorkorderInFilter: (workorder: BaseWorkorder, unit: Unit) => boolean
}

interface ServiceAnalyticContainer {
    [serviceId: number]: ServiceAnalyticModel
}

export const ASSIGNED_ANALYTIC = 0
export const COMPLETE_ANALYTIC = 1
export const APPROVED_ANALYTIC = 2

export type StatusSelection =
    | typeof ASSIGNED_ANALYTIC
    | typeof COMPLETE_ANALYTIC
    | typeof APPROVED_ANALYTIC

export const AnalyticsBlanket = (props: Props) => {
    const {
        open,
        infrastructure,
        unitWorkorderMap,
        isWorkorderInFilter,
    } = props

    const theme = useTheme()

    const [
        serviceAnalytics,
        setServiceAnalytics,
    ] = useState<ServiceAnalyticContainer>({})

    const [unitCount, setUnitCount] = useState(0)

    const [selectedSrvId, setSelectedSrvId] = useState(-1)
    const [selectedStatus, setSelectedStatus] = useState<StatusSelection>(
        ASSIGNED_ANALYTIC,
    )

    useEffect(() => {
        // Only build the Analytic state if the modal is open
        if (open) {
            // create a dictionary of each service Analytic for fast access
            const newSaContainer: ServiceAnalyticContainer = {}
            let newUnitCount = 0
            let firstServiceId = -1

            traverse(infrastructure, (folder) => {
                folder.units.forEach((unit) => {
                    newUnitCount += 1

                    if (unit.id in unitWorkorderMap) {
                        unitWorkorderMap[unit.id]?.forEach((wo) => {
                            if (
                                isWorkorderInFilter(wo, unit) &&
                                wo.service_id
                            ) {
                                if (
                                    newSaContainer[wo.service_id] === undefined
                                ) {
                                    if (firstServiceId === -1) {
                                        firstServiceId = wo.service_id
                                    }

                                    newSaContainer[
                                        wo.service_id
                                    ] = createServiceAnalyticDict(wo)
                                }

                                addWorkorderToAnalytics(
                                    newSaContainer[wo.service_id],
                                    wo,
                                )
                            }
                        })
                    }
                })
            })

            setSelectedSrvId(firstServiceId)
            setServiceAnalytics(newSaContainer)
            setUnitCount(newUnitCount)
        }
    }, [infrastructure, open, unitWorkorderMap])

    const serviceAnalyticComponents = Object.keys(serviceAnalytics).map(
        (serviceId) => {
            const sa = serviceAnalytics[Number(serviceId)]
            return (
                <ServiceAnalytic
                    key={`SERVICE_ANALYTIC_${sa.serviceName}`}
                    analytic={sa}
                    unitCount={unitCount}
                    selectedServiceId={selectedSrvId}
                    selectedStatus={selectedStatus}
                    setAnalytic={(analytic, sid) => {
                        setSelectedStatus(analytic)
                        setSelectedSrvId(sid)
                    }}
                />
            )
        },
    )

    return (
        <Container
            style={{
                flex: 1,
                backgroundColor: theme.palette.grey[100],
            }}
            scrollY
        >
            {/* Service Column */}
            <Container
                scrollY
                style={{
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                {serviceAnalyticComponents}
                {serviceAnalyticComponents.length === 0 && (
                    <Container
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: theme.shape.borderRadius,
                            backgroundColor: theme.palette.grey[200],
                            margin: theme.spacing(1),
                            padding: theme.spacing(1),
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            There are no analytics for your selection
                        </span>
                    </Container>
                )}
            </Container>
            {/* End Service Column */}

            {/* Infrastructure Column */}
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                <FolderAnalytics
                    infrastructure={infrastructure}
                    statusSelection={selectedStatus}
                    unitWorkorderMap={unitWorkorderMap}
                    isWorkorderInFilter={isWorkorderInFilter}
                    selectedServiceId={selectedSrvId}
                />

                <UnitAnalytics
                    infrastructure={infrastructure}
                    unitWorkorderMap={unitWorkorderMap}
                    isWorkorderInFilter={isWorkorderInFilter}
                    statusSelection={selectedStatus}
                    selectedServiceId={selectedSrvId}
                />
            </Container>
            {/* End Infrastructure Column */}
        </Container>
    )
}
