import { AxiosError } from 'axios'
import { AppThunk } from '../'

import { axiosInstance } from '../../helpers'
import {
    createWorkorderFromResponse,
    Inventory,
    InventoryDetail,
    _Workorder,
} from '../../models'
import { setNetworkError } from '../error'
import { ErrorResponse } from '../types'
import { updateBulkWorkorders } from '../workorder'

import {
    GetInfrastructureRequest,
    GET_INFRASTRUCTURE_REQUEST,
    GET_INFRASTRUCTURE_RESPONSE,
    GetInfrastructureActionThunk,
    UpdateAreaStatusRequest,
    UpdateAreaStatusActionThunk,
    UpdateAreaStatusResponseAction,
    UPDATE_AREA_STATUS_REQUEST,
    UPDATE_AREA_STATUS_RESPONSE,
    UpdateAreaStatusResponse,
    InfrastructureRequest,
    InfrastructureActionTypes,
    SET_INFRASTRUCTURE_LOADING,
    SET_INVENTORY,
    GetInventoryListRequest,
    GetInventoryListActionThunk,
    GET_INVENTORY_REQUEST,
    GetInfrastructureManagerRequest,
    GetManagerActionThunk,
    GET_INFRASTRUCTURE_MANAGER_REQUEST,
    SET_MANAGER_WORKORDER_LIST,
    SET_MANAGER_INVENTORY_LIST,
    FolderRes,
    ADD_FOLDER,
    CREATE_FOLDER_REQUEST,
    CreateFolderRequest,
    FolderThunk,
    MoveUnitsRequest,
    UnitListThunk,
    MOVE_UNITS_REQUEST,
    SET_UNITS,
    UnitRes,
    DELETE_FOLDER,
    DeleteFolderRequest,
    DELETE_FOLDER_REQUEST,
} from './types'

export const getInfrastructure = (
    req: GetInfrastructureRequest,
): AppThunk<GetInfrastructureActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: GET_INFRASTRUCTURE_REQUEST,
        })

        const url = `company/apartment/${req.apartmentId}/infrastructure/`

        try {
            // success
            const response = await axiosInstance.get(url)

            dispatch({
                type: GET_INFRASTRUCTURE_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createFolder = (
    req: CreateFolderRequest,
): AppThunk<FolderThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInfrastructureLoading(CREATE_FOLDER_REQUEST, true))

        const url = `infrastructure/folder/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch(addFolder(response.data))
            dispatch(setInfrastructureLoading(CREATE_FOLDER_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            dispatch(setInfrastructureLoading(CREATE_FOLDER_REQUEST, false))

            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const deleteFolderRequest = (
    req: DeleteFolderRequest,
): AppThunk<any> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInfrastructureLoading(DELETE_FOLDER_REQUEST, true))

        const url = `infrastructure/folder/${req.folderId}/`

        try {
            // success
            const response = await axiosInstance.delete(url)

            dispatch(deleteFolder(req.folderId))
            dispatch(setInfrastructureLoading(DELETE_FOLDER_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            dispatch(setInfrastructureLoading(DELETE_FOLDER_REQUEST, false))

            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const moveUnits = (req: MoveUnitsRequest): AppThunk<UnitListThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInfrastructureLoading(MOVE_UNITS_REQUEST, true))

        const url = `infrastructure/folder/${req.folderId}/move_units/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)
            dispatch(setUnits(response.data))
            dispatch(setInfrastructureLoading(MOVE_UNITS_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            dispatch(setInfrastructureLoading(MOVE_UNITS_REQUEST, false))

            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const updateAreaStatus = (
    req: UpdateAreaStatusRequest,
): AppThunk<UpdateAreaStatusActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: UPDATE_AREA_STATUS_REQUEST,
        })

        const url = `infrastructure/area/${req.areaId}/status/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)
            dispatch(setAreaStatus(response.data, req.folderId, req.unitId))
            dispatch(updateBulkWorkorders(response.data.updated_workorders))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getInventory = (
    req: GetInventoryListRequest,
): AppThunk<GetInventoryListActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInfrastructureLoading(GET_INVENTORY_REQUEST, true))

        const url = `infrastructure/inventory/`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch(setInventoryList(response.data))
            dispatch(setInfrastructureLoading(GET_INVENTORY_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(setInfrastructureLoading(GET_INVENTORY_REQUEST, false))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getInfrastructureManagerRequest = (
    req: GetInfrastructureManagerRequest,
): AppThunk<GetManagerActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(
            setInfrastructureLoading(GET_INFRASTRUCTURE_MANAGER_REQUEST, true),
        )

        const url = `infrastructure/manager/`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch(
                setManagerWorkorderList(
                    response.data.workorders.map(createWorkorderFromResponse),
                ),
            )
            dispatch(setManagerInventoryList(response.data.inventory))
            dispatch(
                setInfrastructureLoading(
                    GET_INFRASTRUCTURE_MANAGER_REQUEST,
                    false,
                ),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(
                setInfrastructureLoading(
                    GET_INFRASTRUCTURE_MANAGER_REQUEST,
                    false,
                ),
            )

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

const setAreaStatus = (
    payload: UpdateAreaStatusResponse,
    folderId: number,
    unitId: number,
): UpdateAreaStatusResponseAction => {
    return {
        type: UPDATE_AREA_STATUS_RESPONSE,
        payload: payload,
        folderId: folderId,
        unitId: unitId,
    }
}

export const setInfrastructureLoading = (
    request: InfrastructureRequest,
    loading: boolean,
): InfrastructureActionTypes => {
    return {
        type: SET_INFRASTRUCTURE_LOADING,
        request: request,
        loading: loading,
    }
}

export const setInventoryList = (
    inventoryList?: Inventory[],
): InfrastructureActionTypes => {
    return {
        type: SET_INVENTORY,
        inventoryList: inventoryList,
    }
}

export const setManagerWorkorderList = (
    workorderList?: _Workorder[],
): InfrastructureActionTypes => {
    return {
        type: SET_MANAGER_WORKORDER_LIST,
        workorderList: workorderList,
    }
}

export const setManagerInventoryList = (
    inventoryList?: InventoryDetail[],
): InfrastructureActionTypes => {
    return {
        type: SET_MANAGER_INVENTORY_LIST,
        inventoryList: inventoryList,
    }
}

export const addFolder = (folder: FolderRes): InfrastructureActionTypes => {
    return {
        type: ADD_FOLDER,
        folder: folder,
    }
}

export const setUnits = (units: UnitRes[]): InfrastructureActionTypes => {
    return {
        type: SET_UNITS,
        units: units,
    }
}

export const deleteFolder = (folderId: number): InfrastructureActionTypes => {
    return {
        type: DELETE_FOLDER,
        folderId: folderId,
    }
}
