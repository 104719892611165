import { useState, useRef } from 'react'
import { CalendarGridController } from './useCalendarGrid'
import { setEndOfDay, setMidnight } from '../../../helpers'

export const useDragDateSelector = (
    gridState: CalendarGridController,
    onSubmit?: (startPoint: CursorStartPoint, selectedDate: Date) => void,
) => {
    const cursorState = useRef<CursorState | null>(null)
    const [
        dateChangeRenderState,
        setDateChangeRenderState,
    ] = useState<CursorState | null>(null)

    const handleMouseMove = (moveEv: MouseEvent) => {
        if (cursorState.current === null) {
            return
        }

        const gridPos = gridState.getClickedGridPosition(moveEv.pageX)
        const cursorDate = gridState.covnertGridPositionToDate(gridPos)
        setMidnight(cursorDate)

        const cursorMidnightMs = cursorDate.getTime()

        const newCursorState = {
            ...cursorState.current,
            hoverMidnightMs: cursorMidnightMs,
        }

        if (cursorState.current.hoverMidnightMs !== cursorMidnightMs) {
            setDateChangeRenderState(newCursorState)
        }

        cursorState.current = newCursorState
    }

    const handleMouseUp = () => {
        document.removeEventListener('mouseup', handleMouseUp)
        document.removeEventListener('mousemove', handleMouseMove)

        if (cursorState.current !== null) {
            const updatedDate = new Date(cursorState.current.hoverMidnightMs)

            if (cursorState.current.startPoint === 'end_date') {
                setEndOfDay(updatedDate)
            }

            onSubmit && onSubmit(cursorState.current.startPoint, updatedDate)
        }

        cursorState.current = null
        setDateChangeRenderState(null)
    }

    const handleMouseDown = (
        startMidnightMs: number,
        hoverMidnightMs: number,
        startPoint: 'start_date' | 'end_date',
    ) => {
        cursorState.current = {
            startMidnightMs,
            hoverMidnightMs,
            startPoint,
        }

        document.addEventListener('mouseup', handleMouseUp)
        document.addEventListener('mousemove', handleMouseMove)
    }

    return {
        dateChangeRenderState,
        handleMouseDown,
    }
}

type CursorStartPoint = 'start_date' | 'end_date'

export type CursorState = {
    startPoint: CursorStartPoint
    hoverMidnightMs: number
    startMidnightMs: number
}
