import { TableCell, TableRow, Tooltip, useTheme } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
    CellData,
    Container,
    HeadCell,
    RenderRow,
    RowData,
    SideDrawerContainer,
    SimpleTable,
} from '../../components'
import { useAppDispatch } from '../../hooks'
import { AreaConfig as AreaConfigModel } from '../../models'
import {
    getAreaConfigList,
    getInventoryConfigList,
    RootState,
} from '../../store'
import { useStyles } from '../../styles'
import { AreaConfigDrawer } from './AreaConfigDrawer'

export const AreaConfig = () => {
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const classes = useStyles()

    useEffect(() => {
        dispatch(getAreaConfigList({}))

        //list gotten for drawer
        dispatch(getInventoryConfigList({}))
    }, [])

    const areaConfigList = useSelector(
        (state: RootState) => state.aptConfig.areaConfigList,
    )

    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selectedConfig, setSelectedConfig] = useState<AreaConfigModel>()

    //table setup
    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    interface AreaConfigRowType extends RowData {
        name: CellData<string>
        occupiable: CellData<string>
        areaConf: CellData<AreaConfigModel>
    }

    const headCells: HeadCell<AreaConfigRowType>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'name',
            label: 'Name',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'occupiable',
            label: 'Occupiable',
            style: headCellStyle,
        },
    ]

    const createRow = (areaConf: AreaConfigModel): AreaConfigRowType => {
        return {
            name: { value: areaConf.name, sortValue: areaConf.name },
            occupiable: {
                value: areaConf.occupiable ? 'Yes' : 'No',
                sortValue: areaConf.occupiable ? 1 : 0,
            },

            areaConf: { value: areaConf, sortValue: 1 },
        }
    }

    interface AreaConfRowProps {
        row: AreaConfigRowType
    }

    const AreaConfRow: RenderRow<AreaConfRowProps> = (props) => {
        const { row } = props
        const cellStyle: React.CSSProperties = {
            ...theme.typography.body2,
            fontWeight: theme.typography.fontWeightRegular,
        }

        return (
            <TableRow
                key={`AREA_CONF_ROW_${row.areaConf.value.id}`}
                style={{
                    cursor: 'pointer',
                    backgroundColor:
                        selectedConfig?.id === row.areaConf.value.id
                            ? theme.palette.grey[300]
                            : undefined,
                }}
                className={classes.hoverGrey300}
                onClick={() => {
                    setSelectedConfig(row.areaConf.value)
                    setDrawerOpen(true)
                }}
            >
                <TableCell style={cellStyle}>{row.name.value}</TableCell>
                <TableCell style={cellStyle}>{row.occupiable.value}</TableCell>
            </TableRow>
        )
    }

    return (
        <SideDrawerContainer open={drawerOpen}>
            <Container
                direction={'column'}
                flex={1}
                style={{ margin: theme.spacing(2) }}
            >
                {/* header */}
                <Container>
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            marginBottom: theme.spacing(1),
                            flex: 1,
                        }}
                    >
                        Area Config
                    </span>
                    <Tooltip title="New Area Config">
                        <Add
                            fontSize="large"
                            onClick={() => {
                                // setSelectedConfig(undefined)
                                setDrawerOpen(true)
                            }}
                            color="primary"
                            className={classes.highlightCircleOnHover}
                        />
                    </Tooltip>
                </Container>
                {/* Table */}
                <SimpleTable<AreaConfigRowType>
                    rows={
                        areaConfigList?.map((areaConf: AreaConfigModel) =>
                            createRow(areaConf),
                        ) ?? []
                    }
                    render={(row) => <AreaConfRow row={row} />}
                    orderByDefault={'name'}
                    headCells={headCells}
                />
            </Container>
            {/* TODO: edit and create drawer */}
            <AreaConfigDrawer
                open={drawerOpen}
                handleClose={() => setDrawerOpen(false)}
                areaConfig={selectedConfig}
            />
        </SideDrawerContainer>
    )
}
