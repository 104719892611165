import React from 'react'
import { RFP, getUiStatus } from '../../models'
import { Container } from '../../components'
import { Button, useTheme } from '@material-ui/core'
import { useStyles } from '../../styles'
import { RFPStatusBadge } from '../RFP/RFPStatusBadge'
import { toMMDDYYYY } from '../../helpers'
import { ListPopover } from '../../components/ListPopover/ListPopover'
import { usePopover } from '../../hooks/usePopover'
import { DisplayService } from '../../components/DisplayService'

interface Props {
    ezNowRequest: RFP
    isSelected: boolean
    onClick: () => void
}

export const ApartmentEZNowRow = (props: Props) => {
    const { ezNowRequest, isSelected, onClick } = props

    const theme = useTheme()
    const classes = useStyles()

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    const infoStyle: React.CSSProperties = {
        fontSize: '16px',
        fontWeight: 500,
        flex: 1,
    }

    let startDate = ''
    if (ezNowRequest.project_start_date) {
        startDate = toMMDDYYYY(new Date(ezNowRequest.project_start_date))
    }

    let price: string | number = ''
    if (ezNowRequest.budget > 0) {
        price = `$${ezNowRequest.budget}`
    }

    const rfp = ezNowRequest
    const services = (
        <Container>
            {rfp.rfp_services[0] && (
                <DisplayService service={rfp.rfp_services[0].service} border />
            )}
            {rfp.rfp_services.length > 1 && (
                <>
                    <Container>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                handleOpen(e)
                            }}
                            size="small"
                            style={{
                                margin: theme.spacing(1, 0, 1, 0),
                            }}
                        >
                            +{rfp.rfp_services.length - 1}
                        </Button>
                    </Container>
                    <ListPopover
                        open={isOpen}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            horizontal: -50,
                            vertical: 'bottom',
                        }}
                        style={{ paddingLeft: theme.spacing(1) }}
                    >
                        {rfp.rfp_services.slice(1).map((rfpService) => {
                            return (
                                <DisplayService
                                    service={rfpService.service}
                                    border={false}
                                    key={`RFP_SERVICE_DISPLAY_${rfpService.id}`}
                                />
                            )
                        })}
                    </ListPopover>
                </>
            )}
        </Container>
    )

    return (
        <Container
            style={{
                margin: theme.spacing(1),
                padding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.palette.grey[600]}`,
                cursor: 'pointer',
                backgroundColor: isSelected
                    ? theme.palette.grey[300]
                    : undefined,
                maxHeight: 100,
                minHeight: 100,
                alignItems: 'center',
                overflow: 'hidden',
            }}
            className={classes.hoverGrey200}
            onClick={onClick}
        >
            <span
                style={{
                    ...infoStyle,
                    overflow: 'hidden',
                }}
            >
                {ezNowRequest.name}
            </span>
            <span style={infoStyle}>{services}</span>
            <span style={infoStyle}>
                {ezNowRequest.workorder_group?.workorders.length || 0}
            </span>
            <span style={infoStyle}>{startDate}</span>
            <span style={infoStyle}>{price}</span>
            <span style={infoStyle}>
                <RFPStatusBadge
                    theme={theme}
                    status={ezNowRequest.status}
                    style={{
                        container: {
                            maxWidth: '100px',
                        },
                    }}
                />
            </span>
        </Container>
    )
}
