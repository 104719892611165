import React from 'react'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    useTheme,
} from '@material-ui/core'
import { Container, Finder, SideDrawer } from '../../../components'
import { Folder, InspectionStatus, LeaseStatus } from '../../../models'

import { FinderSelection, useWindowSize } from '../../../hooks'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ParsableDate } from '@material-ui/pickers/constants/prop-types'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import {
    AutoCompleteFilterValue,
    DateFilterOperator,
    DateFilterState,
    IssueFilterValue,
    LeaseStatusFilterState,
    TenantInspectionStatusFilterState,
} from '../../../hooks/useLeaseFilter'
import { PropertyDetails } from '../../../main/containers/settings/PropertyDetails'

interface Props {
    open: boolean
    statusFilter: LeaseStatusFilterState
    setStatusFilter: (sf: LeaseStatusFilterState) => void
    title?: string
    onClose: () => void
    root: Folder
    locationFinderSelection: FinderSelection
    dateFilter: DateFilterState
    onChangeDateFilter: (dateFilter: DateFilterState) => void
    hasIssueFilter: IssueFilterValue
    setHasIssueFilter: (hasIssue: IssueFilterValue) => void
    tenantInspectionStatusFilter: TenantInspectionStatusFilterState
    setTenantInspectionStatusFilter: (
        insStatusFilter: TenantInspectionStatusFilterState,
    ) => void
    autoCompleteFilter: AutoCompleteFilterValue
    setAutoCompleteFilter: (val: AutoCompleteFilterValue) => void
}

export const LeaseFilterDrawer = (props: Props) => {
    const {
        title,
        open,
        onClose,
        statusFilter,
        setStatusFilter,
        root,
        locationFinderSelection,
        hasIssueFilter,
        setHasIssueFilter,
        tenantInspectionStatusFilter,
        setTenantInspectionStatusFilter,
        autoCompleteFilter,
        setAutoCompleteFilter,
    } = props

    const { height } = useWindowSize()
    const theme = useTheme()

    return (
        <SideDrawer open={open} handleClose={onClose} title="Filter">
            <Container style={{ flexDirection: 'column', padding: 12 }}>
                <FormLabel component="legend">Location</FormLabel>
                <Container
                    style={{
                        maxHeight: (height ?? 0) / 3,
                        minHeight: (height ?? 0) / 3,
                        marginTop: 8,
                        marginBottom: 8,
                    }}
                >
                    <Finder
                        defaultOpen
                        root={root}
                        onClickUnit={locationFinderSelection.setFinderSelection}
                        onClickFolder={
                            locationFinderSelection.setFinderSelection
                        }
                        locationSelection={locationFinderSelection.selection}
                        selectedIcon={<></>}
                        getUnitAdornment={(unit) => {
                            return (
                                <Container style={{ flex: 1 }}>
                                    <div style={{ flex: 1 }} />
                                    <Checkbox
                                        checked={
                                            locationFinderSelection.selection
                                                .unit[unit.id] !== undefined
                                        }
                                        onClick={() => {
                                            locationFinderSelection.setFinderSelection(
                                                {
                                                    type: 'unit',
                                                    location: unit,
                                                },
                                            )
                                        }}
                                    />
                                </Container>
                            )
                        }}
                        getFolderAdornment={(folder) => {
                            return (
                                <Container style={{ flex: 1 }}>
                                    <div style={{ flex: 1 }} />
                                    <Checkbox
                                        color={'primary'}
                                        checked={
                                            locationFinderSelection.selection
                                                .folder[folder.id] !== undefined
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            locationFinderSelection.setFinderSelection(
                                                {
                                                    type: 'folder',
                                                    location: folder,
                                                },
                                            )
                                        }}
                                    />
                                </Container>
                            )
                        }}
                    />
                </Container>

                <FormLabel component="legend">Lease Status</FormLabel>
                <Container>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={statusFilter[LeaseStatus.CURRENT]}
                                    onChange={() => {
                                        const v =
                                            statusFilter[LeaseStatus.CURRENT]
                                        setStatusFilter({
                                            ...statusFilter,
                                            [LeaseStatus.CURRENT]: !v,
                                        })
                                    }}
                                />
                            }
                            label="Current"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        statusFilter[LeaseStatus.APPLICANT]
                                    }
                                    onChange={() => {
                                        const v =
                                            statusFilter[LeaseStatus.APPLICANT]
                                        setStatusFilter({
                                            ...statusFilter,
                                            [LeaseStatus.APPLICANT]: !v,
                                        })
                                    }}
                                />
                            }
                            label="Applicant"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={statusFilter[LeaseStatus.FUTURE]}
                                    onChange={() => {
                                        const v =
                                            statusFilter[LeaseStatus.FUTURE]
                                        setStatusFilter({
                                            ...statusFilter,
                                            [LeaseStatus.FUTURE]: !v,
                                        })
                                    }}
                                />
                            }
                            label="Future"
                        />
                    </FormGroup>

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={statusFilter[LeaseStatus.NOTICE]}
                                    onChange={() => {
                                        const v =
                                            statusFilter[LeaseStatus.NOTICE]
                                        setStatusFilter({
                                            ...statusFilter,
                                            [LeaseStatus.NOTICE]: !v,
                                        })
                                    }}
                                />
                            }
                            label="Notice"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={statusFilter[LeaseStatus.PAST]}
                                    onChange={() => {
                                        const v = statusFilter[LeaseStatus.PAST]
                                        setStatusFilter({
                                            ...statusFilter,
                                            [LeaseStatus.PAST]: !v,
                                        })
                                    }}
                                />
                            }
                            label="Past"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        statusFilter[LeaseStatus.CANCELLED]
                                    }
                                    onChange={() => {
                                        const v =
                                            statusFilter[LeaseStatus.CANCELLED]
                                        setStatusFilter({
                                            ...statusFilter,
                                            [LeaseStatus.CANCELLED]: !v,
                                        })
                                    }}
                                />
                            }
                            label="Cancelled"
                        />
                    </FormGroup>
                </Container>
                <DateFilter
                    label="Start Date"
                    operator={props.dateFilter.startDate.operator}
                    changeOperator={(o) => {
                        props.onChangeDateFilter({
                            ...props.dateFilter,
                            startDate: {
                                ...props.dateFilter.startDate,
                                operator: o,
                            },
                        })
                    }}
                    date={props.dateFilter.startDate.date}
                    onChangeDate={(d) => {
                        console.log(d)
                        props.onChangeDateFilter({
                            ...props.dateFilter,
                            startDate: {
                                ...props.dateFilter.startDate,
                                date: d,
                            },
                        })
                    }}
                />

                <DateFilter
                    label="End Date"
                    operator={props.dateFilter.endDate.operator}
                    changeOperator={(o) => {
                        props.onChangeDateFilter({
                            ...props.dateFilter,
                            endDate: {
                                ...props.dateFilter.endDate,
                                operator: o,
                            },
                        })
                    }}
                    date={props.dateFilter.endDate.date}
                    onChangeDate={(d) =>
                        props.onChangeDateFilter({
                            ...props.dateFilter,
                            endDate: {
                                ...props.dateFilter.endDate,
                                date: d,
                            },
                        })
                    }
                />

                <FormLabel
                    component="legend"
                    style={{ marginTop: theme.spacing(2) }}
                >
                    Move In Inspection Status
                </FormLabel>
                <Container>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        tenantInspectionStatusFilter[
                                            InspectionStatus.STAGED
                                        ]
                                    }
                                    onChange={() => {
                                        const v =
                                            tenantInspectionStatusFilter[
                                                InspectionStatus.STAGED
                                            ]
                                        setTenantInspectionStatusFilter({
                                            ...tenantInspectionStatusFilter,
                                            [InspectionStatus.STAGED]: !v,
                                        })
                                    }}
                                />
                            }
                            label="Staged"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        tenantInspectionStatusFilter[
                                            InspectionStatus.READY
                                        ]
                                    }
                                    onChange={() => {
                                        const v =
                                            tenantInspectionStatusFilter[
                                                InspectionStatus.READY
                                            ]
                                        setTenantInspectionStatusFilter({
                                            ...tenantInspectionStatusFilter,
                                            [InspectionStatus.READY]: !v,
                                        })
                                    }}
                                />
                            }
                            label="Sent"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        tenantInspectionStatusFilter[
                                            InspectionStatus.COMPLETE
                                        ]
                                    }
                                    onChange={() => {
                                        const v =
                                            tenantInspectionStatusFilter[
                                                InspectionStatus.COMPLETE
                                            ]
                                        setTenantInspectionStatusFilter({
                                            ...tenantInspectionStatusFilter,
                                            [InspectionStatus.COMPLETE]: !v,
                                        })
                                    }}
                                />
                            }
                            label="Complete"
                        />
                    </FormGroup>

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        tenantInspectionStatusFilter[
                                            InspectionStatus.NA
                                        ]
                                    }
                                    onChange={() => {
                                        const v =
                                            tenantInspectionStatusFilter[
                                                InspectionStatus.NA
                                            ]
                                        setTenantInspectionStatusFilter({
                                            ...tenantInspectionStatusFilter,
                                            [InspectionStatus.NA]: !v,
                                        })
                                    }}
                                />
                            }
                            label="N/A"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        tenantInspectionStatusFilter[
                                            InspectionStatus.LOCKED
                                        ]
                                    }
                                    onChange={() => {
                                        const v =
                                            tenantInspectionStatusFilter[
                                                InspectionStatus.LOCKED
                                            ]
                                        setTenantInspectionStatusFilter({
                                            ...tenantInspectionStatusFilter,
                                            [InspectionStatus.LOCKED]: !v,
                                        })
                                    }}
                                />
                            }
                            label="Locked"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={
                                        tenantInspectionStatusFilter[
                                            InspectionStatus.ARCHIVED
                                        ]
                                    }
                                    onChange={() => {
                                        const v =
                                            tenantInspectionStatusFilter[
                                                InspectionStatus.ARCHIVED
                                            ]
                                        setTenantInspectionStatusFilter({
                                            ...tenantInspectionStatusFilter,
                                            [InspectionStatus.ARCHIVED]: !v,
                                        })
                                    }}
                                />
                            }
                            label="Archived"
                        />
                    </FormGroup>
                </Container>

                <FormControl style={{ marginTop: theme.spacing(2) }}>
                    <FormLabel>Auto Complete Status</FormLabel>
                    <RadioGroup
                        value={props.autoCompleteFilter}
                        onChange={(e) => {
                            const val = Number(e.target.value)
                            setAutoCompleteFilter(val as any)
                        }}
                    >
                        <FormControlLabel
                            value={AutoCompleteFilterValue.NA}
                            control={<Radio />}
                            label="N/A"
                        />
                        <FormControlLabel
                            value={AutoCompleteFilterValue.AUTO_COMPLETE}
                            control={<Radio />}
                            label="Auto Completed"
                        />
                        <FormControlLabel
                            value={AutoCompleteFilterValue.TENANT_COMPLETE}
                            control={<Radio />}
                            label="Tenant Completed"
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl style={{ marginTop: theme.spacing(2) }}>
                    <FormLabel>Issue Filter</FormLabel>
                    <RadioGroup
                        defaultValue={IssueFilterValue.NA}
                        value={props.hasIssueFilter}
                        onChange={(e) => {
                            console.log(e.target.value)
                            const val = e.target.value
                            const numVal = Number(val)
                            console.log(numVal)
                            setHasIssueFilter(numVal)
                        }}
                    >
                        <FormControlLabel
                            value={IssueFilterValue.NA}
                            control={<Radio />}
                            label="N/A"
                        />
                        <FormControlLabel
                            value={IssueFilterValue.HAS_ISSUE}
                            control={<Radio />}
                            label="Has Issue"
                        />
                        <FormControlLabel
                            value={IssueFilterValue.NO_ISSUE}
                            control={<Radio />}
                            label="No Issue"
                        />
                    </RadioGroup>
                </FormControl>
            </Container>
        </SideDrawer>
    )
}

const DateFilter = (props: {
    label: string
    operator: DateFilterOperator
    changeOperator: (operator: DateFilterOperator) => void
    date: ParsableDate
    onChangeDate: (date: MaterialUiPickersDate) => void
}) => {
    return (
        <Container style={{ marginTop: 32, alignItems: 'center' }}>
            <FormLabel component="legend">{props.label}</FormLabel>

            <Select
                value={props.operator}
                onChange={(e) =>
                    props.changeOperator(e.target.value as DateFilterOperator)
                }
                style={{ marginRight: 8 }}
            >
                <MenuItem value={'lte'}>&lt;</MenuItem>
                <MenuItem value={'gte'}>&gt;</MenuItem>
                <MenuItem value={'e'}>=</MenuItem>
            </Select>
            <KeyboardDatePicker
                value={props.date}
                onChange={props.onChangeDate}
                format="MM/dd/yyyy"
                clearable
            />
        </Container>
    )
}
