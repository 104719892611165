import React, { useState } from 'react'

import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import PeopleIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'
import { Container, LocationForm, ServiceDots } from '../../components'
import {
    AreaConfig,
    AreaStatusTag,
    BaseWorkorder,
    Folder,
    ModelListMap,
    ModelMap,
    UnitOccupanyType,
    getUnitOccupancyCountMap,
    getUnitOccupancyType,
} from '../../models'
import { FinderSelection } from '../../hooks'
import { Checkbox, Popover, Tooltip, useTheme } from '@material-ui/core'
import { usePopover } from '../../hooks/usePopover'

export interface ScheduleFinderProps {
    root: Folder
    finderSelection: FinderSelection
    areaConfigMap: ModelMap<AreaConfig>
    areaStatusTagMap: ModelMap<AreaStatusTag>
    unitWorkorderMap: ModelListMap<BaseWorkorder>
    style?: React.CSSProperties
    defaultOpen?: boolean
}

export const SchedulerFinder = (props: ScheduleFinderProps) => {
    const {
        root,
        finderSelection,
        areaConfigMap,
        areaStatusTagMap,
        unitWorkorderMap,
    } = props

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    const [openWorkorders, setOpenWorkorders] = useState<
        BaseWorkorder[] | null
    >(null)

    const theme = useTheme()

    return (
        <LocationForm
            root={root}
            locationSelection={finderSelection.selection}
            setLocationSelection={finderSelection.setFinderSelection}
            style={props.style}
            selectedIcon={<></>}
            getUnitAdornment={(unit) => {
                const uOccMap = getUnitOccupancyCountMap(
                    unit,
                    areaConfigMap,
                    areaStatusTagMap,
                )
                const uOccType = getUnitOccupancyType(uOccMap)

                let icon: JSX.Element | null = null
                switch (uOccType) {
                    case UnitOccupanyType.PARTIAL:
                        icon = (
                            <Tooltip title="Partial Service">
                                <PersonIcon />
                            </Tooltip>
                        )
                        break
                    case UnitOccupanyType.FULL_VACANT:
                        icon = (
                            <Tooltip title="Full Service">
                                <PeopleIcon />
                            </Tooltip>
                        )
                        break
                    default:
                        icon = (
                            <Tooltip title="No Service">
                                <PeopleOutlineIcon />
                            </Tooltip>
                        )
                }

                return (
                    <Container style={{ flex: 1 }}>
                        <Container
                            style={{
                                width: 24,
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: 2,
                            }}
                        >
                            {icon}
                        </Container>

                        <ServiceDots
                            workorders={unitWorkorderMap[unit.id] ?? []}
                            popOverAnchror={anchorEl}
                            openPopover={(e, workorders) => {
                                setOpenWorkorders(workorders)
                                handleOpen(e)
                            }}
                        />

                        <Popover
                            anchorEl={anchorEl}
                            open={isOpen}
                            onClose={() => {
                                setOpenWorkorders(null)
                                handleClose()
                            }}
                        >
                            <Container
                                direction="column"
                                style={{ padding: theme.spacing(1) }}
                            >
                                {openWorkorders?.map((wo) => {
                                    return (
                                        <Container
                                            key={`OPEN_WO_${wo.id}`}
                                            direction="column"
                                            style={{
                                                marginBottom: theme.spacing(1),
                                                borderBottom: `1px solid black`,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    ...theme.typography.body1,
                                                    fontWeight:
                                                        theme.typography
                                                            .fontWeightBold,
                                                }}
                                            >
                                                {wo.service_name ?? ''}
                                            </span>
                                            <span
                                                style={{
                                                    ...theme.typography.body2,
                                                    fontWeight:
                                                        theme.typography
                                                            .fontWeightMedium,
                                                }}
                                            >
                                                {wo.vendor_name}
                                            </span>
                                        </Container>
                                    )
                                })}
                            </Container>
                        </Popover>

                        <div style={{ flex: 1 }} />
                        <Checkbox
                            checked={
                                finderSelection.selection.unit[unit.id] !==
                                undefined
                            }
                            onClick={() => {
                                finderSelection.setFinderSelection({
                                    type: 'unit',
                                    location: unit,
                                })
                            }}
                        />
                    </Container>
                )
            }}
            getFolderAdornment={(folder) => {
                return (
                    <Container style={{ flex: 1 }}>
                        <div style={{ flex: 1 }} />
                        <Checkbox
                            color={'primary'}
                            checked={
                                finderSelection.selection.folder[folder.id] !==
                                undefined
                            }
                            onClick={(e) => {
                                e.stopPropagation()
                                finderSelection.setFinderSelection({
                                    type: 'folder',
                                    location: folder,
                                })
                            }}
                        />
                    </Container>
                )
            }}
        />
    )
}
