import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { dateFilterOptions, DateFilters } from '../../models'
import { RootState, setDateFilter } from '../../store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../hooks'

interface DateFilterProps {
    style?: {
        formControl?: React.CSSProperties
        select?: React.CSSProperties
        menuItem?: React.CSSProperties
    }
}

export const DateFilter = (props: DateFilterProps) => {
    // a button that has a drop down between today, tommorow, and upcoming
    const dispatch = useAppDispatch()
    const dateFilter = useSelector(
        (state: RootState) => state.jobsite.dateFilter,
    )

    return (
        <FormControl style={{ ...props.style?.formControl }}>
            <InputLabel id="DATE_FILTER_LABEL">Time</InputLabel>
            <Select
                labelId="DATE_FILTER_LABEL"
                style={{ ...props.style?.select }}
                variant="standard"
                onChange={(
                    event: React.ChangeEvent<{
                        value: unknown
                    }>,
                ) => {
                    dispatch(setDateFilter(event.target.value as DateFilters))
                }}
                value={dateFilter}
            >
                {dateFilterOptions.map((option) => {
                    return (
                        <MenuItem
                            key={`DATE_FILTER_${option}`}
                            value={option}
                            style={{ ...props.style?.menuItem }}
                        >
                            {option}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}
