import React, { useState, useEffect, useMemo } from 'react'
import TuneIcon from '@material-ui/icons/Tune'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

import {
    Container,
    StatusBadge,
    ButtonPopover,
    IOption,
    Selector,
    SideDrawerContainer,
    SortTitle,
} from '../../../components'

import { EZPayFilterChips } from './FilterChips'
import { isStringNumeric, toMMDDYYYY, toUiTime } from '../../../helpers'
import {
    DwollaTransferStatus,
    DwollaTransferTransitionLog,
    Invoice,
    WorkSpaceUser,
    getTransferUIColor,
    getTransferUIStatus,
} from '../../../models'
import {
    Divider,
    useTheme,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Tooltip,
    Popover,
    Theme,
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import NumberFormat from 'react-number-format'
import { usePopover } from '../../../hooks/usePopover'

import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import { dwollaApi } from '../../../contexts'
import { InvoiceFilterDrawer } from './InvoiceFilterDrawer'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'

import { SearchField } from '../../../components/SearchField'
import { useCompany } from '../../../hooks'
import { InvoiceRow } from './InvoiceRow'

interface Props {
    selected: boolean
    user: WorkSpaceUser
}

interface PayDialogState {
    open: null | 'pay' | 'cancel'
    selectedInvoice: Invoice | null
    selectedFundingSource: number
}

export const InvoiceTab = (props: Props) => {
    const { user, selected } = props

    const {
        fundingSources,
        dwollaCustomer,
        transitionTransferRequest,
        invoiceList,
        getInvoiceList,
        balance,
        getBalance,
    } = dwollaApi()

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    const { vendorList } = useCompany({ getVendorList: true, cleanUp: true })

    const [payDialogState, setPayDialogState] = useState<PayDialogState>({
        open: null,
        selectedInvoice: null,
        selectedFundingSource: dwollaCustomer?.default_funding_source ?? -1,
    })

    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)

    const [filterState, setFilterState] = useState<EZPayTransactionFilterState>(
        defaultTransactionFilterState,
    )

    const [sortState, setSortState] = useState<SortState>({
        category: null,
        direction: 'ascend',
    })

    const [page, setPage] = useState(1)
    const theme = useTheme()

    const pageStart = (page - 1) * ITEMS_PER_PAGE
    const pageEnd = page - 1 + page * ITEMS_PER_PAGE

    useEffect(() => {
        if (selected) {
            getInvoiceList()
        }
        getBalance()
    }, [selected])

    const filteredInvoices = useMemo(() => {
        let enableStatusFilter = false
        Object.keys(filterState.status).forEach((status: any) => {
            const val = status as DwollaTransferStatus

            if (filterState.status[val]) {
                enableStatusFilter = true
            }
        })

        return (
            invoiceList
                ?.filter((invoice) => {
                    if (invoice.dwolla_transfer === null) {
                        return false
                    }

                    const statusValid =
                        !enableStatusFilter ||
                        filterState.status[invoice.dwolla_transfer.status]

                    const lcSearch = filterState.searchString.toLocaleLowerCase()

                    const searchStringValid = String(invoice.id).startsWith(
                        lcSearch,
                    )

                    const vendorValid =
                        filterState.vendorId === -1 ||
                        filterState.vendorId === invoice.vendor.id

                    const fsValid =
                        filterState.fundingSourceId === -1 ||
                        filterState.fundingSourceId ===
                            invoice.dwolla_transfer.source_funding_source

                    return (
                        statusValid &&
                        searchStringValid &&
                        vendorValid &&
                        fsValid
                    )
                })
                .slice(pageStart, pageEnd) ?? []
        )
    }, [invoiceList, filterState, pageStart, pageEnd])

    const sortedInvoices = useMemo(() => {
        return filteredInvoices.sort((a, b) => {
            let first = a
            let second = b

            if (sortState.direction === 'decend') {
                first = b
                second = a
            }

            if (sortState.category === 'amount') {
                return first.total_cost - second.total_cost
            }

            if (sortState.category === 'date') {
                return (
                    new Date(first.created_date).getTime() -
                    new Date(second.created_date).getTime()
                )
            }

            return 0
        })
    }, [filteredInvoices, sortState])

    if (!props.selected) {
        return null
    }

    const popoverOptions: IOption[] = [
        {
            title: 'Close',
            color: 'secondary',
            onClick: () => {
                handleClose()
            },
        },
    ]

    if (
        payDialogState.selectedInvoice?.dwolla_transfer?.status ===
            DwollaTransferStatus.REQUEST ||
        payDialogState.selectedInvoice?.dwolla_transfer?.status ===
            DwollaTransferStatus.CANCELLED ||
        payDialogState.selectedInvoice?.dwolla_transfer?.status ===
            DwollaTransferStatus.FAILED
    ) {
        popoverOptions.unshift({
            title: (
                <Container
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                            marginRight: theme.spacing(1),
                        }}
                    >
                        Pay
                    </span>
                    <LocalAtmIcon />
                </Container>
            ),
            color: 'success',
            onClick: () => {
                setPayDialogState((old) => {
                    return {
                        open: 'pay',
                        selectedInvoice: old.selectedInvoice,
                        selectedFundingSource: old.selectedFundingSource,
                    }
                })
            },
        })
    } else if (
        payDialogState.selectedInvoice?.dwolla_transfer?.status ===
        DwollaTransferStatus.PENDING
    ) {
        popoverOptions.unshift({
            title: (
                <Container
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                            marginRight: theme.spacing(1),
                        }}
                    >
                        Cancel
                    </span>
                    <MoneyOffIcon />
                </Container>
            ),
            color: 'danger',
            onClick: () => {
                setPayDialogState((old) => {
                    return {
                        open: 'cancel',
                        selectedInvoice: old.selectedInvoice,
                        selectedFundingSource: old.selectedFundingSource,
                    }
                })
            },
        })
    }

    return (
        <SideDrawerContainer open={filterDrawerOpen}>
            <Container style={{ flexDirection: 'column', flex: 1 }}>
                {/* Header */}
                <Container style={{ padding: theme.spacing(1) }}>
                    {user.active_workspace.company_type === 'APARTMENT' && (
                        <Container
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h4,
                                    fontWeight: theme.typography.fontWeightBold,
                                    marginRight: theme.spacing(2),
                                }}
                            >
                                Balance
                            </span>

                            <Tooltip title="This balance will typically be $0.  All payments must first clear through the balance and then get routed to their destination.">
                                <NumberFormat
                                    housandSeparator
                                    isNumericString
                                    prefix="$"
                                    value={balance?.value ?? -1}
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale
                                    style={{
                                        ...theme.typography.h5,
                                    }}
                                />
                            </Tooltip>
                        </Container>
                    )}

                    <div style={{ flex: 1 }} />

                    <SearchField
                        variant="outlined"
                        value={filterState.searchString}
                        onChange={(e) => {
                            if (isStringNumeric(e.target.value)) {
                                setFilterState((prev) => {
                                    return {
                                        ...prev,
                                        searchString: e.target.value,
                                    }
                                })
                            }
                        }}
                        style={{ marginRight: theme.spacing(1) }}
                        placeholder="Search Invoice #"
                    />

                    <IconButton
                        onClick={() => setFilterDrawerOpen((prev) => !prev)}
                    >
                        <TuneIcon
                            fontSize="large"
                            color={filterDrawerOpen ? 'primary' : 'inherit'}
                        />
                    </IconButton>
                </Container>

                <Container
                    style={{
                        height: 50,
                        alignItems: 'center',
                    }}
                >
                    <SortTitle
                        title="Date"
                        customStyle={{
                            container: { marginRight: theme.spacing(1) },
                        }}
                        sortDir={
                            sortState.category === 'date'
                                ? sortState.direction
                                : null
                        }
                        onClick={() => {
                            setSortState((prev) => {
                                return {
                                    category: 'date',
                                    direction:
                                        prev.direction === 'ascend'
                                            ? 'decend'
                                            : 'ascend',
                                }
                            })
                        }}
                    />
                    <SortTitle
                        title="Amount"
                        sortDir={
                            sortState.category === 'amount'
                                ? sortState.direction
                                : null
                        }
                        onClick={() => {
                            setSortState((prev) => {
                                return {
                                    category: 'amount',
                                    direction:
                                        prev.direction === 'ascend'
                                            ? 'decend'
                                            : 'ascend',
                                }
                            })
                        }}
                    />

                    <EZPayFilterChips
                        filterState={filterState}
                        setFilterState={setFilterState}
                        vendorList={vendorList}
                    />
                </Container>

                {/* Content */}
                <Container style={{ flexDirection: 'column', flex: 1 }}>
                    <Divider />
                    {sortedInvoices.length === 0 && (
                        <Container
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h4,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                No invoices match your filters
                            </span>
                        </Container>
                    )}

                    {sortedInvoices.map((invoice, idx) => {
                        return (
                            <InvoiceRow
                                key={`INVOICE_ROW_${invoice.id}`}
                                user={user}
                                invoice={invoice}
                                openPopover={(e, invoice) => {
                                    setPayDialogState((old) => {
                                        return {
                                            open: null,
                                            selectedInvoice: invoice,
                                            selectedFundingSource:
                                                old.selectedFundingSource,
                                        }
                                    })
                                    handleOpen(e)
                                }}
                            />
                        )
                    })}
                </Container>

                {/* Footer */}
                <Divider />
                <Container style={{ height: 100, padding: theme.spacing(1) }}>
                    <Pagination
                        page={page}
                        onChange={(e, value) => {
                            setPage(value)
                        }}
                        count={Math.ceil(
                            (invoiceList?.length ?? 0) / ITEMS_PER_PAGE,
                        )}
                        size={'small'}
                        variant={'outlined'}
                        siblingCount={0}
                    />
                </Container>

                <ButtonPopover
                    open={isOpen}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                    }}
                    onClose={() => {
                        handleClose()
                        setPayDialogState((old) => {
                            return {
                                open: null,
                                selectedInvoice: null,
                                selectedFundingSource:
                                    old.selectedFundingSource,
                            }
                        })
                    }}
                    options={popoverOptions}
                />

                <Dialog
                    open={payDialogState.open === 'pay'}
                    onClose={() => {
                        setPayDialogState((old) => {
                            return {
                                open: null,
                                selectedInvoice: old.selectedInvoice,
                                selectedFundingSource:
                                    old.selectedFundingSource,
                            }
                        })
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        Confirm Payment for Invoice #
                        {payDialogState.selectedInvoice?.id}
                    </DialogTitle>
                    <DialogContent>
                        <Container>
                            <DialogContentText
                                id="alert-dialog-description"
                                style={{ flex: 1 }}
                            >
                                A payment of{' '}
                                <NumberFormat
                                    housandSeparator
                                    isNumericString
                                    prefix="$"
                                    value={
                                        payDialogState.selectedInvoice
                                            ?.total_cost
                                    }
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale
                                />{' '}
                                Will be made to{' '}
                                {payDialogState.selectedInvoice?.vendor.name}{' '}
                                out of the selected account
                            </DialogContentText>

                            <Selector
                                customStyle={{
                                    formControl: {
                                        flex: 1,
                                        margin: 0,
                                        marginLeft: theme.spacing(2),
                                    },
                                }}
                                label="Funding Source"
                                currentValue={
                                    payDialogState.selectedFundingSource
                                }
                                data={fundingSources ?? []}
                                getDisplayString={(fs) =>
                                    `${fs.name} - ${fs.account_type}`
                                }
                                onChange={(
                                    event: React.ChangeEvent<{
                                        value: unknown
                                    }>,
                                ) => {
                                    setPayDialogState((old) => {
                                        return {
                                            ...old,
                                            selectedFundingSource: event.target
                                                .value as number,
                                        }
                                    })
                                }}
                            />
                        </Container>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() =>
                                setPayDialogState((old) => {
                                    return {
                                        open: null,
                                        selectedInvoice: old.selectedInvoice,
                                        selectedFundingSource:
                                            old.selectedFundingSource,
                                    }
                                })
                            }
                        >
                            Cancel
                        </Button>
                        <div style={{ flex: 1 }} />
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={async () => {
                                await transitionTransferRequest(
                                    payDialogState.selectedInvoice!
                                        .dwolla_transfer!,
                                    payDialogState.selectedFundingSource,
                                    'pay',
                                )

                                setPayDialogState((old) => {
                                    return {
                                        ...old,
                                        open: null,
                                        selectedInvoice: null,
                                    }
                                })

                                handleClose()
                            }}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={payDialogState.open === 'cancel'}
                    onClose={() => {
                        handleClose()
                        setPayDialogState((old) => {
                            return {
                                open: null,
                                selectedInvoice: old.selectedInvoice,
                                selectedFundingSource:
                                    old.selectedFundingSource,
                            }
                        })
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        Confirm payment cancellation for Invoice #
                        {payDialogState.selectedInvoice?.id}
                    </DialogTitle>
                    <DialogContent>
                        <Container>
                            <DialogContentText
                                id="alert-dialog-description"
                                style={{ flex: 1 }}
                            >
                                {' '}
                                Are you sure you want to cancel the{' '}
                                <NumberFormat
                                    housandSeparator
                                    isNumericString
                                    prefix="$"
                                    value={
                                        payDialogState.selectedInvoice
                                            ?.total_cost
                                    }
                                    displayType="text"
                                    decimalScale={2}
                                    fixedDecimalScale
                                />{' '}
                                Payment?
                            </DialogContentText>
                        </Container>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                                handleClose()
                                setPayDialogState((old) => {
                                    return {
                                        open: null,
                                        selectedInvoice: old.selectedInvoice,
                                        selectedFundingSource:
                                            old.selectedFundingSource,
                                    }
                                })
                            }}
                        >
                            No
                        </Button>
                        <div style={{ flex: 1 }} />
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={async () => {
                                await transitionTransferRequest(
                                    payDialogState.selectedInvoice!
                                        .dwolla_transfer!,
                                    payDialogState.selectedFundingSource,
                                    'cancel',
                                )

                                setPayDialogState((old) => {
                                    return {
                                        ...old,
                                        open: null,
                                        selectedInvoice: null,
                                    }
                                })

                                handleClose()
                            }}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>

            <InvoiceFilterDrawer
                open={filterDrawerOpen}
                onClose={() => setFilterDrawerOpen(false)}
                filterState={filterState}
                onChangeFilter={setFilterState}
                vendorList={vendorList}
            />
        </SideDrawerContainer>
    )
}

const ITEMS_PER_PAGE = 10

export interface EZPayTransactionFilterState {
    status: {
        [DwollaTransferStatus.PENDING]: boolean
        [DwollaTransferStatus.PROCESSED]: boolean
        [DwollaTransferStatus.CANCELLED]: boolean
        [DwollaTransferStatus.FAILED]: boolean
        [DwollaTransferStatus.REQUEST]: boolean
    }
    searchString: string
    vendorId: number
    fundingSourceId: number
}

export const defaultTransactionFilterState: EZPayTransactionFilterState = {
    status: {
        [DwollaTransferStatus.PENDING]: true,
        [DwollaTransferStatus.PROCESSED]: false,
        [DwollaTransferStatus.CANCELLED]: false,
        [DwollaTransferStatus.FAILED]: true,
        [DwollaTransferStatus.REQUEST]: true,
    },
    searchString: '',
    vendorId: -1,
    fundingSourceId: -1,
}

interface SortState {
    category: 'date' | 'amount' | null
    direction: 'ascend' | 'decend'
}
