import { Grid, TableSortLabel, useTheme } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { Container } from '../../components'
import { ListVendor, Service } from '../../models'
import { VendorRow } from './VendorRow'

interface Props {
    headCellStyle: React.CSSProperties
    vendorList: ListVendor[]
    cellStyle: React.CSSProperties
    setEditVendor: (ven: ListVendor) => void
    setOpenDrawer: (open: boolean) => void
    onClickVendorRow: (vendor: ListVendor, index: number) => void
    setOpenAnalytics: (vendor: ListVendor, index: number) => void
    resendWelcomeEmail: (vendor: ListVendor) => void
}

const VendorASC = 'VendorASC'
const VendorDESC = 'VendorDESC'
const AddressASC = 'AddressASC'
const AddressDESC = 'AddressDESC'
const TypeASC = 'TypeASC'
const TypeDESC = 'TypeDESC'
const ServicesASC = 'ServicesASC'
const ServicesDESC = 'ServicesDESC'
const ContactASC = 'ContactASC'
const ContactDESC = 'ContactDESC'

export type VendorTableRow = {
    name: string
    type: string
    services: Service[]
    contactInfo: string
    address: string
    vendor: ListVendor
    id: number
}

export const WorkForceVendorTable = (props: Props) => {
    const {
        headCellStyle,
        vendorList,
        cellStyle,
        setEditVendor,
        setOpenDrawer,
        onClickVendorRow,
        setOpenAnalytics,
        resendWelcomeEmail,
    } = props

    const theme = useTheme()

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [orderBy, setOrderBy] = useState('')

    const createVendorRow = (vendor: ListVendor): VendorTableRow => {
        const type = vendor.in_house ? 'In House' : 'Contractor'
        const serviceList = vendor.services

        let contactInfo = ''
        if (vendor.phone) contactInfo = `${vendor.phone} `
        if (vendor.email) contactInfo += `${vendor.email}`

        const id = vendor.id

        return {
            name: vendor.name,
            type: type,
            services: serviceList,
            contactInfo: contactInfo,
            address: vendor.address,
            vendor: vendor,
            id: id,
        }
    }

    const [sortedRows, setSortedRows] = useState<VendorTableRow[]>([])

    useEffect(() => {
        const rows = vendorList.map((comp: ListVendor) => createVendorRow(comp))
        rows.sort((rowA, rowB) => {
            if (rowA.services.length !== 0 && rowB.services.length === 0) {
                return -1
            } else if (
                rowA.services.length === 0 &&
                rowB.services.length !== 0
            ) {
                return 1
            }
            return 0
        })
        setSortedRows(rows)
    }, [vendorList])

    return (
        <Container scrollY>
            {/* HEADERS */}
            <Grid
                container={true}
                style={{
                    padding: theme.spacing(2, 0, 1, 0),
                }}
            >
                <Grid container={true} justify="space-evenly">
                    <Container
                        style={{ ...headCellStyle, flex: 1 }}
                    ></Container>
                    <Container
                        style={{
                            ...headCellStyle,
                            marginLeft: theme.spacing(2),
                        }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== VendorASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.name.localeCompare(RowB.name),
                                )
                                setSortedRows(newRows)
                                setOrderBy(VendorASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(VendorDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={
                                orderBy === VendorASC || orderBy === VendorDESC
                            }
                            direction={orderBy === VendorASC ? 'asc' : 'desc'}
                        >
                            Vendor
                        </TableSortLabel>
                    </Container>
                    <Container
                        style={{ ...headCellStyle }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== AddressASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.address.localeCompare(RowB.address),
                                )
                                setSortedRows(newRows)
                                setOrderBy(AddressASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(AddressDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={
                                orderBy === AddressASC ||
                                orderBy === AddressDESC
                            }
                            direction={orderBy === AddressASC ? 'asc' : 'desc'}
                        >
                            Address
                        </TableSortLabel>
                    </Container>
                    <Container
                        style={{ ...headCellStyle }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== TypeASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.type.localeCompare(RowB.type),
                                )
                                setSortedRows(newRows)
                                setOrderBy(TypeASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(TypeDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === TypeASC || orderBy === TypeDESC}
                            direction={orderBy === TypeASC ? 'asc' : 'desc'}
                        >
                            Type
                        </TableSortLabel>
                    </Container>
                    <Container
                        style={{ ...headCellStyle }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== ServicesASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.services.length
                                        .toString()
                                        .localeCompare(
                                            RowB.services.length.toString(),
                                        ),
                                )
                                setSortedRows(newRows)
                                setOrderBy(ServicesASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(ServicesDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={
                                orderBy === ServicesASC ||
                                orderBy === ServicesDESC
                            }
                            direction={orderBy === ServicesASC ? 'asc' : 'desc'}
                        >
                            Services
                        </TableSortLabel>
                    </Container>
                    <Container
                        style={{
                            ...headCellStyle,
                            marginLeft: theme.spacing(3),
                        }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== ContactASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.contactInfo.localeCompare(
                                        RowB.contactInfo,
                                    ),
                                )
                                setSortedRows(newRows)
                                setOrderBy(ContactASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(ContactDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={
                                orderBy === ContactASC ||
                                orderBy === ContactDESC
                            }
                            direction={orderBy === ContactASC ? 'asc' : 'desc'}
                        >
                            Contact Info
                        </TableSortLabel>
                    </Container>
                    <Container
                        style={{
                            ...headCellStyle,
                            justifyContent: 'center',
                            flex: 1,
                        }}
                    >
                        Analytics
                    </Container>
                    <Container
                        style={{ ...headCellStyle, flex: 1 }}
                    ></Container>
                </Grid>

                {/* ROWS */}
                <Grid
                    container={true}
                    style={{
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 400px)',
                    }}
                >
                    {sortedRows
                        .map((row, index) => {
                            return (
                                <VendorRow
                                    row={row}
                                    cellStyle={cellStyle}
                                    setEditVendor={setEditVendor}
                                    setOpenDrawer={setOpenDrawer}
                                    key={index}
                                    onClick={() => {
                                        onClickVendorRow(row.vendor, index)
                                    }}
                                    setOpenAnalytics={() => {
                                        setOpenAnalytics(row.vendor, index)
                                    }}
                                    resendWelcomeEmail={resendWelcomeEmail}
                                />
                            )
                        })
                        .slice(
                            (page - 1) * rowsPerPage,
                            (page - 1) * rowsPerPage + rowsPerPage,
                        )}
                </Grid>
                <Container style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Pagination
                        count={Math.ceil(sortedRows.length / rowsPerPage)}
                        page={page}
                        onChange={(_: unknown, newPage: number) => {
                            setPage(newPage)
                        }}
                        variant="outlined"
                        shape="rounded"
                        style={{ margin: theme.spacing(1, 0, 0, 0) }}
                    />
                </Container>
            </Grid>
        </Container>
    )
}
