import {
    Checkbox,
    FormControlLabel,
    Popover,
    PopoverOrigin,
    useTheme,
} from '@material-ui/core'
import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { Container } from '../../../components'
import { axiosInstance } from '../../../helpers'
import {
    IdBoolMap,
    ModelMap,
    PreTurnConfigItem,
    PreTurnItem,
    Schedule,
} from '../../../models'

interface Props {
    open: boolean
    schedule: Schedule
    onClose: () => void
    anchorEl: HTMLElement | null
    anchorOrigin?: PopoverOrigin
    portfolio?: boolean
}

export const SchedulePreTurnChecklist = (props: Props) => {
    const { open, schedule, onClose, anchorEl, anchorOrigin, portfolio } = props

    const theme = useTheme()

    const [preTurnConfigs, setPreTurnConfigs] = useState<PreTurnConfigItem[]>(
        [],
    )

    useEffect(() => {
        if (!open) {
            return
        }

        const preTurnItemPromise = axiosInstance.get(
            'workorder/pre-turn-item/',
            { params: { schedule: schedule.id, portfolio: portfolio } },
        )
        if (schedule.active) {
            scheduleActiveEffect(preTurnItemPromise)
        } else {
            preTurnItemPromise.then((res) => {
                // get list of configs for these items and set it in state
                const preTurnItemList: PreTurnItem[] = res.data

                const preTurnConfigList = preTurnItemList.map<PreTurnConfigItem>(
                    (preTurnItem) => {
                        return {
                            ...preTurnItem.config_item,
                            checked: preTurnItem.checked,
                        }
                    },
                )

                setPreTurnConfigs(preTurnConfigList)
            })
        }
    }, [open])

    const scheduleActiveEffect = (
        preTurnItemPromise: Promise<AxiosResponse<any>>,
    ) => {
        const preTurnItemConfigPromise = axiosInstance.get(
            'apt_config/pre-turn-item-config/',
            { params: { portfolio: portfolio } },
        )

        Promise.all([preTurnItemPromise, preTurnItemConfigPromise]).then(
            (resValues) => {
                const preTurnItems: PreTurnItem[] = resValues[0].data
                const preTurnConfigRes: PreTurnConfigItem[] = resValues[1].data

                //create a list of config items with checked statuses to use for the rendering state

                // first, create a map of the configs returned from the server
                const preTurnConfigMap: ModelMap<PreTurnConfigItem> = {}
                preTurnConfigRes.forEach((preTurnConfig) => {
                    preTurnConfigMap[preTurnConfig.id] = preTurnConfig
                })

                const preTurnConfigCheckedMap: IdBoolMap = {}
                // add missing configs from pre turn items to pre turn config list
                preTurnItems.forEach((preTurnItem) => {
                    if (!(preTurnItem.config_item.id in preTurnConfigMap)) {
                        preTurnConfigMap[preTurnItem.config_item.id] =
                            preTurnItem.config_item
                        preTurnConfigRes.push(preTurnItem.config_item)
                    }
                    preTurnConfigCheckedMap[preTurnItem.config_item.id] =
                        preTurnItem.checked
                })

                // use the map of checked statuses to set checked on the configs
                const preTurnConfigsWithChecks = preTurnConfigRes.reduce<
                    PreTurnConfigItem[]
                >((prev, preTurnConfig) => {
                    const checked =
                        preTurnConfigCheckedMap[preTurnConfig.id] ?? false

                    return prev.concat({
                        ...preTurnConfig,
                        checked: checked,
                    })
                }, [])

                setPreTurnConfigs(preTurnConfigsWithChecks)
            },
        )
    }

    return (
        <Popover
            id="SCHEDULE-PRE-TURN-POPOVER"
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={anchorOrigin}
        >
            <Container
                style={{
                    width: 300,
                    backgroundColor: theme.palette.grey[100],

                    padding: theme.spacing(1),
                }}
            >
                <span style={{ ...theme.typography.body1 }}>Completion: </span>
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: 'bold',
                        marginLeft: theme.spacing(1),
                    }}
                >
                    {
                        preTurnConfigs.filter(
                            (preTurnConfig) => preTurnConfig.checked,
                        ).length
                    }
                    /{preTurnConfigs.length}
                </span>
            </Container>
            <Container
                style={{
                    width: 300,
                    backgroundColor: theme.palette.grey[100],
                    flexDirection: 'column',
                    maxHeight: 500,
                    padding: theme.spacing(1),
                }}
                scrollY
            >
                {preTurnConfigs.map((preTurnConfig) => {
                    return (
                        <FormControlLabel
                            key={`PRE_TURN_CONFIG_ITEM_SCH_${schedule.id}_${preTurnConfig.id}`}
                            control={
                                <Checkbox
                                    checked={preTurnConfig.checked}
                                    style={{
                                        color: theme.palette.primary.dark,
                                    }}
                                    onClick={() => {
                                        axiosInstance
                                            .post(
                                                'workorder/pre-turn-item/create_or_update/',
                                                {
                                                    schedule: schedule.id,
                                                    config_item:
                                                        preTurnConfig.id,
                                                    checked: !preTurnConfig.checked,
                                                },
                                            )
                                            .then((res) => {
                                                const newList = preTurnConfigs.map(
                                                    (inner) => {
                                                        if (
                                                            inner.id ===
                                                            preTurnConfig.id
                                                        ) {
                                                            return {
                                                                ...preTurnConfig,
                                                                checked: !preTurnConfig.checked,
                                                            }
                                                        }
                                                        return inner
                                                    },
                                                )

                                                setPreTurnConfigs(newList)
                                            })
                                    }}
                                    disabled={!schedule.active || portfolio}
                                />
                            }
                            label={
                                <span style={theme.typography.body1}>
                                    {preTurnConfig.name}
                                </span>
                            }
                            labelPlacement="end"
                            style={{
                                fontWeight: 'bold',
                            }}
                        />
                    )
                })}
            </Container>
        </Popover>
    )
}
