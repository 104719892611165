import {
    Button,
    CircularProgress,
    useTheme,
    IconButton,
    Badge,
} from '@material-ui/core'
import React from 'react'
import { Bid } from '../../models'
import { Container } from '../../components'
import { useStyles } from '../../styles'
import { ListPopover } from '../../components/ListPopover/ListPopover'
import { usePopover } from '../../hooks/usePopover'
import { toMMDDYYYY } from '../../helpers'
import { RFPStatusBadge } from './RFPStatusBadge'
import { usePagination } from '../../hooks'
import { Pagination } from '@material-ui/lab'
import { rfpApi } from '../../contexts'
import { DisplayService } from '../../components/DisplayService'
import { ChatBubbleOutline } from '@material-ui/icons'

interface Props {
    bidList: Bid[] | null
    selectedBid: Bid | null
    setSelectedBid: (bid: Bid) => void
    setOpenDetail: (open: boolean) => void
    setChannelId: (id: number) => void
}

export const VendorBidList = (props: Props) => {
    const {
        bidList,
        setSelectedBid,
        selectedBid,
        setOpenDetail,
        setChannelId,
    } = props

    const { loadingState } = rfpApi()

    const theme = useTheme()

    const { page, setPage, pageData, numPages, start, end } = usePagination(
        bidList ?? [],
    )

    const headerStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.darkGreen.main,
        flex: 1,
    }

    return (
        <Container
            flex={1}
            direction="column"
            style={{
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: theme.shape.borderRadius,
                marginTop: theme.spacing(3),
                padding: theme.spacing(1),
            }}
        >
            <Container style={{ margin: theme.spacing(0, 2) }}>
                <Container style={{ ...headerStyle }}>Property Name</Container>
                <Container style={{ ...headerStyle }}>Location</Container>
                <Container style={{ ...headerStyle }}>Services</Container>
                <Container style={{ ...headerStyle }}>Status</Container>
                <Container style={{ ...headerStyle }}>
                    Start - End Dates
                </Container>
                <Container style={{ ...headerStyle }}>
                    Deadline to Respond
                </Container>
                <Container
                    style={{ width: '50px', marginRight: theme.spacing(2) }}
                />
            </Container>
            {loadingState.getBidList ? (
                <Container
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: 'calc(100vh  - 450px)',
                        maxHeight: 'calc(100vh  - 450px)',
                    }}
                >
                    <CircularProgress size={100} />
                </Container>
            ) : (
                <Container
                    direction="column"
                    scrollY
                    flex={1}
                    style={{
                        minHeight: 'calc(100vh  - 500px)',
                        maxHeight: 'calc(100vh  - 500px)',
                    }}
                >
                    {bidList?.map((bid) => {
                        return (
                            <BidRow
                                key={`BID_VENDOR_${bid.id}`}
                                bid={bid}
                                setSelectedBid={setSelectedBid}
                                isSelected={bid.id === selectedBid?.id}
                                setOpenDetail={setOpenDetail}
                                openMessageDrawer={() => {
                                    if (bid.channel_id) {
                                        setChannelId(bid.channel_id)
                                        setSelectedBid(bid)
                                    }
                                }}
                            />
                        )
                    })}
                </Container>
            )}
            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    minHeight: '40px',
                    maxHeight: '40px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Pagination
                    count={numPages}
                    page={page}
                    onChange={(_: unknown, newPage: number) => {
                        setPage(newPage)
                    }}
                />
                <span>
                    {start} - {end} of {bidList?.length ?? 0}
                </span>
            </Container>
        </Container>
    )
}

interface BidRowProps {
    bid: Bid
    setSelectedBid: (bid: Bid) => void
    isSelected: boolean
    setOpenDetail: (open: boolean) => void
    openMessageDrawer: () => void
}

const BidRow = (props: BidRowProps) => {
    const {
        bid,
        setSelectedBid,
        isSelected,
        setOpenDetail,
        openMessageDrawer,
    } = props

    const rfp = bid.rfp

    const theme = useTheme()
    const classes = useStyles()

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    const infoStyle: React.CSSProperties = {
        fontSize: '16px',
        fontWeight: 500,
        flex: 1,
    }

    const services = (
        <Container>
            {rfp.rfp_services[0] && (
                <DisplayService service={rfp.rfp_services[0].service} border />
            )}
            {rfp.rfp_services.length > 1 && (
                <>
                    <Container>
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                handleOpen(e)
                            }}
                            size="small"
                            style={{
                                margin: theme.spacing(1, 0, 1, 0),
                            }}
                        >
                            +{rfp.rfp_services.length - 1}
                        </Button>
                    </Container>
                    <ListPopover
                        open={isOpen}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            horizontal: -50,
                            vertical: 'bottom',
                        }}
                        style={{ paddingLeft: theme.spacing(1) }}
                    >
                        {rfp.rfp_services.slice(1).map((rfpService) => {
                            return (
                                <DisplayService
                                    service={rfpService.service}
                                    border={false}
                                    key={`RFP_SERVICE_DISPLAY_${rfpService.id}`}
                                />
                            )
                        })}
                    </ListPopover>
                </>
            )}
        </Container>
    )

    let deadline = ''
    if (rfp.project_submission_deadline) {
        deadline = toMMDDYYYY(new Date(rfp.project_submission_deadline))
    }

    let dateRange = ''
    if (rfp.project_start_date && rfp.project_end_date) {
        dateRange = `${toMMDDYYYY(
            new Date(rfp.project_start_date),
        )} - ${toMMDDYYYY(new Date(rfp.project_end_date))}`
    }

    return (
        <Container
            style={{
                margin: theme.spacing(1),
                padding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.palette.grey[600]}`,
                cursor: 'pointer',
                backgroundColor: isSelected
                    ? theme.palette.grey[300]
                    : undefined,
                maxHeight: 100,
                minHeight: 100,
                alignItems: 'center',
                overflow: 'hidden',
            }}
            className={classes.hoverGrey200}
            onClick={() => {
                setSelectedBid(bid)
                setOpenDetail(true)
            }}
        >
            <span
                style={{
                    ...infoStyle,
                    overflow: 'hidden',
                }}
            >
                {rfp.apartment.name}
            </span>
            <span style={infoStyle}>
                {rfp.apartment.primary_address.city},{' '}
                {rfp.apartment.primary_address.state}
            </span>
            <span style={infoStyle}>{services}</span>
            <span style={infoStyle}>
                <RFPStatusBadge
                    theme={theme}
                    status={bid.status}
                    style={{
                        container: {
                            maxWidth: '100px',
                        },
                    }}
                />
            </span>
            <span style={infoStyle}>{dateRange}</span>
            <span style={infoStyle}>{deadline}</span>
            <Container style={{ width: '50px' }}>
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation()
                        openMessageDrawer()
                    }}
                >
                    <Badge badgeContent={bid.message_count} color="primary">
                        <ChatBubbleOutline />
                    </Badge>
                </IconButton>
            </Container>
        </Container>
    )
}
