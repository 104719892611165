import {
    Button,
    CircularProgress,
    Divider,
    IconButton,
    Modal,
    Paper,
    Slide,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container, Selector } from '../../components'
import CloseIcon from '@material-ui/icons/Close'
import { Company, Invoice } from '../../models'
import { axiosInstance } from '../../helpers'
import { toast } from 'react-toastify'
import { useAppDispatch, useUser } from '../../hooks'
import { getInvoiceList } from '../../store'
import { ApartmentVendor } from '../NationalPropertyList'
import {
    EntrataGLInfo,
    EntrataLocation,
    EntrataVendor,
} from './ConfigureEntrataDetails'

interface Props {
    open: boolean
    handleClose: () => void
    invoice: Invoice
    setSelectedInvoice: (invoice: Invoice | undefined) => void
    setIsLoading: (loading: boolean) => void
    entrataVendors: EntrataVendor[]
    entrataGLInfo: EntrataGLInfo[]
    apartmentVendor?: ApartmentVendor
    companyId: number
}

export const EntrataInvoiceModal = (props: Props) => {
    const {
        open,
        handleClose,
        invoice,
        setSelectedInvoice,
        setIsLoading,
        companyId,
        apartmentVendor,
        entrataVendors,
        entrataGLInfo,
    } = props

    const [entrataGLID, setEntrataGLID] = useState<number | null>(
        apartmentVendor?.entrata_gl_account_id
            ? apartmentVendor?.entrata_gl_account_id
            : null,
    )
    const [selectedEntrataVendorID, setSelectedEntrataVendorID] = useState<
        number | null
    >(apartmentVendor?.entrata_id ? apartmentVendor?.entrata_id : null)
    const [selectedEntrataLocationID, setSelectedEntrataLocationID] = useState<
        string | null
    >(
        apartmentVendor?.entrata_location_id
            ? apartmentVendor?.entrata_location_id
            : null,
    )

    const [entrataLocations, setEntrataLocations] = useState<EntrataLocation[]>(
        [],
    )

    const [doValidate, setDoValidate] = useState(false)

    const [invoiceSyncing, setInvoiceSyncing] = useState(false)

    const { workspaceUser } = useUser()

    const theme = useTheme()

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (open) {
            setEntrataGLID(
                apartmentVendor?.entrata_gl_account_id
                    ? apartmentVendor.entrata_gl_account_id
                    : null,
            )
            setSelectedEntrataVendorID(
                apartmentVendor?.entrata_id ? apartmentVendor.entrata_id : null,
            )
            setSelectedEntrataLocationID(
                apartmentVendor?.entrata_location_id
                    ? apartmentVendor.entrata_location_id
                    : null,
            )
        } else {
            setEntrataGLID(null)
            setSelectedEntrataVendorID(null)
            setSelectedEntrataLocationID(null)
        }
    }, [open])

    useEffect(() => {
        if (selectedEntrataVendorID) {
            const body = {
                entrata_ids: [selectedEntrataVendorID],
            }
            axiosInstance
                .post(
                    `company/vendor/get_entrata_vendor_details_from_ids/`,
                    body,
                )
                .then((res) => {
                    if (res.data.vendors_data[0]) {
                        if (res.data.vendors_data[0].defaultGLAccountID) {
                            setEntrataGLID(
                                res.data.vendors_data[0].defaultGLAccountID,
                            )
                        } else {
                            setEntrataGLID(null)
                        }
                        const tempLocations: EntrataLocation[] = []
                        res.data.vendors_data[0].locations.map(
                            (location: any) => {
                                tempLocations.push({
                                    id: location.id,
                                    name: location.name,
                                })
                                if (
                                    location.id ===
                                    Number(apartmentVendor?.entrata_location_id)
                                ) {
                                    setSelectedEntrataLocationID(
                                        String(location.id),
                                    )
                                }
                            },
                        )
                        tempLocations.sort((locationA, locationB) =>
                            locationA.name.localeCompare(locationB.name),
                        )
                        setEntrataLocations(tempLocations)
                    }
                })
                .catch((e) => toast.error(e.message))
        }
    }, [selectedEntrataVendorID])

    const checkValidation = () => {
        if (!entrataGLID) {
            setDoValidate(true)
            return true
        }

        if (!selectedEntrataVendorID) {
            setDoValidate(true)
            return true
        }

        if (!selectedEntrataLocationID) {
            setDoValidate(true)
            return true
        }
        return false
    }

    const syncInvoice = () => {
        if (checkValidation()) {
            return
        }
        setInvoiceSyncing(true)
        setSelectedInvoice(invoice)
        setIsLoading(true)
        const body = {
            invoice_id: invoice.id,
            entrata_gl_account_id: entrataGLID,
            entrata_id: selectedEntrataVendorID,
            entrata_location_id: selectedEntrataLocationID,
        }
        axiosInstance
            .post(`invoice/send_invoice/`, body)
            .then(() => {
                toast.success('Invoice sent to entrata')
                dispatch(
                    getInvoiceList({
                        params: {
                            ['vendor']: companyId,
                        },
                    }),
                )
                handleClose()
            })
            .catch((e) => {
                toast.error(e.repsonse.data.message)
            })
            .finally(() => {
                setSelectedInvoice(undefined)
                setIsLoading(false)
                setInvoiceSyncing(false)
            })
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: '600px',
                        height: '520px',
                        maxHeight: 'calc(100vh - 90px)',
                        maxWidth: 'calc(100vh - 100px)',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <Container
                        style={{
                            padding: theme.spacing(3, 0, 4, 2),
                            flex: 1,
                        }}
                        direction="column"
                    >
                        <Container
                            style={{
                                alignItems: 'center',
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: 500,
                                    fontSize: '20px',
                                    flex: 1,
                                }}
                            >
                                Sync Entrata Invoice
                            </span>
                            <Tooltip title="Cancel">
                                <IconButton
                                    onClick={() => {
                                        handleClose()
                                    }}
                                >
                                    <CloseIcon
                                        fontSize="inherit"
                                        style={{
                                            marginRight: theme.spacing(1),
                                            color: theme.palette.darkGreen.main,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Container>
                        <Divider
                            style={{ margin: theme.spacing(0, 3, 0, 0) }}
                        />
                        <Container
                            style={{
                                color: theme.palette.darkGreen.main,
                                fontWeight: 400,
                                fontSize: 15,
                                margin: theme.spacing(2, 0, 2, 0),
                            }}
                        >
                            Enter All information to sync the selected invoice
                        </Container>
                        <Container direction="column">
                            <Container direction="column">
                                <Selector
                                    data={[
                                        { id: -1, vendorName: 'None Selected' },
                                        ...entrataVendors,
                                    ]}
                                    currentValue={
                                        selectedEntrataVendorID
                                            ? selectedEntrataVendorID
                                            : -1
                                    }
                                    onChange={(e) => {
                                        setSelectedEntrataVendorID(
                                            Number(e.target.value),
                                        )
                                    }}
                                    label="Entrata Vendor"
                                    variant="outlined"
                                    getDisplayString={(d) => d.vendorName}
                                    searchable
                                    customStyle={{
                                        select: { width: '400px' },
                                    }}
                                />
                            </Container>

                            <Container
                                direction="column"
                                style={{ margin: theme.spacing(2, 0, 2, 0) }}
                            >
                                <Selector
                                    data={[
                                        {
                                            id: -1,
                                            account_name: 'None Selected',
                                        },
                                        ...entrataGLInfo,
                                    ]}
                                    currentValue={
                                        entrataGLID ? entrataGLID : -1
                                    }
                                    onChange={(e) => {
                                        setEntrataGLID(Number(e.target.value))
                                    }}
                                    label="Entrata GL Account"
                                    variant="outlined"
                                    getDisplayString={(d) => d.account_name}
                                    searchable
                                    customStyle={{
                                        select: { width: '400px' },
                                    }}
                                />
                            </Container>

                            <Container direction="column">
                                <Selector
                                    data={[
                                        { id: -1, name: 'None Selected' },
                                        ...entrataLocations,
                                    ]}
                                    currentValue={Number(
                                        selectedEntrataLocationID,
                                    )}
                                    onChange={(e) => {
                                        setSelectedEntrataLocationID(
                                            String(e.target.value),
                                        )
                                    }}
                                    label="Entrata Location"
                                    variant="outlined"
                                    getDisplayString={(d) => d.name}
                                    customStyle={{
                                        select: { width: '400px' },
                                    }}
                                    searchable
                                />
                            </Container>
                        </Container>
                        <Container flex={1} />
                        <Container justifyContent="flex-end">
                            <Button
                                variant="contained"
                                style={{
                                    margin: theme.spacing(2, 2, 2, 0),
                                    backgroundColor: theme.palette.primary.dark,
                                    color: 'white',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => syncInvoice()}
                                disabled={invoiceSyncing}
                            >
                                {invoiceSyncing ? (
                                    <CircularProgress />
                                ) : (
                                    'Sync Invoice'
                                )}
                            </Button>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
