import { IconButton, Theme, Tooltip } from '@material-ui/core'
import React from 'react'
import { DDLeaseInfo } from './DDLeaseInfo'
import { ReactComponent as EmptyEditIcon } from '../../../../../assets/edit-icon-empty.svg'
import { ReactComponent as FilledEditIcon } from '../../../../../assets/edit-icon-filled.svg'
import { DDLease } from '../../../../../models'
import { Container } from '../../../../../components'

interface Props {
    theme: Theme
    lease: DDLease
    tenantMatch: boolean
    locationMatch: boolean
    startDateMatch: boolean
    endDateMatch: boolean
    rentMatch: boolean
    onOpenLeaseModal: (lease: DDLease) => void
}

export const DDLeaseCard = (props: Props) => {
    const {
        lease,
        theme,
        tenantMatch,
        locationMatch,
        startDateMatch,
        endDateMatch,
        rentMatch,
        onOpenLeaseModal,
    } = props

    return (
        <Container
            style={{
                flex: 1,
                minHeight: 100,
                backgroundColor: theme.palette.white.main,
                cursor: 'pointer',
                alignItems: 'center',
            }}
        >
            <DDLeaseInfo
                theme={theme}
                lease={lease}
                tenantMatch={tenantMatch}
                rentMatch={rentMatch}
                startDateMatch={startDateMatch}
                endDateMatch={endDateMatch}
                locationMatch={locationMatch}
            />

            {lease.edited ? (
                <IconButton
                    onClick={(e) => {
                        onOpenLeaseModal(lease)
                    }}
                    style={{ marginRight: theme.spacing(1) }}
                    size="small"
                >
                    <Tooltip title="Edit">
                        <FilledEditIcon style={{ height: 30, width: 30 }} />
                    </Tooltip>
                </IconButton>
            ) : (
                <IconButton
                    size="small"
                    onClick={(e) => {
                        onOpenLeaseModal(lease)
                    }}
                    style={{ marginRight: theme.spacing(1) }}
                >
                    <Tooltip title="Edit">
                        <EmptyEditIcon style={{ height: 30, width: 30 }} />
                    </Tooltip>
                </IconButton>
            )}
        </Container>
    )
}
