import React, { useState, useEffect, useRef, useMemo } from 'react'

import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Modal,
    Paper,
    Slide,
    TableCell,
    TableRow,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

// import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid'

import {
    Address,
    BaseCompany,
    IdBoolMap,
    IdentifiableNamedObject,
} from '../../../models'
import { axiosInstance } from '../../../helpers'
import { Container } from '../../Container'
import { SelectedWorkspacePanel } from './SelectedWorkspacePanel'
import { MODAL_HEADER_H } from './constants'
import { SearchField } from '../../SearchField'
import { toast } from 'react-toastify'
import { CellData, HeadCell, RowData, SimpleTable } from '../../Table'
import { useStyles } from '../../../styles'

const mapCompanyToRow = (company: CompanyWithInvoiceCode) => {
    const companyTypeStr =
        company.company_type === 'APARTMENT' ? 'Apartment' : 'Vendor'

    return {
        id: { value: company.id, sortValue: company.id },
        name: { value: company.name, sortValue: company.name },
        organization: {
            value: company.organization.name,
            sortValue: company.organization.name,
        },
        address: {
            value: company.primary_address,
            sortValue: company.primary_address?.state ?? '',
        },
        type: { value: companyTypeStr, sortValue: companyTypeStr ?? '' },
        invoice_code: {
            value: company.invoice_code,
            sortValue: company.invoice_code ?? '',
        },
        company: { value: company, sortValue: company.name },
    }
}

interface Props {
    theme: Theme
    open: boolean
    onClose: () => void
}

export const WorkspaceModal = (props: Props) => {
    const { theme, open, onClose } = props

    const [dialogOpen, setDialogOpen] = useState(false)

    const [searchText, setSearchText] = useState('')
    const [userEmailSearch, setUserEmailSearch] = useState('')
    const [tableState, setTableState] = useState<TableState>({
        isLoading: false,
        serverRes: [],
    })

    const [
        selectedWorkspace,
        setSelectedWorkspace,
    ] = useState<BaseCompany | null>(null)

    const [
        selectedWorkspacesMap,
        setSelectedWorkspacesMap,
    ] = useState<IdBoolMap>({})

    const makeRequest = async (userEmailSearch?: string) => {
        setTableState({ ...tableState, isLoading: true })
        axiosInstance
            .get('company/admin-company-manage/', {
                params: {
                    user_email: userEmailSearch,
                },
            })
            .then((res) => {
                setTableState({
                    serverRes: res.data,
                    isLoading: false,
                })
            })
            .catch(() => {
                setTableState({
                    ...tableState,
                    serverRes: [],
                    isLoading: false,
                })
            })
    }

    useEffect(() => {
        if (open) {
            makeRequest()
        }
    }, [open])

    const classes = useStyles()

    const headCellStyle: React.CSSProperties = {
        fontWeight: 600,
        color: theme.palette.darkGreen.main,
        ...theme.typography.h6,
        flex: 2,
        justifyContent: 'center',
    }

    const headCells: HeadCell<RowData>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'check',
            label: '',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'id',
            label: 'ID',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'type',
            label: 'Type',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'organization',
            label: 'Organization',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'name',
            label: 'Name',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'invoice_code',
            label: 'Invoice Code',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'address',
            label: 'Address',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'actions',
            label: '',
            style: headCellStyle,
        },
    ]

    const filteredRows = useMemo(() => {
        return tableState.serverRes
            .filter((row) =>
                row.name
                    .toLocaleLowerCase()
                    .includes(searchText.toLocaleLowerCase()),
            )
            .map(mapCompanyToRow)
    }, [tableState.serverRes, searchText])

    const selectedWorkspaceIds = Object.keys(selectedWorkspacesMap).reduce<
        number[]
    >((prev, id) => {
        const numID = Number(id)
        if (selectedWorkspacesMap[numID] === true) {
            return prev.concat(numID)
        }
        return prev
    }, [])

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            disableEnforceFocus
        >
            <Slide direction="up" in={open}>
                <Paper>
                    <Container
                        style={{
                            borderRadius: theme.shape.borderRadius,
                            maxHeight: 'calc(100vh - 100px)',
                            minHeight: 'calc(100vh - 100px)',
                            maxWidth: 'calc(100vw - 100px)',
                            minWidth: 'calc(100vw - 100px)',
                            overflow: 'hidden',
                            flexDirection: 'column',
                        }}
                    >
                        {/* Header */}
                        <Container
                            style={{
                                height: MODAL_HEADER_H,
                                padding: theme.spacing(1),
                                alignItems: 'center',
                            }}
                        >
                            <SearchField
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value)
                                }}
                                style={{
                                    width: 300,
                                    marginRight: theme.spacing(2),
                                }}
                                placeholder="Search By Company Name"
                            />

                            <SearchField
                                value={userEmailSearch}
                                onChange={(e) => {
                                    setUserEmailSearch(e.target.value)
                                }}
                                style={{ width: 300 }}
                                placeholder="Search By User Email"
                            />

                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    textTransform: 'none',
                                    marginLeft: theme.spacing(2),
                                }}
                                onClick={() => {
                                    makeRequest(userEmailSearch)
                                }}
                            >
                                Apply User Search
                            </Button>

                            <Button
                                variant="contained"
                                color="secondary"
                                style={{
                                    textTransform: 'none',
                                    marginLeft: theme.spacing(2),
                                }}
                                onClick={() => {
                                    setUserEmailSearch('')
                                    makeRequest()
                                }}
                            >
                                Clear User Search
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    textTransform: 'none',
                                    marginLeft: theme.spacing(2),
                                }}
                                disabled={selectedWorkspaceIds.length === 0}
                                onClick={() => {
                                    setDialogOpen(true)
                                }}
                            >
                                Bulk Add User
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    textTransform: 'none',
                                    marginLeft: theme.spacing(2),
                                }}
                                disabled={selectedWorkspaceIds.length === 0}
                                onClick={() => {
                                    setSelectedWorkspacesMap({})
                                }}
                            >
                                Deselect Workspaces (
                                {selectedWorkspaceIds.length})
                            </Button>
                        </Container>

                        {/* Body */}
                        <Container
                            style={{
                                flex: 1,
                                padding: theme.spacing(1),
                                overflow: 'auto',
                            }}
                        >
                            <div style={{ width: '100%', overflow: 'auto' }}>
                                <SimpleTable<CompanyRow>
                                    rows={filteredRows}
                                    render={(row) => {
                                        const address = row.address.value
                                        return (
                                            <TableRow
                                                key={`BASE_COMPANY_ROW_${row.id.value}`}
                                                className={classes.hoverGrey200}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <TableCell>
                                                    <Checkbox
                                                        checked={
                                                            selectedWorkspacesMap[
                                                                row.id.value
                                                            ] === true
                                                        }
                                                        onChange={() => {
                                                            const newMap = {
                                                                ...selectedWorkspacesMap,
                                                            }
                                                            if (
                                                                selectedWorkspacesMap[
                                                                    row.id.value
                                                                ] === true
                                                            ) {
                                                                newMap[
                                                                    row.id.value
                                                                ] = false
                                                            } else {
                                                                newMap[
                                                                    row.id.value
                                                                ] = true
                                                            }
                                                            setSelectedWorkspacesMap(
                                                                newMap,
                                                            )
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {row.id.value}
                                                </TableCell>
                                                <TableCell>
                                                    {row.type.value}
                                                </TableCell>
                                                <TableCell>
                                                    {row.organization.value}
                                                </TableCell>
                                                <TableCell>
                                                    {row.name.value}
                                                </TableCell>
                                                <TableCell>
                                                    {row.invoice_code.value}
                                                </TableCell>
                                                <TableCell>
                                                    {address === null ? (
                                                        <Typography variant="subtitle2">
                                                            N/A
                                                        </Typography>
                                                    ) : (
                                                        <div>
                                                            <Typography variant="subtitle2">
                                                                {
                                                                    address?.address1
                                                                }
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                {address.city},{' '}
                                                                {address.state}{' '}
                                                                {
                                                                    address.zip_code
                                                                }
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton>
                                                        <InfoIcon
                                                            onClick={() => {
                                                                setSelectedWorkspace(
                                                                    row.company
                                                                        .value,
                                                                )
                                                            }}
                                                        />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }}
                                    orderByDefault="id"
                                    headCells={headCells}
                                />

                                {tableState.isLoading && <CircularProgress />}
                            </div>

                            {selectedWorkspace !== null && (
                                <SelectedWorkspacePanel
                                    theme={theme}
                                    workspaceDetail={selectedWorkspace}
                                    updateWorkspaceAddress={(address) => {
                                        setSelectedWorkspace({
                                            ...selectedWorkspace,
                                            primary_address: address,
                                        })
                                        setTableState({
                                            ...tableState,
                                            serverRes: tableState.serverRes.map(
                                                (row) => {
                                                    if (
                                                        row.id ===
                                                        selectedWorkspace.id
                                                    ) {
                                                        return {
                                                            ...row,
                                                            primary_address: address,
                                                        }
                                                    }
                                                    return row
                                                },
                                            ),
                                        })
                                    }}
                                    handleClose={() =>
                                        setSelectedWorkspace(null)
                                    }
                                />
                            )}
                        </Container>
                        <MyDialog
                            open={dialogOpen}
                            workspaceCount={selectedWorkspaceIds.length}
                            onClose={() => {
                                setDialogOpen(false)
                            }}
                            onConfirm={(email) => {
                                axiosInstance
                                    .post('user/_admin/', {
                                        email: email,
                                        workspaces: selectedWorkspaceIds,
                                    })
                                    .then(() => {
                                        toast.success(
                                            `${email} added to ${selectedWorkspaceIds.length} workspaces`,
                                        )
                                    })
                                    .catch((e) => {
                                        toast.error(
                                            `Error: ${e.response.data}`,
                                            { autoClose: 5000 },
                                        )
                                    })
                            }}
                        />
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

interface DialogProps {
    open: boolean
    workspaceCount: number
    onClose: () => void
    onConfirm: (email: string) => void
}

const MyDialog = (props: DialogProps) => {
    const [email, setEmail] = useState('')

    const handleClose = () => {
        setEmail('')
        props.onClose()
    }

    return (
        <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle id="alert-dialog-title">
                Add user to {props.workspaceCount} workspaces?
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Enter an email address to add
                </DialogContentText>

                <TextField
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant="outlined"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <div style={{ flex: 1 }} />
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        props.onConfirm(email)
                        handleClose()
                    }}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}

type TableState = {
    isLoading: boolean
    serverRes: CompanyWithInvoiceCode[]
}

interface CompanyRow extends RowData {
    id: CellData<number>
    organization: CellData<string>
    type: CellData<string>
    name: CellData<string>
    address: CellData<Address | null>
    company: CellData<CompanyWithInvoiceCode>
}

interface CompanyWithInvoiceCode extends BaseCompany {
    invoice_code: string | null
}
