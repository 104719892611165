import React, { useEffect, useState } from 'react'
import {
    Button,
    Chip,
    Divider,
    FormControl,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import TuneIcon from '@material-ui/icons/Tune'

import { useSelector } from 'react-redux'
import {
    AdCarousel,
    CellData,
    ChipContainer,
    ClickableChip,
    Container,
    HeadCell,
    RowData,
    SideDrawer,
    SideDrawerContainer,
    SimpleTable,
} from '../../components'
import { useAppDispatch, useUser } from '../../hooks'
import { Service, ListVendor, IdBoolMap, Bid } from '../../models'
import {
    addVendorToApt,
    getServiceList,
    getVendorList,
    RootState,
} from '../../store'
import { useStyles } from '../../styles'
import { VendorRow } from './VendorRow'
import { useMultiSelectStyles } from '../../styles'
import { axiosInstance, containsOneOf } from '../../helpers'

import zipcodes from 'zipcodes'
import { toast } from 'react-toastify'

export interface NVLRowType extends RowData {
    name: CellData<string>
    services: CellData<JSX.Element>
    contactInfo: CellData<string>
    address: CellData<string>
    addButton: CellData<JSX.Element>
    vendor: CellData<ListVendor>
    sendBidButton: CellData<JSX.Element>
}

const APARTMENT = 'APARTMENT'
const Apartment = 'Apartment'
const VENDOR = 'VENDOR'
const Vendor = 'Vendor'
const PENDING = 'PENDING'
const INCOMPLETE = 'INCOMPLETE'

const NO_MAX = -1
const FIFTY_MILE = 50
const HUNDRED_MILE = 100
const TWO_HUNDRED_FIFTY_MILE = 250
const FIVE_HUNDRED_MILE = 500

type DistanceFilter =
    | typeof NO_MAX
    | typeof FIFTY_MILE
    | typeof HUNDRED_MILE
    | typeof TWO_HUNDRED_FIFTY_MILE
    | typeof FIVE_HUNDRED_MILE

export const NationalVendorList = () => {
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const classes = useStyles()
    const { workspaceUser } = useUser()

    const [searchText, setSearchText] = useState('')
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selectedServices, setSelectedServices] = useState<number[]>([])
    const [filterDistance, setFilterDistance] = useState<DistanceFilter>(-1)
    const [selectedVendor, setSelectedVendor] = useState<
        ListVendor | undefined
    >()
    const [goodServiceList, setGoodServiceList] = useState<Service[]>([])

    const selectClasses = useMultiSelectStyles()

    const serviceList = useSelector(
        (state: RootState) => state.service.serviceList,
    )

    const vendorList = useSelector(
        (state: RootState) => state.company.vendorList,
    )

    // Tables setup
    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    const headCells: HeadCell<NVLRowType>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'name',
            label: 'Vendor',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'services',
            label: 'Services',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'contactInfo',
            label: 'Contact Info',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'address',
            label: 'Address',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'addButton',
            label: '',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'sendBidButton',
            label: '',
            style: headCellStyle,
        },
    ]

    const createVendorRow = (vendor: ListVendor): NVLRowType => {
        const serviceList = (
            <Container flexWrap="wrap" justifyContent="center">
                {vendor.services?.map((s: Service, idx: number) => {
                    return (
                        <Container
                            key={`SERVICE_${idx}`}
                            alignItems="center"
                            style={{ margin: theme.spacing(0, 1) }}
                        >
                            <div
                                className={classes.circle}
                                style={{
                                    backgroundColor: s.color,
                                    height: '12px',
                                    width: '12px',
                                    marginRight: theme.spacing(0.5),
                                }}
                            />
                            <span>{' ' + s.name}</span>
                        </Container>
                    )
                })}
            </Container>
        )
        const servicesSortValue = vendor.services ? vendor.services.length : 0

        let contactInfo = ''
        if (vendor.phone) contactInfo = `${vendor.phone} `
        if (vendor.email) contactInfo += `${vendor.email}`

        const address = `${vendor.address} ${vendor.city} ${vendor.state}, ${vendor.zip_code}`

        //a button that is only rendered if they do not work for the apartment, pressing adds them to my vendors
        const addButton = vendor.my_team ? (
            <div />
        ) : (
            <Button
                style={{ backgroundColor: theme.palette.primary.main }}
                onClick={() =>
                    dispatch(addVendorToApt({ body: { vendor: vendor.id } }))
                }
            >
                <span style={{ color: theme.palette.primary.contrastText }}>
                    Add
                </span>
            </Button>
        )

        const sendBidButton = (
            <Button
                variant="contained"
                style={{
                    backgroundColor: '#008C85',
                    color: 'white',
                    textTransform: 'none',
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                }}
                onClick={() => {
                    setSelectedVendor(vendor)
                }}
            >
                Request Bid
            </Button>
        )

        return {
            name: { value: vendor.name, sortValue: vendor.name },
            services: { value: serviceList, sortValue: servicesSortValue },
            contactInfo: { value: contactInfo, sortValue: contactInfo },
            address: { value: address, sortValue: vendor.city },
            addButton: { value: addButton, sortValue: vendor.my_team ? 1 : 0 },
            vendor: { value: vendor, sortValue: 1 },
            sendBidButton: { value: sendBidButton, sortValue: 1 },
        }
    }

    const handleServiceChange = (
        event: React.ChangeEvent<{ value: unknown }>,
    ) => {
        const selectedServices = event.target.value as number[]
        setSelectedServices(selectedServices)
    }

    const chipData: ClickableChip[] = []

    if (filterDistance > 0) {
        chipData.push({
            displayName: `${filterDistance} miles`,
            onClick: () => setFilterDistance(-1),
        })
    }

    selectedServices.map((srvId) => {
        const service = serviceList.find((srv) => srv.id === srvId)
        if (service) {
            chipData.push({
                displayName: service.name,
                onClick: () =>
                    setSelectedServices(
                        selectedServices.filter((s) => s !== service.id),
                    ),
            })
        }
    })

    return (
        <SideDrawerContainer open={drawerOpen}>
            <Container
                flex={1}
                direction="column"
                style={{ height: 'calc(100vh - 104px)' }}
            >
                {/* header */}
                <Container direction="column" style={{ minHeight: 100 }}>
                    <Container
                        style={{ marginBottom: theme.spacing(1) }}
                        alignItems="center"
                    >
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            National Vendor List
                        </span>

                        <AdCarousel
                            type={'BANNER_AD'}
                            style={{
                                maxHeight: 20,
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            imgStyle={{
                                maxHeight: 100,
                                padding: theme.spacing(0, 1, 0, 1),
                            }}
                        />
                        <AdCarousel
                            type={'BANNER_AD'}
                            style={{
                                maxHeight: 20,
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            imgStyle={{
                                maxHeight: 100,
                                padding: theme.spacing(0, 1, 0, 1),
                            }}
                        />

                        <TextField
                            value={searchText}
                            variant="outlined"
                            placeholder="Search"
                            onChange={(e) => setSearchText(e.target.value)}
                            style={{ marginRight: theme.spacing(1) }}
                        />

                        <Tooltip title="Filter">
                            <IconButton
                                onClick={() => setDrawerOpen(!drawerOpen)}
                                color={drawerOpen ? 'primary' : 'default'}
                            >
                                <TuneIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Container>

                    <ChipContainer data={chipData} />
                </Container>
                {/* Table */}
                <SimpleTable<NVLRowType>
                    filter={(row) => {
                        // Search bar filter
                        const textValid = row.name.value
                            .toLocaleLowerCase()
                            .includes(searchText.toLocaleLowerCase())

                        // Service Filter
                        const serviceValid =
                            selectedServices.length === 0 ||
                            containsOneOf(
                                row.vendor.value.services,
                                selectedServices,
                                (a, b) => a.id === b,
                            )

                        // Radius Filter
                        if (filterDistance !== NO_MAX) {
                            // if the vendor does not have a zipcode set, it is invalid
                            const vendorZip = row.vendor.value.zip_code
                            const userZip =
                                workspaceUser?.active_workspace.zip_code
                            if (vendorZip === null || vendorZip === undefined) {
                                // User is filtering by zip code and this vendor does not have one set.
                                // Do not display it
                                return false
                            }

                            if (userZip === undefined) {
                                // user workspace zipcode is not set.
                                return false
                            }

                            const dist = zipcodes.distance(userZip, vendorZip)
                            if (dist === null || dist > filterDistance) {
                                // Either an invalid zipcode was encountered, or the vendor is to far for the selected
                                // radius
                                return false
                            }
                        }

                        return textValid && serviceValid
                    }}
                    rows={vendorList.map((vendor: ListVendor) =>
                        createVendorRow(vendor),
                    )}
                    render={(row, index) =>
                        VendorRow(
                            {
                                row: row,
                                theme: theme,
                                hoverGrey300: classes.hoverGrey300,
                            },
                            index,
                        )
                    }
                    orderByDefault="name"
                    headCells={headCells}
                />
            </Container>

            <SideDrawer
                open={drawerOpen}
                handleClose={() => setDrawerOpen(false)}
                title="Filter"
            >
                <FormControl style={{ margin: theme.spacing(1) }}>
                    <InputLabel id="service-chip-label">Services</InputLabel>
                    <Select
                        labelId="service-chip-label"
                        id="mutiple-chip-service"
                        multiple
                        placeholder="Service"
                        value={selectedServices}
                        onChange={handleServiceChange}
                        MenuProps={{
                            getContentAnchorEl: () => {
                                return (null as unknown) as Element
                            },
                        }}
                        input={<Input id="select-multiple-chip-service" />}
                        renderValue={(selected) => (
                            <div className={selectClasses.chips}>
                                {(selected as number[]).map((value) => {
                                    const srv = serviceList.find(
                                        (s) => s.id === value,
                                    )
                                    return (
                                        <Chip
                                            key={`SERVICE_CHIP_${
                                                srv ? srv.id : -1
                                            }`}
                                            label={srv ? srv.name : 'unknown'}
                                            className={selectClasses.chip}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    >
                        {serviceList.map((srv) => (
                            <MenuItem
                                key={`SERVICE_OPT_${srv.id}}`}
                                value={srv.id}
                            >
                                {srv.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl style={{ margin: theme.spacing(1) }}>
                    <InputLabel id="radius-label">Radius</InputLabel>
                    <Select
                        value={filterDistance}
                        onChange={(e) => {
                            // if the users apartment workspace does not have a zip code set, warn the user
                            if (
                                workspaceUser?.active_workspace.zip_code ===
                                undefined
                            ) {
                                toast.warning(
                                    'Your workspace does not have a zipcode saved.  Please contact your support representative to set one.',
                                )
                            }

                            setFilterDistance(e.target.value as DistanceFilter)
                        }}
                    >
                        <MenuItem value={NO_MAX}>None</MenuItem>
                        <MenuItem value={FIFTY_MILE}>50 miles</MenuItem>
                        <MenuItem value={HUNDRED_MILE}>100 miles</MenuItem>
                        <MenuItem value={TWO_HUNDRED_FIFTY_MILE}>
                            250 miles
                        </MenuItem>
                        <MenuItem value={FIVE_HUNDRED_MILE}>500 miles</MenuItem>
                    </Select>
                </FormControl>

                <div style={{ flex: 1 }} />
                {/* Footer */}
                <Divider />
                <Container
                    style={{
                        padding: theme.spacing(2),
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{ marginRight: theme.spacing(2) }}
                        onClick={() => {
                            setSelectedServices([])
                            setFilterDistance(NO_MAX)
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setDrawerOpen(false)}
                    >
                        Close
                    </Button>
                </Container>
            </SideDrawer>
        </SideDrawerContainer>
    )
}
