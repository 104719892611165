import React, { useState, useEffect } from 'react'
import {
    Button,
    Divider,
    FormControlLabel,
    Switch,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { Container, Selector, SideDrawer } from '../../components'
import { Inspection, InspectionType, Schedule } from '../../models'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useAppDispatch } from '../../hooks'
import {
    createInspection,
    CreateInspectionAxiosReq,
    GetScheduleListActionThunk,
    GetScheduleListRequest,
    patchInspection,
    UpdateInspectionAxiosReq,
} from '../../store'
import { toast } from 'react-toastify'
import InfoIcon from '@material-ui/icons/Info'

interface Props {
    open: boolean
    selectedInspection?: Inspection
    inspectionTypeList: InspectionType[]
    scheduleList: Schedule[]
    getScheduleList: (req: GetScheduleListRequest) => GetScheduleListActionThunk
    handleClose: () => void
    modalMode?: boolean
}

export const InspectionFormDrawer = (props: Props) => {
    const {
        open,
        selectedInspection,
        handleClose,
        inspectionTypeList,
        scheduleList,
        getScheduleList,
    } = props

    return (
        <SideDrawer
            open={open}
            handleClose={handleClose}
            title={
                selectedInspection === undefined
                    ? 'Create Inspection'
                    : 'Update Inspection'
            }
        >
            <InspectionForm
                open={open}
                selectedInspection={selectedInspection}
                handleClose={handleClose}
                inspectionTypeList={inspectionTypeList}
                scheduleList={scheduleList}
                getScheduleList={getScheduleList}
            />
        </SideDrawer>
    )
}

export const InspectionForm = (props: Props) => {
    const {
        open,
        selectedInspection,
        handleClose,
        inspectionTypeList,
        scheduleList,
        getScheduleList,
        modalMode,
    } = props

    const theme = useTheme()

    const UNDEFNIED_SCHEDULE_ID = -1
    const NEW_SCHEDULE_ID = 0

    const [doValidate, setDoValidate] = useState(false)
    const [name, setName] = useState('')
    const [startDate, setStartDate] = useState<Date | null>(new Date())
    const [endDate, setEndDate] = useState<Date | null>(new Date())
    const [inspectionTypeId, setInspectionTypeId] = useState(-1)
    const [scheduleId, setScheduleId] = useState(UNDEFNIED_SCHEDULE_ID)
    const [userFriendlyScheduleId, setUserFriendlyScheduleId] = useState('')
    const [scheduleOptions, setScheduleOptions] = useState(scheduleList)
    const [active, setActive] = useState(true)

    const dispatch = useAppDispatch()

    const inputStyle: React.CSSProperties = {
        marginBottom: theme.spacing(4),
    }

    const EDIT_MODE = selectedInspection !== undefined

    useEffect(() => {
        if (selectedInspection) {
            setName(selectedInspection.name)
            setStartDate(new Date(selectedInspection.start_date))
            setEndDate(new Date(selectedInspection.end_date))
            if (selectedInspection.schedule) {
                setScheduleId(selectedInspection.schedule.id)
            } else {
                setScheduleId(-1)
            }
            setActive(selectedInspection.active)
        } else {
            setName('')
            setStartDate(new Date())
            setEndDate(new Date())
            setScheduleId(-1)
            setActive(true)
        }
        setUserFriendlyScheduleId('')
        setDoValidate(false)
    }, [selectedInspection])

    useEffect(() => {
        const newScheduleOptions: Schedule[] = [...scheduleList]
        // add new schedule to schedule options
        newScheduleOptions.push({
            id: NEW_SCHEDULE_ID,
            name: 'New Schedule',
            schedule_id: '',
            apartment: -1,
            start_date: '',
            end_date: '',
            active: false,
            baseline_budget: 0,
            assigned_count: 0,
            complete_count: 0,
            approved_count: 0,
            invoiced_count: 0,
            inspection_set: [],
            schedule_budget_items: [],
            color: theme.palette.primary.main,
            channel: null,
        })
        // if were in edit mode and the inspection has a schedule, add that one to schedule options so we can display its info
        if (selectedInspection?.schedule) {
            newScheduleOptions.push(selectedInspection.schedule)
        }
        setScheduleOptions(newScheduleOptions)
    }, [scheduleList, selectedInspection])

    const handleCreate = () => {
        if (
            name === '' ||
            startDate === null ||
            endDate === null ||
            inspectionTypeId === -1
        ) {
            setDoValidate(true)
        } else {
            const req: CreateInspectionAxiosReq = {
                body: {
                    name: name,
                    start_date: startDate.toISOString(),
                    end_date: endDate.toISOString(),
                    inspection_type: inspectionTypeId,
                },
            }

            if (
                scheduleId !== UNDEFNIED_SCHEDULE_ID &&
                scheduleId !== NEW_SCHEDULE_ID
            ) {
                req.body.schedule = scheduleId
            }

            if (scheduleId === NEW_SCHEDULE_ID) {
                req.body.new_schedule = {
                    schedule_id: Math.floor(
                        Math.random() * 10000000,
                    ).toString(),
                }
            }

            dispatch(createInspection(req)).then((res) => {
                toast.success(`${res.data.name} created!`)
                handleClose()
                // a schedule may have been added to a one to one relationship, so update our schedule list
                getScheduleList({ params: { no_inspection: true } })
            })
        }
    }

    const handleUpdate = (id: number) => {
        if (name === '' || startDate === null || endDate === null) {
            setDoValidate(true)
        } else {
            const req: UpdateInspectionAxiosReq = {
                id: id,
                body: {
                    name: name,
                    start_date: startDate.toISOString(),
                    end_date: endDate.toISOString(),
                    active: active,
                },
            }
            if (scheduleId !== NEW_SCHEDULE_ID) {
                req.body.schedule = scheduleId
            }

            if (scheduleId === NEW_SCHEDULE_ID) {
                if (
                    userFriendlyScheduleId == '' ||
                    userFriendlyScheduleId.length > 8
                ) {
                    setDoValidate(true)
                    return
                }
                req.body.new_schedule = { schedule_id: userFriendlyScheduleId }
            }

            dispatch(patchInspection(req)).then(() => {
                toast.success('Success!')
                handleClose()
                // a schedule may have been added to a one to one relationship, so update our schedule list
                getScheduleList({ params: { no_inspection: true } })
            })
        }
    }

    const SubHeaderCellStyle: React.CSSProperties = {
        fontWeight: 500,
        fontSize: '15px',
        marginBottom: theme.spacing(2),
    }

    return (
        <Container
            direction="column"
            flex={1}
            style={{ margin: !modalMode ? theme.spacing(0, 2, 0, 2) : '' }}
        >
            <Container direction="column" flex={1} scrollY>
                <Container style={{ ...SubHeaderCellStyle }}>Name:</Container>

                <TextField
                    error={doValidate && name === ''}
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={inputStyle}
                    size="small"
                />

                <Selector
                    size="small"
                    label={'Inspection Type'}
                    error={doValidate && inspectionTypeId === -1}
                    currentValue={inspectionTypeId}
                    customStyle={{
                        formControl: {
                            margin: 0,
                            ...inputStyle,
                        },
                    }}
                    disabled={EDIT_MODE}
                    onChange={(
                        event: React.ChangeEvent<{
                            value: unknown
                        }>,
                    ) => {
                        setInspectionTypeId(event.target.value as number)
                    }}
                    data={inspectionTypeList}
                    getDisplayString={(s) => s.name}
                />
                <Container>
                    <Selector
                        size="small"
                        label={'Schedule'}
                        currentValue={scheduleId}
                        customStyle={{
                            formControl: {
                                margin: 0,
                                width: 300,
                                ...inputStyle,
                            },
                        }}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            setScheduleId(event.target.value as number)
                        }}
                        data={[
                            { id: UNDEFNIED_SCHEDULE_ID, name: 'None' },
                            ...scheduleOptions,
                        ]}
                        getDisplayString={(s) => s.name}
                    />
                    <Container
                        style={{
                            marginTop: theme.spacing(2),
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        <Tooltip title="Only select a schedule when wanting to walk vacant units, otherwise select None">
                            <InfoIcon fontSize="small" color="action" />
                        </Tooltip>
                    </Container>
                </Container>
                {scheduleId === NEW_SCHEDULE_ID && (
                    <TextField
                        error={
                            doValidate &&
                            (userFriendlyScheduleId === '' ||
                                userFriendlyScheduleId.length > 8)
                        }
                        variant="outlined"
                        value={userFriendlyScheduleId}
                        onChange={(e) =>
                            setUserFriendlyScheduleId(e.target.value)
                        }
                        label="Schedule ID (Required)"
                        style={inputStyle}
                        size="small"
                    />
                )}
                <KeyboardDatePicker
                    error={startDate === null}
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    format="MM/dd/yyyy"
                    inputVariant="outlined"
                    label="Start Date"
                    style={inputStyle}
                    size="small"
                />
                <KeyboardDatePicker
                    error={endDate === null}
                    value={endDate}
                    onChange={(date) => setEndDate(date)}
                    format="MM/dd/yyyy"
                    inputVariant="outlined"
                    label="End Date"
                    style={inputStyle}
                    size="small"
                />

                {selectedInspection && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={!active}
                                color="primary"
                                onClick={() => setActive(!active)}
                            />
                        }
                        labelPlacement="start"
                        label="Archived"
                        style={{ marginRight: theme.spacing(2) }}
                    />
                )}
            </Container>

            {/* Footer */}
            {modalMode ? (
                <Container
                    justifyContent={'flex-end'}
                    style={{
                        marginTop: theme.spacing(1),
                    }}
                >
                    <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        style={{
                            textTransform: 'none',
                            margin: theme.spacing(1, 0, 1, 2),
                        }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(1, 0, 1, 2),
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            if (selectedInspection) {
                                handleUpdate(selectedInspection.id)
                            } else {
                                handleCreate()
                            }
                        }}
                    >
                        Confirm
                    </Button>
                </Container>
            ) : (
                <>
                    <Divider />
                    <Container
                        style={{
                            padding: theme.spacing(2),
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            color="secondary"
                            variant="outlined"
                            style={{ marginRight: theme.spacing(2) }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                                if (selectedInspection) {
                                    handleUpdate(selectedInspection.id)
                                } else {
                                    handleCreate()
                                }
                            }}
                        >
                            {selectedInspection === undefined
                                ? 'Create'
                                : 'Save'}
                        </Button>
                    </Container>
                </>
            )}
        </Container>
    )
}
