import {
    Button,
    Grid,
    Modal,
    Paper,
    Slide,
    TableSortLabel,
    Theme,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from '../../components'
import { Company, VideoAdvertisement } from '../../models'
import { Pagination } from '@material-ui/lab'
import { toMMDDYYYY } from '../../helpers'
import { CreateVideoAdModal } from './CreateVideoAdModal'
import { useStyles } from '../../styles'
import { Check } from '@material-ui/icons'

interface Props {
    open: boolean
    videoAdvertisementList: VideoAdvertisement[]
    selectedVideoAd: VideoAdvertisement | null
    apartmentList: Company[]
    setSelectedVideoAd: React.Dispatch<
        React.SetStateAction<VideoAdvertisement | null>
    >
    createVideoAdvertisement: (request: FormData) => Promise<undefined>
    updateVideoAd: (request: FormData, id: number) => Promise<undefined>
    handleClose: () => void
}

export const VideoAdvertisementModal = (props: Props) => {
    const {
        open,
        videoAdvertisementList,
        selectedVideoAd,
        apartmentList,
        handleClose,
        createVideoAdvertisement,
        setSelectedVideoAd,
        updateVideoAd,
    } = props

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [orderBy, setOrderBy] = useState('')
    const [sortedRows, setSortedRows] = useState<VideoAdvertisement[]>(
        videoAdvertisementList,
    )

    const [openCreateModal, setOpenCreateModal] = useState(false)

    useEffect(() => {
        setSortedRows(videoAdvertisementList)
    }, [videoAdvertisementList])

    const theme = useTheme()

    const headCellStyle: React.CSSProperties = {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.darkGreen.main,
        ...theme.typography.h6,
        flex: 2,
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
    }

    const cellStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        fontWeight: theme.typography.fontWeightMedium,
        alignItems: 'flex-start',
        paddingLeft: theme.spacing(2),
        justifyContent: 'center',
    }

    const NameASC = 'NameASC'
    const NameDESC = 'NameDESC'
    const VideoNameASC = 'VideoNameASC'
    const VideoNameDESC = 'VideoNameDESC'
    const ExpirationDateASC = 'ExpirationDateASC'
    const ExpirationDateDESC = 'ExpirationDateDESC'
    const CreationDateASC = 'CreationDateASC'
    const CreationDateDESC = 'CreationDateDESC'

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper>
                    <Container
                        style={{
                            width: 1500,
                            maxWidth: 'calc(100vw - 100px)',
                            height: 1100,
                            maxHeight: 'calc(100vh - 100px)',
                            display: 'flex',
                            overflow: 'hidden',
                            flexDirection: 'column',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            padding: theme.spacing(2),
                        }}
                    >
                        <Container>
                            <Button
                                onClick={() => {
                                    setOpenCreateModal(true)
                                }}
                                style={{
                                    backgroundColor: theme.palette.primary.dark,
                                    color: 'white',
                                    textTransform: 'none',
                                }}
                            >
                                + Add Video Advertisement
                            </Button>
                        </Container>
                        <Container
                            scrollY
                            style={{
                                flex: 1,
                                flexDirection: 'column',
                                padding: theme.spacing(2, 0, 1, 0),
                            }}
                        >
                            <Container>
                                <Container
                                    style={{ ...headCellStyle }}
                                    onClick={() => {
                                        const newRows = [...sortedRows]
                                        if (orderBy !== NameASC) {
                                            newRows.sort((RowA, RowB) =>
                                                RowA.name.localeCompare(
                                                    RowB.name,
                                                ),
                                            )
                                            setSortedRows(newRows)
                                            setOrderBy(NameASC)
                                        } else {
                                            setSortedRows(newRows.reverse())
                                            setOrderBy(NameDESC)
                                        }
                                    }}
                                >
                                    <TableSortLabel
                                        active={
                                            orderBy === NameASC ||
                                            orderBy === NameDESC
                                        }
                                        direction={
                                            orderBy === NameASC ? 'asc' : 'desc'
                                        }
                                    >
                                        Name
                                    </TableSortLabel>
                                </Container>

                                <Container
                                    style={{ ...headCellStyle }}
                                    onClick={() => {
                                        const newRows = [...sortedRows]
                                        if (orderBy !== VideoNameASC) {
                                            newRows.sort((RowA, RowB) =>
                                                RowA.video_name.localeCompare(
                                                    RowB.video_name,
                                                ),
                                            )
                                            setSortedRows(newRows)
                                            setOrderBy(VideoNameASC)
                                        } else {
                                            setSortedRows(newRows.reverse())
                                            setOrderBy(VideoNameDESC)
                                        }
                                    }}
                                >
                                    <TableSortLabel
                                        active={
                                            orderBy === VideoNameASC ||
                                            orderBy === VideoNameDESC
                                        }
                                        direction={
                                            orderBy === VideoNameASC
                                                ? 'asc'
                                                : 'desc'
                                        }
                                    >
                                        File Name
                                    </TableSortLabel>
                                </Container>

                                <Container
                                    style={{ ...headCellStyle }}
                                    onClick={() => {
                                        const newRows = [...sortedRows]
                                        if (orderBy !== ExpirationDateASC) {
                                            newRows.sort((RowA, RowB) =>
                                                RowA.expiration_date
                                                    .toString()
                                                    .localeCompare(
                                                        RowB.expiration_date.toString(),
                                                    ),
                                            )
                                            setSortedRows(newRows)
                                            setOrderBy(ExpirationDateASC)
                                        } else {
                                            setSortedRows(newRows.reverse())
                                            setOrderBy(ExpirationDateDESC)
                                        }
                                    }}
                                >
                                    <TableSortLabel
                                        active={
                                            orderBy === ExpirationDateASC ||
                                            orderBy === ExpirationDateDESC
                                        }
                                        direction={
                                            orderBy === ExpirationDateASC
                                                ? 'asc'
                                                : 'desc'
                                        }
                                    >
                                        Expiration Date
                                    </TableSortLabel>
                                </Container>
                                <Container
                                    style={{ ...headCellStyle }}
                                    onClick={() => {
                                        const newRows = [...sortedRows]
                                        if (orderBy !== CreationDateASC) {
                                            newRows.sort((RowA, RowB) =>
                                                RowA.created_date.localeCompare(
                                                    RowB.created_date,
                                                ),
                                            )
                                            setSortedRows(newRows)
                                            setOrderBy(CreationDateASC)
                                        } else {
                                            setSortedRows(newRows.reverse())
                                            setOrderBy(CreationDateDESC)
                                        }
                                    }}
                                >
                                    <TableSortLabel
                                        active={
                                            orderBy === CreationDateASC ||
                                            orderBy === CreationDateDESC
                                        }
                                        direction={
                                            orderBy === CreationDateASC
                                                ? 'asc'
                                                : 'desc'
                                        }
                                    >
                                        Created Date
                                    </TableSortLabel>
                                </Container>
                                <Container
                                    style={{ ...headCellStyle }}
                                    onClick={() => {}}
                                >
                                    Company Count
                                </Container>
                                <Container
                                    style={{ ...headCellStyle }}
                                    onClick={() => {}}
                                >
                                    Active
                                </Container>
                            </Container>

                            <Container
                                style={{
                                    overflowY: 'auto',
                                    maxHeight: 'calc(100vh - 300px)',
                                    minHeight: 'calc(100vh - 300px)',
                                    flexDirection: 'column',
                                }}
                            >
                                {sortedRows
                                    .map((row, index) => {
                                        return (
                                            <VideoAdvertisementRow
                                                theme={theme}
                                                videoAdvertisement={row}
                                                cellStyle={cellStyle}
                                                key={`VIDEO_ADVERTISEMENT_${row.id}`}
                                                onClick={() => {
                                                    setSelectedVideoAd(row)
                                                    setOpenCreateModal(true)
                                                }}
                                            />
                                        )
                                    })
                                    .slice(
                                        (page - 1) * rowsPerPage,
                                        (page - 1) * rowsPerPage + rowsPerPage,
                                    )}
                            </Container>
                            <Container
                                style={{
                                    flex: 1,
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Pagination
                                    count={Math.ceil(
                                        sortedRows.length / rowsPerPage,
                                    )}
                                    page={page}
                                    onChange={(_: unknown, newPage: number) => {
                                        setPage(newPage)
                                    }}
                                    variant="outlined"
                                    shape="rounded"
                                    style={{
                                        margin: theme.spacing(1, 0, 0, 0),
                                    }}
                                />
                            </Container>
                        </Container>
                    </Container>
                    <CreateVideoAdModal
                        open={openCreateModal}
                        selectedVideoAd={selectedVideoAd}
                        apartmentList={apartmentList}
                        handleClose={() => {
                            setOpenCreateModal(false)
                            setSelectedVideoAd(null)
                        }}
                        createVideoAdvertisement={createVideoAdvertisement}
                        updateVideoAd={updateVideoAd}
                    />
                </Paper>
            </Slide>
        </Modal>
    )
}

interface VideoAdvertisementRowProps {
    videoAdvertisement: VideoAdvertisement
    theme: Theme
    cellStyle: React.CSSProperties
    onClick: () => void
}

const VideoAdvertisementRow = (props: VideoAdvertisementRowProps) => {
    const { videoAdvertisement, theme, cellStyle, onClick } = props

    const classes = useStyles()

    return (
        <Container
            style={{
                cursor: 'pointer',
                margin: theme.spacing(1, 0, 1, 0),
                border: '1px solid',
                borderColor: theme.palette.grey[400],
                borderRadius: theme.shape.borderRadius,
                flexDirection: 'row',
                height: 75,
            }}
            onClick={onClick}
            className={classes.hoverGrey300}
        >
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                }}
                direction="column"
            >
                {videoAdvertisement.name}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                }}
                direction="column"
            >
                {videoAdvertisement.video_name}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                }}
                direction="column"
            >
                {toMMDDYYYY(new Date(videoAdvertisement.expiration_date))}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                }}
                direction="column"
            >
                {toMMDDYYYY(new Date(videoAdvertisement.created_date))}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                }}
                direction="column"
            >
                {videoAdvertisement.companies.length === 0
                    ? 'All'
                    : videoAdvertisement.companies.length}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                }}
                direction="column"
            >
                {videoAdvertisement.active && <Check />}
            </Container>
        </Container>
    )
}
