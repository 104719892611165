import React from 'react'

import { Container } from '../Container'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useTheme } from '@mui/material'

interface CustomStyle {
    container?: React.CSSProperties
    startDatePicker?: React.CSSProperties
    endDatePicker?: React.CSSProperties
}

interface Props {
    customStyle?: CustomStyle
    startDate?: Date
    endDate?: Date
    disabled?: boolean
    onChangeStartDate: (newDate: Date) => void
    onChangeEndDate: (newDate: Date) => void
}

export const TimelinePicker = (props: Props) => {
    const {
        startDate,
        endDate,
        onChangeStartDate,
        onChangeEndDate,
        customStyle,
        disabled,
    } = props

    const theme = useTheme()

    return (
        <Container
            style={{
                border: `1px solid ${theme.palette.grey[500]}`,
                borderRadius: theme.shape.borderRadius,
                ...customStyle?.container,
            }}
        >
            <KeyboardDatePicker
                disabled={disabled}
                format="MM/dd/yyyy"
                InputProps={{
                    disableUnderline: true,
                }}
                variant="inline"
                value={startDate ?? null}
                style={{
                    margin: theme.spacing(1),
                    width: '100%',
                }}
                label="Start"
                onChange={(date) => onChangeStartDate(date ?? new Date())}
            />

            <div
                style={{ borderRight: `1px solid ${theme.palette.grey[500]}` }}
            />

            <KeyboardDatePicker
                disabled={disabled}
                format="MM/dd/yyyy"
                InputProps={{ disableUnderline: true }}
                style={{
                    margin: theme.spacing(1),
                    width: '100%',
                }}
                variant="inline"
                value={endDate ?? null}
                label="End"
                onChange={(date) => onChangeEndDate(date ?? new Date())}
            />
        </Container>
    )
}
