import React from 'react'
import { Button, useTheme } from '@material-ui/core'
import { AvatarCircle, Container } from '../../components'
import { Blanket, BlanketRect } from '../../components/Blanket'

import { axiosInstance, getInitials } from '../../helpers'
import NumberFormat from 'react-number-format'
import { useAptConfig } from '../../hooks'
import { CustomStatus, ModelMap } from '../../models'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useStyles } from '../../styles'
import { InvInsAnalyticsBlanketData } from './InspectionDetail'
import FileDownload from 'js-file-download'
import { GetApp } from '@material-ui/icons'

interface Props {
    open: boolean
    rect: BlanketRect
    setInvConfAnalyticsBlanketData: (
        invConfAnalyticsBlanketData: InvInsAnalyticsBlanketData,
    ) => void
    setInvConfAnalyticsBlanketOpen: (open: boolean) => void
}

export interface StatusAnalytic {
    id: number
    status: CustomStatus
    cost: number
    total: number
}

export interface InvConfigAnalytic {
    name: string
    total: number
    cost: number
    statusDict: ModelMap<StatusAnalytic>
}

export const AnalyticsBlanket = (props: Props) => {
    const {
        open,
        rect,
        setInvConfAnalyticsBlanketData,
        setInvConfAnalyticsBlanketOpen,
    } = props
    const theme = useTheme()

    const classes = useStyles()

    const statusStyle: React.CSSProperties = {
        cursor: 'pointer',
        borderRadius: theme.shape.borderRadius,
    }

    const { getInventoryConfigMap } = useAptConfig({})
    const inventoryConfigMap = getInventoryConfigMap()

    const inspectionAnalytics = useSelector(
        (state: RootState) => state.inspection.inspectionAnalytics,
    )

    const inspection = useSelector((state: RootState) => state.inspection)

    const inventoryConfigAnalyticComponents: JSX.Element[] = []

    const inventoryConfigIDs: number[] = []

    if (inspectionAnalytics) {
        Object.keys(inspectionAnalytics.inventory_config_analytics).map(
            (invConfIdStr) => {
                if (invConfIdStr === 'null') {
                    return
                }
                const invConfId = Number(invConfIdStr)
                const invConfig = inventoryConfigMap[invConfId]
                inventoryConfigIDs.push(invConfId)
                let cost = 0
                let count = 0
                const statusAnalytics = Object.keys(
                    inspectionAnalytics.inventory_config_analytics[
                        invConfIdStr
                    ],
                ).map((statusId) => {
                    const statusAnalytic =
                        inspectionAnalytics.inventory_config_analytics[
                            invConfIdStr
                        ][statusId]
                    cost +=
                        inspectionAnalytics.inventory_config_analytics[
                            invConfIdStr
                        ][statusId]['cost'] ?? 0
                    count +=
                        inspectionAnalytics.inventory_config_analytics[
                            invConfIdStr
                        ][statusId]['count']
                    if (statusId === 'null') {
                        return
                    }
                    return (
                        <Container
                            key={`STATUS_ANALYTIC_${invConfIdStr}_${statusId}`}
                            style={{
                                flexDirection: 'column',
                                marginTop: theme.spacing(1),
                                ...statusStyle,
                            }}
                            onClick={() => {
                                const invConfAnalyticsBlanketData: InvInsAnalyticsBlanketData = {
                                    status: statusAnalytic.name,
                                    inventoryConfig: invConfig,
                                    statusCount: statusAnalytic.count,
                                    sumPrice: statusAnalytic.cost ?? 0,
                                    statusId: statusId,
                                }

                                setInvConfAnalyticsBlanketData(
                                    invConfAnalyticsBlanketData,
                                )
                                setInvConfAnalyticsBlanketOpen(true)
                            }}
                            className={classes.hoverGrey300}
                        >
                            <Container style={{ alignItems: 'center' }}>
                                <span
                                    style={{
                                        ...theme.typography.subtitle1,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    {statusAnalytic.name}:
                                </span>

                                <NumberFormat
                                    thousandSeparator
                                    value={statusAnalytic.count}
                                    displayType="text"
                                    style={{
                                        ...theme.typography.subtitle1,
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                        marginLeft: theme.spacing(1),
                                    }}
                                />
                            </Container>
                            <NumberFormat
                                prefix="$"
                                thousandSeparator
                                value={statusAnalytic.cost}
                                displayType="text"
                                style={{
                                    ...theme.typography.subtitle2,
                                    fontWeight:
                                        theme.typography.fontWeightMedium,
                                }}
                            />
                        </Container>
                    )
                })

                inventoryConfigAnalyticComponents.push(
                    <Container
                        key={`BLANKET_ANALYTIC_${invConfIdStr}`}
                        style={{
                            backgroundColor: theme.palette.grey[200],
                            borderRadius: theme.shape.borderRadius,
                            border: `1px solid ${theme.palette.grey[400]}`,
                            margin: theme.spacing(2),
                            padding: theme.spacing(2),
                            flexDirection: 'column',
                            flexShrink: 1,
                        }}
                    >
                        <Container style={{ alignItems: 'center' }}>
                            <Container
                                style={{
                                    flexDirection: 'column',
                                    marginRight: theme.spacing(2),
                                }}
                            >
                                <span
                                    style={{
                                        ...theme.typography.subtitle1,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    {invConfig?.name}
                                </span>
                                <span
                                    style={{
                                        ...theme.typography.subtitle2,
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                    }}
                                >
                                    {invConfig?.sku}
                                </span>
                            </Container>
                            <AvatarCircle
                                image={invConfig?.picture}
                                text={getInitials(invConfig?.name)}
                            />
                        </Container>

                        <Container
                            style={{
                                flexDirection: 'column',
                                marginTop: theme.spacing(2),
                            }}
                        >
                            <Container
                                style={{ alignItems: 'center', ...statusStyle }}
                                onClick={() => {
                                    const invConfAnalyticsBlanketData: InvInsAnalyticsBlanketData = {
                                        status: 'Total',
                                        inventoryConfig: invConfig,
                                        statusCount: count,
                                        sumPrice: cost,
                                        statusId: undefined,
                                    }
                                    setInvConfAnalyticsBlanketData(
                                        invConfAnalyticsBlanketData,
                                    )
                                    setInvConfAnalyticsBlanketOpen(true)
                                }}
                                className={classes.hoverGrey300}
                            >
                                <span
                                    style={{
                                        ...theme.typography.subtitle1,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    Total:
                                </span>

                                <NumberFormat
                                    thousandSeparator
                                    value={count}
                                    displayType="text"
                                    style={{
                                        ...theme.typography.subtitle1,
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                        marginLeft: theme.spacing(1),
                                    }}
                                />
                            </Container>
                            <NumberFormat
                                prefix="$"
                                thousandSeparator
                                value={cost}
                                displayType="text"
                                style={{
                                    ...theme.typography.subtitle2,
                                    fontWeight:
                                        theme.typography.fontWeightMedium,
                                }}
                            />

                            {statusAnalytics}
                        </Container>
                    </Container>,
                )
            },
        )
    }

    return (
        <Blanket open={open} rect={rect}>
            <Container
                style={{
                    backgroundColor: theme.palette.grey[100],
                    flex: 1,
                    flexDirection: 'column',
                }}
                scrollY
            >
                <Container style={{ justifyContent: 'flex-end' }}>
                    <Button
                        onClick={() => {
                            const invConfigList = inventoryConfigIDs.join(',')
                            axiosInstance
                                .get(
                                    'inspection/inventory-inspection/get_analytics_list/',
                                    {
                                        params: {
                                            inspection:
                                                inspection.inspectionDetail?.id,
                                            inventory_configs: invConfigList,
                                            excel: true,
                                        },
                                        responseType: 'blob',
                                    },
                                )
                                .then((res) => {
                                    FileDownload(
                                        new Blob([res.data]),
                                        'inventoryInspectionList.xlsx',
                                    )
                                })
                        }}
                        style={{
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            padding: theme.spacing(1),
                            margin: theme.spacing(1, 2, 1, 0),
                        }}
                    >
                        Full Report <GetApp fontSize="small" />
                    </Button>
                </Container>
                {open && (
                    <Container style={{ flexWrap: 'wrap' }}>
                        {inventoryConfigAnalyticComponents}
                    </Container>
                )}
            </Container>
        </Blanket>
    )
}
