import {
    IconButton,
    Modal,
    Paper,
    Slide,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from '../../../../components'
import { Area, ModelMap, Unit, traverse } from '../../../../models'
import CloseIcon from '@material-ui/icons/Close'
import { useAptConfig, useRootInfrastructure } from '../../../../hooks'

interface Props {
    handleClose: () => void
    open: boolean
    vacantAreas: Area[]
}

export const VacantUnitsModal = (props: Props) => {
    const { handleClose, open, vacantAreas } = props

    const [unitMap, setUnitMap] = useState<ModelMap<Unit>>({})

    const { tree } = useRootInfrastructure(true)

    const theme = useTheme()

    const { getUnitConfigMap } = useAptConfig({
        changeOrderConfigList: true,
        unitConfigList: true,
        areaConfigList: true,
        areaStatusConfigList: true,
        cleanUp: true,
    })

    // Build required Model maps
    const unitConfigMap = getUnitConfigMap()

    useEffect(() => {
        const newUnitMap: ModelMap<Unit> = {}

        traverse(tree.root, (folder) => {
            folder.units.forEach((u) => {
                newUnitMap[u.id] = u
            })
        })
        setUnitMap(newUnitMap)
    }, [tree.version])

    const HeadCellStyle: React.CSSProperties = {
        fontWeight: 600,
        color: theme.palette.darkGreen.main,
        fontSize: '17px',
        flex: 1,
    }

    const RowCellStyle: React.CSSProperties = {
        fontWeight: 400,
        fontSize: '15px',
        flex: 1,
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: '600px',
                        height: '650px',
                        maxHeight: 'calc(100vh - 100px)',
                        maxWidth: 'calc(100vh - 100px)',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <Container
                        style={{ padding: theme.spacing(1) }}
                        direction="column"
                    >
                        <Container alignItems="center">
                            <Container
                                style={{
                                    fontWeight: 600,
                                    fontSize: '20px',
                                    margin: theme.spacing(1, 0, 2, 0),
                                    flex: 1,
                                }}
                            >
                                Vacant Areas
                            </Container>
                            <Tooltip title="Close">
                                <IconButton
                                    onClick={() => {
                                        handleClose()
                                    }}
                                >
                                    <CloseIcon
                                        fontSize="inherit"
                                        style={{
                                            color: theme.palette.darkGreen.main,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Container>
                        <Container>
                            <Container style={{ ...HeadCellStyle }}>
                                Unit
                            </Container>
                            <Container style={{ ...HeadCellStyle }}>
                                Folder
                            </Container>
                            <Container style={{ ...HeadCellStyle }}>
                                Config
                            </Container>
                            <Container style={{ ...HeadCellStyle }}>
                                Area
                            </Container>
                        </Container>
                        <Container
                            flex={1}
                            direction="column"
                            style={{
                                overflowY: 'auto',
                                maxHeight: 'calc(60vh)',
                            }}
                        >
                            {vacantAreas
                                .sort((a, b) => {
                                    const nameA =
                                        unitMap[a.unit]?.name ?? 'temp'
                                    const nameB =
                                        unitMap[b.unit]?.name ?? 'temp'
                                    if (Number(nameA) < Number(nameB)) {
                                        return -1
                                    } else if (Number(nameB) < Number(nameA)) {
                                        return 1
                                    }
                                    return 0
                                })
                                .map((area) => {
                                    const unitConfig =
                                        unitMap[area.unit]?.unit_config
                                    return (
                                        <Container
                                            key={`AREA_VACANT_${area.id}`}
                                            direction="row"
                                            flex={1}
                                        >
                                            <Container
                                                style={{ ...RowCellStyle }}
                                            >
                                                {unitMap[area.unit]?.name}
                                            </Container>
                                            <Container
                                                style={{ ...RowCellStyle }}
                                            >
                                                {
                                                    unitMap[area.unit]?.folder
                                                        .path
                                                }
                                                {
                                                    unitMap[area.unit]?.folder
                                                        .name
                                                }
                                            </Container>
                                            <Container
                                                style={{ ...RowCellStyle }}
                                            >
                                                {unitConfig &&
                                                    unitConfigMap[unitConfig]
                                                        ?.name}
                                            </Container>
                                            <Container
                                                style={{ ...RowCellStyle }}
                                            >
                                                {area.area_config_name}{' '}
                                                {area.area_label}
                                            </Container>
                                        </Container>
                                    )
                                })}
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
