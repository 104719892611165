import React from 'react'
import { Container } from '../Container'

interface Props {
    value: number
    index: number
    style?: React.CSSProperties
}

export const TabPanel: React.FC<Props> = (props) => {
    const { value, index, style } = props

    return (
        <Container
            style={{
                display: value === index ? 'flex' : 'none',
                ...style,
            }}
        >
            {props.children}
        </Container>
    )
}
