import React, { useEffect, useState } from 'react'
import {
    Button,
    CircularProgress,
    IconButton,
    Modal,
    Paper,
    Slide,
    TextField,
    useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DescriptionIcon from '@material-ui/icons/Description'

import { Container, NumberInput, StatusBadge } from '../../../../components'

import {
    AreaConfig,
    getInspectionStatusLabel,
    getLeaseStatusString,
    InspectionStatus,
    Lease,
    LeaseStatus,
    ModelMap,
    MoveInspection,
    Unit,
} from '../../../../models'
import { axiosInstance } from '../../../../helpers'
import { toast } from 'react-toastify'

interface Props {
    lease: Lease | null
    unitMap: ModelMap<Unit>
    areaConfigMap: ModelMap<AreaConfig>
    onClose: () => void
    leaseList: Lease[]
    setLeaseList: (leaseList: Lease[]) => void
    setLeaseDetail: (lease: Lease | null) => void
}

export const LeaseDetailModal = (props: Props) => {
    const {
        lease,
        onClose,
        unitMap,
        areaConfigMap,
        leaseList,
        setLeaseList,
        setLeaseDetail,
    } = props

    const open = lease !== null

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        height: '95%',
                        width: '95%',
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                    }}
                >
                    {lease === null ? (
                        <Container
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress size="large" />
                        </Container>
                    ) : (
                        <LeaseDetail
                            lease={lease}
                            unit={unitMap[lease.area.unit]}
                            areaConfig={areaConfigMap[lease.area.area_config]}
                            onClose={onClose}
                            leaseList={leaseList}
                            setLeaseList={setLeaseList}
                            setLeaseDetail={setLeaseDetail}
                        />
                    )}
                </Paper>
            </Slide>
        </Modal>
    )
}

const LeaseDetail: React.FC<{
    lease: Lease
    unit?: Unit
    areaConfig?: AreaConfig
    onClose: () => void
    leaseList: Lease[]
    setLeaseList: (leaseList: Lease[]) => void
    setLeaseDetail: (lease: Lease | null) => void
}> = ({
    lease,
    unit,
    areaConfig,
    onClose,
    leaseList,
    setLeaseList,
    setLeaseDetail,
}) => {
    return (
        <Container
            style={{
                flexDirection: 'column',
                flex: 1,
                margin: 64,
                marginTop: 40,
                marginBottom: 40,
            }}
        >
            <Container
                style={{
                    paddingBottom: 17,
                    alignItems: 'center',
                    borderBottom: '1px solid #C2C2C2',
                }}
            >
                <span
                    style={{
                        fontWeight: 500,
                        fontSize: '20px',
                        lineHeight: '23px',
                        marginRight: 12,
                    }}
                >
                    Lease for location: {unit?.folder.path}
                    {unit?.folder.name}/ {unit?.name}
                </span>

                <StatusBadge
                    text={getLeaseStatusString(lease.status)}
                    customStyle={{
                        container: {
                            backgroundColor: '#DBE3F7',
                            textAlign: 'center',
                        },
                        text: {
                            color: '#2C80FF',
                            fontWeight: 700,
                        },
                    }}
                />

                <div style={{ flex: 1 }} />
                <IconButton onClick={onClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Container>

            <Container style={{ flex: 1, marginTop: 22 }}>
                <Container
                    style={{
                        borderRight: '1px solid #C2C2C2',
                        flexDirection: 'column',
                        flex: 1,
                        paddingRight: 72,
                    }}
                >
                    <span
                        style={{
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '14px',
                            color: '#8A8A8A',
                        }}
                    >
                        Resident
                    </span>

                    <TextField
                        variant="outlined"
                        size="small"
                        disabled
                        value={lease.user.name}
                        style={{
                            marginBottom: 16,
                            marginTop: 14,
                        }}
                    />

                    <TextField
                        variant="outlined"
                        size="small"
                        disabled
                        value={lease.user.email}
                        style={{
                            paddingBottom: 38,
                            borderBottom: '1px solid #C2C2C2',
                        }}
                    />

                    <MoveInspectionCard
                        moveInspection={lease.tenant_move_in_inspection}
                        title="Move In Inspection"
                        fallbackText="Available after move in"
                        lease={lease}
                        enableMakeAvalible
                        leaseList={leaseList}
                        setLeaseList={setLeaseList}
                        setLeaseDetail={setLeaseDetail}
                    />

                    <MoveInspectionCard
                        moveInspection={lease.apartment_move_out_inspection}
                        title="Move Out Inspection"
                        fallbackText="Available after move out"
                        lease={lease}
                        leaseList={leaseList}
                        setLeaseList={setLeaseList}
                        setLeaseDetail={setLeaseDetail}
                    />
                </Container>

                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        paddingLeft: 72,
                    }}
                >
                    <span
                        style={{
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '14px',
                            color: '#8A8A8A',
                        }}
                    >
                        Current Rent
                    </span>

                    <TextField
                        variant="outlined"
                        size="small"
                        disabled
                        value={`$${lease.rent ?? 0}`}
                        style={{
                            marginBottom: 16,
                            marginTop: 14,
                        }}
                    />

                    <div style={{ flex: 1 }} />

                    <Container>
                        <Button
                            style={{ borderColor: '#FB503B', color: '#FB503B' }}
                            variant="outlined"
                            onClick={onClose}
                        >
                            Close
                        </Button>
                        {/* <Button
                            style={{
                                borderColor: '#008C85',
                                color: '#008C85',
                                marginLeft: 16,
                            }}
                            variant="outlined"
                        >
                            Edit
                        </Button> */}
                    </Container>
                </Container>
            </Container>
        </Container>
    )
}

const MoveInspectionCard: React.FC<{
    title: string
    moveInspection?: MoveInspection | null
    fallbackText: string
    lease: Lease
    enableMakeAvalible?: boolean
    leaseList: Lease[]
    setLeaseList: (leaseList: Lease[]) => void
    setLeaseDetail: (lease: Lease | null) => void
}> = ({
    moveInspection,
    title,
    fallbackText,
    lease,
    enableMakeAvalible,
    leaseList,
    setLeaseList,
    setLeaseDetail,
}) => {
    let statusLabel = fallbackText
    let status = ''
    const [days, setDays] = useState('2')
    const theme = useTheme()
    if (moveInspection?.status) {
        statusLabel = getInspectionStatusLabel(moveInspection.status)
        status = moveInspection.status
    }

    const inspectionIsNotStaged = status !== InspectionStatus.STAGED
    const leaseIsCurrentOrPast =
        lease.status === LeaseStatus.CURRENT ||
        lease.status === LeaseStatus.PAST

    return (
        <Container
            style={{
                borderBottom: '1px solid #C2C2C2',
                paddingTop: 14,
                paddingBottom: 14,
                alignItems: 'center',
            }}
        >
            <Container style={{ flexDirection: 'column' }}>
                <span
                    style={{
                        color: '#8A8A8A',
                        fontSize: '13px',
                        fontWeight: 600,
                        lineHeight: '15px',
                    }}
                >
                    {title}
                </span>

                <span
                    style={{
                        color: '#8A8A8A',
                        fontSize: '11px',
                        fontWeight: 400,
                        lineHeight: '15px',
                        marginTop: 4,
                    }}
                >
                    {statusLabel}
                </span>
            </Container>

            <div style={{ flex: 1 }} />
            {enableMakeAvalible &&
                inspectionIsNotStaged &&
                leaseIsCurrentOrPast && (
                    <Container>
                        <Button
                            style={{
                                borderColor: '#008C85',
                                color: '#008C85',
                                marginRight: theme.spacing(2),
                            }}
                            variant="outlined"
                            onClick={() => {
                                if (
                                    lease.tenant_move_in_inspection &&
                                    confirm(
                                        `This will email ${lease.user.name} and allow them to do thier move in inspection, even if they have already completed it. Are you sure you want to do this?`,
                                    )
                                ) {
                                    axiosInstance
                                        .post(
                                            'inspection/area-inspection/bulk_transition/',
                                            {
                                                area_inspections: [
                                                    lease
                                                        .tenant_move_in_inspection
                                                        .id,
                                                ],
                                                to_status: 'READY',
                                                days_until_due: Number(days),
                                                ready_override: true,
                                                children_area_inspections: true,
                                            },
                                        )
                                        .then((res) => {
                                            toast.success('Success!')
                                            const newTenantMoveInIns =
                                                res.data[0]
                                            const newLeaseList = leaseList.map(
                                                (lease) => {
                                                    if (
                                                        lease.tenant_move_in_inspection &&
                                                        lease
                                                            .tenant_move_in_inspection
                                                            .id ===
                                                            newTenantMoveInIns.id
                                                    ) {
                                                        return {
                                                            ...lease,
                                                            tenant_move_in_inspection: newTenantMoveInIns,
                                                        }
                                                    }
                                                    return lease
                                                },
                                            )

                                            setLeaseList(newLeaseList)
                                            setLeaseDetail({
                                                ...lease,
                                                tenant_move_in_inspection: newTenantMoveInIns,
                                            })
                                        })
                                }
                            }}
                        >
                            Alert Tenant
                        </Button>
                        <NumberInput
                            value={days}
                            style={{}}
                            onChange={(event) => {
                                setDays(event.target.value)
                            }}
                            label="Days to Complete"
                        />
                    </Container>
                )}
            {/* <IconButton>
                <DescriptionIcon />
            </IconButton> */}
        </Container>
    )
}
