import React from 'react'
import { Button, ButtonProps, CircularProgress } from '@material-ui/core'

interface Props extends ButtonProps {
    loading?: boolean
}

export const LoadingButton = (props: Props) => {
    let content: React.ReactNode = props.children

    if (props.loading) {
        content = <CircularProgress size={24} />
    }

    return (
        <Button {...props} disabled={props.loading} onClick={props.onClick}>
            {content}
        </Button>
    )
}
