import React from 'react'

// types
import { Order, HeadCell } from './'

// components
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { useStyles } from '../../styles'
import { Typography, useTheme } from '@material-ui/core'

interface EnhancedHeadTableProps<T> {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof T,
        backendSort?: string[],
    ) => void
    order: Order
    orderBy: keyof T
    rowCount: number
    headCells: HeadCell<T>[]
}

export function EnhancedTableHead<T>(props: EnhancedHeadTableProps<T>) {
    const { order, orderBy, headCells, onRequestSort } = props
    const classes = useStyles()
    const theme = useTheme()

    const createSortHandler = (property: keyof T, backendSort?: string[]) => (
        event: React.MouseEvent<unknown>,
    ) => {
        onRequestSort(event, property, backendSort)
    }

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, idx) => (
                    <TableCell
                        key={`TABLE_CELL_${idx}`}
                        align={headCell.align ?? 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightMedium,
                            ...headCell.style,
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(
                                headCell.id,
                                headCell.backendSort,
                            )}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc'
                                        ? 'sorted descending'
                                        : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}
