import { AxiosError } from 'axios'
import {
    ActionItemActionTypes,
    ActionItemListRequest,
    ActionItemListThunk,
    ActionItemRequest,
    ActionItemThunk,
    ADD_ACTION_ITEM,
    CreateActionItemRequest,
    CREATE_ACTION_ITEM_REQUEST,
    GET_ACTION_ITEM_LIST_REQUEST,
    SET_ACTION_ITEM_LIST,
    SET_ACTION_ITEM_LOADING,
    UpdateActionItemRequest,
    UPDATE_ACTION_ITEM,
    UPDATE_ACTION_ITEM_REQUEST,
} from '.'
import { AppThunk, ErrorResponse, setNetworkError } from '..'
import { axiosInstance } from '../../helpers'
import { ActionItem } from '../../models'

export const updateActionItemReq = (
    req: UpdateActionItemRequest,
): AppThunk<ActionItemThunk> => {
    return async (dispatch) => {
        dispatch(setActionItemLoading(UPDATE_ACTION_ITEM_REQUEST, true))

        const url = `action-item/${req.params.id}/`

        try {
            // success
            const response = await axiosInstance.patch(url, req.body)
            dispatch(updateActionItem(response.data))
            dispatch(setActionItemLoading(UPDATE_ACTION_ITEM_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setActionItemLoading(UPDATE_ACTION_ITEM_REQUEST, false))
            dispatch(setNetworkError(error, true))
            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getActionItemList = (
    req: ActionItemListRequest,
): AppThunk<ActionItemListThunk> => {
    return async (dispatch) => {
        dispatch(setActionItemLoading(GET_ACTION_ITEM_LIST_REQUEST, true))

        const url = 'action-item/'

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })
            dispatch(setActionItemList(response.data))
            dispatch(setActionItemLoading(GET_ACTION_ITEM_LIST_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setActionItemLoading(GET_ACTION_ITEM_LIST_REQUEST, false))
            dispatch(setNetworkError(error, true))
            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createActionItem = (
    req: CreateActionItemRequest,
): AppThunk<ActionItemThunk> => {
    return async (dispatch) => {
        dispatch(setActionItemLoading(CREATE_ACTION_ITEM_REQUEST, true))

        const url = `action-item/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)
            dispatch(addActionItem(response.data))
            dispatch(setActionItemLoading(CREATE_ACTION_ITEM_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setActionItemLoading(CREATE_ACTION_ITEM_REQUEST, false))
            dispatch(setNetworkError(error, true))
            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const updateActionItem = (
    actionItem: ActionItem,
): ActionItemActionTypes => {
    return {
        type: UPDATE_ACTION_ITEM,
        actionItem: actionItem,
    }
}

export const setActionItemLoading = (
    request: ActionItemRequest,
    loading: boolean,
): ActionItemActionTypes => {
    return {
        type: SET_ACTION_ITEM_LOADING,
        request: request,
        loading: loading,
    }
}

export const setActionItemList = (
    actionItemList?: ActionItem[],
): ActionItemActionTypes => {
    return {
        type: SET_ACTION_ITEM_LIST,
        actionItemList: actionItemList,
    }
}
export const addActionItem = (
    actionItem: ActionItem,
): ActionItemActionTypes => {
    return {
        type: ADD_ACTION_ITEM,
        actionItem: actionItem,
    }
}
