import { LeaseCluster, Schedule } from '../../models'

export enum CalendarEventType {
    SERVICE_ORDER,
    ACTION_ITEM,
    DEADLINE,
    PROJECT,
    MOVE_OUT,
    MOVE_IN,
}

export type FormState = {
    meta: {
        xPos: number
        yPos: number
        date: Date
    }
    popovers: CalendarPopoverState
    modals: CalendarModalState
}

export type CalendarModalState = {
    deadlineModal: {
        open: boolean
        deadlineId: number
    }
    addDeadlineModal: boolean
    addActionItemModal: boolean
    clusterDetailModal: ClusterDetailFormState | null
    ezNowCreationModal: boolean
    leaseModal: LeaseModalFormState | null
}

export type LeaseModalFormState = {
    type: CalendarEventType.MOVE_IN | CalendarEventType.MOVE_OUT
    leaseCluster: LeaseCluster
}

export type ClusterDetailFormState = {
    selectedClusterIndex: number
}

export type CalendarPopoverState = {
    projectPopover: ProjectPopoverFormState
    addEventPopover: boolean
}

export type ProjectPopoverFormState = {
    schedule: Schedule | null
    open: boolean
}

export const defaultFormState: FormState = {
    meta: {
        xPos: 0,
        yPos: 0,
        date: new Date(),
    },
    popovers: {
        projectPopover: {
            schedule: null,
            open: false,
        },
        addEventPopover: false,
    },
    modals: {
        deadlineModal: {
            open: false,
            deadlineId: -1,
        },
        addDeadlineModal: false,
        addActionItemModal: false,
        clusterDetailModal: null,
        ezNowCreationModal: false,
        leaseModal: null,
    },
}
