import { PaginationActionTypes, PaginationState, SET_NEXT_PREV } from '.'

const initialState: PaginationState = {}

export const paginationReducer = (
    state = initialState,
    action: PaginationActionTypes,
): PaginationState => {
    switch (action.type) {
        case SET_NEXT_PREV: {
            return {
                ...state,
                [action.request]: {
                    prev: action.prev,
                    next: action.next,
                    count: action.count,
                },
            }
        }
        default: {
            return state
        }
    }
}
