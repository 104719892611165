import React, { useEffect, useMemo, useState } from 'react'
import {
    CircularProgress,
    Divider,
    IconButton,
    Modal,
    Paper,
    Slide,
    TableSortLabel,
    Theme,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { DDLease, DDLeaseStatus, LeaseAudit } from '../../../../../models'
import { Container } from '../../../../../components'
import CloseIcon from '@material-ui/icons/Close'
import { toMMDDYYYY } from '../../../../../helpers'
import { SearchField } from '../../../../../components/SearchField'

interface Props {
    open: boolean
    leaseAudit: LeaseAudit
    handleClose: () => void
    getAllPDFLeases: (leaseAuditId: number) => Promise<DDLease[]>
}

export const PDFLeaseUploadStatusModal = (props: Props) => {
    const { open, leaseAudit, handleClose, getAllPDFLeases } = props

    const theme = useTheme()

    const [pdfLeases, setPDFLeases] = useState<DDLease[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (open) {
            setLoading(true)
            getAllPDFLeases(leaseAudit.id)
                .then((res) => setPDFLeases(res))
                .finally(() => setLoading(false))
        }
    }, [open])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        maxWidth: 'calc(100vw - 100px)',
                        minWidth: '900px',
                        flexDirection: 'column',
                        maxHeight: 'calc(100vh - 100px)',
                        minHeight: '600px',
                    }}
                >
                    <Container
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                            overflow: 'auto',
                        }}
                    >
                        <Container
                            style={{
                                alignItems: 'center',
                                padding: theme.spacing(1),
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: theme.typography.fontWeightBold,
                                    flex: 1,
                                }}
                            >
                                PDF Lease Upload Statuses
                            </span>
                            <Container style={{ flex: 1 }} />
                            <Tooltip title="Cancel">
                                <IconButton
                                    onClick={() => {
                                        handleClose()
                                    }}
                                >
                                    <CloseIcon
                                        fontSize="inherit"
                                        style={{
                                            marginRight: theme.spacing(1),
                                            color: theme.palette.darkGreen.main,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Container>
                        <Divider />
                        {loading ? (
                            <Container
                                style={{
                                    flex: 1,
                                    width: '100%',
                                    height: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CircularProgress size={100} />
                            </Container>
                        ) : (
                            <LeasePDFStatusTable
                                pdfLeases={pdfLeases}
                                theme={theme}
                            />
                        )}
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

interface LeasePDFStatusTable {
    pdfLeases: DDLease[]
    theme: Theme
}

const LeasePDFStatusTable = (props: LeasePDFStatusTable) => {
    const { pdfLeases, theme } = props

    const [sortMethod, setSortMethod] = useState<StatusSortMethod>(
        StatusSortMethod.NAME,
    )
    const [sortedRows, setSortedRows] = useState(pdfLeases)
    const [searchText, setSearchText] = useState('')

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.body1,
        fontWeight: 600,
        color: theme.palette.darkGreen.main,
        whiteSpace: 'pre-line',
        justifyContent: 'left',
        flex: 1,
        marginRight: theme.spacing(2),
        padding: theme.spacing(1),
    }

    const cellStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        fontWeight: 400,
        justifyContent: 'left',
        flex: 1,
        marginRight: theme.spacing(2),
        padding: theme.spacing(1),
    }

    const createStatusLabel = (pdfLease: DDLease) => {
        if (pdfLease.status === DDLeaseStatus.FAILURE) {
            return 'Upload Failure'
        } else if (
            pdfLease.status === DDLeaseStatus.LEASE_ADDED ||
            pdfLease.status === DDLeaseStatus.PENDING
        ) {
            return 'Pending Analysis'
        } else {
            return 'Success'
        }
    }

    const sortData = (
        newSortMethod: StatusSortMethod,
        leaseA: DDLease,
        leaseB: DDLease,
    ) => {
        let aValue = leaseA.file_name
        let bValue = leaseB.file_name

        if (
            newSortMethod === StatusSortMethod.STATUS ||
            newSortMethod === StatusSortMethod.REVERSE_STATUS
        ) {
            aValue = leaseA.status
            bValue = leaseB.status
        } else if (
            newSortMethod === StatusSortMethod.UPLOAD_DATE ||
            newSortMethod === StatusSortMethod.REVERSE_UPLOAD_DATE
        ) {
            aValue = leaseA.created_date
            bValue = leaseB.created_date
        }

        if (aValue === null && bValue === null) {
            return 0
        } else if (aValue === null) {
            return 1
        } else if (bValue === null) {
            return -1
        }

        return aValue.localeCompare(bValue)
    }

    const handleSort = (newSortMethod: StatusSortMethod) => {
        const nonReverseMethods = [
            StatusSortMethod.NAME,
            StatusSortMethod.STATUS,
            StatusSortMethod.UPLOAD_DATE,
        ]

        const newSortedRows: DDLease[] = [...pdfLeases]

        if (nonReverseMethods.includes(newSortMethod)) {
            setSortedRows(
                newSortedRows.sort((leaseA, leaseB) =>
                    sortData(newSortMethod, leaseA, leaseB),
                ),
            )
        } else {
            setSortedRows(
                newSortedRows
                    .sort((leaseA, leaseB) =>
                        sortData(newSortMethod, leaseA, leaseB),
                    )
                    .reverse(),
            )
        }

        setSortMethod(newSortMethod)
    }

    const filteredPDFLeases = useMemo(() => {
        return sortedRows.filter((pdfLease) =>
            pdfLease.file_name
                ?.toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase()),
        )
    }, [searchText, sortedRows])

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                overflow: 'auto',
                width: '100%',
            }}
        >
            <Container
                style={{
                    padding: theme.spacing(0, 1, 0, 1),
                }}
            >
                <SearchField
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e.target.value)
                    }}
                    placeholder="Search"
                />
            </Container>
            <Container style={{ width: '100%' }}>
                <Container
                    style={{ ...headCellStyle, flex: 3 }}
                    onClick={() => {
                        if (sortMethod === StatusSortMethod.NAME) {
                            handleSort(StatusSortMethod.REVERSE_NAME)
                        } else {
                            handleSort(StatusSortMethod.NAME)
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            sortMethod === StatusSortMethod.NAME ||
                            sortMethod === StatusSortMethod.REVERSE_NAME
                        }
                        direction={
                            sortMethod === StatusSortMethod.NAME
                                ? 'asc'
                                : 'desc'
                        }
                    >
                        File Name
                    </TableSortLabel>
                </Container>
                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        if (sortMethod === StatusSortMethod.STATUS) {
                            handleSort(StatusSortMethod.REVERSE_STATUS)
                        } else {
                            handleSort(StatusSortMethod.STATUS)
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            sortMethod === StatusSortMethod.STATUS ||
                            sortMethod === StatusSortMethod.REVERSE_STATUS
                        }
                        direction={
                            sortMethod === StatusSortMethod.STATUS
                                ? 'asc'
                                : 'desc'
                        }
                    >
                        Upload Status
                    </TableSortLabel>
                </Container>
                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        if (sortMethod === StatusSortMethod.UPLOAD_DATE) {
                            handleSort(StatusSortMethod.REVERSE_UPLOAD_DATE)
                        } else {
                            handleSort(StatusSortMethod.UPLOAD_DATE)
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            sortMethod === StatusSortMethod.UPLOAD_DATE ||
                            sortMethod === StatusSortMethod.REVERSE_UPLOAD_DATE
                        }
                        direction={
                            sortMethod === StatusSortMethod.UPLOAD_DATE
                                ? 'asc'
                                : 'desc'
                        }
                    >
                        Upload Date
                    </TableSortLabel>
                </Container>
            </Container>
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    overflow: 'scroll',
                }}
            >
                {filteredPDFLeases.map((pdfLease) => {
                    return (
                        <Container
                            key={`PDF_LEASE_STATUS_ROW_${pdfLease.id}`}
                            style={{
                                borderBottom: '1px solid',
                                borderColor: theme.palette.grey[400],
                                flexDirection: 'row',
                            }}
                        >
                            <Container
                                style={{
                                    ...cellStyle,
                                    justifyContent: 'left',
                                    flex: 3,
                                }}
                            >
                                {pdfLease.file_name ?? ''}
                            </Container>
                            <Container
                                style={{
                                    ...cellStyle,
                                }}
                            >
                                {createStatusLabel(pdfLease)}
                            </Container>
                            <Container
                                style={{
                                    ...cellStyle,
                                }}
                            >
                                {toMMDDYYYY(new Date(pdfLease.created_date))}
                            </Container>
                        </Container>
                    )
                })}
            </Container>
        </Container>
    )
}

enum StatusSortMethod {
    NAME = 'NAME',
    REVERSE_NAME = 'REVERSE_NAME',
    STATUS = 'STATUS',
    REVERSE_STATUS = 'REVERSE_STATUS',
    UPLOAD_DATE = 'UPLOAD_DATE',
    REVERSE_UPLOAD_DATE = 'REVERSE_UPLOAD_DATE',
}
