import React, { useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    FormControl,
    Grid,
    Select,
    TextField,
    Paper,
    IconButton,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { ReactComponent as Download } from '../../../../assets/icons/download.svg'
import { BasicDataTable } from '../../../components/data-table'
import { axiosInstance } from '../../../../helpers'
import { useCompany, useService } from '../../../../hooks'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            tableLayout: 'fixed',
            // minWidth: 650,
            '& .MuiTableRow-root .MuiTableCell-root': {
                fontWeight: 400,
                fontSize: '0.75rem',
                /* line-height: 12px; */
                color: '#8A8A8A',
            },
            '& .MuiTableHead-root .MuiTableCell-root': {
                fontWeight: 400,
                fontSize: '0.75rem',
                /* line-height: 12px; */
                color: '#000',
                backgroundColor: '#FFF',
            },
        },
        assignStatus: {
            background: '#DBE3F7',
            borderRadius: '5px',
            fontSize: '0.75rem',
            fontWeight: 700,
            color: '#2C80FF',
            padding: '5px',
        },
        assignedVendor: {
            borderRadius: '50%',
            background: '#008C85',
            padding: '5px',
            color: '#000',
        },
        completedVendor: {
            borderRadius: '50%',
            background: '#D9D9D9',
            padding: '5px',
            color: '#8A8A8A',
        },
        completedStatus: {
            background: '#D9D9D9',
            borderRadius: '5px',
            fontSize: '0.75rem',
            fontWeight: 700,
            color: '#8A8A8A',
            padding: '5px',
        },
        filterDropdown: {
            '& .MuiAutocomplete-inputRoot': {
                paddingRight: '0px',
            },
            '& .MuiInputBase-input': {
                color: '#000',
                fontSize: '0.75rem',
                background: '#E8E8E8',
                borderRadius: '5px',
                padding: '6px !important',
                cursor: 'pointer',
            },
            '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: 0,
            },
            // '& .MuiAutocomplete-endAdornment': {
            //     display: 'none',
            // },
        },
        assigned: {
            color: '#FFF',
            backgroundColor: '#9E9E9E',
            borderRadius: '36px',
            fontSize: '0.625rem',
            fontWeight: 700,
            padding: '2px 5px',
            display: 'block',
            width: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            cursor: 'pointer',
            textAlign: 'center',
        },
        complete: {
            color: '#FFF',
            backgroundColor: 'green',
            borderRadius: '36px',
            fontSize: '0.625rem',
            fontWeight: 700,
            padding: '2px 5px',
            display: 'block',
            width: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            cursor: 'pointer',
            textAlign: 'center',
        },
        inProgress: {
            display: 'block',
            color: '#FFF',
            padding: '2px 5px',
            fontSize: '0.625rem',
            fontWeight: 700,
            borderRadius: '36px',
            backgroundColor: '#FFBF00',
            width: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            cursor: 'pointer',
            textAlign: 'center',
        },
        otherStatus: {
            display: 'block',
            color: '#000',
            padding: '2px 5px',
            fontSize: '0.625rem',
            fontWeight: 700,
            borderRadius: '36px',
            backgroundColor: '#FFF',
            border: '1px solid #000',
            width: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            cursor: 'pointer',
            textAlign: 'center',
        },
    }),
)

const TodaysItems = (props: any) => {
    const classes = useStyles()
    const [todaysWorkorder, setTodaysWorkorder] = useState([])

    const [selectedfilService, setSelectedfilService] = useState<
        any | undefined
    >({ id: '', name: 'All' })
    const [selectedfilVendor, setSelectedfilVendor] = useState<any | undefined>(
        { id: '', name: 'All' },
    )

    const { serviceList } = useService({ getServiceList: true, cleanUp: true })
    const { vendorList } = useCompany({ getVendorList: true, cleanUp: true })

    useEffect(() => {
        getTodaysWorkOrder()
    }, [selectedfilService, selectedfilVendor])

    const getTodaysWorkOrder = () => {
        console.log('selected vendor is...', selectedfilVendor)
        axiosInstance
            .get('workorder/', {
                params: {
                    wo_status: 'active',
                    lower_bound_date: new Date('2022-03-24').toISOString(),
                    vendor:
                        selectedfilVendor && selectedfilVendor !== ''
                            ? selectedfilVendor.id
                            : null,
                    service:
                        selectedfilService && selectedfilService !== ''
                            ? selectedfilService.id
                            : null,
                },
            })
            .then((res: any) => {
                if (res && res.data) {
                    setTodaysWorkorder(res.data)
                }
            })
    }

    const columns = [
        {
            name: 'Location',
            selector: (row: any) => row.location,
            sortable: true,
        },
        {
            name: 'Unit',
            selector: (row: any) => row.unit,
            sortable: true,
        },
        {
            name: 'Unit Type',
            selector: (row: any) => row.unitType,
            sortable: true,
        },
        {
            name: 'Service',
            selector: (row: any) => row.service,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row: any) => row.status,
            sortable: true,
            cell: (row: any) => (
                <span className={getStatusClass(row.status)}>
                    {statusConfig[row.status]}
                </span>
            ),
        },
        {
            name: 'Vendor',
            selector: (row: any) => row.vendor,
            sortable: true,
        },
    ]

    const getStatusClass = (status: string) => {
        switch (status) {
            case 'ASSIGNED':
                return classes.assigned
            case 'COMPLETE':
                return classes.complete
            case 'IN_PROGRESS':
                return classes.inProgress
            default:
                return classes.otherStatus
        }
    }
    const statusConfig: any = {
        ASSIGNED: 'Assigned',
        COMPLETE: 'Complete',
        IN_PROGRESS: 'In Progress',
        GO_BACK: 'Go Back',
        PAUSE: 'Pause',
    }

    const data =
        todaysWorkorder && todaysWorkorder.length > 0
            ? todaysWorkorder.map((value: any) => {
                  return {
                      id: value.id,
                      location: value.path,
                      unit: value.unit_name,
                      unitType: value.unit_config_name,
                      service: value.service_name ?? '',
                      //   status: (
                      //       <span className={getStatusClass(value.status)}>
                      //           {statusConfig[value.status]}
                      //       </span>
                      //   ),
                      status: value.status,
                      vendor: value.vendor_name,
                  }
              })
            : []
    // const data = [
    //     {
    //         id: 1,
    //         location: '/1/1',
    //         unit: 101,
    //         unitType: '2br-2-bath',
    //         service: 'Touch Paint',
    //         status: 'Assigned',
    //         vendor: 'HH',
    //     },
    //     {
    //         id: 2,
    //         location: '/1/1',
    //         unit: 101,
    //         unitType: '2br-2-bath',
    //         service: 'Full Paint',
    //         status: 'Assigned',
    //         vendor: 'HH',
    //     },
    //     {
    //         id: 3,
    //         location: '/1/2',
    //         unit: 102,
    //         unitType: '2br-2-bath',
    //         service: 'Touch Paint',
    //         status: 'Assigned',
    //         vendor: 'HH',
    //     },
    //     {
    //         id: 4,
    //         location: '/1/2',
    //         unit: 103,
    //         unitType: '2br-2-bath',
    //         service: 'Carpet Clean',
    //         status: 'Assigned',
    //         vendor: 'HH',
    //     },
    //     {
    //         id: 5,
    //         location: '/2/1',
    //         unit: 201,
    //         unitType: '4br-4-bath',
    //         service: 'Trash Out',
    //         status: 'Assigned',
    //         vendor: 'HH',
    //     },
    // ]

    const servicesOptions = [
        { title: 'All Services', value: 'All Services' },
        { title: 'Paint', value: 'Paint' },
        { title: 'Full Paint', value: 'Full Paint' },
        { title: 'Touch Paint', value: 'Touch Paint' },
        { title: 'Clean', value: 'Clean' },
        { title: 'Touch Clean', value: 'Touch Clean' },
        { title: 'Carpet', value: 'Carpet' },
    ]

    const vendorsOptions = [
        { title: 'All', value: 'All' },
        { title: '001 TC Test Vendor', value: '001 TC Test Vendor' },
        { title: '1 Way Painting', value: '1 Way Painting' },
        { title: '2 Sisters', value: '2 Sisters' },
        { title: 'Four Corners', value: 'Four Corners' },
        { title: 'A & A Cleaning', value: 'A & A Cleaning' },
        { title: "Lincoln's Paint", value: "Lincoln's Paint" },
    ]

    const downloadCSV = () => {
        let url = `workorder/todays-workorder-csv`
        if (selectedfilService?.id && !selectedfilVendor?.id) {
            url = `workorder/todays-workorder-csv?service_id=${selectedfilService?.id}`
        }
        if (selectedfilVendor?.id && !selectedfilService?.id) {
            url = `workorder/todays-workorder-csv?vendor_id=${selectedfilVendor?.id}`
        }
        if (selectedfilService?.id && selectedfilVendor?.id) {
            url = `workorder/todays-workorder-csv?service_id=${selectedfilService?.id}&vendor_id=${selectedfilVendor?.id}`
        }
        axiosInstance.get(url, { responseType: 'blob' }).then((res: any) => {
            const urls = window.URL.createObjectURL(
                new Blob([res.data], { type: 'text/csv;charset=utf-8' }),
            )
            const link = document.createElement('a')
            link.href = urls
            link.setAttribute('download', `Work Order Todays Date`)
            document.body.appendChild(link)
            link.click()
            console.log('response is....', res)
        })
    }

    return (
        <>
            <Grid container spacing={2} alignItems="stretch">
                <Grid
                    item
                    xs={12}
                    md={6}
                    sm={6}
                    style={{
                        padding: '16px',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                >
                    <span
                        style={{
                            fontWeight: 600,
                            fontSize: '1rem',
                            lineHeight: '19px',
                        }}
                    >
                        For Today
                    </span>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sm={6}
                    style={{ padding: '16px', textAlign: 'right' }}
                >
                    <IconButton
                        aria-label="download"
                        style={{ padding: 0, marginRight: '15px' }}
                        onClick={() => downloadCSV()}
                    >
                        <Download />
                    </IconButton>
                    <FormControl style={{ width: '40%', marginRight: '15px' }}>
                        <Autocomplete
                            className={classes.filterDropdown}
                            disableClearable
                            id="combo-box-demo"
                            options={[
                                { id: '', name: 'All' },
                                ...(serviceList ?? []),
                            ]}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    // variant="outlined"
                                />
                            )}
                            PaperComponent={({ children }) => (
                                <Paper
                                    style={{
                                        fontSize: '0.75rem',
                                        fontWeight: 400,
                                        color: '#000',
                                    }}
                                >
                                    {children}
                                </Paper>
                            )}
                            value={selectedfilService}
                            onChange={(
                                event: React.ChangeEvent<{}>,
                                newValue: any,
                            ) => {
                                setSelectedfilService(newValue)
                                console.log('event is...', newValue)
                            }}
                        />
                    </FormControl>
                    <FormControl style={{ width: '40%' }}>
                        <Autocomplete
                            className={classes.filterDropdown}
                            disableClearable
                            id="combo-box-demo"
                            options={[
                                { id: '', name: 'All' },
                                ...(vendorList ?? []),
                            ]}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    // variant="outlined"
                                />
                            )}
                            PaperComponent={({ children }) => (
                                <Paper
                                    style={{
                                        fontSize: '0.75rem',
                                        fontWeight: 400,
                                        color: '#000',
                                    }}
                                >
                                    {children}
                                </Paper>
                            )}
                            value={selectedfilVendor}
                            onChange={(
                                event: React.ChangeEvent<{}>,
                                newValue: any,
                            ) => {
                                setSelectedfilVendor(newValue)
                                console.log('event is...', newValue)
                            }}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <BasicDataTable
                columns={columns}
                data={data}
                class="todaysDate"
                pagination={false}
                rowClickable={false}
                rowClicked={null}
            />
        </>
    )
}

export default TodaysItems
