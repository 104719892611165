import React, { useEffect, useRef, useState } from 'react'
import { CircularProgress, useTheme } from '@material-ui/core'
import { Container } from '../Container'
import { SideDrawer } from '../SideDrawer'
import { EditorState } from 'draft-js'
import { compositeDecorator, RichTextInput } from '../RichTextInput'
import { useSelector } from 'react-redux'
import { GET_MESSAGE_LIST_REQUEST, RootState } from '../../store'
import { Message } from '../Message/Message'
import { InventoryInspection, MessageDetail } from '../../models'
import { useChannel } from '../../hooks'

interface Props {
    open: boolean
    title?: string
    onClose: () => void
    inv_ins?: InventoryInspection
    onCreateMessage?: (message: MessageDetail) => void
    secondaryChannelId?: number | null
    secondaryTitle?: string
}

export const MessageDrawer = (props: Props) => {
    const {
        open,
        onClose,
        title,
        inv_ins,
        secondaryChannelId,
        secondaryTitle,
    } = props
    const theme = useTheme()

    const currentChannel = useSelector(
        (state: RootState) => state.messaging.currentChannel,
    )

    const messageList = useSelector(
        (state: RootState) => state.messaging.messages,
    )

    const messagesLoading = useSelector(
        (state: RootState) =>
            state.messaging.isLoading[GET_MESSAGE_LIST_REQUEST],
    )

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(compositeDecorator),
    )

    const [fileList, setFileList] = useState<File[]>()
    const endOfMsgRef = useRef<HTMLDivElement>(null)

    const { secondaryMessageList } = useChannel(
        currentChannel?.id,
        secondaryChannelId,
    )

    useEffect(() => {
        // Scroll to end of messages
        endOfMsgRef.current && endOfMsgRef.current.scrollIntoView()
    }, [messageList])

    let messages = messageList
    if (secondaryMessageList && messages) {
        messages = messages
            .concat(secondaryMessageList)
            .sort((messageA, messageB) => {
                return (
                    new Date(messageA.created_date).getTime() -
                    new Date(messageB.created_date).getTime()
                )
            })
    }

    return (
        <SideDrawer
            open={open}
            handleClose={onClose}
            title={title ?? 'Messages'}
        >
            {/* Message container */}
            <Container
                style={{
                    padding: theme.spacing(2),
                    margin: theme.spacing(2),
                    border: `1px solid ${theme.palette.grey[900]}`,
                    borderRadius: theme.shape.borderRadius,
                }}
                direction="column"
                flex={1}
                scrollY
            >
                {/* Messages here */}
                {messagesLoading ? (
                    <CircularProgress />
                ) : (
                    messages?.map((msg) => (
                        <Message
                            key={`WORKORDER_MSG_${msg.id}`}
                            message={msg}
                            attachmentSize={160}
                            isSecondary={msg.channel === secondaryChannelId}
                            secondaryTitle={secondaryTitle}
                        />
                    ))
                )}
                <div ref={endOfMsgRef} />
            </Container>

            <Container style={{ padding: theme.spacing(2) }}>
                <RichTextInput
                    editorState={editorState}
                    setEditorState={setEditorState}
                    currentChannel={currentChannel}
                    fileList={fileList}
                    setFileList={setFileList}
                    inv_ins={inv_ins}
                    onCreateMessage={props.onCreateMessage}
                />
            </Container>
        </SideDrawer>
    )
}
