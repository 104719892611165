import React from 'react'

import { Button, useTheme } from '@material-ui/core'
import { useAppDispatch } from '../../hooks'
import {
    BaseWorkorder,
    getTransitions,
    User,
    WorkorderStatus,
} from '../../models'
import { transitionWorkorderStatus } from '../../store'
import { Container } from '../Container'
import { toast } from 'react-toastify'

type TransitionMethod = (
    event: React.MouseEvent<HTMLButtonElement>,
    workorder: BaseWorkorder,
    status: WorkorderStatus,
) => void

interface Props {
    workorder: BaseWorkorder
    user?: User
    disabled?: boolean
    handleTransition?: TransitionMethod
    style?: React.CSSProperties
}

export const WorkorderTransitionButtons = (props: Props) => {
    const { user, workorder, disabled, style } = props

    const transitions = getTransitions(workorder, user)
    const dispatch = useAppDispatch()
    const theme = useTheme()

    let clickHandler: TransitionMethod
    if (props.handleTransition) {
        clickHandler = props.handleTransition
    } else {
        clickHandler = (_, workorder, status) => {
            dispatch(
                transitionWorkorderStatus({
                    body: { status: status },
                    workorderId: workorder.id,
                }),
            ).then(() => {
                toast.success(
                    `Workorder transitioned from ${workorder.status} to ${
                        transitions.positive!.to_status
                    }`,
                )
            })
        }
    }

    return (
        <Container
            style={{
                justifyContent: 'flex-end',
                margin: theme.spacing(1),
                flexDirection: 'row',
                ...style,
            }}
        >
            {transitions.positive !== undefined && (
                <Button
                    color="primary"
                    variant="outlined"
                    disabled={!transitions.positive.permission || disabled}
                    onClick={(e) => {
                        clickHandler(
                            e,
                            workorder,
                            transitions.positive!.to_status,
                        )
                    }}
                >
                    {transitions.positive.displayString}
                </Button>
            )}
            {transitions.negative !== undefined && (
                <Button
                    color="secondary"
                    variant="outlined"
                    style={{ marginLeft: theme.spacing(1) }}
                    disabled={!transitions.negative.permission || disabled}
                    onClick={(e) => {
                        clickHandler(
                            e,
                            workorder,
                            transitions.negative!.to_status,
                        )
                    }}
                >
                    {transitions.negative.displayString}
                </Button>
            )}
            {transitions.neutral !== undefined && (
                <Button
                    color="secondary"
                    variant="outlined"
                    disabled={!transitions.neutral.permission || disabled}
                    onClick={(e) => {
                        clickHandler(
                            e,
                            workorder,
                            transitions.neutral!.to_status,
                        )
                    }}
                >
                    {transitions.neutral.displayString}
                </Button>
            )}
        </Container>
    )
}
