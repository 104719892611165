import React, { useState, useEffect } from 'react'
import { Grid, useTheme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Container, TabPanel } from '../../components'
import { Location, RootState } from '../../store'
import {
    INVENTORY_TAB,
    InfrastructureManageTab,
} from './InfrastructureManagerHoc'
import { InventoryRow } from './InventoryRow'
import { Unit } from '../../models'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { useAptConfig } from '../../hooks'

interface Props {
    tab: InfrastructureManageTab
    userSelection: Location | undefined
}

export const InventoryTabPanel = (props: Props) => {
    const { tab, userSelection } = props

    const theme = useTheme()

    const inventoryList = useSelector(
        (state: RootState) => state.infrastructure.manager_inventory,
    )

    const [selectedAreaIndex, setSelectedAreaIndex] = useState(0)

    const { getAreaConfigMap } = useAptConfig({ areaConfigList: true })
    const areaConfigMap = getAreaConfigMap()

    useEffect(() => {
        setSelectedAreaIndex(0)
    }, [userSelection])

    let content = (
        <Container
            style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <span
                style={{
                    ...theme.typography.h5,
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >
                Please select a Unit
            </span>
        </Container>
    )

    if (userSelection?.type === 'unit') {
        const unit = userSelection.location as Unit

        content = (
            <Grid
                style={{
                    marginBottom: theme.spacing(1),
                }}
                item
                xs={12}
                direction="column"
            >
                <Grid container item xs={12}>
                    <ToggleButtonGroup
                        value={selectedAreaIndex}
                        onChange={(_, nf) => {
                            setSelectedAreaIndex(nf)
                        }}
                        aria-label="Area Selection"
                        exclusive
                    >
                        {unit.areas.map((area, idx) => {
                            return (
                                <ToggleButton
                                    key={`SELECT_AREA_${area.id}`}
                                    value={idx}
                                >
                                    {areaConfigMap[area.area_config]?.name} (
                                    {area.area_label})
                                </ToggleButton>
                            )
                        })}
                    </ToggleButtonGroup>
                </Grid>

                <Grid item container xs={12}>
                    <Grid container item xs={12} direction="column">
                        <Grid container item direction="column">
                            {inventoryList?.reduce<JSX.Element[]>(
                                (prev, inv) => {
                                    if (
                                        inv.area.id !==
                                        unit.areas[selectedAreaIndex]?.id
                                    ) {
                                        return prev
                                    }

                                    return prev.concat(
                                        <InventoryRow
                                            key={`INFRASTRUCTURE_INVENTORY_${inv.id}`}
                                            inventory={inv}
                                        />,
                                    )
                                },
                                [],
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <TabPanel
            value={INVENTORY_TAB}
            index={tab}
            style={{
                backgroundColor: theme.palette.grey[200],
                flex: 1,
                overflowY: 'scroll',
                flexDirection: 'column',
                padding: theme.spacing(2),
            }}
        >
            <Grid
                container
                style={{
                    display: INVENTORY_TAB === tab ? 'flex' : 'none',
                    justifyContent: 'flex-start',
                    flex: 1,
                }}
            >
                {content}
            </Grid>
        </TabPanel>
    )
}
