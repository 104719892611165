import { Area, IdentifiableObject, Attachment, MoveInspection } from '..'
import { DamageConfig } from '../Config'
import { BaseUser } from '../Users'

export interface Tenant {
    id: number
    user: BaseUser
    lease: number
    lease_detail?: Lease
}

export interface Lease extends IdentifiableObject {
    area: Area
    children?: Lease[]
    rent?: number
    start_date: string
    end_date: string
    user: BaseUser
    status: LeaseStatusType
    apartment_move_out_inspection: MoveInspection | null
    apartment_move_in_inspection: MoveInspection | null
    tenant_move_in_inspection: MoveInspection | null
    parent: number | null
    location?: {
        folder_id: number
        folder_path: string
        folder_name: string
        unit_id: number
        unit_name: string
        area_id: number
        area_label: string
        area_config_name: string
    }
}

export enum DamageStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    DENIED = 'DENIED',
    ENTRATA_SYNCED = 'ENTRATA_SYNCED',
    SYNC_PENDING = 'SYNC_PENDING',
    PRE_APPROVED = 'PRE_APPROVED',
    SYNC_FAILED = 'SYNC_FAILED',
}

export const DamageStatusList = [
    DamageStatus.PENDING,
    DamageStatus.PRE_APPROVED,
    DamageStatus.APPROVED,
    DamageStatus.SYNC_PENDING,
    DamageStatus.ENTRATA_SYNCED,
    DamageStatus.DENIED,
    DamageStatus.SYNC_FAILED,
]

export interface Damage extends IdentifiableObject {
    damage_config?: DamageConfig
    lease: Lease[]
    amount: number
    unit: number
    area: number
    inspection: number
    channel: number
    message_count: number
    description?: string
    cost: number
    unit_name: string
    folder_name: string
    folder_path: string
    area_config_name: string
    area_label: string
    tenants: Tenant[]
    damage_transactions: DamageTransaction[]
    transactions_created: boolean
    attachments: Attachment[]
    status: DamageStatus
    created_date: string
    inspection_name: string
}

export interface DamageTransaction {
    id: number
    lease: Lease
    synced_id: string | null
}

export const LeaseStatus = {
    APPLICANT: 1,
    CANCELLED: 2,
    FUTURE: 3,
    CURRENT: 4,
    NOTICE: 5,
    PAST: 6,
} as const

export type LeaseStatusType = typeof LeaseStatus[keyof typeof LeaseStatus]

export interface DDLease extends IdentifiableObject {
    tenant: string | null
    unit: string | null
    lease_start: string | null
    lease_end: string | null
    status: DDLeaseStatus | null
    rent: string | null
    lease: string | null
    edited: boolean
    file_name: string | null
    prompt_answers: LeasePromptAnswer[]
    modified_date: string
    created_date: string
    amenity_fee: string | null
    parking_fee: string | null
    pet_rent: string | null
    security_deposit: string | null
    master_policy_premium_dues: string | null
    rent_plus: string | null
    monthly_concessions: string | null
    team_member_concessions: string | null
    guarantor_waiver_fee: string | null
    deposit_amount: string | null
    floor_plan: string | null
    furniture_fee: string | null
    lease_status: string | null
    pet_deposit: string | null
    prepaid_rent: string | null
    landlord_administered_prot_prog: string | null
    employee_rent_credit: string | null
    lease_signed_tenant: boolean | null
    lease_sign_page: number | null
    lease_signed_manager: boolean | null
    pest_control_rec: string | null
    pest_control_signed: string | null
    rules_reg_rec: string | null
    rules_reg_signed: string | null
    fee_schedule_rec: string | null
    fee_schedule_signed: string | null
    furniture_rec: string | null
    furniture_signed: string | null
    special_prov_rec: string | null
    special_prov_signed: string | null
    utility_rec: string | null
    utility_signed: string | null
    crime_drug_rec: string | null
    crime_drug_signed: string | null
    bed_bug_rec: string | null
    bed_bug_signed: string | null
    mary_jane_rec: string | null
    mary_jane_signed: string | null
    paint_rec: boolean | null
    paint_signed: boolean | null
    mold_rec: string | null
    mold_signed: string | null
    smoking_rec: string | null
    smoking_signed: string | null
    photo_rec: string | null
    photo_signed: string | null
    card_code_rec: string | null
    card_code_signed: string | null
    asbestos_rec: string | null
    asbestos_signed: string | null
    arbitration_rec: string | null
    aribitration_signed: string | null
    guarantor_rec: string | null
    guarantor_signed: string | null
    gwp_rec: string | null
    gwp_signed: string | null
    signature_audit: boolean
}

export enum DDLeaseStatus {
    LEASE_ADDED = 'lease_added',
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILURE = 'failure',
}

export enum DDLeaseMatchStatus {
    NO_MATCH = 'no_match',
    SUCCESS = 'success',
    PARTIAL_MATCH = 'partial',
    DISCREPANCY = 'discrepancy',
}

export interface MatchedLease extends IdentifiableObject {
    rent_roll_lease: DDLease
    pdf_lease: DDLease | null
    status: DDLeaseMatchStatus
    lease_audit: number
}

export enum LeaseAuditStep {
    PAY = 1,
    UPLOAD_RENT_ROLL = 2,
    BASELINE = 3,
    UPLOAD_LEASES = 4,
    PROCESSING_LEASES = 5,
    MATCHING = 6,
}

export interface LeaseAudit extends IdentifiableObject {
    apartment: number
    steps: LeaseAuditStep
    rent_roll: string
    created_date: string
    modified_date: string
    unmatched_pdf_count: number
    matched_pdf_count: number
    rent_roll_count: number
    pdf_upload_deadline: string | null
    channel: number
    prompts: number[]
    baseline_lease_pdf: DDLease | null
    name: string
}

export interface LeaseClusterResponse {
    move_out: LeaseCluster[]
    move_in: LeaseCluster[]
}

export interface LeaseCluster {
    date: string
    count: number
}

export interface AuditPromptConfig extends IdentifiableObject {
    prompt: string
    category: string
    dd_lease_col_id: number
    display_name: string
    dd_lease_name: string
    format: string
    width: number
    created_date: string
    modified_date: string
}

export interface LeasePromptAnswer extends IdentifiableObject {
    answer: string
    prompt_config: number
    created_date: string
    modified_date: string
}

export interface Feature {
    promptConfig: AuditPromptConfig
    rentRollValue: any
    leaseValue: any
    leaseEdited: boolean
    rentRollEdited: boolean
}
