import {
    GET_SERVICE_CONTRACT_REQUEST_ACTION,
    GET_SERVICE_CONTRACT_RESPONSE_ACTION,
    ServiceContractActionTypes,
    ServiceContractState,
    SET_PORTFOLIO_SERVICE_CONTRACTS,
    SET_SERVICE_CONTRACTS,
    SET_SERVICE_CONTRACT_LOADING,
    UPDATE_BIDS_ACTION,
    UPDATE_PENDING_BIDS_ACTION,
    UPDATE_SERVICE_CONTRACT_REQUEST_ACTION,
    UPDATE_SERVICE_CONTRACT_RESPONSE_ACTION,
} from './types'

const initialState: ServiceContractState = {
    serviceContracts: [],
    isLoading: {
        UPDATE_SERVICE_CONTRACT_REQUEST_ACTION: false,
        GET_SERVICE_CONTRACT_REQUEST_ACTION: false,
        GET_PORTFOLIO_SERVICE_CONTRACT_REQUEST_ACTION: false,
        SET_SERVICE_CONTRACT_REQUEST_ACTION: false,
    },
    pendingBids: 0,
    bids: [],
}

export const serviceContractReducer = (
    state = initialState,
    action: ServiceContractActionTypes,
): ServiceContractState => {
    switch (action.type) {
        case SET_SERVICE_CONTRACT_LOADING:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.request]: action.isLoading,
                },
            }
        case UPDATE_SERVICE_CONTRACT_REQUEST_ACTION:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    UPDATE_SERVICE_CONTRACT_REQUEST_ACTION: true,
                },
            }
        case UPDATE_SERVICE_CONTRACT_RESPONSE_ACTION:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    UPDATE_SERVICE_CONTRACT_REQUEST_ACTION: false,
                },
                serviceContracts: action.payload,
            }
        case GET_SERVICE_CONTRACT_REQUEST_ACTION:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_SERVICE_CONTRACT_REQUEST_ACTION: true,
                },
            }
        case GET_SERVICE_CONTRACT_RESPONSE_ACTION:
        case SET_SERVICE_CONTRACTS:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_SERVICE_CONTRACT_REQUEST_ACTION: false,
                },
                serviceContracts: action.payload,
            }
        case SET_PORTFOLIO_SERVICE_CONTRACTS:
            return {
                ...state,
                portfolioServiceContracts: action.contracts,
            }
        case UPDATE_PENDING_BIDS_ACTION:
            return {
                ...state,
                pendingBids: action.payload,
            }
        case UPDATE_BIDS_ACTION:
            return {
                ...state,
                bids: action.payload,
            }
        default:
            return state
    }
}
