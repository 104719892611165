import React from 'react'
import { useLeaseAudit } from '../../Hooks'
import { Button, CircularProgress, useTheme } from '@material-ui/core'
import { Container } from '../../../../components'
import { LeaseAuditDetailPage } from './LeaseAuditDetailPage'
import { LeaseAuditTable } from './Components/LeaseAuditTable'

export const LeaseAuditTab = () => {
    const {
        leaseAuditList,
        loadingState,
        leaseAudit,
        unmatchedPdfLeases,
        matchedLeases,
        leaseAuditFilterState,
        filteredMatchedLeases,
        filteredUnmatchedPDFLeases,
        pendingCount,
        filteredLeaseAuditList,
        leaseAuditSortMethod,
        selectedDDLease,
        numberUploaded,
        promptConfigs,
        modalState,
        getLeaseAudit,
        setLeaseAuditFilterState,
        uploadRentRoll,
        uploadLeasePDF,
        matchLeases,
        unmatchLeases,
        deletePDFLease,
        setLeaseAudit,
        createLeaseAudit,
        setLeaseAuditSortMethod,
        getLeaseAuditList,
        setSelectedDDLease,
        updateDDLease,
        getLeaseAuditSummary,
        getRentRollTemplate,
        getRentRollExample,
        removeAuditBasePDF,
        updateLeaseAudit,
        setModalState,
        getSelectedConfigs,
        getAllPDFLeases,
        generateExcelReport,
    } = useLeaseAudit()

    const theme = useTheme()

    if (loadingState.getLeaseAuditList || !leaseAuditList) {
        return (
            <Container
                style={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress
                    size={100}
                    style={{ marginTop: theme.spacing(10) }}
                />
            </Container>
        )
    }

    return (
        <Container
            style={{ flex: 1, overflow: 'auto', flexDirection: 'column' }}
        >
            {leaseAudit ? (
                <LeaseAuditDetailPage
                    unmatchedPdfLeases={unmatchedPdfLeases}
                    matchedLeases={matchedLeases}
                    loadingState={loadingState}
                    leaseAuditFilterState={leaseAuditFilterState}
                    filteredMatchedLeases={filteredMatchedLeases}
                    filteredUnmatchedPDFLeases={filteredUnmatchedPDFLeases}
                    leaseAudit={leaseAudit}
                    pendingCount={pendingCount}
                    selectedDDLease={selectedDDLease}
                    numberUploaded={numberUploaded}
                    promptConfigs={promptConfigs}
                    modalState={modalState}
                    setLeaseAuditFilterState={setLeaseAuditFilterState}
                    uploadRentRoll={uploadRentRoll}
                    uploadLeasePDF={uploadLeasePDF}
                    matchLeases={matchLeases}
                    unmatchLeases={unmatchLeases}
                    deletePDFLease={deletePDFLease}
                    setLeaseAudit={setLeaseAudit}
                    getLeaseAuditList={getLeaseAuditList}
                    setSelectedDDLease={setSelectedDDLease}
                    updateDDLease={updateDDLease}
                    getLeaseAuditSummary={getLeaseAuditSummary}
                    getRentRollExample={getRentRollExample}
                    getRentRollTemplate={getRentRollTemplate}
                    removeAuditBasePDF={removeAuditBasePDF}
                    updateLeaseAudit={updateLeaseAudit}
                    setModalState={setModalState}
                    getSelectedConfigs={getSelectedConfigs}
                    getLeaseAudit={getLeaseAudit}
                    getAllPDFLeases={getAllPDFLeases}
                    generateExcelReport={generateExcelReport}
                />
            ) : (
                <Container
                    style={{ overflow: 'hidden', flexDirection: 'column' }}
                >
                    <Container
                        style={{
                            margin: theme.spacing(1, 0),
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h5,
                                color: theme.palette.grey[700],
                                fontWeight: theme.typography.fontWeightBold,
                                flex: 1,
                            }}
                        >
                            Lease Audits
                        </span>
                        <Button
                            variant="contained"
                            style={{
                                ...theme.typography.subtitle1,
                                marginLeft: theme.spacing(2),
                                backgroundColor: theme.palette.primary.dark,
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => createLeaseAudit()}
                        >
                            + Create Lease Audit
                        </Button>
                    </Container>
                    <LeaseAuditTable
                        leaseAuditList={filteredLeaseAuditList}
                        theme={theme}
                        leaseAuditSortMethod={leaseAuditSortMethod}
                        getLeaseAudit={getLeaseAudit}
                        setLeaseAuditSortMethod={setLeaseAuditSortMethod}
                    />
                </Container>
            )}
        </Container>
    )
}
