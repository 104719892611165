export const DESIRED_SIZE_PERCENT = 0.8

export const screenH = window.innerHeight
export const screenW = window.innerWidth
export const aspectRatio = screenH / screenW

export const modalH = screenH * DESIRED_SIZE_PERCENT
export const modalW = screenW * DESIRED_SIZE_PERCENT * aspectRatio

export const headerH = 80
export const footerH = 60
