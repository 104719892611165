import React, { useEffect, useState } from 'react'

import { Container } from '../../../components'
import { WorkSpaceUser } from '../../../models'
import {
    Button,
    CircularProgress,
    Tooltip,
    useTheme,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContentText,
    DialogContent,
    Theme,
    IconButton,
    Modal,
    Slide,
    Paper,
} from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import EditIcon from '@material-ui/icons/Edit'

import { FundingSourceList } from './FundingSourceList'
import { dwollaApi } from '../../../contexts'
import { FundingSourceForm } from './FundingSourceForm'
import { BeneficialOwnerList } from './BeneficialOwnerList'
import { BeneficialOwnerForm } from './BeneficialOwnerForm'
import { useUser } from '../../../hooks'
import { EditCustomerForm } from '../Forms/EditCustomerForm'

interface Props {
    selected: boolean
    user: WorkSpaceUser
}

export const SettingsTab = (props: Props) => {
    const theme = useTheme()

    const {
        fundingSources,
        getFundingSources,
        createFundingSource,
        getBeneficialOwners,
        createBeneficialOwner,
        dwollaCustomer,
        certifyCustomer,
        updateCustomer,
    } = dwollaApi()

    const [fundingSourceModalOpen, setFundingSourceModalOpen] = useState(false)
    const [beneficialOwnerModalOpen, setBeneficialOwnerModalOpen] = useState(
        false,
    )
    const [certifyModalOpen, setCertifyModalOpen] = useState(false)
    const [editCustomerModalOpen, setEditCustomerModalOpen] = useState(false)

    // const [businessType, setBusinessType] = useState('llc')
    // const [businessName, setBusinessName] = useState(
    //     props.user.active_workspace.name,
    // )
    // const [doingBusinessAs, setDoingBusinessAs] = useState('')
    // const [
    //     employerIdentificationNumber,
    //     setEmployerIdentificationNumber,
    // ] = useState('')
    // const [website, setWebsite] = useState('')

    // const [disableEditCustomer, setDisableEditCustomer] = useState(true)

    // const [firstName, setFirstName] = useState('')
    // const [lastName, setLastName] = useState('')
    // const [birthdate, setBirthdate] = useState<Date | null>(new Date())
    // const [ssn, setSsn] = useState('')
    // const [address, setAddress] = useState('')
    // const [city, setCity] = useState('')
    // const [zipCode, setZipCode] = useState('')
    // const [state, setState] = useState('')

    const { workspaceUser } = useUser()

    useEffect(() => {
        getFundingSources()
        getBeneficialOwners()
    }, [props.selected])

    // useEffect(() => {
    //     if (dwollaCustomer) {
    //         // setWebsite(dwollaCustomer.website)
    //         // setEmployerIdentificationNumber(dwollaCustomer.ein)
    //         // setDoingBusinessAs(dwollaCustomer.doingBusinessAs)
    //         // setBusinessName(dwollaCustomer.businessName)
    //         // setBusinessType(dwollaCustomer.business_type)
    //     }
    // }, [dwollaCustomer])

    if (!props.selected) {
        return null
    }

    let fundingSourceBody: JSX.Element

    if (fundingSources === null) {
        // Funding sources loading
        fundingSourceBody = <CircularProgress />
    } else {
        fundingSourceBody = (
            <FundingSourceList fundingSources={fundingSources} />
        )
    }

    const userIsAdmin = props.user.id === dwollaCustomer?.account_admin.id

    let certify: JSX.Element

    if (dwollaCustomer?.certification_status === 'certified') {
        certify = (
            <Container style={{ marginLeft: theme.spacing(2) }}>
                <Tooltip title="Beneficial Owners Certified">
                    <CheckCircleOutlineIcon
                        fontSize="large"
                        htmlColor={theme.palette.primary.dark}
                    />
                </Tooltip>
            </Container>
        )
    } else {
        certify = (
            <Container>
                <Button
                    disabled={!userIsAdmin}
                    variant="contained"
                    style={{
                        marginLeft: theme.spacing(2),

                        backgroundColor: !userIsAdmin
                            ? 'grey'
                            : theme.palette.primary.dark,
                        color: theme.palette.primary.contrastText,
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        setCertifyModalOpen(true)
                    }}
                >
                    Certify Beneficial Owners
                </Button>
            </Container>
        )
    }

    const fsLimitReached = (fundingSources?.length ?? 0) > 5

    return (
        <Container style={{ flex: 1, marginTop: theme.spacing(4) }}>
            {/* Funding source column */}
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                <Container style={{ alignItems: 'center' }}>
                    <span
                        style={{
                            ...theme.typography.h5,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        Funding sources
                    </span>

                    <Button
                        variant="contained"
                        style={{
                            marginLeft: theme.spacing(2),
                            backgroundColor: fsLimitReached
                                ? 'grey'
                                : theme.palette.primary.dark,
                            color: theme.palette.primary.contrastText,
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setFundingSourceModalOpen(true)
                        }}
                        disabled={fsLimitReached}
                    >
                        + Create Funding Source
                    </Button>
                </Container>

                {fundingSourceBody}
            </Container>

            {/* Beneficial owners column */}

            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    marginLeft: theme.spacing(4),
                }}
            >
                <CustomerSection
                    onClickEdit={() => {
                        if (dwollaCustomer) {
                            setEditCustomerModalOpen(true)
                        }
                    }}
                    title={'Business Info'}
                    theme={theme}
                    details={[
                        {
                            title: 'Name',
                            value: dwollaCustomer?.businessName ?? '',
                        },

                        {
                            title: 'Business Type',
                            value: dwollaCustomer?.business_type ?? '',
                        },
                    ]}
                />
                <CustomerSection
                    onClickEdit={() => {}}
                    title={'EZ-Pay Admin'}
                    theme={theme}
                    details={[
                        {
                            title: 'Name',
                            value: dwollaCustomer?.account_admin.name ?? '?',
                        },
                        {
                            title: 'Email',
                            value: dwollaCustomer?.account_admin.email ?? '?',
                        },
                    ]}
                />
                {/* <CustomerSection
                    onClickEdit={() => {}}
                    title={'Controller Info'}
                    theme={theme}
                    details={[
                        { title: 'Name', value: 'Test' },
                        { title: 'Title', value: 'Test' },
                        { title: 'Address', value: 'Test' },
                        { title: 'City', value: 'Test' },
                        { title: 'State', value: 'Test' },
                        { title: 'Country', value: 'Test' },
                        { title: 'Zip Code', value: 'Test' },
                    ]}
                /> */}

                {workspaceUser?.active_workspace.company_type ===
                    'APARTMENT' && (
                    <Container
                        style={{
                            flexDirection: 'column',
                            flex: 1,
                            marginTop: theme.spacing(4),
                        }}
                    >
                        <Container style={{ alignItems: 'center' }}>
                            <span
                                style={{
                                    ...theme.typography.h5,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Owners
                            </span>
                            <Button
                                variant="contained"
                                style={{
                                    marginLeft: theme.spacing(2),
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.primary.contrastText,
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setBeneficialOwnerModalOpen(true)
                                }}
                            >
                                + Add Beneficial Owner
                            </Button>

                            {certify}
                        </Container>

                        <BeneficialOwnerList />
                    </Container>
                )}
            </Container>

            <FundingSourceForm
                onSubmit={(body) => {
                    createFundingSource(body).then(() => {
                        setFundingSourceModalOpen(false)
                    })
                }}
                open={fundingSourceModalOpen}
                onClose={() => setFundingSourceModalOpen(false)}
            />

            <BeneficialOwnerForm
                onSubmit={(body) => {
                    createBeneficialOwner(body).then(() => {
                        setBeneficialOwnerModalOpen(false)
                    })
                }}
                open={beneficialOwnerModalOpen}
                onClose={() => setBeneficialOwnerModalOpen(false)}
            />

            <Dialog
                open={certifyModalOpen}
                onClose={() => setCertifyModalOpen(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    Certify Beneficial Owners
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Businesses are required to verify the identity of
                        beneficial owners. A beneficial owner is anyone who owns
                        or controls at least 25% of the company.
                    </DialogContentText>
                </DialogContent>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        I, {props.user.name}, hereby certify, to the best of my
                        knowledge, that the information provided above is
                        complete and correct.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => setCertifyModalOpen(false)}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={certifyCustomer}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Modal
                open={editCustomerModalOpen}
                onClose={() => setEditCustomerModalOpen(false)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="up" in={editCustomerModalOpen}>
                    <Paper
                        style={{
                            display: 'flex',
                            overflow: 'hidden',
                            outline: 'none',
                            borderRadius: '20px',
                            padding: theme.spacing(4),
                        }}
                    >
                        <EditCustomerForm
                            dwollaCustomer={dwollaCustomer}
                            onSubmit={(name) => {
                                setEditCustomerModalOpen(false)
                                updateCustomer(dwollaCustomer!, { name: name })
                            }}
                        />
                    </Paper>
                </Slide>
            </Modal>
        </Container>
    )
}

interface CustomerDetail {
    title: string
    value: string
}

interface CustomerSectionProps {
    title: string
    theme: Theme
    details: CustomerDetail[]
    onClickEdit: () => void
}

const CustomerSection = (props: CustomerSectionProps) => {
    const { title, theme, details, onClickEdit } = props
    return (
        <Container
            style={{
                flexDirection: 'column',
                border: `1px solid ${theme.palette.grey[500]}`,
                marginBottom: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
            }}
        >
            {/* Title */}
            <Container
                style={{
                    borderBottom: `1px solid ${theme.palette.grey[500]}`,
                    padding: theme.spacing(2),
                    alignItems: 'center',
                }}
            >
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {title}
                </span>
                <div style={{ flex: 1 }} />
                {/* <Tooltip title="Edit">
                    <IconButton onClick={onClickEdit}>
                        <EditIcon />
                    </IconButton>
                </Tooltip> */}
            </Container>

            {/* Boday */}
            <Container
                style={{ flexDirection: 'column', padding: theme.spacing(2) }}
            >
                {details.map((detail, idx) => {
                    return (
                        <Container
                            key={`DETAIL_${idx}_${detail.value}`}
                            style={{}}
                        >
                            <Container
                                style={{
                                    width: 150,
                                    justifyContent: 'flex-end',
                                    marginRight: theme.spacing(2),
                                }}
                            >
                                <span
                                    style={{
                                        ...theme.typography.subtitle1,
                                        color: theme.palette.grey[600],
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                    }}
                                >
                                    {detail.title}:
                                </span>
                            </Container>

                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {detail.value}
                            </span>
                        </Container>
                    )
                })}
            </Container>
        </Container>
    )
}
