import React from 'react'
import { Container } from '../../../components'

import { DwollaFundingSource } from '../../../models'
import { FundingSourceRow } from './FundingSourceRow'
import { dwollaApi } from '../../../contexts'

interface Props {
    fundingSources: DwollaFundingSource[]
}

export const FundingSourceList = (props: Props) => {
    const { fundingSources } = props

    const { dwollaCustomer } = dwollaApi()

    return (
        <Container
            style={{
                flexWrap: 'wrap',
            }}
        >
            {fundingSources.map((fs) => {
                return (
                    <FundingSourceRow
                        key={`FUNDING_SOURCE_${fs.id}`}
                        fundingSource={fs}
                        isDefault={
                            fs.id === dwollaCustomer?.default_funding_source
                        }
                    />
                )
            })}
        </Container>
    )
}
