import {
    Button,
    CircularProgress,
    MenuItem,
    TextField,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { axiosInstance, statesList } from '../../helpers'
import { Container } from '../Container'
import {
    Address,
    Company,
    IdentifiableNamedObject,
    Organization,
} from '../../models'
import { toast } from 'react-toastify'
import { Selector } from '../Selector'

interface Props {}

interface EntrataProperty extends Address, IdentifiableNamedObject {}

export const EntrataOnboardForm = (props: Props) => {
    const theme = useTheme()

    const [organizationList, setOrganizationList] = useState<
        Organization[] | null
    >(null)

    const [getPropertiesLoading, setGetPropertiesLoading] = useState(false)

    const [selectedOrgId, setSelecetedOrgId] = useState(-1)
    const [
        orgForCurrentEntrataProperties,
        setOrgForCurrentEntrataProperties,
    ] = useState(-1)
    const [selectedEntrataId, setSelectedEntrataId] = useState(-1)

    const [entrataProperties, setEntrataProperties] = useState<
        EntrataProperty[]
    >([])

    const selectorStyle: React.CSSProperties = {
        flex: 1,
        marginRight: theme.spacing(1),
    }

    const buttonStyle: React.CSSProperties = { maxHeight: 50, minWidth: 125 }

    useEffect(() => {
        axiosInstance
            .get('company/organization/', { params: { entrata: true } })
            .then((res) => {
                setOrganizationList(res.data)
            })
            .catch((e) => toast.error(e))
    }, [])

    return (
        <Container
            style={{
                flex: 1,
                padding: theme.spacing(2),
                flexDirection: 'column',
                overflow: 'scroll',
            }}
        >
            <span
                style={{
                    ...theme.typography.h5,
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >
                Entrata Onboard
            </span>
            <Container
                style={{ alignItems: 'center', marginTop: theme.spacing(2) }}
            >
                <Selector
                    label="Organization"
                    data={organizationList ?? []}
                    currentValue={selectedOrgId}
                    onChange={(
                        event: React.ChangeEvent<{
                            value: unknown
                        }>,
                    ) => {
                        const orgId = event.target.value as number
                        setSelecetedOrgId(orgId)
                    }}
                    getDisplayString={(org) => org.name}
                    customStyle={{
                        formControl: selectorStyle,
                    }}
                    maxItems={100}
                    searchable
                />
                {getPropertiesLoading ? (
                    <CircularProgress size={60} />
                ) : (
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={selectedOrgId === -1}
                        style={buttonStyle}
                        onClick={() => {
                            const selectedOrg = organizationList?.find(
                                (org) => org.id === selectedOrgId,
                            )
                            if (selectedOrg) {
                                setGetPropertiesLoading(true)
                                axiosInstance
                                    .post(
                                        `${selectedOrg.entrata_endpoint}properties`,
                                        {
                                            auth: { type: 'basic' },
                                            method: {
                                                name: 'getProperties',
                                            },
                                        },
                                        {
                                            auth: {
                                                username:
                                                    selectedOrg.entrata_username,
                                                password:
                                                    selectedOrg.entrata_password,
                                            },
                                        },
                                    )
                                    .then((res) => {
                                        const newEntrataProperties: EntrataProperty[] = res.data[
                                            'response'
                                        ]['result']['PhysicalProperty'][
                                            'Property'
                                        ].map((property: any) => {
                                            return {
                                                name: property['MarketingName'],
                                                id: property['PropertyID'],
                                                address1:
                                                    property['Address'][
                                                        'Address'
                                                    ],
                                                address2: '',
                                                city:
                                                    property['Address']['City'],
                                                state:
                                                    property['Address'][
                                                        'StateCode'
                                                    ],
                                                zip_code:
                                                    property['Address'][
                                                        'PostalCode'
                                                    ],
                                                country:
                                                    property['Address'][
                                                        'Country'
                                                    ],
                                            }
                                        })
                                        setOrgForCurrentEntrataProperties(
                                            selectedOrgId,
                                        )
                                        setEntrataProperties(
                                            newEntrataProperties,
                                        )
                                    })
                                    .catch((e) => toast.error(e))
                                    .finally(() => {
                                        setGetPropertiesLoading(false)
                                    })
                            }
                        }}
                    >
                        Search
                    </Button>
                )}
            </Container>
            {getPropertiesLoading ? (
                <Container
                    style={{ justifyContent: 'center', alignItems: 'center' }}
                >
                    <CircularProgress size={100} />
                </Container>
            ) : (
                <Container
                    style={{
                        alignItems: 'center',
                        marginTop: theme.spacing(2),
                    }}
                >
                    <Selector
                        label="Entrata Properties"
                        data={entrataProperties ?? []}
                        currentValue={selectedEntrataId}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            setSelectedEntrataId(event.target.value as number)
                        }}
                        getDisplayString={(property) => property.name}
                        customStyle={{
                            formControl: selectorStyle,
                        }}
                        maxItems={100}
                        searchable
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        disabled={selectedEntrataId === -1}
                        style={buttonStyle}
                        onClick={() => {
                            const selectedProperty = entrataProperties?.find(
                                (prop) => prop.id === selectedEntrataId,
                            )
                            if (selectedProperty) {
                                axiosInstance
                                    .post(`company/entrata-onboard/`, {
                                        organization: orgForCurrentEntrataProperties,
                                        entrata_company_id: selectedProperty.id,
                                        name: selectedProperty.name,
                                        address: selectedProperty.address1,
                                        city: selectedProperty.city,
                                        state: selectedProperty.state ?? 'KY',
                                        zip: selectedProperty.zip_code,
                                    })
                                    .then(() => {
                                        toast.success(
                                            'You will recieve an email upon onboarding completion',
                                        )
                                    })
                                    .catch((e) => toast.error(e))
                            }
                        }}
                    >
                        Onboard
                    </Button>
                </Container>
            )}
            <div style={{ flex: 1 }} />
        </Container>
    )
}
