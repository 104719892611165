import { AxiosResponse } from 'axios'
import { Action } from 'redux'
import {
    Area,
    AreaConfig,
    AreaStatus,
    BaseFolder,
    Inventory,
    InventoryDetail,
    UnitConfig,
    _Workorder,
} from '../../models'

// Requests
export interface GetInfrastructureRequest {
    apartmentId: number
}

export interface CreateFolderRequest {
    body: {
        name: string
        parent: number | null
    }
}

export interface DeleteFolderRequest {
    folderId: number
}

export interface MoveUnitsRequest {
    folderId: number
    body: {
        units: number[]
    }
}

export interface UpdateAreaStatusRequest {
    areaId: number
    unitId: number
    folderId: number
    body: {
        status: AreaStatus
    }
}

export interface GetInventoryListRequest {
    params?: {
        area?: number
    }
}

// Responses

export interface FolderRes {
    id: number
    name: string
    path: string
    path_ids: string
    parent: number | null
    apartment_owner: {
        // TODO: ER-90 possibly type from skyes typeings
        id: number
        name: string
        address: string
        city: string
        state: string
        zip_code: string
        phone: number | null
        company_type: 'APARTMENT' | 'VENDOR' //TODO: ER-90 add types from skyes type file
    }
}

export interface UnitRes {
    id: number
    name: string
    folder_id: number
    unit_config: number
    areas: Area[]
    channel_id: number
    unit_notes_msg_count: number
    unread_unit_notes: number
}

export interface GetInfrastructureResponse {
    folders: FolderRes[]
    units: UnitRes[]
}

export interface UpdateAreaStatusResponse {
    area: {
        id: number
        status: AreaStatus
        area_config: AreaConfig
        area_number: number
    }
    updated_workorders: _Workorder[]
}

export interface GetInfrastructureManagerRequest {
    params: {
        units: string
    }
}

export interface GetInfrastructureManagerRes {
    workorders: _Workorder[]
    inventory: InventoryDetail[]
}

export interface UnitWithFolder {
    id: number
    name: string
    unit_config: UnitConfig
    folder: BaseFolder
}

// actions
export const GET_INFRASTRUCTURE_REQUEST = 'GET_INFRASTRUCTURE_REQUEST'
export const GET_INFRASTRUCTURE_RESPONSE = 'GET_INFRASTRUCTURE_RESPONSE'
export const UPDATE_AREA_STATUS_REQUEST = 'UPDATE_AREA_STATUS_REQUEST'
export const UPDATE_AREA_STATUS_RESPONSE = 'UPDATE_AREA_STATUS_RESPONSE'
export const SET_INFRASTRUCTURE_LOADING = 'SET_INFRASTRUCTURE_LOADING'
export const GET_INVENTORY_REQUEST = 'GET_INVENTORY_REQUEST'
export const SET_INVENTORY = 'SET_INVENTORY'
export const GET_INFRASTRUCTURE_MANAGER_REQUEST =
    'GET_INFRASTRUCTURE_MANAGER_REQUEST'
export const SET_MANAGER_WORKORDER_LIST = 'SET_MANAGER_WORKORDER_LIST'
export const SET_MANAGER_INVENTORY_LIST = 'SET_MANAGER_INVENTORY_LIST'
export const CREATE_FOLDER_REQUEST = 'CREATE_FOLDER_REQUEST'
export const DELETE_FOLDER_REQUEST = 'DELETE_FOLDER_REQUEST'
export const ADD_FOLDER = 'ADD_FOLDER'
export const MOVE_UNITS_REQUEST = 'MOVE_UNITS_REQUEST'
export const SET_UNITS = 'SET_UNITS'
export const DELETE_FOLDER = 'DELETE_FOLDER'

export type InfrastructureRequest =
    | typeof GET_INFRASTRUCTURE_REQUEST
    | typeof UPDATE_AREA_STATUS_REQUEST
    | typeof GET_INVENTORY_REQUEST
    | typeof GET_INFRASTRUCTURE_MANAGER_REQUEST
    | typeof CREATE_FOLDER_REQUEST
    | typeof MOVE_UNITS_REQUEST
    | typeof DELETE_FOLDER_REQUEST

interface SetInfrastructureLoadingAction extends Action {
    type: typeof SET_INFRASTRUCTURE_LOADING
    request: InfrastructureRequest
    loading: boolean
}

interface SetInventoryAction extends Action {
    type: typeof SET_INVENTORY
    inventoryList?: Inventory[]
}

interface GetInfrastructureRequestAction extends Action {
    type: typeof GET_INFRASTRUCTURE_REQUEST
}

interface GetInfrastructureResponnseAction extends Action {
    type: typeof GET_INFRASTRUCTURE_RESPONSE
    payload: GetInfrastructureResponse
}

interface UpdateAreaStatusRequestAction extends Action {
    type: typeof UPDATE_AREA_STATUS_REQUEST
}

export interface UpdateAreaStatusResponseAction extends Action {
    type: typeof UPDATE_AREA_STATUS_RESPONSE
    payload: UpdateAreaStatusResponse
    unitId: number
    folderId: number
}

export interface SetManagerWorkorderListAction extends Action {
    type: typeof SET_MANAGER_WORKORDER_LIST
    workorderList?: _Workorder[]
}

export interface SetManagerInventoryListAction extends Action {
    type: typeof SET_MANAGER_INVENTORY_LIST
    inventoryList?: InventoryDetail[]
}

export interface AddFolder extends Action {
    type: typeof ADD_FOLDER
    folder: FolderRes
}

export interface SetUnits extends Action {
    type: typeof SET_UNITS
    units: UnitRes[]
}

export interface DeleteFolder extends Action {
    type: typeof DELETE_FOLDER
    folderId: number
}

export type InfrastructureActionTypes =
    | GetInfrastructureRequestAction
    | GetInfrastructureResponnseAction
    | UpdateAreaStatusRequestAction
    | UpdateAreaStatusResponseAction
    | SetInventoryAction
    | SetInfrastructureLoadingAction
    | SetManagerWorkorderListAction
    | SetManagerInventoryListAction
    | AddFolder
    | SetUnits
    | DeleteFolder

// Thunks
export type FolderThunk = Promise<AxiosResponse<FolderRes>>
export type UnitListThunk = Promise<AxiosResponse<UnitRes[]>>

export type GetInfrastructureActionThunk = Promise<
    AxiosResponse<GetInfrastructureResponse>
>

export type UpdateAreaStatusActionThunk = Promise<
    AxiosResponse<UpdateAreaStatusResponse>
>

export type GetInventoryListActionThunk = Promise<AxiosResponse<Inventory[]>>

export type GetManagerActionThunk = Promise<
    AxiosResponse<GetInfrastructureManagerRes>
>

// reducers
export interface InfrastructureState {
    infrastructure?: GetInfrastructureResponse
    inventoryList?: Inventory[]
    manager_workorders?: _Workorder[]
    manager_inventory?: InventoryDetail[]
    isLoading: {
        [GET_INFRASTRUCTURE_REQUEST]: boolean
        [UPDATE_AREA_STATUS_REQUEST]: boolean
        [GET_INVENTORY_REQUEST]: boolean
        [GET_INFRASTRUCTURE_MANAGER_REQUEST]: boolean
        [CREATE_FOLDER_REQUEST]: boolean
        [MOVE_UNITS_REQUEST]: boolean
        [DELETE_FOLDER_REQUEST]: boolean
    }
}
