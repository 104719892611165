import React, { useEffect, useMemo, useState } from 'react'
import {
    Blanket,
    BlanketRect,
    Container,
    EditSpan,
    Selector,
    StatusBadge,
    Swiper,
    SyncActionItemModal,
} from '../../components'
import {
    CircularProgress,
    IconButton,
    useTheme,
    Checkbox,
    Theme,
    Tooltip,
    FormControl,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    Badge,
    Modal,
    Button,
} from '@material-ui/core'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import MessageIcon from '@material-ui/icons/Message'
import SyncIcon from '@material-ui/icons/Sync'
import {
    ActionItem,
    ActionItemStatus,
    ActionItemSyncStatus,
    AreaConfig,
    IdBoolMap,
    InventoryConfig,
    InventoryInspection,
    ListVendor,
    ModelListMap,
    ModelMap,
    Service,
    UnitConfig,
    User,
    getUiSyncStatus,
    getUiSyncStatusColor,
} from '../../models'
import {
    useAptConfig,
    useCompany,
    useEntrataWorkOrderPickList,
    useService,
    useUser,
} from '../../hooks'
import { axiosInstance } from '../../helpers'
import { InventoryInspectionComponent } from './InventoryInspectionComponent'
import CloseIcon from '@material-ui/icons/Close'
import { SearchField } from '../../components/SearchField'
import { setActionItemList } from '../../store'
import { WoStatusBadge } from '../VendorScheduler'
import { ActiveChannel } from './InspectionDetail'

interface Props {
    open: boolean
    rect: BlanketRect
    actionItemList: ActionItem[] | null
    inspectionId: number
    onUpdateActionItem: (updatedActionItem: ActionItem) => void
    onClose: () => void
    openMessageDrawer: (channel: number, activeChannel: ActiveChannel) => void
    invInspectionList: InventoryInspection[] | null
}

export const ActionItemDashboard = (props: Props) => {
    const {
        open,
        rect,
        onClose,
        actionItemList,
        onUpdateActionItem,
        openMessageDrawer,
        invInspectionList,
    } = props

    const { userList, getUserList } = useUser()
    const { vendorList, getVendorList } = useCompany()

    const theme = useTheme()

    const { pickList, loadPicklist } = useEntrataWorkOrderPickList()

    const {
        getAreaConfigMap,
        getUnitConfigMap,
        getInventoryConfigMap,
    } = useAptConfig({})

    const areaConfigMap = getAreaConfigMap()
    const unitConfigMap = getUnitConfigMap()
    const invConfigMap = getInventoryConfigMap()
    const { getServiceList, serviceList } = useService()

    const [searchValue, setSearchValue] = useState('')

    const [syncActionItemId, setSyncActionItemId] = useState(-1)

    const [
        selectedActionItemMap,
        setSelectedActionItemMap,
    ] = useState<IdBoolMap>({})

    const onSelectActionItem = (id: number) => {
        setSelectedActionItemMap((prevMap) => {
            const prevVal = prevMap[id] === true

            return {
                ...prevMap,
                [id]: !prevVal,
            }
        })
    }

    const isLoading = actionItemList === null || invInspectionList === null

    useEffect(() => {
        getUserList({ params: { my_team: true } })
        getVendorList({ params: { my_team: true } })
        getServiceList({})
    }, [])

    useEffect(() => {
        if (open && pickList === null) {
            console.log('load picklist')
            loadPicklist()
        }
    }, [open, pickList])

    const filteredActionItems = actionItemList?.filter((actionItem) => {
        const cleanSearchValue = searchValue.toLocaleLowerCase()
        const unitValid = actionItem.unit_location?.name
            .toLocaleLowerCase()
            .includes(cleanSearchValue)
        const fullPath =
            (actionItem.folder_location?.path.toLocaleLowerCase() ?? '') +
            actionItem.folder_location?.name.toLocaleLowerCase()

        console.log(actionItem.folder_location)
        const pathValid = fullPath.includes(cleanSearchValue)

        return pathValid || unitValid
    })

    const invInspectionAiMap: ModelListMap<ActionItem> = {}
    filteredActionItems?.forEach((actionItem) => {
        const invInsId = actionItem.inventory_inspection ?? -1
        if (invInspectionAiMap[invInsId] === undefined) {
            invInspectionAiMap[invInsId] = []
        }
        invInspectionAiMap[invInsId]?.push(actionItem)
    })

    const selectedActionItem = useMemo(() => {
        return filteredActionItems?.find((ai) => ai.id === syncActionItemId)
    }, [syncActionItemId])

    return (
        <Blanket open={open} rect={rect}>
            <Container
                style={{
                    backgroundColor: theme.palette.grey[100],
                    flex: 1,
                    flexDirection: 'column',
                }}
                scrollY
            >
                <Container style={{ margin: theme.spacing(1) }}>
                    <SearchField
                        placeholder="Search"
                        variant="outlined"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />

                    <div style={{ flex: 1 }} />
                    <IconButton onClick={() => onClose()}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Container>

                {isLoading ? (
                    <CircularProgress size={100} />
                ) : (
                    invInspectionList.map((invIns) => {
                        return (
                            <InventoryInspectionRow
                                key={`INVENTORY_INSPECTION_${invIns.id}`}
                                inventoryInspection={invIns}
                                actionItemList={
                                    invInspectionAiMap[invIns.id] ?? []
                                }
                                theme={theme}
                                areaConfigMap={areaConfigMap}
                                unitConfigMap={unitConfigMap}
                                invConfigMap={invConfigMap}
                                userList={userList}
                                vendorList={vendorList}
                                serviceList={serviceList}
                                onUpdateActionItem={onUpdateActionItem}
                                openMessageDrawer={openMessageDrawer}
                                selectedActionItemMap={selectedActionItemMap}
                                onSelectActionItem={onSelectActionItem}
                                openSyncModal={setSyncActionItemId}
                            />
                        )
                    })
                )}
            </Container>
            <SyncActionItemModal
                open={syncActionItemId !== -1}
                handleClose={() => setSyncActionItemId(-1)}
                actionItem={selectedActionItem}
                onSync={onUpdateActionItem}
            />
        </Blanket>
    )
}

interface InventoryInspectionProps {
    inventoryInspection: InventoryInspection
    actionItemList: ActionItem[]
    areaConfigMap: ModelMap<AreaConfig>
    unitConfigMap: ModelMap<UnitConfig>
    invConfigMap: ModelMap<InventoryConfig>
    theme: Theme
    vendorList: ListVendor[]
    userList: User[]
    serviceList: Service[]
    selectedActionItemMap: IdBoolMap
    onSelectActionItem: (id: number) => void
    onUpdateActionItem: (updatedActionItem: ActionItem) => void
    openMessageDrawer: (channel: number, activeChannel: ActiveChannel) => void
    openSyncModal: (actionItemId: number) => void
}

const InventoryInspectionRow = (props: InventoryInspectionProps) => {
    const {
        inventoryInspection,
        areaConfigMap,
        unitConfigMap,
        invConfigMap,
        theme,
        vendorList,
        userList,
        serviceList,
        onUpdateActionItem,
        openMessageDrawer,
        selectedActionItemMap,
        onSelectActionItem,
        openSyncModal,
    } = props

    // Get the first action item out of the action item list
    // There should always be > 0 action items because the backend
    // request that returned these inventory inspections was filtered
    // to only include rows that have > 0 action items.
    // This is the easiest way to retrieve the unit / folder info
    const firstActionItem = props.actionItemList[0]
    if (firstActionItem === undefined) {
        return null
    }

    const folder = firstActionItem.folder_location

    // Type decloration does not include unit_config
    const unit: any = firstActionItem.unit_location

    const area = firstActionItem.area
    const areaConfig = areaConfigMap[area?.area_config ?? -1]
    const unitConfig = unitConfigMap[unit?.unit_config ?? -1]

    return (
        <Container
            style={{
                flexDirection: 'column',
                marginBottom: theme.spacing(2),
            }}
        >
            {/* Inv inspection info */}
            <Container
                style={{
                    border: `1px solid ${theme.palette.grey[600]}`,
                    borderRadius: theme.shape.borderRadius,
                    padding: theme.spacing(1),
                    backgroundColor: theme.palette.grey[200],
                    alignItems: 'center',
                }}
            >
                {/* Location Information */}
                <Container
                    style={{
                        flexDirection: 'column',
                        width: COLUMN_1_WIDTH,
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightLight,
                        }}
                    >
                        {folder?.path} {folder?.name}
                    </span>

                    <Container>
                        <span
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,

                                marginRight: theme.spacing(1),
                            }}
                        >
                            {unit?.name}
                        </span>
                        <span
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightLight,

                                marginRight: theme.spacing(1),
                            }}
                        >
                            {areaConfig?.name} ({area?.area_label})
                        </span>
                    </Container>

                    <span
                        style={{
                            ...theme.typography.caption,
                            fontWeight: theme.typography.fontWeightMedium,
                        }}
                    >
                        {unitConfig?.name}
                    </span>
                </Container>

                <InventoryInspectionComponent
                    inventoryInspection={inventoryInspection}
                    editMode={false}
                    inventoryConfigMap={invConfigMap}
                    onClick={() =>
                        openMessageDrawer(inventoryInspection.channel ?? -1, {
                            type: 'inventory-inspection',
                            modelId: inventoryInspection.id,
                        })
                    }
                />
            </Container>

            <Container
                style={{
                    flexDirection: 'column',
                    border: `1px solid ${theme.palette.grey[500]}`,
                    borderTop: 'none',
                    marginLeft: theme.spacing(2),
                    borderBottomLeftRadius: 8,
                }}
            >
                {props.actionItemList.map((actionItem, idx) => {
                    return (
                        <ActionItemRow
                            key={`ACTION_ITEM_ROW_${actionItem.id}`}
                            actionItem={actionItem}
                            isLast={idx === props.actionItemList.length - 1}
                            theme={theme}
                            userList={userList}
                            vendorList={vendorList}
                            serviceList={serviceList}
                            onUpdateActionItem={onUpdateActionItem}
                            openMessageDrawer={openMessageDrawer}
                            selectedActionItemMap={selectedActionItemMap}
                            onSelectActionItem={onSelectActionItem}
                            openSyncModal={openSyncModal}
                        />
                    )
                })}
            </Container>
        </Container>
    )
}

interface ActionItemProps {
    actionItem: ActionItem
    isLast: boolean
    theme: Theme
    vendorList: ListVendor[]
    userList: User[]
    serviceList: Service[]
    selectedActionItemMap: IdBoolMap
    onSelectActionItem: (id: number) => void
    onUpdateActionItem: (updatedActionItem: ActionItem) => void
    openMessageDrawer: (channel: number, activeChannel: ActiveChannel) => void
    openSyncModal: (actionItemId: number) => void
}

const ActionItemRow = (props: ActionItemProps) => {
    const {
        actionItem,
        theme,
        openMessageDrawer,
        selectedActionItemMap,
        onSelectActionItem,
    } = props

    const borderBottom = props.isLast
        ? 'none'
        : `1px solid ${theme.palette.grey[500]}`

    let approveSection: JSX.Element | null = null
    if (actionItem.status === ActionItemStatus.STAGED) {
        approveSection = (
            <>
                <Tooltip title="Approve">
                    <IconButton
                        onClick={() =>
                            updateServer({ status: ActionItemStatus.ASSIGNED })
                        }
                    >
                        <ThumbUpIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Deny">
                    <IconButton
                        onClick={() =>
                            updateServer({ status: ActionItemStatus.REJECTED })
                        }
                    >
                        <ThumbDownIcon />
                    </IconButton>
                </Tooltip>
            </>
        )
    }

    const [selectedCompanyId, setSelectedCompanyId] = useState(-1)
    const [selectedUserId, setSelectedUserId] = useState(-1)
    const [selectedServiceId, setSelectedServiceId] = useState(-1)

    const [swiperPosition, setSwiperPosition] = useState(ASSIGN_TO_USER)

    const updateServer = (body: any) => {
        axiosInstance
            .patch(`action-item/${actionItem.id}/`, body)
            .then((res) => {
                console.log(res.data)
                props.onUpdateActionItem(res.data)
            })
    }

    const handleAssignTo = (e: any) => {
        let serverKey = 'assign_to_company'
        let stateHandler = setSelectedCompanyId
        if (swiperPosition === ASSIGN_TO_USER) {
            stateHandler = setSelectedUserId
            serverKey = 'assign_to_user'
        }

        stateHandler(Number(e.target.value))

        updateServer({ [serverKey]: Number(e.target.value) })
    }

    useEffect(() => {
        const assignToId = actionItem.assigned_to_id ?? -1

        setSelectedServiceId(actionItem.service?.id ?? -1)

        if (actionItem.who_type === 'WHO_COMPANY') {
            setSelectedCompanyId(assignToId)
            setSelectedUserId(-1)
            setSwiperPosition(ASSIGN_TO_VENDOR)
        } else if (actionItem.who_type === 'WHO_USER') {
            setSelectedUserId(assignToId)
            setSelectedCompanyId(-1)
            setSwiperPosition(ASSIGN_TO_USER)
        } else {
            setSelectedCompanyId(-1)
            setSelectedUserId(-1)
        }
    }, [actionItem])

    let syncButton: JSX.Element | null = null

    if (
        actionItem.sync_status === ActionItemSyncStatus.FAILED ||
        actionItem.sync_status === ActionItemSyncStatus.NOT_SYNCED
    ) {
        syncButton = (
            <Tooltip title="Send to Entrata">
                <IconButton onClick={() => props.openSyncModal(actionItem.id)}>
                    <SyncIcon />
                </IconButton>
            </Tooltip>
        )
    }

    return (
        <Container
            key={`ACTION_ITEM_${actionItem.id}`}
            style={{
                borderBottom: borderBottom,
                padding: theme.spacing(1),
                height: 120,
            }}
        >
            <Container
                style={{ flexDirection: 'column', width: COLUMN_1_WIDTH }}
            >
                <span
                    style={{
                        ...theme.typography.caption,
                        fontWeight: theme.typography.fontWeightLight,
                        marginRight: theme.spacing(1),
                    }}
                >
                    #{actionItem.id}
                </span>
                <EditSpan
                    onSave={(value) => updateServer({ title: value })}
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightLight,
                        marginRight: theme.spacing(1),
                    }}
                >
                    {actionItem.title}
                </EditSpan>
            </Container>

            <Container
                style={{
                    flexDirection: 'column',
                    width: INV_INS_WIDTH / 2,
                }}
            >
                <div style={{ width: 100 }}>
                    <WoStatusBadge status={actionItem.status} theme={theme} />
                </div>
                <Container
                    style={{
                        flex: 1,
                        marginTop: theme.spacing(1),
                    }}
                >
                    {approveSection}
                </Container>
            </Container>

            <Container
                style={{
                    flexDirection: 'column',
                    width: INV_INS_WIDTH / 2,
                }}
            >
                <FormControlLabel
                    control={
                        <Switch
                            checked={actionItem.priority}
                            color="primary"
                            onChange={() =>
                                updateServer({ priority: !actionItem.priority })
                            }
                        />
                    }
                    labelPlacement="end"
                    label="Priority"
                />
                <div>
                    <IconButton
                        onClick={() =>
                            openMessageDrawer(actionItem.channel, {
                                type: 'action-item',
                                modelId: actionItem.id,
                            })
                        }
                    >
                        <Badge
                            badgeContent={actionItem.message_count}
                            color="secondary"
                            showZero={false}
                        >
                            <MessageIcon />
                        </Badge>
                    </IconButton>
                </div>
            </Container>

            <Container
                style={{
                    flexDirection: 'column',
                }}
            >
                <div style={{ flex: 1 }} />
                <Selector
                    data={[
                        { id: -1, name: 'None Selected' },
                        ...props.serviceList,
                    ]}
                    currentValue={selectedServiceId}
                    onChange={(e) => {
                        setSelectedServiceId(Number(e.target.value))

                        axiosInstance
                            .patch(`action-item/${actionItem.id}/`, {
                                service: e.target.value,
                            })
                            .then((res) => {
                                props.onUpdateActionItem(res.data)
                            })
                    }}
                    label="Service"
                    variant="standard"
                    getDisplayString={(d) => d.name}
                    customStyle={{
                        formControl: { alignSelf: 'flex-end' },
                    }}
                    searchable
                />
            </Container>

            {/* Assigned To Swiper */}
            <Container
                style={{
                    flexDirection: 'column',
                    marginLeft: theme.spacing(1),
                }}
            >
                <span
                    style={{
                        ...theme.typography.caption,
                        fontWeight: theme.typography.fontWeightLight,
                        marginRight: theme.spacing(1),
                    }}
                >
                    Assigned To
                </span>
                <Container
                    style={{
                        border: `1px solid ${theme.palette.grey[500]}`,
                        borderRadius: theme.shape.borderRadius,
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: theme.spacing(1),
                    }}
                >
                    <Swiper
                        position={swiperPosition}
                        onChangePosition={() => setSwiperPosition((p) => !p)}
                        leftItem={
                            <FormControl fullWidth>
                                <Select
                                    value={selectedUserId}
                                    label="My Team"
                                    placeholder="My Team"
                                    onChange={handleAssignTo}
                                >
                                    <MenuItem
                                        key={`NO_USER_SELECTED`}
                                        value={-1}
                                    >
                                        <span
                                            style={{
                                                color: theme.palette.grey[700],
                                            }}
                                        >
                                            Not Assigned (My Team)
                                        </span>
                                    </MenuItem>
                                    {props.userList.map((user) => {
                                        return (
                                            <MenuItem
                                                key={`SELECT_USER_${user.id}`}
                                                value={user.id}
                                            >
                                                {user.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        }
                        rightItem={
                            <FormControl fullWidth>
                                <Select
                                    value={selectedCompanyId}
                                    placeholder="My Vendors"
                                    label="My Vendors"
                                    onChange={handleAssignTo}
                                >
                                    <MenuItem
                                        key={`NO_VENDOR_SELECTED`}
                                        value={-1}
                                    >
                                        <span
                                            style={{
                                                color: theme.palette.grey[700],
                                            }}
                                        >
                                            Not Assigned (My Vendors)
                                        </span>
                                    </MenuItem>
                                    {props.vendorList.map((vnd) => {
                                        return (
                                            <MenuItem
                                                key={`SELECT_VENDOR_${vnd.id}`}
                                                value={vnd.id}
                                            >
                                                {vnd.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        }
                    />
                </Container>
            </Container>

            <Container
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 16,
                }}
            >
                <StatusBadge
                    text={getUiSyncStatus(actionItem.sync_status)}
                    customStyle={{
                        container: {
                            backgroundColor: getUiSyncStatusColor(
                                actionItem.sync_status,
                            ),
                        },
                    }}
                />
                {syncButton}
            </Container>
        </Container>
    )
}

const ASSIGN_TO_VENDOR = true
const ASSIGN_TO_USER = false

const COLUMN_1_WIDTH = 200

const INV_INS_WIDTH = 320
