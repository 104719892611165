import React, { ReactNode, useEffect, useState } from 'react'

import {
    AuditPromptConfig,
    DDLease,
    MatchedLease,
} from '../../../../../../models'

import Grid from '@mui/material/Grid'
import { CellStyle } from './CellStyle'
import { doesMatch, getCellValue } from '../../Services'
import { UpdateDDLeaseRequest } from '../../../../Hooks/useLeaseAudit'
import { SignatureModal } from '../SignatureModal/SignatureModal'
import { EditLeaseModal } from '../EditLeaseModal/EditLeaseModal'

interface Props {
    lease: MatchedLease
    key: string
    filteredConfigs: AuditPromptConfig[]
    promptConfigs: AuditPromptConfig[]
    updateDDLease: (request: UpdateDDLeaseRequest) => Promise<DDLease>
    getLeaseAudit: (leaseAuditID: number) => void
}

export const TableRow = (props: Props) => {
    const {
        lease,
        key,
        filteredConfigs,
        promptConfigs,
        updateDDLease,
        getLeaseAudit,
    } = props
    const [openSignatureModal, setOpenSignatureModal] = useState(false)
    const [openEditLeaseModal, setOpenEditLeaseModal] = useState(false)
    const [selectedLease, setSelectedLease] = useState<MatchedLease | null>(
        null,
    )

    const handleOpenSignatureModal = (lease: MatchedLease) => {
        setSelectedLease(lease)
        setOpenSignatureModal(true)
    }

    const handleCloseSignatureModal = () => {
        setOpenSignatureModal(false)
        setSelectedLease(null)
    }

    const handleOpenEditLeaseModal = (lease: MatchedLease) => {
        setSelectedLease(lease)
        setOpenEditLeaseModal(true)
    }

    const handleCloseEditLeaseModal = () => {
        setOpenEditLeaseModal(false)
        setSelectedLease(null)
    }

    return (
        <React.Fragment key={key}>
            {filteredConfigs.map((col, idx) => (
                <Grid item width={col.width} height={50} key={idx}>
                    {doesMatch(lease, col) ? (
                        <CellStyle
                            format={col.format}
                            value={getCellValue(lease, col)}
                            backgroundColor="transparent"
                            lease={lease}
                            handleOpenSignatureModal={handleOpenSignatureModal}
                            handleOpenEditLeaseModal={handleOpenEditLeaseModal}
                        />
                    ) : (
                        <CellStyle
                            format={col.format}
                            value={getCellValue(lease, col)}
                            backgroundColor="#fac5c5"
                            lease={lease}
                            handleOpenSignatureModal={handleOpenSignatureModal}
                            handleOpenEditLeaseModal={handleOpenEditLeaseModal}
                        />
                    )}
                </Grid>
            ))}

            <SignatureModal
                open={openSignatureModal}
                promptConfigs={promptConfigs}
                selectedLease={selectedLease}
                updateDDLease={updateDDLease}
                getLeaseAudit={getLeaseAudit}
                handleCloseModal={handleCloseSignatureModal}
            />
            <EditLeaseModal
                open={openEditLeaseModal}
                promptConfigs={promptConfigs}
                handleCloseModal={handleCloseEditLeaseModal}
                selectedLease={selectedLease}
                getLeaseAudit={getLeaseAudit}
                updateDDLease={updateDDLease}
            />
        </React.Fragment>
    )
}
