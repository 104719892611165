import React from 'react'

import { Container } from '../../../components'
import { EZPayTransactionFilterState } from './InvoiceTab'
import {
    DwollaFundingSourceStatus,
    DwollaTransferStatus,
    ListVendor,
} from '../../../models'
import { Chip, useTheme } from '@material-ui/core'
import { dwollaApi } from '../../../contexts'

interface Props {
    filterState: EZPayTransactionFilterState
    setFilterState: React.Dispatch<
        React.SetStateAction<EZPayTransactionFilterState>
    >
    vendorList: ListVendor[]
}

export const EZPayFilterChips = (props: Props) => {
    const { filterState, vendorList, setFilterState } = props

    const { fundingSources } = dwollaApi()

    const theme = useTheme()

    const chipStyle: React.CSSProperties = {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        marginRight: theme.spacing(1),
    }

    return (
        <Container>
            {filterState.vendorId !== -1 && (
                <Chip
                    clickable
                    style={chipStyle}
                    onClick={() => {
                        setFilterState((prev) => {
                            return { ...prev, vendorId: -1 }
                        })
                    }}
                    label={`${
                        vendorList.find((v) => v.id === filterState.vendorId)
                            ?.name
                    }`}
                />
            )}

            {filterState.fundingSourceId !== -1 && (
                <Chip
                    clickable
                    style={chipStyle}
                    onClick={() => {
                        setFilterState((prev) => {
                            return { ...prev, fundingSourceId: -1 }
                        })
                    }}
                    label={`${
                        fundingSources?.find(
                            (fs) => fs.id === filterState.fundingSourceId,
                        )?.name
                    }`}
                />
            )}

            {filterState.status.request && (
                <Chip
                    clickable
                    style={chipStyle}
                    onClick={() => {
                        setFilterState((prev) => {
                            return {
                                ...prev,
                                status: {
                                    ...prev.status,
                                    [DwollaTransferStatus.REQUEST]: false,
                                },
                            }
                        })
                    }}
                    label="Payment Request"
                />
            )}

            {filterState.status.pending && (
                <Chip
                    clickable
                    style={chipStyle}
                    onClick={() => {
                        setFilterState((prev) => {
                            return {
                                ...prev,
                                status: {
                                    ...prev.status,
                                    [DwollaTransferStatus.PENDING]: false,
                                },
                            }
                        })
                    }}
                    label="Pending"
                />
            )}
            {filterState.status.processed && (
                <Chip
                    clickable
                    style={chipStyle}
                    onClick={() => {
                        setFilterState((prev) => {
                            return {
                                ...prev,
                                status: {
                                    ...prev.status,
                                    [DwollaTransferStatus.PROCESSED]: false,
                                },
                            }
                        })
                    }}
                    label="Processed"
                />
            )}
            {filterState.status.cancelled && (
                <Chip
                    clickable
                    style={chipStyle}
                    onClick={() => {
                        setFilterState((prev) => {
                            return {
                                ...prev,
                                status: {
                                    ...prev.status,
                                    [DwollaTransferStatus.CANCELLED]: false,
                                },
                            }
                        })
                    }}
                    label="Cancelled"
                />
            )}
            {filterState.status.failed && (
                <Chip
                    clickable
                    style={chipStyle}
                    onClick={() => {
                        setFilterState((prev) => {
                            return {
                                ...prev,
                                status: {
                                    ...prev.status,
                                    [DwollaTransferStatus.FAILED]: false,
                                },
                            }
                        })
                    }}
                    label="Failed"
                />
            )}
        </Container>
    )
}
