import React, { useEffect, useState } from 'react'
import {
    Container,
    MessageForm,
    SideDrawer,
    SideDrawerContainer,
} from '../../components'
import { Bid, BidStatus, RFP, RFPType } from '../../models'
import {
    Avatar,
    Button,
    IconButton,
    Modal,
    Paper,
    Slide,
    Theme,
    useTheme,
    Badge,
    FormControlLabel,
    Switch,
} from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { RFPStatusBadge } from './RFPStatusBadge'
import { CreateAndEditRFP } from './CreateAndEditRFP'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { getDifferenceInDays, getInitials } from '../../helpers'
import { usePagination } from '../../hooks'
import { Pagination } from '@material-ui/lab'
import { rfpApi, updateOrCreateBidRequest } from '../../contexts'
import { BidForm } from './BidForm'
import { ChatBubbleOutline } from '@material-ui/icons'

interface Props {
    selectedRFP: RFP
    setOpenRFPDetail: (open: boolean) => void
}

export const ApartmentRFPDetail = (props: Props) => {
    const { selectedRFP, setOpenRFPDetail } = props

    const [viewRFPForm, setViewRFPForm] = useState(false)
    const [openBidDetail, setOpenBidDetail] = useState(false)

    const [channelId, setChannelId] = useState(-1)

    const {
        filteredBidList,
        bidFilterState,
        selectedBid,
        bidList,
        setSelectedBid,
        updateRFP,
        updateBid,
        updateRFPState,
        setSelectedRFP,
        setBidList,
        setBidFilterState,
    } = rfpApi()

    const theme = useTheme()

    const { page, setPage, pageData, numPages, start, end } = usePagination(
        filteredBidList ?? [],
    )

    const serviceList = useSelector(
        (state: RootState) => state.service.serviceList,
    )

    const handleClose = () => {
        setOpenBidDetail(false)
        setSelectedBid(null)
    }

    useEffect(() => {
        if (selectedRFP) {
            setBidList(selectedRFP.bids)
            setBidFilterState({ ...bidFilterState, includeUnsolicited: false })
        }
    }, [selectedRFP])

    let daysRemaining = 0
    if (selectedRFP.project_submission_deadline) {
        const today = new Date()
        daysRemaining = getDifferenceInDays(
            new Date(selectedRFP.project_submission_deadline),
            today,
        )
    }

    const headerStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.darkGreen.main,
        flex: 1,
    }

    const unsolicitedBidCount =
        bidList?.filter((bid) => bid.is_unsolicited).length ?? 0
    const showUnsolicitedBidToggle =
        unsolicitedBidCount > 0 && selectedRFP.type === RFPType.INVITE_ONLY

    if (viewRFPForm) {
        return (
            <CreateAndEditRFP
                onExit={() => {
                    setViewRFPForm(false)
                }}
                serviceList={serviceList}
                viewMode={viewRFPForm}
            />
        )
    }

    return (
        <SideDrawerContainer open={channelId !== -1} width={400}>
            <Container direction="column" flex={1}>
                <Container style={{ width: '100%' }}>
                    <IconButton
                        style={{
                            ...theme.typography.body2,
                            cursor: 'pointer',
                            color: theme.palette.darkGreen.main,
                        }}
                        onClick={() => setOpenRFPDetail(false)}
                        size="small"
                    >
                        <ArrowBack
                            style={{ marginRight: theme.spacing(0.5) }}
                        />{' '}
                        Back to RFP Dashboard
                    </IconButton>
                </Container>
                <Container
                    direction="column"
                    style={{ padding: theme.spacing(2) }}
                >
                    <Container>
                        <Container
                            style={{
                                ...theme.typography.h4,
                                fontWeight: 600,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            {selectedRFP.name}
                        </Container>
                        <Container flex={1} />
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: 'white',
                                color: theme.palette.primary.dark,
                                borderColor: theme.palette.primary.dark,
                                textTransform: 'none',
                                cursor: 'pointer',
                                width: '150px',
                                marginLeft: theme.spacing(2),
                                height: '40px',
                            }}
                            onClick={() => {
                                setViewRFPForm(true)
                            }}
                        >
                            More Details
                        </Button>
                    </Container>
                    <Container>
                        <RFPStatusBadge
                            theme={theme}
                            status={selectedRFP.status}
                            style={{
                                container: {
                                    width: '100px',
                                },
                            }}
                        />
                    </Container>
                    <Container>
                        {selectedRFP.project_submission_deadline && (
                            <Container
                                style={{
                                    ...theme.typography.subtitle1,
                                    margin: theme.spacing(1, 1, 0, 0),
                                    alignItems: 'center',
                                }}
                            >
                                Days Remaining: {daysRemaining}
                            </Container>
                        )}
                    </Container>
                </Container>
                <Container
                    direction="column"
                    style={{
                        border: `1px solid ${theme.palette.grey[300]}`,
                        borderRadius: theme.shape.borderRadius,
                        padding: theme.spacing(1),
                        marginTop: theme.spacing(1),
                    }}
                >
                    <Container>
                        <Container
                            style={{
                                ...theme.typography.h6,
                                fontWeight: 500,
                                color: theme.palette.primary.dark,
                                marginTop: theme.spacing(1),
                            }}
                        >
                            Vendor Bids and Statuses
                        </Container>
                        <Container flex={1} />
                        {showUnsolicitedBidToggle && (
                            <Container>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={
                                                bidFilterState.includeUnsolicited
                                            }
                                            color="primary"
                                            onChange={() =>
                                                setBidFilterState({
                                                    ...bidFilterState,
                                                    includeUnsolicited: !bidFilterState.includeUnsolicited,
                                                })
                                            }
                                        />
                                    }
                                    labelPlacement="start"
                                    label={`Include Unsolicited Bids (${unsolicitedBidCount})`}
                                    style={{
                                        margin: theme.spacing(1, 0, 0, 1),
                                    }}
                                />
                            </Container>
                        )}
                    </Container>
                    <Container
                        style={{
                            marginTop: theme.spacing(2),
                            padding: theme.spacing(0, 2),
                        }}
                    >
                        <Container
                            style={{
                                marginRight: theme.spacing(3),
                                width: '45px',
                            }}
                        />
                        <Container style={{ ...headerStyle }}>Vendor</Container>
                        <Container style={{ ...headerStyle }}>
                            Location
                        </Container>
                        <Container style={{ ...headerStyle }}>
                            Financial Question Total
                        </Container>
                        <Container style={{ ...headerStyle }}>
                            # Bid Line Items
                        </Container>
                        <Container style={{ ...headerStyle }}>Status</Container>
                        <Container
                            style={{
                                width: '50px',
                                marginRight: theme.spacing(2),
                            }}
                        />
                    </Container>
                    <Container
                        direction="column"
                        scrollY
                        flex={1}
                        style={{
                            minHeight: 'calc(100vh - 450px)',
                            maxHeight: 'calc(100vh - 450px',
                        }}
                    >
                        {pageData.map((bid) => {
                            return (
                                <BidRow
                                    key={`RFP_${selectedRFP.id}_BID_${bid.id}`}
                                    bid={bid}
                                    theme={theme}
                                    isSelected={bid.id === selectedBid?.id}
                                    setSelectedBid={(bid) => {
                                        if (
                                            bid.status === BidStatus.SUBMITTED
                                        ) {
                                            const body: updateOrCreateBidRequest = {
                                                status: BidStatus.REVIEWED,
                                            }
                                            updateBid(bid.id, body, selectedRFP)
                                        }
                                        setSelectedBid(bid)
                                        setOpenBidDetail(true)
                                    }}
                                    openMessageDrawer={() => {
                                        if (bid.channel_id) {
                                            setSelectedBid(bid)
                                            setChannelId(bid.channel_id)
                                        }
                                    }}
                                />
                            )
                        })}
                    </Container>
                    <Container
                        style={{
                            borderTop: `1px solid ${theme.palette.grey[400]}`,
                            padding: theme.spacing(1),
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Pagination
                            count={numPages}
                            page={page}
                            onChange={(_: unknown, newPage: number) => {
                                setPage(newPage)
                            }}
                        />
                        <span>
                            {start} - {end} of {selectedRFP.bids?.length ?? 0}
                        </span>
                    </Container>
                </Container>
            </Container>
            <Modal
                open={openBidDetail}
                onClose={handleClose}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="up" in={openBidDetail}>
                    <Paper
                        style={{
                            width: 1500,
                            maxWidth: 'calc(100vw - 100px)',
                            height: 1100,
                            maxHeight: 'calc(100vh - 100px)',
                        }}
                    >
                        <Container
                            direction="column"
                            style={{
                                padding: theme.spacing(2),
                                flex: 1,
                                height: '100%',
                            }}
                        >
                            <BidForm
                                rfp={selectedRFP}
                                handleClose={handleClose}
                                setTab={(tab) => {}}
                                bid={selectedBid}
                                apartmentView
                                updateRFP={updateRFP}
                                updateBid={updateBid}
                            />
                        </Container>
                    </Paper>
                </Slide>
            </Modal>
            <SideDrawer
                open={channelId !== -1}
                width={400}
                handleClose={() => {
                    setSelectedBid(null)
                    setChannelId(-1)
                }}
            >
                <MessageForm
                    theme={theme}
                    channelId={channelId}
                    afterCreateMessage={() => {
                        if (selectedBid) {
                            const bids = selectedRFP.bids.map((bid) => {
                                if (bid.id === selectedBid.id) {
                                    return {
                                        ...selectedBid,
                                        message_count:
                                            selectedBid.message_count + 1,
                                    }
                                }
                                return bid
                            })
                            updateRFPState({
                                ...selectedRFP,
                                bids: bids,
                            })
                            setSelectedRFP({
                                ...selectedRFP,
                                bids: bids,
                            })
                        }
                    }}
                />
            </SideDrawer>
        </SideDrawerContainer>
    )
}

interface BidRowProps {
    bid: Bid
    theme: Theme
    isSelected: boolean
    setSelectedBid: (bid: Bid) => void
    openMessageDrawer: () => void
}

const BidRow = (props: BidRowProps) => {
    const { bid, theme, isSelected, setSelectedBid, openMessageDrawer } = props

    const infoStyle: React.CSSProperties = {
        fontSize: '16px',
        fontWeight: 500,
        flex: 1,
    }

    let address = ''
    if (bid.vendor.primary_address?.city && bid.vendor.primary_address.state) {
        address = `${bid.vendor.primary_address.city}, ${bid.vendor.primary_address.state}`
    } else if (bid.vendor.primary_address?.state) {
        address = bid.vendor.primary_address.state
    }

    let financialTotal: string = ''
    if (bid.status !== BidStatus.INVITATION) {
        let newFinancialTotal = 0
        bid.bid_line_items
            .filter((lineItem) => lineItem.apartment_question)
            .forEach(
                (lineItem) =>
                    (newFinancialTotal =
                        newFinancialTotal + Number(lineItem.price)),
            )
        financialTotal = `$${newFinancialTotal}`
    }

    let numberExtras: number | null = null
    if (bid.status !== BidStatus.INVITATION) {
        numberExtras = bid.bid_line_items.filter(
            (lineItem) => !lineItem.apartment_question,
        ).length
    }

    return (
        <Container
            style={{
                margin: theme.spacing(1),
                padding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.palette.grey[600]}`,
                cursor: 'pointer',
                backgroundColor: isSelected
                    ? theme.palette.grey[300]
                    : undefined,
                maxHeight: 75,
                minHeight: 75,
                alignItems: 'center',
                overflow: 'hidden',
            }}
            onClick={() => {
                setSelectedBid(bid)
            }}
        >
            <Container style={{ marginRight: theme.spacing(3) }}>
                {bid.vendor.profile_picture !== null ? (
                    <img
                        src={bid.vendor.profile_picture}
                        style={{
                            width: '45px',
                            height: '45px',
                        }}
                    />
                ) : (
                    <Avatar
                        variant="square"
                        style={{
                            backgroundColor: theme.palette.primary.dark,
                            width: '45px',
                            height: '45px',
                            fontSize: 20,
                        }}
                    >
                        {getInitials(bid.vendor.name)}
                    </Avatar>
                )}
            </Container>
            <Container style={{ ...infoStyle }}>{bid.vendor.name}</Container>
            <Container style={{ ...infoStyle }}>{address}</Container>
            <Container style={{ ...infoStyle }}>{financialTotal}</Container>
            <Container style={{ ...infoStyle }}>{numberExtras ?? ''}</Container>
            <Container style={{ ...infoStyle }}>
                <RFPStatusBadge status={bid.status} theme={theme} />
            </Container>
            <Container style={{ width: '50px' }}>
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation()
                        openMessageDrawer()
                    }}
                >
                    <Badge badgeContent={bid.message_count} color="primary">
                        <ChatBubbleOutline />
                    </Badge>
                </IconButton>
            </Container>
        </Container>
    )
}
