import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useFinderStyles = makeStyles((theme) =>
    createStyles({
        infrastructure: {
            cursor: 'pointer',
            height: 35,
            width: '100%',
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
                '& $icon': {
                    visibility: 'visible',
                },
            },
        },
        icon: {
            visibility: 'hidden',
        },
    }),
)
