import React from 'react'
import { Container } from '../../../components'
import {
    Avatar,
    Badge,
    IconButton,
    Popover,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { NVLVendor } from '.'
import { axiosInstance, getInitials, isAccountManager } from '../../../helpers'
import { ReactComponent as MapIcon } from '../../../assets/map-pin.svg'
import { ReactComponent as RibbonThumbGreenIcon } from '../../../assets/icons/thumbs_ribbon-32-filled-big.svg'
import { ReactComponent as RibbonAirplaneDarkIcon } from '../../../assets/icons/airplane_ribbon-32-filled-big.svg'
import { ReactComponent as SkillCatLogoHardHat } from '../../../assets/icons/SkillCatLogo_hardhat.svg'
import { ReactComponent as FeaturedVendorRibbon } from '../../../assets/Featured_Vendor_Badge_Right.svg'
import SkillCatLogoSmall from '../../../assets/SkillCatLogo_small.png'
import { ApartmentVendorChannel } from '../../../containers/NationalPropertyList'
import { ChatBubbleOutline, MoreVert } from '@material-ui/icons'
import { useAppDispatch, useUser } from '../../../hooks'
import { MessagingActionTypes, addVendorToApt } from '../../../store'
import { toast } from 'react-toastify'
import { PlanStatus } from '../../../models'

interface Props {
    vendor: NVLVendor
    apartmentVendorChannels: ApartmentVendorChannel[]
    isSelected: boolean
    setApartmentVendorChannels: (channels: ApartmentVendorChannel[]) => void
    setChannel: (channelID: number) => MessagingActionTypes
    setSelectedVendor: (vendor: NVLVendor) => void
    setMessageDrawerOpen: (open: boolean) => void
    setOpenShareModelDialog: (open: boolean) => void
    openProfile: () => void
}

export const VendorListRow = (props: Props) => {
    const {
        vendor,
        apartmentVendorChannels,
        isSelected,
        setSelectedVendor,
        setMessageDrawerOpen,
        setApartmentVendorChannels,
        setChannel,
        openProfile,
    } = props

    const theme = useTheme()

    const dispatch = useAppDispatch()

    const { workspaceUser } = useUser()

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const popOverOpen = Boolean(anchorEl)

    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closePopover = () => {
        setAnchorEl(null)
    }

    const displayAddress =
        vendor.state !== '' || vendor.address !== '' || vendor.city !== ''

    const apartmentVendorChannel = apartmentVendorChannels.find(
        (aptVenChannel) => vendor.id === aptVenChannel.vendor?.id,
    )

    const ExtraButtons = (
        <Container
            alignItems="center"
            justifyContent="center"
            style={{ fontWeight: 400, fontSize: '15px' }}
        >
            <span style={{ flex: 'flex-shrink' }}>
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation()
                        openPopover(e)
                    }}
                    disableRipple={true}
                    size="small"
                >
                    <MoreVert />
                </IconButton>
            </span>
            <Popover
                open={popOverOpen}
                anchorEl={anchorEl}
                onClose={closePopover}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
            >
                <Container
                    direction="column"
                    style={{ fontWeight: 400, fontSize: '15px' }}
                >
                    {workspaceUser && (
                        <IconButton
                            style={{ fontWeight: 400, fontSize: '15px' }}
                            onClick={() => {
                                dispatch(
                                    addVendorToApt({
                                        body: {
                                            vendor: vendor.id,
                                        },
                                    }),
                                )
                                toast.success('Added!', {
                                    position: 'bottom-center',
                                    autoClose: 1000,
                                })
                            }}
                        >
                            Add To Work Force
                        </IconButton>
                    )}
                    {isAccountManager(workspaceUser) && !vendor.is_trained && (
                        <IconButton
                            style={{
                                ...theme.typography.body1,
                                fontWeight: 400,
                            }}
                            onClick={() => {
                                setVendorAsTrained(vendor)
                            }}
                        >
                            Set Vendor as Trained
                        </IconButton>
                    )}
                    {isAccountManager(workspaceUser) && (
                        <IconButton
                            style={{
                                ...theme.typography.body1,
                                fontWeight: 400,
                            }}
                            onClick={() => {
                                axiosInstance
                                    .post(
                                        `company/vendor/${vendor.id}/remove_from_national_vendor_list/`,
                                    )
                                    .then(() => {
                                        toast.success(
                                            `${vendor.name} will no longer be on the nvl`,
                                        )
                                    })
                                    .catch((e) => {
                                        toast.error(e)
                                    })
                            }}
                        >
                            Remove From National Vendor List
                        </IconButton>
                    )}
                </Container>
            </Popover>
        </Container>
    )

    return (
        <Container
            style={{
                flex: 1,
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: '5px',
                margin: theme.spacing(1, 0, 1, 0),
                cursor: 'pointer',
                backgroundColor: isSelected
                    ? theme.palette.lightGrey.light
                    : '',
                height: '100px',
            }}
            onClick={() => {
                setMessageDrawerOpen(false)
                if (!popOverOpen) {
                    setSelectedVendor(vendor)
                    openProfile()
                }
            }}
        >
            <Container style={{ margin: theme.spacing(2) }}>
                {vendor.profile_picture !== '' ? (
                    <img
                        src={vendor.profile_picture}
                        style={{
                            width: '60px',
                            height: '60px',
                        }}
                    />
                ) : (
                    <Avatar
                        variant="square"
                        style={{
                            backgroundColor: theme.palette.primary.dark,
                            width: '60px',
                            height: '60px',
                            fontSize: 20,
                        }}
                    >
                        {getInitials(vendor.name)}
                    </Avatar>
                )}
            </Container>

            <Container
                style={{
                    margin: theme.spacing(2),
                    justifyContent: 'center',
                    width: '350px',
                }}
                direction="column"
            >
                <Container
                    style={{
                        fontWeight: 600,
                        fontSize: 20,
                        marginBottom: theme.spacing(1),
                    }}
                >
                    {vendor.name}
                </Container>

                {displayAddress && (
                    <Container
                        style={{
                            color: theme.palette.darkGreen.main,
                            fontSize: 14,
                            fontWeight: 400,
                            alignItems: 'center',
                        }}
                    >
                        <MapIcon style={{ marginRight: theme.spacing(1) }} />
                        {vendor.address} {vendor.city}, {vendor.state}
                    </Container>
                )}
            </Container>

            <Container
                style={{
                    fontWeight: 500,
                    fontSize: 16,
                    alignItems: 'center',
                    margin: theme.spacing(0, 2, 0, 2),
                    width: '300px',
                }}
            >
                {vendor.is_trained && (
                    <Container
                        style={{
                            alignItems: 'center',
                        }}
                    >
                        <Tooltip title="EZ Trained">
                            <RibbonThumbGreenIcon
                                style={{ marginLeft: theme.spacing(1) }}
                            />
                        </Tooltip>
                    </Container>
                )}

                {vendor.will_travel && (
                    <Container style={{ alignItems: 'center' }}>
                        <Tooltip title="Will Travel">
                            <RibbonAirplaneDarkIcon
                                style={{ marginLeft: theme.spacing(1) }}
                            />
                        </Tooltip>
                    </Container>
                )}

                {vendor.is_skillcat && (
                    <Container style={{ alignItems: 'center' }}>
                        <Tooltip title="SkillCat">
                            <img
                                src={SkillCatLogoSmall}
                                alt="Skill Cat Logo"
                                style={{
                                    marginLeft: theme.spacing(1),
                                }}
                            />
                        </Tooltip>
                    </Container>
                )}
            </Container>

            <Container flex={1} />

            {workspaceUser && (
                <Container style={{ padding: theme.spacing(5, 2, 5, 0) }}>
                    {apartmentVendorChannel ? (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                setMessageDrawerOpen(false)
                                setSelectedVendor(vendor)
                                const tempApartmentVendorChannels = apartmentVendorChannels.map(
                                    (aptVenChannel) => {
                                        if (
                                            apartmentVendorChannel.channel
                                                .id === aptVenChannel.channel.id
                                        ) {
                                            const tempAptVenChannel = aptVenChannel
                                            tempAptVenChannel.channel.unread_count = 0
                                            return tempAptVenChannel
                                        }
                                        return aptVenChannel
                                    },
                                )
                                setApartmentVendorChannels(
                                    tempApartmentVendorChannels,
                                )
                                setChannel(apartmentVendorChannel.channel.id)
                                setMessageDrawerOpen(true)
                            }}
                        >
                            <Badge
                                badgeContent={
                                    apartmentVendorChannel.channel.unread_count
                                }
                                color="secondary"
                            >
                                <ChatBubbleOutline />
                            </Badge>
                        </IconButton>
                    ) : (
                        <IconButton
                            onClick={() => {
                                setMessageDrawerOpen(false)
                                setSelectedVendor(vendor)
                                const body = {
                                    company_id: vendor.id,
                                }
                                axiosInstance
                                    .post(
                                        'company/apartment_vendors/get_or_create/',
                                        body,
                                    )
                                    .then((res) => {
                                        dispatch(
                                            setChannel(res.data.channel_id),
                                        )
                                        setMessageDrawerOpen(true)
                                    })
                            }}
                        >
                            <ChatBubbleOutline />
                        </IconButton>
                    )}
                </Container>
            )}

            {vendor.plan === PlanStatus.PREMIUM ? (
                <Container>
                    {ExtraButtons}
                    <Badge
                        badgeContent={<FeaturedVendorRibbon />}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        style={{
                            zIndex: 100,
                            flexDirection: 'column',
                            marginRight: theme.spacing(6),
                            marginTop: theme.spacing(3),
                        }}
                    />
                </Container>
            ) : (
                <Container style={{ marginRight: theme.spacing(6) }}>
                    {ExtraButtons}
                </Container>
            )}
        </Container>
    )
}

const setVendorAsTrained = (nvlVendor: NVLVendor) => {
    const body = {
        is_trained: true,
    }

    axiosInstance.patch(`company/vendor/${nvlVendor.id}/`, body).then((res) => {
        nvlVendor.is_trained = true
        toast.success(`${nvlVendor.name} set as trained`)
    })
}
