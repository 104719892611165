import React, { useMemo } from 'react'
import {
    DDLease,
    DDLeaseMatchStatus,
    MatchedLease,
} from '../../../../../models'
import { Theme } from '@material-ui/core'
import { Container } from '../../../../../components'

interface Props {
    matchedLeases: MatchedLease[]
    unmatchedPDFLeases: DDLease[]
    theme: Theme
    pendingCount: number | null
    pendingUpload: number
}

export const LeaseAuditAnalytics = (props: Props) => {
    const {
        matchedLeases,
        unmatchedPDFLeases,
        theme,
        pendingCount,
        pendingUpload,
    } = props

    const totalRentRollLeases = matchedLeases.length
    const totalMatched = matchedLeases.filter(
        (matchedLease) => matchedLease.status !== DDLeaseMatchStatus.NO_MATCH,
    ).length
    const perfectMatches = matchedLeases.filter(
        (matchedLease) => matchedLease.status === DDLeaseMatchStatus.SUCCESS,
    ).length
    const discrepancyMatches = matchedLeases.filter(
        (matchedLease) =>
            matchedLease.status === DDLeaseMatchStatus.DISCREPANCY,
    ).length
    const totalUnmatchedRentRollLeases = matchedLeases.filter(
        (matchedLease) => matchedLease.status === DDLeaseMatchStatus.NO_MATCH,
    ).length
    const totalUnmatchedPDFLeases = unmatchedPDFLeases.length

    const textStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[600],
    }
    const infoStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey[700],
    }

    return (
        <Container>
            <Container>
                <Container style={{ flexDirection: 'column' }}>
                    <span style={textStyle}>Total Rent Roll Leases:</span>
                    <span style={textStyle}>Total Matched:</span>
                </Container>
                <Container
                    style={{
                        marginLeft: theme.spacing(1),
                        flexDirection: 'column',
                    }}
                >
                    <span style={infoStyle}>{totalRentRollLeases}</span>
                    <span style={infoStyle}>{totalMatched}</span>
                </Container>
            </Container>
            <Container style={{ margin: theme.spacing(0, 5) }}>
                <Container style={{ flexDirection: 'column' }}>
                    <span style={textStyle}>Unmatched Rent Roll Leases:</span>
                    <span style={textStyle}>Matched Perfectly:</span>
                </Container>
                <Container
                    style={{
                        marginLeft: theme.spacing(1),
                        flexDirection: 'column',
                    }}
                >
                    <span style={infoStyle}>
                        {totalUnmatchedRentRollLeases}
                    </span>
                    <span style={infoStyle}>{perfectMatches}</span>
                </Container>
            </Container>
            <Container>
                <Container style={{ flexDirection: 'column' }}>
                    <span style={textStyle}>Unmatched PDF Leases:</span>
                    <span style={textStyle}>Matched with discrepancy:</span>
                </Container>
                <Container
                    style={{
                        marginLeft: theme.spacing(1),
                        flexDirection: 'column',
                    }}
                >
                    <span style={infoStyle}>{totalUnmatchedPDFLeases}</span>
                    <span style={infoStyle}>{discrepancyMatches}</span>
                </Container>
            </Container>
            <Container style={{ marginLeft: theme.spacing(5) }}>
                <Container style={{ flexDirection: 'column' }}>
                    <span style={textStyle}>Pending Analysis:</span>
                    <span style={textStyle}>Pending PDF Uploads:</span>
                </Container>
                <Container
                    style={{
                        marginLeft: theme.spacing(1),
                        flexDirection: 'column',
                    }}
                >
                    <span style={infoStyle}>
                        {pendingCount ? pendingCount : 0}
                    </span>
                    <span style={infoStyle}>{pendingUpload}</span>
                </Container>
            </Container>
        </Container>
    )
}
