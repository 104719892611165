import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, useTheme } from '@material-ui/core'
import EzosLogo from '../../../../assets/ezos-logo-primary-dark.png'
import { RegisterForm } from './RegisterForm'
import UserBackground from '../../../../assets/user-register-background.png'
import { Container } from '../../../../components'
import { Routes } from '../../../../helpers'

export const UserRegister = () => {
    const theme = useTheme()
    const history = useHistory()

    return (
        <Container
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                backgroundImage: `url(${UserBackground})`,
                backgroundSize: 'cover',
            }}
        >
            <Container
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    alignItems: 'center',
                    boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
                    maxHeight: 900,
                    width: 800,
                    borderRadius: '40px',
                    overflowY: 'scroll',
                }}
            >
                <Container
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: theme.spacing(3),
                    }}
                >
                    <img
                        src={EzosLogo}
                        style={{
                            height: 50,
                        }}
                    />
                    <span
                        style={{
                            ...theme.typography.h4,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.grey[700],
                            margin: theme.spacing(2, 0, 2, 0),
                        }}
                    >
                        Sign up to become an EZOS Property Manager!
                    </span>
                </Container>
                <RegisterForm />
            </Container>
            <Container>
                <Button
                    style={{
                        ...theme.typography.h6,
                        color: 'white',
                        textTransform: 'none',
                        fontWeight: theme.typography.fontWeightBold,
                        textShadow: '0 0 5px rgba(0, 0, 0, 1.0)',
                        marginTop: theme.spacing(2),
                    }}
                    onClick={() => {
                        history.push(Routes.login)
                    }}
                >
                    Have an account? Login now
                </Button>
            </Container>
        </Container>
    )
}
