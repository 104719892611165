import React, { useEffect, useState } from 'react'

import { Modal, Paper, Slide, Theme } from '@material-ui/core'

import { AddVendor, ExistingVendor } from './Containers'
import {
    AreaConfig,
    IdBoolMap,
    ListVendor,
    Service,
    UnitConfig,
} from '../../../models'
import { WorkforceFormState } from '../Workforce'
import { StepFindVendor } from './Components/StepFindVendor'
import { axiosInstance } from '../../../helpers'
import {
    CompanyDetailActionThunk,
    CreateVendorRequest,
    UpdateVendorRequest,
    UpdateVendorServicesRequest,
    VendorAndContractThunk,
} from '../../../store'

interface Props {
    vendorList: ListVendor[]
    theme: Theme
    formState: WorkforceFormState
    serviceList: Service[]
    unitConfigs: UnitConfig[]
    areaConfigs: AreaConfig[]
    vendorCanSeeUnitNotesMap: IdBoolMap
    onClose: () => void
    updateVendor: (req: UpdateVendorRequest) => VendorAndContractThunk
    updateVendorServices: (
        req: UpdateVendorServicesRequest,
    ) => CompanyDetailActionThunk
    createVendor: (req: CreateVendorRequest) => VendorAndContractThunk
    setVendorCanSeeUnitNotesMap: (map: IdBoolMap) => void
}

export const VendorModal = (props: Props) => {
    const { onClose, theme, vendorList, formState } = props

    const [modalState, setModalState] = useState<VendorModalState>(
        defaultModalState,
    )

    const vendorModalFs = formState.modal.vendorModal
    const { open, vendorId } = vendorModalFs

    const handleSearch = (email: string) => {
        const trimmedEmail = email.trim().toLocaleLowerCase()

        axiosInstance
            .post('company/vendor/find_vendor_by_email/', {
                email: trimmedEmail,
            })
            .then((res) => {
                const vendor: ListVendor = res.data.vendor
                const inWs =
                    vendorList.find((vnd) => vnd.id === vendor.id) !== undefined

                setModalState({
                    ...modalState,
                    activeVendorId: vendor.id,
                    mode: inWs ? 'edit' : 'add-to-workspace',
                    addToWsVendor: vendor,
                })
            })
            .catch(() => {
                setModalState({
                    ...modalState,
                    activeVendorId: -1,
                    mode: 'create-new',
                    searchComplete: true,
                })
            })
    }

    const handleCleanup = () => {
        setModalState(defaultModalState)
    }

    useEffect(() => {
        if (open) {
            const vendor = vendorList.find((vnd) => vnd.id === vendorId)

            if (vendor !== undefined) {
                setModalState({
                    ...modalState,
                    activeVendorId: vendorId,
                    mode: 'edit',
                    searchComplete: true,
                })
            } else {
                setModalState(defaultModalState)
            }
        } else {
            handleCleanup()
        }
    }, [open])

    const activeVendor = vendorList.find(
        (vnd) => vnd.id === modalState.activeVendorId,
    )

    return (
        <Modal
            open={open}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            onClose={onClose}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: theme.shape.borderRadius,
                    }}
                >
                    {modalState.mode === 'search' && (
                        <StepFindVendor
                            theme={theme}
                            searchEmail={modalState.searchEmail}
                            setSearchEmail={setModalState}
                            onSearch={handleSearch}
                        />
                    )}

                    {modalState.mode === 'create-new' && (
                        <AddVendor
                            vendor={null}
                            searchEmail={modalState.searchEmail}
                            {...props}
                        />
                    )}

                    {modalState.mode === 'add-to-workspace' && (
                        <AddVendor
                            vendor={activeVendor ?? modalState.addToWsVendor}
                            searchEmail={modalState.searchEmail}
                            {...props}
                        />
                    )}

                    {modalState.mode === 'edit' && (
                        <ExistingVendor vendor={activeVendor!} {...props} />
                    )}
                </Paper>
            </Slide>
        </Modal>
    )
}

export type VendorModalState = {
    mode: 'create-new' | 'add-to-workspace' | 'edit' | 'search'
    activeVendorId: number
    searchComplete: boolean
    searchEmail: string
    addToWsVendor: ListVendor | null
}

const defaultModalState: VendorModalState = {
    mode: 'search',
    activeVendorId: -1,
    searchComplete: false,
    searchEmail: '',
    addToWsVendor: null,
}
