import {
    Divider,
    IconButton,
    Modal,
    Paper,
    Slide,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { Container } from '../../components'
import {
    AI_HOME_HEADER,
    AI_LIST_FOOTER_H,
    AI_LIST_HEADER_H,
    JANK_SIZE,
    TOP_TOOL_BAR_H,
    VH,
} from '../ActionItem/contants'
import { ModelMap, RFP, RFPStatus, WorkorderType } from '../../models'
import { ApartmentEZNowRow } from './ApartmentEZNowRow'
import { CreateEZNowModal } from './CreateEZNowModal'
import { workorderContext } from '../../contexts'
import { WorkorderResponse } from '../../store'
import CloseIcon from '@material-ui/icons/Close'
import { ApartmentEZNowDetail } from './ApartmentEZNowDetail'
import { usePagination } from '../../hooks'
import { Pagination } from '@material-ui/lab'

interface Props {
    ezNowRequestList: RFP[] | null
    selectedRFP: RFP | null
    setSelectedRFP: (rfp: RFP) => void
}

export const ApartmentEZNowList = (props: Props) => {
    const { ezNowRequestList, setSelectedRFP, selectedRFP } = props

    const [openEZNowModal, setOpenEZNowModal] = useState(false)
    const [workorderMap, setWorkorderMap] = useState<
        ModelMap<WorkorderResponse>
    >({})

    const { getWorkorderList } = workorderContext()

    const theme = useTheme()

    const { page, setPage, pageData, numPages, start, end } = usePagination(
        ezNowRequestList ?? [],
    )

    const headerStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.darkGreen.main,
        flex: 1,
    }

    return (
        <Container
            flex={1}
            direction="column"
            style={{
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: theme.shape.borderRadius,
                margin: theme.spacing(1),
            }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    minHeight: AI_LIST_HEADER_H,
                    maxHeight: AI_LIST_HEADER_H,
                    ...theme.typography.h6,
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >
                <Container>EZNow Requests</Container>
                <div style={{ flex: 1 }} />
                <Container>
                    <Container style={{ ...headerStyle }}>Title</Container>
                    <Container style={{ ...headerStyle }}>Services</Container>
                    <Container style={{ ...headerStyle }}>
                        Workorder Count
                    </Container>
                    <Container style={{ ...headerStyle }}>Start Date</Container>
                    <Container style={{ ...headerStyle }}>Price</Container>
                    <Container style={{ ...headerStyle }}>Status</Container>
                </Container>
            </Container>
            <Container
                direction="column"
                scrollY
                flex={1}
                style={{
                    minHeight: `calc(${VH} - ${TOP_TOOL_BAR_H} - ${AI_LIST_HEADER_H} - ${AI_LIST_FOOTER_H} - ${AI_HOME_HEADER} - ${JANK_SIZE})`,
                    maxHeight: `calc(${VH} - ${TOP_TOOL_BAR_H} - ${AI_LIST_HEADER_H} - ${AI_LIST_FOOTER_H} - ${AI_HOME_HEADER} - ${JANK_SIZE})`,
                }}
            >
                {pageData.map((ezNowRequest) => {
                    return (
                        <ApartmentEZNowRow
                            key={`EZNOW_REQUEST_${ezNowRequest.id}`}
                            ezNowRequest={ezNowRequest}
                            isSelected={ezNowRequest.id === selectedRFP?.id}
                            onClick={() => {
                                setSelectedRFP(ezNowRequest)
                                const tempWorkorderMap: ModelMap<WorkorderResponse> = {}
                                ezNowRequest.workorder_group?.workorders.map(
                                    (wo) => (tempWorkorderMap[wo.id] = wo),
                                )
                                setWorkorderMap(tempWorkorderMap)
                                setOpenEZNowModal(true)
                            }}
                        />
                    )
                })}
            </Container>

            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    minHeight: AI_LIST_FOOTER_H,
                    maxHeight: AI_LIST_FOOTER_H,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Pagination
                    count={numPages}
                    page={page}
                    onChange={(_: unknown, newPage: number) => {
                        setPage(newPage)
                    }}
                />
                <span>
                    {start} - {end} of {ezNowRequestList?.length ?? 0}
                </span>
            </Container>

            {selectedRFP && selectedRFP?.status !== RFPStatus.SELECT_VENDOR ? (
                <Modal
                    open={openEZNowModal}
                    onClose={() => setOpenEZNowModal(false)}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={openEZNowModal}>
                        <Paper
                            style={{
                                display: 'flex',
                                overflow: 'hidden',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                outline: 'none',
                                borderRadius: '20px',
                                height: 'calc(100vh - 200px)',
                                width: 'calc(100vw - 200px)',
                                maxHeight: '700px',
                                maxWidth: '1100px',
                                padding: theme.spacing(1, 4, 1, 4),
                            }}
                        >
                            <Container
                                style={{
                                    alignItems: 'center',
                                }}
                            >
                                <span
                                    style={{
                                        ...theme.typography.h5,
                                        flex: 1,
                                    }}
                                >
                                    EZNow
                                </span>
                                <Tooltip title="Cancel">
                                    <IconButton
                                        onClick={() => {
                                            setOpenEZNowModal(false)
                                        }}
                                    >
                                        <CloseIcon
                                            fontSize="inherit"
                                            style={{
                                                marginRight: theme.spacing(1),
                                                color:
                                                    theme.palette.darkGreen
                                                        .main,
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Container>
                            <Divider />
                            <ApartmentEZNowDetail ezNowRequest={selectedRFP} />
                        </Paper>
                    </Slide>
                </Modal>
            ) : (
                <CreateEZNowModal
                    open={openEZNowModal}
                    handleClose={() => {
                        setOpenEZNowModal(false)
                        getWorkorderList({
                            params: {
                                workorder_type: WorkorderType.ACTION_ITEM,
                            },
                        })
                    }}
                    selectedWorkordersMap={workorderMap}
                    setSelectedWorkordersMap={setWorkorderMap}
                />
            )}
        </Container>
    )
}
