import React, { useState } from 'react'

import NumberFormat from 'react-number-format'

import { IconButton, ListItemText, MenuItem, Menu } from '@material-ui/core'

import { TemplateService } from '../../../models'
import { currencyFormatterNoDecimals } from '../../../helpers/CurrencyFormatter'
import { BaseProps } from '../../TemplateScheduleGantt/types'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import Grid from '@mui/material/Grid'
import { IconValue, MoneyInputValue, MoneyValue, TextValue } from '../styles'
import { toast } from 'react-toastify'

interface Props extends BaseProps {
    templateService: TemplateService
    key: string
    forecastedServiceTotal: number
    invoicedServiceTotal: number
    portfolio?: boolean
}

export const ServiceTableRow = (props: Props) => {
    const {
        scheduleController,
        templateService,
        key,
        forecastedServiceTotal,
        invoicedServiceTotal,
        portfolio,
    } = props

    const [budget, setBudget] = useState(templateService.budget)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <React.Fragment key={key}>
            <Grid item xs={0.5}>
                <IconValue>
                    <IconButton onClick={openMenu}>
                        <DeleteForeverIcon />
                    </IconButton>
                </IconValue>
            </Grid>
            <Grid item xs={3.5}>
                <TextValue>{templateService.service.name}</TextValue>
            </Grid>
            <Grid item xs={2}>
                <MoneyInputValue>
                    <NumberFormat
                        value={budget}
                        getInputRef={(input: HTMLInputElement | null) => {
                            input &&
                                input.addEventListener(
                                    'focus',
                                    (event: FocusEvent) => {
                                        const target = event.target as HTMLInputElement
                                        target.select()
                                    },
                                )
                        }}
                        onBlur={() => {
                            scheduleController.updateTemplateService(
                                templateService.id,
                                {
                                    budget: budget,
                                },
                                portfolio,
                            )
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const value = Number(
                                e.target.value.replace(/[^0-9.-]+/g, ''),
                            )
                            if (isNaN(value)) {
                                return
                            }
                            setBudget(value)
                        }}
                        thousandSeparator={true}
                        decimalScale={0}
                        prefix={`$`}
                        style={{
                            // ...theme.typography.subtitle2,
                            // fontWeight: props.theme.typography.fontWeightBold,
                            textAlign: 'right',
                            border: `1px solid ${props.theme.palette.grey[400]}`,
                            borderRadius: props.theme.shape.borderRadius,
                            padding: props.theme.spacing(0.25, 1),
                            width: 80,
                        }}
                    />
                </MoneyInputValue>
            </Grid>
            <Grid item xs={2}>
                <MoneyValue>
                    {currencyFormatterNoDecimals.format(forecastedServiceTotal)}
                </MoneyValue>
            </Grid>
            <Grid item xs={2}>
                <MoneyValue>
                    {currencyFormatterNoDecimals.format(
                        templateService.budget - forecastedServiceTotal,
                    )}
                </MoneyValue>
            </Grid>
            <Grid item xs={2}>
                <MoneyValue>
                    {currencyFormatterNoDecimals.format(invoicedServiceTotal)}
                </MoneyValue>
            </Grid>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        scheduleController
                            .deleteTemplateService(
                                templateService.id,
                                portfolio,
                            )
                            .then(() => {
                                toast.success('Deleted!')
                            })
                            .catch(() => {
                                toast.error(
                                    'There was a problem with your request',
                                )
                            })
                            .finally(() => {
                                handleClose()
                            })
                    }}
                >
                    <ListItemText>
                        Remove {templateService.service.name}
                    </ListItemText>
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}
