import React, { useEffect, useState } from 'react'
import { Container } from './Container'
import { useTheme } from '@material-ui/core'

interface Props {
    endDate: string
    setExpired: (expired: boolean) => void
}

export const CountdownTimer = (props: Props) => {
    const { endDate, setExpired } = props

    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)

    const theme = useTheme()

    const getTime = () => {
        const time = Date.parse(endDate) - Date.now()
        if (time >= 0) {
            setDays(Math.floor(time / (1000 * 60 * 60 * 24)))
            setHours(Math.floor((time / (1000 * 60 * 60)) % 24))
            setMinutes(Math.floor((time / 1000 / 60) % 60))
            setSeconds(Math.floor((time / 1000) % 60))
        } else {
            setExpired(true)
            setDays(0)
            setHours(0)
            setMinutes(0)
            setSeconds(0)
        }
    }

    const createTimerString = () => {
        let timerString = ''
        if (days > 0) {
            timerString = timerString + days
        }
        if (hours > 0) {
            if (hours < 10) {
                timerString = timerString + '0' + hours + ':'
            } else {
                timerString = timerString + hours + ':'
            }
        }
        if (minutes < 10) {
            timerString = timerString + '0' + minutes + ':'
        } else {
            timerString = timerString + minutes + ':'
        }
        if (seconds < 10) {
            timerString = timerString + '0' + seconds
        } else {
            timerString = timerString + seconds
        }

        return timerString
    }

    useEffect(() => {
        setDays(0)
        setHours(0)
        setMinutes(0)
        setSeconds(0)

        const interval = setInterval(() => getTime(), 1000)

        return () => clearInterval(interval)
    }, [endDate])

    const timerString = createTimerString()

    return (
        <Container style={{ ...theme.typography.subtitle1 }}>
            {timerString}
        </Container>
    )
}
