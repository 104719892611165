import React from 'react'

import EditIcon from '@material-ui/icons/Edit'
import { IconButton, TableCell, TableRow, Theme } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'

import { currencyFormatter, toMMDDYYYY } from '../../helpers'
import { ScheduleRow } from './types'
import { Container, RenderRow, StyledProgressBar } from '../../components'
import NumberFormat from 'react-number-format'
import { hasPermission } from '../../models/Users/services'
import { SEE_VENDOR_FINANCES, User } from '../../models'

interface Props {
    row: ScheduleRow
    classes: ClassNameMap
    theme: Theme
    barStyle: React.CSSProperties
    barLabelStyle: React.CSSProperties
    cellStyle: React.CSSProperties
    workspaceUser?: User
    onClickRow?: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void
}

export const ScheduleRowComponent: RenderRow<Props> = (props) => {
    const {
        row,
        classes,
        cellStyle,
        theme,
        barLabelStyle,
        barStyle,
        workspaceUser,
    } = props

    const startDate = new Date(row.startDate.value)
    const startDateLabel = toMMDDYYYY(startDate)

    let endDateLabel = ''
    if (row.endDate.value) {
        const endDate = new Date(row.endDate.value)
        endDateLabel = toMMDDYYYY(endDate)
    }

    const schedule = row.schedule.value

    let completeBarValue = 0
    let approvedBarValue = 0
    let invoicedBarValue = 0

    if (schedule.assigned_count > 0) {
        completeBarValue =
            (schedule.complete_count / schedule.assigned_count) * 100
        approvedBarValue =
            (schedule.approved_count / schedule.assigned_count) * 100
        invoicedBarValue =
            (schedule.invoiced_count / schedule.assigned_count) * 100
    }

    return (
        <TableRow
            key={`SCHEDULE_ROW_${schedule.id}`}
            style={{ cursor: 'pointer' }}
            className={classes.hoverGrey200}
            onClick={props.onClickRow}
        >
            <TableCell style={cellStyle}>{row.nameAndId.value}</TableCell>
            <TableCell style={cellStyle}>
                {hasPermission(workspaceUser, SEE_VENDOR_FINANCES)
                    ? currencyFormatter.format(row.budget.value)
                    : ''}
            </TableCell>
            <TableCell align="center" style={cellStyle}>
                {startDateLabel}
            </TableCell>
            <TableCell align="center" style={cellStyle}>
                {endDateLabel}
            </TableCell>
            <TableCell align="center" style={cellStyle}>
                <NumberFormat
                    displayType="text"
                    value={row.assignments.value}
                    thousandSeparator
                />
            </TableCell>

            {/* Progress Bars */}
            <TableCell align="center" style={{ height: '100%' }}>
                <Container direction="row" flex={1}>
                    {/* Labels */}
                    <Container
                        direction="column"
                        style={{
                            justifyContent: 'space-evenly',
                            marginRight: theme.spacing(1),
                        }}
                    >
                        <span style={barLabelStyle}>Complete</span>
                        <span style={barLabelStyle}>Approved</span>
                        <span style={barLabelStyle}>Invoiced</span>
                    </Container>

                    {/* Bars */}
                    <Container
                        direction="column"
                        flex={1}
                        style={{
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <StyledProgressBar
                            customStyle={barStyle}
                            value={completeBarValue}
                            variant="determinate"
                            barColor={theme.palette.info.main}
                        />

                        <StyledProgressBar
                            customStyle={barStyle}
                            value={approvedBarValue}
                            variant="determinate"
                            barColor={theme.palette.secondary.main}
                        />

                        <StyledProgressBar
                            customStyle={barStyle}
                            value={invoicedBarValue}
                            variant="determinate"
                            barColor={theme.palette.primary.main}
                        />
                    </Container>
                </Container>
            </TableCell>
        </TableRow>
    )
}
