import { AxiosError } from 'axios'
import { axiosInstance } from '../../helpers'
import { ApartmentServiceAnalytic } from '../../models'
import { setNetworkError } from '../error'
import { AppThunk, ErrorResponse } from '../types'
import {
    AnalyticsActionTypes,
    AnalyticsRequest,
    ApartmentAnalyticsRequest,
    ApartmentServiceAnalyticThunk,
    GetVendorAnalyticRequest,
    GET_APT_SERVICE_ANALYTICS,
    GET_VENDOR_ANALYTICS_REQUEST,
    SET_ANALYTICS_LOADING,
    SET_APT_SERVICE_ANALYTICS,
    SET_PORTFOLIO_VENDOR_ANALYTICS,
    SET_VENDOR_ANALYTICS,
    VendorAnalyticResponse,
    VendorAnalyticsThunk,
} from './types'

export const getAptServiceAnalyticRequest = (
    req: ApartmentAnalyticsRequest,
): AppThunk<ApartmentServiceAnalyticThunk> => {
    return async (dispatch) => {
        dispatch(setAnalyticsLoading(GET_APT_SERVICE_ANALYTICS, true))

        const url = `company/apartment/${req.apartmentId}/analytics/`

        try {
            const response = await axiosInstance.get(url, req)

            dispatch(setAptServiceAnalyticsList(response.data))
            dispatch(setAnalyticsLoading(GET_APT_SERVICE_ANALYTICS, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setAnalyticsLoading(GET_APT_SERVICE_ANALYTICS, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getVendorAnalyticsRequest = (
    req: GetVendorAnalyticRequest,
): AppThunk<VendorAnalyticsThunk> => {
    return async (dispatch) => {
        dispatch(setAnalyticsLoading(GET_VENDOR_ANALYTICS_REQUEST, true))

        const url = `stats/vendor-analytics/`

        try {
            const response = await axiosInstance.get(url, req)

            dispatch(setAnalyticsLoading(GET_VENDOR_ANALYTICS_REQUEST, false))
            if (req.params.schedule) {
                dispatch(setPortfolioVendorAnalytics(response.data))
            } else {
                dispatch(setVendorAnalytics(response.data))
            }

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setAnalyticsLoading(GET_VENDOR_ANALYTICS_REQUEST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const setVendorAnalytics = (
    res: VendorAnalyticResponse,
): AnalyticsActionTypes => {
    return {
        type: SET_VENDOR_ANALYTICS,
        analytics: res,
    }
}

export const setPortfolioVendorAnalytics = (
    analytics?: VendorAnalyticResponse,
): AnalyticsActionTypes => {
    return {
        type: SET_PORTFOLIO_VENDOR_ANALYTICS,
        analytics: analytics,
    }
}

export const setAptServiceAnalyticsList = (
    analyticsList?: ApartmentServiceAnalytic[],
): AnalyticsActionTypes => {
    return {
        type: SET_APT_SERVICE_ANALYTICS,
        analyticsList: analyticsList,
    }
}

export const setAnalyticsLoading = (
    request: AnalyticsRequest,
    isLoading: boolean,
): AnalyticsActionTypes => {
    return {
        type: SET_ANALYTICS_LOADING,
        request: request,
        isLoading: isLoading,
    }
}
