import { CLEAR_WOKRORDER_UNREAD_COUNT, UPDATE_WORKORDER } from '..'
import {
    BaseWorkorder,
    createWorkorderFromResponse,
    sortVendorWorkorder,
    _Workorder,
} from '../../models'
import {
    GET_JOBSITE_LOCATION_LIST_REQUEST,
    GET_JOBSITE_LOCATION_LIST_RESPONSE,
    GET_JOBSITE_SERVICE_LIST_REQUEST,
    GET_JOBSITE_SERVICE_LIST_RESPONSE,
    GET_JOBSITE_WORKORDER_LIST_REQUEST,
    GET_JOBSITE_WORKORDER_LIST_RESPONSE,
    JobsiteActionTypes,
    JobsiteState,
    SET_DATE_FILTER,
    SET_JOBSITE_LOCATION,
    SET_JOBSITE_SERVICE,
    SET_LOADING,
    SET_PRIORITY_FILTER,
    SET_WORKORDER_STATUS_FILTER,
    UPDATE_SUB_WORKORDER_REQUEST,
} from './types'

const initialState: JobsiteState = {
    dateFilter: 'Today',
    workorderStatusFilter: 'ACTIVE',
    priorityFilter: false,
    isLoading: {
        GET_JOBSITE_SERVICE_LIST_REQUEST: false,
        GET_JOBSITE_LOCATION_LIST_REQUEST: false,
        GET_JOBSITE_WORKORDER_LIST_REQUEST: false,
        CREATE_SUB_WORKORDER_REQUEST: false,
        UPDATE_SUB_WORKORDER_REQUEST: false,
    },
}

export const JobsiteReducer = (
    state = initialState,
    action: JobsiteActionTypes,
): JobsiteState => {
    switch (action.type) {
        case GET_JOBSITE_SERVICE_LIST_REQUEST:
        case GET_JOBSITE_LOCATION_LIST_REQUEST:
        case GET_JOBSITE_WORKORDER_LIST_REQUEST:
        case UPDATE_SUB_WORKORDER_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.type.valueOf()]: true,
                },
            }
        case GET_JOBSITE_SERVICE_LIST_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_JOBSITE_SERVICE_LIST_REQUEST: false,
                },
                serviceList: action.payload,
            }
        case GET_JOBSITE_LOCATION_LIST_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_JOBSITE_LOCATION_LIST_REQUEST: false,
                },
                locationList: action.payload,
            }
        case GET_JOBSITE_WORKORDER_LIST_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_JOBSITE_WORKORDER_LIST_REQUEST: false,
                },
                workorderList: sortVendorWorkorder(action.payload).map(
                    createWorkorderFromResponse,
                ),
            }
        case SET_DATE_FILTER:
            return {
                ...state,
                dateFilter: action.payload,
            }
        case SET_JOBSITE_SERVICE:
            return {
                ...state,
                service: action.payload,
            }
        case SET_WORKORDER_STATUS_FILTER:
            return {
                ...state,
                workorderStatusFilter: action.payload,
            }
        case SET_PRIORITY_FILTER:
            return {
                ...state,
                priorityFilter: action.payload,
            }
        case SET_JOBSITE_LOCATION:
            return {
                ...state,
                location: action.payload,
            }
        case UPDATE_WORKORDER:
            return {
                ...state,
                workorderList: state.workorderList?.reduce<BaseWorkorder[]>(
                    (prev, wo) => {
                        // if action.workorder is a string, the workorder was deleted
                        if (typeof action.workorder === 'string') return prev
                        if (wo.id === action.workorder.id) {
                            return prev.concat(action.workorder)
                        }
                        return prev.concat(wo)
                    },
                    [],
                ),
                isLoading: {
                    ...state.isLoading,
                    [action.endLoading ?? -1]: false,
                },
            }
        case CLEAR_WOKRORDER_UNREAD_COUNT:
            return {
                ...state,
                workorderList: state.workorderList?.map((wo) => {
                    if (wo.id === action.woId) {
                        return {
                            ...wo,
                            unread_count: 0,
                        }
                    }
                    return wo
                }),
            }
        case SET_LOADING:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.action ?? -1]: action.loading,
                },
            }
        default:
            return state
    }
}
