import { useTheme } from '@material-ui/core'
import React, { useEffect } from 'react'
import { Container, SideDrawer } from '../../../components'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => createStyles({}))

interface Props {
    open: boolean
    handleClose: () => void
    width: number
}
export const ProfileDrawer = (props: any) => {
    const classes = useStyles()
    const theme = useTheme()

    return (
        <SideDrawer
            open={props.open}
            handleClose={props.handleClose}
            width={props.width}
            title="Profile"
        >
            <Container
                direction="column"
                style={{ padding: theme.spacing(2) }}
                flex={1}
                scrollY
            >
                <p>Profile Drawer</p>
            </Container>
        </SideDrawer>
    )
}
