import React from 'react'
import { BaseProps } from '../../types'
import { mix_hexes } from '../../../../helpers'

interface Props extends BaseProps {
    color: string
    size: number
    left?: number
    right?: number
    onMouseDown: () => void
    className?: string
}

export const DragIcon = (props: Props) => {
    const size = props.size

    const color = mix_hexes(props.color, '#ffffff')

    return (
        <div
            className={props.className}
            style={{
                height: size,
                width: 10,
                backgroundColor: color,
                zIndex: 1,
                position: 'absolute',
                top: `calc(50% - ${size / 2}px)`,
                left: props.left,
                right: props.right,
                borderRadius: props.theme.shape.borderRadius,
                cursor: 'col-resize',
            }}
            onMouseDown={props.onMouseDown}
        />
    )
}
