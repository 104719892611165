import React, { useState } from 'react'
import { Container } from '../../components'
import { _PortfolioAnalytics } from '../_PortfolioAnalytics'
import { Button, useTheme, Theme } from '@material-ui/core'
import { PagePropertyGrade } from './components'

export const PortfolioAnalyticsHome = () => {
    const theme: Theme = useTheme()

    const [oldExperienceModalOpen, setOldExperienceModalOpen] = useState(false)

    return (
        <Container
            style={{
                flexDirection: 'column',
                height: 'calc(100vh - 104px)',
                flex: 1,
            }}
        >
            {/* Header */}
            <Container
                style={{
                    borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    paddingBottom: theme.spacing(2),
                    alignItems: 'center',
                }}
            >
                <span
                    style={{
                        ...theme.typography.h5,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Portfolio Analytics
                </span>

                <div style={{ flex: 1 }} />

                <Button
                    color="primary"
                    variant="contained"
                    style={{
                        textTransform: 'none',
                        marginRight: theme.spacing(1),
                    }}
                    onClick={() => {
                        setOldExperienceModalOpen(true)
                    }}
                >
                    Classic Experience
                </Button>
            </Container>

            {/* Body */}

            <PagePropertyGrade theme={theme} />

            {/* Footer */}
            <_PortfolioAnalytics
                open={oldExperienceModalOpen}
                onClose={() => setOldExperienceModalOpen(false)}
            />
        </Container>
    )
}
