import React, { useState } from 'react'
import { Container } from '../Container'
import { AreaConfig, IdBoolMap, Unit } from '../../models'

import {
    Checkbox,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { AreaSelectorState } from './types'
import { UnitRes } from '../../store'

interface Props {
    areaConfigOptions: AreaConfig[]
    selectionState: AreaSelectorState
    units: Unit[] | UnitRes[]
    onPressRemove: () => void
    updateSelectionState: (newState: AreaSelectorState) => void
    selectedAreaMap: IdBoolMap
    setSelectedAreaMap: (newMap: IdBoolMap) => void
}

const _AreaSelector = (props: Props) => {
    const {
        areaConfigOptions,
        selectionState,
        updateSelectionState,
        units,
        selectedAreaMap,
        setSelectedAreaMap,
    } = props

    const [areaLabel, setAreaLabel] = useState('')

    const areaConfig = areaConfigOptions.find(
        (ac) => ac.id === selectionState.selectedAreaConfigId,
    )

    let totalAreaCount = 0
    let selectedAreaCount = 0

    units.forEach((unit) => {
        unit.areas.forEach((area) => {
            // Does the area need to be considered in the check state?
            if (area.area_config !== selectionState.selectedAreaConfigId) {
                return
            }

            // If the selector is one the initial ones, ignore the area label
            if (selectionState.isInitialValue) {
                // There area must be considered in the calculation of the check state
                totalAreaCount += 1

                if (selectedAreaMap[area.id]) {
                    selectedAreaCount += 1
                }
            }
            // The selector was added by the user, require matching area labels
            else {
                if (area.area_label === areaLabel) {
                    totalAreaCount += 1
                    if (selectedAreaMap[area.id]) {
                        selectedAreaCount += 1
                    }
                }
            }
        })
    })

    const onCheck = (checkValue: boolean) => {
        const newSelectionMap = { ...selectedAreaMap }

        units.forEach((unit) => {
            unit.areas.forEach((area) => {
                // Does the area need to be considered in the check state?
                if (area.area_config !== selectionState.selectedAreaConfigId) {
                    return
                }

                // If the selector is one the initial ones, ignore the area label
                if (selectionState.isInitialValue) {
                    newSelectionMap[area.id] = checkValue
                }
                // The selector was added by the user, require matching area labels
                else {
                    if (area.area_label === areaLabel) {
                        newSelectionMap[area.id] = checkValue
                    }
                }
            })
        })

        setSelectedAreaMap(newSelectionMap)
    }

    const checked = totalAreaCount === selectedAreaCount
    const intermediate =
        selectedAreaCount < totalAreaCount && selectedAreaCount > 0

    // Any area configs that have no areas should be exluded completely
    if (totalAreaCount === 0 && selectionState.isInitialValue) {
        return null
    }

    return (
        <Container
            style={{
                padding: 4,
                paddingLeft: 8,
                paddingRight: 8,
                alignItems: 'center',
                border: `1px solid #d1d1d1`,
                borderRadius: 4,
                marginRight: 8,
                marginBottom: 8,
            }}
        >
            {selectionState.isInitialValue ? (
                <span>{areaConfig?.name ?? 'error'}</span>
            ) : (
                <FormControl style={{ width: 100 }}>
                    <Select
                        inputProps={{ placeholder: 'Area' }}
                        value={selectionState.selectedAreaConfigId}
                        onChange={(e) =>
                            updateSelectionState({
                                ...selectionState,
                                selectedAreaConfigId: e.target.value as number,
                            })
                        }
                    >
                        {areaConfigOptions.map((areaConfig) => {
                            return (
                                <MenuItem
                                    key={areaConfig.id}
                                    value={areaConfig.id}
                                >
                                    {areaConfig.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            )}

            {!selectionState.isInitialValue && (
                <FormControl>
                    <TextField
                        style={{ width: 45, marginLeft: 8 }}
                        placeholder="label"
                        inputProps={{ maxLength: 3 }}
                        value={areaLabel}
                        onChange={(e) => {
                            setAreaLabel(e.target.value)
                        }}
                    />
                </FormControl>
            )}

            <Checkbox
                color="primary"
                checked={checked}
                indeterminate={intermediate}
                onClick={() => {
                    onCheck(!checked)
                }}
            />

            {!selectionState.isInitialValue && (
                <IconButton onClick={props.onPressRemove} color="secondary">
                    <DeleteForeverIcon />
                </IconButton>
            )}

            <span>({selectedAreaCount})</span>
        </Container>
    )
}

export const AreaSelector = React.memo(_AreaSelector)
