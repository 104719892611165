import {
    LeaseActionTypes,
    LeaseState,
    SET_LEASE_LIST,
    SET_LEASE_LOADING,
    BULK_UPDATE_LEASE,
    ADD_LEASES,
    SET_VACANT_AREA_LIST,
    SET_NEXT_AND_PREV_LEASE,
    ADD_DAMAGE,
    SET_DAMAGE_LIST,
    UPDATE_DAMAGE,
    REMOVE_DAMAGE,
} from './types'
import { Damage, Lease } from '../../models'
import {} from '.'
import { SET_TENANT_LIST } from '..'

const initialState: LeaseState = {
    isLoading: {
        GET_LEASE_LIST_REQUEST: false,
        BULK_UPDATE_LEASE_REQUEST: false,
        BULK_CREATE_LEASE_REQUEST: false,
        GET_VACANT_AREA_REQUEST: false,
        GET_NEXT_AND_PREV_LEASE_REQUEST: false,
        CREATE_DAMAGE_REQUEST: false,
        GET_DAMAGE_LIST_REQUEST: false,
        GET_TENANT_LIST_REQUEST: false,
        DELETE_DAMAGE_REQUEST: false,
    },
}

export const leaseReducer = (
    state = initialState,
    action: LeaseActionTypes,
): LeaseState => {
    switch (action.type) {
        case SET_LEASE_LIST:
            if (action.initial) {
                return {
                    ...state,
                    leaseList: action.leases,
                }
            }
            return {
                ...state,
                leaseList: [
                    ...(state.leaseList ?? []),
                    ...(action.leases ?? []),
                ],
            }
        case BULK_UPDATE_LEASE:
            return bulkUpdateLeases(state, action.leases)

        case ADD_LEASES:
            return {
                ...state,
                leaseList: [...(state.leaseList ?? []), ...action.leases],
            }

        case SET_VACANT_AREA_LIST:
            return {
                ...state,
                vacantAreas: action.areas,
            }
        case SET_NEXT_AND_PREV_LEASE:
            return {
                ...state,
                nextLease: action.next,
                prevLease: action.prev,
            }

        case SET_DAMAGE_LIST:
            return {
                ...state,
                damageList: action.damageList,
            }

        case ADD_DAMAGE:
            return {
                ...state,
                damageList: addDamage(action.damage, state.damageList ?? []),
            }
        case UPDATE_DAMAGE:
            return {
                ...state,
                damageList: state.damageList?.map((damage) => {
                    if (damage.id === action.damage.id) {
                        return action.damage
                    }
                    return damage
                }),
            }

        case SET_TENANT_LIST:
            return {
                ...state,
                tenantList: action.tenantList,
            }

        case SET_LEASE_LOADING:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.request]: action.loading,
                },
            }
        case REMOVE_DAMAGE:
            return {
                ...state,
                damageList: state.damageList?.filter(
                    (damage) => damage.id !== action.damageId,
                ),
            }
        default:
            return state
    }
}

const bulkUpdateLeases = (state: LeaseState, updates: Lease[]): LeaseState => {
    const newLeaseList: Lease[] = [...(state.leaseList ?? [])]
    updates.forEach((updatedLease) => {
        for (let i = 0; i < newLeaseList.length; i++) {
            const candiateLease = newLeaseList[i]
            if (candiateLease.id === updatedLease.id) {
                newLeaseList[i] = updatedLease
            }
        }
    })

    return {
        ...state,
        leaseList: newLeaseList,
    }
}

const addDamage = (newDamage: Damage, damageList: Damage[]) => {
    const newDamages: Damage[] = [newDamage, ...damageList]

    for (let i = 0; i < newDamages.length - 1; i++) {
        const currentDamage = newDamages[i]
        const nextDamage = newDamages[i + 1]

        const currentPath =
            currentDamage.folder_path +
            currentDamage.folder_name +
            currentDamage.unit_name
        const nextPath =
            nextDamage.folder_path +
            nextDamage.folder_name +
            nextDamage.unit_name
        // shoud we swap the damages?
        if (currentPath.localeCompare(nextPath) === 1) {
            newDamages[i] = nextDamage
            newDamages[i + 1] = currentDamage
        }
    }

    return newDamages
}
