import { Dispatch, SetStateAction } from 'react'
import { IdentifiableObject } from '../models'

export const insertOrUpdateIdentifiable = <T extends IdentifiableObject>(
    updatedValue: T,
    dispatch: Dispatch<SetStateAction<T[] | null>>,
) => {
    dispatch((old) => {
        const safeOldValue = old ? [...old] : []

        let found = false
        for (let i = 0; i < safeOldValue.length; i++) {
            const current = safeOldValue[i]
            if (current.id === updatedValue.id) {
                safeOldValue[i] = updatedValue
                found = true
                break
            }
        }

        if (!found) {
            safeOldValue.push(updatedValue)
        }

        return safeOldValue
    })
}
