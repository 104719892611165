import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import {
    ChatBubbleOutline as ChatBubbleOutlineIcon,
    Folder as FolderIcon,
} from '@material-ui/icons'

import {
    Avatar,
    ListSubheader,
    Tooltip,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Button,
} from '@material-ui/core'

import { SideDrawerContainer } from '../../../../../components'
import MessageDialogBox from '../../../../components/dialog-box/DialogBox'
import SendIcon from '@material-ui/icons/Send'
import { axiosInstance } from '../../../../../helpers'
import { User } from '../../../../../models'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        memberList: {
            maxHeight: '900px',
            overflowY: 'scroll',
            '& .MuiListItemText-primary': {
                fontSize: '0.813rem',
                color: '#000',
                fontWeight: 700,
                // textOverflow: 'ellipsis',
                // whiteSpace: 'nowrap',
                // overflow: 'hidden',
                // width: '100%',
            },
            '& .MuiListItemText-secondary': {
                fontSize: '0.688rem',
                color: '#000',
                fontWeight: 400,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '100%',
            },
            '& .MuiListItem-root': {
                wordWrap: 'break-word',
            },
        },
    }),
)

interface Props {
    userList: User[]
    openMsgDrawer: (channelId: number) => void
}

const MyTeamList = (props: Props) => {
    const classes = useStyles()

    const openMessageDrawer = (userId: number) => {
        axiosInstance
            .get(
                `messaging/get-or-create-message-channel?input_user_id=${userId}`,
            )
            .then((res: any) => {
                console.log('response is....', res)
                if (res.status === 200) {
                    const channelId = res.data[0]?.id || 0
                    props.openMsgDrawer(channelId)
                }
            })
            .catch((err: any) => {
                console.log('error is....', err)
            })
    }
    return (
        <>
            <List
                dense={true}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        <span
                            style={{
                                fontWeight: 700,
                                fontSize: '13px',
                                lineHeight: '15px',

                                color: '#000000',
                            }}
                        >
                            Team
                        </span>
                        {/* <span
                            style={{
                                float: 'right',
                                fontWeight: 700,
                                fontSize: '13px',
                                color: '#008C85',
                            }}
                        >
                            View All
                        </span> */}
                    </ListSubheader>
                }
            ></List>
            <List
                dense={true}
                className={`ez-custom-scroll ${classes.memberList}`}
            >
                {props.userList.length > 0 ? (
                    props.userList.map((user: User, index: number) => (
                        <ListItem key={`user-${index}`}>
                            <ListItemAvatar>
                                <Avatar>
                                    <FolderIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={user.name}
                                secondary={
                                    <Tooltip title={user.email}>
                                        <span>{user.email}</span>
                                    </Tooltip>
                                }
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    onClick={() => {
                                        openMessageDrawer(user.id)
                                    }}
                                >
                                    <ChatBubbleOutlineIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))
                ) : (
                    <>
                        <p></p>
                    </>
                )}
            </List>
        </>
    )
}

export default MyTeamList
