import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'

import FavoriteIcon from '@material-ui/icons/Favorite'
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ImageIcon from '@material-ui/icons/Image'
import LoopIcon from '@material-ui/icons/Loop'
import { toast } from 'react-toastify'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        memberList: {
            width: '100%',
            maxHeight: '200px',
            overflowY: 'scroll',
            '& .MuiListItemText-primary': {
                fontWeight: 400,
                fontSize: '0.875rem',
                lineHeight: '16px',
                color: '#8A8A8A',
            },
            '& .MuiAvatar-root': {
                borderRadius: '6px',
            },
        },
        memberCard: {
            margin: '10px',
            border: '1px solid #C2C2C2',
            boxShadow: 'none',
            borderRadius: '2px',
            '& .MuiCardHeader-title': {
                fontWeight: 600,
                fontSize: '1rem',
                color: '#000000',
            },
            '& .MuiCardContent-root': {
                padding: 0,
            },
            '& .MuiCardHeader-action': {
                margin: 'auto',
            },
        },
        deleteBtn: {
            background: '#FFF',
            color: '#E9190F',
            border: '1px solid #E9190F',
            fontSize: '0.875rem',
            textTransform: 'inherit',
            fontWeight: 500,
            borderRadius: '5px',
            marginRight: '5px',
        },
        rotateIcon: {
            animation: 'spin 01s linear infinite',
        },
    }),
)

export const MemberList = (props: any) => {
    console.log('membership....', props.memberships)
    const classes = useStyles()
    const [confirmMode, setConfirmMode] = useState({
        status: false,
        memberId: null,
    })
    const [isLoading, setIsLoading] = useState(false)
    const theme = useTheme()

    const remove = (membership: any) => {
        setIsLoading(true)
        props
            .removeMember({
                channelId: props.currentChannelId,
                body: {
                    [membership.member_type]: membership.member.id,
                },
            })
            .then((res: any) => {
                setConfirmMode({ status: false, memberId: null })
                setIsLoading(false)
                toast.success('Member Successfully Removed')
            })
    }
    return (
        <>
            {props.memberships &&
                props.memberships.map((membership: any, index: number) => (
                    <Card
                        className={classes.memberCard}
                        key={`membership-${index}`}
                    >
                        <CardHeader
                            action={
                                <>
                                    {membership.member_type === 'user' &&
                                    (confirmMode.status === false ||
                                        confirmMode.memberId !==
                                            membership.member.id) ? (
                                        <Button
                                            className={classes.deleteBtn}
                                            onClick={() => {
                                                if (props.currentChannelId) {
                                                    setConfirmMode({
                                                        status: true,
                                                        memberId:
                                                            membership.member
                                                                .id,
                                                    })
                                                }
                                            }}
                                        >
                                            Remove
                                        </Button>
                                    ) : null}
                                    {membership.member_type === 'user' &&
                                        confirmMode.status &&
                                        confirmMode.memberId ===
                                            membership.member.id && (
                                            <>
                                                <Button
                                                    onClick={() =>
                                                        setConfirmMode({
                                                            status: false,
                                                            memberId: null,
                                                        })
                                                    }
                                                    color="secondary"
                                                    variant="outlined"
                                                    style={{
                                                        marginRight: theme.spacing(
                                                            1,
                                                        ),
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        remove(membership)
                                                    }}
                                                    color="primary"
                                                    variant="outlined"
                                                >
                                                    {isLoading ? (
                                                        <LoopIcon
                                                            className={
                                                                classes.rotateIcon
                                                            }
                                                        />
                                                    ) : (
                                                        'Confirm'
                                                    )}
                                                </Button>
                                            </>
                                        )}
                                </>
                            }
                            title={`${
                                membership.member_type === 'company'
                                    ? 'Company'
                                    : 'User'
                            }: ${membership.member.name} - Employees`}
                        />
                        <CardContent>
                            <List className={classes.memberList} dense>
                                {membership.member &&
                                membership.member.employees &&
                                membership.member.employees.length > 0
                                    ? membership.member.employees.map(
                                          (employee: any, index: number) => (
                                              <ListItem
                                                  key={`employee-${index}`}
                                              >
                                                  <ListItemAvatar>
                                                      <Avatar>
                                                          <ImageIcon />
                                                      </Avatar>
                                                  </ListItemAvatar>
                                                  <ListItemText
                                                      primary={employee.name}
                                                  />
                                              </ListItem>
                                          ),
                                      )
                                    : null}
                            </List>
                        </CardContent>
                    </Card>
                ))}
            {/* 
            <Card className={classes.memberCard}>
                <CardHeader
                    // action={
                    //     <IconButton aria-label="settings">
                    //         <MoreVertIcon />
                    //     </IconButton>
                    // }
                    title="Company: Apartment 2 - Employees"
                />
                <CardContent>
                    <List className={classes.memberList} dense>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <ImageIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="EZ Turn Support" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <ImageIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Corey James" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <ImageIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Lincoln Ogata" />
                        </ListItem>
                    </List>
                </CardContent>
            </Card> */}
        </>
    )
}
