import { Grid, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { Container } from '../../components'
import {
    SEE_INCOME_FINANCES,
    SEE_VENDOR_FINANCES,
    ServiceContractMap,
    _Workorder,
    getTotalPriceExpense,
    getTotalPriceIncome,
} from '../../models'
import { Pagination } from '@material-ui/lab'
import { WoStatusBadge } from '../VendorScheduler'
import { currencyFormatter, toMMDDYYYY } from '../../helpers'
import { hasPermission } from '../../models/Users/services'
import { useUpstreamServiceContract, useUser } from '../../hooks'

interface Props {
    workorders: _Workorder[]
    showProperty?: boolean
}

export const AnalyticsWorkorderTable = (props: Props) => {
    const { workorders, showProperty } = props

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(1)

    const theme = useTheme()

    const upstreamContractMap = useUpstreamServiceContract()

    const HeadCellStyle: React.CSSProperties = {
        fontWeight: 600,
        color: theme.palette.darkGreen.main,
        fontSize: '15px',
        flex: 2,
    }

    return (
        <Container scrollY style={{ marginTop: theme.spacing(2) }}>
            <Grid
                container={true}
                style={{
                    padding: theme.spacing(2, 0, 1, 0),
                }}
            >
                <Grid
                    container={true}
                    style={{ paddingLeft: theme.spacing(2) }}
                >
                    <Container style={{ ...HeadCellStyle }}>Service</Container>
                    <Container style={{ ...HeadCellStyle }}>Status</Container>
                    <Container style={{ ...HeadCellStyle }}>Vendor</Container>
                    <Container style={{ ...HeadCellStyle }}>Dates</Container>
                    <Container style={{ ...HeadCellStyle }}>Price</Container>
                    {showProperty && (
                        <Container style={{ ...HeadCellStyle }}>
                            Property
                        </Container>
                    )}
                    <Container style={{ ...HeadCellStyle }}>Location</Container>
                </Grid>
                <Grid
                    container={true}
                    style={{
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 450px)',
                    }}
                >
                    {workorders
                        .map((workorder) => {
                            return (
                                <WorkorderRow
                                    workorder={workorder}
                                    upstreamContractMap={upstreamContractMap}
                                    showProperty={showProperty}
                                    key={`WORKORDER_ROW_${workorder.id}`}
                                />
                            )
                        })
                        .slice(
                            (page - 1) * rowsPerPage,
                            (page - 1) * rowsPerPage + rowsPerPage,
                        )}
                </Grid>
                <Container
                    style={{
                        flex: 1,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.lightGrey.main,
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '13px',
                            paddingTop: theme.spacing(1),
                        }}
                    >
                        {workorders.length ?? 0} Rows
                    </span>
                    <Pagination
                        count={Math.ceil(workorders.length / rowsPerPage)}
                        page={page}
                        onChange={(_: unknown, newPage: number) => {
                            setPage(newPage)
                        }}
                        variant="outlined"
                        shape="rounded"
                        style={{ margin: theme.spacing(1, 0, 0, 0) }}
                    />
                </Container>
            </Grid>
        </Container>
    )
}

interface WorkorderRowProps {
    workorder: _Workorder
    upstreamContractMap: ServiceContractMap
    showProperty?: boolean
}

const WorkorderRow = (props: WorkorderRowProps) => {
    const { workorder, upstreamContractMap, showProperty } = props

    const { workspaceUser } = useUser()

    const theme = useTheme()

    const CellStyle: React.CSSProperties = {
        fontWeight: 400,
        fontSize: '15px',
        flex: 1,
    }

    const startDate = workorder.start_date
        ? toMMDDYYYY(new Date(workorder.start_date))
        : ''

    const endDate = workorder.end_date
        ? toMMDDYYYY(new Date(workorder.end_date))
        : ''

    return (
        <Grid
            container
            style={{
                margin: theme.spacing(1, 0, 1, 0),
                border: '1px solid',
                borderColor: theme.palette.grey[400],
                borderRadius: '20px 20px 20px 20px',
                flexDirection: 'row',
                padding: theme.spacing(3, 0, 3, 2),
            }}
        >
            <Container style={{ ...CellStyle }}>
                {workorder.service_name ?? ''}
            </Container>
            <Container style={{ ...CellStyle }}>
                <WoStatusBadge status={workorder.status} theme={theme} />
            </Container>
            <Container style={{ ...CellStyle }}>
                {workorder.vendor_name}
            </Container>
            <Container style={{ ...CellStyle }}>
                {startDate} - {endDate}
            </Container>
            <Container style={{ ...CellStyle }}>
                {hasPermission(workspaceUser, SEE_VENDOR_FINANCES) && (
                    <span
                        style={{
                            ...theme.typography.caption,
                            fontWeight: 'bold',
                            marginRight: theme.spacing(1),
                        }}
                    >
                        (
                        {currencyFormatter.format(
                            getTotalPriceExpense(workorder),
                        )}
                        )
                    </span>
                )}

                {hasPermission(workspaceUser, SEE_INCOME_FINANCES) && (
                    <span
                        style={{
                            ...theme.typography.caption,
                            fontWeight: 'bold',
                            color: 'green',
                        }}
                    >
                        {currencyFormatter.format(
                            getTotalPriceIncome(workorder, upstreamContractMap),
                        )}
                    </span>
                )}
            </Container>
            {showProperty && (
                <Container style={{ ...CellStyle }}>
                    {workorder.apartment_name}
                </Container>
            )}
            <Container style={{ ...CellStyle }}>
                {workorder.unit_name && (
                    <span>
                        {workorder.path}
                        {workorder.folder_name}/{workorder.unit_name}
                    </span>
                )}
            </Container>
        </Grid>
    )
}
