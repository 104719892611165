import { AreaStatusTag } from '../../../models'
import {
    CREATE_SCHEDULE_RESPONSE,
    CREATE_SCHEDULE_REQUEST,
    GET_SCHEDULE_LIST_REQUEST,
    GET_SCHEDULE_LIST_RESPONSE,
    ScheduleActionTypes,
    ScheduleState,
    UPDATE_SCHEDULE_RESPONSE,
    UPDATE_SCHEDULE_REQUEST,
    GET_SCHEDULE_DETAIL_REQUEST,
    GET_SCHEDULE_DETAIL_RESPONSE,
    SET_SCHEDULE_LOADING,
    SET_SCHEDULE_DETAIL,
    SET_AREA_STATUS_TAG_LIST,
    ADD_AREA_STATUS_TAG,
    UPDATE_AREA_STATUS_TAG,
    ADD_SCHEUDLE_BUDGET_ITEM,
    REMOVE_SCHEUDLE_BUDGET_ITEM,
    SET_SCHEDULE_BUDGET_ITEM_LIST,
    SET_PORTFOLIO_SCHEDULE_LIST,
    ADD_TO_PORTFOLIO_SCHEDULE_LIST,
    SET_PORTFOLIO_APARTMENT_COUNT,
    SET_PORTFOLIO_INSPECTION_COUNT,
    SET_PORTFOLIO_BUDGET,
    SET_PORTFOLIO_SCHEDULE_DETAIL,
    SET_PORTFOLIO_SCHEDULE_BUDGET_ITEM_LIST,
    ADD_PORTFOLIO_SCHEDULE_BUDGET_ITEM,
} from './types'

const initialState: ScheduleState = {
    scheduleList: [],
    isLoading: {
        GET_SCHEDULE_LIST_REQUEST: false,
        GET_SCHEDULE_DETAIL_REQUEST: false,
        CREATE_SCHEDULE_REQUEST: false,
        UPDATE_SCHEDULE_REQUEST: false,
        GET_SCHEDULE_SHEET_REQUEST: false,
        UPLOAD_TURN_SHEET_REQUEST: false,
        RESET_SCHEDULE_REQUEST: false,
        GET_AREA_STATUS_TAG_LIST_REQUEST: false,
        CREATE_AREA_STATUS_TAG_REQUEST: false,
        EDIT_AREA_STATUS_TAG_REQUEST: false,
        CREATE_SCHEUDLE_BUDGET_ITEM_REQUEST: false,
        DELETE_SCHEUDLE_BUDGET_ITEM_REQUEST: false,
        GET_PORTFOLIO_SCHEDULE_LIST_REQUEST: false,
        GET_PORTFOLIO_BUDGET: false,
    },
}

export const scheduleReducer = (
    state = initialState,
    action: ScheduleActionTypes,
): ScheduleState => {
    switch (action.type) {
        case GET_SCHEDULE_LIST_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_SCHEDULE_LIST_REQUEST: true,
                },
            }

        case GET_SCHEDULE_LIST_RESPONSE:
            return {
                ...state,
                scheduleList: action.payload,
                isLoading: {
                    ...state.isLoading,
                    GET_SCHEDULE_LIST_REQUEST: false,
                },
            }

        case GET_SCHEDULE_DETAIL_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_SCHEDULE_DETAIL_REQUEST: true,
                },
            }

        case GET_SCHEDULE_DETAIL_RESPONSE:
            return {
                ...state,
                scheduleDetail: action.payload,
                isLoading: {
                    ...state.isLoading,
                    GET_SCHEDULE_DETAIL_REQUEST: false,
                },
            }

        case CREATE_SCHEDULE_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    CREATE_SCHEDULE_REQUEST: true,
                },
            }

        case CREATE_SCHEDULE_RESPONSE:
            return {
                ...state,
                scheduleList: [...state.scheduleList, action.payload],
                isLoading: {
                    ...state.isLoading,
                    CREATE_SCHEDULE_REQUEST: false,
                },
            }

        case UPDATE_SCHEDULE_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    UPDATE_SCHEDULE_REQUEST: true,
                },
            }

        case UPDATE_SCHEDULE_RESPONSE: {
            let updatedScheduleDetail = state.scheduleDetail
            if (state.scheduleDetail?.id === action.payload.id) {
                updatedScheduleDetail = action.payload
            }
            return {
                ...state,
                scheduleDetail: updatedScheduleDetail,
                scheduleList: state.scheduleList.map((sch) => {
                    if (action.payload.id === sch.id) {
                        return action.payload
                    }
                    return sch
                }),
                isLoading: {
                    ...state.isLoading,
                    UPDATE_SCHEDULE_REQUEST: false,
                },
            }
        }

        case SET_SCHEDULE_DETAIL:
            return {
                ...state,
                scheduleDetail: action.schedule,
            }

        case SET_AREA_STATUS_TAG_LIST:
            return {
                ...state,
                areaStatusTagList: action.areaStatusTagList,
            }

        case ADD_AREA_STATUS_TAG:
            return addOrUpdateAreaStatusTag(state, action.areaStatusTag)

        case UPDATE_AREA_STATUS_TAG:
            return addOrUpdateAreaStatusTag(state, action.areaStatusTag)

        case SET_SCHEDULE_BUDGET_ITEM_LIST:
            return {
                ...state,
                scheduleBudgetItems: action.scheduleBudgetItemList,
            }

        case ADD_SCHEUDLE_BUDGET_ITEM:
            return {
                ...state,
                scheduleBudgetItems: [
                    ...(state.scheduleBudgetItems ?? []),
                    action.scheduleBudgetItem,
                ],
            }

        case REMOVE_SCHEUDLE_BUDGET_ITEM:
            return {
                ...state,
                scheduleBudgetItems: state.scheduleBudgetItems?.filter(
                    (item) => item.id !== action.id,
                ),
                portfolioScheduleBudgetItems: state.portfolioScheduleBudgetItems?.filter(
                    (item) => item.id !== action.id,
                ),
            }
        case ADD_PORTFOLIO_SCHEDULE_BUDGET_ITEM:
            return {
                ...state,
                portfolioScheduleBudgetItems: [
                    ...(state.portfolioScheduleBudgetItems ?? []),
                    action.scheduleBudgetItem,
                ],
            }
        case SET_SCHEDULE_LOADING:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.request]: action.isLoading,
                },
            }
        case SET_PORTFOLIO_SCHEDULE_LIST:
            return {
                ...state,
                portfolioScheduleList: action.portfolioScheduleList,
            }
        case SET_PORTFOLIO_SCHEDULE_DETAIL:
            return {
                ...state,
                portfolioScheudleDetail: action.schedule,
            }
        case SET_PORTFOLIO_SCHEDULE_BUDGET_ITEM_LIST:
            return {
                ...state,
                portfolioScheduleBudgetItems: action.scheduleBudgetItemList,
            }
        case SET_PORTFOLIO_APARTMENT_COUNT:
            return {
                ...state,
                portfolioApartmentCount: action.count,
            }
        case SET_PORTFOLIO_INSPECTION_COUNT:
            return {
                ...state,
                portfolioInspectionCount: action.count,
            }
        case ADD_TO_PORTFOLIO_SCHEDULE_LIST:
            return {
                ...state,
                portfolioScheduleList: [
                    ...(state.portfolioScheduleList ?? []),
                    ...action.portfolioScheduleList,
                ],
            }
        case SET_PORTFOLIO_BUDGET:
            return {
                ...state,
                portfolioBudget: action.budget,
            }
        default:
            return state
    }
}

const addOrUpdateAreaStatusTag = (
    state: ScheduleState,
    areaStatusTag: AreaStatusTag,
): ScheduleState => {
    let shouldAddAst = true
    let newAreaStatusTagList: AreaStatusTag[] = []
    if (state.areaStatusTagList) {
        // Check to see if the area status already exists
        newAreaStatusTagList = state.areaStatusTagList.map((ast) => {
            if (ast.id === areaStatusTag.id) {
                shouldAddAst = false
                return areaStatusTag
            }
            return ast
        })
    }

    if (shouldAddAst) {
        newAreaStatusTagList.push(areaStatusTag)
    }

    return {
        ...state,
        areaStatusTagList: newAreaStatusTagList,
    }
}
