import React from 'react'

// hooks
import { useTheme } from '@material-ui/core'

// types
import { Unit } from '../../models'

// components
import { Container } from '../Container'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'

interface Props {
    unit: Unit
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    getUnitAdornment?: (unit: Unit) => JSX.Element | null
}

export const FinderUnit: React.FC<Props> = (props) => {
    const { unit, onClick } = props

    const theme = useTheme()

    const serviceDots: JSX.Element[] = []
    for (let i = 0; i < 3 && i < unit.workorders.length; i++) {
        const wo = unit.workorders[i]
        serviceDots.push(
            <div
                key={`FINDER_WO_${wo.id}`}
                style={{
                    height: 18,
                    width: 18,
                    borderRadius: '100%',
                    backgroundColor:
                        wo.service_color ?? theme.palette.noService.main,
                    transform: `translateX(-${i * 6}px)`,
                    boxShadow: theme.shadows[2],
                }}
            />,
        )
    }

    if (unit.workorders.length > 3) {
        serviceDots.push(
            <Container
                key={`FINDER_UNIT_${unit.id}_WO_OVERFLOW`}
                justifyContent="center"
                style={{
                    height: 18,
                    width: 36,
                    borderRadius: 18,
                    backgroundColor: theme.palette.grey[300],
                    transform: `translateX(-18px)`,
                    boxShadow: theme.shadows[2],
                }}
            >
                <span
                    style={{
                        ...theme.typography.caption,
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.text.primary,
                    }}
                >
                    +{unit.workorders.length - 3}
                </span>
            </Container>,
        )
    }

    return (
        <Container
            alignItems="center"
            flex={1}
            onClick={(e) => {
                e.stopPropagation()
                onClick && onClick(e)
            }}
        >
            <MeetingRoomIcon color="action" />

            <span
                style={{
                    ...theme.typography.caption,
                    fontWeight: theme.typography.fontWeightLight,
                    marginLeft: theme.spacing(1),
                }}
            >
                {unit.name}
            </span>

            <Container flex={1} alignItems="center">
                {serviceDots}
                {props.getUnitAdornment && props.getUnitAdornment(unit)}
            </Container>
        </Container>
    )
}
