import { AxiosResponse } from 'axios'
import { Action } from 'redux'
import {
    ApartmentServiceAnalytic,
    BaseVendor,
    VendorServiceAnalytic,
} from '../../models'

export interface AnalyticsAxiosRequest {
    params?: {
        schedule?: number
        service?: number
        vendor?: number
    }
}

export interface GetVendorAnalyticRequest {
    params: {
        vendor: number
        schedule?: number
    }
}

export interface VendorAnalyticResponse extends BaseVendor {
    all_time: VendorServiceAnalytic[]
    past_week: VendorServiceAnalytic[]
}

export interface ApartmentAnalyticsRequest extends AnalyticsAxiosRequest {
    apartmentId: number
}

export const GET_APT_SERVICE_ANALYTICS = 'GET_APT_SERVICE_ANALYTICS'
export const GET_VENDOR_ANALYTICS_REQUEST = 'GET_VENDOR_ANALYTICS_REQUEST'

export const SET_APT_SERVICE_ANALYTICS = 'SET_APT_SERVICE_ANALYTICS'
export const SET_ANALYTICS_LOADING = 'SET_ANALYTICS_LOADING'
export const SET_VENDOR_ANALYTICS = 'SET_VENDOR_ANALYTICS'
export const SET_PORTFOLIO_VENDOR_ANALYTICS = 'SET_PORTFOLIO_VENDOR_ANALYTICS'

export type AnalyticsRequest =
    | typeof GET_APT_SERVICE_ANALYTICS
    | typeof GET_VENDOR_ANALYTICS_REQUEST

export interface SetAptServiceAnalyticList extends Action {
    type: typeof SET_APT_SERVICE_ANALYTICS
    analyticsList?: ApartmentServiceAnalytic[]
}

export interface SetAnalyticsLoadingAction extends Action {
    type: typeof SET_ANALYTICS_LOADING
    request: AnalyticsRequest
    isLoading: boolean
}

export interface SetVendorAnalyticsAction extends Action {
    type: typeof SET_VENDOR_ANALYTICS
    analytics: VendorAnalyticResponse
}

export interface SetPortfolioVendorAnalyticsAction extends Action {
    type: typeof SET_PORTFOLIO_VENDOR_ANALYTICS
    analytics?: VendorAnalyticResponse
}

export type AnalyticsActionTypes =
    | SetAnalyticsLoadingAction
    | SetAptServiceAnalyticList
    | SetVendorAnalyticsAction
    | SetPortfolioVendorAnalyticsAction

export type ApartmentServiceAnalyticThunk = Promise<AxiosResponse<any[]>>

export type VendorAnalyticsThunk = Promise<
    AxiosResponse<VendorAnalyticResponse>
>

export interface AnalyticState {
    aptServiceAnalytics?: ApartmentServiceAnalytic[]
    vendorAnalytics?: VendorAnalyticResponse
    portfolioVendorAnalytics?: VendorAnalyticResponse
    isLoading: {
        [GET_APT_SERVICE_ANALYTICS]: boolean
        [GET_VENDOR_ANALYTICS_REQUEST]: boolean
    }
}
