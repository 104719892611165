import React, { useEffect } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import {
    Card,
    CardContent,
    Typography,
    AppBar,
    Tabs,
    Tab,
    Box,
} from '@material-ui/core'

import MyTeam from './my-team'
import MyVendors from './my-vendor'
import { useCompany, useUser } from '../../../../hooks'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            borderRadius: '15px',
            border: '1px solid #C2C2C2',
            boxShadow: '0px 6px 15px rgb(138 138 138 / 25%)',
        },
        tabs: {
            '& .MuiTab-wrapper': {
                height: '38px',
                color: '#008c85',
                fontWeight: 700,
                fontSize: '1rem',
                padding: '6px',
            },
            '& .Mui-selected .MuiTab-wrapper': {
                background: '#008c85',
                borderRadius: '5px',
                color: '#FFF',
            },
            '& .MuiTabs-indicator': {
                display: 'none',
            },
            '& .MuiTab-root': {
                textTransform: 'inherit',
                minWidth: '50%',
            },
        },
    }),
)

interface TabPanelProps {
    children?: React.ReactNode
    index: any
    value: any
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const WorkForce = (props: any) => {
    const classes = useStyles()

    const [tabValue, setTabValue] = React.useState(0)

    const handleTabChange = (
        event: React.ChangeEvent<{}>,
        newValue: number,
    ) => {
        setTabValue(newValue)
    }

    const { getVendorList, vendorList } = useCompany()
    const { getUserList, userList } = useUser()

    useEffect(() => {
        getUserList({ params: { my_team: true } })
        getVendorList({ params: { my_team: true } })
    }, [])

    return (
        <Card className={`${classes.root}`}>
            <CardContent>
                <AppBar
                    position="static"
                    style={{
                        background: '#F4F4F4',
                        border: '1px solid #D9D9D9',
                        borderRadius: '5px',
                        boxShadow: 'none',
                        boxSizing: 'border-box',
                    }}
                >
                    <Tabs
                        className={classes.tabs}
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="off"
                        // aria-label="scrollable auto tabs example"
                    >
                        <Tab label="My Team" />
                        <Tab label="My Vendors" />
                    </Tabs>
                </AppBar>
                <TabPanel value={tabValue} index={0}>
                    <MyTeam
                        userList={userList}
                        openMsgDrawer={props.openMsgDrawer}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <MyVendors vendorList={vendorList} />
                </TabPanel>
            </CardContent>
        </Card>
    )
}

export default WorkForce
