import React, { useEffect, useState } from 'react'
import { Container, AvatarCircle } from '../../../../components'
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme,
} from '@material-ui/core/styles'
import {
    Box,
    CircularProgress,
    IconButton,
    LinearProgress,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core'

import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { axiosInstance } from '../../../../helpers'
import { NVLVendor } from '..'
import {
    Document,
    Service,
    SimpleVendor,
    VendorDocuments,
} from '../../../../models'
import { Rating } from '@material-ui/lab'
import { GetApp } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        profileOverview: {
            padding: '16px 0',
            textAlign: 'center',
        },
        progressTitle: {
            fontSize: '0.938rem',
            fontWeight: 700,
            lineHeight: '1.7rem',
        },
        progressValue: {
            fontSize: '0.813rem',
            fontWeight: 400,
            color: '#000',
            lineHeight: '1.7rem',
        },
        progress: {
            height: '10px',
            borderRadius: '15px',
        },
        subTitle: {
            fontSize: '1rem',
            fontWeight: 700,
            lineHeight: '1.7rem',
        },
        basicInformation: {
            padding: '16px 0',
            '& span': {
                fontSize: '0.813rem',
                color: '#000',
                lineHeight: '1.6rem',
                display: 'block',
            },
        },
        skills: {
            padding: '16px 0',
        },
        closeBtn: {
            background: '#FFF',
            color: '#E9190F',
            border: '1px solid #E9190F',
            fontSize: '0.875rem',
            textTransform: 'inherit',
            fontWeight: 500,
            borderRadius: '5px',
            width: '100%',
        },
        //Profile Progress
        progressImage: {
            borderRadius: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '80%',
            height: '80%',
        },
        workOrderInfo: {
            position: 'relative',
            display: 'block',
            left: '88%',
            width: '85px',
            bottom: '30%',
            fontSize: '12px',
            color: '#b7b7b7',
            fontWeight: 'bold',
            '& h3': {
                margin: '0px',
                fontSize: '18px',
                color: 'rgb(0, 140, 133)',
            },
        },
        indicator: {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
)

export interface VendorWorkorderDetails {
    workorder_count: number
    approved_count: number
    go_back_2_count: number
    go_back_count: number
    services: Service[]
}

interface Props {
    selectedVendor: NVLVendor | SimpleVendor
}

const ANALYTICS_TAB = 0
const INFO_TAB = 1

const RATING_INDEX = 0
const DOCUMENT_INDEX = 1

export const VendorDetails = (props: Props) => {
    const { selectedVendor } = props

    const classes = useStyles()
    const theme = useTheme()

    const [rating, setRating] = useState<number | undefined>()

    const [tab, setTab] = useState<number>(ANALYTICS_TAB)

    const [vendorDocuments, setVendorDocuments] = useState<VendorDocuments>({})

    const [
        vendorWorkorderDetails,
        setVendorWorkorderDetails,
    ] = useState<VendorWorkorderDetails>({
        workorder_count: 0,
        approved_count: 0,
        go_back_2_count: 0,
        go_back_count: 0,
        services: [],
    })

    const [loading, setLoading] = useState(false)

    let borderColor = ''
    let accomplishmentValue = 0

    const tabStyle: React.CSSProperties = {
        fontWeight: 800,
        fontSize: '15px',
        textTransform: 'none',
    }

    switch (true) {
        case vendorWorkorderDetails.workorder_count < 1000: {
            borderColor = theme.palette.silver.main
            accomplishmentValue = 1000
            break
        }
        case vendorWorkorderDetails.workorder_count < 10000: {
            borderColor = theme.palette.gold.main
            accomplishmentValue = 10000
            break
        }
        case vendorWorkorderDetails.workorder_count < 100000: {
            borderColor = theme.palette.blue.main
            accomplishmentValue = 100000
            break
        }
        case vendorWorkorderDetails.workorder_count < 1000000: {
            borderColor = theme.palette.darkBlue.main
            accomplishmentValue = 1000000
            break
        }
        default: {
            borderColor = theme.palette.primary.dark
            accomplishmentValue = 2000000
        }
    }

    useEffect(() => {
        setLoading(true)
        const body = {
            vendor_id: selectedVendor.id,
        }
        const ratingPromise = axiosInstance.post(
            `company/national_vendor_list/get_vendor_rating/`,
            body,
        )
        const vendorDocumentPromise = axiosInstance.get(
            `company/national_vendor_list/${selectedVendor.id}/get_vendor_documents/`,
        )
        const vendorDetailsPromise = axiosInstance.post(
            `company/national_vendor_list/get_vendor_detail/`,
            {
                id: selectedVendor.id,
            },
        )

        Promise.all([
            ratingPromise,
            vendorDocumentPromise,
            vendorDetailsPromise,
        ])
            .then((values) => {
                if (values[RATING_INDEX].data.rating !== 0) {
                    setRating(values[0].data.rating)
                } else {
                    setRating(undefined)
                }
                setVendorDocuments(values[DOCUMENT_INDEX].data)
                setVendorWorkorderDetails(values[2].data)
            })
            .finally(() => setLoading(false))
    }, [selectedVendor])

    const completeCount = selectedVendor.complete_count

    if (loading) {
        return (
            <Container justifyContent="center" alignItems="center">
                <CircularProgress size={100} />
            </Container>
        )
    }

    return (
        <Container
            direction="column"
            style={{
                padding: theme.spacing(4),
                paddingTop: 4,
                marginTop: theme.spacing(4),
                overflow: 'auto',
                height: 'calc(90vh - 100px)',
            }}
            flex={1}
        >
            <Box className={classes.profileOverview}>
                {completeCount ? (
                    <div
                        style={{
                            width: 150,
                            height: 150,
                            margin: '0px auto',
                            marginBottom: '20px',
                        }}
                    >
                        <CircularProgressbarWithChildren
                            value={(completeCount / accomplishmentValue) * 100}
                            strokeWidth={6}
                            styles={{ path: { stroke: borderColor } }}
                        >
                            {selectedVendor.profile_picture !== null ? (
                                <img
                                    src={selectedVendor.profile_picture}
                                    style={{
                                        height: '125px',
                                        width: '125px',
                                        borderRadius: '50%',
                                    }}
                                />
                            ) : (
                                <AvatarCircle
                                    text={selectedVendor.name}
                                    initials={true}
                                    styles={{
                                        container: {
                                            padding: theme.spacing(7.5),
                                            fontSize: '40px',
                                            backgroundColor:
                                                theme.palette.primary.dark,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        },
                                    }}
                                />
                            )}
                        </CircularProgressbarWithChildren>
                    </div>
                ) : (
                    <Container
                        justifyContent="center"
                        style={{
                            width: '100%',
                            marginBottom: theme.spacing(2),
                        }}
                    >
                        {selectedVendor.profile_picture !== null ? (
                            <img
                                src={selectedVendor.profile_picture}
                                style={{
                                    height: '125px',
                                    width: '125px',
                                    borderRadius: '50%',
                                }}
                            />
                        ) : (
                            <AvatarCircle
                                text={selectedVendor.name}
                                initials={true}
                                styles={{
                                    container: {
                                        padding: theme.spacing(7.5),
                                        fontSize: '40px',
                                        backgroundColor:
                                            theme.palette.primary.dark,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    },
                                }}
                            />
                        )}
                    </Container>
                )}
                <Typography
                    component="p"
                    style={{
                        fontSize: '1.25rem',
                        fontWeight: 600,
                        lineHeight: '1.3rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <span style={{ paddingLeft: '16px' }}>
                        {selectedVendor.name}
                    </span>
                </Typography>

                <Container
                    style={{
                        fontWeight: 500,
                        fontSize: 18,
                        color: theme.palette.darkGreen.main,
                        margin: theme.spacing(2, 0, 2, 0),
                        justifyContent: 'center',
                    }}
                >
                    {selectedVendor.phone}
                </Container>

                {rating && (
                    <Rating
                        disabled
                        precision={0.5}
                        name={`${selectedVendor.id}_RATING`}
                        value={rating}
                        style={{
                            alignSelf: 'center',
                            marginBottom: theme.spacing(2),
                        }}
                    />
                )}
            </Box>

            <Container>
                <Tabs
                    style={{
                        color: 'black',
                        borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
                        flex: 1,
                        textTransform: 'none',
                    }}
                    value={tab}
                    onChange={(_, v) => {
                        if (v !== tab) {
                            setTab(v)
                        }
                    }}
                    classes={{
                        indicator: classes.indicator,
                    }}
                >
                    <Tab label="Analytics" style={{ ...tabStyle }} />
                    <Tab label="Info" style={{ ...tabStyle }} />
                </Tabs>
            </Container>

            {tab === ANALYTICS_TAB && (
                <Container direction="column">
                    <Box style={{ padding: '15px 0' }}>
                        {completeCount && (
                            <Box>
                                <Typography
                                    component="p"
                                    className={classes.progressTitle}
                                >
                                    Completed
                                </Typography>
                                <LinearProgress
                                    variant="determinate"
                                    value={
                                        vendorWorkorderDetails.workorder_count !==
                                        0
                                            ? (completeCount /
                                                  vendorWorkorderDetails.workorder_count) *
                                              100
                                            : 0
                                    }
                                    className={classes.progress}
                                />
                                <Typography
                                    component="p"
                                    className={classes.progressValue}
                                >
                                    {vendorWorkorderDetails.workorder_count !==
                                    0
                                        ? `${(
                                              (completeCount /
                                                  vendorWorkorderDetails.workorder_count) *
                                              100
                                          ).toFixed(2)}%`
                                        : 'No workorders have been assigned '}
                                </Typography>
                            </Box>
                        )}
                        <Box>
                            <Typography
                                component="p"
                                className={classes.progressTitle}
                            >
                                Double Go Backs
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={
                                    vendorWorkorderDetails.workorder_count !== 0
                                        ? (vendorWorkorderDetails.go_back_2_count /
                                              vendorWorkorderDetails.workorder_count) *
                                          100
                                        : 0
                                }
                                className={classes.progress}
                            />
                            <Typography
                                component="p"
                                className={classes.progressValue}
                            >
                                {vendorWorkorderDetails.workorder_count !== 0
                                    ? `${(
                                          (vendorWorkorderDetails.go_back_2_count /
                                              vendorWorkorderDetails.workorder_count) *
                                          100
                                      ).toFixed(2)}%`
                                    : 'No workorders have been assigned '}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                component="p"
                                className={classes.progressTitle}
                            >
                                Go Backs
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={
                                    vendorWorkorderDetails.workorder_count !== 0
                                        ? (vendorWorkorderDetails.go_back_count /
                                              vendorWorkorderDetails.workorder_count) *
                                          100
                                        : 0
                                }
                                className={classes.progress}
                            />
                            <Typography
                                component="p"
                                className={classes.progressValue}
                            >
                                {vendorWorkorderDetails.workorder_count !== 0
                                    ? `${(
                                          (vendorWorkorderDetails.go_back_count /
                                              vendorWorkorderDetails.workorder_count) *
                                          100
                                      ).toFixed(2)}%`
                                    : 'No workorders have been assigned '}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                component="p"
                                className={classes.progressTitle}
                            >
                                Approved
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={
                                    vendorWorkorderDetails.workorder_count !== 0
                                        ? (vendorWorkorderDetails.approved_count /
                                              vendorWorkorderDetails.workorder_count) *
                                          100
                                        : 0
                                }
                                className={classes.progress}
                            />
                            <Typography
                                component="p"
                                className={classes.progressValue}
                            >
                                {vendorWorkorderDetails.workorder_count !== 0
                                    ? `${(
                                          (vendorWorkorderDetails.approved_count /
                                              vendorWorkorderDetails.workorder_count) *
                                          100
                                      ).toFixed(2)}%`
                                    : 'No workorders have been assigned '}
                            </Typography>
                        </Box>
                    </Box>

                    <Box className={classes.skills}>
                        <Typography component="p" className={classes.subTitle}>
                            All Skills
                        </Typography>
                        {vendorWorkorderDetails.services.map(
                            (service: Service) => {
                                return (
                                    <Container
                                        key={`VENDOR_${selectedVendor.id}_SERVICES_${service.id}`}
                                    >
                                        {service.name}
                                    </Container>
                                )
                            },
                        )}
                    </Box>

                    <Container
                        style={{
                            marginTop: theme.spacing(2),
                            alignItems: 'center',
                        }}
                        direction="column"
                    >
                        <Container
                            style={{
                                color: theme.palette.darkGreen.main,
                                fontSize: 16,
                            }}
                        >
                            Work Orders Complete
                        </Container>
                        <Container
                            style={{
                                fontWeight: 500,
                                fontSize: 18,
                            }}
                        >
                            {selectedVendor?.complete_count}
                        </Container>
                    </Container>
                </Container>
            )}

            {tab === INFO_TAB && (
                <Container direction="column">
                    <Container
                        direction="column"
                        style={{
                            marginTop: theme.spacing(2),
                        }}
                    >
                        <Container
                            style={{
                                fontWeight: 600,
                                fontSize: 16,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            About:
                        </Container>
                        {selectedVendor.description && (
                            <Container
                                style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    border: `1px solid ${theme.palette.lightGrey.light}`,
                                    borderRadius: '5px',
                                    padding: theme.spacing(1),
                                }}
                            >
                                {selectedVendor.description}
                            </Container>
                        )}
                    </Container>
                    <Container
                        direction="column"
                        style={{
                            marginTop: theme.spacing(4),
                        }}
                    >
                        <Container
                            style={{
                                fontWeight: 600,
                                fontSize: 16,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            References:
                        </Container>
                        <Container
                            direction="column"
                            style={{
                                fontSize: 14,
                                fontWeight: 400,
                            }}
                        >
                            {vendorDocuments.reference_1 && (
                                <DownloadDocument
                                    document={vendorDocuments.reference_1}
                                />
                            )}
                            {vendorDocuments.reference_2 && (
                                <DownloadDocument
                                    document={vendorDocuments.reference_2}
                                />
                            )}
                            {vendorDocuments.reference_3 && (
                                <DownloadDocument
                                    document={vendorDocuments.reference_3}
                                />
                            )}
                        </Container>
                    </Container>
                    <Container
                        direction="column"
                        style={{
                            marginTop: theme.spacing(2),
                        }}
                    >
                        <Container
                            style={{
                                fontWeight: 600,
                                fontSize: 16,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Company Docs:
                        </Container>
                        <Container
                            direction="column"
                            style={{
                                fontSize: 14,
                                fontWeight: 400,
                            }}
                        >
                            {vendorDocuments.insurance_1 && (
                                <DownloadDocument
                                    document={vendorDocuments.insurance_1}
                                />
                            )}
                            {vendorDocuments.insurance_2 && (
                                <DownloadDocument
                                    document={vendorDocuments.insurance_2}
                                />
                            )}
                            {vendorDocuments.w9_document && (
                                <DownloadDocument
                                    document={vendorDocuments.w9_document}
                                />
                            )}
                        </Container>
                    </Container>
                </Container>
            )}
        </Container>
    )
}

interface DownloadDocumentProps {
    document: Document
}

const DownloadDocument = (props: DownloadDocumentProps) => {
    const { document } = props

    const theme = useTheme()

    return (
        <Container
            style={{
                marginTop: theme.spacing(1),
                alignItems: 'center',
            }}
        >
            {document.name}
            <IconButton
                onClick={() => {
                    window.open(document.url)
                }}
                size="small"
                style={{
                    marginLeft: theme.spacing(2),
                }}
            >
                <GetApp />
            </IconButton>
        </Container>
    )
}
