import React, { useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import { red } from '@material-ui/core/colors'

import {
    AppBar,
    Tabs,
    Tab,
    Box,
    Typography,
    Card,
    CardContent,
    CardHeader,
} from '@material-ui/core'

import InspectionTypesList from './InspectionTypesList'
import InspectionsList from './InspectionsList'
import { useAptConfig, useAppDispatch } from '../../../../hooks'
import { getInspectionList, RootState } from '../../../../store'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            borderRadius: '15px',
            border: '1px solid #C2C2C2',
            boxShadow: '0px 6px 15px rgb(138 138 138 / 25%)',
        },
        headerLeft: {
            width: '30%',
            textAlign: 'center',
        },
        headerRight: {
            width: '70%',
            textAlign: 'center',
        },
        headerTitle: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '23px',
            display: 'block',
            color: '#008C85',
            textAlign: 'center',
        },
        headersubTitle: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '36px',
            // fontSize: '1.25rem',
            lineHeight: '42px',
        },
        inspectionTabs: {
            '& .MuiTab-wrapper': {
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '12px',
                lineHeight: '14px',
                color: '#000000',
                textTransform: 'inherit',
            },
            '& .Mui-selected .MuiTab-wrapper': {
                color: '#008c85',
            },
            '& .MuiTabs-indicator': {
                display: 'none',
            },
            '& .MuiTab-root': {
                minWidth: 'inherit',
                minHeight: '22px',
                textTransform: 'inherit',
                padding: '0 5px 0',
            },
        },
    }),
)

interface TabPanelProps {
    children?: React.ReactNode
    index: any
    value: any
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    )
}

const Inspections = () => {
    const classes = useStyles()
    const dispatch = useAppDispatch()

    const [tabValue, setTabValue] = React.useState(0)
    const [mostRecentInsp, setMostRecentInsp]: any = useState(null)

    const handleTabChange = (
        event: React.ChangeEvent<{}>,
        newValue: number,
    ) => {
        setTabValue(newValue)
    }

    const inspectionList = useSelector(
        (state: RootState) => state.inspection.inspectionList,
    )

    const { inspectionTypeList } = useAptConfig({
        inspectionTypeList: true,
        inventoryConfigList: true,
    })

    useEffect(() => {
        dispatch(getInspectionList({ params: {} }))
    }, [])

    useEffect(() => {
        const InspectionDec =
            inspectionList && inspectionList.length > 0
                ? inspectionList.sort((a: any, b: any) => {
                      return (
                          new Date(b.start_date).getTime() -
                          new Date(a.start_date).getTime()
                      )
                  })
                : []
        setMostRecentInsp(InspectionDec[0])
    }, [inspectionList])

    const getMostRecentPercent = (data: any) => {
        const uiTotal = data.ui_total ?? 0
        const uiComplete = data.ui_complete ?? 0

        const safeDenominator = uiTotal > 0 ? uiTotal : 1

        const progressPercent = (uiComplete / safeDenominator) * 100
        const progressStr = `${progressPercent.toFixed(1)}%`
        return progressStr
    }

    const getProgress = (data: any) => {
        const uiTotal = data.ui_total ?? 0
        const uiComplete = data.ui_complete ?? 0

        const progressStr = `${uiComplete} / ${uiTotal}`
        return progressStr
    }

    return (
        <Card className={`${classes.root}`}>
            <CardHeader
                // action={
                //     <IconButton aria-label="settings">
                //         <MoreVertIcon />
                //     </IconButton>
                // }
                title={
                    <span style={{ display: 'flex', width: '100%' }}>
                        <div className={classes.headerLeft}>
                            <span className={classes.headerTitle}>
                                Inspections
                            </span>
                            <span className={classes.headersubTitle}>
                                {inspectionList ? inspectionList?.length : 0}
                            </span>
                        </div>
                        <div className={classes.headerRight}>
                            <span className={classes.headerTitle}>
                                Most Recent Inspections
                            </span>
                            {mostRecentInsp ? (
                                <span className={classes.headersubTitle}>
                                    {getMostRecentPercent(mostRecentInsp)}
                                    <span
                                        style={{
                                            display: 'block',
                                            width: '100%',
                                            color: '#8A8A8A',
                                            fontSize: '0.625rem',
                                            lineHeight: '1.334',
                                        }}
                                    >
                                        {mostRecentInsp.name}
                                        <span style={{ paddingLeft: '20px' }}>
                                            {getProgress(mostRecentInsp)}
                                        </span>
                                    </span>
                                </span>
                            ) : (
                                <></>
                            )}
                        </div>
                    </span>
                }
            />
            <CardContent>
                <AppBar
                    position="static"
                    color="default"
                    style={{
                        boxShadow: 'none',
                        height: '22px',
                        backgroundColor: 'background: #F4F4F4',
                        marginBottom: '10px',
                    }}
                >
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        className={classes.inspectionTabs}
                        // variant="scrollable"
                        // scrollButtons="auto"
                        // aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Inspections / " />
                        <Tab label="Inspection Types" />
                    </Tabs>
                </AppBar>
                <TabPanel value={tabValue} index={0}>
                    <div>
                        <InspectionsList inspectionList={inspectionList} />
                    </div>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <InspectionTypesList
                        inspectionTypeList={inspectionTypeList}
                    />
                </TabPanel>
            </CardContent>
        </Card>
    )
}

export default Inspections
