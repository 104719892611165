import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Paper,
    Slide,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from '../../../components'
import CloseIcon from '@material-ui/icons/Close'
import {
    IdBoolMap,
    InspectionType,
    InventoryConfig,
    StatusGroup,
} from '../../../models'
import { NewInvItemForm } from './NewInvItemForm'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { PREVIEW_INSPECTION_TYPE_STEP } from './InspectionConfigurationModal'
import { defaultThemes } from 'react-data-table-component'
import { useUser } from '../../../hooks'
import { axiosInstance } from '../../../helpers'
import { toast } from 'react-toastify'
import { Search } from '@material-ui/icons'

interface Props {
    inspectionTypeName: string
    setInspectionTypeName: (inspectionTypeName: string) => void
    invItemsMap: IdBoolMap
    setInvItemsMap: (map: IdBoolMap) => void
    handleClose: () => void
    setTab: (tab: number) => void
    SubHeaderStyle: React.CSSProperties
    inspectionType?: InspectionType
    setMoveOutInspection?: (val: number) => void
}

export const INSPECTION_TYPE_FORM = 0
export const ADD_NEW_ITEM_FORM = 1
export const ADD_NEW_AREA_FORM = 2
export const ADD_NEW_STATUS_GROUP_FORM = 3

export const ConfigureInspectionType = (props: Props) => {
    const {
        handleClose,
        inspectionTypeName,
        setInspectionTypeName,
        setTab,
        invItemsMap,
        setInvItemsMap,
        SubHeaderStyle,
        inspectionType,
        setMoveOutInspection,
    } = props

    const { workspaceUser } = useUser()

    const inventoryConfigList = useSelector(
        (state: RootState) => state.aptConfig.inventoryConfigList,
    )

    const [formPage, setFormPage] = useState(INSPECTION_TYPE_FORM)

    const [doValidate, setDoValidate] = useState(false)

    const theme = useTheme()

    let atLeastOneChecked = false
    let allChecked = true

    Object.keys(invItemsMap).forEach((keyStr) => {
        const key = Number(keyStr)
        const valueChecked = invItemsMap[key]
        atLeastOneChecked = atLeastOneChecked || valueChecked
        allChecked = allChecked && valueChecked
    })

    const [searchText, setSearchText] = useState('')

    const filteredInvConfigList = inventoryConfigList?.filter((invConfig) =>
        invConfig.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase()),
    )

    const invConfigCheckBoxes =
        filteredInvConfigList?.map((invC) => {
            return (
                <FormControlLabel
                    key={`INV_CONF_${invC.id}_CHECK_INS_NEW_INSPECTION_TYPE`}
                    control={
                        <Checkbox
                            checked={invItemsMap[invC.id] ?? false}
                            style={{ color: theme.palette.primary.dark }}
                            onClick={() => {
                                const newCheckedMap: IdBoolMap = {
                                    ...invItemsMap,
                                }
                                newCheckedMap[invC.id] = !invItemsMap[invC.id]
                                setInvItemsMap(newCheckedMap)
                            }}
                        />
                    }
                    label={
                        <Container>
                            {' '}
                            {invC.name} ({invC.area_config_count})
                        </Container>
                    }
                    labelPlacement="end"
                    style={{
                        fontWeight: 'bold',
                        marginLeft: theme.spacing(0.5),
                    }}
                />
            )
        }) ?? []

    useEffect(() => {
        if (inspectionType) {
            setInspectionTypeName(inspectionType.name)
        }
    }, [])

    return (
        <Container flex={1} direction="column">
            <Container alignItems="center">
                <Container
                    style={{
                        fontWeight: 600,
                        fontSize: '20px',
                        flex: 1,
                    }}
                >
                    Step 1: {inspectionType ? 'Update' : 'Configure'} Inspection
                    Type
                </Container>
                <Tooltip title="Close">
                    <IconButton
                        onClick={() => {
                            handleClose()
                        }}
                    >
                        <CloseIcon
                            fontSize="inherit"
                            style={{
                                color: theme.palette.darkGreen.main,
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Container>

            <Container style={{ marginBottom: theme.spacing(0.5) }}>
                {formPage !== INSPECTION_TYPE_FORM && (
                    <Container>
                        <Container style={{ marginRight: theme.spacing(1) }}>
                            Inspection Name & Choosing Inventory
                        </Container>
                        /
                        <Container
                            style={{
                                margin: theme.spacing(0, 1, 0, 1),
                                color:
                                    formPage === ADD_NEW_ITEM_FORM
                                        ? theme.palette.primary.dark
                                        : '',
                            }}
                        >
                            {` `}Adding An Item
                        </Container>
                    </Container>
                )}
                {formPage === ADD_NEW_AREA_FORM && (
                    <Container>
                        <Container>/</Container>
                        <Container
                            style={{
                                marginLeft: theme.spacing(1),
                                color: theme.palette.primary.dark,
                            }}
                        >
                            Create New Area
                        </Container>
                    </Container>
                )}
                {formPage === ADD_NEW_STATUS_GROUP_FORM && (
                    <Container>
                        <Container>/</Container>
                        <Container
                            style={{
                                marginLeft: theme.spacing(1),
                                color: theme.palette.primary.dark,
                            }}
                        >
                            Create New Status Group
                        </Container>
                    </Container>
                )}
            </Container>

            <Divider />

            {formPage === INSPECTION_TYPE_FORM ? (
                <Container direction="column" style={{ flex: 1 }}>
                    <Container
                        direction="column"
                        style={{
                            maxHeight: 'calc(100vh - 250px)',
                            overflowY: 'auto',
                            paddingRight: theme.spacing(1),
                        }}
                    >
                        <Container
                            style={{ ...SubHeaderStyle, fontSize: '20px' }}
                        >
                            Inspection Name & Choosing Inventory
                        </Container>
                        <Container
                            style={{
                                fontSize: '13px',
                                color: theme.palette.darkGreen.main,
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            You have started to configure your inspection type.
                            In order to do so, please name your inspection type
                            and choose your inventory.
                        </Container>
                        <Container style={{ ...SubHeaderStyle }}>
                            Name Your Inspection Type:
                        </Container>
                        <Container style={{ marginBottom: theme.spacing(2) }}>
                            <TextField
                                error={doValidate && inspectionTypeName === ''}
                                helperText={
                                    doValidate && inspectionTypeName === ''
                                        ? 'Required'
                                        : ''
                                }
                                variant="outlined"
                                value={inspectionTypeName}
                                onChange={(e) =>
                                    setInspectionTypeName(e.target.value)
                                }
                                style={{
                                    width: '100%',
                                }}
                                size="small"
                                label="Inspection Type Name"
                            />
                        </Container>
                        <Container style={{ ...SubHeaderStyle }}>
                            Items You Selected:
                        </Container>
                        <SelectedItems invItemsMap={invItemsMap} />
                        <TextField
                            variant="standard"
                            placeholder="Search"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            style={{
                                marginRight: theme.spacing(4),
                            }}
                        />
                        <Container
                            direction="column"
                            style={{
                                border: `1px solid ${theme.palette.lightGrey.dark}`,
                                borderRadius: '5px',
                                marginTop: theme.spacing(1),
                            }}
                        >
                            <Container
                                direction="column"
                                style={{
                                    height: '150px',
                                    overflowY: 'auto',
                                }}
                            >
                                <FormControlLabel
                                    style={{ marginLeft: theme.spacing(0.5) }}
                                    control={
                                        <Checkbox
                                            checked={allChecked}
                                            style={{
                                                color:
                                                    theme.palette.primary.dark,
                                            }}
                                            // XOR
                                            indeterminate={
                                                (allChecked &&
                                                    !atLeastOneChecked) ||
                                                (!allChecked &&
                                                    atLeastOneChecked)
                                            }
                                            onChange={() => {
                                                const newMap: IdBoolMap = {}
                                                Object.keys(
                                                    invItemsMap,
                                                ).forEach((keyStr) => {
                                                    const key = Number(keyStr)
                                                    newMap[
                                                        key
                                                    ] = !atLeastOneChecked
                                                })
                                                setInvItemsMap(newMap)
                                            }}
                                        />
                                    }
                                    label={
                                        atLeastOneChecked
                                            ? 'Remove All'
                                            : 'Select All'
                                    }
                                />

                                {invConfigCheckBoxes}
                            </Container>
                            <Container
                                style={{
                                    borderTop: `1px solid ${theme.palette.lightGrey.dark}`,
                                }}
                            >
                                <Button
                                    variant="contained"
                                    style={{
                                        margin: theme.spacing(1, 0, 1, 2),
                                        backgroundColor: '#008C85',
                                        color: 'white',
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        setFormPage(ADD_NEW_ITEM_FORM)
                                    }
                                >
                                    + Add New Item
                                </Button>
                            </Container>
                        </Container>
                        {inspectionType && (
                            <Container style={{ marginTop: theme.spacing(2) }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        // margin: theme.spacing(3, 0, 3, 2),
                                        backgroundColor:
                                            theme.palette.primary.dark,
                                        color: 'white',
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        const req = {
                                            body: {
                                                move_out_inspection_config:
                                                    inspectionType?.id,
                                            },
                                        }
                                        const url = `company/apartment/${workspaceUser?.active_workspace.id}/`
                                        axiosInstance
                                            .patch(url, req.body)
                                            .then((res) => {
                                                if (
                                                    res.data
                                                        .move_out_inspection_config
                                                ) {
                                                    if (setMoveOutInspection)
                                                        setMoveOutInspection(
                                                            res.data
                                                                .move_out_inspection_config,
                                                        )
                                                    toast.success(
                                                        'Move in inspection type changed',
                                                    )
                                                } else {
                                                    toast.error(
                                                        'Can not find inspection type',
                                                    )
                                                }
                                            })
                                    }}
                                >
                                    Set Move In Inspection
                                </Button>
                            </Container>
                        )}
                    </Container>
                    <Container flex={1} />
                    <Container justifyContent="flex-end">
                        <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            style={{
                                textTransform: 'none',
                                margin: theme.spacing(1, 0, 1, 2),
                            }}
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                margin: theme.spacing(1, 0, 1, 2),
                                backgroundColor: '#008C85',
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                if (inspectionTypeName === '') {
                                    setDoValidate(true)
                                } else {
                                    setDoValidate(false)
                                    setTab(PREVIEW_INSPECTION_TYPE_STEP)
                                }
                            }}
                        >
                            Preview
                        </Button>
                    </Container>
                </Container>
            ) : (
                <Slide direction="left" in={formPage !== INSPECTION_TYPE_FORM}>
                    <Paper elevation={0} style={{ flex: 1 }}>
                        <NewInvItemForm
                            subHeaderStyle={SubHeaderStyle}
                            setFormPage={setFormPage}
                            invItemsMap={invItemsMap}
                            setInvItemsMap={setInvItemsMap}
                            formPage={formPage}
                        />
                    </Paper>
                </Slide>
            )}
        </Container>
    )
}

export const SelectedItems = (props: { invItemsMap: IdBoolMap }) => {
    const { invItemsMap } = props

    const inventoryConfigList = useSelector(
        (state: RootState) => state.aptConfig.inventoryConfigList,
    )

    const theme = useTheme()

    const selectedItems: JSX.Element[] = []

    inventoryConfigList?.forEach((inventoryConfig) => {
        if (invItemsMap[inventoryConfig.id]) {
            selectedItems.push(
                <Container
                    style={{
                        padding: theme.spacing(0.5),
                    }}
                >
                    {inventoryConfig.name} ({inventoryConfig.area_config_count})
                </Container>,
            )
        }
    })

    return (
        <Container
            style={{
                minHeight: '100px',
                overflowY: 'auto',
                border: `1px solid ${theme.palette.lightGrey.dark}`,
                borderRadius: '5px',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: theme.spacing(2),
            }}
        >
            {selectedItems.length !== 0 ? (
                <Container
                    flexWrap="wrap"
                    style={{ margin: theme.spacing(1, 0, 1, 0) }}
                >
                    {selectedItems}
                </Container>
            ) : (
                <Container>Select an item</Container>
            )}
        </Container>
    )
}
