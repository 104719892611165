import React, { useState, useEffect } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import { Carousel } from 'react-responsive-carousel'

import {
    ChevronRight as ChevronRightIcon,
    ChevronLeft as ChevronLeftIcon,
} from '@material-ui/icons'

import { useSchedule } from '../../../../hooks'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import ScheduleCard from './ScheduleCard'
import { Paper } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scheduleCarousel: {
            '& .carousel.carousel-slider': {
                overflow: 'visible',
            },
            '& .carousel .control-dots .dot.selected': {
                background: '#008c85',
            },
            '& .carousel .control-dots .dot': {
                background: '#D9D9D9',
            },
            '& .carousel .control-dots': {
                margin: '-18px 0',
            },
            '& .slide': {
                padding: '0 18px',
            },
        },
        paperRoot: {
            width: '100%',
            height: '100%',
            borderRadius: '15px',
            border: '1px solid #C2C2C2',
            boxShadow: '0px 6px 15px rgb(138 138 138 / 25%)',
        },
        slidePagination: {
            margin: '20px',
            float: 'right',
            '& .MuiPaginationItem-root': {
                color: '#000',
                fontSize: '0.688rem',
                border: '1px solid #C2C2C2',
                borderRadius: '5px',
                fontWeight: 700,
                height: '24px',
                '& .MuiPaginationItem-icon': {
                    fill: '#008C85',
                },
            },
            '& .MuiPaginationItem-root.Mui-selected': {
                color: '#008C85',
                fontSize: '0.688rem',
                border: '1px solid #008C85',
                borderRadius: '5px',
                fontWeight: 700,
                background: '#FFF',
            },
        },
    }),
)

const ScheduleCarousel = () => {
    const { scheduleList, getScheduleList } = useSchedule()

    const [showArchive, setShowArchive] = useState(false)

    useEffect(() => {
        getScheduleList({})
    }, [])

    console.log('schedule list is.....', scheduleList)
    const classes = useStyles()

    const [currentSlide, setCurrentSlide] = useState(0)

    const nextSlide = () => {
        setCurrentSlide(currentSlide + 1)
    }

    const prevSlide = () => {
        setCurrentSlide(currentSlide - 1)
    }

    const updateCurrentSlide = (index: number) => {
        if (currentSlide !== index) {
            setCurrentSlide(index)
        }
    }

    const handleSlideChange = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        setCurrentSlide(value - 1)
    }
    console.log('schedule list...', scheduleList)
    return (
        <>
            <Paper className={classes.paperRoot}>
                {scheduleList.length > 0 ? (
                    <Carousel
                        selectedItem={currentSlide}
                        onChange={updateCurrentSlide}
                        // infiniteLoop
                        showThumbs={false}
                        showArrows={false}
                        showStatus={false}
                        autoPlay={false}
                        showIndicators={false}
                        className={classes.scheduleCarousel}
                        renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
                            hasPrev && (
                                <ChevronLeftIcon
                                    onClick={clickHandler}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: 0,
                                        cursor: 'pointer',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )
                        }
                        renderArrowNext={(clickHandler, hasNext, labelNext) =>
                            hasNext && (
                                <ChevronRightIcon
                                    onClick={clickHandler}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: 0,
                                        cursor: 'pointer',
                                        marginRight: '-6px',
                                    }}
                                />
                            )
                        }
                    >
                        {scheduleList
                            .filter(
                                (schedule) => schedule.active === !showArchive,
                            )
                            .map((sch: any, index: number) => {
                                const schSeries = [
                                    {
                                        name: sch.name,
                                        data: [
                                            sch.approved_count,
                                            sch.complete_count,
                                            sch.inprogress_count,
                                            sch.behind_count,
                                            sch.not_started_count,
                                            sch.invoiced_count,
                                        ],
                                    },
                                ]
                                return (
                                    <div key={`chart-${index}`}>
                                        <ScheduleCard
                                            series={schSeries}
                                            chartId={`chart-${index}`}
                                            scheduleData={{
                                                approvedCount:
                                                    sch.approved_count,
                                                completeCount:
                                                    sch.complete_count,
                                                invoicedCount:
                                                    sch.invoiced_count,
                                                inProgressCount:
                                                    sch.inprogress_count,
                                                behindCount: sch.behind_count,
                                                notStartedCount:
                                                    sch.not_started_count,
                                                totalWorkOrders: 0,
                                                milestonesCompCount: 25,
                                                name: sch.name,
                                                active: sch.active,
                                                id: sch.id,
                                            }}
                                            nextSlide={nextSlide}
                                            prevSlide={prevSlide}
                                            currentSlide={currentSlide}
                                            totalSlide={scheduleList.length}
                                            showArchived={showArchive}
                                            setShowArchived={setShowArchive}
                                        />
                                    </div>
                                )
                            })}
                    </Carousel>
                ) : (
                    <ScheduleCard
                        series={[]}
                        chartId={null}
                        scheduleData={{
                            approvedCount: 0,
                            completeCount: 0,
                            invoicedCount: 0,
                            inProgressCount: 0,
                            behindCount: 0,
                            notStartedCount: 0,
                            totalWorkOrders: 0,
                            milestonesCompCount: 0,
                            name: '',
                            active: false,
                            id: 0,
                        }}
                        nextSlide={nextSlide}
                        prevSlide={prevSlide}
                        currentSlide={currentSlide}
                        totalSlide={scheduleList.length}
                        showArchived={showArchive}
                        setShowArchived={setShowArchive}
                    />
                )}
                {scheduleList.length > 0 ? (
                    <Pagination
                        count={
                            scheduleList.filter(
                                (schedule) => schedule.active === !showArchive,
                            ).length
                        }
                        variant="outlined"
                        shape="rounded"
                        className={classes.slidePagination}
                        onChange={handleSlideChange}
                    />
                ) : (
                    <></>
                )}
            </Paper>
        </>
    )
}

export default ScheduleCarousel
