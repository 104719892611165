import React, { useState, useEffect } from 'react'
import FileDownload from 'js-file-download'
import { FileCopy } from '@material-ui/icons'
import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish'

import { IconButton, Popover, Tooltip, useTheme } from '@material-ui/core'

import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Checkbox from '@material-ui/core/Checkbox'
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle'

import {
    Container,
    Finder,
    SideDrawer,
    SideDrawerContainer,
    useFinderStyles,
} from '../../components'
import {
    useAppDispatch,
    _useFinderSelection,
    useRootInfrastructure,
    useUser,
} from '../../hooks'
import {
    createFolder,
    FinderLocationSelection,
    FinderSelectionMode,
    getInfrastructureManagerRequest,
    getLocationFromSelection,
    moveUnits,
    deleteFolderRequest,
} from '../../store'
import { Folder, traverse, Unit, IdBoolMap } from '../../models'
import { InfrastructureTabs } from './InfrastructureTabs'
import { ROOT_ID } from '../../hooks/useInfrastructure/types'
import { usePopover } from '../../hooks/usePopover'
import { axiosInstance, isAccountManager } from '../../helpers'
import { toast } from 'react-toastify'

export const ACTION_ITEM_TAB = 0
export const WORKORDER_TAB = 1
export const INVENTORY_TAB = 2
export const DAMAGE_TAB = 3
export const LEASE_TAB = 4
export const MESSAGE_TAB = 5

export type InfrastructureManageTab =
    | typeof ACTION_ITEM_TAB
    | typeof WORKORDER_TAB
    | typeof INVENTORY_TAB
    | typeof DAMAGE_TAB
    | typeof LEASE_TAB
    | typeof MESSAGE_TAB

export const InfrastructureManager = () => {
    const [tab, setTab] = useState<InfrastructureManageTab>(ACTION_ITEM_TAB)

    const { workspaceUser } = useUser()

    const [moveUnitMode, setMoveUnitMode] = useState(false)
    const [moveUnitState, setMoveUnitState] = useState<IdBoolMap>({})
    const [mouseDown, setMouseDown] = useState(false)

    const locationFinderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Single,
    })

    const { tree, addFolder, removeNewFolders } = useRootInfrastructure(true)

    const theme = useTheme()
    const dispatch = useAppDispatch()

    const classes = useFinderStyles()

    const reportPopover = usePopover()

    const [editFolderId, setEditFolderId] = useState<number | undefined>()

    useEffect(() => {
        // get the users selection from the selection state
        const userSelection = getLocationFromSelection(
            locationFinderSelection.selection,
        )

        if (userSelection) {
            // The user currently has a folder or unit selected
            // create an array of unit primary keys that the user wants to see details for
            let units: string = ''

            if (userSelection.type === 'folder') {
                // The user has selected a folder.  Recursively add the folders lineage
                // to the unit primary key array
                const folder = userSelection.location as Folder

                traverse(folder, (current) => {
                    current.units.map((u) => (units += `${u.id},`))
                })
            } else {
                // The user has selected a unit.  Add the single unit to the selection array
                // units.push(userSelection.location.id)
                units = `${userSelection.location.id},`
            }

            units = units.substring(0, units.length - 1)

            // request the data from the backend
            dispatch(
                getInfrastructureManagerRequest({ params: { units: units } }),
            )
        }
    }, [locationFinderSelection.selection])

    const userSelection = getLocationFromSelection(
        locationFinderSelection.selection,
    )
    let headerStr = 'Select a Location'
    if (userSelection) {
        if (userSelection.type === 'folder') {
            const folderSelection = userSelection.location as Folder
            headerStr = `${folderSelection.path}${folderSelection.name}`
        } else {
            const unitSelection = userSelection.location as Unit
            headerStr = `${unitSelection.folder.path}${unitSelection.folder.name}: ${unitSelection.name}`
        }
    }

    const toggleMoveUnitMode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMoveUnitMode(event.target.checked)
        setMoveUnitState({})
    }

    useEffect(() => {
        const mouseDownEvent = () => {
            setMouseDown(true)
        }

        const mouseUpEvent = () => {
            setMouseDown(false)
        }

        document.addEventListener('mousedown', mouseDownEvent)

        document.addEventListener('mouseup', mouseUpEvent)

        return () => {
            document.removeEventListener('mousedown', mouseDownEvent)
            document.removeEventListener('mouseup', mouseUpEvent)
        }
    }, [])

    const DRAWER_WIDTH = 330

    return (
        <SideDrawerContainer open={true} width={DRAWER_WIDTH}>
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    width: '100%',
                    height: 'calc(100vh - 104px)',
                    // padding: theme.spacing(1),
                }}
            >
                {/* Header */}
                <Container>
                    <Container style={{ flexDirection: 'column' }}>
                        <span style={{ ...theme.typography.subtitle1 }}>
                            Infrastructure Manager
                        </span>

                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {headerStr}
                        </span>
                    </Container>
                    <div style={{ flex: 1 }} />

                    {isAccountManager(workspaceUser) && (
                        <IconButton
                            onClick={(e) => {
                                reportPopover.handleOpen(e)
                            }}
                        >
                            <FileCopy fontSize="large" />
                        </IconButton>
                    )}
                </Container>
                {/* End Header */}

                {/* Note */}
                <Container></Container>
                {/* End Note */}

                {/* Tabs */}
                <InfrastructureTabs
                    tab={tab}
                    setTab={setTab}
                    userSelection={userSelection}
                />

                {/* End Tabs */}
            </Container>

            <SideDrawer
                open={true}
                handleClose={() => {}}
                alwaysOpen
                title={
                    <Container style={{ alignItems: 'center' }}>
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightMedium,
                                marginLeft: theme.spacing(2),
                                marginRight: theme.spacing(2),
                            }}
                        >
                            Select Location
                        </span>

                        <FormControlLabel
                            labelPlacement="end"
                            control={
                                <Switch
                                    checked={moveUnitMode}
                                    onChange={toggleMoveUnitMode}
                                />
                            }
                            label="Edit"
                        />
                    </Container>
                }
                width={DRAWER_WIDTH}
            >
                <Container
                    flex={1}
                    direction="column"
                    style={{
                        padding: theme.spacing(2),
                        overflow: 'hidden',
                    }}
                >
                    <Finder
                        root={tree.root}
                        locationSelection={locationFinderSelection.selection}
                        onClickFolder={
                            locationFinderSelection.setFinderSelection
                        }
                        onClickUnit={locationFinderSelection.setFinderSelection}
                        editFolderId={editFolderId}
                        getFolderAdornment={(folder) => {
                            return (
                                <Container
                                    style={{
                                        flex: 1,
                                        marginLeft: theme.spacing(1),
                                    }}
                                    className={classes.infrastructure}
                                >
                                    <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            addFolder(folder.id)
                                        }}
                                        className={classes.icon}
                                    >
                                        <Tooltip title="Create new folder">
                                            <CreateNewFolderIcon
                                                fontSize="small"
                                                htmlColor={
                                                    theme.palette.grey[400]
                                                }
                                            />
                                        </Tooltip>
                                    </IconButton>

                                    {folder.id !== ROOT_ID && moveUnitMode && (
                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                e.stopPropagation()

                                                const unitIds = Object.keys(
                                                    moveUnitState,
                                                ).reduce<number[]>(
                                                    (prev, idStr) => {
                                                        const id = Number(idStr)
                                                        if (
                                                            moveUnitState[
                                                                id
                                                            ] === true
                                                        ) {
                                                            return prev.concat(
                                                                id,
                                                            )
                                                        }
                                                        return prev
                                                    },
                                                    [],
                                                )

                                                dispatch(
                                                    moveUnits({
                                                        folderId: folder.id,
                                                        body: {
                                                            units: unitIds,
                                                        },
                                                    }),
                                                ).finally(() => {
                                                    setMoveUnitState({})
                                                })
                                            }}
                                        >
                                            <Tooltip title="Move selected units here">
                                                <SwapVerticalCircleIcon
                                                    fontSize="small"
                                                    htmlColor={
                                                        theme.palette.grey[400]
                                                    }
                                                />
                                            </Tooltip>
                                        </IconButton>
                                    )}

                                    {folder.id !== ROOT_ID &&
                                        folder.units.length === 0 &&
                                        folder.children.length === 0 && (
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation()

                                                    dispatch(
                                                        deleteFolderRequest({
                                                            folderId: folder.id,
                                                        }),
                                                    )
                                                }}
                                            >
                                                <Tooltip title="Delete">
                                                    <DeleteForeverIcon
                                                        className={classes.icon}
                                                        fontSize="small"
                                                        htmlColor={
                                                            theme.palette
                                                                .grey[400]
                                                        }
                                                    />
                                                </Tooltip>
                                            </IconButton>
                                        )}
                                </Container>
                            )
                        }}
                        getUnitAdornment={(unit) => {
                            if (!moveUnitMode) {
                                return null
                            }
                            return (
                                <Container style={{ alignItems: 'center' }}>
                                    <Checkbox
                                        checked={
                                            moveUnitState[unit.id] === true
                                        }
                                        onMouseDown={() => {
                                            setMoveUnitState({
                                                ...moveUnitState,
                                                [unit.id]: !(
                                                    moveUnitState[unit.id] ===
                                                    true
                                                ),
                                            })
                                        }}
                                        onMouseOver={() => {
                                            if (mouseDown) {
                                                setMoveUnitState({
                                                    ...moveUnitState,
                                                    [unit.id]: !(
                                                        moveUnitState[
                                                            unit.id
                                                        ] === true
                                                    ),
                                                })
                                            }
                                        }}
                                    />
                                </Container>
                            )
                        }}
                        createFolder={(parentId, name) =>
                            dispatch(
                                createFolder({
                                    body: {
                                        name: name,
                                        parent: parentId,
                                    },
                                }),
                            )
                        }
                        cancelCreateFolder={removeNewFolders}
                        cancelEditFolder={() => setEditFolderId(undefined)}
                    />
                </Container>
            </SideDrawer>
            <Popover
                open={reportPopover.isOpen}
                anchorEl={reportPopover.anchorEl}
                onClose={reportPopover.handleClose}
            >
                <Container
                    style={{
                        padding: theme.spacing(2),
                        flexDirection: 'column',
                    }}
                >
                    <Container
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                                marginRight: theme.spacing(2),
                            }}
                        >
                            Area Labels
                        </span>
                        <div style={{ flex: 1 }} />

                        <Tooltip title="Upload">
                            <IconButton
                                onClick={() =>
                                    reportPopover.fileSelectorRef.current?.click()
                                }
                            >
                                <PublishIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Download">
                            <IconButton
                                onClick={() => {
                                    if (workspaceUser) {
                                        axiosInstance
                                            .get(
                                                `company/apartment/${workspaceUser.active_workspace.id}/download_area_name_worksheet/`,
                                                {
                                                    responseType: 'blob',
                                                },
                                            )
                                            .then((res) => {
                                                FileDownload(
                                                    new Blob([res.data]),
                                                    'area_label_sheet.xlsx',
                                                )
                                            })
                                    }
                                }}
                            >
                                <GetAppIcon />
                            </IconButton>
                        </Tooltip>
                    </Container>
                </Container>
            </Popover>

            <input
                ref={reportPopover.fileSelectorRef}
                type="file"
                style={{ display: 'none' }}
                accept={'.xlsx'}
                onChange={(e) => {
                    if (
                        e.target.files?.length !== undefined &&
                        e.target.files.length === 1
                    ) {
                        const sheet = e.target.files[0]

                        const reqBody = new FormData()
                        reqBody.append('sheet', sheet)

                        axiosInstance
                            .post(
                                `infrastructure/area/area_label_sheet/`,
                                reqBody,
                            )
                            .then((res) => {
                                const errorList: string[] = res.data

                                let successStr = 'Success'
                                errorList.forEach((error) => {
                                    successStr += `\n${error}`
                                })
                                toast.success(successStr)
                            })
                            .catch(() => {
                                toast.error(
                                    'There was a problem with your sheet.',
                                )
                            })
                    }
                }}
            />
        </SideDrawerContainer>
    )
}
