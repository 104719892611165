import { useTheme } from '@material-ui/core'
import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Container } from '../../components'
import { getNumFromURLSegment } from '../../helpers'
import { useAppDispatch } from '../../hooks'
import { VendorServiceAnalytic } from '../../models'
import {
    getVendorAnalyticsRequest,
    GET_VENDOR_ANALYTICS_REQUEST,
    RootState,
} from '../../store'
import { VendorAnalyticsBody } from './VendorAnalyticsBody'

export const VendorAnalytics = () => {
    const location = useLocation()
    const dispatch = useAppDispatch()
    const theme = useTheme()

    const analytics = useSelector(
        (state: RootState) => state.analytics.vendorAnalytics,
    )

    useEffect(() => {
        // use the url to get the information from analytics

        // url.pathname is the part after ezturn.co, for ex: /analytics/1/
        const urlSegment = location.pathname.substring(11) // the characters where the number is and beyond
        const venId = getNumFromURLSegment(urlSegment).number

        dispatch(getVendorAnalyticsRequest({ params: { vendor: venId } }))
    }, [])

    return (
        <Container
            direction="column"
            flex={1}
            style={{ height: 'calc(100vh - 104px)' }}
            // style={{ margin: theme.spacing(2) }}
        >
            <span
                style={{
                    ...theme.typography.h5,
                    fontWeight: theme.typography.fontWeightBold,
                    marginBottom: theme.spacing(1),
                }}
            >
                {analytics?.name} Analytics
            </span>

            <VendorAnalyticsBody analytics={analytics} />
        </Container>
    )
}
