import React, { useState, useEffect } from 'react'

import { Container } from '../../../components'
import { Button, TextField, useTheme } from '@material-ui/core'
import { DwollaCustomer } from '../../../models'

interface Props {
    dwollaCustomer: DwollaCustomer | null
    onSubmit: (businessName: string) => void
}

export const EditCustomerForm = (props: Props) => {
    const { dwollaCustomer, onSubmit } = props

    const [doValidate, setDoValidate] = useState(false)

    const [businessName, setBusinessName] = useState('')

    const theme = useTheme()

    useEffect(() => {
        if (dwollaCustomer) {
            setBusinessName(dwollaCustomer.businessName)
        }
    }, [dwollaCustomer])

    const isValid = () => {
        const nameValid = businessName !== ''
        return !doValidate || nameValid
    }

    return (
        <Container style={{ flex: 1, flexDirection: 'column' }}>
            <span
                style={{
                    ...theme.typography.h6,
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >
                Edit {dwollaCustomer?.businessName}
            </span>
            <TextField
                label="Business Name"
                value={businessName}
                fullWidth
                onChange={(e) => setBusinessName(e.target.value)}
                error={!isValid()}
                variant="outlined"
                style={{
                    marginBottom: theme.spacing(4),
                    marginTop: theme.spacing(4),
                }}
                required
            />

            <Button
                variant="contained"
                style={{
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.primary.contrastText,
                    textTransform: 'none',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setDoValidate(true)
                    if (isValid()) {
                        onSubmit(businessName)
                    }
                }}
            >
                Save
            </Button>
        </Container>
    )
}
