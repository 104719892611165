import React from 'react'
import { Container } from '../../../components'
import { dwollaApi } from '../../../contexts'
import { DwollaBeneficialOwner } from '../../../models'
import { IconButton, Popover, useTheme } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { toMMDDYYYY } from '../../../helpers'
import { usePopover } from '../../../hooks/usePopover'

export const BeneficialOwnerList = () => {
    const { beneficialOwners, deleteBeneficialOwner } = dwollaApi()

    return (
        <Container style={{ flexDirection: 'column' }}>
            {beneficialOwners?.map((owner) => {
                return (
                    <BeneficialOwnerRow
                        key={`BENEFICIAL_OWNER_ROW_${owner.id}`}
                        beneficialOwner={owner}
                        handleDelete={deleteBeneficialOwner}
                    />
                )
            })}
        </Container>
    )
}

interface RowProps {
    beneficialOwner: DwollaBeneficialOwner
    handleDelete: (owner: DwollaBeneficialOwner) => Promise<void>
}

const BeneficialOwnerRow = (props: RowProps) => {
    const { beneficialOwner } = props

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    const theme = useTheme()

    const createdDate = new Date(beneficialOwner.created_date)

    return (
        <Container
            style={{
                border: `1px solid ${theme.palette.grey[700]}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(2),
                margin: 8,
                height: 100,
                width: 'calc(50% - 16px)',
            }}
        >
            {/* Column 1 */}
            <Container style={{ flex: 1, flexDirection: 'column' }}>
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {beneficialOwner.first_name} {beneficialOwner.last_name}
                </span>

                <span>Added: {toMMDDYYYY(createdDate)}</span>
            </Container>

            {/* Column 2 */}
            <Container>
                <IconButton onClick={handleOpen}>
                    <MoreVertIcon fontSize="large" />
                </IconButton>
            </Container>

            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                onClose={handleClose}
            >
                <Container style={{ flexDirection: 'column' }}>
                    <Container
                        style={{
                            padding: theme.spacing(2),
                            justifyContent: 'center',
                            cursor: 'pointer',
                            backgroundColor: theme.palette.secondary.main,
                        }}
                        onClick={() =>
                            props
                                .handleDelete(beneficialOwner)
                                .then(handleClose)
                        }
                    >
                        <span
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                                color: theme.palette.secondary.contrastText,
                            }}
                        >
                            Delete
                        </span>
                    </Container>
                </Container>
            </Popover>
        </Container>
    )
}
