import { Grid, TableSortLabel, useTheme } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { Container } from '../../components'
import { Company, IdBoolMap, PlanStatus } from '../../models'
import { PropertyRow } from './PropertyRow'
import { VendorPlans } from '../VendorSettings/VendorPlans'
import { ApartmentVendorChannel } from './NationalPropertyList'

interface Props {
    properties: Company[]
    vendorPlan: string
    setSelectedProperty: (property: Company) => void
    setMessageDrawerOpen: (open: boolean) => void
    apartmentVendorChannels: ApartmentVendorChannel[]
    setApartmentVendorChannels: (
        aptVenChannel: ApartmentVendorChannel[],
    ) => void
    unlockedPropertyMap: IdBoolMap
    setOpenBuyProperty: (open: boolean) => void
    setOpenPropertyDetail: (open: boolean) => void
}

export type PropertyRowType = {
    id: number
    name: string
    address: string
    phoneNumber: string | null
    email?: string
    city: string
    state: string
    property: Company
}

const NameASC = 'NameASC'
const NameDESC = 'NameDESC'
const AddressASC = 'AddressASC'
const AddressDESC = 'AddressDESC'
const CityASC = 'CityASC'
const CityDESC = 'CityDESC'
const StateASC = 'StateASC'
const StateDESC = 'StateDESC'

export const NatPropListTable = (props: Props) => {
    const {
        properties,
        setSelectedProperty,
        vendorPlan,
        setMessageDrawerOpen,
        apartmentVendorChannels,
        setApartmentVendorChannels,
        unlockedPropertyMap,
        setOpenBuyProperty,
        setOpenPropertyDetail,
    } = props

    const theme = useTheme()

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [orderBy, setOrderBy] = useState('')

    const HeadCellStyle: React.CSSProperties = {
        fontWeight: 600,
        color: theme.palette.darkGreen.main,
        fontSize: '15px',
        flex: 2,
    }

    const createPropertyRow = (property: Company): PropertyRowType => {
        return {
            id: property.id,
            name: property.name,
            address: property.address,
            phoneNumber: property.phone,
            email: property.email,
            city: property.city,
            state: property.state,
            property: property,
        }
    }

    const [sortedRows, setSortedRows] = useState<PropertyRowType[]>([])

    useEffect(() => {
        const rows = properties.map((comp: Company) => createPropertyRow(comp))
        setSortedRows(rows)
    }, [properties])

    return (
        <Container scrollY style={{ flex: 1 }}>
            <Grid
                container={true}
                style={{
                    padding: theme.spacing(2, 0, 1, 0),
                }}
            >
                <Grid container={true}>
                    <Container
                        style={{ ...HeadCellStyle }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== NameASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.name.localeCompare(RowB.name),
                                )
                                setSortedRows(newRows)
                                setOrderBy(NameASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(NameDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === NameASC || orderBy === NameDESC}
                            direction={orderBy === NameASC ? 'asc' : 'desc'}
                        >
                            Name
                        </TableSortLabel>
                    </Container>
                    <Container
                        style={{ ...HeadCellStyle }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== AddressASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.address.localeCompare(RowB.address),
                                )
                                setSortedRows(newRows)
                                setOrderBy(AddressASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(AddressDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={
                                orderBy === AddressASC ||
                                orderBy === AddressDESC
                            }
                            direction={orderBy === AddressASC ? 'asc' : 'desc'}
                        >
                            Address
                        </TableSortLabel>
                    </Container>
                    <Container style={{ ...HeadCellStyle }}>
                        Contact Information
                    </Container>
                    <Container
                        style={{ ...HeadCellStyle }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== CityASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.city.localeCompare(RowB.city),
                                )
                                setSortedRows(newRows)
                                setOrderBy(CityASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(CityDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === CityASC || orderBy === CityDESC}
                            direction={orderBy === CityASC ? 'asc' : 'desc'}
                        >
                            City
                        </TableSortLabel>
                    </Container>
                    <Container
                        style={{ ...HeadCellStyle }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== StateASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.state.localeCompare(RowB.state),
                                )
                                setSortedRows(newRows)
                                setOrderBy(StateASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(StateDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={
                                orderBy === StateASC || orderBy === StateDESC
                            }
                            direction={orderBy === StateASC ? 'asc' : 'desc'}
                        >
                            State
                        </TableSortLabel>
                    </Container>
                    {vendorPlan === PlanStatus.PREMIUM && (
                        <Container
                            style={{
                                ...HeadCellStyle,
                                flex: 1,
                                // justifyContent: 'center',
                            }}
                        >
                            Message
                        </Container>
                    )}
                </Grid>

                <Grid
                    container={true}
                    style={{
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 400px)',
                    }}
                >
                    {sortedRows
                        .map((row, index) => {
                            return (
                                <PropertyRow
                                    row={row}
                                    key={index}
                                    setSelectedProperty={setSelectedProperty}
                                    vendorPlan={vendorPlan}
                                    setMessageDrawerOpen={setMessageDrawerOpen}
                                    apartmentVendorChannels={
                                        apartmentVendorChannels
                                    }
                                    setApartmentVendorChannels={
                                        setApartmentVendorChannels
                                    }
                                    unlockedPropertyMap={unlockedPropertyMap}
                                    setOpenBuyProperty={setOpenBuyProperty}
                                    setOpenPropertyDetail={
                                        setOpenPropertyDetail
                                    }
                                />
                            )
                        })
                        .slice(
                            (page - 1) * rowsPerPage,
                            (page - 1) * rowsPerPage + rowsPerPage,
                        )}
                </Grid>

                <Container style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Pagination
                        count={Math.ceil(sortedRows.length / rowsPerPage)}
                        page={page}
                        onChange={(_: unknown, newPage: number) => {
                            setPage(newPage)
                        }}
                        variant="outlined"
                        shape="rounded"
                        style={{ margin: theme.spacing(1, 0, 0, 0) }}
                    />
                </Container>
            </Grid>
        </Container>
    )
}
