import React, { Dispatch, SetStateAction } from 'react'
import { Bid, ModelMap, Service, convertMapToList } from '../../models'
import { BidFilterState } from '../../contexts'
import { Card, FormGroup, IconButton, Switch, Theme } from '@material-ui/core'
import { Container, Selector, TimelinePicker } from '../../components'
import { Actionable } from '../../components/Actionable'
import ClearIcon from '@material-ui/icons/Clear'

interface Props {
    bidList: Bid[] | null
    bidFilterState: BidFilterState
    theme: Theme
    serviceList: Service[]
    setBidFilterState: Dispatch<SetStateAction<BidFilterState>>
}

export const BidFilterForm = (props: Props) => {
    const {
        bidList,
        bidFilterState,
        theme,
        setBidFilterState,
        serviceList,
    } = props

    const selectorStyle: React.CSSProperties = {
        margin: theme.spacing(1, 0),
        flex: 1,
    }

    const apartmentMap: ModelMap<{
        id: number
        name: string
    }> = {}
    bidList?.forEach((bid) => {
        apartmentMap[bid.rfp.apartment.id] = {
            id: bid.rfp.apartment.id,
            name: bid.rfp.apartment.name,
        }
    })
    const apartmentList = convertMapToList(apartmentMap)

    return (
        <Container style={{ flexDirection: 'column' }}>
            <Card
                style={{
                    padding: theme.spacing(2),
                    borderRadius: 0,
                    flexShrink: 0,
                }}
            >
                <Actionable
                    theme={theme}
                    interactionComponent={
                        <Switch
                            value={bidFilterState.timeline.enabled}
                            onChange={(_, checked) => {
                                setBidFilterState({
                                    ...bidFilterState,
                                    timeline: {
                                        ...bidFilterState.timeline,
                                        enabled: checked,
                                    },
                                })
                            }}
                        />
                    }
                >
                    <TimelinePicker
                        disabled={!bidFilterState.timeline.enabled}
                        startDate={bidFilterState.timeline.startDate}
                        endDate={bidFilterState.timeline.endDate}
                        onChangeStartDate={(d) => {
                            setBidFilterState({
                                ...bidFilterState,
                                timeline: {
                                    ...bidFilterState.timeline,
                                    startDate: d,
                                },
                            })
                        }}
                        onChangeEndDate={(d) => {
                            setBidFilterState({
                                ...bidFilterState,
                                timeline: {
                                    ...bidFilterState.timeline,
                                    endDate: d,
                                },
                            })
                        }}
                    />
                </Actionable>
            </Card>
            <Card
                style={{
                    padding: theme.spacing(2),
                    borderRadius: 0,
                    flexShrink: 0,
                }}
            >
                <FormGroup>
                    <Actionable
                        theme={theme}
                        interactionComponent={
                            <IconButton
                                onClick={() =>
                                    setBidFilterState({
                                        ...bidFilterState,
                                        serviceIds: [],
                                    })
                                }
                            >
                                <ClearIcon />
                            </IconButton>
                        }
                    >
                        <Selector
                            data={serviceList}
                            customStyle={{ formControl: selectorStyle }}
                            label="Services"
                            maxItems={10}
                            onChange={(e) => {
                                const selection: number[] = e.target
                                    .value as any

                                setBidFilterState({
                                    ...bidFilterState,
                                    serviceIds: selection,
                                })
                            }}
                            currentValue={bidFilterState.serviceIds}
                            getDisplayString={(v) => v.name}
                            searchable
                            multiple
                        />
                    </Actionable>
                    <Actionable
                        theme={theme}
                        interactionComponent={
                            <IconButton
                                onClick={() =>
                                    setBidFilterState({
                                        ...bidFilterState,
                                        apartmentIds: [],
                                    })
                                }
                            >
                                <ClearIcon />
                            </IconButton>
                        }
                    >
                        <Selector
                            data={apartmentList}
                            customStyle={{ formControl: selectorStyle }}
                            label="Property"
                            maxItems={10}
                            onChange={(e) => {
                                const selection: number[] = e.target
                                    .value as any

                                setBidFilterState({
                                    ...bidFilterState,
                                    apartmentIds: selection,
                                })
                            }}
                            currentValue={bidFilterState.apartmentIds}
                            getDisplayString={(v) => v.name}
                            searchable
                            multiple
                        />
                    </Actionable>
                </FormGroup>
            </Card>
        </Container>
    )
}
