import React, { useEffect, useState } from 'react'
import { AxiosError } from 'axios'

import {
    Button,
    CircularProgress,
    Fade,
    Modal,
    Paper,
    Switch,
    useTheme,
} from '@material-ui/core'
import { Container } from '../../components'

import { useAppDispatch, useCompany, useUser } from '../../hooks'
import {
    ErrorResponse,
    getJobsiteServiceList,
    RootState,
    setJobsiteService,
    setNetworkError,
} from '../../store'
import { useSelector } from 'react-redux'
import { useStyles } from '../../styles'
import { useHistory } from 'react-router-dom'
import { axiosInstance, resolveRoute, Routes } from '../../helpers'
import { Company, ModelMap, getDateRange } from '../../models'
import { DateFilter } from './dateFilter'
import { StatusFilter } from '.'
import { toast } from 'react-toastify'

interface WorkorderCountStruct {
    count: number | null
    id: number
    name: string | null
}

export const VendorSchedulerService = () => {
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()
    const { workspaceUser, updateUser } = useUser()

    const dispatch = useAppDispatch()
    const services = useSelector(
        (state: RootState) => state.jobsite.serviceList,
    )

    const dateFilter = useSelector(
        (state: RootState) => state.jobsite.dateFilter,
    )

    const statusFilter = useSelector(
        (state: RootState) => state.jobsite.workorderStatusFilter,
    )

    const [showModal, setShowModal] = useState(false)
    const [
        modalShowInactiveWorkorders,
        setModalShowInactiveWorkorders,
    ] = useState(false)

    const linkStyle: React.CSSProperties = {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[900],
        flexDirection: 'column',
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        padding: theme.spacing(1),
        height: '120px',
        width: '120px',
    }

    const totalCount = services?.reduce(
        (accum, service) => accum + service.count,
        0,
    )

    const [selectedJobsite, setSelectedJobsite] = useState<number | undefined>()
    const WORKSPACETAB = 1
    const SCHEDULETAB = 2

    const [selectedTab, setSelectedTab] = useState<Number>(WORKSPACETAB)

    const [jobsiteInfo, setJobsiteInfo] = useState<
        ModelMap<WorkorderCountStruct>
    >({})
    const [jobSites, setJobsites] = useState<Company[]>([])

    const [loadingState, setLoadingState] = useState(true)

    useEffect(() => {
        //when the date filter is changed, hit the api again for the new date filter, also for the initial hit
        const dateRange = getDateRange(dateFilter)
        dispatch(
            getJobsiteServiceList({
                params: { ...dateRange, wo_status: statusFilter },
            }),
        )
    }, [dateFilter, statusFilter, selectedJobsite])

    useEffect(() => {
        axiosInstance
            .get('jobsite/workorders-count')
            .then((res) => {
                const newJobsiteInfo: ModelMap<WorkorderCountStruct> = {}
                res.data.map((woCount: WorkorderCountStruct) => {
                    newJobsiteInfo[woCount.id] = woCount
                })
                setJobsiteInfo(newJobsiteInfo)
            })
            .finally(() => {
                setLoadingState(false)
            })

        axiosInstance.get(`company/apartment`).then((res) => {
            setJobsites(res.data)
        })
    }, [])

    return (
        <Container
            direction="column"
            flex={1}
            style={{ padding: theme.spacing(1) }}
        >
            <Container>
                <span
                    style={{
                        marginRight: theme.spacing(0.5),
                        cursor: 'pointer',
                        color:
                            selectedTab === WORKSPACETAB
                                ? 'black'
                                : theme.palette.darkGreen.main,
                    }}
                    onClick={() => {
                        setSelectedTab(WORKSPACETAB)
                    }}
                >
                    Jobsite
                </span>
                /
                <span
                    style={{
                        marginLeft: theme.spacing(0.5),
                        cursor: 'pointer',
                        color:
                            selectedTab === SCHEDULETAB
                                ? 'black'
                                : theme.palette.darkGreen.main,
                    }}
                    onClick={() => {
                        if (selectedJobsite !== undefined) {
                            setSelectedTab(SCHEDULETAB)
                        }
                    }}
                >
                    Schedule
                </span>
            </Container>
            {selectedTab === WORKSPACETAB && (
                <Container direction="column">
                    <Container
                        style={{
                            fontWeight: 700,
                            fontSize: '40px',
                            marginTop: theme.spacing(3),
                        }}
                    >
                        Jobsite
                    </Container>

                    <Container
                        style={{
                            fontWeight: 400,
                            fontSize: '15px',
                            marginTop: theme.spacing(1),
                        }}
                    >
                        Choose the jobsite you are going to be working in today!
                    </Container>

                    <Container
                        className={classes.wrap}
                        style={{
                            marginTop: theme.spacing(3),
                        }}
                    >
                        {loadingState ? (
                            <CircularProgress color="primary" />
                        ) : (
                            jobSites.map((jobsite) => {
                                const IsSelectedJobsite =
                                    selectedJobsite === jobsite.id

                                let workorderCount = 0
                                if (jobsiteInfo[jobsite.id]) {
                                    workorderCount =
                                        jobsiteInfo[jobsite.id]?.count ?? 0
                                }

                                return (
                                    <Container
                                        key={`COMPANY_NAME_${jobsite.id}`}
                                        style={{
                                            border: !IsSelectedJobsite
                                                ? '1px solid #C2C2C2'
                                                : '',
                                            padding: theme.spacing(3, 0, 3, 2),
                                            margin: theme.spacing(2, 4, 2, 0),
                                            cursor: 'pointer',
                                            boxShadow: IsSelectedJobsite
                                                ? theme.shadows[6]
                                                : '',
                                            width: '300px',
                                        }}
                                        onClick={() => {
                                            updateUser({
                                                isCurrentUser: true,
                                                params: {
                                                    id: workspaceUser!.id,
                                                },
                                                body: { jobsite: jobsite.id },
                                            }).then(() => {
                                                const dateRange = getDateRange(
                                                    dateFilter,
                                                )
                                                dispatch(
                                                    getJobsiteServiceList({
                                                        params: {
                                                            ...dateRange,
                                                            wo_status: statusFilter,
                                                        },
                                                    }),
                                                )
                                            })
                                            setSelectedJobsite(jobsite.id)
                                            setSelectedTab(SCHEDULETAB)
                                        }}
                                        direction="column"
                                    >
                                        <span
                                            style={{
                                                fontSize: '20px',
                                                fontWeight: 600,
                                            }}
                                        >
                                            {jobsite.name}
                                        </span>

                                        <span
                                            style={{
                                                fontWeight: 500,
                                                fontSize: '15px',
                                                marginTop: theme.spacing(2),
                                            }}
                                        >
                                            {workorderCount} Work Orders
                                        </span>
                                    </Container>
                                )
                            })
                        )}
                    </Container>
                </Container>
            )}

            {selectedTab === SCHEDULETAB && (
                <Container direction="column">
                    {/* Header */}
                    <Container
                        direction="row"
                        style={{ padding: theme.spacing(1, 1, 3, 1) }}
                    >
                        <Container flex={1}>
                            <span
                                style={{
                                    ...theme.typography.h5,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Select A Service
                            </span>
                            <span
                                style={{
                                    ...theme.typography.subtitle2,
                                    alignSelf: 'center',
                                    paddingLeft: theme.spacing(1),
                                }}
                            >
                                {`${totalCount} Workorders`}
                            </span>
                        </Container>
                        <Container
                            style={{ margin: theme.spacing(0, 2, 1, 0) }}
                            alignItems="flex-end"
                        >
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Jobsite:{' '}
                            </span>
                            <span>{workspaceUser?.jobsite?.name}</span>
                        </Container>
                    </Container>
                    <Container>
                        <Container style={{ flex: 1 }}>
                            <DateFilter
                                style={{
                                    formControl: {
                                        width: 150,
                                        marginLeft: theme.spacing(3),
                                    },
                                }}
                            />
                            <StatusFilter
                                style={{
                                    formControl: {
                                        width: 150,
                                        marginLeft: theme.spacing(3),
                                    },
                                }}
                            />
                        </Container>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => setShowModal(true)}
                        >
                            Email Sub Expense Sheet
                        </Button>
                    </Container>
                    {/* Services */}
                    <Container
                        className={classes.wrap}
                        style={{ padding: theme.spacing(1, 1, 0, 1) }}
                    >
                        {services?.map((service) => {
                            return (
                                <Container
                                    key={`VENDOR_SERVICE_SELECTOR_${service.id}`}
                                    className={`${classes.centerBox} ${classes.boxShadow}`}
                                    style={linkStyle}
                                    onClick={() => {
                                        dispatch(setJobsiteService(service))
                                        history.push(
                                            resolveRoute(
                                                Routes.scheduleLocation,
                                                ':id',
                                                service.id,
                                            ),
                                        )
                                    }}
                                >
                                    <span
                                        style={{
                                            ...theme.typography.subtitle1,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {service.name}
                                    </span>
                                    <span>{`${service.count} Workorders`}</span>
                                </Container>
                            )
                        })}
                    </Container>
                </Container>
            )}

            {/* email warning and option modal */}
            <Modal
                open={showModal}
                onClose={() => {
                    setShowModal(false)
                    setModalShowInactiveWorkorders(false)
                }}
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }}
            >
                <Fade in={showModal}>
                    <Paper
                        style={{
                            height: '30%',
                            width: '30%',
                            minHeight: 240,
                            minWidth: 240,
                            display: 'flex',
                            overflow: 'hidden',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            outline: 'none',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                                padding: theme.spacing(1),
                            }}
                        >
                            IMPORTANT
                        </span>
                        <span
                            style={{
                                padding: theme.spacing(1),
                                ...theme.typography.subtitle2,
                            }}
                        >
                            This will email every Admin in your workspace. Are
                            you sure you would like to do this?
                        </span>

                        <Container style={{ marginTop: theme.spacing(2) }}>
                            <Switch
                                checked={modalShowInactiveWorkorders}
                                color="primary"
                                onClick={() =>
                                    setModalShowInactiveWorkorders(
                                        !modalShowInactiveWorkorders,
                                    )
                                }
                            />
                            <span style={{ alignSelf: 'center' }}>
                                Show Inactive Workorders
                            </span>
                        </Container>

                        <Container
                            alignItems="flex-end"
                            justifyContent="flex-end"
                            flex={1}
                            style={{ padding: theme.spacing(1) }}
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    setShowModal(false)
                                    setModalShowInactiveWorkorders(false)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginLeft: theme.spacing(1) }}
                                onClick={() => {
                                    axiosInstance
                                        .post(
                                            '/workorder/sub-workorder/expense_sheet/',
                                            {
                                                apartment:
                                                    workspaceUser?.jobsite?.id,
                                                show_inactive: modalShowInactiveWorkorders,
                                            },
                                        )
                                        .then(() => {
                                            toast.success(
                                                'You will receive your expense report email shortly.',
                                            )
                                        })
                                        .catch((e) => {
                                            const error = e as AxiosError<ErrorResponse>
                                            dispatch(
                                                setNetworkError(error, true),
                                            )
                                        })
                                        .finally(() => {
                                            setShowModal(false)
                                            setModalShowInactiveWorkorders(
                                                false,
                                            )
                                        })
                                }}
                            >
                                Confirm
                            </Button>
                        </Container>
                    </Paper>
                </Fade>
            </Modal>
        </Container>
    )
}
