import React from 'react'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@material-ui/core'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { Close as CloseIcon } from '@material-ui/icons'

interface Props {
    open: boolean
    handleClose: () => void
    fullWidth: boolean
    dialogHeader: JSX.Element[] | JSX.Element | String
    dialogContent: JSX.Element[] | JSX.Element | String
}
const DialogBox = (props: Props) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                margin: 0,
                padding: theme.spacing(2),
                '& .MuiDialog-paperWidthSm': {
                    maxWidth: '800px',
                },
                '& .MuiDialog-paper': {
                    borderRadius: '15px',
                    width: props.fullWidth ? '100% !important' : 'auto',
                },
                '& .MuiDialogTitle-root .MuiTypography-h6': {
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    borderBottom: '1px solid #C2C2C2',
                    paddingBottom: '14px',
                },
                '& .MuiDialogTitle-root': {
                    padding: '22px 32px 0px 32px',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '1.25rem',
                    fontWeight: 700,
                },
                '& .MuiDialogContent-root': {
                    padding: '8px 24px 24px 24px',
                },
            },
            closeButton: {
                // position: 'absolute',
                // right: theme.spacing(1),
                // top: theme.spacing(1),
                color: theme.palette.grey[500],
                // padding: 0,
            },
        }),
    )

    const classes = useStyles()
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={props.fullWidth}
            className={classes.root}
        >
            <DialogTitle id="form-dialog-title">
                <span style={{ width: '100%', fontWeight: 'bold' }}>
                    {props.dialogHeader}
                </span>

                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={props.handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>{props.dialogContent}</DialogContent>
            {/* <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={props.handleClose} color="primary">
                    Save
                </Button>
            </DialogActions> */}
        </Dialog>
    )
}

export default DialogBox
