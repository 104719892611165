import React, { useState } from 'react'
import { AddressForm, AddressState, Container } from '../../../../../components'
import { Button, Divider, Theme } from '@material-ui/core'
import { ListVendor, RegisterStep } from '../../../../../models'
import { useCompany } from '../../../../../hooks'

interface Props {
    type: string
    vendor: ListVendor | undefined
    theme: Theme
    onNextStep: () => void
    addressState: AddressState
    setAddressState: React.Dispatch<React.SetStateAction<AddressState>>
}

export const StepQuestionnaireArea = (props: Props) => {
    const {
        type,
        vendor,
        theme,
        onNextStep,
        addressState,
        setAddressState,
    } = props

    const [error, setError] = useState<string>('')

    const { updateVendor } = useCompany()

    const handleSaveAndContinue = () => {
        if (
            !addressState.addressLine1 ||
            !addressState.city ||
            !addressState.state ||
            !addressState.zip ||
            !addressState.country
        ) {
            setError('Please fill out all required fields.')
            return
        }

        setError('')
        if (vendor) {
            updateVendor({
                params: {
                    id: vendor.id,
                },
                body: {
                    address:
                        addressState.addressLine1 +
                        ' ' +
                        addressState.addressLine2,
                    city: addressState.city,
                    state: addressState.state,
                    zip_code: addressState.zip,
                    register_step: RegisterStep.SELECT_SERVICES_STEP,
                },
            })
        }
        props.onNextStep()
    }

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    margin: theme.spacing(2, 0, 2, 0),
                    color: theme.palette.grey[800],
                    alignItems: 'center',
                }}
            >
                <span
                    style={{
                        ...theme.typography.h4,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {`Welcome ${type}!`}
                </span>
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Find your service area.
                </span>
            </Container>
            <Divider />
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {/* Address Form */}
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        padding: theme.spacing(2),
                        minWidth: '50%',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        Service Area Address
                    </span>
                    <AddressForm
                        addressState={addressState}
                        setAddressState={setAddressState}
                        inputStyle={{
                            marginBottom: theme.spacing(2),
                        }}
                    />
                </Container>
                {/* Map */}
                <Container
                    style={{
                        flex: 1,
                        minWidth: '100%',
                        height: '100%',
                        padding: theme.spacing(2),
                    }}
                >
                    <iframe
                        width="100%"
                        loading="lazy"
                        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                            &q=${addressState.city}_${addressState.state}`}
                    />
                </Container>
            </Container>
            {error && (
                <span
                    style={{
                        color: 'red',
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        marginBottom: theme.spacing(2),
                    }}
                >
                    {error}
                </span>
            )}
            <Button
                style={{
                    backgroundColor: theme.palette.primary.dark,
                    color: 'white',
                    borderRadius: theme.shape.borderRadius,
                    textTransform: 'none',
                    marginBottom: theme.spacing(3),
                }}
                onClick={handleSaveAndContinue}
            >
                Save & Continue
            </Button>
        </Container>
    )
}

const defaultAddressState: AddressState = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
}
