import { ErrorResponse } from '../types'
import { ErrorActionTypes, SET_NETWORK_ERROR } from './types'

import { AxiosError } from 'axios'

export const setNetworkError = (
    error: AxiosError<ErrorResponse>,
    display: boolean,
): ErrorActionTypes => {
    return {
        type: SET_NETWORK_ERROR,
        payload: error,
        display: display,
    }
}
