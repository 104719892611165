import React from 'react'
import { Attachment, Container } from '../../components'
import {
    Divider,
    IconButton,
    Modal,
    Paper,
    Slide,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import {
    AreaConfig,
    InventoryConfig,
    InventoryInspection,
    Issue,
    Lease,
    ModelListMap,
    ModelMap,
    Unit,
    getIssueCount,
} from '../../models'
import { toMMDDYYYY } from '../../helpers'

interface Props {
    open: boolean
    handleClose: () => void
    areaConfigMap: ModelMap<AreaConfig>
    invConfigMap: ModelMap<InventoryConfig>
    unitMap: ModelMap<Unit>
    lease?: Lease
}

export const MoveInModal = (props: Props) => {
    const {
        open,
        handleClose,
        lease: parentLease,
        areaConfigMap,
        unitMap,
        invConfigMap,
    } = props

    const theme = useTheme()

    const flatLeaseList = [
        parentLease as Lease,
        ...((parentLease?.children ?? []) as Lease[]),
    ]

    let startDate = ''
    let endDate = ''
    if (parentLease) {
        startDate = toMMDDYYYY(new Date(parentLease.start_date))
        endDate = toMMDDYYYY(new Date(parentLease.end_date))
    }

    const issueCount = parentLease ? getIssueCount(parentLease) : 0

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflowY: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: 900,
                        height: 700,
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <Container
                        style={{
                            padding: theme.spacing(3, 0, 4, 2),
                            flex: 1,
                            overflowY: 'scroll',
                        }}
                        direction="column"
                    >
                        {/* Header */}
                        <Container
                            style={{
                                marginBottom: theme.spacing(1),
                                flexDirection: 'column',
                            }}
                        >
                            <Container>
                                <span
                                    style={{
                                        ...theme.typography.h5,
                                        fontWeight: 'bold',
                                        alignSelf: 'center',
                                    }}
                                >
                                    Move In Result: ({issueCount}) Issues Found
                                </span>
                                <div style={{ flex: 1 }} />
                                <Tooltip title="Close">
                                    <IconButton onClick={handleClose}>
                                        <Close fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                            </Container>
                            <Container>
                                <Container style={{ flexDirection: 'column' }}>
                                    <span
                                        style={{
                                            marginBottom: theme.spacing(1),
                                        }}
                                    >
                                        Tenant: {parentLease?.user.name}
                                    </span>
                                    <span>
                                        Email: {parentLease?.user.email}
                                    </span>
                                </Container>
                                <div style={{ flex: 1 }} />
                                <Container style={{ flexDirection: 'column' }}>
                                    <span
                                        style={{
                                            marginBottom: theme.spacing(1),
                                        }}
                                    >
                                        Location:{' '}
                                        {
                                            unitMap[
                                                parentLease?.area.unit ?? -1
                                            ]?.name
                                        }
                                    </span>
                                    <span>
                                        Date: {startDate} - {endDate}
                                    </span>
                                </Container>
                            </Container>
                        </Container>

                        <Divider />

                        {/* Body */}
                        <Container
                            style={{
                                flex: 1,
                                overflowY: 'scroll',
                            }}
                            direction="column"
                        >
                            {/* Move In Results */}
                            {flatLeaseList.map((lease, idx) => {
                                const areaNameAndLabel =
                                    areaConfigMap[lease?.area.area_config ?? -1]
                                        ?.name +
                                    ' (' +
                                    lease?.area.area_label +
                                    ')'

                                const invConfigList: InventoryConfig[] = []
                                // key in dict is inv_conf id
                                const moveInIssues: ModelListMap<Issue> = {}
                                lease?.tenant_move_in_inspection?.inventory_inspection?.forEach(
                                    (inv_ins) => {
                                        const config =
                                            invConfigMap[
                                                inv_ins.inventory_config
                                            ]
                                        if (config) {
                                            invConfigList.push(config)
                                        }
                                        moveInIssues[
                                            inv_ins.inventory_config
                                        ] = []
                                        inv_ins.issue?.forEach((iss) => {
                                            moveInIssues[
                                                inv_ins.inventory_config
                                            ]?.push(iss)
                                        })
                                    },
                                )

                                return (
                                    <Container
                                        style={{
                                            borderRadius:
                                                theme.shape.borderRadius,
                                            border: `3px solid ${theme.palette.grey[400]}`,
                                            flexDirection: 'column',
                                            flex: 1,
                                            marginBottom: theme.spacing(2),
                                        }}
                                        key={`INNER_LEASE_${idx}_${lease?.id}_MOVE_IN_MODAL`}
                                    >
                                        <Container
                                            style={{
                                                borderRadius:
                                                    theme.shape.borderRadius,
                                                borderBottom: `3px solid ${theme.palette.grey[400]}`,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    padding: theme.spacing(2),
                                                    ...theme.typography.h6,
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {areaNameAndLabel}
                                            </span>

                                            {lease?.tenant_move_in_inspection &&
                                                lease?.tenant_move_in_inspection
                                                    ?.auto_completed === 1 && (
                                                    <span
                                                        style={{
                                                            ...theme.typography
                                                                .body1,
                                                            alignSelf: 'center',
                                                            color:
                                                                theme.palette
                                                                    .secondary
                                                                    .main,
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Auto Completed
                                                    </span>
                                                )}
                                        </Container>

                                        {invConfigList.map((invConfig) => {
                                            return (
                                                <MoveInResultInvItem
                                                    key={`MOVE_IN_INV_${invConfig.id}`}
                                                    invConfig={invConfig}
                                                    moveInIssues={moveInIssues}
                                                />
                                            )
                                        })}
                                    </Container>
                                )
                            })}
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

interface MoveOutResultInvItemProps {
    invConfig: InventoryConfig
    moveOutInvIns: ModelMap<InventoryInspection>
}

const MoveOutResultInvItem = (props: MoveOutResultInvItemProps) => {
    const { invConfig, moveOutInvIns } = props

    const theme = useTheme()

    return (
        <Container
            style={{ height: 200, flexDirection: 'column' }}
            key={`MOVE_OUT_INV_${invConfig.id}`}
        >
            <span
                style={{
                    ...theme.typography.h6,
                    padding: theme.spacing(1, 1, 0, 2),
                    fontWeight: 'bold',
                }}
            >
                {invConfig.name}
            </span>
            {moveOutInvIns[invConfig.id] && (
                <Container
                    style={{
                        border: `2px solid ${
                            moveOutInvIns[invConfig.id]?.status?.color
                        }`,
                        backgroundColor: 'white',
                        borderRadius: theme.shape.borderRadius,
                        boxShadow: `2px 2px 6px #ffffff`,
                        padding: theme.spacing(1),
                        margin: theme.spacing(1),
                        flexDirection: 'row',
                        height: 70,
                        minWidth: 200,
                        maxWidth: 200,
                        cursor: 'pointer',
                        alignItems: 'center',
                    }}
                >
                    <span style={theme.typography.body1}>
                        {moveOutInvIns[invConfig.id]?.status?.name}
                    </span>
                </Container>
            )}
        </Container>
    )
}

interface MoveInResultInvItemProps {
    invConfig: InventoryConfig
    moveInIssues: ModelListMap<Issue>
}

const MoveInResultInvItem = (props: MoveInResultInvItemProps) => {
    const { invConfig, moveInIssues } = props

    const theme = useTheme()

    return (
        <Container
            style={{
                marginBottom: theme.spacing(1),
                flexDirection: 'column',
            }}
            key={`MOVE_IN_INV_${invConfig.id}`}
        >
            <span
                style={{
                    ...theme.typography.h6,
                    padding: theme.spacing(1, 1, 0, 2),
                    fontWeight: 'bold',
                }}
            >
                {invConfig.name}
            </span>
            {moveInIssues[invConfig.id] &&
                moveInIssues[invConfig.id]?.map((issue) => {
                    const displayName = issue.issue_config
                        ? issue.issue_config.name
                        : issue.description
                    return (
                        <Container
                            key={`ISSUE_${issue.id}`}
                            style={{
                                flexDirection: 'row',
                                margin: theme.spacing(1, 1, 1, 5),
                                border: `1px solid ${theme.palette.grey[900]}`,
                                borderRadius: theme.shape.borderRadius,
                                height: 150,
                                overflowY: 'scroll',
                                alignItems: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.body1,
                                    marginLeft: theme.spacing(1),
                                    overflowY: 'scroll',
                                    flex: 1,
                                }}
                            >
                                {displayName}
                            </span>

                            <Container
                                style={{
                                    padding: theme.spacing(1),
                                    paddingBottom: 0,
                                    paddingRight: 0,
                                    alignItems: 'center',
                                    overflowX: 'auto',
                                    minWidth: 320,
                                }}
                            >
                                {issue.message.attachments.map((attachment) => {
                                    return (
                                        <div
                                            key={`ATTACHMENT_${attachment.id}`}
                                            style={{}}
                                        >
                                            <Attachment
                                                attachment={attachment.file}
                                                uploaded={attachment.uploaded}
                                                theme={theme}
                                                size={90}
                                            />
                                        </div>
                                    )
                                })}
                            </Container>
                        </Container>
                    )
                })}
        </Container>
    )
}
