import { Theme } from '@material-ui/core'
import { DwollaTransferStatus } from './types'

export const getTransferUIStatus = (status?: DwollaTransferStatus | null) => {
    if (status === null || status === undefined) {
        return 'Not Initiated'
    }

    switch (status) {
        case DwollaTransferStatus.REQUEST:
            return 'Requested'
        case DwollaTransferStatus.CANCELLED:
            return 'Cancelled'
        case DwollaTransferStatus.FAILED:
            return 'Failed'
        case DwollaTransferStatus.PENDING:
            return 'Pending'
        case DwollaTransferStatus.PROCESSED:
            return 'Processed'
    }

    return 'ERR'
}

export const getTransferUIColor = (
    theme: Theme,
    status?: DwollaTransferStatus | null,
) => {
    if (status === null || status === undefined) {
        return {
            backgroundColor: 'grey',
            color: 'white',
        }
    }

    switch (status) {
        case DwollaTransferStatus.CANCELLED:
            return {
                backgroundColor: theme.palette.GO_BACK.main,
                color: theme.palette.GO_BACK.contrastText,
            }
        case DwollaTransferStatus.PENDING:
            return {
                backgroundColor: theme.palette.IN_PROGRESS.main,
                color: theme.palette.IN_PROGRESS.contrastText,
            }
        case DwollaTransferStatus.FAILED:
            return {
                backgroundColor: 'red',
                color: 'white',
            }
        case DwollaTransferStatus.PROCESSED:
            return {
                backgroundColor: theme.palette.COMPLETE.main,
                color: theme.palette.COMPLETE.contrastText,
            }
        case DwollaTransferStatus.REQUEST:
            return {
                backgroundColor: theme.palette.ASSIGNED.main,
                color: theme.palette.ASSIGNED.contrastText,
            }
    }

    return {
        backgroundColor: 'grey',
        color: 'white',
    }
}
