import { BULK_DELETE_WORKORDERS, SET_WORKORDER_IS_LOADING } from '.'
import {
    BaseWorkorder,
    ChangeOrder,
    createWorkorderFromResponse,
} from '../../../models'
import {
    WorkorderState,
    GET_WORKORDER_LIST_REQUEST,
    GET_WORKORDER_LIST_RESPONSE,
    WorkorderActionTypes,
    CREATE_WORKORDER_REQUEST,
    CREATE_WORKORDER_RESPONSE,
    UPDATE_WORKORDER_REQUEST,
    UPDATE_WORKORDER,
    TRANSITION_WORKORDER_REQUEST,
    UPDATE_BULK_WORKORDERS,
    DELETE_WORKORDER,
    UPDATE_CHANGE_ORDER_STATUS,
    SET_PORTFOLIO_WORKORDER_LIST,
    ADD_NEW_CHANGE_ORDER,
} from './types'

const initialState: WorkorderState = {
    workorderList: [],
    portfolioWorkorderList: [],
    isLoading: {
        GET_WORKORDER_LIST_REQUEST: false,
        CREATE_WORKORDER_REQUEST: false,
        UPDATE_WORKORDER_REQUEST: false,
        UPDATE_BULK_WORKORDERS_REQUEST: false,
        BULK_DELETE_WORKORDER_REQUEST: false,
        GET_OR_CREATE_WORKORDER_CHANNEL: false,
    },
}

export const workorderReducer = (
    state = initialState,
    action: WorkorderActionTypes,
): WorkorderState => {
    switch (action.type) {
        case UPDATE_WORKORDER_REQUEST:
        case GET_WORKORDER_LIST_REQUEST:
        case CREATE_WORKORDER_REQUEST:
        case TRANSITION_WORKORDER_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.type]: true,
                },
            }

        case GET_WORKORDER_LIST_RESPONSE:
            return {
                ...state,
                workorderList: action.payload,
                isLoading: {
                    ...state.isLoading,
                    GET_WORKORDER_LIST_REQUEST: false,
                },
            }

        case CREATE_WORKORDER_RESPONSE:
            return {
                ...state,
                workorderList: [
                    ...state.workorderList,
                    ...action.payload.workorders.map(
                        createWorkorderFromResponse,
                    ),
                ],
                isLoading: {
                    ...state.isLoading,
                    CREATE_WORKORDER_REQUEST: false,
                },
            }
        case UPDATE_WORKORDER:
            return {
                ...state,
                workorderList: state.workorderList?.reduce<BaseWorkorder[]>(
                    (prev, wo) => {
                        // if action.workorder is a string, the workorder was deleted
                        if (wo.id === action.workorderId) {
                            if (typeof action.workorder === 'string')
                                return prev
                            return prev.concat(action.workorder)
                        }
                        return prev.concat(wo)
                    },
                    [],
                ),
                isLoading: {
                    ...state.isLoading,
                    [action.endLoading ?? -1]: false,
                },
            }

        case UPDATE_BULK_WORKORDERS:
            return updateBulkWorkorders(state, action.workorders)

        case DELETE_WORKORDER:
            return {
                ...state,
                workorderList: state.workorderList?.reduce<BaseWorkorder[]>(
                    (prev, wo) => {
                        if (wo.id === action.workorderId) {
                            return prev
                        }
                        return prev.concat(wo)
                    },
                    [],
                ),
            }

        case BULK_DELETE_WORKORDERS:
            return bulkDeleteWorkorders(state, action.workorderIds)

        case UPDATE_CHANGE_ORDER_STATUS:
            return updateChangeOrderStatus(
                state,
                action.updatedWorkorder,
                action.newWorkOrder,
            )
        case SET_PORTFOLIO_WORKORDER_LIST:
            return {
                ...state,
                portfolioWorkorderList: action.workorderList,
            }
        case SET_WORKORDER_IS_LOADING:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.request]: action.isLoading,
                },
            }
        case ADD_NEW_CHANGE_ORDER:
            return addNewChangeOrder(
                state,
                action.workorder,
                action.changeOrder,
            )

        default:
            return state
    }
}

const updateChangeOrderStatus = (
    initialState: WorkorderState,
    updatedWorkorder: BaseWorkorder,
    newWorkOrder?: BaseWorkorder,
) => {
    const newWorkorderList: BaseWorkorder[] = []

    for (let i = 0; i < initialState.workorderList.length; i++) {
        // Get the current workorder from state
        const currentWorkorder = initialState.workorderList[i]

        if (currentWorkorder.id === updatedWorkorder.id) {
            // if the current workorder matches the updated workorder, add the updated version to the new state
            newWorkorderList.push(updatedWorkorder)

            if (newWorkOrder) {
                // The user added a new workorder
                // go ahead and add it to the list
                newWorkorderList.push(newWorkOrder)
            }
        } else {
            // otherwise add the original one.
            newWorkorderList.push(currentWorkorder)
        }
    }

    return {
        ...initialState,
        workorderList: newWorkorderList,
    }
}

const addNewChangeOrder = (
    initialState: WorkorderState,
    workorder: BaseWorkorder,
    changeOrder: ChangeOrder,
) => {
    const newWorkorderList: BaseWorkorder[] = []

    for (let i = 0; i < initialState.workorderList.length; i++) {
        // Get the current workorder from state
        const currentWorkorder = initialState.workorderList[i]

        if (currentWorkorder.id === workorder.id) {
            // if the current workorder matches the updated workorder, add the updated version to the new state
            workorder.changeorder_set?.push(changeOrder)
            newWorkorderList.push(workorder)
        } else {
            // otherwise add the original one.
            newWorkorderList.push(currentWorkorder)
        }
    }

    return {
        ...initialState,
        workorderList: newWorkorderList,
    }
}

const bulkDeleteWorkorders = (
    initialState: WorkorderState,
    removeIds: number[],
): WorkorderState => {
    let removeIdx = 0

    return {
        ...initialState,
        workorderList: initialState.workorderList.filter((wo) => {
            // If the entire remove array has been handled return true for the rest of the loop

            if (removeIdx >= removeIds.length) {
                return true
            }

            if (wo.id === removeIds[removeIdx]) {
                // This workorder should be removed
                // incremenet the removeIdx and move on
                removeIdx += 1
                return false
            }

            return true
        }),
    }
}

const updateBulkWorkorders = (
    initialState: WorkorderState,
    mergeWorkorders: BaseWorkorder[],
): WorkorderState => {
    // mergeWorkorders must be sorted in the same way that initialState.workorderList is sorted
    // This method is intended to provide bulk update functionality when the updating is done
    // by the server

    if (initialState.workorderList === undefined) {
        return initialState
    }

    // While the mergeWorkorders will be in the "correct" sort order.  There will be
    // workorders that belong to a different schedule than the workorders found
    // in initialState.workorderList.  We must first start by setting the mergeIdx
    // to the first workorder where the schedule is shared.

    const scheduleId =
        initialState.workorderList.length > 0
            ? initialState.workorderList[0].schedule_id
            : -1

    let mergeIdx = 0
    while (
        mergeIdx < mergeWorkorders.length &&
        scheduleId !== mergeWorkorders[mergeIdx].schedule_id
    ) {
        mergeIdx += 1
    }

    const newWorkorderList: BaseWorkorder[] = []

    // Loop through the state workorders.  When a mergeWorkorder is found
    // with a matching id repalce the state workorder with the new workorder

    for (let i = 0; i < (initialState.workorderList?.length ?? 0); i++) {
        const mergeWo = mergeWorkorders[mergeIdx]

        const stateWo = initialState.workorderList[i]

        if (stateWo.id === mergeWo?.id) {
            // Matching ids.  Use the mergeWo and disregard the stateWo
            newWorkorderList.push(mergeWo)
            mergeIdx += 1
        } else {
            // This workorder was not updated.  Continue searching
            newWorkorderList.push(stateWo)
        }
    }

    return {
        ...initialState,
        workorderList: newWorkorderList,
    }
}
