import React, { useEffect, useState } from 'react'
import { Container } from '../../../../components'
import {
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
    useTheme,
} from '@material-ui/core'
import { axiosInstance } from '../../../../helpers'
import { User } from '../../../../models'

interface Props {
    user: User
}

export const ConfirmRegistrationStep = (props: Props) => {
    const { user } = props

    const [checked, setChecked] = useState(false)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [doValidate, setDoValidate] = useState(false)

    useEffect(() => {
        const names = user.name.split(' ')
        if (names[0] !== undefined) {
            setFirstName(names[0])
        }
        if (names[1] !== undefined) {
            setLastName(names[1])
        }
    }, [user])

    const theme = useTheme()

    return (
        <Container
            style={{
                flexDirection: 'column',
                flex: 1,
                padding: theme.spacing(2),
            }}
        >
            <Container style={{ marginTop: theme.spacing(2) }}>
                <TextField
                    label="First Name"
                    fullWidth
                    value={firstName}
                    onChange={(e) => {
                        setFirstName(e.target.value)
                    }}
                    error={doValidate && firstName === ''}
                    helperText={doValidate && firstName === '' && 'Required'}
                />
                <TextField
                    label="Last Name"
                    fullWidth
                    style={{ marginLeft: theme.spacing(1) }}
                    value={lastName}
                    onChange={(e) => {
                        setLastName(e.target.value)
                    }}
                    error={doValidate && lastName === ''}
                    helperText={doValidate && lastName === '' && 'Required'}
                />
            </Container>
            <div style={{ flex: 1 }} />

            <Container style={{ marginTop: theme.spacing(4) }}>
                <FormControlLabel
                    labelPlacement="end"
                    control={
                        <Checkbox
                            color="primary"
                            checked={checked}
                            onChange={() => setChecked((c) => !c)}
                        />
                    }
                    label={
                        <span
                            style={{
                                ...theme.typography.caption,
                                fontWeight: theme.typography.fontWeightLight,
                                color: theme.palette.grey[600],
                            }}
                        >
                            By checking this box you agree to{' '}
                            <a
                                href="https://www.dwolla.com/legal/tos/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                our Terms of Service
                            </a>{' '}
                            and{' '}
                            <a
                                href="https://www.dwolla.com/legal/tos/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>
                        </span>
                    }
                />

                <Button
                    disabled={!checked}
                    onClick={() => {
                        axiosInstance
                            .post('ezpay/customer/', {
                                first_name: firstName,
                                last_name: lastName,
                            })
                            .then(() => {})
                            .catch((err) => {})
                    }}
                    color="primary"
                    variant="outlined"
                    style={{ textTransform: 'none', minWidth: 300 }}
                >
                    Agree and Continue
                </Button>
            </Container>
        </Container>
    )
}
