import React, { useState } from 'react'
import { Container } from '../../../../../components'
import { Button, Checkbox, FormControlLabel, Theme } from '@material-ui/core'
import { Routes } from '../../../../../helpers'
import { VendorPlans } from '../../../../../containers/VendorSettings/VendorPlans'
import { ListVendor, RegisterStep } from '../../../../../models'
import { useHistory } from 'react-router-dom'
import { useCompany } from '../../../../../hooks'

interface Props {
    theme: Theme
    vendor: ListVendor | undefined
}

export const StepQuestionnaireAgreement = (props: Props) => {
    const { theme, vendor } = props

    const { updateVendor } = useCompany()

    const history = useHistory()

    const [checked, setChecked] = useState(false)

    return (
        <Container style={{ flex: 1, justifyContent: 'center' }}>
            <Container
                style={{ flexDirection: 'column', alignItems: 'center' }}
            >
                <Container
                    style={{
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Container
                        style={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Container
                            style={{
                                minHeight: 500,
                                border: `1px solid ${theme.palette.primary.dark}`,
                                borderRadius: '40px',
                                boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
                                margin: theme.spacing(2),
                                padding: theme.spacing(2),
                                alignSelf: 'stretch',
                                flexDirection: 'column',
                            }}
                        >
                            {/* Body */}
                            <Container>
                                {vendor && <VendorPlans vendor={vendor} />}
                            </Container>
                        </Container>
                    </Container>
                    <Container style={{ marginTop: theme.spacing(4) }}>
                        <FormControlLabel
                            labelPlacement="end"
                            control={
                                <Checkbox
                                    style={{
                                        color: theme.palette.primary.dark,
                                    }}
                                    checked={checked}
                                    onChange={() => setChecked((c) => !c)}
                                />
                            }
                            label={
                                <span
                                    style={{
                                        ...theme.typography.caption,
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                        color: theme.palette.grey[600],
                                    }}
                                >
                                    By checking this box you agree to{' '}
                                    <a
                                        href="https://www.ezos.co/terms"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        our Terms of Service
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        href="https://www.ezos.co/privacy"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                    , as well as our partner{' '}
                                    <a
                                        href="https://www.dwolla.com/legal/tos/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Dwollas Terms of Service
                                    </a>{' '}
                                    and{' '}
                                    <a
                                        href="https://www.dwolla.com/legal/privacy/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Privacy Policy
                                    </a>
                                    .
                                </span>
                            }
                        />
                    </Container>
                    <Button
                        disabled={!checked}
                        onClick={() => {
                            history.push(Routes.home)
                            if (vendor) {
                                updateVendor({
                                    params: {
                                        id: vendor.id,
                                    },
                                    body: {
                                        register_step:
                                            RegisterStep.COMPLETED_STEP,
                                    },
                                })
                            }
                        }}
                        variant="outlined"
                        style={{
                            textTransform: 'none',
                            minWidth: 300,
                            backgroundColor: checked
                                ? theme.palette.primary.dark
                                : theme.palette.grey[300],
                            color: 'white',
                        }}
                    >
                        Agree and Continue
                    </Button>
                </Container>
            </Container>
        </Container>
    )
}
