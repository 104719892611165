import React, { useState } from 'react'
import { Button, IconButton, TextField, useTheme } from '@material-ui/core'
import { Container, Selector } from '../../components'
import { CREATE_QUESTIONS_STEP, GET_STARTED_STEP } from './CreateAndEditRFP'
import { ModelMap, RFP, RFPService, Service } from '../../models'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../helpers'
import { DeleteForever } from '@material-ui/icons'
import { CreateOrUpdateRFPRequest, rfpApi } from '../../contexts'
import { DocumentCard } from '../../components/DocumentCard/DocumentCard'

interface Props {
    description: string
    RFPServicesMap: ModelMap<RFPService>
    RFPServices: RFPService[]
    rfp: RFP | null
    viewMode?: boolean
    setRFPServices: (RFPServices: RFPService[]) => void
    setRFPServicesMap: (map: ModelMap<RFPService>) => void
    setDescription: (description: string) => void
    setActiveStep: (step: number) => void
    updateRFPBody: () => CreateOrUpdateRFPRequest
}

export const ScopeOfWorkForm = (props: Props) => {
    const {
        description,
        setDescription,
        setActiveStep,
        RFPServices,
        setRFPServices,
        updateRFPBody,
        viewMode,
        rfp,
    } = props

    const { updateRFP, uploadRFPDocument, editRFPDocument } = rfpApi()

    const noService: Service = {
        id: -1,
        name: ' ',
        color: '',
        order: 0,
    }

    const noRFPService: RFPService = {
        id: -1,
        service: noService,
        service_details: [],
    }

    const [selectedRFPServiceId, setSelectedRFPServiceId] = useState(
        RFPServices[0]?.id ?? noRFPService.id,
    )

    const [doValidate, setDoValidate] = useState(false)

    const theme = useTheme()

    const uiServiceList: RFPService[] = [noRFPService, ...RFPServices]

    const selectedRFPService = RFPServices.find(
        (RFPService) => RFPService.id === selectedRFPServiceId,
    )

    const checkValidation = () => {
        if (description === '') {
            setDoValidate(true)
            return false
        }
        let serviceDetailMissing = false
        RFPServices.forEach((rfpService) => {
            rfpService.service_details.forEach((serviceDetail) => {
                if (serviceDetail.question_answer === '') {
                    serviceDetailMissing = true
                }
            })
        })
        if (serviceDetailMissing) {
            setDoValidate(true)
            return false
        }
        return true
    }

    return (
        <Container
            direction="column"
            style={{
                padding: theme.spacing(0, 2, 2, 2),
                width: 'calc(100vw - 550px)',
            }}
        >
            <Container
                style={{
                    ...theme.typography.h5,
                    fontWeight: 600,
                }}
            >
                Scope of Work
            </Container>
            <Container
                direction="column"
                style={{ overflow: 'auto', height: 'calc(100vh - 280px)' }}
            >
                <Container
                    style={{
                        margin: theme.spacing(3, 0, 2, 0),
                        justifyContent: 'space-between',
                    }}
                    direction="column"
                >
                    <span style={{ fontWeight: 600, fontSize: '16px' }}>
                        Description of work *
                    </span>
                    <span
                        style={{
                            ...theme.typography.body2,
                            color: theme.palette.primary.dark,
                            margin: theme.spacing(1, 0),
                        }}
                    >
                        Provide a detailed description to help vendors respond!
                    </span>
                    <TextField
                        variant="outlined"
                        size="small"
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value)
                        }}
                        multiline
                        error={doValidate && description === ''}
                        helperText={
                            doValidate && description === '' && 'Required'
                        }
                    />
                </Container>
                <Container direction="column">
                    <Container>
                        <Container
                            style={{ fontWeight: 600, fontSize: '16px' }}
                        >
                            Upload Documents:{' '}
                        </Container>
                        <Container style={{ marginLeft: theme.spacing(2) }}>
                            <input
                                color="primary"
                                type="file"
                                accept="image/png, image/jpeg, image/jpg, .xlxs, .pdf, .doc, .docx, .xlsx"
                                onChange={(e) => {
                                    if (
                                        e.target.files &&
                                        e.target.files[0] &&
                                        rfp
                                    ) {
                                        console.log(e.target.files)
                                        const body = new FormData()
                                        body.append('file', e.target.files[0])
                                        body.append(
                                            'file_name',
                                            e.target.files[0].name,
                                        )
                                        uploadRFPDocument(rfp, body)
                                        console.log(body.get('file'))
                                    }
                                }}
                                style={{ display: 'none' }}
                                id={`RFP_${rfp?.id}_DOCUMENT_UPLOAD`}
                            />

                            <label htmlFor={`RFP_${rfp?.id}_DOCUMENT_UPLOAD`}>
                                <Button
                                    component="span"
                                    style={{
                                        backgroundColor:
                                            theme.palette.darkBlue.main,
                                        textTransform: 'none',
                                        color: 'white',
                                        padding: theme.spacing(0.5, 1, 0.5, 1),
                                        fontSize: 10,
                                    }}
                                >
                                    Upload
                                </Button>
                            </label>
                        </Container>
                    </Container>
                    <Container
                        style={{
                            ...theme.typography.body2,
                            color: theme.palette.primary.dark,
                            marginTop: theme.spacing(1),
                        }}
                    >
                        Toggle whether you would like vendors to view a document
                        (Public) or not (Private)
                    </Container>
                    <Container
                        style={{ marginTop: theme.spacing(2) }}
                        flexWrap="wrap"
                    >
                        {rfp?.rfp_documents.map((document) => {
                            return (
                                <DocumentCard
                                    key={`DOCUMENT_${document.id}`}
                                    document={document}
                                    rfp={rfp}
                                    editDocument={editRFPDocument}
                                />
                            )
                        })}
                    </Container>
                </Container>
                <Container
                    style={{
                        margin: theme.spacing(3, 0, 2, 0),
                        justifyContent: 'space-between',
                    }}
                    direction="column"
                >
                    <span style={{ fontWeight: 600, fontSize: '16px' }}>
                        Job Breakdown:
                    </span>
                    <span
                        style={{
                            ...theme.typography.body2,
                            color: theme.palette.primary.dark,
                            marginTop: theme.spacing(1),
                        }}
                    >
                        Provide details about each service
                    </span>
                    <Container alignItems="center">
                        <Selector
                            label={'Service'}
                            currentValue={selectedRFPServiceId}
                            onChange={(
                                event: React.ChangeEvent<{
                                    value: unknown
                                }>,
                            ) => {
                                setSelectedRFPServiceId(
                                    event.target.value as number,
                                )
                            }}
                            data={uiServiceList}
                            getDisplayString={(s: RFPService) => s.service.name}
                            size="small"
                            customStyle={{
                                formControl: {
                                    marginTop: theme.spacing(2),
                                    width: '80%',
                                },
                            }}
                        />
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: 'white',
                                color: theme.palette.primary.dark,
                                borderColor: theme.palette.primary.dark,
                                textTransform: 'none',
                                cursor: 'pointer',
                                width: '180px',
                                margin: theme.spacing(3, 0, 1, 3),
                            }}
                            disabled={selectedRFPServiceId === -1}
                            onClick={() => {
                                const body = {
                                    rfp_service_id: selectedRFPServiceId,
                                }
                                axiosInstance
                                    .post(
                                        `service-contract/rfp/create_service_detail/`,
                                        body,
                                    )
                                    .then((res) => {
                                        const tempRFPServiceList = RFPServices.map(
                                            (rfpService) => {
                                                if (
                                                    rfpService.id ===
                                                    selectedRFPService?.id
                                                ) {
                                                    const tempRFPService = rfpService
                                                    tempRFPService.service_details.push(
                                                        res.data,
                                                    )
                                                }
                                                return rfpService
                                            },
                                        )
                                        setRFPServices(tempRFPServiceList)
                                    })
                                    .catch((e) => {
                                        toast.error(e.response.data.message)
                                    })
                            }}
                        >
                            Additional Details
                        </Button>
                    </Container>
                    {selectedRFPService?.service_details.map(
                        (serviceDetail) => {
                            return (
                                <Container
                                    style={{ margin: theme.spacing(1, 0) }}
                                    key={`SERVICE_DETAIL_${serviceDetail.id}`}
                                >
                                    <Container
                                        direction="column"
                                        style={{ width: '100%' }}
                                    >
                                        {serviceDetail.question_config && (
                                            <Container
                                                style={{
                                                    fontWeight: 500,
                                                    fontSize: '16px',
                                                }}
                                            >
                                                {
                                                    serviceDetail.question_description
                                                }
                                            </Container>
                                        )}
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            label={
                                                !serviceDetail.question_config &&
                                                'Service Detail'
                                            }
                                            value={
                                                serviceDetail.question_answer
                                            }
                                            onChange={(e) => {
                                                const tempRFPServiceDetails = selectedRFPService.service_details.map(
                                                    (innerServiceDetail) => {
                                                        if (
                                                            innerServiceDetail.id ===
                                                            serviceDetail.id
                                                        ) {
                                                            return {
                                                                ...innerServiceDetail,
                                                                question_answer:
                                                                    e.target
                                                                        .value,
                                                            }
                                                        }
                                                        return innerServiceDetail
                                                    },
                                                )
                                                const tempRFPService = selectedRFPService
                                                tempRFPService.service_details = tempRFPServiceDetails
                                                const tempRFPServiceList = RFPServices.map(
                                                    (rfpService) => {
                                                        if (
                                                            rfpService.id ===
                                                            selectedRFPService.id
                                                        ) {
                                                            return tempRFPService
                                                        }
                                                        return rfpService
                                                    },
                                                )
                                                setRFPServices(
                                                    tempRFPServiceList,
                                                )
                                            }}
                                            multiline
                                            error={
                                                doValidate &&
                                                serviceDetail.question_answer ===
                                                    ''
                                            }
                                            helperText={
                                                doValidate &&
                                                serviceDetail.question_answer ===
                                                    '' &&
                                                'Required'
                                            }
                                        />
                                    </Container>
                                    {!serviceDetail.question_config && (
                                        <Container>
                                            <IconButton
                                                onClick={() => {
                                                    const body = {
                                                        rfp_service_detail:
                                                            serviceDetail.id,
                                                    }
                                                    axiosInstance
                                                        .post(
                                                            `service-contract/rfp/delete_service_detail/`,
                                                            body,
                                                        )
                                                        .then((res) => {
                                                            const tempRFPServiceList = RFPServices.map(
                                                                (
                                                                    RFPService,
                                                                ) => {
                                                                    if (
                                                                        RFPService ===
                                                                        selectedRFPService
                                                                    ) {
                                                                        return res.data
                                                                    }
                                                                    return RFPService
                                                                },
                                                            )
                                                            setRFPServices(
                                                                tempRFPServiceList,
                                                            )
                                                        })
                                                        .catch((e) => {
                                                            toast.error(
                                                                e.response.data
                                                                    .message,
                                                            )
                                                        })
                                                }}
                                            >
                                                <DeleteForever color="secondary" />
                                            </IconButton>
                                        </Container>
                                    )}
                                </Container>
                            )
                        },
                    )}
                </Container>
            </Container>
            <Container flex={1} />
            <Container style={{ marginTop: theme.spacing(2) }}>
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: 'white',
                        color: theme.palette.primary.dark,
                        borderColor: theme.palette.primary.dark,
                        textTransform: 'none',
                        cursor: 'pointer',
                        alignSelf: 'flex-end',
                    }}
                    onClick={() => {
                        if (!viewMode) {
                            if (checkValidation()) {
                                const body = updateRFPBody()
                                updateRFP(body)
                            } else {
                                return
                            }
                        }
                        setActiveStep(GET_STARTED_STEP)
                    }}
                >
                    Previous: Getting Started
                </Button>
                <Container flex={1} />
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                        alignSelf: 'flex-end',
                    }}
                    onClick={() => {
                        if (!viewMode) {
                            if (checkValidation()) {
                                const body = updateRFPBody()
                                updateRFP(body)
                            } else {
                                return
                            }
                        }
                        setActiveStep(CREATE_QUESTIONS_STEP)
                    }}
                >
                    Next: Create Questions
                </Button>
            </Container>
        </Container>
    )
}

const checkServiceDetails = (rfpServices: RFPService[]) => {
    let allServicesHaveDetail = true
    rfpServices.forEach((rfpService) => {
        if (rfpService.service_details.length === 0) {
            allServicesHaveDetail = false
        }
    })
    return allServicesHaveDetail
}
