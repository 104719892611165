import React from 'react'
import { WorkorderResponse } from '../../../store'
import { usePopover } from '../../../hooks/usePopover'
import { Theme } from '@material-ui/core'
import { AreaType } from '../../../models'
import { Container } from '../../Container'
import { ListPopover } from '../../ListPopover/ListPopover'

interface CardProps {
    workorder: WorkorderResponse
    theme: Theme
}

export const WorkorderAreaCard = (props: CardProps) => {
    const { workorder, theme } = props

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    let bedCount = 0
    let comCount = 0
    let bathCount = 0

    workorder.service_area_list.forEach((area) => {
        if (area.area_type === AreaType.BATH) {
            bathCount = bathCount + 1
        } else if (area.area_type === AreaType.BED) {
            bedCount = bedCount + 1
        } else {
            comCount = comCount + 1
        }
    })

    return (
        <>
            <Container
                style={{
                    ...theme.typography.body2,
                    border: `1px solid ${theme.palette.grey[300]}`,
                    padding: theme.spacing(1),
                    boxSizing: 'border-box',
                    borderRadius: theme.shape.borderRadius,
                    marginTop: theme.spacing(1),
                    cursor: 'pointer',
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    handleOpen(e)
                }}
            >
                <Container
                    direction="column"
                    style={{ marginRight: theme.spacing(2) }}
                >
                    <Container>Bed:</Container>
                    <Container>Bath:</Container>
                    <Container>Common:</Container>
                </Container>
                <Container direction="column">
                    <Container>{bedCount}</Container>
                    <Container>{bathCount}</Container>
                    <Container>{comCount}</Container>
                </Container>
            </Container>
            <ListPopover
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    horizontal: -50,
                    vertical: 'bottom',
                }}
                style={{ padding: theme.spacing(1) }}
            >
                {workorder.service_area_list.map((area) => {
                    return (
                        <Container
                            style={{
                                border: `1px solid ${theme.palette.grey[300]}`,
                                padding: theme.spacing(1),
                                boxSizing: 'border-box',
                                margin: theme.spacing(1),
                            }}
                            key={`AREA_POPOVER_${workorder.id}_${area.sa_id}`}
                        >
                            {area.area_name} {area.area_label}
                        </Container>
                    )
                })}
            </ListPopover>
        </>
    )
}
