import React, { useEffect, useRef, useState } from 'react'
import { Theme, useTheme } from '@material-ui/core'
import { Container } from '../Container'
import { SideDrawer } from '../SideDrawer'
import { EditorState } from 'draft-js'
import { compositeDecorator, RichTextInput } from '../RichTextInput'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { Message } from '../Message/Message'
import { InventoryInspection, MessageDetail } from '../../models'
import { useChannel } from '../../hooks'

interface Props {
    theme: Theme
    channelId?: number
    secondaryChannelId?: number | null
    secondaryTitle?: string
    maxHeight?: string
    ignoreScrollToEnd?: boolean
    afterCreateMessage?: (message: MessageDetail) => void
}

export const MessageForm = (props: Props) => {
    const {
        theme,
        channelId,
        afterCreateMessage,
        maxHeight,
        ignoreScrollToEnd,
        secondaryChannelId,
        secondaryTitle,
    } = props
    const {
        messageList,
        createMessage,
        channelDetail,
        secondaryMessageList,
    } = useChannel(channelId, secondaryChannelId)

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(compositeDecorator),
    )

    const [fileList, setFileList] = useState<File[]>()
    const endOfMsgRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        // Scroll to end of messages
        endOfMsgRef.current && endOfMsgRef.current.scrollIntoView()
    }, [messageList])

    let messages = messageList
    if (secondaryMessageList && messages) {
        messages = messages
            .concat(secondaryMessageList)
            .sort((messageA, messageB) => {
                return (
                    new Date(messageA.created_date).getTime() -
                    new Date(messageB.created_date).getTime()
                )
            })
    }

    return (
        <Container
            style={{ flexDirection: 'column', overflow: 'hidden', flex: 1 }}
        >
            <Container
                style={{
                    padding: theme.spacing(2),
                    margin: theme.spacing(2),
                    border: `1px solid ${theme.palette.grey[400]}`,
                    borderRadius: theme.shape.borderRadius,
                    maxHeight: maxHeight,
                }}
                direction="column"
                flex={1}
                scrollY
            >
                {/* Messages here */}
                {messages?.map((msg) => (
                    <Message
                        key={`WORKORDER_MSG_${msg.id}`}
                        message={msg}
                        attachmentSize={160}
                        isSecondary={msg.channel === secondaryChannelId}
                        secondaryTitle={secondaryTitle}
                    />
                ))}
                {!ignoreScrollToEnd && <div ref={endOfMsgRef} />}
            </Container>

            <Container style={{ padding: theme.spacing(2) }}>
                <RichTextInput
                    editorState={editorState}
                    setEditorState={setEditorState}
                    currentChannel={channelDetail}
                    fileList={fileList}
                    setFileList={setFileList}
                    createMessage={createMessage}
                    onCreateMessage={afterCreateMessage}
                />
            </Container>
        </Container>
    )
}
