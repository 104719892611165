import React, { useState } from 'react'
import {
    Container,
    SideDrawer,
    SideDrawerContainer,
    WorkorderAutomationForm,
    WorkorderAutomationRow,
} from '../../components'

import {
    AreaConfig,
    CustomStatus,
    Folder,
    InventoryConfig,
    ListVendor,
    ModelMap,
    Service,
    User,
    WorkorderAutomationConfig,
    WorkorderAutomationType,
} from '../../models'

import {
    AI_LIST_HEADER_H,
    DRAWER_W,
    VH,
    TOP_TOOL_BAR_H,
    AI_LIST_FOOTER_H,
    AI_HOME_HEADER,
    JANK_SIZE,
} from './contants'
import { Button, Theme } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

import { AiDrawerId, AiFormState, closedAiFormState } from './types'
import { workorderContext } from '../../contexts'
import { usePagination } from '../../hooks'
import { toast } from 'react-toastify'

interface Props {
    theme: Theme
    triggerList: WorkorderAutomationConfig[]
    serviceList: Service[]
    userList: User[]
    vendorList: ListVendor[]
    root: Folder
    areaConfigMap: ModelMap<AreaConfig>
    inventoryConfigList?: InventoryConfig[]
    customStatusList?: CustomStatus[]
}

export const ActionItemTriggerList = (props: Props) => {
    const {
        triggerList,
        serviceList,
        userList,
        vendorList,
        root,
        theme,
        inventoryConfigList,
        customStatusList,
    } = props

    const {
        loadingState,
        updateTrigger,
        createInspectionTrigger,
    } = workorderContext()

    const { page, setPage, pageData, numPages, start, end } = usePagination(
        triggerList,
    )

    const [formState, setFormState] = useState<AiFormState>(closedAiFormState)

    const selectedTrigger = pageData[formState.detailFormOpen.index]

    const freqDetailDrawerOpen =
        selectedTrigger?.id === formState.detailFormOpen.id &&
        formState.detailFormOpen.drawerId === AiDrawerId.trigger

    const drawerContainerOpen = formState.createFormOpen || freqDetailDrawerOpen

    const headerStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.darkGreen.main,
        flex: 1,
    }

    const openCreateForm = () => {
        setFormState({ ...closedAiFormState, createFormOpen: true })
    }

    return (
        <SideDrawerContainer open={drawerContainerOpen} width={DRAWER_W}>
            <Container
                style={{
                    border: `1px solid ${theme.palette.grey[400]}`,
                    borderRadius: theme.shape.borderRadius,
                    margin: theme.spacing(1),
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                {/* Header */}
                <Container
                    style={{
                        flexDirection: 'column',
                        borderBottom: `1px solid ${theme.palette.grey[400]}`,
                        padding: theme.spacing(1),
                        minHeight: AI_LIST_HEADER_H,
                        maxHeight: AI_LIST_HEADER_H,
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    <Container
                        style={{
                            height: 60,
                            alignItems: 'center',
                        }}
                    >
                        <span>Inspection Triggers</span>
                        <div style={{ flex: 1 }} />
                        <Button
                            variant="contained"
                            style={{
                                marginLeft: theme.spacing(2),
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                textTransform: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={openCreateForm}
                        >
                            + Create Trigger
                        </Button>
                    </Container>

                    <div style={{ flex: 1 }} />
                    <Container>
                        <Container style={{ ...headerStyle }}>Title</Container>
                        <Container style={{ ...headerStyle }}>
                            Assigned To
                        </Container>
                        <Container style={{ ...headerStyle }}>
                            Service
                        </Container>
                        <Container style={{ ...headerStyle }}>
                            Priority
                        </Container>
                        <Container style={{ ...headerStyle }}>
                            Location
                        </Container>

                        <Container style={{ ...headerStyle }}>
                            Triggered Status
                        </Container>
                        <Container style={{ ...headerStyle }}>
                            Inventory
                        </Container>
                    </Container>
                </Container>

                {/* Body */}
                <Container
                    direction="column"
                    scrollY
                    flex={1}
                    style={{
                        minHeight: `calc(${VH} - ${TOP_TOOL_BAR_H} - ${AI_LIST_HEADER_H} - ${AI_LIST_FOOTER_H} - ${AI_HOME_HEADER} - ${JANK_SIZE})`,
                        maxHeight: `calc(${VH} - ${TOP_TOOL_BAR_H} - ${AI_LIST_HEADER_H} - ${AI_LIST_FOOTER_H} - ${AI_HOME_HEADER} - ${JANK_SIZE})`,
                    }}
                >
                    {pageData.map((trigger, idx) => {
                        return (
                            <WorkorderAutomationRow
                                key={`TRIGGER-${trigger.id}`}
                                theme={theme}
                                automationConfig={trigger}
                                isSelected={selectedTrigger?.id === trigger.id}
                                onClick={() =>
                                    setFormState({
                                        ...closedAiFormState,
                                        detailFormOpen: {
                                            index: idx,
                                            id: trigger.id,
                                            drawerId: AiDrawerId.trigger,
                                        },
                                    })
                                }
                            />
                        )
                    })}
                </Container>

                <Container
                    style={{
                        borderTop: `1px solid ${theme.palette.grey[400]}`,
                        padding: theme.spacing(1),
                        minHeight: AI_LIST_FOOTER_H,
                        maxHeight: AI_LIST_FOOTER_H,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Pagination
                        count={numPages}
                        page={page}
                        onChange={(_: unknown, newPage: number) => {
                            setPage(newPage)
                        }}
                    />
                    <span>
                        {start} - {end} of {triggerList.length}
                    </span>
                </Container>
            </Container>

            <SideDrawer
                open={formState.createFormOpen}
                handleClose={() => setFormState(closedAiFormState)}
                width={DRAWER_W}
            >
                <WorkorderAutomationForm
                    automationType={WorkorderAutomationType.TRIGGER}
                    theme={theme}
                    inventoryConfigList={inventoryConfigList ?? []}
                    customStatusList={customStatusList ?? []}
                    handleCancel={() => setFormState(closedAiFormState)}
                    handleSave={(request) => {
                        createInspectionTrigger(request)
                            .catch((e) => {
                                toast.error(`Problem creating frequency${e}`)
                            })
                            .finally(() => {
                                setFormState(closedAiFormState)
                            })
                    }}
                    services={serviceList}
                    vendors={vendorList}
                    users={userList}
                    root={root}
                    areaConfigMap={props.areaConfigMap}
                />
            </SideDrawer>

            <SideDrawer
                open={freqDetailDrawerOpen}
                handleClose={() => setFormState(closedAiFormState)}
                width={DRAWER_W}
            >
                <WorkorderAutomationForm
                    automationType={WorkorderAutomationType.TRIGGER}
                    theme={theme}
                    automation={selectedTrigger}
                    inventoryConfigList={inventoryConfigList ?? []}
                    customStatusList={customStatusList ?? []}
                    handleCancel={() => setFormState(closedAiFormState)}
                    handleSave={(request) => {
                        updateTrigger(request, selectedTrigger.id)
                            .catch((e) => {
                                toast.error(`Problem updating frequency${e}`)
                            })
                            .finally(() => {
                                setFormState(closedAiFormState)
                            })
                    }}
                    services={serviceList}
                    vendors={vendorList}
                    users={userList}
                    root={root}
                    areaConfigMap={props.areaConfigMap}
                />
            </SideDrawer>
        </SideDrawerContainer>
    )
}
