import { useState, useEffect, RefObject } from 'react'

export const useOnScreen = (ref: RefObject<Element>) => {
    const [isIntersecting, setIntersecting] = useState(false)

    const observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting),
    )

    useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current)
            // Remove the observer as soon as the component is unmounted
            return () => {
                observer.disconnect()
            }
        }
        return () => {}
    }, [])

    return isIntersecting
}
