import React, { useEffect, useState } from 'react'
import {
    IconButton,
    InputAdornment,
    Modal,
    Paper,
    Slide,
    Switch,
    TextField,
    Theme,
    Tooltip,
    useTheme,
} from '@material-ui/core'

import { Container, Selector } from '../../../../components'

import {
    AreaConfig,
    AreaStatusTag,
    IdBoolMap,
    IdentifiableNamedObject,
    ModelMap,
    Schedule,
    UnitMinified,
} from '../../../../models'

import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import { EditAreaStatusTagRequest } from '../../../../store'
import { ExpandLess, ExpandMore, FileCopy } from '@material-ui/icons'
import { axiosInstance } from '../../../../helpers'
import FileDownload from 'js-file-download'

type UpdateAreaStatusTagMethod = (req: EditAreaStatusTagRequest) => void

const NO_FILTER = 0
const COMPLETE = 1
const PARTIAL = 2
const INCOMPLETE = 3

interface Props {
    open: boolean
    schedule?: Schedule
    areaConfigMap: ModelMap<AreaConfig>
    areaStatusTagList?: AreaStatusTag[]
    updateAreaStatusTag: UpdateAreaStatusTagMethod
    handleClose: () => void
}

export const KeyTrackingModal = (props: Props) => {
    const { open, handleClose, areaStatusTagList, updateAreaStatusTag } = props

    const theme = useTheme()

    const [searchText, setSearchText] = useState('')
    const [unitFilter, setUnitFilter] = useState(NO_FILTER)
    const [unitList, setUnitList] = useState<UnitMinified[]>([])

    useEffect(() => {
        const unitMap: ModelMap<UnitMinified> = {}
        const filteredStatusTags = areaStatusTagList?.filter((ast) => {
            return (
                ast.area.area_config.occupiable &&
                ast.area_status_config.should_service
            )
        })
        filteredStatusTags?.forEach((areaStatusTag) => {
            if (unitMap[areaStatusTag.unit.id] === undefined) {
                unitMap[areaStatusTag.unit.id] = {
                    ...areaStatusTag.unit,
                    area_status_tags: [],
                }
            }

            unitMap[areaStatusTag.unit.id]?.area_status_tags?.push(
                areaStatusTag,
            )
        })
        const newUnitList: UnitMinified[] = []

        Object.keys(unitMap).map((key) => {
            if (key !== 'length') {
                newUnitList.push(unitMap[Number(key)] as UnitMinified)
            }
        })
        setUnitList(newUnitList)
    }, [open, areaStatusTagList])

    let keysIn = 0
    let totalCount = 0
    areaStatusTagList?.forEach((ast) => {
        if (
            ast.area.area_config.occupiable &&
            ast.area_status_config.should_service
        ) {
            if (ast.key) {
                keysIn += 1
            }
            totalCount += 1
        }
    })

    areaStatusTagList &&
        areaStatusTagList.map((areaStatusTag) => {
            if (areaStatusTag.key) {
                keysIn++
            }
        })

    const HeadCellStyle: React.CSSProperties = {
        fontWeight: 600,
        color: theme.palette.darkGreen.main,
        fontSize: '17px',
        flex: 2,
    }

    const filterUnits = (unit: UnitMinified) => {
        const unitName = unit.name.toLocaleLowerCase()
        const search = searchText.toLocaleLowerCase()

        const completeCount =
            unit.area_status_tags?.reduce((count, ast) => {
                if (ast.key) {
                    return count + 1
                }
                return count
            }, 0) ?? 0

        let completenessFilter = true

        switch (unitFilter) {
            case COMPLETE:
                completenessFilter =
                    completeCount === unit.area_status_tags?.length
                break
            case PARTIAL:
                completenessFilter =
                    completeCount > 0 &&
                    completeCount < (unit.area_status_tags?.length ?? 0)
                break
            case INCOMPLETE:
                completenessFilter = completeCount === 0
                break
        }

        return unitName.includes(search) && completenessFilter
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: '700px',
                        height: '700px',
                        maxHeight: 'calc(100vh - 100px)',
                        maxWidth: 'calc(100vh - 100px)',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <Container
                        style={{
                            padding: theme.spacing(1, 0, 4, 0),
                            flex: 1,
                        }}
                        direction="column"
                    >
                        <Container alignItems="center">
                            <Container
                                style={{
                                    fontWeight: 600,
                                    fontSize: '18px',
                                    margin: theme.spacing(1, 0, 2, 0),
                                    flex: 1,
                                }}
                            >
                                Key Tracking
                            </Container>
                            <Tooltip title="Close">
                                <IconButton
                                    onClick={() => {
                                        handleClose()
                                    }}
                                >
                                    <CloseIcon
                                        fontSize="inherit"
                                        style={{
                                            color: theme.palette.darkGreen.main,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Container>
                        <Container style={{ marginBottom: theme.spacing(2) }}>
                            <Container>
                                <TextField
                                    variant="outlined"
                                    placeholder="Search Unit"
                                    value={searchText}
                                    onChange={(e) =>
                                        setSearchText(e.target.value)
                                    }
                                    size="small"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Container>
                            <Container flex={1} />
                            <Tooltip title="Get Spreadsheet">
                                <IconButton
                                    onClick={() => {
                                        axiosInstance
                                            .get(
                                                'workorder/area-status-tag/key_xlsx/',
                                                {
                                                    responseType: 'blob',
                                                    params: {
                                                        schedule:
                                                            props.schedule?.id,
                                                    },
                                                },
                                            )
                                            .then((res) => {
                                                FileDownload(
                                                    new Blob([res.data]),
                                                    'report.xlsx',
                                                )
                                            })
                                    }}
                                >
                                    <FileCopy fontSize="large" />
                                </IconButton>
                            </Tooltip>
                            <Selector
                                label="Completeness Filter"
                                data={[
                                    { id: NO_FILTER, name: 'No Filter' },
                                    { id: COMPLETE, name: 'Complete' },
                                    { id: PARTIAL, name: 'Partial' },
                                    { id: INCOMPLETE, name: 'Incomplete' },
                                ]}
                                currentValue={unitFilter}
                                onChange={(
                                    event: React.ChangeEvent<{
                                        value: unknown
                                    }>,
                                ) => {
                                    setUnitFilter(event.target.value as number)
                                }}
                                getDisplayString={(
                                    idObject: IdentifiableNamedObject,
                                ) => idObject.name}
                                customStyle={{
                                    formControl: {
                                        width: 200,
                                    },
                                }}
                            />
                        </Container>
                        <Container>
                            <Container style={{ ...HeadCellStyle }}>
                                Location
                            </Container>
                            <Container style={{ ...HeadCellStyle }}>
                                Unit
                            </Container>
                            <Container style={{ ...HeadCellStyle }}>
                                Keys In {keysIn}/{totalCount}
                            </Container>
                            <Container style={{ ...HeadCellStyle }}></Container>
                        </Container>
                        <Container
                            direction="column"
                            style={{
                                overflowY: 'auto',
                                maxHeight: 'calc(55vh)',
                            }}
                        >
                            {unitList.filter(filterUnits).map((unit) => {
                                return (
                                    <KeyTrackingRowNew
                                        key={`KEY_TRACKING_UNIT_${unit.id}`}
                                        unit={unit}
                                        updateAreaStatusTag={
                                            updateAreaStatusTag
                                        }
                                    />
                                )
                            })}
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

interface KeyTrackingRowProps {
    unit: UnitMinified
    updateAreaStatusTag: UpdateAreaStatusTagMethod
}

const KeyTrackingRowNew = (props: KeyTrackingRowProps) => {
    const { unit, updateAreaStatusTag } = props

    const theme = useTheme()

    let unitComplete = true
    let folderString = ''
    let numberComplete = 0

    unit.area_status_tags?.forEach((areaStatusTag, idx) => {
        if (areaStatusTag.key) {
            numberComplete++
        } else {
            unitComplete = false
        }

        if (idx === 0) {
            folderString = areaStatusTag.folder.path + areaStatusTag.folder.name
        }
    })

    const [areasOpen, setAreasOpen] = useState(!unitComplete)

    const CellStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        flex: 1,
        marginLeft: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        alignSelf: 'center',
    }

    return (
        <Container
            style={{ flexDirection: 'column', marginBottom: theme.spacing(2) }}
        >
            {/* Unit Box */}
            <Container
                style={{
                    flex: 1,
                    borderRadius: theme.shape.borderRadius,
                    border: `1px solid ${theme.palette.grey[700]}`,
                    justifyContent: 'space-between',
                    backgroundColor: unitComplete
                        ? theme.palette.primary.light
                        : theme.palette.grey[300],
                }}
            >
                <span style={CellStyle}>{folderString}</span>
                <span style={CellStyle}>{unit.name}</span>

                <span style={CellStyle}>
                    {numberComplete} / {unit.area_status_tags?.length}
                </span>

                <Container style={{ ...CellStyle, justifyContent: 'flex-end' }}>
                    <IconButton
                        onClick={() => {
                            setAreasOpen(!areasOpen)
                        }}
                    >
                        {areasOpen ? (
                            <ExpandLess color="disabled" fontSize="large" />
                        ) : (
                            <ExpandMore color="disabled" fontSize="large" />
                        )}
                    </IconButton>
                </Container>
            </Container>
            {/* Area Status Tag Boxes */}
            {areasOpen &&
                unit.area_status_tags?.map((areaStatusTag) => {
                    return (
                        <Container
                            key={`AREA_STATUS_TAG_${areaStatusTag.id}_${unit.id}`}
                            style={{
                                flex: 1,
                                border: `1px solid ${theme.palette.grey[700]}`,
                                margin: theme.spacing(0, 3, 0, 3),
                                borderTop: 'none',
                            }}
                        >
                            <span
                                style={{
                                    ...CellStyle,
                                    fontWeight:
                                        theme.typography.fontWeightMedium,
                                }}
                            >
                                {areaStatusTag.area.area_config.name}{' '}
                                {areaStatusTag.area.area_label} (
                                {areaStatusTag.area_status_config.name})
                            </span>

                            <Container
                                style={{
                                    ...CellStyle,
                                    fontWeight:
                                        theme.typography.fontWeightMedium,
                                }}
                            >
                                <span>Out</span>
                                <Switch
                                    color="primary"
                                    onChange={() => {
                                        updateAreaStatusTag({
                                            id: areaStatusTag.id,
                                            body: { key: !areaStatusTag.key },
                                        })
                                    }}
                                    checked={areaStatusTag.key}
                                />
                                <span>In</span>
                            </Container>
                        </Container>
                    )
                })}
        </Container>
    )
}
