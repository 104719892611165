import React, { useEffect } from 'react'
import { Theme, useTheme } from '@material-ui/core'
import { Container } from '../..'
import { VerifiedUser } from '@material-ui/icons'

interface AttachmentProps {
    attachment: url
    theme: Theme
    size?: number
    uploaded: boolean
    autoSize?: boolean
}

export const Attachment = (props: AttachmentProps) => {
    const { size } = props

    const theme = useTheme()

    let height: number | undefined = size ? size : 200
    let width: number | undefined = size ? size : 200

    if (props.autoSize) {
        height = undefined
        width = undefined
    }

    const src = props.attachment ?? ''

    return (
        <Container style={{ position: 'relative' }}>
            {!props.uploaded && (
                <VerifiedUser
                    style={{
                        color: theme.palette.primary.main,
                        position: 'absolute',
                        margin: theme.spacing(1),
                    }}
                />
            )}
            <img
                style={{
                    height: height,
                    width: width,
                    borderRadius: '8px',
                    objectFit: 'contain',
                    backgroundColor: 'black',
                    cursor: 'pointer',
                    marginRight: props.theme.spacing(1),
                    marginBottom: props.theme.spacing(1),
                }}
                src={src}
                onClick={() => window.open(src)}
            />
        </Container>
    )
}
