import {
    Button,
    Divider,
    Modal,
    Paper,
    Popover,
    Slide,
    useTheme,
} from '@material-ui/core'
import React, { useRef, useState } from 'react'
import { useStyles } from '../../styles'
import { AvatarCircle } from '../AvatarCircle'
import { Container } from '../Container'
import clsx from 'clsx'
import { Calendly } from '../Calendly/Calendly'
import { useUser } from '../../hooks'

export const ScheduleAppointment = () => {
    const theme = useTheme()
    const classes = useStyles()

    const [popOverOpen, setPopOverOpen] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [calendlyUrl, setCalendlyUrl] = useState('')

    const { workspaceUser } = useUser()

    const anchorEl = useRef<HTMLDivElement>(null)

    const openCalendlyModal = (url: string) => {
        setPopOverOpen(false)
        setModalOpen(true)
        setCalendlyUrl(url)
    }

    const aptListStyle: React.CSSProperties = {
        height: 80,
        padding: theme.spacing(1),
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightBold,
        cursor: 'pointer',
        alignItems: 'center',
    }

    return (
        <Container style={{ alignSelf: 'flex-end' }}>
            <Container
                ref={anchorEl}
                style={{
                    position: 'absolute',
                    bottom: theme.spacing(2),
                    right: theme.spacing(2),
                    backgroundColor: theme.palette.primary.main,
                    width: 120,
                    height: 50,
                    borderRadius: 20,
                    alignSelf: 'flex-end',
                    color: theme.palette.primary.contrastText,
                    cursor: 'pointer',
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...theme.typography.body2,
                    fontWeight: theme.typography.fontWeightBold,
                    textAlign: 'center',
                }}
                onClick={() => {
                    setPopOverOpen(true)
                }}
                className={classes.hoverShadow}
            >
                Schedule an appointment
            </Container>

            <Popover
                open={popOverOpen}
                onClose={() => setPopOverOpen(false)}
                id="SCHEDULE_APT_POPOVER"
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {workspaceUser?.active_workspace.company_type ===
                    'APARTMENT' && (
                    <Container style={{ flexDirection: 'column' }}>
                        <Container
                            onClick={() =>
                                openCalendlyModal(
                                    'https://calendly.com/lincolnogata?primary_color=46b083',
                                )
                            }
                            style={aptListStyle}
                            className={clsx([
                                classes.hoverPrimary,
                                classes.fontHoverContrast,
                            ])}
                        >
                            <AvatarCircle image={'lincoln_pfp.jpg'} />
                            <span style={{ marginLeft: theme.spacing(1) }}>
                                Lincoln Ogata
                            </span>
                        </Container>

                        <Divider />

                        <Container
                            onClick={() =>
                                openCalendlyModal(
                                    'https://calendly.com/corey-che',
                                )
                            }
                            style={aptListStyle}
                            className={clsx([
                                classes.hoverPrimary,
                                classes.fontHoverContrast,
                            ])}
                        >
                            <AvatarCircle image={'corey_pfp.jpg'} />
                            <span style={{ marginLeft: theme.spacing(1) }}>
                                Corey James
                            </span>
                        </Container>

                        <Divider />

                        <Container
                            onClick={() =>
                                openCalendlyModal(
                                    'https://calendly.com/ezerica',
                                )
                            }
                            style={aptListStyle}
                            className={clsx([
                                classes.hoverPrimary,
                                classes.fontHoverContrast,
                            ])}
                        >
                            <AvatarCircle image={'erica_pfp.png'} />
                            <span style={{ marginLeft: theme.spacing(1) }}>
                                Erica Steele
                            </span>
                        </Container>

                        <Divider />

                        <Container
                            onClick={() =>
                                openCalendlyModal(
                                    'https://calendly.com/garret-ezturn',
                                )
                            }
                            style={aptListStyle}
                            className={clsx([
                                classes.hoverPrimary,
                                classes.fontHoverContrast,
                            ])}
                        >
                            <AvatarCircle image={'garret_pfp.png'} />
                            <span style={{ marginLeft: theme.spacing(1) }}>
                                Garret Buehner
                            </span>
                        </Container>

                        <Container
                            onClick={() =>
                                openCalendlyModal(
                                    'https://calendly.com/garret-ezturn',
                                )
                            }
                            style={aptListStyle}
                            className={clsx([
                                classes.hoverPrimary,
                                classes.fontHoverContrast,
                            ])}
                        >
                            <AvatarCircle image={'annie_pfp.png'} />
                            <span style={{ marginLeft: theme.spacing(1) }}>
                                Annie Sydnor
                            </span>
                        </Container>
                    </Container>
                )}
                {workspaceUser?.active_workspace.company_type === 'VENDOR' && (
                    <Container style={{ flexDirection: 'column' }}>
                        <Container
                            onClick={() =>
                                openCalendlyModal(
                                    'https://calendly.com/lincolnogata?primary_color=46b083',
                                )
                            }
                            style={aptListStyle}
                            className={clsx([
                                classes.hoverPrimary,
                                classes.fontHoverContrast,
                            ])}
                        >
                            <AvatarCircle image={'lincoln_pfp.jpg'} />
                            <span style={{ marginLeft: theme.spacing(1) }}>
                                Lincoln Ogata
                            </span>
                        </Container>
                    </Container>
                )}
            </Popover>

            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
                <Slide direction="up" in={modalOpen}>
                    <Paper
                        style={{
                            // height: '95%',
                            height: MODAL_HEIGHT,
                            width: MODAL_WIDTH,
                            display: 'flex',
                            overflow: 'hidden',
                            flexDirection: 'column',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        <div style={{ height: MODAL_HEIGHT }}>
                            <Calendly url={calendlyUrl} />
                        </div>
                    </Paper>
                </Slide>
            </Modal>
        </Container>
    )
}

const MODAL_HEIGHT = 800
const MODAL_WIDTH = 800
