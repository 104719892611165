import React from 'react'

import NumberFormat from 'react-number-format'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat | null) => void
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
    prefix?: string
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props
    return (
        <NumberFormat
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                })
            }}
            thousandSeparator
            isNumericString
            prefix="$"
            {...other}
        />
    )
}

export const NumberInput = (props: TextFieldProps) => {
    return (
        <TextField
            {...props}
            InputProps={{
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                    prefix: props.prefix,
                },
            }}
        />
    )
}
