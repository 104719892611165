import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { rfpApi, updateOrCreateBidRequest } from '../../contexts'
import { Container } from '../../components'
import { ReactComponent as BriefcaseIcon } from '../../assets/icons/briefcase.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg'
import { createRFPServiceString } from './ReviewAndSubmitForm'
import { toMMDDYYYY } from '../../helpers'
import { convertAddressToParams } from '../../models/Address/services'
import { BidStatus, Bid, RFP } from '../../models'
import { DocumentCard } from '../../components/DocumentCard/DocumentCard'
import { BidForm } from './BidForm'

interface Props {
    rfp?: RFP | null
    bid?: Bid | null
    updateBid?: (
        bid: number,
        request: updateOrCreateBidRequest,
        rfp: RFP,
    ) => void
    handleClose: () => void
    createBid?: (request: updateOrCreateBidRequest, rfp: RFP) => void
}

export const RFP_DETAIL_TAB = 0
export const BID_DETAIL_TAB = 1

export const VendorViewRFPDetail = (props: Props) => {
    const { rfp, bid, updateBid, handleClose, createBid } = props

    let selectedRFP = rfp
    if (!rfp && bid) {
        selectedRFP = bid.rfp
    }

    const [selectedRFPServiceId, setSelectedRFPServiceId] = useState(
        selectedRFP?.rfp_services[0].id ?? -1,
    )

    const [tab, setTab] = useState(RFP_DETAIL_TAB)

    const [openDialog, setOpenDialog] = useState(false)

    const theme = useTheme()

    useEffect(() => {
        setSelectedRFPServiceId(selectedRFP?.rfp_services[0].id ?? -1)
    }, [selectedRFP])

    useEffect(() => {
        setTab(RFP_DETAIL_TAB)
    }, [])

    const subHeaderStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        fontWeight: 600,
    }

    const infoStyle: React.CSSProperties = {
        fontWeight: 500,
        ...theme.typography.body1,
        marginTop: theme.spacing(1),
    }

    const subContainerStyle: React.CSSProperties = {
        paddingRight: theme.spacing(3),
    }

    const iconStyle: React.CSSProperties = {
        height: 20,
        width: 20,
        margin: theme.spacing(0.5, 1, 0, 0),
    }

    let address = ''
    if (selectedRFP?.apartment.primary_address.address1) {
        address = address + selectedRFP.apartment.primary_address.address1
    }
    if (selectedRFP?.apartment.primary_address.city) {
        address = address + `, ${selectedRFP.apartment.primary_address.city}`
    }
    if (selectedRFP?.apartment.primary_address.state) {
        address = address + `, ${selectedRFP.apartment.primary_address.state}`
    }
    if (selectedRFP?.apartment.primary_address.zip_code) {
        address = address + ` ${selectedRFP.apartment.primary_address.zip_code}`
    }

    const selectedRFPService = selectedRFP?.rfp_services.find(
        (rfpService) => rfpService.id === selectedRFPServiceId,
    )

    const showBidButton = !bid || bid.status === BidStatus.INVITATION

    return (
        <Container
            style={{
                height: 1100,
                padding: theme.spacing(2),
            }}
            flex={1}
            direction="column"
        >
            {bid && bid.status !== BidStatus.INVITATION && (
                <Container style={{ ...theme.typography.subtitle1 }}>
                    <Container
                        onClick={() => {
                            setTab(RFP_DETAIL_TAB)
                        }}
                        style={{
                            color:
                                tab === RFP_DETAIL_TAB
                                    ? theme.palette.primary.dark
                                    : theme.palette.darkGreen.main,
                            cursor: 'pointer',
                        }}
                    >
                        Job Details
                    </Container>
                    <Container
                        style={{
                            margin: theme.spacing(0, 0.5, 0, 0.5),
                        }}
                    >
                        /
                    </Container>
                    <Container
                        onClick={() => {
                            setTab(BID_DETAIL_TAB)
                        }}
                        style={{
                            color:
                                tab === BID_DETAIL_TAB
                                    ? theme.palette.primary.dark
                                    : theme.palette.darkGreen.main,
                            cursor: 'pointer',
                        }}
                    >
                        Bid Details
                    </Container>
                </Container>
            )}
            {tab === RFP_DETAIL_TAB && (
                <Container flex={1} direction="column">
                    <Container
                        direction="column"
                        style={{
                            maxHeight: 'calc(100vh - 220px)',
                            overflow: 'auto',
                            width: '100%',
                        }}
                        flex={1}
                    >
                        <Container
                            style={{
                                ...theme.typography.h5,
                                fontWeight: 600,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Property:
                        </Container>
                        <Container>
                            <Container flex={1} direction="column">
                                <Container
                                    style={{
                                        ...theme.typography.h6,
                                        fontWeight: 500,
                                    }}
                                >
                                    {selectedRFP?.apartment.name}
                                </Container>
                                <Container
                                    style={{
                                        ...theme.typography.subtitle1,
                                        fontWeight: 400,
                                        marginTop: theme.spacing(1),
                                    }}
                                >
                                    {address}
                                </Container>
                            </Container>
                            {selectedRFP && (
                                <Container flex={1}>
                                    <iframe
                                        style={{
                                            marginRight: theme.spacing(2),
                                        }}
                                        width="100%"
                                        height="150"
                                        loading="lazy"
                                        src={`https://www.google.com/maps/embed/v1/place?key=${
                                            process.env
                                                .REACT_APP_GOOGLE_MAPS_API_KEY
                                        }
                                            &q=${convertAddressToParams(
                                                selectedRFP.apartment
                                                    .primary_address,
                                            )}`}
                                    ></iframe>
                                </Container>
                            )}
                        </Container>
                        <Divider style={{ margin: theme.spacing(3, 0) }} />
                        <Container
                            style={{
                                ...theme.typography.h5,
                                fontWeight: 600,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Details:
                        </Container>
                        <Container direction="column">
                            <Container>
                                <Container flex={1}>
                                    <BriefcaseIcon style={{ ...iconStyle }} />
                                    <Container
                                        direction="column"
                                        style={{ ...subContainerStyle }}
                                    >
                                        <span style={{ ...subHeaderStyle }}>
                                            RFP Name
                                        </span>
                                        <span style={{ ...infoStyle }}>
                                            {selectedRFP?.name}
                                        </span>
                                    </Container>
                                </Container>
                                <Container flex={1}>
                                    <BriefcaseIcon style={{ ...iconStyle }} />
                                    <Container
                                        direction="column"
                                        style={{ ...subContainerStyle }}
                                    >
                                        <span style={{ ...subHeaderStyle }}>
                                            Services
                                        </span>
                                        <span style={{ ...infoStyle }}>
                                            {selectedRFP &&
                                                createRFPServiceString(
                                                    selectedRFP,
                                                )}
                                        </span>
                                    </Container>
                                </Container>
                                <Container flex={1} />
                            </Container>
                            <Container style={{ marginTop: theme.spacing(3) }}>
                                <Container flex={1}>
                                    {selectedRFP?.project_submission_deadline && (
                                        <>
                                            <CalendarIcon
                                                style={{ ...iconStyle }}
                                            />
                                            <Container
                                                direction="column"
                                                style={{ ...subContainerStyle }}
                                            >
                                                <span
                                                    style={{
                                                        ...subHeaderStyle,
                                                    }}
                                                >
                                                    Submission Deadline
                                                </span>
                                                <span style={{ ...infoStyle }}>
                                                    {toMMDDYYYY(
                                                        new Date(
                                                            selectedRFP.project_submission_deadline,
                                                        ),
                                                    )}
                                                </span>
                                            </Container>
                                        </>
                                    )}
                                </Container>
                                <Container flex={1}>
                                    {selectedRFP?.project_start_date && (
                                        <>
                                            <CalendarIcon
                                                style={{ ...iconStyle }}
                                            />
                                            <Container
                                                direction="column"
                                                style={{ ...subContainerStyle }}
                                            >
                                                <span
                                                    style={{
                                                        ...subHeaderStyle,
                                                    }}
                                                >
                                                    Start Date
                                                </span>
                                                <span style={{ ...infoStyle }}>
                                                    {toMMDDYYYY(
                                                        new Date(
                                                            selectedRFP.project_start_date,
                                                        ),
                                                    )}
                                                </span>
                                            </Container>
                                        </>
                                    )}
                                </Container>
                                <Container flex={1}>
                                    {selectedRFP?.project_end_date && (
                                        <>
                                            <CalendarIcon
                                                style={{ ...iconStyle }}
                                            />
                                            <Container
                                                direction="column"
                                                style={{ ...subContainerStyle }}
                                            >
                                                <span
                                                    style={{
                                                        ...subHeaderStyle,
                                                    }}
                                                >
                                                    End Date
                                                </span>
                                                <span style={{ ...infoStyle }}>
                                                    {toMMDDYYYY(
                                                        new Date(
                                                            selectedRFP.project_end_date,
                                                        ),
                                                    )}
                                                </span>
                                            </Container>
                                        </>
                                    )}
                                </Container>
                            </Container>
                        </Container>
                        <Divider style={{ margin: theme.spacing(3, 0) }} />
                        <Container
                            style={{
                                ...theme.typography.h5,
                                fontWeight: 600,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Scope of Work:
                        </Container>
                        <Container direction="column">
                            <Container direction="column">
                                <Container style={{ ...subHeaderStyle }}>
                                    Description of Work
                                </Container>
                                <Container
                                    style={{
                                        border: `1px solid ${theme.palette.lightGrey.light}`,
                                        borderRadius: '3px',
                                        width: '100%',
                                        minHeight: '50px',
                                        maxHeight: '100px',
                                        flexWrap: 'wrap',
                                        padding: theme.spacing(1),
                                        overflow: 'auto',
                                    }}
                                >
                                    {selectedRFP?.description}
                                </Container>
                            </Container>
                            <Container
                                direction="column"
                                style={{ marginTop: theme.spacing(1) }}
                            >
                                <Container style={{ ...subHeaderStyle }}>
                                    Documents
                                </Container>
                                <Container flexWrap="wrap">
                                    {selectedRFP?.rfp_documents
                                        .filter(
                                            (rfpDocument) =>
                                                rfpDocument.public === true,
                                        )
                                        .map((rfpDocument) => {
                                            return (
                                                <DocumentCard
                                                    key={`DOCUMENT_CARD_${selectedRFP?.id}_${rfpDocument.id}`}
                                                    document={rfpDocument}
                                                />
                                            )
                                        })}
                                    {selectedRFP?.rfp_documents.filter(
                                        (rfpDocument) =>
                                            rfpDocument.public === true,
                                    ).length === 0 && (
                                        <Container
                                            style={{
                                                color:
                                                    theme.palette.primary.dark,
                                            }}
                                        >
                                            There are no documents to view.
                                        </Container>
                                    )}
                                </Container>
                            </Container>
                            {selectedRFP?.rfp_services && (
                                <Container
                                    style={{ marginTop: theme.spacing(1) }}
                                    direction="column"
                                >
                                    <Container style={{ ...subHeaderStyle }}>
                                        Service Details
                                    </Container>
                                    <Container direction="column">
                                        <Container>
                                            {selectedRFP.rfp_services.map(
                                                (rfpService) => {
                                                    return (
                                                        <Container
                                                            key={`RFP_SERVICE_${rfpService.id}`}
                                                            alignItems="center"
                                                        >
                                                            <Container
                                                                style={{
                                                                    ...theme
                                                                        .typography
                                                                        .subtitle1,
                                                                    fontWeight: 500,
                                                                    color:
                                                                        rfpService.id ===
                                                                        selectedRFPService?.id
                                                                            ? theme
                                                                                  .palette
                                                                                  .primary
                                                                                  .dark
                                                                            : '',
                                                                    cursor:
                                                                        'pointer',
                                                                }}
                                                                onClick={() =>
                                                                    setSelectedRFPServiceId(
                                                                        rfpService.id,
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    rfpService
                                                                        .service
                                                                        .name
                                                                }
                                                            </Container>
                                                            <Container
                                                                style={{
                                                                    margin: theme.spacing(
                                                                        0,
                                                                        1,
                                                                    ),
                                                                }}
                                                            >
                                                                |
                                                            </Container>
                                                        </Container>
                                                    )
                                                },
                                            )}
                                        </Container>
                                        {selectedRFPService?.service_details.map(
                                            (serviceDetail) => {
                                                return (
                                                    <Container
                                                        key={`RFP_SERVICE_DETAIL_${serviceDetail.id}`}
                                                        direction="column"
                                                        style={{
                                                            marginTop: theme.spacing(
                                                                1,
                                                            ),
                                                        }}
                                                    >
                                                        {serviceDetail.question_config && (
                                                            <Container
                                                                style={{
                                                                    ...theme
                                                                        .typography
                                                                        .subtitle1,
                                                                    fontWeight: 500,
                                                                    marginBottom: theme.spacing(
                                                                        1,
                                                                    ),
                                                                }}
                                                            >
                                                                {
                                                                    serviceDetail.question_description
                                                                }
                                                            </Container>
                                                        )}
                                                        <Container
                                                            style={{
                                                                ...theme
                                                                    .typography
                                                                    .subtitle1,
                                                                fontWeight: 500,
                                                                padding: theme.spacing(
                                                                    1,
                                                                ),
                                                                border: `1px solid ${theme.palette.lightGrey.light}`,
                                                                borderRadius:
                                                                    '3px',
                                                            }}
                                                        >
                                                            {
                                                                serviceDetail.question_answer
                                                            }
                                                        </Container>
                                                    </Container>
                                                )
                                            },
                                        )}
                                    </Container>
                                </Container>
                            )}
                        </Container>
                    </Container>
                    <Container justifyContent="flex-end">
                        {bid?.status === BidStatus.INVITATION && updateBid && (
                            <Button
                                variant="contained"
                                style={{
                                    margin: theme.spacing(2),
                                    backgroundColor: 'red',
                                    color: 'white',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setOpenDialog(true)
                                }}
                            >
                                Decline
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            style={{
                                margin: theme.spacing(2, 0, 2, 0),
                                backgroundColor: theme.palette.primary.dark,
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => setTab(BID_DETAIL_TAB)}
                        >
                            {showBidButton ? 'Bid' : 'View Bid'}
                        </Button>
                    </Container>
                </Container>
            )}
            {tab === BID_DETAIL_TAB && selectedRFP && (
                <BidForm
                    bid={bid}
                    rfp={selectedRFP}
                    updateBid={updateBid}
                    handleClose={handleClose}
                    setTab={setTab}
                    createBid={createBid}
                />
            )}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Decline Bid Request</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to decline this bid request?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{
                            marginRight: theme.spacing(2),
                            textTransform: 'none',
                        }}
                        onClick={() => setOpenDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            if (bid && updateBid) {
                                const body: updateOrCreateBidRequest = {
                                    status: BidStatus.DECLINED,
                                }
                                updateBid(bid.id, body, bid.rfp)
                                handleClose()
                            }
                        }}
                    >
                        Decline
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
