import React, { useRef } from 'react'
import { Container } from '..'

import { useOnScreen } from '../../hooks'

interface Props {
    height?: number
    style?: React.CSSProperties
}

export const OnScreenHoc: React.FC<Props> = (props) => {
    const ref = useRef<HTMLDivElement>(null)
    const onScreen = useOnScreen(ref)

    const HEIGHT = props.height ?? 166

    const componentSize: React.CSSProperties = {
        maxHeight: HEIGHT,
        minHeight: HEIGHT,
    }

    return (
        <Container style={{ ...props.style, ...componentSize }} ref={ref}>
            {onScreen && props.children}
        </Container>
    )
}
