import { TableCell, TableRow, useTheme } from '@material-ui/core'
import React from 'react'
import {
    CellData,
    HeadCell,
    RenderRow,
    RowData,
    SimpleTable,
} from '../../components'
import { toMMDDYYYY } from '../../helpers'
import { Frequency } from '../../models'
import { useStyles } from '../../styles'
import { PERIOD_OPTIONS } from './InspectionAssignModal'

interface Props {
    frequencyList: Frequency[]
    setFrequencyModalOpenViewOnly: (frequency: Frequency) => void
}

export const InspectionFrequencyTable = (props: Props) => {
    const { setFrequencyModalOpenViewOnly } = props

    const theme = useTheme()
    const classes = useStyles()

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    interface FrequencyRowType extends RowData {
        inspectionType: CellData<string>
        period: CellData<string>
        unitCount: CellData<number>
        startPoint: CellData<string>
    }

    const headCells: HeadCell<FrequencyRowType>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'inspectionType',
            label: 'Inspection Type',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'period',
            label: 'Frequency',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'unitCount',
            label: 'Unit Count',
            style: headCellStyle,
        },

        {
            disablePadding: false,
            align: 'center',
            id: 'startPoint',
            label: 'Initial Date',
            style: headCellStyle,
        },
    ]

    const createRow = (freq: Frequency): FrequencyRowType => {
        const period =
            PERIOD_OPTIONS.find((period) => period.substring === freq.period)
                ?.name ?? ''

        // if (freq.period === 'DC') {
        //     period = freq.days + ' ' + period
        // }

        const start = new Date(freq.start_point)
        const startStr = toMMDDYYYY(start)
        return {
            inspectionType: {
                value: freq.inspection_type.name,
                sortValue: freq.inspection_type.name,
            },
            period: {
                value: period,
                sortValue: period,
            },
            unitCount: {
                value: freq.unit_ins?.length ?? 0,
                sortValue: freq.unit_ins?.length ?? 0,
            },
            startPoint: {
                value: startStr,
                sortValue: startStr,
            },
            freq: { value: freq, sortValue: 1 },
        }
    }

    interface FrequencyRowProps {
        row: FrequencyRowType
    }

    const FreqRow: RenderRow<FrequencyRowProps> = (props) => {
        const { row } = props
        const cellStyle: React.CSSProperties = {
            ...theme.typography.body2,
            fontWeight: theme.typography.fontWeightRegular,
        }

        return (
            <TableRow
                key={`FREQ_ROW_${row.freq.value.id}`}
                style={{
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setFrequencyModalOpenViewOnly(row.freq.value)
                }}
                className={classes.hoverGrey300}
            >
                <TableCell style={cellStyle}>
                    {row.inspectionType.value}
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                    {row.period.value}
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                    {row.unitCount.value}
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                    {row.startPoint.value}
                </TableCell>
            </TableRow>
        )
    }

    return (
        <SimpleTable<FrequencyRowType>
            rows={props.frequencyList?.map((freq) => createRow(freq)) ?? []}
            render={(row) => <FreqRow row={row} />}
            orderByDefault={'inspectionType'}
            headCells={headCells}
        />
    )
}
