import { Grid, IconButton, Popover, useTheme } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import React, { useState } from 'react'
import { Container } from '../../components'
import { Permissible, PermissionRole } from '../../models'
import { useStyles } from '../../styles'
import { PermissionRoleRowType } from './types'
import { Share } from '@material-ui/icons'
import { ShareModal } from './ShareModal'

interface Props {
    cellStyle: React.CSSProperties
    setEditPermissionRole: (role: PermissionRole) => void
    setOpenDrawer: (open: boolean) => void
    row: PermissionRoleRowType
    index: number
}

export const PermissionRow = (props: Props) => {
    const {
        cellStyle,
        setEditPermissionRole,
        setOpenDrawer,
        row,
        index,
    } = props

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const popOverOpen = Boolean(anchorEl)

    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closePopover = () => {
        setAnchorEl(null)
    }

    const [openShareModal, setOpenShareModal] = useState(false)
    const [body, setBody] = useState('')
    const [subject, setSubject] = useState('')

    const theme = useTheme()
    const classes = useStyles()

    return (
        <Grid
            container={true}
            key={index}
            style={{
                cursor: 'pointer',
                margin: theme.spacing(1, 0, 1, 0),
                border: '1px solid',
                borderColor: theme.palette.grey[400],
                borderRadius: '20px 20px 20px 20px',
                flexDirection: 'row',
                backgroundColor: '#E5E5E5',
            }}
            onClick={() => {
                if (!popOverOpen) {
                    setOpenDrawer(true)
                    setEditPermissionRole(row.permissionRole)
                }
            }}
            className={classes.hoverGrey300}
        >
            <Container
                style={{
                    ...cellStyle,
                    flex: 1,
                    margin: theme.spacing(2, 0, 2, 3),
                    alignItems: 'center',
                }}
            >
                {row.name}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 4,
                    margin: theme.spacing(2, 0, 2, 0),
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {row.numberOfPermissions}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 8,
                    margin: theme.spacing(2, 2, 2, 0),
                    justifyContent: 'right',
                    alignItems: 'right',
                }}
            >
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation()
                        openPopover(e)
                    }}
                    disableRipple={true}
                    size="small"
                >
                    <MoreVert />
                </IconButton>
                <Popover
                    open={popOverOpen}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                    }}
                >
                    <Container>
                        <IconButton
                            onClick={() => {
                                const tempSubject = `Here is the information about the ${row.name} Permission Role`
                                let tempBody = ''

                                Object.keys(row.permissionRole).forEach(
                                    (key) => {
                                        if (
                                            key !== 'id' &&
                                            key !== 'name' &&
                                            key !== 'organization'
                                        ) {
                                            const words = key.split('_')
                                            let name = ''
                                            words.forEach((word) => {
                                                name +=
                                                    word[0].toUpperCase() +
                                                    word.substring(1) +
                                                    ' '
                                            })
                                            const permissibleKey = key as keyof Permissible
                                            const canDo =
                                                row.permissionRole[
                                                    permissibleKey
                                                ] === 1
                                                    ? 'True'
                                                    : 'False'
                                            tempBody += `${name}: ${canDo}%0D`
                                        }
                                    },
                                )
                                setBody(tempBody)
                                setSubject(tempSubject)
                                setOpenShareModal(true)
                            }}
                        >
                            <Share style={{ fontSize: '17px' }} />
                            <Container
                                style={{
                                    margin: theme.spacing(0, 3, 0, 1),
                                    fontSize: '14px',
                                }}
                            >
                                Share
                            </Container>
                        </IconButton>
                    </Container>
                    <ShareModal
                        open={openShareModal}
                        handleClose={() => setOpenShareModal(false)}
                        body={body}
                        subject={subject}
                    />
                </Popover>
            </Container>
        </Grid>
    )
}
