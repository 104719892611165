import React from 'react'
import { ToggleButtonGroup } from '@material-ui/lab'
import { Button, IconButton, Tooltip, useTheme } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Check } from '@material-ui/icons'
import { Range } from 'react-date-range'
import { Container } from '../../../../../components'

export type InputFilter = typeof ALL | typeof RENT_ROLL | typeof LEASE_ADDENDUMS

interface Props {
    inputFilter: InputFilter
    setInputFilter: (dateFilter: InputFilter) => void
}

export const TableFilterButtons = (props: Props) => {
    const { inputFilter, setInputFilter } = props

    const theme = useTheme()

    const ButtonGroupStyle: React.CSSProperties = {
        textTransform: 'none',
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightBold,
    }

    return (
        <Container>
            <ToggleButtonGroup
                value={inputFilter}
                aria-label="Input Filter"
                exclusive
                style={{
                    border: `1px solid ${theme.palette.primary.dark}`,
                    borderRadius: '5px',
                    marginTop: theme.spacing(1),
                }}
            >
                <Button
                    value={ALL}
                    aria-label="All"
                    style={{
                        ...ButtonGroupStyle,
                        borderRight: `1px solid ${theme.palette.primary.dark}`,
                        borderLeft: `1px solid ${theme.palette.primary.dark}`,
                        backgroundColor:
                            inputFilter === ALL
                                ? theme.palette.primary.dark
                                : '',
                        color: inputFilter === ALL ? 'white' : '',
                    }}
                    onClick={() => {
                        setInputFilter(ALL)
                    }}
                >
                    All
                </Button>
                <Button
                    value={RENT_ROLL}
                    aria-label="Rent Roll"
                    style={{
                        ...ButtonGroupStyle,
                        backgroundColor:
                            inputFilter === RENT_ROLL
                                ? theme.palette.primary.dark
                                : '',
                        color: inputFilter === RENT_ROLL ? 'white' : '',
                    }}
                    onClick={() => {
                        setInputFilter(RENT_ROLL)
                    }}
                >
                    Rent Roll
                </Button>
                <Button
                    value={LEASE_ADDENDUMS}
                    aria-label="Lease Addendums"
                    style={{
                        ...ButtonGroupStyle,
                        borderRight: `1px solid ${theme.palette.primary.dark}`,
                        borderLeft: `1px solid ${theme.palette.primary.dark}`,
                        backgroundColor:
                            inputFilter === LEASE_ADDENDUMS
                                ? theme.palette.primary.dark
                                : '',
                        color: inputFilter === LEASE_ADDENDUMS ? 'white' : '',
                    }}
                    onClick={() => {
                        setInputFilter(LEASE_ADDENDUMS)
                    }}
                >
                    Lease Addendums
                </Button>
            </ToggleButtonGroup>
        </Container>
    )
}

export const ALL = 0
export const RENT_ROLL = 1
export const LEASE_ADDENDUMS = 2
