import React from 'react'
import { AuditPromptConfig, DDLease, LeaseAudit } from '../../../../../models'
import {
    Theme,
    Paper,
    Modal,
    Slide,
    CircularProgress,
    useTheme,
} from '@material-ui/core'
import Grid from '@mui/material/Grid'
import { CellStyle } from '../Modals/TableModal/CellStyle'
import { styled } from '@mui/material/styles'
import { LeaseAuditLoadingState } from '../../../Hooks'
import { Container } from '../../../../../components'
import { PromptSpinner } from './PromptSpinner'

interface Props {
    openPromptModal: boolean
    setOpenPromptResultsModal: (openPromptModal: false) => void
    loadingState: LeaseAuditLoadingState
    leaseAudit: LeaseAudit
    lease: DDLease
    promptConfigs: AuditPromptConfig[]
    selectedConfigs: number[]
}

export const BaseLineResults = (props: Props) => {
    const {
        openPromptModal,
        setOpenPromptResultsModal,
        loadingState,
        leaseAudit,
        lease,
        promptConfigs,
        selectedConfigs,
    } = props

    const theme = useTheme()
    const promptAnswers = promptConfigs.filter((config) =>
        selectedConfigs.includes(config.dd_lease_col_id),
    )

    return (
        <Modal
            open={openPromptModal}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            onClose={() => {
                setOpenPromptResultsModal(false)
            }}
        >
            <Slide direction="up" in={openPromptModal}>
                <Paper
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '90%',
                        width: '90%',
                    }}
                >
                    <Container
                        style={{
                            ...theme.typography.h5,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.grey[800],
                            margin: theme.spacing(1),
                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        Baseline Audit
                    </Container>
                    {loadingState.baseLineLoading ? (
                        <Container
                            style={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1,
                            }}
                        >
                            <CircularProgress size={75} />
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight:
                                        theme.typography.fontWeightMedium,
                                    color: theme.palette.grey[600],
                                    marginTop: theme.spacing(2),
                                }}
                            >
                                Allow up to 60 seconds for your results to
                                process
                            </span>
                        </Container>
                    ) : (
                        <Grid
                            container
                            style={{
                                flex: 1,
                                padding: theme.spacing(1),
                            }}
                        >
                            {/* lease audit */}
                            <Grid
                                container
                                item
                                xs={5}
                                style={{
                                    flex: 1,
                                }}
                            >
                                <PromptSpinner
                                    promptAnswers={promptAnswers}
                                    lease={lease}
                                />
                            </Grid>
                            {/* lease */}
                            <Grid
                                container
                                item
                                xs={7}
                                style={{
                                    flex: 1,
                                }}
                            >
                                <iframe
                                    width="100%"
                                    height="95%"
                                    loading="lazy"
                                    src={`${lease.lease}`}
                                ></iframe>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </Slide>
        </Modal>
    )
}
