import React, { useEffect } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import {
    Card,
    CardHeader,
    CardContent,
    IconButton,
    Typography,
} from '@material-ui/core'

import { ReactComponent as PlusCircleIcon } from '../../../../assets/icons/plus-circle.svg'
import MessageList from './MessageList'
import { useChannelList } from '../../../../hooks'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            borderRadius: '15px',
            border: '1px solid #C2C2C2',
            boxShadow: '0px 6px 15px rgb(138 138 138 / 25%)',
            paddingBottom: '30px', //To be Removed Later
        },
        headerTitle: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '23px',
            display: 'block',
            color: '#008C85',
        },
        headerSubTitle: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '11px',
            lineHeight: '13px',
            color: '#8A8A8A',
        },
    }),
)

const Messages = (props: any) => {
    const classes = useStyles()

    const { channelList, getChannelList } = useChannelList()

    useEffect(() => {
        getChannelList({ params: { in_ezchat: true } })
    }, [])

    console.log('Channel List is....', channelList)

    return (
        <Card className={`${classes.root}`}>
            <CardHeader
                // action={
                //     <IconButton aria-label="settings">
                //         <PlusCircleIcon />
                //     </IconButton>
                // }
                title={
                    <Typography className={classes.headerTitle}>
                        Messages
                    </Typography>
                }
                // subheader={
                //     <Typography className={classes.headerSubTitle}>
                //         Channel: <b>#UniversityProperty</b>
                //     </Typography>
                // }
            />
            <CardContent>
                <MessageList
                    channelList={channelList}
                    openMsgDrawer={props.openMsgDrawer}
                />
            </CardContent>
        </Card>
    )
}

export default Messages
