import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    ListItem,
    Popover,
    useTheme,
} from '@material-ui/core'
import { BarChart, MoreVert } from '@material-ui/icons'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import React, { useState } from 'react'
import { AvatarCircle, Container } from '../../components'
import { ListVendor } from '../../models'
import { useStyles } from '../../styles'
import { Share } from '@material-ui/icons'
import { VendorTableRow } from './WorkforceVendorTable'
import { ListPopover } from '../../components/ListPopover/ListPopover'
import { ShareModal } from './ShareModal'
import { axiosInstance, isAccountManager } from '../../helpers'
import { toast } from 'react-toastify'
import { useCompany, useUser } from '../../hooks'
import { ReactComponent as Logo } from '../../assets/EZ_Circle_Logo_Icon.svg'

interface Props {
    cellStyle: React.CSSProperties
    setEditVendor: (ven: ListVendor) => void
    setOpenDrawer: (open: boolean) => void
    row: VendorTableRow
    onClick: () => void
    setOpenAnalytics: (open: boolean) => void
    resendWelcomeEmail: (vendor: ListVendor) => void
}

export const VendorRow = (props: Props) => {
    const {
        cellStyle,
        setEditVendor,
        setOpenDrawer,
        row,
        onClick,
        setOpenAnalytics,
        resendWelcomeEmail,
    } = props

    const [openDialog, setOpenDialog] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const popOverOpen = Boolean(anchorEl)

    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closePopover = () => {
        setAnchorEl(null)
    }

    const [openShareModal, setOpenShareModal] = useState(false)
    const [body, setBody] = useState('')
    const [subject, setSubject] = useState('')

    const { workspaceUser } = useUser()

    const [
        anchorElServices,
        setAnchorElServices,
    ] = React.useState<HTMLElement | null>(null)

    const popOverOpenServices = Boolean(anchorElServices)

    const openPopoverServices = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElServices(event.currentTarget)
    }

    const closePopoverServices = () => {
        setAnchorElServices(null)
    }

    const theme = useTheme()
    const classes = useStyles()

    const { removeVendor } = useCompany()

    return (
        <Grid
            container
            key={row.vendor.id}
            style={{
                cursor: 'pointer',
                margin: theme.spacing(1, 0, 1, 0),
                border: '1px solid',
                borderColor: theme.palette.grey[400],
                borderRadius: '20px 20px 20px 20px',
                flexDirection: 'row',
                backgroundColor:
                    row.services.length !== 0
                        ? '#E5E5E5'
                        : theme.palette.grey[400],
            }}
            onClick={() => {
                if (!popOverOpen && !popOverOpenServices) {
                    onClick()
                    setOpenDrawer(true)
                    setEditVendor(row.vendor)
                }
            }}
            className={classes.hoverGrey300}
        >
            <Container
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {row.vendor.profile_picture ? (
                    <AvatarCircle
                        styles={{ container: { margin: theme.spacing(1) } }}
                        image={row.vendor.profile_picture}
                    />
                ) : (
                    <AvatarCircle
                        text={row.name}
                        initials={true}
                        styles={{ container: { margin: theme.spacing(1) } }}
                    />
                )}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                    alignItems: 'center',
                    marginLeft: theme.spacing(2),
                }}
            >
                {row.name}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                    justifyContent: 'center',
                }}
                direction="column"
            >
                <span>{row.address}</span>

                <span>
                    {`${row.vendor.city} ${row.vendor.state}, ${row.vendor.zip_code}`}
                </span>
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                    alignItems: 'center',
                }}
            >
                {row.type}
                {row.vendor.imported ? (
                    <Container
                        style={{
                            backgroundColor: 'gold',
                            borderRadius: '5px',
                            padding: theme.spacing(0.5),
                            marginLeft: theme.spacing(1),
                            fontSize: '10px',
                        }}
                    >
                        Imported
                    </Container>
                ) : (
                    <Container
                        style={{
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        <Logo
                            fill={theme.palette.primary.main}
                            style={{
                                height: 25,
                                width: 25,
                            }}
                        />
                    </Container>
                )}
                {row.vendor.entrata_sync_enabled && (
                    <Container
                        style={{
                            marginLeft: theme.spacing(1),
                            fontWeight: 400,
                            fontSize: 13,
                            color: theme.palette.darkGreen.main,
                        }}
                    >
                        (Sync Ready)
                    </Container>
                )}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                    alignItems: 'center',
                }}
            >
                <span style={{ flex: 1 }}>
                    {createServicesString(row.vendor)}
                </span>

                {row.vendor.services.length > 3 ? (
                    <>
                        <Container>
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    openPopoverServices(e)
                                }}
                                style={{ marginRight: theme.spacing(2) }}
                            >
                                +{row.vendor.services.length - 3}
                            </Button>
                        </Container>
                        <ListPopover
                            open={popOverOpenServices}
                            anchorEl={anchorElServices}
                            onClose={closePopoverServices}
                            anchorOrigin={{
                                horizontal: -50,
                                vertical: 'bottom',
                            }}
                        >
                            {row.services.slice(3).map((service) => {
                                return (
                                    <ListItem key={`SERVICE_ID_${service.id}`}>
                                        {service.name}
                                    </ListItem>
                                )
                            })}
                        </ListPopover>
                    </>
                ) : (
                    <div />
                )}
            </Container>
            <Container
                direction="column"
                alignItems="flex-start"
                style={{
                    ...cellStyle,
                    flex: 2,
                    justifyContent: 'center',
                    marginLeft: theme.spacing(3),
                }}
            >
                <span
                    style={{
                        fontWeight: theme.typography.fontWeightMedium,
                    }}
                >
                    {row.vendor.email}
                </span>
                <span
                    style={{
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    {row.vendor.phone}
                </span>
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <BarChart
                    fontSize="large"
                    color="action"
                    className={classes.highlightCircleOnHover}
                    onClick={(e) => {
                        e.stopPropagation()
                        setOpenAnalytics(true)
                    }}
                />
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <span style={{ flex: 'flex-shrink' }}>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            openPopover(e)
                        }}
                        disableRipple={true}
                        size="small"
                    >
                        <MoreVert />
                    </IconButton>
                </span>
                <Popover
                    open={popOverOpen}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                    }}
                >
                    <Container direction="column">
                        <IconButton
                            onClick={() => {
                                let tempBody = `${row.name}%0D${row.type}%0D${
                                    row.vendor.phone ?? ''
                                }%0D`
                                tempBody += `${row.vendor.email}%0D${
                                    row.address ?? ''
                                } ${row.vendor.city ?? ''} ${
                                    row.vendor.state ?? ''
                                }, ${row.vendor.zip_code ?? ''}`
                                setSubject(
                                    `Here is the information about ${row.name}`,
                                )
                                setBody(tempBody)
                                setOpenShareModal(true)
                            }}
                        >
                            <Share style={{ fontSize: '17px' }} />
                            <Container
                                style={{
                                    margin: theme.spacing(0, 3, 0, 1),
                                    fontSize: '14px',
                                }}
                            >
                                Share
                            </Container>
                        </IconButton>

                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                setOpenDialog(true)
                            }}
                        >
                            <DeleteForeverIcon style={{ fontSize: '17px' }} />
                            <Container
                                style={{
                                    margin: theme.spacing(0, 3, 0, 1),
                                    fontSize: '14px',
                                }}
                            >
                                Remove
                            </Container>
                        </IconButton>

                        {isAccountManager(workspaceUser) && (
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    resendWelcomeEmail(row.vendor)
                                }}
                                disabled={!isAccountManager(workspaceUser)}
                            >
                                <Container
                                    style={{
                                        margin: theme.spacing(0, 3, 0, 1),
                                        fontSize: '14px',
                                    }}
                                >
                                    Resend Welcome Email
                                </Container>
                            </IconButton>
                        )}
                    </Container>
                    <ShareModal
                        open={openShareModal}
                        handleClose={() => setOpenShareModal(false)}
                        body={body}
                        subject={subject}
                    />
                </Popover>

                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                    <DialogTitle>Remove {row.vendor.name}?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to remove {row.vendor.name}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            variant="outlined"
                            style={{
                                marginRight: theme.spacing(2),
                                textTransform: 'none',
                            }}
                            onClick={(e) => {
                                e.stopPropagation()
                                setOpenDialog(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            style={{
                                marginRight: theme.spacing(2),
                                textTransform: 'none',
                            }}
                            onClick={(e) => {
                                e.stopPropagation()
                                setOpenDialog(false)
                                removeVendor({
                                    body: { vendor: row.vendor.id },
                                })
                                    .then(() => {
                                        e.stopPropagation()
                                        toast.success(
                                            `${row.vendor.name} Removed`,
                                        )
                                    })
                                    .catch(() => {
                                        toast.error(
                                            `There was a problem removing ${row.vendor.name}`,
                                        )
                                    })
                            }}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Grid>
    )
}

export const createServicesString = (vendor: ListVendor) => {
    if (vendor.services.length >= 3) {
        return `${vendor.services[0].name}, ${vendor.services[1].name}, ${vendor.services[2].name}`
    } else if (vendor.services.length === 2) {
        return `${vendor.services[0].name}, ${vendor.services[1].name}`
    } else if (vendor.services.length === 1) {
        return `${vendor.services[0].name}`
    }
    return ''
}
