import React, { useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import {
    Avatar,
    Typography,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Tooltip,
} from '@material-ui/core'

import {
    Image as ImageIcon,
    Work as WorkIcon,
    BeachAccess as BeachAccessIcon,
} from '@material-ui/icons'
import { axiosInstance } from '../../../../../helpers'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        headerTitle: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '23px',
            display: 'block',
            color: '#008C85',
            margin: theme.spacing(4, 0, 2),
            paddingLeft: '16px',
        },
        vendorFallingBehind: {
            maxHeight: '300px',
            overflowY: 'scroll',
            '& .MuiListItemText-primary': {
                fontSize: '0.813rem',
                color: '#000',
                fontWeight: 500,
                // textOverflow: 'ellipsis',
                // whiteSpace: 'nowrap',
                // overflow: 'hidden',
                // width: '100%',
            },
            '& .MuiListItemText-secondary': {
                fontSize: '0.875rem',
                color: '#000',
                fontWeight: 400,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '100%',
            },
            '& .MuiListItem-root': {
                wordWrap: 'break-word',
            },
        },
        behind: {
            width: '100%',
            display: 'block',
        },
        name: {
            width: '100%',
            display: 'block',
        },
        state: {
            fontSize: '0.625rem',
            color: '#8A8A8A',
            fontWeight: 400,
        },
    }),
)

const VendorsFallingBehind = () => {
    const classes = useStyles()
    const [vendorFallingBehind, setVendorFallingBehind] = useState([])

    useEffect(() => {
        getVendorFallingBehind()
    }, [])

    const getVendorFallingBehind = () => {
        axiosInstance
            .get('company/vedor_falling_behind', {
                // params: {
                //     occupiable: true,
                //     vacant_start: new Date().toISOString(),
                //     vacant_end: new Date().toISOString(),
                // },
            })
            .then((res: any) => {
                if (res && res.data) {
                    setVendorFallingBehind(res.data)
                }
                console.log('response is....', res)
            })
    }
    return (
        <>
            <Typography variant="h6" className={classes.headerTitle}>
                Vendor falling behind
            </Typography>
            {/* <List dense>
                <ListItem>
                    <ListItemAvatar>
                        <span
                            style={{
                                fontWeight: 700,
                                fontSize: '13px',
                                lineHeight: '14px',
                                color: '#000000',
                            }}
                        >
                            Today
                        </span>
                    </ListItemAvatar>
                    <ListItemText
                        style={{
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '14px',
                            color: '#8A8A8A',
                        }}
                        primary="August 26th, 2022"
                    />
                </ListItem>
            </List> */}
            <List
                dense
                className={`ez-custom-scroll ${classes.vendorFallingBehind}`}
            >
                {vendorFallingBehind && vendorFallingBehind.length > 0 ? (
                    vendorFallingBehind.map((vendor: any, index: number) => (
                        <>
                            <ListItem key={`fallingBehind-${index}`}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <ImageIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <>
                                            <span className={classes.name}>
                                                {vendor.name}
                                            </span>
                                            <span className={classes.state}>
                                                {vendor.city}{' '}
                                                {vendor.state
                                                    ? `, ${vendor.state}`
                                                    : ''}
                                            </span>
                                        </>
                                    }
                                    secondary={
                                        <>
                                            <Tooltip title={vendor.phone}>
                                                <span
                                                    className={classes.behind}
                                                >
                                                    Behind {vendor.behind_count}
                                                </span>
                                            </Tooltip>
                                        </>
                                    }
                                />
                            </ListItem>
                        </>
                    ))
                ) : (
                    <></>
                )}
            </List>
            {/* <List dense>
                <ListItem>
                    <ListItemAvatar>
                        <span
                            style={{
                                fontWeight: 700,
                                fontSize: '13px',
                                lineHeight: '14px',
                                color: '#000000',
                            }}
                        >
                            Yesterday
                        </span>
                    </ListItemAvatar>
                    <ListItemText
                        style={{
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '14px',
                            color: '#8A8A8A',
                            paddingLeft: '8px',
                        }}
                        primary="August 25th, 2022"
                    />
                </ListItem>
            </List>
            <List dense className={`ez-custom-scroll ${classes.todaysAct}`}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <ImageIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Jimmy Mann"
                        secondary={
                            <Tooltip title="Updated xyz on xyz page...">
                                <span>Updated xyz on xyz page...</span>
                            </Tooltip>
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <WorkIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Work"
                        secondary={
                            <Tooltip title="Jan 7, 2014">
                                <span>Jan 7, 2014</span>
                            </Tooltip>
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <BeachAccessIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Tommy"
                        secondary={
                            <Tooltip title="Updated abc on abc page twice...">
                                <span>Updated abc on abc page twice...</span>
                            </Tooltip>
                        }
                    />
                </ListItem>
            </List> */}
        </>
    )
}

export default VendorsFallingBehind
