import React from 'react'

import { useTheme } from '@material-ui/core'

import {
    BaseWorkorder,
    Folder,
    isWorkorderApproved,
    isWorkorderComplete,
    ModelListMap,
    Unit,
} from '../../../models'
import { Container } from '../../../components'
import {
    APPROVED_ANALYTIC,
    ASSIGNED_ANALYTIC,
    COMPLETE_ANALYTIC,
    StatusSelection,
} from './AnalyticsBlanket'

interface Props {
    infrastructure: Folder
    unitWorkorderMap: ModelListMap<BaseWorkorder>
    statusSelection: StatusSelection
    selectedServiceId: number
    isWorkorderInFilter: (workorder: BaseWorkorder, unit: Unit) => boolean
}

export const UnitAnalytics = (props: Props) => {
    const {
        infrastructure,
        unitWorkorderMap,
        isWorkorderInFilter,
        selectedServiceId,
        statusSelection,
    } = props

    const theme = useTheme()

    const content: JSX.Element[] = []

    const buildUnitAnalytics = (folder: Folder) => {
        const folderUnits: JSX.Element[] = []

        folder.units.forEach((unit) => {
            let shouldUnitRender = false

            if (unit.id in unitWorkorderMap) {
                unitWorkorderMap[unit.id]?.forEach((wo) => {
                    if (
                        isWorkorderInFilter(wo, unit) &&
                        wo.service_id === selectedServiceId
                    ) {
                        if (
                            statusSelection === COMPLETE_ANALYTIC &&
                            isWorkorderComplete(wo)
                        ) {
                            shouldUnitRender = true
                        }
                        if (
                            statusSelection === APPROVED_ANALYTIC &&
                            isWorkorderApproved(wo)
                        ) {
                            shouldUnitRender = true
                        }
                        if (statusSelection === ASSIGNED_ANALYTIC) {
                            shouldUnitRender = true
                        }
                    }
                })
            }

            if (shouldUnitRender) {
                folderUnits.push(
                    <span
                        style={{
                            ...theme.typography.body2,
                            fontWeight: theme.typography.fontWeightLight,
                            margin: theme.spacing(0, 1, 1, 0),
                        }}
                    >
                        {unit.name}
                    </span>,
                )
            }
        })

        if (folderUnits.length > 0) {
            content.push(
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {folder.path}
                    {folder.name}
                </span>,
            )
            content.push(
                <Container
                    style={{ flexWrap: 'wrap', marginBottom: theme.spacing(2) }}
                >
                    {folderUnits}
                </Container>,
            )
        }

        folder.children.forEach((child) => {
            buildUnitAnalytics(child)
        })
    }

    buildUnitAnalytics(infrastructure)

    return (
        <Container
            style={{
                flex: 1,
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.grey[200],
                margin: theme.spacing(1),
                padding: theme.spacing(1),
                flexDirection: 'column',
            }}
            scrollY
        >
            {content}
            {content.length === 0 && (
                <Container
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        There are no unit analytics for your selection
                    </span>
                </Container>
            )}
        </Container>
    )
}
