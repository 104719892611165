import React from 'react'
import { useTheme } from '@material-ui/core'
import { Container } from '../../../components'

interface Props {
    name: string
    style?: React.CSSProperties
}

export const FolderRow: React.FC<Props> = (props: Props) => {
    const { name } = props

    const theme = useTheme()

    return (
        <Container
            style={{
                alignItems: 'center',
                paddingLeft: theme.spacing(1),
                ...props.style,
            }}
        >
            <span
                style={{
                    ...theme.typography.h5,
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >
                {name}
            </span>
        </Container>
    )
}
