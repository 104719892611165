import React, { useMemo } from 'react'

import { Button, IconButton, Theme, Tooltip } from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import { Container, Selector } from '../../../../components'
import { ListVendor, Service, getServiceImageDetails } from '../../../../models'
import { VENDOR_MODAL_H, VENDOR_MODAL_W } from './contants'

interface Props {
    serviceList: Service[]
    vendor: ListVendor
    theme: Theme
    onAddService: (vendor: ListVendor, service: Service) => void
    onRemoveService: (vendor: ListVendor, service: Service) => void
    onPressNext: () => void
}

export const StepSelectServices = (props: Props) => {
    const { theme, vendor, serviceList, onPressNext } = props

    const filteredServiceList = useMemo(() => {
        return serviceList.filter((service) => {
            return (
                vendor.services.find((serviceOffering) => {
                    return service.id === serviceOffering.id
                }) === undefined
            )
        })
    }, [vendor, serviceList])

    return (
        <Container
            style={{
                flexDirection: 'column',
                overflow: 'hidden',
                maxHeight: VENDOR_MODAL_H,
                minHeight: VENDOR_MODAL_H,
                minWidth: VENDOR_MODAL_W,
                maxWidth: VENDOR_MODAL_W,
            }}
        >
            {/* Header */}
            <Container
                style={{
                    flexDirection: 'column',
                    padding: theme.spacing(2),
                    borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    maxHeight: 130,
                }}
            >
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.grey[700],
                        marginBottom: theme.spacing(1),
                    }}
                >
                    Select Services for {vendor.name}!
                </span>
                <Selector
                    data={filteredServiceList}
                    label="Service"
                    searchable
                    maxItems={10}
                    getDisplayString={(srv) => srv.name}
                    currentValue={-1}
                    onChange={(e) => {
                        const service = serviceList.find(
                            (srv) => srv.id === e.target.value,
                        )
                        if (service) {
                            props.onAddService(vendor, service)
                        }
                    }}
                />
            </Container>
            {/* Body */}
            <Container
                style={{
                    padding: theme.spacing(2),
                    flexWrap: 'wrap',
                    overflowY: 'scroll',
                    maxHeight: VENDOR_MODAL_H - 130 - 60,
                }}
            >
                {vendor.services.map((s) => {
                    return (
                        <ServiceCard
                            key={`SERVICE-${s.id}`}
                            service={s}
                            theme={theme}
                            onClickDelete={(service) =>
                                props.onRemoveService(vendor, service)
                            }
                        />
                    )
                })}
            </Container>

            {/* Footer */}
            <div style={{ flex: 1 }} />
            <Container
                style={{
                    minHeight: 60,
                    maxHeight: 60,
                    flex: 1,
                    justifyContent: 'flex-end',
                    padding: theme.spacing(1),
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    disabled={vendor.services.length === 0}
                    style={{ height: 40, textTransform: 'none' }}
                    onClick={onPressNext}
                >
                    Next
                </Button>
            </Container>
        </Container>
    )
}

interface ServiceCardProps {
    service: Service
    theme: Theme
    onClickDelete: (service: Service) => void
}

const ServiceCard = (props: ServiceCardProps) => {
    const { service, theme, onClickDelete } = props

    return (
        <Container
            style={{
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(1),
                marginBottom: theme.spacing(1),
                marginRight: theme.spacing(1),
                alignItems: 'center',
                minWidth: CARD_W,
                maxWidth: CARD_W,
                minHeight: CARD_H,
                maxHeight: CARD_H,
            }}
        >
            <img
                src={getServiceImageDetails(service.id).icon}
                style={{
                    maxHeight: 30,
                    maxWidth: 32,
                    marginRight: theme.spacing(1),
                }}
            />

            <span
                style={{
                    ...theme.typography.body1,
                    fontWeight: theme.typography.fontWeightMedium,
                    color: theme.palette.grey[800],
                }}
            >
                {service.name}
            </span>

            <div style={{ flex: 1 }} />

            <Tooltip title="Remove">
                <IconButton onClick={() => onClickDelete(service)}>
                    <DeleteForeverIcon />
                </IconButton>
            </Tooltip>
        </Container>
    )
}

const CARD_W = 200
const CARD_H = 70

const CARDS_PER_ROW = 4
