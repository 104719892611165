import React from 'react'

import DataTable from 'react-data-table-component'

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionItem: {
            '& .rdt_TableBody': {
                maxHeight: '250px',
                overflowY: 'scroll',
            },
            // [theme.breakpoints.up('sm')]: {
            '& .rdt_TableCell': {
                paddingLeft: 0,
            },
            '& .rdt_TableRow': {
                "& [data-column-id='1']": {
                    maxWidth: '165px',
                    minWidth: '30px',
                },
                "& [data-column-id='2']": {
                    maxWidth: '70px',
                    minWidth: '20px',
                },
            },
            '& .rdt_TableCol': {
                // maxWidth: '100px',
                // minWidth: '30px',
                fontSize: '0.75rem',
                fontWeight: 700,
                color: '#000',
                paddingLeft: '5px',
            },
            '& .rdt_TableHeadRow': {
                width: '100%',
                backgroundColor: '#F4F4F4',
                marginBottom: '20px',
                borderRadius: '2px',
                minHeight: '30px',
                paddingLeft: 0,
                "& [data-column-id='1']": {
                    maxWidth: '165px',
                    minWidth: '30px',
                },
                "& [data-column-id='2']": {
                    maxWidth: '70px',
                    minWidth: '20px',
                },
            },
            // },
        },
        todaysDate: {
            '& .rdt_TableBody': {
                maxHeight: '250px',
                overflowY: 'scroll',
            },
            // [theme.breakpoints.up('sm')]: {
            '& .rdt_TableCell': {
                // maxWidth: '100px',
                minWidth: '80px',
            },
            '& .rdt_TableCol': {
                // maxWidth: '100px',
                minWidth: '80px',
                fontSize: '0.75rem',
                fontWeight: 700,
                color: '#000',
            },
            '& .rdt_TableHeadRow': {
                // backgroundColor: '#F4F4F4',
                // marginBottom: '20px',
                // borderRadius: '2px',
                minHeight: '30px',
            },
            // },
        },
        vendorListTable: {
            '& .rdt_Table': {
                background: 'transparent',
            },
            '& .rdt_TableHeadRow': {
                background: 'transparent',
                border: 'none !important',
            },
            '& .rdt_TableRow': {
                padding: '15px',
                background: '#F4F4F4',
                border: '1px solid #C4C4C4 !important',
                borderRadius: '15px',
                marginBottom: '10px',
                fontSize: '0.813rem',
                color: '#000',
                cursor: 'pointer',
            },
            '& .rdt_TableRow:hover': {
                background: '#E4E3E3',
                border: '1px solid #C4C4C4',
            },
            '& .glyQNg': {
                paddingRight: 0,
            },
            '& .rdt_TableCell': {
                // paddingLeft: 0,
                "&[data-column-id='2']": {
                    paddingLeft: 0,
                    paddingRight: '32px',
                },
                "&[data-column-id='4']": {
                    justifyContent: 'center',
                },
                "&[data-column-id='5']": {
                    justifyContent: 'center',
                },
                "&[data-column-id='6']": {
                    justifyContent: 'center',
                },
            },
            '& .rdt_TableCol': {
                "&[data-column-id='4']": {
                    justifyContent: 'center',
                },
                "&[data-column-id='5']": {
                    justifyContent: 'center',
                },
                "&[data-column-id='6']": {
                    justifyContent: 'center',
                },
            },
        },
    }),
)

interface Props {
    columns: Array<any>
    data: Array<any>
    pagination: boolean
    rowClickable: boolean | undefined
    rowClicked: any
    class: string | undefined
}
export const BasicDataTable = (props: Props) => {
    const classes: any = useStyles()

    return (
        <DataTable
            columns={props.columns}
            data={props.data}
            className={props.class ? classes[props.class] : ''}
            pagination={props.pagination ?? false}
            onRowClicked={(row, event) => {
                console.log('event is....', row, event)
                if (props.rowClickable) {
                    props.rowClicked(row, event)
                }
            }}
        />
    )
}
