import React from 'react'
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    useTheme,
} from '@material-ui/core'

import { useEffect, useState } from 'react'
import { Container, SideDrawer } from '../../components'
import { useAppDispatch, useAptConfig } from '../../hooks'
import { IdBoolMap, InspectionType } from '../../models'
import { toast } from 'react-toastify'
import { editInspectionTypeReq } from '../../store'
import { useHistory } from 'react-router-dom'
import { Routes, resolveRoute } from '../../helpers'

interface DrawerProps {
    open: boolean
    handleClose: () => void
    inspectionType: InspectionType
}

export const InspectionTypeInvConfigDrawer = (props: DrawerProps) => {
    const { open, handleClose, inspectionType } = props

    const history = useHistory()

    const dispatch = useAppDispatch()
    const theme = useTheme()

    const { inventoryConfigList } = useAptConfig({})

    const [checkedMap, setCheckedMap] = useState<IdBoolMap>({})

    useEffect(() => {
        const newCheckedMap: IdBoolMap =
            inventoryConfigList?.reduce<IdBoolMap>((prev, iC, idx) => {
                return { ...prev, [iC.id]: false }
            }, {}) ?? {}

        inspectionType.inventory_configs.forEach((iC) => {
            newCheckedMap[iC.id] = true
        })

        setCheckedMap(newCheckedMap)
    }, [inspectionType, inventoryConfigList])

    let atLeastOneChecked = false
    let allChecked = true

    Object.keys(checkedMap).forEach((keyStr) => {
        const key = Number(keyStr)
        const valueChecked = checkedMap[key]
        atLeastOneChecked = atLeastOneChecked || valueChecked
        allChecked = allChecked && valueChecked
    })

    const invConfigCheckBoxes =
        inventoryConfigList?.map((invC) => {
            return (
                <FormControlLabel
                    key={`INV_CONF_${invC.id}_CHECK_INS_${inspectionType.id}`}
                    control={
                        <Checkbox
                            checked={checkedMap[invC.id] ?? false}
                            style={{ color: theme.palette.primary.main }}
                            onClick={() => {
                                const newCheckedMap: IdBoolMap = {
                                    ...checkedMap,
                                }
                                newCheckedMap[invC.id] = !checkedMap[invC.id]
                                setCheckedMap(newCheckedMap)
                            }}
                        />
                    }
                    label={
                        <Container>
                            {' '}
                            {invC.name} ({invC.area_config_count})
                        </Container>
                    }
                    labelPlacement="end"
                    style={{
                        fontWeight: 'bold',
                        marginBottom: theme.spacing(2),
                    }}
                />
            )
        }) ?? []

    return (
        <SideDrawer
            open={open}
            handleClose={handleClose}
            title={`${inspectionType.name} Inventory`}
        >
            <FormControlLabel
                style={{ marginLeft: theme.spacing(0.5) }}
                control={
                    <Checkbox
                        checked={allChecked}
                        // XOR
                        indeterminate={
                            (allChecked && !atLeastOneChecked) ||
                            (!allChecked && atLeastOneChecked)
                        }
                        onChange={() => {
                            const newMap: IdBoolMap = {}
                            Object.keys(checkedMap).forEach((keyStr) => {
                                const key = Number(keyStr)
                                newMap[key] = !atLeastOneChecked
                            })
                            setCheckedMap(newMap)
                        }}
                    />
                }
                label={atLeastOneChecked ? 'Remove All' : 'Select All'}
            />
            <Container
                flex={1}
                flexWrap="wrap"
                style={{
                    marginLeft: theme.spacing(2),
                    marginTop: theme.spacing(2),
                }}
                scrollY
            >
                <FormGroup>{invConfigCheckBoxes}</FormGroup>
            </Container>
            <Divider />
            <Container
                style={{
                    padding: theme.spacing(2),
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    variant="contained"
                    style={{
                        marginRight: theme.spacing(2),
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        history.push(
                            resolveRoute(Routes.infrastructureConfig, '', ''),
                        )
                    }}
                >
                    + Add New Item
                </Button>
                <Button
                    color="secondary"
                    style={{ marginRight: theme.spacing(2) }}
                    variant="outlined"
                    onClick={handleClose}
                >
                    Close
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        const invConfigs = inventoryConfigList?.reduce<
                            number[]
                        >((prev, iC, index) => {
                            if (checkedMap[iC.id]) {
                                return prev.concat(iC.id)
                            }
                            return prev
                        }, [])

                        dispatch(
                            editInspectionTypeReq({
                                id: inspectionType.id,
                                body: { inv_configs: invConfigs },
                            }),
                        ).then(() => {
                            toast.success(
                                `${inspectionType.name} Inventory updated.`,
                            )
                        })
                    }}
                >
                    Save
                </Button>
            </Container>
        </SideDrawer>
    )
}
