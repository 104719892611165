import React, { useEffect, useMemo, useState } from 'react'

import {
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    IconButton,
    Modal,
    Paper,
    Slide,
    TableSortLabel,
    useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import {
    AvatarCircle,
    Container,
    EllipsisSpan,
    NumberInput,
} from '../../../../components'
import {
    AreaConfig,
    IdBoolMap,
    InspectionStatus,
    InspectionStatusType,
    Lease,
    ModelMap,
    Unit,
    getLeaseStatusString,
} from '../../../../models'
import { axiosInstance, toMMDDYYYY } from '../../../../helpers'
import { Pagination } from '@material-ui/lab'
import { toast } from 'react-toastify'
import { SearchField } from '../../../../components/SearchField'

interface Props {
    open: boolean
    onClose: () => void
    leaseList: Lease[]
    unitMap: ModelMap<Unit>
    areaConfigMap: ModelMap<AreaConfig>
    setLeaseList: (leaseList: Lease[]) => void
}

const tableHeaderStyle: React.CSSProperties = {
    flex: 1,
    color: '#8A8A8A',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '18px',
    marginBottom: '16px',
    marginTop: '16px',
}

const emailASC = 'emailASC'
const emailDESC = 'emailDESC'
const locationASC = 'locationASC'
const locationDESC = 'locationDESC'
const unitASC = 'unitASC'
const unitDESC = 'unitDESC'
const startASC = 'startASC'
const startDESC = 'startDESC'
const endASC = 'endASC'
const endDESC = 'endDESC'
const statusASC = 'statusASC'
const statusDESC = 'statusDESC'

export const TenantInspectionModal = (props: Props) => {
    const {
        open,
        onClose,
        leaseList,
        unitMap,
        areaConfigMap,
        setLeaseList,
    } = props

    const pageSize = 10
    const isLoading = false

    const [page, setPage] = useState(0)
    const [days, setDays] = useState<Number | null>(null)

    const [searchValue, setSearchValue] = useState('')

    const [orderBy, setOrderBy] = useState('')

    const getInitialSelectedLeases = () => {
        const initSelectedLeases: IdBoolMap = {}
        leaseList.forEach((lease) => {
            initSelectedLeases[lease.id] = false
        })
        return initSelectedLeases
    }

    const [selectedLeases, setSelectedLeases] = useState<IdBoolMap>(
        getInitialSelectedLeases(),
    )
    const [sortedLeaseList, setSortedLeaseList] = useState(leaseList)

    useEffect(() => {
        setSortedLeaseList(leaseList)
        setOrderBy('')
        setSelectedLeases(getInitialSelectedLeases())
    }, [leaseList])

    const theme = useTheme()

    const numPages = Math.ceil((leaseList?.length ?? 0) / pageSize)

    const paginatedList = useMemo(() => {
        const start = page * pageSize
        const end = start + pageSize

        return sortedLeaseList
            ?.filter((l) => {
                const caseNormalizedSearchValue = searchValue.toLocaleLowerCase()
                const emailValid = l.user.email
                    .toLocaleLowerCase()
                    .includes(caseNormalizedSearchValue)
                const nameValid = l.user.name
                    .toLocaleLowerCase()
                    .includes(caseNormalizedSearchValue)
                const searchValid = emailValid || nameValid

                return searchValid
            })
            .slice(start, end)
    }, [page, sortedLeaseList, searchValue])

    const selectedLeaseCount = useMemo(() => {
        if (selectedLeases === null || leaseList === undefined) {
            return 0
        }

        let selectedCount = 0
        Object.keys(selectedLeases).forEach((key) => {
            if (selectedLeases[(key as unknown) as number]) {
                selectedCount++
            }
        })

        return selectedCount
    }, [selectedLeases, leaseList])

    const selectAllChecked = selectedLeaseCount === leaseList?.length
    const selectAllIntermediate =
        selectedLeaseCount > 0 && selectedLeaseCount < (leaseList?.length ?? 0)

    const bulkTransitionSelected = (
        status: InspectionStatusType,
        skip_email?: boolean,
    ) => {
        const areaInspectionList: number[] = []
        sortedLeaseList.forEach((lease, idx) => {
            if (selectedLeases && selectedLeases[lease.id]) {
                areaInspectionList.push(
                    lease.tenant_move_in_inspection?.id ?? -1,
                )
            }
        })
        axiosInstance
            .post('inspection/area-inspection/bulk_transition/', {
                area_inspections: areaInspectionList,
                to_status: status,
                days_until_due: Number(days),
                children_area_inspections: true,
                skip_email: skip_email,
            })
            .then(() => {
                const newLeaseList = leaseList.reduce<Lease[]>(
                    (prev, lease) => {
                        if (selectedLeases && selectedLeases[lease.id]) {
                            return prev
                        } else {
                            return prev.concat(lease)
                        }
                    },
                    [],
                )

                setLeaseList(newLeaseList)
                setSelectedLeases(getInitialSelectedLeases())
            })
            .catch(() => {
                toast.error('An error occured.')
            })
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            keepMounted
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        height: '95%',
                        width: '85%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Container
                        style={{
                            padding: theme.spacing(2),
                            flex: 1,
                            flexDirection: 'column',
                            overflowY: 'scroll',
                        }}
                    >
                        {/* Header */}
                        <Container
                            direction="row"
                            style={{ alignItems: 'center' }}
                        >
                            <Container
                                style={{
                                    flexDirection: 'column',
                                    marginBottom: theme.spacing(1),
                                }}
                            >
                                <span
                                    style={{
                                        ...theme.typography.h4,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        margin: theme.spacing(2),
                                    }}
                                >
                                    Staged Tenant Inspections
                                </span>
                                <SearchField
                                    placeholder="Tenant Search"
                                    value={searchValue}
                                    onChange={(e) =>
                                        setSearchValue(e.target.value)
                                    }
                                />
                            </Container>

                            <div style={{ flex: 1 }} />

                            <NumberInput
                                value={days}
                                style={{ margin: theme.spacing(2) }}
                                onChange={(event) => {
                                    setDays(
                                        event.target.value === ''
                                            ? null
                                            : Number(event.target.value),
                                    )
                                }}
                                label="Days to Complete"
                                required={true}
                            />

                            <div>
                                <IconButton onClick={props.onClose}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            </div>
                        </Container>

                        <Divider />

                        {/* Main Content */}
                        <Container
                            style={{
                                flexDirection: 'column',
                                overflowY: 'scroll',
                            }}
                        >
                            {leaseList.length === 0 ? (
                                <>
                                    <span style={theme.typography.h4}>
                                        No Staged Tenant Inspections
                                    </span>
                                    <span style={theme.typography.body1}>
                                        {`Inspections will get staged automatically when you perform a move in or sync "Current" leases from entrata.`}
                                    </span>
                                </>
                            ) : (
                                <Container direction="column">
                                    <Container
                                        style={{
                                            textAlign: 'center',
                                            paddingLeft: '41px',
                                            paddingRight: '41px',
                                            alignItems: 'center',
                                            height: '50px',
                                        }}
                                    >
                                        <TableSortLabel
                                            style={tableHeaderStyle}
                                            active={
                                                orderBy === emailASC ||
                                                orderBy === emailDESC
                                            }
                                            direction={
                                                orderBy === emailASC
                                                    ? 'asc'
                                                    : 'desc'
                                            }
                                            onClick={() => {
                                                const newSorted = [
                                                    ...sortedLeaseList,
                                                ]
                                                if (orderBy !== emailASC) {
                                                    newSorted.sort(
                                                        (leaseA, leaseB) =>
                                                            leaseA.user.email.localeCompare(
                                                                leaseB.user
                                                                    .email,
                                                            ),
                                                    )
                                                    setSortedLeaseList(
                                                        newSorted,
                                                    )
                                                    setOrderBy(emailASC)
                                                } else {
                                                    setSortedLeaseList(
                                                        newSorted.reverse(),
                                                    )
                                                    setOrderBy(emailDESC)
                                                }
                                            }}
                                        >
                                            Tenant Info
                                        </TableSortLabel>
                                        <TableSortLabel
                                            style={tableHeaderStyle}
                                            active={
                                                orderBy === locationASC ||
                                                orderBy === locationDESC
                                            }
                                            direction={
                                                orderBy === locationASC
                                                    ? 'asc'
                                                    : 'desc'
                                            }
                                            onClick={() => {
                                                const newSorted = [
                                                    ...sortedLeaseList,
                                                ]
                                                if (orderBy !== locationASC) {
                                                    newSorted.sort(
                                                        (leaseA, leaseB) => {
                                                            const unitA =
                                                                unitMap[
                                                                    leaseA.area
                                                                        .unit
                                                                ]
                                                            const locationStringA = `${unitA?.folder.path} ${unitA?.folder.name}`
                                                            const unitB =
                                                                unitMap[
                                                                    leaseB.area
                                                                        .unit
                                                                ]
                                                            const locationStringB = `${unitB?.folder.path} ${unitB?.folder.name}`

                                                            return locationStringA.localeCompare(
                                                                locationStringB,
                                                            )
                                                        },
                                                    )
                                                    setSortedLeaseList(
                                                        newSorted,
                                                    )
                                                    setOrderBy(locationASC)
                                                } else {
                                                    setSortedLeaseList(
                                                        newSorted.reverse(),
                                                    )
                                                    setOrderBy(locationDESC)
                                                }
                                            }}
                                        >
                                            Location
                                        </TableSortLabel>
                                        <TableSortLabel
                                            style={tableHeaderStyle}
                                            active={
                                                orderBy === unitASC ||
                                                orderBy === unitDESC
                                            }
                                            direction={
                                                orderBy === unitASC
                                                    ? 'asc'
                                                    : 'desc'
                                            }
                                            onClick={() => {
                                                const newSorted = [
                                                    ...sortedLeaseList,
                                                ]
                                                if (orderBy !== unitASC) {
                                                    newSorted.sort(
                                                        (leaseA, leaseB) => {
                                                            const unitA =
                                                                unitMap[
                                                                    leaseA.area
                                                                        .unit
                                                                ]
                                                            const areaConfigA =
                                                                areaConfigMap[
                                                                    leaseA.area
                                                                        .area_config
                                                                ]
                                                            const unitStringA = `${unitA?.name} ${leaseA.area.area_label} ${areaConfigA?.name}`
                                                            const unitB =
                                                                unitMap[
                                                                    leaseB.area
                                                                        .unit
                                                                ]
                                                            const areaConfigB =
                                                                areaConfigMap[
                                                                    leaseB.area
                                                                        .area_config
                                                                ]
                                                            const unitStringB = `${unitB?.name} ${leaseB.area.area_label} ${areaConfigB?.name}`
                                                            return unitStringA.localeCompare(
                                                                unitStringB,
                                                            )
                                                        },
                                                    )
                                                    setSortedLeaseList(
                                                        newSorted,
                                                    )
                                                    setOrderBy(unitASC)
                                                } else {
                                                    setSortedLeaseList(
                                                        newSorted.reverse(),
                                                    )
                                                    setOrderBy(unitDESC)
                                                }
                                            }}
                                        >
                                            Unit/Area
                                        </TableSortLabel>
                                        <TableSortLabel
                                            style={tableHeaderStyle}
                                            active={
                                                orderBy === startASC ||
                                                orderBy === startDESC
                                            }
                                            direction={
                                                orderBy === startASC
                                                    ? 'asc'
                                                    : 'desc'
                                            }
                                            onClick={() => {
                                                const newSorted = [
                                                    ...sortedLeaseList,
                                                ]
                                                if (orderBy !== startASC) {
                                                    newSorted.sort(
                                                        (leaseA, leaseB) => {
                                                            const startA = new Date(
                                                                leaseA.start_date,
                                                            )
                                                            const startB = new Date(
                                                                leaseB.start_date,
                                                            )
                                                            return (
                                                                startA.valueOf() -
                                                                startB.valueOf()
                                                            )
                                                        },
                                                    )
                                                    setSortedLeaseList(
                                                        newSorted,
                                                    )
                                                    setOrderBy(startASC)
                                                } else {
                                                    setSortedLeaseList(
                                                        newSorted.reverse(),
                                                    )
                                                    setOrderBy(startDESC)
                                                }
                                            }}
                                        >
                                            Start
                                        </TableSortLabel>
                                        <TableSortLabel
                                            style={tableHeaderStyle}
                                            active={
                                                orderBy === endASC ||
                                                orderBy === endDESC
                                            }
                                            direction={
                                                orderBy === endASC
                                                    ? 'asc'
                                                    : 'desc'
                                            }
                                            onClick={() => {
                                                const newSorted = [
                                                    ...sortedLeaseList,
                                                ]
                                                if (orderBy !== endASC) {
                                                    newSorted.sort(
                                                        (leaseA, leaseB) => {
                                                            const endA = new Date(
                                                                leaseA.end_date,
                                                            )
                                                            const endB = new Date(
                                                                leaseB.end_date,
                                                            )
                                                            return (
                                                                endA.valueOf() -
                                                                endB.valueOf()
                                                            )
                                                        },
                                                    )
                                                    setSortedLeaseList(
                                                        newSorted,
                                                    )
                                                    setOrderBy(endASC)
                                                } else {
                                                    setSortedLeaseList(
                                                        newSorted.reverse(),
                                                    )
                                                    setOrderBy(endDESC)
                                                }
                                            }}
                                        >
                                            End
                                        </TableSortLabel>
                                        <TableSortLabel
                                            style={tableHeaderStyle}
                                            active={
                                                orderBy === statusASC ||
                                                orderBy === statusDESC
                                            }
                                            direction={
                                                orderBy === statusASC
                                                    ? 'asc'
                                                    : 'desc'
                                            }
                                            onClick={() => {
                                                const newSorted = [
                                                    ...sortedLeaseList,
                                                ]
                                                if (orderBy !== statusASC) {
                                                    newSorted.sort(
                                                        (leaseA, leaseB) =>
                                                            leaseA.status -
                                                            leaseB.status,
                                                    )
                                                    setSortedLeaseList(
                                                        newSorted,
                                                    )
                                                    setOrderBy(statusASC)
                                                } else {
                                                    setSortedLeaseList(
                                                        newSorted.reverse(),
                                                    )
                                                    setOrderBy(statusDESC)
                                                }
                                            }}
                                        >
                                            Lease Status
                                        </TableSortLabel>
                                        <Container
                                            style={{
                                                ...tableHeaderStyle,

                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginRight: theme.spacing(1),
                                            }}
                                        >
                                            <div style={{ flex: 1 }} />
                                            <FormControlLabel
                                                label={`${selectedLeaseCount} Selected`}
                                                labelPlacement="start"
                                                control={
                                                    <Checkbox
                                                        onChange={() => {
                                                            if (
                                                                selectAllChecked
                                                            ) {
                                                                setSelectedLeases(
                                                                    getInitialSelectedLeases(),
                                                                )
                                                            } else {
                                                                const allSelectedLeases: IdBoolMap = {}
                                                                leaseList.forEach(
                                                                    (lease) => {
                                                                        allSelectedLeases[
                                                                            lease.id
                                                                        ] = true
                                                                    },
                                                                )
                                                                setSelectedLeases(
                                                                    allSelectedLeases,
                                                                )
                                                            }
                                                        }}
                                                        checked={
                                                            selectAllChecked
                                                        }
                                                        indeterminate={
                                                            selectAllIntermediate
                                                        }
                                                    />
                                                }
                                            />
                                        </Container>
                                    </Container>
                                    {isLoading ? (
                                        <Container
                                            style={{
                                                flex: 1,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <CircularProgress size={200} />
                                        </Container>
                                    ) : (
                                        <Container
                                            style={{
                                                flexDirection: 'column',
                                                textAlign: 'center',
                                                overflowY: 'scroll',
                                            }}
                                            className="scroll-bar"
                                        >
                                            {paginatedList?.map(
                                                (lease, index) => {
                                                    return (
                                                        <LeaseRow
                                                            key={`LEASE_ROW_${lease.id}`}
                                                            lease={lease}
                                                            unitMap={unitMap}
                                                            areaConfigMap={
                                                                areaConfigMap
                                                            }
                                                            // the comparison to true is neccesarry to cause a rerender
                                                            isChecked={
                                                                selectedLeases[
                                                                    lease.id
                                                                ] === true
                                                            }
                                                            onCheck={() => {
                                                                setSelectedLeases(
                                                                    (
                                                                        oldSelectedLeases,
                                                                    ) => {
                                                                        const newSelectedLeases = {
                                                                            ...oldSelectedLeases,
                                                                        }
                                                                        newSelectedLeases[
                                                                            lease.id
                                                                        ] = !newSelectedLeases[
                                                                            lease
                                                                                .id
                                                                        ]
                                                                        return newSelectedLeases
                                                                    },
                                                                )
                                                            }}
                                                        />
                                                    )
                                                },
                                            )}
                                        </Container>
                                    )}
                                </Container>
                            )}
                        </Container>

                        <div style={{ flex: 1 }} />
                        <Divider />

                        {/* Footer */}
                        <Container
                            style={{
                                alignItems: 'center',
                                paddingTop: theme.spacing(1),
                                paddingBottom: theme.spacing(1),
                                justifyContent: 'flex-end',
                                flex: 1,
                                minHeight: FOOTER_HEIGHT,
                                maxHeight: FOOTER_HEIGHT,
                            }}
                        >
                            <Container
                                style={{ flexDirection: 'column', flex: 1 }}
                            >
                                <Container
                                    style={{
                                        margin: 8,
                                    }}
                                >
                                    <div style={{ flex: 1 }} />
                                    <Pagination
                                        page={page + 1}
                                        onChange={(_, newPage) => {
                                            setPage(newPage - 1)
                                        }}
                                        count={numPages}
                                    />

                                    <span
                                        style={{
                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: '#C4C4C4',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            fontSize: '13px',
                                            lineHeight: '15px',
                                        }}
                                    >
                                        {leaseList?.length ?? 0} Rows
                                    </span>
                                </Container>
                                <Container style={{ flex: 1 }}>
                                    <Button
                                        style={{
                                            color: '#fff',
                                            backgroundColor: '#008C85',
                                            height: '50px',
                                            borderRadius: '5px',
                                            alignSelf: 'center',
                                            fontSize: '14px',
                                            lineHeight: '17px',
                                            fontWeight: 500,
                                            cursor: 'pointer',
                                            textTransform: 'none',
                                        }}
                                        onClick={() => {
                                            if (selectedLeaseCount) {
                                                if (
                                                    confirm(
                                                        `This will make ${selectedLeaseCount} tenant move in inspections available WITHOUT emailing them.  You will need to inform the tenants on your own if you continue.`,
                                                    )
                                                ) {
                                                    bulkTransitionSelected(
                                                        InspectionStatus.READY,
                                                        true,
                                                    )
                                                }
                                            } else {
                                                alert(
                                                    'Please select one or more leases for tenants to inspect.',
                                                )
                                            }
                                        }}
                                    >
                                        Make available without email
                                    </Button>

                                    <div style={{ flex: 1 }} />

                                    <Button
                                        style={{
                                            color: '#fff',
                                            backgroundColor:
                                                theme.palette.secondary.main,
                                            height: '50px',
                                            // width: '140px',
                                            borderRadius: '5px',
                                            alignSelf: 'center',
                                            fontSize: '14px',
                                            lineHeight: '17px',
                                            fontWeight: 500,
                                            cursor: 'pointer',
                                            textTransform: 'none',
                                            marginRight: theme.spacing(4),
                                        }}
                                        onClick={() => {
                                            if (selectedLeaseCount) {
                                                if (
                                                    confirm(
                                                        `This will mark the move in inspection as Non Applicable for the selected leases.  Are you sure you want to do this?`,
                                                    )
                                                ) {
                                                    bulkTransitionSelected(
                                                        InspectionStatus.NA,
                                                    )
                                                }
                                            } else {
                                                alert(
                                                    'Please select one or more leases for tenants to inspect.',
                                                )
                                            }
                                        }}
                                    >
                                        Set N/A
                                    </Button>

                                    <Button
                                        style={{
                                            color: '#fff',
                                            backgroundColor:
                                                days === null || 0
                                                    ? 'gray'
                                                    : '#008C85',
                                            height: '50px',
                                            borderRadius: '5px',
                                            alignSelf: 'center',
                                            fontSize: '14px',
                                            lineHeight: '17px',
                                            fontWeight: 500,
                                            cursor: 'pointer',
                                            textTransform: 'none',
                                        }}
                                        disabled={days === null}
                                        onClick={() => {
                                            if (selectedLeaseCount) {
                                                if (days === null) {
                                                    alert(
                                                        'Please enter the number of days to complete this inspection.',
                                                    )
                                                } else {
                                                    if (
                                                        confirm(
                                                            `This will email ${selectedLeaseCount} tenants and allow them to preform their move-in inspections. Are you sure you want to do this?`,
                                                        )
                                                    ) {
                                                        bulkTransitionSelected(
                                                            InspectionStatus.READY,
                                                        )
                                                    }
                                                }
                                            } else {
                                                alert(
                                                    'Please select one or more leases for tenants to inspect.',
                                                )
                                            }
                                        }}
                                    >
                                        Send Move In Inspection Email
                                    </Button>
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

const LeaseRow = (props: {
    lease: Lease
    unitMap: ModelMap<Unit>
    areaConfigMap: ModelMap<AreaConfig>
    isChecked: boolean
    onCheck: () => void
}) => {
    const { lease, unitMap, areaConfigMap } = props
    const unit: Unit | undefined = unitMap[lease.area.unit]

    const areaConfig: AreaConfig | undefined =
        areaConfigMap[lease.area.area_config]

    return (
        <Container
            key={lease.id}
            style={{
                height: '80px',
                backgroundColor: '#F4F4F4',
                border: '1px solid #C4C4C4',
                borderRadius: '15px',
                marginBottom: '15px',
                padding: '41px',
                paddingTop: '11px',
                paddingBottom: '11px',
                alignItems: 'center',
                textAlign: 'center',
            }}
        >
            <Container flex={1}>
                <AvatarCircle text={lease.user.name} initials />
                <Container
                    style={{
                        flexDirection: 'column',
                        marginLeft: '13px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                    }}
                >
                    <EllipsisSpan
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: 180,
                        }}
                    >
                        {lease.user.name}
                    </EllipsisSpan>
                    <EllipsisSpan
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: 180,
                        }}
                    >
                        {lease.user.email}
                    </EllipsisSpan>
                </Container>
            </Container>

            <Container flex={1}>
                <span style={{ width: '100%' }}>
                    {unit?.folder.path}
                    {unit?.folder.name}
                </span>
            </Container>

            <Container
                flex={1}
                style={{
                    flexDirection: 'column',
                }}
            >
                <span>{unit?.name}</span>
                <span>
                    {areaConfig?.name ?? '?'} {lease.area.area_label}
                </span>
            </Container>

            <Container flex={1}>
                <span style={{ width: '100%' }}>
                    {toMMDDYYYY(new Date(lease.start_date))}
                </span>
            </Container>

            <Container flex={1}>
                <span style={{ width: '100%' }}>
                    {toMMDDYYYY(new Date(lease.end_date))}
                </span>
            </Container>

            <Container flex={1}>
                <span style={{ width: '100%' }}>
                    {getLeaseStatusString(lease.status)}
                </span>
            </Container>
            <Container flex={1} style={{ justifyContent: 'flex-end' }}>
                <Checkbox checked={props.isChecked} onChange={props.onCheck} />
            </Container>
        </Container>
    )
}

const FOOTER_HEIGHT = 120
