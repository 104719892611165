import React, { useEffect, useState } from 'react'
import { Container } from '../../../../components'
import { Divider, Step, StepButton, Stepper, useTheme } from '@material-ui/core'
import { WorkSpaceUser } from '../../../../models'
import { TermsOfServiceStep } from './TermsOfServiceStep'
import { AccountVerificationStep } from '../AccountVerificationStep'
import { CompanyVerificationStep } from './CompanyVerificationStep'
import { useUser } from '../../../../hooks'

interface Props {
    user: WorkSpaceUser
}

export const ApartmentRegisterForm = (props: Props) => {
    const theme = useTheme()

    const { rootUser } = useUser()

    const [activeStep, setActiveStep] = useState(STEP_1)

    const [stepCompletion, setStepCompletion] = useState([false, false, false])

    useEffect(() => {
        if (
            rootUser &&
            rootUser.email_verified &&
            rootUser.has_secure_password
        ) {
            completeStep(STEP_1)
            // check for step 2 completion
        }
    }, [rootUser?.email_verified, rootUser?.has_secure_password])

    const completeStep = (step: number) => {
        setStepCompletion((c) => {
            const newCompletionList = [...c]
            newCompletionList[step] = true
            return newCompletionList
        })
        setActiveStep(step + 1)
    }

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            {/* Header */}
            <Container
                style={{
                    height: 80,
                    padding: theme.spacing(2),
                    alignItems: 'center',
                }}
            >
                <span style={{ ...theme.typography.h5 }}>Register</span>
            </Container>
            <Divider />
            {/* Body */}

            <Container
                style={{
                    flexDirection: 'column',
                    overflowY: 'scroll',
                    flex: 1,
                }}
            >
                {activeStep === STEP_1 && (
                    <AccountVerificationStep
                        onSubmit={() => {
                            completeStep(STEP_1)
                        }}
                    />
                )}

                {activeStep === STEP_2 && (
                    <CompanyVerificationStep
                        user={props.user}
                        onSubmit={() => completeStep(STEP_2)}
                    />
                )}
            </Container>

            {/* Footer */}
            <Divider />
            <Container
                style={{
                    padding: theme.spacing(2),
                    justifyContent: 'center',
                }}
            >
                <Stepper nonLinear activeStep={activeStep}>
                    <Step completed={stepCompletion[STEP_1]}>
                        <StepButton
                            color="inherit"
                            onClick={() => {
                                setActiveStep(STEP_1)
                            }}
                        >
                            Account verification
                        </StepButton>
                    </Step>

                    <Step
                        completed={stepCompletion[STEP_2]}
                        disabled={!stepCompletion[STEP_1]}
                    >
                        <StepButton
                            color="inherit"
                            onClick={() => {
                                setActiveStep(STEP_2)
                            }}
                        >
                            Company Verification
                        </StepButton>
                    </Step>
                </Stepper>
            </Container>
        </Container>
    )
}

const STEP_1 = 0
const STEP_2 = 1
