import React, { useState } from 'react'
import styles from './SectionPanel.module.css'
import { IdentifiableObject } from '../../models'

interface CustomStyle {
    container?: React.CSSProperties
    itemStyle?: React.CSSProperties
    selectedItemStyle?: React.CSSProperties
}

interface ItemProps<T> {
    item: T
    key: string
    style: React.CSSProperties
    onClick: () => void
    className: string
}

interface Props<T> {
    data: T[]
    itemHeight: number
    itemMargin?: number
    maxItems?: number
    customStyle?: CustomStyle
    renderItem: React.FC<ItemProps<T>>
    emptyDataItem?: (style: React.CSSProperties) => JSX.Element
    onClick?: (item: T) => void
    keyGen: (item: T) => string
}

export const CollapsableSelector = <T extends IdentifiableObject>(
    props: Props<T>,
) => {
    const [controlState, setControlState] = useState({
        selectedIndex: -1,
        expanded: true,
    })

    const itemStyle: React.CSSProperties = {
        minHeight: props.itemHeight - (props.itemMargin ?? 0),
        maxHeight: props.itemHeight,
        position: 'absolute',
        width: '98%',
    }

    const containerStyle: React.CSSProperties = {
        overflowY: 'scroll',
        position: 'relative',
        minHeight: props.itemHeight,
        maxHeight: props.itemHeight,
    }

    if (controlState.expanded) {
        const dataLength = Math.max(1, props.data.length)
        let heightMultiplier = dataLength
        if (props.maxItems !== undefined) {
            heightMultiplier = Math.min(dataLength, props.maxItems)
        }
        const containerSize = props.itemHeight * heightMultiplier

        containerStyle.minHeight = containerSize
        containerStyle.maxHeight = containerSize
    } else {
        itemStyle.top = 0
        itemStyle.left = 0
    }

    const clickItem = (index: number, item: T) => {
        props.onClick && props.onClick(item)
        setControlState({
            expanded: !controlState.expanded,
            selectedIndex: index,
        })
    }

    let emptyListItem: JSX.Element | null = null
    if (props.data.length === 0) {
        emptyListItem = props.emptyDataItem
            ? props.emptyDataItem({
                  cursor: 'pointer',
                  ...props.customStyle?.itemStyle,
                  ...itemStyle,
              })
            : null
    }

    return (
        <div
            style={{ ...props.customStyle?.container, ...containerStyle }}
            className={styles.Container}
        >
            {emptyListItem}
            {props.data.map((item, idx) => {
                const selected = idx === controlState.selectedIndex
                const ifSelectedStyle = selected
                    ? props.customStyle?.selectedItemStyle
                    : {}
                const zIndex = selected ? props.data.length : idx

                return props.renderItem({
                    key: props.keyGen(item),
                    item: item,
                    className: styles.Item,
                    onClick: () => clickItem(idx, item),
                    style: {
                        // Overrideable default pointer
                        cursor: 'pointer',
                        // Low priority goes to user style,  anything after this
                        // is required for component to work
                        ...props.customStyle?.itemStyle,
                        // Positionining used when the container is open
                        top: idx * props.itemHeight,
                        // functionally required styles
                        ...itemStyle,
                        // Styles applied to the selected element
                        ...ifSelectedStyle,
                        zIndex: zIndex,
                    },
                })
            })}
        </div>
    )
}
