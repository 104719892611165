import React, { ReactNode, useState } from 'react'

import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'

import { currencyFormatter, toMMDDYYYY } from '../../../../../../helpers'
import { DDLeaseMatchStatus, MatchedLease } from '../../../../../../models'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import { Button, useTheme } from '@material-ui/core'
import { Style } from '@material-ui/icons'

export const formatCurrency = (value: any) => {
    if (value === undefined) {
        return undefined
    } else if (value === null) {
        return null
    } else {
        return currencyFormatter.format(Number(value))
    }
}

export const formatDate = (value: any) => {
    if (value === null) {
        return null
    }
    const [year, month, day] = value.split('T')[0].split('-')
    return `${month}-${day}-${year}`
}

const StyleCell = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
}))

interface Props {
    format: string
    value: ReactNode
    backgroundColor: string
}

export const CellStyle: React.FC<Props> = (props) => {
    const { format, value, backgroundColor } = props
    const theme = useTheme()
    if (format === 'text') {
        return <StyleCell style={{ backgroundColor }}>{value}</StyleCell>
    } else if (format === 'currency') {
        return (
            <StyleCell style={{ backgroundColor }}>
                {formatCurrency(value)}
            </StyleCell>
        )
    } else if (format === 'date') {
        return (
            <StyleCell style={{ backgroundColor }}>
                {formatDate(value)}
            </StyleCell>
        )
    } else if (format === 'bool') {
        if (value === null) {
            return <StyleCell>Audit Required</StyleCell>
        } else if (value === true) {
            return <StyleCell>Yes</StyleCell>
        } else if (value === false) {
            return <StyleCell>No</StyleCell>
        } else {
            return <StyleCell>Audit Required</StyleCell>
        }
    } else {
        return <StyleCell>{value}</StyleCell>
    }
    return <StyleCell>N/A</StyleCell>
}
