import React, { useEffect, useState } from 'react'
import { SliderPicker } from 'react-color'
import {
    Button,
    CircularProgress,
    FormControlLabel,
    IconButton,
    Popover,
    Switch,
    TextField,
    Theme,
    Tooltip,
} from '@material-ui/core'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import CloseIcon from '@material-ui/icons/Close'

import { KeyboardDatePicker } from '@material-ui/pickers'

import { ProjectPopoverFormState } from '../types'
import { Container, NumberInput } from '../../../components'
import { useDateRange } from '../../../hooks'
import {
    CreateScheduleRequest,
    ScheduleDetailActionThunk,
    UpdateScheduleRequest,
} from '../../../store'
import { isHex } from '../../../helpers'
import { Schedule } from '../../../models'

interface Props {
    theme: Theme
    schedule: Schedule | null
    loading?: boolean
    color: string
    initialStartDate: Date
    setColor: (color: string) => void
    onClose: () => void
    createSchedule: (req: CreateScheduleRequest) => ScheduleDetailActionThunk
    updateSchedule: (req: UpdateScheduleRequest) => ScheduleDetailActionThunk
}

export const ScheduleForm = (props: Props) => {
    const {
        theme,
        schedule,
        onClose,
        loading,
        createSchedule,
        updateSchedule,
        color,
        setColor,
        initialStartDate,
    } = props

    const [doValidate, setDoValidate] = useState(false)
    const [name, setName] = useState(schedule?.name ?? '')
    const [scheduleId, setScheduleId] = useState(schedule?.schedule_id ?? '')
    const [baselineBudget, setBaselineBudget] = useState(0)
    const [archived, setArchived] = useState(false)

    const { setRange, setStartDate, setEndDate, dateRange } = useDateRange('')
    const { startDate, endDate } = dateRange

    const inputStyle: React.CSSProperties = {
        marginBottom: theme.spacing(4),
    }

    const isValid = () => {
        const nameValid = name !== ''
        const scheduleIdValid = scheduleId !== ''
        const budgetValid = baselineBudget >= 0
        const colorValid = isHex(color)

        return nameValid && scheduleIdValid && budgetValid && colorValid
    }

    useEffect(() => {
        if (schedule) {
            setName(schedule.name)
            setScheduleId(schedule.schedule_id)
            setBaselineBudget(schedule.baseline_budget)
            setArchived(!schedule.active)

            const startDate = new Date(schedule.start_date)
            const endDate = new Date(schedule.end_date)
            setRange({ startDate: startDate, endDate: endDate })
        } else {
            setName('')
            setScheduleId('')
            setBaselineBudget(0)
            setArchived(false)
            setRange({ startDate: initialStartDate, endDate: initialStartDate })
        }
    }, [schedule])

    return (
        <Container style={{ flex: 1, flexDirection: 'column' }}>
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    padding: theme.spacing(1),
                    marginTop: theme.spacing(1),
                }}
            >
                <TextField
                    error={doValidate && name === ''}
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label="Name"
                    style={inputStyle}
                />

                <TextField
                    error={doValidate && scheduleId === ''}
                    variant="outlined"
                    value={scheduleId}
                    onChange={(e) => setScheduleId(e.target.value)}
                    label="Project Id"
                    inputProps={{ maxLength: 8 }}
                    style={inputStyle}
                    disabled={schedule !== null}
                />

                <NumberInput
                    error={doValidate && baselineBudget < 0}
                    prefix={'$'}
                    variant="outlined"
                    label="Set Budget"
                    value={baselineBudget}
                    onChange={(e) => setBaselineBudget(Number(e.target.value))}
                    style={inputStyle}
                />

                <KeyboardDatePicker
                    error={startDate === null}
                    value={startDate}
                    onChange={(date) => {
                        if (date) {
                            setStartDate(date)
                        }
                    }}
                    format="MM/dd/yyyy"
                    inputVariant="outlined"
                    label="Start Date"
                    style={inputStyle}
                />

                <KeyboardDatePicker
                    error={endDate === null}
                    value={endDate}
                    onChange={(date) => {
                        if (date) {
                            setEndDate(date)
                        }
                    }}
                    format="MM/dd/yyyy"
                    inputVariant="outlined"
                    label="End Date"
                    style={inputStyle}
                />

                <Container style={{ flexDirection: 'column' }}>
                    <span
                        style={{
                            ...theme.typography.body2,
                            fontWeight: theme.typography.fontWeightLight,
                            color: theme.palette.grey[600],
                        }}
                    >
                        Archived
                    </span>
                    <Switch
                        checked={archived}
                        color="primary"
                        onClick={() => {
                            setArchived(!archived)
                        }}
                        disabled={schedule === null}
                    />
                </Container>

                <Container
                    style={{
                        alignItems: 'center',
                        marginBottom: theme.spacing(1),
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body2,
                            fontWeight: theme.typography.fontWeightLight,
                            color: theme.palette.grey[600],
                            marginRight: theme.spacing(1),
                        }}
                    >
                        Project Color
                    </span>

                    <TextField
                        error={doValidate && !isHex(color)}
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                        style={{ width: 80 }}
                        inputProps={{
                            style: {
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightLight,
                                color: theme.palette.grey[600],
                                textAlign: 'center',
                            },
                        }}
                    />
                </Container>

                <SliderPicker
                    color={color}
                    onChange={(color) => {
                        setColor(color.hex)
                    }}
                />
            </Container>

            {/* Footer */}

            <Container
                style={{
                    padding: theme.spacing(1),
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                }}
            >
                <Button
                    variant="contained"
                    style={{
                        margin: theme.spacing(1, 0, 1, 2),
                        backgroundColor: theme.palette.secondary.dark,
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                        width: 75,
                    }}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <div style={{ flex: 1 }} />

                {loading ? (
                    <CircularProgress />
                ) : (
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(1, 0, 1, 2),
                            backgroundColor: theme.palette.primary.dark,
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            width: 75,
                        }}
                        onClick={() => {
                            setDoValidate(true)

                            if (!isValid()) {
                                return
                            }

                            if (schedule) {
                                updateSchedule({
                                    scheduleId: schedule.id,
                                    body: {
                                        name: name,
                                        start_date: startDate?.toISOString(),
                                        end_date: endDate?.toISOString(),
                                        color: color,
                                        active: !archived,
                                        baseline_budget: baselineBudget ?? 0,
                                    },
                                }).then(onClose)
                            } else {
                                createSchedule({
                                    body: {
                                        name: name,
                                        start_date:
                                            startDate?.toISOString() ??
                                            new Date().toISOString(),
                                        end_date:
                                            endDate?.toISOString() ??
                                            new Date().toISOString(),
                                        schedule_id: scheduleId,
                                        color: color,
                                        baseline_budget: baselineBudget ?? 0,
                                    },
                                }).then(onClose)
                            }
                        }}
                    >
                        Save
                    </Button>
                )}
            </Container>
        </Container>
    )
}
