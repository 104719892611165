import React from 'react'
import { Container } from '../Container'
import { BaseProps } from './types'
import { Button, Grid } from '@material-ui/core'
import { TemplateServiceChartRow, TemplateServiceDetailRow } from './Components'
import { ServiceDetailHeader } from './Components/Header'
import { useCalendarGrid } from './hooks'
import { HEADER_H } from './constants'
import { setMidnight } from '../../helpers'
import { CalendarGrid } from './Components/CalendarGrid'
import { usePopover } from '../../hooks/usePopover'
import { ListVendor, Schedule, Service } from '../../models'
import { SelectorPopover } from './Components/SelectorPopover'

interface Props extends BaseProps {
    serviceList: Service[]
    vendorList: ListVendor[]
}

export const TemplateScheduleGantt = (props: Props) => {
    const { theme, scheduleController } = props

    const popoverState = usePopover()

    const schedule = scheduleController.schedule

    const startDate = schedule ? new Date(schedule.start_date) : new Date()
    const endDate = schedule ? new Date(schedule.end_date) : new Date()

    setMidnight(startDate)
    setMidnight(endDate)

    const grid = useCalendarGrid(startDate, endDate)

    const handleChooseTransferDate = (clickX: number) => {
        if (schedule === null || grid.gridRef.current === null) return

        if (clickX === -1) {
            scheduleController.setTransferDate(null)
            return
        }

        const gridPos = grid.getClickedGridPosition(clickX)

        const clickedDate = grid.covnertGridPositionToDate(gridPos)
        scheduleController.setTransferDate(clickedDate)
    }

    let servicesWithAtLeastOneVendor = 0

    return (
        <Container style={{ maxHeight: '100%', overflowY: 'scroll' }}>
            <Grid
                container
                style={{
                    border: `1px solid ${theme.palette.grey[400]}`,
                    borderRadius: theme.shape.borderRadius,
                    margin: theme.spacing(2),
                    overflowY: 'scroll',
                    maxHeight: '100%',
                }}
            >
                {/* Service Detail Detail Column */}
                <Grid container item xs={4}>
                    <ServiceDetailHeader {...props} gridController={grid} />

                    {schedule?.template_services.map((ts) => {
                        return (
                            <TemplateServiceDetailRow
                                key={`TEMPLATE-SERVICE-${ts.id}`}
                                {...props}
                                templateService={ts}
                                projectDuration={
                                    grid.gridPositionInfo.numProjectDays
                                }
                            />
                        )
                    })}

                    <Grid
                        container
                        item
                        xs={12}
                        style={{ padding: theme.spacing(2) }}
                    >
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: theme.palette.primary.dark,
                                color: theme.palette.primary.contrastText,
                                height: 40,
                                textTransform: 'none',
                            }}
                            onClick={popoverState.handleOpen}
                        >
                            + Service
                        </Button>
                    </Grid>
                </Grid>

                {/* Gantt column */}
                <Grid container xs={8}>
                    {/* Spacer Column */}
                    <div
                        style={{
                            height: HEADER_H,
                            width: 8,
                            boxSizing: 'border-box',
                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                        }}
                    />

                    <Container
                        ref={grid.gridRef}
                        style={{
                            flex: 1,
                            overflowX: 'scroll',
                            flexDirection: 'column',
                            boxShadow: 'border-box',
                            position: 'relative',
                            zIndex: theme.zIndex.modal,
                        }}
                    >
                        <CalendarGrid
                            {...props}
                            calendarGridState={grid}
                            handleChooseTransferDate={handleChooseTransferDate}
                        />

                        {schedule?.template_services.map((ts) => {
                            const tsHasVendor =
                                ts.template_service_vendors.length > 0 ? 1 : 0

                            servicesWithAtLeastOneVendor += tsHasVendor

                            return (
                                <TemplateServiceChartRow
                                    key={`CHART-SERVICE-BAR${ts.id}`}
                                    {...props}
                                    gridState={grid}
                                    templateService={ts}
                                    prevVendorCount={
                                        servicesWithAtLeastOneVendor -
                                        tsHasVendor
                                    }
                                />
                            )
                        })}
                    </Container>
                </Grid>
            </Grid>

            <SelectorPopover
                {...props}
                popoverState={popoverState}
                data={props.serviceList}
                label="Select Service"
                onSubmit={(selectedId) => {
                    if (scheduleController.schedule === null) return
                    scheduleController.addTemplateService(
                        schedule?.id ?? -1,
                        selectedId,
                    )
                }}
            />
        </Container>
    )
}
