import { AccordionSummary, IconButton, useTheme } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons'

import React from 'react'
import { Container } from '../../../components'

interface Props {
    expanded: boolean
    title?: JSX.Element
    footer?: JSX.Element
    onChange: (open: boolean) => void
}

export const SectionSummary = (props: Props) => {
    const { expanded, onChange } = props

    const theme = useTheme()

    return (
        <AccordionSummary
            style={{
                flex: 1,
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
            onClick={() => onChange(!expanded)}
        >
            <IconButton
                onClick={() => onChange(!expanded)}
                size="small"
                style={{ marginRight: theme.spacing(1) }}
            >
                {expanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>

            {/* Title + toggle */}
            <Container direction="column">{props.title}</Container>

            <div style={{ flex: 1 }} />

            <Container direction="column" alignItems="flex-end">
                {props.footer}
            </Container>
        </AccordionSummary>
    )
}
