import React from 'react'

import Plot from 'react-plotly.js'

import { BlanketProps, Container } from '../../../components'
import { Theme } from '@material-ui/core'
import { BaseWorkorder, Unit, WorkorderStatus } from '../../../models'
import { setEndOfDay, toMMDDYYYY } from '../../../helpers'

interface Props extends BlanketProps {
    theme: Theme
    workorders: BaseWorkorder[]
    isWorkorderInFilter: (workorder: BaseWorkorder, unit: Unit) => boolean
}

interface DateWorkorderCountListItem {
    dateMs: number
    workorderCount: number
}

interface DateWorkorderCountMap {
    [dateMs: number]: DateWorkorderCountListItem
}

export const BurnDownBlanket = (props: Props) => {
    const { theme, workorders, isWorkorderInFilter } = props

    // Filter workorders based on the provided filter function
    const filteredWorkorders = workorders.filter((wo) => {
        return isWorkorderInFilter(wo, {
            id: wo.unit_id ?? -1,
            folder: {
                id: -1,
                apartmentOwner: null,
                units: [],
                children: [],
                lineageUnitCount: 0,
                path: '',
                pathIds: '',
                parent: null,
                name: '',
            },
            areas: [],
            workorders: [],
            inspectionDamages: [],
            inventoryInspections: [],
            unit_config: -1,
            name: '',
        })
    })

    const baselineCountMap: DateWorkorderCountMap = {}
    const completeCountMap: DateWorkorderCountMap = {}

    // Function to update the workorder count map for a given date
    const updateWorkorderCountMap = (
        dateStr: string,
        dateWorkorderCountMap: DateWorkorderCountMap,
    ) => {
        const date = new Date(dateStr)
        setEndOfDay(date)
        const dateMs = date.getTime()
        if (dateWorkorderCountMap[dateMs] === undefined) {
            dateWorkorderCountMap[dateMs] = {
                dateMs: dateMs,
                workorderCount: 0,
            }
        }
        dateWorkorderCountMap[dateMs].workorderCount += 1
    }

    // First pass: populate baseline count map with workorder end dates
    filteredWorkorders.forEach((wo) => {
        const dateStr = wo.end_date ?? wo.start_date
        if (dateStr) {
            updateWorkorderCountMap(dateStr, baselineCountMap)
        }
    })

    // Second pass: populate complete count map with workorder completion dates
    filteredWorkorders.forEach((wo) => {
        for (let i = 0; i < wo.transition_logs.length; i++) {
            const log = wo.transition_logs[i]
            if (log.to_status === WorkorderStatus.COMPLETE) {
                updateWorkorderCountMap(log.date, completeCountMap)
                break
            }
        }
    })

    const baselineCountList: DateWorkorderCountListItem[] = []
    const completeCountList: DateWorkorderCountListItem[] = []

    // Convert baseline count map to list
    Object.keys(baselineCountMap).forEach((key) => {
        baselineCountList.push(baselineCountMap[Number(key)])
    })

    // Convert complete count map to list
    Object.keys(completeCountMap).forEach((key) => {
        completeCountList.push(completeCountMap[Number(key)])
    })

    // Sort the lists by date
    const sortedBaselineList = baselineCountList.sort((a, b) => {
        return a.dateMs - b.dateMs
    })

    const sortedCompleteList = completeCountList.sort((a, b) => {
        return a.dateMs - b.dateMs
    })

    const baselineX: Date[] = []
    const baselineY: number[] = []

    const completeX: Date[] = []
    const completeY: number[] = []

    const burnDownBaselineData: DateWorkorderCountListItem[] = []
    const burnDownCompleteData: DateWorkorderCountListItem[] = []

    // Calculate cumulative baseline data
    for (let i = 0; i < sortedBaselineList.length; i++) {
        let total = 0
        for (let j = i; j < sortedBaselineList.length; j++) {
            total += sortedBaselineList[j].workorderCount
        }
        burnDownBaselineData.push({
            dateMs: sortedBaselineList[i].dateMs,
            workorderCount: total,
        })
    }

    // Calculate cumulative complete data
    for (let i = 0; i < sortedCompleteList.length; i++) {
        let total = 0
        for (let j = 0; j < i; j++) {
            total += sortedCompleteList[j].workorderCount
        }
        burnDownCompleteData.push({
            dateMs: sortedCompleteList[i].dateMs,
            workorderCount: filteredWorkorders.length - total,
        })
    }

    // Prepare data for plotting
    burnDownBaselineData.forEach((dailyValue) => {
        const date = new Date(dailyValue.dateMs)

        baselineX.push(date)
        baselineY.push(dailyValue.workorderCount)
    })

    burnDownCompleteData.forEach((dailyValue) => {
        const date = new Date(dailyValue.dateMs)

        completeX.push(date)
        completeY.push(dailyValue.workorderCount)
    })

    return (
        <Container
            style={{
                flex: 1,
            }}
        >
            <Plot
                data={[
                    {
                        line: {
                            color: 'black',
                            dash: 'dash',
                            width: 6,
                        },
                        mode: 'lines',
                        name: 'Baseline',
                        x: baselineX,
                        y: baselineY,
                        type: 'scatter',
                        hoverinfo: 'y',
                    },
                    {
                        line: {
                            color: theme.palette.primary.main,
                            width: 4,
                        },
                        mode: 'lines+markers',
                        name: 'Actual',
                        x: completeX,
                        y: completeY,
                        hoverinfo: 'y',
                    },
                ]}
                layout={{
                    title: 'Remaining Workorders',
                    titlefont: {
                        family: theme.typography.h1.fontFamily,
                        size: 20,
                        color: theme.typography.h1.color,
                    },
                    yaxis: {
                        tickfont: {
                            size: 14,
                        },
                    },
                    xaxis: {
                        tickfont: {
                            size: 14,
                        },
                    },
                    legend: {
                        font: {
                            family: theme.typography.h1.fontFamily,
                            size: 16,
                            color: theme.typography.h1.color,
                        },
                    },
                    hoverlabel: {
                        font: {
                            family: theme.typography.h1.fontFamily,
                            size: 18,
                            color: theme.palette.grey[200],
                            // color: '#FFFFFF',
                        },
                    },
                    width: props.rect.width,
                    // autosize: true,
                }}
                // config={{ responsive: true }}
            />
        </Container>
    )
}
