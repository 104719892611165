import React, { useState } from 'react'

import { Container } from '../../../components'
import {
    Button,
    MenuItem,
    Modal,
    Paper,
    Slide,
    TextField,
    useTheme,
} from '@material-ui/core'
import { stateAbbreviationMap, statesList, toYYYYMMDD } from '../../../helpers'
import { CreateBeneficialOwnerBody } from '../../../contexts'
import { DatePicker } from '@material-ui/pickers'

interface Props {
    open: boolean
    onClose: () => void
    onSubmit: (request: CreateBeneficialOwnerBody) => void
}

export const BeneficialOwnerForm = (props: Props) => {
    const { open, onClose, onSubmit } = props

    const theme = useTheme()

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [birthdate, setBirthdate] = useState<Date | null>(new Date())
    const [ssn, setSsn] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [state, setState] = useState('')

    const [doValidate, setDoValidate] = useState(false)

    const formValid = () => {
        return true
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper style={{ width: 500 }}>
                    <Container
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                            border: `1px solid ${theme.palette.grey[700]}`,
                            borderRadius: theme.shape.borderRadius,
                            padding: theme.spacing(2),
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            Add a beneficial owner
                        </span>
                        <span
                            style={{
                                ...theme.typography.subtitle2,
                                fontWeight: theme.typography.fontWeightLight,
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            Businesses are required to verify the identity of
                            beneficial owners. A beneficial owner is anyone who
                            owns or controls at least 25% of the company.
                        </span>
                        <Container style={{ marginTop: theme.spacing(2) }}>
                            <TextField
                                label="First Name"
                                fullWidth
                                value={firstName}
                                onChange={(e) => {
                                    setFirstName(e.target.value)
                                }}
                                error={doValidate && firstName === ''}
                                helperText={
                                    doValidate && firstName === '' && 'Required'
                                }
                            />
                            <TextField
                                label="Last Name"
                                fullWidth
                                style={{ marginLeft: theme.spacing(1) }}
                                value={lastName}
                                onChange={(e) => {
                                    setLastName(e.target.value)
                                }}
                                error={doValidate && lastName === ''}
                                helperText={
                                    doValidate && lastName === '' && 'Required'
                                }
                            />
                        </Container>
                        <DatePicker
                            style={{ marginTop: theme.spacing(2) }}
                            label="Birthdate"
                            value={birthdate}
                            openTo="year"
                            onChange={(date) => {
                                setBirthdate(date)
                            }}
                            format="MM/dd/yyyy"
                        />
                        <TextField
                            label="SSN"
                            fullWidth
                            style={{ marginTop: theme.spacing(2) }}
                            value={ssn}
                            error={doValidate && ssn.length !== 9}
                            onChange={(e) => {
                                setSsn(e.target.value)
                            }}
                            inputProps={{ maxLength: 9 }}
                        />
                        <TextField
                            label="Address"
                            fullWidth
                            style={{ marginTop: theme.spacing(2) }}
                            value={address}
                            onChange={(e) => {
                                setAddress(e.target.value)
                            }}
                            error={doValidate && address === ''}
                            helperText={
                                doValidate && address === '' && 'Required'
                            }
                        />
                        <TextField
                            label="City"
                            fullWidth
                            style={{ marginTop: theme.spacing(2) }}
                            value={city}
                            onChange={(e) => {
                                setCity(e.target.value)
                            }}
                            error={doValidate && city === ''}
                            helperText={doValidate && city === '' && 'Required'}
                        />
                        <Container style={{ marginTop: theme.spacing(1) }}>
                            <TextField
                                error={doValidate && state === ''}
                                helperText={
                                    doValidate && state === '' ? 'Required' : ''
                                }
                                value={state}
                                onChange={(e) =>
                                    setState(e.target.value as string)
                                }
                                label="State"
                                fullWidth
                                style={{ marginRight: theme.spacing(1) }}
                                select
                            >
                                {statesList.map((s) => {
                                    return (
                                        <MenuItem key={s} value={s}>
                                            {
                                                stateAbbreviationMap[
                                                    s as keyof typeof stateAbbreviationMap
                                                ]
                                            }
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                            <TextField
                                label="Zip Code"
                                fullWidth
                                value={zipCode}
                                onChange={(e) => {
                                    setZipCode(e.target.value)
                                }}
                            />
                        </Container>

                        <Container
                            style={{
                                justifyContent: 'space-between',
                                marginTop: theme.spacing(2),
                                borderTop: `1px solid ${theme.palette.grey[200]}`,
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={onClose}
                                style={{ marginTop: theme.spacing(2) }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                    if (formValid()) {
                                        onSubmit({
                                            first_name: firstName,
                                            last_name: lastName,
                                            date_of_birth: toYYYYMMDD(
                                                birthdate!,
                                            ),
                                            address: address,
                                            city: city,
                                            state: state,
                                            zip_code: zipCode,
                                            country: 'US',
                                            ssn: ssn,
                                        })
                                    } else {
                                        setDoValidate(true)
                                    }
                                }}
                                style={{ marginTop: theme.spacing(2) }}
                            >
                                Submit
                            </Button>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
