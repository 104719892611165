import React from 'react'

import { Container } from '../../../../../components'
import { BaseProps } from '../types'
import { Schedule } from '../../../../../models'
import { toMMDDYYYY } from '../../../../../helpers'

interface Props extends BaseProps {
    project: Schedule
    onClick: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        project: Schedule,
    ) => void
}

export const EventTypeProject = (props: Props) => {
    const { theme, project } = props

    const startDate = new Date(project.start_date)
    const endDate = new Date(project.end_date)

    let timelineLabel = (
        <span
            style={{
                ...theme.typography.body2,
                fontWeight: theme.typography.fontWeightLight,
                marginRight: theme.spacing(2),
            }}
        >
            (Archived)
        </span>
    )

    if (project.active) {
        timelineLabel = (
            <Container>
                <span
                    style={{
                        ...theme.typography.body2,
                        fontWeight: theme.typography.fontWeightBold,
                        marginRight: theme.spacing(1),
                    }}
                >
                    {toMMDDYYYY(startDate)}
                </span>
                <span
                    style={{
                        ...theme.typography.body2,
                        fontWeight: theme.typography.fontWeightLight,
                        marginRight: theme.spacing(1),
                    }}
                >
                    -
                </span>
                <span
                    style={{
                        ...theme.typography.body2,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {toMMDDYYYY(endDate)}
                </span>
            </Container>
        )
    }

    return (
        <Container
            style={{
                paddingLeft: theme.spacing(1),
                alignItems: 'center',
                flex: 1,
            }}
            onClick={(e) => props.onClick(e, project)}
        >
            <span
                style={{
                    ...theme.typography.body1,
                    fontWeight: theme.typography.fontWeightBold,
                    marginRight: theme.spacing(2),
                }}
            >
                {project.name}
            </span>
            {timelineLabel}
        </Container>
    )
}
