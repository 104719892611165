import React, { useState, useRef } from 'react'

import FileDownload from 'js-file-download'

import {
    ScheduleReportPopOver as Popover,
    Container,
    ItemProps,
} from '../../../../components'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
    Tooltip,
    Button,
} from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { usePopover } from '../../../../hooks/usePopover'
import { axiosInstance } from '../../../../helpers'
import { CREATE_WORKORDERS, Schedule, WorkSpaceUser } from '../../../../models'
import { hasPermission } from '../../../../models/Users/services'
import { toast } from 'react-toastify'

interface Props {
    schedule?: Schedule
    workspaceUser?: WorkSpaceUser
}

export const ScheduleReportPopOver = (props: Props) => {
    const { schedule, workspaceUser } = props

    const popoverState = usePopover()

    const [bedStatusSyncDialogOpen, setBedStatusSyncDialogOpen] = useState(
        false,
    )

    const [setupLoading, setSetupLoading] = useState(false)
    const [reportLoading, setReportLoading] = useState(false)

    const uploadBedSheetRef = useRef<HTMLInputElement>(null)
    const uploadUnitSheetRef = useRef<HTMLInputElement>(null)

    const getUnitReport = async () => {
        setReportLoading(true)

        try {
            const res = await axiosInstance.post(
                'stats/workorder-report/',
                {
                    schedule: schedule?.id,
                    variant: 'unit',
                    tz_offset: new Date().getTimezoneOffset(),
                },
                { responseType: 'blob' },
            )
            FileDownload(
                new Blob([res.data]),
                `${props.schedule?.name}_unit_report.xlsx`,
            )
        } catch (err) {
            console.log(err)
            toast.error('There was a problem downloading your report')
        }
        setReportLoading(false)
    }

    const popoverSetupOptions: ItemProps[] = [
        {
            title: 'Bed Status',
            downloadLink: {
                title: 'Download',
                onClick: () => {
                    setSetupLoading(true)
                    axiosInstance
                        .get(
                            `workorder/schedule/${schedule?.id}/status_sheet/`,
                            { responseType: 'blob' },
                        )
                        .then(() => {
                            toast.success(
                                'You will recieve a status sheet via email shortly.',
                            )
                        })
                        .finally(() => {
                            setSetupLoading(false)
                        })
                },
            },
            uploadLink: {
                title: 'Upload (Overwrites all changes)',
                onClick: () => uploadBedSheetRef.current?.click(),
            },
            syncLink: workspaceUser?.active_workspace.entrata_integrated
                ? {
                      title: 'Entrata Sync',
                      onClick: () => {
                          setBedStatusSyncDialogOpen(true)
                      },
                  }
                : undefined,
        },
    ]

    return (
        <Container>
            <Tooltip title="Reports / Setup">
                <IconButton onClick={popoverState.handleOpen}>
                    <FileCopyIcon />
                </IconButton>
            </Tooltip>

            <Popover
                open={popoverState.isOpen}
                onClose={popoverState.handleClose}
                anchorEl={popoverState.anchorEl}
                reportLoading={reportLoading}
                setupLoading={setupLoading}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                reportItems={[
                    {
                        title: 'Workorder Report',
                        downloadLink: {
                            title: 'Download',
                            onClick: () => {
                                setReportLoading(true)
                                axiosInstance
                                    .post(
                                        'stats/workorder-report/',
                                        {
                                            schedule: schedule?.id,
                                        },
                                        { responseType: 'blob' },
                                    )
                                    .then((response) => {
                                        FileDownload(
                                            new Blob([response.data]),
                                            `${props.schedule?.name}_workorder_report.xlsx`,
                                        )
                                    })
                                    .finally(() => {
                                        setReportLoading(false)
                                    })
                            },
                        },
                    },
                    {
                        title: 'Unit Report',
                        downloadLink: {
                            title: 'Download',
                            onClick: getUnitReport,
                        },
                        uploadLink: hasPermission(
                            workspaceUser,
                            CREATE_WORKORDERS,
                        )
                            ? {
                                  title: 'Upload',
                                  onClick: () => {
                                      uploadUnitSheetRef.current?.click()
                                  },
                              }
                            : undefined,
                    },
                ]}
                setupItems={popoverSetupOptions}
            />
            <input
                ref={uploadBedSheetRef}
                type="file"
                style={{ display: 'none' }}
                accept={'.xlsx'}
                onChange={(e) => {
                    if (
                        e.target.files?.length !== undefined &&
                        e.target.files.length > 0
                    ) {
                        const sheet = e.target.files[0]

                        const reqBody = new FormData()
                        reqBody.append('sheet', sheet)

                        setSetupLoading(true)

                        axiosInstance
                            .post(
                                `workorder/schedule/${schedule?.id}/status_sheet/`,
                                reqBody,
                            )
                            .then(() => {
                                toast.success(
                                    'You will recieve an email on upload complete.',
                                )
                            })
                            .catch(() => {
                                toast.error(
                                    'There was a problem with your sheet.',
                                )
                            })
                            .finally(() => setSetupLoading(false))
                    }
                }}
            />

            <Dialog open={bedStatusSyncDialogOpen}>
                <DialogTitle>Sync Bed Status</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to sync your bed statuses? This
                        action can be destructive. If you have moved your leases
                        out in Entrata the bed statuses will be marked as vacant
                        when renewal is what you expect. This action will
                        download a unit report.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => setBedStatusSyncDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={async () => {
                            await getUnitReport()
                            axiosInstance
                                .get(
                                    `workorder/schedule/${schedule?.id}/entrata_sync_bed_status/`,
                                )
                                .then(() => {
                                    toast.success(
                                        'Your request is being processed.  We will send you an email as soon as we are finished.',
                                    )
                                })
                                .catch(() => {
                                    toast.error(
                                        'There was a problem with your request',
                                    )
                                })
                                .finally(() => {
                                    setSetupLoading(false)
                                })
                            setBedStatusSyncDialogOpen(false)
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <input
                ref={uploadUnitSheetRef}
                type="file"
                style={{ display: 'none' }}
                accept={'.xlsx'}
                onChange={(e) => {
                    if (
                        e.target.files?.length !== undefined &&
                        e.target.files.length > 0 &&
                        schedule
                    ) {
                        const sheet = e.target.files[0]

                        const reqBody = new FormData()
                        reqBody.append('sheet', sheet)
                        reqBody.append('schedule', schedule.id.toString())
                        reqBody.append(
                            'tz_offset',
                            new Date().getTimezoneOffset().toString(),
                        )

                        axiosInstance
                            .post(
                                `workorder/import_unit_workorder_sheet/`,
                                reqBody,
                            )
                            .then(() => {
                                toast.success(
                                    'Success you will receive an email with your upload report soon.',
                                )
                            })
                            .catch(() => {
                                toast.error(
                                    'There was a problem with your sheet.',
                                )
                            })
                    }
                }}
            />
        </Container>
    )
}
