import React, { useState } from 'react'
import { Grid, useTheme } from '@material-ui/core'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { AvatarCircle, Container } from '../../components'
import { _Workorder } from '../../models'
import { getInitials, toMMDDYYYY, toUiTime } from '../../helpers'
import { WoStatusBadge } from '../VendorScheduler'

interface Props {
    workorder: _Workorder
}

export const WorkorderRow = (props: Props) => {
    const theme = useTheme()

    const { workorder } = props

    const [expanded, setExpanded] = useState(false)

    const containerStyle: React.CSSProperties = {
        backgroundColor: theme.palette.grey[50],
        padding: theme.spacing(1),
        cursor: 'pointer',
    }

    return (
        <Grid
            container
            onClick={() => setExpanded(!expanded)}
            style={{ margin: theme.spacing(1, 0) }}
        >
            <Grid
                container
                style={{
                    ...containerStyle,
                    borderRadius: theme.shape.borderRadius,
                    border: `2px solid ${
                        workorder.service_color ?? theme.palette.noService.main
                    }`,
                    alignItems: 'center',
                }}
            >
                <Grid item xs={4}>
                    <Container style={{ alignItems: 'center' }}>
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {workorder.user_friendly_schedule_id}
                        </span>

                        <span
                            style={{
                                ...theme.typography.subtitle2,
                                fontWeight: theme.typography.fontWeightBold,
                                marginLeft: theme.spacing(1),
                            }}
                        >
                            {workorder.service_name ?? ''}
                        </span>
                    </Container>

                    <Container>
                        <span
                            style={{
                                ...theme.typography.subtitle2,
                                fontWeight: theme.typography.fontWeightMedium,
                            }}
                        >
                            {workorder.path ?? ''} {workorder.folder_name ?? ''}
                        </span>
                        <span
                            style={{
                                ...theme.typography.subtitle2,
                                fontWeight: theme.typography.fontWeightBold,
                                marginLeft: theme.spacing(1),
                            }}
                        >
                            {workorder.unit_name ?? ''}
                        </span>
                    </Container>
                </Grid>

                <Grid item xs={2}>
                    <Container
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {workorder.vendor_name}
                        </span>
                        <span
                            style={{
                                ...theme.typography.subtitle2,
                                fontWeight: theme.typography.fontWeightLight,
                            }}
                        >
                            {toMMDDYYYY(workorder.startDate)} -{' '}
                            {toMMDDYYYY(workorder.endDate)}
                        </span>
                    </Container>
                </Grid>

                <Grid item xs={4} container>
                    {workorder.service_area_list.map((sa) => {
                        return (
                            <Grid
                                key={`WO_SA_${sa.sa_id}`}
                                item
                                style={{ marginLeft: theme.spacing(1) }}
                            >
                                <span
                                    style={{
                                        ...theme.typography.subtitle2,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    {sa.area_name}
                                </span>
                                <span
                                    style={{
                                        ...theme.typography.subtitle2,
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                        marginLeft: theme.spacing(0.5),
                                    }}
                                >
                                    ({sa.area_label})
                                </span>
                            </Grid>
                        )
                    })}
                </Grid>

                <Grid item xs={2}>
                    <WoStatusBadge
                        theme={theme}
                        status={workorder.status}
                        style={{
                            text: {
                                fontWeight: theme.typography.fontWeightBold,
                            },
                            container: {
                                margin: theme.spacing(1),
                            },
                        }}
                    />
                </Grid>
            </Grid>

            {expanded && (
                <Grid
                    container
                    style={{
                        ...containerStyle,
                        padding: 0,
                        margin: theme.spacing(0, 2, 0, 4),
                        borderBottomLeftRadius: theme.shape.borderRadius,
                        borderBottomRightRadius: theme.shape.borderRadius,
                        border: `1px solid ${
                            workorder.service_color ??
                            theme.palette.noService.main
                        }`,
                        borderTop: 0,
                    }}
                >
                    {workorder.transition_logs.map((log, idx) => {
                        // Do not render a bottom border on the last item
                        const isLastItem =
                            idx === workorder.transition_logs.length - 1
                        return (
                            <Grid
                                container
                                key={`WO_LOG_${log.log_id}`}
                                alignItems="center"
                                style={{
                                    borderBottom: !isLastItem
                                        ? `1px solid ${
                                              workorder.service_color ??
                                              theme.palette.noService.main
                                          }`
                                        : undefined,
                                    padding: theme.spacing(1),
                                }}
                            >
                                <Grid item xs={1}>
                                    <AvatarCircle
                                        text={getInitials(
                                            log.transition_user_name,
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={5} container direction="column">
                                    <span
                                        style={{
                                            ...theme.typography.subtitle2,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {log.transition_user_name}
                                    </span>
                                    <Container>
                                        <span
                                            style={{
                                                ...theme.typography.subtitle2,
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightLight,
                                            }}
                                        >
                                            {toMMDDYYYY(new Date(log.date))}
                                        </span>

                                        <span
                                            style={{
                                                ...theme.typography.subtitle2,
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightLight,
                                                marginLeft: theme.spacing(1),
                                            }}
                                        >
                                            {toUiTime(new Date(log.date))}
                                        </span>
                                    </Container>
                                </Grid>
                                <Grid item xs={2}>
                                    <WoStatusBadge
                                        theme={theme}
                                        status={log.from_status}
                                        style={{
                                            text: {
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                            },
                                            container: {
                                                margin: theme.spacing(1),
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Container
                                        style={{ justifyContent: 'center' }}
                                    >
                                        <ArrowRightAltIcon fontSize="large" />
                                    </Container>
                                </Grid>
                                <Grid item xs={2}>
                                    <WoStatusBadge
                                        theme={theme}
                                        status={log.to_status}
                                        style={{
                                            text: {
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                            },
                                            container: {
                                                margin: theme.spacing(1),
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            )}
        </Grid>
    )
}
