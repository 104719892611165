import { TableCell, TableRow, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import {
    CellData,
    Container,
    HeadCell,
    RowData,
    SimpleTable,
} from '../../components'
import { currencyFormatter, toMMDDYYYY } from '../../helpers'
import { useUser } from '../../hooks'
import { Schedule, SEE_VENDOR_FINANCES } from '../../models'
import { hasPermission } from '../../models/Users/services'
import { useStyles } from '../../styles'

export interface ScheduleRow extends RowData {
    nameAndId: CellData<JSX.Element>
    budget: CellData<number>
    startDate: CellData<string>
    endDate: CellData<string | null>
    assignments: CellData<number>
    lastReport: CellData<string | undefined>
    schedule: CellData<Schedule>
}

interface Props {
    scheduleList: Schedule[]
    onClick: (schedule: Schedule) => void
}

export const WorkorderReportTable = (props: Props) => {
    const { scheduleList, onClick } = props
    const theme = useTheme()
    const classes = useStyles()

    const { workspaceUser } = useUser()

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    const cellStyle: React.CSSProperties = {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightRegular,
        width: '1px',
    }

    const headCells: HeadCell<ScheduleRow>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'nameAndId',
            label: 'Schedule',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'lastReportDate',
            label: 'Last Report',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'budget',
            label: 'Budget',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'startDate',
            label: 'Start',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'endDate',
            label: 'End',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'assignments',
            label: 'Assignments',
            style: headCellStyle,
        },
    ]

    const createScheduleRow = (schedule: Schedule): ScheduleRow => {
        const endDateSort = schedule.end_date ? schedule.end_date : -1

        const nameAndIdComponent = (
            <Container direction="column">
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {schedule.name}
                </span>
                <span
                    style={{
                        ...theme.typography.body2,
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    {schedule.schedule_id}
                </span>
            </Container>
        )

        return {
            nameAndId: { value: nameAndIdComponent, sortValue: schedule.name },
            budget: {
                value: schedule.baseline_budget,
                sortValue: schedule.baseline_budget,
            },
            startDate: {
                value: schedule.start_date,
                sortValue: schedule.start_date,
            },
            endDate: { value: schedule.end_date, sortValue: endDateSort },
            assignments: {
                value: schedule.assigned_count,
                sortValue: schedule.assigned_count,
            },
            lastReport: {
                value: schedule.most_recent_workorder_report_time,
                sortValue: schedule.most_recent_workorder_report_time ?? '',
            },
            schedule: { value: schedule, sortValue: -1 },
        }
    }

    return (
        <SimpleTable<ScheduleRow>
            rows={scheduleList.map((sch) => createScheduleRow(sch))}
            render={(row) => {
                const startDate = new Date(row.startDate.value)
                const startDateLabel = toMMDDYYYY(startDate)

                let endDateLabel = ''
                if (row.endDate.value) {
                    const endDate = new Date(row.endDate.value)
                    endDateLabel = toMMDDYYYY(endDate)
                }
                let reportDateLabel = ''
                if (row.lastReport.value) {
                    const reportDate = new Date(row.lastReport.value)
                    reportDateLabel = toMMDDYYYY(reportDate)
                }

                return (
                    <TableRow
                        key={`SCHEDULE_ROW_${row.schedule.value.id}`}
                        style={{ cursor: 'pointer' }}
                        className={classes.hoverGrey200}
                        onClick={() => {
                            onClick(row.schedule.value)
                        }}
                    >
                        <TableCell style={cellStyle}>
                            {row.nameAndId.value}
                        </TableCell>
                        <TableCell style={cellStyle} align="center">
                            {reportDateLabel}
                        </TableCell>
                        <TableCell style={cellStyle}>
                            {hasPermission(workspaceUser, SEE_VENDOR_FINANCES)
                                ? currencyFormatter.format(row.budget.value)
                                : ''}
                        </TableCell>
                        <TableCell align="center" style={cellStyle}>
                            {startDateLabel}
                        </TableCell>
                        <TableCell align="center" style={cellStyle}>
                            {endDateLabel}
                        </TableCell>
                        <TableCell align="center" style={cellStyle}>
                            <NumberFormat
                                displayType="text"
                                value={row.assignments.value}
                                thousandSeparator
                            />
                        </TableCell>
                    </TableRow>
                )
            }}
            orderByDefault="startDate"
            headCells={headCells}
        />
    )
}
