import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { Container } from '../../Container'
import { BaseProps } from '../types'
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    Switch,
    TextField,
    Tooltip,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

import { Selector } from '../../Selector'
import { PERIOD_OPTIONS } from '../../../containers/Inspection/InspectionAssignModal'
import { TimelinePicker } from '../../TimelinePicker'
import {
    Area,
    AreaConfig,
    CustomStatus,
    Folder,
    FrequencyType,
    IdBoolMap,
    InventoryConfig,
    ListVendor,
    ModelMap,
    PeriodOption,
    PeriodOptions,
    Service,
    Unit,
    User,
    WorkorderAutomationConfig,
    WorkorderAutomationType,
    WorkorderFrequencyAutomation,
    traverse,
} from '../../../models'
import { Swiper } from '../../Swiper'

import { Finder } from '../../Finder'
import { _useFinderSelection } from '../../../hooks'
import { FinderLocationSelection, FinderSelectionMode } from '../../../store'

import { CreateWorkorderAutomationRequest } from '../../../contexts'
import { getPeriodOptionLabel } from '../../../models/Frequency/services'

interface Props extends BaseProps {
    services: Service[]
    vendors: ListVendor[]
    users: User[]
    root: Folder
    areaConfigMap: ModelMap<AreaConfig>
    automation?: WorkorderAutomationConfig
    automationType: WorkorderAutomationType
    inventoryConfigList: InventoryConfig[]
    customStatusList: CustomStatus[]
    handleCancel: () => void
    handleSave: (body: CreateWorkorderAutomationRequest) => void
}

export const WorkorderAutomationForm = (props: Props) => {
    const {
        theme,
        handleCancel,
        handleSave,
        services,
        users,
        vendors,
        root,
        automation,
    } = props

    const [doValidation, setDoValidation] = useState(false)

    const [swiperPosition, setSwiperPosition] = useState(false)

    const [title, setTitle] = useState('')
    const [frequencyPeriod, setFrequencyPeriod] = useState(PeriodOptions[0])
    const [selectedServiceId, setSelectedServiceId] = useState(-1)
    const [selectedVendorId, setSelectedVendorId] = useState(-1)
    const [selectedUserId, setSelectedUserId] = useState(-1)
    const [priority, setPriority] = useState(false)
    const [daysToComplete, setDaysToComplete] = useState(1)
    const [selectedInventory, setSelectedInventory] = useState<number>(-1)
    const [selectedStatus, setSelectedStatus] = useState<number>(-1)

    const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null)

    const finderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.PrunedSelection,
        selectionMode: FinderSelectionMode.Single,
    })

    const [areaSelectionState, setAreaSelectionState] = useState<IdBoolMap>({})

    useEffect(() => {
        if (automation) {
            setTitle(automation.title)

            setSelectedServiceId(automation.service?.id ?? -1)

            // set the swiper state to match the frequency
            if (automation.assigned_to_company) {
                setSwiperPosition(true)
                setSelectedVendorId(automation.assigned_to_company.id)
                setSelectedUserId(-1)
            } else if (automation.assigned_to_user) {
                setSwiperPosition(false)
                setSelectedVendorId(-1)
                setSelectedUserId(automation.assigned_to_user.id)
            } else {
                setSwiperPosition(false)
                setSelectedVendorId(-1)
                setSelectedUserId(-1)
            }

            setSelectedStatus(-1)
            setSelectedInventory(-1)

            setDaysToComplete(automation.days_to_complete)
            setPriority(automation.priority)

            if (automation.frequency !== null) {
                const frequency = automation.frequency
                const po = PeriodOptions.find(
                    (po) => po.name === frequency.period,
                )
                setFrequencyPeriod(po ?? PeriodOptions[0])
            }

            if (automation.trigger !== null) {
                const trigger = automation.trigger
                setSelectedInventory(trigger.inventory_config?.id ?? -1)
                setSelectedStatus(trigger.trigger_status.id)
            }

            // Find the unit in the tree and select the unit in the finder
            traverse(root, (folder) => {
                folder.units.forEach((u) => {
                    if (u.id === automation.unit?.id) {
                        setSelectedUnit(u)

                        if (finderSelection.selection.unit[u.id]) {
                            // the unit is already selected, the setFinderSelection method
                            // will toggle the selection off if it were to run
                        } else {
                            finderSelection.setFinderSelection({
                                type: 'unit',
                                location: u,
                            })
                        }
                        const serviceAreaMap: IdBoolMap = {}
                        automation.areas?.forEach((a) => {
                            serviceAreaMap[a.id] = true
                        })
                        setAreaSelectionState(serviceAreaMap)
                    }
                })
            })
        } else {
            setTitle('')
            // setFrequencyPeriod(PeriodOptions[0])
            setSelectedServiceId(-1)

            // Clear the swiper state
            setSwiperPosition(false)
            setSelectedVendorId(-1)
            setSelectedUserId(-1)

            setDaysToComplete(1)
            setPriority(false)

            setSelectedStatus(-1)
            setSelectedInventory(-1)

            // Clear the finder selection
            finderSelection.setFinderSelection()
            setSelectedUnit(null)

            setAreaSelectionState({})
        }
    }, [automation])

    const filteredInventory = useMemo(() => {
        return props.inventoryConfigList.filter((inv) => {
            if (selectedStatus === -1) {
                return true
            }

            return inv.custom_status_list?.find((status) => {
                return status.id === selectedStatus
            })
        })
    }, [selectedStatus])

    const inputStyle: React.CSSProperties = {
        margin: theme.spacing(2, 0),
        height: 56,
    }

    const isFormValid = () => {
        const titleValid = title !== ''
        let statusValid = true

        if (props.automationType === WorkorderAutomationType.TRIGGER) {
            statusValid = selectedStatus !== -1
        }

        return titleValid && statusValid
    }

    return (
        <Container
            style={{
                flexDirection: 'column',
                justifyContent: 'space-between',
                overflowY: 'scroll',
                flex: 1,
            }}
        >
            {/* body */}
            <Container
                scrollY
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    padding: theme.spacing(2),
                }}
            >
                {/* title */}
                <TextField
                    variant="outlined"
                    label="Title (Required)"
                    value={title}
                    error={doValidation && title === ''}
                    onChange={(e) => {
                        setTitle(e.target.value)
                    }}
                    style={inputStyle}
                    size="small"
                />

                {props.automationType === WorkorderAutomationType.FREQUENCY && (
                    <Selector
                        label="Frequency"
                        currentValue={frequencyPeriod.id}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            const opt = PeriodOptions.find(
                                (po) =>
                                    po.id === (event.target.value as number),
                            )
                            setFrequencyPeriod(opt ?? PeriodOptions[0])
                        }}
                        data={PeriodOptions}
                        getDisplayString={(period) => {
                            return getPeriodOptionLabel(period.name)
                        }}
                        customStyle={{
                            formControl: inputStyle,
                        }}
                    />
                )}

                {props.automationType === WorkorderAutomationType.TRIGGER && (
                    <Container style={{ flexDirection: 'column' }}>
                        <Selector
                            data={props.customStatusList}
                            currentValue={selectedStatus}
                            error={doValidation && selectedStatus === -1}
                            onChange={(e) => {
                                setSelectedStatus(Number(e.target.value))
                                setSelectedInventory(-1)
                            }}
                            label="Status (Required)"
                            getDisplayString={(d) => d.name}
                            customStyle={{
                                formControl: {
                                    margin: 0,
                                },
                            }}
                            searchable
                        />

                        <Selector
                            data={[
                                { id: -1, name: 'All' },
                                ...filteredInventory,
                            ]}
                            currentValue={selectedInventory}
                            onChange={(e) =>
                                setSelectedInventory(Number(e.target.value))
                            }
                            label="Inventory"
                            getDisplayString={(d) => d.name}
                            customStyle={{
                                formControl: {
                                    margin: 0,
                                    marginTop: theme.spacing(2),
                                },
                            }}
                            searchable
                        />
                    </Container>
                )}

                <Container style={{ alignItems: 'center' }}>
                    <Selector
                        label="Service"
                        maxItems={10}
                        searchable
                        currentValue={selectedServiceId}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            setSelectedServiceId(event.target.value as number)
                        }}
                        data={services}
                        getDisplayString={(service) => service.name}
                        customStyle={{
                            formControl: {
                                flex: 1,
                                margin: theme.spacing(2, 0),
                            },
                            select: { height: 56 },
                        }}
                        size="small"
                    />

                    <Tooltip title="Clear">
                        <IconButton onClick={() => setSelectedServiceId(-1)}>
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                </Container>

                {/* service drop down */}

                <Divider style={{ margin: theme.spacing(2, 0) }} />

                <Container
                    style={{
                        flexDirection: 'column',
                        minHeight: 200,
                        maxHeight: 200,
                        overflow: 'hidden',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: theme.typography.fontWeightBold,
                            margin: theme.spacing(2, 0),
                        }}
                    >
                        Assign To
                    </span>

                    <Swiper
                        style={{ overflow: 'hidden' }}
                        width={380}
                        position={swiperPosition}
                        onChangePosition={() => setSwiperPosition((p) => !p)}
                        leftItem={
                            <Selector
                                label="My Team"
                                searchable
                                currentValue={selectedUserId}
                                onChange={(
                                    event: React.ChangeEvent<{
                                        value: unknown
                                    }>,
                                ) => {
                                    setSelectedUserId(
                                        event.target.value as number,
                                    )
                                }}
                                data={[
                                    { id: -1, name: 'All Team Members' },
                                    ...users,
                                ]}
                                getDisplayString={(user) => user.name}
                                customStyle={{
                                    formControl: {
                                        ...inputStyle,
                                        flex: 1,
                                    },
                                }}
                            />
                        }
                        rightItem={
                            <Selector
                                label="My Vendors"
                                searchable
                                currentValue={selectedVendorId}
                                onChange={(
                                    event: React.ChangeEvent<{
                                        value: unknown
                                    }>,
                                ) => {
                                    setSelectedVendorId(
                                        event.target.value as number,
                                    )
                                }}
                                data={vendors}
                                getDisplayString={(vendor) => vendor.name}
                                customStyle={{
                                    formControl: {
                                        ...inputStyle,
                                        flex: 1,
                                    },
                                }}
                            />
                        }
                    />
                    <Container>
                        <Container style={{ flexDirection: 'column' }}>
                            <span>Days to complete</span>
                            <TextField
                                inputProps={{ type: 'number', min: 1 }}
                                value={daysToComplete}
                                onChange={(e) => {
                                    setDaysToComplete(Number(e.target.value))
                                }}
                            />
                        </Container>
                        <div style={{ flex: 1 }} />
                        <FormControlLabel
                            labelPlacement="top"
                            label="Priority"
                            style={{ marginRight: theme.spacing(2) }}
                            control={
                                <Switch
                                    checked={priority}
                                    color="primary"
                                    onClick={() => {
                                        setPriority(!priority)
                                    }}
                                />
                            }
                        />
                    </Container>
                </Container>

                <Divider style={{ margin: theme.spacing(2, 0) }} />

                {/* Unit Selector */}
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightBold,
                        margin: theme.spacing(2, 0),
                    }}
                >
                    Select a unit
                </span>
                <Container style={{ minHeight: 400, maxHeight: 400 }}>
                    <Finder
                        root={root}
                        locationSelection={finderSelection.selection}
                        onClickUnit={finderSelection.setFinderSelection}
                        selectedIcon={<></>}
                        defaultOpen
                        getUnitAdornment={(unit) => {
                            return (
                                <Container
                                    style={{
                                        flex: 1,
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Checkbox
                                        checked={
                                            finderSelection.selection.unit[
                                                unit.id
                                            ] !== undefined
                                        }
                                        onClick={() => {
                                            setAreaSelectionState({})
                                            if (selectedUnit?.id === unit.id) {
                                                setSelectedUnit(null)
                                            } else {
                                                setSelectedUnit(unit)
                                            }
                                            finderSelection.setFinderSelection({
                                                type: 'unit',
                                                location: unit,
                                            })
                                        }}
                                    />
                                </Container>
                            )
                        }}
                    />
                </Container>

                {/* Service Area selector */}
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightBold,
                        margin: theme.spacing(2, 0),
                    }}
                >
                    Select Service Areas
                </span>

                <Container
                    style={{
                        minHeight: 200,
                        border: `1px solid ${theme.palette.grey[400]}`,
                        borderRadius: theme.shape.borderRadius,
                    }}
                >
                    {selectedUnit ? (
                        <ServiceAreaSelector
                            unit={selectedUnit}
                            theme={theme}
                            areaConfigMap={props.areaConfigMap}
                            areaSelectionMap={areaSelectionState}
                            onCheckArea={(area) =>
                                setAreaSelectionState({
                                    ...areaSelectionState,
                                    [area.id]: areaSelectionState[area.id]
                                        ? false
                                        : true,
                                })
                            }
                        />
                    ) : (
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                                margin: 'auto',
                            }}
                        >
                            Unit required
                        </span>
                    )}
                </Container>
            </Container>

            {/* footer */}
            <Container
                justifyContent={'flex-end'}
                style={{
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    marginTop: theme.spacing(1),
                }}
            >
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    style={{
                        textTransform: 'none',
                        margin: theme.spacing(2, 0, 2, 0),
                    }}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    style={{
                        margin: theme.spacing(2),
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        setDoValidation(true)

                        if (!isFormValid()) {
                            return
                        }

                        const requestBody: CreateWorkorderAutomationRequest = {
                            start_date: new Date().toISOString(),
                            title: title,
                            period: null,
                            inspection_subscription: null,
                            assign_to_type: swiperPosition ? 'vendor' : 'user',
                            assign_to_id: swiperPosition
                                ? selectedVendorId
                                : selectedUserId,
                            days_to_complete: daysToComplete,
                            priority: priority,
                            area_ids: Object.keys(areaSelectionState).map(
                                Number,
                            ),
                        }

                        if (
                            props.automationType ===
                            WorkorderAutomationType.FREQUENCY
                        ) {
                            requestBody.period = frequencyPeriod.name
                        }

                        if (
                            props.automationType ===
                            WorkorderAutomationType.TRIGGER
                        ) {
                            // requestBody
                            requestBody.inspection_subscription = {
                                status_id: selectedStatus,
                                inventroy_config_id: selectedInventory,
                            }
                        }

                        if (selectedServiceId !== -1) {
                            requestBody.service_id = selectedServiceId
                        }

                        if (selectedUnit) {
                            requestBody.unit_id = selectedUnit.id
                        }

                        handleSave(requestBody)
                    }}
                >
                    Save
                </Button>
            </Container>
        </Container>
    )
}

interface SAProps extends BaseProps {
    unit: Unit
    areaConfigMap: ModelMap<AreaConfig>
    areaSelectionMap: IdBoolMap
    onCheckArea: (area: Area) => void
}

const ServiceAreaSelector = (props: SAProps) => {
    const { unit, areaConfigMap, theme } = props

    return (
        <Container
            style={{
                flex: 1,
                flexWrap: 'wrap',
                overflowY: 'scroll',
            }}
        >
            {unit.areas.map((area) => {
                const areaConfig = areaConfigMap[area.area_config]

                return (
                    <Container
                        key={`SA-SELECT-${area.id}`}
                        style={{
                            width: `calc(50% - ${theme.spacing(2)}px)`,
                            minHeight: 50,
                            maxHeight: 50,
                            margin: theme.spacing(1),
                            border: `1px solid ${theme.palette.grey[600]}`,
                            borderRadius: theme.shape.borderRadius,
                            alignItems: 'center',
                        }}
                    >
                        <Checkbox
                            checked={props.areaSelectionMap[area.id]}
                            onChange={() => props.onCheckArea(area)}
                        />
                        <span>
                            {areaConfig?.name} {area.area_label}
                        </span>
                    </Container>
                )
            })}
        </Container>
    )
}
