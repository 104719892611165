import React from 'react'
import {
    AreaConfig,
    Lease,
    LeaseStatus,
    LeaseStatusType,
    ModelMap,
    Unit,
    getInspectionStatusLabel,
    getLeaseStatusString,
    getLeaseTransition,
} from '../../../../models'
import {
    AvatarCircle,
    ConfirmationButton,
    Container,
    EllipsisSpan,
} from '../../../../components'
import { toMMDDYYYY } from '../../../../helpers'
import { IconButton, Tooltip } from '@material-ui/core'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import { toast } from 'react-toastify'

export const LeaseRow = (props: {
    lease: Lease
    unitMap: ModelMap<Unit>
    areaConfigMap: ModelMap<AreaConfig>
    transitionLease: (leaseId: number, toStatus: LeaseStatusType) => void
    onClick: (lease: Lease) => void
    allowTransitions: boolean
    isChecked: boolean
    onCheck: () => void
    onClickTransfer: () => void
    selectionMode?: boolean
}) => {
    const { lease, unitMap, areaConfigMap, transitionLease, onClick } = props

    const unit: Unit | undefined = unitMap[lease.area.unit]

    const areaConfig: AreaConfig | undefined =
        areaConfigMap[lease.area.area_config]

    const leaseTransition = getLeaseTransition(lease.status)

    const today = new Date()
    const leaseEndDate = new Date(lease.end_date)

    const canTransfer =
        leaseEndDate > today && lease.status !== LeaseStatus.PAST

    let toolTipText: string | JSX.Element = 'Transfer'

    if (!canTransfer) {
        toolTipText = (
            <Container style={{ flexDirection: 'column' }}>
                {leaseEndDate < today && (
                    <span>- Lease end date has already passed</span>
                )}
                {lease.status === LeaseStatus.PAST && (
                    <span>- Lease has already been set to Past</span>
                )}
            </Container>
        )
    }

    return (
        <Container
            key={lease.id}
            onClick={() => onClick(lease)}
            style={{
                height: 80,
                backgroundColor: '#F4F4F4',
                border: '1px solid #C4C4C4',
                borderRadius: 12,
                marginBottom: 16,
                padding: 12,
                alignItems: 'center',
                cursor: 'pointer',
            }}
        >
            <Container flex={1}>
                <AvatarCircle text={lease.user.name} initials />
                <Container
                    style={{
                        flexDirection: 'column',
                        marginLeft: '13px',
                        justifyContent: 'center',
                    }}
                >
                    <EllipsisSpan
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: 100,
                        }}
                    >
                        {lease.user.name}
                    </EllipsisSpan>
                    <EllipsisSpan
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: 100,
                        }}
                    >
                        {lease.user.email}
                    </EllipsisSpan>
                </Container>
            </Container>

            <Container flex={1}>
                <span style={{ width: '100%' }}>
                    {unit?.folder.path}
                    {unit?.folder.name}
                </span>
            </Container>

            <Container
                flex={1}
                style={{
                    flexDirection: 'column',
                }}
            >
                <span>{unit?.name}</span>
                <span>
                    {areaConfig?.name ?? '?'} {lease.area.area_label}
                </span>
            </Container>

            <Container flex={1}>
                <span style={{ width: '100%' }}>
                    {toMMDDYYYY(new Date(lease.start_date))}
                </span>
            </Container>

            <Container flex={1}>
                <span style={{ width: '100%' }}>
                    {toMMDDYYYY(new Date(lease.end_date))}
                </span>
            </Container>

            <Container flex={1}>
                <span style={{ width: '100%' }}>
                    {getLeaseStatusString(lease.status)}
                </span>
            </Container>
            <Container flex={1}>
                <span style={{ width: '100%' }}>
                    {getInspectionStatusLabel(
                        lease.tenant_move_in_inspection?.status,
                    )}
                </span>
            </Container>

            <Container style={{ flex: 1, alignItems: 'center' }}>
                <ConfirmationButton
                    actionLabel={leaseTransition?.positive?.actionLabel ?? '?'}
                    onConfirm={() => {
                        if (leaseTransition?.positive?.status) {
                            transitionLease(
                                lease.id,
                                leaseTransition.positive.status,
                            )
                        } else {
                            toast.error(
                                'The lease cannot be transitioned forward.',
                            )
                        }
                    }}
                />

                <Tooltip title={toolTipText} style={{ marginLeft: 12 }}>
                    <span>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                props.onClickTransfer()
                            }}
                            disabled={!canTransfer}
                        >
                            <SwapHorizIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </Container>
        </Container>
    )
}
