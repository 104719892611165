import { PaginationActionTypes, SET_NEXT_PREV } from '.'
import { AppRequests } from '..'

export const setPaginationNextPrev = (
    next: string,
    prev: string,
    count: number,
    request: AppRequests,
): PaginationActionTypes => {
    return {
        next: next,
        prev: prev,
        request: request,
        type: SET_NEXT_PREV,
        count: count,
    }
}
