import React, { useMemo, useState } from 'react'

import { toast } from 'react-toastify'

import {
    Badge,
    Button,
    CircularProgress,
    IconButton,
    Modal,
    Paper,
    Slide,
    Tooltip,
    useTheme,
} from '@material-ui/core'

import { Pagination } from '@material-ui/lab'
import TuneIcon from '@material-ui/icons/Tune'
import { ChatBubbleOutline, ReportProblem } from '@material-ui/icons'

import {
    BaseWorkorder,
    ListVendor,
    SEE_VENDOR_FINANCES,
    Service,
    UnitConfig,
    WorkSpaceUser,
    User,
    getServiceImageDetails,
    WorkorderStatus,
    ModelMap,
    WorkorderType,
    RFPType,
} from '../../models'

import {
    Attachment,
    Container,
    CreateWorkorderForm,
    FilterChips,
    MessageForm,
    SideDrawer,
    SideDrawerContainer,
    WorkorderDetailForm,
    WorkorderEditForm,
    WorkorderFilterForm,
    WorkorderStatusBadge,
    WorkorderTransitionButtons,
} from '../../components'
import { mix_hexes } from '../../helpers'
import { _useFinderSelection, usePagination, useUser } from '../../hooks'
import {
    CreateWorkOrderRequest,
    FinderLocationSelection,
    FinderSelectionMode,
    WorkorderResponse,
} from '../../store'

import { useStyles } from '../../styles'
import { workorderContext } from '../../contexts'
import { ScheduleFinderProps } from '../Scheduler_v2/SchedulerFinder'
import { BaseCreateWorkorderFormProps } from '../../components/WorkorderLib/CreateWorkorderForm/types'
import { hasPermission } from '../../models/Users/services'

import {
    DRAWER_W,
    VH,
    TOP_TOOL_BAR_H,
    AI_LIST_HEADER_H,
    AI_LIST_FOOTER_H,
    AI_HOME_HEADER,
    JANK_SIZE,
    LEFT_TOOL_BAR_W,
    LEFT_COL_W,
    MID_COL_W,
    PADDING,
} from './contants'
import { AiDrawerId, AiFormState, closedAiFormState } from './types'
import { SearchField } from '../../components/SearchField'

import { ReactComponent as EZTurnLogo } from '../../assets/Logo.svg'
import { EZNowSelectionDrawer } from '../EZNow/EZNowSelectionDrawer'
import { CreateEZNowModal } from '../EZNow/CreateEZNowModal'

interface Props extends ScheduleFinderProps, BaseCreateWorkorderFormProps {
    serviceList: Service[]
    vendorList: ListVendor[]
    unitConfigList: UnitConfig[]
    userList: User[]
    workspaceUser?: WorkSpaceUser
}

export const ActionItemList = (props: Props) => {
    const { theme } = props

    const [formState, setFormState] = useState<AiFormState>(closedAiFormState)

    const openFilterDrawer = () => {
        setFormState({
            ...closedAiFormState,
            filterFormOpen: true,
        })
    }

    const openDetailDrawer = (
        idx: number,
        selectionId: number,
        drawerId: AiDrawerId,
    ) => {
        setFormState({
            ...closedAiFormState,
            detailFormOpen: {
                index: idx,
                id: selectionId,
                drawerId: drawerId,
            },
        })
    }

    const finderFilterSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Recursive,
    })

    const {
        loadingState,
        createWorkorders,
        workorderFilterState,
        setWorkorderFilterState,
        filteredWorkorderList,
        getChipData,
        updateWorkorderState,
        updateWorkorder,
        transitionWorkorder,
        getWorkorderList,
        workorderSelectionMap: ezNowWorkorderSelectionMap,
        setWorkorderSelectionMap: setEZNowWorkorderSelectionMap,
        deleteWorkorder,
    } = workorderContext()

    const { workspaceUser } = useUser()

    const [EZNowMode, setEZNowMode] = useState(false)
    const [openEZNowModal, setOpenEZNowModal] = useState(false)
    const [createEZNowRequest, setCreateEZNowRequest] = useState(false)

    const [createModalOpen, setCreateModalOpen] = useState(false)

    const [search, setSearch] = useState('')

    const searchFilteredList = useMemo(() => {
        const lcSearch = search.toLocaleLowerCase()
        return filteredWorkorderList.filter((wo) => {
            const lcTitle = wo.title.toLocaleLowerCase()
            const titleValid = lcTitle.includes(lcSearch)

            return titleValid
        })
    }, [filteredWorkorderList, search])

    const { page, setPage, pageData, numPages, start, end } = usePagination(
        searchFilteredList ?? [],
    )

    const handleCreateWorkorders = (request: CreateWorkOrderRequest) => {
        createWorkorders(request, createEZNowRequest)
            .then(() => {
                setCreateModalOpen(false)
                if (createEZNowRequest) {
                    setOpenEZNowModal(true)
                    setCreateEZNowRequest(false)
                }
            })
            .catch((e) => {
                let errorMessage = <span>Error</span>
                if (e.response?.data?.message !== undefined) {
                    errorMessage = (
                        <Container
                            style={{
                                flexDirection: 'column',
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Error
                            </span>
                            <span>- {e.response?.data?.message}</span>
                        </Container>
                    )
                }
                toast.error(errorMessage, {
                    autoClose: 3000,
                })
            })
    }

    const headerStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.darkGreen.main,
    }

    const selectedWo: WorkorderResponse | undefined =
        pageData[formState.detailFormOpen.index]

    const drawerContainerOpen =
        formState.filterFormOpen ||
        (selectedWo?.id === formState.detailFormOpen.id &&
            formState.detailFormOpen.drawerId !== AiDrawerId.editActionItem)

    return (
        <SideDrawerContainer open={drawerContainerOpen} width={DRAWER_W}>
            <Container
                flex={1}
                direction="column"
                style={{
                    border: `1px solid ${theme.palette.grey[400]}`,
                    borderRadius: theme.shape.borderRadius,
                    margin: theme.spacing(1),
                }}
            >
                {/* Header */}
                <Container
                    style={{
                        flexDirection: 'column',
                        borderBottom: `1px solid ${theme.palette.grey[400]}`,
                        padding: theme.spacing(1),
                        minHeight: AI_LIST_HEADER_H,
                        maxHeight: AI_LIST_HEADER_H,
                    }}
                >
                    <Container
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            alignItems: 'center',
                            height: 60,
                        }}
                    >
                        <span>Action Items</span>
                        <div style={{ flex: 1 }} />
                        <Tooltip title="EZNow">
                            <IconButton
                                onClick={() => {
                                    setEZNowMode(!EZNowMode)
                                    // setDrawerState(closedDrawerState)
                                    setWorkorderFilterState({
                                        ...workorderFilterState,
                                        canBeEZNow: !EZNowMode,
                                    })
                                }}
                                size="small"
                            >
                                <EZTurnLogo style={{ height: 40, width: 40 }} />
                            </IconButton>
                        </Tooltip>
                        <Button
                            variant="contained"
                            style={{
                                marginLeft: theme.spacing(2),
                                backgroundColor: 'white',
                                color: theme.palette.primary.dark,
                                textTransform: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => setCreateModalOpen(true)}
                        >
                            + Create Action Item
                        </Button>

                        <IconButton onClick={openFilterDrawer}>
                            <TuneIcon fontSize="large" />
                        </IconButton>
                    </Container>
                    <SearchField
                        value={search}
                        style={{ maxWidth: 300 }}
                        variant="outlined"
                        onChange={(e) => setSearch(e.target.value)}
                    />

                    <FilterChips theme={theme} chips={getChipData()} />

                    <Container>
                        <span
                            style={{
                                ...headerStyle,
                                minWidth: 300,
                                maxWidth: 300,
                            }}
                        >
                            Info
                        </span>
                    </Container>
                </Container>

                {/* Body */}
                <Container
                    direction="column"
                    scrollY
                    flex={1}
                    style={{
                        minHeight: `calc(${VH} - ${TOP_TOOL_BAR_H} - ${AI_LIST_HEADER_H} - ${AI_LIST_FOOTER_H} - ${AI_HOME_HEADER} - ${JANK_SIZE})`,
                        maxHeight: `calc(${VH} - ${TOP_TOOL_BAR_H} - ${AI_LIST_HEADER_H} - ${AI_LIST_FOOTER_H} - ${AI_HOME_HEADER} - ${JANK_SIZE})`,
                    }}
                >
                    {loadingState.getWorkorderList && (
                        <Container
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress size={100} />
                        </Container>
                    )}
                    {pageData.map((workorder, idx) => {
                        return (
                            <ActionItemRow
                                key={`ACTION_ITEM_WORKORDER_${workorder.id}`}
                                workorder={workorder}
                                onClick={() => {
                                    if (EZNowMode) {
                                        const newEditSelection = {
                                            ...ezNowWorkorderSelectionMap,
                                        }

                                        if (
                                            newEditSelection[workorder.id] !==
                                            undefined
                                        ) {
                                            delete newEditSelection[
                                                workorder.id
                                            ]
                                        } else {
                                            newEditSelection[
                                                workorder.id
                                            ] = workorder
                                        }

                                        setEZNowWorkorderSelectionMap(
                                            newEditSelection,
                                        )
                                    } else {
                                        openDetailDrawer(
                                            idx,
                                            workorder.id,
                                            AiDrawerId.actionItem,
                                        )
                                    }
                                }}
                                isSelected={
                                    idx === formState.detailFormOpen.index &&
                                    workorder.id === formState.detailFormOpen.id
                                }
                                openMessageDrawer={() => {
                                    openDetailDrawer(
                                        idx,
                                        workorder.id,
                                        AiDrawerId.message,
                                    )
                                }}
                                drawerOpen={drawerContainerOpen}
                                EZNowSelected={
                                    EZNowMode &&
                                    ezNowWorkorderSelectionMap[workorder.id] !==
                                        undefined
                                }
                                // serviceList={serviceList}
                                // serviceRequestMap={serviceRequestMap}
                                user={props.workspaceUser}
                                transitionWorkorder={transitionWorkorder}
                            />
                        )
                    })}
                </Container>

                {/* Footer */}
                <Container
                    style={{
                        borderTop: `1px solid ${theme.palette.grey[400]}`,
                        padding: theme.spacing(1),
                        minHeight: AI_LIST_FOOTER_H,
                        maxHeight: AI_LIST_FOOTER_H,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Pagination
                        count={numPages}
                        page={page}
                        onChange={(_: unknown, newPage: number) => {
                            setPage(newPage)
                        }}
                    />
                    <span>
                        {start} - {end} of {filteredWorkorderList.length}
                    </span>
                </Container>

                <Modal
                    open={createModalOpen}
                    onClose={() => {
                        setCreateModalOpen(false)
                        setCreateEZNowRequest(false)
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={createModalOpen}>
                        <Paper style={{}}>
                            <CreateWorkorderForm
                                {...props}
                                onClickCreate={handleCreateWorkorders}
                                createEZNowRequest={createEZNowRequest}
                                setCreateEZNowRequest={setCreateEZNowRequest}
                            />
                        </Paper>
                    </Slide>
                </Modal>

                <Modal
                    open={
                        formState.detailFormOpen.drawerId ===
                        AiDrawerId.editActionItem
                    }
                    onClose={() => setFormState(closedAiFormState)}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide
                        direction="up"
                        in={
                            formState.detailFormOpen.drawerId ===
                            AiDrawerId.editActionItem
                        }
                    >
                        <Paper style={{}}>
                            <WorkorderEditForm
                                theme={theme}
                                serviceList={props.serviceList}
                                root={props.root}
                                userList={props.userList}
                                vendorList={props.vendorList}
                                workorder={selectedWo}
                                handleClose={() =>
                                    setFormState(closedAiFormState)
                                }
                                areaConfigMap={props.areaConfigMap}
                                handleSubmit={(request) => {
                                    return updateWorkorder(request)
                                }}
                                loading={loadingState.updateWorkorder}
                            />
                        </Paper>
                    </Slide>
                </Modal>

                <SideDrawer
                    open={formState.filterFormOpen}
                    width={DRAWER_W}
                    handleClose={() => setFormState(closedAiFormState)}
                >
                    <WorkorderFilterForm
                        theme={theme}
                        root={props.root}
                        finderSelection={finderFilterSelection}
                        serviceList={props.serviceList}
                        vendorList={props.vendorList}
                        userList={props.userList}
                        unitConfigList={props.unitConfigList}
                        workorderFilterState={workorderFilterState}
                        setWorkorderFilterState={setWorkorderFilterState}
                    />
                </SideDrawer>

                <EZNowSelectionDrawer
                    open={EZNowMode}
                    selectedWorkorderMap={ezNowWorkorderSelectionMap}
                    setSelectedWorkordersMap={setEZNowWorkorderSelectionMap}
                    onClose={() => {
                        setEZNowMode(false)
                        setWorkorderFilterState({
                            ...workorderFilterState,
                            canBeEZNow: false,
                        })
                    }}
                    setOpenEZNowModal={setOpenEZNowModal}
                />

                <CreateEZNowModal
                    open={openEZNowModal}
                    handleClose={() => {
                        setOpenEZNowModal(false)
                        getWorkorderList({
                            params: {
                                workorder_type: WorkorderType.ACTION_ITEM,
                            },
                        })
                    }}
                    selectedWorkordersMap={ezNowWorkorderSelectionMap}
                    setSelectedWorkordersMap={setEZNowWorkorderSelectionMap}
                />

                <SideDrawer
                    open={
                        selectedWo?.id === formState.detailFormOpen.id &&
                        formState.detailFormOpen.drawerId ===
                            AiDrawerId.actionItem
                    }
                    width={DRAWER_W}
                    handleClose={() => setFormState(closedAiFormState)}
                >
                    <WorkorderDetailForm
                        workspaceUser={workspaceUser}
                        theme={theme}
                        handleClose={() => setFormState(closedAiFormState)}
                        workorder={selectedWo}
                        openMessageDrawer={() => {
                            openDetailDrawer(
                                formState.detailFormOpen.index,
                                formState.detailFormOpen.id,
                                AiDrawerId.message,
                            )
                        }}
                        showFinances={hasPermission(
                            props.workspaceUser,
                            SEE_VENDOR_FINANCES,
                        )}
                        onClickEdit={() =>
                            openDetailDrawer(
                                formState.detailFormOpen.index,
                                formState.detailFormOpen.id,
                                AiDrawerId.editActionItem,
                            )
                        }
                        deleteWorkorder={deleteWorkorder}
                    />
                </SideDrawer>

                <SideDrawer
                    open={
                        selectedWo?.id === formState.detailFormOpen.id &&
                        formState.detailFormOpen.drawerId === AiDrawerId.message
                    }
                    width={DRAWER_W}
                    handleClose={() => setFormState(closedAiFormState)}
                >
                    <MessageForm
                        theme={theme}
                        channelId={selectedWo?.channel}
                        secondaryChannelId={selectedWo?.inv_ins_channel}
                        afterCreateMessage={() => {
                            if (selectedWo) {
                                updateWorkorderState({
                                    ...selectedWo,
                                    message_count:
                                        (selectedWo.message_count ?? 0) + 1,
                                })
                            }
                        }}
                        secondaryTitle="From Inventory Inspection"
                    />
                </SideDrawer>
            </Container>
        </SideDrawerContainer>
    )
}

interface ActionItemRowProps {
    workorder: BaseWorkorder
    onClick: () => void
    isSelected: boolean
    openMessageDrawer: () => void
    drawerOpen?: boolean
    EZNowSelected?: boolean
    user?: User
    transitionWorkorder: (
        workorder: BaseWorkorder,
        status: WorkorderStatus,
    ) => Promise<void>
}

const ActionItemRow = (props: ActionItemRowProps) => {
    const {
        workorder,
        isSelected,
        openMessageDrawer,
        drawerOpen,
        user,
        EZNowSelected,
    } = props

    const theme = useTheme()
    const classes = useStyles()

    const location = `${workorder.path ?? ''}${workorder.folder_name ?? ''}`

    const unitName = workorder.unit_name ?? ''

    const priorityIcon =
        (workorder as any).priority === 1 ? (
            <Tooltip title="Priority">
                <ReportProblem color="secondary" fontSize="small" />
            </Tooltip>
        ) : null

    // let tenantName = undefined
    // let serviceRequestIcon = null
    // let serviceRequest = null
    // if (workorder.service_request) {
    //     serviceRequestIcon = (
    //         <Tooltip title="Service Request">
    //             <ServiceRequestIcon />
    //         </Tooltip>
    //     )
    //     tenantName =
    //         serviceRequestMap[workorder.service_request]?.lease.user.name
    //     serviceRequest = serviceRequestMap[workorder.service_request]
    // }

    const openDrawerAdjustment = drawerOpen ? `- ${DRAWER_W}px` : ''
    const rowWidthCalc = `calc(100vw - ${LEFT_COL_W} - ${LEFT_COL_W} - ${PADDING} - ${PADDING} - ${LEFT_TOOL_BAR_W} ${openDrawerAdjustment})`

    let backgroundColor = ''
    if (isSelected) {
        backgroundColor = theme.palette.grey[300]
    }
    if (EZNowSelected) {
        backgroundColor = backgroundColor = mix_hexes(
            theme.palette.secondary.main,
            '#ffffff',
        )
    }

    const serviceIcon = getServiceImageDetails(workorder.service_id ?? -1)

    let serviceComponent: JSX.Element | null = <span>No Service</span>
    if (serviceIcon.src !== null) {
        serviceComponent = (
            <Container>
                <img
                    src={serviceIcon.icon}
                    style={{
                        maxHeight: 25,
                        maxWidth: 27,
                        minHeight: 25,
                        minWidth: 27,
                        marginRight: theme.spacing(1),
                    }}
                />
                <span>{workorder.service_name ?? ''}</span>
            </Container>
        )
    }

    let EZNowIcon: JSX.Element | null = null
    if (workorder.active_rfp && workorder.rfp_type === RFPType.EZNOW) {
        EZNowIcon = (
            <Tooltip title="EZNow">
                <EZTurnLogo style={{ height: 30, width: 30 }} />
            </Tooltip>
        )
    }

    return (
        <Container
            style={{
                margin: theme.spacing(1),
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.palette.grey[600]}`,
                minHeight: 240,
                maxHeight: 240,
                overflowX: 'auto',
                cursor: 'pointer',
                backgroundColor: backgroundColor,
            }}
            // className={classes.hoverGrey300}
            // onClick={handleSelect}
            className={classes.hoverGrey200}
            onClick={props.onClick}
        >
            {/* Left Column */}
            <Container
                direction="column"
                style={{
                    padding: theme.spacing(2),
                    fontWeight: theme.typography.fontWeightBold,
                    ...theme.typography.body1,
                    minWidth: LEFT_COL_W,
                    maxWidth: LEFT_COL_W,
                }}
            >
                <span
                    style={{
                        fontWeight: theme.typography.fontWeightBold,
                        ...theme.typography.h6,
                    }}
                >
                    {workorder.title}
                </span>

                <span
                    style={{ fontWeight: theme.typography.fontWeightRegular }}
                >
                    {location}
                </span>
                <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                    {unitName}
                </span>

                <span>{workorder.vendor_name}</span>
                {serviceComponent}

                <WorkorderTransitionButtons
                    workorder={workorder}
                    user={user}
                    style={{
                        margin: 0,
                        justifyContent: 'flex-start',
                        marginTop: theme.spacing(1),
                    }}
                    handleTransition={(e, workorder, status) => {
                        e.stopPropagation()
                        props
                            .transitionWorkorder(workorder, status)
                            .then(() => toast.success('Success'))
                    }}
                />

                <div style={{ flex: 1 }} />

                <Container
                    style={{
                        alignItems: 'center',
                    }}
                >
                    <WorkorderStatusBadge workorder={workorder} theme={theme} />

                    <Container flex={1} />
                    <Container style={{ width: '45px' }}>{EZNowIcon}</Container>
                    <Container style={{ width: '30px' }}>
                        {priorityIcon}
                    </Container>
                    <Container>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                openMessageDrawer()
                            }}
                        >
                            <Badge
                                badgeContent={workorder.message_count}
                                color={
                                    (workorder.unread_count ?? 0) > 0
                                        ? 'primary'
                                        : 'secondary'
                                }
                            >
                                <ChatBubbleOutline />
                            </Badge>
                        </IconButton>
                    </Container>
                </Container>
            </Container>

            {/* Middle Column */}
            <Container
                style={{
                    borderLeft: `1px solid ${theme.palette.grey[600]}`,
                    borderRight: `1px solid ${theme.palette.grey[600]}`,
                    flex: 1,
                    padding: theme.spacing(2),
                    minWidth: MID_COL_W,
                    maxWidth: MID_COL_W,
                    overflow: 'auto',
                }}
                direction="column"
            >
                <Container
                    style={{
                        marginBottom: theme.spacing(2),
                        fontWeight: 600,
                        fontSize: '18px',
                    }}
                >
                    {/* {tenantName} */}
                    {workorder.vendor_name ?? workorder.user_name ?? 'My Team'}
                </Container>
                {/* {serviceRequest && (
                    <Container direction="column">
                        <Container
                            style={{ fontSize: '16px', fontWeight: 500 }}
                        >
                            Tenant Description:
                        </Container>
                        <Container
                            style={{ fontSize: '15px', fontWeight: 400 }}
                        >
                            {serviceRequest?.description}
                        </Container>
                    </Container>
                )} */}
                {/* {serviceRequest?.entry_note && (
                    <Container
                        direction="column"
                        style={{ marginTop: theme.spacing(2) }}
                    >
                        <Container
                            style={{ fontSize: '16px', fontWeight: 500 }}
                        >
                            Entry Note:
                        </Container>
                        <Container
                            style={{ fontSize: '15px', fontWeight: 400 }}
                        >
                            {serviceRequest.entry_note}
                        </Container>
                    </Container>
                )} */}
            </Container>

            {/* Right Column */}
            <Container
                style={{
                    padding: theme.spacing(1),
                    paddingBottom: 0,
                    paddingRight: 0,
                    alignItems: 'center',
                    overflowX: 'auto',
                    maxWidth: rowWidthCalc,
                }}
            >
                {/* {serviceRequest &&
                    serviceRequest.message.attachments.map((attachment) => {
                        return (
                            <div key={`ATTACHMENT_${attachment.id}`} style={{}}>
                                <Attachment
                                    attachment={attachment.file}
                                    uploaded={attachment.uploaded}
                                    theme={theme}
                                />
                            </div>
                        )
                    })} */}
                {workorder.attachment_list?.map((attachment) => {
                    return (
                        <div key={`ATTACHMENT_${attachment.id}`} style={{}}>
                            <Attachment
                                attachment={attachment.file}
                                uploaded={attachment.uploaded}
                                theme={theme}
                            />
                        </div>
                    )
                })}
            </Container>
        </Container>
    )
}
