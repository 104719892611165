import React, { useEffect, useState } from 'react'

import {
    _useLease,
    useAppDispatch,
    useAptConfig,
    useAreaConfig,
    useAreaStatusTag,
    useCompany,
    useSchedule,
    useService,
    useUser,
} from '../../hooks'
import { useLocation, useParams } from 'react-router-dom'
import {
    GET_AREA_STATUS_CONFIG_REQUEST,
    GET_CHANGE_ORDER_CONFIG_LIST_REQUEST,
    GET_UNIT_CONFIG_LIST_REQUEST,
    getInfrastructure,
    setWorkSpaceUser,
} from '../../store'
import { CircularProgress, useTheme } from '@material-ui/core'
import { Container } from '../../components'
import { Scheduler } from './Scheduler'
import { User } from '../../models'

export const SchedulerRoot = () => {
    const { rootUser } = useUser()
    const dispatch = useAppDispatch()
    const location = useLocation()

    const [loadData, setLoadData] = useState(false)

    const queryParams = new URLSearchParams(location.search)
    const apartment = queryParams.get('apartment')

    useEffect(() => {
        if (apartment) {
            const apartmentId = parseInt(apartment)
            const newWsUser = rootUser?.workspaces.find(
                (wsUser) => wsUser.active_workspace.id === apartmentId,
            )

            if (newWsUser) {
                dispatch(setWorkSpaceUser(newWsUser))
                dispatch(
                    getInfrastructure({
                        apartmentId: newWsUser.active_workspace.id,
                    }),
                )
            }
        }
        setLoadData(true)
    }, [apartment])

    if (loadData && rootUser) {
        return <_SchedulerRoot rootUser={rootUser} />
    } else {
        return <div />
    }
}

const _SchedulerRoot = (props: { rootUser: User }) => {
    const theme = useTheme()

    const { id } = useParams<{ id?: string }>()

    const { scheduleDetail, loadingDetail } = useSchedule({
        cleanUp: true,
        getScheduleDetail: parseInt(id ?? '-1'),
    })

    const astController = useAreaStatusTag({
        getListForSchedule: parseInt(id ?? '-1'),
        cleanUp: true,
    })

    const { serviceList, isLoading: serviceListLoading } = useService({
        getServiceList: true,
        cleanUp: true,
    })
    const { vendorList, vendorsLoading } = useCompany({
        getVendorList: true,
        cleanUp: true,
    })

    const {
        getAreaConfigs,
        areaConfigMap,
        loading: areaConfigLoading,
    } = useAreaConfig()

    const aptConfigController = useAptConfig({
        changeOrderConfigList: true,
        unitConfigList: true,
        areaStatusConfigList: true,
        cleanUp: true,
    })

    const leaseController = _useLease({
        cleanUp: true,
        getDamageListForInspecitonId: scheduleDetail?.inspection ?? -1,
    })

    useEffect(() => {
        getAreaConfigs({ shows_on_schedule: true })
    }, [])

    const getLoadingState = () => {
        if (aptConfigController.loadingState[GET_UNIT_CONFIG_LIST_REQUEST]) {
            return [true, 'Unit Configs']
        }

        if (
            aptConfigController.loadingState[
                GET_CHANGE_ORDER_CONFIG_LIST_REQUEST
            ]
        ) {
            return [true, 'Change Order Configs']
        }

        if (serviceListLoading) {
            return [true, 'Services']
        }

        if (vendorsLoading) {
            return [true, 'Vendors']
        }

        if (aptConfigController.loadingState[GET_AREA_STATUS_CONFIG_REQUEST]) {
            return [true, 'Area Status Configs']
        }

        if (loadingDetail) {
            return [true, 'Schedule Details']
        }

        if (areaConfigLoading) {
            return [true, 'Area Configs']
        }

        if (leaseController.leaseListLoading) {
            return [true, 'Leases']
        }

        return [false, null]
    }

    const loadingState = getLoadingState()
    if (loadingState[0]) {
        return (
            <Container
                style={{
                    flex: 1,
                    width: '100%',
                    height: 'calc(100vh - 104px)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <CircularProgress size={100} />
                <span
                    style={{
                        ...theme.typography.h5,
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.grey[700],
                    }}
                >
                    {loadingState[1]}
                </span>
            </Container>
        )
    }

    if (scheduleDetail === undefined) {
        return <span>invalid schedule</span>
    }

    return (
        <Scheduler
            rootUser={props.rootUser}
            theme={theme}
            scheduleDetail={scheduleDetail}
            aptConfigController={aptConfigController}
            astController={astController}
            leaseController={leaseController}
            serviceList={serviceList}
            vendorList={vendorList}
            areaConfigMap={areaConfigMap}
        />
    )
}
