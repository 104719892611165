import {
    Button,
    Divider,
    IconButton,
    Modal,
    Paper,
    Slide,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { Container } from '../../components'
import CloseIcon from '@material-ui/icons/Close'
import { useStyles } from '../../styles'
import { ReactComponent as DownSymbol } from '../../assets/arrow-down-circle.svg'
import { axiosInstance } from '../../helpers'
import { useStripe } from '../../hooks/useStripe'

interface Props {
    open: boolean
    handleClose: () => void
}

const ZERO = 0
const FIVE = 5
const TEN = 10
const FIFTY = 50
const ONE_HUNDRED = 100

export const EZTokenModal = (props: Props) => {
    const { open, handleClose } = props

    const { createStripeCheckoutSession } = useStripe()

    const theme = useTheme()
    const classes = useStyles()

    const [tokenAmount, setTokenAmount] = useState<number>(ZERO)

    const tokenStyle: React.CSSProperties = {
        height: 90,
        width: 110,
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '40px',
        fontWeight: 600,
        margin: theme.spacing(1),
        cursor: 'pointer',
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'auto',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: '600px',
                        height: '450px',
                        maxHeight: 'calc(100vh - 100px)',
                        maxWidth: 'calc(100vh - 100px)',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    {/* Header */}
                    <Container
                        style={{
                            alignItems: 'center',
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 500,
                                fontSize: '20px',
                                flex: 1,
                            }}
                        >
                            Purchase Tokens
                        </span>
                        <Tooltip title="Cancel">
                            <IconButton
                                onClick={() => {
                                    handleClose()
                                }}
                            >
                                <CloseIcon
                                    fontSize="inherit"
                                    style={{
                                        marginRight: theme.spacing(1),
                                        color: theme.palette.darkGreen.main,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Container>
                    <Divider />
                    {/* Body */}
                    <Container direction="column">
                        <Container
                            flexWrap="wrap"
                            justifyContent="center"
                            alignItems="center"
                            style={{ margin: theme.spacing(3, 0, 3, 0) }}
                        >
                            <Container
                                style={{
                                    ...tokenStyle,
                                    backgroundColor:
                                        tokenAmount === FIVE
                                            ? theme.palette.grey[300]
                                            : '',
                                }}
                                className={classes.hoverGrey300}
                                onClick={() => setTokenAmount(FIVE)}
                            >
                                5
                            </Container>
                            <Container
                                style={{
                                    ...tokenStyle,
                                    backgroundColor:
                                        tokenAmount === TEN
                                            ? theme.palette.grey[300]
                                            : '',
                                }}
                                className={classes.hoverGrey300}
                                onClick={() => setTokenAmount(TEN)}
                            >
                                10
                            </Container>
                            <Container
                                style={{
                                    ...tokenStyle,
                                    backgroundColor:
                                        tokenAmount === FIFTY
                                            ? theme.palette.grey[300]
                                            : '',
                                }}
                                className={classes.hoverGrey300}
                                onClick={() => setTokenAmount(FIFTY)}
                            >
                                50
                            </Container>
                            <Container
                                style={{
                                    ...tokenStyle,
                                    backgroundColor:
                                        tokenAmount === ONE_HUNDRED
                                            ? theme.palette.grey[300]
                                            : '',
                                }}
                                className={classes.hoverGrey300}
                                onClick={() => setTokenAmount(ONE_HUNDRED)}
                            >
                                100
                            </Container>
                        </Container>
                        <Container alignItems="center">
                            <Divider style={{ flex: 1 }} />
                            <DownSymbol
                                style={{ margin: theme.spacing(0, 1, 0, 1) }}
                            />
                            <Divider style={{ flex: 1 }} />
                        </Container>
                        <Container
                            style={{
                                margin: theme.spacing(4, 0, 4, 0),
                                color: theme.palette.primary.dark,
                                fontSize: '25px',
                                fontWeight: 500,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <span>$</span>
                            <span>{tokenAmount * 2}</span>
                            <span>.00</span>
                        </Container>
                    </Container>
                    {/* Footer */}
                    <Container justifyContent="flex-end">
                        <Button
                            variant="contained"
                            style={{
                                margin: theme.spacing(2),
                                backgroundColor: '#008C85',
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                const body = {
                                    product: tokenAmount,
                                    success_url: window.location.href,
                                }
                                createStripeCheckoutSession(body)
                            }}
                            disabled={tokenAmount === 0}
                        >
                            Purchase Tokens
                        </Button>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
