import React, { useEffect, useRef, useState } from 'react'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Typography,
    useTheme,
} from '@material-ui/core'
import { Autorenew } from '@material-ui/icons'
import {
    CompositeDecorator,
    ContentBlock,
    ContentState,
    EditorState,
} from 'draft-js'
import { Container, SideDrawerContainer } from '../../../components'

import { useChannelList } from '../../../hooks/useChannel'
import { useMessage } from '../../../hooks/useMessage'
import { Mention } from '../../../components/RichTextEditor/DecoratorComponents/Mention'
import { useAppDispatch, useUser } from '../../../hooks'

import { Company, ListChannel } from '../../../models'
import {
    getMessagingUserOptionList,
    getVendorList,
    RootState,
    setCurrentChannel,
} from '../../../store'
import { useSelector } from 'react-redux'

import { makeStyles, createStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

export const useStyles = makeStyles((theme) =>
    createStyles({
        heading: {
            fontWeight: 600,
            fontSize: '1.25rem',
            lineHeight: '23px',
            color: '#000000',
        },
        addActionItemTitle: {
            flexBasis: '33.33%',
            flexShrink: 0,
            flex: '1 1 0%',
        },
        channelAccordian: {
            boxShadow: 'none',
            background: 'transparent',
            '& .MuiAccordionSummary-root': {
                minHeight: 'unset',
            },
            '& .MuiAccordionSummary-content': {
                display: 'flex',
                alignItems: 'center',
            },
            '& .MuiAccordionDetails-root': {
                padding: '0 16px',
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '12px 0',
                '& .MuiSvgIcon-root': {
                    '-webkit-transform': 'rotate(90deg)',
                    transform: 'rotate(90deg)',
                },
            },
        },
        channelList: {
            width: '100%',
            padding: 0,
            maxHeight: '200px',
            overflowY: 'auto',
        },
        directUserList: {
            width: '100%',
            padding: 0,
            maxHeight: '400px',
            overflowY: 'auto',
        },
        /********* Old ********* */
        fontLarge: {
            fontSize: '40px',
        },
        fontPrimaryMain: {
            color: theme.palette.primary.main,
        },
        fontPrimaryDark: {
            color: theme.palette.primary.dark,
        },
        fontHoverPrimaryDark: {
            '&:hover': {
                color: theme.palette.primary.dark,
            },
        },
        fontHoverPrimary: {
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        fontHoverContrast: {
            '&:hover': {
                color: theme.palette.primary.contrastText,
            },
        },
        fontBrand1: {
            color: theme.palette.brand1.main,
        },
        fontHoverBrand1: {
            '&:hover': {
                color: theme.palette.brand1.main,
            },
        },
        fontSecondaryMain: {
            color: theme.palette.secondary.main,
        },
        fontHoverSecondaryMain: {
            '&:hover': {
                color: theme.palette.secondary.main,
            },
        },
        fontHoverSecondaryDark: {
            '&:hover': {
                color: theme.palette.secondary.dark,
            },
        },
        fontHoverGrey600: {
            '&:hover': {
                color: theme.palette.grey[600],
            },
        },
        hoverGrey200: {
            '&:hover': {
                backgroundColor: theme.palette.grey[200],
            },
        },
        hoverGrey300: {
            '&:hover': {
                backgroundColor: theme.palette.grey[300],
            },
        },
        hoverPrimary: {
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        hoverDarkPrimary: {
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
        hoverDarkSecondary: {
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
        },
        circle: {
            borderRadius: '50%',
        },
        boxShadow: {
            '&:hover': {
                boxShadow: theme.shadows[5],
            },
        },
        highlightCircle: {
            backgroundColor: theme.palette.grey[300],
            borderRadius: '50%',
        },
        highlightCircleOnHover: {
            '&:hover': {
                backgroundColor: theme.palette.grey[300],
                borderRadius: '50%',
            },
        },
        box60: {
            minHeight: '60px',
            maxHeight: '60px',
            minWidth: '60px',
            maxWidth: '60px',
        },
        box50: {
            minHeight: '50px',
            maxHeight: '50px',
            minWidth: '50px',
            maxWidth: '50px',
        },
        box100: {
            height: '100px',
            width: '100px',
        },
        box120: {
            height: '120px',
            width: '120px',
        },
        centerBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'none',
        },
        wrap: {
            flexWrap: 'wrap',
        },
        table: {
            minWidth: 650,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        checkBox: {
            color: theme.palette.common.black,
            '&$checked': {
                color: theme.palette.primary.main,
            },
        },
        showOnHover: {
            opacity: 0,
            '&:hover': {
                opacity: 1,
            },
        },
        hoverShadow: {
            '&:hover': {
                boxShadow: theme.shadows[5],
            },
        },
    }),
)

/**************** NEW IMPORT  [START] **************************/

import CreateChannelDialogBox from '../../components/dialog-box/DialogBox'
import CreateConversationDialogBox from '../../components/dialog-box/DialogBox'
import AddMemberDialogBox from '../../components/dialog-box/DialogBox'
import MemberListDialogBox from '../../components/dialog-box/DialogBox'
import AddCompanyDialogBox from '../../components/dialog-box/DialogBox'
import { CreateChannelModal } from './CreateChannelModal'
import { CreateConversation } from './CreateConversation'
import { MemberList } from './MemberList'
import { AddMember } from './AddMember'
import AddIcon from '@material-ui/icons/Add'
import { ActionItemDrawer } from '../../components/ActionItemDrawer'
import { MessageTextEditor } from '../../components/messageTextEditor'
import { ChatHistory } from '../../components/chat-history'

import { ReactComponent as AddMemberIcon } from '../../../assets/icons/user-plus.svg'
import { ReactComponent as AddCompanyIcon } from '../../../assets/icons/company-icon.svg'
import { AddCompany } from './AddCompany'
import { axiosInstance } from '../../../helpers'

/**************** NEW IMPORT  [END] **************************/

type DecoratorStrategy = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
) => void

const mentionStrategy: DecoratorStrategy = (contentBlock, callback) => {
    contentBlock.findEntityRanges((character) => {
        return character.getEntity() !== null
    }, callback)
}

const compositeDecorator = new CompositeDecorator([
    {
        strategy: mentionStrategy,
        component: Mention,
    },
])

export const EZChat = () => {
    /************************************ NEW CODE [START]*********************/
    const [openCreateChannelModal, setOpenCreateChannelModal] = useState(false)
    const [
        openCreateConversationModal,
        setOpenCreateConversationModal,
    ] = useState(false)
    const [openMemberListModal, setOpenMemberListModal] = useState(false)
    const [openAddMemberModal, setOpenAddMemberModal] = useState(false)
    const [addActionItemDrawerOpen, setAddActionItemDrawerOpen] = useState(
        false,
    )
    const [openAddCompanyModal, setOpenAddCompanyModal] = useState(false)

    const handleToggleAddCompanyModal = () => {
        setOpenAddCompanyModal(!openAddCompanyModal)
    }
    const handleToggleCreateChannelModal = () => {
        setOpenCreateChannelModal(!openCreateChannelModal)
    }

    const handleToggleCreateConversationModal = () => {
        setOpenCreateConversationModal(!openCreateConversationModal)
    }

    const handleToggleMemberListModal = () => {
        setOpenMemberListModal(!openMemberListModal)
    }

    const handleToggleAddMemberModal = () => {
        setOpenAddMemberModal(!openAddMemberModal)
    }

    const toggleAddActionItemDrawerOpen = () => {
        setAddActionItemDrawerOpen(!addActionItemDrawerOpen)
    }

    /************************************ NEW CODE [START]*********************/

    const {
        currentChannel,
        channelList,
        createChannel,
        addMember,
        removeMember,
        getChannelList,
        clearChannelUnread,
        getChannelDetail,
    } = useChannelList()

    const { resetMessageState, getMessageList, messageList } = useMessage()

    const { workspaceUser } = useUser()

    const messagingUserOptionList = useSelector(
        (state: RootState) => state.messaging.messagingUserOptionList,
    )

    const dispatch = useAppDispatch()
    const theme = useTheme()
    const classes = useStyles()

    const endOfMsgRef = useRef<HTMLDivElement>(null)

    const headerStyle: React.CSSProperties = {
        padding: theme.spacing(0, 1),
        height: 70,
        minHeight: 70,
        borderBottom: `3px solid #C2C2C2`,
    }

    const [generalChannels, setGeneralChannels] = useState<ListChannel[]>([])

    const [newChannelMembers, setNewChannelMembers] = useState<number[]>([])

    useEffect(() => {
        dispatch(getMessagingUserOptionList())
        dispatch(getVendorList({ params: { my_team: false } }))
        axiosInstance
            .get('messaging/channel/', {
                params: {
                    channel_type: 'APARTMENT_VENDOR',
                },
            })
            .then((res) => {
                setGeneralChannels(res.data)
            })
        return () => {
            dispatch(setCurrentChannel())
        }
    }, [])

    const vendorList = useSelector(
        (state: RootState) => state.company.vendorList,
    )

    useEffect(() => {
        resetMessageState()

        if (currentChannel) {
            if (
                channelList.find(
                    (channel) => channel.id === currentChannel.id,
                ) ||
                generalChannels.find(
                    (channel) => channel.id === currentChannel.id,
                )
            ) {
                getMessageList({ channelId: currentChannel.id })
                return
            }
        }

        if (channelList.length > 0) {
            getChannelDetail({ channelId: channelList[0].id })
            getMessageList({ channelId: channelList[0].id })
        }
    }, [currentChannel])

    useEffect(() => {
        // Scroll to end of messages
        endOfMsgRef.current && endOfMsgRef.current.scrollIntoView()
    }, [messageList])

    const handleNewChannelMembersChange = (
        event: React.ChangeEvent<{ value: unknown }>,
    ) => {
        const arr = (event.target.value as (number | undefined)[]).reduce<
            number[]
        >((prev, val) => {
            if (val === undefined) return prev
            return prev.concat(val)
        }, [])

        setNewChannelMembers(arr)
    }

    let mainContent = null

    const membersInChannelCount = currentChannel
        ? currentChannel.members.reduce((accum, member) => {
              if (member.member_type === 'user') {
                  return accum + 1
              }
              const comp = member.member as Company

              return accum + (comp.employees ? comp.employees?.length : 0)
          }, 0)
        : 0

    const getChannelName = (name: string) => {
        if (name.includes('||')) {
            return name.split('|| ')[1]
        } else {
            return name
        }
    }

    if (currentChannel) {
        const safeMessageList = messageList ? messageList : []
        let selectedChannelName = currentChannel.pair
            ? currentChannel.pair_user
            : currentChannel.name

        selectedChannelName = getChannelName(selectedChannelName)

        mainContent = (
            <Container
                direction="column"
                flex={1}
                style={{
                    borderLeft: `3px solid #C2C2C2`,
                }}
            >
                {/* Title Area */}
                <Container alignItems="center" style={headerStyle}>
                    <Typography component="span" className={classes.heading}>
                        {selectedChannelName}
                    </Typography>
                    <span
                        style={{
                            marginLeft: theme.spacing(1),
                            ...theme.typography.caption,
                        }}
                    >
                        {safeMessageList.length} Messages
                    </span>

                    <Container flex={1} />
                    <IconButton
                        onClick={() => {
                            getChannelList({ params: { in_ezchat: true } })
                            resetMessageState()
                            getMessageList({ channelId: currentChannel.id })
                        }}
                    >
                        <Autorenew
                            style={{
                                cursor: 'pointer',
                                color: theme.palette.primary.main,
                            }}
                        />
                    </IconButton>

                    {!currentChannel.pair && (
                        // Group channels only
                        // Member synopsis + add member button
                        <Container>
                            <Container
                                alignItems="center"
                                style={{
                                    cursor: 'pointer',
                                    paddingRight: theme.spacing(1),
                                }}
                            >
                                <IconButton
                                    aria-label="add"
                                    size="small"
                                    style={{
                                        marginLeft: '10px',
                                    }}
                                    onClick={() => setOpenAddCompanyModal(true)}
                                >
                                    <AddCompanyIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="add"
                                    size="small"
                                    style={{
                                        marginLeft: '10px',
                                    }}
                                    onClick={() => setOpenAddMemberModal(true)}
                                >
                                    <AddMemberIcon />
                                </IconButton>
                            </Container>
                            <Container
                                alignItems="flex-end"
                                style={{ paddingRight: theme.spacing(0.5) }}
                            >
                                <Button
                                    size="small"
                                    style={{
                                        marginLeft: '10px',
                                        textTransform: 'inherit',
                                        color: '#02635E',
                                        fontSize: '0.938rem',
                                        fontWeight: 500,
                                        lineHeight: '16px',
                                        padding: '10px',
                                        borderRadius: '3px',
                                    }}
                                    onClick={() => setOpenMemberListModal(true)}
                                >
                                    {`${
                                        currentChannel &&
                                        currentChannel.members.length
                                    } Members`}
                                </Button>
                            </Container>
                        </Container>
                    )}
                </Container>

                {/* Content Area */}
                <Container
                    direction="column"
                    flex={1}
                    style={{
                        borderRadius: `${theme.shape.borderRadius} 0 0 0`,
                        height: '0',
                    }}
                >
                    {/* Message Display*/}
                    <Container
                        flex={1}
                        direction="column"
                        scrollY
                        style={{ paddingTop: theme.spacing(1) }}
                    >
                        <div id="chatMessageArea">
                            <ChatHistory
                                messageList={safeMessageList}
                                workspaceUser={workspaceUser}
                            />
                        </div>

                        <div ref={endOfMsgRef} />
                    </Container>

                    {/* New Message Area */}
                    <Container
                        style={{
                            borderTop: `3px solid #C2C2C2`,
                            padding: theme.spacing(0, 0, 1, 1),
                        }}
                    >
                        <MessageTextEditor channelId={currentChannel?.id} />
                    </Container>
                </Container>
            </Container>
        )
    }

    const userDMChoices: any = []
    const addToChannelChoices: any = []

    // add the rest of the companies
    let prevCompId = -1

    // List of users sorted by workspace
    messagingUserOptionList?.forEach((msgOptionUser) => {
        const isActiveWorkspace =
            msgOptionUser.active_workspace?.id ===
            workspaceUser?.active_workspace?.id

        // see if we need a new section header
        if (msgOptionUser.active_workspace?.id !== prevCompId) {
            // If this users company is in the workspace users company,
            // ignore this label.  We will add it after the function
            if (!isActiveWorkspace) {
                // The workspace just changed, add a new section header to the jsx arrays
                userDMChoices.push(msgOptionUser)
                addToChannelChoices.push(msgOptionUser)

                // update the prevCompId
                prevCompId = msgOptionUser.active_workspace?.id ?? -1
            }
        }

        const arrayMethod = isActiveWorkspace ? 'unshift' : 'push'

        // Decide if we should display the user as a DM option
        if (
            msgOptionUser.id !== workspaceUser?.id &&
            channelList.find((ch) => ch.pair_user === msgOptionUser.name) ===
                undefined
        ) {
            // We do not already have a DM with the user
            // Add them as an option
            userDMChoices[arrayMethod](msgOptionUser)
        }

        // Decide if this user is already in the "current channel"
        if (
            msgOptionUser.id !== workspaceUser?.id &&
            currentChannel?.members.find(
                (mem) =>
                    mem.member_type === 'user' &&
                    mem.member.id === msgOptionUser.id,
            ) === undefined
        ) {
            // This user is not in "current channel"
            // Add this user to the options list

            addToChannelChoices[arrayMethod](msgOptionUser)
        }
    })

    userDMChoices.unshift(workspaceUser?.active_workspace)
    addToChannelChoices.unshift(workspaceUser?.active_workspace)

    console.log('ezchat final user dm choices....', userDMChoices)
    console.log('ezchat final add to member choice....', addToChannelChoices)

    const selectedChannelName = currentChannel
        ? currentChannel?.pair
            ? currentChannel?.pair_user
            : currentChannel?.name
        : ''

    const channelMessages = (
        <Accordion className={classes.channelAccordian} defaultExpanded>
            <AccordionSummary
                aria-controls="channel-content"
                id="channel-header"
            >
                <ChevronRightIcon />
                <Typography
                    className={`${classes.heading} ${classes.addActionItemTitle}`}
                >
                    Channel
                </Typography>
                <IconButton
                    aria-label="add"
                    size="small"
                    onClick={() => handleToggleCreateChannelModal()}
                >
                    <AddIcon />
                </IconButton>
            </AccordionSummary>
            <AccordionDetails>
                <List dense={true} className={classes.channelList}>
                    {channelList && channelList.length > 0
                        ? channelList.map((channel: any, index: number) =>
                              !channel.pair ? (
                                  <ListItem
                                      button
                                      key={`channel-${index}`}
                                      onClick={() => {
                                          getChannelDetail({
                                              channelId: channel.id,
                                          })
                                          clearChannelUnread(channel.id)
                                      }}
                                      selected={
                                          selectedChannelName === channel.name
                                      }
                                  >
                                      <ListItemText primary={channel.name} />
                                  </ListItem>
                              ) : null,
                          )
                        : null}
                </List>
            </AccordionDetails>
        </Accordion>
    )

    const directMessages = (
        <Accordion className={classes.channelAccordian}>
            <AccordionSummary
                aria-controls="directbh-content"
                id="directbh-header"
            >
                <ChevronRightIcon />
                <Typography
                    className={`${classes.heading} ${classes.addActionItemTitle}`}
                >
                    Direct
                </Typography>
                <IconButton
                    aria-label="add"
                    size="small"
                    onClick={() => handleToggleCreateConversationModal()}
                >
                    <AddIcon />
                </IconButton>
            </AccordionSummary>
            <AccordionDetails>
                <List dense={true} className={classes.directUserList}>
                    {channelList && channelList.length > 0
                        ? channelList.map((channel: any, index: number) =>
                              channel.pair && channel.pair_user ? (
                                  <ListItem
                                      button
                                      key={`channel-${index}`}
                                      onClick={() => {
                                          getChannelDetail({
                                              channelId: channel.id,
                                          })
                                          clearChannelUnread(channel.id)
                                      }}
                                      selected={
                                          selectedChannelName ===
                                          channel.pair_user
                                      }
                                  >
                                      <ListItemText
                                          primary={channel.pair_user}
                                      />
                                  </ListItem>
                              ) : null,
                          )
                        : null}
                </List>
            </AccordionDetails>
        </Accordion>
    )

    const generalMessages = (
        <Accordion className={classes.channelAccordian}>
            <AccordionSummary
                aria-controls="primary-content"
                id="primary-header"
            >
                <ChevronRightIcon />
                <Typography
                    className={`${classes.heading} ${classes.addActionItemTitle}`}
                >
                    General Messages
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List dense={true} className={classes.directUserList}>
                    {generalChannels.length > 0
                        ? generalChannels.map((channel: any, index: number) => (
                              <ListItem
                                  button
                                  key={`channel-${index}`}
                                  onClick={() => {
                                      getChannelDetail({
                                          channelId: channel.id,
                                      })
                                      const tempGeneralChannels = generalChannels.map(
                                          (ch) => {
                                              if (ch.id === channel.id) {
                                                  return {
                                                      ...ch,
                                                      unread_count: 0,
                                                  }
                                              }
                                              return ch
                                          },
                                      )
                                      setGeneralChannels(tempGeneralChannels)
                                  }}
                                  selected={
                                      selectedChannelName === channel.name
                                  }
                              >
                                  <Badge
                                      badgeContent={channel.unread_count}
                                      color="secondary"
                                  >
                                      <ListItemText
                                          primary={getChannelName(channel.name)}
                                      />
                                  </Badge>
                              </ListItem>
                          ))
                        : null}
                </List>
            </AccordionDetails>
        </Accordion>
    )

    return (
        <SideDrawerContainer open={addActionItemDrawerOpen} width={330}>
            <Container
                justifyContent="flex-start"
                flex={1}
                style={{ overflow: 'hidden', height: 'calc(100vh - 56px)' }}
            >
                {/* channel/dm selector */}
                <Container direction="column" style={{ width: '250px' }}>
                    {/* channel container */}
                    <Container
                        style={{ ...headerStyle, padding: '0px 14px' }}
                        alignItems="center"
                    >
                        <Typography
                            className={`${classes.heading} ${classes.addActionItemTitle}`}
                            component="span"
                        >
                            Add Action Item
                        </Typography>
                        <IconButton
                            aria-label="add"
                            size="small"
                            onClick={() => setAddActionItemDrawerOpen(true)}
                        >
                            <AddIcon />
                        </IconButton>
                    </Container>
                    <Accordion
                        className={classes.channelAccordian}
                        defaultExpanded
                    >
                        <AccordionSummary
                            aria-controls="primary-content"
                            id="primary-header"
                        >
                            <ChevronRightIcon />
                            <Typography
                                className={`${classes.heading} ${classes.addActionItemTitle}`}
                            >
                                Primary Messages
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Container direction="column">
                                <Container direction="column">
                                    {channelMessages}
                                </Container>
                                <Container direction="column">
                                    {directMessages}
                                </Container>
                            </Container>
                        </AccordionDetails>
                    </Accordion>

                    {generalMessages}
                </Container>
                {/* main content */}
                {mainContent}
            </Container>

            <MemberListDialogBox
                open={openMemberListModal}
                handleClose={handleToggleMemberListModal}
                dialogContent={
                    <MemberList
                        handleClose={handleToggleMemberListModal}
                        memberships={currentChannel && currentChannel.members}
                        removeMember={removeMember}
                        currentChannelId={currentChannel && currentChannel?.id}
                    />
                }
                dialogHeader={
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ flex: '1 1 0%' }}>
                            {`${
                                currentChannel && currentChannel.members.length
                            } members in ${
                                currentChannel && currentChannel.name
                            }`}
                        </span>
                        <Button
                            startIcon={<AddIcon />}
                            style={{
                                background: '#008C85',
                                color: '#FFF',
                                fontSize: '0.875rem',
                                textTransform: 'inherit',
                                fontWeight: 500,
                                borderRadius: '5px',
                                boxShadow: 'none',
                            }}
                            onClick={() => handleToggleAddMemberModal()}
                        >
                            Add Member
                        </Button>
                    </span>
                }
                fullWidth={true}
            />
            <CreateConversationDialogBox
                open={openCreateConversationModal}
                handleClose={handleToggleCreateConversationModal}
                dialogContent={
                    <CreateConversation
                        handleClose={handleToggleCreateConversationModal}
                        userDMChoices={userDMChoices}
                        createChannel={createChannel}
                    />
                }
                dialogHeader="Start a conversation"
                fullWidth={false}
            />
            <CreateChannelDialogBox
                open={openCreateChannelModal}
                handleClose={handleToggleCreateChannelModal}
                dialogContent={
                    <CreateChannelModal
                        handleClose={handleToggleCreateChannelModal}
                        createChannel={createChannel}
                        channelList={channelList}
                    />
                }
                dialogHeader="Create Channel"
                fullWidth={false}
            />

            <AddCompanyDialogBox
                open={openAddCompanyModal}
                handleClose={handleToggleAddCompanyModal}
                dialogContent={
                    <AddCompany
                        handleClose={handleToggleAddCompanyModal}
                        currentChannel={currentChannel}
                        vendorList={vendorList}
                        addMember={addMember}
                    />
                }
                dialogHeader="Add a Company"
                fullWidth={false}
            />
            <AddMemberDialogBox
                open={openAddMemberModal}
                handleClose={handleToggleAddMemberModal}
                dialogContent={
                    <AddMember
                        handleClose={handleToggleAddMemberModal}
                        userList={addToChannelChoices}
                        addMember={addMember}
                        currentChannel={currentChannel}
                    />
                }
                dialogHeader="Invite people to the conversation"
                fullWidth={false}
            />
            <ActionItemDrawer
                open={addActionItemDrawerOpen}
                handleClose={toggleAddActionItemDrawerOpen}
                width={330}
                serviceList={[]}
                vendorList={[]}
            />
        </SideDrawerContainer>
    )
}
