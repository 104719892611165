import { Company } from '../Company'
import { Service } from '../Services'
import { BaseUser } from '../Users'
import {
    BaseModel,
    IdentifiableNamedObject,
    IdentifiableObject,
} from '../types'

export interface UnitConfig extends IdentifiableNamedObject {
    shows_on_schedule: boolean
}

export interface UnitConfigAreaCounts extends UnitConfig {
    bed_count: number | null
    bath_count: number | null
}
export interface UnitAreaConfig extends IdentifiableObject {
    area_label: string
    area_config: AreaConfig
    unit_config: number
    created_date: string
    modified_date: string
}

export enum AreaType {
    BED = 'BED',
    BATH = 'BATH',
    COM = 'COM',
}

export interface AreaConfig extends IdentifiableNamedObject {
    apartment?: Company
    occupiable: boolean
    inventory_area_configs?: InventoryAreaConfig[]
    shows_on_schedule: boolean
    area_type: AreaType
}

export interface BaseAreaConfig extends IdentifiableNamedObject {
    apartment: number
    occupiable: boolean
}

export interface InventoryAreaConfig extends IdentifiableObject {
    inventory_config: InventoryConfig
    amount: number
}

export interface ListInventoryAreaConfig {
    amount: number
    inventory_config?: InventoryConfig
    area_config?: AreaConfig
}

export interface InventoryConfig extends IdentifiableNamedObject {
    sku: string
    picture: string | null
    description: string
    file?: string
    status_group?: StatusGroup
    custom_status_list?: StatusWithCost[]
    area_config_count?: number
    ins_has_note: boolean
    inspection_score: number
}

export interface StatusWithCost extends CustomStatus {
    cost: number
}

export interface DamageConfig {
    id: number
    description: string
    cost: number
    apartment: number
    integration_id: string | null
    charge_item_description: string | null
    archived: boolean
}

export interface CustomStatus extends IdentifiableNamedObject {
    color: string
    apartment: number
    cost?: number
    causes_flag: boolean
}

export const UNINSPECTED_STATUS: CustomStatus = {
    id: -1,
    name: 'Uninspected',
    apartment: -1,
    color: '#111',
    causes_flag: false,
}

export interface StatusGroup extends IdentifiableNamedObject {
    apartment: number
    default_status?: CustomStatus
    custom_statuses?: CustomStatus[]
}

export interface IdCost extends IdentifiableObject {
    // is string or number for compatibility with other types
    cost: string | number
}

export interface InspectionType extends IdentifiableNamedObject {
    inventory_configs: InventoryConfig[]
}

export interface InventoryConfigChecked extends InventoryConfig {
    checked: boolean
}

export interface AreaStatusConfig extends IdentifiableNamedObject {
    apartment: number
    should_service: boolean
}

export interface ChangeOrderConfig extends IdentifiableNamedObject {
    description: string
    addon: boolean
    suggested_add_price: number
    upstream_price: number | null
}

export interface IssueConfig extends IdentifiableNamedObject {
    default_damage_config: DamageConfig
}

export interface InventoryIssueOption extends IdentifiableObject {
    issue_config: IssueConfig
    inventory_config: number
}

export interface ActionItemInspectionStatusTrigger extends IdentifiableObject {
    apartment_owner: Company
    service: Service | null
    assigned_by: BaseUser
    assign_to_user: BaseUser | null
    assign_to_company: Company | null
    title: string | null
    start_date_buffer_days: number
    days_to_complete: number
    priority: boolean
    inventory_config: InventoryConfig | null
    trigger_status: CustomStatus
    entrata_workorder_config: EntrataWorkOrderConfig | null
}

export interface EntrataWorkOrderConfig {
    customerId: number | null
    leaseId: number | null
    unitNumber: string | null
    customerFirstName: string | null
    customerLastName: string | null
    mainPhoneNumber: number | null
    altPhoneNumber: number | null
    emailAddress: string | null
    permissionToEnter: boolean
    maintenanceLocationId: number | null
    maintenanceCategoryId: number | null
    maintenanceProblemId: number | null
    subMaintenanceProblemId: number | null
    maintenancePriorityId: number | null
    problemDescription: string | null
    petsDescription: string | null
    buildingName: string | null
    assignedTo: string | null
    scheduledStartDateTime: string | null
    scheduledEndDateTime: string | null
    dueDate: string | null
}

export const emptyEntrataWorkorderConfig: EntrataWorkOrderConfig = {
    leaseId: null,
    customerId: null,
    unitNumber: null,
    customerFirstName: null,
    customerLastName: null,
    mainPhoneNumber: null,
    altPhoneNumber: null,
    emailAddress: null,
    permissionToEnter: false,
    maintenanceLocationId: null,
    maintenanceCategoryId: null,
    maintenanceProblemId: null,
    maintenancePriorityId: null,
    subMaintenanceProblemId: null,
    problemDescription: null,
    petsDescription: null,
    buildingName: null,
    assignedTo: null,
    scheduledStartDateTime: null,
    scheduledEndDateTime: null,
    dueDate: null,
}

export interface InventoryGroupItem extends BaseModel {
    inventory_group: number
    amount: number
    inventory_config: number
}

export interface InventoryGroup extends BaseModel {
    name: string
    inventory_configs: InventoryGroupItem[]
    apartment: number
}
