import React, { useState } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import MenuOpenIcon from '@material-ui/icons/MenuOpen'

import TopNavBar from './top-bar'
import SideBar from './sidebar'
import { useLocation } from 'react-router-dom'
import { isAccountManager } from '../../../helpers'
import { Drawer } from '@material-ui/core'
import { Container } from '../../../components'
import { AdminPanel } from '../../../components/AdminPanel/AdminPanel'
import { RegisterStep } from '../../../models'

const AdminLayout = (props: any) => {
    const [drawerWidth, setdrawerWidth] = useState(136)

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: 'flex',
                width: '100%',
            },
            appBar: {
                zIndex: theme.zIndex.drawer + 1,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
            appBarShift: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            menuButton: {
                marginRight: 36,
            },
            hide: {
                display: 'none',
            },
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
                whiteSpace: 'nowrap',
                backgroundColor: '#2f2e41',
                color: '#737378',
                zIndex: 9999,
            },
            drawerOpen: {
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                backgroundColor: '#2f2e41',
                color: '#737378',
            },
            drawerClose: {
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                overflowX: 'hidden',
                width: `${drawerWidth} !important`,
                // width: theme.spacing(7) + 1,
                [theme.breakpoints.up('sm')]: {
                    width: `${drawerWidth} !important`,
                },
                backgroundColor: '#2f2e41',
                color: '#737378',
            },
            toolbar: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: theme.spacing(0, 1),
                minHeight: '56px !important',
                // necessary for content to be below app bar
                ...theme.mixins.toolbar,
            },
            content: {
                flexGrow: 1,
                // padding: theme.spacing(3),
                width: `calc(100vw - ${drawerWidth}px)`,
                // height: '100vh',
            },
        }),
    )
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)

    const handleDrawerOpen = () => {
        setOpen(true)
        setdrawerWidth(240)
    }

    const handleDrawerClose = () => {
        setOpen(false)
        setdrawerWidth(136)
    }

    const [adminDrawerOpen, setAdminDrawerOpen] = useState(false)

    const isLoggedIn = () => {
        if (props.workspaceUser) {
            return props.workspaceUser && props.workspaceUser.id
        }
        return props.rootUser && props.rootUser.id
    }

    const location = useLocation()

    return (
        <>
            {isLoggedIn() ? (
                <div className={classes.root}>
                    {/* <CssBaseline /> */}

                    {!(
                        location.pathname === '/vendor-register' ||
                        location.pathname === '/skillcat-register'
                    ) && (
                        <>
                            <TopNavBar
                                open={open}
                                // handleDrawerClose={handleDrawerClose}
                                // handleDrawerOpen={handleDrawerOpen}
                                drawerWidth={drawerWidth}
                            />
                            <SideBar
                                open={open}
                                // handleDrawerClose={handleDrawerClose}
                                // handleDrawerOpen={handleDrawerOpen}
                                drawerWidth={drawerWidth}
                            />
                        </>
                    )}

                    <main
                        className={classes.content}
                        style={{
                            padding:
                                location.pathname === '/ez-chat-final' ||
                                location.pathname === '/vendor-register' ||
                                location.pathname === '/skillcat-register'
                                    ? 0
                                    : '24px',
                        }}
                    >
                        <div
                            className={classes.toolbar}
                            style={{
                                minHeight:
                                    location.pathname === '/vendor-register' ||
                                    location.pathname === '/skillcat-register'
                                        ? 0
                                        : '56px',
                            }}
                        />

                        {props.children}
                    </main>

                    {isAccountManager(props.workspaceUser) && (
                        <div>
                            <div
                                style={{
                                    height: 32,
                                    width: 32,
                                    position: 'fixed',
                                    bottom: 2,
                                    right: 2,
                                    backgroundColor: '#2F2E41',
                                    borderRadius: 32,
                                    zIndex: 1000000000000,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                    setAdminDrawerOpen((open) => !open)
                                }
                            >
                                <MenuOpenIcon htmlColor="#fff" />
                            </div>
                            <AdminPanel
                                open={adminDrawerOpen}
                                onClose={() => setAdminDrawerOpen(false)}
                            />
                        </div>
                    )}
                </div>
            ) : (
                props.children
            )}
        </>
    )
}

export default AdminLayout
