import React, { useEffect, useState } from 'react'
import { IdBoolMap, Service, ServiceRequestCategory } from '../../../../models'
import {
    Button,
    Chip,
    Divider,
    FormControl,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    Slide,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { Container } from '../../../../components'
import CloseIcon from '@material-ui/icons/Close'
import { useMultiSelectStyles } from '../../../../styles'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../../../helpers'

interface Props {
    handleClose: () => void
    open: boolean
    serviceList: Service[]
    serviceRequestCategories: ServiceRequestCategory[]
    setServiceRequestCategories: (categories: ServiceRequestCategory[]) => void
}

export const CreateCategoryModal = (props: Props) => {
    const {
        handleClose,
        open,
        serviceList,
        serviceRequestCategories,
        setServiceRequestCategories,
    } = props

    const [name, setName] = useState('')
    const [selectedServicesMap, setSelectedServicesMap] = useState<IdBoolMap>(
        {},
    )
    const [doValidate, setDoValidate] = useState(false)

    const classes = useMultiSelectStyles()
    const theme = useTheme()

    useEffect(() => {
        setSelectedServicesMap({})
        setName('')
        setDoValidate(false)
    }, [open])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: '600px',
                        height: '400px',
                        maxHeight: 'calc(100vh - 100px)',
                        maxWidth: 'calc(100vh - 100px)',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <Container
                        style={{
                            padding: theme.spacing(3, 0, 4, 2),
                            flex: 1,
                        }}
                        direction="column"
                    >
                        <Container
                            style={{
                                alignItems: 'center',
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: 500,
                                    fontSize: '20px',
                                    flex: 1,
                                }}
                            >
                                Create Category
                            </span>
                            <Tooltip title="Cancel">
                                <IconButton
                                    onClick={() => {
                                        handleClose()
                                    }}
                                >
                                    <CloseIcon
                                        fontSize="inherit"
                                        style={{
                                            marginRight: theme.spacing(1),
                                            color: theme.palette.darkGreen.main,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Container>
                        <Divider
                            style={{ margin: theme.spacing(0, 0, 3, 0) }}
                        />
                        <Container
                            style={{
                                flex: 1,
                                overflowY: 'auto',
                                maxHeight: '200px',
                            }}
                            direction="column"
                        >
                            <TextField
                                value={name}
                                variant="outlined"
                                size="medium"
                                title="Name"
                                placeholder="Name"
                                label="Name"
                                style={{ margin: theme.spacing(0, 5, 2, 0) }}
                                onChange={(e) => setName(e.target.value)}
                                error={doValidate && name === ''}
                                helperText={
                                    doValidate && name === '' && 'Required'
                                }
                            />
                            <FormControl className={classes.formControl}>
                                <InputLabel id="service-chip-label">
                                    Services
                                </InputLabel>
                                <Select
                                    labelId="service-chip-label"
                                    id="mutiple-chip-service"
                                    style={{ width: '350px' }}
                                    multiple
                                    value={Object.keys(selectedServicesMap).map(
                                        Number,
                                    )}
                                    onChange={(
                                        event: React.ChangeEvent<{
                                            value: unknown
                                        }>,
                                    ) => {
                                        const selectedServices = event.target
                                            .value as number[]

                                        const newSelectedServices = selectedServices.reduce<IdBoolMap>(
                                            (prev, srvId) => {
                                                return {
                                                    ...prev,
                                                    [srvId]: true,
                                                }
                                            },
                                            {},
                                        )
                                        setSelectedServicesMap(
                                            newSelectedServices,
                                        )
                                    }}
                                    input={
                                        <Input id="select-multiple-chip-service" />
                                    }
                                    renderValue={(selected) => (
                                        <div className={classes.chips}>
                                            {(selected as number[]).map(
                                                (value) => {
                                                    const srv = serviceList.find(
                                                        (s) => s.id === value,
                                                    )
                                                    return (
                                                        <Chip
                                                            key={`SERVICE_CHIP_${
                                                                srv
                                                                    ? srv.id
                                                                    : -1
                                                            }`}
                                                            label={
                                                                srv
                                                                    ? srv.name
                                                                    : 'unknown'
                                                            }
                                                            className={
                                                                classes.chip
                                                            }
                                                        />
                                                    )
                                                },
                                            )}
                                        </div>
                                    )}
                                >
                                    {serviceList.map((srv) => {
                                        return (
                                            <MenuItem
                                                key={`SERVICE_MENU_ITEM_${srv.id}`}
                                                value={srv.id}
                                            >
                                                {srv.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Container>
                        <Container justifyContent="flex-end">
                            <Button
                                variant="outlined"
                                style={{
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                    margin: theme.spacing(2, 0, 2, 0),
                                }}
                                color="secondary"
                                size="small"
                                onClick={() => {
                                    handleClose()
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                style={{
                                    margin: theme.spacing(2),
                                    backgroundColor: '#008C85',
                                    color: 'white',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (name === '') {
                                        setDoValidate(true)
                                        return
                                    }
                                    const services: {
                                        id: number
                                    }[] = []
                                    serviceList.forEach((service) => {
                                        if (selectedServicesMap[service.id]) {
                                            services.push({ id: service.id })
                                        }
                                    })
                                    const body = {
                                        name: name,
                                        services: services,
                                    }
                                    axiosInstance
                                        .post(
                                            `action-item/service-request-category/`,
                                            body,
                                        )
                                        .then((res) => {
                                            const tempServiceRequestCategories = serviceRequestCategories
                                            tempServiceRequestCategories.push(
                                                res.data,
                                            )
                                            setServiceRequestCategories(
                                                tempServiceRequestCategories,
                                            )
                                            toast.success(
                                                `${res.data.name} created!`,
                                            )
                                            handleClose()
                                        })
                                        .catch((e) =>
                                            toast.error(
                                                e.response.data.message,
                                            ),
                                        )
                                }}
                            >
                                Submit
                            </Button>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
