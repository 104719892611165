import { InspectionType } from '../Config'
import { Inspection } from '../Inspection'
import { IdentifiableObject } from '../types'

export enum FrequencyType {
    WO = 'WO',
}

export enum PeriodOption {
    DY = 'DY',
    WK = 'WK',
    BW = 'BW',
    MN = 'MN',
    BY = 'BY',
    YR = 'YR',
    WD = 'WD',
}

export const PeriodOptions = [
    { id: 1, name: PeriodOption.DY },
    { id: 2, name: PeriodOption.WK },
    { id: 3, name: PeriodOption.BW },
    { id: 4, name: PeriodOption.MN },
    { id: 5, name: PeriodOption.BY },
    { id: 6, name: PeriodOption.YR },
    { id: 7, name: PeriodOption.WD },
]

export interface Frequency extends IdentifiableObject {
    frequency_type: string
    start_point: string
    period: PeriodOption
    inspection_type: InspectionType
    days?: number
    unit_ins?: UnitInspectFreqConfig[]
    action_item_list_frequency?: ActionItemListFrequency[]
    inspection?: Inspection
}

export interface UnitInspectFreqConfig extends IdentifiableObject {
    frequency: number
    unit: number
    user: number
    area_ins: AreaInsFreqConfig[]
}

export interface AreaInsFreqConfig extends IdentifiableObject {
    frequency: number
    unit_ins_freq_config: number
    area: number
}

export interface ActionItemListFrequency extends IdentifiableObject {
    frequency: number
    period: string
    action_item_frequency?: ActionItemFrequency
}

export interface ActionItemFrequency extends IdentifiableObject {
    action_item_list_freq_config: number
    title: string
    location?: string
    assigned_by_company: number
    assinged_by: number
    assigned_to_user?: string
    assigned_to_company?: string
    priority: boolean
    service: number
}
