import {
    ActionItemActionTypes,
    ActionItemState,
    ADD_ACTION_ITEM,
    SET_ACTION_ITEM_LIST,
    SET_ACTION_ITEM_LOADING,
    UPDATE_ACTION_ITEM,
} from './types'

const initialState: ActionItemState = {
    isLoading: {
        GET_ACTION_ITEM_LIST_REQUEST: false,
        CREATE_ACTION_ITEM_REQUEST: false,
        UPDATE_ACTION_ITEM_REQUEST: false,
    },
}

export const actionItemReducer = (
    state = initialState,
    action: ActionItemActionTypes,
): ActionItemState => {
    switch (action.type) {
        case SET_ACTION_ITEM_LIST:
            return {
                ...state,
                actionItemList: action.actionItemList,
            }
        case SET_ACTION_ITEM_LOADING:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.request]: action.loading,
                },
            }
        case ADD_ACTION_ITEM:
            return {
                ...state,
                actionItemList: state.actionItemList
                    ? [...state.actionItemList, action.actionItem]
                    : [action.actionItem],
            }
        case UPDATE_ACTION_ITEM:
            return {
                ...state,
                actionItemList: state.actionItemList?.map((ai) => {
                    if (ai.id === action.actionItem?.id) {
                        return action.actionItem
                    }
                    return ai
                }),
            }
        default:
            return state
    }
}
