import React from 'react'
import { Container } from '../../components'
import BgImage from '../../assets/ez-pay-register-bg.jpg'
import {
    FormControl,
    InputLabel,
    Paper,
    Select,
    useTheme,
} from '@material-ui/core'
import { WorkSpaceUser } from '../../models'
import { ApartmentRegisterForm } from './Registration/ApartmentRegisterForm'
import { VendorRegisterForm } from './Registration/VendorRegisterForm'

interface Props {
    user: WorkSpaceUser
}

export const EZPayRegister = (props: Props) => {
    const theme = useTheme()

    return (
        <Container
            style={{ flex: 1, flexDirection: 'column', overflowY: 'auto' }}
        >
            {/* Top half */}
            <Container
                style={{
                    flex: 1,
                    backgroundImage: `url(${BgImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <span
                    style={{
                        ...theme.typography.h4,
                        fontWeight: 'bold',
                        color: 'white',
                        marginTop: theme.spacing(3),
                    }}
                >
                    Lets get started with EZ-Pay
                </span>
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflowY: 'auto',
                    }}
                >
                    <Paper
                        variant="outlined"
                        elevation={10}
                        style={{
                            height: FORM_HEIGHT,
                            width: FORM_WIDTH,
                            display: 'flex',
                            flexDirection: 'column',
                            border: `1px solid ${theme.palette.grey[400]}`,
                            borderRadius: theme.shape.borderRadius,
                        }}
                    >
                        {props.user.active_workspace.company_type ===
                            'APARTMENT' && (
                            <ApartmentRegisterForm user={props.user} />
                        )}
                        {props.user.active_workspace.company_type ===
                            'VENDOR' && (
                            <VendorRegisterForm user={props.user} />
                        )}
                    </Paper>
                </Container>
            </Container>

            {/* Bottom Half */}
        </Container>
    )
}

const FORM_HEIGHT = 550
const FORM_WIDTH = 950
