import React from 'react'
import {
    Divider,
    IconButton,
    Paper,
    Tooltip,
    Badge,
    Theme,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import BuildIcon from '@material-ui/icons/Build'

import {
    Area,
    AreaConfig,
    AreaStatusConfig,
    AreaStatusTag,
    IdBoolMap,
    ModelListMap,
    ModelMap,
    Schedule,
    Unit,
    UnitConfig,
    BaseWorkorder,
    isWorkorderSafe,
    CREATE_WORKORDERS,
    ServiceContractMap,
    WorkSpaceUser,
} from '../../../models'

import { Container } from '../../../components'
import { UnitArea, WorkorderComponent, GhostWorkorderComponent } from './'

import { hasPermission } from '../../../models/Users/services'
import { ReactComponent as UnitNotesIcon } from '../../../assets/icons/unitnotes.svg'
import { ScheduleFilterController } from '../../../hooks/useFilter'

interface Props {
    unit: Unit
    areaConfigMap: ModelMap<AreaConfig>
    unitWorkorderMap: ModelListMap<BaseWorkorder>
    // areaLeaseMap: ModelListMap<Lease>
    schedule?: Schedule
    unitConfig?: UnitConfig
    serviceAreaMap: IdBoolMap
    selectedWorkorder?: BaseWorkorder
    style: React.CSSProperties
    editMode: boolean
    editWorkorderSelection: ModelMap<BaseWorkorder>
    areaStatusTagMap: ModelMap<AreaStatusTag>
    areaStatusConfigList: AreaStatusConfig[]
    selected: boolean
    damageCount: number
    upstreamContractMap: ServiceContractMap
    vacantAreas: Area[]
    filterController: ScheduleFilterController
    workspaceUser: WorkSpaceUser | undefined
    theme: Theme
    onSelectWorkorder: (workorder: BaseWorkorder, unit: Unit) => void
    clickAssign: (unit: Unit) => void
    clickDamage: (unit: Unit) => void
    onClickChangeOrder: (workorder: BaseWorkorder) => void
    clickUnitNotes: (unit: Unit) => void
}

const AREA_CONTAINER_HEIGHT = 50
const DETAIL_WIDTH = 160

const _SchedulerUnit = (props: Props) => {
    const {
        unit,
        unitConfig,
        areaConfigMap,
        unitWorkorderMap,
        schedule,
        serviceAreaMap,
        selectedWorkorder,
        onSelectWorkorder,
        clickAssign,
        style,
        editMode,
        editWorkorderSelection,
        areaStatusTagMap,
        areaStatusConfigList,
        clickDamage,
        selected,
        damageCount,
        upstreamContractMap,
        clickUnitNotes,
        vacantAreas,
        filterController,
        workspaceUser,
        theme,
    } = props

    const unitWorkorders: BaseWorkorder[] | undefined =
        unitWorkorderMap[unit.id]

    return (
        <Container style={{ ...style }}>
            <Paper
                style={{
                    boxShadow: theme.shadows[2],
                    margin: theme.spacing(1),
                    flex: 1,
                }}
            >
                <Container
                    style={{
                        flex: 1,
                        height: '100%',
                        ...theme.typography.body2,
                    }}
                >
                    <Container
                        style={{
                            width: DETAIL_WIDTH,
                            cursor: 'pointer',
                            borderRadius: `${theme.shape.borderRadius} 0 0 ${theme.shape.borderRadius}`,
                            backgroundColor: theme.palette.grey[200],
                            boxShadow: `3px 0px 3px -1px ${theme.palette.grey[500]}`,
                        }}
                        direction="column"
                    >
                        {/* Top half */}
                        <Container style={{ padding: theme.spacing(1) }}>
                            <Container
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    overflowX: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                <span
                                    style={{
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        overflowX: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {unit.folder.path}
                                    {unit.folder.name}
                                </span>
                                <span
                                    style={{
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        overflowX: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {unit.name}
                                </span>
                                <span
                                    style={{
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                        overflowX: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {unitConfig?.name ?? '?'}
                                </span>
                            </Container>
                        </Container>

                        {/* Bottom Half */}
                        <Container
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <Container>
                                <div>
                                    {schedule?.active && (
                                        <Tooltip title="Assign">
                                            <IconButton
                                                onClick={() =>
                                                    clickAssign(unit)
                                                }
                                                disabled={
                                                    !hasPermission(
                                                        workspaceUser,
                                                        CREATE_WORKORDERS,
                                                    )
                                                }
                                            >
                                                <AddIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>

                                <div>
                                    <Tooltip title="Damages">
                                        <IconButton
                                            onClick={() => clickDamage(unit)}
                                            disabled={
                                                schedule === undefined ||
                                                schedule.inspection ===
                                                    undefined ||
                                                schedule.inspection === null
                                            }
                                        >
                                            <Badge
                                                badgeContent={damageCount}
                                                color="secondary"
                                            >
                                                <BuildIcon fontSize="small" />
                                            </Badge>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </Container>
                            <Container>
                                <div>
                                    <Tooltip title="Unit Notes">
                                        <Badge
                                            badgeContent={
                                                unit.unit_notes_msg_count
                                            }
                                            color={
                                                (unit.unread_unit_notes ?? 0) >
                                                0
                                                    ? 'secondary'
                                                    : 'primary'
                                            }
                                        >
                                            <IconButton
                                                onClick={() =>
                                                    clickUnitNotes(unit)
                                                }
                                            >
                                                <UnitNotesIcon />
                                            </IconButton>
                                        </Badge>
                                    </Tooltip>
                                </div>
                            </Container>
                        </Container>
                    </Container>

                    <Container
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                            backgroundColor: selected
                                ? theme.palette.primary.light
                                : undefined,
                            overflowX: 'auto',
                            width: '300px',
                            overflowY: 'hidden',
                        }}
                    >
                        {/* Area Container */}
                        <Container
                            style={{
                                minHeight: AREA_CONTAINER_HEIGHT,
                                maxHeight: AREA_CONTAINER_HEIGHT,
                                flex: 1,
                                paddingLeft: 2,
                                paddingRight: 2,
                            }}
                        >
                            {unit.areas.map((area) => {
                                if (
                                    areaConfigMap[area.area_config]
                                        ?.shows_on_schedule
                                ) {
                                    return (
                                        <UnitArea
                                            key={area.id}
                                            area={area}
                                            areaConfig={
                                                areaConfigMap[area.area_config]
                                            }
                                            schedule={schedule}
                                            isAreaInFilter={
                                                filterController.filter
                                                    .dateFilter.enable
                                                    ? filterController.isAreaInFilter
                                                    : undefined
                                            }
                                            areaStatusTag={
                                                areaStatusTagMap[area.id]
                                            }
                                            areaStatusConfigList={
                                                areaStatusConfigList
                                            }
                                            vacantAreas={vacantAreas}
                                            filter={filterController.filter}
                                        />
                                    )
                                }
                                return <></>
                            })}
                        </Container>
                        <Divider />

                        {/* Workorder Container */}
                        <Container
                            style={{
                                flex: 1,
                            }}
                        >
                            {unitWorkorders
                                ?.filter((workorder) =>
                                    filterController.isWorkorderInFilter(
                                        workorder,
                                        unit,
                                    ),
                                )
                                .map((workorder) => {
                                    if (isWorkorderSafe(workorder)) {
                                        return (
                                            <WorkorderComponent
                                                key={workorder.id}
                                                workspaceUser={workspaceUser}
                                                theme={theme}
                                                workorder={workorder}
                                                unit={unit}
                                                onClick={onSelectWorkorder}
                                                serviceAreaMap={serviceAreaMap}
                                                areaStatusTagMap={
                                                    areaStatusTagMap
                                                }
                                                upstreamContractMap={
                                                    upstreamContractMap
                                                }
                                                onClickChangeOrder={
                                                    props.onClickChangeOrder
                                                }
                                                selected={
                                                    selectedWorkorder?.id ===
                                                    workorder.id
                                                }
                                                inEditSelection={
                                                    editMode &&
                                                    editWorkorderSelection[
                                                        workorder.id
                                                    ] !== undefined
                                                }
                                            />
                                        )
                                    }

                                    return (
                                        <GhostWorkorderComponent
                                            key={workorder.id}
                                            workorder={workorder}
                                            areaStatusTagMap={areaStatusTagMap}
                                            unit={unit}
                                            onClick={onSelectWorkorder}
                                            serviceAreaMap={serviceAreaMap}
                                            selected={
                                                selectedWorkorder?.id ===
                                                workorder.id
                                            }
                                            inEditSelection={
                                                editMode &&
                                                editWorkorderSelection[
                                                    workorder.id
                                                ] !== undefined
                                            }
                                        />
                                    )
                                })}
                        </Container>
                    </Container>
                </Container>
            </Paper>
        </Container>
    )
}

export const SchedulerUnit = React.memo(_SchedulerUnit)
