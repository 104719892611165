import React from 'react'

import { Theme, Popover } from '@material-ui/core'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

import { DeadlineTransitionLog } from '../../../../models'
import { usePopover } from '../../../../hooks/usePopover'
import { AvatarCircle, Container } from '../../../../components'
import { toMMDDYYYY, toUiTime } from '../../../../helpers'

interface Props {
    theme: Theme
    transitions: DeadlineTransitionLog[]
}

export const DeadlineTransitionCard = (props: Props) => {
    const { theme, transitions } = props

    const lastTransition: DeadlineTransitionLog | undefined =
        transitions[transitions.length - 1]

    const popoverState = usePopover()

    return (
        <>
            <Container
                style={{
                    border: `1px solid ${theme.palette.grey[400]}`,
                    borderRadius: theme.shape.borderRadius,
                    padding: theme.spacing(1),
                    marginLeft: theme.spacing(4),
                    minHeight: 78,
                    maxHeight: 78,
                }}
                onClick={(e) => {
                    if (transitions.length > 1) {
                        popoverState.handleOpen(e)
                    }
                }}
            >
                <TransitionRow theme={theme} transition={lastTransition} />
            </Container>
            <Popover
                open={popoverState.isOpen}
                onClose={popoverState.handleClose}
                anchorEl={popoverState.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {transitions
                    .slice(0, -1)
                    .reverse()
                    .map((log) => {
                        return (
                            <TransitionRow
                                key={`LOG-${log.id}`}
                                theme={theme}
                                transition={log}
                                style={{
                                    padding: theme.spacing(1),
                                    borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                }}
                            />
                        )
                    })}
            </Popover>
        </>
    )
}

interface TransitionRowProps {
    theme: Theme
    transition?: DeadlineTransitionLog
    style?: React.CSSProperties
}

const TransitionRow = (props: TransitionRowProps) => {
    const { theme, transition } = props

    if (transition === undefined) {
        return (
            <Container
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                        color: theme.palette.grey[800],
                    }}
                >
                    No activity
                </span>
            </Container>
        )
    }

    const transitionDate = new Date(transition.created_date)

    const toLabel = transition.to_completion ? 'Complete' : 'Incomplete'
    const fromLabel = transition.to_completion ? 'Incomplete' : 'Complete'

    return (
        <Container
            style={{
                flexDirection: 'row',
                cursor: 'pointer',
                ...props.style,
            }}
        >
            <AvatarCircle
                initials
                text={transition.transitioned_by.name}
                tooltip={transition.transitioned_by.name}
            />
            <Container
                style={{
                    flexDirection: 'column',
                    marginLeft: theme.spacing(1),
                    justifyContent: 'center',
                }}
            >
                <Container style={{ alignItems: 'center' }}>
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightLight,
                            color: theme.palette.grey[800],
                        }}
                    >
                        {fromLabel}
                    </span>
                    <ArrowRightAltIcon />
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.grey[800],
                        }}
                    >
                        {toLabel}
                    </span>
                </Container>
                <span
                    style={{
                        ...theme.typography.body2,
                        fontWeight: theme.typography.fontWeightLight,
                        color: theme.palette.grey[700],
                    }}
                >
                    {toMMDDYYYY(transitionDate)} {toUiTime(transitionDate)}
                </span>
            </Container>
        </Container>
    )
}
