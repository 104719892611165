import { Theme } from '@material-ui/core'
import {
    InspectionStatusType,
    InspectionStatus,
    IncidentStatus,
    IncidentStatusNumbers,
} from './types'

export const getInspectionStatusLabel = (status?: InspectionStatusType) => {
    switch (status) {
        case InspectionStatus.ARCHIVED:
            return 'Archived'
        case InspectionStatus.LOCKED:
            return 'Locked'
        case InspectionStatus.READY:
            return 'Sent'
        case InspectionStatus.COMPLETE:
            return 'Complete'
        case InspectionStatus.NA:
            return 'N/A'
        case InspectionStatus.STAGED:
            return 'Staged'
    }
    return ''
}

export const getIncidentReoprtStatusColor = (status: string, theme: Theme) => {
    switch (status) {
        case IncidentStatus.PENDING:
            return theme.palette.secondary.main
        case IncidentStatus.NOT_SUBMITTED:
            return theme.palette.error.main
        case IncidentStatus.REVIEWED:
            return theme.palette.APPROVED.main
        case IncidentStatus.SUBMITTED:
            return theme.palette.primary.main
    }
    return theme.palette.warning.main
}

export const getIncidentStatusFromNumber = (statusId: number) => {
    switch (statusId) {
        case IncidentStatusNumbers.PENDING:
            return IncidentStatus.PENDING
        case IncidentStatusNumbers.NOT_SUBMITTED:
            return IncidentStatus.NOT_SUBMITTED
        case IncidentStatusNumbers.REVIEWED:
            return IncidentStatus.REVIEWED
        case IncidentStatusNumbers.SUBMITTED:
            return IncidentStatus.SUBMITTED
    }

    return ''
}
