import { Folder, Unit } from '../../models'
import {
    FinderActionTypes,
    FinderState,
    FinderLocationSelection,
    LocationSelection,
    SET_FINDER_SELECTION,
    SetFinderSelectionAction,
    FinderSelectionMode,
    Location,
    COPY_FINDER_SELECTION,
} from './types'

export const defaultLocationSelection = {
    unit: { length: 0 },
    folder: { length: 0 },
}

const initialState: FinderState = {
    [FinderLocationSelection.RootSelection]: defaultLocationSelection,
    [FinderLocationSelection.PrunedSelection]: defaultLocationSelection,
}

export const finderReducer = (
    state = initialState,
    action: FinderActionTypes,
): FinderState => {
    switch (action.type) {
        case SET_FINDER_SELECTION:
            return setFinderSelection(action, state)
        case COPY_FINDER_SELECTION:
            return {
                ...state,
                [action.option]: {
                    folder: { ...action.locationSelection.folder },
                    unit: { ...action.locationSelection.unit },
                },
            }

        default:
            return state
    }
}

const setFinderSelection = (
    action: SetFinderSelectionAction,
    state: FinderState,
) => {
    let newState = state

    if (action.mode === FinderSelectionMode.Multi) {
        newState = updateSelectionStateMulti(action, state)
    } else if (action.mode === FinderSelectionMode.Single) {
        newState = updateSelectionStateSingle(action, state)
    } else if (action.mode === FinderSelectionMode.Recursive) {
        newState = updateSelectionStateRecursive(
            action,
            state,
            undefined,
            action.excluder,
        )
    }

    action.resultCallback && action.resultCallback(newState[action.option])

    return newState
}

const updateSelectionStateSingle = (
    action: SetFinderSelectionAction,
    state: FinderState,
) => {
    const location = action.location

    if (location === undefined) {
        return initialState
    }

    const locAndLenMod = getNewLocationAndLengthMod(action, state, location)

    return {
        ...initialState,
        [action.option]: {
            ...initialState[action.option],
            [location.type]: {
                ...initialState[action.option][location.type],
                [location.location.id]: locAndLenMod.location,
                length:
                    locAndLenMod.lengthModifier < 0
                        ? 0
                        : locAndLenMod.lengthModifier,
            },
        },
    }
}

const updateSelectionStateMulti = (
    action: SetFinderSelectionAction,
    state: FinderState,
) => {
    const location = action.location

    if (location === undefined) {
        return {
            ...state,
            [action.option]: defaultLocationSelection,
        }
    }

    const locAndLenMod = getNewLocationAndLengthMod(action, state, location)

    const updatedSelection: LocationSelection = {
        ...state[action.option],
        [location.type]: {
            ...state[action.option][location.type],
            [location.location.id]: locAndLenMod.location,
            length:
                state[action.option][location.type].length +
                locAndLenMod.lengthModifier,
        },
    }

    return {
        ...state,
        [action.option]: updatedSelection,
    }
}

const getNewLocationAndLengthMod = (
    action: SetFinderSelectionAction,
    state: FinderState,
    location: Location,
) => {
    let newLoc: Folder | Unit | undefined
    let lenModifier = -1

    if (
        state[action.option][location.type][location.location.id] === undefined
    ) {
        newLoc = location.location
        lenModifier = 1
    }

    return {
        lengthModifier: lenModifier,
        location: newLoc,
    }
}

const updateSelectionStateRecursive = (
    action: SetFinderSelectionAction,
    state: FinderState,
    handler?: AddRemoveHandler,
    excluder?: (location: Location) => boolean,
) => {
    const location = action.location

    if (location === undefined) {
        return initialState
    }

    if (handler === undefined) {
        // this is the top level call.  We are recursively adding the sub tree
        // if this branch was not included previously.  We will remove the branch
        // if it was included previously.
        if (isLocationSelected(state, location, action.option)) {
            handler = removeLocation
        } else {
            handler = addLocation
        }
    }

    // Base condition
    if (location.type === 'unit') {
        return handler!(
            state,
            { type: 'unit', location: location.location },
            action.option,
            excluder,
        )
    }

    const folder = location.location as Folder
    state = handler!(state, location, action.option, excluder)

    // Recursively handle the folders children fodlers
    folder.children.forEach((child) => {
        const childFolderLocation: Location = {
            type: 'folder',
            location: child,
        }

        state = handler!(state, childFolderLocation, action.option, excluder)

        state = updateSelectionStateRecursive(
            { ...action, location: childFolderLocation },
            state,
            handler,
            excluder,
        )
    })

    folder.units.forEach((unit) => {
        const childUnitLocation: Location = { type: 'unit', location: unit }
        state = handler!(state, childUnitLocation, action.option, excluder)
    })

    return state
}

type AddRemoveHandler = (
    state: FinderState,
    location: Location,
    selection: FinderLocationSelection,
    excluder?: (location: Location) => boolean,
) => FinderState

const isLocationSelected = (
    state: FinderState,
    location: Location,
    selection: FinderLocationSelection,
) => {
    return state[selection][location.type][location.location.id] !== undefined
}

const addLocation: AddRemoveHandler = (
    state,
    location,
    selection,
    excluder,
): FinderState => {
    // This location did not previously exist.  it must be added.
    const excludeLocation = excluder && excluder(location)
    if (!isLocationSelected(state, location, selection) && !excludeLocation) {
        return {
            ...state,
            [selection]: {
                ...state[selection],
                [location.type]: {
                    ...state[selection][location.type],
                    [location.location.id]: location.location,
                    length: state[selection][location.type].length + 1,
                },
            },
        }
    }

    // The location did previously exist, move on
    return state
}

const removeLocation: AddRemoveHandler = (
    state,
    location,
    selection,
    excluder,
): FinderState => {
    // This location did previously exist.  it must be removed.
    const excludeLocation = excluder && excluder(location)
    if (isLocationSelected(state, location, selection) && !excludeLocation) {
        const newLen = Math.max(0, state[selection][location.type].length - 1)
        return {
            ...state,
            [selection]: {
                ...state[selection],
                [location.type]: {
                    ...state[selection][location.type],
                    [location.location.id]: undefined,
                    length: newLen,
                },
            },
        }
    }

    // The location did not previously exist, move on
    return state
}
