import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Theme } from '@material-ui/core'
import VendorLoginImage from '../../../../assets/vendor-login.png'
import ApartmentLoginImage from '../../../../assets/apartment-login.png'
import { Routes } from '../../../../helpers'
import { Container } from '../../../../components'

interface RegisterCardProps {
    routerType: 'login' | 'register'
    registerType: 'Service Provider' | 'Property Manager'
    theme: Theme
}
export const RegisterCard = (props: RegisterCardProps) => {
    const { routerType, registerType, theme } = props

    const history = useHistory()

    const isLogin = routerType === 'login'

    return (
        <Container
            style={{
                backgroundColor: 'white',
                borderRadius: '40px',
                alignItems: 'center',
                margin: theme.spacing(2),
                padding: theme.spacing(2),
                maxWidth: 500,
                flexShrink: 2,
                flexDirection: 'column',
                textAlign: 'center',
                boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
            }}
        >
            {registerType == 'Service Provider' && (
                <img
                    src={VendorLoginImage}
                    style={{ maxWidth: 350, maxHeight: 350 }}
                />
            )}
            {registerType == 'Property Manager' && (
                <img
                    src={ApartmentLoginImage}
                    style={{ maxWidth: 350, maxHeight: 350 }}
                />
            )}

            <span
                style={{
                    ...theme.typography.h4,
                    color: theme.palette.common.black,
                    margin: theme.spacing(1),
                }}
            >
                {registerType}
            </span>
            <span
                style={{
                    color: theme.palette.grey[700],
                    minWidth: 300,
                    ...theme.typography.body1,
                    marginBottom: theme.spacing(1),
                    textAlign: 'center',
                }}
            >
                {registerType === 'Service Provider'
                    ? 'Establish valuable connections through our platform with companies searching for top-notch services.'
                    : 'Streamline your maintenance process effortlessly. Gain instant access to a vast network of skilled contractors.'}
            </span>

            <Button
                style={{
                    color: 'white',
                    backgroundColor: theme.palette.primary.dark,
                    borderRadius: theme.shape.borderRadius,
                    margin: theme.spacing(1),
                    textTransform: 'none',
                    width: 100,
                    transition:
                        'background-color 0.3s, color 0.3s, border-color 0.3s',
                    border: `2px solid ${theme.palette.primary.dark}`,
                }}
                onClick={() => {
                    if (isLogin) {
                        history.push(Routes.loginHome)
                    } else {
                        history.push(
                            registerType === 'Property Manager'
                                ? Routes.userRegister
                                : Routes.vendorRegister,
                        )
                    }
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = 'white'
                    e.currentTarget.style.color = theme.palette.primary.dark
                    e.currentTarget.style.borderColor =
                        theme.palette.primary.dark
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor =
                        theme.palette.primary.dark
                    e.currentTarget.style.color = 'white'
                }}
            >
                {isLogin ? 'Login' : 'Sign Up'}
            </Button>
        </Container>
    )
}
