import { AxiosResponse } from 'axios'
import { Action } from 'redux'

import { User, WorkSpaceUser, Permissible } from '../../models'

// Requests

export interface LoginRequest {
    email: string
    password: string
}

export interface GetUserListRequest {
    params?: {
        my_team?: boolean
    }
}

export interface ArchiveUserRequest {
    user: User
}

export interface CreateUserRequest {
    body: {
        email: string
        name: string
        profile_color?: string
        public?: boolean
        phone?: string
        cell_phone?: string
        admin?: boolean
        permission_roles?: number[]
    }
}

export interface UpdateUserRequest {
    isCurrentUser: boolean
    params: {
        id: number
    }
    body: {
        name?: string
        profile_color?: string
        public?: boolean
        phone?: string
        cell_phone?: string
        active_workspace?: number
        jobsite?: number
        admin?: boolean
        permissions?: Permissible
        permission_roles?: number[]
        email?: string
        profile_picture?: string
    }
}

export interface UpdateUserRequestFormData {
    isCurrentUser: boolean
    params: {
        id: number
    }
    body: FormData
}

export interface GetMyWorkforceRequest {}

export interface GetRootUserRequest {}

// actions
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST'
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const GET_MY_WORKFORCE_REQUEST = 'GET_MY_WORKFORCE_REQUEST'
export const GET_ROOT_USER_REQUEST = 'GET_ROOT_USER_REQUEST'
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const ARCHIVE_USER_REQUEST = 'ARCHIVE_USER_REQUEST'

export const SET_ROOT_USER = 'SET_ROOT_USER'
export const SET_WORKSPACE_USER = 'SET_WORKSPACE_USER'
export const SET_USER_LIST = 'SET_USER_LIST'
export const UPDATE_USER_LIST = 'UPDATE_USER_LIST'
export const REMOVE_USER_FROM_LIST = 'REMOVE_USER_FROM_LIST'

export const CREATE_USER_RESPONSE = 'CREATE_USER_RESPONSE'

export const SET_USER_LOADING = 'SET_USER_LOADING'

export type UserRequests =
    | typeof LOGIN_REQUEST
    | typeof GET_USER_LIST_REQUEST
    | typeof CREATE_USER_REQUEST
    | typeof UPDATE_USER_REQUEST
    | typeof GET_MY_WORKFORCE_REQUEST
    | typeof GET_ROOT_USER_REQUEST
    | typeof ARCHIVE_USER_REQUEST

interface SetUserLoadingAction extends Action {
    type: typeof SET_USER_LOADING
    request: UserRequests
    isLoading: boolean
}

interface RemoveUserAction extends Action {
    type: typeof REMOVE_USER_FROM_LIST
    user: User
}

interface LoginRequestAction extends Action {
    type: typeof LOGIN_REQUEST
}

interface SetRootUserAction extends Action {
    type: typeof SET_ROOT_USER
    user?: User
}

interface SetWorkSpaceUserAction extends Action {
    type: typeof SET_WORKSPACE_USER
    user?: WorkSpaceUser
}

interface CreateUserRequestAction extends Action {
    type: typeof CREATE_USER_REQUEST
}

interface CreateUserResponseAction extends Action {
    type: typeof CREATE_USER_RESPONSE
    payload: User
}

interface SetUserListAction extends Action {
    type: typeof SET_USER_LIST
    userList: User[]
}

interface UpdateUserListAction extends Action {
    type: typeof UPDATE_USER_LIST
    user: User
}

type CreateUserActionTypes = CreateUserResponseAction | CreateUserRequestAction

type LoginActionTypes = LoginRequestAction | SetRootUserAction

export type UserActionTypes =
    | LoginActionTypes
    | CreateUserActionTypes
    | SetUserLoadingAction
    | SetWorkSpaceUserAction
    | SetUserListAction
    | UpdateUserListAction
    | RemoveUserAction

// thunks
export type BaseUserListThunk = Promise<AxiosResponse<User[]>>
export type LoginActionThunk = Promise<AxiosResponse<User>>
//this thunk is used in both create and update user
export type UserDetailActionThunk = Promise<AxiosResponse<WorkSpaceUser>>

// reducers
export interface UserState {
    rootUser?: User
    workspaceUser?: WorkSpaceUser

    isLoading: {
        [LOGIN_REQUEST]: boolean
        [GET_USER_LIST_REQUEST]: boolean
        [CREATE_USER_REQUEST]: boolean
        [UPDATE_USER_REQUEST]: boolean
        [GET_MY_WORKFORCE_REQUEST]: boolean
        [GET_ROOT_USER_REQUEST]: boolean
        [ARCHIVE_USER_REQUEST]: boolean
    }
    userList: User[]
}
