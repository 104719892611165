import React from 'react'
import { Grid, useTheme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { TabPanel } from '../../components'
import { RootState } from '../../store'
import {
    WORKORDER_TAB,
    InfrastructureManageTab,
} from './InfrastructureManagerHoc'
import { WorkorderRow } from './WorkorderRow'

interface Props {
    tab: InfrastructureManageTab
}

export const WorkorderTabPanel = (props: Props) => {
    const { tab } = props

    const theme = useTheme()

    const workorderList = useSelector(
        (state: RootState) => state.infrastructure.manager_workorders,
    )

    return (
        <TabPanel
            value={WORKORDER_TAB}
            index={tab}
            style={{
                backgroundColor: theme.palette.grey[200],
                flex: 1,
                overflowY: 'scroll',
                flexDirection: 'column',
                padding: theme.spacing(2),
            }}
        >
            <Grid
                container
                style={{
                    display: WORKORDER_TAB === tab ? 'flex' : 'none',
                    justifyContent: 'flex-start',
                }}
            >
                {workorderList?.map((wo) => {
                    return (
                        <WorkorderRow
                            key={`INFRASTRUCTURE_WORKORDER_${wo.id}`}
                            workorder={wo}
                        />
                    )
                })}
            </Grid>
        </TabPanel>
    )
}
