import { AxiosError } from 'axios'
import {
    GetLeaseListRequest,
    GET_LEASE_LIST_REQUEST,
    LeaseActionTypes,
    LeaseListResponseThunk,
    LeaseRequest,
    SET_LEASE_LIST,
    SET_LEASE_LOADING,
    BULK_UPDATE_LEASE_REQUEST,
    BulkUpdateLeaseRequest,
    BULK_UPDATE_LEASE,
    BulkCreateLeaseRequest,
    AreaListThunk,
    ADD_LEASES,
    BULK_CREATE_LEASE_REQUEST,
    GetNextAndPrevLeaseRequest,
    GetVacantAreaRequest,
    GET_NEXT_AND_PREV_LEASE_REQUEST,
    GET_VACANT_AREA_REQUEST,
    NextAndPrevLeaseThunk,
    SET_NEXT_AND_PREV_LEASE,
    SET_VACANT_AREA_LIST,
    CreateDamageReqeust,
    CREATE_DAMAGE_REQUEST,
    DamageThunk,
    GetDamageRequest,
    DamageListThunk,
    GET_DAMAGE_LIST_REQUEST,
    SET_DAMAGE_LIST,
    ADD_DAMAGE,
    SET_TENANT_LIST,
    GetTenantListRequest,
    TenantListThunk,
    GET_TENANT_LIST_REQUEST,
    UPDATE_DAMAGE,
    DELETE_DAMAGE_REQUEST,
    REMOVE_DAMAGE,
} from './types'
import { AppThunk, ErrorResponse, setNetworkError } from '..'

import { axiosInstance } from '../../helpers'
import { Area, Damage, Lease, Tenant } from '../../models'
import { setPaginationNextPrev } from '../pagination/action'

export const getLeaseList = (
    req: GetLeaseListRequest,
): AppThunk<LeaseListResponseThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setLeaseLoading(GET_LEASE_LIST_REQUEST, true))

        const url = req.url ?? `lease/`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })
            let leases
            let initial = true
            if (req.params?.page || req.params?.page_size || req?.url) {
                leases = response.data.results
                // determine if this is the intiial request
                if (response.data.previous) {
                    initial = false
                }
                // set pagination state
                dispatch(
                    setPaginationNextPrev(
                        response.data.next,
                        response.data.previous,
                        response.data.count,
                        GET_LEASE_LIST_REQUEST,
                    ),
                )
            } else {
                leases = response.data
            }
            dispatch(setLeaseList(initial, leases))
            dispatch(setLeaseLoading(GET_LEASE_LIST_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setLeaseLoading(GET_LEASE_LIST_REQUEST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const bulkUpdateLeaseRequest = (
    req: BulkUpdateLeaseRequest,
): AppThunk<LeaseListResponseThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setLeaseLoading(BULK_UPDATE_LEASE_REQUEST, true))

        const url = `lease/bulk_update/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch(updateLeases(response.data))
            dispatch(setLeaseLoading(BULK_UPDATE_LEASE_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setLeaseLoading(BULK_UPDATE_LEASE_REQUEST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createDamageRequest = (
    req: CreateDamageReqeust,
): AppThunk<DamageThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setLeaseLoading(CREATE_DAMAGE_REQUEST, true))

        const url = `lease/damage/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)
            dispatch(addDamage(response.data))
            dispatch(setLeaseLoading(CREATE_DAMAGE_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setLeaseLoading(CREATE_DAMAGE_REQUEST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const deleteDamageRequest = (
    damageId: number,
): AppThunk<DamageThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setLeaseLoading(DELETE_DAMAGE_REQUEST, true))

        const url = `lease/damage/${damageId}/`

        try {
            // success
            const response = await axiosInstance.delete(url)
            dispatch(removeDamage(damageId))
            dispatch(setLeaseLoading(DELETE_DAMAGE_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setLeaseLoading(DELETE_DAMAGE_REQUEST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getDamageListRequest = (
    req: GetDamageRequest,
): AppThunk<DamageListThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setLeaseLoading(GET_DAMAGE_LIST_REQUEST, true))

        const url = `lease/damage/`

        try {
            // success
            const response = await axiosInstance.get(url, req)

            dispatch(setDamageList(response.data))
            dispatch(setLeaseLoading(GET_DAMAGE_LIST_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setLeaseLoading(GET_DAMAGE_LIST_REQUEST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const bulkCreateLease = (
    req: BulkCreateLeaseRequest,
): AppThunk<LeaseListResponseThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setLeaseLoading(BULK_CREATE_LEASE_REQUEST, true))

        const url = `lease/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch(addLeases(response.data))
            dispatch(setLeaseLoading(BULK_CREATE_LEASE_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setLeaseLoading(BULK_CREATE_LEASE_REQUEST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getNextAndPrevLeases = (
    req: GetNextAndPrevLeaseRequest,
): AppThunk<NextAndPrevLeaseThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setLeaseLoading(GET_NEXT_AND_PREV_LEASE_REQUEST, true))

        const url = `lease/${req.id}/get_next_and_prev_lease/`

        try {
            // success
            const response = await axiosInstance.get(url)
            const nextLease = response.data.next_lease
            const prevLease = response.data.prev_lease
            dispatch(setNextPrevLease(nextLease, prevLease))
            dispatch(setLeaseLoading(GET_NEXT_AND_PREV_LEASE_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setLeaseLoading(GET_NEXT_AND_PREV_LEASE_REQUEST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getVacantAreas = (
    req: GetVacantAreaRequest,
): AppThunk<AreaListThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setLeaseLoading(GET_VACANT_AREA_REQUEST, true))

        const url = `infrastructure/area/`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch(setVacantAreas(response.data))
            dispatch(setLeaseLoading(GET_VACANT_AREA_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setLeaseLoading(GET_VACANT_AREA_REQUEST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getTenantList = (
    req: GetTenantListRequest,
): AppThunk<TenantListThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setLeaseLoading(GET_TENANT_LIST_REQUEST, true))

        const url = `lease/tenant/`

        try {
            // success
            const response = await axiosInstance.get(url, req)

            dispatch(setTenantList(response.data))
            dispatch(setLeaseLoading(GET_TENANT_LIST_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setLeaseLoading(GET_TENANT_LIST_REQUEST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const setLeaseLoading = (
    request: LeaseRequest,
    loading: boolean,
): LeaseActionTypes => {
    return {
        type: SET_LEASE_LOADING,
        request: request,
        loading: loading,
    }
}

export const setLeaseList = (
    initial: boolean,
    leaseList?: Lease[],
): LeaseActionTypes => {
    return {
        type: SET_LEASE_LIST,
        leases: leaseList,
        initial: initial,
    }
}

export const updateLeases = (leases: Lease[]): LeaseActionTypes => {
    return {
        type: BULK_UPDATE_LEASE,
        leases: leases,
    }
}

export const addLeases = (leases: Lease[]): LeaseActionTypes => {
    return {
        type: ADD_LEASES,
        leases: leases,
    }
}

export const setVacantAreas = (areas?: Area[]): LeaseActionTypes => {
    return {
        type: SET_VACANT_AREA_LIST,
        areas: areas,
    }
}

export const setNextPrevLease = (
    nextLease?: Lease,
    prevLease?: Lease,
): LeaseActionTypes => {
    return {
        type: SET_NEXT_AND_PREV_LEASE,
        next: nextLease,
        prev: prevLease,
    }
}

export const setDamageList = (damageList?: Damage[]): LeaseActionTypes => {
    return {
        type: SET_DAMAGE_LIST,
        damageList: damageList,
    }
}

export const addDamage = (damage: Damage): LeaseActionTypes => {
    return {
        type: ADD_DAMAGE,
        damage: damage,
    }
}

export const updateDamage = (damage: Damage): LeaseActionTypes => {
    return {
        type: UPDATE_DAMAGE,
        damage: damage,
    }
}

export const removeDamage = (damageId: number): LeaseActionTypes => {
    return {
        type: REMOVE_DAMAGE,
        damageId: damageId,
    }
}

export const setTenantList = (tenantList?: Tenant[]): LeaseActionTypes => {
    return {
        type: SET_TENANT_LIST,
        tenantList: tenantList,
    }
}
