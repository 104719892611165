import { Tab, Tabs, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from '../../components'
import { ReactComponent as EZWorkLogo } from '../../assets/EZWorkLogo.svg'
import { EZWorkWorkorderList } from './EZWorkWorkorderList'
import { VendorEZNowList } from '../EZNow/VendorEZNowList'
import { OpenJobsPage } from '../RFP/OpenJobsPage'
import { VendorMyBidsPage } from '../RFP/VendorMyBidsPage'
import { rfpApi, workorderContext } from '../../contexts'
import { useUser } from '../../hooks'
import { PlanStatus, RFPStatus, RFPType, getDateRange } from '../../models'
import { toast } from 'react-toastify'

const ASSIGNED_TO_ME_TAB = 1
const EZNOW_TAB = 2
const OPEN_JOBS_TAB = 3
const MY_BIDS_TAB = 4
const UNSOLICITED_TAB = 5

export const EZWorkHome = () => {
    const {
        clearState,
        getRFPAndBidCounts,
        rfpAndBidCounts,
        getRFPList,
        radius,
    } = rfpApi()

    const { workspaceUser } = useUser()

    const {
        getWorkorderList,
        workorderList,
        clearState: clearWorkorderState,
    } = workorderContext()

    const theme = useTheme()

    const [tab, setTab] = useState(ASSIGNED_TO_ME_TAB)

    const handleSetTab = (tab: number) => {
        setTab(tab)
        clearState()
    }

    useEffect(() => {
        clearWorkorderState()
        const dateRange = getDateRange('Today')
        getWorkorderList({
            params: {
                upper_bound_date: dateRange.lte_date,
                lower_bound_date: dateRange.gte_date,
                all_jobsites: true,
            },
        }).catch((e) => {
            toast.error(`ERROR ${e}`)
        })
        getRFPAndBidCounts()
    }, [workspaceUser])

    const tabStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        fontWeight: 800,
        textTransform: 'none',
    }

    const hasPremium =
        workspaceUser?.active_workspace.stripe_subscription?.plan ===
        PlanStatus.PREMIUM

    const workorderLength = workorderList?.length ?? 0
    const ezNowLength = rfpAndBidCounts?.ez_now_count ?? 0
    const openJobCount = rfpAndBidCounts?.open_job_count ?? 0
    const invitedBidCount = rfpAndBidCounts?.invited_bid_count ?? 0
    const unsolicitedCount = rfpAndBidCounts?.unsolicited_job_count ?? 0

    return (
        <Container style={{ flex: 1, flexDirection: 'column', height: '100%' }}>
            <Container alignItems="center">
                <EZWorkLogo />
                <Container style={{ marginRight: theme.spacing(10) }} />
                <Tabs
                    value={tab}
                    onChange={(_, v) => {
                        handleSetTab(v)
                        if (v === OPEN_JOBS_TAB) {
                            getRFPList({
                                params: {
                                    type: RFPType.PUBLIC,
                                    open_jobs: true,
                                    radius: radius,
                                    statuses: RFPStatus.PUBLISHED,
                                },
                            })
                        } else if (v === UNSOLICITED_TAB) {
                            getRFPList({
                                params: {
                                    type: RFPType.INVITE_ONLY,
                                    open_jobs: true,
                                    radius: radius,
                                    statuses: RFPStatus.PUBLISHED,
                                    unsolicited_jobs: true,
                                },
                            })
                        }
                    }}
                    style={{}}
                >
                    <Tab
                        label={
                            workorderLength > 0
                                ? `Assigned To Me (${workorderLength})`
                                : `Assigned To Me`
                        }
                        value={ASSIGNED_TO_ME_TAB}
                        style={{ ...tabStyle }}
                    />
                    <Tab
                        label={
                            ezNowLength > 0 ? `EZNow (${ezNowLength})` : 'EZNow'
                        }
                        value={EZNOW_TAB}
                        style={{ ...tabStyle }}
                    />
                    <Tab
                        label={
                            openJobCount > 0
                                ? `Open Jobs (${openJobCount})`
                                : 'Open Jobs'
                        }
                        value={OPEN_JOBS_TAB}
                        style={{ ...tabStyle }}
                    />
                    <Tab
                        label={
                            invitedBidCount > 0
                                ? `My Bids (${invitedBidCount})`
                                : 'My Bids'
                        }
                        value={MY_BIDS_TAB}
                        style={{ ...tabStyle }}
                    />
                    {hasPremium && (
                        <Tab
                            label={
                                unsolicitedCount > 0
                                    ? `Unsolicited Jobs (${unsolicitedCount})`
                                    : 'Unsolicited Jobs'
                            }
                            value={UNSOLICITED_TAB}
                            style={{ ...tabStyle }}
                        />
                    )}
                </Tabs>
            </Container>
            {tab === ASSIGNED_TO_ME_TAB && <EZWorkWorkorderList />}
            {tab === EZNOW_TAB && <VendorEZNowList />}
            {tab === OPEN_JOBS_TAB && <OpenJobsPage />}
            {tab === MY_BIDS_TAB && <VendorMyBidsPage />}
            {tab === UNSOLICITED_TAB && <OpenJobsPage notInvitedJobs />}
        </Container>
    )
}
