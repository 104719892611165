import {
    Button,
    Divider,
    MenuItem,
    Modal,
    Paper,
    Slide,
    Step,
    StepButton,
    Stepper,
    TextField,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from '../Container'
import { axiosInstance, statesList } from '../../helpers'
import { ApartmentDetailForm } from './ApartmentDetailForm'
import { Selector } from '../Selector'
import { toast } from 'react-toastify'
import { Company } from '../../models'
import { OnboardApartmentFileForm } from './OnboardApartmentFileForm'
import { EntrataOnboardForm } from './EntrataOnboardForm'

interface Props {
    open: boolean
    onClose: () => void
}

export const OnboardApartmentModal = (props: Props) => {
    const { open, onClose } = props

    const [step, setStep] = useState(0)
    const [entrataOnboarding, setEntrataOnboarding] = useState(false)

    const theme = useTheme()

    const [pendingApartments, setPendingApartments] = useState<Company[]>([])
    const [selectedAptId, setSelectedAptId] = useState(-1)

    useEffect(() => {
        axiosInstance
            .get('company/apartment/', {
                params: { onboard_pending: true },
            })
            .then((res) => setPendingApartments(res.data))
            .catch((e) => toast.error(e))
    }, [])

    const selectedApt: Company | undefined = pendingApartments.find(
        (apt) => apt.id === selectedAptId,
    )

    const updateApartment = (apt: Company) => {
        setPendingApartments((oldList) => {
            return oldList.map((oldApt) => {
                if (apt.id === oldApt.id) {
                    return apt
                }

                return oldApt
            })
        })
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper>
                    <Container
                        style={{
                            height: 600,
                            width: 700,
                            display: 'flex',
                            overflow: 'hidden',
                            flexDirection: 'column',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        {step === 0 && (
                            <Container
                                style={{
                                    flexDirection: 'column',
                                    flex: 1,
                                    padding: theme.spacing(2),
                                }}
                            >
                                <Container style={{}}>
                                    <Selector
                                        label="Resume onboarding"
                                        currentValue={selectedAptId}
                                        onChange={(
                                            event: React.ChangeEvent<{
                                                value: unknown
                                            }>,
                                        ) => {
                                            setSelectedAptId(
                                                event.target.value as number,
                                            )
                                        }}
                                        searchable
                                        data={pendingApartments}
                                        getDisplayString={(apt) => apt.name}
                                        customStyle={{
                                            formControl: {
                                                width: '100%',
                                                marginRight: theme.spacing(2),
                                            },
                                        }}
                                    />

                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled={selectedApt === undefined}
                                        onClick={() => {
                                            setEntrataOnboarding(false)
                                            setStep(2)
                                        }}
                                    >
                                        Continue
                                    </Button>
                                </Container>

                                <Container
                                    style={{
                                        marginTop: theme.spacing(2),
                                        flex: 1,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                            setSelectedAptId(-1)
                                            setEntrataOnboarding(false)
                                            setStep(1)
                                        }}
                                    >
                                        Add Apartment w/ no integration
                                    </Button>

                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        style={{ marginTop: theme.spacing(2) }}
                                        onClick={() => {
                                            setEntrataOnboarding(true)
                                            setStep(1)
                                        }}
                                    >
                                        Add Entrata Apartment
                                    </Button>
                                </Container>
                            </Container>
                        )}

                        {step === 1 && !entrataOnboarding && (
                            <ApartmentDetailForm
                                apartment={selectedApt}
                                onSubmit={(formData) => {
                                    if (selectedApt) {
                                        // handle update
                                    } else {
                                        // handle Create
                                        axiosInstance
                                            .post(
                                                'company/apartment/onboard_apartment/',
                                                formData,
                                            )
                                            .then((res) => {
                                                setPendingApartments(
                                                    (oldList) => {
                                                        return [
                                                            ...oldList,
                                                            res.data,
                                                        ]
                                                    },
                                                )

                                                setSelectedAptId(res.data.id)

                                                toast.success(
                                                    `${formData.name} Created`,
                                                )

                                                setStep(2)
                                            })
                                            .catch(() => {
                                                toast.error('error')
                                            })
                                    }
                                }}
                            />
                        )}

                        {step === 1 && entrataOnboarding && (
                            <EntrataOnboardForm />
                        )}

                        {step === 2 && (
                            <OnboardApartmentFileForm
                                apartment={selectedApt}
                                onUpdateApartment={updateApartment}
                                onPressContinue={() => setStep(3)}
                            />
                        )}

                        {step === 3 && (
                            <Container
                                style={{
                                    flex: 1,
                                    padding: theme.spacing(2),
                                    flexDirection: 'column',
                                }}
                            >
                                <span
                                    style={{
                                        ...theme.typography.h4,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    Complete {selectedApt?.name} onboarding?
                                </span>
                                <div style={{ flex: 1 }} />

                                <span
                                    style={{
                                        ...theme.typography.h6,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    Please review the apartment configuration
                                    before confirming. Once you press confirm
                                    the property will be fully onboarded and you
                                    will not be able to reset the configuration.
                                </span>

                                <div style={{ flex: 1 }} />
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        if (selectedApt) {
                                            axiosInstance
                                                .post(
                                                    `company/apartment/${selectedApt.id}/confirm_onboard/`,
                                                )
                                                .then(() => {
                                                    toast.success(
                                                        `${selectedApt.name} Complete`,
                                                    )

                                                    setPendingApartments(
                                                        (oldList) => {
                                                            return oldList.filter(
                                                                (oldApt) => {
                                                                    if (
                                                                        oldApt.id ===
                                                                        selectedAptId
                                                                    ) {
                                                                        return false
                                                                    }
                                                                    return true
                                                                },
                                                            )
                                                        },
                                                    )

                                                    setStep(0)
                                                    setSelectedAptId(-1)
                                                    props.onClose()
                                                })
                                                .catch((e) => {
                                                    toast.error(
                                                        `Problem confirming onboard: ${e}`,
                                                    )
                                                })
                                        }
                                    }}
                                >
                                    Confirm
                                </Button>
                            </Container>
                        )}

                        <Divider />
                        {step > 0 && (
                            <Stepper activeStep={step}>
                                <Step>
                                    <StepButton
                                        color="inherit"
                                        onClick={() => {
                                            setStep(0)
                                        }}
                                    >
                                        Choose Onboarding Method
                                    </StepButton>
                                </Step>

                                <Step>
                                    <StepButton
                                        color="inherit"
                                        onClick={() => {
                                            setStep(1)
                                        }}
                                    >
                                        {entrataOnboarding
                                            ? 'Onboard'
                                            : 'Apartment Details'}
                                    </StepButton>
                                </Step>
                                {!entrataOnboarding && (
                                    <Step>
                                        <StepButton
                                            color="inherit"
                                            onClick={() => {
                                                setStep(2)
                                            }}
                                        >
                                            Upload Files
                                        </StepButton>
                                    </Step>
                                )}
                                {!entrataOnboarding && (
                                    <Step>
                                        <StepButton
                                            color="inherit"
                                            onClick={() => {
                                                setStep(3)
                                            }}
                                        >
                                            Confirm
                                        </StepButton>
                                    </Step>
                                )}
                            </Stepper>
                        )}
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
