import React, { useEffect, useState } from 'react'
import {
    Button,
    CircularProgress,
    IconButton,
    TableCell,
    TableRow,
    TextField,
    useTheme,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import {
    CellData,
    Container,
    HeadCell,
    RowData,
    SimpleTable,
} from '../../components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { useAppDispatch, useCompany, useUser } from '../../hooks'
import {
    Company,
    Invoice,
    ListVendor,
    ModelMap,
    WorkorderStatus,
} from '../../models'
import { getInvoiceList, RootState } from '../../store'
import {
    axiosInstance,
    isAccountManager,
    resolveRoute,
    Routes,
    toMMDDYYYY,
    VENDOR,
} from '../../helpers'
import { useStyles } from '../../styles'
import { useHistory } from 'react-router'
import { MergeVendorsModal } from './MergeVendorsModal'
import { ApartmentVendor } from '../NationalPropertyList'
import {
    ConfigureEntrataDetails,
    EntrataGLInfo,
    EntrataVendor,
} from './ConfigureEntrataDetails'
import { toast } from 'react-toastify'
import { VendorSendInvoiceModal } from './VendorSendInvoiceModal/VendorSendInvoiceModal'
import { workorderContext } from '../../contexts'

interface CompanyInvoiceRow extends RowData {
    sent_by: CellData<string>
    sent_to: CellData<string>
    invoices: CellData<number>
    latest_date: CellData<Date>
    company: CellData<Company>
}

interface GroupedInvoice {
    company: Company
    invoices: Invoice[]
    latestDate: Date
    sentBy: Company
    sentTo: Company
}

export const InvoiceHome = () => {
    const [searchValue, setSearchValue] = useState('')
    const [openMatchVendor, setOpenMatchVendor] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const [apartmentVendors, setApartmentVendors] = useState<ApartmentVendor[]>(
        [],
    )
    const [openConfigureVendors, setOpenConfigureVendors] = useState(false)
    const [openSendInvoiceModal, setOpenSendInvoiceModel] = useState(false)

    const [entrataVendors, setEntrataVendors] = useState<EntrataVendor[]>([])
    const [entrataGLInfo, setEntrataGLInfo] = useState<EntrataGLInfo[]>([])

    const { getVendorList, vendorList } = useCompany()

    const { workspaceUser } = useUser()
    const theme = useTheme()
    const dispatch = useAppDispatch()
    const classes = useStyles()
    const history = useHistory()

    const { getWorkorderList, workorderList } = workorderContext()

    const invoiceList = useSelector(
        (state: RootState) => state.invoice.invoiceList,
    )

    const getVendorData = () => {
        dispatch(getInvoiceList({})).finally(() => setIsLoading(false))
        getWorkorderList({
            params: {
                wo_status: WorkorderStatus.APPROVED,
                all_jobsites: true,
            },
        })
    }

    useEffect(() => {
        if (workspaceUser?.active_workspace.company_type === 'APARTMENT') {
            const vendorPromise = getVendorList({ params: { my_team: true } })
            const invoicePromise = dispatch(getInvoiceList({}))
            const apartmentVendorPromise = axiosInstance.get(
                `company/apartment_vendors/`,
            )
            const EntrataVendorPromise = axiosInstance.post(
                `company/vendor/get_entrata_vendor_info_for_apartment/`,
            )
            const EntrataGLPromise = axiosInstance.post(
                `company/vendor/get_entrata_gl_accounts/`,
            )

            Promise.all([
                vendorPromise,
                invoicePromise,
                apartmentVendorPromise,
                EntrataVendorPromise,
                EntrataGLPromise,
            ])
                .then((values) => {
                    setApartmentVendors(values[2].data)
                    const tempEntrataVendors: EntrataVendor[] = []
                    values[3].data.vendors.map((vendor: EntrataVendor) => {
                        tempEntrataVendors.push({
                            id: vendor.vendorID,
                            vendorName: vendor.vendorName,
                            vendorID: vendor.vendorID,
                        })
                    })
                    tempEntrataVendors.sort((vendorA, vendorB) =>
                        vendorA.vendorName.localeCompare(vendorB.vendorName),
                    )
                    setEntrataVendors(tempEntrataVendors)

                    const tempEntrataGLInfo: EntrataGLInfo[] = []
                    values[4].data.gl_accounts.map((gl_info: EntrataGLInfo) => {
                        tempEntrataGLInfo.push({
                            id: gl_info.gl_account_id,
                            gl_account_id: gl_info.gl_account_id,
                            account_name: gl_info.account_name,
                        })
                    })
                    tempEntrataGLInfo.sort((gl_infoA, gl_infoB) =>
                        gl_infoA.account_name.localeCompare(
                            gl_infoB.account_name,
                        ),
                    )
                    setEntrataGLInfo(tempEntrataGLInfo)
                })
                .catch((e) => toast.error(e.response.data.message))
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            getVendorData()
        }
    }, [])

    let key: 'apartment' | 'vendor' = 'apartment'
    if (workspaceUser) {
        if (workspaceUser.active_workspace.company_type === 'APARTMENT') {
            key = 'vendor'
        }
    }

    // Company map is a dictionary mapping company id to the index it can be found in the
    // groupedInvoices array
    const companyMap: { [cId: number]: number } = {}
    const groupedInvoices: GroupedInvoice[] = []

    invoiceList?.forEach((inv) => {
        const company = inv[key]

        if (companyMap[company.id] === undefined) {
            // This invoices company has not been encountered yet.
            // Add the company to the company map
            // add a grouped invoice in the grouped invoice array at the end
            companyMap[company.id] = groupedInvoices.length

            groupedInvoices[groupedInvoices.length] = {
                company: company,
                invoices: [],
                latestDate: new Date(inv.created_date),
                sentBy: inv.vendor,
                sentTo: inv.apartment,
            }
        }

        // Add the invoice to the grouped invoice invoice list
        const groupedInvoice = groupedInvoices[companyMap[company.id]]
        groupedInvoice.invoices.push(inv)

        // Set the latestDate to this invoices date if it is greater than the previous max
        const checkDate = new Date(inv.created_date)
        groupedInvoice.latestDate =
            checkDate.valueOf > groupedInvoice.latestDate.valueOf
                ? checkDate
                : groupedInvoice.latestDate
    })

    // Table setup
    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    const cellStyle: React.CSSProperties = {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightRegular,
    }

    const headCells: HeadCell<CompanyInvoiceRow>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'sent_by',
            label: 'Sent By',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'sent_to',
            label: 'Sent To',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'invoices',
            label: 'Invoices',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'latest_date',
            label: 'Latest Invoice Sent',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'empty',
            label: '',
            style: headCellStyle,
        },
    ]

    const createRow = (row: GroupedInvoice): CompanyInvoiceRow => ({
        sent_by: {
            value: row.sentBy.name,
            sortValue: row.sentBy.name,
        },
        sent_to: {
            value: row.sentTo.name,
            sortValue: row.sentTo.name,
        },
        invoices: {
            value: row.invoices.length,
            sortValue: row.invoices.length,
        },
        latest_date: {
            value: row.latestDate,
            sortValue: row.latestDate.valueOf(),
        },
        company: {
            value: row.company,
            sortValue: -1,
        },
    })
    // End Table Setup

    const clickRow = (company: Company) => {
        history.push(resolveRoute(Routes.invoiceList, ':id', company.id))
    }

    const vendorMap: ModelMap<ListVendor> = {}
    vendorList.forEach((vendor) => (vendorMap[vendor.id] = vendor))

    return (
        <Container
            direction="column"
            style={{ flex: 1, height: 'calc(100vh - 104px)' }}
        >
            <Container
                style={{ marginBottom: theme.spacing(1), alignItems: 'center' }}
            >
                <span
                    style={{
                        ...theme.typography.h4,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Invoicing
                </span>
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightLight,
                        marginLeft: theme.spacing(1),
                    }}
                >
                    {groupedInvoices.length} Clients
                </span>

                <div style={{ flex: 1 }} />

                {workspaceUser?.active_workspace.company_type === VENDOR && (
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(2, 2, 2, 0),
                            backgroundColor: theme.palette.primary.dark,
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => setOpenSendInvoiceModel(true)}
                    >
                        {`Ready to Invoice (${workorderList?.length ?? 0})`}
                    </Button>
                )}

                {workspaceUser?.active_workspace.entrata_integrated && (
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(2, 2, 2, 0),
                            backgroundColor: theme.palette.primary.dark,
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => setOpenConfigureVendors(true)}
                    >
                        Configure Vendors
                    </Button>
                )}

                {isAccountManager(workspaceUser) && (
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(2, 2, 2, 0),
                            backgroundColor: '#8A8A8A',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => setOpenMatchVendor(true)}
                    >
                        Merge Vendors
                    </Button>
                )}

                {workspaceUser?.active_workspace.entrata_integrated && (
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(2, 2, 2, 0),
                            backgroundColor: theme.palette.primary.dark,
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            axiosInstance
                                .post('company/vendor/sync_entrata_vendors/')
                                .then((res) => {
                                    toast.success(res.data.message)
                                })
                                .catch((e) => {
                                    toast.error(e.response.data.message)
                                })
                        }}
                    >
                        Resync Vendors
                    </Button>
                )}

                <TextField
                    variant="outlined"
                    placeholder="Search Invoicing"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                />
            </Container>
            {isLoading ? (
                <Container flex={1} style={{ justifyContent: 'center' }}>
                    <CircularProgress
                        style={{ alignSelf: 'center', justifySelf: 'center' }}
                        size={100}
                    />
                </Container>
            ) : (
                <SimpleTable<CompanyInvoiceRow>
                    filter={(r) =>
                        r.company.value.name
                            .toLocaleLowerCase()
                            .includes(searchValue.toLocaleLowerCase())
                    }
                    rows={groupedInvoices.map((group) => createRow(group))}
                    render={(row) => {
                        return (
                            <TableRow
                                key={`INVOICE_COMPANY_${row.company.value.id}`}
                                style={{ cursor: 'pointer' }}
                                className={classes.hoverGrey300}
                                onClick={() => clickRow(row.company.value)}
                            >
                                <TableCell>
                                    <Container>
                                        <Container
                                            style={{ alignItems: 'center' }}
                                        >
                                            {row.sent_by.value}
                                        </Container>
                                        {vendorMap[row.company.value.id]
                                            ?.entrata_sync_enabled && (
                                            <Container
                                                style={{
                                                    marginLeft: theme.spacing(
                                                        1,
                                                    ),
                                                    fontWeight: 400,
                                                    fontSize: 13,
                                                    color:
                                                        theme.palette.darkGreen
                                                            .main,
                                                }}
                                            >
                                                (Sync Ready)
                                            </Container>
                                        )}
                                    </Container>
                                </TableCell>
                                <TableCell>{row.sent_to.value}</TableCell>
                                <TableCell style={cellStyle}>
                                    {row.invoices.value}
                                </TableCell>
                                <TableCell style={cellStyle}>
                                    {toMMDDYYYY(row.latest_date.value)}
                                </TableCell>
                                <TableCell align="center" style={cellStyle}>
                                    <IconButton>
                                        <ChevronRightIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                    }}
                    orderByDefault="latest_date"
                    headCells={headCells}
                />
            )}

            <MergeVendorsModal
                open={openMatchVendor}
                handleClose={() => setOpenMatchVendor(false)}
                vendors={vendorList}
            />

            <ConfigureEntrataDetails
                open={openConfigureVendors}
                handleClose={() => setOpenConfigureVendors(false)}
                apartmentVendors={apartmentVendors}
                setApartmentVendors={setApartmentVendors}
                entrataVendors={entrataVendors}
                entrataGLInfo={entrataGLInfo}
            />

            <VendorSendInvoiceModal
                open={openSendInvoiceModal}
                theme={theme}
                handleClose={() => setOpenSendInvoiceModel(false)}
                refreshData={getVendorData}
            />
        </Container>
    )
}
