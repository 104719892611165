import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'

import {
    Button,
    Modal,
    Paper,
    Slide,
    TextField,
    useTheme,
} from '@material-ui/core'
import {
    AuditPromptConfig,
    DDLease,
    MatchedLease,
    Feature,
} from '../../../../../../models/Lease/types'
import { Container } from '../../../../../../components'
import { formatCurrency, formatDate } from './CellStyle'
import { UpdateDDLeaseRequest } from '../../../../Hooks'

interface Props {
    open: boolean
    selectedLease: MatchedLease | null
    promptConfigs: AuditPromptConfig[]
    handleCloseModal: () => void
    updateDDLease: (request: UpdateDDLeaseRequest) => Promise<DDLease>
    getLeaseAudit: (leaseAuditID: number) => void
}

export const EditLeaseModal = (props: Props) => {
    const {
        open,
        handleCloseModal,
        selectedLease,
        promptConfigs,
        updateDDLease,
        getLeaseAudit,
    } = props
    const theme = useTheme()

    const [features, setFeatures] = useState<Feature[]>([])

    useEffect(() => {
        if (selectedLease !== null) {
            const editableFeatures: Feature[] = promptConfigs
                .filter((pc) => pc.category === 'rent_roll')
                .map((pc) => {
                    return {
                        promptConfig: pc,
                        rentRollValue: answerValue(
                            selectedLease.rent_roll_lease,
                            pc,
                        ),
                        leaseValue: answerValue(selectedLease.pdf_lease, pc),
                        rentRollEdited: false,
                        leaseEdited: false,
                    }
                })
            setFeatures(editableFeatures)
        }
    }, [selectedLease, promptConfigs])

    const formatValue = (format: string, value: any) => {
        if (format === 'text') {
            return value
        } else if (format === 'date') {
            return formatDate(value)
        } else if (format === 'currency') {
            return formatCurrency(value)
        } else if (format === 'bool') {
            if (value) {
                return 'Yes'
            } else {
                return 'No'
            }
        }
    }

    const answerValue = (lease: DDLease | null, prompt: AuditPromptConfig) => {
        if (lease !== null) {
            const name = prompt.dd_lease_name as keyof DDLease
            const value = lease[name]
            if (value === null || value === undefined) {
                return formatValue('text', 'Not Found')
            } else {
                return formatValue(prompt.format, value)
            }
        }
    }

    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        idx: number,
        field: 'rentRollValue' | 'leaseValue',
        editField: 'rentRollEdited' | 'leaseEdited',
    ) => {
        const updatedFeatures = [...features]
        updatedFeatures[idx] = {
            ...updatedFeatures[idx],
            [field]: event.target.value,
            [editField]: true,
        }
        setFeatures(updatedFeatures)
    }

    const handleSave = () => {
        if (selectedLease?.pdf_lease) {
            const request: UpdateDDLeaseRequest = {
                id: selectedLease?.pdf_lease.id,
                features: features,
            }
            updateDDLease(request).then(() => {
                getLeaseAudit(selectedLease.lease_audit)
                handleCloseModal()
            })
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflowY: 'scroll',
                        flexDirection: 'column',
                        height: '90%',
                        width: '80%',
                        padding: theme.spacing(2),
                    }}
                >
                    {selectedLease?.pdf_lease && (
                        <Grid
                            container
                            style={{
                                flex: 1,
                                padding: theme.spacing(1),
                            }}
                        >
                            {/* lease edit */}
                            <Grid
                                container
                                item
                                xs={5}
                                style={{
                                    flex: 1,
                                }}
                            >
                                <Grid
                                    item
                                    xs={4}
                                    style={{
                                        ...theme.typography.h6,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        borderBottom: `2px solid ${theme.palette.grey[600]}`,
                                        height: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Feature
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    style={{
                                        ...theme.typography.h6,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        borderBottom: `2px solid ${theme.palette.grey[600]}`,
                                        height: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Rent Roll
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    style={{
                                        ...theme.typography.h6,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        borderBottom: `2px solid ${theme.palette.grey[600]}`,
                                        height: 30,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Lease
                                </Grid>
                                {features.map((feat, idx) => (
                                    <React.Fragment key={idx}>
                                        <Grid
                                            item
                                            xs={4}
                                            height={50}
                                            style={{
                                                ...theme.typography.body1,
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '0 8px',
                                                backgroundColor:
                                                    feat.leaseValue ===
                                                    feat.rentRollValue
                                                        ? 'transparent'
                                                        : '#fac5c5',
                                            }}
                                        >
                                            {feat.promptConfig.display_name}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            height={50}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <TextField
                                                fullWidth
                                                value={feat.rentRollValue}
                                                variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: 30,
                                                        padding: '0 8px',
                                                        margin: '0',
                                                        lineHeight: 'normal',
                                                    },
                                                }}
                                                onChange={(e) =>
                                                    handleChange(
                                                        e,
                                                        idx,
                                                        'rentRollValue',
                                                        'rentRollEdited',
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            height={50}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '0 8px',
                                            }}
                                        >
                                            <TextField
                                                fullWidth
                                                value={feat.leaseValue}
                                                variant="outlined"
                                                InputProps={{
                                                    style: {
                                                        height: 30,
                                                        padding: '0 8px',
                                                        margin: '0',
                                                        lineHeight: 'normal',
                                                    },
                                                }}
                                                onChange={(e) =>
                                                    handleChange(
                                                        e,
                                                        idx,
                                                        'leaseValue',
                                                        'leaseEdited',
                                                    )
                                                }
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Grid>
                            {/* lease */}
                            <Grid
                                container
                                item
                                xs={7}
                                style={{
                                    flex: 1,
                                }}
                            >
                                <iframe
                                    width="100%"
                                    height="95%"
                                    loading="lazy"
                                    src={`${selectedLease.pdf_lease.lease}`}
                                ></iframe>
                            </Grid>
                        </Grid>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        style={{
                            marginTop: theme.spacing(2),
                            maxWidth: '200px',
                            minWidth: '200px',
                            color: '#fff',
                            backgroundColor: theme.palette.primary.dark,
                        }}
                    >
                        Save
                    </Button>
                </Paper>
            </Slide>
        </Modal>
    )
}
