import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme) =>
    createStyles({
        fontLarge: {
            fontSize: '40px',
        },
        fontPrimaryMain: {
            color: theme.palette.primary.main,
        },
        fontPrimaryDark: {
            color: theme.palette.primary.dark,
        },
        fontHoverPrimaryDark: {
            '&:hover': {
                color: theme.palette.primary.dark,
            },
        },
        fontHoverBlue: {
            '&:hover': {
                color: 'blue',
            },
        },
        fontHoverPrimary: {
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        fontHoverContrast: {
            '&:hover': {
                color: theme.palette.primary.contrastText,
            },
        },
        fontBrand1: {
            color: theme.palette.brand1.main,
        },
        fontHoverBrand1: {
            '&:hover': {
                color: theme.palette.brand1.main,
            },
        },
        fontSecondaryMain: {
            color: theme.palette.secondary.main,
        },
        fontHoverSecondaryMain: {
            '&:hover': {
                color: theme.palette.secondary.main,
            },
        },
        fontHoverSecondaryDark: {
            '&:hover': {
                color: theme.palette.secondary.dark,
            },
        },
        fontHoverGrey600: {
            '&:hover': {
                color: theme.palette.grey[600],
            },
        },
        hoverGrey100: {
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
        },
        hoverGrey200: {
            '&:hover': {
                backgroundColor: theme.palette.grey[200],
            },
        },
        hoverGrey300: {
            '&:hover': {
                backgroundColor: theme.palette.grey[300],
            },
        },
        hoverPrimary: {
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        hoverDarkPrimary: {
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
        hoverDarkSecondary: {
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
        },
        circle: {
            borderRadius: '50%',
        },
        boxShadow: {
            '&:hover': {
                boxShadow: theme.shadows[5],
            },
        },
        highlightCircle: {
            backgroundColor: theme.palette.grey[300],
            borderRadius: '50%',
        },
        highlightCircleOnHover: {
            '&:hover': {
                backgroundColor: theme.palette.grey[300],
                borderRadius: '50%',
            },
        },
        box60: {
            minHeight: '60px',
            maxHeight: '60px',
            minWidth: '60px',
            maxWidth: '60px',
        },
        box50: {
            minHeight: '50px',
            maxHeight: '50px',
            minWidth: '50px',
            maxWidth: '50px',
        },
        box100: {
            height: '100px',
            width: '100px',
        },
        box120: {
            height: '120px',
            width: '120px',
        },
        centerBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'none',
        },
        wrap: {
            flexWrap: 'wrap',
        },
        table: {
            minWidth: 650,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        checkBox: {
            color: theme.palette.common.black,
            '&$checked': {
                color: theme.palette.primary.main,
            },
        },
        showOnHover: {
            opacity: 0,
            '&:hover': {
                opacity: 1,
            },
        },
        hoverShadow: {
            '&:hover': {
                boxShadow: theme.shadows[5],
            },
        },
        indicator: {
            backgroundColor: theme.palette.primary.dark,
        },
        carouselScroll: {
            '& .carousel.carousel-slider': {
                overflow: 'visible',
            },
        },
    }),
)

export const useMultiSelectStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }),
)
