import React, { useEffect, useState } from 'react'
import { useAppDispatch, useUser } from '../../../../hooks'
import {
    Breadcrumbs,
    Button,
    Chip,
    FormControl,
    FormControlLabel,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Container } from '../../../../components'
import { axiosInstance } from '../../../../helpers'
import { toast } from 'react-toastify'
import { RootState, getServiceList, setWorkSpaceUser } from '../../../../store'
import { IdBoolMap, ServiceRequestCategory } from '../../../../models'
import { useSelector } from 'react-redux'
import { useMultiSelectStyles } from '../../../../styles'
import { Check, DeleteForever } from '@material-ui/icons'
import { CreateCategoryModal } from './CreateCategoryModal'

export const ServiceRequestSettings = () => {
    const { workspaceUser, updateUser } = useUser()
    const theme = useTheme()

    const dispatch = useAppDispatch()

    const [allowServiceRequest, setAllowServiceRequest] = useState(
        workspaceUser?.active_workspace.allow_service_request ? true : false,
    )

    const [serviceRequestCategories, setServiceRequestCategories] = useState<
        ServiceRequestCategory[]
    >([])

    const [openCreateCategory, setOpenCreateCategory] = useState(false)

    const serviceList = useSelector(
        (state: RootState) => state.service.serviceList,
    )

    useEffect(() => {
        dispatch(getServiceList({}))
        axiosInstance
            .get(`action-item/service-request-category/`)
            .then((res) => {
                setServiceRequestCategories(res.data)
            })
    }, [])

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    to="/settings"
                    style={{
                        color: '#8A8A8A',
                        fontSize: '15px',
                        textDecoration: 'none',
                    }}
                >
                    Settings
                </Link>
                <Typography
                    color="textPrimary"
                    style={{
                        color: '#8A8A8A',
                        fontSize: '15px',
                        textDecoration: 'none',
                    }}
                >
                    Service Request Settings
                </Typography>
            </Breadcrumbs>
            <h2
                style={{
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '40px',
                    lineHeight: '37px',
                    marginBottom: '15px',
                    marginTop: '5px',
                    color: '#2F2E41',
                }}
            >
                Service Request Settings
            </h2>
            <Container direction="column">
                <Container style={{ marginTop: theme.spacing(2) }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={allowServiceRequest}
                                color="primary"
                                onChange={(e) => {
                                    const body = {
                                        allow_service_request: e.target.checked,
                                    }
                                    axiosInstance
                                        .post(
                                            `company/apartment/set_allow_service_request/`,
                                            body,
                                        )
                                        .then((res) => {
                                            const updatedUser = workspaceUser
                                            if (updatedUser?.active_workspace) {
                                                updatedUser.active_workspace =
                                                    res.data
                                                dispatch(
                                                    setWorkSpaceUser(
                                                        updatedUser,
                                                    ),
                                                )
                                            }
                                            setAllowServiceRequest(
                                                res.data.allow_service_request,
                                            )
                                            toast.success(
                                                `Allow Service Request updated`,
                                            )
                                        })
                                        .catch((e) => {
                                            toast.error(e.response.data.message)
                                        })
                                }}
                            />
                        }
                        labelPlacement="end"
                        label="Allow Service Request"
                        style={{ marginTop: theme.spacing(2) }}
                    />
                </Container>
                <Container
                    style={{
                        fontWeight: 500,
                        fontSize: '20px',
                        marginTop: theme.spacing(2),
                    }}
                >
                    Service Request Categories:
                    <Button
                        variant="contained"
                        style={{
                            marginLeft: theme.spacing(3),
                            width: '150px',
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => setOpenCreateCategory(true)}
                    >
                        + Add Category
                    </Button>
                </Container>
                <Container
                    direction="column"
                    style={{ marginBottom: theme.spacing(4) }}
                >
                    {serviceRequestCategories.map((serviceRequestCategory) => {
                        return (
                            <ServiceRequestCategoryRow
                                serviceRequestCategory={serviceRequestCategory}
                                key={`SERVICE_REQUEST_CATEGORY_${serviceRequestCategory.id}`}
                                setServiceRequestCategories={
                                    setServiceRequestCategories
                                }
                                serviceRequestCategories={
                                    serviceRequestCategories
                                }
                            />
                        )
                    })}
                </Container>
            </Container>
            <CreateCategoryModal
                open={openCreateCategory}
                handleClose={() => setOpenCreateCategory(false)}
                serviceList={serviceList}
                serviceRequestCategories={serviceRequestCategories}
                setServiceRequestCategories={setServiceRequestCategories}
            />
        </>
    )
}

interface ServiceRequestCategoryRowProps {
    serviceRequestCategory: ServiceRequestCategory
    serviceRequestCategories: ServiceRequestCategory[]
    setServiceRequestCategories: (categories: ServiceRequestCategory[]) => void
}

const ServiceRequestCategoryRow = (props: ServiceRequestCategoryRowProps) => {
    const {
        serviceRequestCategory,
        setServiceRequestCategories,
        serviceRequestCategories,
    } = props

    const [name, setName] = useState(serviceRequestCategory.name)
    const [selectedServicesMap, setSelectedServicesMap] = useState<IdBoolMap>(
        {},
    )
    const [doValidate, setDoValidate] = useState(false)

    const serviceList = useSelector(
        (state: RootState) => state.service.serviceList,
    )

    const classes = useMultiSelectStyles()
    const theme = useTheme()

    useEffect(() => {
        const tempSelectedServicesMap: IdBoolMap = {}
        serviceRequestCategory.services.forEach(
            (service) => (tempSelectedServicesMap[service.id] = true),
        )
        setSelectedServicesMap(tempSelectedServicesMap)
    }, [])

    return (
        <Container>
            <Container
                style={{
                    border: `1px solid ${theme.palette.grey[400]}`,
                    borderRadius: '5px',
                    height: '130px',
                    width: '700px',
                    minWidth: '700px',
                    padding: theme.spacing(2),
                    marginTop: theme.spacing(2),
                    alignItems: 'center',
                    overflowY: 'auto',
                }}
            >
                <TextField
                    value={name}
                    variant="outlined"
                    size="medium"
                    title="Name"
                    placeholder="Name"
                    label="Name"
                    style={{ marginRight: theme.spacing(5) }}
                    onChange={(e) => setName(e.target.value)}
                    error={doValidate && name === ''}
                    helperText={doValidate && name === '' && 'Required'}
                />
                <FormControl className={classes.formControl}>
                    <InputLabel id="service-chip-label">Services</InputLabel>
                    <Select
                        labelId="service-chip-label"
                        id="mutiple-chip-service"
                        style={{ width: '350px' }}
                        multiple
                        value={Object.keys(selectedServicesMap).map(Number)}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            const selectedServices = event.target
                                .value as number[]

                            const newSelectedServices = selectedServices.reduce<IdBoolMap>(
                                (prev, srvId) => {
                                    return {
                                        ...prev,
                                        [srvId]: true,
                                    }
                                },
                                {},
                            )
                            setSelectedServicesMap(newSelectedServices)
                        }}
                        input={<Input id="select-multiple-chip-service" />}
                        renderValue={(selected) => (
                            <div className={classes.chips}>
                                {(selected as number[]).map((value) => {
                                    const srv = serviceList.find(
                                        (s) => s.id === value,
                                    )
                                    return (
                                        <Chip
                                            key={`SERVICE_CHIP_${
                                                srv ? srv.id : -1
                                            }`}
                                            label={srv ? srv.name : 'unknown'}
                                            className={classes.chip}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    >
                        {serviceList.map((srv) => {
                            return (
                                <MenuItem
                                    key={`SERVICE_MENU_ITEM_${srv.id}`}
                                    value={srv.id}
                                >
                                    {srv.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Container>
            <Container style={{ alignItems: 'center' }}>
                <Tooltip title="Apply">
                    <IconButton
                        component="span"
                        onClick={() => {
                            if (name === '') {
                                setDoValidate(true)
                                return
                            }
                            const services: {
                                id: number
                            }[] = []
                            serviceList.forEach((service) => {
                                if (selectedServicesMap[service.id]) {
                                    services.push({ id: service.id })
                                }
                            })
                            const body = {
                                name: name,
                                services: services,
                            }
                            axiosInstance
                                .patch(
                                    `action-item/service-request-category/${serviceRequestCategory.id}/`,
                                    body,
                                )
                                .then((res) => {
                                    const tempServiceRequestCategories = serviceRequestCategories.map(
                                        (category) => {
                                            if (category.id === res.data.id) {
                                                return res.data
                                            }
                                            return category
                                        },
                                    )
                                    setServiceRequestCategories(
                                        tempServiceRequestCategories,
                                    )
                                    toast.success(`${res.data.name} updated!`)
                                })
                                .catch((e) =>
                                    toast.error(e.response.data.message),
                                )
                        }}
                        style={{ padding: '6px' }}
                    >
                        <Check />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton
                        component="span"
                        onClick={() => {
                            if (
                                confirm(
                                    `Are you sure you want to delete ${serviceRequestCategory.name}?`,
                                )
                            ) {
                                axiosInstance
                                    .post(
                                        `action-item/service-request-category/delete/`,
                                        {
                                            service_request_category:
                                                serviceRequestCategory.id,
                                        },
                                    )
                                    .then(() => {
                                        toast.success(
                                            `${serviceRequestCategory.name} deleted`,
                                        )
                                        const tempServiceRequestCategories = serviceRequestCategories.filter(
                                            (category) =>
                                                category.id !==
                                                serviceRequestCategory.id,
                                        )
                                        setServiceRequestCategories(
                                            tempServiceRequestCategories,
                                        )
                                    })
                                    .catch((e) =>
                                        toast.error(e.response.data.message),
                                    )
                            }
                        }}
                        style={{ padding: '6px' }}
                    >
                        <DeleteForever style={{ color: 'red' }} />
                    </IconButton>
                </Tooltip>
            </Container>
        </Container>
    )
}
