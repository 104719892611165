import React, { useEffect } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import { Divider } from '@material-ui/core'

import MyTeamList from './MyTeamList'
import RecentActivities from './RecentActivities'
import { useUser } from '../../../../../hooks'
import { User } from '../../../../../models'

const useStyles = makeStyles((theme: Theme) => createStyles({}))

interface Props {
    userList: User[]
    openMsgDrawer: (channelId: number) => void
}

const MyTeam = (props: Props) => {
    const classes = useStyles()
    return (
        <>
            <MyTeamList
                userList={props.userList}
                openMsgDrawer={props.openMsgDrawer}
            />
        </>
    )
}

export default MyTeam
