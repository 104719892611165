export const DRAWER_W = 420
export const VH = '100vh'
export const TOP_TOOL_BAR_H = '56px'
export const AI_LIST_HEADER_H = '200px'
export const AI_LIST_FOOTER_H = '40px'
export const AI_HOME_HEADER = '48px'
export const JANK_SIZE = '60px'
export const LEFT_TOOL_BAR_W = '135px'
export const LEFT_COL_W = '300px'
export const MID_COL_W = '300px'
export const PADDING = '24px'
