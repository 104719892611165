import React, { useState } from 'react'
import { Container } from '../../../../components'
import { Step, StepButton, StepLabel, Stepper, Theme } from '@material-ui/core'
import {
    AreaConfig,
    IdBoolMap,
    ListVendor,
    Service,
    UnitConfig,
    parseScCompoundKeyStr,
} from '../../../../models'
import {
    CompanyDetailActionThunk,
    CreateVendorRequest,
    UpdateVendorServicesRequest,
    VendorAndContractThunk,
} from '../../../../store'
import { useServiceContract } from '../../../../hooks/useServiceContract'
import {
    StepSelectServices,
    StepVendorDetail,
    StepVendorPrices,
} from '../Components'
import { toast } from 'react-toastify'

interface Props {
    theme: Theme
    vendor: ListVendor | null
    serviceList: Service[]
    unitConfigs: UnitConfig[]
    areaConfigs: AreaConfig[]
    searchEmail: string
    vendorList: ListVendor[]
    vendorCanSeeUnitNotesMap: IdBoolMap
    updateVendorServices: (
        req: UpdateVendorServicesRequest,
    ) => CompanyDetailActionThunk
    createVendor: (req: CreateVendorRequest) => VendorAndContractThunk
}

export const AddVendor = (props: Props) => {
    const {
        theme,
        vendor,
        serviceList,
        updateVendorServices,
        vendorList,
        vendorCanSeeUnitNotesMap,
    } = props

    const [currentStep, setCurrentStep] = useState<AddVendorStep>(
        STEP_VENDOR_DETAILS,
    )

    const { setServiceContracts } = useServiceContract()

    const [createdVendorId, setCreatedVendorId] = useState(vendor?.id ?? -1)

    const title = vendor
        ? `Add ${vendor.name} to your vendors?`
        : 'Create Vendor'

    const buttonTitle = vendor ? 'Add' : 'Create'

    const setStep = (step: AddVendorStep) => {
        const nextStep = currentStep + 1

        if (step > nextStep) {
            return
        }

        setCurrentStep(step)
    }

    const stepVendor = vendorList.find((v) => v.id === createdVendorId)

    return (
        <Container
            style={{
                flexDirection: 'column',
            }}
        >
            <Container
                style={{
                    padding: theme.spacing(1),
                    justifyContent: 'center',
                }}
            >
                <Stepper activeStep={currentStep}>
                    <Step completed={currentStep > STEP_VENDOR_DETAILS}>
                        <StepButton color="inherit" onClick={() => setStep(0)}>
                            Vendor Details
                        </StepButton>
                    </Step>

                    <Step
                        completed={currentStep > STEP_SELECT_SERVICES}
                        disabled={currentStep < STEP_SELECT_SERVICES}
                    >
                        <StepButton
                            color="inherit"
                            onClick={() => {
                                setStep(1)
                            }}
                        >
                            Select Services
                        </StepButton>
                    </Step>

                    <Step
                        completed={currentStep > STEP_EDIT_PRICES}
                        disabled={(stepVendor?.services.length ?? 0) < 1}
                    >
                        <StepButton
                            color="inherit"
                            onClick={() => {
                                setStep(2)
                            }}
                        >
                            Edit Prices
                        </StepButton>
                    </Step>
                </Stepper>
            </Container>

            {currentStep === STEP_VENDOR_DETAILS && (
                <StepVendorDetail
                    vendor={vendor}
                    theme={theme}
                    searchEmail={props.searchEmail}
                    onSubmit={(body) => {
                        props
                            .createVendor({
                                body: {
                                    email: body.email,
                                    name: body.name,
                                    address:
                                        body.address.addressLine1 +
                                        ' ' +
                                        body.address.addressLine2,
                                    city: body.address.city,
                                    state: body.address.state,
                                    zip_code: body.address.zip,
                                    phone: body.phone,
                                    service_contracts: [],
                                    services: [],
                                    is_skillcat: false,
                                    in_house: body.inHouse,
                                },
                            })
                            .then((res) => {
                                toast.success('Success')
                                setCreatedVendorId(res.data.vendor.id)
                                setStep(STEP_SELECT_SERVICES)
                            })
                    }}
                    submitTitle={buttonTitle}
                    title={title}
                    vendorCanSeeUnitNotesMap={vendorCanSeeUnitNotesMap}
                />
            )}

            {currentStep === STEP_SELECT_SERVICES && stepVendor && (
                <StepSelectServices
                    serviceList={serviceList}
                    vendor={stepVendor}
                    theme={theme}
                    onPressNext={() => setStep(STEP_EDIT_PRICES)}
                    onAddService={(vendor, service) => {
                        const newServices = vendor.services.map((srv) => ({
                            id: srv.id,
                            offers: true,
                        }))

                        newServices.push({ id: service.id, offers: true })

                        updateVendorServices({
                            params: { id: vendor.id },
                            body: {
                                services: newServices,
                            },
                        })
                    }}
                    onRemoveService={(vendor, service) => {
                        const newServices = vendor.services.map((srv) => {
                            return {
                                id: srv.id,
                                offers: srv.id !== service.id,
                            }
                        })

                        updateVendorServices({
                            params: { id: vendor.id },
                            body: {
                                services: newServices,
                            },
                        })
                    }}
                />
            )}

            {currentStep === STEP_EDIT_PRICES && stepVendor && (
                <StepVendorPrices
                    {...props}
                    vendor={stepVendor}
                    onSubmit={(serviceContractMap) => {
                        const serviceContracts: any[] = []
                        Object.keys(serviceContractMap).forEach(
                            (compoundKeyStr) => {
                                const compoundKey = parseScCompoundKeyStr(
                                    compoundKeyStr,
                                )
                                if (compoundKey === null) {
                                    return
                                }

                                serviceContracts.push({
                                    vendor: compoundKey.vendorId,
                                    area_config: compoundKey.areaConfigId,
                                    unit_config: compoundKey.unitConfigId,
                                    service: compoundKey.serviceId,
                                    price: serviceContractMap[compoundKeyStr],
                                })
                            },
                        )

                        setServiceContracts({
                            body: {
                                vendor: stepVendor.id,
                                service_contracts: serviceContracts,
                            },
                        }).then(() => {
                            toast.success('Service Contracts Saved')
                        })
                    }}
                />
            )}
        </Container>
    )
}

const STEP_VENDOR_DETAILS = 0
const STEP_SELECT_SERVICES = 1
const STEP_EDIT_PRICES = 2

type AddVendorStep =
    | typeof STEP_VENDOR_DETAILS
    | typeof STEP_SELECT_SERVICES
    | typeof STEP_EDIT_PRICES
