import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Chip,
    Divider,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { Container } from '../../components'
import {
    CHOOSE_VENDOR_STEP,
    CREATE_QUESTIONS_STEP,
    REVIEW_AND_SUBMIT_STEP,
} from './CreateAndEditRFP'
import { NVLVendor } from '../../main/containers/national-vendor-list'
import { RFPVendorList, RFPVendorRow } from './RFPVendorList'
import SearchIcon from '@material-ui/icons/Search'
import { useMultiSelectStyles } from '../../styles'
import { statesList } from '../../helpers'
import {
    IdBoolMap,
    ModelMap,
    RFP,
    RFPType,
    SimpleVendor,
    convertMapToList,
} from '../../models'
import { VendorDetails } from '../../main/containers/national-vendor-list/VendorDetails'
import { CreateOrUpdateRFPRequest, rfpApi } from '../../contexts'

interface Props {
    vendorList: (NVLVendor | SimpleVendor)[]
    selectedVendorsMap: ModelMap<NVLVendor | SimpleVendor>
    completed: IdBoolMap
    selectedRFP: RFP | null
    viewMode?: boolean
    setSelectedVendorsMap: (map: ModelMap<NVLVendor | SimpleVendor>) => void
    setActiveStep: (step: number) => void
    setCompleted: (completed: IdBoolMap) => void
    updateRFPBody: () => CreateOrUpdateRFPRequest
}

export const SelectVendorsForm = (props: Props) => {
    const {
        setActiveStep,
        vendorList,
        selectedVendorsMap,
        setSelectedVendorsMap,
        selectedRFP,
        setCompleted,
        completed,
        viewMode,
        updateRFPBody,
    } = props

    const { updateRFP } = rfpApi()

    const [searchText, setSearchText] = useState('')
    const [selectedLocations, setSelectedLocations] = useState<string[]>([])

    const [selectedVendor, setSelectedVendor] = useState<
        NVLVendor | SimpleVendor | undefined
    >()

    const theme = useTheme()

    const classes = useMultiSelectStyles()

    let filteredVendors = vendorList
    if (searchText !== '') {
        filteredVendors = filteredVendors.filter((vendor) =>
            vendor.name
                .toLocaleLowerCase()
                .includes(searchText.toLocaleLowerCase()),
        )
    }
    if (selectedLocations.length !== 0) {
        filteredVendors = filteredVendors.filter((vendor) =>
            selectedLocations.includes(vendor.primary_address?.state ?? ''),
        )
    }

    const selectedVendorsList = convertMapToList(selectedVendorsMap)
    console.log(selectedVendorsList)

    return (
        <Container>
            <Container
                direction="column"
                style={{
                    padding: theme.spacing(0, 2, 2, 2),
                    width: 'calc(100vw - 550px)',
                    overflow: 'auto',
                }}
            >
                <Container
                    direction="column"
                    style={{ height: 'calc(100vh - 280px)' }}
                >
                    <Container
                        style={{
                            ...theme.typography.h5,
                            fontWeight: 600,
                        }}
                    >
                        Invite Vendors
                    </Container>
                    <Container>
                        <TextField
                            variant="outlined"
                            placeholder="Search"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            style={{
                                margin: theme.spacing(1, 2, 1, 0),
                            }}
                        />
                        <FormControl>
                            <InputLabel id="property-location-label">
                                Location
                            </InputLabel>
                            <Select
                                displayEmpty
                                labelId="property-location-label"
                                id="mutiple-chip-property-location"
                                fullWidth
                                style={{
                                    minWidth: '100px',
                                }}
                                multiple
                                value={selectedLocations}
                                MenuProps={{
                                    getContentAnchorEl: () => {
                                        return (null as unknown) as Element
                                    },
                                }}
                                onChange={(
                                    event: React.ChangeEvent<{
                                        value: unknown
                                    }>,
                                ) => {
                                    const selectedStates = event.target
                                        .value as string[]
                                    setSelectedLocations(selectedStates)
                                }}
                                input={
                                    <Input id="select-multiple-chip-property-location" />
                                }
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {(selected as string[]).map((value) => {
                                            const vnd = statesList.find(
                                                (s) => s === value,
                                            )
                                            return (
                                                <Chip
                                                    key={`LOCATION_CHIP_${
                                                        vnd ? vnd : -1
                                                    }`}
                                                    label={
                                                        vnd ? vnd : 'unknown'
                                                    }
                                                    className={classes.chip}
                                                />
                                            )
                                        })}
                                    </div>
                                )}
                            >
                                {statesList.map((state) => {
                                    return (
                                        <MenuItem
                                            key={`SLOCATION_MENU_ITEM_${state}`}
                                            value={state}
                                        >
                                            {state}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <Container flex={1} />
                        <Container direction="column">
                            {Object.keys(selectedVendorsMap).length > 0 && (
                                <Container
                                    style={{
                                        ...theme.typography.h6,
                                        fontSize: '20px',
                                        fontWeight: 600,
                                        alignItems: 'right',
                                        justifyContent: 'right',
                                    }}
                                >
                                    {Object.keys(selectedVendorsMap).length}{' '}
                                    Vendors Selected
                                </Container>
                            )}
                            <Container
                                style={{
                                    color: theme.palette.darkGreen.main,
                                    fontSize: 14,
                                    fontWeight: 400,
                                    alignItems: 'right',
                                    justifyContent: 'right',
                                }}
                            >
                                {selectedRFP?.type === RFPType.INVITE_ONLY
                                    ? 'Must select a minimum of 3 vendors'
                                    : 'Vendor Selection Optional'}
                            </Container>
                        </Container>
                    </Container>
                    <RFPVendorList
                        vendorList={filteredVendors}
                        selectedVendorsMap={selectedVendorsMap}
                        viewMode={viewMode}
                        setSelectedVendorsMap={setSelectedVendorsMap}
                        setSelectedVendor={setSelectedVendor}
                    />
                </Container>
                <Container style={{ marginTop: theme.spacing(2) }}>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: 'white',
                            color: theme.palette.primary.dark,
                            borderColor: theme.palette.primary.dark,
                            textTransform: 'none',
                            cursor: 'pointer',
                            alignSelf: 'flex-end',
                        }}
                        onClick={() => {
                            if (!viewMode) {
                                const body = updateRFPBody()
                                updateRFP(body)
                            }
                            setActiveStep(CREATE_QUESTIONS_STEP)
                        }}
                    >
                        Previous: Create Questions
                    </Button>
                    <Container flex={1} />
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            alignSelf: 'flex-end',
                        }}
                        onClick={() => {
                            if (!viewMode) {
                                const body = updateRFPBody()
                                updateRFP(body)
                            }
                            setActiveStep(REVIEW_AND_SUBMIT_STEP)
                            const tempCompleted = completed
                            tempCompleted[CHOOSE_VENDOR_STEP] = true
                            setCompleted(tempCompleted)
                        }}
                        disabled={
                            selectedRFP?.type === RFPType.INVITE_ONLY &&
                            Object.keys(selectedVendorsMap).length < 3
                        }
                    >
                        Next: Review and Submit
                    </Button>
                </Container>
            </Container>
            <Container
                style={{
                    minWidth: '350px',
                    width: '350px',
                    position: 'sticky',
                    top: 0,
                    right: 0,
                    maxHeight: '500px',
                }}
            >
                <Divider
                    orientation="vertical"
                    style={{ margin: theme.spacing(0, 2, 0, 2) }}
                />
                <Container
                    style={{
                        minWidth: '350px',
                        width: '350px',
                        flex: 1,
                        justifyContent: 'center',
                        position: 'sticky',
                    }}
                >
                    {selectedVendor ? (
                        <VendorDetails selectedVendor={selectedVendor} />
                    ) : (
                        <Container style={{ alignSelf: 'center' }}>
                            Select a vendor to view their details!
                        </Container>
                    )}
                </Container>
            </Container>
        </Container>
    )
}
