import React from 'react'
import {
    FormControlLabel,
    IconButton,
    Switch,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import { Container, EditSpan } from '../../components'
import { useAppDispatch, useUser } from '../../hooks'
import { AreaConfig } from '../../models'
import { updateAreaConfigReq } from '../../store'
import { useInfrastructureConfigStyles } from './useInfrastructureConfigStyles'
import { Add } from '@material-ui/icons'
import { isAccountManager } from '../../helpers'

interface Props {
    areaConfig: AreaConfig
    selected: boolean
    onClick: (id: number) => void
    onClickPlus: (areaConfig: AreaConfig) => void
}

export const AreaConfigRow = (props: Props) => {
    const { areaConfig, selected } = props

    const styles = useInfrastructureConfigStyles()
    const theme = useTheme()
    const dispatch = useAppDispatch()

    const { workspaceUser } = useUser()

    return (
        <Container
            style={{
                ...styles.rowStyle,
                ...styles.panelBorder,
                backgroundColor: selected
                    ? theme.palette.primary.light
                    : theme.palette.grey[200],
                alignItems: 'center',
            }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    marginRight: theme.spacing(1),
                }}
            >
                <EditSpan
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                    editIconTooltip="Edit Name"
                    onSave={(name) => {
                        dispatch(
                            updateAreaConfigReq({
                                params: {
                                    id: areaConfig.id,
                                },
                                body: {
                                    name: name,
                                },
                            }),
                        )
                    }}
                    disabled={!isAccountManager(workspaceUser)}
                >
                    {areaConfig.name}
                </EditSpan>
                <FormControlLabel
                    labelPlacement="end"
                    label="Shows on Schedule"
                    style={{ marginRight: theme.spacing(2) }}
                    disabled={!isAccountManager(workspaceUser)}
                    control={
                        <Switch
                            checked={areaConfig.shows_on_schedule}
                            color="primary"
                            onClick={() => {
                                dispatch(
                                    updateAreaConfigReq({
                                        params: {
                                            id: areaConfig.id,
                                        },
                                        body: {
                                            name: areaConfig.name,
                                            shows_on_schedule: !areaConfig.shows_on_schedule,
                                        },
                                    }),
                                )
                            }}
                        />
                    }
                />

                <span
                    style={{
                        ...theme.typography.body1,
                    }}
                >
                    {areaConfig.occupiable ? 'Occupiable' : 'Non-Occupiable'}
                </span>
            </Container>

            <div style={{ flex: 1 }} />

            <Tooltip title="Add Items">
                <IconButton
                    onClick={() => {
                        if (isAccountManager(workspaceUser)) {
                            props.onClick(areaConfig.id)
                        }
                    }}
                    disabled={!isAccountManager(workspaceUser)}
                >
                    <AccountTreeIcon />
                </IconButton>
            </Tooltip>
            {isAccountManager(workspaceUser) && (
                <Tooltip title="Add to Units">
                    <IconButton onClick={() => props.onClickPlus(areaConfig)}>
                        <Add />
                    </IconButton>
                </Tooltip>
            )}
        </Container>
    )
}
