export const PAINT_SERVICE_IMAGE = require('./paint-icon.png')
export const FULL_PAINT_IMAGE = require('./full-paint-icon.png')
export const TOUCH_PAINT_IMAGE = require('./touch-paint-icon.png')
export const CARPET_SERVICE_IMAGE = require('./carpet-icon.png')
export const CLEAN_SERVICE_IMAGE = require('./clean-icon.png')
export const CARPET_R_SERVICE_IMAGE = require('./carpet-r-icon.png')
export const TRASHOUT_SERVICE_IMAGE = require('./trashout-icon.png')
export const MAINTENANCE_SERVICE_IMAGE = require('./maintenance-icon.png')
export const CARPET_C_SERVICE_IMAGE = require('./carpet-c-icon.png')
export const FURNITURE_SERVICE_IMAGE = require('./furniture-icon.png')
export const VINYL_SERVICE_IMAGE = require('./vinyl-icon.png')
export const MISC_SERVICE_IMAGE = require('./misc-icon.png')
export const KEY_SERVICE_IMAGE = require('./key-icon.png')
export const HVAC_SERVICE_IMAGE = require('./hvac-icon.png')
export const BLINDS_SERVICE_IMAGE = require('./blinds-icon.png')
export const STAIN_SERVICE_IMAGE = require('./stain-icon.png')
export const ODOR_SERVICE_IMAGE = require('./odor-icon.png')
export const ALT_CLEAN_SERVICE_IMAGE = require('./alt-clean-icon.png')
export const APPLIANCE_SERVICE_IMAGE = require('./appliance-icon.png')
export const PEST_CONTROL_SERVICE_IMAGE = require('./pest-control-icon.png')
export const INSPECTION_SERVICE_IMAGE = require('./inspection-icon.png')
export const PRESSURE_WASH_SERVICE_IMAGE = require('./pressure-wash-icon.png')
export const OCC_WALK_SERVICE_IMAGE = require('./occ-walk-icon.png')
export const CHARGE_BACK_SERVICE_IMAGE = require('./charge-back-icon.png')
export const LIGHT_LOG_SERVICE_IMAGE = require('./light-log-icon.png')
export const ELECTRICAL_SERVICE_IMAGE = require('./electrical-icon.png')
export const PLUMBING_SERVICE_IMAGE = require('./plumbing-icon.png')
export const PREP_ROOM_CA_IMAGE = require('./prep-room-ca.png')
export const PREP_ROOM_WGA_IMAGE = require('./prep-room-wga.png')
export const INSPECTION_ROOM_CA_IMAGE = require('./inspection-room-ca.png')
export const INSPECTION_ROOM_WGA_IMAGE = require('./inspection-room-wga.png')
export const PET_SERVICE_IMAGE = require('./pet-icon.png')
export const UPGRADE_SERVICE_IMAGE = require('./upgrade-icon.png')
export const FIRE_EXTINGUISHER_SERVICE_IMAGE = require('./fire-extinguisher-icon.png')
export const FURNITURE_REMOVAL_IMAGE = require('./furniture-removal.png')
export const TOILET_REMOVAL_IMAGE = require('./toilet-removal.png')
export const FLOORING_REMOVAL_IMAGE = require('./flooring-removal.png')
export const TOILET_INSTALL_IMAGE = require('./toilet-install.png')
export const FURNITURE_INSTALL_IMAGE = require('./furniture-install.png')
export const DRYWALL_PAINT_TOUCHUP_IMAGE = require('./drywall-paint-touchup.png')
export const KITCHEN_REPLACEMENT_IMAGE = require('./kitchen-replacement.png')
export const VANITY_REPLACEMENT_IMAGE = require('./vanity-replacement.png')
export const SPARKLE_IMAGE = require('./sparkle.png')
export const ELEVATOR_IMAGE = require('./elevator.png')
export const ESG_IMAGE = require('./esg.png')
export const FIRE_ALARM_IMAGE = require('./fire-alarm.png')
export const INTERNET_IMAGE = require('./internet.png')
export const COMPUTER_IMAGE = require('./computer.png')
export const IRRIGATION_IMAGE = require('./irrigation.png')
export const GRASS_IMAGE = require('./grass.png')
export const LAUNDRY_IMAGE = require('./laundry.png')
export const POOL_IMAGE = require('./pool.png')
export const SIGNATURE_IMAGE = require('./signature.png')
export const BADGE_IMAGE = require('./badge.png')
export const SNOW_IMAGE = require('./snow.png')
export const TREE_IMAGE = require('./tree.png')
export const WATER_IMAGE = require('./water.png')
export const HEAVY_CLEAN_SERVICE_IMAGE = require('./heavy-clean.png')
export const PARTIAL_PAINT_IMAGE = require('./partial-paint.png')
export const LANDSCAPING_IMAGE = require('./landscaping.png')
export const ACFILTER_IMAGE = require('./ac-filter.png')
export const ACVACUUM_IMAGE = require('./ac-vacuum.png')
export const TOUCH_CLEAN_IMAGE = require('./touch-clean.png')
