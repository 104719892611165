import React, { useState } from 'react'

import { Button, TextField, Theme } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import { Container } from '../../../../components'
import { isEmailValid } from '../../../../helpers'
import { SearchField } from '../../../../components/SearchField'
import { VendorModalState } from '../VendorModal'

interface Props {
    theme: Theme
    searchEmail: string
    setSearchEmail: React.Dispatch<React.SetStateAction<VendorModalState>>
    onSearch: (email: string) => void
}

export const StepFindVendor = (props: Props) => {
    const { theme, searchEmail, setSearchEmail } = props

    const [doValidate, setDoValidate] = useState(false)

    const trimmedEmail = searchEmail.trim().toLocaleLowerCase()

    return (
        <Container
            direction="column"
            style={{
                height: 250,
                justifyContent: 'center',
                display: 'flex',
                margin: theme.spacing(2),
            }}
        >
            <Container>
                <span
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: theme.palette.grey[700],
                        fontSize: '18px',
                        marginBottom: theme.spacing(2),
                    }}
                >
                    Search for a vendor email
                </span>
            </Container>
            <Container direction="row">
                <SearchField
                    value={searchEmail}
                    error={doValidate && !isEmailValid(trimmedEmail)}
                    placeholder="Vendor Email"
                    onChange={(event: { target: { value: string } }) =>
                        setSearchEmail((prev) => {
                            return { ...prev, searchEmail: event.target.value }
                        })
                    }
                    helperText={
                        doValidate && !isEmailValid(trimmedEmail)
                            ? 'Invalid Email'
                            : ''
                    }
                    size="small"
                    variant="outlined"
                    type="text"
                    style={{
                        margin: theme.spacing(0, 1, 0, 0),
                    }}
                    fullWidth
                />
                <Button
                    variant="outlined"
                    style={{
                        textTransform: 'none',
                        backgroundColor: theme.palette.primary.dark,
                        color: 'white',
                        margin: theme.spacing(0, 1, 0, 1),
                    }}
                    onClick={() => {
                        setDoValidate(true)

                        if (isEmailValid(trimmedEmail)) {
                            props.onSearch(trimmedEmail)
                        }
                    }}
                >
                    <ArrowForwardIcon />
                </Button>
            </Container>
        </Container>
    )
}
