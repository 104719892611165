import React, { useState } from 'react'

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Theme,
    Tooltip,
} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import {
    ChangeOrder,
    ChangeOrderConfig,
    ChangeOrderStatus,
    SEE_INCOME_FINANCES,
    SEE_VENDOR_FINANCES,
    WorkSpaceUser,
    WorkorderStatus,
    getChangeOrderColor,
} from '../../models'
import { Container } from '../Container'
import { Attachment } from '../Message'
import { ChangeOrderConfirmDeny } from './ChangeOrderConfirmDeny'
import { hasPermission } from '../../models/Users/services'
import { currencyFormatter } from '../../helpers'

interface Props {
    changeOrder: ChangeOrder
    theme: Theme
    changeOrderConfig?: ChangeOrderConfig
    workspaceUser?: WorkSpaceUser
    approveDenyChangeOrder: (
        changeOrder: ChangeOrder,
        status: ChangeOrderStatus,
    ) => Promise<void>
    deleteChangeOrder: (changeOrder: ChangeOrder) => Promise<void>
}

export const ChangeOrderRow = (props: Props) => {
    const {
        changeOrder,
        theme,
        changeOrderConfig,
        workspaceUser,
        approveDenyChangeOrder,
        deleteChangeOrder,
    } = props

    const [openDialog, setOpenDialog] = useState(false)

    let price = changeOrderConfig?.suggested_add_price
    if (changeOrder.price) {
        price = changeOrder.price
    }

    const changeOrderTotalCost = changeOrder.quantity * (price ?? 0)

    const changeOrderIncome =
        changeOrder.quantity * (changeOrderConfig?.upstream_price ?? 0)

    let costString = ''
    if (hasPermission(workspaceUser, SEE_VENDOR_FINANCES)) {
        costString = `Cost: $${price} x ${
            changeOrder.quantity
        } = ${currencyFormatter.format(changeOrderTotalCost)}`
    }

    let incomeString = ''
    if (hasPermission(workspaceUser, SEE_INCOME_FINANCES)) {
        incomeString = `Income: $${changeOrderConfig?.upstream_price} x ${
            changeOrder.quantity
        } = ${currencyFormatter.format(changeOrderIncome)}`
    }

    let canDeleteChangeOrder = changeOrder.workorder ? true : false
    canDeleteChangeOrder =
        canDeleteChangeOrder &&
        changeOrder.workorder?.status !== WorkorderStatus.INVOICED &&
        changeOrder.workorder?.status !== WorkorderStatus.ASSIGNED

    return (
        <Container
            style={{
                margin: theme.spacing(1),
                backgroundColor: theme.palette.grey[100],
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.palette.grey[600]}`,
                minHeight: 200,
                overflowX: 'auto',
            }}
        >
            {/* Left Column: Damage Details */}
            <Container
                style={{
                    padding: theme.spacing(1),
                    minWidth: LEFT_COL_W,
                    maxWidth: LEFT_COL_W,
                }}
            >
                <Container style={{ flexDirection: 'column', flex: 1 }}>
                    {/* Type and Quantity */}
                    <Container style={{ alignItems: 'center' }}>
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {changeOrderConfig?.name}
                        </span>
                        <span
                            style={{
                                marginLeft: theme.spacing(1),
                            }}
                        >
                            x {changeOrder.quantity}
                        </span>
                    </Container>

                    {/* Folder, Path */}
                    <Container style={{ alignItems: 'center' }}>
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightMedium,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {changeOrder.location?.path ?? ''}
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeightBold,
                                    marginLeft: theme.spacing(1),
                                }}
                            >
                                {changeOrder.location?.folder_name ?? ''}
                            </span>
                        </span>
                    </Container>

                    {/* Unit */}
                    <Container>
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {changeOrder.location?.unit_name ?? ''}
                        </span>
                    </Container>

                    {/* Vendor */}
                    <Container>
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightMedium,
                            }}
                        >
                            {changeOrder.vendor?.name ?? ''}
                        </span>
                    </Container>

                    <Container style={{ flex: 1 }} />

                    <Container>
                        <span
                            style={{
                                ...theme.typography.subtitle2,
                                backgroundColor: getChangeOrderColor(
                                    changeOrder.status,
                                    theme,
                                ),
                                color: 'white',
                                borderRadius: theme.shape.borderRadius,
                                borderWidth: 1,
                                borderColor: getChangeOrderColor(
                                    changeOrder.status,
                                    theme,
                                ),
                                padding: theme.spacing(1),
                                width: 80,
                                textAlign: 'center',
                            }}
                        >
                            {changeOrder.status}
                        </span>
                        <div style={{ marginLeft: theme.spacing(2) }} />
                        {changeOrder.status === ChangeOrderStatus.PENDING && (
                            <ChangeOrderConfirmDeny
                                changeOrder={changeOrder}
                                approveDenyChangeOrder={approveDenyChangeOrder}
                            />
                        )}
                        <div style={{ flex: 1 }} />
                        {canDeleteChangeOrder && (
                            <Tooltip title="Delete">
                                <IconButton
                                    onClick={() => setOpenDialog(true)}
                                    size="small"
                                >
                                    <DeleteForeverIcon color="secondary" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Container>
                </Container>
            </Container>

            {/* Middle Column: Description */}
            <Container
                style={{
                    borderLeft: `1px solid ${theme.palette.grey[600]}`,
                    borderRight: `1px solid ${theme.palette.grey[600]}`,
                    minWidth: 300,
                    maxWidth: 300,
                    flexDirection: 'column',
                    fontWeight: theme.typography.fontWeightMedium,
                }}
            >
                <Container
                    style={{
                        flex: 2,
                        borderBottom: `1px solid ${theme.palette.grey[600]}`,
                        padding: theme.spacing(1),
                        overflow: 'auto',
                    }}
                >
                    <span>{changeOrder.description}</span>
                </Container>
                <Container
                    style={{
                        flex: 1,
                        padding: theme.spacing(1),
                        flexDirection: 'column',
                    }}
                >
                    <span style={{ height: 20 }}>{costString}</span>
                    <span style={{ height: 20 }}>{incomeString}</span>
                </Container>
            </Container>

            {/* Right Column: Attachments */}
            <Container
                style={{
                    padding: theme.spacing(1),
                    paddingBottom: 0,
                    paddingRight: 0,
                    alignItems: 'center',
                    overflowX: 'auto',
                }}
            >
                {changeOrder.image_0 && (
                    <Attachment
                        attachment={changeOrder.image_0}
                        uploaded={changeOrder.image_0_uploaded}
                        theme={theme}
                        size={180}
                    />
                )}
                {changeOrder.image_1 && (
                    <Attachment
                        attachment={changeOrder.image_1}
                        uploaded={changeOrder.image_1_uploaded}
                        theme={theme}
                        size={180}
                    />
                )}
                {changeOrder.image_2 && (
                    <Attachment
                        attachment={changeOrder.image_2}
                        uploaded={changeOrder.image_2_uploaded}
                        theme={theme}
                        size={180}
                    />
                )}
            </Container>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle id="alert-dialog-title">
                    Delete Change Order
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this Change Order?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => setOpenDialog(false)}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            deleteChangeOrder(changeOrder).then(() => {
                                setOpenDialog(false)
                            })
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

const LEFT_COL_W = 300
const CHECK_BOX_W = 42
