import { AxiosError } from 'axios'
import { AppThunk, ErrorResponse, setNetworkError, UPDATE_WORKORDER } from '..'
import { axiosInstance } from '../../helpers'
import { DateFilters, _Workorder, WorkorderStatusFilters } from '../../models'
import { CLEAR_WOKRORDER_UNREAD_COUNT } from '../workorder'
import {
    GetJobsiteServiceListActionThunk,
    GetJobsiteRequest,
    GET_JOBSITE_SERVICE_LIST_REQUEST,
    GET_JOBSITE_SERVICE_LIST_RESPONSE,
    SET_DATE_FILTER,
    JobsiteActionTypes,
    JobsiteServiceResponse,
    SET_JOBSITE_SERVICE,
    GetJobsiteLocationListActionThunk,
    GET_JOBSITE_LOCATION_LIST_REQUEST,
    GET_JOBSITE_LOCATION_LIST_RESPONSE,
    SET_WORKORDER_STATUS_FILTER,
    SET_PRIORITY_FILTER,
    JobsiteLocationResponse,
    SET_JOBSITE_LOCATION,
    GET_JOBSITE_WORKORDER_LIST_REQUEST,
    GET_JOBSITE_WORKORDER_LIST_RESPONSE,
    JobsiteRequest,
    SET_LOADING,
    CREATE_SUB_WORKORDER_REQUEST,
    CreateSubWorkorderRequest,
    JobsiteWorkorderActionThunk,
    UpdateSubWorkorderRequest,
    UPDATE_SUB_WORKORDER_REQUEST,
} from './types'

export const getJobsiteServiceList = (
    req: GetJobsiteRequest,
): AppThunk<GetJobsiteServiceListActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: GET_JOBSITE_SERVICE_LIST_REQUEST,
        })

        const url = `jobsite/services/`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch({
                type: GET_JOBSITE_SERVICE_LIST_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            // temp solution to network error not setting laoding to false bug
            dispatch({
                type: GET_JOBSITE_SERVICE_LIST_RESPONSE,
                payload: undefined,
            })
            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getJobsiteLocationList = (
    req: GetJobsiteRequest,
): AppThunk<GetJobsiteLocationListActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: GET_JOBSITE_LOCATION_LIST_REQUEST,
        })

        const url = `jobsite/locations/`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch({
                type: GET_JOBSITE_LOCATION_LIST_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            // temp solution to network error not setting laoding to false bug
            dispatch({
                type: GET_JOBSITE_LOCATION_LIST_RESPONSE,
                payload: undefined,
            })
            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getJobsiteWorkorderList = (
    req: GetJobsiteRequest,
): AppThunk<GetJobsiteLocationListActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: GET_JOBSITE_WORKORDER_LIST_REQUEST,
        })

        const url = `jobsite/workorders/`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch({
                type: GET_JOBSITE_WORKORDER_LIST_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            // temp solution to network error not setting laoding to false bug
            dispatch({
                type: GET_JOBSITE_WORKORDER_LIST_RESPONSE,
                payload: undefined,
            })
            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createSubWorkorder = (
    req: CreateSubWorkorderRequest,
): AppThunk<JobsiteWorkorderActionThunk> => {
    return async (dispatch) => {
        dispatch({
            type: CREATE_SUB_WORKORDER_REQUEST,
        })

        const url = 'workorder/sub-workorder/'

        try {
            //success
            const response = await axiosInstance.post(url, req.body)
            dispatch(
                updateJobsiteWorkorder(
                    response.data,
                    CREATE_SUB_WORKORDER_REQUEST,
                ),
            )

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>

            //set the loading to false and set the network error
            dispatch(setNetworkError(error, true))
            dispatch(setLoading(CREATE_SUB_WORKORDER_REQUEST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const updateSubWorkorder = (
    req: UpdateSubWorkorderRequest,
): AppThunk<JobsiteWorkorderActionThunk> => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_SUB_WORKORDER_REQUEST,
        })

        const url = `workorder/sub-workorder/${req.params.id}/`

        try {
            //success
            const response = await axiosInstance.patch(url, req.body)
            dispatch(
                updateJobsiteWorkorder(
                    response.data,
                    UPDATE_SUB_WORKORDER_REQUEST,
                ),
            )

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>

            //set the loading to false and set the network error
            dispatch(setNetworkError(error, true))
            dispatch(setLoading(UPDATE_SUB_WORKORDER_REQUEST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const setDateFilter = (dateFilter: DateFilters): JobsiteActionTypes => {
    return {
        type: SET_DATE_FILTER,
        payload: dateFilter,
    }
}

export const updateJobsiteWorkorder = (
    workorder: _Workorder,
    endLoading: JobsiteRequest,
): JobsiteActionTypes => {
    return {
        type: UPDATE_WORKORDER,
        workorder: workorder,
        endLoading: endLoading,
        workorderId: workorder.id,
    }
}

export const setLoading = (
    action: JobsiteRequest,
    loading: boolean,
): JobsiteActionTypes => {
    return {
        type: SET_LOADING,
        action: action,
        loading: loading,
    }
}

export const setWorkorderStatusFilter = (
    statusFilter: WorkorderStatusFilters,
): JobsiteActionTypes => {
    return {
        type: SET_WORKORDER_STATUS_FILTER,
        payload: statusFilter,
    }
}

export const setJobsiteService = (
    service: JobsiteServiceResponse,
): JobsiteActionTypes => {
    return {
        type: SET_JOBSITE_SERVICE,
        payload: service,
    }
}

export const setPriorityFilter = (priority: boolean): JobsiteActionTypes => {
    return {
        type: SET_PRIORITY_FILTER,
        payload: priority,
    }
}

export const setJobsiteLocation = (
    location: JobsiteLocationResponse,
): JobsiteActionTypes => {
    return {
        type: SET_JOBSITE_LOCATION,
        payload: location,
    }
}

export const clearJobsiteWorkorderUnreadCount = (
    woId: number,
): JobsiteActionTypes => {
    return {
        type: CLEAR_WOKRORDER_UNREAD_COUNT,
        woId: woId,
    }
}
