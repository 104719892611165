import { IdentifiableObject, ModelMap } from './types'

export const convertMapToList = <T extends IdentifiableObject>(
    map: ModelMap<T>,
) => {
    return Object.keys(map).reduce<T[]>((prev, key) => {
        const listItem = map[Number(key)]
        if (listItem === undefined) {
            return prev
        }
        return prev.concat(listItem)
    }, [])
}

export const convertListToMap = <T extends IdentifiableObject>(
    list: T[],
): ModelMap<T> => {
    return list.reduce((prev, item) => {
        return {
            ...prev,
            [item.id]: item,
        }
    }, {})
}

export const generateUUID = () => {
    return 'xxxx-xxxx-xxx-xxxx'.replace(/[x]/g, (c) => {
        const r = Math.floor(Math.random() * 16)
        return r.toString(16)
    })
}
