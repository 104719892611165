import React, { useState } from 'react'
import { IconButton, Theme, Tooltip, useTheme } from '@material-ui/core'
import { Container } from '../../../components'
import {
    AreaStatusTag,
    Schedule,
    SEE_INCOME_FINANCES,
    SEE_VENDOR_FINANCES,
    WorkorderAnalyticDict,
} from '../../../models'
import { currencyFormatter } from '../../../helpers'
import { useUser } from '../../../hooks'
import { hasPermission } from '../../../models/Users/services'

import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import AssignmentIcon from '@material-ui/icons/Assignment'

interface Props {
    analyticDict: WorkorderAnalyticDict
    scheduleDetail?: Schedule
    areaStatusTagList?: AreaStatusTag[]
}

const WORKORDER_TAB = 0
const FINANCE_TAB = 1

type TabOptions = typeof FINANCE_TAB | typeof WORKORDER_TAB

export const SchedulerAnalyticsHeadline = (props: Props) => {
    const { analyticDict, scheduleDetail, areaStatusTagList } = props
    const theme = useTheme()

    const { workspaceUser } = useUser()

    const [tab, setTab] = useState<TabOptions>(WORKORDER_TAB)

    const incomePermission = hasPermission(workspaceUser, SEE_INCOME_FINANCES)
    const costPermission = hasPermission(workspaceUser, SEE_VENDOR_FINANCES)

    const isFinanceTabVisible = incomePermission || costPermission

    const astCountMap: any = {}
    areaStatusTagList?.forEach((ast) => {
        const areaConfig = ast.area.area_config
        if (areaConfig.occupiable) {
            const astName = ast.area_status_config.name
            if (astCountMap[astName] === undefined) {
                astCountMap[astName] = 0
            }
            astCountMap[astName] += 1
        }
    })

    return (
        <Container
            style={{
                flexDirection: 'column',
                marginLeft: theme.spacing(3),
                minWidth: 425,
                flex: 1,
            }}
        >
            <Container style={{ alignItems: 'center' }}>
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Analytics
                </span>

                <Tooltip title="Work order analytics">
                    <IconButton onClick={() => setTab(WORKORDER_TAB)}>
                        <AssignmentIcon
                            color={
                                tab === WORKORDER_TAB ? 'primary' : undefined
                            }
                        />
                    </IconButton>
                </Tooltip>

                {isFinanceTabVisible && (
                    <Tooltip title="Finance analytics">
                        <IconButton onClick={() => setTab(FINANCE_TAB)}>
                            <LocalAtmIcon
                                color={
                                    tab === FINANCE_TAB ? 'primary' : undefined
                                }
                            />
                        </IconButton>
                    </Tooltip>
                )}
                <span
                    style={{
                        ...theme.typography.body2,
                        fontWeight: theme.typography.fontWeightLight,
                        marginRight: theme.spacing(1),
                    }}
                >
                    Total Units:{' '}
                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {analyticDict.unitCount}
                    </span>
                </span>

                <span
                    style={{
                        ...theme.typography.body2,
                        fontWeight: theme.typography.fontWeightLight,
                        marginRight: theme.spacing(1),
                    }}
                >
                    Services Areas:{' '}
                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {analyticDict.serviceAreaCount}
                    </span>
                </span>

                <span
                    style={{
                        ...theme.typography.body2,
                        fontWeight: theme.typography.fontWeightLight,
                    }}
                >
                    Ghost Service Areas:{' '}
                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {analyticDict.ghostServiceAreaCount}
                    </span>
                </span>
            </Container>

            {tab === FINANCE_TAB && (
                <Container>
                    {/* expenses */}
                    {costPermission && (
                        <Container style={{ marginRight: theme.spacing(2) }}>
                            <Container style={{ flexDirection: 'column' }}>
                                <span
                                    style={{
                                        ...theme.typography.body2,
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                    }}
                                >
                                    Set Budget:{' '}
                                    <span
                                        style={{
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {currencyFormatter.format(
                                            scheduleDetail?.baseline_budget ??
                                                0,
                                        )}
                                    </span>
                                </span>

                                <span
                                    style={{
                                        ...theme.typography.body2,
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                    }}
                                >
                                    Forecasted Budget:{' '}
                                    <span
                                        style={{
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {currencyFormatter.format(
                                            analyticDict.forecastedBudget,
                                        )}
                                    </span>
                                </span>
                                <span
                                    style={{
                                        ...theme.typography.body2,
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                    }}
                                >
                                    Invoiced Budget:{' '}
                                    <span
                                        style={{
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {currencyFormatter.format(
                                            analyticDict.invoicedBudget,
                                        )}
                                    </span>
                                </span>
                            </Container>
                        </Container>
                    )}

                    {/* Income */}
                    {incomePermission && (
                        <Container>
                            <Container style={{ flexDirection: 'column' }}>
                                <span
                                    style={{
                                        ...theme.typography.body2,
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                    }}
                                >
                                    Forecasted Income:{' '}
                                    <span
                                        style={{
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {currencyFormatter.format(
                                            analyticDict.incomeForecast ?? 0,
                                        )}
                                    </span>
                                </span>

                                {/* Only show profit if user has permission for both income and expense */}
                                {costPermission && (
                                    <span
                                        style={{
                                            ...theme.typography.body2,
                                            fontWeight:
                                                theme.typography
                                                    .fontWeightLight,
                                        }}
                                    >
                                        Forecasted Profit:{' '}
                                        <span
                                            style={{
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                            }}
                                        >
                                            {currencyFormatter.format(
                                                analyticDict.incomeForecast -
                                                    analyticDict.forecastedBudget,
                                            )}
                                        </span>
                                    </span>
                                )}
                            </Container>
                        </Container>
                    )}

                    {/* Pending Change Orders */}
                    <Container
                        style={{
                            flexDirection: 'column',
                            marginLeft: theme.spacing(2),
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightLight,
                            }}
                        >
                            Pending Change Orders:{' '}
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {analyticDict.changeOrderAnalytics.pendingCount}
                            </span>
                        </span>

                        {costPermission && (
                            <span
                                style={{
                                    ...theme.typography.body2,
                                    fontWeight:
                                        theme.typography.fontWeightLight,
                                }}
                            >
                                Pending Change Order cost:{' '}
                                <span
                                    style={{
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    {currencyFormatter.format(
                                        analyticDict.changeOrderAnalytics
                                            .pendingCost,
                                    )}
                                </span>
                            </span>
                        )}

                        {incomePermission && (
                            <span
                                style={{
                                    ...theme.typography.body2,
                                    fontWeight:
                                        theme.typography.fontWeightLight,
                                }}
                            >
                                Pending Change Order Income:{' '}
                                <span
                                    style={{
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    {currencyFormatter.format(
                                        analyticDict.changeOrderAnalytics
                                            .pendingIncome,
                                    )}
                                </span>
                            </span>
                        )}
                    </Container>

                    {/* Approved Change Orders */}
                    <Container
                        style={{
                            flexDirection: 'column',
                            marginLeft: theme.spacing(2),
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightLight,
                            }}
                        >
                            Approved Change Orders:{' '}
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {
                                    analyticDict.changeOrderAnalytics
                                        .approvedCount
                                }
                            </span>
                        </span>

                        {costPermission && (
                            <span
                                style={{
                                    ...theme.typography.body2,
                                    fontWeight:
                                        theme.typography.fontWeightLight,
                                }}
                            >
                                Approved Change Order cost:{' '}
                                <span
                                    style={{
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    {currencyFormatter.format(
                                        analyticDict.changeOrderAnalytics
                                            .approvedCost,
                                    )}
                                </span>
                            </span>
                        )}

                        {incomePermission && (
                            <span
                                style={{
                                    ...theme.typography.body2,
                                    fontWeight:
                                        theme.typography.fontWeightLight,
                                }}
                            >
                                Approved Change Order Income:{' '}
                                <span
                                    style={{
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    {currencyFormatter.format(
                                        analyticDict.changeOrderAnalytics
                                            .approvedIncome,
                                    )}
                                </span>
                            </span>
                        )}
                    </Container>
                </Container>
            )}

            {tab === WORKORDER_TAB && (
                <Container>
                    <Container style={{ flexDirection: 'column' }}>
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightLight,
                            }}
                        >
                            Total Work Orders:{' '}
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {analyticDict.workorderCount}
                            </span>
                        </span>

                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightLight,
                            }}
                        >
                            Behind Count:{' '}
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {analyticDict.behindTrack}
                            </span>
                        </span>

                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightLight,
                            }}
                        >
                            Not Started:{' '}
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {analyticDict.notStarted}
                            </span>
                        </span>
                    </Container>

                    <Container
                        style={{
                            flexDirection: 'column',
                            marginLeft: theme.spacing(2),
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightLight,
                            }}
                        >
                            In Progress:{' '}
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {analyticDict.IN_PROGRESS +
                                    analyticDict.PAUSE +
                                    analyticDict.GO_BACK}
                            </span>
                        </span>
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightLight,
                            }}
                        >
                            Complete:{' '}
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {analyticDict.COMPLETE}
                            </span>
                        </span>
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightLight,
                            }}
                        >
                            Approved:{' '}
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {analyticDict.APPROVED}
                            </span>
                        </span>
                    </Container>

                    <Container
                        style={{
                            flexDirection: 'column',
                            marginLeft: theme.spacing(2),
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightLight,
                            }}
                        >
                            Invoiced:{' '}
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {analyticDict.INVOICED}
                            </span>
                        </span>
                    </Container>

                    <Container
                        style={{
                            flexDirection: 'column',
                            marginLeft: theme.spacing(4),
                        }}
                    >
                        <AreaStatusTagLabelCountLabel
                            theme={theme}
                            name={'V'}
                            value={astCountMap['V']}
                        />
                        <AreaStatusTagLabelCountLabel
                            theme={theme}
                            name={'R'}
                            value={astCountMap['R']}
                        />
                        <AreaStatusTagLabelCountLabel
                            theme={theme}
                            name={'TI'}
                            value={astCountMap['TI']}
                        />
                    </Container>

                    <Container
                        style={{
                            flexDirection: 'column',
                            marginLeft: theme.spacing(4),
                        }}
                    >
                        <AreaStatusTagLabelCountLabel
                            theme={theme}
                            name={'TO'}
                            value={astCountMap['TO']}
                        />
                        <AreaStatusTagLabelCountLabel
                            theme={theme}
                            name={'EM'}
                            value={astCountMap['EM']}
                        />
                        <AreaStatusTagLabelCountLabel
                            theme={theme}
                            name={'DNT'}
                            value={astCountMap['DNT']}
                        />
                    </Container>

                    <Container
                        style={{
                            flexDirection: 'column',
                            marginLeft: theme.spacing(2),
                        }}
                    >
                        <AreaStatusTagLabelCountLabel
                            theme={theme}
                            name={'O'}
                            value={astCountMap['O']}
                        />
                        <AreaStatusTagLabelCountLabel
                            theme={theme}
                            name={'Yes'}
                            value={astCountMap['Yes']}
                        />
                        <AreaStatusTagLabelCountLabel
                            theme={theme}
                            name={'No'}
                            value={astCountMap['No']}
                        />
                    </Container>
                </Container>
            )}
        </Container>
    )
}

const AreaStatusTagLabelCountLabel = (props: {
    theme: Theme
    name: string
    value: number | undefined
}) => {
    const { theme, name, value } = props

    return (
        <Container style={{ marginRight: theme.spacing(1) }}>
            <span
                style={{
                    flex: 1,
                    width: 38,
                    ...theme.typography.body2,
                    fontWeight: theme.typography.fontWeightLight,
                }}
            >{`${name} :`}</span>
            <span
                style={{
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >
                {value ?? 0}
            </span>
        </Container>
    )
}
