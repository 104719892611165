import { ErrorActionTypes, ErrorState, SET_NETWORK_ERROR } from './types'

const initialState: ErrorState = {
    display: false,
}

export const errorReducer = (
    state = initialState,
    action: ErrorActionTypes,
): ErrorState => {
    switch (action.type) {
        case SET_NETWORK_ERROR:
            return {
                ...state,
                networkError: action.payload,
                display: action.display,
            }

        default:
            return initialState
    }
}
