import { Badge, Button, Grid, IconButton, useTheme } from '@material-ui/core'
import React from 'react'
import { Container } from '../../components'
import {
    Company,
    EDIT_VENDOR_SUBSCRIPTION_STATUS,
    IdBoolMap,
    PlanStatus,
} from '../../models'
import { PropertyRowType } from './NatPropListTable'
import { ChatBubbleOutline, ChatBubbleRounded } from '@material-ui/icons'
import { axiosInstance } from '../../helpers'
import { useMessageDrawer, useUser } from '../../hooks'
import { ApartmentVendorChannel } from './NationalPropertyList'
import { ReactComponent as EZToken } from '../../assets/EZToken.svg'
import { hasPermission } from '../../models/Users/services'
import { useStyles } from '../../styles'

interface Props {
    row: PropertyRowType
    vendorPlan: string
    setSelectedProperty: (property: Company) => void
    setMessageDrawerOpen: (open: boolean) => void
    apartmentVendorChannels: ApartmentVendorChannel[]
    setApartmentVendorChannels: (
        aptVenChannel: ApartmentVendorChannel[],
    ) => void
    unlockedPropertyMap: IdBoolMap
    setOpenBuyProperty: (open: boolean) => void
    setOpenPropertyDetail: (open: boolean) => void
}

export const PropertyRow = (props: Props) => {
    const {
        row,
        setSelectedProperty,
        vendorPlan,
        setMessageDrawerOpen,
        apartmentVendorChannels,
        setApartmentVendorChannels,
        unlockedPropertyMap,
        setOpenBuyProperty,
        setOpenPropertyDetail,
    } = props

    const theme = useTheme()
    const classes = useStyles()

    const { setChannel } = useMessageDrawer()

    const { workspaceUser } = useUser()

    const cellStyle: React.CSSProperties = {
        fontWeight: 400,
        fontSize: '15px',
        flex: 1,
    }

    const apartmentVendorChannel = apartmentVendorChannels.find(
        (aptVenChannel) => row.property.id === aptVenChannel.apartment?.id,
    )

    const disabled = !hasPermission(
        workspaceUser,
        EDIT_VENDOR_SUBSCRIPTION_STATUS,
    )

    return (
        <Grid
            container
            key={`PROPERTY_ID_${row.id}`}
            style={{
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
                borderBottom: `1px solid ${theme.palette.darkGreen.main}`,
                cursor: unlockedPropertyMap[row.property.id] ? 'pointer' : '',
            }}
            onClick={() => {
                if (unlockedPropertyMap[row.property.id]) {
                    setSelectedProperty(row.property)
                    setOpenPropertyDetail(true)
                }
            }}
            className={
                unlockedPropertyMap[row.property.id] ? classes.hoverGrey200 : ''
            }
        >
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                    alignItems: 'center',
                }}
                className={
                    unlockedPropertyMap[row.property.id]
                        ? classes.fontHoverBlue
                        : ''
                }
            >
                {row.name}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                    alignItems: 'center',
                }}
            >
                {row.address}
            </Container>
            {unlockedPropertyMap[row.property.id] ? (
                <Container
                    style={{
                        ...cellStyle,
                        flex: 2,
                        justifyContent: 'center',
                    }}
                    direction="column"
                >
                    <Container>
                        {row.property.property_manager?.phone}
                    </Container>
                    {row.property.property_manager?.phone !==
                        row.property.property_manager?.cell_phone && (
                        <Container>
                            {row.property.property_manager?.cell_phone}
                        </Container>
                    )}
                    <Container>
                        {row.property.property_manager?.email}
                    </Container>
                </Container>
            ) : (
                <Container
                    style={{
                        ...cellStyle,
                        flex: 2,
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        onClick={() => {
                            setSelectedProperty(row.property)
                            setOpenBuyProperty(true)
                        }}
                        disabled={disabled}
                    >
                        <EZToken />
                    </IconButton>
                </Container>
            )}
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                    alignItems: 'center',
                }}
            >
                {row.city}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                    alignItems: 'center',
                }}
            >
                {row.state}
            </Container>
            {vendorPlan === PlanStatus.PREMIUM && (
                <Container
                    style={{
                        ...cellStyle,
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'left',
                    }}
                >
                    {apartmentVendorChannel ? (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                setMessageDrawerOpen(false)
                                setSelectedProperty(row.property)
                                const tempApartmentVendorChannels = apartmentVendorChannels.map(
                                    (aptVenChannel) => {
                                        if (
                                            apartmentVendorChannel.channel
                                                .id === aptVenChannel.channel.id
                                        ) {
                                            const tempAptVenChannel = aptVenChannel
                                            tempAptVenChannel.channel.unread_count = 0
                                            return tempAptVenChannel
                                        }
                                        return aptVenChannel
                                    },
                                )
                                setApartmentVendorChannels(
                                    tempApartmentVendorChannels,
                                )
                                setChannel(apartmentVendorChannel.channel.id)
                                setMessageDrawerOpen(true)
                            }}
                        >
                            <Badge
                                badgeContent={
                                    apartmentVendorChannel.channel.unread_count
                                }
                                color="secondary"
                            >
                                <ChatBubbleOutline />
                            </Badge>
                        </IconButton>
                    ) : (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                setMessageDrawerOpen(false)
                                setSelectedProperty(row.property)
                                const body = {
                                    company_id: row.property.id,
                                }
                                axiosInstance
                                    .post(
                                        'company/apartment_vendors/get_or_create/',
                                        body,
                                    )
                                    .then((res) => {
                                        setChannel(res.data.channel_id)
                                        setMessageDrawerOpen(true)
                                    })
                            }}
                        >
                            <ChatBubbleOutline />
                        </IconButton>
                    )}
                </Container>
            )}
        </Grid>
    )
}
