import React, { useEffect, useMemo, useState } from 'react'

import {
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    Modal,
    Paper,
    Radio,
    RadioGroup,
    Slide,
    useTheme,
} from '@material-ui/core'
import {
    AvatarCircle,
    ConfirmationButton,
    Container,
    EllipsisSpan,
    NumberInput,
} from '../../../../components'
import {
    AreaConfig,
    Lease,
    LeaseStatus,
    LeaseStatusType,
    ModelMap,
    Unit,
    getLeaseStatusString,
    getLeaseTransition,
} from '../../../../models'
import { axiosInstance, toMMDDYYYY } from '../../../../helpers'
import { Pagination } from '@material-ui/lab'
import { toast } from 'react-toastify'

interface Props {
    open: boolean
    onClose: () => void
    leaseList: Lease[]
    unitMap: ModelMap<Unit>
    areaConfigMap: ModelMap<AreaConfig>
    setLeaseList: (leaseList: Lease[]) => void
}

const tableHeaderStyle: React.CSSProperties = {
    flex: 1,
    color: '#8A8A8A',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '18px',
    marginBottom: '16px',
    marginTop: '16px',
}

const MOVE_OUT = 0
const MOVE_IN = 1
const CANCEL = 2
const APPROVE = 3

export const BulkTransitionModal = (props: Props) => {
    const {
        open,
        onClose,
        leaseList,
        unitMap,
        areaConfigMap,
        setLeaseList,
    } = props

    const [renderLeaseList, setRenderLeaseList] = useState<Lease[]>([])
    const [selectionMode, setSelectionMode] = useState(MOVE_IN)
    const [selectedLeases, setSelectedLeases] = useState<boolean[]>(
        renderLeaseList.map(() => false),
    )
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let newLeaseList: Lease[] = []
        if (selectionMode === MOVE_IN) {
            newLeaseList = leaseList.reduce<Lease[]>((prev, lease) => {
                if (lease.status === LeaseStatus.FUTURE) {
                    return prev.concat(lease)
                }

                return prev
            }, [])
        } else if (selectionMode === MOVE_OUT) {
            newLeaseList = leaseList.reduce<Lease[]>((prev, lease) => {
                if (lease.status === LeaseStatus.CURRENT) {
                    return prev.concat(lease)
                }

                return prev
            }, [])
        } else if (selectionMode === CANCEL) {
            newLeaseList = leaseList.reduce<Lease[]>((prev, lease) => {
                if (
                    lease.status === LeaseStatus.APPLICANT ||
                    lease.status === LeaseStatus.FUTURE
                ) {
                    return prev.concat(lease)
                }

                return prev
            }, [])
        } else {
            newLeaseList = leaseList.reduce<Lease[]>((prev, lease) => {
                if (lease.status === LeaseStatus.APPLICANT) {
                    return prev.concat(lease)
                }

                return prev
            }, [])
        }
        setRenderLeaseList(newLeaseList)
        setSelectedLeases(newLeaseList.map(() => false))
    }, [leaseList, selectionMode])

    const [pageSize, setPageSize] = useState(5)
    const [page, setPage] = useState(0)

    const theme = useTheme()
    const [isLoading, setIsLoading] = useState(false)

    const numPages = Math.ceil((renderLeaseList?.length ?? 0) / pageSize)

    const paginatedList = useMemo(() => {
        const start = page * pageSize
        const end = start + pageSize

        return renderLeaseList?.slice(start, end)
    }, [page, renderLeaseList])

    const selectedLeaseCount = useMemo(() => {
        if (selectedLeases === null || renderLeaseList === undefined) {
            return 0
        }

        let selectedCount = 0
        for (let i = 0; i < selectedLeases.length; i++) {
            if (selectedLeases[i]) {
                selectedCount++
            }
        }

        return selectedCount
    }, [selectedLeases, renderLeaseList])

    const selectAllChecked = selectedLeaseCount === renderLeaseList?.length
    const selectAllIntermediate =
        selectedLeaseCount > 0 &&
        selectedLeaseCount < (renderLeaseList?.length ?? 0)

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            keepMounted
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        height: '95%',
                        width: '95%',
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                    }}
                >
                    <Container
                        style={{
                            padding: theme.spacing(2),
                            flex: 1,
                            flexDirection: 'column',
                        }}
                    >
                        <Container direction="row">
                            <span
                                style={{
                                    ...theme.typography.h4,
                                    fontWeight: theme.typography.fontWeightBold,
                                    margin: theme.spacing(2),
                                }}
                            >
                                Bulk Transition Modal
                            </span>

                            <div style={{ flex: 1 }} />
                            <RadioGroup
                                value={selectionMode}
                                style={{
                                    flexDirection: 'row',
                                    border: `1px solid ${theme.palette.common.black}`,
                                    borderRadius: theme.shape.borderRadius,
                                    marginRight: theme.spacing(1),
                                    paddingLeft: theme.spacing(1),
                                    marginBottom: theme.spacing(1),
                                }}
                            >
                                <FormControlLabel
                                    value="move_in"
                                    control={
                                        <Radio
                                            style={{
                                                color:
                                                    theme.palette.primary.dark,
                                            }}
                                        />
                                    }
                                    label="Move In"
                                    checked={selectionMode === MOVE_IN}
                                    onClick={() => setSelectionMode(MOVE_IN)}
                                />
                                <FormControlLabel
                                    value="move_out"
                                    control={
                                        <Radio
                                            style={{
                                                color:
                                                    theme.palette.primary.dark,
                                            }}
                                        />
                                    }
                                    label="Move Out"
                                    checked={selectionMode === MOVE_OUT}
                                    onClick={() => setSelectionMode(MOVE_OUT)}
                                />
                                <FormControlLabel
                                    value="cancel"
                                    control={
                                        <Radio
                                            style={{
                                                color:
                                                    theme.palette.primary.dark,
                                            }}
                                        />
                                    }
                                    label="Cancel"
                                    checked={selectionMode === CANCEL}
                                    onClick={() => setSelectionMode(CANCEL)}
                                />
                                <FormControlLabel
                                    value="cancel"
                                    control={
                                        <Radio
                                            style={{
                                                color:
                                                    theme.palette.primary.dark,
                                            }}
                                        />
                                    }
                                    label="Approve"
                                    checked={selectionMode === APPROVE}
                                    onClick={() => setSelectionMode(APPROVE)}
                                />
                            </RadioGroup>

                            <Button
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#008C85',
                                    height: '50px',
                                    width: '140px',
                                    borderRadius: '5px',
                                    alignSelf: 'center',
                                    fontSize: '14px',
                                    lineHeight: '17px',
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    textTransform: 'none',
                                    marginRight: theme.spacing(4),
                                }}
                                disabled={loading}
                                onClick={() => {
                                    if (selectedLeaseCount) {
                                        if (
                                            confirm(
                                                `This will transition ${selectedLeaseCount} leases.`,
                                            )
                                        ) {
                                            const toTransitionLeaseList: number[] = []
                                            renderLeaseList.forEach(
                                                (lease, idx) => {
                                                    if (
                                                        selectedLeases &&
                                                        selectedLeases[idx]
                                                    ) {
                                                        toTransitionLeaseList.push(
                                                            lease.id,
                                                        )
                                                    }
                                                },
                                            )
                                            let status: LeaseStatusType =
                                                LeaseStatus.APPLICANT
                                            switch (selectionMode) {
                                                case APPROVE:
                                                    status = LeaseStatus.FUTURE
                                                    break
                                                case CANCEL:
                                                    status =
                                                        LeaseStatus.CANCELLED
                                                    break
                                                case MOVE_IN:
                                                    status = LeaseStatus.CURRENT
                                                    break
                                                case MOVE_OUT:
                                                    status = LeaseStatus.PAST
                                            }
                                            setLoading(true)
                                            axiosInstance
                                                .post(
                                                    'lease/bulk_transition/',
                                                    {
                                                        leases: toTransitionLeaseList,
                                                        status: status,
                                                    },
                                                )
                                                .then((res) => {
                                                    const updatedLeaseMap: ModelMap<Lease> = {}
                                                    res.data.forEach(
                                                        (lease: Lease) => {
                                                            updatedLeaseMap[
                                                                lease.id
                                                            ] = lease
                                                        },
                                                    )
                                                    const newLeaseList = leaseList.map(
                                                        (lease, idx) => {
                                                            if (
                                                                updatedLeaseMap[
                                                                    idx
                                                                ]
                                                            ) {
                                                                return updatedLeaseMap[
                                                                    idx
                                                                ] as Lease
                                                            } else {
                                                                return lease
                                                            }
                                                        },
                                                    )
                                                    setLeaseList(newLeaseList)

                                                    toast.success('Success!')
                                                })
                                                .catch((e) => {
                                                    toast.error(e)
                                                })
                                                .finally(() => {
                                                    setLoading(false)
                                                })
                                        }
                                    } else {
                                        alert(
                                            'Please select 1 or more leases to transition.',
                                        )
                                    }
                                }}
                            >
                                Bulk Transition {`(${selectedLeaseCount})`}
                            </Button>
                        </Container>
                        <Divider />
                        {/* Main Content */}
                        {renderLeaseList.length === 0 ? (
                            <span
                                style={{
                                    ...theme.typography.h1,
                                    flex: 1,
                                    alignSelf: 'center',
                                    justifySelf: 'center',
                                }}
                            >
                                No Leases to transition
                            </span>
                        ) : (
                            <Container direction="column">
                                <Container
                                    style={{
                                        textAlign: 'center',
                                        paddingLeft: '41px',
                                        paddingRight: '41px',
                                        alignItems: 'center',
                                        height: '50px',
                                    }}
                                >
                                    <span style={tableHeaderStyle}>
                                        Tenant Info
                                    </span>
                                    <span style={tableHeaderStyle}>
                                        Location
                                    </span>
                                    <span style={tableHeaderStyle}>
                                        Unit/Area
                                    </span>
                                    <span style={tableHeaderStyle}>Start</span>
                                    <span style={tableHeaderStyle}>End</span>
                                    <span style={tableHeaderStyle}>
                                        Lease Status
                                    </span>
                                    <Container
                                        style={{
                                            ...tableHeaderStyle,

                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            marginRight: theme.spacing(1),
                                        }}
                                    >
                                        <div style={{ flex: 1 }} />
                                        <Checkbox
                                            onChange={() => {
                                                if (selectAllChecked) {
                                                    setSelectedLeases(
                                                        renderLeaseList?.map(
                                                            () => false,
                                                        ) ?? null,
                                                    )
                                                } else {
                                                    setSelectedLeases(
                                                        renderLeaseList?.map(
                                                            () => true,
                                                        ) ?? null,
                                                    )
                                                }
                                            }}
                                            checked={selectAllChecked}
                                            indeterminate={
                                                selectAllIntermediate
                                            }
                                        />
                                    </Container>
                                </Container>
                                {isLoading ? (
                                    <Container
                                        style={{
                                            flex: 1,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CircularProgress size={200} />
                                    </Container>
                                ) : (
                                    <Container
                                        style={{
                                            flexDirection: 'column',
                                            textAlign: 'center',
                                            overflowY: 'scroll',
                                        }}
                                        className="scroll-bar"
                                    >
                                        {paginatedList?.map((lease, index) => {
                                            const paginatedIndex =
                                                index + page * pageSize

                                            const idxChecked =
                                                selectedLeases[paginatedIndex]

                                            return (
                                                <LeaseRow
                                                    key={`LEASE_ROW_${lease.id}`}
                                                    lease={lease}
                                                    unitMap={unitMap}
                                                    areaConfigMap={
                                                        areaConfigMap
                                                    }
                                                    // the comparison to true is neccesarry to cause a rerender
                                                    isChecked={
                                                        selectedLeases[
                                                            paginatedIndex
                                                        ] === true
                                                    }
                                                    onCheck={() =>
                                                        setSelectedLeases(
                                                            (oldValues) => {
                                                                if (oldValues) {
                                                                    const newValues = [
                                                                        ...oldValues,
                                                                    ]
                                                                    newValues[
                                                                        paginatedIndex
                                                                    ] = !idxChecked
                                                                    return newValues
                                                                }
                                                                return oldValues
                                                            },
                                                        )
                                                    }
                                                />
                                            )
                                        })}
                                    </Container>
                                )}
                                <Container style={{ marginTop: 8 }}>
                                    <div style={{ flex: 1 }} />
                                    <Pagination
                                        page={page + 1}
                                        onChange={(_, newPage) => {
                                            setPage(newPage - 1)
                                        }}
                                        count={numPages}
                                    />

                                    <span
                                        style={{
                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: '#C4C4C4',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            fontSize: '13px',
                                            lineHeight: '15px',
                                        }}
                                    >
                                        {renderLeaseList?.length ?? 0} Rows
                                    </span>
                                </Container>
                            </Container>
                        )}
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

const LeaseRow = (props: {
    lease: Lease
    unitMap: ModelMap<Unit>
    areaConfigMap: ModelMap<AreaConfig>
    isChecked: boolean
    onCheck: () => void
}) => {
    const { lease, unitMap, areaConfigMap } = props
    const unit: Unit | undefined = unitMap[lease.area.unit]

    const areaConfig: AreaConfig | undefined =
        areaConfigMap[lease.area.area_config]

    return (
        <Container
            key={lease.id}
            style={{
                height: '80px',
                backgroundColor: '#F4F4F4',
                border: '1px solid #C4C4C4',
                borderRadius: '15px',
                marginBottom: '15px',
                padding: '41px',
                paddingTop: '11px',
                paddingBottom: '11px',
                alignItems: 'center',
                textAlign: 'center',
            }}
        >
            <Container flex={1}>
                <AvatarCircle text={lease.user.name} initials />
                <Container
                    style={{
                        flexDirection: 'column',
                        marginLeft: '13px',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                    }}
                >
                    <EllipsisSpan
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: 180,
                        }}
                    >
                        {lease.user.name}
                    </EllipsisSpan>
                    <EllipsisSpan
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: 180,
                        }}
                    >
                        {lease.user.email}
                    </EllipsisSpan>
                </Container>
            </Container>

            <Container flex={1}>
                <span style={{ width: '100%' }}>
                    {unit?.folder.path}
                    {unit?.folder.name}
                </span>
            </Container>

            <Container
                flex={1}
                style={{
                    flexDirection: 'column',
                }}
            >
                <span>{unit?.name}</span>
                <span>
                    {areaConfig?.name ?? '?'} {lease.area.area_label}
                </span>
            </Container>

            <Container flex={1}>
                <span style={{ width: '100%' }}>
                    {toMMDDYYYY(new Date(lease.start_date))}
                </span>
            </Container>

            <Container flex={1}>
                <span style={{ width: '100%' }}>
                    {toMMDDYYYY(new Date(lease.end_date))}
                </span>
            </Container>

            <Container flex={1}>
                <span style={{ width: '100%' }}>
                    {getLeaseStatusString(lease.status)}
                </span>
            </Container>
            <Container flex={1} style={{ justifyContent: 'flex-end' }}>
                <Checkbox checked={props.isChecked} onChange={props.onCheck} />
            </Container>
        </Container>
    )
}
