import { enumKeys } from '../../helpers'
import { ROOT_ID } from '../../hooks/useInfrastructure/types'
import {
    FolderRes,
    UnitRes,
    InfrastructureFilterOptions,
    LocationSelection,
    AssignmentFilter,
} from '../../store'
import { AreaConfig } from '../Config'
import { AreaStatusTag, _Workorder } from '../WorkOrder'
import { ModelMap } from '../types'
import {
    AreaStatus,
    Folder,
    NonOccStatus,
    OccStatus,
    Unit,
    UnitOccupanyType,
} from './types'

type TraverseCallBack<T> = (folder: Folder, level: number) => T

export const traverse = <T = void>(
    folder: Folder,
    callback: TraverseCallBack<T>,
    level: number = 0,
    cleanUp?: () => void,
) => {
    const rv = callback(folder, level)

    folder.children.forEach((child) => {
        traverse(child, callback, level + 1)
    })

    cleanUp && cleanUp()

    return rv
}

export const buildInfrastructure = (folders: FolderRes[], units: UnitRes[]) => {
    const root: Folder = {
        id: ROOT_ID,
        name: 'Root',
        path: '-1',
        pathIds: '-1',
        parent: null,
        apartmentOwner: null,
        units: [],
        children: [],
        lineageUnitCount: 0,
    }

    const trail = [root]
    let currentUnitIdx = 0

    folders?.forEach((folderRes) => {
        if (folderRes.parent === null) {
            folderRes.parent = ROOT_ID
        }

        let current = trail[trail.length - 1]

        while (current.id !== folderRes.parent) {
            trail.pop()
            current = trail[trail.length - 1]
        }

        const newFolder: Folder = {
            id: folderRes.id,
            name: folderRes.name,
            path: folderRes.path,
            pathIds: folderRes.path_ids,
            parent: current!,
            apartmentOwner: null,
            units: [],
            children: [],
            lineageUnitCount: 0,
        }

        // Add the units to the new folder
        if (units) {
            let currentUnit = units[currentUnitIdx]
            while (currentUnit && currentUnit.folder_id === newFolder.id) {
                const newUnit: Unit = {
                    id: currentUnit.id,
                    name: currentUnit.name,
                    folder: newFolder,
                    unit_config: currentUnit.unit_config,
                    areas: [],
                    workorders: [],
                    inspectionDamages: [],
                    inventoryInspections: [],
                    channel_id: currentUnit.channel_id,
                    unit_notes_msg_count: currentUnit.unit_notes_msg_count,
                    unread_unit_notes: currentUnit.unread_unit_notes,
                }

                newUnit.areas = [...currentUnit.areas]

                newFolder.units.push(newUnit)

                currentUnitIdx += 1
                currentUnit = units[currentUnitIdx]
            }
        }

        // Add the new folder to the children list of the parent
        current.children.push(newFolder)
        trail.push(newFolder)
    })

    return root
}

export const _filterInfrastructure = (
    root: Folder,
    locations?: LocationSelection,
    isUnitInFilter?: (unit: Unit) => boolean,
): Folder => {
    const newRoot: Folder = {
        id: ROOT_ID,
        name: 'Root',
        path: '-1',
        pathIds: '-1',
        parent: null,
        apartmentOwner: null,
        units: [],
        children: [],
        lineageUnitCount: 0,
    }

    let prunedTree: Folder | null = newRoot

    const pruneTree = (
        folder: Folder,
        ptLocations: LocationSelection,
    ): Folder | null => {
        let locationValid = false

        if (ptLocations.folder.length === 0 && ptLocations.unit.length === 0) {
            // The user has no filter selections
            locationValid = true
        } else {
            // The user is filtering by location
            if (ptLocations.folder[folder.id] !== undefined) {
                // This folder is in the users filter
                locationValid = true
            }
        }

        const currentNode: Folder = {
            ...folder,
            children: [],
        }

        if (locationValid === true) {
            // Either this folder is specifically selected in the location filter,
            // or the user has no selections in the location filter period
            // return a deep copy of this folder
            return deepCopyFolder(folder, isUnitInFilter)
        }

        //  The current node is not in the filter.
        //  There are still three cases that would require this folder
        //  be included in the pruned tree
        //      1.  This folder has a descendant that is explicitly included in the locationFilter
        //      2.  This folder has a unit that is explicitly included in the locationFilter
        //      3.  This folder has a descendant that has a unit that is explicitly included
        //          in the location filter

        // Check case #1 & case #3 recursively
        folder.children.forEach((f) => {
            const prunedChild = pruneTree(f, ptLocations)
            if (prunedChild !== null) {
                currentNode.children.push(prunedChild)
            }
        })

        // Check case #2
        if (currentNode.children.length === 0) {
            // This folder and none of its descendant are in the folder filter
            // It is still possible that this folder has a unit in the unit filter
            // if it does it should be included

            currentNode.units = currentNode.units.filter((u) => {
                return ptLocations.unit[u.id] !== undefined
            })

            if (currentNode.units.length > 0) {
                return currentNode
            }
        }

        return currentNode.children.length > 0 ? currentNode : null
    }

    if (locations) {
        // User is filtering with locations
        // prune the tree
        return pruneTree(root, locations) ?? prunedTree
    } else {
        prunedTree = deepCopyFolder(root, isUnitInFilter)
    }

    return prunedTree ?? newRoot
}

export const deepCopyFolder = (
    folder: Folder,
    isUnitInFilter?: (unit: Unit) => boolean,
): Folder | null => {
    const copyFolder = filteredFolder(folder, isUnitInFilter)

    if (copyFolder === null) {
        return null
    }

    copyFolder.children = []
    folder.children.forEach((f) => {
        const copyChild = deepCopyFolder(f, isUnitInFilter)
        if (copyChild) {
            copyFolder.children.push(copyChild)
            copyFolder.lineageUnitCount += copyChild.lineageUnitCount
        }
    })

    if (copyFolder.lineageUnitCount > 0) {
        return copyFolder
    }

    return null
}

const filteredFolder = (
    folder: Folder,
    isUnitInFilter?: (unit: Unit) => boolean,
): Folder | null => {
    // User did not pass a filter method, the folder should be returned as is
    if (isUnitInFilter === undefined) {
        return {
            ...folder,
            children: [...folder.children],
            units: [...folder.units],
            lineageUnitCount: folder.units.length,
        }
    }

    // Apply the filter method
    const units = folder.units.filter(isUnitInFilter)
    return {
        ...folder,
        lineageUnitCount: units.length,
        units: units,
    }
}

export const getLineageUnits = (folder: Folder) => {
    const units: Unit[] = []

    traverse(folder, (currentFolder) => {
        currentFolder.units.forEach((u) => units.push(u))
    })

    return units
}

export const getAreaIdLabel = (areaNumber: number) => {
    return String.fromCharCode(areaNumber + 64)
}

export const isServiceableStatus = (status: AreaStatus) => {
    switch (status) {
        case OccStatus.VACANT:
        case NonOccStatus.YES:
            return true

        case OccStatus.RENEWAL:
        case OccStatus.TRANSFER_IN:
        case OccStatus.TRANSFER_OUT:
        case OccStatus.EARLY_MOVE_IN:
        case OccStatus.OCCUPIED:
        case OccStatus.DO_NOT_TURN:
        case NonOccStatus.NO:
        default:
            return false
    }
}

export const getFlatInfrastructure = (tree: Folder) => {
    const flatInfrastructure: (Folder | Unit)[] = []
    traverse(tree, (folder) => {
        if (folder.units.length > 0) {
            flatInfrastructure.push(folder)
            folder.units.forEach((unit) => {
                flatInfrastructure.push(unit)
            })
        }
    })
    return flatInfrastructure
}

type UnitOccupancyCountMap = {
    vacantCount: number
    renewCount: number
}

export const getUnitOccupancyCountMap = (
    unit: Unit,
    areaConfigMap: ModelMap<AreaConfig>,
    areaStatusTagMap: ModelMap<AreaStatusTag>,
): UnitOccupancyCountMap => {
    let vacantCount = 0
    let renewCount = 0

    for (let i = 0; i < unit.areas.length; i++) {
        const area = unit.areas[i]

        const areaConfig = areaConfigMap[area.area_config]

        if (areaConfig === undefined) {
            continue
        }

        if (!areaConfig.shows_on_schedule) {
            continue
        }

        const areaStatusTag: AreaStatusTag | undefined =
            areaStatusTagMap[area.id]

        if (areaStatusTag === undefined) {
            vacantCount++
        } else if (areaStatusTag.area_status_config.should_service) {
            vacantCount++
        } else {
            renewCount++
        }
    }

    return {
        vacantCount: vacantCount,
        renewCount: renewCount,
    }
}

export const getUnitOccupancyType = (
    unitOccupancyCountMap: UnitOccupancyCountMap,
): UnitOccupanyType => {
    const { vacantCount, renewCount } = unitOccupancyCountMap

    if (vacantCount > 0 && renewCount > 0) {
        return UnitOccupanyType.PARTIAL
    }

    if (vacantCount === 0) {
        return UnitOccupanyType.FULL_RENEW
    }

    return UnitOccupanyType.FULL_VACANT
}

export const filterInfrastructure = (
    root: Folder,
    locations?: LocationSelection,
    isUnitInFilter?: (unit: Unit) => boolean,
): Folder => {
    const newRoot: Folder = {
        id: ROOT_ID,
        name: 'Root',
        path: '-1',
        pathIds: '-1',
        parent: null,
        apartmentOwner: null,
        units: [],
        children: [],
        lineageUnitCount: 0,
    }

    let prunedTree: Folder | null = newRoot

    const pruneTree = (
        folder: Folder,
        ptLocations: LocationSelection,
    ): Folder | null => {
        const currentNode: Folder = {
            ...folder,
            children: [],
        }

        if (ptLocations.unit.length === 0) {
            // The user has no filter selections
            return deepCopyFolder(folder, isUnitInFilter)
        }

        //  The two cases that would require this folder
        //  be included in the pruned tree
        //      1.  This folder has a unit that is explicitly included in the locationFilter
        //      2.  This folder has a descendant that has a unit that is explicitly included
        //          in the location filter

        // Check case #1 recursively
        folder.children.forEach((f) => {
            const prunedChild = pruneTree(f, ptLocations)
            if (prunedChild !== null) {
                currentNode.children.push(prunedChild)
            }
        })

        // Check case #2
        if (currentNode.children.length === 0) {
            // This folder and none of its descendant are in the folder filter
            // It is still possible that this folder has a unit in the unit filter
            // if it does it should be included

            currentNode.units = currentNode.units.filter((u) => {
                return ptLocations.unit[u.id] !== undefined
            })

            if (currentNode.units.length > 0) {
                return currentNode
            }
        }

        return currentNode.children.length > 0 ? currentNode : null
    }

    if (locations) {
        // User is filtering with locations
        // prune the tree
        return pruneTree(root, locations) ?? prunedTree
    } else {
        prunedTree = deepCopyFolder(root, isUnitInFilter)
    }

    return prunedTree ?? newRoot
}
