import { IconButton, Switch, useTheme } from '@material-ui/core'
import React from 'react'
import { Container } from '../Container'
import { RFP, RFPDocument, RFPDocumentType } from '../../models'
import { ReactComponent as DocLogo } from '../../assets/document_icon.svg'
import { ReactComponent as DocXLogo } from '../../assets/docx_icon.svg'
import { ReactComponent as PDFLogo } from '../../assets/pdf_icon.svg'
import { ReactComponent as XLSXLogo } from '../../assets/xlsx_icon.svg'
import { EditRFPDocumentRequest } from '../../contexts'
import { DeleteForever } from '@material-ui/icons'
import { useStyles } from '../../styles'

interface Props {
    document: RFPDocument
    editDocument?: (rfp: RFP, body: EditRFPDocumentRequest) => void
    rfp?: RFP
}

export const DocumentCard = (props: Props) => {
    const { document, editDocument, rfp } = props

    const theme = useTheme()
    const classes = useStyles()

    return (
        <Container
            style={{
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(1),
                maxWidth: '450px',
                minWidth: '450px',
                margin: theme.spacing(1),
                cursor: 'pointer',
            }}
            className={classes.hoverGrey300}
            alignItems="center"
            onClick={() => window.open(document.file)}
        >
            <Container>{getDocumentDisplayImage(document)}</Container>
            <Container
                style={{
                    marginLeft: theme.spacing(1),
                    overflow: 'hidden',
                }}
            >
                {document.file_name}
            </Container>
            <Container flex={1} />
            {editDocument && rfp && (
                <Container alignItems="center">
                    <Container>Private</Container>
                    <Switch
                        color="primary"
                        value={document.public}
                        onClick={(e) => {
                            e.stopPropagation()
                            const body: EditRFPDocumentRequest = {
                                document_id: document.id,
                                public: !document.public,
                            }
                            editDocument(rfp, body)
                        }}
                        checked={document.public}
                    />
                    <Container>Public</Container>
                </Container>
            )}
            <Container>
                {editDocument && rfp && (
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation()
                            if (
                                confirm(
                                    `Are you sure you want to remove ${document.file_name}`,
                                )
                            ) {
                                const body: EditRFPDocumentRequest = {
                                    document_id: document.id,
                                    remove: true,
                                }
                                editDocument(rfp, body)
                            }
                        }}
                    >
                        <DeleteForever color="secondary" />
                    </IconButton>
                )}
            </Container>
        </Container>
    )
}

export const getDocumentDisplayImage = (document: RFPDocument) => {
    const theme = useTheme()

    const logoStyle: React.CSSProperties = {
        height: '30px',
        width: '30px',
    }

    switch (document.file_type) {
        case RFPDocumentType.DOC:
            return <DocLogo style={{ ...logoStyle }} />
        case RFPDocumentType.DOCX:
            return <DocXLogo style={{ ...logoStyle }} />
        case RFPDocumentType.PDF:
            return <PDFLogo style={{ ...logoStyle }} />
        case RFPDocumentType.XLSX:
            return <XLSXLogo style={{ ...logoStyle }} />
        default:
            return (
                <img
                    key={`RFP_DOCUMENT_${document.id}`}
                    style={{
                        height: 30,
                        width: 30,
                        borderRadius: '8px',
                        objectFit: 'contain',
                        backgroundColor: 'black',
                        cursor: 'pointer',
                    }}
                    src={document.file}
                />
            )
    }
}
