import React, { useState } from 'react'
import { Chip, Tooltip } from '@material-ui/core'

import { Container, getFinderChips } from '../../components'
import { getUiCamelCase, toMMDDYYYY } from '../../helpers'
import {
    CustomStatus,
    Folder,
    InventoryConfig,
    Service,
    Unit,
    UnitConfig,
    ListVendor,
    WorkorderStatus,
    IdBoolMap,
} from '../../models'

import {
    AssignmentFilter,
    LocationSelection,
    InfrastructureFilterOptions,
    setInfrastructureFilter,
    Location,
    InspectionCompletionFilter,
    InspectionHandledFilter,
    AreaStatusFilter,
    ChangeOrderFilterMode,
    PartialFilterMode,
} from '../../store'
import { useTheme } from '@material-ui/core'
import { useAppDispatch } from '../../hooks'
import { Divider } from '@material-ui/core'
import { filteredAppliedType } from './UseFilterApplied'

interface Props {
    filter: InfrastructureFilterOptions
    locationSelection: LocationSelection
    invConfigList?: InventoryConfig[]
    serviceList?: Service[]
    vendorList?: ListVendor[]
    unitConfigList?: UnitConfig[]
    customStatusList?: CustomStatus[]
    disableChips?: boolean
    filterApplied?: filteredAppliedType
    hideInspectionChips?: boolean
    containerStyle?: React.CSSProperties
    setLocationSelection: (location?: Location) => void
    makeFilteredRequest?: () => void
}

export const InfrastructureFilterChips = (props: Props) => {
    const theme = useTheme()

    const dispatch = useAppDispatch()

    const {
        filter,
        invConfigList,
        serviceList,
        vendorList,
        unitConfigList,
        locationSelection,
        setLocationSelection,
        customStatusList,
        hideInspectionChips,
        containerStyle,
        filterApplied,
        makeFilteredRequest,
    } = props

    const chipStyle: React.CSSProperties = {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    }

    const getChipStyle = (
        applied?: boolean,
        secondaryDefault?: boolean,
    ): React.CSSProperties => {
        if (filterApplied) {
            if (applied) {
                return chipStyle
            }
            return {
                ...chipStyle,
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
            }
        }

        if (secondaryDefault) {
            return {
                ...chipStyle,
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
            }
        }
        return chipStyle
    }

    const setFilter = (newFilter: InfrastructureFilterOptions) => {
        dispatch(setInfrastructureFilter(newFilter))
    }

    const areAllApplied = () => {
        if (!filterApplied) {
            return true
        }
        let allApplied = true
        Object.keys(filterApplied).forEach((key) => {
            if (!filterApplied[key as keyof filteredAppliedType]) {
                allApplied = false
            }
        })
        return allApplied
    }

    const locationCount = Object.keys(locationSelection['unit']).reduce<number>(
        (prev, k) => {
            const typedFilter = locationSelection['unit']
            const key = k === 'length' ? k : parseInt(k)

            return (
                prev +
                (key === 'length' || typedFilter[key] === undefined ? 0 : 1)
            )
        },
        0,
    )

    let locationChip: JSX.Element | null = null
    if (locationCount > 0) {
        locationChip = (
            <Chip
                key={`SELECTED_UNIT_COUNT_CHIP`}
                disabled={props.disableChips}
                clickable
                style={getChipStyle(filterApplied?.unit, true)}
                onClick={() => {
                    setLocationSelection()
                    // setLocationSelection(l)
                }}
                label={`${locationCount} Units`}
            />
        )
    }

    return (
        <Container
            style={{
                flexDirection: 'column',
                minHeight: 40,
                ...containerStyle,
            }}
        >
            <Container
                flex={1}
                style={{
                    flexWrap: 'wrap',
                }}
            >
                {/* inv config filter chips */}
                {invConfigList && (
                    <ObjectFilterChips
                        disabled={props.disableChips}
                        filter={filter.invConfigFilter}
                        chipStyle={getChipStyle(filterApplied?.invConfigFilter)}
                        idList={invConfigList}
                        setFilter={(objFil) =>
                            setFilter({ ...filter, invConfigFilter: objFil })
                        }
                    />
                )}
                {/* service filter chips */}
                {serviceList && (
                    <ObjectFilterChips
                        disabled={props.disableChips}
                        filter={filter.serviceFilter}
                        chipStyle={getChipStyle(filterApplied?.serviceFilter)}
                        idList={serviceList}
                        setFilter={(objFil) => {
                            setFilter({ ...filter, serviceFilter: objFil })
                        }}
                    />
                )}

                {/* vendor filter chips */}
                {vendorList && (
                    <ObjectFilterChips
                        disabled={props.disableChips}
                        filter={filter.vendorFilter}
                        chipStyle={getChipStyle(filterApplied?.vendorFilter)}
                        idList={vendorList}
                        setFilter={(objFil) => {
                            setFilter({ ...filter, vendorFilter: objFil })
                        }}
                    />
                )}

                {/* unit config filter chips */}
                {unitConfigList && (
                    <ObjectFilterChips
                        disabled={props.disableChips}
                        filter={filter.unitConfigFilter}
                        chipStyle={getChipStyle(
                            filterApplied?.unitConfigFilter,
                        )}
                        idList={unitConfigList}
                        setFilter={(objFil) => {
                            setFilter({ ...filter, unitConfigFilter: objFil })
                        }}
                    />
                )}

                {/* vacant chip */}
                {filter.vacantFilter ? (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.vacantFilter)}
                        label={'Vacant'}
                        onClick={() => {
                            setFilter({ ...filter, vacantFilter: false })
                        }}
                    />
                ) : null}

                {/* priority filter chip */}
                {filter.priorityFilter ? (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.priorityFilter)}
                        label={'Priority'}
                        onClick={() => {
                            setFilter({ ...filter, priorityFilter: false })
                        }}
                    />
                ) : null}

                {/* unread filter chip */}
                {filter.unreadFilter ? (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.unreadFilter)}
                        label={'Unread'}
                        onClick={() => {
                            setFilter({ ...filter, unreadFilter: false })
                        }}
                    />
                ) : null}

                {/* has messages chip */}
                {filter.hasMessages ? (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.hasMessages)}
                        label={'Has Messages'}
                        onClick={() => {
                            setFilter({ ...filter, hasMessages: false })
                        }}
                    />
                ) : null}

                {/* ghost chip */}
                {filter.isGhost ? (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.isGhost)}
                        label={'Ghost'}
                        onClick={() => {
                            setFilter({ ...filter, isGhost: false })
                        }}
                    />
                ) : null}

                {/* ready unit chip */}
                {filter.readyUnitFilter.enabled ? (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.readyUnitFilter)}
                        label={'Ready Unit'}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                readyUnitFilter: {
                                    ...filter.readyUnitFilter,
                                    enabled: false,
                                },
                            })
                        }}
                    />
                ) : null}

                {/* Behind chip */}
                {filter.behindWorkorders ? (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.behindWorkorders)}
                        label={'Behind'}
                        onClick={() => {
                            setFilter({ ...filter, behindWorkorders: false })
                        }}
                    />
                ) : null}

                {filter.hasFlaggedItem ? (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.hasFlaggedItem)}
                        label={'Has Flag'}
                        onClick={() => {
                            setFilter({ ...filter, hasFlaggedItem: false })
                        }}
                    />
                ) : null}

                {filter.hasCostDriver ? (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.hasCostDriver)}
                        label={'Has Cost'}
                        onClick={() => {
                            setFilter({ ...filter, hasCostDriver: false })
                        }}
                    />
                ) : null}

                {/* Assignment Filter chips */}
                {filter.assignmentFilter === AssignmentFilter.Assigned && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.assignmentFilter)}
                        label={'Assigned Units'}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                assignmentFilter: AssignmentFilter.All,
                            })
                        }}
                    />
                )}

                {filter.assignmentFilter === AssignmentFilter.NotAssigned && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.assignmentFilter)}
                        label={'Unassigned Units'}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                assignmentFilter: AssignmentFilter.All,
                            })
                        }}
                    />
                )}

                {/* Partial Service Filter */}
                {filter.partialUnitFilter === PartialFilterMode.Partial && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.partialUnitFilter)}
                        label={'Partial Service'}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                partialUnitFilter: PartialFilterMode.All,
                            })
                        }}
                    />
                )}

                {filter.partialUnitFilter === PartialFilterMode.Renewed && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.partialUnitFilter)}
                        label={'No Services'}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                partialUnitFilter: PartialFilterMode.All,
                            })
                        }}
                    />
                )}

                {filter.partialUnitFilter === PartialFilterMode.Vacant && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.partialUnitFilter)}
                        label={'Full Service'}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                partialUnitFilter: PartialFilterMode.All,
                            })
                        }}
                    />
                )}

                {/* Completion Filter Chips */}

                {filter.inspectionCompletionFilter ===
                    InspectionCompletionFilter.Complete &&
                    !hideInspectionChips && (
                        <Chip
                            disabled={props.disableChips}
                            clickable
                            style={getChipStyle(
                                filterApplied?.inspectionCompletionFilter,
                            )}
                            label={'Complete'}
                            onClick={() => {
                                setFilter({
                                    ...filter,
                                    inspectionCompletionFilter:
                                        InspectionCompletionFilter.All,
                                })
                            }}
                        />
                    )}

                {filter.inspectionCompletionFilter ===
                    InspectionCompletionFilter.Assigned && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(
                            filterApplied?.inspectionCompletionFilter,
                        )}
                        label={'Assigned'}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                inspectionCompletionFilter:
                                    InspectionCompletionFilter.All,
                            })
                        }}
                    />
                )}

                {(filter.handled === InspectionHandledFilter.Handled ||
                    filter.handled === InspectionHandledFilter.NotHandled) && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.handled)}
                        label={
                            filter.handled == InspectionHandledFilter.Handled
                                ? 'Handled'
                                : 'Not Handled'
                        }
                        onClick={() => {
                            setFilter({
                                ...filter,
                                handled: InspectionHandledFilter.All,
                            })
                        }}
                    />
                )}

                {filter.hasDamages && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.hasDamages)}
                        label={'Has Damages'}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                hasDamages: false,
                            })
                        }}
                    />
                )}

                {filter.manualServiceAreas && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.manualServiceAreas)}
                        label={'Has Edited Service Areas'}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                manualServiceAreas: false,
                            })
                        }}
                    />
                )}

                {filter.hasGoBack && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.hasGoBack)}
                        label={'Has Go Back'}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                hasGoBack: false,
                            })
                        }}
                    />
                )}

                {/* bed status filter chips */}
                {Object.keys(filter.areaStatusFilter).map((key) => {
                    if (
                        filter.areaStatusFilter[key as keyof AreaStatusFilter]
                    ) {
                        if (key === 'filterLength') {
                            return null
                        }

                        const filterItem = filter.areaStatusFilter[key as any]

                        const areaStatusConfig = filterItem.areaStatusConfig

                        if (!filterItem.inFilter) {
                            return null
                        }

                        return (
                            <Chip
                                key={`AREA_STATUS-${key}`}
                                disabled={props.disableChips}
                                clickable
                                label={areaStatusConfig.name}
                                style={getChipStyle(
                                    filterApplied?.areaStatusFilter,
                                )}
                                onClick={() => {
                                    setFilter({
                                        ...filter,
                                        areaStatusFilter: {
                                            ...filter.areaStatusFilter,
                                            [key]: {
                                                inFilter: false,
                                                areaStatusConfig: areaStatusConfig,
                                            },
                                            filterLength:
                                                filter.areaStatusFilter
                                                    .filterLength - 1,
                                        },
                                    })
                                }}
                            />
                        )
                    }
                    return null
                })}

                {/* status filter chips */}
                {Object.keys(filter.statusFilter).map((key) => {
                    if (
                        filter.statusFilter[key as keyof typeof WorkorderStatus]
                    ) {
                        return (
                            <Chip
                                key={`STATUS-FILTER-${key}`}
                                disabled={props.disableChips}
                                clickable
                                label={getUiCamelCase(key)}
                                style={getChipStyle(
                                    filterApplied?.statusFilter,
                                )}
                                onClick={() => {
                                    const updatedStatusFilter =
                                        filter.statusFilter
                                    updatedStatusFilter[
                                        key as keyof typeof WorkorderStatus
                                    ] = false

                                    setFilter({
                                        ...filter,
                                        statusFilter: updatedStatusFilter,
                                    })
                                }}
                            />
                        )
                    }
                    return null
                })}

                {/* Custom status filter chips */}
                {customStatusList &&
                    Object.keys(filter.customStatusFilter).map((key) => {
                        const csId = Number(key)

                        if (filter.customStatusFilter[csId]) {
                            return (
                                <Chip
                                    key={`CUSTOM-STATUS-${key}`}
                                    disabled={props.disableChips}
                                    clickable
                                    label={
                                        customStatusList.find(
                                            (cs) => cs.id === csId,
                                        )?.name ?? '?'
                                    }
                                    style={getChipStyle(
                                        filterApplied?.customStatusFilter,
                                    )}
                                    onClick={() => {
                                        const newCsFilter = {
                                            ...filter.customStatusFilter,
                                            [csId]: true,
                                        }

                                        if (filter.customStatusFilter[csId]) {
                                            delete newCsFilter[csId]
                                        }

                                        dispatch(
                                            setInfrastructureFilter({
                                                ...filter,
                                                customStatusFilter: newCsFilter,
                                            }),
                                        )
                                    }}
                                />
                            )
                        }
                        return null
                    })}

                {/* date filter chip */}
                {filter.dateFilter.enable ? (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.dateFilter)}
                        label={`${toMMDDYYYY(
                            filter.dateFilter.range.startDate,
                        )} - ${toMMDDYYYY(filter.dateFilter.range.endDate)}`}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                dateFilter: {
                                    ...filter.dateFilter,
                                    enable: false,
                                },
                            })
                        }}
                    />
                ) : null}

                {filter.unitSearch !== '' && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.unitSearch)}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                unitSearch: '',
                            })
                        }}
                        label={`Unit Search: ${filter.unitSearch}`}
                    />
                )}

                {locationChip}

                {/* Change Order Filter Chips */}
                {filter.changeOrder === ChangeOrderFilterMode.HasAny && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={{
                            ...getChipStyle(filterApplied?.changeOrder),
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                        }}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                changeOrder: ChangeOrderFilterMode.All,
                            })
                        }}
                        label="Change Orders"
                    />
                )}

                {filter.changeOrder === ChangeOrderFilterMode.HasPending && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={{
                            ...getChipStyle(filterApplied?.changeOrder),
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                        }}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                changeOrder: ChangeOrderFilterMode.All,
                            })
                        }}
                        label="Pending Change Orders"
                    />
                )}

                {filter.changeOrder === ChangeOrderFilterMode.HasApproved && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={{
                            ...getChipStyle(filterApplied?.changeOrder),
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                        }}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                changeOrder: ChangeOrderFilterMode.All,
                            })
                        }}
                        label="Approved Change Orders"
                    />
                )}

                {filter.changeOrder === ChangeOrderFilterMode.HasDenied && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={{
                            ...getChipStyle(filterApplied?.changeOrder),
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                        }}
                        onClick={() => {
                            setFilter({
                                ...filter,
                                changeOrder: ChangeOrderFilterMode.All,
                            })
                        }}
                        label="Denied Change Orders"
                    />
                )}
                {filterApplied && (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={{
                            ...chipStyle,

                            backgroundColor: areAllApplied()
                                ? theme.palette.primary.main
                                : theme.palette.secondary.main,
                            color: areAllApplied()
                                ? theme.palette.primary.contrastText
                                : theme.palette.secondary.contrastText,
                        }}
                        onClick={makeFilteredRequest}
                        label="Apply"
                    />
                )}
                {filter.displayVacantAreas ? (
                    <Chip
                        disabled={props.disableChips}
                        clickable
                        style={getChipStyle(filterApplied?.displayVacantAreas)}
                        label={'Vacant Areas'}
                        onClick={() => {
                            setFilter({ ...filter, displayVacantAreas: false })
                        }}
                    />
                ) : null}
            </Container>
            <Divider />
        </Container>
    )
}

interface ObjectFilterChipsProps {
    filter: IdBoolMap
    idList: { id: number; name: string }[]
    setFilter: (objFil: IdBoolMap) => void
    chipStyle: React.CSSProperties
    disabled?: boolean
}

const ObjectFilterChips = (props: ObjectFilterChipsProps) => {
    // a set of chips for a filter of type ObjectFilter
    const { filter, idList, setFilter, chipStyle } = props

    return (
        <Container>
            {Object.keys(filter).map((key) => {
                if (filter[Number(key)]) {
                    return (
                        <Chip
                            key={`OBJ-${key}`}
                            disabled={props.disabled}
                            clickable
                            label={
                                idList.find((obj) => obj.id === Number(key))
                                    ?.name
                            }
                            style={chipStyle}
                            onClick={() => {
                                const reducedFilters: IdBoolMap = {}
                                Object.keys(filter).forEach((innerKey) => {
                                    if (innerKey !== key) {
                                        reducedFilters[Number(innerKey)] =
                                            filter[Number(innerKey)]
                                    }
                                })

                                setFilter(reducedFilters)
                            }}
                        />
                    )
                }
                return null
            })}
        </Container>
    )
}
