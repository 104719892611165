import React, { useState } from 'react'
import {
    ApartmentQuestion,
    ApartmentQuestionType,
    IdBoolMap,
} from '../../models'
import { CreateOrUpdateRFPRequest, rfpApi } from '../../contexts'
import {
    Button,
    IconButton,
    TextField,
    Theme,
    useTheme,
} from '@material-ui/core'
import { Container } from '../../components'
import {
    CHOOSE_VENDOR_STEP,
    CREATE_QUESTIONS_STEP,
    SCOPE_OF_WORK_STEP,
} from './CreateAndEditRFP'
import { DeleteForever } from '@material-ui/icons'

interface Props {
    generalQuestions: ApartmentQuestion[]
    financialQuestions: ApartmentQuestion[]
    completed: IdBoolMap
    viewMode?: boolean
    setGeneralQuestions: (questions: ApartmentQuestion[]) => void
    setFinancialQuestions: (questions: ApartmentQuestion[]) => void
    setActiveStep: (step: number) => void
    setCompleted: (completed: IdBoolMap) => void
}

export const ApartmentQuestionForm = (props: Props) => {
    const {
        setActiveStep,
        viewMode,
        setCompleted,
        completed,
        generalQuestions,
        setGeneralQuestions,
        financialQuestions,
        setFinancialQuestions,
    } = props

    const { updateRFP } = rfpApi()

    const theme = useTheme()

    const [doValidate, setDoValidate] = useState(false)

    const checkValidation = () => {
        let questionMissing = false
        financialQuestions.forEach((question) => {
            if (question.question_description === '') {
                setDoValidate(true)
                questionMissing = true
            }
        })
        generalQuestions.forEach((question) => {
            if (question.question_description === '') {
                setDoValidate(true)
                questionMissing = true
            }
        })
        if (financialQuestions.length === 0) {
            setDoValidate(true)
            questionMissing = true
        }
        return !questionMissing
    }

    return (
        <Container
            direction="column"
            style={{
                padding: theme.spacing(0, 2, 2, 2),
                width: 'calc(100vw - 550px)',
            }}
        >
            <Container
                style={{
                    ...theme.typography.h5,
                    fontWeight: 600,
                    marginBottom: theme.spacing(3),
                }}
            >
                Questions
            </Container>
            <Container
                style={{
                    ...theme.typography.subtitle1,
                    color: theme.palette.primary.dark,
                }}
            >
                Add general or financial questions here that you would like
                vendors to respond to!
            </Container>
            <Container
                direction="column"
                style={{ marginTop: theme.spacing(1) }}
            >
                <Container justifyContent="center" direction="column">
                    <Container
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: 600,
                        }}
                    >
                        General Questions:
                    </Container>
                    <Container>
                        {!viewMode && (
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: 'white',
                                    color: theme.palette.primary.dark,
                                    borderColor: theme.palette.primary.dark,
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                    width: '200px',
                                }}
                                onClick={() => {
                                    setGeneralQuestions([
                                        ...generalQuestions,
                                        {
                                            id: -1,
                                            question_description: '',
                                            type: ApartmentQuestionType.GENERAL,
                                        },
                                    ])
                                }}
                            >
                                + Add General Question
                            </Button>
                        )}
                        {viewMode && generalQuestions.length === 0 && (
                            <Container
                                style={{
                                    ...theme.typography.subtitle1,
                                    marginLeft: theme.spacing(2),
                                }}
                            >
                                No questions were created
                            </Container>
                        )}
                    </Container>
                </Container>
                <Container direction="column">
                    {generalQuestions.map((question, idx) => {
                        return (
                            <ApartmentQuestionRow
                                key={`GENERAL_APARTMENT_QUESTION_${idx}`}
                                apartmentQuestion={question}
                                apartmentQuestions={generalQuestions}
                                idx={idx}
                                theme={theme}
                                doValidate={doValidate}
                                viewMode={viewMode}
                                setApartmentQuestions={setGeneralQuestions}
                            />
                        )
                    })}
                </Container>
                <Container
                    direction="column"
                    style={{ marginTop: theme.spacing(4) }}
                >
                    <Container justifyContent="center" direction="column">
                        <Container
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: 600,
                            }}
                        >
                            Financial Questions:
                        </Container>
                        {doValidate && financialQuestions.length === 0 && (
                            <span
                                style={{
                                    ...theme.typography.subtitle2,
                                    color: 'red',
                                }}
                            >
                                Please provide atleast 1 financial question.
                            </span>
                        )}
                        <Container>
                            {!viewMode && (
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: 'white',
                                        color: theme.palette.primary.dark,
                                        borderColor: theme.palette.primary.dark,
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                        width: '200px',
                                    }}
                                    onClick={() => {
                                        setFinancialQuestions([
                                            ...financialQuestions,
                                            {
                                                id: -1,
                                                question_description: '',
                                                type:
                                                    ApartmentQuestionType.FINANCIAL,
                                            },
                                        ])
                                    }}
                                >
                                    + Add Financial Question
                                </Button>
                            )}
                            {viewMode && financialQuestions.length === 0 && (
                                <Container
                                    style={{
                                        ...theme.typography.subtitle1,
                                        marginLeft: theme.spacing(2),
                                    }}
                                >
                                    No questions were created
                                </Container>
                            )}
                        </Container>
                    </Container>
                    <Container direction="column">
                        {financialQuestions.map((question, idx) => {
                            return (
                                <ApartmentQuestionRow
                                    key={`FINANCIAL_APARTMENT_QUESTION_${idx}`}
                                    apartmentQuestion={question}
                                    apartmentQuestions={financialQuestions}
                                    idx={idx}
                                    theme={theme}
                                    doValidate={doValidate}
                                    viewMode={viewMode}
                                    setApartmentQuestions={
                                        setFinancialQuestions
                                    }
                                />
                            )
                        })}
                    </Container>
                </Container>
            </Container>
            <Container flex={1} />
            <Container style={{ marginTop: theme.spacing(2) }}>
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: 'white',
                        color: theme.palette.primary.dark,
                        borderColor: theme.palette.primary.dark,
                        textTransform: 'none',
                        cursor: 'pointer',
                        alignSelf: 'flex-end',
                    }}
                    onClick={() => {
                        if (!viewMode) {
                            if (checkValidation()) {
                                const body: CreateOrUpdateRFPRequest = {
                                    apartment_questions: generalQuestions.concat(
                                        financialQuestions,
                                    ),
                                }
                                updateRFP(body)
                            } else {
                                return
                            }
                        }
                        setActiveStep(SCOPE_OF_WORK_STEP)
                    }}
                >
                    Previous: Scope of Work
                </Button>
                <Container flex={1} />
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                        alignSelf: 'flex-end',
                    }}
                    onClick={() => {
                        if (!viewMode) {
                            if (checkValidation()) {
                                const body: CreateOrUpdateRFPRequest = {
                                    apartment_questions: generalQuestions.concat(
                                        financialQuestions,
                                    ),
                                }
                                updateRFP(body)
                            } else {
                                return
                            }
                        }
                        const tempCompleted = completed
                        tempCompleted[CREATE_QUESTIONS_STEP] = true
                        setCompleted(tempCompleted)
                        setActiveStep(CHOOSE_VENDOR_STEP)
                    }}
                >
                    Next: Select Vendors
                </Button>
            </Container>
        </Container>
    )
}

interface ApartmentQuestionRowProps {
    apartmentQuestion: ApartmentQuestion
    apartmentQuestions: ApartmentQuestion[]
    viewMode?: boolean
    doValidate: boolean
    theme: Theme
    idx: number
    setApartmentQuestions: (question: ApartmentQuestion[]) => void
}

const ApartmentQuestionRow = (props: ApartmentQuestionRowProps) => {
    const {
        apartmentQuestion,
        apartmentQuestions,
        viewMode,
        doValidate,
        theme,
        idx,
        setApartmentQuestions,
    } = props

    return (
        <Container>
            <TextField
                variant="outlined"
                size="small"
                value={apartmentQuestion.question_description}
                onChange={(e) => {
                    const tempApartmentQuestions = apartmentQuestions.map(
                        (apartmentQuestion, index) => {
                            if (index === idx) {
                                return {
                                    id: apartmentQuestion.id,
                                    question_description: e.target.value,
                                    type: apartmentQuestion.type,
                                }
                            }
                            return apartmentQuestion
                        },
                    )
                    setApartmentQuestions(tempApartmentQuestions)
                }}
                style={{
                    width: '100%',
                    marginTop: theme.spacing(1),
                }}
                title="Question"
                error={
                    doValidate && apartmentQuestion.question_description === ''
                }
                helperText={
                    doValidate &&
                    apartmentQuestion.question_description === '' &&
                    'Required'
                }
                disabled={viewMode}
            />
            {!viewMode && (
                <IconButton
                    onClick={() => {
                        setApartmentQuestions(
                            apartmentQuestions.reduce<ApartmentQuestion[]>(
                                (prev, apartmentQuestion, index) => {
                                    if (index === idx) {
                                        return prev
                                    }
                                    return prev.concat(apartmentQuestion)
                                },
                                [],
                            ),
                        )
                    }}
                >
                    <DeleteForever color="secondary" />
                </IconButton>
            )}
        </Container>
    )
}
