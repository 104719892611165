import React from 'react'
import { Checkbox, Theme, useTheme } from '@material-ui/core'
import { Container } from '../../../components'
import {
    currencyFormatter,
    isAccountManager,
    toMMDDYYYY,
} from '../../../helpers'
import { useUser } from '../../../hooks'
import {
    BaseWorkorder,
    getServiceImageDetails,
    getTotalPriceExpense,
    getTotalPriceIncome,
    SEE_INCOME_FINANCES,
    SEE_VENDOR_FINANCES,
    ServiceContractMap,
    Workorder,
    WorkorderStatus,
    WorkSpaceUser,
} from '../../../models'
import { hasPermission } from '../../../models/Users/services'
import { WoStatusBadge } from '../../VendorScheduler'
import { TransitionsDict } from './BulkTransitionEditor'

interface Props {
    workorder: BaseWorkorder
    transitions: TransitionsDict
    toStatus: string | undefined
    onStatusChange: (status: string | undefined) => void
    upStreamContractMap?: ServiceContractMap
    isVendor?: boolean
}

export const WorkorderRow = (props: Props) => {
    const {
        workorder,
        transitions,
        toStatus,
        onStatusChange,
        upStreamContractMap,
        isVendor,
    } = props

    const { workspaceUser } = useUser()

    const theme = useTheme()

    let validStart = true
    if (isVendor) {
        const today = new Date()
        const startDate = new Date(workorder.start_date ?? '')
        validStart = startDate <= today
    }

    const getDisabled = (status: WorkorderStatus, workorder: BaseWorkorder) => {
        if (isAccountManager(workspaceUser)) {
            return false
        }

        return !transitions[workorder.status].includes(status) || !validStart
    }

    const getIndeterminate = (
        status: WorkorderStatus,
        workorder: BaseWorkorder,
    ) => {
        if (isAccountManager(workspaceUser)) {
            return false
        }

        return !transitions[workorder.status].includes(status)
    }

    return (
        <Container
            key={workorder.id}
            style={{
                borderBottom: `1px solid ${theme.palette.grey[400]}`,
                padding: theme.spacing(1),
                flex: 1,
            }}
        >
            {isVendor ? (
                <VendorWoView theme={theme} workorder={workorder} />
            ) : (
                <ApartmentWoView
                    workorder={workorder}
                    theme={theme}
                    workspaceUser={workspaceUser}
                    upStreamContractMap={upStreamContractMap}
                />
            )}
            {isVendor && (
                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div>
                        <Checkbox
                            indeterminate={getIndeterminate(
                                WorkorderStatus.ASSIGNED,
                                workorder,
                            )}
                            disabled={getDisabled(
                                WorkorderStatus.ASSIGNED,
                                workorder,
                            )}
                            checked={toStatus === WorkorderStatus.ASSIGNED}
                            onChange={() => {
                                if (toStatus === WorkorderStatus.ASSIGNED) {
                                    return onStatusChange(undefined)
                                } else {
                                    return onStatusChange(
                                        WorkorderStatus.ASSIGNED,
                                    )
                                }
                            }}
                        />
                    </div>
                </Container>
            )}
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div>
                    <Checkbox
                        indeterminate={getIndeterminate(
                            WorkorderStatus.IN_PROGRESS,
                            workorder,
                        )}
                        disabled={getDisabled(
                            WorkorderStatus.IN_PROGRESS,
                            workorder,
                        )}
                        checked={toStatus === WorkorderStatus.IN_PROGRESS}
                        onChange={() => {
                            if (toStatus === WorkorderStatus.IN_PROGRESS) {
                                return onStatusChange(undefined)
                            } else {
                                return onStatusChange(
                                    WorkorderStatus.IN_PROGRESS,
                                )
                            }
                        }}
                    />
                </div>
            </Container>
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div>
                    <Checkbox
                        indeterminate={getIndeterminate(
                            WorkorderStatus.COMPLETE,
                            workorder,
                        )}
                        disabled={getDisabled(
                            WorkorderStatus.COMPLETE,
                            workorder,
                        )}
                        checked={toStatus === WorkorderStatus.COMPLETE}
                        onChange={() => {
                            if (toStatus === WorkorderStatus.COMPLETE) {
                                return onStatusChange(undefined)
                            } else {
                                return onStatusChange(WorkorderStatus.COMPLETE)
                            }
                        }}
                    />
                </div>
            </Container>
            {!isVendor && (
                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div>
                        <Checkbox
                            indeterminate={getIndeterminate(
                                WorkorderStatus.GO_BACK,
                                workorder,
                            )}
                            disabled={getDisabled(
                                WorkorderStatus.GO_BACK,
                                workorder,
                            )}
                            checked={toStatus === WorkorderStatus.GO_BACK}
                            onChange={() => {
                                if (toStatus === WorkorderStatus.GO_BACK) {
                                    return onStatusChange(undefined)
                                } else {
                                    return onStatusChange(
                                        WorkorderStatus.GO_BACK,
                                    )
                                }
                            }}
                        />
                    </div>
                </Container>
            )}
            {isVendor && (
                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div>
                        <Checkbox
                            indeterminate={getIndeterminate(
                                WorkorderStatus.PAUSE,
                                workorder,
                            )}
                            disabled={getDisabled(
                                WorkorderStatus.PAUSE,
                                workorder,
                            )}
                            checked={toStatus === WorkorderStatus.PAUSE}
                            onChange={() => {
                                if (toStatus === WorkorderStatus.PAUSE) {
                                    return onStatusChange(undefined)
                                } else {
                                    return onStatusChange(WorkorderStatus.PAUSE)
                                }
                            }}
                        />
                    </div>
                </Container>
            )}
            {!isVendor && (
                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div>
                        <Checkbox
                            indeterminate={getIndeterminate(
                                WorkorderStatus.PRE_APPROVED,
                                workorder,
                            )}
                            disabled={getDisabled(
                                WorkorderStatus.PRE_APPROVED,
                                workorder,
                            )}
                            checked={
                                transitions[workorder.status].includes(
                                    WorkorderStatus.PRE_APPROVED,
                                ) && toStatus === WorkorderStatus.PRE_APPROVED
                            }
                            onChange={() => {
                                if (toStatus === WorkorderStatus.PRE_APPROVED) {
                                    return onStatusChange(undefined)
                                } else {
                                    return onStatusChange(
                                        WorkorderStatus.PRE_APPROVED,
                                    )
                                }
                            }}
                        />
                    </div>
                </Container>
            )}
            {!isVendor && (
                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div>
                        <Checkbox
                            indeterminate={getIndeterminate(
                                WorkorderStatus.APPROVED,
                                workorder,
                            )}
                            disabled={getDisabled(
                                WorkorderStatus.APPROVED,
                                workorder,
                            )}
                            checked={toStatus === WorkorderStatus.APPROVED}
                            onChange={() => {
                                if (toStatus === WorkorderStatus.APPROVED) {
                                    return onStatusChange(undefined)
                                } else {
                                    return onStatusChange(
                                        WorkorderStatus.APPROVED,
                                    )
                                }
                            }}
                        />
                    </div>
                </Container>
            )}
        </Container>
    )
}

interface ApartmentWoViewProps {
    workorder: BaseWorkorder
    theme: Theme
    upStreamContractMap?: ServiceContractMap
    workspaceUser?: WorkSpaceUser
}

const ApartmentWoView = (props: ApartmentWoViewProps) => {
    const { workorder, upStreamContractMap, theme, workspaceUser } = props

    return (
        <Container style={{ flex: 1, justifyContent: 'center' }}>
            <Container style={{ flex: 2, flexDirection: 'column' }}>
                <Container style={{ flex: 1 }}>
                    {workorder.path} - {workorder.unit_name ?? ''} (
                    {workorder.service_area_list.length})
                </Container>
                <Container
                    style={{
                        flex: 1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {workorder.vendor_name} / {workorder.service_name ?? ''}
                </Container>

                {workorder.start_date !== null && (
                    <Container style={{ flex: 1 }}>
                        {toMMDDYYYY(new Date(workorder.start_date ?? ''))} -{' '}
                        {toMMDDYYYY(new Date(workorder.end_date ?? ''))}
                    </Container>
                )}
            </Container>
            <Container style={{ flex: 1, flexDirection: 'column' }}>
                <Container style={{ flex: 1, justifyContent: 'right' }}>
                    <WoStatusBadge
                        theme={theme}
                        status={workorder.status}
                        style={{
                            text: {
                                fontWeight: theme.typography.fontWeightBold,
                            },
                            container: {
                                margin: theme.spacing(1, 0, 1, 1),
                            },
                        }}
                    />
                </Container>
                <Container style={{ flex: 1, justifyContent: 'right' }}>
                    {hasPermission(workspaceUser, SEE_VENDOR_FINANCES) && (
                        <span
                            style={{
                                ...theme.typography.caption,
                                fontWeight: 'bold',
                                marginRight: theme.spacing(1),
                            }}
                        >
                            (
                            {currencyFormatter.format(
                                getTotalPriceExpense(workorder),
                            )}
                            )
                        </span>
                    )}

                    {hasPermission(workspaceUser, SEE_INCOME_FINANCES) &&
                        upStreamContractMap && (
                            <span
                                style={{
                                    ...theme.typography.caption,
                                    fontWeight: 'bold',
                                    color: 'green',
                                }}
                            >
                                {currencyFormatter.format(
                                    getTotalPriceIncome(
                                        workorder,
                                        upStreamContractMap,
                                    ),
                                )}
                            </span>
                        )}
                </Container>
            </Container>
        </Container>
    )
}

interface VendorWoViewProps {
    workorder: BaseWorkorder
    theme: Theme
}

const VendorWoView = (props: VendorWoViewProps) => {
    const { workorder, theme } = props

    return (
        <Container
            style={{
                flex: 1,
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <Container>
                <Container style={{ flex: 2, flexDirection: 'column' }}>
                    <Container
                        style={{
                            ...theme.typography.subtitle1,
                            flex: 1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {workorder.apartment_name}
                    </Container>

                    <Container
                        style={{
                            ...theme.typography.subtitle2,
                            color: theme.palette.grey[700],
                            flex: 1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {workorder.service_name ?? ''}
                        {workorder.service_id &&
                            getServiceImageDetails(workorder.service_id)
                                .icon !== null && (
                                <img
                                    src={
                                        getServiceImageDetails(
                                            workorder.service_id,
                                        ).icon
                                    }
                                    style={{
                                        maxHeight: 20,
                                        maxWidth: 22,
                                        marginLeft: theme.spacing(1),
                                    }}
                                />
                            )}
                    </Container>
                </Container>
                <Container style={{ flex: 1, flexDirection: 'column' }}>
                    <Container style={{ flex: 1, justifyContent: 'right' }}>
                        <WoStatusBadge
                            theme={theme}
                            status={workorder.status}
                            style={{
                                text: {
                                    fontWeight: theme.typography.fontWeightBold,
                                },
                                container: {
                                    margin: theme.spacing(1, 0, 1, 1),
                                },
                            }}
                        />
                    </Container>
                </Container>
            </Container>
            <Container>
                <Container>
                    {workorder.path} - {workorder.unit_name ?? ''} (
                    {workorder.service_area_list.length})
                </Container>
                <Container style={{ flex: 1 }} />
                {workorder.start_date !== null && (
                    <Container>
                        {toMMDDYYYY(new Date(workorder.start_date ?? ''))} -{' '}
                        {toMMDDYYYY(new Date(workorder.end_date ?? ''))}
                    </Container>
                )}
            </Container>
        </Container>
    )
}
