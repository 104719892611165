import React from 'react'

import { IconButton, Tooltip, useTheme } from '@material-ui/core'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'

import { ChangeOrder, ChangeOrderStatus } from '../../models'
import { Container } from '../Container'

interface Props {
    changeOrder: ChangeOrder
    approveDenyChangeOrder: (
        changeOrder: ChangeOrder,
        status: ChangeOrderStatus,
    ) => Promise<void>
}

export const ChangeOrderConfirmDeny = (props: Props) => {
    const { changeOrder, approveDenyChangeOrder } = props

    const theme = useTheme()

    return (
        <Container
            style={{
                alignItems: 'center',
            }}
        >
            <div>
                <Tooltip title="Deny">
                    <IconButton
                        onClick={() => {
                            approveDenyChangeOrder(
                                changeOrder,
                                ChangeOrderStatus.DENIED,
                            )
                        }}
                        size="small"
                        style={{ marginRight: theme.spacing(1) }}
                    >
                        <ThumbDownIcon />
                    </IconButton>
                </Tooltip>
            </div>

            <div>
                <Tooltip title="Approve">
                    <IconButton
                        onClick={() => {
                            approveDenyChangeOrder(
                                changeOrder,
                                ChangeOrderStatus.APPROVED,
                            )
                        }}
                        size="small"
                    >
                        <ThumbUpIcon />
                    </IconButton>
                </Tooltip>
            </div>
        </Container>
    )
}
