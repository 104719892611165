import { useRef } from 'react'

export const useAnimationDeadline = (
    onTick: (timeRemainingMs: number) => void,
) => {
    const timer = useRef<any>(null)

    const getTimeRemaining = (deadline: Date) => {
        return deadline.getTime() - new Date().getTime()
    }

    const getDeadline = (countDownTimeMs: number) => {
        const deadline = new Date()

        deadline.setMilliseconds(deadline.getMilliseconds() + countDownTimeMs)
        return deadline
    }

    const tick = (deadLine: Date) => {
        const timeRemainingMs = getTimeRemaining(deadLine)

        if (timeRemainingMs < 0) {
            cancelTimer()
        }

        onTick(timeRemainingMs)
    }

    const startTimer = (countDownTimeMs: number, tickSpeedMs: number) => {
        // If a timer is running, clear it
        if (timer.current) clearInterval(timer.current)

        const deadline = getDeadline(countDownTimeMs)

        // Set a timer that ticks at the specified interval
        tick(deadline)
        timer.current = setInterval(() => {
            tick(deadline)
        }, tickSpeedMs)
    }

    const cancelTimer = () => {
        clearInterval(timer.current)
    }

    return { startTimer, cancelTimer }
}
