import React, { useState } from 'react'
import {
    Badge,
    Button,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Theme,
    Tooltip,
} from '@material-ui/core'
import { Message, ReportProblem } from '@material-ui/icons'
import { Container, StatusBadge } from '../../components'
import { toMMDDYYYY } from '../../helpers'
import {
    DateFilters,
    User,
    getAreaIdLabel,
    _Workorder,
    WorkorderStatus,
    WorkSpaceUser,
    BaseWorkorder,
} from '../../models'
import {
    clearJobsiteWorkorderUnreadCount,
    createSubWorkorder,
    getChannelDetail,
    getMessageList,
    transitionWorkorderStatus,
    updateSubWorkorder,
} from '../../store'
import { AppDispatch } from '../../hooks'
import { toast } from 'react-toastify'
import { WoStatusBadge } from './woStatusBadge'
import { useStyles } from '../../styles'
import { ReactComponent as UnitNotesIcon } from '../../assets/icons/unitnotes.svg'

interface VendorWorkorderProps {
    wo: _Workorder
    theme: Theme
    userList: User[]
    user: WorkSpaceUser | undefined
    dispatch: AppDispatch
    dateFilter: DateFilters
    handleOpenDrawer: (wo: _Workorder) => void
    currentMessage: _Workorder | null
    clickUnitNotes: (wo: _Workorder) => void
}

interface displayDateDict {
    date: string
    suffix: string
}

const getStartAndEndDate = (
    startDate: string | undefined | null,
    endDate: string | undefined | null,
) => {
    // returns display dates for start date and end date for a workorder
    const retValue = {
        start: { date: '', suffix: '' },
        end: { date: '', suffix: '' },
    }
    if (startDate === undefined || startDate === null) {
        if (endDate !== undefined && endDate !== null) {
            //there is only an end date
            retValue.end = formatDate(endDate, true)
        }
        // there is no date
    } else {
        if (endDate === undefined || endDate === null) {
            //there is only a start date
            retValue.start = formatDate(startDate, true)
        } else {
            //there is a start date and an end date
            retValue.start = formatDate(startDate, false)
            retValue.end = formatDate(endDate, true)
        }
    }

    return retValue
}

const formatDate = (date: string, daysLeft: boolean) => {
    // takes in a string, if it is start or end, and if it should append the days left
    //and returns the value that should be displayed for the workorder
    const displayDate = {
        date: '',
        suffix: '',
    }

    const dateObject = new Date(date)

    displayDate.date = toMMDDYYYY(dateObject)

    if (daysLeft) {
        // if it is before today, the display string needs to let the user know its late
        const today = new Date()
        //set todays time to the start of today
        today.setHours(0, 0, 0, 0)

        // if the date is before start of today it is late
        if (today > dateObject) {
            displayDate.suffix = ' (LATE)'
        } else {
            // else it has x days left where x is the difference of number of days between today and dateObject
            const difference = dateObject.getTime() - today.getTime() // this is the difference in milliseconds
            const days = Math.ceil(difference / (1000 * 3600 * 24))

            displayDate.suffix = ` (${days} Days Left)`
        }
    }
    return displayDate
}

export const VendorWorkorder = (props: VendorWorkorderProps) => {
    const {
        wo,
        theme,
        userList,
        user,
        dispatch,
        dateFilter,
        handleOpenDrawer,
        currentMessage,
        clickUnitNotes,
    } = props
    const startAndEnd = getStartAndEndDate(wo.start_date, wo.end_date)

    const classes = useStyles()

    //methods for rendering
    const displayDate = (date: displayDateDict) => {
        return (
            <Container
                direction="row"
                style={{ marginTop: theme.spacing(0.5) }}
            >
                <span
                    style={{
                        ...theme.typography.caption,
                        marginLeft: theme.spacing(0.5),
                    }}
                >
                    {date.date}
                </span>
                <span
                    style={{
                        ...theme.typography.caption,
                        marginLeft: theme.spacing(0.5),
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {date.suffix}
                </span>
            </Container>
        )
    }

    const serviceAreaList = wo.service_area_list.map((sa) => {
        return (
            <Container
                key={`VENDOR_DETAIL_WORKORDER_${wo.id}_AREA_${sa.area_id}`}
                style={{
                    marginRight: theme.spacing(0.5),
                }}
            >
                <span
                    style={{
                        ...theme.typography.subtitle2,
                        fontWeight: theme.typography.fontWeightBold,
                        marginRight: theme.spacing(0.25),
                    }}
                >
                    {sa.area_name}
                </span>
                <span
                    style={{ ...theme.typography.subtitle2 }}
                >{`(${sa.area_label})`}</span>
            </Container>
        )
    })

    const StatusTransitionButtons = () => {
        const BUTTON_HEIGTH = 30
        switch (wo.status) {
            case WorkorderStatus.APPROVED:
            case WorkorderStatus.INVOICED:
                return null
            case WorkorderStatus.ASSIGNED:
            case WorkorderStatus.PAUSE:
            case WorkorderStatus.GO_BACK:
                return (
                    <Container
                        style={{
                            padding: theme.spacing(2),
                            alignSelf: 'flex-start',
                        }}
                        direction="row"
                        justifyContent="center"
                    >
                        <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            style={{ maxHeight: BUTTON_HEIGTH }}
                            disabled={dateFilter !== 'Today'}
                            onClick={() => {
                                dispatch(
                                    transitionWorkorderStatus({
                                        workorderId: wo.id,
                                        body: {
                                            status: WorkorderStatus.IN_PROGRESS,
                                        },
                                    }),
                                )
                            }}
                        >
                            <span style={{ ...theme.typography.caption }}>
                                Start
                            </span>
                        </Button>
                    </Container>
                )
            case WorkorderStatus.IN_PROGRESS:
                return (
                    <Container
                        direction="row"
                        justifyContent="center"
                        flex={1}
                        style={{
                            paddingTop: theme.spacing(2),
                            alignSelf: 'stretch',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={dateFilter !== 'Today'}
                            size="small"
                            style={{
                                marginLeft: theme.spacing(2),
                                maxHeight: BUTTON_HEIGTH,
                            }}
                            onClick={() => {
                                dispatch(
                                    transitionWorkorderStatus({
                                        workorderId: wo.id,
                                        body: {
                                            status: WorkorderStatus.COMPLETE,
                                        },
                                    }),
                                )
                            }}
                        >
                            <span style={{ ...theme.typography.caption }}>
                                Complete
                            </span>
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            size="small"
                            style={{
                                marginRight: theme.spacing(2),
                                maxHeight: BUTTON_HEIGTH,
                            }}
                            disabled={dateFilter !== 'Today'}
                            onClick={() => {
                                dispatch(
                                    transitionWorkorderStatus({
                                        workorderId: wo.id,
                                        body: {
                                            status: WorkorderStatus.PAUSE,
                                        },
                                    }),
                                )
                            }}
                        >
                            <span style={{ ...theme.typography.caption }}>
                                Pause
                            </span>
                        </Button>
                    </Container>
                )
            default:
                return null
        }
    }

    return (
        <Container
            flex={1}
            direction="row"
            style={{
                backgroundColor: theme.palette.grey[50],
                boxShadow:
                    currentMessage?.id === wo.id
                        ? `2px 2px 6px ${theme.palette.primary.main}`
                        : theme.shadows[2],
                margin: theme.spacing(1),
            }}
        >
            {/* leftmost container */}
            <Container
                direction="column"
                style={{
                    borderRight: `1px solid ${theme.palette.grey[400]}`,
                }}
                alignItems="flex-start"
            >
                <Container direction="column">
                    <Container>
                        {/* unit name row */}
                        <Container direction="column">
                            <span
                                style={{
                                    padding: theme.spacing(2, 1, 0.5, 1),
                                    ...theme.typography.body1,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {wo.unit_name ?? ''}
                            </span>
                            <span
                                style={{
                                    ...theme.typography.caption,
                                    padding: theme.spacing(0, 1, 0.5, 1),
                                }}
                            >
                                {wo.user_friendly_schedule_id}
                            </span>
                        </Container>
                        <Container style={{ margin: theme.spacing(2) }}>
                            <Tooltip title="Unit Notes">
                                <Badge
                                    badgeContent={wo.unit_notes_msg_count}
                                    color={
                                        (wo.unread_unit_notes ?? 0) > 0
                                            ? 'secondary'
                                            : 'primary'
                                    }
                                >
                                    <IconButton
                                        onClick={() => clickUnitNotes(wo)}
                                    >
                                        <UnitNotesIcon />
                                    </IconButton>
                                </Badge>
                            </Tooltip>
                        </Container>
                    </Container>
                    <Divider />
                    {/* rest of content row */}
                    <Container>
                        {/* date column */}
                        <Container
                            direction="column"
                            style={{
                                borderRight: `1px solid ${theme.palette.grey[400]}`,
                                padding: theme.spacing(1),
                            }}
                        >
                            {wo.fully_vacant && (
                                <StatusBadge
                                    text="Vacant"
                                    customStyle={{
                                        container: {
                                            height: 30,
                                            width: 80,
                                            backgroundColor:
                                                theme.palette.common.black,
                                        },
                                        text: { ...theme.typography.caption },
                                    }}
                                />
                            )}
                            {displayDate(startAndEnd.start)}
                            {displayDate(startAndEnd.end)}
                        </Container>
                        {/* Status and Message column */}
                        <Container
                            direction="column"
                            style={{ padding: theme.spacing(1) }}
                        >
                            {/* Status and Vacant row */}
                            <Container>
                                <WoStatusBadge
                                    status={wo.status}
                                    theme={theme}
                                />
                            </Container>
                            {/* Messaging and priority Row */}
                            <Container style={{ marginTop: theme.spacing(1) }}>
                                <Container
                                    className={`${classes.hoverGrey300} ${classes.circle} `}
                                >
                                    <Badge
                                        badgeContent={wo.unread_count}
                                        color="secondary"
                                        overlap="circle"
                                    >
                                        <Message
                                            style={{
                                                color: theme.palette.grey[700],
                                                margin: 10,
                                            }}
                                            fontSize="default"
                                            onClick={() => {
                                                handleOpenDrawer(wo)
                                            }}
                                        />
                                    </Badge>
                                </Container>
                                {wo.priority && (
                                    <ReportProblem
                                        color="secondary"
                                        style={{ alignSelf: 'center' }}
                                    />
                                )}
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>

            <Container
                direction="column"
                flex={1}
                style={{
                    marginLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                    borderRight: `1px solid ${theme.palette.grey[400]}`,
                }}
                justifyContent="center"
                alignItems="center"
            >
                <Container alignItems="flex-start" style={{ flexWrap: 'wrap' }}>
                    {serviceAreaList}
                </Container>
            </Container>

            <Container
                direction="column"
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 225,
                    paddingTop: theme.spacing(2),
                    marginBottom: theme.spacing(1),
                }}
            >
                <StatusTransitionButtons />
            </Container>
        </Container>
    )
}
