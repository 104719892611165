import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import { Carousel } from 'react-responsive-carousel'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import {
    ChevronRight as ChevronRightIcon,
    ChevronLeft as ChevronLeftIcon,
} from '@material-ui/icons'
import {
    Button,
    Card,
    CardContent,
    Typography,
    useTheme,
} from '@material-ui/core'
import { NVLVendor } from '../main/containers/national-vendor-list'
import { ListVendor } from '../models'
import { Container } from '.'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        slidePagination: {
            margin: '20px',
            float: 'right',
            '& .MuiPaginationItem-root': {
                color: '#000',
                fontSize: '0.688rem',
                border: '1px solid #C2C2C2',
                borderRadius: '5px',
                fontWeight: 700,
                height: '24px',
                '& .MuiPaginationItem-icon': {
                    fill: '#008C85',
                },
            },
            '& .MuiPaginationItem-root.Mui-selected': {
                color: '#008C85',
                fontSize: '0.688rem',
                border: '1px solid #008C85',
                borderRadius: '5px',
                fontWeight: 700,
                background: '#FFF',
            },
        },

        //Fixed
        adCarousel: {
            width: '100%',
            // width: '290px',
            float: 'right',
        },
        cardRoot: {
            display: 'flex',
            // maxWidth: 'fit-content',
            height: '100px',
            boxshadow: 'none',
        },
        details: {
            width: '100%',
            background: '#008C85',
        },
        content: {
            flex: '1 0 auto',
        },
        subTitle: {
            color: '#FFF',
            fontSize: '13px',
            display: 'block',
        },
        title: {
            color: '#FFF',
            fontSize: '17px',
            display: 'block',
        },
    }),
)

interface Props {
    sponsoredVendors: NVLVendor[] | ListVendor[]
}

const AdvertisementSlider = (props: Props) => {
    const { sponsoredVendors } = props

    const classes = useStyles()

    const [currentSlide, setCurrentSlide] = useState(0)

    const nextSlide = () => {
        setCurrentSlide(currentSlide + 1)
    }

    const prevSlide = () => {
        setCurrentSlide(currentSlide - 1)
    }

    const updateCurrentSlide = (index: number) => {
        if (currentSlide !== index) {
            setCurrentSlide(index)
        }
    }

    return (
        <Container
            direction="column"
            style={{ width: '450px', alignSelf: 'flex-end' }}
        >
            <Carousel
                selectedItem={currentSlide}
                onChange={updateCurrentSlide}
                infiniteLoop
                showThumbs={false}
                showArrows={false}
                showStatus={false}
                autoPlay={true}
                showIndicators={false}
                className={classes.adCarousel}
                interval={3000}
            >
                {sponsoredVendors.map(
                    (vendor: NVLVendor | ListVendor, index: number) => {
                        return (
                            <div
                                key={`chart-${index}`}
                                onClick={() => {
                                    if (vendor.url && vendor.url !== '') {
                                        if (
                                            vendor.url.startsWith('http://') ||
                                            vendor.url.startsWith('https://')
                                        ) {
                                            window.open(vendor.url)
                                        }
                                        window.open(`http://${vendor.url}`)
                                    }
                                }}
                                style={{
                                    cursor: vendor.url !== '' ? 'pointer' : '',
                                }}
                            >
                                <Card className={classes.cardRoot}>
                                    {vendor.sponsor_image && (
                                        <div style={{ flex: 1 }}>
                                            <img
                                                src={vendor.sponsor_image}
                                                style={{
                                                    height: '100%',
                                                }}
                                            />
                                        </div>
                                    )}
                                    <div
                                        className={classes.details}
                                        style={{ flex: 2 }}
                                    >
                                        <CardContent
                                            className={classes.content}
                                        >
                                            <Typography
                                                component="span"
                                                className={classes.subTitle}
                                            >
                                                {vendor.tagline}
                                            </Typography>
                                            <Typography
                                                component="span"
                                                className={classes.title}
                                            >
                                                {vendor.name}
                                            </Typography>
                                            <Typography
                                                component="span"
                                                className={classes.subTitle}
                                            >
                                                {vendor.url}
                                            </Typography>
                                        </CardContent>
                                    </div>
                                </Card>
                            </div>
                        )
                    },
                )}
            </Carousel>
            <Container>
                <Button
                    onClick={() => prevSlide()}
                    variant="outlined"
                    color="primary"
                    style={{
                        marginTop: '16px',
                        marginRight: '10px',
                        padding: '2px',
                        borderRadius: '5px',
                        minWidth: 'auto',
                    }}
                >
                    <ChevronLeftIcon />
                </Button>
                <Button
                    onClick={() => nextSlide()}
                    variant="outlined"
                    color="primary"
                    style={{
                        marginTop: '16px',
                        padding: '2px',
                        borderRadius: '5px',
                        minWidth: 'auto',
                    }}
                >
                    <ChevronRightIcon />
                </Button>
            </Container>
        </Container>
    )
}

export default AdvertisementSlider
