import React, { useMemo, useState } from 'react'
import { Container, Selector } from '../../../../components'
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Theme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
} from '@material-ui/core'

import { Pagination } from '@material-ui/lab'

import CloseIcon from '@material-ui/icons/Close'

import { headerH, modalH, footerH } from './constants'
import { LeaseController, usePagination } from '../../../../hooks'
import { SelectionController } from '../../../../hooks/useSelectionMap'
import { LeaseRow } from './LeaseRow'
import { SearchField } from '../../../../components/SearchField'
import { InspectionStatus, LeaseStatus } from '../../../../models'

interface Props {
    theme: Theme
    leaseController: LeaseController
    selectionController: SelectionController
    selectedDate: Date
    onClickScheduleMoveIn: () => void
    handleClose: () => void
}

export const LeaseListPage = (props: Props) => {
    const {
        theme,
        handleClose,
        leaseController,
        selectionController,
        onClickScheduleMoveIn,
        selectedDate,
    } = props

    const [alertTenantDialogOpen, setAlertTenantDialogOpen] = useState(false)

    const [filter, setFilter] = useState({
        searchText: '',
        inspectionStatus: 'All',
        leaseStatus: -1,
    })

    const selectionCount = selectionController.selectionMap.length
    const allSelected = selectionCount === leaseController.leaseList.length
    const intermiate = selectionCount > 0 && !allSelected

    const filteredLeases = leaseController.leaseList.filter((lease) => {
        const nameValid = lease.user.name
            .toLowerCase()
            .includes(filter.searchText.toLowerCase())

        const emailValid = lease.user.email
            .toLowerCase()
            .includes(filter.searchText.toLowerCase())

        const searchValid = nameValid || emailValid

        // Inspection Status
        let inspecetionStatusValid = true
        const inspectionStatus = lease.tenant_move_in_inspection?.status
        if (filter.inspectionStatus !== 'All') {
            inspecetionStatusValid =
                inspectionStatus === filter.inspectionStatus

            // Check auto completed
            if (filter.inspectionStatus === 'Auto Completed') {
                inspecetionStatusValid =
                    inspectionStatus === InspectionStatus.COMPLETE &&
                    lease.tenant_move_in_inspection?.auto_completed === 1
            }
        }

        // Lease Status
        let leaseStatusValid = true
        if (filter.leaseStatus !== -1) {
            leaseStatusValid = lease.status === filter.leaseStatus
        }

        return searchValid && inspecetionStatusValid && leaseStatusValid
    })

    const pagination = usePagination(filteredLeases)

    const selectedAutoCompletedLeases = useMemo(() => {
        const selectedLeases = selectionController
            .getSelectedItems(filteredLeases)
            .filter((lease) => {
                return (
                    lease.tenant_move_in_inspection?.status === 'COMPLETE' &&
                    lease.tenant_move_in_inspection?.auto_completed === 1
                )
            })

        return selectedLeases
    }, [selectionController.selectionMap, filteredLeases])

    return (
        <Container
            style={{
                flexDirection: 'column',
                padding: theme.spacing(1),
                flex: 1,
            }}
        >
            {/* Header */}
            <Container
                style={{
                    height: headerH,
                    padding: theme.spacing(1),
                    alignItems: 'center',
                }}
            >
                <SearchField
                    label="Tenant Search"
                    value={filter.searchText}
                    onChange={(e) =>
                        setFilter({ ...filter, searchText: e.target.value })
                    }
                />

                <FormControl>
                    <InputLabel>Lease Status</InputLabel>
                    <Select
                        style={{ marginLeft: theme.spacing(2), width: 200 }}
                        value={filter.leaseStatus}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                leaseStatus: Number(e.target.value),
                            })
                        }
                    >
                        <MenuItem value={-1}>All</MenuItem>
                        <MenuItem value={LeaseStatus.APPLICANT}>
                            Applicant
                        </MenuItem>
                        <MenuItem value={LeaseStatus.FUTURE}>Future</MenuItem>
                        <MenuItem value={LeaseStatus.CURRENT}>Current</MenuItem>
                        <MenuItem value={LeaseStatus.NOTICE}>Notice</MenuItem>
                        <MenuItem value={LeaseStatus.PAST}>Past</MenuItem>
                    </Select>
                </FormControl>

                <FormControl>
                    <InputLabel>Move In Status</InputLabel>
                    <Select
                        style={{ marginLeft: theme.spacing(2), width: 200 }}
                        value={filter.inspectionStatus}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                inspectionStatus: e.target.value as string,
                            })
                        }
                    >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value={InspectionStatus.LOCKED}>
                            Locked
                        </MenuItem>
                        <MenuItem value={InspectionStatus.STAGED}>
                            Staged
                        </MenuItem>
                        <MenuItem value={InspectionStatus.READY}>
                            Ready
                        </MenuItem>
                        <MenuItem value={InspectionStatus.COMPLETE}>
                            Complete
                        </MenuItem>
                        <MenuItem value={'Auto Completed'}>
                            Auto Completed
                        </MenuItem>
                        <MenuItem value={InspectionStatus.NA}>
                            Not Applicable
                        </MenuItem>
                        <MenuItem value={InspectionStatus.ARCHIVED}>
                            Archived
                        </MenuItem>
                    </Select>
                </FormControl>
            </Container>
            {/* Body */}
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    maxHeight:
                        modalH - headerH - headerH - footerH - theme.spacing(2),
                    minHeight:
                        modalH - headerH - headerH - footerH - theme.spacing(2),
                }}
            >
                {leaseController.isLoading && (
                    <Container
                        style={{
                            flex: 1,
                            alignContent: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress size={50} />
                    </Container>
                )}
                {!leaseController.isLoading && (
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={allSelected}
                                            indeterminate={intermiate}
                                            onChange={() => {
                                                if (selectionCount === 0) {
                                                    selectionController.selectAll(filteredLeases)
                                                } else {
                                                    selectionController.clearSelections()
                                                }
                                            }}
                                        />
                                    </TableCell>

                                    <TableCell>Tenant</TableCell>
                                    <TableCell>Location</TableCell>
                                    <TableCell>Start</TableCell>
                                    <TableCell>End</TableCell>
                                    <TableCell>Lease Status</TableCell>

                                    <TableCell>Move In Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pagination.pageData.map((lease) => {
                                    return (
                                        <LeaseRow
                                            key={`LEASE-${lease.id}`}
                                            lease={lease}
                                            theme={theme}
                                            isChecked={selectionController.isIdSelected(
                                                lease.id,
                                            )}
                                            onCheck={(newValue) => {
                                                selectionController.setIdSelectionValue(
                                                    lease.id,
                                                    newValue,
                                                )
                                            }}
                                        />
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Container>

            {/* Footer */}
            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    minHeight: footerH,
                    maxHeight: footerH,
                    alignItems: 'center',
                }}
            >
                <div style={{ flex: 1 }} />
                <Container style={{ alignItems: 'center' }}>
                    <span style={{ marginRight: theme.spacing(2) }}>
                        Rows / Page
                    </span>
                    <Selector
                        size="small"
                        data={[
                            { id: 5, value: 5 },
                            { id: 10, value: 10 },
                            { id: 25, value: 25 },
                            { id: 50, value: 50 },
                            { id: 100, value: 100 },
                        ]}
                        onChange={(v) =>
                            pagination.setPageSize(Number(v.target.value))
                        }
                        currentValue={pagination.pageSize}
                        getDisplayString={(v) => `${v.value}`}
                    />

                    <Pagination
                        page={pagination.page}
                        onChange={(_, newPage) => {
                            pagination.setPage(newPage)
                        }}
                        count={pagination.numPages}
                    />
                    <span>
                        {pagination.start} - {pagination.end} of{' '}
                        {filteredLeases.length}
                    </span>
                </Container>

                <div style={{ flex: 1 }} />

                <Button
                    variant="contained"
                    style={{
                        textTransform: 'none',
                        marginRight: theme.spacing(2),
                    }}
                    color="secondary"
                    disabled={selectedAutoCompletedLeases.length === 0}
                    onClick={() => setAlertTenantDialogOpen(true)}
                >
                    Alert Tenant ({selectedAutoCompletedLeases.length})
                </Button>

                <Button
                    variant="contained"
                    style={{ textTransform: 'none' }}
                    color="primary"
                    disabled={selectionCount === 0}
                    onClick={() => onClickScheduleMoveIn()}
                >
                    Schedule move in ({selectionCount})
                </Button>
            </Container>
            <AlertTenantDialog
                loading={leaseController.isLoading}
                open={alertTenantDialogOpen}
                autoCompletedCount={selectedAutoCompletedLeases.length}
                onClose={() => setAlertTenantDialogOpen(false)}
                onConfirm={(days) => {
                    leaseController.reAlertTenants(
                        selectedAutoCompletedLeases,
                        days,
                        () => {
                            leaseController.getLeaseClusterList({
                                params: {
                                    move_in_date: selectedDate.toISOString(),
                                    is_child: false,
                                    exclude_children: true,
                                    home: true,
                                },
                            })
                            setAlertTenantDialogOpen(false)
                        },
                    )
                }}
            />
        </Container>
    )
}

interface AlertTenantDialogProps {
    open: boolean
    autoCompletedCount: number
    onClose: () => void
    onConfirm: (days: number) => void
    loading: boolean
}

export const AlertTenantDialog: React.FC<AlertTenantDialogProps> = ({
    open,
    autoCompletedCount,
    onClose,
    onConfirm,
    loading,
}) => {
    const [days, setDays] = useState(3)

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle id="alert-dialog-title">Alert Tenants</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Only tenants with who had their move in status completed
                    automatically will be alerted ({autoCompletedCount} tenants)
                </DialogContentText>

                <TextField
                    label="Days"
                    value={days}
                    onChange={(e) => {
                        const value = e.target.value
                        if (value.match(/^\d+$/)) {
                            setDays(Number(value))
                        }
                        if (value === '') {
                            setDays(0)
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" variant="outlined" onClick={onClose}>
                    Cancel
                </Button>
                <div style={{ flex: 1 }} />
                <Button
                    disabled={days === 0 || loading}
                    color="primary"
                    variant="outlined"
                    onClick={() => onConfirm(days)}
                >
                    {loading ? <CircularProgress size={20} /> : 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
