import { useSelector } from 'react-redux'
import { ServiceContract } from '../models'
import {
    RootState,
    updateServiceContract,
    ServiceContractActionThunk,
    UpdateServiceContractsRequest,
    GetServiceContractListRequest,
    getServiceContractList,
    setServiceContractList,
    SetServiceContractsRequest,
    setServiceContracts,
} from '../store'
import { useAppDispatch } from './useAppDispatch'

type UseServiceContract = {
    serviceContracts: ServiceContract[]
    updateServiceContracts: (
        req: UpdateServiceContractsRequest,
    ) => ServiceContractActionThunk
    getServiceContractList: (
        req: GetServiceContractListRequest,
    ) => ServiceContractActionThunk
    setServiceContractList: (req: ServiceContract[]) => void
    setServiceContracts: (
        req: SetServiceContractsRequest,
    ) => ServiceContractActionThunk
}

export const useServiceContract = (): UseServiceContract => {
    const dispatch = useAppDispatch()

    const serviceContracts = useSelector(
        (state: RootState) => state.serviceContract.serviceContracts,
    )

    return {
        serviceContracts: serviceContracts,
        updateServiceContracts: (req) => dispatch(updateServiceContract(req)),
        setServiceContracts: (req) => dispatch(setServiceContracts(req)),
        getServiceContractList: (req) => dispatch(getServiceContractList(req)),
        setServiceContractList: (req) => dispatch(setServiceContractList(req)),
    }
}
