import { useTheme } from '@material-ui/core'
import React from 'react'
import { ReactComponent as Logo } from '../../assets/EZ_turn.svg'
import { Container } from '../../components'

export const PermissionDenied = () => {
    const theme = useTheme()

    return (
        <Container
            style={{ width: '100%', height: '100%' }}
            alignItems="center"
            justifyContent="center"
            direction="column"
        >
            <Logo fill={theme.palette.primary.main} />
            <span
                style={{
                    ...theme.typography.h2,
                    fontWeight: theme.typography.fontWeightBold,

                    textAlign: 'center',
                }}
            >
                You do not have permission to use this website.
            </span>
            <span
                style={{
                    ...theme.typography.h2,
                    fontWeight: theme.typography.fontWeightBold,

                    textAlign: 'center',
                }}
            >
                Instead, download our app on the iOS App Store or the Google
                Play Store.
            </span>
        </Container>
    )
}
