import React, { useEffect, useState } from 'react'

import { Container } from '../../../components'

import { WorkSpaceUser } from '../../../models'
import { Divider, Tab, Tabs, useTheme } from '@material-ui/core'

import { InvoiceTab } from './InvoiceTab'
import { SettingsTab } from './SettingsTab'

interface Props {
    user: WorkSpaceUser
}

export const EZPayHome = (props: Props) => {
    const { user } = props

    const theme = useTheme()

    const [tab, setTab] = useState(0)

    return (
        <Container style={{ flex: 1, flexDirection: 'column' }}>
            <Divider />

            {/* Body */}

            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    overflowY: 'scroll',
                    padding: theme.spacing(2),
                }}
            >
                <Tabs onChange={(_, v) => setTab(v)} value={tab}>
                    <Tab label="Invoices" value={0} />
                    <Tab label="Settings" value={1} />
                </Tabs>

                <InvoiceTab selected={tab === 0} user={user} />
                <SettingsTab selected={tab === 1} user={user} />
            </Container>
        </Container>
    )
}
