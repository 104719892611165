import { List, Popover, PopoverProps } from '@material-ui/core'
import React from 'react'
import { Container } from '../Container'
import { ListPopoverStyleArgs, ListPopoverStyles } from './styles'

interface Props extends PopoverProps {
    styleArguments?: ListPopoverStyleArgs
}

export const ListPopover: React.FC<Props> = (props) => {
    const { open, anchorEl, onClose, anchorOrigin, styleArguments } = props

    const styles = ListPopoverStyles(styleArguments)

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={anchorOrigin}
        >
            <Container className={styles.paper}>
                <List dense={true} className={styles.serviceMenu}>
                    {props.children}
                </List>
            </Container>
        </Popover>
    )
}
