import React from 'react'
import { TableCell, withStyles } from '@material-ui/core'

export const StickyTableCell = withStyles((theme) => ({
    body: {
        minWidth: 75,
        left: 0,
        backgroundColor: theme.palette.common.white,
        position: 'sticky',
        zIndex: 10000,
    },
}))(TableCell)
