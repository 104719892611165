import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    Grid,
    List,
    ListItem,
    ListItemText,
    Button,
    FormControlLabel,
    Switch,
} from '@material-ui/core'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import ColumnChart from '../../../components/appex-charts/ColumnChart'
import { ReactComponent as PlusCircleIcon } from '../../../../assets/icons/plus-circle.svg'
import { ReactComponent as ArrowLeftIcon } from '../../../../assets/icons/chevron-left.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/chevron-right.svg'
import { resolveRoute } from '../../../../helpers'
import { useHistory } from 'react-router-dom'
import { Routes } from '../../../config/routes'
import { Container } from '../../../../components'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            borderRadius: '15px',
            // border: '1px solid #C2C2C2',
            // boxShadow: '0px 6px 15px rgb(138 138 138 / 25%)',
            border: 'none',
            boxShadow: 'none',
        },
        headerTitle: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '23px',
            color: '#008C85',
            textAlign: 'left',
            alignItem: 'center',
            border: 'none',
            textTransform: 'inherit',
            justifyContent: 'left',

            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '100%',
        },
        chartLegend: {
            paddingTop: 0,
            // display: 'block',
            // cursor: 'pointer',
            '& .MuiListItemText-primary': {
                fontSize: '0.625rem',
                color: '#8A8A8A',
                fontWeight: 400,
            },
            '& .MuiListItem-root': {
                textAlign: 'right',
                padding: 0,
            },
        },
        slideCustomBtn: {
            color: '#000',
            borderRadius: '15px',
            border: '1px solid #C2C2C2',
            fontSize: '0.75rem',
            textTransform: 'none',
            backgroundColor: '#FFF !important',
            boxShadow: 'none !important',
            float: 'right',
            height: '26px',
            lineHeight: '14px',
            '& .MuiButton-startIcon': {
                borderRight: '1px solid #C2C2C2',
                paddingRight: '6px',
            },
            '& .MuiButton-endIcon': {
                borderLeft: '1px solid #C2C2C2',
                paddingLeft: '6px',
            },
            '& :hover': {
                backgroundColor: 'transparent',
                boxShadow: 'none',
            },
        },
    }),
)

interface Props {
    series: Array<any>
    chartId: String | null
    scheduleData: {
        approvedCount: number
        completeCount: number
        invoicedCount: number
        inProgressCount: number
        behindCount: number
        notStartedCount: number
        totalWorkOrders: number
        milestonesCompCount: number
        name: String
        active: Boolean
        id: number
    }
    nextSlide: () => void
    prevSlide: () => void
    currentSlide: number
    totalSlide: number
    showArchived: boolean
    setShowArchived: (showArchived: boolean) => void
}
const ScheduleCard = (props: Props) => {
    const classes = useStyles()

    const history = useHistory()
    const colors = [
        '#2c80ff',
        '#008c85',
        '#e7be42',
        '#f24f2d',
        '#fdccc9',
        '#46af84',
    ]

    const categories = [
        ['Approved'],
        ['Complete'],
        ['In Progress'],
        ['Behind'],
        ['Not Started'],
        ['Invoiced'],
    ]

    return (
        <>
            <Card className={`${classes.root}`}>
                <CardHeader
                    // action={
                    //     <IconButton aria-label="settings">
                    //         <MoreVertIcon />
                    //     </IconButton>
                    // }
                    title={
                        <Grid container spacing={2} alignItems="stretch">
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                {props.scheduleData.name ? (
                                    <Container>
                                        <Button
                                            className={classes.headerTitle}
                                            variant="outlined"
                                            onClick={() => {
                                                let route =
                                                    Routes.apartmentScheduleArchivedDetail
                                                if (props.scheduleData.active) {
                                                    route =
                                                        Routes.apartmentScheduleDetail
                                                }
                                                // console.log("schedule data...", props.scheduleData)
                                                // console.log("resolve route...", route)
                                                history.push(
                                                    resolveRoute(
                                                        route,
                                                        ':id',
                                                        props.scheduleData.id,
                                                    ),
                                                )
                                            }}
                                        >
                                            {props.scheduleData.name}
                                        </Button>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    value={props.showArchived}
                                                    color="primary"
                                                    onChange={() =>
                                                        props.setShowArchived(
                                                            !props.showArchived,
                                                        )
                                                    }
                                                />
                                            }
                                            labelPlacement="start"
                                            label="Archived"
                                            style={{}}
                                        />
                                    </Container>
                                ) : (
                                    <>
                                        <Typography
                                            className={classes.headerTitle}
                                        >
                                            Add Schedule
                                            <PlusCircleIcon
                                                style={{
                                                    height: 22,
                                                    width: 22,
                                                    marginLeft: '5px',
                                                }}
                                            />
                                        </Typography>
                                    </>
                                )}
                                {/* {props.scheduleData.name ? (
                                    <span
                                        style={{
                                            display: 'flex',
                                            marginTop: '5px',
                                        }}
                                    >
                                        <TrophyIcon
                                            style={{ marginRight: '8px' }}
                                        />
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                fontSize: '11px',
                                                marginRight: '8px',
                                            }}
                                        >
                                            {
                                                props.scheduleData
                                                    .milestonesCompCount
                                            }
                                        </span>
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                fontSize: '10px',
                                                color: '#008C85',
                                            }}
                                        >
                                            Milestones Completed
                                        </span>
                                    </span>
                                ) : (
                                    <>
                                        <span
                                            style={{
                                                display: 'flex',
                                                marginTop: '5px',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: 700,
                                                    fontSize: '10px',
                                                    color: '#C2C2C2',
                                                }}
                                            >
                                                Add Milestone
                                            </span>
                                            <PlusCircleIcon
                                                style={{
                                                    marginLeft: '5px',
                                                    height: 15,
                                                    width: 15,
                                                }}
                                            />
                                        </span>
                                    </>
                                )} */}
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={7} lg={7}>
                                <Grid
                                    container
                                    spacing={2}
                                    alignItems="stretch"
                                >
                                    <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <List
                                            dense
                                            className={classes.chartLegend}
                                        >
                                            <ListItem>
                                                <ListItemText>
                                                    <span
                                                        style={{
                                                            color:
                                                                props.series
                                                                    .length > 0
                                                                    ? '#5A9CFF'
                                                                    : '#000',
                                                        }}
                                                    >
                                                        Approved :
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: '#000',
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {
                                                            props.scheduleData
                                                                .approvedCount
                                                        }
                                                    </span>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText>
                                                    <span
                                                        style={{
                                                            color:
                                                                props.series
                                                                    .length > 0
                                                                    ? '#13958E'
                                                                    : '#000',
                                                        }}
                                                    >
                                                        Complete :
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: '#000',
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {
                                                            props.scheduleData
                                                                .completeCount
                                                        }
                                                    </span>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText>
                                                    <span
                                                        style={{
                                                            color:
                                                                props.series
                                                                    .length > 0
                                                                    ? '#F0D585'
                                                                    : '#000',
                                                        }}
                                                    >
                                                        In Progress:
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: '#000',
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {
                                                            props.scheduleData
                                                                .inProgressCount
                                                        }
                                                    </span>
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <List
                                            dense
                                            className={classes.chartLegend}
                                        >
                                            <ListItem>
                                                <ListItemText>
                                                    Total Work Orders :
                                                    <span
                                                        style={{
                                                            color: '#000',
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {
                                                            props.scheduleData
                                                                .totalWorkOrders
                                                        }
                                                    </span>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText>
                                                    <span
                                                        style={{
                                                            color:
                                                                props.series
                                                                    .length > 0
                                                                    ? '#FD917F'
                                                                    : '#000',
                                                        }}
                                                    >
                                                        Behind Count :
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: '#000',
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {
                                                            props.scheduleData
                                                                .behindCount
                                                        }
                                                    </span>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText>
                                                    <span
                                                        style={{
                                                            color:
                                                                props.series
                                                                    .length > 0
                                                                    ? '#FDD3D1'
                                                                    : '#000',
                                                        }}
                                                    >
                                                        Not Started :
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: '#000',
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {
                                                            props.scheduleData
                                                                .notStartedCount
                                                        }
                                                    </span>
                                                </ListItemText>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText>
                                                    <span
                                                        style={{
                                                            color:
                                                                props.series
                                                                    .length > 0
                                                                    ? '#8BE2BA'
                                                                    : '#000',
                                                        }}
                                                    >
                                                        Invoiced :
                                                    </span>
                                                    <span
                                                        style={{
                                                            color: '#000',
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        {
                                                            props.scheduleData
                                                                .invoicedCount
                                                        }
                                                    </span>
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                    </Grid>
                                </Grid>
                                {props.series.length > 0 ? (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.slideCustomBtn}
                                        startIcon={
                                            <ArrowLeftIcon
                                                onClick={() =>
                                                    props.prevSlide()
                                                }
                                            />
                                        }
                                        endIcon={
                                            <ArrowRightIcon
                                                onClick={() =>
                                                    props.nextSlide()
                                                }
                                            />
                                        }
                                    >
                                        {props.currentSlide} of {props.totalSlide}
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </Grid> */}
                        </Grid>
                    }
                />
                <CardContent>
                    {props.series.length ? (
                        <ColumnChart
                            series={props.series}
                            colors={colors}
                            categories={categories}
                            height={250}
                            chartId={`schChart-${props.scheduleData.id}`}
                        />
                    ) : (
                        <></>
                    )}
                </CardContent>
            </Card>
        </>
    )
}

export default ScheduleCard
