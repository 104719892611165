import {
    Button,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import {
    Container,
    EnhancedTableHead,
    HeadCell,
    Order,
    RowData,
    Selector,
    StickyTableCell,
} from '../../components'
import { axiosInstance } from '../../helpers'
import { useAppDispatch } from '../../hooks'
import {
    PortfolioServiceContract,
    Service,
    StringNumberMap,
} from '../../models'
import {
    getPortfolioServiceContracts,
    getServiceList,
    GET_PORTFOLIO_SERVICE_CONTRACT_REQUEST_ACTION,
    RootState,
} from '../../store'
import FileDownload from 'js-file-download'
import { toast } from 'react-toastify'

interface ServiceContractRow {
    aptName: string
    aptLoc: string
    venName: string
    areas: { [name: string]: number }
    units: { [name: string]: number }
}

export const PortfolioServiceContractTab = () => {
    const [selectedServiceId, setSelectedServiceId] = useState<number>(0)
    const isPortfolioBudgetLoading = useSelector(
        (state: RootState) =>
            state.serviceContract.isLoading[
                GET_PORTFOLIO_SERVICE_CONTRACT_REQUEST_ACTION
            ],
    )

    const dispatch = useAppDispatch()
    const theme = useTheme()
    const serviceList = useSelector(
        (state: RootState) => state.service.serviceList,
    )

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(getServiceList({}))
    }, [])

    useEffect(() => {
        if (selectedServiceId !== 0) {
            setLoading(true)
            dispatch(
                getPortfolioServiceContracts({
                    params: { service: selectedServiceId },
                }),
            ).finally(() => setLoading(false))
        }
    }, [selectedServiceId])

    const [reportLoading, setReportLoading] = useState(false)
    const scList = useSelector(
        (state: RootState) => state.serviceContract.portfolioServiceContracts,
    )

    const ORDER_BY_DEFAULT_TYPE = 0
    const ORDER_BY_AREA_TYPE = 1
    const ORDER_BY_UNIT_TYPE = 2
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState<keyof ServiceContractRow>('aptName')
    const [orderByType, setOrderByType] = useState(ORDER_BY_DEFAULT_TYPE)

    const getServiceContractRenderData = (
        scList?: PortfolioServiceContract[],
    ) => {
        const serviceContractRenderData: ServiceContractRow[] = []
        const areaList: string[] = []
        const unitList: string[] = []
        const unitNameMap: { [name: string]: boolean } = {}
        const areaNameMap: { [name: string]: boolean } = {}
        let curApt = 0
        let curVen = 0
        let curRow: ServiceContractRow | undefined = undefined

        const areaTotals: StringNumberMap = {}
        const areaCounts: StringNumberMap = {}
        const unitCounts: StringNumberMap = {}
        const unitTotals: StringNumberMap = {}

        scList?.forEach((sc) => {
            if (curApt === 0) {
                curApt = sc.apartment.id
            }
            if (curVen === 0) {
                curVen = sc.vendor.id
            }

            if (curRow === undefined) {
                curRow = {
                    aptName: sc.apartment.name,
                    venName: sc.vendor.name,
                    aptLoc: sc.apartment.state,
                    areas: {},
                    units: {},
                }
            }

            if (curVen !== sc.vendor.id || curApt !== sc.apartment.id) {
                // new row
                serviceContractRenderData.push(curRow)
                curRow = {
                    aptName: sc.apartment.name,
                    venName: sc.vendor.name,
                    aptLoc: sc.apartment.state,
                    areas: {},
                    units: {},
                }
                curVen = sc.vendor.id
                curApt = sc.apartment.id
            } else {
                // continue building cur row
                if (sc.area_config) {
                    curRow.areas[sc.area_config.name] = sc.price ?? 0
                    if (!(sc.area_config.name in areaNameMap)) {
                        areaNameMap[sc.area_config.name] = true
                        areaCounts[sc.area_config.name] = 0
                        areaTotals[sc.area_config.name] = 0
                        areaList.push(sc.area_config.name)
                    }
                    areaCounts[sc.area_config.name]++
                    areaTotals[sc.area_config.name] += Number(sc.price) ?? 0
                }
                if (sc.unit_config) {
                    const bedCountStr = sc.unit_config.bed_count ?? '0'
                    const bathCountStr = sc.unit_config.bath_count ?? '0'
                    const name = `${bedCountStr} X ${bathCountStr}`
                    curRow.units[name] = sc.price ?? 0
                    if (!(name in unitNameMap)) {
                        unitNameMap[name] = true
                        unitCounts[name] = 0
                        unitTotals[name] = 0
                        unitList.push(name)
                    }
                    unitCounts[name]++
                    unitTotals[name] += Number(sc.price) ?? 0
                }
            }
        })
        if (curRow) {
            serviceContractRenderData.push(curRow)
        }

        const averageRow: ServiceContractRow = {
            aptName: 'AVERAGES',
            aptLoc: '-',
            venName: '-',
            areas: {},
            units: {},
        }
        areaList.forEach((name) => {
            const numerator = areaTotals[name]
            const denominator = areaCounts[name]
            averageRow.areas[name] = numerator / denominator
        })
        unitList.forEach((name) => {
            const numerator = unitTotals[name]
            const denominator = unitCounts[name]
            averageRow.units[name] = numerator / denominator
        })

        return {
            areaList: areaList,
            unitList: unitList,
            serviceContractRenderData: serviceContractRenderData,
            areaNameMap: areaNameMap,
            unitNameMap: unitNameMap,
            averageRow: averageRow,
        }
    }

    function descendingComparator(
        a: ServiceContractRow,
        b: ServiceContractRow,
    ) {
        let bValue: string | number = b[orderBy] as string
        let aValue: string | number = a[orderBy] as string
        if (orderByType === ORDER_BY_AREA_TYPE) {
            // we are sorting by an area, use the orderBy a level deeper to set a and b value
            const bAreas = b['areas']
            const aAreas = a['areas']
            if (bAreas[orderBy]) {
                bValue = bAreas[orderBy]
            } else {
                bValue = '-1'
            }
            if (aAreas[orderBy]) {
                aValue = aAreas[orderBy]
            } else {
                aValue = '-1'
            }
        }
        if (orderByType === ORDER_BY_UNIT_TYPE) {
            // we are sorting by a unit, use the orderBy a level deeper to set a and b value
            const bUnits = b['units']
            const aUnits = a['units']
            if (bUnits[orderBy]) {
                bValue = bUnits[orderBy]
            } else {
                bValue = '-1'
            }
            if (aUnits[orderBy]) {
                aValue = aUnits[orderBy]
            } else {
                aValue = '-1'
            }
        }
        if (bValue < aValue) {
            return -1
        } else if (bValue > aValue) {
            return 1
        }
        return 0
    }

    function getComparator(): (
        a: ServiceContractRow,
        b: ServiceContractRow,
    ) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b)
            : (a, b) => -descendingComparator(a, b)
    }

    const renderData = getServiceContractRenderData(scList)
    const contractDataWithIndexes = renderData.serviceContractRenderData.map(
        (el, index) => [el, index] as [ServiceContractRow, number],
    )
    const comparator = getComparator()
    contractDataWithIndexes.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })

    const sortedRows = contractDataWithIndexes.map((el) => el[0])
    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }
    const stickyHeadCellStyle: React.CSSProperties = {
        ...headCellStyle,
        minWidth: 75,
        left: 0,
        position: 'sticky',
        zIndex: 100000,
    }

    const areaHeadCells: HeadCell<RowData>[] = renderData.areaList.map(
        (name) => {
            return {
                disablePadding: false,
                align: 'center',
                id: name,
                label: name,
                style: headCellStyle,
            }
        },
    )
    const unitHeadCells: HeadCell<RowData>[] = renderData.unitList.map(
        (name) => {
            return {
                disablePadding: false,
                align: 'center',
                id: name,
                label: name,
                style: headCellStyle,
            }
        },
    )
    const headCells: HeadCell<RowData>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'aptName',
            label: 'Property',
            style: stickyHeadCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'aptLoc',
            label: 'Location',
            style: { ...stickyHeadCellStyle, left: 100 },
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'venName',
            label: 'Vendor',
            style: { ...stickyHeadCellStyle, left: 200 },
        },
        ...areaHeadCells,
        ...unitHeadCells,
    ]

    return (
        <Container style={{ flexDirection: 'column', height: '100%' }}>
            <Container>
                <Selector
                    label="Service"
                    currentValue={selectedServiceId}
                    data={serviceList}
                    getDisplayString={(s: Service) => s.name}
                    onChange={(
                        event: React.ChangeEvent<{
                            value: unknown
                        }>,
                    ) => {
                        setSelectedServiceId(event.target.value as number)
                    }}
                    customStyle={{ formControl: { flex: 1 } }}
                />
                <Button
                    color="primary"
                    variant="outlined"
                    style={{ margin: theme.spacing(2) }}
                    onClick={() => {
                        if (selectedServiceId === 0) {
                            toast.error(
                                'Please select a service to generate a report',
                            )
                        } else {
                            setReportLoading(true)
                            axiosInstance
                                .get(
                                    'workorder/portfolio-schedule/get_portfolio_list/',
                                    {
                                        params: {
                                            excel_sheet: true,
                                            service: selectedServiceId,
                                        },
                                        responseType: 'blob',
                                    },
                                )
                                .then((response) => {
                                    FileDownload(
                                        new Blob([response.data]),
                                        `service-contract-report-${
                                            serviceList.find(
                                                (service) =>
                                                    service.id ===
                                                    selectedServiceId,
                                            )?.name
                                        }.xlsx`,
                                    )
                                })
                                .finally(() => {
                                    setReportLoading(false)
                                })
                        }
                    }}
                >
                    Generate Report
                </Button>
                {isPortfolioBudgetLoading ||
                    (reportLoading && (
                        <CircularProgress
                            size={50}
                            style={{
                                alignSelf: 'center',
                                justifySelf: 'center',
                            }}
                        />
                    ))}
            </Container>
            {loading ? (
                <Container flex={1} alignItems="center" justifyContent="center">
                    <CircularProgress
                        size={100}
                        style={{ marginTop: theme.spacing(10) }}
                    />
                </Container>
            ) : (
                <TableContainer
                    component={Paper}
                    style={{
                        maxHeight: 'calc(100vh - 320px)',
                        overflowY: 'auto',
                        overflowX: 'auto',
                    }}
                >
                    <Table stickyHeader style={{ minWidth: 650 }}>
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={(_, property) => {
                                const isAsc =
                                    orderBy === property && order === 'asc'
                                setOrder(isAsc ? 'desc' : 'asc')
                                setOrderBy(property as keyof ServiceContractRow)
                                if (property in renderData.areaNameMap) {
                                    setOrderByType(ORDER_BY_AREA_TYPE)
                                } else if (property in renderData.unitNameMap) {
                                    setOrderByType(ORDER_BY_UNIT_TYPE)
                                } else {
                                    setOrderByType(ORDER_BY_DEFAULT_TYPE)
                                }
                            }}
                            rowCount={
                                renderData.serviceContractRenderData.length
                            }
                            headCells={headCells}
                        />
                        <TableBody>
                            {sortedRows
                                .map((row, idx) => (
                                    <ServiceTableRow
                                        key={`${idx}`}
                                        row={row}
                                        areaList={renderData.areaList}
                                        unitList={renderData.unitList}
                                    />
                                ))
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage,
                                )}
                            {/* average row */}
                            <ServiceTableRow
                                key={'AVERAGES_ROW'}
                                row={renderData.averageRow}
                                areaList={renderData.areaList}
                                unitList={renderData.unitList}
                                style={{
                                    backgroundColor: theme.palette.grey[100],
                                }}
                            />
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={renderData.serviceContractRenderData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={(_: unknown, newPage: number) => {
                            setPage(newPage)
                        }}
                        onChangeRowsPerPage={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                            setRowsPerPage(parseInt(event.target.value, 10))
                            setPage(0)
                        }}
                    />
                </TableContainer>
            )}
        </Container>
    )
}
interface RowProps {
    key: string
    row: ServiceContractRow
    areaList: string[]
    unitList: string[]
    style?: React.CSSProperties
}
const ServiceTableRow = (props: RowProps) => {
    const { key, row, areaList, unitList, style } = props
    const theme = useTheme()
    return (
        <TableRow key={key}>
            <StickyTableCell style={{ ...theme.typography.body2, ...style }}>
                {row.aptName}
            </StickyTableCell>
            <StickyTableCell
                style={{ ...theme.typography.body2, left: 100, ...style }}
            >
                {row.aptLoc}
            </StickyTableCell>
            <StickyTableCell
                style={{ ...theme.typography.body2, left: 200, ...style }}
            >
                {row.venName}
            </StickyTableCell>
            {areaList.map((name) => {
                return (
                    <TableCell
                        key={`TABLE_BODY_AREA_${name}_${key}`}
                        style={style}
                    >
                        {row.areas[name] ? (
                            <NumberFormat
                                prefix={'$'}
                                value={row.areas[name]}
                                displayType="text"
                                thousandSeparator=","
                                decimalScale={2}
                                style={{
                                    ...theme.typography.body2,
                                }}
                            />
                        ) : (
                            <span>-</span>
                        )}
                    </TableCell>
                )
            })}
            {unitList.map((name) => {
                return (
                    <TableCell
                        key={`TABLE_BODY_UNIT_${name}_${key}`}
                        style={{ minWidth: 75, ...style }}
                    >
                        {row.units[name] ? (
                            <NumberFormat
                                prefix={'$'}
                                value={row.units[name]}
                                displayType="text"
                                thousandSeparator=","
                                decimalScale={2}
                                style={{
                                    ...theme.typography.body2,
                                }}
                            />
                        ) : (
                            <span>-</span>
                        )}
                    </TableCell>
                )
            })}
        </TableRow>
    )
}
