import {
    Button,
    IconButton,
    Modal,
    Paper,
    Slide,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Container, Selector, SideDrawer } from '../../components'
import { axiosInstance } from '../../helpers'
import {
    AreaConfig,
    IdentifiableNamedObject,
    InventoryAreaConfig,
    InventoryConfig,
    ListInventoryAreaConfig,
} from '../../models'
import {
    ConfigSelectionRow,
    SELECT_AREA_MODE,
    SELECT_INVENTORY_MODE,
} from './ConfigSelectionRow'
import {
    AREA_CONFIG,
    DrawerState,
    FORM_CLOSED,
    INVENTORY_CONFIG,
} from './InfrastructureConfig'
import { useInventoryGroup } from '../../hooks'

interface Props {
    open: DrawerState
    handleDrawerChange: (drawer?: DrawerState) => void
    areaConfigList: AreaConfig[]
    inventoryConfigList: InventoryConfig[]
}

export interface RenderIAC {
    amount: number
    areaConfig?: AreaConfig
}

interface BulkSetIACRequest {
    inventory_config_id?: number
    area_config_id?: number
    iacs: RenderIAC[]
}

export interface ReqIAC {
    inventory_config_id: number
    area_config_id: number
    amount: number
}

export const InvAreaConfigDrawer = (props: Props) => {
    const {
        open,
        handleDrawerChange,
        areaConfigList,
        inventoryConfigList,
    } = props

    const theme = useTheme()

    const [IACList, setIACList] = useState<ListInventoryAreaConfig[]>([
        { amount: 1 },
    ])

    const refreshIacList = () => {
        const params: {
            inventory_config?: number
            area_config?: number
        } = {}
        if (open.formOpen === INVENTORY_CONFIG) {
            params['inventory_config'] = open.selected?.id ?? -1
        } else {
            params['area_config'] = open.selected?.id ?? -1
        }
        axiosInstance
            .get(`apt_config/inventory_area_config/`, { params: params })
            .then((res) => {
                console.log(res.data)
                setIACList(res.data)
            })
    }

    useEffect(() => {
        if (open.selected) {
            refreshIacList()
        }
    }, [open.selected])

    return (
        <SideDrawer
            open={
                open.formOpen === INVENTORY_CONFIG ||
                open.formOpen === AREA_CONFIG
            }
            handleClose={() => handleDrawerChange()}
            title={open.selected?.name}
        >
            {open.formOpen === AREA_CONFIG && (
                <GroupSection
                    areaConfigId={open.selected?.id ?? -1}
                    refreshIacList={refreshIacList}
                />
            )}

            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    overflowY: 'scroll',
                }}
            >
                {IACList.map((iac, idx) => {
                    let configList: IdentifiableNamedObject[] = []
                    if (open.formOpen === INVENTORY_CONFIG) {
                        configList = areaConfigList.reduce<AreaConfig[]>(
                            (prev, areaConfig) => {
                                // if it is the config for this iac, return it
                                if (areaConfig.id === iac.area_config?.id) {
                                    return prev.concat(areaConfig)
                                }

                                // if this is the config for any other iac, do not return it
                                const isInOtherIAC = IACList.find(
                                    (iac) =>
                                        iac.area_config?.id === areaConfig.id,
                                )
                                if (isInOtherIAC) {
                                    return prev
                                }
                                return prev.concat(areaConfig)
                            },
                            [],
                        )
                    } else {
                        configList = inventoryConfigList.reduce<
                            InventoryConfig[]
                        >((prev, inventoryConfig) => {
                            // if it is the config for this iac, return it
                            if (
                                inventoryConfig.id === iac.inventory_config?.id
                            ) {
                                return prev.concat(inventoryConfig)
                            }

                            // if this is the config for any other iac, do not return it
                            const isInOtherIAC = IACList.find(
                                (iac) =>
                                    iac.inventory_config?.id ===
                                    inventoryConfig.id,
                            )
                            if (isInOtherIAC) {
                                return prev
                            }
                            return prev.concat(inventoryConfig)
                        }, [])
                    }
                    return (
                        <ConfigSelectionRow
                            key={idx}
                            iac={iac}
                            selectMode={
                                open.formOpen === INVENTORY_CONFIG
                                    ? SELECT_AREA_MODE
                                    : SELECT_INVENTORY_MODE
                            }
                            updateIAC={(newIac) => {
                                const newIACList: RenderIAC[] = []
                                IACList.forEach((innerIAC, innerIDX) => {
                                    if (idx !== innerIDX) {
                                        newIACList.push(innerIAC)
                                    } else {
                                        newIACList.push(newIac)
                                    }
                                })
                                setIACList(newIACList)
                            }}
                            configOptions={configList}
                            remove={() => {
                                const newIACList: RenderIAC[] = []
                                IACList.forEach((innerIAC, innerIDX) => {
                                    if (idx !== innerIDX) {
                                        newIACList.push(innerIAC)
                                    }
                                })
                                setIACList(newIACList)
                            }}
                        />
                    )
                })}
                <Tooltip title="Add Inventory to Area">
                    <IconButton
                        onClick={() => {
                            setIACList([...IACList, { amount: 1 }])
                        }}
                    >
                        <Add color={'primary'} fontSize="large" />
                    </IconButton>
                </Tooltip>
            </Container>

            <Container
                justifyContent={'flex-end'}
                style={{
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    marginTop: theme.spacing(1),
                }}
            >
                <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => handleDrawerChange()}
                    style={{ marginRight: theme.spacing(1) }}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        const newIACList: ListInventoryAreaConfig[] = []
                        IACList.forEach((innerIAC, innerIDX) => {
                            if (innerIAC.amount !== 0) {
                                newIACList.push(innerIAC)
                            }
                        })
                        let reqIACS = []
                        if (open.formOpen === INVENTORY_CONFIG) {
                            reqIACS = newIACList.reduce<ReqIAC[]>(
                                (prev, iac) => {
                                    if (iac.area_config && iac.amount > 0) {
                                        return prev.concat({
                                            inventory_config_id:
                                                open.selected?.id ?? -1,
                                            area_config_id:
                                                iac.area_config?.id ?? -1,
                                            amount: iac.amount,
                                        })
                                    }
                                    return prev
                                },
                                [],
                            )
                        } else {
                            reqIACS = newIACList.reduce<ReqIAC[]>(
                                (prev, iac) => {
                                    if (
                                        iac.inventory_config &&
                                        iac.amount > 0
                                    ) {
                                        return prev.concat({
                                            inventory_config_id:
                                                iac.inventory_config?.id ?? -1,
                                            area_config_id:
                                                open.selected?.id ?? -1,
                                            amount: iac.amount,
                                        })
                                    }
                                    return prev
                                },
                                [],
                            )
                        }
                        const body: BulkSetIACRequest = { iacs: reqIACS }
                        if (open.formOpen === INVENTORY_CONFIG) {
                            body.inventory_config_id = open.selected?.id
                        } else {
                            body.area_config_id = open.selected?.id
                        }
                        axiosInstance
                            .post(
                                `apt_config/inventory_area_config/bulk_set_iac/`,
                                body,
                            )
                            .then(() => {
                                toast.success(
                                    `Successfully updated ${open.selected?.name}!`,
                                )
                                handleDrawerChange()
                            })
                    }}
                >
                    Save
                </Button>
            </Container>
        </SideDrawer>
    )
}

interface GSProps {
    areaConfigId: number
    refreshIacList: any
}

const GroupSection = (props: GSProps) => {
    const theme = useTheme()

    const [selectedGroupId, setSelectedGroupId] = useState(-1)

    const { inventoryGroups, addGroupToAreaConfig } = useInventoryGroup()

    const [modalOpen, setModalOpen] = useState(false)

    return (
        <Container
            style={{
                justifyContent: 'space-between',
                margin: theme.spacing(1),
            }}
        >
            <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                    setModalOpen(true)
                }}
            >
                Add Group
            </Button>

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="up" in={modalOpen}>
                    <Paper
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Container
                            style={{
                                padding: theme.spacing(2),
                                flexDirection: 'column',
                                width: 500,
                            }}
                        >
                            <span style={{ marginBottom: theme.spacing(2) }}>
                                Add Groups
                            </span>
                            <Selector
                                currentValue={selectedGroupId}
                                data={inventoryGroups ?? []}
                                onChange={(e) =>
                                    setSelectedGroupId(e.target.value as number)
                                }
                                getDisplayString={(d) => d.name}
                                label="Inventory Group"
                            />

                            <Container
                                style={{
                                    marginTop: theme.spacing(2),
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => setModalOpen(false)}
                                >
                                    Close
                                </Button>

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={async () => {
                                        await addGroupToAreaConfig(
                                            selectedGroupId,
                                            props.areaConfigId,
                                        )

                                        props.refreshIacList()
                                    }}
                                >
                                    Add Group
                                </Button>
                            </Container>
                        </Container>
                    </Paper>
                </Slide>
            </Modal>
        </Container>
    )
}
