import { Match } from '@testing-library/react'
import {
    AuditPromptConfig,
    DDLease,
    DDLeaseMatchStatus,
    MatchedLease,
} from '../../../../../models'
import { toMMDDYYYY } from '../../../../../helpers'
import { ReactNode } from 'react'

const statusSortOrder: DDLeaseMatchStatus[] = [
    DDLeaseMatchStatus.SUCCESS,
    DDLeaseMatchStatus.PARTIAL_MATCH,
    DDLeaseMatchStatus.DISCREPANCY,
    DDLeaseMatchStatus.NO_MATCH,
]

export const statusIndex: {
    [key in DDLeaseMatchStatus]: number
} = statusSortOrder.reduce((acc, status, index) => {
    acc[status] = index
    return acc
}, {} as { [key in DDLeaseMatchStatus]: number })

export const getCellValue = (
    lease: MatchedLease,
    prompt: AuditPromptConfig,
): ReactNode => {
    if (prompt.category === 'status') {
        return lease.status
    } else if (prompt.category === 'rent_roll') {
        const column = prompt.dd_lease_name as keyof DDLease
        return lease.rent_roll_lease[column]
    } else if (prompt.category === 'lease') {
        if (lease.pdf_lease !== null) {
            const column = prompt.dd_lease_name as keyof DDLease
            return lease.pdf_lease[column]
        }
    }
}

export const answerValue = (lease: DDLease, prompt: AuditPromptConfig) => {
    const name = prompt.dd_lease_name as keyof DDLease
    return lease[name]
}

export const doesMatch = (lease: MatchedLease, prompt: AuditPromptConfig) => {
    let match = true
    if (
        lease.pdf_lease &&
        prompt.category === 'rent_roll' &&
        prompt.dd_lease_name !== 'floor_plan' &&
        prompt.dd_lease_name !== 'tenant'
    ) {
        const name = prompt.dd_lease_name as keyof DDLease
        match = lease.rent_roll_lease[name] === lease.pdf_lease[name]
    }
    return match
}
