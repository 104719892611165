import React from 'react'
import { Button, Divider, TextField, useTheme } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Container, NumberInput, SideDrawer } from '../../components'
import { useAppDispatch } from '../../hooks'
import { ChangeOrderConfig } from '../../models'
import {
    createChangeOrderConfigRequest,
    CreateChangeOrderConfigRequestBody,
    editChangeOrderConfigRequest,
} from '../../store'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

interface Props {
    open: boolean
    changeOrderConfig?: ChangeOrderConfig
    handleClose: () => void
}

export const ChangeOrderConfigDrawer = (props: Props) => {
    const theme = useTheme()
    const dispatch = useAppDispatch()

    const [description, setDescription] = useState('')
    const [name, setName] = useState('')
    const [addon, setAddon] = useState(true)
    const [suggestedAddPrice, setSuggestedAddPrice] = useState('')
    const [upstreamPrice, setUpstreamPrice] = useState('')
    const [doValidate, setDoValidate] = useState(true)

    useEffect(() => {
        if (props.changeOrderConfig) {
            setDescription(props.changeOrderConfig.description)
            setName(props.changeOrderConfig.name)
            setAddon(props.changeOrderConfig.addon)
            setSuggestedAddPrice(
                props.changeOrderConfig.suggested_add_price.toString(),
            )
            setUpstreamPrice(
                props.changeOrderConfig.upstream_price?.toString() ?? '',
            )
        } else {
            setDescription('')
            setName('')
            setAddon(true)
            setDescription('')
            setUpstreamPrice('')
            setSuggestedAddPrice('')
        }
        setDoValidate(false)
    }, [props.changeOrderConfig, props.open])

    const inputStyle: React.CSSProperties = {
        margin: theme.spacing(1),
    }

    return (
        <SideDrawer
            open={props.open}
            handleClose={props.handleClose}
            title={
                props.changeOrderConfig
                    ? `Edit Change Order Config`
                    : 'Create Change Order Config'
            }
        >
            {/* Main Content  */}
            <Container flex={1} scrollY direction="column">
                <TextField
                    variant="outlined"
                    error={doValidate && name === ''}
                    helperText={doValidate && name === '' && 'Required'}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label="Name"
                    style={inputStyle}
                />
                <TextField
                    variant="outlined"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    label="Description"
                    style={inputStyle}
                />

                <ToggleButtonGroup
                    value={addon}
                    onChange={(_, value) => setAddon(value)}
                    aria-label="Addon"
                    exclusive
                    style={inputStyle}
                >
                    <ToggleButton value={true} aria-label="Addon">
                        Add On
                    </ToggleButton>
                    <ToggleButton value={false} aria-label="Change of Scope">
                        Change of Scope
                    </ToggleButton>
                </ToggleButtonGroup>

                <NumberInput
                    prefix={'$'}
                    variant="outlined"
                    label="Cost (ea)"
                    style={inputStyle}
                    value={suggestedAddPrice}
                    onChange={(e) => setSuggestedAddPrice(e.target.value)}
                />

                <NumberInput
                    prefix={'$'}
                    variant="outlined"
                    label="Billable (ea)"
                    style={inputStyle}
                    value={upstreamPrice}
                    onChange={(e) => setUpstreamPrice(e.target.value)}
                />

                <div style={{ flex: 1 }} />

                {/* Footer */}
                <Divider />
                <Container style={{ padding: theme.spacing(1) }}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{ marginRight: theme.spacing(2) }}
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="primary"
                        variant="outlined"
                        style={{ marginRight: theme.spacing(2) }}
                        onClick={() => {
                            if (name === '') {
                                setDoValidate(true)
                            } else {
                                const body: CreateChangeOrderConfigRequestBody = {
                                    description: description,
                                    name: name,
                                    addon: addon,
                                }

                                if (suggestedAddPrice !== '') {
                                    body.suggested_add_price = Number(
                                        suggestedAddPrice,
                                    )
                                }

                                if (upstreamPrice !== '') {
                                    body.upstream_price = Number(upstreamPrice)
                                }

                                if (props.changeOrderConfig) {
                                    // Update
                                    dispatch(
                                        editChangeOrderConfigRequest({
                                            id: props.changeOrderConfig.id,
                                            body: body,
                                        }),
                                    ).then(() => {
                                        toast.success(
                                            'Change Order Config Updated',
                                        )
                                        props.handleClose()
                                    })
                                } else {
                                    // Create
                                    dispatch(
                                        createChangeOrderConfigRequest({
                                            body: body,
                                        }),
                                    ).then(() => {
                                        toast.success(
                                            'Change Order Config Created',
                                        )
                                        props.handleClose()
                                    })
                                }
                            }
                        }}
                    >
                        Save
                    </Button>
                </Container>
            </Container>
        </SideDrawer>
    )
}
