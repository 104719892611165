import { AnimationEffectMap, AnimationItem } from '../hooks'
import { IdentifiableObject } from '../models'

export const RemoveListItem = <T extends IdentifiableObject>(
    durationMs: number,
    slideOut: T,
    slideUp: T[],
    slideDistance: number,
    cleanup?: () => void,
): AnimationItem => {
    const effectMap: AnimationEffectMap = {}
    // Slide Right
    effectMap[slideOut.id] = {
        animationStyles: {
            transitionProperty: 'transform, opacity',
            transitionDelay: '1ms',
            transitionDuration: `${durationMs}ms`,
            transitionTimingFunction: 'ease-in',
            transform: 'translateX(100%)',
            opacity: 0,
        },
    }

    // Slide Up
    slideUp.forEach((item) => {
        effectMap[item.id] = {
            animationStyles: {
                transitionProperty: 'transform',
                transitionDelay: '1ms',
                transitionDuration: `${durationMs}ms`,
                transitionTimingFunction: 'ease-in',
                transform: `translateY(-${slideDistance}px)`,
            },
        }
    })

    return {
        // Add 1ms to accomodate the delay
        duration: durationMs + 1,
        effectMap: effectMap,
        cleanup: cleanup,
    }
}
