import React, { useState } from 'react'

import {
    Badge,
    Checkbox,
    IconButton,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import MessageIcon from '@material-ui/icons/Message'
import AddBoxIcon from '@material-ui/icons/AddBox'

import {
    Attachment,
    Container,
    EditSpan,
    Selector,
    StatusBadge,
} from '../../components'
import {
    AreaConfig,
    Damage,
    DamageConfig,
    DamageStatus,
    DamageTransaction,
    getDamageStatusColor,
    getDamageStatusName,
    InventoryConfig,
    Lease,
    ModelMap,
    Unit,
    WorkSpaceUser,
} from '../../models'
import { currencyFormatter, toMMDDYYYY } from '../../helpers'

import { DamageTransactionRow } from './DamageTransactionRow'
import { DamageStatusChangeButtons } from './DamageStatusChangeButtons'

import { toast } from 'react-toastify'
import { MoveInModal } from './MoveInModal'
import { UpdateDamageRequest } from '../../hooks'

interface Props {
    damage: Damage
    selected: boolean
    messageDrawerOpen?: boolean
    workspaceUser?: WorkSpaceUser
    damageConfigList?: DamageConfig[]
    displayProblems: boolean
    onSelect: () => void
    handleStatusChange: (damages: Damage[], status: DamageStatus) => void
    removeDamageTransaction: (
        transaction: DamageTransaction,
        damage: Damage,
    ) => void
    onOpenMessageDrawer: (channelId: number, damageId: number) => void
    updateDamage: (
        damage: Damage,
        request: UpdateDamageRequest,
    ) => Promise<Damage | void>
    openPopover: (
        event: React.MouseEvent<HTMLElement>,
        damageId: number,
    ) => void
    areaConfigMap?: ModelMap<AreaConfig>
    invConfigMap?: ModelMap<InventoryConfig>
    unitMap?: ModelMap<Unit>
}

export const DamageRow = (props: Props) => {
    const {
        damage,
        handleStatusChange,
        messageDrawerOpen,
        workspaceUser,
        damageConfigList,
        updateDamage,
        openPopover,
        displayProblems,
        areaConfigMap,
        invConfigMap,
        unitMap,
    } = props

    const theme = useTheme()

    const damageCost = damage.amount * damage.cost

    const description = damage.damage_config
        ? damage.damage_config.description
        : damage.description

    const damageDisabled =
        damage.status !== DamageStatus.PENDING &&
        damage.status !== DamageStatus.PRE_APPROVED

    const [lease, setLease] = useState<Lease>()

    return (
        <Container
            style={{
                margin: theme.spacing(1),
                backgroundColor: messageDrawerOpen
                    ? theme.palette.primary.light
                    : theme.palette.grey[100],
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.palette.grey[600]}`,
                minHeight: 275,
                overflowX: 'auto',
            }}
        >
            {/* Left Column: Damage Details */}
            <Container
                style={{
                    padding: theme.spacing(1),
                    minWidth: LEFT_COL_W,
                    maxWidth: LEFT_COL_W,
                }}
            >
                <Container style={{ flexDirection: 'column', flex: 1 }}>
                    {/* Folder Path + CheckBox */}
                    <Container style={{ alignItems: 'center' }}>
                        <Checkbox
                            style={{ width: CHECK_BOX_W }}
                            checked={props.selected}
                            onChange={props.onSelect}
                        />
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightMedium,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width:
                                    LEFT_COL_W -
                                    CHECK_BOX_W -
                                    2 * theme.spacing(1),
                            }}
                        >
                            {damage.folder_path}
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeightBold,
                                    marginLeft: theme.spacing(1),
                                }}
                            >
                                {damage.folder_name}
                            </span>
                        </span>
                    </Container>

                    <Container
                        style={{
                            alignItems: 'center',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                color: theme.palette.grey[700],
                            }}
                        >
                            {damage.inspection_name}
                        </span>

                        <div style={{ flex: 1 }} />

                        <span
                            style={{
                                ...theme.typography.subtitle2,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {toMMDDYYYY(new Date(damage.created_date))}
                        </span>
                    </Container>

                    {/* Unit + area */}
                    <Container>
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {damage.unit_name}
                        </span>

                        <span
                            style={{
                                ...theme.typography.h6,
                                marginLeft: theme.spacing(1),
                            }}
                        >
                            {damage.area_config_name} ({damage.area_label})
                        </span>
                    </Container>

                    {/* Damage Description */}
                    <EditSpan
                        style={{
                            ...theme.typography.subtitle1,
                            overflow: 'hidden',
                            maxLines: 1,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: LEFT_COL_W - 2 * theme.spacing(2),
                        }}
                        disabled={damageDisabled}
                        hideSubmitButtons
                        customPopoverContent={
                            <Container style={{ flexDirection: 'column' }}>
                                <Selector
                                    label="Damage Config"
                                    searchable
                                    currentValue={
                                        damage.damage_config?.id ?? -1
                                    }
                                    data={damageConfigList ?? []}
                                    customStyle={{
                                        formControl: { margin: 0, width: 200 },
                                    }}
                                    onChange={(
                                        event: React.ChangeEvent<{
                                            value: unknown
                                        }>,
                                    ) => {
                                        const request: UpdateDamageRequest = {
                                            damage_config: event.target
                                                .value as number,
                                        }

                                        updateDamage(damage, request).catch(
                                            (e) => {
                                                toast.error(
                                                    `Error updating damage ${e}`,
                                                )
                                            },
                                        )
                                    }}
                                    getDisplayString={(
                                        damageConfig: DamageConfig,
                                    ) => `${damageConfig.description}`}
                                />
                            </Container>
                        }
                    >
                        {description}
                    </EditSpan>

                    {/* Damage Cost */}
                    <Container
                        style={{
                            overflow: 'hidden',
                            maxLines: 1,
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: LEFT_COL_W - 2 * theme.spacing(1),
                            alignItems: 'center',
                            minHeight: 32,
                        }}
                    >
                        <EditSpan
                            style={{ ...theme.typography.subtitle1 }}
                            dataType="number"
                            disabled={damageDisabled}
                            onSave={(newValue) => {
                                const request: UpdateDamageRequest = {
                                    cost: newValue,
                                }

                                updateDamage(damage, request).catch((e) => {
                                    toast.error(`Error updating damage ${e}`)
                                })
                            }}
                        >
                            {currencyFormatter.format(damage.cost)}
                        </EditSpan>
                        <span style={{ ...theme.typography.subtitle1 }}>x</span>
                        <EditSpan
                            style={{ ...theme.typography.subtitle1 }}
                            dataType="number"
                            disabled={damageDisabled}
                            onSave={(newValue) => {
                                const request: UpdateDamageRequest = {
                                    amount: Number(newValue),
                                }

                                updateDamage(damage, request).catch((e) => {
                                    toast.error(`Error updating damage ${e}`)
                                })
                            }}
                        >
                            {damage.amount}
                        </EditSpan>
                        <span style={{ ...theme.typography.subtitle1 }}>=</span>
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                marginLeft: theme.spacing(1),
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {currencyFormatter.format(damageCost)}
                        </span>
                    </Container>

                    <DamageStatusChangeButtons
                        damages={damage}
                        handleStatusChange={handleStatusChange}
                        workspaceUser={workspaceUser}
                    />

                    <Container>
                        <StatusBadge
                            text={getDamageStatusName(damage.status)}
                            customStyle={{
                                container: {
                                    width: 150,
                                    backgroundColor: getDamageStatusColor(
                                        damage,
                                    ),
                                },
                                text: { fontWeight: 'bold' },
                            }}
                        />
                        <div style={{ flex: 1 }} />
                        <IconButton
                            onClick={() =>
                                props.onOpenMessageDrawer(
                                    damage.channel,
                                    damage.id,
                                )
                            }
                        >
                            <Badge
                                badgeContent={damage.message_count}
                                color="secondary"
                            >
                                <MessageIcon />
                            </Badge>
                        </IconButton>
                    </Container>
                </Container>
            </Container>

            {/* Middle Column: AR Transactions */}
            <Container
                style={{
                    borderLeft: `1px solid ${theme.palette.grey[600]}`,
                    borderRight: `1px solid ${theme.palette.grey[600]}`,
                    minWidth: 510,
                    maxWidth: 510,
                    flexDirection: 'column',
                    padding: theme.spacing(1),
                }}
            >
                <Container style={{ alignItems: 'center' }}>
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: 'bold',
                        }}
                    >
                        AR Transactions
                    </span>

                    <div style={{ flex: 1 }} />

                    <div>
                        <Tooltip title={'Add Transaction'}>
                            <IconButton
                                disabled={damageDisabled}
                                onClick={(e) => openPopover(e, damage.id)}
                            >
                                <AddBoxIcon
                                    color={
                                        damageDisabled ? 'disabled' : 'primary'
                                    }
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Container>
                <Container style={{ flexWrap: 'wrap' }} scrollY>
                    {damage.damage_transactions.map((dt) => {
                        return (
                            <DamageTransactionRow
                                key={`DAMAGE_TRANSACTION_${dt.id}`}
                                damageTransaction={dt}
                                damage={damage}
                                totalCost={damage.amount * damage.cost}
                                splitBy={damage.damage_transactions.length}
                                removeDamageTransaction={() =>
                                    props.removeDamageTransaction(dt, damage)
                                }
                                displayProblems={displayProblems}
                                openMoveInModal={() => {
                                    if (invConfigMap) {
                                        console.log(dt)
                                        setLease(dt.lease)
                                    }
                                }}
                            />
                        )
                    })}
                </Container>
            </Container>

            {/* Right Column: Attachments */}
            <Container
                style={{
                    padding: theme.spacing(1),
                    paddingBottom: 0,
                    paddingRight: 0,
                    alignItems: 'center',
                    overflowX: 'auto',
                }}
            >
                {damage.attachments.map((attachment) => {
                    return (
                        <div key={`ATTACHMENT_${attachment.id}`} style={{}}>
                            <Attachment
                                attachment={attachment.file}
                                uploaded={attachment.uploaded}
                                theme={theme}
                            />
                        </div>
                    )
                })}
            </Container>

            <div style={{ flex: 1 }} />
            {invConfigMap && areaConfigMap && unitMap && (
                <MoveInModal
                    open={lease !== undefined}
                    handleClose={() => setLease(undefined)}
                    areaConfigMap={areaConfigMap}
                    unitMap={unitMap}
                    invConfigMap={invConfigMap}
                    lease={lease}
                />
            )}
        </Container>
    )
}

const LEFT_COL_W = 300
const CHECK_BOX_W = 42
