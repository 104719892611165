import { TableCell, TableRow, Tooltip, useTheme } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    CellData,
    Container,
    HeadCell,
    RenderRow,
    RowData,
    SideDrawerContainer,
    SimpleTable,
} from '../../components'
import { useAppDispatch } from '../../hooks'
import { ChangeOrderConfig } from '../../models'
import { getChangeOrderConfigListRequest, RootState } from '../../store'
import { useStyles } from '../../styles'
import { ChangeOrderConfigDrawer } from './ChangeOrderConfigDrawer'

export const ChangeOrderConfigList = () => {
    const dispatch = useAppDispatch()

    const changeOrderConfigList = useSelector(
        (state: RootState) => state.aptConfig.changeOrderConfigList,
    )
    const [selectedConfig, setSelectedConfig] = useState<ChangeOrderConfig>()
    const [drawerOpen, setDrawerOpen] = useState(false)
    const theme = useTheme()
    const classes = useStyles()

    useEffect(() => {
        dispatch(getChangeOrderConfigListRequest({}))
    }, [])

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    interface ChangeOrderConfigRowType extends RowData {
        name: CellData<string>
        description: CellData<string>
        addon: CellData<boolean>
        suggestedAddPrice: CellData<number>
        upstreamPrice: CellData<number | null>
    }

    const headCells: HeadCell<ChangeOrderConfigRowType>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'name',
            label: 'Name',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'description',
            label: 'Description',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'addon',
            label: 'Type',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'suggestedAddPrice',
            label: 'Expense',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'upstreamPrice',
            label: 'Billable',
            style: headCellStyle,
        },
    ]

    const createRow = (
        changeOrderConfig: ChangeOrderConfig,
    ): ChangeOrderConfigRowType => {
        return {
            name: {
                value: changeOrderConfig.name,
                sortValue: changeOrderConfig.name,
            },
            description: {
                value: changeOrderConfig.description,
                sortValue: changeOrderConfig.description,
            },
            addon: {
                value: changeOrderConfig.addon,
                sortValue: changeOrderConfig.addon ? 1 : 0,
            },
            suggestedAddPrice: {
                value: changeOrderConfig.suggested_add_price,
                sortValue: changeOrderConfig.suggested_add_price,
            },
            upstreamPrice: {
                value: changeOrderConfig.upstream_price,
                sortValue: changeOrderConfig.upstream_price ?? 0,
            },
            changeOrderConfig: { value: changeOrderConfig, sortValue: 1 },
        }
    }

    interface ChangeOrderConfigRowProps {
        row: ChangeOrderConfigRowType
    }

    const ChangeOrderConfigRow: RenderRow<ChangeOrderConfigRowProps> = (
        props,
    ) => {
        const { row } = props
        const cellStyle: React.CSSProperties = {
            ...theme.typography.body2,
            fontWeight: theme.typography.fontWeightRegular,
        }

        return (
            <TableRow
                key={`CHANGE_ORDER_CONF_ROW_${row.changeOrderConfig.value.id}`}
                style={{
                    cursor: 'pointer',
                    backgroundColor:
                        selectedConfig?.id === row.changeOrderConfig.value.id
                            ? theme.palette.grey[300]
                            : undefined,
                }}
                className={classes.hoverGrey300}
                onClick={() => {
                    setSelectedConfig(row.changeOrderConfig.value)
                    setDrawerOpen(true)
                }}
            >
                <TableCell style={cellStyle}>{row.name.value}</TableCell>
                <TableCell align="center" style={cellStyle}>
                    {row.description.value}
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                    {row.addon.value ? 'Add on' : 'Change of Scope'}
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                    {row.suggestedAddPrice.value}
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                    {row.upstreamPrice.value}
                </TableCell>
            </TableRow>
        )
    }

    return (
        <SideDrawerContainer open={drawerOpen}>
            <Container
                direction={'column'}
                flex={1}
                style={{ height: 'calc(100vh - 104px)' }}
            >
                {/* header */}
                <Container>
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            marginBottom: theme.spacing(1),
                            flex: 1,
                        }}
                    >
                        Change Order Config
                    </span>
                    <Tooltip title="New Change Order Config">
                        <Add
                            fontSize="large"
                            onClick={() => {
                                setSelectedConfig(undefined)
                                setDrawerOpen(true)
                            }}
                            color="primary"
                            className={classes.highlightCircleOnHover}
                        />
                    </Tooltip>
                </Container>

                <SimpleTable<ChangeOrderConfigRowType>
                    rows={
                        changeOrderConfigList?.map(
                            (changeOrderConfig: ChangeOrderConfig) =>
                                createRow(changeOrderConfig),
                        ) ?? []
                    }
                    render={(row) => <ChangeOrderConfigRow row={row} />}
                    orderByDefault={'name'}
                    headCells={headCells}
                />
                <ChangeOrderConfigDrawer
                    open={drawerOpen}
                    handleClose={() => {
                        setSelectedConfig(undefined)
                        setDrawerOpen(false)
                    }}
                    changeOrderConfig={selectedConfig}
                />
            </Container>
        </SideDrawerContainer>
    )
}
