import { axiosInstance } from '../helpers'

export const useStripe = () => {
    const createStripeCheckoutSession = (
        request: CreateStripeCheckoutRequest,
    ) => {
        axiosInstance
            .post(
                'company/stripe_sessions/create_stripe_checkout_session/',
                request,
            )
            .then((res) => {
                window.location.href = res.data.url
            })
    }

    const createStripePortalSession = (
        request: CreateStripePortalSessionRequest,
    ) => {
        axiosInstance
            .post(
                'company/stripe_sessions/create_stripe_portal_session/',
                request,
            )
            .then((res) => {
                window.location.href = res.data.url
            })
    }

    const purchaseLeaseAudit = (request: PurchaseLeaseAuditRequest) => {
        axiosInstance
            .post('company/stripe_sessions/purchase_lease_audit/', request)
            .then((res) => {
                window.location.href = res.data.url
            })
    }

    return {
        createStripeCheckoutSession,
        createStripePortalSession,
        purchaseLeaseAudit,
    }
}

export interface CreateStripeCheckoutRequest {
    success_url: string
    plan_type?: string
    product?: number | string
}

export interface CreateStripePortalSessionRequest {
    return_url: string
}

export interface PurchaseLeaseAuditRequest {
    success_url: string
    lease_audit: number
}
