import React from 'react'
import {
    RFPContext,
    WorkorderContext,
    useRFPState,
    useWorkorderState,
} from '../../contexts'
import { ActionItemHome } from './ActionItemHome'

export const ActionItemRoot = () => {
    const workorderState = useWorkorderState()
    const rfpState = useRFPState()

    return (
        <WorkorderContext.Provider value={workorderState}>
            <RFPContext.Provider value={rfpState}>
                <ActionItemHome />
            </RFPContext.Provider>
        </WorkorderContext.Provider>
    )
}
