import React, { useEffect } from 'react'

import { Container } from '../../../../components'
import {
    TableContainer,
    Theme,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableBody,
    Button,
    IconButton,
} from '@material-ui/core'
import { MoveInInspectionController } from '../../../../hooks/useMoveInInspection'
import { footerH, headerH, modalH } from './constants'
import { LeaseRow } from './LeaseRow'
import { useLease } from '../../../../hooks'
import { DeleteForever } from '@material-ui/icons'

interface Props {
    theme: Theme
    onClickBack: () => void
    moveInController: MoveInInspectionController
}

export const MoveInEventDetail = (props: Props) => {
    const { theme, moveInController, onClickBack } = props

    const leaseController = useLease()

    useEffect(() => {
        if (moveInController.selectedMoveInEvent === null) {
            return
        }

        const leaseIds = moveInController.selectedMoveInEvent.leases.map(
            (l) => l.id,
        )
        const leaseIdStr = leaseIds.join(',')
        leaseController.getLeaseList({
            params: {
                leases: leaseIdStr,
                exclude_children: true,
                home: true,
            },
        })
    }, [moveInController.selectedMoveInEvent])

    const selectedEvent = moveInController.selectedMoveInEvent

    const showDelete = selectedEvent?.handled === false

    return (
        <Container
            style={{
                flexDirection: 'column',
                padding: theme.spacing(1),
                flex: 1,
            }}
        >
            {/* Body */}

            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    maxHeight: modalH - headerH - footerH - theme.spacing(2),
                    minHeight: modalH - headerH - footerH - theme.spacing(2),
                }}
            >
                {/* Header */}
                <Container
                    style={{
                        minHeight: headerH,
                        maxHeight: headerH,
                    }}
                >
                    <Container
                        style={{
                            flexDirection: 'column',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                                color: theme.palette.text.primary,
                            }}
                        >
                            Event Status
                        </span>
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                color: theme.palette.text.secondary,
                                textAlign: 'left',
                            }}
                        >
                            {selectedEvent?.handled ? 'Started' : 'Not Started'}
                        </span>
                    </Container>

                    <Container
                        style={{
                            flexDirection: 'column',
                            marginLeft: theme.spacing(8),
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                                color: theme.palette.text.primary,
                                textAlign: 'left',
                            }}
                        >
                            Start Date
                        </span>
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                color: theme.palette.text.secondary,
                                textAlign: 'left',
                            }}
                        >
                            {new Date(
                                selectedEvent?.send_date ?? '',
                            ).toLocaleString()}
                        </span>
                    </Container>
                </Container>

                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Tenant</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Start</TableCell>
                                <TableCell>End</TableCell>
                                <TableCell>Lease Status</TableCell>
                                <TableCell>Move In Status</TableCell>
                                {showDelete && <TableCell></TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {leaseController.leaseList.map((lease) => {
                                return (
                                    <LeaseRow
                                        key={`LEASE-${lease.id}`}
                                        lease={lease}
                                        theme={theme}
                                    >
                                        {showDelete && (
                                            <TableCell>
                                                <IconButton
                                                    onClick={() => {
                                                        moveInController
                                                            .removeLeaseFromEvent(
                                                                selectedEvent,
                                                                lease,
                                                            )
                                                            .catch((e) => {
                                                                console.log(e)
                                                            })
                                                    }}
                                                >
                                                    <DeleteForever />
                                                </IconButton>
                                            </TableCell>
                                        )}
                                    </LeaseRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>

            {/* Footer */}
            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    minHeight: footerH,
                    maxHeight: footerH,
                    alignItems: 'center',
                }}
            >
                <Button
                    variant="contained"
                    color="secondary"
                    style={{
                        textTransform: 'none',
                    }}
                    onClick={onClickBack}
                >
                    Back
                </Button>
                <div style={{ flex: 1 }} />
                <Button
                    variant="contained"
                    color="secondary"
                    style={{
                        textTransform: 'none',
                    }}
                    disabled={moveInController.selectedMoveInEvent?.handled}
                    onClick={() => {
                        if (moveInController.selectedMoveInEvent === null) {
                            return
                        }

                        moveInController.deleteQueuedEvent(
                            moveInController.selectedMoveInEvent,
                        )
                        onClickBack()
                    }}
                >
                    Cancel Move In Event
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        textTransform: 'none',
                        marginLeft: theme.spacing(2),
                    }}
                    disabled={
                        moveInController.loading ||
                        selectedEvent?.handled ||
                        selectedEvent === null
                    }
                    onClick={() => {
                        if (selectedEvent === null) {
                            return
                        }

                        moveInController.startMoveInNow(selectedEvent)
                    }}
                >
                    Start Move In Now
                </Button>
            </Container>
        </Container>
    )
}
