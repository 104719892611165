import { Tab, Tabs, useTheme } from '@material-ui/core'
import React from 'react'
import { Container, TabPanel } from '../../components'
import { Location } from '../../store'
import { ComingSoon } from '../ComingSoon'
import {
    ACTION_ITEM_TAB,
    WORKORDER_TAB,
    INVENTORY_TAB,
    DAMAGE_TAB,
    LEASE_TAB,
    MESSAGE_TAB,
    InfrastructureManageTab,
} from './InfrastructureManagerHoc'
import { InventoryTabPanel } from './InventoryTabPanel'
import { WorkorderTabPanel } from './WorkorderTabPanel'

interface Props {
    tab: InfrastructureManageTab
    userSelection: Location | undefined
    setTab: (tab: InfrastructureManageTab) => void
}

export const InfrastructureTabs = (props: Props) => {
    const { tab, userSelection, setTab } = props

    const theme = useTheme()

    const TabPanelStyle: React.CSSProperties = {
        backgroundColor: theme.palette.grey[200],
        flex: 1,
    }

    const tabsDisabled = userSelection === undefined
    const isFolderSelected = userSelection?.type === 'folder'

    const tabs = (
        <Tabs
            style={{ backgroundColor: theme.palette.grey[300] }}
            centered
            value={tab}
            onChange={(_, newTab) => {
                setTab(newTab)
            }}
        >
            <Tab
                label="Action Items"
                value={ACTION_ITEM_TAB}
                disabled={tabsDisabled}
            />
            <Tab
                label="Work Orders"
                value={WORKORDER_TAB}
                disabled={tabsDisabled}
            />
            <Tab
                label="Inventory"
                value={INVENTORY_TAB}
                disabled={tabsDisabled || isFolderSelected}
            />
            <Tab label="Damages" value={DAMAGE_TAB} disabled={tabsDisabled} />
            <Tab label="Leases" value={LEASE_TAB} disabled={tabsDisabled} />
            <Tab label="Messages" value={MESSAGE_TAB} disabled={tabsDisabled} />
        </Tabs>
    )

    const tabPannels =
        userSelection === undefined ? (
            <Container
                style={{
                    ...TabPanelStyle,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <span
                    style={{
                        ...theme.typography.h5,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Please select a Folder or Unit
                </span>
            </Container>
        ) : (
            <>
                <TabPanel
                    value={ACTION_ITEM_TAB}
                    index={tab}
                    style={TabPanelStyle}
                >
                    <ComingSoon />
                </TabPanel>

                <WorkorderTabPanel tab={tab} />
                <InventoryTabPanel tab={tab} userSelection={userSelection} />

                <TabPanel value={DAMAGE_TAB} index={tab} style={TabPanelStyle}>
                    <ComingSoon />
                </TabPanel>

                <TabPanel value={LEASE_TAB} index={tab} style={TabPanelStyle}>
                    <ComingSoon />
                </TabPanel>

                <TabPanel value={MESSAGE_TAB} index={tab} style={TabPanelStyle}>
                    <ComingSoon />
                </TabPanel>
            </>
        )

    return (
        <Container style={{ flex: 1, flexDirection: 'column' }} scrollY>
            {tabs}
            {tabPannels}
        </Container>
    )
}
