import { ServiceContract, ServiceContractCompoundKey } from './types'

export const getScCompoundKeyString = (
    serviceContract: ServiceContractCompoundKey | ServiceContract,
) => {
    if ('serviceId' in serviceContract) {
        const areaKey = serviceContract.areaConfigId ?? '-'
        const unitKey = serviceContract.unitConfigId ?? '-'
        return `${serviceContract.serviceId}_${serviceContract.vendorId}_${areaKey}_${unitKey}`
    } else {
        const areaKey = serviceContract.area_config ?? '-'
        const unitKey = serviceContract.unit_config ?? '-'

        return `${serviceContract.service}_${serviceContract.vendor}_${areaKey}_${unitKey}`
    }
}

export const parseScCompoundKeyStr = (
    keyStr: string,
): ServiceContractCompoundKey | null => {
    const values = keyStr.split('_')
    if (values.length !== 4) {
        return null
    }

    let areaConfigId: number | null
    if (values[2] === '-') {
        areaConfigId = null
    } else {
        areaConfigId = Number(values[2])
    }

    let unitConfigId: number | null
    if (values[3] === '-') {
        unitConfigId = null
    } else {
        unitConfigId = Number(values[3])
    }

    return {
        serviceId: Number(values[0]),
        vendorId: Number(values[1]),
        areaConfigId: areaConfigId,
        unitConfigId: unitConfigId,
    }
}
