import React, { useEffect, useMemo, useState } from 'react'
import {
    Button,
    Checkbox,
    CircularProgress,
    IconButton,
    TextField,
    useTheme,
} from '@material-ui/core'

import CachedIcon from '@material-ui/icons/Cached'
import SearchIcon from '@material-ui/icons/Search'
import TuneIcon from '@material-ui/icons/Tune'

import { Container } from '../../../../components'
import { FinderSelection, useAptConfig, useLease } from '../../../../hooks'
import {
    Lease,
    LeaseStatus,
    LeaseStatusType,
    ModelMap,
    traverse,
    Unit,
} from '../../../../models'
import { axiosInstance, toMMDDYYYY } from '../../../../helpers'
import { UnitWithFolder } from '../../../../store'

import { toast } from 'react-toastify'

import {
    AutoCompleteFilterValue,
    DateFilterState,
    IssueFilterValue,
    LeaseStatusFilterState,
    TenantInspectionStatusFilterState,
} from '../../../../hooks/useLeaseFilter'
import { VersionedTree } from '../../../../hooks/useInfrastructure/types'
import { PaginationController } from '../../../DamageHome/MoveInspectionContainer'
import { LeaseRow } from './LeaseRow'
import {
    BulkTransitionModal,
    CreateLeaseModal,
    LeaseDetailModal,
    LeaseTransferModal,
    TenantInspectionModal,
} from '../../Forms'
import { LeaseHomeChips } from './LeaseHomeChips'

interface Props {
    getFilteredLeases: (leaseList: Lease[], unitMap: ModelMap<Unit>) => Lease[]
    statusFilter: LeaseStatusFilterState
    setStatusFilter: (filter: LeaseStatusFilterState) => void
    filterDrawerOpen: boolean
    setFilterDrawerOpen: (open: boolean) => void
    searchValue: string
    setSearchValue: (val: string) => void
    dateFilter: DateFilterState
    setDateFilter: (state: DateFilterState) => void
    locationFinderSelection: FinderSelection
    tree: VersionedTree
    unitCount: number
    leaseList: Lease[]
    updateLease: (lease: Lease) => void
    transitionLease: (
        leaseId: number,
        toStatus: LeaseStatusType,
    ) => Promise<Lease>
    isLoading: boolean
    setLeaseList: (leases: Lease[]) => void
    hasIssueFilter: IssueFilterValue
    setHasIssue: (has: IssueFilterValue) => void
    tenantInspectionStatusFilter: TenantInspectionStatusFilterState
    setTenantInspectionStatusFilter: (
        status: TenantInspectionStatusFilterState,
    ) => void
    autoCompleteFilter: AutoCompleteFilterValue
    setAutoCompleteFilter: (val: AutoCompleteFilterValue) => void
    lastSyncDate: Date | null
}

export const LeaseHomeLeasesTab = (props: Props) => {
    const {
        getFilteredLeases,
        statusFilter,
        filterDrawerOpen,
        setFilterDrawerOpen,
        setSearchValue,
        searchValue,
        dateFilter,
        setDateFilter,
        locationFinderSelection,
        setStatusFilter,
        tree,
        unitCount,
        leaseList,
        transitionLease,
        isLoading,
        setLeaseList,
        hasIssueFilter,
        setHasIssue,
        tenantInspectionStatusFilter,
        setTenantInspectionStatusFilter,
        updateLease,
        autoCompleteFilter,
        setAutoCompleteFilter,
        lastSyncDate,
    } = props
    const [createModalOpen, setCreateModalOpen] = useState(false)

    // const [transferModalOpen, setTransferModalOpen] = useState(false)

    const [transferLeaseIdx, setTransferLeaseIdx] = useState(-1)

    const [tenantInspectModalOpen, setTenantInspectModalOpen] = useState(false)
    const [allowTransitions, setAllowTransitions] = useState(false)

    const [pageSize, setPageSize] = useState(25)
    const [page, setPage] = useState(0)
    const [bulkTransitionModalOpen, setBulkTransitionModalOpen] = useState(
        false,
    )

    const [unitMap, setUnitMap] = useState<ModelMap<Unit>>({})

    const {
        leaseList: stagedLeaseList,
        getLeaseList: getStagedLeaseList,
        isLoading: stagedLeaseLoading,
        setLeaseList: setStagedLeaseList,
    } = useLease()

    const transitionAndCheckStaged = (
        leaseId: number,
        toStatus: LeaseStatusType,
    ) => {
        transitionLease(leaseId, toStatus).then((lease) => {
            if (toStatus === LeaseStatus.CURRENT) {
                setStagedLeaseList([...stagedLeaseList, lease])
            }
        })
    }

    const { getAreaConfigMap } = useAptConfig({ areaConfigList: true })
    const areaConfigMap = getAreaConfigMap()

    const [selectedLeases, setSelectedLeases] = useState<boolean[] | null>(null)

    const [leaseDetail, setLeaseDetail] = useState<Lease | null>(null)

    const filteredLeases = getFilteredLeases(leaseList ?? [], unitMap)

    const [
        unitOccupancyDetails,
        setUnitOccupancyDetails,
    ] = useState<UnitOccState>({
        occupied_unit_list: [],
        partial_unit_list: [],
        vacant_unit_list: [],
    })

    // Load the unit details
    useEffect(() => {
        axiosInstance.get('lease/home_detail/').then((r) => {
            setUnitOccupancyDetails(r.data)
        })
        getStagedLeaseList({
            params: {
                statuses: `${LeaseStatus.CURRENT},`,
                home: true,
                staged: true,
                is_child: false,
            },
        })
    }, [])

    // Build a unit map
    useEffect(() => {
        const newUnitMap: ModelMap<Unit> = {}

        traverse(tree.root, (folder) => {
            folder.units.forEach((u) => {
                newUnitMap[u.id] = u
            })
        })
        setUnitMap(newUnitMap)
    }, [tree.version])

    // When the filters change, make sure that the users selected page still exists
    // If it does not select the the last valid page
    useEffect(() => {
        if (page + 1 > numPages) {
            setPage(Math.max(0, numPages - 1))
        }
    }, [filteredLeases])

    const numPages = Math.ceil((filteredLeases?.length ?? 0) / pageSize)

    const paginatedList = useMemo(() => {
        const start = page * pageSize
        const end = start + pageSize

        return filteredLeases?.slice(start, end)
    }, [page, filteredLeases])

    const selectedLeaseCount: number = useMemo(() => {
        if (selectedLeases === null || leaseList === undefined) {
            return 0
        }

        let selectedCount = 0
        for (let i = 0; i < selectedLeases.length; i++) {
            if (selectedLeases[i]) {
                selectedCount++
            }
        }

        return selectedCount
    }, [selectedLeases, leaseList])

    const selectAllChecked = selectedLeaseCount === leaseList?.length
    const selectAllIntermediate =
        selectedLeaseCount > 0 && selectedLeaseCount < (leaseList?.length ?? 0)

    const transferLease = paginatedList[transferLeaseIdx]

    return (
        <Container>
            <Container style={{ flexDirection: 'column', flex: 1 }}>
                <Header
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    onClickAddLease={() => setCreateModalOpen(true)}
                    onClickFilter={() => setFilterDrawerOpen(!filterDrawerOpen)}
                    onClickTenantInspectReady={() =>
                        setTenantInspectModalOpen(true)
                    }
                    dateFilter={dateFilter}
                    setDateFilter={setDateFilter}
                    locationFinderSelection={locationFinderSelection}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    stagedCount={stagedLeaseList.length}
                    stagedLoading={stagedLeaseLoading}
                    setBulkMode={setBulkTransitionModalOpen}
                    bulkMode={bulkTransitionModalOpen}
                    page={page}
                    numPages={numPages}
                    listLength={filteredLeases.length}
                    setPage={setPage}
                    hasIssue={hasIssueFilter}
                    setHasIssue={setHasIssue}
                    tenantInspectionStatusFilter={tenantInspectionStatusFilter}
                    setTenantInspectionStatusFilter={
                        setTenantInspectionStatusFilter
                    }
                    autoCompleteFilter={autoCompleteFilter}
                    setAutoCompleteFilter={setAutoCompleteFilter}
                    lastSyncDate={props.lastSyncDate}
                />

                <Container
                    style={{
                        backgroundColor: '#F4F4F4',
                        minHeight: '48px',
                        marginTop: '15px',
                        borderRadius: '5px',
                        justifyContent: 'space-around',
                    }}
                >
                    <ValueLabel
                        value={unitCount.toString()}
                        label="Total Units"
                    />
                    <ValueLabel
                        value={unitOccupancyDetails.occupied_unit_list.length.toString()}
                        label="Occupied Units"
                    />
                    <ValueLabel
                        value={unitOccupancyDetails.vacant_unit_list.length.toString()}
                        label="Vacant Units"
                    />
                    <ValueLabel
                        value={unitOccupancyDetails.partial_unit_list.length.toString()}
                        label="Partial Units"
                    />
                </Container>

                <Container
                    style={{
                        textAlign: 'center',
                        paddingLeft: '41px',
                        paddingRight: '41px',
                        alignItems: 'center',
                        height: '50px',
                    }}
                >
                    <span style={tableHeaderStyle}>Tenant Info</span>
                    <span style={tableHeaderStyle}>Location</span>
                    <span style={tableHeaderStyle}>Unit/Area</span>
                    <span style={tableHeaderStyle}>Start</span>
                    <span style={tableHeaderStyle}>End</span>
                    <span style={tableHeaderStyle}>Lease Status</span>
                    <span style={tableHeaderStyle}>Move In Status</span>
                    <Container
                        style={{
                            ...tableHeaderStyle,

                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ flex: 1 }} />

                        {allowTransitions && (
                            <Checkbox
                                onChange={() => {
                                    if (selectAllChecked) {
                                        setSelectedLeases(
                                            leaseList?.map(() => false) ?? null,
                                        )
                                    } else {
                                        setSelectedLeases(
                                            leaseList?.map(() => true) ?? null,
                                        )
                                    }
                                }}
                                checked={selectAllChecked}
                                indeterminate={selectAllIntermediate}
                            />
                        )}
                    </Container>
                </Container>

                {isLoading ? (
                    <Container
                        style={{
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress size={200} />
                    </Container>
                ) : (
                    <Container
                        style={{
                            flexDirection: 'column',
                            textAlign: 'center',
                            overflowY: 'scroll',
                        }}
                        className="scroll-bar"
                    >
                        {paginatedList?.map((lease, index) => {
                            const paginatedIndex = index + page * pageSize

                            const idxChecked =
                                selectedLeases === null
                                    ? false
                                    : selectedLeases[paginatedIndex]

                            return (
                                <LeaseRow
                                    key={`LEASE_ROW_${lease.id}`}
                                    lease={lease}
                                    onClickTransfer={() =>
                                        setTransferLeaseIdx(index)
                                    }
                                    unitMap={unitMap}
                                    areaConfigMap={areaConfigMap}
                                    transitionLease={transitionAndCheckStaged}
                                    onClick={setLeaseDetail}
                                    allowTransitions={allowTransitions}
                                    isChecked={idxChecked}
                                    onCheck={() =>
                                        setSelectedLeases((oldValues) => {
                                            if (oldValues) {
                                                const newValues = [...oldValues]
                                                newValues[
                                                    paginatedIndex
                                                ] = !idxChecked
                                                return newValues
                                            }
                                            return oldValues
                                        })
                                    }
                                />
                            )
                        })}
                    </Container>
                )}
                <Container>
                    <div style={{ flex: 1 }} />
                    <PaginationController
                        page={page}
                        numPages={numPages}
                        listLength={filteredLeases.length ?? 0}
                        setPage={setPage}
                    />
                </Container>
            </Container>

            <CreateLeaseModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                unitMap={unitMap}
                areaConfigMap={areaConfigMap}
            />

            <TenantInspectionModal
                open={tenantInspectModalOpen}
                onClose={() => setTenantInspectModalOpen(false)}
                leaseList={stagedLeaseList}
                unitMap={unitMap}
                areaConfigMap={areaConfigMap}
                setLeaseList={setStagedLeaseList}
            />

            <BulkTransitionModal
                open={bulkTransitionModalOpen}
                onClose={() => setBulkTransitionModalOpen(false)}
                leaseList={filteredLeases}
                unitMap={unitMap}
                areaConfigMap={areaConfigMap}
                setLeaseList={setLeaseList}
            />

            <LeaseDetailModal
                lease={leaseDetail}
                onClose={() => setLeaseDetail(null)}
                unitMap={unitMap}
                areaConfigMap={areaConfigMap}
                leaseList={leaseList}
                setLeaseList={setLeaseList}
                setLeaseDetail={setLeaseDetail}
            />

            <LeaseTransferModal
                open={transferLease !== undefined}
                onClose={() => setTransferLeaseIdx(-1)}
                lease={transferLease}
                unitMap={unitMap}
                areaConfigMap={areaConfigMap}
                onTransferLease={updateLease}
            />
        </Container>
    )
}

const Header: React.FC<{
    searchValue: string
    setSearchValue: (newVal: string) => void
    onClickAddLease: () => void
    onClickTenantInspectReady: () => void
    onClickFilter: () => void
    dateFilter: DateFilterState
    setDateFilter: (df: DateFilterState) => void
    locationFinderSelection: FinderSelection
    statusFilter: LeaseStatusFilterState
    setStatusFilter: (sf: LeaseStatusFilterState) => void
    stagedLoading: boolean
    stagedCount: number
    setBulkMode: (bool: boolean) => void
    bulkMode: boolean
    page: number
    numPages: number
    listLength: number
    setPage: (page: number) => void
    hasIssue: IssueFilterValue
    setHasIssue: (hasIssue: IssueFilterValue) => void
    tenantInspectionStatusFilter: TenantInspectionStatusFilterState
    setTenantInspectionStatusFilter: (
        newFilter: TenantInspectionStatusFilterState,
    ) => void
    autoCompleteFilter: AutoCompleteFilterValue
    setAutoCompleteFilter: (val: AutoCompleteFilterValue) => void
    lastSyncDate: Date | null
}> = ({
    onClickAddLease,
    onClickTenantInspectReady,
    onClickFilter,
    searchValue,
    setSearchValue,
    stagedCount,
    stagedLoading,
    bulkMode,
    setBulkMode,
    page,
    setPage,
    numPages,
    listLength,
    dateFilter,
    setDateFilter,
    locationFinderSelection,
    statusFilter,
    setStatusFilter,
    hasIssue,
    setHasIssue,
    tenantInspectionStatusFilter,
    setTenantInspectionStatusFilter,
    autoCompleteFilter,
    setAutoCompleteFilter,
    lastSyncDate,
}) => {
    const theme = useTheme()
    return (
        <Container
            style={{
                flexDirection: 'column',
            }}
        >
            <Container
                style={{
                    flex: 1,
                    alignItems: 'center',
                }}
            >
                <span
                    style={{
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: '40px',
                        lineHeight: '37px',
                    }}
                >
                    Lease Home
                </span>
                <div style={{ flex: 1 }} />

                <span
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: theme.typography.fontWeightBold,
                        marginRight: theme.spacing(2),
                    }}
                >
                    Last Sync:{' '}
                    {lastSyncDate ? toMMDDYYYY(lastSyncDate) : 'Never'}
                </span>

                <Button
                    variant="outlined"
                    style={{
                        backgroundColor: '#fff',
                        color: '#008C85',
                        borderRadius: '196px',
                        border: '1px solid #008C85',
                        textTransform: 'none',
                    }}
                    onClick={() => {
                        axiosInstance.get(`lease/entrata_sync/`).then((res) => {
                            toast.success(res.data)
                        })
                    }}
                >
                    <CachedIcon style={{ marginRight: 4 }} /> Sync
                </Button>
            </Container>

            <LeaseHomeChips
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                locationFinderSelection={locationFinderSelection}
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                hasIssue={hasIssue}
                setHasIssue={setHasIssue}
                tenantInspectionStatusFilter={tenantInspectionStatusFilter}
                setTenantInspectionStatusFilter={
                    setTenantInspectionStatusFilter
                }
                autoCompleteFilter={autoCompleteFilter}
                setAutoCompleteFilter={setAutoCompleteFilter}
            />

            <Container
                style={{
                    alignItems: 'center',
                    flex: 1,
                }}
            >
                <TextField
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    variant="outlined"
                    placeholder="Search"
                    size="small"
                    InputProps={{
                        startAdornment: <SearchIcon htmlColor="#C2C2C2" />,
                    }}
                    style={{
                        borderRadius: '5px',
                        width: '224px',
                    }}
                />

                <Button
                    style={{
                        color: '#fff',
                        backgroundColor: '#008C85',
                        height: '33px',
                        width: '106px',
                        borderRadius: '5px',
                        borderWidth: 0,
                        marginLeft: '9px',
                        fontSize: '14px',
                        lineHeight: '17px',
                        fontWeight: 500,
                        cursor: 'pointer',
                        textTransform: 'none',
                    }}
                    onClick={onClickAddLease}
                >
                    + Add Lease
                </Button>

                <div style={{ flex: 1 }} />
                <Button
                    variant="outlined"
                    style={{
                        color: '#fff',
                        backgroundColor: '#008C85',
                        height: '33px',
                        width: '106px',
                        borderRadius: '5px',
                        borderWidth: 0,
                        marginLeft: '9px',
                        fontSize: '14px',
                        lineHeight: '17px',
                        fontWeight: 500,
                        cursor: 'pointer',
                        textTransform: 'none',
                    }}
                    onClick={() => {
                        setBulkMode(true)
                    }}
                >
                    Transition
                </Button>
                <Button
                    style={{
                        color: '#fff',
                        backgroundColor: '#008C85',
                        height: '33px',
                        width: '106px',
                        borderRadius: '5px',
                        borderWidth: 0,
                        marginLeft: '9px',
                        fontSize: '14px',
                        lineHeight: '17px',
                        fontWeight: 500,
                        cursor: 'pointer',
                        textTransform: 'none',
                    }}
                    onClick={onClickTenantInspectReady}
                >
                    Staged {stagedLoading ? 'Loading' : `(${stagedCount})`}
                </Button>

                <PaginationController
                    page={page}
                    numPages={numPages}
                    listLength={listLength}
                    setPage={setPage}
                />

                <IconButton onClick={onClickFilter}>
                    <TuneIcon htmlColor="#8A8A8A" />
                    <span
                        style={{
                            marginLeft: 8,
                            fontStretch: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '16px',
                            color: '#8A8A8A',
                        }}
                    >
                        Filter
                    </span>
                </IconButton>
            </Container>
        </Container>
    )
}

const ValueLabel: React.FC<{
    value: string
    label: string
}> = ({ value, label }) => {
    return (
        <Container alignItems="center">
            <span
                style={{
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '20px',
                    lineHeight: '23px',
                    color: '#000',
                }}
            >
                {value}
            </span>
            <span
                style={{
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '15px',
                    lineHeight: '18px',
                    color: '#8A8A8A',
                    marginLeft: '7px',
                }}
            >
                {label}
            </span>
        </Container>
    )
}

const tableHeaderStyle: React.CSSProperties = {
    flex: 1,
    color: '#8A8A8A',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '18px',
    marginBottom: '16px',
    marginTop: '16px',
}

interface UnitOccState {
    occupied_unit_list: UnitWithFolder[]
    partial_unit_list: UnitWithFolder[]
    vacant_unit_list: UnitWithFolder[]
}
