import React, { useState } from 'react'
import { Report } from '../../models'
import {
    CellData,
    Container,
    HeadCell,
    RowData,
    SimpleTable,
} from '../../components'
import {
    CircularProgress,
    IconButton,
    TableCell,
    TableRow,
    useTheme,
} from '@material-ui/core'
import { toMMDDYYYY } from '../../helpers'
import { useStyles } from '../../styles'
import { FileCopy } from '@material-ui/icons'
import PDFMerger from 'pdf-merger-js/browser'

interface Props {
    reports: Report[]
}

export const ReportTable = (props: Props) => {
    const { reports } = props

    const [reportGenerating, setReportGenerating] = useState(false)
    const [generatingReportId, setGeneratingReportId] = useState<
        number | null
    >()

    const theme = useTheme()
    const classes = useStyles()

    interface ReportRow extends RowData {
        report_name: CellData<string>
        created_by: CellData<string>
        created_date: CellData<string>
        report_string: CellData<string>
        report: CellData<Report>
    }

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    const headCells: HeadCell<ReportRow>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'report_name',
            label: 'Name',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'created_by',
            label: 'Created By',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'created_date',
            label: 'Created Date',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'report',
            label: 'Report',
            style: headCellStyle,
        },
    ]

    const generateReport = async (report: Report) => {
        console.log(report)
        setReportGenerating(true)
        setGeneratingReportId(report.id)

        const merger = new PDFMerger()

        // using traditional for structure to make awaits function properly
        for (const page of report.report_pages) {
            for (const file of page.report_files) {
                await fetch(file.file)
                    .then((res) => {
                        return res.blob()
                    })
                    .then(async (arrayBuffer) => {
                        await merger.add(arrayBuffer)
                    })
            }
        }

        const mergedPDF = await merger.saveAsBlob()
        const url = URL.createObjectURL(mergedPDF)
        window.open(url)

        setReportGenerating(false)
        setGeneratingReportId(null)
    }

    const createReportRow = (report: Report): ReportRow => {
        return {
            report_name: { value: report.name, sortValue: report.name },
            created_date: {
                value: report.creation_time,
                sortValue: report.creation_time,
            },
            created_by: {
                value: report.generated_by.name,
                sortValue: report.generated_by.name,
            },
            report_string: { value: report.xlsx_report ?? '', sortValue: -1 },
            report: { value: report, sortValue: -1 },
        }
    }

    return (
        <SimpleTable<ReportRow>
            rows={reports.map((report) => createReportRow(report)) ?? []}
            render={(row) => {
                const createdDate = row.created_date.value
                    ? toMMDDYYYY(new Date(row.created_date.value))
                    : ''

                const rowStyle: React.CSSProperties = {
                    cursor: 'pointer',
                }
                return (
                    <TableRow
                        key={`REPORT_ROW_${row.report.value.id}`}
                        className={classes.hoverGrey200}
                        style={rowStyle}
                    >
                        <TableCell>{row.report_name.value}</TableCell>
                        <TableCell>{row.created_by.value}</TableCell>
                        <TableCell>{createdDate}</TableCell>
                        <TableCell>
                            <Container>
                                {reportGenerating &&
                                row.report.value.id === generatingReportId ? (
                                    <CircularProgress
                                        size={30}
                                        style={{ margin: theme.spacing(2) }}
                                    />
                                ) : (
                                    <IconButton
                                        disabled={reportGenerating}
                                        onClick={async () => {
                                            if (row.report.value.xlsx_report) {
                                                window.open(
                                                    row.report.value
                                                        .xlsx_report,
                                                )
                                            } else {
                                                generateReport(row.report.value)
                                            }
                                        }}
                                    >
                                        <FileCopy />
                                    </IconButton>
                                )}
                            </Container>
                        </TableCell>
                    </TableRow>
                )
            }}
            orderByDefault="created_date"
            headCells={headCells}
        />
    )
}
