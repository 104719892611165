import { Button, Slide, useTheme } from '@material-ui/core'
import React from 'react'
import { DateRange, Range } from 'react-date-range'
import { CreateLeaseStep, DATE_STEP } from '.'
import { Container } from '../../../../components'

interface Props {
    dateRange: Range
    rangeKey: string
    activeStep: CreateLeaseStep
    setDateRange: (range: Range) => void
    confirmRange: () => void
}

export const CreateLeaseDateSelect = (props: Props) => {
    const {
        dateRange,
        rangeKey,
        setDateRange,
        activeStep,
        confirmRange,
    } = props

    const theme = useTheme()

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <span
                style={{
                    ...theme.typography.h5,
                    fontWeight: theme.typography.fontWeightBold,
                    margin: theme.spacing(2),
                }}
            >
                Lease Term
            </span>
            <DateRange
                ranges={dateRange ? [dateRange] : undefined}
                fixedHeight
                onChange={(range) => {
                    const key = rangeKey as keyof typeof range
                    const newRange = range[key] as {
                        startDate: Date
                        endDate: Date
                    }
                    setDateRange(newRange)
                }}
            />

            <Button
                title="Next"
                variant="outlined"
                onClick={confirmRange}
                style={{ margin: theme.spacing(2) }}
                color="primary"
            >
                Next
            </Button>
        </Container>
    )
}
