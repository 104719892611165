import { AxiosError } from 'axios'

import { AppThunk, SET_PORTFOLIO_APARTMENT_COUNT } from '../../'

import { axiosInstance } from '../../../helpers'
import {
    AreaStatusTag,
    createWorkorderFromResponse,
    PortfolioBudget,
    PortfolioSchedule,
    Schedule,
    ScheduleBudgetItem,
} from '../../../models'
import { setNetworkError } from '../../error'
import { setPaginationNextPrev } from '../../pagination/action'
import { ErrorResponse, UndefinedThunk } from '../../types'

import { updateBulkWorkorders } from '../../'

import {
    GetScheduleListRequest,
    GetScheduleListActionThunk,
    ScheduleDetailActionThunk,
    GET_SCHEDULE_LIST_REQUEST,
    GET_SCHEDULE_LIST_RESPONSE,
    CreateScheduleRequest,
    CREATE_SCHEDULE_REQUEST,
    CREATE_SCHEDULE_RESPONSE,
    UpdateScheduleRequest,
    UPDATE_SCHEDULE_REQUEST,
    UPDATE_SCHEDULE_RESPONSE,
    GetScheduleDetailRequest,
    GET_SCHEDULE_DETAIL_REQUEST,
    ScheduleRequest,
    ScheduleActionTypes,
    SET_SCHEDULE_LOADING,
    GET_SCHEDULE_SHEET_REQUEST,
    UPLOAD_TURN_SHEET_REQUEST,
    UploadScheduleSheetRequest,
    ResetScheduleRequest,
    RESET_SCHEDULE_REQUEST,
    SET_SCHEDULE_DETAIL,
    GET_AREA_STATUS_TAG_LIST_REQUEST,
    GetAreaStatusTagListRequest,
    AreaStatusTagListThunk,
    SET_AREA_STATUS_TAG_LIST,
    CreateAreaStatusTagRequest,
    EditAreaStatusTagRequest,
    AreaStatusTagThunk,
    CREATE_AREA_STATUS_TAG_REQUEST,
    EDIT_AREA_STATUS_TAG_REQUEST,
    ADD_AREA_STATUS_TAG,
    UPDATE_AREA_STATUS_TAG,
    CreateScheduleBudgetItemRequet,
    ScheduleBudgetItemThunk,
    CREATE_SCHEUDLE_BUDGET_ITEM_REQUEST,
    ADD_SCHEUDLE_BUDGET_ITEM,
    DeleteScheduleBudgetItemRequest,
    DELETE_SCHEUDLE_BUDGET_ITEM_REQUEST,
    REMOVE_SCHEUDLE_BUDGET_ITEM,
    SET_SCHEDULE_BUDGET_ITEM_LIST,
    GetPortfolioScheduleListRequest,
    GET_PORTFOLIO_SCHEDULE_LIST_REQUEST,
    SET_PORTFOLIO_SCHEDULE_LIST,
    ADD_TO_PORTFOLIO_SCHEDULE_LIST,
    SET_PORTFOLIO_INSPECTION_COUNT,
    GetPortfolioBudgetRequest,
    PortfolioBudgetThunk,
    GET_PORTFOLIO_BUDGET,
    SET_PORTFOLIO_BUDGET,
    SET_PORTFOLIO_SCHEDULE_DETAIL,
    SET_PORTFOLIO_SCHEDULE_BUDGET_ITEM_LIST,
    ADD_PORTFOLIO_SCHEDULE_BUDGET_ITEM,
} from './types'

export const getScheduleList = (
    req: GetScheduleListRequest,
): AppThunk<GetScheduleListActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: GET_SCHEDULE_LIST_REQUEST,
        })

        const url = `workorder/schedule/`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch({
                type: GET_SCHEDULE_LIST_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getAreaStatusTagList = (
    req: GetAreaStatusTagListRequest,
): AppThunk<AreaStatusTagListThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setScheduleLoading(GET_AREA_STATUS_TAG_LIST_REQUEST, true))

        const url = `workorder/area-status-tag/`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch(
                setScheduleLoading(GET_AREA_STATUS_TAG_LIST_REQUEST, false),
            )
            dispatch(setAreaStatusTagList(response.data))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(
                setScheduleLoading(GET_AREA_STATUS_TAG_LIST_REQUEST, false),
            )

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createAreaStatusTag = (
    req: CreateAreaStatusTagRequest,
): AppThunk<AreaStatusTagThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setScheduleLoading(CREATE_AREA_STATUS_TAG_REQUEST, true))

        const url = `workorder/area-status-tag/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch(setScheduleLoading(CREATE_AREA_STATUS_TAG_REQUEST, false))
            const ast = response.data['area_status_tag']
            const workorderChanges = response.data['workorders']
            dispatch(addAreaStatusTag(ast))
            dispatch(
                updateBulkWorkorders(
                    workorderChanges.map(createWorkorderFromResponse),
                ),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setScheduleLoading(CREATE_AREA_STATUS_TAG_REQUEST, false))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const patchAreaStatusTag = (
    req: EditAreaStatusTagRequest,
): AppThunk<AreaStatusTagThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setScheduleLoading(EDIT_AREA_STATUS_TAG_REQUEST, true))

        const url = `workorder/area-status-tag/${req.id}/`

        try {
            // success
            const response = await axiosInstance.patch(url, req.body)

            dispatch(setScheduleLoading(EDIT_AREA_STATUS_TAG_REQUEST, false))
            dispatch(updateAreaStatusTag(response.data))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setScheduleLoading(EDIT_AREA_STATUS_TAG_REQUEST, false))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getScheduleDetail = (
    req: GetScheduleDetailRequest,
): AppThunk<ScheduleDetailActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: GET_SCHEDULE_DETAIL_REQUEST,
        })

        const url = `workorder/schedule/${req.scheduleId}/`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch(setScheduleLoading(GET_SCHEDULE_DETAIL_REQUEST, false))
            if (req.scheduleBudgetItemsCallbacks) {
                req.scheduleBudgetItemsCallbacks.forEach((callback) => {
                    dispatch(callback(response.data.schedule_budget_items))
                })
            } else {
                dispatch(
                    setScheduleBudgetItemList(
                        response.data.schedule_budget_items,
                    ),
                )
            }
            if (req.scheduleCallbacks) {
                req.scheduleCallbacks.forEach((callback) =>
                    dispatch(callback(response.data)),
                )
            } else {
                dispatch(setScheduleDetail(response.data))
            }

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setScheduleLoading(GET_SCHEDULE_DETAIL_REQUEST, false))
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createSchedule = (
    req: CreateScheduleRequest,
): AppThunk<ScheduleDetailActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: CREATE_SCHEDULE_REQUEST,
        })

        const url = `workorder/schedule/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch({
                type: CREATE_SCHEDULE_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const updateSchedule = (
    req: UpdateScheduleRequest,
    params?: {
        portfolio?: boolean
    },
): AppThunk<ScheduleDetailActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: UPDATE_SCHEDULE_REQUEST,
        })

        const url = `workorder/schedule/${req.scheduleId}/`

        try {
            // success
            const response = await axiosInstance.patch(url, req.body, {
                params: {
                    portfolio: params?.portfolio,
                },
            })

            dispatch({
                type: UPDATE_SCHEDULE_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getScheduleSheet = (
    req: GetScheduleDetailRequest,
): AppThunk<any> => {
    return async (dispatch) => {
        // begin request
        dispatch(setScheduleLoading(GET_SCHEDULE_SHEET_REQUEST, true))

        const url = `workorder/schedule/${req.scheduleId}/sheet/`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch(setScheduleLoading(GET_SCHEDULE_SHEET_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setScheduleLoading(GET_SCHEDULE_SHEET_REQUEST, false))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const uploadScheduleSheet = (
    req: UploadScheduleSheetRequest,
): AppThunk<any> => {
    return async (dispatch) => {
        // begin request
        dispatch(setScheduleLoading(UPLOAD_TURN_SHEET_REQUEST, true))

        const url = `workorder/schedule/${req.scheduleId}/sheet/`

        try {
            // success

            const reqBody = new FormData()
            reqBody.append('schedule-sheet', req.sheet)
            const response = await axiosInstance.post(url, reqBody)

            dispatch(setScheduleLoading(UPLOAD_TURN_SHEET_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            dispatch(setScheduleLoading(UPLOAD_TURN_SHEET_REQUEST, false))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const resetSchedule = (req: ResetScheduleRequest): AppThunk<any> => {
    return async (dispatch) => {
        // begin request
        dispatch(setScheduleLoading(RESET_SCHEDULE_REQUEST, true))

        const url = `workorder/schedule/${req.scheduleId}/reset_schedule/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)
            dispatch(setScheduleLoading(RESET_SCHEDULE_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setScheduleLoading(RESET_SCHEDULE_REQUEST, false))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createScheduleBudgetItem = (
    req: CreateScheduleBudgetItemRequet,
): AppThunk<ScheduleBudgetItemThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setScheduleLoading(CREATE_SCHEUDLE_BUDGET_ITEM_REQUEST, true))

        const url = `workorder/schedule-budget-item/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch(
                setScheduleLoading(CREATE_SCHEUDLE_BUDGET_ITEM_REQUEST, false),
            )
            if (req.dispatchCallbacks) {
                req.dispatchCallbacks.forEach((callback) => {
                    dispatch(callback(response.data))
                })
            } else {
                dispatch(addScheduleBudgetItem(response.data))
            }

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(
                setScheduleLoading(CREATE_SCHEUDLE_BUDGET_ITEM_REQUEST, false),
            )
            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const deleteScheduleBudgetItem = (
    req: DeleteScheduleBudgetItemRequest,
): AppThunk<UndefinedThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setScheduleLoading(DELETE_SCHEUDLE_BUDGET_ITEM_REQUEST, true))

        const url = `workorder/schedule-budget-item/${req.scheduleBudgetItem}/`

        try {
            // success
            const response = await axiosInstance.delete(url, {
                params: req.params,
            })

            dispatch(
                setScheduleLoading(DELETE_SCHEUDLE_BUDGET_ITEM_REQUEST, false),
            )
            dispatch(removeScheduleBudgetItem(req.scheduleBudgetItem))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(
                setScheduleLoading(DELETE_SCHEUDLE_BUDGET_ITEM_REQUEST, false),
            )
            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getPortfolioScheduleList = (
    req: GetPortfolioScheduleListRequest,
): AppThunk<GetScheduleListActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setScheduleLoading(GET_PORTFOLIO_SCHEDULE_LIST_REQUEST, true))

        const url = req.url ?? `workorder/portfolio-schedule/`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch(
                setScheduleLoading(GET_PORTFOLIO_SCHEDULE_LIST_REQUEST, false),
            )
            if (req.url) {
                // it is not the initial request because the url is provided
                dispatch(addToPortfolioScheduleList(response.data.results))
            } else {
                dispatch(
                    setPortfolioInspectionCount(response.data.inspection_count),
                )
                dispatch(
                    setPortfolioApartmentCount(response.data.property_count),
                )
                dispatch(setPortfolioScheduleList(response.data.results))
            }

            // update pagination state
            dispatch(
                setPaginationNextPrev(
                    response.data.next,
                    response.data.prev,
                    response.data.count,
                    GET_PORTFOLIO_SCHEDULE_LIST_REQUEST,
                ),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(
                setScheduleLoading(GET_PORTFOLIO_SCHEDULE_LIST_REQUEST, false),
            )
            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getPortfolioBudget = (
    req: GetPortfolioBudgetRequest,
): AppThunk<PortfolioBudgetThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setScheduleLoading(GET_PORTFOLIO_BUDGET, true))

        const url = `workorder/portfolio-schedule/get_total_budget/`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch(setScheduleLoading(GET_PORTFOLIO_BUDGET, false))
            dispatch(setPortfolioBudget(response.data))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setScheduleLoading(GET_PORTFOLIO_BUDGET, false))
            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const setPortfolioScheduleList = (
    portfolioScheduleList?: PortfolioSchedule[],
): ScheduleActionTypes => {
    return {
        type: SET_PORTFOLIO_SCHEDULE_LIST,
        portfolioScheduleList: portfolioScheduleList,
    }
}

export const setPortfolioScheduleDetail = (
    schedule?: Schedule,
): ScheduleActionTypes => {
    return {
        type: SET_PORTFOLIO_SCHEDULE_DETAIL,
        schedule: schedule,
    }
}

export const setPortfolioScheduleBudgetItemList = (
    scheduleBudgetItems?: ScheduleBudgetItem[],
): ScheduleActionTypes => {
    return {
        type: SET_PORTFOLIO_SCHEDULE_BUDGET_ITEM_LIST,
        scheduleBudgetItemList: scheduleBudgetItems,
    }
}

export const setPortfolioApartmentCount = (
    count?: number,
): ScheduleActionTypes => {
    return {
        type: SET_PORTFOLIO_APARTMENT_COUNT,
        count: count,
    }
}

export const setPortfolioInspectionCount = (
    count?: number,
): ScheduleActionTypes => {
    return {
        type: SET_PORTFOLIO_INSPECTION_COUNT,
        count: count,
    }
}

export const addToPortfolioScheduleList = (
    portfolioScheduleList: PortfolioSchedule[],
): ScheduleActionTypes => {
    return {
        type: ADD_TO_PORTFOLIO_SCHEDULE_LIST,
        portfolioScheduleList: portfolioScheduleList,
    }
}

export const setScheduleDetail = (schedule?: Schedule): ScheduleActionTypes => {
    return {
        type: SET_SCHEDULE_DETAIL,
        schedule: schedule,
    }
}

export const addScheduleBudgetItem = (
    scheduleBudgetItem: ScheduleBudgetItem,
): ScheduleActionTypes => {
    return {
        type: ADD_SCHEUDLE_BUDGET_ITEM,
        scheduleBudgetItem: scheduleBudgetItem,
    }
}

export const setScheduleBudgetItemList = (
    scheduleBudgetItems?: ScheduleBudgetItem[],
): ScheduleActionTypes => {
    return {
        type: SET_SCHEDULE_BUDGET_ITEM_LIST,
        scheduleBudgetItemList: scheduleBudgetItems,
    }
}

export const removeScheduleBudgetItem = (id: number): ScheduleActionTypes => {
    return {
        type: REMOVE_SCHEUDLE_BUDGET_ITEM,
        id: id,
    }
}

export const addPortfolioScheduleBudgetItem = (
    budgetItem: ScheduleBudgetItem,
): ScheduleActionTypes => {
    return {
        type: ADD_PORTFOLIO_SCHEDULE_BUDGET_ITEM,
        scheduleBudgetItem: budgetItem,
    }
}

export const setAreaStatusTagList = (
    areaStatusTagList?: AreaStatusTag[],
): ScheduleActionTypes => {
    return {
        type: SET_AREA_STATUS_TAG_LIST,
        areaStatusTagList: areaStatusTagList,
    }
}

export const addAreaStatusTag = (
    areaStatusTag: AreaStatusTag,
): ScheduleActionTypes => {
    return {
        type: ADD_AREA_STATUS_TAG,
        areaStatusTag: areaStatusTag,
    }
}

export const updateAreaStatusTag = (
    areaStatusTag: AreaStatusTag,
): ScheduleActionTypes => {
    return {
        type: UPDATE_AREA_STATUS_TAG,
        areaStatusTag: areaStatusTag,
    }
}

export const setScheduleLoading = (
    request: ScheduleRequest,
    isLoading: boolean,
): ScheduleActionTypes => {
    return {
        type: SET_SCHEDULE_LOADING,
        request: request,
        isLoading: isLoading,
    }
}

export const updateScheduleState = (
    schedule: Schedule,
): ScheduleActionTypes => {
    return {
        type: UPDATE_SCHEDULE_RESPONSE,
        payload: schedule,
    }
}

export const setPortfolioBudget = (
    budget?: PortfolioBudget,
): ScheduleActionTypes => {
    return {
        type: SET_PORTFOLIO_BUDGET,
        budget: budget,
    }
}
