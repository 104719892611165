import { AxiosError } from 'axios'
import { setNetworkError } from '..'
import { axiosInstance } from '../../helpers'
import { Service } from '../../models'
import { AppThunk, ErrorResponse } from '../types'
import {
    GetServiceListActionThunk,
    GetServiceListRequest,
    GET_SERVICE_LIST_REQUEST,
    GET_SERVICE_LIST_RESPONSE,
    ServiceActionTypes,
    SET_SERVICE_LIST,
} from './types'

export const getServiceList = (
    req: GetServiceListRequest,
): AppThunk<GetServiceListActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: GET_SERVICE_LIST_REQUEST,
        })

        const url = 'service/'

        try {
            // success
            const response = await axiosInstance.get(url, req)

            dispatch({
                type: GET_SERVICE_LIST_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const setServiceList = (serviceList: Service[]): ServiceActionTypes => {
    return {
        type: SET_SERVICE_LIST,
        payload: serviceList,
    }
}
