import React from 'react'
import { Checkbox, Paper, useTheme } from '@material-ui/core'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import { Container } from '../../../../components'

import {
    Area,
    AreaConfig,
    IdBoolMap,
    Lease,
    ModelListMap,
    ModelMap,
    Schedule,
    Timeline,
    Unit,
    UnitConfig,
    isAreaAvailableDuringTimeline,
    AreaStatusTag,
    AreaStatusConfig,
} from '../../../../models'
import { UnitArea } from '../../Body'

interface Props {
    unit: Unit
    unitConfig?: UnitConfig
    areaLeaseMap: ModelListMap<Lease>
    areaConfigMap: ModelMap<AreaConfig>
    schedule?: Schedule
    assignTimeline: Timeline
    style?: React.CSSProperties
    areaStatusTagMap: ModelMap<AreaStatusTag>
    areaStatusConfigList: AreaStatusConfig[]
    useLeasesToCalculateServiceArea: boolean
    areaSelection: IdBoolMap
    setAreaSelection: (map: IdBoolMap) => void
    isAreaInFilter: (area: Area) => boolean
}

const DETAIL_WIDTH = 96

export const AssignUnit = (props: Props) => {
    const {
        unit,
        unitConfig,
        schedule,
        areaLeaseMap,
        areaConfigMap,
        assignTimeline,
        isAreaInFilter,
        style,
        areaStatusTagMap,
        areaSelection,
        setAreaSelection,
    } = props

    const theme = useTheme()

    return (
        <Container style={style}>
            <Paper
                style={{
                    boxShadow: theme.shadows[2],
                    flex: 1,
                    margin: theme.spacing(1),
                }}
            >
                <Container
                    style={{
                        flex: 1,
                        height: '100%',
                        ...theme.typography.body2,
                    }}
                >
                    <Container
                        style={{
                            width: DETAIL_WIDTH,
                            padding: theme.spacing(1),
                            cursor: 'pointer',
                            borderRadius: `${theme.shape.borderRadius} 0 0 ${theme.shape.borderRadius}`,
                            backgroundColor: theme.palette.grey[200],
                            boxShadow: `3px 0px 3px -1px ${theme.palette.grey[500]}`,
                        }}
                        direction="column"
                    >
                        {/* Top half */}
                        <Container>
                            <Container
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    overflowX: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                <span
                                    style={{
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        overflowX: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {unit.name}
                                </span>
                                <span
                                    style={{
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                        overflowX: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {unitConfig ? unitConfig.name : '?'}
                                </span>
                            </Container>
                        </Container>
                    </Container>

                    <Container
                        style={{
                            flex: 1,
                            flexWrap: 'wrap',
                            flexDirection: 'column',
                        }}
                        scrollY
                    >
                        {/* Workorder Container */}
                        <Container>
                            {unit.areas.map((area) => {
                                if (
                                    !areaConfigMap[area.area_config]
                                        ?.shows_on_schedule
                                ) {
                                    return <></>
                                }

                                const areaLeases = areaLeaseMap[area.id]

                                // Check if this area has tenants during the selected timeline
                                const areaAvailable = isAreaAvailableDuringTimeline(
                                    assignTimeline,
                                    areaLeases,
                                )

                                return (
                                    <UnitArea
                                        areaStatusConfigList={
                                            props.areaStatusConfigList
                                        }
                                        areaStatusTag={
                                            areaStatusTagMap[area.id]
                                        }
                                        key={`ASSIGN_SA_${area.id}`}
                                        area={area}
                                        areaConfig={
                                            areaConfigMap[area.area_config]
                                        }
                                        schedule={schedule}
                                        isAreaInFilter={isAreaInFilter}
                                        rightRender={() => {
                                            return (
                                                <Container
                                                    style={{
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Checkbox
                                                        checked={
                                                            areaSelection[
                                                                area.id
                                                            ] === true
                                                        }
                                                        onChange={() => {
                                                            setAreaSelection({
                                                                ...areaSelection,
                                                                [area.id]: !areaSelection[
                                                                    area.id
                                                                ],
                                                            })
                                                        }}
                                                    />
                                                    {areaAvailable ? (
                                                        <EventAvailableIcon color="primary" />
                                                    ) : (
                                                        <EventBusyIcon />
                                                    )}
                                                </Container>
                                            )
                                        }}
                                    />
                                )
                            })}
                        </Container>
                    </Container>
                </Container>
            </Paper>
        </Container>
    )
}

// export const AssignUnit = React.memo(_AssignUnit)
