import React, { useEffect } from 'react'

interface Props {
    url: string
}

export const Calendly = (props: Props) => {
    const { url } = props

    useEffect(() => {
        const head = document.querySelector('head')
        const script = document.createElement('script')
        script.setAttribute(
            'src',
            'https://assets.calendly.com/assets/external/widget.js',
        )
        head?.appendChild(script)
    }, [])

    return (
        <div
            className="calendly-inline-widget"
            data-url={url}
            style={{ width: '100%', height: '100%' }}
        />
    )
}
