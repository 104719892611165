import { useState } from 'react'
import { axiosInstance } from '../../helpers'

import { toast } from 'react-toastify'
import { insertOrUpdateIdentifiable } from '../services'
import { ApartmentDeadline, DeadlineDetail } from '../../models'

export const useDeadline = () => {
    const [apartmentDeadlineList, setApartmentDeadlineList] = useState<
        ApartmentDeadline[] | null
    >(null)
    const [loadingState, setLoadingState] = useState<LoadingState>({
        getList: false,
        getDetail: false,
        create: false,
        transition: false,
    })

    const getApartmentDeadlines = (errorHandler?: (e: any) => void) => {
        setLoadingState({ ...loadingState, getList: true })
        axiosInstance
            .get('deadline/')
            .then((res) => {
                setApartmentDeadlineList(res.data)
            })
            .catch((e) => {
                if (errorHandler) {
                    errorHandler(e)
                } else {
                    toast.error(`unable to load deadlines`)
                }
            })
            .finally(() => {
                setLoadingState({ ...loadingState, getList: false })
            })
    }

    const createDeadline = (request: CreateDeadlineRequest) => {
        setLoadingState({ ...loadingState, create: true })

        return new Promise<ApartmentDeadline>((resolve, reject) => {
            axiosInstance
                .post('deadline/', request)
                .then((res: any) => {
                    insertOrUpdateIdentifiable(
                        res.data,
                        setApartmentDeadlineList,
                    )

                    resolve(res.data)
                })
                .catch(reject)
                .finally(() => {
                    setLoadingState({ ...loadingState, getList: false })
                })
        })
    }

    const getDeadlineDetail = (deadlineId: number) => {
        setLoadingState({ ...loadingState, getDetail: true })

        return new Promise<DeadlineDetail>((resolve, reject) => {
            axiosInstance
                .get(`deadline/${deadlineId}/`)
                .then((res) => {
                    return resolve(res.data)
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({ ...loadingState, getDetail: false })
                })
        })
    }

    const transitionDeadline = (deadlineId: number, complete: boolean) => {
        setLoadingState({ ...loadingState, transition: true })

        return new Promise<DeadlineDetail>((resolve, reject) => {
            axiosInstance
                .post(`deadline/${deadlineId}/set_complete_status/`, {
                    complete_status: complete,
                })
                .then((res) => {
                    const deadlineDetail: DeadlineDetail = res.data
                    const aptDeadline = deadlineDetail.apartment_deadlines.find(
                        (aptDl) => aptDl.id === deadlineId,
                    )
                    if (aptDeadline) {
                        insertOrUpdateIdentifiable(
                            aptDeadline,
                            setApartmentDeadlineList,
                        )
                    }

                    return resolve(res.data)
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({ ...loadingState, transition: false })
                })
        })
    }

    const incrementMessageCount = (deadline: ApartmentDeadline) => {
        insertOrUpdateIdentifiable(
            { ...deadline, message_count: deadline.message_count + 1 },
            setApartmentDeadlineList,
        )
    }

    return {
        apartmentDeadlineList,
        loadingState,
        getApartmentDeadlines,
        createDeadline,
        getDeadlineDetail,
        transitionDeadline,
        incrementMessageCount,
    }
}

export interface CreateDeadlineRequest {
    name: string
    description: string
    date: string
    apartments: number[]
}

interface LoadingState {
    getList: boolean
    getDetail: boolean
    create: boolean
    transition: boolean
}
