import React, { useEffect, useState } from 'react'
import {
    Grid,
    Modal,
    Paper,
    Slide,
    TableSortLabel,
    Theme,
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import LoopIcon from '@material-ui/icons/Loop'
import { Container } from '../Container'
import { axiosInstance } from '../../helpers'
import { ListVendor } from '../../models'
import { toast } from 'react-toastify'

interface Props {
    theme: Theme
    open: boolean
    onClose: () => void
}

export const SkillcatUserList = (props: Props) => {
    const { theme, open, onClose } = props

    const headCellStyle: React.CSSProperties = {
        fontWeight: 600,
        color: theme.palette.darkGreen.main,
        ...theme.typography.h6,
        flex: 2,
        justifyContent: 'center',
    }

    const cellStyle: React.CSSProperties = {
        fontWeight: 400,
        ...theme.typography.body1,
        alignItems: 'center',
    }

    const NameASC = 'NameASC'
    const NameDESC = 'NameDESC'
    const EmailASC = 'EmailASC'
    const EmailDESC = 'EmailDESC'
    const PhoneASC = 'PhoneASC'
    const PhoneDESC = 'PhoneDESC'
    const AreaASC = 'AreaASC'
    const AreaDESC = 'AreaDESC'

    const [skillcatVendors, setSkillcatVendors] = useState<ListVendor[]>([])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [orderBy, setOrderBy] = useState('')
    const [sortedRows, setSortedRows] = useState<TeamRowsType[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const fetchVendors = async () => {
        setIsLoading(true)

        try {
            const res = await axiosInstance.post(
                'company/vendor/retrieve_skillcat/',
            )
            setSkillcatVendors(res.data.vendors)
            setIsLoading(false)
        } catch (e) {
            toast.error('Error fetching Skillcat vendors')
            setIsLoading(false)
        }
    }

    const createVendorRow = (vendor: ListVendor): TeamRowsType => {
        const email = vendor.email ?? ''
        const phone = vendor.phone ?? ''

        return {
            name: vendor.name || 'None Listed',
            email: email || 'None Listed',
            phone: phone || 'None Listed',
            area: (vendor.city || 'None') + ', ' + (vendor.state || 'None'),
        }
    }

    useEffect(() => {
        fetchVendors()
    }, [])

    useEffect(() => {
        const rows = skillcatVendors.map((vendor: ListVendor) =>
            createVendorRow(vendor),
        )
        setSortedRows(rows)
    }, [skillcatVendors])

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper>
                    <Container
                        style={{
                            height: 600,
                            maxWidth: 1000,
                            display: 'flex',
                            overflow: 'hidden',
                            flexDirection: 'column',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        <Container
                            direction="column"
                            style={{
                                justifyContent: 'center',
                                display: 'flex',
                                margin: theme.spacing(2),
                            }}
                        >
                            <Container
                                style={{
                                    justifyContent: 'center',
                                    ...theme.typography.h5,
                                }}
                            >
                                <span
                                    style={{
                                        color: theme.palette.primary.dark,
                                        marginBottom: theme.spacing(2),
                                    }}
                                >
                                    Total Skillcat Vendors:{' '}
                                    {skillcatVendors.length}
                                </span>
                            </Container>
                            {isLoading ? (
                                <Container
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: 'auto',
                                    }}
                                >
                                    <LoopIcon
                                        style={{
                                            animation:
                                                'spin 1s linear infinite',
                                        }}
                                    />
                                </Container>
                            ) : (
                                <Container scrollY style={{ flex: 1 }}>
                                    <Grid
                                        container={true}
                                        style={{
                                            padding: theme.spacing(2, 0, 1, 0),
                                        }}
                                    >
                                        <Grid
                                            container={true}
                                            justify="space-evenly"
                                        >
                                            <Container
                                                style={{ ...headCellStyle }}
                                                onClick={() => {
                                                    const newRows = [
                                                        ...sortedRows,
                                                    ]
                                                    if (orderBy !== NameASC) {
                                                        newRows.sort(
                                                            (RowA, RowB) =>
                                                                RowA.name.localeCompare(
                                                                    RowB.name,
                                                                ),
                                                        )
                                                        setSortedRows(newRows)
                                                        setOrderBy(NameASC)
                                                    } else {
                                                        setSortedRows(
                                                            newRows.reverse(),
                                                        )
                                                        setOrderBy(NameDESC)
                                                    }
                                                }}
                                            >
                                                <TableSortLabel
                                                    active={
                                                        orderBy === NameASC ||
                                                        orderBy === NameDESC
                                                    }
                                                    direction={
                                                        orderBy === NameASC
                                                            ? 'asc'
                                                            : 'desc'
                                                    }
                                                >
                                                    Name
                                                </TableSortLabel>
                                            </Container>

                                            <Container
                                                style={{ ...headCellStyle }}
                                                onClick={() => {
                                                    const newRows = [
                                                        ...sortedRows,
                                                    ]
                                                    if (orderBy !== EmailASC) {
                                                        newRows.sort(
                                                            (RowA, RowB) =>
                                                                RowA.email
                                                                    .toString()
                                                                    .localeCompare(
                                                                        RowB.email.toString(),
                                                                    ),
                                                        )
                                                        setSortedRows(newRows)
                                                        setOrderBy(EmailASC)
                                                    } else {
                                                        setSortedRows(
                                                            newRows.reverse(),
                                                        )
                                                        setOrderBy(EmailDESC)
                                                    }
                                                }}
                                            >
                                                <TableSortLabel
                                                    active={
                                                        orderBy === EmailASC ||
                                                        orderBy === EmailDESC
                                                    }
                                                    direction={
                                                        orderBy === EmailASC
                                                            ? 'asc'
                                                            : 'desc'
                                                    }
                                                >
                                                    Email
                                                </TableSortLabel>
                                            </Container>
                                            <Container
                                                style={{ ...headCellStyle }}
                                                onClick={() => {
                                                    const newRows = [
                                                        ...sortedRows,
                                                    ]
                                                    if (orderBy !== PhoneASC) {
                                                        newRows.sort(
                                                            (RowA, RowB) =>
                                                                RowA.phone.localeCompare(
                                                                    RowB.phone,
                                                                ),
                                                        )
                                                        setSortedRows(newRows)
                                                        setOrderBy(PhoneASC)
                                                    } else {
                                                        setSortedRows(
                                                            newRows.reverse(),
                                                        )
                                                        setOrderBy(PhoneDESC)
                                                    }
                                                }}
                                            >
                                                <TableSortLabel
                                                    active={
                                                        orderBy === PhoneASC ||
                                                        orderBy === PhoneDESC
                                                    }
                                                    direction={
                                                        orderBy === PhoneASC
                                                            ? 'asc'
                                                            : 'desc'
                                                    }
                                                >
                                                    Phone
                                                </TableSortLabel>
                                            </Container>
                                            <Container
                                                style={{ ...headCellStyle }}
                                                onClick={() => {
                                                    const newRows = [
                                                        ...sortedRows,
                                                    ]
                                                    if (orderBy !== AreaASC) {
                                                        newRows.sort(
                                                            (RowA, RowB) =>
                                                                RowA.area.localeCompare(
                                                                    RowB.area,
                                                                ),
                                                        )
                                                        setSortedRows(newRows)
                                                        setOrderBy(AreaASC)
                                                    } else {
                                                        setSortedRows(
                                                            newRows.reverse(),
                                                        )
                                                        setOrderBy(AreaDESC)
                                                    }
                                                }}
                                            >
                                                <TableSortLabel
                                                    active={
                                                        orderBy === AreaASC ||
                                                        orderBy === AreaDESC
                                                    }
                                                    direction={
                                                        orderBy === AreaASC
                                                            ? 'asc'
                                                            : 'desc'
                                                    }
                                                >
                                                    Service Area
                                                </TableSortLabel>
                                            </Container>
                                        </Grid>

                                        <Grid
                                            container={true}
                                            style={{
                                                overflowY: 'auto',
                                                maxHeight:
                                                    'calc(100vh - 400px)',
                                            }}
                                        >
                                            {sortedRows
                                                .map((row, index) => {
                                                    return (
                                                        <TeamRow
                                                            theme={theme}
                                                            rows={row}
                                                            cellStyle={
                                                                cellStyle
                                                            }
                                                            key={index}
                                                        />
                                                    )
                                                })
                                                .slice(
                                                    (page - 1) * rowsPerPage,
                                                    (page - 1) * rowsPerPage +
                                                        rowsPerPage,
                                                )}
                                        </Grid>
                                        <Container
                                            style={{
                                                flex: 1,
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <Pagination
                                                count={Math.ceil(
                                                    sortedRows.length /
                                                        rowsPerPage,
                                                )}
                                                page={page}
                                                onChange={(
                                                    _: unknown,
                                                    newPage: number,
                                                ) => {
                                                    setPage(newPage)
                                                }}
                                                variant="outlined"
                                                shape="rounded"
                                                style={{
                                                    margin: theme.spacing(
                                                        1,
                                                        0,
                                                        0,
                                                        0,
                                                    ),
                                                }}
                                            />
                                        </Container>
                                    </Grid>
                                </Container>
                            )}
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

export interface TeamRowsType {
    name: string
    email: string
    phone: string
    area: string
}

interface TeamRowProps {
    theme: Theme
    cellStyle: React.CSSProperties
    rows: TeamRowsType
}

const TeamRow = (props: TeamRowProps) => {
    const { theme, cellStyle, rows } = props

    return (
        <Grid
            container={true}
            key={rows.name}
            style={{
                cursor: 'pointer',
                margin: theme.spacing(1, 0, 1, 0),
                border: '1px solid',
                borderColor: theme.palette.grey[400],
                borderRadius: '20px 20px 20px 20px',
                flexDirection: 'row',
                backgroundColor: '#E5E5E5',
            }}
        >
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                }}
                direction="column"
            >
                <Container>{rows.name}</Container>
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                }}
                direction="column"
            >
                <Container>{rows.email}</Container>
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                }}
                direction="column"
            >
                <Container>{rows.phone}</Container>
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    flex: 2,
                }}
                direction="column"
            >
                <Container>{rows.area}</Container>
            </Container>
        </Grid>
    )
}
