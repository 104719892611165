import {
    Button,
    CircularProgress,
    IconButton,
    useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { Container } from '../../../../../components'
import { DropFiles } from '../../../../../components/DropFiles'
import { DeleteForever } from '@material-ui/icons'
import { LeaseAudit } from '../../../../../models'
import { ReactComponent as PDFLogo } from '../../../../../assets/pdf_icon.svg'

interface Props {
    displayPDFUpload: boolean
    pdfsUploading: boolean
    numberUploaded: number
    leaseAudit: LeaseAudit
    pdfFiles: File[]
    uploadLeasePDF: (files: File[], leaseAuditID: number) => Promise<void>
    handleAfterUpload?: () => void
    setPDFFiles: React.Dispatch<React.SetStateAction<File[]>>
}

export const LeaseAuditPDFUpload = (props: Props) => {
    const {
        displayPDFUpload,
        pdfsUploading,
        numberUploaded,
        leaseAudit,
        pdfFiles,
        uploadLeasePDF,
        handleAfterUpload,
        setPDFFiles,
    } = props

    const theme = useTheme()

    return (
        <Container
            style={{
                flexDirection: 'column',
                overflowY: 'scroll',
                padding: theme.spacing(2),
                flex: 1,
            }}
        >
            {displayPDFUpload ? (
                <>
                    <DropFiles
                        multipleFiles={true}
                        fileTypes={{ 'application/pdf': [] }}
                        displayString="Drag and Drop Your Lease PDF files or folders Here"
                        onDrop={(files) => {
                            setPDFFiles(pdfFiles.concat(files))
                        }}
                    />
                    <Container
                        style={{
                            marginTop: theme.spacing(2),
                            overflowY: 'scroll',
                            flexWrap: 'wrap',
                        }}
                    >
                        {pdfFiles
                            .sort((fileA, fileB) => {
                                if (fileA.name < fileB.name) {
                                    return -1
                                } else if (fileA.name > fileB.name) {
                                    return 1
                                }
                                return 0
                            })
                            .map((file, idx) => {
                                return (
                                    <Container
                                        key={`PDF_FILE_${idx}`}
                                        style={{
                                            margin: theme.spacing(1, 1, 1, 0),
                                            border: `1px solid ${theme.palette.grey[400]}`,
                                            borderRadius:
                                                theme.shape.borderRadius,
                                            padding: theme.spacing(1),
                                            alignItems: 'center',
                                        }}
                                    >
                                        <PDFLogo
                                            style={{
                                                height: '30px',
                                                width: '30px',
                                                marginRight: theme.spacing(1),
                                            }}
                                        />
                                        {file.name}
                                        <IconButton
                                            onClick={() => {
                                                const tempFiles: File[] = []
                                                pdfFiles.forEach((f, index) => {
                                                    if (index !== idx) {
                                                        tempFiles.push(f)
                                                    }
                                                })
                                                setPDFFiles(tempFiles)
                                            }}
                                        >
                                            <DeleteForever color="secondary" />
                                        </IconButton>
                                    </Container>
                                )
                            })}
                    </Container>
                    {pdfFiles.length !== 0 && (
                        <Container style={{ alignItems: 'flex-end' }}>
                            {pdfsUploading ? (
                                <Container style={{ alignItems: 'center' }}>
                                    <CircularProgress />
                                    <Container
                                        style={{
                                            ...theme.typography.subtitle2,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                            marginLeft: theme.spacing(1),
                                        }}
                                    >
                                        {numberUploaded}/{pdfFiles.length}
                                    </Container>
                                </Container>
                            ) : (
                                <Button
                                    style={{
                                        color: '#fff',
                                        backgroundColor:
                                            theme.palette.primary.dark,
                                        height: '33px',
                                        maxWidth: '150px',
                                        minWidth: '150px',
                                        borderRadius: '5px',
                                        borderWidth: 0,
                                        marginLeft: '9px',
                                        fontSize: '14px',
                                        lineHeight: '17px',
                                        fontWeight: 500,
                                        cursor: 'pointer',
                                        textTransform: 'none',
                                        marginTop: theme.spacing(1),
                                    }}
                                    onClick={() => {
                                        uploadLeasePDF(
                                            pdfFiles,
                                            leaseAudit.id,
                                        ).then(() => setPDFFiles([]))
                                        if (handleAfterUpload) {
                                            handleAfterUpload()
                                        }
                                    }}
                                    disabled={
                                        pdfsUploading || pdfFiles.length === 0
                                    }
                                >
                                    Upload
                                </Button>
                            )}
                        </Container>
                    )}
                </>
            ) : (
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        color: theme.palette.grey[600],
                        fontWeight: theme.typography.fontWeightBold,
                        marginTop: theme.spacing(2),
                    }}
                >
                    The 30 days to upload your lease PDFs has expired
                </span>
            )}
        </Container>
    )
}
