import React from 'react'
import NumberFormat from 'react-number-format'

import { useTheme, Paper, IconButton, Tooltip, Badge } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import FlagIcon from '@material-ui/icons/Flag'
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn'

import { AreaInspectionComponent } from './AreaInspectionComponent'
import { OnScreenHoc, Container } from '../../components'
import {
    AreaStatusTag,
    ModelMap,
    Unit,
    UnitConfig,
    UnitInspection,
} from '../../models'

import { toMMDDYYYY } from '../../helpers'
import { UnitInspectionStatusBadge } from './UnitInspectionStatusBadge'
import { Build } from '@material-ui/icons'

import { ReactComponent as UnitNotesIcon } from '../../assets/icons/unitnotes.svg'

interface Props {
    unit: Unit
    damageCount: number
    unitConfig?: UnitConfig
    unitInspection?: UnitInspection
    selected?: boolean
    areaStatusMap: ModelMap<AreaStatusTag>
    clickDamage: (unit: Unit) => void
    onClickDetail?: () => void
    onClickAssign: () => void
    onClickUnitNotes: () => void
}

const _InspectionUnit: React.FC<Props> = (props) => {
    const {
        unit,
        unitConfig,
        unitInspection,
        damageCount,
        clickDamage,
        onClickDetail,
        onClickAssign,
        onClickUnitNotes,
    } = props

    const theme = useTheme()

    const HEIGHT = 210

    let totalCost = 0
    let totalFlags = 0
    let totalHandled = 0

    unitInspection?.area_inspections.forEach((areaIns) => {
        totalCost += areaIns.cost
        totalFlags += areaIns.flagged_count
        totalHandled += areaIns.handled_count
    })

    let assignmentInfo: JSX.Element
    if (unitInspection) {
        assignmentInfo = (
            <>
                <UnitInspectionStatusBadge unitInspection={unitInspection} />
                {unitInspection && (
                    <Container direction="column">
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                                overflowX: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {unitInspection.assigned_to?.name ?? 'My Team'}
                        </span>

                        <span>
                            S: {toMMDDYYYY(new Date(unitInspection.start_date))}
                        </span>

                        <span>
                            E: {toMMDDYYYY(new Date(unitInspection.end_date))}
                        </span>
                    </Container>
                )}
            </>
        )
    } else {
        assignmentInfo = (
            <Container
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                }}
            >
                <IconButton>
                    <Tooltip title="Assign">
                        <AddIcon fontSize="large" />
                    </Tooltip>
                </IconButton>
            </Container>
        )
    }

    return (
        <OnScreenHoc style={{ margin: theme.spacing(1, 0) }} height={HEIGHT}>
            <Paper
                style={{
                    boxShadow: theme.shadows[2],
                    flex: 1,
                    backgroundColor: props.selected
                        ? theme.palette.primary.light
                        : 'inherit',
                }}
                onClick={unitInspection ? onClickDetail : onClickAssign}
            >
                <Container
                    style={{
                        flex: 1,
                        height: '100%',
                        ...theme.typography.body2,
                    }}
                >
                    <Container
                        style={{
                            width: 160,
                            padding: theme.spacing(1),
                            cursor: 'pointer',
                            borderRadius: `${theme.shape.borderRadius} 0 0 ${theme.shape.borderRadius}`,
                            backgroundColor: theme.palette.grey[200],
                            boxShadow: `3px 0px 3px -1px ${theme.palette.grey[500]}`,
                        }}
                        direction="column"
                    >
                        {/* Top half */}
                        <Container>
                            <Container
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    overflowX: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                <span
                                    style={{
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        overflowX: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {unit.name}
                                </span>
                                <span
                                    style={{
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                        overflowX: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {unitConfig?.name ?? '?'}
                                </span>

                                <Container
                                    style={{
                                        flex: 1,
                                    }}
                                >
                                    <NumberFormat
                                        value={totalCost}
                                        prefix="$"
                                        thousandSeparator
                                        displayType="text"
                                        style={{
                                            ...theme.typography.subtitle1,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    />
                                </Container>
                            </Container>

                            <Container
                                style={{
                                    flex: 1,
                                    padding: theme.spacing(1),
                                    flexDirection: 'column',
                                }}
                            >
                                <Container>
                                    <Badge
                                        badgeContent={totalFlags}
                                        color="secondary"
                                    >
                                        <FlagIcon
                                            color={
                                                totalFlags > 0
                                                    ? undefined
                                                    : 'disabled'
                                            }
                                        />
                                    </Badge>

                                    <div style={{ flex: 1 }} />

                                    <Badge
                                        badgeContent={totalHandled}
                                        color="primary"
                                    >
                                        <AssignmentTurnedIn
                                            color={
                                                totalHandled > 0
                                                    ? undefined
                                                    : 'disabled'
                                            }
                                        />
                                    </Badge>
                                </Container>
                            </Container>
                        </Container>

                        {/* Bottom Half */}
                        <Container style={{ flex: 1, flexDirection: 'column' }}>
                            {assignmentInfo}
                            <Container style={{ alignItems: 'center' }}>
                                <Tooltip title="Damages">
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            clickDamage(unit)
                                        }}
                                    >
                                        <Badge
                                            badgeContent={damageCount}
                                            color="secondary"
                                        >
                                            <Build fontSize="small" />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                                <div style={{ flex: 1 }} />
                                <Tooltip title="Unit Notes">
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            onClickUnitNotes()
                                        }}
                                    >
                                        <Badge
                                            badgeContent={
                                                unit.unit_notes_msg_count
                                            }
                                            color={
                                                (unit.unread_unit_notes ?? 0) >
                                                0
                                                    ? 'secondary'
                                                    : 'primary'
                                            }
                                        >
                                            <UnitNotesIcon />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                            </Container>
                        </Container>
                        {/* Unit name, type and info button */}
                    </Container>

                    <Container
                        style={{
                            flex: 1,
                            flexWrap: 'wrap',
                        }}
                        scrollY
                    >
                        {unitInspection?.area_inspections.map(
                            (areaInspection) => {
                                return (
                                    <AreaInspectionComponent
                                        key={`AREA_INSPECTION_${areaInspection.id}`}
                                        areaInspection={areaInspection}
                                        areaStatusMap={props.areaStatusMap}
                                    />
                                )
                            },
                        )}
                    </Container>
                </Container>
            </Paper>
        </OnScreenHoc>
    )
}

export const InspectionUnit: React.FC<Props> = React.memo(_InspectionUnit)
