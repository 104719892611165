import { CompositeDecorator, ContentBlock, ContentState } from 'draft-js'
import { Mention } from '../RichTextEditor'

type DecoratorStrategy = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
) => void

const mentionStrategy: DecoratorStrategy = (contentBlock, callback) => {
    contentBlock.findEntityRanges((character) => {
        return character.getEntity() !== null
    }, callback)
}

export const compositeDecorator = new CompositeDecorator([
    {
        strategy: mentionStrategy,
        component: Mention,
    },
])
