import React from 'react'

interface Props {
    attachments: Array<any>
}
export const Attachment = (props: Props) => {
    return (
        <>
            {props.attachments && props.attachments.length > 0 ? (
                props.attachments.map((attachedFile: any, index: number) => (
                    <img
                        src={attachedFile.file}
                        alt=""
                        key={`attachment-${index}`}
                        style={{ width: '100px', height: '100px' }}
                    />
                ))
            ) : (
                <></>
            )}
        </>
    )
}
