import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import {
    ChatBubbleOutline as ChatBubbleOutlineIcon,
    Folder as FolderIcon,
} from '@material-ui/icons'

import {
    Avatar,
    ListSubheader,
    Tooltip,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core'
import { ListVendor } from '../../../../../models'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        memberList: {
            marginTop: '30px',
            maxHeight: '500px',
            overflowY: 'scroll',
            '& .MuiListItemText-primary': {
                fontSize: '0.813rem',
                color: '#000',
                fontWeight: 700,
                // textOverflow: 'ellipsis',
                // whiteSpace: 'nowrap',
                // overflow: 'hidden',
                // width: '100%',
            },
            '& .MuiListItemText-secondary': {
                fontSize: '0.688rem',
                color: '#000',
                fontWeight: 400,
                // textOverflow: 'ellipsis',
                // whiteSpace: 'nowrap',
                // overflow: 'hidden',
                // width: '100%',
            },
            '& .MuiListItem-root': {
                wordWrap: 'break-word',
            },
        },
        email: {
            width: '100%',
            display: 'block',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        phone: {
            width: '100%',
            display: 'block',
        },
        name: {
            width: '100%',
            display: 'block',
        },
        state: {
            fontSize: '0.625rem',
            color: '#8A8A8A',
            fontWeight: 400,
        },
    }),
)

interface Props {
    vendorList: ListVendor[] | undefined | null
}

const MyVendorList = (props: Props) => {
    const classes = useStyles()

    return (
        <>
            {/* <List
                dense={true}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        <span
                            style={{
                                fontWeight: 700,
                                fontSize: '13px',
                                lineHeight: '15px',

                                color: '#000000',
                            }}
                        >
                             My Vendors
                        </span>
                        <span
                            style={{
                                float: 'right',
                                fontWeight: 700,
                                fontSize: '13px',
                                color: '#008C85',
                            }}
                        >
                            View All
                        </span>
                    </ListSubheader>
                }
                // className={classes.memberList}
            ></List> */}
            <List
                dense={true}
                className={`ez-custom-scroll ${classes.memberList}`}
            >
                {props.vendorList && props.vendorList.length > 0 ? (
                    props.vendorList.map(
                        (vendor: ListVendor, index: number) => (
                            <ListItem key={`vendor-${index}`}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <FolderIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <>
                                            <span className={classes.name}>
                                                {vendor.name}
                                            </span>
                                            <span className={classes.state}>
                                                {vendor.city}{' '}
                                                {vendor.state
                                                    ? `, ${vendor.state}`
                                                    : ''}
                                            </span>
                                        </>
                                    }
                                    secondary={
                                        <>
                                            <Tooltip
                                                title={
                                                    vendor.email
                                                        ? vendor.email
                                                        : ''
                                                }
                                            >
                                                <span className={classes.email}>
                                                    {vendor.email}
                                                </span>
                                            </Tooltip>
                                            <Tooltip
                                                title={
                                                    vendor.phone
                                                        ? vendor.phone
                                                        : ''
                                                }
                                            >
                                                <span className={classes.phone}>
                                                    {vendor.phone}
                                                </span>
                                            </Tooltip>
                                        </>
                                    }
                                />
                                {/* <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => {
                                        props.toggleDrawer()
                                    }}
                                >
                                    <ChatBubbleOutlineIcon />
                                </IconButton>
                            </ListItemSecondaryAction> */}
                            </ListItem>
                        ),
                    )
                ) : (
                    <>
                        <p></p>
                    </>
                )}
            </List>
        </>
    )
}

export default MyVendorList
