import {
    Button,
    Checkbox,
    Divider,
    IconButton,
    Modal,
    Paper,
    Slide,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from '../../components'

import { UserDrawerProps } from '.'
import {
    emailHelperText,
    isAccountManager,
    isEmailValid,
    toMMDDYYYY,
} from '../../helpers'
import { useUser } from '../../hooks'
import {
    archiveUser,
    CreateUserRequest,
    RootState,
    UpdateUserRequest,
} from '../../store'
import { toast } from 'react-toastify'
import { CalendarTodayOutlined } from '@material-ui/icons'
import {
    defaultPermissible,
    EDIT_PERMISSION_ROLES,
    EDIT_USER,
    EDIT_USER_PERMISSIONS,
    getCleanPermissible,
    Permissible,
    PermissionRole,
} from '../../models/Permissions'
import { hasPermission } from '../../models/Users/services'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'

export const UserModal = (props: UserDrawerProps) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const permissionRoles = useSelector(
        (state: RootState) => state.permission.permissionRoleList,
    )
    const { createUser, updateUser, workspaceUser, logout } = useUser()

    // user state
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [cellPhone, setCellPhone] = useState('')
    const [userPermissionRoles, setUserPermissionRoles] = useState<number[]>([])
    const [date, setDate] = useState<Date | null>(null)

    // ui management state
    const [doValidate, setDoValidate] = useState(false)

    // permissions state
    const [updatedPermissible, setUpdatedPermissible] = useState<Permissible>(
        defaultPermissible,
    )

    const { open, drawerUser, handleClose } = props

    useEffect(() => {
        setName('')
        setEmail('')
        setPhone('')
        setCellPhone('')
        setUserPermissionRoles([])
        setDate(null)
    }, [handleClose])

    useEffect(() => {
        //on user change, do this
        if (drawerUser) {
            setUpdatedPermissible({ ...drawerUser })
            setName(drawerUser.name)
            setEmail(drawerUser.email)
            setDate(drawerUser.created_date)
            if (drawerUser.phone) setPhone(drawerUser.phone)
            if (drawerUser.cell_phone) setCellPhone(drawerUser.cell_phone)
            setUserPermissionRoles(
                drawerUser.permission_roles.map((role) => role.id),
            )
        } else {
            setName('')
            setEmail('')
            setPhone('')
            setCellPhone('')
            setUserPermissionRoles([])
            setDate(null)
        }
        setDoValidate(false)
    }, [drawerUser, open])

    const inputStyle: React.CSSProperties = {
        margin: theme.spacing(1, 2, 1, 2),
    }

    const saveButtonClick = () => {
        // first validate the name
        if (name === '') {
            setDoValidate(true)
            return
        }
        if (drawerUser) {
            //update the user
            //first create the req body
            const req: UpdateUserRequest = {
                isCurrentUser: workspaceUser?.id === drawerUser.id,
                params: {
                    id: drawerUser.id,
                },
                body: {
                    name: name,
                    phone: phone,
                    cell_phone: cellPhone,
                    email: email,
                },
            }

            if (hasPermission(workspaceUser, EDIT_USER_PERMISSIONS)) {
                req.body.permissions = getCleanPermissible(updatedPermissible)
            }
            if (hasPermission(workspaceUser, EDIT_PERMISSION_ROLES)) {
                req.body.permission_roles = userPermissionRoles
            }

            updateUser(req).then(() => {
                toast.success(`${name} was updated!`)
                //now we can close the drawer
                handleClose()
            })
        } else {
            //create a new user
            if (!isEmailValid(email)) {
                //this isn't validating they aren't in the system, this just validates the format using a regex
                setDoValidate(true)
                return
            }
            const req: CreateUserRequest = {
                body: {
                    email: email,
                    name: name,
                    phone: phone,
                    cell_phone: cellPhone,
                    permission_roles: userPermissionRoles,
                },
            }

            //not validating that they aren't creating a user with a duplicate email because the toast will alert the user
            createUser(req).then(() => {
                toast.success(`${name} was added to your workspace!`)
                //now we can close the drawer
                handleClose()
            })
        } // end if else chain
    }

    const DRAWER_USER_IS_WORKSPACE_OWNER = drawerUser?.workspace_owner
    const DRAWER_USER_IS_NOT_SELF =
        drawerUser && drawerUser.id !== workspaceUser?.id
    const USER_CAN_EDIT_PERMISSION_ROLES = hasPermission(
        workspaceUser,
        EDIT_USER_PERMISSIONS,
    )

    const disableCheckbox =
        (DRAWER_USER_IS_WORKSPACE_OWNER && DRAWER_USER_IS_NOT_SELF) ||
        !USER_CAN_EDIT_PERMISSION_ROLES

    const renderPermissionRoles = (
        permissionRoles: PermissionRole[] | undefined,
    ) => {
        return permissionRoles?.map((role) => {
            return (
                <Container
                    key={role.id}
                    style={{ alignItems: 'center' }}
                    onClick={() => {
                        if (!disableCheckbox) {
                            if (userPermissionRoles.includes(role.id)) {
                                setUserPermissionRoles(
                                    userPermissionRoles.filter((r) => {
                                        return r != role.id
                                    }),
                                )
                            } else {
                                setUserPermissionRoles([
                                    ...userPermissionRoles,
                                    role.id,
                                ])
                            }
                        }
                    }}
                >
                    <Container style={{ flex: 1 }}>{role.name}</Container>
                    <Checkbox
                        checked={userPermissionRoles.includes(role.id)}
                        disabled={disableCheckbox}
                        style={{
                            color: disableCheckbox
                                ? undefined
                                : theme.palette.primary.dark,
                        }}
                    />
                </Container>
            )
        })
    }

    const disableEmail =
        workspaceUser && drawerUser
            ? (hasPermission(workspaceUser, EDIT_USER) &&
                  workspaceUser.id === drawerUser.id) ||
              isAccountManager(workspaceUser)
            : hasPermission(workspaceUser, EDIT_USER)

    let canSave = true

    if (drawerUser) {
        canSave = hasPermission(workspaceUser, EDIT_USER)
    }

    return (
        <Modal
            open={open}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            onClose={() => {
                handleClose()
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                    }}
                >
                    <Container
                        style={{ margin: theme.spacing(3) }}
                        direction="column"
                    >
                        <Container style={{ flex: 1 }}>
                            {(drawerUser && (
                                <span
                                    style={{
                                        ...theme.typography.h5,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        marginTop: theme.spacing(1),
                                        marginLeft: theme.spacing(4),
                                    }}
                                >
                                    {name}
                                </span>
                            )) || (
                                <span
                                    style={{
                                        ...theme.typography.h5,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        marginTop: theme.spacing(1),
                                        marginLeft: theme.spacing(4),
                                    }}
                                >
                                    Add New Member
                                </span>
                            )}
                            <div style={{ flex: 1 }} />
                            <Tooltip title="Cancel">
                                <IconButton onClick={handleClose}>
                                    <CloseIcon
                                        fontSize="large"
                                        style={{
                                            marginRight: theme.spacing(1),
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Container>
                        <Divider
                            style={{ margin: theme.spacing(0, 3, 0, 4) }}
                        />

                        <Container
                            direction="row"
                            style={{ margin: theme.spacing(2) }}
                        >
                            {/* body */}
                            <Container
                                direction="column"
                                flex={1}
                                scrollY
                                style={{ width: '350px' }}
                            >
                                {(drawerUser && (
                                    <Container
                                        style={{
                                            margin: theme.spacing(1, 2, 1, 2),
                                            color: 'grey',
                                        }}
                                    >
                                        You&apos;re reviewing the details for{' '}
                                        {drawerUser.name} down below!
                                    </Container>
                                )) || (
                                    <Container
                                        style={{
                                            margin: theme.spacing(1, 2, 1, 2),
                                            color: 'grey',
                                        }}
                                    >
                                        Please enter your teammates details
                                        below!
                                    </Container>
                                )}
                                {/* add input fields for name, email, phone, and cell phone */}
                                <Container direction="column">
                                    <TextField
                                        error={doValidate && name === ''}
                                        helperText={
                                            doValidate && name === ''
                                                ? 'Required'
                                                : ''
                                        }
                                        variant="outlined"
                                        value={name}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        label="Name"
                                        style={{ ...inputStyle }}
                                        disabled={
                                            !hasPermission(
                                                workspaceUser,
                                                EDIT_USER,
                                            )
                                        }
                                        size="small"
                                    />
                                    <TextField
                                        error={
                                            doValidate &&
                                            (email === '' ||
                                                !isEmailValid(email))
                                        }
                                        helperText={emailHelperText(
                                            doValidate,
                                            email,
                                        )}
                                        variant="outlined"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        label="Email"
                                        style={inputStyle}
                                        size="small"
                                        disabled={!disableEmail}
                                    />
                                    <TextField
                                        variant="outlined"
                                        value={cellPhone}
                                        onChange={(e) =>
                                            setCellPhone(e.target.value)
                                        }
                                        label="Cell Phone"
                                        style={{ ...inputStyle, flex: 1 }}
                                        size="small"
                                        disabled={
                                            !hasPermission(
                                                workspaceUser,
                                                EDIT_USER,
                                            )
                                        }
                                    />
                                    {/* </Container> */}
                                    <TextField
                                        variant="outlined"
                                        value={phone}
                                        onChange={(e) =>
                                            setPhone(e.target.value)
                                        }
                                        label="Phone"
                                        style={inputStyle}
                                        size="small"
                                        disabled={
                                            !hasPermission(
                                                workspaceUser,
                                                EDIT_USER,
                                            )
                                        }
                                    />
                                    {drawerUser && (
                                        <TextField
                                            variant="outlined"
                                            value={
                                                date !== null
                                                    ? toMMDDYYYY(new Date(date))
                                                    : ''
                                            }
                                            label="Created Date"
                                            style={{
                                                ...inputStyle,
                                                cursor: 'pointer',
                                            }}
                                            size="small"
                                            disabled={true}
                                            InputProps={{
                                                endAdornment: (
                                                    <CalendarTodayOutlined fontSize="small" />
                                                ),
                                            }}
                                        />
                                    )}
                                </Container>

                                <Divider
                                    style={{
                                        margin: theme.spacing(1, 2, 0, 2),
                                    }}
                                />
                                <Container style={{ flex: 1 / 2 }} />
                            </Container>

                            <Container
                                direction="column"
                                style={{
                                    marginLeft: theme.spacing(8),
                                    width: '300px',
                                }}
                            >
                                {/* add and remove user permission roles */}

                                <Container
                                    style={{
                                        marginBottom: theme.spacing(1),
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        fontSize: 18,
                                    }}
                                >
                                    Permission Role
                                </Container>
                                <Container
                                    direction="column"
                                    style={{ maxHeight: '600px' }}
                                    scrollY
                                >
                                    {renderPermissionRoles(permissionRoles)}
                                </Container>

                                {/* footer */}
                                <Divider />
                            </Container>
                        </Container>
                        <Container
                            style={{
                                padding: theme.spacing(2),
                                justifyContent: 'flex-end',
                            }}
                        >
                            {drawerUser && !drawerUser.workspace_owner && (
                                <Button
                                    variant="outlined"
                                    style={{
                                        color: theme.palette.primary.dark,
                                        textTransform: 'none',
                                    }}
                                    onClick={() => {
                                        if (
                                            confirm(
                                                `Doing this will remove ${
                                                    drawerUser.id ===
                                                    workspaceUser?.id
                                                        ? 'you'
                                                        : drawerUser.name
                                                } from ${
                                                    workspaceUser
                                                        ?.active_workspace.name
                                                }${
                                                    drawerUser.id ===
                                                    workspaceUser?.id
                                                        ? ' and log you out'
                                                        : ''
                                                }. Are you sure you want to do this?`,
                                            )
                                        ) {
                                            dispatch(
                                                archiveUser({
                                                    user: drawerUser,
                                                }),
                                            )
                                            if (
                                                drawerUser.id ===
                                                workspaceUser?.id
                                            ) {
                                                logout()
                                            } else {
                                                toast.success(
                                                    `${drawerUser.name} was removed from your workspace!`,
                                                )
                                            }
                                            handleClose()
                                        }
                                    }}
                                >
                                    Archive
                                </Button>
                            )}
                            {drawerUser && (
                                <Button
                                    color="secondary"
                                    style={{
                                        marginRight: theme.spacing(3),
                                        marginLeft: theme.spacing(3),
                                        textTransform: 'none',
                                    }}
                                    variant="outlined"
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                            )}
                            <Container>
                                <Button
                                    variant={'outlined'}
                                    onClick={() => saveButtonClick()}
                                    style={{
                                        color: drawerUser
                                            ? 'black'
                                            : theme.palette.primary.dark,
                                        textTransform: 'none',
                                    }}
                                    disabled={!canSave}
                                >
                                    {drawerUser ? 'Update' : 'Save'}
                                </Button>
                            </Container>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
