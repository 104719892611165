import React, { useEffect, useState } from 'react'
import { AddressState, Container } from '../../../../components'
import { Questionnaire } from './Components'
import { useUser } from '../../../../hooks'
import { ListVendor, RegisterStep, Service } from '../../../../models'
import { axiosInstance } from '../../../../helpers'

export const SkillcatQuestionnaire = () => {
    const { workspaceUser } = useUser()

    const [vendor, setVendor] = useState<ListVendor | undefined>()

    const [activeStep, setActiveStep] = useState(RegisterStep.DETAIL_STEP)

    const [addressState, setAddressState] = useState<AddressState>(
        defaultAddressState,
    )

    const [selectedServices, setSelectedServices] = useState<Service[]>([])

    const handleNextStep = () => {
        const nextStep = activeStep + 1
        setActiveStep(nextStep)
    }

    useEffect(() => {
        if (workspaceUser) {
            axiosInstance
                .get(`company/vendor/${workspaceUser?.active_workspace.id}`)
                .then((res) => {
                    const fetchedVendor = res.data
                    setVendor(fetchedVendor)
                    setActiveStep(Number(fetchedVendor.register_step))
                })
        }
    }, [])
    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
            }}
        >
            <Questionnaire
                type="Skillcat"
                workspaceUser={workspaceUser}
                vendor={vendor}
                activeStep={activeStep}
                addressState={addressState}
                setAddressState={setAddressState}
                selectedServices={selectedServices}
                setSelectedServices={setSelectedServices}
                handleNextStep={handleNextStep}
            />
        </Container>
    )
}

const defaultAddressState: AddressState = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
}
