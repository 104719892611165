export const dateFilterOptions = ['Today', 'Tomorrow', 'Upcoming'] as const

export type DateFilters = typeof dateFilterOptions[number]

// be is backend, d is display
export const filterOptions = [
    { be: 'ALL', d: 'All' },
    { be: 'ASSIGNED', d: 'Assigned' },
    { be: 'ACTIVE', d: 'Active' },
    { be: 'GO_BACK', d: 'Go Back' },
    { be: 'IN_PROGRESS', d: 'In Progress' },
    { be: 'PAUSE', d: 'Pause' },
    { be: 'COMPLETE', d: 'Complete' },
    { be: 'APPROVED', d: 'Approved' },
] as const

export const beWorkorderStatuses = filterOptions.map((option) => {
    return option.be
})

export type WorkorderStatusFilters = typeof beWorkorderStatuses[number]
