import React, { useEffect, useState } from 'react'

import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Modal,
    Paper,
    Slide,
    useTheme,
} from '@material-ui/core'
import { Addendum, UpdateDDLeaseRequest } from '../../../../Hooks'
import {
    AuditPromptConfig,
    DDLease,
    MatchedLease,
} from '../../../../../../models/Lease/types'

interface Props {
    open: boolean
    promptConfigs: AuditPromptConfig[]
    selectedLease: MatchedLease | null
    updateDDLease: (request: UpdateDDLeaseRequest) => Promise<DDLease>
    getLeaseAudit: (leaseAuditID: number) => void
    handleCloseModal: () => void
}

export const SignatureModal = (props: Props) => {
    const {
        open,
        promptConfigs,
        selectedLease,
        updateDDLease,
        getLeaseAudit,
        handleCloseModal,
    } = props

    const theme = useTheme()

    const [addendums, setAddendums] = useState<Addendum[]>([])

    const inititalizeAddendums = () => {
        const signatureConfigs: AuditPromptConfig[] = promptConfigs.filter(
            (pc) => pc.category == 'lease',
        )

        const initialAddendums: Addendum[] = []

        signatureConfigs.forEach((config) => {
            if (selectedLease && selectedLease.pdf_lease !== null) {
                const value = config.dd_lease_name as keyof DDLease
                const selected = selectedLease.pdf_lease[value]
                if (typeof selected === 'boolean') {
                    initialAddendums.push({
                        promptConfig: config,
                        selected: selected,
                    })
                } else {
                    initialAddendums.push({
                        promptConfig: config,
                        selected: null,
                    })
                }
            }
        })
        return initialAddendums
    }

    useEffect(() => {
        if (promptConfigs.length > 0 && selectedLease !== null) {
            const initialAddendums = inititalizeAddendums()
            setAddendums(initialAddendums)
        }
    }, [promptConfigs, selectedLease])

    const handleCheckboxChange = (index: number, value: boolean) => {
        setAddendums((prevAddendums) =>
            prevAddendums.map((addendum, i) =>
                i === index ? { ...addendum, selected: value } : addendum,
            ),
        )
    }

    const viewLease = (selectedLease: MatchedLease | null) => {
        if (
            selectedLease &&
            selectedLease.pdf_lease !== null &&
            selectedLease.pdf_lease.lease !== null
        ) {
            // TODO: set page to page number stored in the db.
            return (
                <iframe
                    width="100%"
                    height="100%"
                    loading="lazy"
                    src={`${selectedLease.pdf_lease.lease}#page=${selectedLease.pdf_lease.lease_sign_page}`}
                ></iframe>
            )
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflowY: 'scroll',
                        flexDirection: 'column',
                        height: '90%',
                        width: '80%',
                        padding: theme.spacing(2),
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flex: 1,
                        }}
                    >
                        {/* Check boxes */}
                        <Grid item xs={4}>
                            {addendums.map((addendum, idx) => (
                                <Grid
                                    container
                                    key={idx}
                                    alignItems="center"
                                    spacing={1}
                                    style={{
                                        marginBottom: theme.spacing(1),
                                    }}
                                >
                                    <Grid item xs={6}>
                                        {addendum.promptConfig.display_name}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        addendum.selected ===
                                                        true
                                                    }
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            idx,
                                                            true,
                                                        )
                                                    }
                                                />
                                            }
                                            label="Yes"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        addendum.selected ===
                                                        false
                                                    }
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            idx,
                                                            false,
                                                        )
                                                    }
                                                />
                                            }
                                            label="No"
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                            <Button
                                style={{
                                    color: '#fff',
                                    borderTop: theme.spacing(2),
                                    backgroundColor: theme.palette.primary.dark,
                                    height: '33px',
                                    maxWidth: '200px',
                                    minWidth: '200px',
                                    borderRadius: '5px',
                                    borderWidth: 0,
                                    fontSize: '14px',
                                    lineHeight: '17px',
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    textTransform: 'none',
                                }}
                                onClick={() => {
                                    if (
                                        selectedLease &&
                                        selectedLease.pdf_lease
                                    ) {
                                        const request: UpdateDDLeaseRequest = {
                                            id: selectedLease.pdf_lease.id,
                                            signature_audit: true,
                                            signatures: addendums,
                                        }
                                        updateDDLease(request).then(() => {
                                            getLeaseAudit(
                                                selectedLease.lease_audit,
                                            )
                                            handleCloseModal()
                                        })
                                    }
                                }}
                            >
                                Save
                            </Button>
                        </Grid>

                        {/* Lease sign pages */}
                        <Grid item xs={8}>
                            {' '}
                            {viewLease(selectedLease)}
                        </Grid>
                    </Grid>
                </Paper>
            </Slide>
        </Modal>
    )
}
