import React, { useEffect, useState } from 'react'

import { Button, Tab, Tabs, Theme } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { Container, NumberInput } from '../../../../components'
import {
    AreaConfig,
    ListVendor,
    ServiceContract,
    UnitConfig,
    getScCompoundKeyString,
    ServiceContractCompoundKey,
} from '../../../../models'
import { useServiceContract } from '../../../../hooks/useServiceContract'
import { VENDOR_MODAL_H, VENDOR_MODAL_W } from './contants'

interface Props {
    vendor: ListVendor
    theme: Theme
    unitConfigs: UnitConfig[]
    areaConfigs: AreaConfig[]
    onSubmit: (inputState: InputState) => void
}

export const StepVendorPrices = (props: Props) => {
    const { vendor, unitConfigs, areaConfigs, theme, onSubmit } = props

    const [selectedTab, setSelectedTab] = useState(0)

    const { getServiceContractList } = useServiceContract()

    const [scMap, setScMap] = useState<InputState>({})

    useEffect(() => {
        getServiceContractList({ params: { vendor: vendor.id } }).then(
            (res) => {
                const selectedService = vendor.services[0]
                if (selectedService !== undefined) {
                    mapServiceContracts(res.data)
                }
            },
        )
    }, [])

    const mapServiceContracts = (serviceContracts: ServiceContract[]) => {
        const mapping: InputState = {}

        serviceContracts.forEach((sc) => {
            mapping[getScCompoundKeyString(sc)] = sc.price
        })
        setScMap(mapping)
    }

    const updateInputValue = (
        key: ServiceContractCompoundKey,
        value: number,
    ) => {
        setScMap((prev) => {
            return { ...prev, [getScCompoundKeyString(key)]: value }
        })
    }

    const getServiceLabel = (serviceId: number) => {
        const denominator = unitConfigs.length + areaConfigs.length

        if (denominator === 0) {
            return (
                <span
                    style={{
                        ...theme.typography.caption,
                        fontWeight: theme.typography.fontWeightLight,
                        marginRight: theme.spacing(1),
                    }}
                >
                    (n/a)
                </span>
            )
        }

        let numerator = 0
        areaConfigs.forEach((ac) => {
            const k = getScCompoundKeyString({
                serviceId: serviceId,
                areaConfigId: ac.id,
                unitConfigId: null,
                vendorId: vendor.id,
            })

            if (scMap[k] !== undefined) {
                numerator += 1
            }
        })

        unitConfigs.forEach((uc) => {
            const k = getScCompoundKeyString({
                serviceId: serviceId,
                areaConfigId: null,
                unitConfigId: uc.id,
                vendorId: vendor.id,
            })

            if (scMap[k] !== undefined) {
                numerator += 1
            }
        })

        if (numerator === denominator) {
            return (
                <CheckCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    style={{ marginRight: theme.spacing(1) }}
                />
            )
        }

        return (
            <span
                style={{
                    ...theme.typography.caption,
                    fontWeight: theme.typography.fontWeightLight,
                    marginRight: theme.spacing(1),
                }}
            >
                ({numerator}/{denominator})
            </span>
        )
    }

    const selectedService = vendor.services[selectedTab]
    if (selectedService === undefined) {
        return null
    }

    return (
        <Container
            style={{
                flex: 1,
                borderTop: `1px solid ${theme.palette.grey[400]}`,
                maxHeight: VENDOR_MODAL_H,
                minHeight: VENDOR_MODAL_H,
                minWidth: VENDOR_MODAL_W,
                maxWidth: VENDOR_MODAL_W,
            }}
        >
            {/* Left Column, Choose service */}
            <Container
                style={{
                    flexDirection: 'column',
                    width: 150,
                    borderRight: `1px solid ${theme.palette.grey[400]}`,
                }}
            >
                <Tabs
                    value={selectedTab}
                    orientation="vertical"
                    variant="scrollable"
                    onChange={(_, value) => {
                        setSelectedTab(value)
                    }}
                >
                    {vendor.services.map((srv, index) => {
                        return (
                            <Tab
                                key={`SERVICE-OFFERING-${srv.id}`}
                                value={index}
                                label={
                                    <Container
                                        style={{
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <span>{srv.name}</span>
                                        <div style={{ flex: 1 }} />
                                        {getServiceLabel(srv.id)}
                                    </Container>
                                }
                                style={{ textTransform: 'none' }}
                            />
                        )
                    })}
                </Tabs>
            </Container>

            {/* Right Column, Body */}
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    maxHeight: VENDOR_MODAL_H - FOOTER_H,
                }}
            >
                {/* Body, Service Contract Columns */}
                <Container
                    style={{
                        flex: 1,
                        maxHeight: VENDOR_MODAL_H - FOOTER_H,
                        minHeight: VENDOR_MODAL_H - FOOTER_H,
                    }}
                >
                    {/* Column 1,  Areas*/}
                    <Container
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                            padding: theme.spacing(1),
                            overflowY: 'scroll',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                                color: theme.palette.grey[800],
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            Areas
                        </span>

                        {areaConfigs.map((areaConfig) => {
                            return (
                                <NumberInput
                                    key={`AREA-CONFIG-${areaConfig.id}-${selectedTab}`}
                                    prefix={'$'}
                                    variant="outlined"
                                    label={areaConfig.name}
                                    value={
                                        scMap[
                                            getScCompoundKeyString({
                                                serviceId: selectedService.id,
                                                vendorId: vendor.id,
                                                areaConfigId: areaConfig.id,
                                                unitConfigId: null,
                                            })
                                        ]
                                    }
                                    style={{ marginBottom: theme.spacing(2) }}
                                    onChange={(e) =>
                                        updateInputValue(
                                            {
                                                serviceId: selectedService.id,
                                                vendorId: vendor.id,
                                                areaConfigId: areaConfig.id,
                                                unitConfigId: null,
                                            },
                                            Number(e.target.value),
                                        )
                                    }
                                />
                            )
                        })}
                    </Container>

                    {/* Column 2, Unit Types */}
                    <Container
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                            padding: theme.spacing(1),
                            overflowY: 'scroll',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                                color: theme.palette.grey[800],
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            Unit Types
                        </span>

                        {unitConfigs.map((unitConfig) => {
                            return (
                                <NumberInput
                                    key={`UNIT-CONFIG-${unitConfig.id}-${selectedTab}`}
                                    prefix={'$'}
                                    variant="outlined"
                                    label={unitConfig.name}
                                    value={
                                        scMap[
                                            getScCompoundKeyString({
                                                serviceId: selectedService.id,
                                                vendorId: vendor.id,
                                                areaConfigId: null,
                                                unitConfigId: unitConfig.id,
                                            })
                                        ]
                                    }
                                    style={{ marginBottom: theme.spacing(2) }}
                                    onChange={(e) =>
                                        updateInputValue(
                                            {
                                                serviceId: selectedService.id,
                                                vendorId: vendor.id,
                                                areaConfigId: null,
                                                unitConfigId: unitConfig.id,
                                            },
                                            Number(e.target.value),
                                        )
                                    }
                                />
                            )
                        })}
                    </Container>
                </Container>

                {/* Footer, Submit button */}
                <Container
                    style={{
                        borderTop: `1px solid ${theme.palette.grey[400]}`,
                        maxHeight: FOOTER_H,
                        minHeight: FOOTER_H,
                        padding: theme.spacing(1),
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: 'none', height: 40 }}
                        onClick={() => {
                            onSubmit(scMap)
                        }}
                    >
                        Save
                    </Button>
                </Container>
            </Container>
        </Container>
    )
}

interface InputState {
    [compoundKey: string]: number | null
}

const FOOTER_H = 80
