import React from 'react'
import { Container } from '../Container'
import { SvgIconTypeMap, useTheme } from '@material-ui/core'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'

interface CustomStyle {
    container?: React.CSSProperties
    text?: React.CSSProperties
}

interface Props {
    title: string
    customStyle?: CustomStyle
    onClick?: () => void
    sortDir: 'ascend' | 'decend' | null
}

export const SortTitle = (props: Props) => {
    const { title, sortDir, onClick } = props
    const theme = useTheme()

    let icon: JSX.Element | null = null

    if (sortDir === 'ascend') {
        icon = <ArrowDropUpIcon />
    } else if (sortDir === 'decend') {
        icon = <ArrowDropDownIcon />
    }

    return (
        <Container
            onClick={onClick}
            style={{
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                ...props.customStyle?.container,
            }}
        >
            <span
                style={{
                    ...theme.typography.subtitle1,
                    fontWeight:
                        sortDir !== null
                            ? theme.typography.fontWeightBold
                            : undefined,
                    ...props.customStyle?.text,
                }}
            >
                {title}
            </span>

            <Container style={{ width: 30, justifyContent: 'center' }}>
                {icon}
            </Container>
        </Container>
    )
}
