import {
    Button,
    Checkbox,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    Slide,
    Step,
    StepButton,
    Stepper,
    TextField,
    Theme,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { AddAPhoto, Close, Delete } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { Container, Finder, NumberInput, Selector } from '../../components'
import {
    Claimant,
    IncidentReport,
    IncidentSeverity,
    Lease,
    WitnessClaimant,
    traverse,
} from '../../models'
import { axiosInstance } from '../../helpers'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { FinderSelection } from '../../hooks'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { VersionedTree } from '../../hooks/useInfrastructure/types'
import { IncidentReportContext } from './IncidentReportRoot'

interface Props {
    open: boolean
    onClose: () => void
    addNewIncidentReport: (incidentReport: IncidentReport) => void
    tree: VersionedTree
    locationFinderSelection: FinderSelection
}

export const FIRST_STEP = 0
export const SECOND_STEP = 1

const QUESTION_HEIGHT = 40

export type CreateIncidentReportStep = typeof FIRST_STEP | typeof SECOND_STEP

const baseWitnessClaimant: WitnessClaimant = {
    name: '',
    email: '',
    firstAid: '',
    medicalTransportProvider: '',
    objectCarryingOrHolding: '',
    reasonOnCommunity: '',
}

export const IncidentReportCreateForm = (props: Props) => {
    const { open, onClose, tree, locationFinderSelection } = props

    const theme = useTheme()

    const {
        incidentTypes,
        organization,
        addIncidentReport,
        getIncidentTypes,
    } = useContext(IncidentReportContext)

    useEffect(() => {
        getIncidentTypes()
    }, [])
    const [
        selectedIncidentTypeId,
        setSelectedIncidentTypeId,
    ] = useState<number>(-1)
    const [severity, setSeverity] = useState<string>(IncidentSeverity.LOW)

    const [description, setDescription] = useState('')
    const [title, setTitle] = useState('')

    const [incidentDate, setIncidentDate] = useState<Date | null>(new Date())

    const [onMasterInsurance, setOnMasterInsurance] = useState<boolean>()
    const [tenantNegligent, setTenantNegligent] = useState<boolean>()

    const [leases, setLeases] = useState<Lease[]>()

    const [claimants, setClaimants] = useState<WitnessClaimant[]>([])
    const [witnesses, setWitnesses] = useState<WitnessClaimant[]>([])

    const [
        locationInspectedAfter,
        setLocationInspectedAfter,
    ] = useState<boolean>()
    const [inspectorName, setInspectorName] = useState('')
    const [inspectorEmail, setInspectorEmail] = useState('')
    const [inspectedDate, setInspectedDate] = useState<Date | null>(new Date())

    const [policeOrFireCalled, setPoliceOrFireCalled] = useState<boolean>()
    const [policeOrFireContact, setPoliceOrFireContact] = useState('')
    const [policeOrFireCaseNumber, setPoliceOrFireCaseNumber] = useState('')

    const [bodilyInjury, setBodilyInjury] = useState<boolean>()
    const [bodilyInjuryDescription, setBodilyInjuryDescription] = useState('')
    const [lightingConditions, setLightingConditions] = useState('')
    const [weatherConditions, setWeatherConditions] = useState('')
    const [walkwayGroundConditions, setWalkwayGroundConditions] = useState('')

    const [propertyDamage, setPropertyDamage] = useState<boolean>()
    const [typeOfLoss, setTypeOfLoss] = useState('')
    const [lossCause, setLossCause] = useState('')
    const [estimatedCost, setEstimatedCost] = useState('')

    const [error, setError] = useState(false)

    const [activeStep, setActiveStep] = useState<CreateIncidentReportStep>(
        FIRST_STEP,
    )

    const [images, setImages] = useState<File[]>([])

    useEffect(() => {
        let unitIdList = ''
        if (locationFinderSelection.selection.unit.length === 1) {
            Object.keys(locationFinderSelection.selection.unit).forEach(
                (key) => {
                    if (key !== 'length') {
                        unitIdList = key + ','
                    }
                },
            )
        }

        if (locationFinderSelection.selection.folder.length === 1) {
            Object.keys(locationFinderSelection.selection.folder).forEach(
                (key) => {
                    if (key !== 'length') {
                        traverse(tree.root, (folder) => {
                            if (folder.id === Number(key)) {
                                // traverse the matching fodler and all its subfolders to get the unit options
                                traverse(folder, (child_folder) => {
                                    child_folder.units.forEach((unit) => {
                                        unitIdList += `${unit.id},`
                                    })
                                })
                            }
                        })
                    }
                },
            )
        }
        if (unitIdList.length > 0) {
            unitIdList = unitIdList.slice(0, -1)
            axiosInstance
                .get('lease/', {
                    params: {
                        units: unitIdList,
                        // overlap_start: new Date(),
                        // overlap_end: new Date(),
                    },
                })
                .then((res) => {
                    setLeases(res.data)
                })
        }
    }, [locationFinderSelection])

    const steps = ['Basic Info', 'Location & People']
    const selectStep = (step: CreateIncidentReportStep) => {
        // Use this method to go backwards to a previous step
        if (step <= activeStep) {
            setActiveStep(step)
        }
    }

    const firstStepComplete = () => {
        if (selectedIncidentTypeId === -1) {
            return false
        }

        const nonEmptyStrings = [title, description]

        const notUndefined = [
            onMasterInsurance,
            tenantNegligent,
            bodilyInjury,
            locationInspectedAfter,
            policeOrFireCalled,
            propertyDamage,
        ]

        if (bodilyInjury) {
            nonEmptyStrings.push(
                bodilyInjuryDescription,
                lightingConditions,
                weatherConditions,
                walkwayGroundConditions,
            )
        }
        if (locationInspectedAfter) {
            nonEmptyStrings.push(inspectorName, inspectorEmail)
        }
        if (policeOrFireCalled) {
            nonEmptyStrings.push(policeOrFireCaseNumber, policeOrFireContact)
        }
        if (propertyDamage) {
            nonEmptyStrings.push(typeOfLoss, lossCause, estimatedCost)
        }

        if (notUndefined.some((val) => val === undefined)) {
            return false
        }

        return !nonEmptyStrings.includes('')
    }

    const secondStepComplete = () => {
        const nonEmptyStrings: string[] = []
        witnesses.forEach((witness) => {
            nonEmptyStrings.push(
                witness.name,
                witness.email,
                witness.reasonOnCommunity,
            )
        })
        claimants.forEach((claimant) => {
            nonEmptyStrings.push(
                claimant.name,
                claimant.email,
                claimant.reasonOnCommunity,
                claimant.medicalTransportProvider ?? '',
                claimant.firstAid ?? '',
                claimant.objectCarryingOrHolding ?? '',
            )
        })

        return !nonEmptyStrings.includes('')
    }

    const handleClose = () => {
        setSelectedIncidentTypeId(-1)
        setSeverity(IncidentSeverity.LOW)
        setDescription('')
        setTitle('')
        setIncidentDate(new Date())
        setOnMasterInsurance(undefined)
        setTenantNegligent(undefined)
        setClaimants([])
        setWitnesses([])
        setLocationInspectedAfter(undefined)
        setInspectorName('')
        setInspectorEmail('')
        setInspectedDate(new Date())
        setPoliceOrFireCalled(undefined)
        setPoliceOrFireContact('')
        setPoliceOrFireCaseNumber('')
        setBodilyInjury(undefined)
        setBodilyInjuryDescription('')
        setLightingConditions('')
        setWeatherConditions('')
        setWalkwayGroundConditions('')
        setPropertyDamage(undefined)
        setTypeOfLoss('')
        setLossCause('')
        setEstimatedCost('')
        setError(false)
        setActiveStep(FIRST_STEP)
        setImages([])
        props.onClose()
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Incident Report Details"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflowY: 'scroll',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: '1200px',
                        height: '800px',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    {/* Header */}
                    <Container
                        style={{
                            marginBottom: theme.spacing(1),
                            flexDirection: 'row',
                        }}
                    >
                        <Container
                            style={{
                                fontWeight: 600,
                                fontSize: '25px',
                                margin: theme.spacing(1, 0, 2, 0),
                                flex: 1,
                            }}
                        >
                            New Incident Report
                        </Container>

                        <Tooltip title="Exit">
                            <IconButton
                                onClick={() => {
                                    onClose()
                                }}
                            >
                                <Close
                                    fontSize="inherit"
                                    style={{
                                        marginRight: theme.spacing(1),
                                        color: theme.palette.darkGreen.main,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Container>

                    {/* Body */}
                    <Container style={{ overflowY: 'scroll' }}>
                        {activeStep === FIRST_STEP && (
                            <Container style={{ flex: 1 }}>
                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        padding: theme.spacing(1),
                                    }}
                                >
                                    <TextField
                                        value={title}
                                        style={{ marginBottom: 8 }}
                                        label="Title"
                                        placeholder="Title"
                                        error={error && title === ''}
                                        helperText={
                                            error && title === '' && 'Required'
                                        }
                                        onChange={(e) => {
                                            setTitle(e.target.value)
                                        }}
                                    />
                                    <TextField
                                        value={description}
                                        style={{ marginBottom: 8 }}
                                        label="Description"
                                        placeholder="Description"
                                        error={error && title === ''}
                                        helperText={
                                            error &&
                                            description === '' &&
                                            'Required'
                                        }
                                        onChange={(e) => {
                                            setDescription(e.target.value)
                                        }}
                                    />
                                    <ToggleButtonGroup
                                        value={severity}
                                        onChange={(_, innerSev) => {
                                            setSeverity(innerSev)
                                        }}
                                        aria-label="Severity"
                                        exclusive
                                    >
                                        <ToggleButton
                                            value={IncidentSeverity.LOW}
                                            color={theme.palette.primary.main}
                                        >
                                            Low
                                        </ToggleButton>
                                        <ToggleButton
                                            value={IncidentSeverity.MID}
                                        >
                                            Medium
                                        </ToggleButton>
                                        <ToggleButton
                                            value={IncidentSeverity.HIGH}
                                        >
                                            High
                                        </ToggleButton>
                                    </ToggleButtonGroup>

                                    <Selector
                                        label="Incident Types"
                                        data={incidentTypes}
                                        currentValue={selectedIncidentTypeId}
                                        getDisplayString={(type) => type.name}
                                        error={
                                            error &&
                                            selectedIncidentTypeId === -1
                                        }
                                        onChange={(e) => {
                                            setSelectedIncidentTypeId(
                                                e.target.value as number,
                                            )
                                        }}
                                        customStyle={{
                                            formControl: {
                                                margin: theme.spacing(1),
                                                marginLeft: 0,
                                                minWidth: 120,
                                                maxWidth: 300,
                                            },
                                        }}
                                    />

                                    <KeyboardDatePicker
                                        error={incidentDate === null}
                                        value={incidentDate}
                                        onChange={(date) =>
                                            setIncidentDate(date)
                                        }
                                        format="MM/dd/yyyy"
                                        inputVariant="outlined"
                                        style={{ marginTop: theme.spacing(1) }}
                                        label="Incident Date"
                                    />

                                    <Container
                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div style={{ flex: 1 }} />
                                        <Container
                                            style={{
                                                marginRight: theme.spacing(1),
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    marginRight: theme.spacing(
                                                        3,
                                                    ),
                                                }}
                                            >
                                                No
                                            </span>
                                            <span>Yes</span>
                                        </Container>
                                    </Container>

                                    <Container>
                                        <Container
                                            style={{
                                                flexDirection: 'column',
                                                flex: 1,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    ...theme.typography.body1,
                                                    fontWeight: 'bold',
                                                    height: QUESTION_HEIGHT,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color:
                                                        error &&
                                                        onMasterInsurance ===
                                                            undefined
                                                            ? theme.palette
                                                                  .error.main
                                                            : undefined,
                                                }}
                                            >
                                                Are you on {organization?.name}{' '}
                                                Master Insurance?
                                            </span>
                                            <span
                                                style={{
                                                    ...theme.typography.body1,
                                                    fontWeight: 'bold',
                                                    height: QUESTION_HEIGHT,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color:
                                                        error &&
                                                        tenantNegligent ===
                                                            undefined
                                                            ? theme.palette
                                                                  .error.main
                                                            : undefined,
                                                }}
                                            >
                                                Was a tenant negligent?
                                            </span>
                                        </Container>

                                        <Container
                                            style={{ flexDirection: 'column' }}
                                        >
                                            <Container
                                                style={{
                                                    height: QUESTION_HEIGHT,
                                                }}
                                            >
                                                <Checkbox
                                                    // check against false because otherwise it evauates wrong due to the variable being able to be undefined
                                                    checked={
                                                        onMasterInsurance ===
                                                        false
                                                    }
                                                    onClick={() => {
                                                        setOnMasterInsurance(
                                                            false,
                                                        )
                                                    }}
                                                    indeterminate={
                                                        onMasterInsurance ===
                                                        false
                                                    }
                                                />
                                                <Checkbox
                                                    // check against true because otherwise it evauates wrong due to the variable being able to be undefined
                                                    checked={
                                                        onMasterInsurance ===
                                                        true
                                                    }
                                                    onClick={() => {
                                                        setOnMasterInsurance(
                                                            true,
                                                        )
                                                    }}
                                                    color="primary"
                                                />
                                            </Container>
                                            <Container
                                                style={{
                                                    height: QUESTION_HEIGHT,
                                                }}
                                            >
                                                <Checkbox
                                                    checked={
                                                        tenantNegligent ===
                                                        false
                                                    }
                                                    onClick={() => {
                                                        setTenantNegligent(
                                                            false,
                                                        )
                                                    }}
                                                    indeterminate={
                                                        tenantNegligent ===
                                                        false
                                                    }
                                                />
                                                <Checkbox
                                                    checked={
                                                        tenantNegligent === true
                                                    }
                                                    onClick={() => {
                                                        setTenantNegligent(true)
                                                    }}
                                                    color="primary"
                                                />
                                            </Container>
                                        </Container>
                                    </Container>
                                </Container>
                                <Container
                                    style={{ flex: 1, flexDirection: 'column' }}
                                >
                                    <Container
                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div style={{ flex: 1 }} />
                                        <Container
                                            style={{
                                                marginRight: theme.spacing(1),
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    marginRight: theme.spacing(
                                                        3,
                                                    ),
                                                }}
                                            >
                                                No
                                            </span>
                                            <span>Yes</span>
                                        </Container>
                                    </Container>
                                    {/* Bodily Injury */}
                                    <Container
                                        style={{ flexDirection: 'column' }}
                                    >
                                        <Container
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    ...theme.typography.body1,
                                                    fontWeight: 'bold',
                                                    flex: 1,
                                                    color:
                                                        error &&
                                                        bodilyInjury ===
                                                            undefined
                                                            ? theme.palette
                                                                  .error.main
                                                            : undefined,
                                                }}
                                            >
                                                Bodily Injury?
                                            </span>
                                            <Container>
                                                <Checkbox
                                                    checked={
                                                        bodilyInjury === false
                                                    }
                                                    onClick={() => {
                                                        setBodilyInjury(false)
                                                    }}
                                                    indeterminate={
                                                        bodilyInjury === false
                                                    }
                                                />
                                                <Checkbox
                                                    checked={
                                                        bodilyInjury === true
                                                    }
                                                    onClick={() => {
                                                        setBodilyInjury(true)
                                                    }}
                                                    color="primary"
                                                />
                                            </Container>
                                        </Container>
                                        {bodilyInjury && (
                                            <Container
                                                style={{
                                                    flexDirection: 'column',
                                                    padding: theme.spacing(2),
                                                    paddingTop: 0,
                                                }}
                                            >
                                                <TextField
                                                    value={
                                                        bodilyInjuryDescription
                                                    }
                                                    style={{ marginBottom: 8 }}
                                                    error={
                                                        error &&
                                                        bodilyInjury &&
                                                        bodilyInjuryDescription ===
                                                            ''
                                                    }
                                                    helperText={
                                                        error &&
                                                        bodilyInjury &&
                                                        bodilyInjuryDescription ===
                                                            '' &&
                                                        'Required'
                                                    }
                                                    label="Bodily Injury Description"
                                                    placeholder="Bodily Injury Description"
                                                    onChange={(e) => {
                                                        setBodilyInjuryDescription(
                                                            e.target.value,
                                                        )
                                                    }}
                                                />
                                                <TextField
                                                    value={lightingConditions}
                                                    style={{ marginBottom: 8 }}
                                                    error={
                                                        error &&
                                                        bodilyInjury &&
                                                        lightingConditions ===
                                                            ''
                                                    }
                                                    helperText={
                                                        error &&
                                                        bodilyInjury &&
                                                        lightingConditions ===
                                                            '' &&
                                                        'Required'
                                                    }
                                                    label="Lighting Conditions"
                                                    placeholder="Lighting Conditions"
                                                    onChange={(e) => {
                                                        setLightingConditions(
                                                            e.target.value,
                                                        )
                                                    }}
                                                />
                                                <TextField
                                                    value={weatherConditions}
                                                    style={{ marginBottom: 8 }}
                                                    error={
                                                        error &&
                                                        bodilyInjury &&
                                                        weatherConditions === ''
                                                    }
                                                    helperText={
                                                        error &&
                                                        bodilyInjury &&
                                                        weatherConditions ===
                                                            '' &&
                                                        'Required'
                                                    }
                                                    label="Weather Conditions"
                                                    placeholder="Weather Conditions"
                                                    onChange={(e) => {
                                                        setWeatherConditions(
                                                            e.target.value,
                                                        )
                                                    }}
                                                />
                                                <TextField
                                                    value={
                                                        walkwayGroundConditions
                                                    }
                                                    style={{ marginBottom: 8 }}
                                                    error={
                                                        error &&
                                                        bodilyInjury &&
                                                        walkwayGroundConditions ===
                                                            ''
                                                    }
                                                    helperText={
                                                        error &&
                                                        bodilyInjury &&
                                                        walkwayGroundConditions ===
                                                            '' &&
                                                        'Required'
                                                    }
                                                    label="Walkway / Ground Conditions"
                                                    placeholder="Walkway / Ground Conditions"
                                                    onChange={(e) => {
                                                        setWalkwayGroundConditions(
                                                            e.target.value,
                                                        )
                                                    }}
                                                />
                                            </Container>
                                        )}
                                    </Container>
                                    {/* Location Inspected After */}
                                    <Container
                                        style={{ flexDirection: 'column' }}
                                    >
                                        <Container
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    ...theme.typography.body1,
                                                    fontWeight: 'bold',
                                                    flex: 1,
                                                    color:
                                                        error &&
                                                        locationInspectedAfter ===
                                                            undefined
                                                            ? theme.palette
                                                                  .error.main
                                                            : undefined,
                                                }}
                                            >
                                                Location Inspected After?
                                            </span>
                                            <Container>
                                                <Checkbox
                                                    checked={
                                                        locationInspectedAfter ===
                                                        false
                                                    }
                                                    onClick={() => {
                                                        setLocationInspectedAfter(
                                                            false,
                                                        )
                                                    }}
                                                    indeterminate={
                                                        locationInspectedAfter ===
                                                        false
                                                    }
                                                />
                                                <Checkbox
                                                    checked={
                                                        locationInspectedAfter ===
                                                        true
                                                    }
                                                    onClick={() => {
                                                        setLocationInspectedAfter(
                                                            true,
                                                        )
                                                    }}
                                                    color="primary"
                                                />
                                            </Container>
                                        </Container>
                                        {locationInspectedAfter && (
                                            <Container
                                                style={{
                                                    flexDirection: 'column',
                                                    padding: theme.spacing(2),
                                                    paddingTop: 0,
                                                }}
                                            >
                                                <TextField
                                                    value={inspectorName}
                                                    style={{ marginBottom: 8 }}
                                                    error={
                                                        error &&
                                                        locationInspectedAfter &&
                                                        inspectorName === ''
                                                    }
                                                    helperText={
                                                        error &&
                                                        locationInspectedAfter &&
                                                        inspectorName === '' &&
                                                        'Required'
                                                    }
                                                    label="Inspector Name"
                                                    placeholder="Inspector Name"
                                                    onChange={(e) => {
                                                        setInspectorName(
                                                            e.target.value,
                                                        )
                                                    }}
                                                />
                                                <TextField
                                                    value={inspectorEmail}
                                                    style={{ marginBottom: 8 }}
                                                    error={
                                                        error &&
                                                        locationInspectedAfter &&
                                                        inspectorEmail === ''
                                                    }
                                                    helperText={
                                                        error &&
                                                        locationInspectedAfter &&
                                                        inspectorEmail === '' &&
                                                        'Required'
                                                    }
                                                    label="Inspector Email"
                                                    placeholder="Inspector Email"
                                                    onChange={(e) => {
                                                        setInspectorEmail(
                                                            e.target.value,
                                                        )
                                                    }}
                                                />
                                                <KeyboardDatePicker
                                                    error={
                                                        inspectedDate === null
                                                    }
                                                    value={inspectedDate}
                                                    onChange={(date) =>
                                                        setInspectedDate(date)
                                                    }
                                                    format="MM/dd/yyyy"
                                                    inputVariant="outlined"
                                                    style={{
                                                        marginTop: theme.spacing(
                                                            1,
                                                        ),
                                                    }}
                                                    label="Inspected Date"
                                                />
                                            </Container>
                                        )}
                                    </Container>
                                    {/* Police / Fire Called */}
                                    <Container
                                        style={{ flexDirection: 'column' }}
                                    >
                                        <Container
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    ...theme.typography.body1,
                                                    fontWeight: 'bold',
                                                    flex: 1,
                                                    color:
                                                        error &&
                                                        policeOrFireCalled ===
                                                            undefined
                                                            ? theme.palette
                                                                  .error.main
                                                            : undefined,
                                                }}
                                            >
                                                Police / Fire Called?
                                            </span>
                                            <Container>
                                                <Checkbox
                                                    checked={
                                                        policeOrFireCalled ===
                                                        false
                                                    }
                                                    onClick={() => {
                                                        setPoliceOrFireCalled(
                                                            false,
                                                        )
                                                    }}
                                                    indeterminate={
                                                        policeOrFireCalled ===
                                                        false
                                                    }
                                                />
                                                <Checkbox
                                                    checked={
                                                        policeOrFireCalled ===
                                                        true
                                                    }
                                                    onClick={() => {
                                                        setPoliceOrFireCalled(
                                                            true,
                                                        )
                                                    }}
                                                    color="primary"
                                                />
                                            </Container>
                                        </Container>
                                        {policeOrFireCalled && (
                                            <Container
                                                style={{
                                                    flexDirection: 'column',
                                                    padding: theme.spacing(2),
                                                    paddingTop: 0,
                                                }}
                                            >
                                                <TextField
                                                    value={policeOrFireContact}
                                                    style={{ marginBottom: 8 }}
                                                    error={
                                                        error &&
                                                        policeOrFireCalled &&
                                                        policeOrFireContact ===
                                                            ''
                                                    }
                                                    helperText={
                                                        error &&
                                                        policeOrFireCalled &&
                                                        policeOrFireContact ===
                                                            '' &&
                                                        'Required'
                                                    }
                                                    label="Police / Fire Contact"
                                                    placeholder="Police / Fire Contact"
                                                    onChange={(e) => {
                                                        setPoliceOrFireContact(
                                                            e.target.value,
                                                        )
                                                    }}
                                                />
                                                <TextField
                                                    value={
                                                        policeOrFireCaseNumber
                                                    }
                                                    style={{ marginBottom: 8 }}
                                                    error={
                                                        error &&
                                                        policeOrFireCalled &&
                                                        policeOrFireCaseNumber ===
                                                            ''
                                                    }
                                                    helperText={
                                                        error &&
                                                        policeOrFireCalled &&
                                                        policeOrFireCaseNumber ===
                                                            '' &&
                                                        'Required'
                                                    }
                                                    label="Police / Fire Case Number"
                                                    placeholder="Police / Fire Case Number"
                                                    onChange={(e) => {
                                                        setPoliceOrFireCaseNumber(
                                                            e.target.value,
                                                        )
                                                    }}
                                                />
                                            </Container>
                                        )}
                                    </Container>
                                    {/* Property Damage */}
                                    <Container
                                        style={{ flexDirection: 'column' }}
                                    >
                                        <Container
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    ...theme.typography.body1,
                                                    fontWeight: 'bold',
                                                    flex: 1,
                                                    color:
                                                        error &&
                                                        propertyDamage ===
                                                            undefined
                                                            ? theme.palette
                                                                  .error.main
                                                            : undefined,
                                                }}
                                            >
                                                Property Damage?
                                            </span>
                                            <Container>
                                                <Checkbox
                                                    checked={
                                                        propertyDamage === false
                                                    }
                                                    onClick={() => {
                                                        setPropertyDamage(false)
                                                    }}
                                                    indeterminate={
                                                        propertyDamage === false
                                                    }
                                                />
                                                <Checkbox
                                                    checked={
                                                        propertyDamage === true
                                                    }
                                                    onClick={() => {
                                                        setPropertyDamage(true)
                                                    }}
                                                    color="primary"
                                                />
                                            </Container>
                                        </Container>
                                        {propertyDamage && (
                                            <Container
                                                style={{
                                                    flexDirection: 'column',
                                                    padding: theme.spacing(2),
                                                    paddingTop: 0,
                                                }}
                                            >
                                                <TextField
                                                    value={typeOfLoss}
                                                    style={{ marginBottom: 8 }}
                                                    error={
                                                        error &&
                                                        propertyDamage &&
                                                        typeOfLoss === ''
                                                    }
                                                    helperText={
                                                        error &&
                                                        propertyDamage &&
                                                        typeOfLoss === '' &&
                                                        'Required'
                                                    }
                                                    label="Type of Loss"
                                                    placeholder="Type of Loss"
                                                    onChange={(e) => {
                                                        setTypeOfLoss(
                                                            e.target.value,
                                                        )
                                                    }}
                                                />
                                                <TextField
                                                    value={lossCause}
                                                    style={{ marginBottom: 8 }}
                                                    error={
                                                        error &&
                                                        propertyDamage &&
                                                        lossCause === ''
                                                    }
                                                    helperText={
                                                        error &&
                                                        propertyDamage &&
                                                        lossCause === '' &&
                                                        'Required'
                                                    }
                                                    label="Cause of Loss"
                                                    placeholder="Cause of Loss"
                                                    onChange={(e) => {
                                                        setLossCause(
                                                            e.target.value,
                                                        )
                                                    }}
                                                />
                                                <NumberInput
                                                    value={estimatedCost}
                                                    style={{ marginBottom: 8 }}
                                                    error={
                                                        error &&
                                                        propertyDamage &&
                                                        estimatedCost === ''
                                                    }
                                                    helperText={
                                                        error &&
                                                        propertyDamage &&
                                                        estimatedCost === '' &&
                                                        'Required'
                                                    }
                                                    label="Estimated Cost"
                                                    placeholder="Estimated Cost"
                                                    prefix="$"
                                                    onChange={(e) => {
                                                        setEstimatedCost(
                                                            e.target.value,
                                                        )
                                                    }}
                                                />
                                            </Container>
                                        )}
                                    </Container>
                                </Container>
                                <Container
                                    style={{ flexDirection: 'column', flex: 1 }}
                                >
                                    <span
                                        style={{
                                            ...theme.typography.body1,
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Location
                                    </span>
                                    <Finder
                                        root={tree.root}
                                        onClickUnit={
                                            locationFinderSelection.setFinderSelection
                                        }
                                        onClickFolder={
                                            locationFinderSelection.setFinderSelection
                                        }
                                        locationSelection={
                                            locationFinderSelection.selection
                                        }
                                        selectedIcon={<></>}
                                        getUnitAdornment={(unit) => {
                                            return (
                                                <Container style={{ flex: 1 }}>
                                                    <div style={{ flex: 1 }} />
                                                    <Checkbox
                                                        checked={
                                                            locationFinderSelection
                                                                .selection.unit[
                                                                unit.id
                                                            ] !== undefined
                                                        }
                                                        onClick={() => {
                                                            locationFinderSelection.setFinderSelection(
                                                                {
                                                                    type:
                                                                        'unit',
                                                                    location: unit,
                                                                },
                                                            )
                                                        }}
                                                    />
                                                </Container>
                                            )
                                        }}
                                        getFolderAdornment={(folder) => {
                                            return (
                                                <Container style={{ flex: 1 }}>
                                                    <div style={{ flex: 1 }} />
                                                    <Checkbox
                                                        color={'primary'}
                                                        checked={
                                                            locationFinderSelection
                                                                .selection
                                                                .folder[
                                                                folder.id
                                                            ] !== undefined
                                                        }
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            locationFinderSelection.setFinderSelection(
                                                                {
                                                                    type:
                                                                        'folder',
                                                                    location: folder,
                                                                },
                                                            )
                                                        }}
                                                    />
                                                </Container>
                                            )
                                        }}
                                    />
                                </Container>
                            </Container>
                        )}

                        {activeStep === SECOND_STEP && (
                            <Container
                                style={{
                                    flex: 1,
                                    height: '100%',
                                    overflowY: 'scroll',
                                }}
                            >
                                {/* Claimants */}
                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        overflowY: 'scroll',
                                    }}
                                >
                                    <Button
                                        onClick={() => {
                                            setClaimants([
                                                ...claimants,
                                                baseWitnessClaimant,
                                            ])
                                        }}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        + Claimant
                                    </Button>
                                    {claimants.map((claimant, idx) => {
                                        return (
                                            <ClaimantComponent
                                                key={`CLAIMANT_COMPONENT_${idx}`}
                                                leases={leases}
                                                error={error}
                                                witnessClaimant={claimant}
                                                setWitnessClaimant={(
                                                    newClaimant,
                                                ) => {
                                                    const newClaimants = claimants.map(
                                                        (
                                                            innerClaimant,
                                                            innerIdx,
                                                        ) => {
                                                            if (
                                                                innerIdx === idx
                                                            ) {
                                                                return newClaimant
                                                            }
                                                            return innerClaimant
                                                        },
                                                    )
                                                    setClaimants(newClaimants)
                                                }}
                                                removeWitnessClaimant={() => {
                                                    const newClaimants = claimants.reduce<
                                                        WitnessClaimant[]
                                                    >(
                                                        (
                                                            prev,
                                                            current,
                                                            innerIdx,
                                                        ) => {
                                                            if (
                                                                innerIdx === idx
                                                            ) {
                                                                return prev
                                                            }
                                                            return prev.concat(
                                                                current,
                                                            )
                                                        },
                                                        [],
                                                    )
                                                    setClaimants(newClaimants)
                                                }}
                                                theme={theme}
                                            />
                                        )
                                    })}
                                </Container>
                                {/* Witnesses */}
                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        margin: theme.spacing(1),
                                        marginTop: 0,
                                        overflowY: 'scroll',
                                    }}
                                >
                                    <Button
                                        onClick={() => {
                                            setWitnesses([
                                                ...witnesses,
                                                baseWitnessClaimant,
                                            ])
                                        }}
                                        variant="outlined"
                                        color="primary"
                                    >
                                        + Witness
                                    </Button>
                                    {witnesses.map((witness, idx) => {
                                        return (
                                            <WitnessComponent
                                                key={`WITNESS_COMPONENT_${idx}`}
                                                error={error}
                                                isWitness
                                                leases={leases}
                                                witnessClaimant={witness}
                                                setWitnessClaimant={(
                                                    newWitness,
                                                ) => {
                                                    const newWitnesses = witnesses.map(
                                                        (
                                                            innerWitness,
                                                            innerIdx,
                                                        ) => {
                                                            if (
                                                                innerIdx === idx
                                                            ) {
                                                                return newWitness
                                                            }
                                                            return innerWitness
                                                        },
                                                    )
                                                    setWitnesses(newWitnesses)
                                                }}
                                                removeWitnessClaimant={() => {
                                                    const newWitnesses = witnesses.reduce<
                                                        WitnessClaimant[]
                                                    >(
                                                        (
                                                            prev,
                                                            current,
                                                            innerIdx,
                                                        ) => {
                                                            if (
                                                                innerIdx === idx
                                                            ) {
                                                                return prev
                                                            }
                                                            return prev.concat(
                                                                current,
                                                            )
                                                        },
                                                        [],
                                                    )
                                                    setWitnesses(newWitnesses)
                                                }}
                                                theme={theme}
                                            />
                                        )
                                    })}
                                </Container>

                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <input
                                        accept="image/png, image/jpeg, image/jpg"
                                        id="icon-button-file"
                                        type="file"
                                        style={{ display: 'none' }}
                                        multiple
                                        onChange={(e) => {
                                            if (
                                                e.target.files &&
                                                e.target.files[0]
                                            ) {
                                                setImages([
                                                    ...images,
                                                    e.target.files[0],
                                                ])
                                            }
                                        }}
                                    />

                                    {images.length < 3 && (
                                        <label htmlFor="icon-button-file">
                                            <Container
                                                style={{
                                                    height: 150,
                                                    width: 150,
                                                    borderRadius:
                                                        theme.shape
                                                            .borderRadius,
                                                    border: `1px solid ${theme.palette.grey[400]}`,
                                                    backgroundColor:
                                                        theme.palette.grey[200],
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginLeft: theme.spacing(
                                                        1,
                                                    ),
                                                }}
                                            >
                                                <AddAPhoto fontSize="large" />
                                            </Container>
                                        </label>
                                    )}
                                    {images.map((image) => {
                                        return (
                                            <img
                                                key={`IMAGE_${image.name}`}
                                                src={URL.createObjectURL(image)}
                                                style={{
                                                    maxHeight: 200,
                                                    maxWidth: 200,
                                                    margin: theme.spacing(
                                                        1,
                                                        2,
                                                        1,
                                                        0,
                                                    ),
                                                }}
                                            />
                                        )
                                    })}
                                </Container>
                            </Container>
                        )}
                    </Container>
                    <div style={{ flex: 1 }} />
                    <Button
                        onClick={() => {
                            if (activeStep === FIRST_STEP) {
                                if (firstStepComplete()) {
                                    setError(false)
                                    setActiveStep(SECOND_STEP)
                                } else {
                                    setError(true)
                                }
                            } else {
                                if (secondStepComplete()) {
                                    setError(false)
                                    const args: any = {
                                        title: title,
                                        incident_date: incidentDate?.toISOString(),
                                        description: description,
                                        incident_type: selectedIncidentTypeId,
                                        severity: severity,
                                        on_master_insurance: onMasterInsurance,
                                        tenant_negligible: tenantNegligent,
                                    }
                                    if (locationInspectedAfter) {
                                        args.inspector_email = inspectorEmail
                                        args.inspector_name = inspectorName
                                        args.inspection_date = inspectedDate?.toISOString()
                                    }
                                    if (policeOrFireCalled) {
                                        args.police_or_fire_contact = policeOrFireContact
                                        args.police_or_fire_case_number = policeOrFireCaseNumber
                                    }
                                    if (bodilyInjury) {
                                        args.bodily_injury_description = bodilyInjuryDescription
                                        args.lighting_conditions = lightingConditions
                                        args.weather_conditions = weatherConditions
                                        args.walkway_groud_conditions = walkwayGroundConditions
                                    }
                                    if (propertyDamage) {
                                        args.type_of_loss = typeOfLoss
                                        args.what_caused_the_loss = lossCause
                                        args.estimated_damage_cost = estimatedCost
                                    }
                                    if (witnesses.length > 0) {
                                        args.witnesses = witnesses.map(
                                            (witness) => {
                                                return {
                                                    email: witness.email,
                                                    name: witness.name,
                                                    reason_on_the_community:
                                                        witness.reasonOnCommunity,
                                                }
                                            },
                                        )
                                    }
                                    if (claimants.length > 0) {
                                        args.claimants = claimants.map(
                                            (claimant) => {
                                                return {
                                                    email: claimant.email,
                                                    name: claimant.name,
                                                    reason_on_the_community:
                                                        claimant.reasonOnCommunity,
                                                    seeking_compensation:
                                                        claimant.seekingComp,
                                                    object_carrying_description:
                                                        claimant.objectCarryingOrHolding,
                                                    first_aid_description:
                                                        claimant.firstAid,
                                                    medical_transport_provider:
                                                        claimant.medicalTransportProvider,
                                                }
                                            },
                                        )
                                    }
                                    if (
                                        locationFinderSelection.selection.folder
                                            .length === 1
                                    ) {
                                        Object.keys(
                                            locationFinderSelection.selection
                                                .folder,
                                        ).forEach((key) => {
                                            if (key !== 'length') {
                                                args.folder = key
                                            }
                                        })
                                    }
                                    if (
                                        locationFinderSelection.selection.unit
                                            .length === 1
                                    ) {
                                        Object.keys(
                                            locationFinderSelection.selection
                                                .unit,
                                        ).forEach((key) => {
                                            if (key !== 'length') {
                                                args.unit = key
                                            }
                                        })
                                    }

                                    addIncidentReport(args, images)
                                    handleClose()
                                } else {
                                    setError(true)
                                }
                            }
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        {activeStep === FIRST_STEP ? 'Next Step' : 'Save'}
                    </Button>
                    <Divider />

                    <Stepper nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                            <Step key={label} completed={activeStep > index}>
                                <StepButton
                                    color="inherit"
                                    onClick={() => {
                                        selectStep(
                                            index as CreateIncidentReportStep,
                                        )
                                    }}
                                >
                                    {label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Paper>
            </Slide>
        </Modal>
    )
}

const SharedHoc = (props: React.PropsWithChildren<WitnessClaimantProps>) => {
    const {
        witnessClaimant,
        isWitness,
        setWitnessClaimant,
        removeWitnessClaimant,
        theme,
        error,
        leases,
    } = props

    const { selectedIncidentReport, addWitness, addClaimant } = useContext(
        IncidentReportContext,
    )

    const [selectedLeaseId, setSelectedLeaseId] = useState(-1)

    return (
        <Container
            style={{
                border: `1px solid ${theme.palette.grey[400]}`,
                flexDirection: 'column',
                margin: theme.spacing(1),
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(1),
            }}
        >
            {leases && leases?.length > 0 && (
                <Selector
                    label="Attach to a Tenant (optional)"
                    data={leases}
                    currentValue={selectedLeaseId}
                    getDisplayString={(lease) => lease.user.name}
                    onChange={(e) => {
                        const leaseId = e.target.value as number
                        setSelectedLeaseId(leaseId)
                        const lease = leases.find(
                            (inner) => inner.id === leaseId,
                        )
                        if (lease) {
                            setWitnessClaimant({
                                ...witnessClaimant,
                                name: lease.user.name,
                                email: lease.user.email,
                            })
                        }
                    }}
                    customStyle={{
                        formControl: {
                            margin: theme.spacing(1),
                            marginLeft: 0,
                            minWidth: 120,
                            maxWidth: 300,
                        },
                    }}
                />
            )}
            <Container style={{ alignItems: 'flex-end' }}>
                <TextField
                    value={witnessClaimant.name}
                    style={{ marginBottom: 8, flex: 1 }}
                    label="Name"
                    placeholder="Name"
                    error={error && witnessClaimant.name === ''}
                    onChange={(e) => {
                        setWitnessClaimant({
                            ...witnessClaimant,
                            name: e.target.value,
                        })
                    }}
                />
                <Tooltip title={`Remove ${isWitness ? 'Witness' : 'Claimant'}`}>
                    <IconButton
                        onClick={() => {
                            removeWitnessClaimant()
                        }}
                    >
                        <Delete
                            style={{
                                marginBottom: theme.spacing(1),
                                color: theme.palette.secondary.main,
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Container>
            <TextField
                value={witnessClaimant.email}
                style={{ marginBottom: 8 }}
                label="Email"
                placeholder="Email"
                error={error && witnessClaimant.email === ''}
                onChange={(e) => {
                    setWitnessClaimant({
                        ...witnessClaimant,
                        email: e.target.value,
                    })
                }}
            />
            <TextField
                value={witnessClaimant.reasonOnCommunity}
                style={{ marginBottom: 8 }}
                label="Reason on the Community"
                placeholder="Reason on the Community"
                error={error && witnessClaimant.reasonOnCommunity === ''}
                onChange={(e) => {
                    setWitnessClaimant({
                        ...witnessClaimant,
                        reasonOnCommunity: e.target.value,
                    })
                }}
            />
            {props.children}
            {selectedIncidentReport !== undefined && (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        if (isWitness) {
                            addWitness(witnessClaimant, selectedIncidentReport)
                        } else {
                            addClaimant(witnessClaimant, selectedIncidentReport)
                        }
                    }}
                >
                    Save
                </Button>
            )}
        </Container>
    )
}

export const WitnessComponent = (props: WitnessClaimantProps) => {
    return <SharedHoc {...props}></SharedHoc>
}

export const ClaimantComponent = (props: WitnessClaimantProps) => {
    const {
        witnessClaimant,
        isWitness,
        setWitnessClaimant,
        removeWitnessClaimant,
        theme,
        error,
    } = props
    return (
        <SharedHoc {...props}>
            <>
                <Container
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <div style={{ flex: 1 }} />
                    <Container
                        style={{
                            marginRight: theme.spacing(1),
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        <span style={{ marginRight: theme.spacing(3) }}>
                            No
                        </span>
                        <span>Yes</span>
                    </Container>
                </Container>
                <Container
                    style={{ flexDirection: 'row', alignItems: 'center' }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: 'bold',
                            color:
                                error &&
                                witnessClaimant.seekingComp === undefined
                                    ? theme.palette.error.main
                                    : undefined,
                            flex: 1,
                        }}
                    >
                        Seeking Compensation?
                    </span>
                    <Checkbox
                        checked={witnessClaimant.seekingComp === false}
                        onClick={() => {
                            setWitnessClaimant({
                                ...witnessClaimant,
                                seekingComp: false,
                            })
                        }}
                        indeterminate={witnessClaimant.seekingComp === false}
                    />
                    <Checkbox
                        checked={witnessClaimant.seekingComp === true}
                        onClick={() => {
                            setWitnessClaimant({
                                ...witnessClaimant,
                                seekingComp: true,
                            })
                        }}
                        color="primary"
                    />
                </Container>
                <TextField
                    value={witnessClaimant.firstAid}
                    style={{ marginBottom: 8 }}
                    error={error && witnessClaimant.firstAid === ''}
                    label="First Aid"
                    placeholder="First Aid"
                    onChange={(e) => {
                        setWitnessClaimant({
                            ...witnessClaimant,
                            firstAid: e.target.value,
                        })
                    }}
                />
                <TextField
                    value={witnessClaimant.medicalTransportProvider}
                    style={{ marginBottom: 8 }}
                    label="Medical Transport Provider"
                    placeholder="Medical Transport Provider"
                    error={
                        error && witnessClaimant.medicalTransportProvider === ''
                    }
                    onChange={(e) => {
                        setWitnessClaimant({
                            ...witnessClaimant,
                            medicalTransportProvider: e.target.value,
                        })
                    }}
                />
                <TextField
                    value={witnessClaimant.objectCarryingOrHolding}
                    style={{ marginBottom: 8 }}
                    label="Objects they were carrying or holding"
                    placeholder="Objects they were carrying or holding"
                    error={
                        error && witnessClaimant.objectCarryingOrHolding === ''
                    }
                    onChange={(e) => {
                        setWitnessClaimant({
                            ...witnessClaimant,
                            objectCarryingOrHolding: e.target.value,
                        })
                    }}
                />
            </>
        </SharedHoc>
    )
}
export interface WitnessClaimantProps {
    witnessClaimant: WitnessClaimant
    isWitness?: boolean
    setWitnessClaimant: (wc: WitnessClaimant) => void
    removeWitnessClaimant: () => void
    leases?: Lease[]
    incidentReport?: IncidentReport
    handleAddWitnessClaimant?: (witnessClaimant: Claimant) => void
    theme: Theme
    error?: boolean
}
