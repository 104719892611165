import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { ErrorResponse, RootState } from '../store'

export const ErrorProvider = () => {
    const networkError = useSelector(
        (state: RootState) => state.error.networkError,
    )

    const display = useSelector((state: RootState) => state.error.display)

    useEffect(() => {
        if (networkError !== undefined && display) {
            let errorMsg = 'There was a problem with your request'

            for (const property in networkError.response?.data) {
                const key = property as keyof ErrorResponse

                const value = networkError.response!.data[key]
                errorMsg += `\n${key}: ${value}`
            }

            toast.error(errorMsg)
        }
    }, [networkError])

    return <React.Fragment />
}
