import { Button, IconButton, Popover, Theme, Tooltip } from '@material-ui/core'
import React from 'react'
import { DDLease, MatchedLease } from '../../../../../models'
import { Container } from '../../../../../components'
import { DDLeaseInfo } from './DDLeaseInfo'
import { usePopover } from '../../../../../hooks/usePopover'
import { MoreVert } from '@material-ui/icons'
import { ReactComponent as EmptyEditIcon } from '../../../../../assets/edit-icon-empty.svg'
import { ReactComponent as FilledEditIcon } from '../../../../../assets/edit-icon-filled.svg'

interface Props {
    theme: Theme
    matchedLease: MatchedLease
    pdfLease: DDLease
    tenantMatch: boolean
    locationMatch: boolean
    startDateMatch: boolean
    endDateMatch: boolean
    rentMatch: boolean
    unmatchLeases: (leaseMatchId: number) => void
    onOpenLeaseModal: (lease: DDLease) => void
    onOpenLeasePDFModal: (lease: DDLease) => void
    onOpenPromptResultsModal: (lease: DDLease) => void
}

export const MatchedPDFLeaseCard = (props: Props) => {
    const {
        theme,
        matchedLease,
        pdfLease,
        tenantMatch,
        locationMatch,
        startDateMatch,
        endDateMatch,
        rentMatch,
        unmatchLeases,
        onOpenLeaseModal,
        onOpenLeasePDFModal,
        onOpenPromptResultsModal,
    } = props

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    return (
        <Container
            style={{
                flex: 1,
                minHeight: 100,
                backgroundColor: theme.palette.white.main,
                cursor: 'pointer',
                alignItems: 'center',
            }}
        >
            <DDLeaseInfo
                theme={theme}
                lease={pdfLease}
                tenantMatch={tenantMatch}
                rentMatch={rentMatch}
                startDateMatch={startDateMatch}
                endDateMatch={endDateMatch}
                locationMatch={locationMatch}
            />

            {pdfLease.edited ? (
                <IconButton
                    onClick={(e) => {
                        onOpenLeaseModal(pdfLease)
                    }}
                    size="small"
                >
                    <Tooltip title="Edit">
                        <FilledEditIcon style={{ height: 30, width: 30 }} />
                    </Tooltip>
                </IconButton>
            ) : (
                <IconButton
                    size="small"
                    onClick={(e) => {
                        onOpenLeaseModal(pdfLease)
                    }}
                >
                    <Tooltip title="Edit">
                        <EmptyEditIcon style={{ height: 30, width: 30 }} />
                    </Tooltip>
                </IconButton>
            )}

            <IconButton
                onClick={(e) => {
                    e.stopPropagation()
                    handleOpen(e)
                }}
                disableRipple={true}
                size="small"
                style={{
                    marginLeft: theme.spacing(1),
                }}
            >
                <MoreVert />
            </IconButton>

            <Popover
                anchorEl={anchorEl}
                open={isOpen}
                onClose={() => {
                    handleClose()
                }}
            >
                <Container
                    style={{
                        flexDirection: 'column',
                        padding: theme.spacing(1),
                    }}
                >
                    {/* <Button
                        onClick={(e) => {
                            onOpenPromptResultsModal(pdfLease)
                        }}
                        disableRipple={true}
                        style={{
                            textTransform: 'none',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                color: theme.palette.grey[700],
                            }}
                        >
                            View Prompt Results
                        </span>
                    </Button> */}
                    <Button
                        onClick={(e) => {
                            onOpenLeasePDFModal(pdfLease)
                        }}
                        disableRipple={true}
                        style={{
                            textTransform: 'none',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                color: theme.palette.grey[700],
                            }}
                        >
                            View PDF
                        </span>
                    </Button>
                    <Button
                        onClick={(e) => {
                            unmatchLeases(matchedLease.id)
                            handleClose()
                        }}
                        disableRipple={true}
                        style={{
                            textTransform: 'none',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                color: theme.palette.grey[700],
                            }}
                        >
                            Unmatch Leases
                        </span>
                    </Button>
                </Container>
            </Popover>
        </Container>
    )
}
