import { AxiosError } from 'axios'
import { setNetworkError } from '..'
import { axiosInstance } from '../../helpers'
import { useAppDispatch } from '../../hooks'
import { Bid, PortfolioServiceContract, ServiceContract } from '../../models'
import { AppThunk, ErrorResponse } from '../types'
import {
    UPDATE_SERVICE_CONTRACT_REQUEST_ACTION,
    UPDATE_SERVICE_CONTRACT_RESPONSE_ACTION,
    ServiceContractActionThunk,
    UpdateServiceContractsRequest,
    GetServiceContractListRequest,
    GET_SERVICE_CONTRACT_REQUEST_ACTION,
    GET_SERVICE_CONTRACT_RESPONSE_ACTION,
    ServiceContractActionTypes,
    SET_SERVICE_CONTRACTS,
    GetPortfolioServiceContractListRequest,
    ServiceContractRequests,
    SET_SERVICE_CONTRACT_LOADING,
    SET_PORTFOLIO_SERVICE_CONTRACTS,
    GET_PORTFOLIO_SERVICE_CONTRACT_REQUEST_ACTION,
    UPDATE_PENDING_BIDS_ACTION,
    UPDATE_BIDS_ACTION,
    SetServiceContractsRequest,
    SET_SERVICE_CONTRACT_REQUEST_ACTION,
} from './types'

export const updateServiceContract = (
    req: UpdateServiceContractsRequest,
): AppThunk<ServiceContractActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: UPDATE_SERVICE_CONTRACT_REQUEST_ACTION,
        })

        const url = 'service-contract/'

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch({
                type: UPDATE_SERVICE_CONTRACT_RESPONSE_ACTION,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const setServiceContracts = (
    req: SetServiceContractsRequest,
): AppThunk<ServiceContractActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: SET_SERVICE_CONTRACT_REQUEST_ACTION,
        })

        const url = 'service-contract/set_service_contracts/'

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch({
                type: UPDATE_SERVICE_CONTRACT_RESPONSE_ACTION,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const updatePendingBids = (req: number): ServiceContractActionTypes => {
    return {
        type: UPDATE_PENDING_BIDS_ACTION,
        payload: req,
    }
}

export const updateBids = (req: Bid[]): ServiceContractActionTypes => {
    return {
        type: UPDATE_BIDS_ACTION,
        payload: req,
    }
}

export const getServiceContractList = (
    req: GetServiceContractListRequest,
): AppThunk<ServiceContractActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: GET_SERVICE_CONTRACT_REQUEST_ACTION,
        })

        const url = 'service-contract/'

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })

            dispatch({
                type: GET_SERVICE_CONTRACT_RESPONSE_ACTION,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getPortfolioServiceContracts = (
    req: GetPortfolioServiceContractListRequest,
): AppThunk<ServiceContractActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(
            setServiceContractLoading(
                GET_PORTFOLIO_SERVICE_CONTRACT_REQUEST_ACTION,
                true,
            ),
        )

        const url = 'workorder/portfolio-schedule/get_portfolio_list/'

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })
            dispatch(setPortfolioServiceContractList(response.data))
            dispatch(
                setServiceContractLoading(
                    GET_PORTFOLIO_SERVICE_CONTRACT_REQUEST_ACTION,
                    false,
                ),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(
                setServiceContractLoading(
                    GET_PORTFOLIO_SERVICE_CONTRACT_REQUEST_ACTION,
                    false,
                ),
            )
            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const setServiceContractList = (
    scList: ServiceContract[],
): ServiceContractActionTypes => {
    return {
        type: SET_SERVICE_CONTRACTS,
        payload: scList,
    }
}

export const setServiceContractLoading = (
    request: ServiceContractRequests,
    isLoading: boolean,
): ServiceContractActionTypes => {
    return {
        type: SET_SERVICE_CONTRACT_LOADING,
        request: request,
        isLoading: isLoading,
    }
}

export const setPortfolioServiceContractList = (
    contracts?: PortfolioServiceContract[],
): ServiceContractActionTypes => {
    return {
        type: SET_PORTFOLIO_SERVICE_CONTRACTS,
        contracts: contracts,
    }
}
