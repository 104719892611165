import { useState } from 'react'
import { IdentifiableObject, LengthTrackingIdBoolMap } from '../models'

export const useSelectionMap = () => {
    const [selectionMap, setSelectionMap] = useState<LengthTrackingIdBoolMap>({
        length: 0,
    })

    const setIdSelectionValue = (id: number, isSelected: boolean) => {
        setSelectionMap({
            ...selectionMap,
            [id]: isSelected,
            length: isSelected
                ? (selectionMap.length += 1)
                : (selectionMap.length -= 1),
        })
    }

    const clearSelections = () => {
        setSelectionMap({ length: 0 })
    }

    const selectAll = (items: IdentifiableObject[]) => {
        const newSelectionMap: LengthTrackingIdBoolMap = { length: 0 }
        items.forEach((item) => {
            newSelectionMap[item.id] = true
            newSelectionMap.length += 1
        })

        setSelectionMap(newSelectionMap)
    }

    const isIdSelected = (id: number) => {
        return selectionMap[id] === true
    }

    const getSelectedItems = <T extends IdentifiableObject>(items: T[]) => {
        return items.filter((item) => {
            return isIdSelected(item.id)
        })
    }

    return {
        selectionMap,
        setSelectionMap,
        setIdSelectionValue,
        clearSelections,
        selectAll,
        isIdSelected,
        getSelectedItems,
    }
}

export type SelectionController = ReturnType<typeof useSelectionMap>
