import React, { useState } from 'react'
import { Tooltip, IconButton, Badge, useTheme } from '@material-ui/core'

import { Container, ScheduleReportPopOver } from '../../../components'
import {
    BarChart as BarChartIcon,
    Add as AddIcon,
    Tune as TuneIcon,
    Edit as EditIcon,
    MonetizationOn as MonetizationOnIcon,
    Check as CheckIcon,
    VpnKey,
} from '@material-ui/icons'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'
import Link from '@material-ui/icons/Link'

import { useAppDispatch, useUser } from '../../../hooks'
import { hasPermission } from '../../../models/Users/services'
import {
    AreaStatusTag,
    BaseWorkorder,
    ChangeOrderStatus,
    CREATE_WORKORDERS,
    EDIT_WORKORDERS,
    Schedule,
    SEE_VENDOR_FINANCES,
} from '../../../models'
import { axiosInstance, resolveRoute, Routes } from '../../../helpers'
import FileDownload from 'js-file-download'
import {
    ChangeOrderFilterMode,
    InfrastructureFilterOptions,
    RootState,
    setInfrastructureFilter,
} from '../../../store'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import { SchedulePreTurnChecklist } from './SchedulePreTurnChecklist'
import { ReactComponent as UnitNotesIcon } from '../../../assets/icons/unitnotes.svg'

interface Props {
    filterOpen: boolean
    editMode: boolean
    clickAssign: () => void
    clickBudget: () => void
    clickFilter: () => void
    clickSmartScheduler: () => void
    clickAnalytics: () => void
    analyticsOpen: boolean
    setEditMode: (editMode: boolean) => void
    clickKeyTracking: () => void
    schedule?: Schedule
    workorderList?: BaseWorkorder[]
    areaStatusTagList?: AreaStatusTag[]
    openProjectNotes: boolean
    clickProjectNotes: () => void
    clickChangeOrder: () => void
}

export const SchedulerHeaderNav = (props: Props) => {
    const { schedule, clickKeyTracking } = props

    const history = useHistory()

    const dispatch = useAppDispatch()

    const filter = useSelector(
        (state: RootState) => state.infrastructureFilter.filter,
    )

    const { workspaceUser } = useUser()

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
    const [preTurnAnchorEl, setPreTurnAnchorEl] = useState<HTMLElement | null>(
        null,
    )

    const [reportLoading, setReportLoading] = useState(false)

    const openPreTurnPopover = (event: React.MouseEvent<HTMLElement>) => {
        setPreTurnAnchorEl(event.currentTarget)
    }
    const closePreTurnPopover = () => {
        setPreTurnAnchorEl(null)
    }
    const preTurnPopoverOpen = Boolean(preTurnAnchorEl)

    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closePopover = () => {
        setAnchorEl(null)
    }

    const theme = useTheme()

    const popOverOpen = Boolean(anchorEl)

    const pendingChangeOrderCount = props.workorderList?.reduce(
        (prevCount, wo) => {
            return (prevCount +=
                wo.changeorder_set?.reduce((coCount, co) => {
                    return (coCount +=
                        co.status === ChangeOrderStatus.PENDING ? 1 : 0)
                }, 0) ?? 0)
        },
        0,
    )

    let keyInCount = 0
    let keysRequired = 0
    props.areaStatusTagList?.forEach((ast) => {
        if (
            ast.area.area_config.occupiable &&
            ast.area_status_config.should_service
        ) {
            if (ast.key) {
                keyInCount += 1
            }
            keysRequired += 1
        }
    })

    return (
        <Container
            style={{
                flexDirection: 'column',
            }}
        >
            <Container>
                <div style={{ flex: 1 }} />

                {schedule?.channel && (
                    <Tooltip title="Project Notes">
                        <IconButton onClick={props.clickProjectNotes}>
                            <UnitNotesIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                )}

                {schedule && (
                    <Tooltip title="EZ Scheduler">
                        <IconButton onClick={props.clickSmartScheduler}>
                            <BubbleChartIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                )}
                {schedule && (
                    <Tooltip title="Checklist">
                        <IconButton onClick={openPreTurnPopover}>
                            <CheckIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                )}
                {schedule && (
                    <Tooltip title="Budget Details">
                        <IconButton
                            onClick={() => props.clickBudget()}
                            disabled={
                                props.editMode ||
                                !hasPermission(
                                    workspaceUser,
                                    SEE_VENDOR_FINANCES,
                                )
                            }
                        >
                            <MonetizationOnIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                )}
                {schedule && schedule?.active && (
                    <Tooltip title="Assign">
                        <IconButton
                            onClick={props.clickAssign}
                            disabled={
                                !hasPermission(workspaceUser, CREATE_WORKORDERS)
                            }
                        >
                            <AddIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                )}

                {schedule && (
                    <Tooltip title="Filter">
                        <IconButton onClick={props.clickFilter}>
                            <TuneIcon
                                fontSize="large"
                                color={props.filterOpen ? 'primary' : undefined}
                            />
                        </IconButton>
                    </Tooltip>
                )}
            </Container>

            <Container style={{ alignItems: 'center' }}>
                <span style={{ ...theme.typography.body2 }}>
                    {keyInCount} / {keysRequired}
                </span>
                <Tooltip title="Key Tracking">
                    <IconButton onClick={clickKeyTracking}>
                        <VpnKey />
                    </IconButton>
                </Tooltip>

                {schedule && (
                    <Tooltip title="Reports / Setup">
                        <IconButton onClick={openPopover}>
                            <FileCopyIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                )}

                {schedule && (
                    <Tooltip title="Schedule Analytics">
                        <IconButton
                            disabled={props.editMode}
                            onClick={props.clickAnalytics}
                            color={props.analyticsOpen ? 'primary' : 'default'}
                        >
                            <BarChartIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                )}

                {schedule && schedule?.active && (
                    <Tooltip title="Bulk Editor">
                        <IconButton
                            onClick={() => {
                                props.setEditMode(!props.editMode)
                            }}
                            disabled={
                                !hasPermission(workspaceUser, EDIT_WORKORDERS)
                            }
                            color={props.editMode ? 'primary' : 'default'}
                        >
                            <EditIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                )}

                {schedule && (
                    <Tooltip title="Pending Change Orders">
                        <IconButton
                            onClick={props.clickChangeOrder}
                            color={
                                filter.changeOrder ===
                                ChangeOrderFilterMode.HasPending
                                    ? 'primary'
                                    : 'default'
                            }
                        >
                            <Badge
                                badgeContent={pendingChangeOrderCount ?? 0}
                                color="secondary"
                            >
                                <Link fontSize="large" />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                )}

                {schedule && !schedule?.active && (
                    <Container style={{ alignItems: 'center' }}>
                        <Tooltip title="Exit">
                            <IconButton
                                onClick={() => {
                                    if (schedule) {
                                        const route =
                                            Routes.apartmentScheduleArchivedDetail
                                        history.push(
                                            resolveRoute(
                                                route,
                                                ':id',
                                                schedule.id,
                                            ),
                                        )
                                    }
                                }}
                            >
                                <CloseIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Container>
                )}
            </Container>

            <ScheduleReportPopOver
                open={popOverOpen}
                onClose={closePopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                reportLoading={reportLoading}
                reportItems={[
                    {
                        title: 'Excel Workorder Report',
                        downloadLink: {
                            title: 'Download',
                            onClick: () => {
                                setReportLoading(true)
                                axiosInstance
                                    .post(
                                        'stats/workorder-report/',
                                        {
                                            schedule: props.schedule?.id,
                                            workorder_list: props.workorderList?.map(
                                                (wo) => wo.id,
                                            ),
                                        },
                                        { responseType: 'blob' },
                                    )
                                    .then((response) => {
                                        FileDownload(
                                            new Blob([response.data]),
                                            `${props.schedule?.name}_workorder_report.xlsx`,
                                        )
                                    })
                                    .finally(() => {
                                        setReportLoading(false)
                                    })
                            },
                        },
                    },
                    {
                        title: 'PDF Workorder Report (w/ messages)',
                        downloadLink: {
                            title: 'Download',
                            onClick: () => {
                                setReportLoading(true)
                                axiosInstance
                                    .post('stats/workorder-report/', {
                                        schedule: props.schedule?.id,
                                        workorder_list: props.workorderList?.map(
                                            (wo) => wo.id,
                                        ),
                                        variant: 'workorder_pdf',
                                    })
                                    .then((response) => {
                                        toast.success(response.data)
                                    })
                                    .finally(() => {
                                        setReportLoading(false)
                                    })
                            },
                        },
                    },
                    {
                        title: 'Unit Report',
                        downloadLink: {
                            title: 'Download',
                            onClick: () => {
                                setReportLoading(true)
                                axiosInstance
                                    .post(
                                        'stats/workorder-report/',
                                        {
                                            schedule: props.schedule?.id,
                                            variant: 'unit',
                                            tz_offset: new Date().getTimezoneOffset(),
                                        },
                                        { responseType: 'blob' },
                                    )
                                    .then((response) => {
                                        FileDownload(
                                            new Blob([response.data]),
                                            `${props.schedule?.name}_unit_report.xlsx`,
                                        )
                                    })
                                    .finally(() => {
                                        setReportLoading(false)
                                    })
                            },
                        },
                    },
                ]}
            />
            {schedule && (
                <SchedulePreTurnChecklist
                    open={preTurnPopoverOpen}
                    schedule={schedule}
                    onClose={closePreTurnPopover}
                    anchorEl={preTurnAnchorEl}
                    anchorOrigin={{
                        horizontal: 'left',
                        vertical: 'bottom',
                    }}
                />
            )}
        </Container>
    )
}
