import {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useState,
} from 'react'
import { axiosInstance } from '../helpers'
import { toast } from 'react-toastify'

export interface IVendorInvoiceContext {
    invoiceCode: string
    setInvoiceCode: Dispatch<SetStateAction<string>>
    step: number
    setStep: Dispatch<SetStateAction<number>>
}

export const VendorInvoiceContext = createContext<IVendorInvoiceContext>(
    (null as any) as IVendorInvoiceContext,
)

export const useVendorInvoiceState = (): IVendorInvoiceContext => {
    const [invoiceCode, setInvoiceCode] = useState<string>('')
    const [step, setStep] = useState(INVOICE_CODE_STEP)

    return {
        invoiceCode,
        step,
        setInvoiceCode,
        setStep,
    }
}

export const useVendorInvoice = () => {
    const ctx = useContext(VendorInvoiceContext)

    const validateCode = async () => {
        try {
            const res = await axiosInstance.post(`invoice/validate_code/`, {
                invoice_code: ctx.invoiceCode,
            })
            const codeValid: boolean = res.data
            if (codeValid) {
                ctx.setStep(SEND_INVOICE_STEP)
            }
            return codeValid
        } catch (e) {
            return Promise.reject(e)
        }
    }

    const sendInvoice = async (request: SendInvoiceRequest) => {
        try {
            const res = await axiosInstance.post('invoice/', request)
            const badWorkorderCount: number = res.data.bad_wo_list.length
            if (badWorkorderCount > 0) {
                toast.error(
                    `${res.data.bad_wo_list.length} Work Orders did not have pricing configured`,
                )
            }
            return badWorkorderCount
        } catch (e) {
            return Promise.reject(e)
        }
    }

    return { ...ctx, validateCode, sendInvoice }
}

export const INVOICE_CODE_STEP = 1
export const SEND_INVOICE_STEP = 2

export interface SendInvoiceRequest {
    apartment: number
    workorders: number[]
    invoice_code: string
}
