import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Drawer, TextField, useTheme } from '@material-ui/core'
import SkillcatLogo from '../../assets/SkillCatLogo_small.png'
import { Container } from '../Container'
import { axiosInstance } from '../../helpers'
import { RFPContext, useRFPState } from '../../contexts'
import { OnboardApartmentModal } from './OnboardApartmentModal'
import { CopyConfigsModal } from './CopyConfigsModal'
import { ManagerRFPModal } from '../../containers/RFP/ManagerRFPModal'
import { UserModal } from './UserModal'
import { SkillcatUserList } from './SkillcatUserList'
import { useVideoAdvertisements } from '../../hooks/useVideoAdvertisements'
import { VideoAdvertisementModal } from '../../containers/VideoAdvertisement/VideoAdvertisementModal'
import { useCompany, useUser } from '../../hooks'
import { WorkspaceModal } from './WorkspaceModal/WorkspaceModal'
import { DRAWER_W } from '../../containers/ActionItem/contants'
import { SideDrawer } from '../SideDrawer'
import { MessageForm } from '../MessageDrawer'

interface Props {
    open: boolean
    onClose: () => void
}

export const AdminPanel = (props: Props) => {
    const [emailField, setEmailField] = useState('')
    const [passwordField, setPasswordField] = useState('')

    const theme = useTheme()

    const rfpState = useRFPState()

    const {
        videoAdvertisementList,
        selectedVideoAd,
        setSelectedVideoAd,
        getVideoAdList,
        createVideoAd,
        updateVideoAd,
    } = useVideoAdvertisements()

    const [onboardModalOpen, setOnboardModalOpen] = useState(false)
    const [copyConfigsModalOpen, setCopyConfigsModalOpen] = useState(false)
    const [rfpModalOpen, setRFPModalOpen] = useState(false)
    const [userModalOpen, setUserModalOpen] = useState(false)
    const [skillcatModalOpen, setSkillcatModalOpen] = useState(false)
    const [videoAdModalOpen, setVideoAdModalOpen] = useState(false)

    const [workspaceModalOpen, setWorkspaceModalOpen] = useState(false)

    const [openMessageDrawer, setOpenMessageDrawer] = useState(false)

    const { getApartmentList, apartmentList } = useCompany()
    const { workspaceUser } = useUser()

    useEffect(() => {
        getApartmentList({
            params: { all_apartments: true },
        })
    }, [])

    return (
        <Drawer
            variant="temporary"
            open={props.open}
            anchor="right"
            onClose={props.onClose}
        >
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    width: 250,
                    padding: 8,
                }}
            >
                <TextField
                    placeholder="Email"
                    value={emailField}
                    onChange={(e) => setEmailField(e.target.value)}
                    style={{ marginBottom: 8 }}
                />
                <TextField
                    placeholder="Password"
                    value={passwordField}
                    onChange={(e) => setPasswordField(e.target.value)}
                />

                <Button
                    onClick={() => {
                        axiosInstance
                            .post('user/admin-set-password/', {
                                email: emailField,
                                password: passwordField,
                            })
                            .then(() => {
                                toast.success(
                                    `${emailField} password updated`,
                                    { position: 'bottom-center' },
                                )
                                setEmailField('')
                                setPasswordField('')
                            })
                            .catch((e) => {
                                toast.error(`${e}`, {
                                    position: 'bottom-center',
                                })
                            })
                    }}
                    style={{
                        marginTop: 8,
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                    }}
                >
                    Change Password
                </Button>

                <Button
                    onClick={() => {
                        setOnboardModalOpen(true)
                    }}
                    style={{
                        marginTop: 8,
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                    }}
                >
                    Onboard Apartment
                </Button>
                <Button
                    onClick={() => {
                        setCopyConfigsModalOpen(true)
                    }}
                    style={{
                        marginTop: 8,
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                    }}
                >
                    Copy Configs
                </Button>
                <Button
                    onClick={() => {
                        setRFPModalOpen(true)
                    }}
                    style={{
                        marginTop: 8,
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                    }}
                >
                    Published RFPs
                </Button>
                <Button
                    onClick={() => {
                        setUserModalOpen(true)
                    }}
                    style={{
                        marginTop: 8,
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                    }}
                >
                    Search By User
                </Button>
                <Button
                    onClick={() => {
                        setSkillcatModalOpen(true)
                    }}
                    style={{
                        marginTop: 8,
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                    }}
                >
                    <img src={SkillcatLogo} />
                </Button>

                <Button
                    onClick={() => {
                        setWorkspaceModalOpen(true)
                    }}
                    style={{
                        marginTop: 8,
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                    }}
                >
                    Workspace Manager
                </Button>

                <Button
                    onClick={() => {
                        getVideoAdList()
                        setVideoAdModalOpen(true)
                    }}
                    style={{
                        marginTop: 8,
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                    }}
                >
                    Video Advertisements
                </Button>

                <Button
                    onClick={() => {
                        setOpenMessageDrawer(true)
                    }}
                    style={{
                        marginTop: 8,
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                    }}
                >
                    Account Manager Channel
                </Button>
            </Container>

            <OnboardApartmentModal
                open={onboardModalOpen}
                onClose={() => setOnboardModalOpen(false)}
            />
            <CopyConfigsModal
                open={copyConfigsModalOpen}
                onClose={() => setCopyConfigsModalOpen(false)}
            />
            <RFPContext.Provider value={rfpState}>
                <ManagerRFPModal
                    open={rfpModalOpen}
                    handleClose={() => setRFPModalOpen(false)}
                />
            </RFPContext.Provider>

            <UserModal
                theme={theme}
                open={userModalOpen}
                onClose={() => {
                    setUserModalOpen(false)
                }}
            />

            <SkillcatUserList
                theme={theme}
                open={skillcatModalOpen}
                onClose={() => {
                    setSkillcatModalOpen(false)
                }}
            />

            <WorkspaceModal
                open={workspaceModalOpen}
                theme={theme}
                onClose={() => setWorkspaceModalOpen(false)}
            />

            <VideoAdvertisementModal
                open={videoAdModalOpen}
                videoAdvertisementList={videoAdvertisementList}
                selectedVideoAd={selectedVideoAd}
                apartmentList={apartmentList}
                setSelectedVideoAd={setSelectedVideoAd}
                createVideoAdvertisement={createVideoAd}
                handleClose={() => setVideoAdModalOpen(false)}
                updateVideoAd={updateVideoAd}
            />

            <SideDrawer
                open={openMessageDrawer}
                width={DRAWER_W}
                handleClose={() => setOpenMessageDrawer(false)}
            >
                <MessageForm
                    theme={theme}
                    channelId={
                        workspaceUser?.active_workspace
                            .account_manager_channel ?? undefined
                    }
                />
            </SideDrawer>
        </Drawer>
    )
}
