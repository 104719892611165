import React, { useState, useMemo } from 'react'
import Plot from 'react-plotly.js'
import { Container, Selector } from '../../../components'
import {
    BaseWorkorder,
    ChangeOrder,
    ChangeOrderStatus,
    IdentifiableNamedObject,
    Schedule,
} from '../../../models'
import { getTotalPriceExpense } from '../../../models/WorkOrder/services'
import { toMMDDYYYY } from '../../../helpers'
import { BaseProps } from '../../TemplateScheduleGantt/types'

interface Props extends BaseProps {
    workorders: BaseWorkorder[]
    scheduleDetail: Schedule | undefined
}

const generateDataForPlot = (workorders: BaseWorkorder[], today: Date) => {
    const amountMap: { [dateStr: string]: number } = {}
    const invoicedMap: { [dateStr: string]: number } = {}
    const workorderDateMap: { [dateStr: string]: number } = {}
    let totalChangeOrderCost = 0
    let totalChangeOrderCount = 0

    workorders.forEach((wo) => {
        const date = wo.end_date ? new Date(wo.end_date) : today
        const dateStr = toMMDDYYYY(date)

        amountMap[dateStr] =
            (amountMap[dateStr] || 0) + getTotalPriceExpense(wo)

        if (wo.invoiced_price !== undefined && wo.invoiced_price !== null) {
            invoicedMap[dateStr] =
                (invoicedMap[dateStr] || 0) + wo.invoiced_price
        }

        if (date >= today) {
            workorderDateMap[dateStr] = (workorderDateMap[dateStr] || 0) + 1
        }

        wo.changeorder_set?.forEach((co: ChangeOrder) => {
            if (co.status === ChangeOrderStatus.APPROVED) {
                totalChangeOrderCost += co.price
                totalChangeOrderCount++
            }
        })
    })

    const sortedDates = Object.keys(amountMap).sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime(),
    )

    const forecastedX: string[] = []
    const forecastedY: number[] = []
    const invoicedX: string[] = []
    const invoicedY: number[] = []
    let cumulativeAmount = 0
    let cumulativeInvoiced = 0

    sortedDates.forEach((dateStr) => {
        cumulativeAmount += amountMap[dateStr]
        forecastedX.push(dateStr)
        forecastedY.push(cumulativeAmount)

        if (invoicedMap[dateStr]) {
            cumulativeInvoiced += invoicedMap[dateStr]
            invoicedX.push(dateStr)
            invoicedY.push(cumulativeInvoiced)
        }
    })

    return {
        forecastedX,
        forecastedY,
        invoicedX,
        invoicedY,
        workorderDateMap,
        totalChangeOrderCost,
        totalChangeOrderCount,
    }
}

export const BudgetPlot = ({
    theme,
    scheduleController,
    workorders,
}: Props) => {
    const [selectedServiceId, setSelectedServiceId] = useState<number>(0)

    const filteredWorkorders = useMemo(
        () =>
            selectedServiceId
                ? workorders.filter((wo) => wo.service_id === selectedServiceId)
                : workorders,
        [workorders, selectedServiceId],
    )

    const totalServiceBudget = useMemo(
        () =>
            scheduleController.schedule?.template_services?.reduce(
                (total, service) => total + Number(service.budget),
                0,
            ) || 0,
        [scheduleController.schedule?.template_services],
    )

    const templateServiceList = useMemo(
        () => [
            { id: 0, name: 'All', budget: totalServiceBudget },
            ...(scheduleController.schedule?.template_services?.map((ts) => ({
                id: ts.service.id,
                name: ts.service.name,
            })) || []),
        ],
        [scheduleController.schedule?.template_services, totalServiceBudget],
    )

    const selectedServiceBudget = useMemo(
        () =>
            selectedServiceId === 0
                ? totalServiceBudget
                : scheduleController.schedule?.template_services.find(
                      (service) => service.service.id === selectedServiceId,
                  )?.budget || 0,
        [
            selectedServiceId,
            totalServiceBudget,
            scheduleController.schedule?.template_services,
        ],
    )

    const today = new Date()
    today.setHours(23, 59, 59, 999)

    const {
        forecastedX,
        forecastedY,
        invoicedX,
        invoicedY,
        workorderDateMap,
        totalChangeOrderCost,
        totalChangeOrderCount,
    } = useMemo(() => generateDataForPlot(filteredWorkorders, today), [
        filteredWorkorders,
        today,
    ])

    const potentialWorkordersCount = filteredWorkorders.filter(
        (wo) => wo.end_date && new Date(wo.end_date) <= today,
    ).length

    const weightedCostChangeOrder =
        potentialWorkordersCount > 0
            ? (totalChangeOrderCount / potentialWorkordersCount) *
              totalChangeOrderCost
            : 0

    const { predictionX, predictionY } = useMemo(() => {
        let cumulativePrediction = 0
        const x: string[] = []
        const y: number[] = []

        forecastedX.forEach((date, index) => {
            if (new Date(date) > today) {
                const remainingWorkorders = workorderDateMap[date] || 0
                const predictedAmount =
                    weightedCostChangeOrder * remainingWorkorders
                cumulativePrediction += predictedAmount
                x.push(date)
                y.push(forecastedY[index] + cumulativePrediction)
            }
        })

        return { predictionX: x, predictionY: y }
    }, [
        forecastedX,
        forecastedY,
        today,
        workorderDateMap,
        weightedCostChangeOrder,
    ])

    const numXTicks = Math.floor(Math.max(10, forecastedX.length / 4))

    return (
        <Container
            style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
        >
            <Container
                style={{
                    marginTop: theme.spacing(3),
                    marginRight: theme.spacing(9),
                    marginLeft: theme.spacing(5),
                    maxWidth: 250,
                }}
            >
                <Selector
                    label="Service Filter"
                    currentValue={selectedServiceId}
                    data={templateServiceList}
                    getDisplayString={(s: IdentifiableNamedObject) => s.name}
                    onChange={(
                        event: React.ChangeEvent<{ value: unknown }>,
                    ) => {
                        setSelectedServiceId(event.target.value as number)
                    }}
                    customStyle={{ formControl: { flex: 1 } }}
                />
            </Container>

            <Plot
                data={[
                    {
                        line: { color: 'blue', width: 4 },
                        mode: 'lines',
                        name: 'Forecast',
                        x: forecastedX,
                        y: forecastedY,
                        hovertemplate: '%{x}<br>$%{y:,}<extra></extra>',
                    },
                    {
                        line: { color: 'red', width: 4, dash: 'dash' },
                        mode: 'lines',
                        name: 'Budget',
                        x: forecastedX,
                        y: Array(forecastedX.length).fill(
                            selectedServiceBudget,
                        ),
                        hovertemplate: '%{x}<br>$%{y:,}<extra></extra>',
                    },
                    {
                        line: { color: '#e3c710', width: 4 },
                        mode: 'lines',
                        name: 'Invoiced',
                        x: invoicedX,
                        y: invoicedY,
                        hovertemplate: '%{x}<br>$%{y:,}<extra></extra>',
                    },
                    {
                        line: { color: 'green', width: 4 },
                        mode: 'lines',
                        name: 'Prediction',
                        x: predictionX,
                        y: predictionY,
                        hovertemplate: '%{x}<br>$%{y:,}<extra></extra>',
                    },
                ]}
                layout={{
                    yaxis: {
                        tickfont: { size: 14 },
                        range: [
                            0,
                            Math.max(
                                ...forecastedY,
                                selectedServiceBudget,
                                ...invoicedY,
                                ...predictionY,
                            ) * 1.1,
                        ],
                    },
                    xaxis: {
                        tickfont: { size: 14 },
                        automargin: true,
                        nticks: numXTicks,
                        tickangle: 30,
                    },
                    legend: {
                        orientation: 'h',
                        yanchor: 'top',
                        y: 1.1,
                        xanchor: 'center',
                        x: 0.5,
                        font: {
                            family: theme.typography.h1.fontFamily,
                            size: 16,
                            color: theme.typography.h1.color,
                        },
                    },
                    hoverlabel: {
                        font: {
                            family: theme.typography.h1.fontFamily,
                            size: 18,
                            color: theme.palette.grey[200],
                        },
                    },
                }}
            />
        </Container>
    )
}
