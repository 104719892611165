import React from 'react'

import { Theme, Tooltip, IconButton } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'

import { Container } from '../../../../components'

interface Props {
    theme: Theme
    complete: boolean
    onClick: () => void
}

export const DeadlineStatusButton = (props: Props) => {
    const { theme, onClick, complete } = props

    let label = 'Incomplete'
    let Icon = RadioButtonUncheckedIcon
    let tooltip = 'Mark Complete'
    if (complete) {
        tooltip = 'Mark Incomplete'
        label = 'Complete'
        Icon = CheckCircleOutlineIcon
    }

    return (
        <Container style={{ alignItems: 'center' }}>
            <Tooltip title={tooltip}>
                <IconButton onClick={onClick}>
                    <Icon />
                </IconButton>
            </Tooltip>
            <span
                style={{
                    ...theme.typography.body1,
                    fontWeight: theme.typography.fontWeightBold,
                    color: theme.palette.grey[800],
                }}
            >
                {label}
            </span>
        </Container>
    )
}
