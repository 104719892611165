import { BaseFolder, BaseWorkorder, Service, _Workorder } from '../../models'
import { Action } from 'redux'
import { AxiosResponse } from 'axios'
import {
    DateFilters,
    WorkorderStatusFilters,
} from '../../containers/VendorScheduler/types'
import { UpdateWorkorderAction } from '..'
import {
    ClearWorkorderUnreadCountAction,
    WorkorderResponse,
} from '../workorder'

// Requests
export interface GetJobsiteRequest {
    params?: {
        lte_date?: string
        gte_date?: string
        service?: number
        wo_status?: WorkorderStatusFilters
        priority?: boolean
        location?: number
        sub_wo_status?: WorkorderStatusFilters
    }
}

export interface CreateSubWorkorderRequest {
    body: {
        workorder: number
        user: number
        expense?: number
    }
}

export interface UpdateSubWorkorderRequest {
    params: {
        id: number
    }
    body: {
        user?: number
        status?: WorkorderStatusFilters
        expense?: number
    }
}

// Responses

export interface JobsiteServiceResponse extends Service {
    count: number
}

export interface JobsiteLocationResponse extends BaseFolder {
    count: number
}

// Actions
export const GET_JOBSITE_SERVICE_LIST_REQUEST =
    'GET_JOBSITE_SERVICE_LIST_REQUEST'
export const GET_JOBSITE_SERVICE_LIST_RESPONSE =
    'GET_JOBSITE_SERVICE_LIST_RESPONSE'
export const GET_JOBSITE_LOCATION_LIST_REQUEST =
    'GET_JOBSITE_LOCATION_LIST_REQUEST'
export const GET_JOBSITE_LOCATION_LIST_RESPONSE =
    'GET_JOBSITE_LOCATION_LIST_RESPONSE'
export const SET_DATE_FILTER = 'SET_DATE_FILTER'
export const SET_JOBSITE_SERVICE = 'SET_JOBSITE_SERVICE'
export const SET_WORKORDER_STATUS_FILTER = 'SET_WORKORDER_STATUS_FILTER'
export const SET_PRIORITY_FILTER = 'SET_PRIORITY_FILTER'
export const SET_JOBSITE_LOCATION = 'SET_JOBSITE_LOCATION'
export const GET_JOBSITE_WORKORDER_LIST_REQUEST =
    'GET_JOBSITE_WORKORDER_LIST_REQUEST'
export const GET_JOBSITE_WORKORDER_LIST_RESPONSE =
    'GET_JOBSITE_WORKORDER_LIST_RESPONSE'
export const CREATE_SUB_WORKORDER_REQUEST = 'CREATE_SUB_WORKORDER_REQUEST'
export const UPDATE_SUB_WORKORDER_REQUEST = 'UPDATE_SUB_WORKORDER_REQUEST'

export const SET_LOADING = 'SET_LOADING'

export type JobsiteRequest =
    | typeof GET_JOBSITE_LOCATION_LIST_REQUEST
    | typeof GET_JOBSITE_LOCATION_LIST_REQUEST
    | typeof CREATE_SUB_WORKORDER_REQUEST
    | typeof UPDATE_SUB_WORKORDER_REQUEST

interface GetJobsiteServiceListRequestAction extends Action {
    type: typeof GET_JOBSITE_SERVICE_LIST_REQUEST
}

interface GetJobsiteServiceListResponseAction extends Action {
    type: typeof GET_JOBSITE_SERVICE_LIST_RESPONSE
    payload: JobsiteServiceResponse[]
}

interface GetJobsiteLocationListRequestAction extends Action {
    type: typeof GET_JOBSITE_LOCATION_LIST_REQUEST
}

interface GetJobsiteLocationListResponseAction extends Action {
    type: typeof GET_JOBSITE_LOCATION_LIST_RESPONSE
    payload: JobsiteLocationResponse[]
}

interface GetJobsiteWorkorderListRequestAction extends Action {
    type: typeof GET_JOBSITE_WORKORDER_LIST_REQUEST
}

interface GetJobsiteWokrorderListResponseAction extends Action {
    type: typeof GET_JOBSITE_WORKORDER_LIST_RESPONSE
    payload: WorkorderResponse[]
}

interface SetDateFilterAction extends Action {
    type: typeof SET_DATE_FILTER
    payload: DateFilters
}

interface SetJobsiteServiceAction extends Action {
    type: typeof SET_JOBSITE_SERVICE
    payload: JobsiteServiceResponse
}

interface SetWorkorderStatusFilterAction extends Action {
    type: typeof SET_WORKORDER_STATUS_FILTER
    payload: WorkorderStatusFilters
}

interface SetPriorityFilterAction {
    type: typeof SET_PRIORITY_FILTER
    payload: boolean
}

interface SetJobsiteLocationAction extends Action {
    type: typeof SET_JOBSITE_LOCATION
    payload: JobsiteLocationResponse
}

export interface CreateSubWorkorderRequestAction extends Action {
    type: typeof CREATE_SUB_WORKORDER_REQUEST
}

export interface UpdateSubWorkorderRequestAction extends Action {
    type: typeof UPDATE_SUB_WORKORDER_REQUEST
}

export interface SetLoadingAction extends Action {
    type: typeof SET_LOADING
    action?: JobsiteRequest
    loading: boolean
}

type GetJobsiteServiceListActionTypes =
    | GetJobsiteServiceListRequestAction
    | GetJobsiteServiceListResponseAction

type GetJobsiteLocationListActionTypes =
    | GetJobsiteLocationListRequestAction
    | GetJobsiteLocationListResponseAction

type GetJobsiteWorkorderListActionTypes =
    | GetJobsiteWorkorderListRequestAction
    | GetJobsiteWokrorderListResponseAction

type JobsiteFilterActionTypes =
    | SetDateFilterAction
    | SetWorkorderStatusFilterAction
    | SetPriorityFilterAction

export type JobsiteActionTypes =
    | GetJobsiteServiceListActionTypes
    | GetJobsiteLocationListActionTypes
    | SetJobsiteServiceAction
    | SetJobsiteLocationAction
    | JobsiteFilterActionTypes
    | GetJobsiteWorkorderListActionTypes
    | CreateSubWorkorderRequestAction
    | UpdateWorkorderAction
    | SetLoadingAction
    | UpdateSubWorkorderRequestAction
    | ClearWorkorderUnreadCountAction

// Thunks
export type GetJobsiteServiceListActionThunk = Promise<
    AxiosResponse<JobsiteServiceResponse[]>
>

export type GetJobsiteLocationListActionThunk = Promise<
    AxiosResponse<JobsiteLocationResponse[]>
>

export type GetJobsiteWorkorderListActionThunk = Promise<
    AxiosResponse<_Workorder[]>
>

export type JobsiteWorkorderActionThunk = Promise<AxiosResponse<_Workorder>>

// Reducers
export interface JobsiteState {
    serviceList?: JobsiteServiceResponse[]
    locationList?: JobsiteLocationResponse[]
    workorderList?: BaseWorkorder[]
    dateFilter: DateFilters
    priorityFilter: boolean
    workorderStatusFilter: WorkorderStatusFilters
    service?: JobsiteServiceResponse
    location?: JobsiteLocationResponse
    isLoading: {
        [GET_JOBSITE_SERVICE_LIST_REQUEST]: boolean
        [GET_JOBSITE_LOCATION_LIST_REQUEST]: boolean
        [GET_JOBSITE_WORKORDER_LIST_REQUEST]: boolean
        [CREATE_SUB_WORKORDER_REQUEST]: boolean
        [UPDATE_SUB_WORKORDER_REQUEST]: boolean
    }
}
