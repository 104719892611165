import { useState, useEffect } from 'react'
import { axiosInstance } from '../helpers'
import { UpstreamServiceContract, ServiceContractMap } from '../models'

export const useUpstreamServiceContract = () => {
    const [
        upstreamServiceContractMap,
        setUpstreamServiceContractMap,
    ] = useState<ServiceContractMap>({})

    useEffect(() => {
        axiosInstance
            .get('service-contract/upstream-contract/')
            .then((response) => {
                const serviceContracts: UpstreamServiceContract[] =
                    response.data

                const newServiceContractMap: ServiceContractMap = {}

                serviceContracts.forEach((serviceContract) => {
                    if (
                        newServiceContractMap[serviceContract.service] ===
                        undefined
                    ) {
                        newServiceContractMap[serviceContract.service] = {
                            areaConfig: {},
                            unitConfig: {},
                        }
                    }

                    if (serviceContract.area_config) {
                        newServiceContractMap[
                            serviceContract.service
                        ].areaConfig[serviceContract.area_config] =
                            serviceContract.price ?? 0
                    } else if (serviceContract.unit_config) {
                        newServiceContractMap[
                            serviceContract.service
                        ].unitConfig[serviceContract.unit_config] =
                            serviceContract.price ?? 0
                    }
                }, {})

                setUpstreamServiceContractMap(newServiceContractMap)
            })
    }, [])

    return upstreamServiceContractMap
}
