import { AxiosResponse } from 'axios'
import { Action } from 'redux'
import { Invoice } from '../../models'

// Requests
export interface InvoiceAxiosRequest {
    params?: {}
}

// actions
export const GET_INVOICE_LIST_REQUEST = 'GET_INVOICE_LIST_REQUEST'
export const SET_INVOICE_LIST = 'SET_INVOICE_LIST'
export const UPDATE_INVOICE = 'UPDATE_INVOICE'
export const SET_INVOICE_LOADING = 'SET_INVOICE_LOADING'

export type InvoiceRequest = typeof GET_INVOICE_LIST_REQUEST

interface SetInvoiceLoadingAction extends Action {
    type: typeof SET_INVOICE_LOADING
    request: InvoiceRequest
    isLoading: boolean
}

interface SetInvoiceListAction extends Action {
    type: typeof SET_INVOICE_LIST
    invoiceList?: Invoice[]
}

interface UpdateInvoiceAction extends Action {
    type: typeof UPDATE_INVOICE
    invoice: Invoice
}

export type InvoiceActionTypes =
    | SetInvoiceLoadingAction
    | SetInvoiceListAction
    | UpdateInvoiceAction

// thunks
export type InvoiceListThunk = Promise<AxiosResponse<Invoice[]>>

// reducers
export interface InvoiceState {
    invoiceList?: Invoice[]
    isLoading: {
        [GET_INVOICE_LIST_REQUEST]: boolean
    }
}
