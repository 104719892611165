import { useTheme, Tooltip, Button } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { Container, SideDrawer } from '../../components'
import { IdBoolMap, ModelMap, convertMapToList } from '../../models'
import { WorkorderResponse } from '../../store'
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation'
import { useAnimationQueue } from '../../hooks'
import { RemoveListItem } from '../../animations'

interface Props {
    open: boolean
    selectedWorkorderMap: ModelMap<WorkorderResponse>
    setSelectedWorkordersMap: (map: ModelMap<WorkorderResponse>) => void
    onClose: () => void
    setOpenEZNowModal: (open: boolean) => void
}

export const EZNowSelectionDrawer = (props: Props) => {
    const {
        open,
        onClose,
        selectedWorkorderMap,
        setSelectedWorkordersMap,
        setOpenEZNowModal,
    } = props

    const [serviceMap, setServiceMap] = useState<IdBoolMap>({})

    const theme = useTheme()

    const { enqueueAnimation, currentAnimation } = useAnimationQueue()

    const workorders = open ? convertMapToList(selectedWorkorderMap) : []

    useEffect(() => {
        const tempServiceMap: IdBoolMap = {}
        Object.keys(selectedWorkorderMap).map((key) => {
            const workorder = selectedWorkorderMap[Number(key)]
            if (workorder && workorder.service_id) {
                tempServiceMap[workorder.service_id] = true
            }
        })
        setServiceMap(tempServiceMap)
    }, [selectedWorkorderMap])

    const removeWorkorder = (id: number) => {
        const newEditSelection = {
            ...selectedWorkorderMap,
        }

        if (newEditSelection[id] !== undefined) {
            delete newEditSelection[id]
        }

        setSelectedWorkordersMap(newEditSelection)
    }

    const checkWorkorderAssignments = (workorders: WorkorderResponse[]) => {
        let valid = true
        workorders.forEach((workorder) => {
            if (workorder.vendor_id) {
                valid = false
            }
        })
        return valid
    }

    const invalidWorkorderAssignment = !checkWorkorderAssignments(workorders)

    return (
        <SideDrawer
            open={open}
            handleClose={onClose}
            width={420}
            title="EZNow Selection"
            headerRight={
                <Button
                    size="small"
                    variant="contained"
                    style={{
                        marginLeft: theme.spacing(2),
                        backgroundColor: 'white',
                        color: theme.palette.primary.dark,
                        textTransform: 'none',
                        cursor: 'pointer',
                        marginRight: theme.spacing(2),
                    }}
                    onClick={() => setSelectedWorkordersMap({})}
                >
                    {`Remove All (${workorders.length})`}
                </Button>
            }
        >
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    overflowX: 'hidden',
                    padding: theme.spacing(2),
                }}
                scrollY
            >
                <Container
                    style={{
                        ...theme.typography.h6,
                    }}
                >
                    ({workorders.length}) Action Items Selected
                </Container>
                <Container
                    style={{
                        ...theme.typography.h6,
                    }}
                >
                    ({Object.keys(serviceMap).length}) Services
                </Container>
                {invalidWorkorderAssignment && (
                    <Container
                        style={{
                            ...theme.typography.body2,
                            color: 'red',
                        }}
                    >
                        There are action items selected that have already been
                        assigned to a vendor. Remove those workorders to
                        continue
                    </Container>
                )}
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        overflowX: 'hidden',
                    }}
                    scrollY
                >
                    {workorders.map((wo, idx) => {
                        return (
                            <WorkorderSelectionRow
                                key={`EDIT_WORKORDER_${wo.id}`}
                                workorder={wo}
                                animationClassName={
                                    currentAnimation?.effectMap[wo.id]
                                        ?.animationCssClass
                                }
                                animationStyle={{
                                    ...currentAnimation?.effectMap[wo.id]
                                        ?.animationStyles,
                                }}
                                removeWorkorder={(id) => {
                                    enqueueAnimation(
                                        RemoveListItem(
                                            250,
                                            wo,
                                            workorders.slice(idx + 1),
                                            ROW_HEIGHT +
                                                theme.spacing(ROW_MARGIN) * 2,
                                            () => {
                                                removeWorkorder(id)
                                            },
                                        ),
                                    )
                                }}
                            />
                        )
                    })}
                </Container>
                <Container
                    style={{
                        justifyContent: 'flex-end',
                        marginTop: theme.spacing(2),
                    }}
                >
                    <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        style={{
                            textTransform: 'none',
                            marginRight: theme.spacing(2),
                        }}
                        onClick={() => onClose()}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        style={{
                            textTransform: 'none',
                            backgroundColor: theme.palette.primary.dark,
                            color: 'white',
                        }}
                        disabled={invalidWorkorderAssignment}
                        onClick={() => setOpenEZNowModal(true)}
                    >
                        Next
                    </Button>
                </Container>
            </Container>
        </SideDrawer>
    )
}

interface RowProps {
    workorder: WorkorderResponse
    animationClassName?: string
    animationStyle?: React.CSSProperties
    removeWorkorder?: (id: number) => void
}

const WorkorderSelectionRow = (props: RowProps) => {
    const {
        workorder,
        removeWorkorder,
        animationClassName,
        animationStyle,
    } = props

    const theme = useTheme()

    const displayRemoveButton = removeWorkorder !== undefined

    return (
        <Container
            style={{
                minHeight: ROW_HEIGHT,
                maxHeight: ROW_HEIGHT,
                margin: theme.spacing(ROW_MARGIN),
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.grey[100],
                boxSizing: 'border-box',
                ...animationStyle,
            }}
            className={animationClassName}
        >
            <Container
                style={{
                    padding: theme.spacing(1),
                    flex: 1,
                    borderTopLeftRadius: theme.shape.borderRadius,
                    borderBottomLeftRadius: theme.shape.borderRadius,
                    borderTopRightRadius: !displayRemoveButton
                        ? theme.shape.borderRadius
                        : '',
                    borderBottomRightRadius: !displayRemoveButton
                        ? theme.shape.borderRadius
                        : '',
                    border: `1px solid ${theme.palette.grey[400]}`,
                }}
            >
                <Container style={{ flexDirection: 'column' }}>
                    {/* Row 1 */}
                    <Container direction="column">
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {workorder.title ?? ''}
                        </span>

                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {workorder.service_name ?? ''}
                        </span>
                    </Container>

                    {/* Row 2 */}
                    <span style={{ ...theme.typography.subtitle2 }}>
                        {workorder.vendor_name}
                    </span>

                    <div style={{ flex: 1 }} />

                    {/* Row 3 */}
                    <Container>
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {workorder.path ?? ''}
                        </span>
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {workorder.folder_name ?? ''}
                            {workorder.folder_name && '/'}
                        </span>
                        <span style={{ marginLeft: theme.spacing(1) }}>
                            {workorder.unit_name ?? ''}
                        </span>
                    </Container>
                </Container>
            </Container>
            {displayRemoveButton && (
                <Container
                    style={{
                        padding: theme.spacing(1),
                        borderTopRightRadius: theme.shape.borderRadius,
                        borderBottomRightRadius: theme.shape.borderRadius,
                        backgroundColor: theme.palette.secondary.light,
                        alignItems: 'flex-end',
                        flexDirection: 'column',
                        boxShadow: `inset 2px 0px 5px ${theme.palette.secondary.dark}`,
                        cursor: 'pointer',
                    }}
                    onClick={() => removeWorkorder(workorder.id)}
                >
                    <div style={{ flex: 1 }} />
                    <div>
                        <Tooltip title="Remove from selection">
                            <CancelPresentationIcon fontSize="large" />
                        </Tooltip>
                    </div>

                    <div style={{ flex: 1 }} />
                </Container>
            )}
        </Container>
    )
}

const ROW_HEIGHT = 110
const ROW_MARGIN = 1
