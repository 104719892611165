import React, { useState, useEffect } from 'react'

import { Button, Divider, Modal, Slide, useTheme } from '@material-ui/core'
import { Container, CollapsableSelector } from '../../../../components'
import {
    useAppDispatch,
    useSelectVendorService,
    useUser,
    useWindowSize,
} from '../../../../hooks'
import {
    Area,
    AreaConfig,
    BaseWorkorder,
    ChangeOrder,
    ChangeOrderConfig,
    getChangeOrderColor,
    ListVendor,
    ModelMap,
    Service,
    ChangeOrderStatus,
    createWorkorderFromResponse,
    Schedule,
    ChangeOrderDetail,
    isWorkorderSafe,
} from '../../../../models'

import { ChangeOrderDetailPanel } from './ChangeOrderDetailPanel'

import { axiosInstance } from '../../../../helpers'

import { Range } from 'react-date-range'
import { toast } from 'react-toastify'
import { updateChangeOrderStatus } from '../../../../store'
import { BlankPanel } from './BlankPanel'
import { ChangeOrderViewWorkorderPanel } from './ChangeOrderViewWorkorderPanel'
import { ChangeOrderEditWorkorderPanel } from './ChangeOrderEditWorkorderPanel'

interface Props {
    areaConfigMap: ModelMap<AreaConfig>
    workorder?: BaseWorkorder
    changeOrderConfigList?: ChangeOrderConfig[]
    vendorList: ListVendor[]
    serviceList: Service[]
    schedule?: Schedule
    onClose: () => void
}

const NO_ID_SELECTED = -1
export const CO_WO_RANGE_KEY = 'CO_WO_RANGE_KEY'

export interface ServiceAreaChangeState {
    [areaId: number]: { initial: boolean; current: boolean; label: string }
}

const getServiceAreaListFromState = (state: ServiceAreaChangeState) => {
    return Object.keys(state).reduce<number[]>((prev, strKey) => {
        const areaId = Number(strKey)

        if (state[areaId].current) {
            prev.push(areaId)
        }

        return prev
    }, [])
}

export const ChangeOrderModal = (props: Props) => {
    const {
        workorder,
        onClose,
        changeOrderConfigList,
        serviceList,
        vendorList,
        schedule,
    } = props

    const theme = useTheme()

    const dispatch = useAppDispatch()

    const open = workorder !== undefined

    const { height, width } = useWindowSize()

    const { workspaceUser } = useUser()

    const [changeOrderId, setChangeOrderId] = useState(NO_ID_SELECTED)

    const [
        updateWorkorderServiceAreaState,
        setUpdateWorkorderServiceAreaState,
    ] = useState<ServiceAreaChangeState>({})

    const [changeOrderDetail, setChangeOrderDetail] = useState<
        ChangeOrderDetail | undefined
    >()

    // Create new workorder state
    const [shouldValidateForm, setShouldValidateForm] = useState(false)

    const [
        newWorkorderServiceAreaState,
        setNewWorkorderServiceAreaState,
    ] = useState<ServiceAreaChangeState>({})

    const {
        selectService,
        selectVendor,
        selectedServiceId,
        selectedVendorId,
        filteredVendorList,
    } = useSelectVendorService(vendorList)

    const [newWorkorderDateRange, setNewWorkorderDateRange] = useState<Range>({
        startDate: new Date(),
        endDate: new Date(),
        color: theme.palette.primary.main,
        key: CO_WO_RANGE_KEY,
    })

    const modalHeight = Math.max(MIN_MODAL_HEIGHT, height ?? 0)
    const modalWidth = Math.max(MIN_MODAL_WIDTH, width ?? 0)

    const titleStyle: React.CSSProperties = {
        ...theme.typography.h5,
        fontWeight: 'bold',
        color: theme.palette.grey[700],
    }

    const selectedChangeOrder = workorder?.changeorder_set?.find(
        (changeOrder) => changeOrder.id === changeOrderId,
    )
    const selectedConfig = changeOrderConfigList?.find(
        (config) => config.id === selectedChangeOrder?.change_order_config,
    )

    const disableForm =
        selectedChangeOrder === undefined ||
        selectedChangeOrder.status !== ChangeOrderStatus.PENDING

    const getErrorMessage = () => {
        if (selectedChangeOrder?.status === ChangeOrderStatus.PENDING) {
            if (!selectedConfig?.addon) {
                // The user is able to create and edit a workorder at the same time
                // If the user has selected a service & vendor make sure that there is at least 1 service area in the resulting workorders

                const updateServiceAreas = getServiceAreaListFromState(
                    updateWorkorderServiceAreaState,
                )

                // If the users selections will not result in a new workorder creation
                // DO NOT include the selected service areas from the create workorder panel.
                if (!willCreateNewWorkorder()) {
                    if (updateServiceAreas.length === 0) {
                        return 'You cannot remove all of the service areas if you are not creating a new workorder.'
                    }
                    return ''
                }

                const newServiceAreas = getServiceAreaListFromState(
                    newWorkorderServiceAreaState,
                )

                if (newServiceAreas.length === 0) {
                    return 'Service areas required to create a new workorder'
                }
            }
        }

        return ''
    }

    const willCreateNewWorkorder = () => {
        return (
            selectedServiceId !== NO_ID_SELECTED &&
            selectedVendorId !== NO_ID_SELECTED
        )
    }

    const getConfirmationString = (status: ChangeOrderStatus) => {
        if (status === ChangeOrderStatus.DENIED) {
            return 'Confirm that you would like to deny the change order.'
        }

        if (selectedConfig?.addon) {
            return 'Confirm that you would like to approve the addon change order.'
        }

        // We are dealing with a change of scope.  Determine what the result of the users action will be
        // There are 5 possibilities
        //      Existing    New
        // 1.   Edit        Create
        // 2.   Edit        Ignore
        // 3.   Ignore      Create
        // 4.   Ignore      Ignore
        // 5.   Remove      Create

        const newServiceAreas = getServiceAreaListFromState(
            newWorkorderServiceAreaState,
        )

        let isEdit = false
        let isRemove = true
        Object.keys(updateWorkorderServiceAreaState).forEach((strKey) => {
            const areaId = Number(strKey)

            if (updateWorkorderServiceAreaState[areaId].current) {
                isRemove = false
            }

            if (
                updateWorkorderServiceAreaState[areaId].current !==
                updateWorkorderServiceAreaState[areaId].initial
            ) {
                isEdit = true
            }
        })

        if (willCreateNewWorkorder()) {
            // Handle options 1, 3, & 5
            if (isRemove) {
                let option5Msg =
                    'Confirm that you would like to approve the change order.\n'
                option5Msg += '- Remove current workorder\n'
                option5Msg += `- Replace with new workorder with ${newServiceAreas.length} service areas`
                return option5Msg
            }

            if (isEdit) {
                let option1Msg =
                    'Confirm that you would like to approve the change order.\n'
                option1Msg += '- Edit existing service areas\n'
                option1Msg += `- Create new workorder with ${newServiceAreas.length} service areas`
                return option1Msg
            }

            let option3Msg =
                'Confirm that you would like to approve the change order.\n'
            option3Msg += '- No change on the existing workorder\n'
            option3Msg += `- Create new workorder with ${newServiceAreas.length} service areas`
            return option3Msg
        }

        // Handle options 2 & 4
        if (isEdit) {
            let option2Msg =
                'Confirm that you would like to approve the change order.\n'
            option2Msg += '- Edit existing service areas\n'
            option2Msg += '- No new workorder'
            return option2Msg
        }

        let option4Msg =
            'Confirm that you would like to approve the change order.\n'
        option4Msg += '- No change on the existing workorder\n'
        option4Msg += '- No new workorder'
        return option4Msg
    }

    const approveDenyChangeOrder = (status: ChangeOrderStatus) => {
        if (workorder === undefined) {
            return
        }

        setShouldValidateForm(true)

        if (getErrorMessage() !== '') {
            return
        }

        if (confirm(getConfirmationString(status))) {
            const reqBody: any = {
                status: status,
            }
            if (status === ChangeOrderStatus.APPROVED) {
                const updateServiceAreas: number[] = []
                Object.keys(updateWorkorderServiceAreaState).forEach(
                    (strKey) => {
                        const areaId = Number(strKey)
                        if (updateWorkorderServiceAreaState[areaId].current) {
                            updateServiceAreas.push(areaId)
                        }
                    },
                )

                reqBody.update_workorder = {
                    service_areas: updateServiceAreas,
                }

                const serviceAreas: number[] = []
                Object.keys(newWorkorderServiceAreaState).forEach((strKey) => {
                    const areaId = Number(strKey)
                    if (newWorkorderServiceAreaState[areaId].current) {
                        serviceAreas.push(areaId)
                    }
                })

                reqBody.new_workorder = {
                    start_date: newWorkorderDateRange.startDate?.toISOString(),
                    end_date: newWorkorderDateRange.endDate?.toISOString(),
                    service: selectedServiceId,
                    vendor: selectedVendorId,
                    units: [
                        {
                            unit_id: workorder.unit_id,
                            service_areas: serviceAreas,
                        },
                    ],
                    schedule: schedule?.id,
                }
            }

            axiosInstance
                .post(
                    `workorder/change-order/${changeOrderId}/change_status/`,
                    reqBody,
                )
                .catch((error) => {
                    let message = ''
                    try {
                        message = error.response.data.message
                    } catch (e) {
                        message = 'An unknown error has occured'
                    }
                    toast.error(message)
                })
                .then((res) => {
                    if (res) {
                        const updatedWorkorder: BaseWorkorder = createWorkorderFromResponse(
                            res.data.updated_workorder,
                        )

                        let newWorkorder: BaseWorkorder | undefined
                        if (res.data.new_workorder) {
                            newWorkorder = createWorkorderFromResponse(
                                res.data.new_workorder,
                            )
                        }

                        dispatch(
                            updateChangeOrderStatus(
                                updatedWorkorder,
                                newWorkorder,
                            ),
                        )
                    }
                })
                .finally(() => onClose())
        }
    }

    useEffect(() => {
        if (workorder) {
            axiosInstance
                .get('infrastructure/area/', {
                    params: { unit: workorder.unit_id },
                })
                .then((res) => {
                    const areas = res.data as Area[]

                    const newSaChangeState: ServiceAreaChangeState = {}
                    const newNewWorkorderServiceAreaState: ServiceAreaChangeState = {}

                    areas.forEach((area) => {
                        newNewWorkorderServiceAreaState[area.id] = {
                            initial: false,
                            current: false,
                            label: `${
                                props.areaConfigMap[area.area_config]?.name
                            } (${area.area_label})`,
                        }
                        newSaChangeState[area.id] = {
                            initial: false,
                            current: false,
                            label: `${
                                props.areaConfigMap[area.area_config]?.name
                            } (${area.area_label})`,
                        }
                    })

                    workorder.service_area_list.forEach((sa) => {
                        newSaChangeState[sa.area_id].initial = true
                        newSaChangeState[sa.area_id].current = true
                    })

                    setUpdateWorkorderServiceAreaState(newSaChangeState)
                    setNewWorkorderServiceAreaState(
                        newNewWorkorderServiceAreaState,
                    )
                })
        }
    }, [workorder])

    useEffect(() => {
        if (selectedChangeOrder) {
            axiosInstance
                .get(`workorder/change-order/${changeOrderId}/`)
                .then((res) => {
                    setChangeOrderDetail(res.data)
                })
        } else {
            setChangeOrderDetail(undefined)
        }
    }, [changeOrderId])

    let leftPanel: JSX.Element = (
        <ChangeOrderViewWorkorderPanel
            workorder={props.workorder}
            serviceAreaState={updateWorkorderServiceAreaState}
        />
    )
    let rightPanel: JSX.Element = (
        <BlankPanel headLine="Select a change order to continue" />
    )

    if (selectedChangeOrder?.status === ChangeOrderStatus.APPROVED) {
        // - Left panel should display view only workorder details
        // - Right panel should display view only workorder details IF the selected change order
        //   has a child workorder
        // - Right panel should display a Blank panel otherwise

        rightPanel = (
            <BlankPanel
                headLine="Created Workorder"
                content="No workorder was created"
            />
        )
        if (changeOrderDetail?.child_workorder) {
            // Create a copy of the service area state.
            // This works because the workorder is for the same unit and all we need from this is the areas
            const childServiceAreaState: ServiceAreaChangeState = {
                // ...updateWorkorderServiceAreaState,
            }

            // Set all the areas equal to false
            Object.keys(updateWorkorderServiceAreaState).forEach((strKey) => {
                const areaId = Number(strKey)
                childServiceAreaState[areaId] = {
                    initial: false,
                    current: false,
                    label: updateWorkorderServiceAreaState[areaId].label,
                }
            })

            // Update the areas to true for each service area in the child workorder
            changeOrderDetail?.child_workorder.service_area_list.forEach(
                (sa) => {
                    childServiceAreaState[sa.area_id].initial = true
                    childServiceAreaState[sa.area_id].current = true
                },
            )

            rightPanel = (
                <ChangeOrderViewWorkorderPanel
                    workorder={createWorkorderFromResponse(
                        changeOrderDetail.child_workorder,
                    )}
                    serviceAreaState={childServiceAreaState}
                    headline="Created Workorder"
                />
            )
        }
    }

    if (selectedChangeOrder?.status === ChangeOrderStatus.DENIED) {
        // - Left panel should display view only workorder details
        // - Right panel should dispaly a blank panel
        rightPanel = (
            <BlankPanel
                headLine="Change Order Denied"
                content="Nothing to display"
            />
        )
    }

    if (selectedChangeOrder?.status === ChangeOrderStatus.PENDING) {
        // - Check the change order type
        if (selectedConfig?.addon) {
            // - Left panel should display view only workorder details
            // - Right panel should dispaly a blank panel
            rightPanel = (
                <BlankPanel
                    headLine="Change order is an addon"
                    content="Cannot add workorder to addon"
                />
            )
        }

        if (selectedConfig?.addon === false) {
            // - Left panel should display edit view for the change order workorder
            // - Right panel should display edit view for a new workorder

            if (workorder) {
                let workorderRange: Range = {}
                if (isWorkorderSafe(workorder)) {
                    workorderRange = {
                        startDate: workorder.startDate,
                        endDate: workorder.endDate,
                        key: 'EXISTING_WORKORDER',
                    }
                }

                leftPanel = (
                    <ChangeOrderEditWorkorderPanel
                        headLine="Current Workorder"
                        serviceAreaState={updateWorkorderServiceAreaState}
                        changeOrder={selectedChangeOrder}
                        changeOrderConfig={selectedConfig}
                        vendorList={vendorList}
                        serviceList={serviceList}
                        selectedServiceId={workorder?.service_id ?? -1}
                        selectedVendorId={workorder?.vendor_id ?? -1}
                        dateRange={workorderRange}
                        disableDate
                        disableService
                        disableVendor
                        onChangeRange={() => {}}
                        onSelectService={() => {}}
                        onSelectVendor={() => {}}
                        showInitialServiceAreas
                        onSelectArea={(areaId) => {
                            setUpdateWorkorderServiceAreaState({
                                ...updateWorkorderServiceAreaState,
                                [areaId]: {
                                    ...updateWorkorderServiceAreaState[areaId],
                                    current: !updateWorkorderServiceAreaState[
                                        areaId
                                    ].current,
                                },
                            })
                        }}
                    />
                )
            }

            let rightPanelHeadline = 'Create Workorder'
            if (!willCreateNewWorkorder()) {
                rightPanelHeadline += ' (Select a service and vendor to create)'
            }

            rightPanel = (
                <ChangeOrderEditWorkorderPanel
                    headLine={rightPanelHeadline}
                    serviceAreaState={newWorkorderServiceAreaState}
                    vendorList={filteredVendorList}
                    changeOrder={selectedChangeOrder}
                    changeOrderConfig={selectedConfig}
                    serviceList={serviceList}
                    selectedServiceId={selectedServiceId}
                    onSelectService={selectService}
                    selectedVendorId={selectedVendorId}
                    onSelectVendor={selectVendor}
                    dateRange={newWorkorderDateRange}
                    onChangeRange={setNewWorkorderDateRange}
                    onSelectArea={(areaId) => {
                        setNewWorkorderServiceAreaState({
                            ...newWorkorderServiceAreaState,
                            [areaId]: {
                                ...newWorkorderServiceAreaState[areaId],
                                current: !newWorkorderServiceAreaState[areaId]
                                    .current,
                            },
                        })
                    }}
                />
            )
        }
    }

    // console.log('marker', changeOrderDetail, workorder)

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'scroll',
            }}
        >
            <Slide in={open} direction="up">
                <Container
                    style={{
                        minHeight: modalHeight * 0.8,
                        maxHeight: modalHeight * 0.8,
                        minWidth: modalWidth * 0.8,
                        maxWidth: modalWidth * 0.8,
                        backgroundColor: 'white',
                        flexDirection: 'column',
                    }}
                    scrollY
                >
                    {/* Main Content */}
                    <Container style={{ flex: 1 }}>
                        {/* Choose Changeorder / Changeorder Detail Columns */}
                        <Container
                            style={{
                                marginBottom: theme.spacing(1),
                                flex: 1,
                                overflow: 'none',
                                padding: theme.spacing(1),
                                paddingBottom: 0,
                                flexDirection: 'column',
                                minWidth: 300,
                            }}
                        >
                            <span style={titleStyle}>Select Change Order</span>
                            <CollapsableSelector<ChangeOrder>
                                itemHeight={CHOOSE_CHANGE_ORDER_HEIGHT}
                                itemMargin={CHOOSE_CHANGE_ORDER_MARGIN}
                                data={workorder?.changeorder_set ?? []}
                                maxItems={6}
                                onClick={(changeOrder) =>
                                    setChangeOrderId(changeOrder.id)
                                }
                                customStyle={{
                                    itemStyle: {
                                        backgroundColor: 'white',
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                    },
                                    selectedItemStyle: {
                                        boxShadow: theme.shadows[3],
                                        backgroundColor:
                                            theme.palette.grey[300],
                                    },
                                    container: {
                                        overflowX: 'hidden',
                                    },
                                }}
                                keyGen={(changeOrder) =>
                                    `CHANGE_ORDER_${changeOrder.id}`
                                }
                                renderItem={(itemProps) => {
                                    const changeOrder = itemProps.item

                                    const changeOrderConfig = changeOrderConfigList?.find(
                                        (conf) =>
                                            conf.id ===
                                            changeOrder.change_order_config,
                                    )

                                    return (
                                        <Container
                                            {...itemProps}
                                            style={{
                                                ...itemProps.style,
                                                paddingLeft: theme.spacing(1),
                                                paddingRight: theme.spacing(1),
                                                flexDirection: 'column',
                                                minHeight:
                                                    CHOOSE_CHANGE_ORDER_HEIGHT -
                                                    CHOOSE_CHANGE_ORDER_MARGIN,
                                                maxHeight:
                                                    CHOOSE_CHANGE_ORDER_HEIGHT -
                                                    CHOOSE_CHANGE_ORDER_MARGIN,
                                            }}
                                            scrollY
                                        >
                                            <span
                                                style={{
                                                    ...theme.typography.h6,
                                                    fontWeight: 'bold',
                                                    color:
                                                        theme.palette.grey[900],
                                                }}
                                            >
                                                {changeOrderConfig?.name} (
                                                {changeOrder.quantity})
                                            </span>
                                            <div style={{ flex: 1 }} />

                                            <Container>
                                                <span
                                                    style={{
                                                        ...theme.typography
                                                            .body2,
                                                        color: getChangeOrderColor(
                                                            changeOrder.status,
                                                            theme,
                                                        ),
                                                    }}
                                                >
                                                    {changeOrder.status}
                                                </span>

                                                <div style={{ flex: 1 }} />

                                                <span
                                                    style={{
                                                        ...theme.typography
                                                            .body2,
                                                    }}
                                                >
                                                    {changeOrderConfig?.addon
                                                        ? 'Addon'
                                                        : 'Change Of Scope'}
                                                </span>
                                            </Container>
                                        </Container>
                                    )
                                }}
                                emptyDataItem={(style) => {
                                    return (
                                        <Container
                                            style={{
                                                ...style,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <span style={titleStyle}>
                                                No Change Orders
                                            </span>
                                        </Container>
                                    )
                                }}
                            />

                            {workorder && (
                                <ChangeOrderDetailPanel
                                    areaConfigMap={props.areaConfigMap}
                                    changeOrder={selectedChangeOrder}
                                    changeOrderConfig={selectedConfig}
                                    changeOrderDetail={changeOrderDetail}
                                    workspaceUser={workspaceUser}
                                />
                            )}
                        </Container>

                        {leftPanel}
                        {rightPanel}
                    </Container>

                    <Divider />
                    <Container style={{ margin: theme.spacing(1) }}>
                        <div style={{ flex: 1 }} />
                        {shouldValidateForm && (
                            <span
                                style={{
                                    ...titleStyle,
                                    color: 'red',
                                    marginRight: theme.spacing(1),
                                }}
                            >
                                {getErrorMessage()}
                            </span>
                        )}
                        <Button
                            variant="outlined"
                            disabled={
                                disableForm ||
                                (shouldValidateForm && getErrorMessage() !== '')
                            }
                            color="secondary"
                            style={{ marginRight: theme.spacing(2) }}
                            onClick={() => {
                                approveDenyChangeOrder(ChangeOrderStatus.DENIED)
                            }}
                        >
                            Deny
                        </Button>

                        <Button
                            variant="outlined"
                            disabled={
                                disableForm ||
                                (shouldValidateForm && getErrorMessage() !== '')
                            }
                            color="primary"
                            onClick={() => {
                                approveDenyChangeOrder(
                                    ChangeOrderStatus.APPROVED,
                                )
                            }}
                        >
                            Approve
                        </Button>
                    </Container>

                    {/* Footer */}
                </Container>
            </Slide>
        </Modal>
    )
}

const MIN_MODAL_HEIGHT = 500
const MIN_MODAL_WIDTH = 500

const CHOOSE_CHANGE_ORDER_HEIGHT = 64
const CHOOSE_CHANGE_ORDER_MARGIN = 8
