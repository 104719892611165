import React, { useMemo, useState } from 'react'
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Theme,
} from '@material-ui/core'
import { workorderContext } from '../../../contexts'
import { Container, Selector } from '../../../components'
import {
    IdBoolMap,
    ModelMap,
    Service,
    convertListToMap,
    convertMapToList,
    getTotalPriceExpense,
} from '../../../models'
import { InvoiceWorkorderCard } from './InvoiceWorkorderCard'
import {
    SendInvoiceRequest,
    useVendorInvoice,
} from '../../../contexts/useVendorInvoice'
import { toast } from 'react-toastify'
import { currencyFormatter } from '../../../helpers'

interface Props {
    theme: Theme
    refreshData: () => void
}

export const SendInvoice = (props: Props) => {
    const { theme, refreshData } = props

    const { workorderList } = workorderContext()
    const vendorInvoiceController = useVendorInvoice()

    const firstWorkorder = workorderList ? workorderList[0] : null

    const [openDialog, setOpenDialog] = useState(false)
    const [loading, setLoading] = useState(false)

    const [selectedServiceId, setSelectedServiceId] = useState(ALL_SERVICES)
    const [selectedPropertyId, setSelectedPropertyId] = useState(
        firstWorkorder ? firstWorkorder.apartment_id : NO_PROPERTY,
    )

    const [selectedWorkorderMap, setSelectedWorkorderMap] = useState<IdBoolMap>(
        {},
    )

    const filteredWorkorders = useMemo(() => {
        return (
            workorderList?.filter((wo) => {
                const serviceValid =
                    selectedServiceId === ALL_SERVICES ||
                    selectedServiceId === wo.service_id
                const propertyValid = wo.apartment_id === selectedPropertyId

                return serviceValid && propertyValid
            }) ?? []
        )
    }, [workorderList, selectedServiceId, selectedPropertyId])

    const propertyMap: ModelMap<{
        id: number
        name: string
        amount: number
    }> = {}
    const serviceMap: ModelMap<Service> = {}
    serviceMap[initialService.id] = initialService

    workorderList?.forEach((wo) => {
        // const service = serviceList.find((ser) => ser.id === wo.service_id)
        if (wo.service_id && !serviceMap[wo.service_id]) {
            const newService = {
                id: wo.service_id ?? -1,
                name: wo.service_name ?? '',
                color: wo.service_color ?? '',
                order: 1,
                icon: null,
            }
            serviceMap[wo.service_id] = newService
        }

        if (!propertyMap[wo.apartment_id]) {
            propertyMap[wo.apartment_id] = {
                id: wo.apartment_id,
                name: wo.apartment_name,
                amount: workorderList.filter(
                    (workorder) => workorder.apartment_id === wo.apartment_id,
                ).length,
            }
        }
    })

    const selectedWorkorderIds = Object.keys(selectedWorkorderMap).reduce<
        number[]
    >((prev, id) => {
        if (id === 'length') {
            return prev
        }
        const numID = Number(id)
        if (selectedWorkorderMap[numID] === true) {
            return prev.concat(numID)
        }
        return prev
    }, [])

    const selectedWorkordersLength = selectedWorkorderIds.length
    const allChecked = selectedWorkordersLength === filteredWorkorders.length
    const atLeastOneChecked = selectedWorkordersLength > 0

    const getInvoiceTotal = () => {
        let invoiceTotal = 0
        const workorderMap = convertListToMap(filteredWorkorders)
        selectedWorkorderIds.forEach((id) => {
            const wo = workorderMap[id]
            if (wo) {
                invoiceTotal += getTotalPriceExpense(wo)
            }
        })
        return invoiceTotal
    }

    const serviceList = convertMapToList(serviceMap)
    const propertyList = convertMapToList(propertyMap)

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                overflow: 'auto',
            }}
        >
            <Container style={{ marginBottom: theme.spacing(1) }}>
                <FormControlLabel
                    style={{ marginLeft: theme.spacing(1) }}
                    control={
                        <Checkbox
                            checked={allChecked}
                            style={{
                                color: theme.palette.secondary.main,
                            }}
                            // XOR
                            indeterminate={atLeastOneChecked}
                            onChange={() => {
                                const newMap: IdBoolMap = {}
                                Object.keys(selectedWorkorderMap).forEach(
                                    (keyStr) => {
                                        const key = Number(keyStr)
                                        newMap[key] = !allChecked
                                    },
                                )
                                setSelectedWorkorderMap(newMap)
                                if (selectedWorkordersLength > 0) {
                                    setSelectedWorkorderMap({})
                                } else {
                                    setSelectedWorkorderMap(
                                        filteredWorkorders.reduce((map, wo) => {
                                            const validCost =
                                                getTotalPriceExpense(wo) > 0
                                            return {
                                                ...map,
                                                [wo.id]: validCost,
                                            }
                                        }, {}),
                                    )
                                }
                            }}
                        />
                    }
                    label={atLeastOneChecked ? 'Remove All' : 'Select All'}
                />
                <Selector
                    label="Property"
                    data={propertyList}
                    searchable
                    currentValue={selectedPropertyId}
                    onChange={(e) => {
                        setSelectedPropertyId(e.target.value as number)
                        setSelectedWorkorderMap({})
                    }}
                    getDisplayString={(property) =>
                        `${property.name} (${property.amount})`
                    }
                    customStyle={{
                        formControl: {
                            margin: theme.spacing(1),
                            minWidth: '200px',
                        },
                    }}
                />
                <Selector
                    label="Service"
                    data={serviceList}
                    searchable
                    currentValue={selectedServiceId}
                    onChange={(e) => {
                        setSelectedServiceId(e.target.value as number)
                        setSelectedWorkorderMap({})
                    }}
                    getDisplayString={(service) => service.name}
                    customStyle={{
                        formControl: {
                            margin: theme.spacing(1),
                            minWidth: '200px',
                        },
                    }}
                />
            </Container>
            <Container
                style={{
                    flexDirection: 'column',
                    overflow: 'scroll',
                    flex: 1,
                }}
            >
                {filteredWorkorders.map((wo) => {
                    const cost = getTotalPriceExpense(wo)
                    return (
                        <Container key={`SEND_INVOICE_WORKORDER_${wo.id}`}>
                            <Checkbox
                                style={{ width: CHECK_BOX_W }}
                                checked={selectedWorkorderMap[wo.id] === true}
                                onChange={() => {
                                    const tempSelectedWorkorderMap = {
                                        ...selectedWorkorderMap,
                                    }
                                    if (tempSelectedWorkorderMap[wo.id]) {
                                        tempSelectedWorkorderMap[wo.id] = false
                                    } else {
                                        tempSelectedWorkorderMap[wo.id] = true
                                    }
                                    setSelectedWorkorderMap(
                                        tempSelectedWorkorderMap,
                                    )
                                }}
                                disabled={cost === 0}
                            />
                            <InvoiceWorkorderCard
                                workorder={wo}
                                theme={theme}
                            />
                        </Container>
                    )
                })}
            </Container>
            <Container
                style={{
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    padding: theme.spacing(2, 1, 2, 0),
                }}
            >
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: theme.palette.primary.dark,
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => setOpenDialog(true)}
                    disabled={selectedWorkordersLength === 0}
                >
                    {`Send Invoice (${selectedWorkordersLength})`}
                </Button>
            </Container>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle id="alert-dialog-title">
                    Confirm Sending Invoice
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You are about to invoice ({selectedWorkordersLength})
                        Workorders for a total of{' '}
                        {currencyFormatter.format(getInvoiceTotal())}
                    </DialogContentText>

                    {selectedWorkordersLength < 5 && (
                        <DialogContentText
                            style={{
                                ...theme.typography.caption,
                                marginTop: theme.spacing(2),
                            }}
                        >
                            {`* This is an unusually small invoice. Unless you're
                            sure you only want to invoice ${selectedWorkordersLength}
                            workorders consider adding some more to simplify
                            the payment process.`}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => setOpenDialog(false)}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            const request: SendInvoiceRequest = {
                                apartment: selectedPropertyId,
                                invoice_code:
                                    vendorInvoiceController.invoiceCode,
                                workorders: selectedWorkorderIds,
                            }
                            setLoading(true)
                            vendorInvoiceController
                                .sendInvoice(request)
                                .then((badWorkorderCount) => {
                                    toast.success(
                                        `${
                                            selectedWorkordersLength -
                                            badWorkorderCount
                                        } Workorders invoiced successfully`,
                                    )
                                    refreshData()
                                    setSelectedWorkorderMap({})
                                    setOpenDialog(false)
                                })
                                .catch((e) => {
                                    toast.error('Error sending invoice')
                                })
                                .finally(() => setLoading(false))
                        }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={25} /> : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

const ALL_SERVICES = -1
const NO_PROPERTY = -1
const CHECK_BOX_W = 42
const initialService = {
    id: ALL_SERVICES,
    name: 'All',
    color: '#000000',
    order: -1,
    icon: null,
}
