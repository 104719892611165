import React, { useEffect, useState } from 'react'
import { SideDrawer } from '../../components'
import { axiosInstance } from '../../helpers'
import { Report } from '../../models'
import { ReportsDrawerItem } from './ReportsDrawerItem'

interface Props {
    scheduleId?: number
    inspectionId?: number
    open: boolean
    handleClose: () => void
}

export const ReportsDrawer = (props: Props) => {
    const { scheduleId, inspectionId, open, handleClose } = props

    const [reportList, setReportList] = useState<Report[]>([])

    useEffect(() => {
        const params: { schedule?: number; inspection?: number } = {}
        if (scheduleId) {
            params.schedule = scheduleId
        }
        if (inspectionId) {
            params.inspection = inspectionId
        }
        axiosInstance
            .get('stats/reports/', {
                params: params,
            })
            .then((res) => {
                setReportList(res.data)
            })
    }, [scheduleId, inspectionId])

    return (
        <SideDrawer open={open} handleClose={handleClose} title={'Reports'}>
            {reportList.map((report) => (
                <ReportsDrawerItem
                    report={report}
                    key={`REPORT_${report.id}`}
                />
            ))}
        </SideDrawer>
    )
}
