import React, { useState } from 'react'
import { Container } from '../../../components'
import { useVendorInvoice } from '../../../contexts/useVendorInvoice'
import { Button, TextField, Theme } from '@material-ui/core'
import Logo from '../../../assets/ezos-logo-primary-dark.png'

interface Props {
    theme: Theme
}

export const InvoiceCode = (props: Props) => {
    const { theme } = props

    const vendorInvoiceController = useVendorInvoice()

    const [incorrectCode, setIncorrectCode] = useState(false)

    return (
        <Container
            style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <img
                src={Logo}
                alt="EZ Turn"
                style={{
                    width: '300px',
                    height: '100px',
                }}
            />

            <TextField
                variant="outlined"
                placeholder="Invoice Code"
                value={vendorInvoiceController.invoiceCode}
                onChange={(e) => {
                    vendorInvoiceController.setInvoiceCode(e.target.value)
                }}
                style={{
                    margin: theme.spacing(8, 0, 4, 0),
                    width: '80%',
                }}
                error={incorrectCode}
                helperText={incorrectCode && 'Invalid Code'}
            />

            <Button
                variant="contained"
                style={{
                    backgroundColor: theme.palette.primary.dark,
                    color: 'white',
                    textTransform: 'none',
                    cursor: 'pointer',
                    width: '80%',
                }}
                onClick={() =>
                    vendorInvoiceController
                        .validateCode()
                        .then((res) => setIncorrectCode(!res))
                }
            >
                Submit
            </Button>
        </Container>
    )
}
