import { useTheme } from '@material-ui/core'
import { InspectionDetailGrades } from '../../../hooks/useAnalyticData'
import React from 'react'
import { Container } from '../../../components'
import { useStyles } from '../../../styles'
import { toMMDDYYYY } from '../../../helpers'

interface Props {
    inspectionDetailGrade: InspectionDetailGrades
}

export const InspectionDetailGradeRow = (props: Props) => {
    const { inspectionDetailGrade } = props

    const theme = useTheme()
    const classes = useStyles()

    const cellStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        fontWeight: 400,
        whiteSpace: 'nowrap',
        justifyContent: 'right',
        paddingRight: theme.spacing(7),
        flex: 1,
    }

    const truncatedString = (str: string, maxLength: number) => {
        if (str.length > maxLength) {
            const truncstr = str?.substring(0, maxLength - 3) + '...'
            return truncstr
        }
        return str
    }

    const maxNameLength = 24

    return (
        <Container
            style={{
                borderBottom: '1px solid',
                borderColor: theme.palette.grey[400],
                flexDirection: 'row',
                cursor: 'pointer',
            }}
            // alignItems="center"
            className={classes.hoverShadow}
            onClick={() => {}}
        >
            <Container
                style={{
                    ...cellStyle,
                    minWidth: theme.spacing(maxNameLength),
                    maxWidth: theme.spacing(maxNameLength),
                    justifyContent: 'left',
                }}
            >
                {truncatedString(inspectionDetailGrade.name, maxNameLength)}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                    paddingLeft: theme.spacing(4),
                    paddingRight: theme.spacing(4),
                }}
            >
                {toMMDDYYYY(new Date(inspectionDetailGrade.end_date))}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {inspectionDetailGrade.flags}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {inspectionDetailGrade.potential_flags}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {inspectionDetailGrade.units_inspected}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {inspectionDetailGrade.units_requiring_inspection}
            </Container>
        </Container>
    )
}
