import { useEffect, useMemo, useState } from 'react'
import { IdentifiableObject } from '../models'

export const usePagination = <T extends IdentifiableObject>(
    list: T[],
    _pageSize?: number,
) => {
    const [pageSize, setPageSize] = useState(_pageSize ?? 25)
    const [page, setPage] = useState(1)

    const numPages = Math.ceil(list.length / pageSize)

    const pageStart = (page - 1) * pageSize
    const pageEnd = pageStart + pageSize

    const pageData = useMemo(() => {
        return list.slice(pageStart, pageEnd)
    }, [page, pageSize, list])

    const uiStart = Math.min(pageStart + 1, list.length)
    const uiEnd = Math.min(pageEnd, list.length)

    useEffect(() => {
        if (pageStart >= list.length) {
            setPage(1)
        }
    }, [page, list])

    return {
        pageSize,
        setPageSize,
        page,
        setPage,
        numPages,
        pageData,
        start: uiStart,
        end: uiEnd,
    }
}
