export const PAINT_SERVICE_ICON = require('./paint-icon.png')
export const CARPET_SERVICE_ICON = require('./carpet-icon.png')
export const CLEAN_SERVICE_ICON = require('./clean-icon.png')
export const CARPET_R_SERVICE_ICON = require('./carpet-r-icon.png')
export const TRASHOUT_SERVICE_ICON = require('./trashout-icon.png')
export const MAINTENANCE_SERVICE_ICON = require('./maintenance-icon.png')
export const CARPET_C_SERVICE_ICON = require('./carpet-c-icon.png')
export const FURNITURE_SERVICE_ICON = require('./furniture-icon.png')
export const VINYL_SERVICE_ICON = require('./vinyl-icon.png')
export const MISC_SERVICE_ICON = require('./misc-icon.png')
export const KEY_SERVICE_ICON = require('./key-icon.png')
export const HVAC_SERVICE_ICON = require('./hvac-icon.png')
export const BLINDS_SERVICE_ICON = require('./blinds-icon.png')
export const STAIN_SERVICE_ICON = require('./stain-icon.png')
export const ODOR_SERVICE_ICON = require('./odor-icon.png')
export const ALT_CLEAN_SERVICE_ICON = require('./alt-clean-icon.png')
export const APPLIANCE_SERVICE_ICON = require('./appliance-icon.png')
export const PEST_CONTROL_SERVICE_ICON = require('./pest-control-icon.png')
export const INSPECTION_SERVICE_ICON = require('./inspection-icon.png')
export const PRESSURE_WASH_SERVICE_ICON = require('./pressure-wash-icon.png')
export const OCC_WALK_SERVICE_ICON = require('./occ-walk-icon.png')
export const CHARGE_BACK_SERVICE_ICON = require('./charge-back-icon.png')
export const LIGHT_LOG_SERVICE_ICON = require('./light-log-icon.png')
export const ELECTRICAL_SERVICE_ICON = require('./electrical-icon.png')
export const PLUMBING_SERVICE_ICON = require('./plumbing-icon.png')
export const ROOM_CHECK_SERVICE_ICON = require('./room-check-icon.png')
export const PET_SERVICE_ICON = require('./pet-icon.png')
export const UPGRADE_SERVICE_ICON = require('./upgrade-icon.png')
export const FIRE_EXTINGUISHER_SERVICE_ICON = require('./fire-extinguisher-icon.png')
export const FURNITURE_REMOVAL_ICON = require('./furniture-removal.png')
export const TOILET_REMOVAL_ICON = require('./toilet-removal.png')
export const FLOORING_REMOVAL_ICON = require('./flooring-removal.png')
export const TOILET_INSTALL_ICON = require('./toilet-install.png')
export const FURNITURE_INSTALL_ICON = require('./furniture-install.png')
export const DRYWALL_PAINT_TOUCHUP_ICON = require('./drywall-paint-touchup.png')
export const KITCHEN_REPLACEMENT_ICON = require('./kitchen-replacement.png')
export const VANITY_REPLACEMENT_ICON = require('./vanity-replacement.png')
export const SPARKLE_ICON = require('./sparkle.png')
export const ELEVATOR_ICON = require('./elevator.png')
export const ESG_ICON = require('./esg.png')
export const FIRE_ALARM_ICON = require('./fire-alarm.png')
export const INTERNET_ICON = require('./internet.png')
export const COMPUTER_ICON = require('./computer.png')
export const IRRIGATION_ICON = require('./irrigation.png')
export const GRASS_ICON = require('./grass.png')
export const LAUNDRY_ICON = require('./laundry.png')
export const POOL_ICON = require('./pool.png')
export const SIGNATURE_ICON = require('./signature.png')
export const BADGE_ICON = require('./badge.png')
export const SNOW_ICON = require('./snow.png')
export const TREE_ICON = require('./tree.png')
export const WATER_ICON = require('./water.png')
