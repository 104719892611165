import React, { useEffect } from 'react'
import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TableBody,
    Theme,
    IconButton,
    Tooltip,
    Button,
} from '@material-ui/core'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import { Container } from '../../../../components'
import {
    MoveInInspectionController,
    MoveInInspectionQueueEvent,
} from '../../../../hooks/useMoveInInspection'
import { footerH, headerH, modalH } from './constants'

interface Props {
    theme: Theme
    moveInController: MoveInInspectionController
    selectedDate: Date
    selectEvent: (event: MoveInInspectionQueueEvent) => void
}

export const MoveInEventList = (props: Props) => {
    const { theme, moveInController } = props

    return (
        <Container
            style={{
                padding: theme.spacing(2),
                flexDirection: 'column',
                overflowY: 'scroll',
                maxHeight: modalH - footerH,
                minHeight: modalH - footerH,
            }}
        >
            <TableContainer style={{ flex: 1 }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Created By</TableCell>
                            <TableCell>Created Date</TableCell>
                            <TableCell>Ready Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Leases</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {moveInController.moveInEvents.map((event) => {
                            const createdDate = new Date(event.created_date)
                            const createdDateStr = createdDate.toLocaleDateString()

                            const readyDate = new Date(event.send_date)
                            const readyDateStr = readyDate.toLocaleDateString()
                            return (
                                <TableRow key={`EVENT-${event.id}`}>
                                    <TableCell>
                                        {event.created_by?.name}
                                    </TableCell>
                                    <TableCell>{createdDateStr}</TableCell>
                                    <TableCell>{readyDateStr}</TableCell>
                                    <TableCell>
                                        {event.handled
                                            ? 'Inspection Email Sent'
                                            : 'Queued'}
                                    </TableCell>
                                    <TableCell>{event.leases.length}</TableCell>

                                    <TableCell>
                                        <Tooltip title="View Details">
                                            <IconButton
                                                onClick={() =>
                                                    props.selectEvent(event)
                                                }
                                            >
                                                <ArrowRightAltIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {/*  */}
            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(1),
                    minHeight: footerH,
                    maxHeight: footerH,
                    alignItems: 'center',
                }}
            >
                <div style={{ flex: 1 }} />
            </Container>
        </Container>
    )
}
