import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import { Button, Grid } from '@material-ui/core'

import { ReactComponent as AssetIcon } from '../../../assets/icons/settings/asset.svg'
import { ReactComponent as BarChartIcon } from '../../../assets/icons/settings/bar-chart.svg'
import { ReactComponent as BoxIcon } from '../../../assets/icons/settings/box.svg'
import { ReactComponent as FileUploadHIcon } from '../../../assets/icons/settings/file-upload-horizontal.svg'
import { ReactComponent as ReportsIcon } from '../../../assets/icons/settings/reports.svg'
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings/settings.svg'
import { ReactComponent as ThumbsDownIcon } from '../../../assets/icons/settings/thumbs-down.svg'
import { ReactComponent as ThumbsUpIcon } from '../../../assets/icons/settings/thumbs-up.svg'
import { ReactComponent as ToolIcon } from '../../../assets/icons/settings/tool.svg'
import { useHistory } from 'react-router-dom'
import { resolveRoute, Routes } from '../../../helpers'
import { _PortfolioAnalytics } from '../../../containers/_PortfolioAnalytics'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 345,
            height: '100%',
            position: 'relative',
            border: '1px solid #000',
            cursor: 'pointer',
            '& .MuiCardHeader-avatar': {
                border: '1px solid #000',
                borderRadius: '5px',
                padding: '5px',
                display: 'flex',
            },
            '&:hover': {
                border: '1px solid #008C85',
                filter: 'drop-shadow(0px 4px 4px rgba(0, 140, 133, 0.45))',
            },
            '& .MuiCardHeader-title': {
                color: '#000',
                fontWeight: '600',
                fontSize: '0.938rem',
            },
            '& .MuiCardActions-root': {
                padding: 0,
                marginTop: '36px',
            },
        },
        openAction: {
            position: 'absolute',
            left: 0,
            bottom: 0,
            color: '#FFF',
            fontWeight: 700,
            textTransform: 'inherit',
            borderRadius: 0,
            '&.MuiButton-containedPrimary': {
                backgroundColor: '#008C85 !important',
                '&:hover': {
                    backgroundColor: '#008C85 !important',
                },
            },
        },
    }),
)

export const Settings = () => {
    const classes = useStyles()
    const history = useHistory()

    const [portfolioAnalyticsOpen, setPortfolioAnalyticsOpen] = useState(false)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3} lg={3}>
                <Card className={classes.root}>
                    <CardHeader
                        avatar={<SettingsIcon />}
                        title="General Settings"
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ color: '#000', fontSize: '0.813rem' }}
                        >
                            Review your personal Settings here
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button
                            variant="contained"
                            fullWidth
                            className={classes.openAction}
                            onClick={() => history.push(Routes.generalSetting)}
                            color="primary"
                        >
                            Open
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
                <Card className={classes.root}>
                    <CardHeader
                        avatar={<SettingsIcon />}
                        title="Property Details"
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ color: '#000', fontSize: '0.813rem' }}
                        >
                            Review your property details here
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button
                            variant="contained"
                            fullWidth
                            className={classes.openAction}
                            onClick={() => history.push(Routes.propertyDetails)}
                            color="primary"
                        >
                            Open
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
                <Card className={classes.root}>
                    <CardHeader
                        avatar={<SettingsIcon />}
                        title="Service Request Settings"
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ color: '#000', fontSize: '0.813rem' }}
                        >
                            Review your Service Request settings here
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button
                            variant="contained"
                            fullWidth
                            className={classes.openAction}
                            onClick={() =>
                                history.push(Routes.serviceRequestSettings)
                            }
                            color="primary"
                        >
                            Open
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
                <Card className={classes.root}>
                    <CardHeader avatar={<BarChartIcon />} title="Analytics" />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ color: '#000', fontSize: '0.813rem' }}
                        >
                            Review your analytics here
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button
                            variant="contained"
                            fullWidth
                            className={classes.openAction}
                            onClick={() => history.push(Routes.analytics)}
                            color="primary"
                        >
                            Open
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
                <Card className={classes.root}>
                    <CardHeader avatar={<BoxIcon />} title="Asset Manager" />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ color: '#000', fontSize: '0.813rem' }}
                        >
                            Review your asset here
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button
                            variant="contained"
                            fullWidth
                            className={classes.openAction}
                            onClick={() =>
                                history.push(Routes.infrastructureManager)
                            }
                            color="primary"
                        >
                            Open
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
                <Card className={classes.root}>
                    <CardHeader avatar={<ReportsIcon />} title="Reports" />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ color: '#000', fontSize: '0.813rem' }}
                        >
                            Review your reports here
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button
                            variant="contained"
                            fullWidth
                            className={classes.openAction}
                            onClick={() => history.push(Routes.reports)}
                            color="primary"
                        >
                            Open
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
                <Card className={classes.root}>
                    <CardHeader
                        avatar={<AssetIcon />}
                        title="Asset Configuration"
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ color: '#000', fontSize: '0.813rem' }}
                        >
                            Review your infrastructure Configuration here
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            className={classes.openAction}
                            onClick={() =>
                                history.push(Routes.infrastructureConfig)
                            }
                        >
                            Open
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
                <Card className={classes.root}>
                    <CardHeader avatar={<ToolIcon />} title="Damages" />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ color: '#000', fontSize: '0.813rem' }}
                        >
                            Review your damages here
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button
                            variant="contained"
                            fullWidth
                            className={classes.openAction}
                            onClick={() => history.push(Routes.damageConf)}
                            color="primary"
                        >
                            Open
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
                <Card className={classes.root}>
                    <CardHeader
                        avatar={
                            <>
                                <ThumbsUpIcon style={{ margin: '5px' }} />
                                <ThumbsDownIcon style={{ margin: '5px' }} />
                            </>
                        }
                        title="Status Groups"
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ color: '#000', fontSize: '0.813rem' }}
                        >
                            Review your status group here
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button
                            variant="contained"
                            fullWidth
                            className={classes.openAction}
                            onClick={() => history.push(Routes.statusGroups)}
                            color="primary"
                        >
                            Open
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
                <Card className={classes.root}>
                    <CardHeader
                        avatar={<FileUploadHIcon />}
                        title="Change Order"
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ color: '#000', fontSize: '0.813rem' }}
                        >
                            Review your change order here
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button
                            variant="contained"
                            fullWidth
                            className={classes.openAction}
                            onClick={() =>
                                history.push(Routes.changeOrderConfig)
                            }
                            color="primary"
                        >
                            Open
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={3}>
                <Card className={classes.root}>
                    <CardHeader
                        avatar={<SettingsIcon />}
                        title="Portfolio Analytics"
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{ color: '#000', fontSize: '0.813rem' }}
                        >
                            Review your full portfolio analytics here
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <Button
                            variant="contained"
                            fullWidth
                            className={classes.openAction}
                            onClick={() => {
                                setPortfolioAnalyticsOpen(true)
                            }}
                            color="primary"
                        >
                            Open
                        </Button>
                    </CardActions>
                </Card>
            </Grid>

            <_PortfolioAnalytics
                open={portfolioAnalyticsOpen}
                onClose={() => setPortfolioAnalyticsOpen(false)}
            />
        </Grid>
    )
}
