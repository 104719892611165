import {
    Button,
    Container,
    FormControl,
    FormLabel,
    TextField,
    Typography,
} from '@material-ui/core'
import React from 'react'
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme,
} from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        closeBtn: {
            background: '#FFF',
            color: '#E9190F',
            border: '1px solid #E9190F',
            fontSize: '0.875rem',
            textTransform: 'inherit',
            fontWeight: 500,
            borderRadius: '5px',
            marginRight: '5px',
        },
        saveBtn: {
            background: '#008C85',
            color: '#FFF',
            border: '1px solid #008C85',
            fontSize: '0.875rem',
            textTransform: 'inherit',
            fontWeight: 500,
            borderRadius: '5px',
        },
    }),
)

interface Props {
    handleClose: () => void
}

export const ShareVendor = (props: Props) => {
    const classes = useStyles()
    return (
        <>
            <Container style={{ padding: '0 8px' }}>
                <Typography
                    component="p"
                    style={{
                        fontSize: '0.75rem',
                        lineHeight: '1.7rem',
                        color: '#8A8A8A',
                    }}
                >
                    Please enter the details below!
                </Typography>
                <form
                    noValidate
                    autoComplete="off"
                    style={{ padding: '20px 0' }}
                >
                    <FormControl fullWidth>
                        <TextField
                            id="email"
                            label="Email address:"
                            type="email"
                            variant="outlined"
                            size="small"
                        />
                    </FormControl>
                </form>
                <div style={{ marginTop: '15px', textAlign: 'right' }}>
                    <Button
                        className={classes.closeBtn}
                        onClick={() => props.handleClose()}
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            background: '#008C85',
                            color: '#FFF',
                            fontSize: '0.875rem',
                            textTransform: 'inherit',
                            fontWeight: 500,
                            borderRadius: '5px',
                            boxShadow: 'none',
                        }}
                        onClick={() => props.handleClose()}
                    >
                        Send
                    </Button>
                </div>
            </Container>
        </>
    )
}
