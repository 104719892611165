import React from 'react'
import { RFPContext, useRFPState } from '../../contexts'
import { NVLHomePage } from './NVLHomePage'

export const NVLRoot = () => {
    const rfpState = useRFPState()

    return (
        <RFPContext.Provider value={rfpState}>
            <NVLHomePage />
        </RFPContext.Provider>
    )
}
