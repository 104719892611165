import { AxiosResponse } from 'axios'
import { Action } from 'redux'
import {
    Inspection,
    InspectionDamage,
    InspectionDetail,
    InventoryInspection,
    ModelListMap,
    UnitInspection,
} from '../../models'
import { PaginatedResponse } from '../types'

// Requests
export interface InspectionDamageRequest {
    params?: {
        unit?: number
        inspection?: number
        damage_config?: number
    }
}

export interface CreateInspectionDamageRequest {
    // unit_inspection OR (unit AND inspection) is required
    body: {
        unit_inspection?: number
        damage_config_id: number
        amount: number
        unit?: number
        inspection?: number
    }
}
export interface GetInspectionListAxiosReq {
    params?: {
        type?: number
        has_schedule?: boolean
    }
}

export interface CreateInspectionAxiosReq {
    body: {
        name: string
        inspection_type: number
        start_date: string
        end_date: string
        schedule?: number
        new_schedule?: {
            schedule_id?: string
        }
    }
}

export interface UpdateInspectionAxiosReq {
    id: number
    body: {
        name?: string
        start_date?: string
        end_date?: string
        active?: boolean
        schedule?: number
        new_schedule?: {
            schedule_id?: string
        }
    }
}

export interface InventoryInspectionListAxiosReq {
    params?: {
        inspection?: number
        inventory?: number
        area?: number
        lower_bound_date?: string
        upper_bound_date?: string
        unit_name?: string
        flagged?: boolean
        complete?: boolean
        unassigned?: boolean
        handled?: boolean
        // backend doesn't support false for the following params
        has_messasge?: true
        has_unread?: true
        cost_driver?: true
        // comma seperated lists
        units?: string
        inv_configs?: string
        statuses?: string
        unit_configs?: string
    }
}

export interface UnitInspectionParams {
    limit?: number
    offset?: number
    unit_name?: string
    complete?: boolean
    //comma seperated lists
    units?: string
    folders?: string
    unit_configs?: string
    inventory_configs?: string
    statuses?: string
    // end comma seperated lists
    has_flag?: boolean
    handled?: boolean
    // for the anys all that matters is the param is present
    cost_driver?: any
    has_message?: any
    has_unread?: any
    inspection?: number
    has_damage?: boolean
}

export interface UnitInspecitonListAxiosReq {
    inspection_id: number
    url?: string
    params?: UnitInspectionParams
}

export interface DeleteInspectionDamageRequest {
    params: {
        id: number
    }
}

export interface AssignUnitInspectionRequest {
    body: {
        inspection: number
        areas: number[]
        assign_to?: number
        start_date?: string
        end_date?: string
    }
}

export interface UpdateInventoryInspectionReqeust {
    inventoryInspectionId: number
    body: {
        handled?: boolean
        config_index?: number
        status?: number
        note?: string
    }
}

export interface BulkHandleInventoryInspectionReqeust {
    body: {
        handled: boolean
        inventory_inspections: number[]
    }
}

export interface UpdateUnitInspectionRequest {
    unitInspection: UnitInspection
    body: {
        complete: boolean
    }
}

export interface InspectionAnalyticsResponse {
    assigned: number
    complete_count: number
    total_unit_count: number
    filtered_unit_count: number
    total_flag_count: number
    filtered_flag_count: number
    filtered_handled: number
    total_handled: number
    uninspected: number
    inventory_config_analytics: {
        [inv_config_name: string]: {
            [status_id: string]: {
                name: string
                count: number
                cost: number | null
            }
        }
    }
}

// actions
export const GET_INSPECTION_DAMAGE_LIST_REQUEST =
    'GET_INSPECTION_DAMAGE_LIST_REQUEST'
export const GET_INSPECTION_DETAIL = 'GET_INSPECTION_DETAIL'
export const CREATE_INSPECTION_DAMAGE_REQUEST =
    'CREATE_INSPECTION_DAMAGE_REQUEST'
export const DELETE_INSPECTION_DAMAGE_REQUEST =
    'DELETE_INSPECTION_DAMAGE_REQUEST'
export const GET_UNIT_INSPECTION_LIST_REQUEST =
    'GET_UNIT_INSPECTION_LIST_REQUEST'
export const UPDATE_INVENTORY_INSPECTION_REQUEST =
    'UPDATE_INVENTORY_INSPECTION_REQUEST'
export const GET_INSPECTION_ANALYTICS_REQUEST =
    'GET_INSPECTION_ANALYTICS_REQUEST'

export const ADD_INSPECTION_DAMAGE = 'ADD_INSPECTION_DAMAGE'
export const CREATE_INSPECTION_REQUEST = 'CREATE_INSPECTION_REQUEST'
export const UPDATE_INSPECTION_REQUEST = 'UPDATE_INSPECTION_REQUEST'
export const GET_INVENTORY_INSPECTION_REQUEST =
    'GET_INVENTORY_INSPECTION_REQUEST'
export const SET_INSPECTION_DAMAGE_LIST = 'SET_INSPECTION_DAMAGE_LIST'
export const GET_INSPECTION_LIST = 'GET_INSPECTION_LIST'
export const SET_INSPECTION_LIST = 'SET_INSPECTION_LIST'
export const SET_INSPECTION_DETAIL = 'SET_INSPECTION_DETAIL'
export const ADD_INSPECTION = 'ADD_INSPECTION'
export const REMOVE_INSPECTION_DAMAGE = 'REMOVE_INSPECTION_DAMAGE'
export const UPDATE_INSPECTION = 'UPDATE_INSPECTION'
export const SET_INVENTORY_INSPECTION_LIST = 'SET_MANAGE_INSPECTION_INVENTORY'
export const SET_INSPECTION_LOADING = 'SET_INSPECTION_LOADING'
export const ADD_UNIT_INSPECTIONS = 'ADD_UNIT_INSPECTIONS'
export const SET_UNIT_INSPECTION_LIST = 'SET_UNIT_INSPECTION_LIST'
export const UPDATE_UNIT_INSPECTION = 'UPDATE_UNIT_INSPECTION'
export const UPDATE_INVENTORY_INSPECTION = 'UPDATE_INVENTORY_INSPECTION'
export const ASSIGN_UNIT_INSPECTION_REQUEST = 'ASSIGN_UNIT_INSPECTION_REQUEST'
export const CREATE_INVENTORY_INSPECTION_CHANNEL =
    'CREATE_INVENTORY_INSPECTION_CHANNEL'
export const BULK_HANDLE_INVENTORY_INSPECTION_REQ =
    'BULK_HANDLE_INVENTORY_INSPECTION_REQ'
export const SET_INSPECTION_ANALYTICS = 'SET_INSPECTION_ANALYTICS'

export type InspectionRequest =
    | typeof GET_INSPECTION_DAMAGE_LIST_REQUEST
    | typeof CREATE_INSPECTION_DAMAGE_REQUEST
    | typeof GET_INSPECTION_LIST
    | typeof CREATE_INSPECTION_REQUEST
    | typeof UPDATE_INSPECTION_REQUEST
    | typeof GET_INSPECTION_DETAIL
    | typeof GET_INVENTORY_INSPECTION_REQUEST
    | typeof DELETE_INSPECTION_DAMAGE_REQUEST
    | typeof GET_UNIT_INSPECTION_LIST_REQUEST
    | typeof ASSIGN_UNIT_INSPECTION_REQUEST
    | typeof CREATE_INVENTORY_INSPECTION_CHANNEL
    | typeof UPDATE_INVENTORY_INSPECTION_REQUEST
    | typeof BULK_HANDLE_INVENTORY_INSPECTION_REQ
    | typeof GET_INSPECTION_ANALYTICS_REQUEST

interface SetInspectionLoadingAction extends Action {
    type: typeof SET_INSPECTION_LOADING
    request: InspectionRequest
    isLoading: boolean
}

interface AddInspectionDamageAction extends Action {
    type: typeof ADD_INSPECTION_DAMAGE
    inspectionDamage: InspectionDamage
}

interface SetInspectionDamageListAction extends Action {
    type: typeof SET_INSPECTION_DAMAGE_LIST
    inspectionDamageList?: InspectionDamage[]
}

interface SetInspectionListAction extends Action {
    type: typeof SET_INSPECTION_LIST
    inspectionList?: Inspection[]
}

interface AddInspectionAction extends Action {
    type: typeof ADD_INSPECTION
    inspection: Inspection
}

interface UpdateInspectionAction extends Action {
    type: typeof UPDATE_INSPECTION
    id: number
    updatedInspection: Inspection
}

interface SetInspectionDetailAction extends Action {
    type: typeof SET_INSPECTION_DETAIL
    inspection?: InspectionDetail
}

interface SetInventoryInspectionList extends Action {
    type: typeof SET_INVENTORY_INSPECTION_LIST
    inventoryInspectionList?: InventoryInspection[]
}

interface RemoveInspectionDamageAction extends Action {
    type: typeof REMOVE_INSPECTION_DAMAGE
    inspectionDamageId: number
}

interface MergeUnitInspectionListAction extends Action {
    type: typeof ADD_UNIT_INSPECTIONS
    unitInspectionList?: UnitInspection[]
}

interface SetUnitInspectionList extends Action {
    type: typeof SET_UNIT_INSPECTION_LIST
    unitInspectionList?: UnitInspection[]
}

interface UpdateInventoryInspectionAction extends Action {
    type: typeof UPDATE_INVENTORY_INSPECTION
    inv_ins: InventoryInspection | InventoryInspection[]
}

interface UpdateUnitInspectionAction extends Action {
    type: typeof UPDATE_UNIT_INSPECTION
    unitIns: UnitInspection
}

interface SetInspectionAnalyticsAction extends Action {
    type: typeof SET_INSPECTION_ANALYTICS
    analytics?: InspectionAnalyticsResponse
}

export type InspectionActionTypes =
    | SetInspectionLoadingAction
    | SetInspectionDamageListAction
    | AddInspectionDamageAction
    | SetInspectionListAction
    | AddInspectionAction
    | UpdateInspectionAction
    | SetInspectionDetailAction
    | SetInventoryInspectionList
    | RemoveInspectionDamageAction
    | MergeUnitInspectionListAction
    | UpdateInventoryInspectionAction
    | SetUnitInspectionList
    | UpdateUnitInspectionAction
    | SetInspectionAnalyticsAction

// thunks
export type GetInspectionListActionThunk = Promise<AxiosResponse<Inspection[]>>
export type UnitInspectionActionThunk = Promise<AxiosResponse<UnitInspection>>
export type UnitInspectionListActionThunk = Promise<
    AxiosResponse<PaginatedResponse<UnitInspection[]>>
>

export type InspectionDamageListThunk = Promise<
    AxiosResponse<InspectionDamage[]>
>

export type InspectionDamageThunk = Promise<AxiosResponse<InspectionDamage>>
export type InspectionListThunk = Promise<AxiosResponse<Inspection[]>>
export type InspectionThunk = Promise<AxiosResponse<Inspection>>
export type InventoryInspectionListThunk = Promise<
    AxiosResponse<InventoryInspection[]>
>
export type InventoryInspectionThunk = Promise<
    AxiosResponse<InventoryInspection>
>
export type UnitInspectionThunk = Promise<AxiosResponse<UnitInspection[]>>

// reducers
export interface InspectionState {
    inspectionList?: Inspection[]
    inspectionDamageList?: InspectionDamage[]
    inspectionDetail?: InspectionDetail
    unitInspectionList?: UnitInspection[]
    inventoryInspectionList?: InventoryInspection[]
    inventoryInspectionMap?: ModelListMap<InventoryInspection>
    inspectionAnalytics?: InspectionAnalyticsResponse
    isLoading: {
        [GET_INSPECTION_DAMAGE_LIST_REQUEST]: boolean
        [CREATE_INSPECTION_DAMAGE_REQUEST]: boolean
        [GET_INSPECTION_LIST]: boolean
        [CREATE_INSPECTION_REQUEST]: boolean
        [UPDATE_INSPECTION_REQUEST]: boolean
        [GET_INSPECTION_DETAIL]: boolean
        [GET_INVENTORY_INSPECTION_REQUEST]: boolean
        [DELETE_INSPECTION_DAMAGE_REQUEST]: boolean
        [GET_UNIT_INSPECTION_LIST_REQUEST]: boolean
        [CREATE_INVENTORY_INSPECTION_CHANNEL]: boolean
        [UPDATE_INVENTORY_INSPECTION_REQUEST]: boolean
        [BULK_HANDLE_INVENTORY_INSPECTION_REQ]: boolean
        [GET_INSPECTION_ANALYTICS_REQUEST]: boolean
        [ASSIGN_UNIT_INSPECTION_REQUEST]: boolean
    }
}
