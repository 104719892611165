import React from 'react'

import { useTheme, Badge } from '@material-ui/core'
import MessageIcon from '@material-ui/icons/Message'
import FlagIcon from '@material-ui/icons/Flag'
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn'

import NumberFormat from 'react-number-format'

import { Container } from '../../components'
import { AreaInspection, AreaStatusTag, ModelMap } from '../../models'

interface Props {
    areaInspection: AreaInspection
    areaStatusMap: ModelMap<AreaStatusTag>
}

export const AreaInspectionComponent = (props: Props) => {
    const { areaInspection, areaStatusMap } = props

    const area = areaInspection.area

    const theme = useTheme()

    const areaStatusStr = areaStatusMap[area.id]
        ? `(${areaStatusMap[area.id]?.area_status_config.name})`
        : ''

    return (
        <Container
            direction="column"
            style={{
                border: `1px solid ${theme.palette.grey[900]}`,
                margin: theme.spacing(1),
                padding: theme.spacing(1, 2),
                height: 80,
                width: 200,
                ...theme.typography.body2,
                borderRadius: theme.shape.borderRadius,
            }}
        >
            <Container>
                <span
                    style={{
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {areaInspection.area.area_config.name} {area.area_label}
                </span>
                <div style={{ flex: 1 }} />

                <span>{areaStatusStr}</span>
            </Container>

            <Container style={{ margin: theme.spacing(1, 0) }}>
                <div style={{ width: 30, marginRight: theme.spacing(1) }}>
                    <Badge
                        badgeContent={areaInspection.flagged_count}
                        color="secondary"
                    >
                        <FlagIcon
                            color={
                                areaInspection.flagged_count > 0
                                    ? undefined
                                    : 'disabled'
                            }
                        />
                    </Badge>
                </div>

                <div style={{ width: 30, marginRight: theme.spacing(1) }}>
                    <Badge
                        badgeContent={areaInspection.handled_count}
                        color="primary"
                    >
                        <AssignmentTurnedIn
                            color={
                                areaInspection.handled_count > 0
                                    ? undefined
                                    : 'disabled'
                            }
                        />
                    </Badge>
                </div>

                <div style={{ width: 30 }}>
                    <Badge
                        badgeContent={areaInspection.message_count}
                        color={
                            areaInspection.unread_count > 0
                                ? 'secondary'
                                : 'primary'
                        }
                        style={{ alignSelf: 'center' }}
                    >
                        <MessageIcon
                            color={
                                areaInspection.message_count > 0
                                    ? undefined
                                    : 'disabled'
                            }
                        />
                    </Badge>
                </div>
                <div style={{ flex: 1 }} />
                <NumberFormat
                    value={areaInspection.cost}
                    prefix="$"
                    thousandSeparator
                    displayType="text"
                    style={{ ...theme.typography.subtitle1 }}
                />
            </Container>
        </Container>
    )
}
