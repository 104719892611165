import React, { useEffect, useState } from 'react'

import { KeyboardDatePicker } from '@material-ui/pickers'
import {
    Button,
    Checkbox,
    Modal,
    Paper,
    Slide,
    TextField,
    Theme,
    Tooltip,
} from '@material-ui/core'

import { Container } from '../../../../components'
import { CreateDeadlineRequest, useUser } from '../../../../hooks'
import { ApartmentDeadline, IdBoolMap } from '../../../../models'
import { FormState } from '../../types'
import { dateValid } from '../../../../helpers'
import { toast } from 'react-toastify'

interface Props {
    theme: Theme
    formState: FormState
    onCreate: (request: CreateDeadlineRequest) => Promise<ApartmentDeadline>
    onClose: () => void
}

export const AddDeadlineModal = (props: Props) => {
    const { formState, theme, onClose } = props

    const [doValidate, setDoValidate] = useState(false)

    const [date, setDate] = useState(formState.meta.date)
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    const { rootUser, workspaceUser } = useUser()

    const [
        selectedPropertyState,
        setSelectedPropertyState,
    ] = useState<IdBoolMap>({})

    useEffect(() => {
        setDate(formState.meta.date)
        setTitle('')
        setDescription('')
        setDoValidate(false)
        setCurrentWorkspaceSelection()
    }, [formState, rootUser, workspaceUser])

    const setCurrentWorkspaceSelection = () => {
        if (workspaceUser) {
            setSelectedPropertyState({
                [workspaceUser.active_workspace.id]: true,
            })
        }
    }

    const getIsValid = () => {
        const titleValid = title !== ''

        const dv = dateValid(date)

        return titleValid && dv && selectedCount > 0
    }

    const selectedIds = Object.keys(selectedPropertyState).reduce<number[]>(
        (prev, strKey) => {
            const key = Number(strKey)
            if (selectedPropertyState[key]) {
                return prev.concat(key)
            }
            return prev
        },
        [],
    )

    const selectedCount = selectedIds.length

    const open = formState.modals.addDeadlineModal

    const filteredWorkspaces =
        rootUser?.workspaces.filter((wsOption) => {
            return (
                wsOption.active_workspace.organization ===
                workspaceUser?.active_workspace.organization
            )
        }) ?? []

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        minHeight: h,
                        maxHeight: h,
                        minWidth: w,
                        maxWidth: w,
                    }}
                >
                    {/* Content */}
                    <Container
                        style={{
                            flexDirection: 'column',
                            flex: 1,
                            overflowY: 'scroll',
                        }}
                    >
                        {/* Title */}
                        <Container
                            style={{
                                minHeight: 100,
                                maxHeight: 100,
                                borderBottom: `1px solid ${theme.palette.grey[700]}`,
                                flex: 1,
                                padding: theme.spacing(1),
                                alignItems: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Add a new deadline
                            </span>
                        </Container>
                        {/* Body */}
                        <Container
                            style={{
                                flex: 1,
                                flexDirection: 'column',
                                overflow: 'hidden',
                            }}
                        >
                            {/* Row 1 Date & title */}
                            <Container
                                style={{
                                    padding: theme.spacing(1),
                                }}
                            >
                                <KeyboardDatePicker
                                    format="MM/dd/yyyy"
                                    variant="dialog"
                                    value={date}
                                    error={doValidate && !dateValid(date)}
                                    style={{ marginRight: theme.spacing(2) }}
                                    label="Date"
                                    onChange={(date) =>
                                        setDate(date ?? new Date())
                                    }
                                    required
                                />

                                <TextField
                                    fullWidth
                                    error={doValidate && title === ''}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    label="Title"
                                    required
                                />
                            </Container>

                            {/* Row 2  Description*/}
                            <Container
                                style={{
                                    padding: theme.spacing(1),
                                }}
                            >
                                <TextField
                                    fullWidth
                                    value={description}
                                    variant="outlined"
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                    label="Description"
                                    multiline
                                    rows={4}
                                />
                            </Container>

                            {/* Row 3 Choose workspaces Choose all / title*/}
                            <Container
                                style={{
                                    padding: theme.spacing(1),
                                    alignItems: 'center',
                                }}
                            >
                                <Checkbox
                                    style={{
                                        marginRight: theme.spacing(1),
                                        marginLeft: theme.spacing(1),
                                    }}
                                    checked={
                                        selectedCount ===
                                        filteredWorkspaces.length
                                    }
                                    indeterminate={
                                        selectedCount > 0 &&
                                        selectedCount <
                                            filteredWorkspaces.length
                                    }
                                    onChange={() => {
                                        if (selectedCount > 0) {
                                            setSelectedPropertyState({})
                                        } else {
                                            const newState: IdBoolMap = {}
                                            filteredWorkspaces.forEach((ws) => {
                                                newState[
                                                    ws.active_workspace.id
                                                ] = true
                                            })
                                            setSelectedPropertyState(newState)
                                        }
                                    }}
                                />
                                <Tooltip title="Reset selection to current workspace">
                                    <span
                                        style={{
                                            ...theme.typography.subtitle1,
                                            fontWeight:
                                                theme.typography
                                                    .fontWeightMedium,
                                            color: theme.palette.grey[600],
                                            cursor: 'pointer',
                                        }}
                                        onClick={setCurrentWorkspaceSelection}
                                    >
                                        Choose properties
                                    </span>
                                </Tooltip>
                            </Container>

                            {/* Workspace Rows */}
                            <Container
                                style={{
                                    flexDirection: 'column',
                                    overflowY: 'scroll',
                                    borderTop: `1px solid ${theme.palette.grey[500]}`,
                                }}
                            >
                                {filteredWorkspaces.map((ws) => {
                                    const propertyId = ws.active_workspace.id

                                    const isChecked =
                                        selectedPropertyState[propertyId] ===
                                        true

                                    const onClick = () => {
                                        setSelectedPropertyState({
                                            ...selectedPropertyState,
                                            [propertyId]: !isChecked,
                                        })
                                    }

                                    return (
                                        <Container
                                            key={`WS-${propertyId}`}
                                            style={{
                                                padding: theme.spacing(1),
                                                borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                                margin: theme.spacing(1),
                                                alignItems: 'center',
                                                height: 75,
                                                cursor: 'pointer',
                                            }}
                                            onClick={onClick}
                                        >
                                            <Checkbox
                                                style={{
                                                    marginRight: theme.spacing(
                                                        1,
                                                    ),
                                                }}
                                                checked={
                                                    selectedPropertyState[
                                                        propertyId
                                                    ] === true
                                                }
                                            />
                                            <span
                                                style={{
                                                    ...theme.typography.body1,
                                                    fontWeight:
                                                        theme.typography
                                                            .fontWeightMedium,
                                                    color:
                                                        theme.palette.grey[800],
                                                }}
                                            >
                                                {ws.active_workspace.name}
                                            </span>
                                        </Container>
                                    )
                                })}
                            </Container>
                        </Container>

                        {/* Footer */}
                        <Container
                            style={{
                                minHeight: 100,
                                maxHeight: 100,
                                borderTop: `1px solid ${theme.palette.grey[700]}`,
                                flex: 1,
                                padding: theme.spacing(1),
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{
                                    width: 150,
                                    textTransform: 'none',
                                    height: 50,
                                    marginRight: theme.spacing(1),
                                }}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <div style={{ flex: 1 }} />
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    width: 150,
                                    textTransform: 'none',
                                    height: 50,
                                }}
                                disabled={selectedCount === 0}
                                onClick={() => {
                                    setDoValidate(true)

                                    if (getIsValid()) {
                                        props
                                            .onCreate({
                                                name: title,
                                                description: description,
                                                date: date.toISOString(),
                                                apartments: selectedIds,
                                            })
                                            .then(onClose)
                                            .catch((e) => {
                                                toast.error(
                                                    `Unable to add deadline: ${e}`,
                                                )
                                            })
                                    }
                                }}
                            >
                                Create ({selectedCount})
                            </Button>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

const DESIRED_SIZE_PERCENT = 0.8

const screenH = window.innerHeight
const screenW = window.innerWidth
const aspectRatio = screenH / screenW

const h = screenH * DESIRED_SIZE_PERCENT
const w = screenW * DESIRED_SIZE_PERCENT * aspectRatio
