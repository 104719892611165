import { BaseWorkorder, ChangeOrderStatus, WorkorderStatus } from '../WorkOrder'
import { WorkorderAnalyticDict } from './types'
import { isWorkorderSafe } from '..'
import { ServiceAnalytic } from '.'

export const addWorkorderToAnalytics = (
    analytics: WorkorderAnalyticDict,
    workorder: BaseWorkorder,
) => {
    const today = new Date()
    today.setHours(23, 59, 59, 999)

    analytics[workorder.status] += 1
    analytics.workorderCount += 1
    analytics.serviceAreaCount += workorder.service_area_list.length

    workorder.changeorder_set?.forEach((co) => {
        switch (co.status) {
            case ChangeOrderStatus.APPROVED:
                analytics.changeOrderAnalytics.approvedCount += 1
                analytics.changeOrderAnalytics.approvedCost += co.price
                analytics.changeOrderAnalytics.approvedIncome +=
                    co.income_price ?? 0
                break
            case ChangeOrderStatus.PENDING:
                analytics.changeOrderAnalytics.pendingCount += 1
                analytics.changeOrderAnalytics.pendingCost += co.price
                analytics.changeOrderAnalytics.pendingIncome +=
                    co.income_price ?? 0
                break
        }
    })

    if (isWorkorderSafe(workorder)) {
        switch (workorder.status) {
            case WorkorderStatus.ASSIGNED:
                analytics.notStarted += 1
                if (today > workorder.endDate) {
                    analytics.behindTrack += 1
                }
                break
            case WorkorderStatus.IN_PROGRESS:
            case WorkorderStatus.PAUSE:
            case WorkorderStatus.GO_BACK:
                if (today > workorder.endDate) {
                    analytics.behindTrack += 1
                }
                break
        }
    } else {
        analytics.ghostCount += 1
        analytics.ghostServiceAreaCount += workorder.service_area_list.length
    }
}

export const createWorkorderAnalyticDict = (): WorkorderAnalyticDict => {
    return {
        unitCount: 0,
        serviceAreaCount: 0,
        ghostServiceAreaCount: 0,
        workorderCount: 0,
        ghostCount: 0,
        notStarted: 0,
        behindTrack: 0,
        invoicedBudget: 0,
        forecastedBudget: 0,
        serviceForecastedBudget: 0,
        lineItemForecastedBudget: 0,
        incomeForecast: 0,
        serviceBudgets: {},
        ASSIGNED: 0,
        IN_PROGRESS: 0,
        PAUSE: 0,
        GO_BACK: 0,
        COMPLETE: 0,
        APPROVED: 0,
        PRE_APPROVED: 0,
        INVOICED: 0,
        changeOrderAnalytics: {
            pendingCount: 0,
            pendingCost: 0,
            pendingIncome: 0,
            approvedCount: 0,
            approvedCost: 0,
            approvedIncome: 0,
        },
    }
}

export const createServiceAnalyticDict = (
    workorder: BaseWorkorder,
): ServiceAnalytic => {
    return {
        ...createWorkorderAnalyticDict(),
        serviceId: workorder.service_id ?? -1,
        serviceName: workorder.service_name ?? '',
        color: workorder.service_color ?? '#46b083',
    }
}
