import {
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    Paper,
    TextField,
    Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme,
} from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import { useForm } from 'react-hook-form'
import LoopIcon from '@material-ui/icons/Loop'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        closeBtn: {
            background: '#FFF',
            color: '#E9190F',
            border: '1px solid #E9190F',
            fontSize: '0.875rem',
            textTransform: 'inherit',
            fontWeight: 500,
            borderRadius: '5px',
            marginRight: '5px',
        },
        saveBtn: {
            background: '#008C85',
            color: '#FFF',
            border: '1px solid #008C85',
            fontSize: '0.875rem',
            textTransform: 'inherit',
            fontWeight: 500,
            borderRadius: '5px',
        },
        createConversationForm: {
            padding: '10px 0',
            '& .MuiFormControl-root': {
                margin: '5px 0',
            },
            '& .MuiFormLabel-root': {
                fontSize: '0.75rem',
                color: '#000',
                fontWeight: 700,
            },
            '& .MuiInputBase-input': {
                fontSize: '0.813rem',
            },
        },
        inputDropdown: {},
        rotateIcon: {
            animation: 'spin 01s linear infinite',
        },
    }),
)

interface State {
    personId: number
}

export const CreateConversation = (props: any) => {
    const classes = useStyles()

    const [isLoading, setIsLoading] = useState(false)
    const [directMessageDropdown, setDirectMessageDropdown] = useState([])
    const [isError, setIsError] = useState(false)

    const [values, setValues] = React.useState<State>({
        personId: 0,
    })

    const onSubmit = (data: any, event: any) => {
        event.preventDefault()
        console.log('data is...', data)
        setIsLoading(true)
        const userId = values.personId
        if (!values.personId) {
            setIsError(true)
            setIsLoading(false)
            return false
        } else {
            props
                .createChannel({
                    body: {
                        name: ``,
                        pair: true,
                        users: [userId],
                    },
                })
                .then((res: any) => {
                    setIsLoading(false)
                    props.handleClose()
                })
                .catch((err: any) => {
                    setIsLoading(false)
                })
        }
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'all',
    })

    const handleChange = (
        prop: keyof State,
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        console.log('create conversation....', event.target.value)
        // setValues({ ...values, personId: event.target.value })
    }

    console.log('is error....', isError)
    console.log('user list create conversation...', props.userDMChoices)
    return (
        <>
            <Container style={{ padding: '0 8px' }}>
                <Typography
                    component="p"
                    style={{
                        fontSize: '0.75rem',
                        color: '#8A8A8A',
                        lineHeight: '1.9em',
                        margin: '15px 0',
                    }}
                >
                    Please select a person down below.
                </Typography>
                <form
                    noValidate
                    autoComplete="off"
                    className={classes.createConversationForm}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormLabel component="legend">User Name:</FormLabel>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="person"
                            size="small"
                            options={[...(props.userDMChoices ?? [])]}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Person"
                                    {...register('personId', {
                                        required: true,
                                        // onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                                        //     handleChange('personId', event),
                                    })}
                                    error={
                                        errors?.personId?.type === 'required'
                                    }
                                />
                            )}
                            PaperComponent={({ children }) => (
                                <Paper
                                    style={{
                                        fontSize: '0.75rem',
                                        fontWeight: 400,
                                        color: '#000',
                                    }}
                                >
                                    {children}
                                </Paper>
                            )}
                            onChange={(
                                event: React.ChangeEvent<{}>,
                                newValue: any,
                            ) => {
                                if (newValue) {
                                    if (isError) {
                                        setIsError(false)
                                    }
                                    setValues({
                                        ...values,
                                        personId: newValue.id,
                                    })
                                    console.log('new Values....', newValue)
                                }
                            }}
                        />
                        <Box>
                            {errors.personId &&
                                errors.personId.type === 'required' && (
                                    <span className="error-message">
                                        This is required field
                                    </span>
                                )}
                        </Box>
                    </FormControl>

                    <div style={{ marginTop: '15px', textAlign: 'right' }}>
                        <Button
                            className={classes.closeBtn}
                            onClick={() => props.handleClose()}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                background: '#008C85',
                                color: '#FFF',
                                fontSize: '0.875rem',
                                textTransform: 'inherit',
                                fontWeight: 500,
                                borderRadius: '5px',
                                boxShadow: 'none',
                            }}
                            type="submit"
                        >
                            {isLoading ? (
                                <LoopIcon className={classes.rotateIcon} />
                            ) : (
                                'Save'
                            )}
                        </Button>
                    </div>
                </form>
            </Container>
        </>
    )
}
