import React from 'react'

import { Container, IContainerProps } from '../components'

export const ContainerRoot: React.FC<IContainerProps> = (props) => {
    return (
        <Container
            style={{
                ...props.style,
                height: 'calc(100vh - 104px)',
            }}
        >
            {props.children}
        </Container>
    )
}
