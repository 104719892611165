import {
    Popover,
    PopoverProps,
    Theme,
    createStyles,
    makeStyles,
    useTheme,
} from '@material-ui/core'
import React from 'react'
import { Container } from '../Container'

interface OptionStyle {
    container?: React.CSSProperties
    text?: React.CSSProperties
}

export interface IOption {
    title: string | JSX.Element
    onClick: React.MouseEventHandler<HTMLDivElement>
    color?: 'success' | 'secondary' | 'primary' | 'danger' | 'warning' | 'info'
    style?: OptionStyle
}

interface Props extends PopoverProps {
    options: IOption[]
    containerSyle?: React.CSSProperties
}

export const ButtonPopover: React.FC<Props> = (props) => {
    const { open, anchorEl, onClose, anchorOrigin, options } = props

    const theme = useTheme()

    const styles = useStyles()

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={anchorOrigin}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    width: 100,
                    ...props.containerSyle,
                }}
            >
                {options.map((opt, idx) => {
                    const color = opt.color
                    let colorClassName: string | undefined = undefined

                    if (color) {
                        const safeColor = color as any
                        const safeStyles = styles as any
                        colorClassName = opt.color
                            ? safeStyles[safeColor]
                            : undefined
                    }

                    return (
                        <Option
                            colorClassName={colorClassName}
                            key={`${opt.title}_${idx}`}
                            index={idx}
                            theme={theme}
                            isLast={idx === options.length - 1}
                            {...opt}
                        />
                    )
                })}
            </Container>
        </Popover>
    )
}

interface OptionProps extends IOption {
    index: number
    theme: Theme
    colorClassName?: string
    isLast?: boolean
}

const Option = (props: OptionProps) => {
    const { theme, isLast, colorClassName } = props

    let content = props.title
    if (typeof props.title === 'string') {
        content = (
            <span
                style={{
                    ...theme.typography.body1,
                    fontWeight: theme.typography.fontWeightBold,
                    ...props.style?.text,
                }}
            >
                {props.title}
            </span>
        )
    }

    return (
        <Container
            onClick={props.onClick}
            className={colorClassName}
            style={{
                borderBottom: isLast
                    ? undefined
                    : `1px solid ${theme.palette.grey[800]}`,
                padding: theme.spacing(1),
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',

                ...props.style?.container,
            }}
        >
            {content}
        </Container>
    )
}

export const useStyles = makeStyles((theme) =>
    createStyles({
        primary: {
            backgroundColor: '#cce5ff',
            color: '#004085',
            '&:hover': {
                backgroundColor: '#004085',
                color: '#cce5ff',
            },
        },
        secondary: {
            backgroundColor: '#e2e3e5',
            color: '#383d41',
            '&:hover': {
                backgroundColor: '#383d41',
                color: '#e2e3e5',
            },
        },
        success: {
            backgroundColor: '#d4edda',
            color: '#155724',
            '&:hover': {
                backgroundColor: '#155724',
                color: '#d4edda',
            },
        },
        danger: {
            backgroundColor: '#f8d7da',
            color: '#721c24',
            '&:hover': {
                backgroundColor: '#721c24',
                color: '#f8d7da',
            },
        },
        warning: {
            backgroundColor: '#fff3cd',
            color: '#856404',
            '&:hover': {
                backgroundColor: '#856404',
                color: '#fff3cd',
            },
        },
        info: {
            backgroundColor: '#d1ecf1',
            color: '#0c5460',
            '&:hover': {
                backgroundColor: '#0c5460',
                color: '#d1ecf1',
            },
        },
    }),
)
