import {
    Button,
    IconButton,
    Modal,
    Paper,
    Slide,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { Container } from '../../components'
import React, { useContext, useEffect, useState } from 'react'
import { AddAPhoto, AddCircleOutline, Close, Mail } from '@material-ui/icons'
import {
    Claimant,
    IncidentReport,
    IncidentSeverity,
    IncidentStatus,
    Lease,
    Witness,
    WitnessClaimant,
    getIncidentReoprtStatusColor,
    getUiString,
    traverse,
} from '../../models'
import { getLocation } from './IncidentReportTable'
import { axiosInstance, isEmailValid, toMMDDYYYY } from '../../helpers'
import {
    ClaimantComponent,
    WitnessComponent,
} from './IncidentReportCreateModal'
import { IncidentReportContext } from './IncidentReportRoot'
import { useRootInfrastructure } from '../../hooks'
interface Props {
    open: boolean
    onClose: () => void
    incidentReport?: IncidentReport
}

const baseWitnessClaimant: WitnessClaimant = {
    name: '',
    email: '',
    firstAid: '',
    medicalTransportProvider: '',
    objectCarryingOrHolding: '',
    reasonOnCommunity: '',
}

const QUESTION_WIDTH = 300

export const IncidentReportModal = (props: Props) => {
    const { open, onClose, incidentReport } = props
    const theme = useTheme()

    const [leases, setLeases] = useState<Lease[]>([])
    const { tree } = useRootInfrastructure(true)
    useEffect(() => {
        let unitIdList = ''
        if (incidentReport?.unit?.id) {
            unitIdList = `${incidentReport?.unit.id}`
        } else if (incidentReport?.folder?.id) {
            traverse(tree.root, (folder) => {
                if (folder.id === incidentReport?.folder?.id) {
                    // traverse the matching fodler and all its subfolders to get the unit options
                    traverse(folder, (child_folder) => {
                        child_folder.units.forEach((unit) => {
                            unitIdList += `${unit.id},`
                        })
                    })
                }
            })
        }
        if (unitIdList.length > 0) {
            unitIdList = unitIdList.slice(0, -1)
            axiosInstance
                .get('lease/', {
                    params: {
                        units: unitIdList,
                        // overlap_start: new Date(),
                        // overlap_end: new Date(),
                    },
                })
                .then((res) => {
                    setLeases(res.data)
                })
        }
    }, [incidentReport])

    return (
        <Modal
            open={open}
            onClose={onClose}
            title="Incident Report Details"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: '1200px',
                        height: '800px',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <IncidentReportForm
                        incidentReport={incidentReport}
                        onClose={onClose}
                    />
                </Paper>
            </Slide>
        </Modal>
    )
}

interface FormProps {
    incidentReport?: IncidentReport
    onClose: () => void
    leases?: Lease[]
}

export const IncidentReportForm = (formProps: FormProps) => {
    const { incidentReport, onClose, leases } = formProps
    const theme = useTheme()
    let severityColor = theme.palette.error.main

    const {
        getPDF,
        transitionStatus,
        organization,
        addPictures,
        updateIncidentReport,
    } = useContext(IncidentReportContext)

    const [newWitness, setNewWitness] = useState<WitnessClaimant | undefined>()
    const [newClaimant, setNewClaimant] = useState<
        WitnessClaimant | undefined
    >()
    const [toEmail, setToEmail] = useState('')
    const [validateEmail, setValidateEmail] = useState(false)
    let content = <></>
    if (incidentReport != undefined) {
        if (incidentReport.severity === IncidentSeverity.LOW) {
            severityColor = theme.palette.primary.main
        }
        if (incidentReport.severity === IncidentSeverity.MID) {
            severityColor = theme.palette.warning.main
        }

        let nextStatus = IncidentStatus.REVIEWED
        if (incidentReport.status === IncidentStatus.REVIEWED) {
            nextStatus = IncidentStatus.SUBMITTED
        }
        content = (
            <Container
                style={{
                    flexDirection: 'column',
                    padding: theme.spacing(1),
                    maxHeight: '100%',
                    overflowY: 'scroll',
                }}
            >
                <Container
                    style={{
                        alignItems: 'center',
                        marginBottom: theme.spacing(1),
                        justifyContent: 'flex-end',
                    }}
                >
                    <Container
                        style={{
                            ...theme.typography.h5,
                            fontWeight: theme.typography.fontWeightBold,

                            margin: theme.spacing(1, 0, 2, 0),
                            flex: 1,
                        }}
                    >
                        {incidentReport.title}
                    </Container>
                    <Container style={{ alignItems: 'center' }}>
                        <TextField
                            variant="outlined"
                            error={validateEmail && !isEmailValid(toEmail)}
                            helperText={
                                validateEmail &&
                                !isEmailValid(toEmail) &&
                                'Email Invalid'
                            }
                            placeholder="Email"
                            value={toEmail}
                            onChange={(e) => setToEmail(e.target.value)}
                        />
                        <Tooltip title={'Get PDF Report'}>
                            <IconButton
                                onClick={() => {
                                    if (isEmailValid(toEmail)) {
                                        setValidateEmail(false)
                                        getPDF(toEmail)
                                    } else {
                                        setValidateEmail(true)
                                    }
                                }}
                            >
                                <Mail />
                            </IconButton>
                        </Tooltip>
                        <span
                            style={{
                                border: `2px solid ${getIncidentReoprtStatusColor(
                                    incidentReport.status,
                                    theme,
                                )}`,
                                padding: theme.spacing(1),
                                borderRadius: theme.shape.borderRadius,
                                color: getIncidentReoprtStatusColor(
                                    incidentReport.status,
                                    theme,
                                ),
                                fontWeight: theme.typography.fontWeightBold,
                                marginRight: theme.spacing(1),
                                height: 40,
                                marginLeft: theme.spacing(2),
                            }}
                        >
                            {getUiString(incidentReport.status)}
                        </span>
                        {incidentReport.status !==
                            IncidentStatus.NOT_SUBMITTED &&
                            incidentReport.status !==
                                IncidentStatus.SUBMITTED && (
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        transitionStatus(incidentReport)
                                    }}
                                >
                                    {nextStatus}
                                </Button>
                            )}
                        {incidentReport.status === IncidentStatus.REVIEWED && (
                            <Button
                                color="secondary"
                                variant="outlined"
                                style={{ marginLeft: theme.spacing(1) }}
                                onClick={() => {
                                    transitionStatus(incidentReport, true)
                                }}
                            >
                                Not Submitted
                            </Button>
                        )}
                        <Tooltip title="Exit">
                            <IconButton
                                onClick={() => {
                                    onClose()
                                }}
                            >
                                <Close
                                    fontSize="inherit"
                                    style={{
                                        marginRight: theme.spacing(1),
                                        color: theme.palette.darkGreen.main,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Container>
                </Container>
                {/* Body */}
                <Container
                    style={{
                        flexDirection: 'column',
                        maxHeight: '100%',
                        overflowY: 'scroll',
                    }}
                >
                    {/* Top */}
                    <Container
                        style={{
                            borderTop: `1px solid ${theme.palette.grey[400]}`,
                            paddingTop: theme.spacing(1),
                            paddingRight: theme.spacing(1),
                        }}
                    >
                        {/* Top Left */}
                        <Container style={{ flex: 1 }}>
                            {/* Left */}
                            <Container style={{ flexDirection: 'column' }}>
                                <span
                                    style={{
                                        marginTop: theme.spacing(1),
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Location:
                                </span>

                                <span
                                    style={{
                                        fontWeight: 'bold',
                                        marginTop: theme.spacing(1),
                                    }}
                                >
                                    Reporter:
                                </span>
                                <span
                                    style={{
                                        marginTop: theme.spacing(1),
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Incident Date:
                                </span>
                                <Container
                                    style={{
                                        marginTop: theme.spacing(1),
                                    }}
                                >
                                    <span
                                        style={{
                                            border: `2px solid ${theme.palette.primary.main}`,
                                            padding: theme.spacing(1),
                                            borderRadius:
                                                theme.shape.borderRadius,
                                            color: theme.palette.primary.main,
                                            fontWeight: 'bold',
                                            minWidth: 100,
                                            textAlign: 'center',
                                        }}
                                    >
                                        {incidentReport.incident_type.name}
                                    </span>
                                    <span
                                        style={{
                                            border: `2px solid ${severityColor}`,
                                            padding: theme.spacing(1),
                                            borderRadius:
                                                theme.shape.borderRadius,
                                            color: severityColor,
                                            fontWeight: 'bold',
                                            marginLeft: theme.spacing(1),
                                            minWidth: 100,
                                            textAlign: 'center',
                                        }}
                                    >
                                        {incidentReport.severity[0] +
                                            incidentReport.severity
                                                .substring(1)
                                                .toLocaleLowerCase()}
                                    </span>
                                </Container>
                            </Container>

                            {/* Right */}
                            <Container style={{ flexDirection: 'column' }}>
                                <span
                                    style={{
                                        marginTop: theme.spacing(1),
                                        overflowY: 'scroll',
                                    }}
                                >
                                    {getLocation(incidentReport)}
                                </span>
                                <span style={{ marginTop: theme.spacing(1) }}>
                                    {incidentReport?.reporting_employee.name}
                                </span>
                                <span style={{ marginTop: theme.spacing(1) }}>
                                    {toMMDDYYYY(new Date(incidentReport.date))}
                                </span>
                            </Container>
                        </Container>
                        {/* Top Right */}
                        {incidentReport.images.map((image, idx) => {
                            return (
                                <Container
                                    style={{ marginLeft: theme.spacing(1) }}
                                    key={`INCIDENT_REPORT_DETAIL_IMAGE_${idx}`}
                                >
                                    <img
                                        src={image.file}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                            borderRadius:
                                                theme.shape.borderRadius,
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            window.open(image.file)
                                        }}
                                    />
                                </Container>
                            )
                        })}
                        {incidentReport.images.length === 0 && (
                            <span
                                style={{
                                    flex: 1,
                                    alignSelf: 'center',
                                    ...theme.typography.h3,
                                }}
                            >
                                No Images
                            </span>
                        )}
                        <input
                            accept="image/png, image/jpeg, image/jpg"
                            id="icon-button-file"
                            type="file"
                            style={{ display: 'none' }}
                            multiple
                            onChange={(event) => {
                                if (event.target.files) {
                                    const fileList: File[] = []
                                    for (
                                        let index = 0;
                                        index < event.target.files.length;
                                        index++
                                    ) {
                                        fileList.push(event.target.files[index])
                                    }
                                    addPictures(incidentReport, fileList)
                                }
                            }}
                        />
                        {incidentReport.images.length < 3 && (
                            <label htmlFor="icon-button-file">
                                <Container
                                    style={{
                                        height: 150,
                                        width: 150,
                                        borderRadius: theme.shape.borderRadius,
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        backgroundColor:
                                            theme.palette.grey[200],
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginLeft: theme.spacing(1),
                                    }}
                                >
                                    <AddAPhoto fontSize="large" />
                                </Container>
                            </label>
                        )}
                    </Container>

                    <Container
                        style={{
                            maxHeight: '100px',
                            marginTop: theme.spacing(2),
                            borderTop: `1px solid ${theme.palette.grey[400]}`,
                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                            minHeight: '100px',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: 'bold',
                                marginRight: theme.spacing(1),
                            }}
                        >
                            Description:
                        </span>
                        <span
                            style={{
                                overflowY: 'scroll',
                                ...theme.typography.caption,
                                paddingTop: theme.spacing(1),
                            }}
                        >
                            {incidentReport.description}
                        </span>
                    </Container>
                    {/* Inspection and Police / Fire */}
                    <Container
                        style={{
                            justifyContent: 'space-between',
                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                            flexDirection: 'column',
                        }}
                    >
                        <Container
                            style={{
                                flex: 1,
                                textAlign: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: 'bold',
                                    paddingLeft: theme.spacing(1),
                                    width: QUESTION_WIDTH,
                                    textAlign: 'left',
                                }}
                            >
                                On {organization?.name} master insurance?
                            </span>
                            <span
                                style={{
                                    paddingLeft: theme.spacing(1),
                                    alignSelf: 'center',
                                }}
                            >
                                {incidentReport.on_master_insurance
                                    ? 'Yes'
                                    : 'No'}
                            </span>
                        </Container>
                        <Container
                            style={{
                                flex: 1,
                                textAlign: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: 'bold',
                                    paddingLeft: theme.spacing(1),
                                    width: QUESTION_WIDTH,
                                    textAlign: 'left',
                                }}
                            >
                                Was the tenant negligible?
                            </span>
                            <span
                                style={{
                                    paddingLeft: theme.spacing(1),
                                    alignSelf: 'center',
                                }}
                            >
                                {incidentReport.tenant_negligible
                                    ? 'Yes'
                                    : 'No'}
                            </span>
                        </Container>
                        <Container
                            style={{
                                flex: 1,
                                textAlign: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: 'bold',
                                    paddingLeft: theme.spacing(1),
                                    width: QUESTION_WIDTH,
                                    textAlign: 'left',
                                }}
                            >
                                Bodily Injury?
                            </span>
                            <span
                                style={{
                                    paddingLeft: theme.spacing(1),
                                    alignSelf: 'center',
                                }}
                            >
                                {incidentReport.bodily_injury ? 'Yes' : 'No'}
                            </span>
                        </Container>
                        {incidentReport.bodily_injury && (
                            <Container style={{ flexDirection: 'row' }}>
                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        margin: theme.spacing(1),
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                        minHeight: 80,
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                            paddingLeft: theme.spacing(1),
                                        }}
                                    >
                                        Bodily Injury Description
                                    </span>
                                    <span
                                        style={{
                                            ...theme.typography.caption,
                                            margin: theme.spacing(1),
                                        }}
                                    >
                                        {
                                            incidentReport.bodily_injury
                                                .description
                                        }
                                    </span>
                                </Container>

                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        margin: theme.spacing(1),
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                        minHeight: 80,
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                            paddingLeft: theme.spacing(1),
                                        }}
                                    >
                                        Lighting Conditions
                                    </span>
                                    <span
                                        style={{
                                            ...theme.typography.caption,
                                            margin: theme.spacing(1),
                                        }}
                                    >
                                        {
                                            incidentReport.bodily_injury
                                                .lighting_conditions
                                        }
                                    </span>
                                </Container>

                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        margin: theme.spacing(1),
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                        minHeight: 80,
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                            paddingLeft: theme.spacing(1),
                                        }}
                                    >
                                        Weather Conditions
                                    </span>
                                    <span
                                        style={{
                                            ...theme.typography.caption,
                                            margin: theme.spacing(1),
                                        }}
                                    >
                                        {
                                            incidentReport.bodily_injury
                                                .weather_conditions
                                        }
                                    </span>
                                </Container>

                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        margin: theme.spacing(1),
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                        minHeight: 80,
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                            paddingLeft: theme.spacing(1),
                                        }}
                                    >
                                        Walkway / Ground Conditions
                                    </span>
                                    <span
                                        style={{
                                            ...theme.typography.caption,
                                            margin: theme.spacing(1),
                                        }}
                                    >
                                        {
                                            incidentReport.bodily_injury
                                                .walkway_ground_conditions
                                        }
                                    </span>
                                </Container>
                            </Container>
                        )}

                        <Container
                            style={{
                                flex: 1,
                                textAlign: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: 'bold',
                                    paddingLeft: theme.spacing(1),
                                    width: QUESTION_WIDTH,
                                    textAlign: 'left',
                                }}
                            >
                                Damage to the Property?
                            </span>
                            <span
                                style={{
                                    paddingLeft: theme.spacing(1),
                                    alignSelf: 'center',
                                }}
                            >
                                {incidentReport.damage_to_property
                                    ? 'Yes'
                                    : 'No'}
                            </span>
                        </Container>
                        {incidentReport.damage_to_property && (
                            <Container style={{ flexDirection: 'row' }}>
                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        margin: theme.spacing(1),
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                        minHeight: 80,
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                            paddingLeft: theme.spacing(1),
                                        }}
                                    >
                                        Type of Loss
                                    </span>
                                    <span
                                        style={{
                                            ...theme.typography.caption,
                                            margin: theme.spacing(1),
                                        }}
                                    >
                                        {
                                            incidentReport.damage_to_property
                                                .type_of_loss
                                        }
                                    </span>
                                </Container>

                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        margin: theme.spacing(1),
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                        minHeight: 80,
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                            paddingLeft: theme.spacing(1),
                                        }}
                                    >
                                        Loss Cause
                                    </span>
                                    <span
                                        style={{
                                            ...theme.typography.caption,
                                            margin: theme.spacing(1),
                                        }}
                                    >
                                        {
                                            incidentReport.damage_to_property
                                                .what_caused_the_loss
                                        }
                                    </span>
                                </Container>

                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        margin: theme.spacing(1),
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                        minHeight: 80,
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                            paddingLeft: theme.spacing(1),
                                        }}
                                    >
                                        Estimated Damage Cost
                                    </span>
                                    <span
                                        style={{
                                            ...theme.typography.caption,
                                            margin: theme.spacing(1),
                                        }}
                                    >
                                        $
                                        {
                                            incidentReport.damage_to_property
                                                .estimated_cost
                                        }
                                    </span>
                                </Container>
                            </Container>
                        )}

                        <Container
                            style={{
                                flex: 1,
                                textAlign: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: 'bold',
                                    paddingLeft: theme.spacing(1),
                                    width: QUESTION_WIDTH,
                                    textAlign: 'left',
                                }}
                            >
                                Location Inspected After?
                            </span>
                            <span
                                style={{
                                    paddingLeft: theme.spacing(1),
                                    alignSelf: 'center',
                                }}
                            >
                                {incidentReport.location_inspected_after
                                    ? 'Yes'
                                    : 'No'}
                            </span>
                        </Container>
                        {incidentReport.location_inspected_after && (
                            <Container style={{ flexDirection: 'row' }}>
                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        margin: theme.spacing(1),
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                        minHeight: 80,
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                            paddingLeft: theme.spacing(1),
                                        }}
                                    >
                                        Inspector
                                    </span>
                                    <span
                                        style={{
                                            ...theme.typography.caption,
                                            margin: theme.spacing(1),
                                        }}
                                    >
                                        {
                                            incidentReport
                                                .location_inspected_after
                                                .inspector
                                        }
                                    </span>
                                </Container>

                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        margin: theme.spacing(1),
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                        minHeight: 80,
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                            paddingLeft: theme.spacing(1),
                                        }}
                                    >
                                        Inspected Date
                                    </span>
                                    <span
                                        style={{
                                            ...theme.typography.caption,
                                            margin: theme.spacing(1),
                                        }}
                                    >
                                        {
                                            incidentReport
                                                .location_inspected_after.date
                                        }
                                    </span>
                                </Container>
                            </Container>
                        )}

                        <Container
                            style={{
                                flex: 1,
                                textAlign: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: 'bold',
                                    paddingLeft: theme.spacing(1),
                                    width: QUESTION_WIDTH,
                                    textAlign: 'left',
                                }}
                            >
                                Police or Fire Called?
                            </span>
                            <span
                                style={{
                                    paddingLeft: theme.spacing(1),
                                    alignSelf: 'center',
                                }}
                            >
                                {incidentReport.police_or_fire_called
                                    ? 'Yes'
                                    : 'No'}
                            </span>
                        </Container>
                        {incidentReport.police_or_fire_called && (
                            <Container style={{ flexDirection: 'row' }}>
                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        margin: theme.spacing(1),
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                        minHeight: 80,
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                            paddingLeft: theme.spacing(1),
                                        }}
                                    >
                                        Police / Fire Contact
                                    </span>
                                    <span
                                        style={{
                                            ...theme.typography.caption,
                                            margin: theme.spacing(1),
                                        }}
                                    >
                                        {
                                            incidentReport.police_or_fire_called
                                                .contact
                                        }
                                    </span>
                                </Container>

                                <Container
                                    style={{
                                        flex: 1,
                                        flexDirection: 'column',
                                        margin: theme.spacing(1),
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                        minHeight: 80,
                                    }}
                                >
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                            paddingLeft: theme.spacing(1),
                                        }}
                                    >
                                        Police / Fire Case Number
                                    </span>
                                    <span
                                        style={{
                                            ...theme.typography.caption,
                                            margin: theme.spacing(1),
                                        }}
                                    >
                                        {
                                            incidentReport.police_or_fire_called
                                                .case_number
                                        }
                                    </span>
                                </Container>
                            </Container>
                        )}
                    </Container>

                    {/* Claimants & Witnesses */}
                    <Container style={{ flex: 1, flexDirection: 'column' }}>
                        {/* Claimants */}
                        <Container
                            style={{
                                flex: 1,
                                flexDirection: 'column',
                                marginRight: theme.spacing(1),
                            }}
                        >
                            <Container style={{ alignItems: 'center' }}>
                                <span
                                    style={{
                                        ...theme.typography.h6,
                                        marginRight: theme.spacing(1),
                                    }}
                                >
                                    Claimants: {incidentReport.claimants.length}
                                </span>
                                <Container
                                    onClick={() => {
                                        setNewClaimant(baseWitnessClaimant)
                                    }}
                                >
                                    <AddCircleOutline color="primary" />
                                </Container>
                            </Container>
                            <Container style={{ flex: 1 }}>
                                <Container
                                    style={{ flexDirection: 'column', flex: 1 }}
                                >
                                    {incidentReport.claimants
                                        .filter((claim, idx) => idx % 2 === 0)
                                        .map((claimant, idx) => {
                                            return (
                                                <ClaimantBox
                                                    claimant={claimant}
                                                    key={`CLAIMANT_LEFT_DETAIL_MODAL_${idx}`}
                                                />
                                            )
                                        })}
                                    {incidentReport.claimants.length % 2 ===
                                        0 &&
                                        newClaimant && (
                                            <ClaimantComponent
                                                witnessClaimant={newClaimant}
                                                setWitnessClaimant={
                                                    setNewClaimant
                                                }
                                                removeWitnessClaimant={() => {
                                                    setNewClaimant(undefined)
                                                }}
                                                leases={leases}
                                                incidentReport={incidentReport}
                                                handleAddWitnessClaimant={(
                                                    claimant,
                                                ) => {
                                                    setNewClaimant(undefined)
                                                    updateIncidentReport({
                                                        ...incidentReport,
                                                        claimants: [
                                                            ...(incidentReport.claimants ??
                                                                []),
                                                            claimant,
                                                        ],
                                                    })
                                                }}
                                                theme={theme}
                                            />
                                        )}
                                </Container>
                                <Container
                                    style={{ flexDirection: 'column', flex: 1 }}
                                >
                                    {incidentReport.claimants
                                        .filter((claim, idx) => idx % 2 !== 0)
                                        .map((claimant, idx) => {
                                            return (
                                                <ClaimantBox
                                                    claimant={claimant}
                                                    key={`CLAIMANT_RIGHT_DETAIL_MODAL_${idx}`}
                                                />
                                            )
                                        })}
                                    {incidentReport.claimants.length % 2 !==
                                        0 &&
                                        newClaimant && (
                                            <ClaimantComponent
                                                witnessClaimant={newClaimant}
                                                setWitnessClaimant={
                                                    setNewClaimant
                                                }
                                                removeWitnessClaimant={() => {
                                                    setNewClaimant(undefined)
                                                }}
                                                leases={leases}
                                                incidentReport={incidentReport}
                                                handleAddWitnessClaimant={(
                                                    claimant,
                                                ) => {
                                                    setNewClaimant(undefined)
                                                    updateIncidentReport({
                                                        ...incidentReport,
                                                        claimants: [
                                                            ...(incidentReport.claimants ??
                                                                []),
                                                            claimant,
                                                        ],
                                                    })
                                                }}
                                                theme={theme}
                                            />
                                        )}
                                </Container>
                            </Container>
                        </Container>

                        {/* Witnesses */}
                        <Container style={{ flex: 1, flexDirection: 'column' }}>
                            <Container style={{ alignItems: 'center' }}>
                                <span
                                    style={{
                                        ...theme.typography.h6,
                                        marginRight: theme.spacing(1),
                                    }}
                                >
                                    Witnesses: {incidentReport.witnesses.length}
                                </span>
                                <Container
                                    onClick={() => {
                                        setNewWitness(baseWitnessClaimant)
                                    }}
                                >
                                    <AddCircleOutline color="primary" />
                                </Container>
                            </Container>
                            <Container style={{ flex: 1 }}>
                                <Container
                                    style={{ flexDirection: 'column', flex: 1 }}
                                >
                                    {incidentReport.witnesses
                                        .filter((wit, idx) => idx % 2 === 0)
                                        .map((witness, idx) => {
                                            return (
                                                <WitnessBox
                                                    witness={witness}
                                                    key={`WITNESS_LEFT_DETAIL_MODAL_${idx}`}
                                                />
                                            )
                                        })}
                                    {incidentReport.witnesses.length % 2 ===
                                        0 &&
                                        newWitness && (
                                            <WitnessComponent
                                                witnessClaimant={newWitness}
                                                setWitnessClaimant={
                                                    setNewWitness
                                                }
                                                removeWitnessClaimant={() => {
                                                    setNewWitness(undefined)
                                                }}
                                                isWitness
                                                leases={leases}
                                                incidentReport={incidentReport}
                                                handleAddWitnessClaimant={(
                                                    witness,
                                                ) => {
                                                    setNewWitness(undefined)
                                                    updateIncidentReport({
                                                        ...incidentReport,
                                                        witnesses: [
                                                            ...(incidentReport.witnesses ??
                                                                []),
                                                            witness,
                                                        ],
                                                    })
                                                }}
                                                theme={theme}
                                            />
                                        )}
                                </Container>
                                <Container
                                    style={{ flexDirection: 'column', flex: 1 }}
                                >
                                    {incidentReport.witnesses
                                        .filter((wit, idx) => idx % 2 !== 0)
                                        .map((witness, idx) => {
                                            return (
                                                <WitnessBox
                                                    witness={witness}
                                                    key={`WITNESS_RIGHT_DETAIL_MODAL_${idx}`}
                                                />
                                            )
                                        })}

                                    {incidentReport.witnesses.length % 2 !==
                                        0 &&
                                        newWitness && (
                                            <WitnessComponent
                                                witnessClaimant={newWitness}
                                                setWitnessClaimant={
                                                    setNewWitness
                                                }
                                                removeWitnessClaimant={() => {
                                                    setNewWitness(undefined)
                                                }}
                                                leases={leases}
                                                isWitness
                                                incidentReport={incidentReport}
                                                handleAddWitnessClaimant={(
                                                    witness,
                                                ) => {
                                                    setNewWitness(undefined)
                                                    updateIncidentReport({
                                                        ...incidentReport,
                                                        witnesses: [
                                                            ...(incidentReport.witnesses ??
                                                                []),
                                                            witness,
                                                        ],
                                                    })
                                                }}
                                                theme={theme}
                                            />
                                        )}
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>
        )
    }

    return content
}

interface ClaimantProps {
    claimant: Claimant
}

const ClaimantBox = (props: ClaimantProps) => {
    const { claimant } = props
    const theme = useTheme()

    return (
        <Container
            style={{
                height: 140,
                border: `1px solid ${theme.palette.grey[400]}`,
                width: '90%',
                padding: theme.spacing(1),
                marginBottom: theme.spacing(1),
                marginRight: theme.spacing(1),
            }}
        >
            <Container style={{ flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>Name:</span>
                <span style={{ fontWeight: 'bold' }}>Reason on Community:</span>
                <span style={{ fontWeight: 'bold' }}>
                    Seeking Compensation:
                </span>
                <span style={{ fontWeight: 'bold' }}>
                    Object They were carrying:
                </span>
                <span style={{ fontWeight: 'bold' }}>First Aid Provided:</span>
                <span style={{ fontWeight: 'bold' }}>
                    Medical Transport Provider:
                </span>
            </Container>
            <Container
                style={{
                    flexDirection: 'column',
                    marginLeft: theme.spacing(1),
                }}
            >
                <span style={{ ...theme.typography.caption }}>
                    {claimant.user.name}
                </span>
                <span style={{ ...theme.typography.caption }}>
                    {claimant.reason_on_the_community}
                </span>
                <span style={{ ...theme.typography.caption }}>
                    {claimant.seeking_compensation ? 'Yes' : 'No'}
                </span>
                <span style={{ ...theme.typography.caption }}>
                    {claimant.object_carrying_description}
                </span>
                <span style={{ ...theme.typography.caption }}>
                    {claimant.first_aid_description}
                </span>
                <span style={{ ...theme.typography.caption }}>
                    {claimant.medical_transport_provider}
                </span>
            </Container>
        </Container>
    )
}

interface WitnessProps {
    witness: Witness
}

const WitnessBox = (props: WitnessProps) => {
    const { witness } = props
    const theme = useTheme()
    return (
        <Container
            style={{
                height: 60,
                border: `1px solid ${theme.palette.grey[400]}`,
                width: '90%',
                padding: theme.spacing(1),
                marginBottom: theme.spacing(1),
                marginRight: theme.spacing(1),
            }}
        >
            <Container style={{ flexDirection: 'column' }}>
                <span style={{ fontWeight: 'bold' }}>Name:</span>
                <span style={{ fontWeight: 'bold' }}>Reason on Community:</span>
            </Container>
            <Container
                style={{
                    flexDirection: 'column',
                    marginLeft: theme.spacing(1),
                }}
            >
                <span style={{ ...theme.typography.caption }}>
                    {witness.user.name}
                </span>
                <span style={{ ...theme.typography.caption }}>
                    {witness.reason_on_the_community}
                </span>
            </Container>
        </Container>
    )
}
