import { useEffect, useCallback, useMemo } from 'react'

import {
    getAreaStatusTagList,
    RootState,
    setAreaStatusTagList,
    CreateAreaStatusTagRequest,
    EditAreaStatusTagRequest,
    createAreaStatusTag,
    patchAreaStatusTag,
} from '../store'

import { useAppDispatch } from '../hooks'
import { useSelector } from 'react-redux'
import { AreaStatusTag, ModelMap } from '../models'

interface Options {
    getListForSchedule?: number
    cleanUp?: boolean
}

export const useAreaStatusTag = (options?: Options) => {
    const dispatch = useAppDispatch()

    const areaStatusTagList = useSelector(
        (state: RootState) => state.workorder.schedule.areaStatusTagList,
    )

    const getAreaStatusTags = useCallback(
        (scheduleId?: number) => {
            dispatch(
                getAreaStatusTagList({
                    params: { schedule: scheduleId ?? -1 },
                }),
            )
        },
        [dispatch],
    )

    useEffect(() => {
        if (options?.getListForSchedule && options.getListForSchedule > 0) {
            getAreaStatusTags(options.getListForSchedule)
        }

        return () => {
            if (options?.cleanUp) {
                dispatch(setAreaStatusTagList())
            }
        }
    }, [
        options?.getListForSchedule,
        options?.cleanUp,
        getAreaStatusTags,
        dispatch,
    ])

    const areaStatusTagMap = useMemo(() => {
        if (!areaStatusTagList) return {}
        return areaStatusTagList.reduce((acc, ast) => {
            acc[ast.area.id] = ast
            return acc
        }, {} as ModelMap<AreaStatusTag>)
    }, [areaStatusTagList])

    const createAreaStatusTagCallback = useCallback(
        (req: CreateAreaStatusTagRequest) => dispatch(createAreaStatusTag(req)),
        [dispatch],
    )

    const editAreaStatusTagCallback = useCallback(
        (req: EditAreaStatusTagRequest) => dispatch(patchAreaStatusTag(req)),
        [dispatch],
    )

    return {
        getAreaStatusTags,
        areaStatusTagList,
        areaStatusTagMap,
        createAreaStatusTag: createAreaStatusTagCallback,
        editAreaStatusTag: editAreaStatusTagCallback,
    }
}

export type AreaStatusTagController = ReturnType<typeof useAreaStatusTag>
