import React from 'react'
import { WorkorderContext, useWorkorderState } from '../../contexts'
import { InvoiceHome } from './InvoiceHome'
import {
    useVendorInvoiceState,
    VendorInvoiceContext,
} from '../../contexts/useVendorInvoice'

export const InvoiceRoot = () => {
    const workorderState = useWorkorderState()
    const vendorInvoiceState = useVendorInvoiceState()

    return (
        <WorkorderContext.Provider value={workorderState}>
            <VendorInvoiceContext.Provider value={vendorInvoiceState}>
                <InvoiceHome />
            </VendorInvoiceContext.Provider>
        </WorkorderContext.Provider>
    )
}
