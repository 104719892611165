import { Button, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container, NumberInput } from '../../components'
import { BidStatus, RFP } from '../../models'
import { usePopover } from '../../hooks/usePopover'
import { ListPopover } from '../../components/ListPopover/ListPopover'
import { AccessTime, Apartment, WorkOutline } from '@material-ui/icons'
import { CountdownTimer } from '../../components/CountdownTimer'
import moment from 'moment'
import { rfpApi, updateOrCreateBidRequest } from '../../contexts'
import { useUser } from '../../hooks'
import { convertAddressToParams } from '../../models/Address/services'
import { DisplayService } from '../../components/DisplayService'

interface Props {
    rfp: RFP
}

export const VendorEZNowDetail = (props: Props) => {
    const { rfp } = props

    const [price, setPrice] = useState<number | undefined>()
    const [expired, setExpired] = useState(false)

    const [doValidate, setDoValidate] = useState(false)

    const { updateBid } = rfpApi()

    const theme = useTheme()

    const { workspaceUser } = useUser()

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    const endDate = moment(new Date(rfp.created_date)).add(30, 'm').toDate()

    const bid = rfp.bids.find(
        (bid) => bid.vendor.id === workspaceUser?.active_workspace.id,
    )

    useEffect(() => {
        setDoValidate(false)
        setExpired(false)
        setPrice(undefined)
    }, [])

    return (
        <Container
            direction="column"
            flex={1}
            style={{
                maxHeight: 'calc(100vh - 200px)',
                height: 'calc(100vh - 200px)',
            }}
            scrollY
        >
            <Container
                direction="column"
                style={{ width: '100%', margin: theme.spacing(2, 0, 2, 0) }}
            >
                <Container style={{ justifyContent: 'space-between' }}>
                    {rfp.budget > 0 ? (
                        <Container
                            style={{
                                ...theme.typography.h4,
                                fontWeight: 600,
                                alignItems: 'center',
                            }}
                        >
                            Pay ${rfp.budget}
                        </Container>
                    ) : (
                        <Container style={{ alignItems: 'center' }}>
                            <span
                                style={{
                                    ...theme.typography.h5,
                                    fontWeight: 600,
                                    marginRight: theme.spacing(2),
                                }}
                            >
                                Set Price:
                            </span>
                            <NumberInput
                                prefix={'$'}
                                variant="outlined"
                                value={price}
                                onChange={(e) => {
                                    if (e.target.value === '') {
                                        setPrice(undefined)
                                    } else {
                                        setPrice(Number(e.target.value))
                                    }
                                }}
                                size="small"
                                error={doValidate && !price}
                                helperText={doValidate && !price && 'Required'}
                            />
                        </Container>
                    )}
                    <Container
                        direction="column"
                        style={{ margin: theme.spacing(1, 4, 0, 0) }}
                    >
                        <Container
                            style={{
                                ...theme.typography.h6,
                                fontWeight: 600,
                                alignItems: 'center',
                            }}
                        >
                            <AccessTime
                                style={{ marginRight: theme.spacing(1) }}
                            />
                            Time Left
                        </Container>
                        <Container>
                            <CountdownTimer
                                endDate={endDate.toISOString()}
                                setExpired={setExpired}
                            />
                        </Container>
                    </Container>
                </Container>
                {bid?.status === BidStatus.INVITATION && (
                    <Container style={{ margin: theme.spacing(2, 0, 2, 0) }}>
                        <Button
                            variant="contained"
                            style={{
                                marginRight: theme.spacing(2),
                                backgroundColor: theme.palette.primary.dark,
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                                width: '300px',
                            }}
                            onClick={() => {
                                const body: updateOrCreateBidRequest = {
                                    status: BidStatus.SUBMITTED,
                                }
                                if (rfp.budget === 0) {
                                    if (!price) {
                                        setDoValidate(true)
                                        return
                                    }
                                    body.group_bid_price = price
                                } else {
                                    body.group_bid_price = rfp.budget
                                }
                                if (bid) {
                                    updateBid(bid.id, body, rfp)
                                }
                            }}
                            // disabled={expired}
                        >
                            Submit Bid
                        </Button>
                        <Button
                            variant="outlined"
                            style={{
                                textTransform: 'none',
                                cursor: 'pointer',
                                width: '300px',
                            }}
                            color="secondary"
                            size="small"
                            onClick={() => {
                                const body: updateOrCreateBidRequest = {
                                    status: BidStatus.DECLINED,
                                }
                                if (bid) {
                                    updateBid(bid.id, body, rfp)
                                    // .then(() => {
                                    //     toast.success(`${rfp.name} has been declined!`)
                                    // })
                                }
                            }}
                            // disabled={expired}
                        >
                            Deny
                        </Button>
                    </Container>
                )}
            </Container>
            <Container>
                <iframe
                    width="750"
                    height="350"
                    loading="lazy"
                    src={`https://www.google.com/maps/embed/v1/place?key=${
                        process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                    }
                        &q=${convertAddressToParams(
                            rfp.apartment.primary_address,
                        )}`}
                ></iframe>
            </Container>
            <Container
                style={{
                    marginTop: theme.spacing(2),
                    width: '100%',
                    border: `1px solid ${theme.palette.grey[300]}`,
                    padding: theme.spacing(2),
                    borderRadius: theme.shape.borderRadius,
                }}
                direction="column"
            >
                <Container style={{ justifyContent: 'space-evenly' }}>
                    <Container
                        direction="column"
                        style={{ alignSelf: 'flex-start' }}
                    >
                        <Container
                            style={{
                                ...theme.typography.h6,
                                fontWeight: 600,
                                alignItems: 'center',
                            }}
                        >
                            <Apartment
                                style={{ marginRight: theme.spacing(1) }}
                            />
                            Property Name
                        </Container>
                        <Container
                            style={{
                                ...theme.typography.subtitle1,
                                marginLeft: theme.spacing(0.5),
                            }}
                        >
                            {rfp.apartment.name}
                        </Container>
                    </Container>
                    <Container direction="column">
                        <Container
                            style={{
                                ...theme.typography.h6,
                                fontWeight: 600,
                                alignItems: 'center',
                            }}
                        >
                            {/* <AccessTime style={{marginRight: theme.spacing(1)}}/> */}
                            # Work Orders
                        </Container>
                        <Container
                            style={{
                                ...theme.typography.subtitle1,
                                marginLeft: theme.spacing(0.5),
                            }}
                        >
                            {rfp.workorder_group?.workorders.length ?? 0}
                        </Container>
                    </Container>
                    <Container direction="column">
                        <Container
                            style={{
                                ...theme.typography.h6,
                                fontWeight: 600,
                                alignItems: 'center',
                            }}
                        >
                            <WorkOutline
                                style={{ marginRight: theme.spacing(1) }}
                            />
                            Services
                        </Container>
                        <Container style={{ marginLeft: theme.spacing(0.5) }}>
                            {rfp.rfp_services[0] && (
                                <DisplayService
                                    service={rfp.rfp_services[0].service}
                                    border={false}
                                    noPadding
                                />
                            )}
                            {rfp.rfp_services.length > 1 && (
                                <>
                                    <Container>
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                handleOpen(e)
                                            }}
                                            size="small"
                                            style={{
                                                margin: theme.spacing(
                                                    1,
                                                    0,
                                                    1,
                                                    0,
                                                ),
                                            }}
                                        >
                                            +{rfp.rfp_services.length - 1}
                                        </Button>
                                    </Container>
                                    <ListPopover
                                        open={isOpen}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            horizontal: -50,
                                            vertical: 'bottom',
                                        }}
                                        style={{
                                            paddingLeft: theme.spacing(1),
                                        }}
                                    >
                                        {rfp.rfp_services
                                            .slice(1)
                                            .map((rfpService) => {
                                                return (
                                                    <DisplayService
                                                        service={
                                                            rfpService.service
                                                        }
                                                        border={false}
                                                        key={`RFP_SERVICE_DISPLAY_${rfpService.id}`}
                                                    />
                                                )
                                            })}
                                    </ListPopover>
                                </>
                            )}
                        </Container>
                    </Container>
                </Container>
                <Container
                    style={{ marginTop: theme.spacing(2) }}
                    direction="column"
                >
                    <Container
                        style={{ ...theme.typography.h6, fontWeight: 500 }}
                    >
                        Scope of Work:
                    </Container>
                    <Container
                        style={{
                            margin: theme.spacing(1),
                            padding: theme.spacing(1),
                            border: `1px solid ${theme.palette.grey[300]}`,
                            borderRadius: theme.shape.borderRadius,
                        }}
                    >
                        {rfp.description}
                    </Container>
                </Container>
            </Container>
            {(bid?.status === BidStatus.SUBMITTED ||
                bid?.status === BidStatus.REVIEWED) && (
                <Container
                    style={{
                        ...theme.typography.h6,
                        margin: theme.spacing(2, 0, 2, 0),
                    }}
                >
                    Request for {rfp.name} has been submitted!
                </Container>
            )}
            {bid?.status === BidStatus.DECLINED && (
                <Container
                    style={{
                        ...theme.typography.h6,
                        margin: theme.spacing(2, 0, 2, 0),
                    }}
                >
                    Request for {rfp.name} has been declined!
                </Container>
            )}
        </Container>
    )
}
