import { NVLVendor } from '../../main/containers/national-vendor-list'
import { Company, ListVendor, SimpleVendor } from '../Company'
import {
    AreaConfig,
    BaseAreaConfig,
    UnitConfig,
    UnitConfigAreaCounts,
} from '../Config'
import { Service } from '../Services'
import { WorkorderGroup } from '../WorkOrder'
import { Address, IdentifiableNamedObject, IdentifiableObject } from '../types'

export type ServiceContractType = 'AREA' | 'UNIT'

interface BaseServiceContract extends IdentifiableObject {
    service: number
    price: number | null
}
export interface ServiceContract extends BaseServiceContract {
    apartment: number
    vendor: number
    area_config?: number
    unit_config?: number
}

export interface PortfolioServiceContract extends BaseServiceContract {
    apartment: Company
    vendor: Company
    area_config: AreaConfig | null
    unit_config: UnitConfigAreaCounts | null
}

export interface UpstreamServiceContract extends BaseServiceContract {
    apartment: number
    area_config?: number
    unit_config?: number
}

export interface ServiceContractMap {
    [serviceId: number]: {
        areaConfig: { [configId: number]: number | string }
        unitConfig: { [configId: number]: number | string }
    }
}

export declare type BidStatusType =
    | 'PENDING'
    | 'APPROVED'
    | 'DENIED'
    | 'INCOMPLETE'

export enum RFPType {
    INVITE_ONLY = 'INVITE_ONLY',
    PUBLIC = 'PUBLIC',
    EZNOW = 'EZNOW',
}

export enum RFPStatus {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
    CLOSED = 'CLOSED',
    ARCHIVED = 'ARCHIVED',
    FAILED = 'FAILED',
    SELECT_VENDOR = 'SELECT_VENDOR',
}

export interface RFPServiceDetail extends IdentifiableObject {
    question_description: string
    question_answer: string
    question_config?: number
}

export interface RFPService extends IdentifiableObject {
    service: Service
    service_details: RFPServiceDetail[]
}

export enum BidStatus {
    INVITATION = 'INVITATION',
    SUBMITTED = 'SUBMITTED',
    DECLINED = 'DECLINED',
    ACCEPTED = 'ACCEPTED',
    REVIEWED = 'REVIEWED',
}

export interface Bid extends IdentifiableObject {
    vendor: NVLVendor
    status: BidStatus
    group_bid_price: number | null
    rfp: RFP
    vendor_answers: VendorAnswer[]
    bid_line_items: BidLineItem[]
    channel_id: number | null
    message_count: number
    is_unsolicited: boolean
}

export enum RFPDocumentType {
    PDF = 'PDF',
    DOC = 'DOC',
    DOCX = 'DOCX',
    JPG = 'JPG',
    JPEG = 'JPEG',
    PNG = 'PNG',
    XLSX = 'XLSX',
}

export interface RFPDocument {
    id: number
    file: string
    rfp: number
    public: boolean
    file_name: string
    file_type: RFPDocumentType
}

export enum ApartmentQuestionType {
    GENERAL = 'GENERAL',
    FINANCIAL = 'FINANCIAL',
}

export interface ApartmentQuestion extends IdentifiableObject {
    question_description: string
    type: ApartmentQuestionType
}

export interface VendorAnswer extends IdentifiableObject {
    apartment_question: number
    answer_description: string
}

export interface RFP extends IdentifiableObject {
    apartment: {
        id: number
        name: string
        primary_address: Address
        profile_picture: string | null
    }
    name: string
    project_submission_deadline: string | null
    project_start_date: string | null
    project_end_date: string | null
    type: RFPType
    status: RFPStatus
    budget: number
    rfp_services: RFPService[]
    description: string
    bids: Bid[]
    workorder_group: WorkorderGroup | null
    created_date: string
    accepted_bid: Bid | null
    rfp_documents: RFPDocument[]
    apartment_questions: ApartmentQuestion[]
}

export interface BidLineItem extends IdentifiableObject {
    apartment_question: number | null
    rfp_service: number | null
    description: string
    price: number
}

export type ServiceContractCompoundKey = {
    serviceId: number
    vendorId: number
    areaConfigId: number | null
    unitConfigId: number | null
}
