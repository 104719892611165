import { CircularProgress, Divider, useTheme } from '@material-ui/core'
import React, { useEffect } from 'react'
import { rfpApi } from '../../contexts'
import { Container } from '../../components'
import { VendorEZNowRow } from './VendorEZNowRow'
import { BidStatus, RFP, RFPStatus, RFPType } from '../../models'
import { VendorEZNowDetail } from './VendorEZNowDetail'

export const VendorEZNowList = () => {
    const theme = useTheme()

    const {
        rfpList,
        selectedRFP,
        setSelectedRFP,
        getRFPList,
        loadingState,
    } = rfpApi()

    useEffect(() => {
        getRFPList({
            params: {
                type: RFPType.EZNOW,
                statuses: `${RFPStatus.PUBLISHED}`,
                bid_statuses: `${BidStatus.INVITATION}`,
            },
        })
    }, [])

    if (loadingState.getRFPList) {
        return (
            <Container
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress size={100} />
            </Container>
        )
    }

    return (
        <Container>
            <Container direction="column" style={{ width: '650px' }}>
                <Container
                    style={{
                        ...theme.typography.subtitle1,
                        margin: theme.spacing(1),
                    }}
                >
                    {rfpList?.length ?? 0} jobs found
                </Container>
                <Container
                    direction="column"
                    scrollY
                    style={{
                        maxHeight: 'calc(100vh - 200px)',
                        minHeight: 'calc(100vh - 200px)',
                    }}
                    flex={1}
                >
                    {rfpList?.map((rfp) => {
                        return (
                            <VendorEZNowRow
                                key={`EZNOW_RFP_${rfp.id}`}
                                rfp={rfp}
                                isSelectedRFP={rfp.id === selectedRFP?.id}
                                onClickRFP={(rfp: RFP) => {
                                    setSelectedRFP(rfp)
                                }}
                            />
                        )
                    })}
                </Container>
            </Container>
            <Divider
                style={{ margin: theme.spacing(0, 1, 0, 1) }}
                orientation="vertical"
                flexItem
            />
            {selectedRFP && <VendorEZNowDetail rfp={selectedRFP} />}
        </Container>
    )
}
