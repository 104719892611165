export const validateTextFieldNum = (str: string, decimalScale?: number) => {
    let decimalFound = false

    let decimalLoc = 0

    let digitsValid = true
    for (let i = 0; i < str.length; i++) {
        if (decimalScale && decimalFound) {
            const distance = i - decimalLoc
            if (distance > decimalScale) {
                return false
            }
        }

        const asciiCode = str.charCodeAt(i)
        if (asciiCode < 48 || asciiCode > 57) {
            if (!decimalFound && asciiCode === 46) {
                decimalFound = true
                decimalLoc = i
                continue
            }

            digitsValid = false

            break
        }
    }

    return digitsValid
}
