import { Grid, TableSortLabel, useTheme } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { Container } from '../../components'
import { toMMDDYYYY, toMonthYear } from '../../helpers'
import { User } from '../../models'
import { TeamRow } from './TeamRow'
import { TeamRowType, UserRowType } from './types'
import { useUser } from '../../hooks'

interface Props {
    headCellStyle: React.CSSProperties
    userList: User[]
    cellStyle: React.CSSProperties
    setEditUser: (user: User) => void
    setOpenDrawer: (open: boolean) => void
}

const NameASC = 'NameASC'
const NameDESC = 'NameDESC'
const EmailASC = 'EmailASC'
const EmailDESC = 'EmailDESC'
const PhoneASC = 'PhoneASC'
const PhoneDESC = 'PhoneDESC'
const RoleASC = 'RoleASC'
const RoleDESC = 'RoleDESC'
const DateASC = 'DateASC'
const DateDESC = 'DateDESC'

export const WorkforceTeamTable = (props: Props) => {
    const {
        headCellStyle,
        userList,
        cellStyle,
        setEditUser,
        setOpenDrawer,
    } = props

    const { workspaceUser } = useUser()

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [orderBy, setOrderBy] = useState('')

    const [propertyManager, setPropertyManager] = useState<
        User | undefined | null
    >(workspaceUser?.active_workspace.property_manager)

    const theme = useTheme()

    const createUserRow = (user: User): TeamRowType => {
        const date = toMonthYear(new Date(user.created_date))
        let phone = ''
        let roles_str = user.permission_roles.reduce(
            (prev, role) => prev.concat(`${role.name}, `),
            '',
        )
        roles_str = roles_str.slice(0, -2)
        if (user.cell_phone) phone = user.cell_phone
        else if (user.phone) phone = user.phone
        return {
            name: user.name,
            permission: roles_str,
            phone: phone,
            email: user.email,
            date: date,
            picture: user.profile_picture,
            user: user,
        }
    }

    const [sortedRows, setSortedRows] = useState<TeamRowType[]>([])

    useEffect(() => {
        const rows = userList.map((comp: User) => createUserRow(comp))
        setSortedRows(rows)
    }, [userList])

    return (
        <Container scrollY style={{ flex: 1 }}>
            <Grid
                container={true}
                style={{
                    padding: theme.spacing(2, 0, 1, 0),
                }}
            >
                <Grid container={true} justify="space-evenly">
                    <Container
                        style={{ ...headCellStyle, flex: 1 }}
                    ></Container>
                    <Container
                        style={{ ...headCellStyle }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== NameASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.name.localeCompare(RowB.name),
                                )
                                setSortedRows(newRows)
                                setOrderBy(NameASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(NameDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === NameASC || orderBy === NameDESC}
                            direction={orderBy === NameASC ? 'asc' : 'desc'}
                        >
                            Name
                        </TableSortLabel>
                    </Container>
                    <Container
                        style={{ ...headCellStyle }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== DateASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.date.localeCompare(RowB.date),
                                )
                                setSortedRows(newRows)
                                setOrderBy(DateASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(DateDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === DateASC || orderBy === DateDESC}
                            direction={orderBy === DateASC ? 'asc' : 'desc'}
                        >
                            Date
                        </TableSortLabel>
                    </Container>
                    <Container
                        style={{ ...headCellStyle }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== RoleASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.permission.localeCompare(
                                        RowB.permission,
                                    ),
                                )
                                setSortedRows(newRows)
                                setOrderBy(RoleASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(RoleDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === RoleASC || orderBy === RoleDESC}
                            direction={orderBy === RoleASC ? 'asc' : 'desc'}
                        >
                            Role
                        </TableSortLabel>
                    </Container>
                    <Container
                        style={{ ...headCellStyle }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== EmailASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.email
                                        .toString()
                                        .localeCompare(RowB.email.toString()),
                                )
                                setSortedRows(newRows)
                                setOrderBy(EmailASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(EmailDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={
                                orderBy === EmailASC || orderBy === EmailDESC
                            }
                            direction={orderBy === EmailASC ? 'asc' : 'desc'}
                        >
                            Email
                        </TableSortLabel>
                    </Container>
                    <Container
                        style={{ ...headCellStyle }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== PhoneASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.phone.localeCompare(RowB.phone),
                                )
                                setSortedRows(newRows)
                                setOrderBy(PhoneASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(PhoneDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={
                                orderBy === PhoneASC || orderBy === PhoneDESC
                            }
                            direction={orderBy === PhoneASC ? 'asc' : 'desc'}
                        >
                            Phone
                        </TableSortLabel>
                    </Container>
                    <Container
                        style={{ ...headCellStyle, flex: 1 }}
                    ></Container>
                </Grid>

                <Grid
                    container={true}
                    style={{
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 400px)',
                    }}
                >
                    {sortedRows
                        .map((row, index) => {
                            return (
                                <TeamRow
                                    rows={row}
                                    cellStyle={cellStyle}
                                    setEditUser={setEditUser}
                                    setOpenDrawer={setOpenDrawer}
                                    key={index}
                                    propertyManager={propertyManager}
                                    setPropertyManager={setPropertyManager}
                                />
                            )
                        })
                        .slice(
                            (page - 1) * rowsPerPage,
                            (page - 1) * rowsPerPage + rowsPerPage,
                        )}
                </Grid>
                <Container style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Pagination
                        count={Math.ceil(sortedRows.length / rowsPerPage)}
                        page={page}
                        onChange={(_: unknown, newPage: number) => {
                            setPage(newPage)
                        }}
                        variant="outlined"
                        shape="rounded"
                        style={{ margin: theme.spacing(1, 0, 0, 0) }}
                    />
                </Container>
            </Grid>
        </Container>
    )
}
