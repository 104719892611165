import { Lease, DamageStatus, Damage, LeaseStatusType, LeaseStatus } from '.'
import {
    Folder,
    IdBoolMap,
    Timeline,
    traverse,
    WorkSpaceUser,
    DMG_PENDING_TO_PREAPPROVED,
    DMG_PENDING_TO_DENIED,
    DMG_PREAPPROVED_TO_APPROVED,
    DMG_PREAPPROVED_TO_DENIED,
    DMG_APPROVED_TO_SYNCED,
} from '..'
import { timelinesOverlap } from '../../helpers'
import { GetLeaseListRequest } from '../../store'
import { Range } from 'react-date-range'
import { hasPermission } from '../Users/services'

export const getLeaseTimeline = (lease: Lease): Timeline => {
    return {
        startDate: new Date(lease.start_date),
        endDate: new Date(lease.end_date),
    }
}

const reconcileLeases = (leases: Lease[]) => {
    /**
     *  Loop through the sorted leases and ensure that there are no overlapping leases
     *  If there are merge the dates
     *
     *  Input:
     *  [----------]
     *                  [----------]
     *                      [----------]
     *
     *  Output:
     *  [----------]    [--------------]
     */

    let leaseArrayIdx = 1
    const leaseArray: Lease[] = new Array(leases.length)

    let prevLease = leases[0]
    leaseArray[0] = prevLease

    for (let i = 1; i < leases.length; i++) {
        const currentLease = leases[i]
        const currentTimeline = getLeaseTimeline(currentLease)
        const previousTimeline = getLeaseTimeline(prevLease)

        if (timelinesOverlap(previousTimeline, currentTimeline)) {
            const minStart =
                previousTimeline.startDate < currentTimeline.endDate
                    ? previousTimeline.startDate
                    : currentTimeline.startDate
            const maxEnd =
                previousTimeline.endDate > currentTimeline.endDate
                    ? previousTimeline.endDate
                    : currentTimeline.endDate

            // Replace the previous spot in the lease array with the merged value
            leaseArray[leaseArrayIdx - 1] = {
                ...currentLease,
                start_date: minStart.toISOString(),
                end_date: maxEnd.toISOString(),
            }
        } else {
            //
            leaseArray[leaseArrayIdx] = currentLease
            leaseArrayIdx += 1
        }

        prevLease = currentLease
    }

    // Trim any excess undefined values out of the leaseArray
    const trimmedLeaseArray: Lease[] = new Array(leaseArrayIdx)
    for (let i = 0; i < leaseArrayIdx; i++) {
        trimmedLeaseArray[i] = leaseArray[i]
    }

    return trimmedLeaseArray
}

const findGap = (left: Timeline, right: Timeline): Timeline => {
    return {
        startDate: left.endDate,
        endDate: right.startDate,
    }
}

const findGaps = (leases: Lease[], relativeTimeline?: Timeline) => {
    /**
     * Find and return gaps between the date sorted leases in `leases`.
     *
     * If `relativeTimeline` is provided it will look for holes to the left of the first lease, and the right of the second
     * relativeTimeline will also not include gaps that occur outside of the timeline
     */
    const gaps: Timeline[] = []

    let previousTimeline = getLeaseTimeline(leases[0])
    // Look for a "Hole" to the left of prev lease and relativeTimeline.start_date
    if (
        relativeTimeline &&
        previousTimeline.startDate > relativeTimeline.startDate
    ) {
        gaps.push({
            startDate: relativeTimeline.startDate,
            endDate: previousTimeline.startDate,
        })
    }

    for (let i = 1; i < leases.length; i++) {
        const currentLease = leases[i]
        const currentTimeline = getLeaseTimeline(currentLease)
        // If the caller passed a relative timeline, only include the gap if both the prev lease and current lease
        // overlap with the relative timeline
        if (relativeTimeline) {
            if (
                timelinesOverlap(currentTimeline, relativeTimeline) &&
                timelinesOverlap(previousTimeline, relativeTimeline)
            ) {
                gaps.push(findGap(previousTimeline, currentTimeline))
            }
        } else {
            gaps.push(findGap(previousTimeline, currentTimeline))
        }

        previousTimeline = currentTimeline
    }

    // Look for a "Hole" to the right of prevLease and relativeTimeline.end_date
    if (
        relativeTimeline &&
        previousTimeline.endDate < relativeTimeline.endDate
    ) {
        gaps.push({
            startDate: previousTimeline.endDate,
            endDate: relativeTimeline.endDate,
        })
    }

    return gaps
}

export const areaHasGapInTimeline = (timeline: Timeline, leases?: Lease[]) => {
    // If there are no leases to compare, the space must be vacant
    if (leases === undefined || leases.length === 0) {
        return true
    }

    // Ensure that the leases are in the correct sort order and that they share
    // an area
    let prevLease = leases[0]
    let prevTimeline = getLeaseTimeline(prevLease)
    for (let i = 1; i < leases.length; i++) {
        const currentLease = leases[i]
        const currentTimeline = getLeaseTimeline(currentLease)

        // Ensure they belong to the same area
        if (prevLease.area.id !== currentLease.area.id) {
            throw Error('leases do not share an area')
        }

        // Ensure the leases are sorted
        if (currentTimeline.startDate < prevTimeline.startDate) {
            throw Error('Leases are not sorted properly')
        }
        prevLease = currentLease
        prevTimeline = currentTimeline
    }

    // Leases are sorted correctly

    // Reconcile The leases to ensure there are no overlapping leases
    const reconciledLeases = reconcileLeases(leases)

    /**
     * Check the reconciled leases for gaps relative to the provided start / end date.
     *
     * Example:
     *
     * S                                   E
     * [-----------------------------------]
     *
     * Reconciled Leases
     * gggg[----------]gggggg[--------------]
     *
     */
    const gaps = findGaps(reconciledLeases, {
        startDate: timeline.startDate,
        endDate: timeline.endDate,
    })

    // If there are no gaps the space must be renewed
    if (gaps.length === 0) {
        return false
    }

    // There are gaps.
    // TODO: use the gaps to generate TI, TO, EM, etc..
    // For now assume Vacant
    return true
}

export const getInitialLeaseListParams = (
    historyMode: boolean,
    filterOnTree: boolean,
    prunedTree: Folder,
    applyDate: boolean,
    dateRange: Range,
) => {
    const newParams: GetLeaseListRequest = {
        params: {
            history: historyMode,
            sort_order: historyMode
                ? 'start_date,end_date'
                : '-start_date,-end_date',
            occupiable: true,
        },
    }
    // get units that are in the filter
    if (historyMode && newParams.params && filterOnTree) {
        let unitIdString = ''

        traverse(prunedTree, (f) => {
            f.units.forEach((u) => {
                unitIdString = unitIdString + `${u.id},`
            })
        })

        unitIdString = unitIdString.slice(0, -1)
        newParams.params.units = unitIdString
    }

    if (historyMode && newParams.params) {
        newParams.params.page = 1
    }

    if (applyDate && newParams.params) {
        dateRange.startDate?.setHours(0, 0, 0, 0)
        dateRange.endDate?.setHours(23, 59, 59, 999)
        newParams.params.overlap_start = dateRange.startDate?.toISOString()
        newParams.params.overlap_end = dateRange.endDate?.toISOString()
    }

    return newParams
}

export const filterLeases = (
    historyMode: boolean,
    leaseList: Lease[],
    filterOnTree: boolean,
    prunedTree: Folder,
    applyDate: boolean,
    dateRange: Range,
) => {
    if (historyMode) {
        // in history mode, all filtering is handled server side
        return leaseList
    }

    let filteredList: Lease[] = leaseList ?? []
    // we aren't in history mode so the filtering needs to be handled locally
    if (filterOnTree) {
        // the user has filtered down to a selection of folders or units
        const unitsToShowMap: IdBoolMap = {}
        traverse(prunedTree, (f) => {
            f.units.forEach((u) => {
                unitsToShowMap[u.id] = true
            })
        })

        filteredList = filteredList.reduce<Lease[]>((prev, lease) => {
            const unitID = lease.area.unit
            if (unitID in unitsToShowMap) {
                return prev.concat(lease)
            }
            return prev
        }, [])
    }

    if (applyDate) {
        dateRange.startDate?.setHours(0, 0, 0, 0)
        dateRange.endDate?.setHours(23, 59, 59, 999)
        // filter for leases that intersect the given date range
        filteredList = filteredList.reduce<Lease[]>((prev, lease) => {
            if (timelinesOverlap(getLeaseTimeline(lease), dateRange)) {
                return prev.concat(lease)
            }
            return prev
        }, [])
    }

    return filteredList
}

export const getDamageStatusColor = (damage: Damage) => {
    switch (damage.status) {
        case DamageStatus.DENIED:
            return '#e73535'
        case DamageStatus.PRE_APPROVED:
            return '#bee3d4'
        case DamageStatus.APPROVED:
            return '#46b083'
        case DamageStatus.PENDING:
            return '#e0c23a'
        case DamageStatus.SYNC_PENDING:
            return '#016fb9'
        case DamageStatus.SYNC_FAILED:
            return '#e73535'
    }
    return '#016fb9'
}

export const getDamageStatusName = (damageStatus: DamageStatus) => {
    switch (damageStatus) {
        case DamageStatus.DENIED:
            return 'Denied'
        case DamageStatus.APPROVED:
            return 'Approved'
        case DamageStatus.PRE_APPROVED:
            return 'Preapproved'
        case DamageStatus.PENDING:
            return 'Pending'
        case DamageStatus.ENTRATA_SYNCED:
            return 'Synced'
        case DamageStatus.SYNC_PENDING:
            return 'Sync Pending'
        case DamageStatus.SYNC_FAILED:
            return 'Failed'
    }
    return '?'
}

interface TransitionInfo {
    transition: string
    permission: boolean
    to_status: DamageStatus
    title: string
}
interface Transitions {
    positive?: TransitionInfo
    negative?: TransitionInfo
    neutral?: TransitionInfo
}

export const getDamageTransitions = (damage: Damage, user?: WorkSpaceUser) => {
    const transitions: Transitions = {
        positive: undefined,
        negative: undefined,
        neutral: undefined,
    }

    switch (damage.status) {
        case DamageStatus.PENDING:
            transitions['positive'] = {
                permission: hasPermission(user, DMG_PENDING_TO_PREAPPROVED),
                transition: DMG_PENDING_TO_PREAPPROVED,
                to_status: DamageStatus.PRE_APPROVED,
                title: 'Preapprove',
            }
            transitions['negative'] = {
                permission: hasPermission(user, DMG_PENDING_TO_DENIED),
                transition: DMG_PENDING_TO_DENIED,
                to_status: DamageStatus.DENIED,
                title: 'Deny',
            }
            break
        case DamageStatus.PRE_APPROVED:
            transitions['positive'] = {
                permission: hasPermission(user, DMG_PREAPPROVED_TO_APPROVED),
                transition: DMG_PREAPPROVED_TO_APPROVED,
                to_status: DamageStatus.APPROVED,
                title: 'Approve',
            }
            transitions['negative'] = {
                permission: hasPermission(user, DMG_PREAPPROVED_TO_DENIED),
                transition: DMG_PREAPPROVED_TO_DENIED,
                to_status: DamageStatus.DENIED,
                title: 'Deny',
            }
            break
        case DamageStatus.APPROVED:
            transitions['positive'] = {
                permission: hasPermission(user, DMG_APPROVED_TO_SYNCED),
                transition: DMG_APPROVED_TO_SYNCED,
                to_status: DamageStatus.ENTRATA_SYNCED,
                title: 'Sync',
            }
            break
        case DamageStatus.SYNC_FAILED:
            transitions['positive'] = {
                permission: hasPermission(user, DMG_APPROVED_TO_SYNCED),
                transition: DMG_APPROVED_TO_SYNCED,
                to_status: DamageStatus.ENTRATA_SYNCED,
                title: 'Sync',
            }
            break
    }

    return transitions
}

export const getLeaseStatusString = (leaseStatus: LeaseStatusType) => {
    switch (leaseStatus) {
        case LeaseStatus.APPLICANT:
            return 'Applicant'
        case LeaseStatus.CANCELLED:
            return 'Cancelled'
        case LeaseStatus.CURRENT:
            return 'Current'
        case LeaseStatus.FUTURE:
            return 'Future'
        case LeaseStatus.NOTICE:
            return 'Notice'
    }
    return 'Past'
}

export const getLeaseStatusColor = (leaseStatus: LeaseStatusType) => {
    switch (leaseStatus) {
        case LeaseStatus.APPLICANT:
            return {
                color: 'grey',
                text: 'black',
            }
        case LeaseStatus.CANCELLED:
            return {
                color: 'red',
                text: 'black',
            }
        case LeaseStatus.CURRENT:
            return {
                color: 'green',
                text: 'black',
            }
        case LeaseStatus.FUTURE:
            return {
                color: 'blue',
                text: 'black',
            }
        case LeaseStatus.NOTICE:
            return {
                color: 'yellow',
                text: 'black',
            }
    }
    return {
        color: 'blue',
        text: 'black',
    }
}

type LeaseTransitionWithLabel = {
    actionLabel: string
    status: LeaseStatusType
}

type LeaseTransitionDetails = {
    positive: LeaseTransitionWithLabel | null
    negative: LeaseTransitionWithLabel | null
} | null

export const getLeaseTransition = (
    leaseStatus: LeaseStatusType,
): LeaseTransitionDetails => {
    switch (leaseStatus) {
        case LeaseStatus.APPLICANT:
            return {
                positive: {
                    actionLabel: 'Approve',
                    status: LeaseStatus.FUTURE,
                },
                negative: {
                    actionLabel: 'Cancel',
                    status: LeaseStatus.CANCELLED,
                },
            }
        case LeaseStatus.CANCELLED:
            return null
        case LeaseStatus.CURRENT:
            return {
                positive: { actionLabel: 'Move Out', status: LeaseStatus.PAST },
                negative: null,
            }

        case LeaseStatus.FUTURE:
            return {
                positive: {
                    actionLabel: 'Move In',
                    status: LeaseStatus.CURRENT,
                },
                negative: {
                    actionLabel: 'Cancel',
                    status: LeaseStatus.CANCELLED,
                },
            }
        case LeaseStatus.NOTICE:
            return null
    }
    return null
}

export const getIssueCount = (lease: Lease) => {
    let count = 0

    lease.tenant_move_in_inspection?.inventory_inspection?.forEach(
        (inv_ins) => {
            count += inv_ins.issue?.length ?? 0
        },
    )

    lease.children?.forEach((child) => {
        count += getIssueCount(child)
    })
    return count
}
