import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'

import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { Container } from '@material-ui/core'

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
const DEFAULT_DRAWER_WIDTH = 330

export const useStyles = (width: number = DEFAULT_DRAWER_WIDTH) => {
    return makeStyles((theme: Theme) =>
        createStyles({
            drawer: {
                flexShrink: 0,
                willChange: 'margin',
                display: 'flex',
                flexDirection: 'column',
            },
            drawerPaper: {
                width: width,
                border: '1px solid #9e9e9e',
                margin: '0px 0px 0px 0px',
                boxShadow: '0px 9px 10px',
                borderRadius: '20px',
            },
            drawerHeader: {
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(0, 1),
                ...theme.mixins.toolbar,
                justifyContent: 'flex-start',
            },
            content: {
                flexGrow: 1,
                padding: theme.spacing(3),
                willChange: 'margin',
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
            contentShift: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginRight: width,
            },
        }),
    )()
}

interface SideDrawerProps {
    open: boolean
    width?: number
    title?: string | JSX.Element
    headerRight?: JSX.Element
    alwaysOpen?: boolean
    handleClose: () => void
}

export const SideDrawer: React.FC<SideDrawerProps> = (props) => {
    const classes = useStyles(props.width)
    const theme = useTheme()

    const displayCloseIcon =
        props.alwaysOpen === undefined || props.alwaysOpen === false

    const closeIcon = displayCloseIcon ? (
        <IconButton onClick={props.handleClose}>
            {theme.direction === 'rtl' ? (
                <ChevronLeftIcon />
            ) : (
                <ChevronRightIcon />
            )}
        </IconButton>
    ) : null

    let titleComponent: JSX.Element | undefined
    if (typeof props.title === 'string') {
        titleComponent = (
            <span
                style={{
                    ...theme.typography.h6,
                    fontWeight: theme.typography.fontWeightMedium,
                    marginLeft: !displayCloseIcon ? theme.spacing(2) : 0,
                }}
            >
                {props.title}
            </span>
        )
    } else {
        titleComponent = props.title
    }

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={props.open}
            classes={{
                paper: classes.drawerPaper,
            }}
            style={{ flexDirection: 'column' }}
        >
            <Container className={classes.drawerHeader}>
                {closeIcon}
                {titleComponent}
                <div style={{ flex: 1 }} />
                {props.headerRight}
            </Container>
            <Divider />
            {props.children}
        </Drawer>
    )
}
