import { useTheme } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { Container } from '../../components'
import { VendorServiceAnalytic } from '../../models'
import {
    GET_VENDOR_ANALYTICS_REQUEST,
    RootState,
    VendorAnalyticResponse,
} from '../../store'

interface Props {
    analytics?: VendorAnalyticResponse
}

export const VendorAnalyticsBody = (props: Props) => {
    const { analytics } = props

    const theme = useTheme()

    const vendorAnalyticsLoading = useSelector(
        (state: RootState) =>
            state.analytics.isLoading[GET_VENDOR_ANALYTICS_REQUEST],
    )

    return analytics?.all_time.length === 0 && !vendorAnalyticsLoading ? (
        <Container
            flex={1}
            alignItems="center"
            justifyContent="center"
            direction="column"
        >
            <span style={{ ...theme.typography.h3 }}>
                This Vendor has not done any work yet.
            </span>
        </Container>
    ) : (
        <Container flex={1} scrollY>
            {/* past week */}
            <Container
                scrollY
                flex={1}
                style={{
                    backgroundColor: theme.palette.grey[300],
                    margin: theme.spacing(1),
                    borderRadius: theme.shape.borderRadius,
                    padding: theme.spacing(2),
                }}
            >
                <Container flex={1} direction="column">
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            marginLeft: theme.spacing(2),
                        }}
                    >
                        Past Week
                    </span>
                    {analytics?.past_week.map((s) => (
                        <SingleAnalytic
                            analytic={s}
                            key={`PAST_WEEK_ANALYTIC_${analytics.id}_${s.id}`}
                        />
                    ))}
                </Container>
                {/* all time */}
                <Container direction="column" flex={1}>
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            marginLeft: theme.spacing(2),
                        }}
                    >
                        All Time
                    </span>
                    {analytics?.all_time.map((s) => (
                        <SingleAnalytic
                            analytic={s}
                            useDenominator
                            key={`ALL_TIME_ANALYTIC_${analytics.id}_${s.id}`}
                        />
                    ))}
                </Container>
            </Container>
        </Container>
    )
}

interface SingleProps {
    analytic: VendorServiceAnalytic
    useDenominator?: boolean
}

const SingleAnalytic = (props: SingleProps) => {
    const { analytic, useDenominator } = props

    const theme = useTheme()

    let statusStr = 'Status'
    if (useDenominator) {
        statusStr += ' / Total Assigned Workorders'
    }

    // if denominator, make the denominators the assigned count
    const denom = useDenominator ? analytic.assign_count : 1

    // a string or num to display for a specific stat
    const stat = (num: number) => {
        let percent = (num / denom) * 100
        if (isNaN(percent)) {
            percent = 0
        }
        return useDenominator
            ? `${num} / ${denom} Workorders (${percent.toFixed(2)}%)`
            : num
    }

    const statHeaderStyle: React.CSSProperties = {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightBold,
        marginTop: theme.spacing(1),
    }

    const statStyle: React.CSSProperties = {
        ...theme.typography.subtitle2,
    }

    return (
        <Container
            direction="column"
            style={{
                backgroundColor: theme.palette.grey[200],
                margin: theme.spacing(1),
                padding: theme.spacing(2),
            }}
        >
            <span
                style={{
                    ...theme.typography.h6,
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >
                {analytic.name}
            </span>
            <span style={{ ...theme.typography.caption }}>{statusStr}</span>

            {/* stats */}
            <span style={statHeaderStyle}>Approved</span>
            <span style={statStyle}>{stat(analytic.approved_count)}</span>

            <span style={statHeaderStyle}>Approved (1st)</span>
            <span style={statStyle}>{stat(analytic.app_1st_attempt)}</span>

            <span style={statHeaderStyle}>Completed</span>
            <span style={statStyle}>{stat(analytic.complete_count)}</span>

            <span style={statHeaderStyle}>Go Back</span>
            <span style={statStyle}>{stat(analytic.go_back_count)}</span>

            <span style={statHeaderStyle}>Go Back (2nd)</span>
            <span style={statStyle}>{stat(analytic.go_back_2nd_count)}</span>

            <span style={statHeaderStyle}>Go Back (Max)</span>
            <span style={statStyle}>{stat(analytic.go_back_max)}</span>
        </Container>
    )
}
