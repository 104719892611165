import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import {
    Card,
    CardHeader,
    CardContent,
    IconButton,
    Typography,
} from '@material-ui/core'

import { ReactComponent as PlusCircleIcon } from '../../../../assets/icons/plus-circle.svg'

import DialogBox from '../../../components/dialog-box/DialogBox'
import AddActionItem from './AddActionItem'
import ActionItemList from './ActionItemList'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            borderRadius: '15px',
            border: '1px solid #C2C2C2',
            boxShadow: '0px 6px 15px rgb(138 138 138 / 25%)',
        },
        headerTitle: {
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '20px',
            lineHeight: '23px',
            display: 'block',
            color: '#008C85',
        },
    }),
)

const ActionItems = () => {
    const classes = useStyles()

    const [openAddItemDialog, setOpenAddItemDialog] = useState(false)

    const handleToggleAddItemDialog = () => {
        setOpenAddItemDialog(!openAddItemDialog)
    }

    return (
        <>
            <Card className={`${classes.root}`}>
                <CardHeader
                    // action={
                    //     <IconButton
                    //         aria-label="settings"
                    //         onClick={handleToggleAddItemDialog}
                    //     >
                    //         <PlusCircleIcon />
                    //     </IconButton>
                    // }
                    title={
                        <Typography className={classes.headerTitle}>
                            My Action Items
                        </Typography>
                    }
                    // subheader="September 14, 2016"
                />
                <CardContent>
                    <ActionItemList />
                </CardContent>
            </Card>
            <DialogBox
                open={openAddItemDialog}
                handleClose={handleToggleAddItemDialog}
                dialogContent={<AddActionItem />}
                dialogHeader="Add Action Item"
                fullWidth={true}
            />
        </>
    )
}

export default ActionItems
