import React from 'react'
import { BidStatus, RFP, RFPStatus } from '../../models'
import { Button, Divider, Theme } from '@material-ui/core'
import { usePopover } from '../../hooks/usePopover'
import { getDifferenceInDays, toMMDDYYYY } from '../../helpers'
import { Container } from '../../components'
import { ListPopover } from '../../components/ListPopover/ListPopover'
import { DisplayService } from '../../components/DisplayService'
import { useStyles } from '../../styles'
import { RFPStatusBadge } from './RFPStatusBadge'

interface Props {
    rfp: RFP
    theme: Theme
    managerView?: boolean
    onClick?: () => void
}

export const RFPCard = (props: Props) => {
    const { rfp, theme, managerView, onClick } = props

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    const classes = useStyles()

    const subHeaderStyle: React.CSSProperties = {
        ...theme.typography.body2,
        marginRight: theme.spacing(1),
        alignItems: 'center',
        fontWeight: 400,
        marginBottom: theme.spacing(1),
    }

    const infoStyle: React.CSSProperties = {
        ...theme.typography.subtitle2,
        fontWeight: 600,
    }

    const numberResponses = rfp.bids.filter(
        (bid) => bid.status !== BidStatus.INVITATION,
    ).length

    let daysRemaining = 0
    if (rfp.project_submission_deadline) {
        const today = new Date()
        daysRemaining = getDifferenceInDays(
            new Date(rfp.project_submission_deadline),
            today,
        )
    }

    return (
        <Container
            style={{
                height: '245px',
                width: '600px',
                border: `1px solid ${theme.palette.lightGrey.light}`,
                borderRadius: '3px',
                margin: theme.spacing(1),
                cursor: 'pointer',
            }}
            onClick={onClick}
            className={classes.hoverGrey200}
            direction="column"
        >
            <Container
                style={{
                    marginBottom: theme.spacing(1),
                    borderBottom: `1px solid ${theme.palette.lightGrey.light}`,
                    padding: theme.spacing(1, 2, 1, 2),
                }}
            >
                <Container
                    style={{
                        ...theme.typography.h6,
                        fontWeight: 600,
                    }}
                >
                    {rfp.name}
                </Container>
                {managerView && (
                    <Container
                        style={{
                            ...theme.typography.h6,
                            fontWeight: 600,
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        - {rfp.apartment.name}
                    </Container>
                )}
                <Container flex={1} />
                <RFPStatusBadge status={rfp.status} theme={theme} />
            </Container>
            <Container style={{ padding: theme.spacing(1, 2, 1, 2) }}>
                <Container
                    flex={1}
                    direction="column"
                    style={{ marginRight: theme.spacing(1), overflow: 'auto' }}
                >
                    {managerView && (
                        <Container
                            style={{
                                ...infoStyle,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            {rfp.apartment.primary_address.city},{' '}
                            {rfp.apartment.primary_address.state}
                        </Container>
                    )}
                    {rfp.status !== RFPStatus.DRAFT && (
                        <Container>
                            <Container
                                style={{ ...subHeaderStyle, width: '125px' }}
                            >
                                Vendor Responses:
                            </Container>
                            <Container style={{ ...infoStyle }}>
                                {numberResponses}
                            </Container>
                        </Container>
                    )}
                    {rfp.project_submission_deadline && (
                        <Container>
                            <Container
                                style={{ ...subHeaderStyle, width: '125px' }}
                            >
                                Days Remaining:
                            </Container>
                            <Container style={{ ...infoStyle }}>
                                {daysRemaining}
                            </Container>
                        </Container>
                    )}
                </Container>
                <Container direction="column" flex={1} style={{}}>
                    {rfp.created_date && (
                        <Container>
                            <Container
                                style={{ ...subHeaderStyle, width: '90px' }}
                            >
                                Created Date:
                            </Container>
                            <Container style={{ ...infoStyle }}>
                                {toMMDDYYYY(new Date(rfp.created_date))}
                            </Container>
                        </Container>
                    )}
                    {rfp.project_start_date && (
                        <Container>
                            <Container
                                style={{ ...subHeaderStyle, width: '90px' }}
                            >
                                Start Date:
                            </Container>
                            <Container style={{ ...infoStyle }}>
                                {toMMDDYYYY(new Date(rfp.project_start_date))}
                            </Container>
                        </Container>
                    )}
                    {rfp.project_end_date && (
                        <Container>
                            <Container
                                style={{ ...subHeaderStyle, width: '90px' }}
                            >
                                End Date:
                            </Container>
                            <Container style={{ ...infoStyle }}>
                                {toMMDDYYYY(new Date(rfp.project_end_date))}
                            </Container>
                        </Container>
                    )}
                    <Container>
                        <Container
                            style={{
                                ...subHeaderStyle,
                                width: '90px',
                                marginBottom: 0,
                            }}
                        >
                            Budget:
                        </Container>
                        <Container style={{ ...infoStyle }}>
                            ${rfp.budget}
                        </Container>
                    </Container>
                </Container>
            </Container>
            <Container
                flexWrap="wrap"
                style={{ padding: theme.spacing(0, 2, 1, 2) }}
            >
                {rfp.rfp_services[0] && (
                    <DisplayService
                        service={rfp.rfp_services[0].service}
                        border
                    />
                )}
                {rfp.rfp_services[1] && (
                    <DisplayService
                        service={rfp.rfp_services[1].service}
                        border
                    />
                )}
                {rfp.rfp_services.length > 2 && (
                    <>
                        <Container>
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleOpen(e)
                                }}
                                size="small"
                                style={{
                                    margin: theme.spacing(1, 0, 1, 0),
                                }}
                            >
                                +{rfp.rfp_services.length - 2}
                            </Button>
                        </Container>
                        <ListPopover
                            open={isOpen}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                horizontal: -50,
                                vertical: 'bottom',
                            }}
                            style={{ paddingLeft: theme.spacing(1) }}
                        >
                            {rfp.rfp_services.slice(2).map((rfpService) => {
                                return (
                                    <DisplayService
                                        service={rfpService.service}
                                        border={false}
                                        key={`RFP_SERVICE_DISPLAY_${rfpService.id}`}
                                    />
                                )
                            })}
                        </ListPopover>
                    </>
                )}
            </Container>
        </Container>
    )
}
