import React from 'react'
import { Button, Divider, TextField, useTheme } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Container, SideDrawer } from '../../components'
import { InspectionType } from '../../models'
import { useAppDispatch, useUser } from '../../hooks'
import { createInspectionType, editInspectionTypeReq } from '../../store'
import { toast } from 'react-toastify'
import { InspectionTypeInvConfigDrawer } from './InspectionTypeInvConfigDrawer'
import { axiosInstance } from '../../helpers'

interface InspectionTypeDrawerProps {
    inspectionType?: InspectionType
    open: boolean
    handleClose: () => void
    setMoveOutInspection: (id: number) => void
}

export const InspectionTypeDrawer = (props: InspectionTypeDrawerProps) => {
    const { inspectionType, open, handleClose, setMoveOutInspection } = props

    const EDIT_MODE = inspectionType ? true : false

    const theme = useTheme()
    const dispatch = useAppDispatch()

    const [name, setName] = useState(inspectionType?.name ?? '')
    const [doValidate, setDoValidate] = useState(false)
    const [openInvConfigDrawer, setOpenInvConfigDrawer] = useState(false)

    const { workspaceUser } = useUser()

    useEffect(() => {
        setDoValidate(false)
        if (inspectionType) {
            setName(inspectionType.name)
        } else {
            setName('')
        }
    }, [inspectionType])

    const drawerHandleClose = () => {
        handleClose()
        setDoValidate(false)
        setOpenInvConfigDrawer(false)
    }

    return (
        <SideDrawer
            open={open}
            handleClose={handleClose}
            title={
                inspectionType
                    ? 'Update Inspection Type'
                    : 'Create Inspection Type'
            }
        >
            {/* body */}
            <Container
                direction="column"
                style={{ padding: theme.spacing(2) }}
                flex={1}
                scrollY
            >
                <TextField
                    error={doValidate && name === ''}
                    helperText={doValidate && name === '' ? 'Required' : ''}
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label="Name"
                />

                {/* Button to go to inv config drawer */}
                <Button
                    color="primary"
                    variant="outlined"
                    disabled={!EDIT_MODE}
                    onClick={() => setOpenInvConfigDrawer(true)}
                    style={{ margin: theme.spacing(2, 0) }}
                >
                    Select Items
                </Button>
                <Button
                    color="secondary"
                    variant="outlined"
                    disabled={!EDIT_MODE}
                    onClick={() => {
                        const req = {
                            body: {
                                move_out_inspection_config: inspectionType?.id,
                            },
                        }
                        const url = `company/apartment/${workspaceUser?.active_workspace.id}/`
                        axiosInstance.patch(url, req.body).then((res) => {
                            if (res.data.move_out_inspection_config) {
                                setMoveOutInspection(
                                    res.data.move_out_inspection_config,
                                )
                                toast.success('Move in inspection type changed')
                            } else {
                                toast.error('Can not find inspection type')
                            }
                        })
                    }}
                >
                    Set Move In Inspection
                </Button>
            </Container>
            {/* footer */}
            <Divider />
            <Container
                style={{
                    padding: theme.spacing(2),
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    color="secondary"
                    style={{ marginRight: theme.spacing(2) }}
                    variant="outlined"
                    onClick={drawerHandleClose}
                >
                    Close
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                        if (name !== '') {
                            if (inspectionType) {
                                dispatch(
                                    editInspectionTypeReq({
                                        id: inspectionType.id,
                                        body: { name: name },
                                    }),
                                ).then(() => {
                                    toast.success(`${name} edited.`)
                                    drawerHandleClose()
                                })
                            } else {
                                dispatch(
                                    createInspectionType({
                                        body: {
                                            name: name,
                                        },
                                    }),
                                ).then(() => {
                                    toast.success(`${name} created.`)
                                    drawerHandleClose()
                                })
                            }
                        } else {
                            setDoValidate(true)
                            toast.error('Name is Required')
                        }
                    }}
                >
                    Save
                </Button>
            </Container>
            {inspectionType !== undefined && (
                <InspectionTypeInvConfigDrawer
                    open={openInvConfigDrawer}
                    handleClose={() => setOpenInvConfigDrawer(false)}
                    inspectionType={inspectionType}
                />
            )}
        </SideDrawer>
    )
}
