import React, { useEffect, useMemo, useState } from 'react'
import { Container } from '../../components'
import {
    Button,
    Checkbox,
    Divider,
    TextField,
    Theme,
    useTheme,
} from '@material-ui/core'
import { InventoryConfig, InventoryGroup } from '../../models'
import { SearchField } from '../../components/SearchField'
import { InventoryGroupRequest } from '../../hooks'

interface Props {
    group: InventoryGroup | null
    inventoryConfigs?: InventoryConfig[]
    handleSave: (request: InventoryGroupRequest) => Promise<void>
    handleClose: () => void
}

export const InventoryGroupForm = (props: Props) => {
    const { inventoryConfigs, group, handleSave, handleClose } = props

    const [
        inventoryConfigSelectionState,
        setInventoryConfigSelectionState,
    ] = useState<InventoryConfigSelectionState>({})

    const [name, setName] = useState('')

    const [search, setSearch] = useState('')

    const theme = useTheme()

    useEffect(() => {
        const newState: InventoryConfigSelectionState = {}
        inventoryConfigs?.forEach((ic) => {
            newState[ic.id] = { selected: false, amount: 1 }
        })

        if (group) {
            group.inventory_configs.forEach((config) => {
                newState[config.inventory_config] = {
                    selected: true,
                    amount: config.amount,
                }
            })

            setName(group.name)
        }

        setInventoryConfigSelectionState(newState)
    }, [inventoryConfigs, group])

    const updateState = (
        icId: number,
        value: {
            selected?: boolean
            amount?: number
        },
    ) => {
        setInventoryConfigSelectionState((oldState) => {
            const newState = { ...oldState }

            const oldValue = oldState[icId]
            const newValue = {
                ...oldValue,
            }

            if (value.selected !== undefined) {
                newValue.selected = value.selected
            }

            if (value.amount !== undefined) {
                newValue.amount = value.amount
            }

            newState[icId] = newValue

            return newState
        })
    }

    const filteredInv = useMemo(() => {
        const lcSearch = search.toLocaleLowerCase()
        return inventoryConfigs?.filter((ic) =>
            ic.name.toLocaleLowerCase().includes(lcSearch),
        )
    }, [inventoryConfigs, search])

    return (
        <Container
            style={{
                padding: theme.spacing(2),
                flexDirection: 'column',

                maxHeight: 'calc(100% - 100px)',
                height: 700,
                width: 500,
            }}
        >
            <span
                style={{
                    ...theme.typography.h6,
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >
                {group ? `Edit ${group.name}` : 'New Inventory Group'}
            </span>
            <TextField
                style={{ marginTop: theme.spacing(1) }}
                variant="outlined"
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                inputProps={{ maxLength: 30 }}
            />

            <SearchField
                label="Inventory Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{
                    marginRight: theme.spacing(1),
                    marginTop: theme.spacing(2),
                }}
            />

            <Container
                style={{
                    flexDirection: 'column',
                    overflowY: 'scroll',
                    marginBottom: theme.spacing(1),
                    marginTop: theme.spacing(1),
                    flex: 1,
                }}
            >
                {filteredInv?.map((ic) => {
                    return (
                        <ICRow
                            checked={
                                inventoryConfigSelectionState[ic.id]
                                    ?.selected ?? false
                            }
                            onChangeChecked={(newChecked) => {
                                updateState(ic.id, { selected: newChecked })
                            }}
                            key={`IC_ROW_${ic.id}`}
                            inventoryConfig={ic}
                            theme={theme}
                        />
                    )
                })}
            </Container>

            <Divider />

            <Container
                style={{
                    justifyContent: 'space-between',
                    padding: theme.spacing(1),
                }}
            >
                <Button
                    variant="outlined"
                    color="secondary"
                    style={{
                        textTransform: 'none',
                    }}
                    onClick={handleClose}
                >
                    Cancel
                </Button>

                <Button
                    variant="outlined"
                    color="primary"
                    style={{
                        textTransform: 'none',
                    }}
                    onClick={() => {
                        const inventoryConfigs: any[] = []
                        Object.keys(inventoryConfigSelectionState).forEach(
                            (strId) => {
                                const numId = Number(strId)
                                if (
                                    inventoryConfigSelectionState[numId]
                                        .selected
                                ) {
                                    inventoryConfigs.push({
                                        id: numId,
                                        amount:
                                            inventoryConfigSelectionState[numId]
                                                .amount,
                                    })
                                }
                            },
                        )

                        // console.log(inventoryConfigs)
                        // return

                        handleSave({
                            name: name,
                            inventory_configs: inventoryConfigs,
                        })
                    }}
                >
                    Save
                </Button>
            </Container>
        </Container>
    )
}

interface ICRowProps {
    inventoryConfig: InventoryConfig
    theme: Theme
    checked: boolean
    onChangeChecked: (newChecked: boolean) => void
}

const ICRow = (props: ICRowProps) => {
    const { inventoryConfig, theme, checked, onChangeChecked } = props

    return (
        <Container
            style={{
                border: `1px solid ${theme.palette.grey[700]}`,
                borderRadius: theme.shape.borderRadius,
                marginTop: theme.spacing(2),
                marginRight: theme.spacing(1),
                padding: theme.spacing(1),
                alignItems: 'center',
            }}
        >
            <Checkbox
                checked={checked}
                onChange={(_, checked) => onChangeChecked(checked)}
            />
            <span
                style={{
                    ...theme.typography.body1,
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >
                {inventoryConfig.name}
            </span>
        </Container>
    )
}

interface SelectedState {
    selected: boolean
    amount: number
}

interface InventoryConfigSelectionState {
    [invConfigId: number]: SelectedState
}
