import React from 'react'

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'

import { Container } from '../../../../../components'
import { BaseProps } from '../types'
import { ApartmentDeadline } from '../../../../../models'

interface Props extends BaseProps {
    deadline: ApartmentDeadline
    onClick: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        deadline: ApartmentDeadline,
    ) => void
}

export const EventTypeDeadline = (props: Props) => {
    const { theme, deadline } = props

    const Icon = deadline.complete_status
        ? RadioButtonCheckedIcon
        : RadioButtonUncheckedIcon

    return (
        <Container
            style={{ alignItems: 'center' }}
            onClick={(e) => props.onClick(e, deadline)}
        >
            <Icon
                style={{
                    fontSize: 12,
                    marginRight: theme.spacing(1),
                }}
            />
            <span
                style={{
                    ...theme.typography.body2,
                    fontWeight: theme.typography.fontWeightMedium,
                }}
            >
                {deadline.deadline.name}
            </span>
        </Container>
    )
}
