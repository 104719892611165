import { CircularProgress, Tab, Tabs, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container, SideDrawerContainer } from '../../components'
import { axiosInstance } from '../../helpers'
import {
    InspectionWithReportDate,
    Lease,
    Report,
    ReportTypes,
    Schedule,
} from '../../models'
import { InspectionReportTable } from './InspectionReportTable'
import { ReportsDrawer } from './ReportsDrawer'
import { WorkorderReportTable } from './WorkorderReportTable'
import { ReportTable } from './ReportTable'

const reportTypes =
    'LEASE_SYNC_REPORT,BED_STATUS_SYNC_REPORT,DAMAGE_AND_TRANSACTION_REPORT'

export const ReportsHome = () => {
    const theme = useTheme()

    useEffect(() => {
        const inspectionPromise = axiosInstance.get('inspection/', {
            params: { has_report: true },
        })
        const workorderPromise = axiosInstance.get(`workorder/schedule/`, {
            params: { has_report: true },
        })
        const reportPromise = axiosInstance.get(`stats/reports/`, {
            params: { report_types: reportTypes },
        })

        Promise.all([inspectionPromise, workorderPromise, reportPromise])
            .then((values) => {
                setInspectionList(values[0].data)
                setScheduleList(values[1].data)

                const allReports: Report[] = values[2].data
                setLeaseSyncReports(
                    allReports.filter(
                        (report) =>
                            report.report_type ===
                            ReportTypes.LEASE_SYNC_REPORT,
                    ),
                )
                setBedStatusSyncReports(
                    allReports.filter(
                        (report) =>
                            report.report_type ===
                            ReportTypes.BED_STATUS_SYNC_REPORT,
                    ),
                )
                setDamageReports(
                    allReports.filter(
                        (report) =>
                            report.report_type ===
                            ReportTypes.DAMAGE_AND_TRANSACTION_REPORT,
                    ),
                )
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const [scheduleList, setScheduleList] = useState<Schedule[]>([])
    const [inspectionList, setInspectionList] = useState<
        InspectionWithReportDate[]
    >([])

    const [leaseSyncReports, setLeaseSyncReports] = useState<Report[]>([])
    const [bedStatusSyncReports, setBedStatusSyncReports] = useState<Report[]>(
        [],
    )
    const [damageReports, setDamageReports] = useState<Report[]>([])

    const [loading, setLoading] = useState(true)

    const [selectedSchedule, setSelectedSchedule] = useState<Schedule>()
    const [
        selectedInspection,
        setSelectedInspection,
    ] = useState<InspectionWithReportDate>()

    const WORKORDER_TAB = 0
    const INSPECTION_TAB = 1
    const LEASE_SYNCING_TAB = 2
    const BED_STATUS_SYNCING_TAB = 3
    const DAMAGE_TAB = 4

    const [selectedTab, setSelectedTab] = useState<number>(WORKORDER_TAB)
    const [drawerOpen, setDrawerOpen] = useState(false)

    const closeDrawer = () => {
        setDrawerOpen(false)
        setSelectedInspection(undefined)
        setSelectedSchedule(undefined)
    }

    return (
        <SideDrawerContainer open={drawerOpen}>
            {loading ? (
                <Container
                    style={{
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '80vh',
                    }}
                >
                    <CircularProgress size={80} />
                </Container>
            ) : (
                <Container
                    flex={1}
                    direction="column"
                    style={{ height: 'calc(100vh - 104px)' }}
                >
                    {/* Header */}
                    <Container>
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Reports
                        </span>
                    </Container>

                    {/* Table */}

                    <Container direction="column" scrollY>
                        <Tabs
                            style={{
                                width: '100%',
                                backgroundColor: theme.palette.grey[300],
                            }}
                            value={selectedTab}
                            onChange={(_, v) => {
                                if (v !== selectedTab) {
                                    setSelectedTab(v)
                                    closeDrawer()
                                }
                            }}
                        >
                            <Tab label="Work Orders" />
                            <Tab label="Inspections" />
                            <Tab label="Lease Syncing" />
                            <Tab label="Bed Status Syncing" />
                            <Tab label="Damages" />
                        </Tabs>
                        <Container
                            style={{
                                flex: 1,
                                backgroundColor: theme.palette.grey[100],
                            }}
                            scrollY
                        >
                            {selectedTab === WORKORDER_TAB && (
                                <WorkorderReportTable
                                    scheduleList={scheduleList}
                                    onClick={(newSchedule) => {
                                        setSelectedSchedule(newSchedule)
                                        setDrawerOpen(true)
                                    }}
                                />
                            )}
                            {selectedTab === INSPECTION_TAB && (
                                <InspectionReportTable
                                    inspectionList={inspectionList}
                                    onClick={(inspection) => {
                                        setSelectedInspection(inspection)
                                        setDrawerOpen(true)
                                    }}
                                />
                            )}
                            {selectedTab === LEASE_SYNCING_TAB && (
                                <ReportTable reports={leaseSyncReports} />
                            )}
                            {selectedTab === BED_STATUS_SYNCING_TAB && (
                                <ReportTable reports={bedStatusSyncReports} />
                            )}
                            {selectedTab === DAMAGE_TAB && (
                                <ReportTable reports={damageReports} />
                            )}
                        </Container>
                    </Container>
                </Container>
            )}

            <ReportsDrawer
                open={drawerOpen}
                handleClose={closeDrawer}
                inspectionId={selectedInspection?.id}
                scheduleId={selectedSchedule?.id}
            />
        </SideDrawerContainer>
    )
}
