import { useSelector } from 'react-redux'
import { Message } from '../models'
import {
    createMessage,
    getMessageList,
    GetMessageListActionThunk,
    GetMessageListRequest,
    MessageDetailActionThunk,
    resetMessageState,
    RootState,
} from '../store'
import { useAppDispatch } from './useAppDispatch'

type UseMessage = {
    messageList: Message[]
    getMessageList: (req: GetMessageListRequest) => GetMessageListActionThunk
    createMessage: (req: FormData) => MessageDetailActionThunk
    resetMessageState: () => void
}

export const useMessage = (): UseMessage => {
    const dispatch = useAppDispatch()
    const messageList = useSelector(
        (state: RootState) => state.messaging.messages,
    )

    return {
        messageList: messageList,
        getMessageList: (req) => dispatch(getMessageList(req)),
        createMessage: (req) => dispatch(createMessage(req)),
        resetMessageState: () => dispatch(resetMessageState()),
    }
}
