import React, { useRef, useState } from 'react'
import clsx from 'clsx'

import { Container } from '../Container'
import { IconButton, makeStyles } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

type SwiperPosition = boolean
interface Props {
    width?: number
    position: SwiperPosition
    leftItem: JSX.Element
    rightItem: JSX.Element
    onChangePosition: (position: SwiperPosition) => void
    style?: React.CSSProperties
    disabled?: boolean
}

export const Swiper = (props: Props) => {
    const iconWidth = 48
    const containerWidth = props.width ?? 342
    const itemWidth = containerWidth - iconWidth

    const animStyles = makeStyles((theme) => ({
        left: {
            transform: `translateX(-${itemWidth}px)`,
        },
        right: {
            transform: `translateX(0}px)`,
        },
        animateable: {
            transition: `all 250ms`,
        },
    }))()

    const Icon = props.position ? ChevronRightIcon : ChevronLeftIcon

    return (
        // Container
        <Container
            style={{
                ...props.style,
                maxWidth: containerWidth,
                minWidth: containerWidth,
                display: 'relative',
            }}
        >
            {/* Slider */}
            <Container
                style={{ alignItems: 'center' }}
                className={clsx(
                    animStyles.animateable,
                    props.position ? animStyles.left : animStyles.right,
                )}
            >
                {/* Left Item */}
                <Container
                    style={{
                        flex: 1,
                        maxWidth: itemWidth,
                        minWidth: itemWidth,
                    }}
                >
                    {props.leftItem}
                </Container>

                <div style={{ minWidth: iconWidth, maxWidth: iconWidth }}>
                    <IconButton
                        // size="small"
                        onClick={() => {
                            props.onChangePosition(!props.position)
                        }}
                        disabled={props.disabled}
                    >
                        <Icon />
                    </IconButton>
                </div>

                {/* Right Item */}
                <Container
                    style={{
                        flex: 1,
                        maxWidth: itemWidth,
                        minWidth: itemWidth,
                    }}
                >
                    {props.rightItem}
                </Container>
            </Container>
        </Container>
    )
}
