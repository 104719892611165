import { Folder, Unit } from '../../models'
import { Location, LocationSelection } from './types'

export const getLocationFromSelection = (
    selection: LocationSelection,
): Location | undefined => {
    if (selection.folder.length !== 0) {
        const selectionKeys = Object.keys(selection.folder)
        for (let i = 0; i < selectionKeys.length; i++) {
            const curKey = selectionKeys[i]
            if (curKey === 'length') {
                continue
            }

            const curSelection = selection.folder[Number(curKey)]
            if (curSelection !== undefined) {
                return {
                    type: 'folder',
                    location: curSelection,
                }
            }
        }
    } else if (selection.unit.length !== 0) {
        const selectionKeys = Object.keys(selection.unit)
        for (let i = 0; i < selectionKeys.length; i++) {
            const curKey = selectionKeys[i]
            if (curKey === 'length') {
                continue
            }

            const curSelection = selection.unit[Number(curKey)]
            if (curSelection !== undefined) {
                return {
                    type: 'unit',
                    location: curSelection,
                }
            }
        }
    }
    return undefined
}
