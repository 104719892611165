import {
    Button,
    CircularProgress,
    Grid,
    Modal,
    Paper,
    Slide,
    Tab,
    Tabs,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useSelector, useStore } from 'react-redux'
import { toast } from 'react-toastify'
import { Container } from '../../components'
import { axiosInstance } from '../../helpers'
import { useAppDispatch, useCompany, useUser } from '../../hooks'
import {
    getPortfolioBudget,
    getPortfolioScheduleList,
    GetPortfolioScheduleListParams,
    RootState,
} from '../../store'
import { USER_PROFILE_DRAWER_WIDTH } from '../UserProfileDrawer'
import { NameValueDict } from './PortfolioAnalyticsFilterRow'
import { PortfolioInspectionTab } from './PortfolioInspectionTab'
import { PortfolioScheduleTab } from './PortfolioScheduleTab'
import { PortfolioServiceContractTab } from './PortfolioServiceContractTab'
import AdvertisementSlider from '../../components/AdvertisementSlider'
import {
    INCIDENT_REPORTS,
    PreTurnConfigItem,
    TenantNegligibleFilter,
} from '../../models'
import { NVLVendor } from '../../main/containers/national-vendor-list'
import { AnalyticsHome } from '../Analytics'
import { hasPermission } from '../../models/Users/services'
import { IncidentReportRoot } from '../Inspection/IncidentReportRoot'

interface Props {
    open: boolean
    onClose: () => void
}

export interface Filter {
    param?: keyof GetPortfolioScheduleListParams
    operatorOptions?: NameValueDict[]
    operatorParam?: keyof GetPortfolioScheduleListParams
    operatorValue?: string
    value?: string
    uuid: string
}

const PREMIUM_PLUS = 'PREMIUM_PLUS'

export const _PortfolioAnalytics = (props: Props) => {
    const { open, onClose } = props

    const theme = useTheme()
    const dispatch = useAppDispatch()
    const [reportLoading, setReportLoading] = useState(false)

    const SCHEDULE_TAB = 0
    const SERVICE_CONTRACT_TAB = 1
    const INSPECTION_TAB = 2
    const VENDOR_TAB = 3
    const INCIDENT_REPORT_TAB = 4

    const [selectedTab, setSelectedTab] = useState<number>(SCHEDULE_TAB)

    const scheduleCount = useSelector(
        (state: RootState) =>
            state.pagination.GET_PORTFOLIO_SCHEDULE_LIST_REQUEST?.count,
    )
    const inspectionCount = useSelector(
        (state: RootState) => state.workorder.schedule.portfolioInspectionCount,
    )
    const apartmentCount = useSelector(
        (state: RootState) => state.workorder.schedule.portfolioApartmentCount,
    )
    const budgetInfo = useSelector(
        (state: RootState) => state.workorder.schedule.portfolioBudget,
    )
    const [checkListItemConfigs, setCheckListItemConfigs] = useState<
        PreTurnConfigItem[]
    >([])

    const { workspaceUser } = useUser()

    const [vendors, setVendors] = useState<NVLVendor[]>([])
    const [incidentReportFilters, setIncidentReportFilters] = useState({
        status: -1,
        incidentType: -1,
        tenantNegligible: TenantNegligibleFilter.NO_FILTER,
    })

    useEffect(() => {
        dispatch(getPortfolioScheduleList({ params: { page_size: 15 } }))
        dispatch(getPortfolioBudget({}))
        axiosInstance
            .get('apt_config/pre-turn-item-config/', {
                params: { portfolio: true },
            })
            .then((res) => {
                console.log('check list items', res.data)
                setCheckListItemConfigs(res.data)
            })
        axiosInstance
            .get(`company/national_vendor_list?has_plan=True`)
            .then((res) => {
                setVendors(res.data)
            })
    }, [])

    const displayAds =
        vendors.filter((vendor) => vendor.plan === PREMIUM_PLUS).length !== 0

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        flex: 1,
                        maxWidth: '80%',
                        height: '90%',
                    }}
                >
                    <Container
                        style={{
                            flexDirection: 'column',
                            flex: 1,
                            padding: theme.spacing(1),
                        }}
                    >
                        {/* header */}
                        <Container style={{ alignItems: 'center' }}>
                            <span
                                style={{
                                    ...theme.typography.h5,
                                    fontWeight: theme.typography.fontWeightBold,
                                    marginBottom: theme.spacing(1),
                                }}
                            >
                                Portfolio Analytics
                            </span>
                            <Container
                                style={{
                                    flexDirection: 'column',
                                    flex: 1,
                                    marginLeft: theme.spacing(2),
                                }}
                            >
                                <Container style={{ alignItems: 'center' }}>
                                    <span
                                        style={{
                                            ...theme.typography.body1,
                                            marginBottom: theme.spacing(1),
                                            flex: 1,
                                        }}
                                    >
                                        Schedule Count: {scheduleCount}
                                    </span>
                                    <span
                                        style={{
                                            ...theme.typography.body1,
                                            marginBottom: theme.spacing(1),
                                            flex: 1,
                                        }}
                                    >
                                        Inspection Count: {inspectionCount}
                                    </span>
                                    <span
                                        style={{
                                            ...theme.typography.body1,
                                            marginBottom: theme.spacing(1),
                                            flex: 1,
                                        }}
                                    >
                                        Apartment Count: {apartmentCount}
                                    </span>
                                </Container>
                                <Container style={{ alignItems: 'center' }}>
                                    <NumberFormat
                                        prefix={'Forecasted Total: $'}
                                        value={budgetInfo?.forecasted ?? 0}
                                        displayType="text"
                                        thousandSeparator=","
                                        decimalScale={2}
                                        style={{
                                            ...theme.typography.body1,
                                            marginBottom: theme.spacing(1),
                                            flex: 1,
                                        }}
                                    />

                                    <NumberFormat
                                        prefix={'Budgeted Total: $'}
                                        value={budgetInfo?.budgeted ?? 0}
                                        displayType="text"
                                        thousandSeparator=","
                                        decimalScale={2}
                                        style={{
                                            ...theme.typography.body1,
                                            marginBottom: theme.spacing(1),
                                            flex: 1,
                                        }}
                                    />

                                    <NumberFormat
                                        prefix={'Invoiced Total: $'}
                                        value={budgetInfo?.invoiced ?? 0}
                                        displayType="text"
                                        thousandSeparator=","
                                        decimalScale={2}
                                        style={{
                                            ...theme.typography.body1,
                                            marginBottom: theme.spacing(1),
                                            flex: 1,
                                        }}
                                    />
                                </Container>
                            </Container>
                            {reportLoading ? (
                                <CircularProgress
                                    style={{ margin: theme.spacing(2) }}
                                />
                            ) : (
                                <></>
                                // <Button
                                //     style={{ margin: theme.spacing(2) }}
                                //     variant="outlined"
                                //     color="primary"
                                //     onClick={() => {
                                //         if (
                                //             confirm(
                                //                 'This will email all active schedules to every admin user in every workspace that you share.',
                                //             )
                                //         ) {
                                //             setReportLoading(true)
                                //             axiosInstance
                                //                 .get(
                                //                     '/company/apartment/schedule_report/',
                                //                     {
                                //                         params: {
                                //                             'all-workspace': true,
                                //                         },
                                //                     },
                                //                 )
                                //                 .then(() => {
                                //                     toast.success(
                                //                         'You will receive your schedule report emails shortly.',
                                //                     )
                                //                 })
                                //                 .catch(() => {
                                //                     toast.error(
                                //                         'No schedules found',
                                //                     )
                                //                 })
                                //                 .finally(() =>
                                //                     setReportLoading(false),
                                //                 )
                                //         }
                                //     }}
                                // >
                                //     Daily Summary Report
                                // </Button>
                            )}
                            {displayAds && (
                                <AdvertisementSlider
                                    sponsoredVendors={vendors.filter(
                                        (vendor) =>
                                            vendor.plan === PREMIUM_PLUS,
                                    )}
                                />
                            )}
                        </Container>

                        <Tabs
                            style={{
                                width: '100%',
                                backgroundColor: theme.palette.grey[300],
                            }}
                            value={selectedTab}
                            onChange={(_, v) => {
                                if (v !== selectedTab) {
                                    setSelectedTab(v)
                                }
                            }}
                        >
                            <Tab label="Schedules" />
                            <Tab label="Service Contracts" />
                            <Tab label="Inspections" />
                            <Tab label="Vendors" />
                            {hasPermission(workspaceUser, INCIDENT_REPORTS) && (
                                <Tab label="Incident Reports" />
                            )}
                        </Tabs>
                        {selectedTab === SCHEDULE_TAB && (
                            <PortfolioScheduleTab
                                checkListItemConfigs={checkListItemConfigs}
                            />
                        )}
                        {selectedTab === SERVICE_CONTRACT_TAB && (
                            <PortfolioServiceContractTab />
                        )}
                        {selectedTab === INSPECTION_TAB && (
                            <PortfolioInspectionTab />
                        )}
                        {selectedTab === VENDOR_TAB && (
                            <AnalyticsHome
                                portfolio_analytics_view
                                height="calc(80vh - 320px)"
                            />
                        )}
                        {selectedTab === INCIDENT_REPORT_TAB && (
                            <IncidentReportRoot
                                onIncidentSelect={() => {}}
                                portfolio
                                incidentReportFilters={incidentReportFilters}
                                handleFiltersClose={() => {}}
                                setFilters={() => {}}
                                filtersOpen={false}
                            />
                        )}
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
