import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'

import { Container } from '../../components'
import {
    Damage,
    DamageStatus,
    getDamageTransitions,
    WorkSpaceUser,
} from '../../models'

interface Props {
    workspaceUser?: WorkSpaceUser
    damages?: Damage[] | Damage
    handleStatusChange: (damages: Damage[], status: DamageStatus) => void
}

export const DamageStatusChangeButtons = (props: Props) => {
    const { damages, workspaceUser } = props

    if (damages === undefined) {
        return null
    }

    let safeDamages: Damage[]
    if (!Array.isArray(damages)) {
        safeDamages = [damages]
    } else {
        safeDamages = damages
    }

    if (safeDamages.length === 0) {
        return null
    }

    // Make sure every damage in the array has the same status
    const firstStatus = safeDamages[0].status

    for (let i = 0; i < safeDamages.length; i++) {
        if (firstStatus !== safeDamages[i].status) {
            return null
        }
    }

    const transitions = getDamageTransitions(safeDamages[0], workspaceUser)

    let PositionTransitionIcon = ThumbUpIcon
    if (transitions.positive?.to_status === DamageStatus.ENTRATA_SYNCED) {
        PositionTransitionIcon = CloudUploadIcon
    }

    return (
        <Container
            style={{
                alignItems: 'center',
            }}
        >
            {transitions.negative && (
                <div>
                    <Tooltip
                        title={
                            !transitions.negative.permission
                                ? 'Permission denied'
                                : transitions.negative.title
                        }
                    >
                        <IconButton
                            onClick={() => {
                                if (transitions.negative?.permission) {
                                    props.handleStatusChange(
                                        safeDamages,
                                        transitions.negative.to_status,
                                    )
                                }
                            }}
                        >
                            <ThumbDownIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )}

            {transitions.positive && (
                <div>
                    <Tooltip
                        title={
                            !transitions.positive.permission
                                ? 'Permission denied'
                                : transitions.positive.title
                        }
                    >
                        <IconButton
                            onClick={() => {
                                if (transitions.positive?.permission) {
                                    props.handleStatusChange(
                                        safeDamages,
                                        transitions.positive.to_status,
                                    )
                                }
                            }}
                        >
                            <PositionTransitionIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </Container>
    )
}
