import {
    FinderActionTypes,
    FinderLocationSelection,
    SET_FINDER_SELECTION,
    Location,
    FinderSelectionMode,
    COPY_FINDER_SELECTION,
    LocationSelection,
} from './types'

export const setFinderSelection = (
    option: FinderLocationSelection,
    mode: FinderSelectionMode,
    location?: Location,
    excluder?: (location: Location) => boolean,
    resultCallback?: (result: LocationSelection) => void,
): FinderActionTypes => {
    return {
        type: SET_FINDER_SELECTION,
        option: option,
        mode: mode,
        location: location,
        excluder: excluder,
        resultCallback: resultCallback,
    }
}

export const copyFinderSelection = (
    option: FinderLocationSelection,
    locationSelection: LocationSelection,
): FinderActionTypes => {
    return {
        type: COPY_FINDER_SELECTION,
        option: option,
        locationSelection: locationSelection,
    }
}
