import { useTheme } from '@material-ui/core'

export const useInfrastructureConfigStyles = (): {
    [key: string]: React.CSSProperties
} => {
    const theme = useTheme()

    return {
        columnStyle: {
            flex: 1,
            flexDirection: 'column',
        },
        panelBorder: {
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${theme.palette.grey[400]}`,
        },
        titleStyle: {
            ...theme.typography.h6,
            fontWeight: theme.typography.fontWeightBold,
        },
        rowStyle: {
            margin: theme.spacing(1),
            padding: theme.spacing(1),
        },
    }
}
