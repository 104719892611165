import React from 'react'

import { Theme } from '@material-ui/core'
import { StatusBadge } from '../../components'
import { BidStatus, getUiString, RFPStatus } from '../../models'

interface RFPStatusBadgeProps {
    theme: Theme
    status: RFPStatus | BidStatus
    style?: { container?: React.CSSProperties; text?: React.CSSProperties }
}

export const RFPStatusBadge = (props: RFPStatusBadgeProps) => {
    const { status, theme, style } = props
    const text = getUiString(status)

    return (
        <StatusBadge
            text={text}
            customStyle={{
                container: {
                    backgroundColor: theme.palette[status].main,
                    // max height so they do not try to strech
                    maxHeight: '30px',
                    //min width or else in progress gets put on two lines
                    minWidth: 80,
                    ...style?.container,
                },
                text: {
                    ...theme.typography.caption,
                    ...style?.text,
                },
            }}
        />
    )
}
