import { useState, useEffect } from 'react'
import { AnimationItem, AnimationState } from './types'

export const useAnimationQueue = (): AnimationState => {
    const [animationQueue, setAnimationQueue] = useState<AnimationItem[]>([])
    const [inProgress, setInProgress] = useState<AnimationItem | undefined>(
        undefined,
    )

    const enqueueAnimation = (...items: AnimationItem[]) => {
        setAnimationQueue([...animationQueue, ...items])
    }

    useEffect(() => {
        const peek = () => {
            if (animationQueue.length > 0) {
                return animationQueue[0]
            }
            return undefined
        }

        const dequeue = () => {
            const newQueue = [...animationQueue]
            const nextInLine = newQueue.shift()
            setAnimationQueue(newQueue)
            return nextInLine
        }

        const startNextAnimation = async (animation: AnimationItem) => {
            // Sleep for the duration of the animation
            await new Promise((r) => setTimeout(r, animation.duration))
            animation.cleanup && animation.cleanup()
        }

        const nextInLine = peek()
        // As long as there are still items in the queue, and no animations are currently
        // in progress, animate the next in line
        if (nextInLine && inProgress === undefined) {
            setInProgress(nextInLine)
            dequeue()

            startNextAnimation(nextInLine).then(() => {
                setInProgress(undefined)
            })
        }
    }, [animationQueue, inProgress])

    return {
        animationQueue: animationQueue,
        enqueueAnimation: enqueueAnimation,
        currentAnimation: inProgress,
    }
}
