import React, { useEffect, useRef, useState } from 'react'
import {
    Divider,
    IconButton,
    Popover,
    Theme,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import AddBoxIcon from '@material-ui/icons/AddBox'

import { Container } from '../../components'
import { Damage, getLeaseTimeline, Lease } from '../../models'
import { _useLease } from '../../hooks'
import { axiosInstance, toMMDDYYYY } from '../../helpers'

const endDateCutoff = new Date()
endDateCutoff.setMonth(endDateCutoff.getMonth() - 6)

export const TenantPopover: React.FC<{
    open: boolean
    anchor: Element | null | ((element: Element) => Element)
    damage?: Damage
    onClose: () => void
    addDamageTransaction: (lease: Lease, damage: Damage) => void
}> = ({ open, anchor, damage, onClose, addDamageTransaction }) => {
    const theme = useTheme()

    const todayString = new Date().toISOString()

    const { getLeaseList, leaseList, clearTenantList } = _useLease()

    const moreOptionAnchorElRef = useRef<HTMLDivElement>(null)
    const [moreOptionsOpen, setMoreOptionsOpen] = useState(false)

    const [moreTenantOptions, setMoreTenantOptions] = useState<Lease[]>([])

    useEffect(() => {
        return () => {
            clearTenantList()
        }
    }, [])

    useEffect(() => {
        if (open && damage) {
            getLeaseList({
                params: {
                    area: damage.area,
                    gte_end_date: endDateCutoff.toISOString(),
                },
            })
        }
    }, [open])

    const inAreaTransactionOptions = leaseList?.filter((lease) => {
        return (
            damage?.damage_transactions.find(
                (dt) => dt.lease.user.id === lease.user.id,
            ) === undefined
        )
    })

    const openMoreOptions = () => {
        setMoreOptionsOpen(true)

        if (damage?.area) {
            axiosInstance
                .get('lease/', {
                    params: {
                        units: damage.unit.toString(),
                        occupiable: true,
                        sort_order: 'area__area_config,area__area_label',
                        lte_start_date: todayString,
                        gte_end_date: endDateCutoff.toISOString(),
                    },
                })
                .then((res) => {
                    setMoreTenantOptions(res.data)
                })
        }
    }

    const rootContainerStyle: React.CSSProperties = {
        flexDirection: 'column',
        padding: theme.spacing(1),
        minWidth: 300,
    }

    if (damage === undefined) {
        return null
    }

    return (
        <Popover
            open={open}
            anchorEl={anchor}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
            }}
            onClose={onClose}
        >
            <Container style={rootContainerStyle} ref={moreOptionAnchorElRef}>
                <AreaHeader
                    theme={theme}
                    areaName={damage?.area_config_name ?? ''}
                    areaLabel={damage?.area_label ?? ''}
                    style={{
                        padding: theme.spacing(1),
                        backgroundColor: theme.palette.grey[100],
                    }}
                />
                {inAreaTransactionOptions?.map((lease) => {
                    return (
                        <AddTenantRow
                            key={`ADD_LEASE_${lease.id}`}
                            lease={lease}
                            theme={theme}
                            damage={damage}
                            addDamageTransaction={addDamageTransaction}
                        />
                    )
                })}

                <Container
                    style={{ flex: 1, alignItems: 'center', cursor: 'pointer' }}
                    onClick={openMoreOptions}
                >
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        More Options
                    </span>
                    <div style={{ flex: 1 }} />
                    <Tooltip title="">
                        <IconButton>
                            <MoreVertIcon />
                        </IconButton>
                    </Tooltip>
                </Container>
            </Container>
            <MoreOptionsPopover
                theme={theme}
                open={moreOptionsOpen}
                onClose={() => setMoreOptionsOpen(false)}
                anchorEl={moreOptionAnchorElRef.current}
                leaseList={moreTenantOptions}
                style={rootContainerStyle}
                damage={damage}
                addDamageTransaction={addDamageTransaction}
            />
        </Popover>
    )
}

const AreaHeader: React.FC<{
    theme: Theme
    areaName: string
    areaLabel: string
    style?: React.CSSProperties
}> = ({ theme, areaName, areaLabel, style }) => {
    return (
        <>
            <span
                style={{
                    ...theme.typography.h6,
                    fontWeight: theme.typography.fontWeightBold,
                    ...style,
                }}
            >
                {areaName} ({areaLabel}) Tenants
            </span>
            <Divider />
        </>
    )
}

const MoreOptionsPopover: React.FC<{
    theme: Theme
    open: boolean
    anchorEl: Element | null
    leaseList: Lease[]
    style: React.CSSProperties
    damage: Damage
    addDamageTransaction: (lease: Lease, damage: Damage) => void
    onClose: () => void
}> = ({
    theme,
    open,
    onClose,
    anchorEl,
    leaseList,
    style,
    damage,
    addDamageTransaction,
}) => {
    let prevAreaId = -1
    const body = leaseList
        .filter((lease) => {
            return (
                damage?.damage_transactions.find(
                    (dt) => dt.lease.user.id === lease.user.id,
                ) === undefined
            )
        })
        .map((lease) => {
            let header: JSX.Element | null = null

            const currentArea = lease.area

            if (prevAreaId !== currentArea?.id) {
                header = (
                    <AreaHeader
                        key={`LEASE_HEADER_${lease.id}`}
                        theme={theme}
                        areaName={lease.area.area_config_name ?? '?'}
                        areaLabel={lease.area.area_label}
                        style={{
                            padding: theme.spacing(1),
                            backgroundColor: theme.palette.grey[100],
                        }}
                    />
                )
            }

            prevAreaId = lease.area.id

            return (
                <>
                    {header}
                    <AddTenantRow
                        key={`ADD_LEASE_${lease.id}`}
                        theme={theme}
                        lease={lease}
                        damage={damage}
                        addDamageTransaction={addDamageTransaction}
                    />
                </>
            )
        })

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'top',
            }}
            style={{ marginLeft: theme.spacing(0.5) }}
            onClose={onClose}
        >
            <Container style={style}>{body}</Container>
        </Popover>
    )
}

const AddTenantRow: React.FC<{
    theme: Theme
    lease: Lease
    damage: Damage
    addDamageTransaction: (lease: Lease, damage: Damage) => void
}> = ({ theme, lease, damage, addDamageTransaction }) => {
    const leaseTimeline = getLeaseTimeline(lease)

    return (
        <>
            <Container style={{ flex: 1, alignItems: 'center' }}>
                <IconButton onClick={() => addDamageTransaction(lease, damage)}>
                    <AddBoxIcon />
                </IconButton>
                <Container style={{ flexDirection: 'column' }}>
                    <span
                        style={{
                            ...theme.typography.body2,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {lease.user.name}
                    </span>
                    <span style={{ ...theme.typography.body2 }}>
                        {lease.user.email}
                    </span>
                </Container>
                <div style={{ flex: 1 }} />
                <span style={{ marginLeft: theme.spacing(1) }}>
                    {toMMDDYYYY(leaseTimeline.startDate)} -{' '}
                    {toMMDDYYYY(leaseTimeline.endDate)}
                </span>
            </Container>
            <Divider />
        </>
    )
}
