import React from 'react'

import { Container } from '../../../../../components'
import { BaseProps } from '../types'
import { LeaseCluster } from '../../../../../models'

interface Props extends BaseProps {
    leaseCluster: LeaseCluster
    onClick: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        leaseCluster: LeaseCluster,
    ) => void
}

export const EventTypeMoveIn = (props: Props) => {
    const { theme, leaseCluster } = props

    return (
        <Container
            style={{ alignItems: 'center' }}
            onClick={(e) => props.onClick(e, leaseCluster)}
        >
            <span
                style={{
                    ...theme.typography.body2,
                    fontWeight: theme.typography.fontWeightMedium,
                }}
            >
                {leaseCluster.count} Move Ins
            </span>
        </Container>
    )
}
