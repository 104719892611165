import {
    Button,
    Divider,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { Container } from '../../../components'
import { IdBoolMap, InspectionType } from '../../../models'
import CloseIcon from '@material-ui/icons/Close'
import {
    CONGIFURE_INSPECTION_TYPE_STEP,
    CREATE_FREQUENCY_TAB,
    CREATE_INSPECTION_TAB,
} from './InspectionConfigurationModal'
import { SelectedItems } from './ConfigureInspectionType'
import {
    CreateInspectionTypeRequest,
    EditInspectionTypeRequest,
    RootState,
    createInspectionType,
    editInspectionTypeReq,
} from '../../../store'
import { useAppDispatch } from '../../../hooks'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

interface Props {
    inspectionTypeName: string
    invItemsMap: IdBoolMap
    SubHeaderStyle: React.CSSProperties
    handleClose: () => void
    setTab: (tab: number) => void
    inspectionType?: InspectionType
}

const INSPECTION = 1
const FREQUENCY = 2
const NONE = 3

export const PreviewInspectionType = (props: Props) => {
    const {
        handleClose,
        invItemsMap,
        inspectionTypeName,
        setTab,
        SubHeaderStyle,
        inspectionType,
    } = props

    const [nextStep, setNextStep] = useState(NONE)

    const theme = useTheme()

    const dispatch = useAppDispatch()

    const inventoryConfigList = useSelector(
        (state: RootState) => state.aptConfig.inventoryConfigList,
    )

    const saveInspectionType = () => {
        const invItems: number[] = []

        inventoryConfigList?.map((invConfig) => {
            if (invItemsMap[invConfig.id]) {
                invItems.push(invConfig.id)
            }
        })

        if (inspectionType) {
            const req: EditInspectionTypeRequest = {
                id: inspectionType.id,
                body: {
                    name: inspectionTypeName,
                    inv_configs: invItems,
                },
            }

            dispatch(editInspectionTypeReq(req)).then(() => {
                toast.success(`${inspectionTypeName} updated.`)
                if (nextStep === INSPECTION) {
                    setTab(CREATE_INSPECTION_TAB)
                } else if (nextStep === FREQUENCY) {
                    setTab(CREATE_FREQUENCY_TAB)
                } else {
                    handleClose()
                }
            })
        } else {
            const req: CreateInspectionTypeRequest = {
                body: {
                    name: inspectionTypeName,
                    inv_configs: invItems,
                },
            }

            dispatch(createInspectionType(req)).then(() => {
                toast.success(`${inspectionTypeName} created.`)
                if (nextStep === INSPECTION) {
                    setTab(CREATE_INSPECTION_TAB)
                } else if (nextStep === FREQUENCY) {
                    setTab(CREATE_FREQUENCY_TAB)
                } else {
                    handleClose()
                }
            })
        }
    }

    return (
        <Container flex={1} direction="column">
            <Container
                alignItems="center"
                style={{ marginBottom: theme.spacing(2) }}
            >
                <Container
                    style={{
                        fontWeight: 600,
                        fontSize: '20px',
                        flex: 1,
                    }}
                >
                    Step 2: New Inspection Configuration
                </Container>
                <Tooltip title="Close">
                    <IconButton
                        onClick={() => {
                            handleClose()
                        }}
                    >
                        <CloseIcon
                            fontSize="inherit"
                            style={{
                                color: theme.palette.darkGreen.main,
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Container>

            <Divider />
            <Container
                direction="column"
                style={{
                    maxHeight: '680px',
                    height: 'calc(65vh)',
                    overflowY: 'auto',
                }}
            >
                <Container style={{ ...SubHeaderStyle }}>
                    Configured Inspection Type
                </Container>

                <Container
                    style={{
                        fontSize: '13px',
                        color: theme.palette.darkGreen.main,
                        marginBottom: theme.spacing(2),
                    }}
                >
                    {`Your personalized inspection is shown below. Review everything
                    and don't forget you can always make any changes you need.`}
                </Container>

                <Container style={{ ...SubHeaderStyle }}>
                    Inspection Type Name:
                </Container>

                <Container
                    style={{
                        border: `1px solid ${theme.palette.lightGrey.dark}`,
                        borderRadius: '5px',
                        marginBottom: theme.spacing(2),
                        padding: theme.spacing(1),
                    }}
                >
                    {inspectionTypeName}
                </Container>

                <Container style={{ ...SubHeaderStyle }}>
                    Items Selected:
                </Container>

                <SelectedItems invItemsMap={invItemsMap} />

                <Container style={{ ...SubHeaderStyle }} direction="column">
                    <Container>
                        Would you like to create an Inspection or an Inspection
                        Frequency?
                    </Container>
                    <RadioGroup value={nextStep}>
                        <FormControlLabel
                            value="inspection"
                            control={
                                <Radio
                                    style={{
                                        color: theme.palette.primary.dark,
                                    }}
                                />
                            }
                            label="Create an Inspection"
                            checked={nextStep === INSPECTION}
                            onClick={() => setNextStep(INSPECTION)}
                        />
                        <FormControlLabel
                            value="frequency"
                            control={
                                <Radio
                                    style={{
                                        color: theme.palette.primary.dark,
                                    }}
                                />
                            }
                            label="Create an Inspection Frequency"
                            checked={nextStep === FREQUENCY}
                            onClick={() => setNextStep(FREQUENCY)}
                        />
                        <FormControlLabel
                            value="none"
                            control={
                                <Radio
                                    style={{
                                        color: theme.palette.primary.dark,
                                    }}
                                />
                            }
                            label="Update Inspection Items Only"
                            checked={nextStep === NONE}
                            onClick={() => setNextStep(NONE)}
                        />
                    </RadioGroup>
                </Container>

                <Container flex={1} />
            </Container>
            <Container flex={1} />
            <Container justifyContent="flex-end">
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    style={{
                        textTransform: 'none',
                        margin: theme.spacing(1, 0, 1, 2),
                    }}
                    onClick={() => setTab(CONGIFURE_INSPECTION_TYPE_STEP)}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    style={{
                        margin: theme.spacing(1, 0, 1, 2),
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => saveInspectionType()}
                >
                    {inspectionType ? 'Update' : 'Create'}
                </Button>
            </Container>
        </Container>
    )
}
