import React, { Dispatch, SetStateAction, useState } from 'react'
import { IdentifiableObject, VideoAdvertisement } from '../models'
import { axiosInstance } from '../helpers'
import { toast } from 'react-toastify'

export const useVideoAdvertisements = () => {
    const [videoAdvertisementList, setVideoAdvertisementList] = useState<
        VideoAdvertisement[]
    >([])

    const [
        selectedVideoAd,
        setSelectedVideoAd,
    ] = useState<VideoAdvertisement | null>(null)

    const getVideoAdList = () => {
        axiosInstance
            .get(`video-advertisement/`)
            .then((res) => {
                setVideoAdvertisementList(res.data)
            })
            .catch((e) => toast.error(e.response.data.message))
    }

    const createVideoAd = async (request: FormData) => {
        try {
            const res = await axiosInstance.post(
                `video-advertisement/`,
                request,
            )
            toast.success(`${res.data['name']} created successfully!`)
            _insertOrUpdateIdentifiable(res.data, setVideoAdvertisementList)
        } catch (e) {
            toast.error('Error creating video advertisement')
            return Promise.reject(e)
        }
    }

    const updateVideoAd = async (request: FormData, id: number) => {
        try {
            const res = await axiosInstance.patch(
                `video-advertisement/${id}/`,
                request,
            )
            toast.success(`${res.data['name']} updated successfully!`)
            _insertOrUpdateIdentifiable(res.data, setVideoAdvertisementList)
        } catch (e) {
            toast.error('Error updating video advertisement')
            return Promise.reject(e)
        }
    }

    const _insertOrUpdateIdentifiable = <T extends IdentifiableObject>(
        updatedValue: T,
        dispatch: Dispatch<SetStateAction<T[]>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            let found = false
            for (let i = 0; i < safeOldValue.length; i++) {
                const current = safeOldValue[i]
                if (current.id === updatedValue.id) {
                    safeOldValue[i] = updatedValue
                    found = true
                    break
                }
            }

            if (!found) {
                safeOldValue.push(updatedValue)
            }

            return safeOldValue
        })
    }

    return {
        videoAdvertisementList,
        selectedVideoAd,
        setVideoAdvertisementList,
        setSelectedVideoAd,
        getVideoAdList,
        createVideoAd,
        updateVideoAd,
    }
}
