import React, { useEffect } from 'react'
import { Container } from '../../components'
import { useHistory, useLocation } from 'react-router-dom'
import { Routes, axiosInstance } from '../../helpers'
import { toast } from 'react-toastify'
import { CircularProgress } from '@material-ui/core'
import { useUser } from '../../hooks'

const useQuery = () => {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
}

export const VerifyEmail = () => {
    const query = useQuery()
    const serverCode = query.get('code')
    const serverEmail = query.get('email')

    const history = useHistory()

    const { workspaceUser } = useUser()

    useEffect(() => {
        axiosInstance
            .post('user/verify_email/', {
                email: serverEmail,
                code: serverCode,
            })
            .then(() => {
                toast.success(`${serverEmail} successfully Validated!`)
            })
            .catch(() => {
                toast.error(`${serverEmail} could not be validated`)
            })
            .finally(() => {
                if (workspaceUser) {
                    history.push(Routes.ezpay)
                } else {
                    history.push(Routes.login)
                }
            })
    }, [serverCode, serverEmail])

    return <CircularProgress size={400} />
}
