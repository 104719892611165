import React from 'react'
import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

export const SearchField = (props: TextFieldProps) => {
    return (
        <TextField
            {...props}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                ...props.InputProps,
            }}
        />
    )
}
