import React from 'react'
import {
    FormGroup,
    useTheme,
    Divider,
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Switch,
} from '@material-ui/core'
import FlagIcon from '@material-ui/icons/Flag'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import { Container, MultiSelector, SideDrawer } from '../../components'
import { CustomStatus, InventoryConfig, UnitConfig } from '../../models'
import {
    InspectionCompletionFilter,
    InspectionHandledFilter,
    LocationSelection,
} from '../../store'
import { useInspectionFilter } from '../../hooks/useFilter'

interface Props {
    open: boolean
    unitConfigList: UnitConfig[]
    inventoryConfigList: InventoryConfig[]
    customStatusList: CustomStatus[]
    locationSelection: LocationSelection
    makeFilteredRequest: () => void
    handleClose: () => void
}

export const FilterForm = (props: Props) => {
    const {
        open,
        handleClose,
        unitConfigList,
        inventoryConfigList,
        customStatusList,
        makeFilteredRequest,
    } = props

    const {
        filter,
        resetFilters,
        setCustomStatusFilter,
        toggleUnreadFilter,
        toggleHasMessages,
        toggleHasFlagged,
        toggleHasCost,
        handleChangeMultiSelect,
        setInspectionStatusFilter,
        setHandled,
        toggleDamageFilter,
    } = useInspectionFilter()

    const theme = useTheme()

    const renderStatusCheckboxes = (csList: CustomStatus[]) => {
        return csList.map((cs) => {
            return (
                <FormControlLabel
                    key={`CS_CHECK_${cs.id}`}
                    control={
                        <Checkbox
                            color="primary"
                            checked={filter.customStatusFilter[cs.id] === true}
                            onChange={() => setCustomStatusFilter(cs)}
                        />
                    }
                    label={
                        <Container style={{ alignItems: 'center' }}>
                            <span>{cs.name}</span>
                            {cs.causes_flag && <FlagIcon />}
                        </Container>
                    }
                />
            )
        })
    }

    return (
        <SideDrawer open={open} handleClose={handleClose} title="Filter">
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                <Card
                    style={{
                        padding: theme.spacing(2),
                    }}
                >
                    <FormGroup>
                        <MultiSelector
                            label="Unit Types"
                            keyIdentifier="UNIT_CONFIG"
                            options={unitConfigList}
                            selectedOptions={Object.keys(
                                filter.unitConfigFilter,
                            ).map(Number)}
                            onChange={(newOpts) => {
                                handleChangeMultiSelect(
                                    newOpts,
                                    'unitConfigFilter',
                                )
                            }}
                        />

                        <MultiSelector
                            label="Inventory Types"
                            keyIdentifier="INV_CONFIG"
                            options={inventoryConfigList}
                            selectedOptions={Object.keys(
                                filter.invConfigFilter,
                            ).map(Number)}
                            onChange={(newOpts) => {
                                handleChangeMultiSelect(
                                    newOpts,
                                    'invConfigFilter',
                                )
                            }}
                        />
                    </FormGroup>
                </Card>
                <Divider />
                <Card style={{ padding: theme.spacing(2) }}>
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        Status
                    </span>
                    <Container>
                        <FormGroup>
                            {renderStatusCheckboxes(
                                customStatusList.slice(
                                    0,
                                    customStatusList.length / 2,
                                ),
                            )}
                        </FormGroup>
                        <div style={{ flex: 1 }} />
                        <FormGroup>
                            {renderStatusCheckboxes(
                                customStatusList.slice(
                                    customStatusList.length / 2,
                                ),
                            )}
                        </FormGroup>
                    </Container>
                </Card>

                <Divider />
                {/* Priority / assignments only / has unreads*/}
                <Card style={{ padding: theme.spacing(2), flexShrink: 0 }}>
                    <FormGroup>
                        <FormControlLabel
                            labelPlacement="end"
                            label="Has Damages"
                            style={{ marginRight: theme.spacing(2) }}
                            control={
                                <Switch
                                    checked={filter.hasDamages}
                                    color="primary"
                                    onClick={toggleDamageFilter}
                                />
                            }
                        />

                        <FormControlLabel
                            labelPlacement="end"
                            label="Has Unread Messages"
                            style={{ marginRight: theme.spacing(2) }}
                            control={
                                <Switch
                                    checked={filter.unreadFilter}
                                    color="primary"
                                    onClick={toggleUnreadFilter}
                                />
                            }
                        />

                        <FormControlLabel
                            labelPlacement="end"
                            label="Has Messages"
                            style={{ marginRight: theme.spacing(2) }}
                            control={
                                <Switch
                                    checked={filter.hasMessages}
                                    color="primary"
                                    onClick={toggleHasMessages}
                                />
                            }
                        />

                        <FormControlLabel
                            labelPlacement="end"
                            label="Has Flagged Item"
                            style={{ marginRight: theme.spacing(2) }}
                            control={
                                <Switch
                                    checked={filter.hasFlaggedItem}
                                    color="primary"
                                    onClick={toggleHasFlagged}
                                />
                            }
                        />

                        <FormControlLabel
                            labelPlacement="end"
                            label="Has Cost Driver"
                            style={{ marginRight: theme.spacing(2) }}
                            control={
                                <Switch
                                    checked={filter.hasCostDriver}
                                    color="primary"
                                    onClick={toggleHasCost}
                                />
                            }
                        />

                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                                margin: theme.spacing(1, 0),
                            }}
                        >
                            Completion Filter
                        </span>

                        <FormGroup>
                            <ToggleButtonGroup
                                value={filter.inspectionCompletionFilter}
                                onChange={(_, nf) =>
                                    setInspectionStatusFilter(nf)
                                }
                                aria-label="Completion Filter"
                                exclusive
                            >
                                <ToggleButton
                                    value={InspectionCompletionFilter.Complete}
                                    aria-label="Complete"
                                >
                                    Complete
                                </ToggleButton>
                                <ToggleButton
                                    value={InspectionCompletionFilter.Assigned}
                                    aria-label="Assigned"
                                >
                                    Assigned
                                </ToggleButton>
                                <ToggleButton
                                    value={InspectionCompletionFilter.All}
                                    aria-label="All"
                                >
                                    All
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </FormGroup>
                    </FormGroup>
                </Card>

                <Card style={{ padding: theme.spacing(2), flexShrink: 0 }}>
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: theme.typography.fontWeightBold,
                            margin: theme.spacing(1, 0),
                        }}
                    >
                        Handled Filter
                    </span>

                    <FormGroup>
                        <ToggleButtonGroup
                            value={filter.handled}
                            onChange={(_, nf) => setHandled(nf)}
                            aria-label="Handled Filter"
                            exclusive
                        >
                            <ToggleButton
                                value={InspectionHandledFilter.All}
                                aria-label="All"
                            >
                                All
                            </ToggleButton>
                            <ToggleButton
                                value={InspectionHandledFilter.Handled}
                                aria-label="Handled"
                            >
                                Handled
                            </ToggleButton>
                            <ToggleButton
                                value={InspectionHandledFilter.NotHandled}
                                aria-label="Not Handled"
                            >
                                Not Handled
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </FormGroup>
                </Card>

                <div style={{ flex: 1 }} />
                {/* Footer */}
                <Divider />
                <Container
                    style={{
                        padding: theme.spacing(2),
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        color="primary"
                        variant="outlined"
                        style={{ marginRight: theme.spacing(2) }}
                        onClick={resetFilters}
                    >
                        Reset
                    </Button>

                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{ marginRight: theme.spacing(2) }}
                        onClick={handleClose}
                    >
                        Close
                    </Button>

                    <Button
                        color="primary"
                        variant="outlined"
                        style={{ marginRight: theme.spacing(2) }}
                        onClick={makeFilteredRequest}
                    >
                        Apply
                    </Button>
                </Container>
            </Container>
        </SideDrawer>
    )
}
