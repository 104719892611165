import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../hooks'
import { DamageConfig, IdentifiableObject } from '../../models'
import { getDamageConfigList, RootState } from '../../store'

import { Tab, Tabs, useTheme } from '@material-ui/core'

import { Container, SideDrawerContainer } from '../../components'
import { DamageConfigForm } from './DamageConfigForm'

import { DamageConfigContainer } from './DamageConfig'
import { FORM_CLOSED } from '../InfrastructureConfig'
import { DamageList } from './DamageList'

export const DAMAGE_FORM_CLOSED = 0
export const DAMAGE_LIST = 1
export const DAMAGE_CONFIG = 2
export const MOVE_INSPECTIONS = 3

type DamageFormSelection =
    | typeof DAMAGE_LIST
    | typeof DAMAGE_CONFIG
    | typeof MOVE_INSPECTIONS

type DamageFormOpenState = typeof DAMAGE_FORM_CLOSED | DamageFormSelection

export interface DamageDrawerState {
    open: DamageFormOpenState
    selected?: IdentifiableObject
}

export const closedDamageDrawer: DamageDrawerState = {
    open: DAMAGE_FORM_CLOSED,
}

export const DamageHome = () => {
    const [drawerState, setDrawerState] = useState<DamageDrawerState>(
        closedDamageDrawer,
    )
    const [tab, setTab] = useState<DamageFormSelection>(DAMAGE_LIST)

    const dispatch = useAppDispatch()
    const theme = useTheme()

    const damageConfigList: DamageConfig[] | undefined = useSelector(
        (state: RootState) => state.aptConfig.damageConfigList,
    )

    useEffect(() => {
        dispatch(getDamageConfigList({}))
        setDrawerState(closedDamageDrawer)
    }, [tab])

    return (
        <SideDrawerContainer open={drawerState.open !== FORM_CLOSED}>
            <Container
                direction="column"
                flex={1}
                style={{
                    height: 'calc(100vh - 104px)',
                    width: 'calc(100vw - 184px)',
                }}
            >
                <Tabs
                    style={{
                        backgroundColor: theme.palette.grey[200],
                        margin: theme.spacing(1),
                    }}
                    value={tab}
                    onChange={(_, v) => {
                        setTab(v)
                    }}
                >
                    <Tab label="Damage List" value={DAMAGE_LIST} />
                    <Tab label="Damage types" value={DAMAGE_CONFIG} />
                </Tabs>

                {tab === DAMAGE_LIST && (
                    <DamageList damageConfigList={damageConfigList} />
                )}

                {tab === DAMAGE_CONFIG && (
                    <DamageConfigContainer
                        damageConfigList={damageConfigList}
                        drawerState={drawerState}
                        onSelectDamage={(damageConfig) => {
                            setDrawerState({
                                open: DAMAGE_CONFIG,
                                selected: damageConfig,
                            })
                        }}
                    />
                )}
            </Container>

            <DamageConfigForm
                open={drawerState.open !== DAMAGE_FORM_CLOSED}
                handleClose={() => setDrawerState(closedDamageDrawer)}
                damageConfig={drawerState.selected as DamageConfig | undefined}
            />
        </SideDrawerContainer>
    )
}
