import { makeStyles, createStyles } from '@material-ui/core/styles'
import { WORKORDER_HEIGHT, WORKORDER_WIDTH } from '../constants'

export const useAreaStyles = makeStyles((theme) =>
    createStyles({
        root: {
            ...theme.typography.caption,
            display: 'flex',
            textAlign: 'center',
            textOverflow: 'clip',
            justifyContent: 'center',
            alignItems: 'center',
        },
        icon: {
            display: 'none',
        },
    }),
)

export const useWorkorderStyles = (color: string, shadowColor: string) => {
    return makeStyles((theme) =>
        createStyles({
            root: {
                border: `2px solid ${color}`,
                boxShadow: `2px 2px 6px ${shadowColor}`,
                padding: theme.spacing(0.5),
                margin: theme.spacing(1),
                flexDirection: 'column',
                height: WORKORDER_HEIGHT,
                minWidth: WORKORDER_WIDTH,
                maxWidth: WORKORDER_WIDTH,
                cursor: 'pointer',
                '&:hover': {
                    boxShadow: `6px 5px 6px ${shadowColor}`,
                },
            },
            lightCaption: {
                ...theme.typography.caption,
                fontWeight: theme.typography.fontWeightLight,
            },
            boldCaption: {
                ...theme.typography.caption,
                fontWeight: theme.typography.fontWeightBold,
            },
        }),
    )()
}
