import { useRef } from 'react'
import { DAY_CELL_W } from '../constants'
import { MS_PER_DAY, getDifferenceInDays } from '../../../helpers'

export const useCalendarGrid = (startDate: Date, endDate: Date) => {
    const gridRef = useRef<HTMLDivElement>(null)

    const getDateGridPosition = (end: Date) => {
        const diffDays = getDifferenceInDays(end, gridStart, true)

        return diffDays
    }

    const getClickedGridPosition = (clickX: number) => {
        if (gridRef.current === null) return 0

        const gridEl = gridRef.current.getBoundingClientRect()

        const clickXCoord = clickX - gridEl.x + gridRef.current.scrollLeft

        const gridPos = clickXCoord / DAY_CELL_W
        return Math.floor(gridPos)
    }

    const covnertGridPositionToDate = (gridPos: number) => {
        const startMs = gridStart.getTime()
        const offsetMs = gridPos * MS_PER_DAY
        return new Date(startMs + offsetMs)
    }

    const getGridColumnCount = () => {
        let gridWidth = 0
        if (gridRef.current) {
            gridWidth = gridRef.current.getBoundingClientRect().width
        }

        return Math.ceil(gridWidth / DAY_CELL_W)
    }

    const getProjectDurationDays = () => {
        return getDifferenceInDays(endDate, startDate, true)
    }

    const gridColumnCount = getGridColumnCount()

    const startPointMs =
        startDate.getTime() - MS_PER_DAY * NUM_DAY_START_PADDING
    const gridStart = new Date(startPointMs)

    const numProjectDays = getProjectDurationDays()

    const prePaddingDisplayDays = Math.max(numProjectDays, gridColumnCount)
    const numDisplayDays = prePaddingDisplayDays + NUM_DAY_PADDING

    return {
        gridRef,
        getDateGridPosition,
        getClickedGridPosition,
        covnertGridPositionToDate,
        gridPositionInfo: {
            numDisplayDays: numDisplayDays,
            numProjectDays: numProjectDays,
            numStartPaddingDays: NUM_DAY_START_PADDING,
        },
        dateInfo: {
            projectStart: startDate,
            projectEnd: endDate,
            displayStart: gridStart,
        },
    }
}

export type CalendarGridController = ReturnType<typeof useCalendarGrid>

const NUM_DAY_PADDING = 8
const NUM_DAY_START_PADDING = 4
