import { AxiosError } from 'axios'
import { axiosInstance } from '../../helpers'
import { PermissionRole } from '../../models'
import { setNetworkError } from '../error'
import { AppThunk, ErrorResponse } from '../types'
import {
    AddPermissionRoleRequest,
    ADD_PERMISSION_ROLE,
    ADD_PERMISSION_ROLE_REQUEST,
    EDIT_PERMISSION_ROLE_REQUEST,
    GetPermissionRoleListRequest,
    GET_PERMISSION_ROLE_LIST_REQUEST,
    PermissionActionTypes,
    PermissionRequest,
    PermissionRoleListThunk,
    PermissionRoleThunk,
    SET_PERMISSION_LOADING,
    SET_PERMISSION_ROLE_LIST,
    UpdatePermissionRoleRequest,
    UPDATE_PERMISSION_ROLE,
} from './types'

export const getPermissionRoleList = (
    req: GetPermissionRoleListRequest,
): AppThunk<PermissionRoleListThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setPermissionLoading(GET_PERMISSION_ROLE_LIST_REQUEST, true))

        const url = `permission/`

        try {
            // success
            const response = await axiosInstance.get(url, req)

            dispatch(setPermissionRoles(response.data))
            dispatch(
                setPermissionLoading(GET_PERMISSION_ROLE_LIST_REQUEST, false),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(
                setPermissionLoading(GET_PERMISSION_ROLE_LIST_REQUEST, false),
            )

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}
export const createPermissionRole = (
    req: AddPermissionRoleRequest,
): AppThunk<PermissionRoleThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setPermissionLoading(ADD_PERMISSION_ROLE_REQUEST, true))

        const url = `permission/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch(addPermissionRole(response.data))
            dispatch(setPermissionLoading(ADD_PERMISSION_ROLE_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setPermissionLoading(ADD_PERMISSION_ROLE_REQUEST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}
export const updatePermissionRoleRequest = (
    req: UpdatePermissionRoleRequest,
): AppThunk<PermissionRoleThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setPermissionLoading(EDIT_PERMISSION_ROLE_REQUEST, true))

        const url = `permission/${req.id}/`

        try {
            // success
            const response = await axiosInstance.patch(url, req.body)

            dispatch(updatePermissionRole(response.data))
            dispatch(setPermissionLoading(EDIT_PERMISSION_ROLE_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(setPermissionLoading(EDIT_PERMISSION_ROLE_REQUEST, false))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const setPermissionRoles = (
    permissionRoles?: PermissionRole[],
): PermissionActionTypes => {
    return {
        type: SET_PERMISSION_ROLE_LIST,
        permissionRoles: permissionRoles,
    }
}

export const setPermissionLoading = (
    request: PermissionRequest,
    loading: boolean,
): PermissionActionTypes => {
    return {
        type: SET_PERMISSION_LOADING,
        request: request,
        loading: loading,
    }
}

export const addPermissionRole = (
    permissionRole: PermissionRole,
): PermissionActionTypes => {
    return {
        type: ADD_PERMISSION_ROLE,
        permissionRole: permissionRole,
    }
}

export const updatePermissionRole = (
    permissionRole: PermissionRole,
): PermissionActionTypes => {
    return {
        type: UPDATE_PERMISSION_ROLE,
        permissionRole: permissionRole,
    }
}
