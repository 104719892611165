import {
    Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useState } from 'react'
import { axiosInstance, statesList } from '../../helpers'
import { toast } from 'react-toastify'
import { Blanket, BlanketRect, Container, Selector } from '../../components'
import { Service } from '../../models'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import CloseIcon from '@material-ui/icons/Close'

interface DataCalculations {
    [key: string]: number
}

interface AreaCalculations {
    BATH: DataCalculations
    BED: DataCalculations
    COM: DataCalculations
}

interface ServicePricing {
    state: AreaCalculations
    national: AreaCalculations
    region: AreaCalculations
}

interface ChartData {
    name: string
    data: number[]
}

interface Props {
    open: boolean
    rect: BlanketRect
    serviceList: Service[]
    onClose: () => void
}

const AVERAGE = 0
const HIGHEST = 1
const LOWEST = 2
const MEDIAN = 3

export const MarketAnalysisTool = (props: Props) => {
    const { open, rect, onClose, serviceList } = props

    const [servicePricing, setServicePricing] = useState<
        ServicePricing | undefined
    >()
    const [selectedServiceId, setSelectedServiceId] = useState(-1)
    const [city, setCity] = useState('')
    const [selectedState, setSelectedState] = useState('')
    const [loading, setLoading] = useState(false)
    const [doValidate, setDoValidate] = useState(false)
    const [statistic, setStatistic] = useState(AVERAGE)
    const [chartData, setChartData] = useState<ChartData[]>([])

    const theme = useTheme()

    const onPressSearch = () => {
        setLoading(true)
        axiosInstance
            .get(`company/service_pricing/`, {
                params: {
                    service_id: selectedServiceId,
                    city: city,
                    state: selectedState,
                },
            })
            .then((res) => {
                setServicePricing(res.data)
                CreateTableData(res.data, statistic)
            })
            .catch((e) => toast.error(e.response.data.message))
            .finally(() => setLoading(false))
    }

    const selectedServiceName = serviceList.find(
        (service) => service.id === selectedServiceId,
    )?.name

    const noService: Service = {
        id: -1,
        name: ' ',
        color: '',
        order: 0,
    }

    const uiServiceList: Service[] = [noService, ...serviceList]

    const CreateTableData = (
        servicePricing: ServicePricing,
        statistic: number,
    ) => {
        const series: {
            name: string
            data: number[]
        }[] = []
        let idx = 0
        let key = 'mean'
        if (statistic === HIGHEST) {
            key = 'max'
        } else if (statistic === LOWEST) {
            key = 'min'
        } else if (statistic === MEDIAN) {
            key = 'median'
        }

        if (servicePricing) {
            series.push({
                name: 'Bed',
                data: [],
            })
            series[idx].data.push(servicePricing.state.BED[key])
            series[idx].data.push(servicePricing.region.BED[key])
            series[idx].data.push(servicePricing.national.BED[key])
            idx += 1
            series.push({
                name: 'Com',
                data: [],
            })
            series[idx].data.push(servicePricing.state.COM[key])
            series[idx].data.push(servicePricing.region.COM[key])
            series[idx].data.push(servicePricing.national.COM[key])
            idx += 1
            series.push({
                name: 'Bath',
                data: [],
            })
            series[idx].data.push(servicePricing.state.BATH[key])
            series[idx].data.push(servicePricing.region.BATH[key])
            series[idx].data.push(servicePricing.national.BATH[key])
        }
        setChartData(series)
    }

    const options: ApexOptions = {
        chart: {
            id: 'basic-bar',
        },
        xaxis: {
            categories: ['State', 'Regional', 'National'],
        },
        yaxis: {
            labels: {
                formatter: (value: number) => '$' + value,
            },
        },
        dataLabels: {
            formatter: (value: number) => '$' + value,
        },
        title: {
            text: `${selectedServiceName} - ${city}, ${selectedState}`,
            align: 'center',
            style: {
                fontSize: '20px',
            },
        },
        colors: [
            theme.palette.purple.main,
            theme.palette.darkGreen.light,
            theme.palette.darkBlue.dark,
        ],
    }

    const height = '90%'
    const width = '80%'

    return (
        <Blanket open={open} rect={rect}>
            <Container
                style={{
                    flex: 1,
                    backgroundColor: theme.palette.grey[100],
                    boxShadow: `2px -3px 8px ${theme.palette.grey[300]}`,
                    overflow: 'auto',
                }}
                scrollY
                direction="column"
            >
                <Container>
                    <Container
                        style={{
                            marginTop: theme.spacing(3),
                            fontWeight: 600,
                            fontSize: '23px',
                        }}
                    >
                        Market Rate Analysis Tool
                    </Container>
                    <Container flex={1} />
                    <Tooltip title="Close">
                        <IconButton
                            onClick={() => {
                                onClose()
                            }}
                        >
                            <CloseIcon
                                fontSize="inherit"
                                style={{
                                    marginRight: theme.spacing(1),
                                    color: theme.palette.darkGreen.main,
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </Container>
                <Container style={{ margin: theme.spacing(2, 0) }}>
                    <Selector
                        label={'Service'}
                        currentValue={selectedServiceId}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            setSelectedServiceId(event.target.value as number)
                        }}
                        data={uiServiceList}
                        getDisplayString={(s: Service) => s.name}
                        size="small"
                        customStyle={{
                            formControl: {
                                margin: theme.spacing(0, 2, 2, 0),
                                width: '200px',
                            },
                        }}
                        error={doValidate && selectedServiceId === -1}
                    />
                    <TextField
                        error={doValidate && city === ''}
                        helperText={doValidate && city === '' ? 'Required' : ''}
                        variant="outlined"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        label="City"
                        style={{
                            width: '200px',
                            marginRight: theme.spacing(2),
                        }}
                        size="small"
                    />
                    <TextField
                        error={doValidate && selectedState === ''}
                        helperText={
                            doValidate && selectedState === '' ? 'Required' : ''
                        }
                        variant="outlined"
                        value={selectedState}
                        onChange={(e) =>
                            setSelectedState(e.target.value as string)
                        }
                        label="State"
                        size="small"
                        select
                        style={{ width: '100px' }}
                    >
                        {statesList.map((s) => {
                            return (
                                <MenuItem key={s} value={s}>
                                    {s}
                                </MenuItem>
                            )
                        })}
                    </TextField>
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(0, 0, 2, 2),
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            if (city === '') {
                                setDoValidate(true)
                                return
                            }
                            if (selectedState === '') {
                                setDoValidate(true)
                                return
                            }
                            if (selectedServiceId === -1) {
                                setDoValidate(true)
                                return
                            }
                            onPressSearch()
                        }}
                    >
                        Search
                    </Button>
                </Container>
                <Divider />
                <Container
                    style={{
                        width: '100%',
                        marginTop: theme.spacing(3),
                    }}
                    flex={1}
                >
                    {servicePricing && (
                        <Container
                            style={{ alignItems: 'space-around', flex: 1 }}
                        >
                            <Container
                                flex={1}
                                style={{ justifySelf: 'flex-start' }}
                            >
                                <iframe
                                    width={width}
                                    height={height}
                                    loading="lazy"
                                    src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                        &q=${city}_${selectedState}`}
                                ></iframe>
                            </Container>
                            <Container
                                direction="column"
                                flex={1}
                                style={{ marginLeft: theme.spacing(4) }}
                            >
                                <ReactApexChart
                                    options={options}
                                    series={chartData}
                                    type="bar"
                                    height={height}
                                    id={`SERVICE_PRICING_CHART_${selectedServiceId}`}
                                    width={width}
                                />
                                <Container justifyContent="center">
                                    <RadioGroup value={statistic} row>
                                        <FormControlLabel
                                            value="average"
                                            control={
                                                <Radio
                                                    style={{
                                                        color:
                                                            theme.palette
                                                                .primary.dark,
                                                    }}
                                                />
                                            }
                                            label="Average"
                                            checked={statistic === AVERAGE}
                                            onClick={() => {
                                                setStatistic(AVERAGE)
                                                if (servicePricing) {
                                                    CreateTableData(
                                                        servicePricing,
                                                        AVERAGE,
                                                    )
                                                }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="highest"
                                            control={
                                                <Radio
                                                    style={{
                                                        color:
                                                            theme.palette
                                                                .primary.dark,
                                                    }}
                                                />
                                            }
                                            label="Highest"
                                            checked={statistic === HIGHEST}
                                            onClick={() => {
                                                setStatistic(HIGHEST)
                                                if (servicePricing) {
                                                    CreateTableData(
                                                        servicePricing,
                                                        HIGHEST,
                                                    )
                                                }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="lowest"
                                            control={
                                                <Radio
                                                    style={{
                                                        color:
                                                            theme.palette
                                                                .primary.dark,
                                                    }}
                                                />
                                            }
                                            label="Lowest"
                                            checked={statistic === LOWEST}
                                            onClick={() => {
                                                setStatistic(LOWEST)
                                                if (servicePricing) {
                                                    CreateTableData(
                                                        servicePricing,
                                                        LOWEST,
                                                    )
                                                }
                                            }}
                                        />
                                        <FormControlLabel
                                            value="median"
                                            control={
                                                <Radio
                                                    style={{
                                                        color:
                                                            theme.palette
                                                                .primary.dark,
                                                    }}
                                                />
                                            }
                                            label="Median"
                                            checked={statistic === MEDIAN}
                                            onClick={() => {
                                                setStatistic(MEDIAN)
                                                if (servicePricing) {
                                                    CreateTableData(
                                                        servicePricing,
                                                        MEDIAN,
                                                    )
                                                }
                                            }}
                                        />
                                    </RadioGroup>
                                </Container>
                                <Container
                                    style={{
                                        ...theme.typography.caption,
                                        color: theme.palette.primary.dark,
                                        alignSelf: 'center',
                                    }}
                                >
                                    Regional - 200 mile radius from entered city
                                </Container>
                            </Container>
                        </Container>
                    )}
                    {!servicePricing && loading && (
                        <Container
                            justifyContent="center"
                            alignItems="center"
                            flex={1}
                        >
                            <CircularProgress size={100} />
                        </Container>
                    )}
                </Container>
            </Container>
        </Blanket>
    )
}
