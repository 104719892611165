import {
    ADD_PERMISSION_ROLE,
    PermissionActionTypes,
    PermissionReduxState,
    SET_PERMISSION_LOADING,
    SET_PERMISSION_ROLE_LIST,
    UPDATE_PERMISSION_ROLE,
} from './types'

const initialState: PermissionReduxState = {
    isLoading: {
        GET_PERMISSION_ROLE_LIST_REQUEST: false,
        ADD_PERMISSION_ROLE_REQUEST: false,
        EDIT_PERMISSION_ROLE_REQUEST: false,
    },
}

export const permissionReducer = (
    state = initialState,
    action: PermissionActionTypes,
): PermissionReduxState => {
    switch (action.type) {
        case SET_PERMISSION_ROLE_LIST:
            return {
                ...state,
                permissionRoleList: action.permissionRoles,
            }
        case ADD_PERMISSION_ROLE:
            return {
                ...state,
                permissionRoleList: [
                    ...(state.permissionRoleList ?? []),
                    action.permissionRole,
                ],
            }
        case UPDATE_PERMISSION_ROLE:
            return {
                ...state,
                permissionRoleList: state.permissionRoleList?.map((role) => {
                    if (role.id === action.permissionRole.id) {
                        return action.permissionRole
                    }
                    return role
                }),
            }
        case SET_PERMISSION_LOADING:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.request]: action.loading,
                },
            }
        default:
            return state
    }
}
