import { useTheme } from '@material-ui/core'
import React from 'react'
import { ReactComponent as Logo } from '../../assets/EZ_turn.svg'

import { Container } from '../../components'

export const ComingSoon = () => {
    const theme = useTheme()

    return (
        <Container
            flex={1}
            alignItems="center"
            justifyContent="center"
            direction="column"
        >
            <Logo
                fill={theme.palette.primary.main}
                style={{ height: 400, width: 400 }}
            />
            <span
                style={{
                    ...theme.typography.h1,
                    fontWeight: theme.typography.fontWeightBold,
                    width: 400,
                    textAlign: 'center',
                }}
            >
                Coming Soon!
            </span>
        </Container>
    )
}
