import React from 'react'
import { Grid } from '@material-ui/core'
import { BaseProps } from '../../types'
import { HEADER_H } from '../../constants'
import { CalendarGridController } from '../../hooks'

interface Props extends BaseProps {
    gridController: CalendarGridController
}

export const ServiceDetailHeader = (props: Props) => {
    const { theme, scheduleController } = props

    const projectDuration =
        props.gridController.gridPositionInfo.numProjectDays + 1
    const daysOff = props.scheduleController.schedule?.day_off_list.length ?? 0

    const workingDays = projectDuration - daysOff

    const schedule = scheduleController.schedule

    return (
        <Grid
            container
            item
            xs={12}
            style={{
                borderBottom: `1px solid ${theme.palette.grey[400]}`,
                borderRight: `1px solid ${theme.palette.grey[200]}`,
                minHeight: HEADER_H,
                maxHeight: HEADER_H,
                boxSizing: 'border-box',
                padding: theme.spacing(1),
            }}
        >
            <Grid item xs={6} style={{}}>
                <span
                    style={{
                        ...theme.typography.h6,
                    }}
                >
                    {schedule?.total_service_areas ?? 0} Service Areas
                </span>
            </Grid>

            <Grid item xs={6}>
                <span
                    style={{
                        ...theme.typography.h6,
                        padding: theme.spacing(1),
                    }}
                >
                    {workingDays} Working days
                </span>
            </Grid>
        </Grid>
    )
}
