import React from 'react'
import { SideDrawer } from '../../components'
import {
    _useFinderSelection,
    _usePrunedInfrastructure,
    useRootInfrastructure,
} from '../../hooks'
import { FinderLocationSelection, FinderSelectionMode } from '../../store'
import { AssignInspectionForm } from './AssignInspectionForm'

interface Props {
    open: boolean
    handleClose: () => void
    setCreateModalOpen: () => void
}

export const InspectionFrequencyDrawer = (props: Props) => {
    const { open, handleClose, setCreateModalOpen } = props

    const { tree } = useRootInfrastructure(true)

    const locationFinderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Multi,
    })

    const prunedTree = _usePrunedInfrastructure(
        tree,
        locationFinderSelection.selection,
    )
    return (
        //Assign Drawer
        <SideDrawer open={open} handleClose={handleClose} title="Assign">
            <AssignInspectionForm
                prunedTree={prunedTree.root}
                onClose={handleClose}
                setAssignModalOpen={setCreateModalOpen}
            />
        </SideDrawer>
    )
}
