import { AxiosError } from 'axios'
import { axiosInstance } from '../../helpers'
import { Invoice } from '../../models'
import { setNetworkError } from '../error'
import { AppThunk, ErrorResponse } from '../types'
import {
    InvoiceListThunk,
    InvoiceRequest,
    InvoiceActionTypes,
    SET_INVOICE_LOADING,
    GET_INVOICE_LIST_REQUEST,
    SET_INVOICE_LIST,
    InvoiceAxiosRequest,
    UPDATE_INVOICE,
} from './types'

export const getInvoiceList = (
    req: InvoiceAxiosRequest,
): AppThunk<InvoiceListThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setInvoiceLoading(GET_INVOICE_LIST_REQUEST, true))

        const url = `invoice/`

        try {
            // success
            const response = await axiosInstance.get(url, req)

            dispatch(setInvoiceList(response.data))
            dispatch(setInvoiceLoading(GET_INVOICE_LIST_REQUEST, false))

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(setInvoiceLoading(GET_INVOICE_LIST_REQUEST, false))
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const setInvoiceList = (invoiceList?: Invoice[]): InvoiceActionTypes => {
    return {
        type: SET_INVOICE_LIST,
        invoiceList: invoiceList,
    }
}

export const updateInvoice = (invoice: Invoice): InvoiceActionTypes => {
    return {
        type: UPDATE_INVOICE,
        invoice: invoice,
    }
}

export const setInvoiceLoading = (
    request: InvoiceRequest,
    isLoading: boolean,
): InvoiceActionTypes => {
    return {
        type: SET_INVOICE_LOADING,
        request: request,
        isLoading: isLoading,
    }
}
