import {
    Card,
    FormGroup,
    IconButton,
    Switch,
    useTheme,
} from '@material-ui/core'
import React from 'react'
import {
    Container,
    Selector,
    SideDrawer,
    TimelinePicker,
} from '../../components'
import { useMultiSelectStyles } from '../Scheduler_v2/styles'
import { Service } from '../../models'
import { rfpApi } from '../../contexts'
import { Actionable } from '../../components/Actionable'
import ClearIcon from '@material-ui/icons/Clear'

interface Props {
    open: boolean
    serviceList: Service[]
    onClose: () => void
}

export const RFPFilterDrawer = (props: Props) => {
    const { open, onClose, serviceList } = props

    const { rfpFilterState, setRFPFilterState } = rfpApi()

    const theme = useTheme()
    const classes = useMultiSelectStyles()

    const selectorStyle: React.CSSProperties = {
        margin: theme.spacing(1, 0),
        flex: 1,
    }

    return (
        <SideDrawer
            open={open}
            handleClose={onClose}
            width={380}
            title="Filters"
        >
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    overflowX: 'hidden',
                    padding: theme.spacing(2),
                }}
                scrollY
            >
                <Card
                    style={{
                        padding: theme.spacing(2),
                        borderRadius: 0,
                        flexShrink: 0,
                    }}
                >
                    <Actionable
                        theme={theme}
                        interactionComponent={
                            <Switch
                                value={rfpFilterState.timeline.enabled}
                                onChange={(_, checked) => {
                                    setRFPFilterState({
                                        ...rfpFilterState,
                                        timeline: {
                                            ...rfpFilterState.timeline,
                                            enabled: checked,
                                        },
                                    })
                                }}
                            />
                        }
                    >
                        <TimelinePicker
                            disabled={!rfpFilterState.timeline.enabled}
                            startDate={rfpFilterState.timeline.startDate}
                            endDate={rfpFilterState.timeline.endDate}
                            onChangeStartDate={(d) => {
                                setRFPFilterState({
                                    ...rfpFilterState,
                                    timeline: {
                                        ...rfpFilterState.timeline,
                                        startDate: d,
                                    },
                                })
                            }}
                            onChangeEndDate={(d) => {
                                setRFPFilterState({
                                    ...rfpFilterState,
                                    timeline: {
                                        ...rfpFilterState.timeline,
                                        endDate: d,
                                    },
                                })
                            }}
                        />
                    </Actionable>
                </Card>
                <Card
                    style={{
                        padding: theme.spacing(2),
                        borderRadius: 0,
                        flexShrink: 0,
                    }}
                >
                    <FormGroup>
                        <Actionable
                            theme={theme}
                            interactionComponent={
                                <IconButton
                                    onClick={() =>
                                        setRFPFilterState({
                                            ...rfpFilterState,
                                            serviceIds: [],
                                        })
                                    }
                                >
                                    <ClearIcon />
                                </IconButton>
                            }
                        >
                            <Selector
                                data={serviceList}
                                customStyle={{ formControl: selectorStyle }}
                                label="Services"
                                maxItems={10}
                                onChange={(e) => {
                                    const selection: number[] = e.target
                                        .value as any

                                    setRFPFilterState({
                                        ...rfpFilterState,
                                        serviceIds: selection,
                                    })
                                }}
                                currentValue={rfpFilterState.serviceIds}
                                getDisplayString={(v) => v.name}
                                searchable
                                multiple
                            />
                        </Actionable>
                    </FormGroup>
                </Card>
            </Container>
        </SideDrawer>
    )
}
