import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        attachFilePreviewDiv: {
            height: '51px',
            overflowY: 'scroll',
        },
        imageWrap: {
            position: 'relative',
            display: 'inline-block',
            borderRadius: '5px',
            '& .removeBtn': {
                position: 'absolute',
                top: 0,
                right: 0,
                color: 'red',
                display: 'none',
                cursor: 'pointer',
                padding: 0,
                borderRadius: '50%',
                background: '#FDCCC9',
            },
            '&:hover .removeBtn': {
                display: 'block',
            },
        },
    }),
)

interface Props {
    attachments: File[]
    onRemoveFile: (file: File) => void
}

export const AttachmentContainer = (props: Props) => {
    const classes = useStyles()
    if (props.attachments.length > 0) {
        props.attachments.map((value: any) => {
            const filePreview = URL.createObjectURL(value)
            console.log('file preview..', filePreview)
            console.log('file...', value)
            console.log('type...', value.type)
        })
    }
    return (
        <>
            {props.attachments?.length > 0 ? (
                <div className={classes.attachFilePreviewDiv}>
                    {props.attachments.map((file: any, index: number) => {
                        const filePreview = URL.createObjectURL(file)
                        return (
                            <>
                                <div
                                    className={classes.imageWrap}
                                    style={{ marginRight: '5px' }}
                                >
                                    <IconButton
                                        component="span"
                                        onClick={() => props.onRemoveFile(file)}
                                        className="removeBtn"
                                    >
                                        <CloseIcon />
                                    </IconButton>

                                    <img
                                        src={filePreview}
                                        // data-id={filePreview.name}
                                        alt={filePreview}
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                        }}
                                    />
                                </div>
                            </>
                        )
                    })}
                </div>
            ) : (
                <></>
            )}
        </>
    )
}
