import {
    Button,
    Divider,
    IconButton,
    Step,
    StepButton,
    Stepper,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from '../../components'
import {
    ApartmentQuestion,
    ApartmentQuestionType,
    IdBoolMap,
    ModelMap,
    PlanStatus,
    RFPService,
    RFPStatus,
    RFPType,
    Service,
    SimpleVendor,
} from '../../models'
import { ArrowBack } from '@material-ui/icons'
import { GetStartedForm } from './GetStartedForm'
import { axiosInstance } from '../../helpers'
import { ScopeOfWorkForm } from './ScopeOfWorkForm'
import { SelectVendorsForm } from './SelectVendorsForm'
import { ReviewAndSubmitForm } from './ReviewAndSubmitForm'
import { NVLVendor } from '../../main/containers/national-vendor-list'
import { CreateOrUpdateRFPRequest, rfpApi } from '../../contexts'
import { ApartmentQuestionForm } from './ApartmentQuestionForm'
import { toast } from 'react-toastify'

export const GET_STARTED_STEP = 0
export const SCOPE_OF_WORK_STEP = 1
export const CREATE_QUESTIONS_STEP = 2
export const CHOOSE_VENDOR_STEP = 3
export const REVIEW_AND_SUBMIT_STEP = 4

export type CreateRFPStep =
    | typeof GET_STARTED_STEP
    | typeof SCOPE_OF_WORK_STEP
    | typeof CREATE_QUESTIONS_STEP
    | typeof CHOOSE_VENDOR_STEP
    | typeof REVIEW_AND_SUBMIT_STEP

interface Props {
    serviceList: Service[]
    viewMode?: boolean
    onExit: () => void
}

export const CreateAndEditRFP = (props: Props) => {
    const { onExit, serviceList, viewMode } = props

    const { selectedRFP, updateRFP, createRFP } = rfpApi()

    const [vendorList, setVendorList] = useState<(NVLVendor | SimpleVendor)[]>(
        [],
    )
    const [selectedVendorsMap, setSelectedVendorsMap] = useState<
        ModelMap<NVLVendor | SimpleVendor>
    >({})

    const [type, setType] = useState(RFPType.INVITE_ONLY)
    const [name, setName] = useState('')
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [projectDeadline, setProjectDeadline] = useState<Date | null>(null)
    const [budget, setBudget] = useState<number | undefined>()
    const [selectedServicesMap, _setSelectedServicesMap] = useState<IdBoolMap>(
        {},
    )
    const [description, setDescription] = useState('')

    const [RFPServicesMap, setRFPServicesMap] = useState<ModelMap<RFPService>>(
        {},
    )
    const [RFPServiceList, setRFPServiceList] = useState<RFPService[]>([])

    const [activeStep, setActiveStep] = useState(GET_STARTED_STEP)
    const steps = [
        'Get Started',
        'Scope of Work',
        'Create Questions',
        `Choose Vendors ${type === RFPType.PUBLIC ? '(Optional)' : ''}`,
        'Review and Submit',
    ]
    const selectStep = (step: CreateRFPStep) => {
        // Use this method to go backwards to a previous step
        setActiveStep(step)
    }

    const [completed, setCompleted] = useState<IdBoolMap>({})

    const [generalQuestions, setGeneralQuestions] = useState<
        ApartmentQuestion[]
    >([])
    const [financialQuestions, setFinancialQuestions] = useState<
        ApartmentQuestion[]
    >([])

    const theme = useTheme()

    useEffect(() => {
        if (selectedRFP) {
            const tempCompletedMap = completed
            tempCompletedMap[GET_STARTED_STEP] = true
            if (selectedRFP.status !== RFPStatus.DRAFT) {
                tempCompletedMap[SCOPE_OF_WORK_STEP] = true
                tempCompletedMap[CHOOSE_VENDOR_STEP] = true
                tempCompletedMap[REVIEW_AND_SUBMIT_STEP] = true
                tempCompletedMap[CREATE_QUESTIONS_STEP] = true
            }
            if (selectedRFP.description !== '') {
                tempCompletedMap[SCOPE_OF_WORK_STEP] = true
            }
            setCompleted(tempCompletedMap)
            setType(selectedRFP.type)
            setName(selectedRFP.name)
            setBudget(selectedRFP.budget)
            if (selectedRFP.project_start_date) {
                setStartDate(new Date(selectedRFP.project_start_date))
            }
            if (selectedRFP.project_end_date) {
                setEndDate(new Date(selectedRFP.project_end_date))
            }
            if (selectedRFP.project_submission_deadline) {
                setProjectDeadline(
                    new Date(selectedRFP.project_submission_deadline),
                )
            }
            const tempServiceMap: IdBoolMap = {}
            selectedRFP.rfp_services.forEach(
                (rfpservice) => (tempServiceMap[rfpservice.service.id] = true),
            )
            setSelectedServicesMap(tempServiceMap)
            const tempRFPServicesMap: ModelMap<RFPService> = {}
            selectedRFP.rfp_services.map(
                (RFPService) =>
                    (tempRFPServicesMap[RFPService.id] = RFPService),
            )
            setRFPServicesMap(tempRFPServicesMap)
            setRFPServiceList(selectedRFP.rfp_services)
            setDescription(selectedRFP.description)
            const tempSelectedVendorsMap: ModelMap<
                NVLVendor | SimpleVendor
            > = {}
            selectedRFP.bids.forEach(
                (bid) => (tempSelectedVendorsMap[bid.vendor.id] = bid.vendor),
            )
            setSelectedVendorsMap(tempSelectedVendorsMap)
            setGeneralQuestions(
                selectedRFP.apartment_questions.filter(
                    (question) =>
                        question.type === ApartmentQuestionType.GENERAL,
                ),
            )
            setFinancialQuestions(
                selectedRFP.apartment_questions.filter(
                    (question) =>
                        question.type === ApartmentQuestionType.FINANCIAL,
                ),
            )
        } else {
            setType(RFPType.INVITE_ONLY)
            setName('')
            setBudget(undefined)
            setStartDate(null)
            setEndDate(null)
            setProjectDeadline(null)
            setSelectedServicesMap({})
            setRFPServicesMap({})
            setDescription('')
            setSelectedVendorsMap({})
            setFinancialQuestions([])
            setGeneralQuestions([])
        }
    }, [selectedRFP])

    const setSelectedServicesMap = async (selectedServicesMap: IdBoolMap) => {
        const params: {
            services?: string
        } = {}

        let services = ''
        Object.keys(selectedServicesMap).forEach((key, index: number) => {
            if (index !== Object.keys(selectedServicesMap).length - 1) {
                services = services + `${Number(key)}, `
            } else {
                services = services + `${Number(key)}`
            }
        })

        if (services.length !== 0) {
            params.services = services
        }

        const response = await axiosInstance.get(
            `company/national_vendor_list/`,
            { params: params },
        )
        setVendorList(
            response.data.sort((vendorOne: NVLVendor, vendorTwo: NVLVendor) => {
                if (
                    vendorOne.plan === PlanStatus.PREMIUM &&
                    vendorTwo.plan !== PlanStatus.PREMIUM
                ) {
                    return -2
                } else if (
                    vendorOne.plan !== PlanStatus.PREMIUM &&
                    vendorTwo.plan === PlanStatus.PREMIUM
                ) {
                    return 2
                } else {
                    return 0
                }
            }),
        )

        _setSelectedServicesMap(selectedServicesMap)
    }

    const updateRFPBody = (submit?: boolean) => {
        const services: {
            id: number
        }[] = []
        serviceList.forEach((service) => {
            if (selectedServicesMap[service.id]) {
                services.push({ id: service.id })
            }
        })
        const body: CreateOrUpdateRFPRequest = {
            name: name,
            type: type,
            services: services,
        }
        if (budget) {
            body.budget = budget
        }
        if (projectDeadline) {
            body.project_submission_deadline = projectDeadline.toISOString()
        }
        if (startDate) {
            body.project_start_date = startDate.toISOString()
        }
        if (endDate) {
            body.project_end_date = endDate.toISOString()
        }
        if (description) {
            body.description = description
        }
        const serviceDetails: {
            id: number
            rfp_service: number
            question_description: string
            question_answer: string
            question_config?: number
        }[] = []
        selectedRFP?.rfp_services.forEach((RFPService) => {
            RFPService.service_details.forEach((ServiceDetail) => {
                serviceDetails.push({
                    id: ServiceDetail.id,
                    question_description: ServiceDetail.question_description,
                    question_answer: ServiceDetail.question_answer,
                    rfp_service: RFPService.service.id,
                })
            })
        })
        body.service_details = serviceDetails
        const vendors: {
            id: number
        }[] = []
        Object.keys(selectedVendorsMap).forEach((key) => {
            vendors.push({ id: Number(key) })
        })
        body.vendors = vendors
        if (submit) {
            body.submit = true
        }
        body.apartment_questions = generalQuestions.concat(financialQuestions)

        return body
    }

    const createRFPBody = () => {
        const services: {
            id: number
        }[] = []
        serviceList.forEach((service) => {
            if (selectedServicesMap[service.id]) {
                services.push({ id: service.id })
            }
        })
        const body: CreateOrUpdateRFPRequest = {
            name: name,
            type: type,
            services: services,
        }
        if (budget) {
            body.budget = budget
        }
        if (projectDeadline) {
            body.project_submission_deadline = projectDeadline.toISOString()
        }
        if (startDate) {
            body.project_start_date = startDate.toISOString()
        }
        if (endDate) {
            body.project_end_date = endDate.toISOString()
        }
        return body
    }

    const displaySaveButton =
        !selectedRFP || selectedRFP.status === RFPStatus.DRAFT

    return (
        <Container
            flex={1}
            direction="column"
            style={{ height: 'calc(100vh - 104px)' }}
        >
            <Container style={{ width: '100%', justifyContent: 'center' }}>
                <IconButton
                    style={{
                        ...theme.typography.body2,
                        cursor: 'pointer',
                        color: theme.palette.darkGreen.main,
                    }}
                    onClick={() => onExit()}
                    size="small"
                >
                    <ArrowBack style={{ marginRight: theme.spacing(0.5) }} />{' '}
                    Back
                </IconButton>
                <Container style={{ width: '100%', justifyContent: 'center' }}>
                    <Stepper
                        nonLinear
                        activeStep={activeStep}
                        style={{ width: '70%' }}
                    >
                        {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                                <StepButton
                                    color="inherit"
                                    onClick={() => {
                                        selectStep(index as CreateRFPStep)
                                    }}
                                    disabled={!selectedRFP || !completed[index]}
                                >
                                    {label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Container>
                <Container alignItems="center">
                    {displaySaveButton && (
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: 'white',
                                color: theme.palette.primary.dark,
                                borderColor: theme.palette.primary.dark,
                                textTransform: 'none',
                                cursor: 'pointer',
                                width: '150px',
                                height: '40px',
                                justifySelf: 'center',
                            }}
                            onClick={() => {
                                if (selectedRFP) {
                                    const body = updateRFPBody()
                                    updateRFP(body).then(() =>
                                        toast.success('Progress Saved!'),
                                    )
                                } else {
                                    const body = createRFPBody()
                                    createRFP(body).then(() =>
                                        toast.success('Progress Saved!'),
                                    )
                                }
                            }}
                        >
                            Save Progress
                        </Button>
                    )}
                </Container>
            </Container>
            <Divider style={{ margin: theme.spacing(0, 0, 3, 0) }} />
            <Container style={{ justifyContent: 'center' }} flex={1}>
                {activeStep === GET_STARTED_STEP && (
                    <GetStartedForm
                        name={name}
                        budget={budget}
                        type={type}
                        startDate={startDate}
                        projectDeadline={projectDeadline}
                        selectedServicesMap={selectedServicesMap}
                        endDate={endDate}
                        serviceList={serviceList}
                        viewMode={viewMode}
                        setBudget={setBudget}
                        setName={setName}
                        setProjectDeadline={setProjectDeadline}
                        setStartDate={setStartDate}
                        setSelectedServicesMap={setSelectedServicesMap}
                        setType={setType}
                        setActiveStep={setActiveStep}
                        setEndDate={setEndDate}
                        createRFPBody={createRFPBody}
                    />
                )}
                {activeStep === SCOPE_OF_WORK_STEP && (
                    <ScopeOfWorkForm
                        description={description}
                        RFPServicesMap={RFPServicesMap}
                        RFPServices={RFPServiceList}
                        viewMode={viewMode}
                        rfp={selectedRFP}
                        setDescription={setDescription}
                        setActiveStep={setActiveStep}
                        setRFPServicesMap={setRFPServicesMap}
                        setRFPServices={setRFPServiceList}
                        updateRFPBody={updateRFPBody}
                    />
                )}
                {activeStep === CREATE_QUESTIONS_STEP && (
                    <ApartmentQuestionForm
                        generalQuestions={generalQuestions}
                        financialQuestions={financialQuestions}
                        viewMode={viewMode}
                        completed={completed}
                        setCompleted={setCompleted}
                        setActiveStep={setActiveStep}
                        setGeneralQuestions={setGeneralQuestions}
                        setFinancialQuestions={setFinancialQuestions}
                    />
                )}
                {activeStep === CHOOSE_VENDOR_STEP && (
                    <SelectVendorsForm
                        setActiveStep={setActiveStep}
                        vendorList={vendorList}
                        selectedVendorsMap={selectedVendorsMap}
                        setSelectedVendorsMap={setSelectedVendorsMap}
                        completed={completed}
                        setCompleted={setCompleted}
                        selectedRFP={selectedRFP}
                        viewMode={viewMode}
                        updateRFPBody={updateRFPBody}
                    />
                )}
                {activeStep === REVIEW_AND_SUBMIT_STEP && selectedRFP && (
                    <ReviewAndSubmitForm
                        setActiveStep={setActiveStep}
                        selectedVendorsMap={selectedVendorsMap}
                        setSelectedVendorsMap={setSelectedVendorsMap}
                        updateRFPBody={updateRFPBody}
                        onExit={onExit}
                        viewMode={viewMode}
                    />
                )}
            </Container>
        </Container>
    )
}
