import { Folder } from '../../models'

export const INITIAL_ROOT_ID = -2
export const ROOT_ID = -1
export const NEW_FOLDER_ID = -3

export interface VersionedTree {
    root: Folder
    version: number
}
