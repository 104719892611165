import React, { PropsWithChildren, useState } from 'react'
import { IdentifiableObject } from '../../models'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    onDragStart: React.DragEventHandler<HTMLDivElement>
    dragHandle?: React.MutableRefObject<any>
    payload: IdentifiableObject
}

export const Draggable = (props: PropsWithChildren<Props>) => {
    const [isDragging, setIsDragging] = useState(false)
    const [dragEnabled, setDragEnabled] = useState(true)

    const getDragEnabled = (
        dragHandle?: React.MutableRefObject<any>,
        mouseDownEvent?: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        // - Check to see if the mouse down event target matches the provided drag handle
        // - if the provided drag handle is undefined always allow dragging
        // - if the mouse down event target is an svg "path" use the parent "svg" element
        //   as the target instead

        if (dragHandle === undefined) {
            setDragEnabled(true)
        } else {
            const target = mouseDownEvent?.target as HTMLElement | undefined

            if (target) {
                if (target.tagName === 'svg') {
                    setDragEnabled(dragHandle.current === target)
                } else if (target.parentElement?.tagName === 'svg') {
                    const de = dragHandle.current === target.parentElement
                    setDragEnabled(de)
                    return
                }
            }

            setDragEnabled(dragHandle.current === mouseDownEvent?.target)
        }
    }

    return (
        <div
            {...props}
            style={{
                ...props.style,
                visibility: isDragging ? 'hidden' : 'visible',
            }}
            draggable={dragEnabled}
            onMouseDown={(e) => {
                getDragEnabled(props.dragHandle, e)
            }}
            onDragStart={(e) => {
                e.dataTransfer.setData(
                    'text/plain',
                    props.payload.id.toString(),
                )
                window.requestAnimationFrame(() => {
                    setIsDragging(true)
                })
                props.onDragStart(e)
            }}
            onDragEnd={() => {
                setIsDragging(false)
            }}
        >
            {props.children}
        </div>
    )
}
