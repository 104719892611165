import React, { useEffect, useState } from 'react'

// hooks
import { useTheme } from '@material-ui/core'

// types
import { Folder, traverse, Unit } from '../../models'

// components
import { Container } from '../Container'
import { FinderUnit, FinderFolder, FinderItem } from './'
import { Location, LocationSelection } from '../../store'
import { NEW_FOLDER_ID, ROOT_ID } from '../../hooks/useInfrastructure/types'
import { FinderEditFolder } from './FinderEditFolder'

interface Props {
    root?: Folder
    locationSelection: LocationSelection
    selectedIcon?: JSX.Element
    defaultOpen?: boolean
    editFolderId?: number
    onClickFolder?: (folder: Location) => void
    onClickUnit?: (unit: Location) => void
    getUnitAdornment?: (unit: Unit) => JSX.Element | null
    getFolderAdornment?: (folder: Folder) => JSX.Element | null
    createFolder?: (parentId: number, name: string) => void
    editFolder?: (folderId: number, name: string) => void
    cancelCreateFolder?: () => void
    cancelEditFolder?: () => void
}

interface FolderOpenState {
    [folderId: number]: boolean
}

const FinderC: React.FC<Props> = (props) => {
    const { root, locationSelection, onClickFolder, onClickUnit } = props

    const [openState, setOpenState] = useState<FolderOpenState>({})

    const theme = useTheme()

    useEffect(() => {
        if (root) {
            const newOpenState: FolderOpenState = { [-1]: true, [-2]: true }

            traverse(root, (folder) => {
                let isOpen = false
                if (folder.id in openState) {
                    isOpen = openState[folder.id]
                }
                newOpenState[folder.id] = isOpen
            })

            setOpenState(newOpenState)
        }
    }, [root])

    useEffect(() => {
        if (props.defaultOpen && root) {
            const newOpenState: FolderOpenState = { [-1]: true, [-2]: true }

            traverse(root, (folder) => {
                newOpenState[folder.id] = true
            })

            setOpenState(newOpenState)
        }
    }, [props.defaultOpen, root])

    const renderedTree: JSX.Element[] = []

    const renderFolder = (folder: Folder, level = 0) => {
        const selected = locationSelection.folder[folder.id] !== undefined

        if (folder.id === NEW_FOLDER_ID) {
            renderedTree.push(
                <FinderItem
                    level={level}
                    myKey={`FINDER_FOLDER_${folder.id}`}
                    selected={false}
                    selectedIcon={<></>}
                >
                    <FinderEditFolder
                        folder={folder}
                        onSave={(name) => {
                            if (props.createFolder) {
                                props.createFolder(
                                    folder.parent?.id ?? ROOT_ID,
                                    name,
                                )
                            }
                        }}
                        onCancel={() =>
                            props.cancelCreateFolder &&
                            props.cancelCreateFolder()
                        }
                    />
                </FinderItem>,
            )
        } else if (folder.id === props.editFolderId) {
            renderedTree.push(
                <FinderItem
                    level={level}
                    myKey={`FINDER_FOLDER_${folder.id}`}
                    selected={false}
                    selectedIcon={<></>}
                >
                    <FinderEditFolder
                        folder={folder}
                        onSave={(name) => {
                            props.editFolder &&
                                props.editFolder(folder.id, name)
                        }}
                        onCancel={() =>
                            props.cancelEditFolder && props.cancelEditFolder()
                        }
                    />
                </FinderItem>,
            )
        } else {
            renderedTree.push(
                <FinderItem
                    level={level}
                    selectedIcon={props.selectedIcon}
                    key={`FINDER_FOLDER_${folder.id}`}
                    myKey={`FINDER_FOLDER_${folder.id}`}
                    onClickIcon={() => {
                        onClickFolder &&
                            onClickFolder({ type: 'folder', location: folder })
                    }}
                    selected={selected}
                >
                    <FinderFolder
                        folder={folder}
                        open={openState[folder.id]}
                        onClick={() => {
                            setOpenState({
                                ...openState,
                                [folder.id]: !openState[folder.id],
                            })
                        }}
                        getFolderAdornment={props.getFolderAdornment}
                    />
                </FinderItem>,
            )
        }

        if (openState[folder.id]) {
            folder.children.forEach((child) => {
                renderFolder(child, level + 1)
            })

            folder.units.forEach((unit) => {
                const selected = locationSelection.unit[unit.id] !== undefined

                renderedTree.push(
                    <FinderItem
                        level={level + 1}
                        selectedIcon={props.selectedIcon}
                        key={`FINDER_UNIT_${unit.id}`}
                        myKey={`FINDER_UNIT_${unit.id}`}
                        onClickIcon={() => {
                            onClickUnit &&
                                onClickUnit({ type: 'unit', location: unit })
                        }}
                        selected={selected}
                    >
                        <FinderUnit
                            unit={unit}
                            getUnitAdornment={props.getUnitAdornment}
                        />
                    </FinderItem>,
                )
            })
        }
    }

    if (root) {
        renderFolder(root, 0)
    }

    return (
        <Container
            direction="column"
            flex={1}
            style={{
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(1),
                height: '100%',
            }}
            scrollY
        >
            {renderedTree}
        </Container>
    )
}

export const Finder: React.FC<Props> = React.memo(FinderC)
