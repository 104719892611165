import React from 'react'
import { Container } from './Container'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { PasswordRequirementResult } from '../helpers'

interface Props {
    passwordRequirementResult: PasswordRequirementResult
    containerStyle?: React.CSSProperties
}

export const PasswordRequirements = (props: Props) => {
    const { passwordRequirementResult, containerStyle } = props

    return (
        <Container
            style={{
                flexDirection: 'column',
                ...containerStyle,
            }}
        >
            <Requirement
                valid={passwordRequirementResult.lengthValid}
                text="At least 14 characters"
            />
            <Requirement
                valid={passwordRequirementResult.lowerCaseValid}
                text="At least 1 lower case letter"
            />
            <Requirement
                valid={passwordRequirementResult.upperCaseValid}
                text="At least 1 upper case letter"
            />
            <Requirement
                valid={passwordRequirementResult.numberValid}
                text="At least 1 number"
            />
            <Requirement
                valid={passwordRequirementResult.passwordsMatch}
                text="Passwords must match"
            />
        </Container>
    )
}

interface RequirementProps {
    text: string
    valid: boolean
}

const Requirement = (props: RequirementProps) => {
    return (
        <Container style={{ marginBottom: 4 }}>
            {props.valid ? (
                <CheckCircleIcon fontSize="small" color="primary" />
            ) : (
                <ErrorOutlineIcon fontSize="small" />
            )}
            <span style={{ marginLeft: 2 }}>{props.text}</span>
        </Container>
    )
}
