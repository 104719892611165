import React, { useMemo, useState } from 'react'
import { Container } from '../Container'
import { Button, IconButton, useTheme } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { AreaConfig, IdBoolMap, ModelMap, Unit } from '../../models'
import { AreaSelector } from './AreaSelector'
import { AreaSelectorState } from './types'
import { UnitRes } from '../../store'

interface Props {
    areaConfigMap: ModelMap<AreaConfig>
    areaSelectionMap: IdBoolMap
    setAreaSelectionMap: (newMap: IdBoolMap) => void
    units: Unit[] | UnitRes[]
    maxHeight?: string
    ignoreScroll?: boolean
}

const _AreaSelectorCollection = (props: Props) => {
    const {
        areaConfigMap,
        units,
        areaSelectionMap,
        setAreaSelectionMap,
        maxHeight,
        ignoreScroll,
    } = props

    const theme = useTheme()

    const acOptions = useMemo(() => {
        return Object.keys(areaConfigMap).map((areaIdStr) => {
            const areaId = Number(areaIdStr)
            return areaConfigMap[areaId] as AreaConfig
        })
    }, [areaConfigMap])

    const [collectionState, setCollectionState] = useState<AreaSelectorState[]>(
        acOptions.map((opt) => {
            return {
                selectedAreaConfigId: opt.id,
                useAreaLabel: false,
                isInitialValue: true,
            }
        }),
    )

    return (
        <Container
            style={{
                overflow: !ignoreScroll ? 'scroll' : '',
                flexWrap: 'wrap',
                maxHeight: maxHeight ? maxHeight : '100px',
                width: '90%',
            }}
        >
            {collectionState.map((item, idx) => {
                return (
                    <AreaSelector
                        key={idx}
                        units={units}
                        selectionState={collectionState[idx]}
                        updateSelectionState={(newState) => {
                            const nsList = [...collectionState]
                            nsList[idx] = newState
                            setCollectionState(nsList)
                        }}
                        areaConfigOptions={acOptions}
                        onPressRemove={() => {
                            const ns = collectionState.filter(
                                (s, removeCandidateIdx) => {
                                    if (idx === removeCandidateIdx) {
                                        console.log(
                                            collectionState[removeCandidateIdx],
                                        )
                                    }

                                    return idx !== removeCandidateIdx
                                },
                            )
                            setCollectionState(ns)
                        }}
                        selectedAreaMap={areaSelectionMap}
                        setSelectedAreaMap={setAreaSelectionMap}
                    />
                )
            })}

            <div>
                <IconButton
                    onClick={() =>
                        setCollectionState([
                            ...collectionState,
                            {
                                selectedAreaConfigId: null,
                                isInitialValue: false,
                            },
                        ])
                    }
                >
                    <AddIcon fontSize="large" />
                </IconButton>
            </div>
        </Container>
    )
}

export const AreaSelectorCollection = React.memo(_AreaSelectorCollection)
