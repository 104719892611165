import { useTheme } from '@material-ui/core'
import React from 'react'
import { Container } from '../../../../components'

interface Props {
    headLine?: string
    content?: string
}

export const BlankPanel = (props: Props) => {
    const theme = useTheme()

    const titleStyle: React.CSSProperties = {
        ...theme.typography.h5,
        fontWeight: 'bold',
        color: theme.palette.grey[700],
    }

    return (
        <Container
            style={{
                flex: 2,
                flexDirection: 'column',
                margin: theme.spacing(1),
            }}
        >
            <span style={titleStyle}>{props.headLine}</span>

            <Container
                style={{
                    flex: 1,
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: theme.shape.borderRadius,
                    flexDirection: 'column',
                    alignContent: 'center',
                    justifyContent: 'center',
                }}
            >
                <span style={{ ...titleStyle, textAlign: 'center' }}>
                    {props.content}
                </span>
            </Container>
        </Container>
    )
}
