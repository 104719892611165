import React from 'react'
import { AuditPromptConfig, DDLease, LeaseAudit } from '../../../../../models'
import { Theme, Paper, Modal } from '@material-ui/core'
import Grid from '@mui/material/Grid'
import { CellStyle } from '../Modals/TableModal/CellStyle'
import { styled } from '@mui/material/styles'

interface Props {
    leaseAudit: LeaseAudit
    lease: DDLease
    promptConfigs: AuditPromptConfig[]
    theme: Theme
    selectedConfigs: number[]
}

export const PromptResults = (props: Props) => {
    const { leaseAudit, lease, promptConfigs, theme, selectedConfigs } = props

    const promptAnswers = promptConfigs.filter((config) =>
        selectedConfigs.includes(config.dd_lease_col_id),
    )

    const HeaderStyle = styled(Paper)(({ theme }) => ({
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightBold,
        borderBottom: `1px solid ${theme.palette.grey[500]}`,
        justifyContent: 'flex-start',
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        display: 'flex',
        alignItems: 'flex-end',
        height: '100%',
    }))

    const answerValue = (lease: DDLease, prompt: AuditPromptConfig) => {
        const name = prompt.dd_lease_name as keyof DDLease
        return lease[name]
    }

    return (
        <Paper
            style={{
                display: 'flex',
                overflowY: 'scroll',
                flexDirection: 'column',
                height: '90%',
                width: '90%',
                padding: theme.spacing(2),
            }}
        >
            {/* <Grid container maxWidth={700} marginLeft={theme.spacing(1)} style={{}}>
            {promptAnswers.map((prompt, index) => (
                <React.Fragment key={index}>
                    <Grid item height={35} xs={6}>
                        <HeaderStyle>{prompt.display_name}</HeaderStyle>
                    </Grid>
                    <Grid item height={35} xs={6}>
                        {answerValue(lease, prompt) ? (
                            <CellStyle
                                format={prompt.format}
                                value={answerValue(lease, prompt)}
                                backgroundColor="transparent"
                            ></CellStyle>
                        ) : (
                            <CellStyle
                                format={'TEXT'}
                                value={'Audit Required'}
                                backgroundColor="transparent"
                            ></CellStyle>
                        )}
                    </Grid>
                </React.Fragment>
            ))}
        </Grid> */}
        </Paper>
    )
}
