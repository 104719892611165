import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import {
    useTheme,
    Modal,
    Paper,
    Slide,
    Tooltip,
    IconButton,
    CircularProgress,
    TablePagination,
} from '@material-ui/core'
import {
    AuditPromptConfig,
    DDLease,
    LeaseAudit,
    MatchedLease,
} from '../../../../../../models'
import { Container } from '../../../../../../components'
import CloseIcon from '@material-ui/icons/Close'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import Grid from '@mui/material/Grid'
import { TableRow } from './TableRow'
import { LeaseAuditLoadingState, UpdateDDLeaseRequest } from '../../../../Hooks'
import { axiosInstance } from '../../../../../../helpers/axios'
import { toast } from 'react-toastify'
import {
    ALL,
    InputFilter,
    LEASE_ADDENDUMS,
    RENT_ROLL,
    TableFilterButtons,
} from '../../Components/TableFilterButtons'
import { statusIndex } from '../../Services'

const status: AuditPromptConfig = {
    id: 1,
    prompt: '',
    category: 'status',
    dd_lease_col_id: 1,
    display_name: 'Status',
    dd_lease_name: 'status', // doesn't actually pull this from dd_lease. key of required.
    format: 'icon',
    width: 70,
    created_date: '',
    modified_date: '',
}
const verify: AuditPromptConfig = {
    id: 66,
    prompt: '',
    category: 'lease',
    dd_lease_col_id: 66,
    display_name: 'Audit Signatures',
    dd_lease_name: 'signature_audit',
    format: 'audit',
    width: 110,
    created_date: '',
    modified_date: '',
}
const edit: AuditPromptConfig = {
    id: 71,
    prompt: '',
    category: 'lease',
    dd_lease_col_id: 71,
    display_name: 'View/Edit Lease',
    dd_lease_name: 'edited',
    format: 'edit',
    width: 110,
    created_date: '',
    modified_date: '',
}

interface Props {
    open: boolean
    onClose: () => void
    matchedLeases: MatchedLease[]
    leaseAudit: LeaseAudit
    updateDDLease: (request: UpdateDDLeaseRequest) => Promise<DDLease>
    getLeaseAudit: (leaseAuditID: number) => void
    loadingState: LeaseAuditLoadingState
}

export const TableModal = (props: Props) => {
    const {
        open,
        onClose,
        matchedLeases,
        leaseAudit,
        updateDDLease,
        getLeaseAudit,
        loadingState,
    } = props

    const theme = useTheme()
    const [inputFilter, setInputFilter] = useState<InputFilter>(ALL)
    const [tableWidth, setTableWidth] = useState<number>()
    const [sortColumn, setSortColumn] = useState<string | null>(null)
    const [data, setData] = useState(matchedLeases)
    const [promptConfigs, setPromptConfigs] = useState<AuditPromptConfig[]>([])
    const [filteredConfigs, setFilteredConfigs] = useState<AuditPromptConfig[]>(
        [verify, edit, status],
    )
    const [loadingPrompts, setLoadingPrompts] = useState(true)

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    useEffect(() => {
        setLoadingPrompts(true)
        axiosInstance
            .get(`lease/dd-lease/get_all_prompts/`, {
                params: {
                    lease_audit_id: leaseAudit.id,
                },
            })
            .then((res) => {
                setPromptConfigs(res.data)
            })
            .catch((e) => toast.error(e.response.data.message))
    }, [])

    useEffect(() => {
        const newPromptConfigs: AuditPromptConfig[] = [verify, edit, status]
        setLoadingPrompts(true)
        let newTableWidth: number = 0
        if (inputFilter === ALL) {
            newTableWidth += 340
            promptConfigs.forEach((pc) => {
                newTableWidth += pc.width
                newPromptConfigs.push(pc)
            })
        } else if (inputFilter === RENT_ROLL) {
            newTableWidth += 340
            promptConfigs.forEach((pc) => {
                if (pc.category === 'rent_roll') {
                    newTableWidth += pc.width
                    newPromptConfigs.push(pc)
                }
            })
        } else if (inputFilter === LEASE_ADDENDUMS) {
            newTableWidth += 340
            promptConfigs.forEach((pc) => {
                if (pc.category === 'lease') {
                    newTableWidth += pc.width
                    newPromptConfigs.push(pc)
                }
            })
        }
        setFilteredConfigs(newPromptConfigs)
        setTableWidth(newTableWidth)
        setLoadingPrompts(false)
    }, [inputFilter, promptConfigs])

    const sortMethod = (col: AuditPromptConfig) => {
        return (a: MatchedLease, b: MatchedLease): number => {
            const colName = col.dd_lease_name as keyof DDLease
            const aValue = a.rent_roll_lease[colName]
            const bValue = b.rent_roll_lease[colName]

            if (aValue === null && bValue === null) {
                return 0
            } else if (aValue === null) {
                return 1
            } else if (bValue === null) {
                return -1
            }

            if (col.format === 'text') {
                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return aValue.localeCompare(bValue)
                }
            } else if (col.format === 'currency') {
                return Number(aValue) - Number(bValue)
            } else if (col.format === 'icon') {
                return statusIndex[a.status] - statusIndex[b.status]
            } else if (col.format === 'date') {
                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    const dateA = new Date(aValue)
                    const dateB = new Date(bValue)
                    return dateA.getTime() - dateB.getTime()
                }
            } else if (col.format === 'bool') {
                return aValue === bValue ? 0 : aValue ? -1 : 1
            } else if (col.format === 'audit') {
                return aValue === bValue ? 0 : aValue ? -1 : 1
            }
            return 0
        }
    }

    const handleSort = (col: AuditPromptConfig, sortColumn: string | null) => {
        if (sortColumn !== col.dd_lease_name) {
            setSortColumn(col.dd_lease_name)
            setData(data.sort(sortMethod(col)))
        } else {
            setSortColumn(null)
            setData(data.sort(sortMethod(col)).reverse())
        }
    }

    useEffect(() => {
        handleSort(status, null)
    }, [])

    const HeaderStyle = styled(Paper)(({ theme }) => ({
        ...theme.typography.subtitle1,
        fontWeight: theme.typography.fontWeightBold,
        borderBottom: `2px solid ${theme.palette.grey[300]}`,
        borderTop: `2px solid ${theme.palette.grey[300]}`,
        justifyContent: 'flex-start',
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        display: 'flex',
        alignItems: 'flex-end',
        minHeight: '115px',
        position: 'relative',
        '&:hover .sort-icon': {
            display: 'flex',
        },
    }))

    const SortIconContainer = styled('div')({
        display: 'none',
        position: 'absolute',
        left: '80%',
        top: '75%',
        transform: 'translateX(-50%)',
    })

    return (
        <Container>
            {loadingPrompts ? (
                <Container
                    style={{
                        paddingTop: theme.spacing(20),
                        justifyContent: 'center',
                        flex: 1,
                    }}
                >
                    <CircularProgress color="primary" size="5rem" />
                </Container>
            ) : (
                <Modal
                    open={open}
                    onClose={onClose}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Slide direction="up" in={open}>
                        <Paper
                            style={{
                                display: 'flex',
                                overflowY: 'scroll',
                                flexDirection: 'column',
                                height: '90%',
                                padding: theme.spacing(2),
                            }}
                        >
                            <Grid
                                container
                                style={{
                                    padding: theme.spacing(2),
                                    display: 'flex',
                                    flexDirection: 'row',
                                    overflowX: 'scroll',
                                    width: `${tableWidth}px`,
                                }}
                            >
                                {/* Header */}
                                <Container
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        flex: 1,
                                        maxHeight: '90px',
                                        minHeight: '80px',
                                    }}
                                >
                                    <Tooltip title="Close">
                                        <IconButton onClick={props.onClose}>
                                            <CloseIcon fontSize="large" />
                                        </IconButton>
                                    </Tooltip>

                                    <TableFilterButtons
                                        inputFilter={inputFilter}
                                        setInputFilter={setInputFilter}
                                    ></TableFilterButtons>
                                </Container>

                                <Container
                                    style={{
                                        display: 'flex',
                                    }}
                                >
                                    {/* Main Table */}
                                    <Grid container spacing={0}>
                                        {/* Header Row */}
                                        {filteredConfigs.map((col, idx) => (
                                            // set width dynamically based on predefined values
                                            <Grid
                                                item
                                                key={idx}
                                                width={col.width}
                                            >
                                                <HeaderStyle>
                                                    <div className="header-text">
                                                        {col.display_name}
                                                    </div>
                                                    <SortIconContainer className="sort-icon">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                handleSort(
                                                                    col,
                                                                    sortColumn,
                                                                )
                                                            }
                                                        >
                                                            <SwapVertIcon></SwapVertIcon>
                                                        </IconButton>
                                                    </SortIconContainer>
                                                </HeaderStyle>
                                            </Grid>
                                        ))}

                                        {/* Data Rows */}
                                        {data
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage +
                                                    rowsPerPage,
                                            )
                                            .map((lease) => (
                                                <TableRow
                                                    lease={lease}
                                                    key={`Lease-${lease.id}`}
                                                    filteredConfigs={
                                                        filteredConfigs
                                                    }
                                                    promptConfigs={
                                                        promptConfigs
                                                    }
                                                    updateDDLease={
                                                        updateDDLease
                                                    }
                                                    getLeaseAudit={
                                                        getLeaseAudit
                                                    }
                                                ></TableRow>
                                            ))}
                                    </Grid>
                                </Container>
                            </Grid>
                            <Container
                                style={{
                                    flex: 1,
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={
                                        handleChangeRowsPerPage
                                    }
                                />
                            </Container>
                        </Paper>
                    </Slide>
                </Modal>
            )}
        </Container>
    )
}
