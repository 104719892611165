import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
    getMessageList,
    RootState,
    setCurrentChannel,
    setMessageList,
} from '../store'
import { useAppDispatch } from './useAppDispatch'

export const useMessageDrawer = () => {
    const dispatch = useAppDispatch()

    const currentChannel = useSelector(
        (state: RootState) => state.messaging.currentChannel,
    )

    useEffect(() => {
        // When the channel changes get that channels message list
        if (currentChannel) {
            dispatch(getMessageList({ channelId: currentChannel.id }))
        } else {
            // if the channel was cleared, clear out the message list
            dispatch(setMessageList([]))
        }
    }, [currentChannel])

    // Clear the channel and messages on cleanup
    useEffect(() => {
        return () => {
            dispatch(setCurrentChannel())
            dispatch(setMessageList([]))
        }
    }, [])

    return {
        setChannel: (channelId: number) =>
            dispatch(
                setCurrentChannel({
                    id: channelId,
                    members: [],
                    name: '',
                    pair: false,
                    pair_user: '',
                }),
            ),
    }
}
