import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import CounterCards from './counter-cards'
import WorkForce from './workforce'
import ActionItems from './action-item'
import TestSchedule from './schedule'
import Messages from './messages'
import Inspections from './inspections'
import { SideDrawerContainer } from '../../../components'
import { useUser } from '../../../hooks'
import { MessageDrawer } from '../../components/message-drawer'
import { ProfileDrawer } from '../../components/profileDrawer'

const Dashboard = () => {
    const [channelId, setChannelId] = useState<number | null>(null)

    const { workspaceUser, rootUser } = useUser()

    const [msgDrawerOpen, setMsgDrawerOpen] = useState(false)
    const [profileDrawerOpen, setProfileDrawerOpen] = useState(false)
    const [messageDrawerData, setMessageDrawerData]: any = useState(null)

    const openMsgDrawer = (channelId: number) => {
        setChannelId(channelId)
        if (msgDrawerOpen === false) {
            setMsgDrawerOpen(true)
        }
    }

    const closeMsgDrawer = () => {
        setChannelId(null)
        setMsgDrawerOpen(false)
    }

    const toggleProfileDrawer = () => {
        setProfileDrawerOpen(!msgDrawerOpen)
    }

    return (
        <SideDrawerContainer
            open={msgDrawerOpen}
            width={330}
            key={`USER_WORKSPACE_${workspaceUser?.id}`}
        >
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <h2
                        style={{
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontSize: '40px',
                            lineHeight: '37px',
                            marginBottom: '15px',
                            marginTop: '5px',
                            color: '#2F2E41',
                        }}
                    >
                        Welcome back, {workspaceUser?.name}!
                    </h2>
                </Grid>

                <Grid item xs={12} sm={12} md={9} lg={9}>
                    <Grid
                        container
                        spacing={2}
                        style={{ marginBottom: '15px' }}
                    >
                        <Grid item xs={12}>
                            <CounterCards />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="stretch">
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            style={{ marginBottom: '15px' }}
                        >
                            <ActionItems />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={8}
                            lg={8}
                            style={{ marginBottom: '15px' }}
                        >
                            <TestSchedule />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems="stretch">
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            style={{ marginBottom: '15px' }}
                        >
                            <Messages openMsgDrawer={openMsgDrawer} />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={8}
                            lg={8}
                            style={{ marginBottom: '15px' }}
                        >
                            <Inspections />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    style={{ marginBottom: '10px' }}
                >
                    <WorkForce openMsgDrawer={openMsgDrawer} />
                </Grid>
            </Grid>
            <MessageDrawer
                open={msgDrawerOpen}
                handleClose={closeMsgDrawer}
                width={330}
                // messageData={messageDrawerData}
                // toggleProfileDrawer={toggleProfileDrawer}
                channelId={channelId}
            />
            <ProfileDrawer
                open={profileDrawerOpen}
                handleClose={toggleProfileDrawer}
                width={330}
                messageData={messageDrawerData}
            />
        </SideDrawerContainer>
    )
}

export default Dashboard
