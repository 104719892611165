import React, { useEffect, useState } from 'react'
import { axiosInstance } from '../../helpers'
import {
    DamageConfig,
    IdBoolMap,
    InventoryConfig,
    InventoryIssueOption,
    IssueConfig,
} from '../../models'
import { DrawerState, ISSUE_CONFIG } from './InfrastructureConfig'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    IconButton,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { Container, Selector, SideDrawer } from '../../components'
import { Add, ExpandLess, ExpandMore, LineStyle } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { toast } from 'react-toastify'

interface Props {
    open: DrawerState
    handleDrawerChange: (drawer?: DrawerState) => void
    damageConfigList: DamageConfig[]
}

export const IssueOptionsDrawer = (props: Props) => {
    const { open, handleDrawerChange, damageConfigList } = props

    const theme = useTheme()

    const [issueConfigList, setIssueConfigList] = useState<IssueConfig[]>([])
    const [issueSelectedMap, setIssueSelectedMap] = useState<IdBoolMap>({})
    const [newIssueOpen, setNewIssueOpen] = useState(false)
    const [newIssueName, setNewIssueName] = useState('')
    const [doValidate, setDoValidate] = useState(false)
    const [selectedDamageConfigId, setSelectedDamageConfigId] = useState(-1)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (open.selected && open.formOpen === ISSUE_CONFIG) {
            setLoading(true)
            axiosInstance.get(`apt_config/issue-config/`).then((outerRes) => {
                axiosInstance
                    .get(
                        `apt_config/inventory_config/${open.selected?.id}/get_issue_option_list/`,
                    )
                    .then((res) => {
                        const newIssueSelectedMap: IdBoolMap = {}
                        outerRes.data.forEach((issue_config: IssueConfig) => {
                            newIssueSelectedMap[issue_config.id] = false
                        })
                        res.data.forEach(
                            (inventoryIssueOption: InventoryIssueOption) => {
                                newIssueSelectedMap[
                                    inventoryIssueOption.issue_config.id
                                ] = true
                            },
                        )

                        setIssueSelectedMap(newIssueSelectedMap)
                        setIssueConfigList(outerRes.data)
                        setLoading(false)
                    })
            })
        }
    }, [open.selected])

    return (
        <SideDrawer
            open={open.formOpen === ISSUE_CONFIG}
            handleClose={() => handleDrawerChange()}
            title={open.selected?.name}
        >
            <Accordion>
                <AccordionSummary>
                    <span
                        style={{
                            ...theme.typography.h6,
                            alignSelf: 'center',
                            paddingBottom: theme.spacing(1),
                        }}
                    >
                        {' '}
                        Quick Add Issue Config
                    </span>
                    <Tooltip title="Add New Issue Config">
                        <IconButton
                            onClick={() => {
                                setNewIssueOpen(!newIssueOpen)
                            }}
                        >
                            {newIssueOpen ? (
                                <ExpandLess color="disabled" fontSize="large" />
                            ) : (
                                <ExpandMore color="disabled" fontSize="large" />
                            )}
                        </IconButton>
                    </Tooltip>
                </AccordionSummary>
                <AccordionDetails>
                    <Container
                        style={{
                            margin: theme.spacing(2),
                            border: `1px solid ${theme.palette.common.black}`,
                            borderRadius: theme.shape.borderRadius,
                            flex: 1,
                        }}
                        direction="column"
                    >
                        <TextField
                            // inputProps={{ maxLength: 50 }}
                            variant="outlined"
                            value={newIssueName}
                            error={doValidate && newIssueName === ''}
                            onChange={(e) => {
                                if (e.target.value.length < 31) {
                                    setNewIssueName(e.target.value)
                                }
                            }}
                            label="Name"
                            style={{
                                margin: theme.spacing(2),
                            }}
                        />
                        <Selector
                            label={'Damage Config'}
                            currentValue={selectedDamageConfigId}
                            onChange={(
                                event: React.ChangeEvent<{
                                    value: unknown
                                }>,
                            ) => {
                                setSelectedDamageConfigId(
                                    event.target.value as number,
                                )
                            }}
                            data={damageConfigList}
                            getDisplayString={(d: DamageConfig) =>
                                d.description
                            }
                        />
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                                if (newIssueName === '') {
                                    setDoValidate(true)
                                } else {
                                    const data: {
                                        name: string
                                        damage_config?: number
                                    } = {
                                        name: newIssueName,
                                    }
                                    if (selectedDamageConfigId !== -1) {
                                        data.damage_config = selectedDamageConfigId
                                    }

                                    axiosInstance
                                        .post('apt_config/issue-config/', data)
                                        .then((res) => {
                                            setDoValidate(false)
                                            setNewIssueName('')
                                            setSelectedDamageConfigId(-1)
                                            setIssueConfigList([
                                                ...issueConfigList,
                                                res.data,
                                            ])
                                            const newIssueSelectedMap = {
                                                ...issueSelectedMap,
                                            }
                                            newIssueSelectedMap[
                                                res.data.id
                                            ] = false
                                            setIssueSelectedMap(
                                                newIssueSelectedMap,
                                            )
                                            toast.success(
                                                `Issue Config ${res.data.name} was created!`,
                                            )
                                        })
                                }
                            }}
                            style={{ margin: theme.spacing(1, 2, 1, 2) }}
                        >
                            Save
                        </Button>
                    </Container>
                </AccordionDetails>
            </Accordion>

            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    margin: theme.spacing(1),
                }}
            >
                {loading ? (
                    <CircularProgress
                        size={20}
                        style={{ alignSelf: 'center', justifySelf: 'center' }}
                    />
                ) : (
                    <Container style={{ flexDirection: 'column' }}>
                        {issueConfigList.map((issue, idx) => {
                            return (
                                <FormControlLabel
                                    key={`ISSUE_LIST_${idx}_${issue.id}`}
                                    control={
                                        <Checkbox
                                            checked={issueSelectedMap[issue.id]}
                                            //style={{ color: theme.palette.primary.main, }}
                                            style={{
                                                color:
                                                    theme.palette.primary.dark,
                                            }}
                                            onClick={() => {
                                                axiosInstance
                                                    .post(
                                                        `apt_config/issue-config/${issue.id}/add_remove_issue_option/`,
                                                        {
                                                            inventory_config:
                                                                open.selected
                                                                    ?.id,
                                                        },
                                                    )
                                                    .then(() => {
                                                        const newIssueSelectedMap = {
                                                            ...issueSelectedMap,
                                                        }
                                                        newIssueSelectedMap[
                                                            issue.id
                                                        ] = !issueSelectedMap[
                                                            issue.id
                                                        ]
                                                        setIssueSelectedMap(
                                                            newIssueSelectedMap,
                                                        )
                                                    })
                                            }}
                                        />
                                    }
                                    label={issue.name}
                                    labelPlacement="end"
                                    style={{
                                        ...theme.typography.body1,
                                        fontWeight: 'bold',
                                    }}
                                />
                            )
                        })}
                    </Container>
                )}
                <div style={{ flex: 1 }} />
                <Container
                    justifyContent={'flex-end'}
                    style={{
                        borderTop: `1px solid ${theme.palette.grey[400]}`,
                        padding: theme.spacing(1),
                        marginTop: theme.spacing(1),
                    }}
                >
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => handleDrawerChange()}
                        style={{ marginRight: theme.spacing(1) }}
                    >
                        Close
                    </Button>
                </Container>
            </Container>
        </SideDrawer>
    )
}
