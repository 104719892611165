import React, { useMemo, useState } from 'react'

import { IconButton, Menu, TextField, Button } from '@material-ui/core'
import { Schedule, Service } from '../../../models'
import { Container } from '../../../components'
import { formatTotals } from '../services'
import { BaseProps } from '../../TemplateScheduleGantt/types'
import { LineItemTotals } from '../../../containers/Scheduler_v2/Modals/BudgetModal/BudgetModal'
import AddIcon from '@material-ui/icons/Add'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import {
    HeaderValue,
    IconValue,
    TextValue,
    TotalsMoneyValue,
    TotalsTextValue,
} from '../styles'
import { LineItemRow } from './LineItemRow'
import { toast } from 'react-toastify'

interface Props extends BaseProps {
    totals: LineItemTotals | undefined
    scheduleDetail: Schedule | undefined
}

export const LineItemTable = (props: Props) => {
    const { theme, scheduleController, totals, scheduleDetail } = props

    const schedule = scheduleController.schedule
    const [description, setDescription] = useState('')
    const [cost, setCost] = useState(0)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleLineItemSave = () => {
        if (
            description.trim() === '' ||
            cost <= 0 ||
            scheduleDetail === undefined
        ) {
            toast.error(
                'Description cannot be blank and cost must be a value greater than 0',
            )
            return
        }

        scheduleController
            .addLineItem(scheduleDetail.id, description, cost)
            .then(() => {
                toast.success('Added!')
                setDescription('')
                setCost(0)
            })
            .catch(() => {
                toast.error('There was a problem with your request')
            })
            .finally(() => {
                handleClose()
            })
    }

    const totalCost = useMemo(() => {
        return (
            schedule?.line_items.reduce((sum, item) => sum + item.cost, 0) || 0
        )
    }, [schedule?.line_items])

    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Container
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    minHeight: 40,
                }}
            >
                {/* Title */}
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        display: 'flex',
                    }}
                >
                    Line Items
                </span>
            </Container>
            <Grid container spacing={0}>
                {/* Header Row */}
                <Grid item xs={0.5}>
                    <HeaderValue>&nbsp;</HeaderValue>
                </Grid>
                <Grid item xs={9.5}>
                    <HeaderValue
                        style={{
                            justifyContent: 'flex-start',
                        }}
                    >
                        Line Item
                    </HeaderValue>
                </Grid>
                <Grid item xs={2}>
                    <HeaderValue
                        style={{
                            justifyContent: 'flex-end',
                        }}
                    >
                        Cost
                    </HeaderValue>
                </Grid>

                {/* data rows */}
                {schedule?.line_items.map((li) => (
                    <LineItemRow
                        {...props}
                        key={`LINE-ITEM-${li.id}`}
                        lineItem={li}
                    ></LineItemRow>
                ))}

                {/* Add Line Item Row */}
                <Grid item xs={0.5}>
                    <IconValue
                        style={{
                            justifyContent: 'center',
                        }}
                    >
                        <IconButton onClick={openMenu}>
                            <AddIcon />
                        </IconButton>
                    </IconValue>
                </Grid>
                <Grid item xs={9.5}>
                    <TextValue></TextValue>
                </Grid>
                <Grid item xs={2}>
                    <TextValue></TextValue>
                </Grid>

                {/* Totals Row */}
                <Grid item xs={0.5}>
                    <TotalsTextValue>&nbsp;</TotalsTextValue>
                </Grid>
                <Grid item xs={9.5}>
                    <TotalsTextValue>Totals</TotalsTextValue>
                </Grid>
                <Grid item xs={2}>
                    <TotalsMoneyValue>
                        {formatTotals(totalCost)}
                    </TotalsMoneyValue>
                </Grid>
            </Grid>
            <Menu
                style={
                    {
                        // height: '70%',
                        // width: '50%',
                    }
                }
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Container>
                    <TextField
                        style={{ margin: theme.spacing(1) }}
                        label="Line Item"
                        placeholder="Enter Description"
                        value={description}
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        style={{ margin: theme.spacing(1) }}
                        label="Cost"
                        placeholder="Enter Value"
                        type="number"
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => setCost(Number(e.target.value))}
                        prefix="$"
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        style={{
                            ...theme.typography.subtitle1,
                            marginLeft: theme.spacing(2),
                            marginTop: theme.spacing(3),
                            marginRight: theme.spacing(1),
                            backgroundColor: theme.palette.primary.dark,
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            height: 30,
                            width: 40,
                        }}
                        onClick={() => handleLineItemSave()}
                    >
                        Save
                    </Button>
                </Container>
            </Menu>
        </Box>
    )
}
