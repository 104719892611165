import { PeriodOption } from './types'

export const getPeriodOptionLabel = (option: PeriodOption) => {
    switch (option) {
        case PeriodOption.DY:
            return 'Daily'
        case PeriodOption.WK:
            return 'Weekly'
        case PeriodOption.BW:
            return 'Bi-Weekly'
        case PeriodOption.MN:
            return 'Monthly'
        case PeriodOption.BY:
            return 'Bi-Yearly'
        case PeriodOption.YR:
            return 'Yearly'
        case PeriodOption.WD:
            return 'Week Days'
    }

    return '?'
}
