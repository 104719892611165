import React from 'react'
import { useTheme } from '@material-ui/core'

import {
    APPROVED_ANALYTIC,
    ASSIGNED_ANALYTIC,
    COMPLETE_ANALYTIC,
    StatusSelection,
} from './AnalyticsBlanket'
import {
    BaseWorkorder,
    Folder,
    isWorkorderApproved,
    isWorkorderComplete,
    ModelListMap,
    Unit,
} from '../../../models'
import { Container, StyledProgressBar } from '../../../components'

interface Props {
    infrastructure: Folder
    statusSelection: StatusSelection
    unitWorkorderMap: ModelListMap<BaseWorkorder>
    selectedServiceId: number
    isWorkorderInFilter: (workorder: BaseWorkorder, unit: Unit) => boolean
}

const _FolderAnalytics = (props: Props) => {
    const {
        statusSelection,
        unitWorkorderMap,
        isWorkorderInFilter,
        selectedServiceId,
    } = props

    const theme = useTheme()

    let barColor = ''
    switch (statusSelection) {
        case ASSIGNED_ANALYTIC:
            barColor = theme.palette.primary.main
            break
        case COMPLETE_ANALYTIC:
            barColor = theme.palette.secondary.main
            break
        case APPROVED_ANALYTIC:
            barColor = theme.palette.brand1.main
            break
    }

    const folders: JSX.Element[] = []

    const buildFolderAnalytics = (folder: Folder) => {
        let unitCount = 0
        let totalWorkorderCount = 0
        let filteredWorkorderCount = 0

        folder.units.forEach((unit) => {
            unitCount++
            if (unit.id in unitWorkorderMap) {
                unitWorkorderMap[unit.id]?.forEach((wo) => {
                    if (
                        isWorkorderInFilter(wo, unit) &&
                        wo.service_id === selectedServiceId
                    ) {
                        if (
                            statusSelection === COMPLETE_ANALYTIC &&
                            isWorkorderComplete(wo)
                        ) {
                            filteredWorkorderCount++
                        }
                        if (
                            statusSelection === APPROVED_ANALYTIC &&
                            isWorkorderApproved(wo)
                        ) {
                            filteredWorkorderCount++
                        }
                        if (statusSelection === ASSIGNED_ANALYTIC) {
                            filteredWorkorderCount++
                        }
                        totalWorkorderCount++
                    }
                })
            }
        })

        folder.children.forEach((child) => {
            const counts = buildFolderAnalytics(child)
            unitCount += counts.totalUnits
            totalWorkorderCount += counts.totalWorkorderCount
            filteredWorkorderCount += counts.filteredWorkorderCount
        })

        if (totalWorkorderCount > 0) {
            let folderName = folder.name
            if (folder.path !== '-1') {
                folderName = `${folder.path}${folder.name}`
            }

            const numerator = filteredWorkorderCount

            let denominator = totalWorkorderCount
            if (statusSelection === ASSIGNED_ANALYTIC) {
                denominator = unitCount
            }

            denominator = Math.max(1, denominator)

            const content = (
                <Container
                    style={{
                        flexDirection: 'column',
                        margin: theme.spacing(1, 0),
                    }}
                >
                    <Container>
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {folderName}
                        </span>
                        <div style={{ flex: 1 }} />
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightLight,
                            }}
                        >
                            {numerator} / {denominator}
                        </span>
                    </Container>

                    <StyledProgressBar
                        value={Math.min(100, (numerator / denominator) * 100)}
                        variant="determinate"
                        barColor={barColor}
                    />
                </Container>
            )
            if (folderName === 'Root') {
                folders.unshift(content)
            } else {
                folders.push(content)
            }
        }

        return {
            totalUnits: unitCount,
            totalWorkorderCount: totalWorkorderCount,
            filteredWorkorderCount: filteredWorkorderCount,
        }
    }

    buildFolderAnalytics(props.infrastructure)

    return (
        <Container
            style={{
                flex: 1,
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.grey[200],
                margin: theme.spacing(1),
                padding: theme.spacing(1),
                flexDirection: 'column',
            }}
            scrollY
        >
            {folders}
            {folders.length === 0 && (
                <Container
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        There are no folder analytics for your selection
                    </span>
                </Container>
            )}
        </Container>
    )
}

export const FolderAnalytics = React.memo(_FolderAnalytics)
