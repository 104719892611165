import React, { useEffect, useState } from 'react'
// import { SideDrawer, Container } from '../../../components'
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme,
} from '@material-ui/core/styles'
import {
    FormControl,
    InputAdornment,
    List,
    ListItem,
    ListSubheader,
    OutlinedInput,
    ListItemIcon,
    Collapse,
    ListItemText,
    Checkbox,
    ListItemSecondaryAction,
    Divider,
    TextField,
    FormLabel,
    Typography,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    Slider,
    Menu,
    MenuItem,
    Container,
    Tooltip,
} from '@material-ui/core'

import InboxIcon from '@material-ui/icons/MoveToInbox'
import DraftsIcon from '@material-ui/icons/Drafts'
import SendIcon from '@material-ui/icons/Send'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import StarBorder from '@material-ui/icons/StarBorder'

import SearchIcon from '@material-ui/icons/Search'
import { Autocomplete, Rating } from '@material-ui/lab'
import RemoveIcon from '@material-ui/icons/Remove'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { SideDrawer } from '../../components/SideDrawer'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'
import { Service } from '../../../models'
import { Check, FolderOpenSharp } from '@material-ui/icons'
import { useUser } from '../../../hooks'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchInput: {
            '& .MuiOutlinedInput-input': {
                padding: '9px 0 9px',
            },
            marginBottom: '16px',
        },
        filterForm: {
            '& .MuiFormControl-root': {
                marginBottom: '5px',
            },
            '& .MuiFormLabel-root': {
                color: '#000',
                fontSize: '0.875rem',
                lineHeight: '2rem',
            },
        },
        filterItemsCheckList: {
            width: '100%',
            '& .MuiListSubheader-root': {
                color: '#000',
                fontSize: '1.25rem',
                fontWeight: 700,
                position: 'relative',
            },
            '& .MuiListItemIcon-root': {
                minWidth: 'auto',
            },
        },
        filterHeader: {
            color: '#000',
            fontSize: '1.25rem',
            fontWeight: 700,
            position: 'relative',
        },
        primaryCheckbox: {
            color: '#008c85 !important',
            padding: '0 9px',
        },
        submitBtn: {
            background: '#008C85',
            color: '#FFF',
            fontSize: '0.875rem',
            textTransform: 'inherit',
            fontWeight: 500,
            borderRadius: '5px',
            width: '100%',
        },
        radioPrimary: {
            '& .Mui-checked': {
                color: '#008c85',
            },
        },
        filterRating: {
            '& .MuiRating-iconEmpty': {
                color: 'transparent',
            },
        },
        countFieldText: {
            '& .MuiInputBase-input': {
                height: '1em',
                fontSize: '0.813rem',
                textTransform: 'none',
                fontWeight: 600,
            },
        },
        sliderDisabled: {
            color: 'gray',
        },
    }),
)

interface FilterData {
    searchText: String
    services: Array<Service>
    rating: Array<number>
    radius: number
    willingToTravel: String
    trained: String
    complete_count: {
        type: string
        value: string
    }
    go_back_count: {
        type: string
        value: string
    }
}

interface Props {
    open: boolean
    handleClose: () => void
    width: number
    serviceList: Service[] | undefined
    filterData: FilterData
    onRadiusChange: (
        event: React.ChangeEvent<{}>,
        newValue: number | number[],
    ) => void
    handleServiceChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        serviceName: string,
    ) => void
    handleSearchFilterChange: (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => void
    handleTrainedChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleWilToTravelChange: (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => void
    resetFilter: () => void
    onCompleteCountChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        type: string,
    ) => void
    onGoBackCountChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        type: string,
    ) => void
    setFilterData: (data: FilterData) => void
}

const FilterDrawer = (props: Props) => {
    const classes = useStyles()
    const theme = useTheme()

    const [uiRadius, setUiRadius] = useState(props.filterData.radius)

    useEffect(() => {
        setUiRadius(props.filterData.radius)
    }, [props.filterData.radius])

    const radiusMarks = [
        {
            value: 50,
            label: '50',
        },
        {
            value: 150,
            label: '150',
        },
        {
            value: 250,
            label: '250',
        },
        {
            value: 350,
            label: '350',
        },
        {
            value: 450,
            label: '450',
        },
        {
            value: 500,
            label: '500',
        },
    ]

    const [
        completeMenuIconOpen,
        setCompleteMenuIconOpen,
    ] = React.useState<null | HTMLElement>(null)

    const handleCompleteMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setCompleteMenuIconOpen(event.currentTarget)
    }

    const handleCloseComplete = () => {
        setCompleteMenuIconOpen(null)
    }

    const [completeCountIcon, setCompleteCountIcon] = useState<string>(
        'greater',
    )
    const [completeCount, setCompleteCount] = useState<string>('')

    const [goBackCountIcon, setGoBackCountIcon] = useState<string>('greater')
    const [goBackCount, setGoBackCount] = useState<string>('')

    /******* Go Back Menu ************* */
    const [
        gobackMenuIconOpen,
        setGobackMenuIconOpen,
    ] = React.useState<null | HTMLElement>(null)

    const handleGoBackMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setGobackMenuIconOpen(event.currentTarget)
    }

    const handleCloseGoBack = () => {
        setGobackMenuIconOpen(null)
    }

    const { workspaceUser } = useUser()

    return (
        <SideDrawer
            open={props.open}
            handleClose={props.handleClose}
            width={props.width}
            title={'Filter'}
        >
            <Container style={{ overflowY: 'scroll', height: '100%' }}>
                {/* </Container>
            <Container
                direction="column"
                style={{ padding: theme.spacing(2) }}
                flex={1}
                scrollY
            > */}
                <form className={classes.filterForm}>
                    {/* <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            value={props.filterData.searchText}
                            labelWidth={0}
                            placeholder="Search"
                            className={classes.searchInput}
                            onChange={props.handleSearchFilterChange}
                        />
                    </FormControl> */}
                    <div style={{ padding: '16px 0' }}>
                        <Typography
                            component="span"
                            className={classes.filterHeader}
                        >
                            Services
                        </Typography>
                    </div>
                    <List
                        className={classes.filterItemsCheckList}
                        dense={true}
                        style={{
                            maxHeight: '200px',
                            overflowY: 'auto',
                            marginBottom: '15px',
                        }}
                    >
                        {props.serviceList && props.serviceList.length > 0 ? (
                            props.serviceList.map(
                                (service: any, index: number) => (
                                    <ListItem key={`serviceFilter-${index}`}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                disableRipple
                                                className={
                                                    classes.primaryCheckbox
                                                }
                                                value={service.id}
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) =>
                                                    props.handleServiceChange(
                                                        event,
                                                        service.name,
                                                    )
                                                }
                                                checked={
                                                    props.filterData.services.filter(
                                                        (value: any) =>
                                                            value.id ===
                                                            service.id,
                                                    ).length > 0
                                                }
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={service.name} />
                                    </ListItem>
                                ),
                            )
                        ) : (
                            <></>
                        )}
                    </List>
                    <div style={{ padding: '16px 0' }}>
                        <Divider />
                    </div>
                    {/* <List
                        className={classes.filterItemsCheckList}
                        dense={true}
                        subheader={
                            <ListSubheader
                                component="div"
                                id="nested-list-subheader"
                            >
                                Rating
                            </ListSubheader>
                        }
                    >
                        {[1, 2, 3, 4, 5].map((value: number) => (
                            <ListItem key={`rating-${value}`}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        disableRipple
                                        className={classes.primaryCheckbox}
                                        value={value}
                                        onChange={props.handleRatingsChange}
                                        checked={
                                            props.filterData.rating.indexOf(
                                                value,
                                            ) >= 0
                                        }
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Rating
                                            name="read-only"
                                            value={value}
                                            readOnly
                                            className={classes.filterRating}
                                        />
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                    <div style={{ padding: '0 16px' }}>
                        <Divider />
                    </div> */}
                    <div style={{ padding: '16px 0' }}>
                        <Typography
                            component="span"
                            className={classes.filterHeader}
                        >
                            Select Count Fields
                        </Typography>
                        <div>
                            <Button
                                variant="outlined"
                                disableRipple
                                style={{
                                    margin: '5px',
                                    fontSize: '0.813rem',
                                    textTransform: 'none',
                                    fontWeight: 600,
                                    cursor: 'default',
                                }}
                            >
                                Complete
                            </Button>
                            <Button
                                variant="outlined"
                                style={{
                                    margin: '5px',
                                    fontSize: '0.813rem',
                                    textTransform: 'none',
                                    fontWeight: 600,
                                    minWidth: 'unset',
                                    width: '30px',
                                }}
                                onClick={handleCompleteMenu}
                            >
                                {completeCountIcon === 'less' ? (
                                    <ChevronLeftIcon />
                                ) : completeCountIcon === 'greater' ? (
                                    <ChevronRightIcon />
                                ) : (
                                    <RemoveIcon />
                                )}
                            </Button>
                            <TextField
                                type="number"
                                id="complete-value"
                                placeholder="Value"
                                variant="outlined"
                                style={{ width: '27%', margin: '5px' }}
                                size="small"
                                className={classes.countFieldText}
                                InputProps={{ inputProps: { min: 1 } }}
                                onChange={(event) =>
                                    setCompleteCount(event.target.value)
                                }
                                value={completeCount}
                            />
                            {(completeCount !==
                                props.filterData.complete_count.value ||
                                completeCountIcon !==
                                    props.filterData.complete_count.type) && (
                                <Tooltip title="Apply">
                                    <IconButton
                                        component="span"
                                        onClick={() => {
                                            props.setFilterData({
                                                ...props.filterData,
                                                complete_count: {
                                                    type: completeCountIcon,
                                                    value: completeCount,
                                                },
                                            })
                                        }}
                                        style={{ padding: '6px' }}
                                    >
                                        <Check />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {props.filterData.complete_count.value > '0' && (
                                <Tooltip title="Clear">
                                    <IconButton
                                        component="span"
                                        onClick={() => {
                                            setCompleteCountIcon('greater')
                                            setCompleteCount('')
                                            props.setFilterData({
                                                ...props.filterData,
                                                complete_count: {
                                                    type: 'greater',
                                                    value: '',
                                                },
                                            })
                                        }}
                                        className="removeBtn"
                                        style={{ padding: '6px' }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Menu
                                id="simple-menu"
                                anchorEl={completeMenuIconOpen}
                                keepMounted
                                open={Boolean(completeMenuIconOpen)}
                                onClose={handleCloseComplete}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setCompleteCountIcon('less')
                                        handleCloseComplete()
                                    }}
                                >
                                    <ChevronLeftIcon />
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setCompleteCountIcon('greater')
                                        handleCloseComplete()
                                    }}
                                >
                                    <ChevronRightIcon />
                                </MenuItem>
                            </Menu>
                        </div>

                        <div>
                            <Button
                                variant="outlined"
                                disableRipple
                                style={{
                                    margin: '5px',
                                    width: '94px',
                                    fontSize: '0.813rem',
                                    textTransform: 'none',
                                    fontWeight: 600,
                                }}
                            >
                                Go Back
                            </Button>
                            <Button
                                variant="outlined"
                                style={{
                                    margin: '5px',
                                    minWidth: 'unset',
                                    width: '30px',
                                }}
                                onClick={handleGoBackMenu}
                            >
                                {goBackCountIcon === 'less' ? (
                                    <ChevronLeftIcon />
                                ) : goBackCountIcon === 'greater' ? (
                                    <ChevronRightIcon />
                                ) : (
                                    <RemoveIcon />
                                )}
                            </Button>
                            <TextField
                                type="number"
                                id="complete-value"
                                placeholder="Value"
                                variant="outlined"
                                style={{ width: '27%', margin: '5px' }}
                                size="small"
                                className={classes.countFieldText}
                                InputProps={{ inputProps: { min: 1 } }}
                                onChange={(event) => {
                                    setGoBackCount(event.target.value)
                                }}
                                value={goBackCount}
                            />
                            {(goBackCount !==
                                props.filterData.go_back_count.value ||
                                goBackCountIcon !==
                                    props.filterData.go_back_count.type) && (
                                <Tooltip title="Apply">
                                    <IconButton
                                        component="span"
                                        onClick={() => {
                                            props.setFilterData({
                                                ...props.filterData,
                                                go_back_count: {
                                                    type: goBackCountIcon,
                                                    value: goBackCount,
                                                },
                                            })
                                        }}
                                        style={{ padding: '6px' }}
                                    >
                                        <Check />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {props.filterData.go_back_count.value > '' && (
                                <Tooltip title="Clear">
                                    <IconButton
                                        component="span"
                                        onClick={() => {
                                            setGoBackCountIcon('greater')
                                            setGoBackCount('')
                                            props.setFilterData({
                                                ...props.filterData,
                                                go_back_count: {
                                                    type: 'greater',
                                                    value: '',
                                                },
                                            })
                                        }}
                                        style={{ padding: '6px' }}
                                        className="removeBtn"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Menu
                                id="simple-menu"
                                anchorEl={gobackMenuIconOpen}
                                keepMounted
                                open={Boolean(gobackMenuIconOpen)}
                                onClose={handleCloseGoBack}
                            >
                                <MenuItem
                                    onClick={() => {
                                        setGoBackCountIcon('less')
                                        handleCloseGoBack()
                                    }}
                                >
                                    <ChevronLeftIcon />
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setGoBackCountIcon('greater')
                                        handleCloseGoBack()
                                    }}
                                >
                                    <ChevronRightIcon />
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                    {workspaceUser && (
                        <div style={{ padding: '16px 0' }}>
                            <div style={{ marginBottom: '16px' }}>
                                <Divider />
                            </div>
                            <Typography
                                component="span"
                                className={classes.filterHeader}
                            >
                                Radius
                            </Typography>
                            <Slider
                                value={uiRadius}
                                aria-labelledby="discrete-slider-custom"
                                step={10}
                                valueLabelDisplay="auto"
                                marks={radiusMarks}
                                min={0}
                                max={500}
                                onChange={(_, value) => {
                                    if (typeof value === 'object') {
                                        setUiRadius(0)
                                    } else {
                                        setUiRadius(value)
                                    }
                                }}
                                onChangeCommitted={props.onRadiusChange}
                                className={
                                    props.filterData.radius <= 0
                                        ? classes.sliderDisabled
                                        : ''
                                }
                            />
                        </div>
                    )}
                    <div style={{ padding: '16px 0' }}>
                        <div style={{ marginBottom: '16px' }}>
                            <Divider />
                        </div>
                        <Typography
                            component="span"
                            className={classes.filterHeader}
                        >
                            Willing to Travel
                        </Typography>
                        <RadioGroup
                            aria-label="willingToTravel"
                            name="willingToTravel"
                            value={props.filterData.willingToTravel}
                            className={classes.radioPrimary}
                            onChange={props.handleWilToTravelChange}
                        >
                            <FormControlLabel
                                value="all"
                                control={<Radio />}
                                label="All"
                            />
                            <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                            />
                            <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                            />
                        </RadioGroup>
                        <div style={{ padding: '16px 0' }}>
                            <Divider />
                        </div>
                        <Typography
                            component="span"
                            className={classes.filterHeader}
                        >
                            EZ Turn Trained
                        </Typography>
                        <RadioGroup
                            aria-label="trained"
                            name="trained"
                            value={props.filterData.trained}
                            className={classes.radioPrimary}
                            onChange={props.handleTrainedChange}
                        >
                            <FormControlLabel
                                value="all"
                                control={<Radio />}
                                label="All"
                            />
                            <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                            />
                            <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                            />
                        </RadioGroup>
                        <Divider />
                    </div>
                </form>
            </Container>
            <div style={{ textAlign: 'right', padding: '24px' }}>
                <Button
                    variant="outlined"
                    color="default"
                    fullWidth
                    style={{ marginTop: '10px' }}
                    onClick={() => props.resetFilter()}
                >
                    Reset
                </Button>
            </div>
        </SideDrawer>
    )
}

export default FilterDrawer
