import React, { useRef, useState } from 'react'
import { IconButton, Tooltip, useTheme } from '@material-ui/core'

import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

import { Container, Selector } from '../../../components'
import {
    Area,
    AreaConfig,
    AreaStatusConfig,
    AreaStatusTag,
    Schedule,
} from '../../../models'

import { useAreaStatusTag } from '../../../hooks'
import { Lock } from '@material-ui/icons'
import { InfrastructureFilterOptions, KeysFilterMode } from '../../../store'

interface CustomStyle {
    container?: React.CSSProperties
}

interface Props {
    area: Area
    schedule?: Schedule
    areaConfig?: AreaConfig
    customStyle?: CustomStyle
    areaStatusTag?: AreaStatusTag
    areaStatusConfigList: AreaStatusConfig[]
    vacantAreas?: Area[]
    filter?: InfrastructureFilterOptions
    rightRender?: () => JSX.Element
    isAreaInFilter?: (area: Area) => boolean
}

const _UnitArea = (props: Props) => {
    const {
        area,
        areaConfig,
        isAreaInFilter,
        areaStatusTag,
        areaStatusConfigList,
        schedule,
        vacantAreas,
        filter,
    } = props

    const disabled = false

    const theme = useTheme()

    const anchorEl = useRef<HTMLDivElement>(null)

    const { createAreaStatusTag, editAreaStatusTag } = useAreaStatusTag()

    const handleClick = () => {
        if (!open && !disabled) {
            setOpen(true)
        }
    }

    const [open, setOpen] = useState(false)
    const [selectedAreaStatus, setSelectedAreaStatus] = useState(
        areaStatusTag?.area_status_config.id ?? -1,
    )

    let inFilterIcon: JSX.Element | null = null
    // If the caller passed a function render the correct icon
    if (isAreaInFilter) {
        if (isAreaInFilter(area)) {
            inFilterIcon = (
                <Container
                    style={{ alignItems: 'center', margin: theme.spacing(1) }}
                >
                    <Tooltip title="Matches date filter">
                        <VisibilityIcon fontSize="small" color="primary" />
                    </Tooltip>
                </Container>
            )
        } else {
            inFilterIcon = (
                <Container
                    style={{ alignItems: 'center', margin: theme.spacing(1) }}
                >
                    <Tooltip title="Does not match date filter">
                        <VisibilityOffIcon fontSize="small" color="secondary" />
                    </Tooltip>
                </Container>
            )
        }
    }

    let inFilter = false
    if (filter?.displayVacantAreas) {
        vacantAreas?.map((va) => {
            if (va.id === area.id) {
                inFilter = true
            }
        })
    }

    // if (
    //     filter?.keysFilter !== KeysFilterMode.All &&
    //     areaConfig?.occupiable &&
    //     areaStatusTag?.key
    // ) {
    //     inFilter = true
    // }

    return (
        <Container
            ref={anchorEl}
            style={{
                margin: theme.spacing(0.5, 0.5),
                padding: theme.spacing(0.5),
                ...theme.typography.caption,
                fontWeight: theme.typography.fontWeightLight,
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: theme.shape.borderRadius,
                ...props.customStyle?.container,
                minWidth: MIN_AREA_WIDTH,
                backgroundColor: inFilter ? theme.palette.primary.light : '',
            }}
        >
            {/* Info Column */}
            <Container
                style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: disabled ? 'default' : 'pointer',
                    color: disabled ? theme.palette.grey[600] : 'black',
                    flex: 1,
                }}
                onClick={handleClick}
            >
                <span>
                    {areaConfig?.name} {area.area_label}
                </span>

                <Container
                    style={{ width: '100%', padding: theme.spacing(0, 1) }}
                >
                    <IconButton
                        onClick={(e) => {
                            if (schedule && areaStatusTag) {
                                editAreaStatusTag({
                                    id: areaStatusTag.id,
                                    body: {
                                        locked: !areaStatusTag.locked,
                                    },
                                })
                            }
                            e.stopPropagation()
                        }}
                        size="small"
                    >
                        <Lock
                            fontSize="small"
                            style={{ paddingBottom: theme.spacing(1) }}
                            color={
                                areaStatusTag?.locked ? 'secondary' : undefined
                            }
                        />
                    </IconButton>

                    <div style={{ flex: 1, margin: theme.spacing(0, 0.5) }} />
                    <Selector
                        disabled={!schedule?.active || areaStatusTag?.locked}
                        label=""
                        variant="standard"
                        customStyle={{
                            formControl: { margin: 0, marginBottom: 2 },
                            select: {
                                margin: 0,
                                maxHeight: 20,
                                border: '0px ',
                            },
                            text: {
                                ...theme.typography.caption,
                            },
                        }}
                        data={[
                            {
                                id: -1,
                                apartment: -1,
                                name: '',
                                should_service: true,
                            },
                            ...areaStatusConfigList,
                        ]}
                        currentValue={selectedAreaStatus}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            event.stopPropagation()
                            const newAreaStatusId = event.target.value as number
                            if (schedule) {
                                createAreaStatusTag({
                                    body: {
                                        area: area.id,
                                        schedule: schedule.id,
                                        area_status_config: newAreaStatusId,
                                    },
                                })
                            }
                            setSelectedAreaStatus(newAreaStatusId)
                        }}
                        getDisplayString={(
                            areaStatusConfig: AreaStatusConfig,
                        ) => areaStatusConfig.name}
                        onClick={(e) => e.stopPropagation()}
                    />
                </Container>
            </Container>
            {inFilterIcon}

            {/* Optional Interaction Column */}

            {props.rightRender && props.rightRender()}
        </Container>
    )
}

export const UnitArea = React.memo(_UnitArea)
const MIN_AREA_WIDTH = 100
