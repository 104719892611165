import React, { useEffect, useMemo, useState } from 'react'

import {
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    IconButton,
    Tooltip,
    useTheme,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    FormControlLabel,
    Switch,
} from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'

import { Pagination } from '@material-ui/lab'
import RefreshIcon from '@material-ui/icons/Refresh'
import TuneIcon from '@material-ui/icons/Tune'

import FileDownload from 'js-file-download'

import {
    Container,
    MessageDrawer,
    ScheduleReportPopOver,
} from '../../components'
import {
    Damage,
    DamageConfig,
    DamageStatus,
    Inspection,
    Lease,
    ModelMap,
    Unit,
    getIssueCount,
    traverse,
} from '../../models'
import { DamageRow } from './DamageRow'
import { DamageStatusChangeButtons } from './DamageStatusChangeButtons'
import { toast } from 'react-toastify'
import {
    FinderSelection,
    useAptConfig,
    _useFinderSelection,
    useMessageDrawer,
    usePaginatedDamageList,
    useRootInfrastructure,
    useUser,
    useAppDispatch,
    DamageRequestFilters,
} from '../../hooks'
import { DamageFilterDrawer } from './DamageFilterDrawer'
import {
    FinderLocationSelection,
    FinderSelectionMode,
    RootState,
    getInspectionList,
} from '../../store'
import { DamageFilterChips } from './DamageFilterChips'
import { TenantPopover } from './TenantPopover'
import { axiosInstance, currencyFormatter, isAccountManager } from '../../helpers'
import { useSelector } from 'react-redux'

const PAGE_SIZE = 25

interface Props {
    damageConfigList?: DamageConfig[]
}

export enum HasIssuesFilter {
    ISSUES = 'ISSUES',
    NO_ISSUES = 'NO_ISSUES',
    NO_FILTER = 'NO_FILTER',
}

export type HasIssuesFilterType = `${HasIssuesFilter}`
export interface DamageFilter {
    status: DamageStatus[]
    modified: boolean
    hasIssues: HasIssuesFilterType
    inspection: number
    created_date_sort: boolean
    created_before: Date
    created_after: Date
}

const oneYearAgo = new Date()
oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)

export const defaultDamageFilter: DamageFilter = {
    status: [DamageStatus.PENDING],
    modified: false,
    hasIssues: HasIssuesFilter.NO_FILTER,
    inspection: -1,
    created_date_sort: false,
    created_before: new Date(),
    created_after: oneYearAgo,
}

export const DamageList = (props: Props) => {
    const { damageConfigList } = props

    const dispatch = useAppDispatch()

    const [damageFilterState, setDamageFilterState] = useState(
        defaultDamageFilter,
    )
    const [damageCost, setDamageCost] = useState(0)

    const inspectionList = useSelector(
        (state: RootState) => state.inspection.inspectionList,
    )

    const appliedFinderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.PrunedSelection,
        selectionMode: FinderSelectionMode.Recursive,
    })

    const [
        tenantPopoverAnchorEl,
        setTenantPopoverAnchorEl,
    ] = React.useState<HTMLElement | null>(null)

    const [
        reportPopoverAnchorEl,
        setReportPopoverAnchorEl,
    ] = React.useState<HTMLElement | null>(null)

    const openTenantPopover = (
        event: React.MouseEvent<HTMLElement>,
        damageId: number,
    ) => {
        setSelectedDamageId(damageId)
        setTenantPopoverAnchorEl(event.currentTarget)
    }

    const closeTenantPopover = () => {
        setTenantPopoverAnchorEl(null)
    }

    const openReportPopover = (event: React.MouseEvent<HTMLElement>) => {
        setReportPopoverAnchorEl(event.currentTarget)
    }

    const closeReportPopover = () => {
        setReportPopoverAnchorEl(null)
    }

    const reportPopoverOpen = Boolean(reportPopoverAnchorEl)
    const tenantPopOverOpen = Boolean(tenantPopoverAnchorEl)

    const {
        damageList,
        getDamageList,
        loadingDamages,
        setPage,
        currentPage,
        totalDamageCount,
        updateDamageStatus,
        removeDamageTransaction,
        addDamageTransaction,
        updateDamageState,
        updateDamage,
    } = usePaginatedDamageList(
        PAGE_SIZE,
        (res, damageList) => {
            setSelectedState(damageList?.map(() => false) ?? [])
        },
        (e) => toast.error(e.message),
    )

    const { tree } = useRootInfrastructure(true)

    const [selectedState, setSelectedState] = useState<boolean[]>([])
    const [openMessageDamageId, setOpenMessageDamageId] = useState(-1)
    const [filterOpen, setFilterOpen] = useState(false)
    const [selectedDamageId, setSelectedDamageId] = useState<number>(-1)
    const [dialogOpen, setDialogOpen] = useState(false)

    const [arTransactionErrorMode, setArTransactionErrorMode] = useState(false)

    const { workspaceUser } = useUser()
    const { setChannel } = useMessageDrawer()

    const theme = useTheme()

    let intermediate = false
    let isChecked = selectedState[0]
    for (let i = 1; i < selectedState.length; i++) {
        isChecked = isChecked || selectedState[i]
        if (selectedState[i] !== selectedState[i - 1]) {
            intermediate = true
        }
    }

    const selectedCount = selectedState.reduce((checkedCount, current) => {
        return (checkedCount += current ? 1 : 0)
    }, 0)

    const onOpenMessageDrawer = (channelId: number, damageId: number) => {
        setChannel(channelId)
        setOpenMessageDamageId(damageId)
    }

    const getFilterParams = (
        filter: DamageFilter,
        finderSelection?: FinderSelection,
    ): DamageRequestFilters => {
        let unitIdString: string | undefined

        if (finderSelection) {
            unitIdString = ''
            Object.keys(finderSelection.selection.unit).forEach((unitKey) => {
                // Exclude the "length" property
                if (unitKey === 'length') {
                    return
                }

                // add the unit id if it is selected in the finder
                const unitKeyNumber = parseInt(unitKey)
                if (
                    !isNaN(unitKeyNumber) &&
                    finderSelection.selection.unit[unitKeyNumber]
                ) {
                    unitIdString += `${unitKey},`
                }
            })

            // Remove the trailing comma
            if (unitIdString.length > 0) {
                unitIdString = unitIdString.substring(
                    0,
                    unitIdString.length - 1,
                )
            }
        }

        if (unitIdString === '') {
            unitIdString = undefined
        }

        let statusString: string | undefined
        if (filter.status.length > 0) {
            statusString = ''
            filter.status.forEach((status) => {
                statusString += `${status},`
            })
            if (statusString.length > 0) {
                statusString = statusString.substring(
                    0,
                    statusString.length - 1,
                )
            }
        }

        let issues: boolean | undefined
        if (filter.hasIssues === HasIssuesFilter.ISSUES) {
            issues = true
        }
        if (filter.hasIssues === HasIssuesFilter.NO_ISSUES) {
            issues = false
        }

        let inspection: number | undefined
        if (filter.inspection !== -1) {
            inspection = filter.inspection
        }

        let sort_by_created_date: boolean | undefined
        if (filter.created_date_sort) {
            sort_by_created_date = true
        }

        return {
            excludeDenied: true,
            unitIds: unitIdString,
            statuses: statusString,
            issues: issues,
            inspection: inspection,
            sort_by_created_date: sort_by_created_date,
            created_before: filter.created_before.toISOString(),
            created_after: filter.created_after.toISOString(),
        }
    }

    const getFilteredDamages = (
        filter: DamageFilter,
        page: number,
        finderSelection?: FinderSelection,
    ) => {
        const params = getFilterParams(filter, finderSelection)
        axiosInstance.get('lease/damage/get_total_cost/', {params: params}).then((res) => { setDamageCost(res.data)})

        getDamageList(page, params)
    }

    const applyFilter = (
        filter: DamageFilter,
        finderSelection?: FinderSelection,
        page?: number,
    ) => {
        // finderSelection should be undefined when clearing the filter
        if (finderSelection === undefined) {
            appliedFinderSelection.setFinderSelection(undefined)
        }

        // reset the page back to 1
        let newPage = 1
        if (page !== undefined) {
            newPage = page
        }
        setPage(newPage)

        // Make network request
        getFilteredDamages(filter, newPage, finderSelection)
        

        // make sure the modification state is set to false
        setDamageFilterState({ ...filter, modified: false })

        // close the filter drawer
        setFilterOpen(false)
    }

    useEffect(() => {
        dispatch(getInspectionList({ params: {} }))
        getFilteredDamages(damageFilterState, 1)
    }, [])

    const filteredDamages = useMemo(() => {
        const today = new Date()
        const endCutoff = new Date()
        endCutoff.setDate(endCutoff.getDate() - 122)
        console.info(today, endCutoff)
        return (
            damageList?.filter((dmg) => {
                if (arTransactionErrorMode) {
                    if (dmg.damage_transactions.length === 0) {
                        // Damages without any transactions probably need to be given transactions by the gm
                        return true
                    }

                    let hasIssue = false
                    dmg.damage_transactions.forEach((arTransaction) => {
                        const startDate = new Date(
                            arTransaction.lease.start_date,
                        )
                        const endDate = new Date(arTransaction.lease.end_date)

                        // If the lease has not started it should not be associated with a damage
                        if (startDate > today) {
                            hasIssue = true
                        }

                        // If the lease ended more than 4 months ago it should probably not be associated with a damage
                        if (endDate < endCutoff) {
                            hasIssue = true
                        }
                    })

                    return hasIssue
                }
                return true
            }) ?? []
        )
    }, [damageList, arTransactionErrorMode, damageFilterState.hasIssues])

    const { getInventoryConfigMap, getAreaConfigMap } = useAptConfig({
        inventoryConfigList: true,
        areaConfigList: true,
    })

    const inventoryConfigMap = getInventoryConfigMap()
    const areaConfigMap = getAreaConfigMap()

    const [unitMap, setUnitMap] = useState<ModelMap<Unit>>({})

    useEffect(() => {
        const newUnitMap: ModelMap<Unit> = {}

        traverse(tree.root, (folder) => {
            folder.units.forEach((u) => {
                newUnitMap[u.id] = u
            })
        })
        setUnitMap(newUnitMap)
    }, [tree.version])

    return (
        <>
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                }}
                scrollY
            >
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        padding: theme.spacing(1),
                    }}
                    scrollY
                >
                    <Container style={{ alignItems: 'center', height: 50 }}>
                        <Checkbox
                            checked={isChecked === true}
                            onChange={() => {
                                setSelectedState(
                                    selectedState.map(() => !isChecked),
                                )
                            }}
                            indeterminate={intermediate}
                        />

                        <span
                            style={{
                                ...theme.typography.body1,
                                marginLeft: theme.spacing(1),
                                marginRight: theme.spacing(1),
                            }}
                        >
                            {selectedCount > 0
                                ? `${selectedCount} Selected`
                                : ''}
                        </span>

                        {selectedCount > 0 && (
                            <DamageStatusChangeButtons
                                damages={filteredDamages?.filter((_, idx) => {
                                    return selectedState[idx]
                                })}
                                handleStatusChange={updateDamageStatus}
                                workspaceUser={workspaceUser}
                            />
                        )}

                        {isAccountManager(workspaceUser) && selectedCount > 0 && (
                            <Button
                                color="secondary"
                                variant="outlined"
                                style={{ marginRight: theme.spacing(1) }}
                                onClick={() => {
                                    setDialogOpen(true)
                                }}
                            >
                                Reset to Pending
                            </Button>
                        )}

                        {damageFilterState.modified && (
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={() =>
                                    applyFilter(
                                        damageFilterState,
                                        appliedFinderSelection,
                                    )
                                }
                                style={{ marginRight: theme.spacing(1) }}
                            >
                                Apply Filter Changes
                            </Button>
                        )}

                        <DamageFilterChips
                            damageFilter={damageFilterState}
                            finderSelection={appliedFinderSelection}
                            inspectionList={inspectionList ?? []}
                            setDamageFilter={setDamageFilterState}
                        />

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={arTransactionErrorMode}
                                    color="primary"
                                    onChange={() => {
                                        setSelectedState((oldState) => {
                                            return oldState.map(() => false)
                                        })
                                        setArTransactionErrorMode((v) => !v)
                                    }}
                                />
                            }
                            labelPlacement="top"
                            label="Find transaction issues"
                        />

                        <div style={{ flex: 1 }} />
                        <span style={{

                            textAlign: 'center',
                            ...theme.typography.subtitle1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}>
                            Total Damage Cost: {currencyFormatter.format(damageCost)}
                        </span>
                        {loadingDamages ? (
                            <CircularProgress />
                        ) : (
                            <Tooltip title={'Refresh Damages'}>
                                <IconButton
                                    onClick={() =>
                                        applyFilter(
                                            damageFilterState,
                                            appliedFinderSelection,
                                            currentPage,
                                        )
                                    }
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                        )}


                        <Tooltip
                            title="Reports"
                            style={{ marginLeft: theme.spacing(4) }}
                        >
                            <IconButton onClick={openReportPopover}>
                                <FileCopyIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip
                            title="Filter"
                            style={{ marginLeft: theme.spacing(4) }}
                        >
                            <IconButton
                                onClick={() => setFilterOpen(!filterOpen)}
                            >
                                <TuneIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Container>

                    <Divider />
                    <Container
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                        }}
                        scrollY
                    >
                        {filteredDamages?.map((damage, idx) => {
                            return (
                                <DamageRow
                                    displayProblems={arTransactionErrorMode}
                                    key={`DAMAGE_${damage.id}`}
                                    damage={damage}
                                    selected={selectedState[idx] === true}
                                    damageConfigList={damageConfigList}
                                    messageDrawerOpen={
                                        openMessageDamageId === damage.id
                                    }
                                    onSelect={() => {
                                        const newSelectedState = [
                                            ...selectedState,
                                        ]
                                        newSelectedState[
                                            idx
                                        ] = !newSelectedState[idx]
                                        setSelectedState(newSelectedState)
                                    }}
                                    handleStatusChange={updateDamageStatus}
                                    removeDamageTransaction={
                                        removeDamageTransaction
                                    }
                                    onOpenMessageDrawer={onOpenMessageDrawer}
                                    workspaceUser={workspaceUser}
                                    updateDamage={updateDamage}
                                    openPopover={openTenantPopover}
                                    invConfigMap={inventoryConfigMap}
                                    areaConfigMap={areaConfigMap}
                                    unitMap={unitMap}
                                />
                            )
                        })}
                    </Container>
                </Container>
                <Divider />

                <Container
                    style={{
                        minHeight: 50,
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: theme.spacing(1),
                    }}
                >
                    <Pagination
                        count={Math.ceil(totalDamageCount / PAGE_SIZE)}
                        page={currentPage}
                        onChange={(e, value) => {
                            setPage(value)
                            getFilteredDamages(
                                damageFilterState,
                                value,
                                appliedFinderSelection,
                            )
                        }}
                    />

                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                            marginLeft: theme.spacing(2),
                        }}
                    >
                        {(currentPage - 1) * PAGE_SIZE + 1} -{' '}
                        {currentPage * PAGE_SIZE} of {totalDamageCount}
                        {/* {totalDamageCount} Damages */}
                    </span>
                </Container>
            </Container>
            <MessageDrawer
                open={openMessageDamageId > 0}
                onClose={() => setOpenMessageDamageId(-1)}
                onCreateMessage={(message) => {
                    setOpenMessageDamageId(-1)
                    const updatedDamage = filteredDamages?.find(
                        (dmg) => dmg.id === openMessageDamageId,
                    )

                    if (updatedDamage) {
                        updateDamageState([
                            {
                                ...updatedDamage,
                                message_count: updatedDamage.message_count + 1,
                                attachments: [
                                    ...updatedDamage.attachments,
                                    ...message.attachments,
                                ],
                            },
                        ])
                    }
                }}
            />
            <DamageFilterDrawer
                open={filterOpen}
                onClose={() => setFilterOpen(false)}
                damageFilterState={damageFilterState}
                setDamageFilterState={setDamageFilterState}
                tree={tree.root}
                selection={appliedFinderSelection}
                onApplyFilter={applyFilter}
                inspectionList={
                    inspectionList?.filter((ins) => ins.active) ?? []
                }
            />

            <TenantPopover
                open={tenantPopOverOpen}
                anchor={tenantPopoverAnchorEl}
                onClose={() => closeTenantPopover()}
                damage={filteredDamages?.find(
                    (dmg) => dmg.id === selectedDamageId,
                )}
                addDamageTransaction={addDamageTransaction}
            />

            <ScheduleReportPopOver
                open={reportPopoverOpen}
                onClose={closeReportPopover}
                anchorEl={reportPopoverAnchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                reportLoading={false}
                reportItems={[
                    {
                        title: 'Excel Report',
                        downloadLink: {
                            title: 'Download',
                            onClick: () => {
                                const params = getFilterParams(
                                    damageFilterState,
                                    appliedFinderSelection,
                                )
                                axiosInstance
                                    .get('lease/damage/transaction_report/', {
                                        params: {
                                            statuses: params?.statuses,
                                            unitIds: params?.unitIds,
                                            issues: params?.issues,
                                            inspection: params?.inspection,
                                        },
                                    })
                                    .then(() => {
                                        toast.success(
                                            'The report will be sent to your email!',
                                        )
                                    })
                            },
                        },
                    },
                    {
                        title: 'PDF Report',
                        downloadLink: {
                            title: 'Download',
                            onClick: () => {
                                const params = getFilterParams(
                                    damageFilterState,
                                    appliedFinderSelection,
                                )
                                axiosInstance
                                    .get('lease/damage/bulk_pdf_report/', {
                                        params: {
                                            statuses: params?.statuses,
                                            unitIds: params?.unitIds,
                                            issues: params?.issues,
                                            inspection: params?.inspection,
                                            tz_offset: new Date().getTimezoneOffset(),
                                        },
                                    })
                                    .then(() => {
                                        toast.success(
                                            'You Will Recieve an email when the report is done',
                                        )
                                    })
                            },
                        },
                    },
                ]}
            />

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle id="alert-dialog-title">
                    Reset the damage status?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please confirm that you want to reset {selectedCount}{' '}
                        damages back to pending.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                            const damages = filteredDamages?.filter(
                                (_, idx) => {
                                    return selectedState[idx]
                                },
                            )
                            updateDamageStatus(
                                damages ?? [],
                                DamageStatus.PENDING,
                            )
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
