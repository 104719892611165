import {
    Button,
    Divider,
    IconButton,
    Modal,
    Paper,
    Slide,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from '../../components'
import CloseIcon from '@material-ui/icons/Close'

interface Props {
    open: boolean
    handleClose: () => void
    subject: string
    body: string
}

export const ShareModal = (props: Props) => {
    const { open, handleClose, subject, body } = props

    const [sendEmail, setSendEmail] = useState('')

    const theme = useTheme()

    useEffect(() => {
        setSendEmail('')
    }, [handleClose])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: '800px',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <Container
                        style={{ margin: theme.spacing(3) }}
                        direction="column"
                    >
                        <Container
                            style={{
                                flex: 1,
                                alignItems: 'center',
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: 500,
                                    fontSize: '20px',
                                }}
                            >
                                Share
                            </span>
                            <div style={{ flex: 1 }} />
                            <Tooltip title="Cancel">
                                <IconButton onClick={handleClose}>
                                    <CloseIcon
                                        fontSize="default"
                                        style={{
                                            marginRight: theme.spacing(1),
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Container>
                        <Divider
                            style={{ margin: theme.spacing(0, 3, 0, 0) }}
                        />
                        <Container
                            style={{
                                marginTop: theme.spacing(2),
                                color: theme.palette.darkGreen.main,
                                fontWeight: 400,
                                fontSize: '14px',
                                flex: 1,
                            }}
                        >
                            Please enter the details below!
                        </Container>
                        <Container
                            style={{
                                marginTop: theme.spacing(2),
                            }}
                            direction="column"
                        >
                            <span
                                style={{
                                    fontWeight: 400,
                                    fontSize: '12px',
                                }}
                            >
                                Email address:
                            </span>
                            <Container style={{ flex: 1 }}>
                                <TextField
                                    variant="outlined"
                                    value={sendEmail}
                                    onChange={(e) =>
                                        setSendEmail(e.target.value)
                                    }
                                    size="small"
                                    style={{
                                        flex: 1,
                                        marginTop: theme.spacing(1),
                                    }}
                                />
                                <div style={{ flex: 1 }} />
                            </Container>
                        </Container>
                        <Container
                            style={{
                                flex: 1,
                                marginTop: theme.spacing(4),
                            }}
                        >
                            <div style={{ flex: 1 }} />
                            <Button
                                color="secondary"
                                style={{
                                    marginRight: theme.spacing(1),
                                    textTransform: 'none',
                                }}
                                variant="outlined"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                style={{
                                    textTransform: 'none',
                                    backgroundColor: theme.palette.primary.dark,
                                    color: 'white',
                                }}
                                onClick={() => {
                                    window.open(
                                        `mailto:${sendEmail}?subject=${subject}&body=${body}`,
                                    )
                                    handleClose()
                                }}
                            >
                                Send
                            </Button>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
