import { AxiosError } from 'axios'
import { ErrorResponse } from '../types'

import { Action } from 'redux'

// actions
export const SET_NETWORK_ERROR = 'SET_NETWORK_ERROR'
export const SET_ERROR = 'SET_ERROR'

interface SetNetworkErrorAction extends Action {
    type: typeof SET_NETWORK_ERROR
    payload: AxiosError<ErrorResponse>
    display: boolean
}

export type ErrorActionTypes = SetNetworkErrorAction

// reducers
export interface ErrorState {
    error?: ErrorResponse
    networkError?: AxiosError<ErrorResponse>
    display: boolean
}
