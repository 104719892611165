import React, { useEffect, useState } from 'react'
import { Button, Modal, Paper, Slide, Theme } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import { Container } from '../Container'
import { SearchField } from '../SearchField'
import { axiosInstance, isEmailValid } from '../../helpers'
import { User } from '../../models'

interface Props {
    theme: Theme
    open: boolean
    onClose: () => void
}

export const UserModal = (props: Props) => {
    const { theme, open, onClose } = props

    const [searchEmail, setSearchEmail] = useState('')
    const [doValidate, setDoValidate] = useState(false)
    const [user, setUser] = useState<User | null>(null)
    const [error, setError] = useState('')

    const trimmedEmail = searchEmail.trim().toLocaleLowerCase()

    const handleSearch = (email: string) => {
        const trimmedEmail = email.trim().toLocaleLowerCase()

        axiosInstance
            .post('user/find_user_by_email/', {
                email: trimmedEmail,
            })
            .then((res) => {
                const user: User = res.data.user
                setUser(user)
            })
            .catch(() => {
                setError('No users found with that email.')
            })
    }

    useEffect(() => {
        if (!open) {
            setSearchEmail('')
            setDoValidate(false)
            setUser(null)
        }
    }, [open])

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        borderRadius: theme.shape.borderRadius,
                        maxHeight: 'calc(100vh - 100px)',
                        minHeight: 'calc(100vh - 100px)',
                        maxWidth: 'calc(100vw - 100px)',
                        minWidth: 'calc(100vw - 100px)',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                    }}
                >
                    <Container
                        style={{
                            flexDirection: 'column',
                        }}
                    >
                        <Container
                            style={{
                                flexDirection: 'column',
                                justifyContent: 'center',
                                display: 'flex',
                                margin: theme.spacing(2),
                                height: '100%',
                                padding: theme.spacing(1),
                            }}
                        >
                            <Container
                                style={{
                                    flexDirection: 'column',
                                }}
                            >
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: theme.palette.grey[800],
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        ...theme.typography.h6,
                                        marginBottom: theme.spacing(2),
                                    }}
                                >
                                    Search for a user by email
                                </span>
                                <Container direction="row">
                                    <SearchField
                                        value={searchEmail}
                                        error={
                                            doValidate &&
                                            !isEmailValid(trimmedEmail)
                                        }
                                        placeholder="User Email"
                                        onChange={(e) =>
                                            setSearchEmail(e.target.value)
                                        }
                                        helperText={
                                            doValidate &&
                                            !isEmailValid(trimmedEmail)
                                                ? 'Invalid Email'
                                                : ''
                                        }
                                        size="small"
                                        variant="outlined"
                                        type="text"
                                        style={{
                                            margin: theme.spacing(0, 1, 0, 0),
                                        }}
                                        fullWidth
                                    />
                                    <Button
                                        variant="outlined"
                                        style={{
                                            textTransform: 'none',
                                            backgroundColor:
                                                theme.palette.primary.dark,
                                            color: 'white',
                                            margin: theme.spacing(0, 1, 0, 1),
                                            height: 40,
                                        }}
                                        onClick={() => {
                                            setDoValidate(true)
                                            if (isEmailValid(trimmedEmail)) {
                                                handleSearch(trimmedEmail)
                                            }
                                        }}
                                    >
                                        <ArrowForwardIcon />
                                    </Button>
                                </Container>
                            </Container>
                            {user ? (
                                <Container
                                    style={{
                                        margin: theme.spacing(2, 0, 2, 0),
                                        flexDirection: 'column',
                                        overflowY: 'scroll',
                                        height: '100%',
                                    }}
                                >
                                    <Container
                                        style={{
                                            margin: theme.spacing(1, 0, 1, 0),
                                            width: '50%',
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                            ...theme.typography.h6,
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: theme.palette.grey[800],
                                            }}
                                        >
                                            {`User: ${user.workspaces[0].name}`}
                                        </span>
                                    </Container>

                                    <span
                                        style={{
                                            color: theme.palette.grey[800],
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                            ...theme.typography.h6,
                                        }}
                                    >
                                        Workspaces: {user.workspaces.length}
                                    </span>

                                    {user.workspaces &&
                                        user.workspaces.map((workspace) => (
                                            <div key={workspace.id}>
                                                <Container
                                                    style={{
                                                        flexDirection: 'column',
                                                        border: `3px solid ${theme.palette.primary.dark}`,
                                                        color: 'white',
                                                        backgroundColor:
                                                            theme.palette
                                                                .primary.dark,
                                                        borderRadius:
                                                            theme.shape
                                                                .borderRadius,
                                                        padding: theme.spacing(
                                                            1,
                                                        ),
                                                        margin: theme.spacing(
                                                            1,
                                                            0,
                                                            1,
                                                            0,
                                                        ),
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight:
                                                                theme.typography
                                                                    .fontWeightBold,
                                                            ...theme.typography
                                                                .h6,
                                                        }}
                                                    >
                                                        {
                                                            workspace
                                                                .active_workspace
                                                                .name
                                                        }
                                                    </span>
                                                    <span>
                                                        {
                                                            workspace
                                                                .active_workspace
                                                                .address
                                                        }
                                                    </span>
                                                    <span>
                                                        {`${workspace.active_workspace.city},

                                                            ${workspace.active_workspace.state}`}
                                                    </span>
                                                    <span>
                                                        {
                                                            workspace
                                                                .active_workspace
                                                                .zip_code
                                                        }
                                                    </span>
                                                    {workspace.active_workspace
                                                        .company_type ===
                                                    'APARTMENT' ? (
                                                        <span
                                                            style={{
                                                                marginTop: theme.spacing(
                                                                    1,
                                                                ),
                                                                fontWeight:
                                                                    theme
                                                                        .typography
                                                                        .fontWeightBold,
                                                            }}
                                                        >
                                                            Apartment
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{
                                                                marginTop: theme.spacing(
                                                                    1,
                                                                ),
                                                                fontWeight:
                                                                    theme
                                                                        .typography
                                                                        .fontWeightBold,
                                                            }}
                                                        >
                                                            Vendor
                                                        </span>
                                                    )}
                                                </Container>
                                            </div>
                                        ))}
                                </Container>
                            ) : (
                                <Container
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        ...theme.typography.h6,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        color: theme.palette.grey[800],
                                        height: '100%',
                                    }}
                                >
                                    {error}
                                </Container>
                            )}
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
