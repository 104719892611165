import { useTheme } from '@material-ui/core'
import { InspectionTypeGrades } from '../../../hooks/useAnalyticData'
import React, { useState } from 'react'
import { Container } from '../../../components'
import { useStyles } from '../../../styles'
import {
    ALL,
    CUSTOM,
    DateFilter,
    LAST_MONTH,
    THIS_MONTH,
} from '../../../components/DateFilterButtons'
import { getPreviousMonthDates } from '../../../helpers'
import { Range } from 'react-date-range'

interface Props {
    inspectionTypeGrade: InspectionTypeGrades
    dateFilter: DateFilter
    timeline: Range
    selectedInspectionType: InspectionTypeGrades | null
    setSelectedInspectionType: (
        inspectionTypeGrades: InspectionTypeGrades,
    ) => void
    getInspectionDetailGrades: (
        apartmentId: number,
        inspection_type_id: number,
        timeline?: { startDate?: Date; endDate?: Date },
    ) => void
    setOpenInspectionDetailModal: (open: boolean) => void
}

export const InspectionTypeGradeRow = (props: Props) => {
    const {
        inspectionTypeGrade,
        dateFilter,
        timeline,
        selectedInspectionType,
        setSelectedInspectionType,
        getInspectionDetailGrades,
        setOpenInspectionDetailModal,
    } = props

    const theme = useTheme()
    const classes = useStyles()

    const cellStyle: React.CSSProperties = {
        ...theme.typography.subtitle1,
        fontWeight: 400,
        margin: theme.spacing(0, 2, 0, 1),
        whiteSpace: 'nowrap',
        width: theme.spacing(15),
        justifyContent: 'right',
        paddingRight: theme.spacing(4),
        flex: 1,
    }

    const [popoverOpen, setPopoverOpen] = useState(false)
    const getTimeline = () => {
        if (dateFilter === ALL) {
            return null
        } else if (dateFilter === LAST_MONTH) {
            return getPreviousMonthDates()
        } else if (dateFilter === THIS_MONTH) {
            const today = new Date()
            const startOfMonth = new Date(
                today.getFullYear(),
                today.getMonth(),
                1,
            )
            const endOfMonth = new Date(
                today.getFullYear(),
                today.getMonth() + 1,
                0,
            )
            return { startDate: startOfMonth, endDate: endOfMonth }
        } else if (dateFilter === CUSTOM) {
            return timeline
        }
    }

    const isSelected =
        inspectionTypeGrade.inspection_type_id ===
        selectedInspectionType?.inspection_type_id

    return (
        <Container
            style={{
                borderBottom: '1px solid',
                borderColor: theme.palette.grey[400],
                flexDirection: 'row',
                cursor: 'pointer',
                backgroundColor: isSelected ? theme.palette.grey[200] : '',
            }}
            // alignItems="center"
            className={classes.hoverShadow}
            onClick={() => {
                if (!popoverOpen) {
                    const dateRange = getTimeline()
                    if (dateRange) {
                        getInspectionDetailGrades(
                            inspectionTypeGrade.apartment_id,
                            inspectionTypeGrade.inspection_type_id,
                            dateRange,
                        )
                    } else {
                        getInspectionDetailGrades(
                            inspectionTypeGrade.apartment_id,
                            inspectionTypeGrade.inspection_type_id,
                        )
                    }
                    setOpenInspectionDetailModal(true)
                    setSelectedInspectionType(inspectionTypeGrade)
                }
            }}
        >
            <Container
                style={{
                    ...cellStyle,
                    justifyContent: 'left',
                }}
            >
                {inspectionTypeGrade.inspection_type_name}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {inspectionTypeGrade.type_weight.toLocaleString(undefined, {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3,
                })}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {inspectionTypeGrade.flags.toLocaleString()}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {inspectionTypeGrade.potential_flags.toLocaleString()}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {inspectionTypeGrade.units_inspected.toLocaleString()}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {inspectionTypeGrade.units_requiring_inspection.toLocaleString()}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {inspectionTypeGrade.building_score.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                })}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {inspectionTypeGrade.inspector_score.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                })}
            </Container>
            <Container
                style={{
                    ...cellStyle,
                }}
            >
                {inspectionTypeGrade.type_score.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                })}
            </Container>
        </Container>
    )
}
