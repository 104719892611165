import React, { useState } from 'react'

import {
    Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    IconButton,
    Modal,
    Paper,
    Slide,
    Switch,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { Container } from '../../../../components'
import {
    Area,
    AreaConfig,
    AreaStatusConfig,
    AreaStatusTag,
    BaseWorkorder,
    IdBoolMap,
    Lease,
    ListVendor,
    ModelListMap,
    ModelMap,
    Schedule,
    Service,
    UnitConfig,
} from '../../../../models'
import { FinderSelection, useDateRange } from '../../../../hooks'
import {
    AssignUnitDict,
    CreateWorkorderActionThunk,
    CreateWorkOrderRequest,
} from '../../../../store'
import { ChooseServiceAreas } from './ChooseServiceAreas'
import { SelectDetails } from './SelectDetails'
import { toast } from 'react-toastify'
import { VersionedTree } from '../../../../hooks/useInfrastructure/types'

interface Props {
    open: boolean
    filteredTree: VersionedTree
    unitConfigMap: ModelMap<UnitConfig>
    areaConfigMap: ModelMap<AreaConfig>
    unitWorkorderMap: ModelListMap<BaseWorkorder>
    areaLeaseMap: ModelListMap<Lease>
    serviceList?: Service[]
    vendorList?: ListVendor[]
    schedule?: Schedule
    serviceAreaMap: IdBoolMap
    assignmentFinderSelection: FinderSelection
    areaStatusTagMap: ModelMap<AreaStatusTag>
    areaStatusConfigList: AreaStatusConfig[]
    createWorkorder: (req: CreateWorkOrderRequest) => CreateWorkorderActionThunk
    isAreaInFilter: (area: Area) => boolean
    onClose: () => void
}

export const SchedulerAssignModal = (props: Props) => {
    const {
        open,
        onClose,
        filteredTree,
        serviceList,
        unitConfigMap,
        unitWorkorderMap,
        areaLeaseMap,
        schedule,
        areaConfigMap,
        vendorList,
        serviceAreaMap,
        createWorkorder,
        assignmentFinderSelection,
        isAreaInFilter,
        areaStatusTagMap,
    } = props
    const RANGE_KEY = 'ASSIGN_DATE_RANGE'
    const theme = useTheme()
    const MARGIN = theme.spacing(4)
    const MODAL_HEIGHT = window.innerHeight - 2 * MARGIN
    const MODAL_WIDTH = window.innerWidth - 2 * MARGIN
    const HEADER_FOOTER_HEIGTH = 64

    const [includeDates, setIncludeDates] = useState(true)

    const { dateRange, setRange } = useDateRange(
        RANGE_KEY,
        includeDates ? theme.palette.primary.main : theme.palette.grey[500],
        true,
    )

    const [selectedServiceId, setSelectedServiceId] = useState(-1)
    const [selectedVendorId, setSelectedVendorId] = useState(-1)

    const [subToBedStatus, setSubToBedStatus] = useState(true)
    const [createLoading, setCreateLoading] = useState(false)

    const [areaSelection, setAreaSelection] = useState<IdBoolMap>({})

    return (
        <Modal open={open} onClose={onClose} keepMounted>
            <Slide in={open} direction="up">
                <Paper style={{ marginLeft: MARGIN, marginRight: MARGIN }}>
                    <Container
                        style={{
                            height: MODAL_HEIGHT,
                            width: MODAL_WIDTH,
                            marginTop: MARGIN,
                            marginBottom: MARGIN,
                            flexDirection: 'column',
                        }}
                    >
                        {/* Modal Header */}
                        <Container
                            style={{
                                padding: theme.spacing(1),
                                height: HEADER_FOOTER_HEIGTH,
                                alignItems: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h5,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Assign Services
                            </span>

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={subToBedStatus}
                                        color="primary"
                                        onChange={() => {
                                            setSubToBedStatus(!subToBedStatus)
                                        }}
                                    />
                                }
                                labelPlacement="end"
                                label={
                                    <span
                                        style={{
                                            ...theme.typography.subtitle2,
                                        }}
                                    >
                                        Subscribe to bed status changes
                                    </span>
                                }
                            />

                            <div style={{ flex: 1 }} />

                            <Tooltip title="Cancel">
                                <IconButton onClick={onClose}>
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </Container>
                        <Divider />

                        {/* Modal Body */}
                        <Container style={{ flex: 1 }} scrollY>
                            <SelectDetails
                                prunedTree={filteredTree.root}
                                assignmentFinderSelection={
                                    assignmentFinderSelection
                                }
                                unitWorkorderMap={unitWorkorderMap}
                                includeDates={includeDates}
                                setIncludeDates={setIncludeDates}
                                dateRange={dateRange}
                                setDateRange={setRange}
                                serviceList={serviceList}
                                selectedServiceId={selectedServiceId}
                                setSelectedServiceId={setSelectedServiceId}
                                rangeKey={RANGE_KEY}
                                vendorList={vendorList}
                                selectedVendorId={selectedVendorId}
                                setSelectedVendorId={setSelectedVendorId}
                                clearLocationSelection={() =>
                                    assignmentFinderSelection.setFinderSelection()
                                }
                                areaConfigMap={areaConfigMap}
                                areaStatusTagMap={areaStatusTagMap}
                            />

                            {/* Column 3 */}
                            <ChooseServiceAreas
                                areaStatusTagMap={props.areaStatusTagMap}
                                areaStatusConfigList={
                                    props.areaStatusConfigList
                                }
                                assignmentFinderSelection={
                                    assignmentFinderSelection
                                }
                                unitConfigMap={unitConfigMap}
                                unitWorkorderMap={unitWorkorderMap}
                                areaLeaseMap={areaLeaseMap}
                                schedule={schedule}
                                areaConfigMap={areaConfigMap}
                                serviceAreaMap={serviceAreaMap}
                                isAreaInFilter={isAreaInFilter}
                                assignTimeline={{
                                    startDate:
                                        dateRange.startDate ?? new Date(),
                                    endDate: dateRange.endDate ?? new Date(),
                                }}
                                includeTimeline={includeDates}
                                useLeasesToCalculateServiceArea={false}
                                areaSelection={areaSelection}
                                setAreaSelection={setAreaSelection}
                            />
                        </Container>
                        <Divider />
                        {/* Modal Footer */}

                        <Container
                            style={{
                                height: HEADER_FOOTER_HEIGTH,
                                padding: theme.spacing(1),
                            }}
                        >
                            <div style={{ flex: 1 }} />

                            {createLoading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                        if (
                                            dateRange.startDate &&
                                            dateRange.endDate &&
                                            schedule
                                        ) {
                                            const startDateStr = includeDates
                                                ? dateRange.startDate.toISOString()
                                                : undefined
                                            const endDateStr = includeDates
                                                ? dateRange.endDate.toISOString()
                                                : undefined

                                            const assignUnits: AssignUnitDict[] = []
                                            const unitKeys = Object.keys(
                                                assignmentFinderSelection
                                                    .selection.unit,
                                            )
                                            unitKeys.forEach((unitKey) => {
                                                if (unitKey === 'length') {
                                                    return
                                                }

                                                const unit =
                                                    assignmentFinderSelection
                                                        .selection.unit[
                                                        Number(unitKey)
                                                    ]

                                                if (unit === undefined) return

                                                const assignUnitDict: AssignUnitDict = {
                                                    unit_id: unit.id,
                                                    service_areas: [],
                                                }
                                                unit.areas.forEach((area) => {
                                                    const isChecked =
                                                        areaSelection[
                                                            area.id
                                                        ] === true

                                                    if (isChecked) {
                                                        assignUnitDict.service_areas.push(
                                                            area.id,
                                                        )
                                                    }
                                                })
                                                assignUnits.push(assignUnitDict)
                                            })

                                            setCreateLoading(true)

                                            createWorkorder({
                                                body: {
                                                    start_date: startDateStr,
                                                    end_date: endDateStr,
                                                    vendor:
                                                        selectedVendorId != -1
                                                            ? selectedVendorId
                                                            : undefined,
                                                    service: selectedServiceId,
                                                    schedule: schedule.id,
                                                    units: assignUnits,
                                                    subscribe_to_bed_status_change: subToBedStatus,
                                                },
                                            })
                                                .then((res) => {
                                                    onClose()
                                                    toast.success(
                                                        `Success! ${res.data.created_count} workorders created.`,
                                                    )
                                                })
                                                .finally(() => {
                                                    setCreateLoading(false)
                                                })
                                        }
                                    }}
                                >
                                    Create (
                                    {
                                        assignmentFinderSelection.selection.unit
                                            .length
                                    }
                                    )
                                </Button>
                            )}
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
