import React, { useEffect, useState } from 'react'
import {
    Badge,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    Popover,
    Switch,
    Tooltip,
    useTheme,
} from '@material-ui/core'

import { Container, MessageDrawer, SideDrawer } from '../../components'
import {
    AreaInspection,
    AreaStatusTag,
    InventoryConfig,
    InventoryInspection,
    ModelMap,
    UnitConfig,
    UnitInspection,
} from '../../models'

import { UnitInspectionStatusBadge } from './UnitInspectionStatusBadge'
import { InventoryInspectionComponent } from './InventoryInspectionComponent'
import {
    bulkHandleInventoryInspections,
    updateInventoryInspection,
    updateUnitInspection,
    updateUnitInspectionStatus,
} from '../../store'
import { Flag, Info } from '@material-ui/icons'
import { useAppDispatch, useInspection } from '../../hooks'
import { axiosInstance, containsOneOf } from '../../helpers'
import { toast } from 'react-toastify'

interface Props {
    unitConfigMap: ModelMap<UnitConfig>
    inventoryConfigMap: ModelMap<InventoryConfig>
    unitInspection?: UnitInspection
    areaStatusMap: ModelMap<AreaStatusTag>
    isInventoryInspectionInFilter: (invIns: InventoryInspection) => boolean
    onClose: () => void
    setUnitInspection?: (unitInspection: UnitInspection) => void
}

export const UnitInspectionDetailForm = (props: Props) => {
    const {
        unitInspection,
        onClose,
        unitConfigMap,
        inventoryConfigMap,
        areaStatusMap,
        isInventoryInspectionInFilter,
        setUnitInspection,
    } = props

    const dispatch = useAppDispatch()

    const [messagesOpen, setMessagesOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closePopover = () => {
        setAnchorEl(null)
    }
    const popOverOpen = Boolean(anchorEl)
    const [selectedInvIns, setSelectedInvIns] = useState<
        InventoryInspection | undefined
    >(undefined)

    const [editMode, setEditMode] = useState(false)

    interface AreaInsSelectionDict {
        areaIns: AreaInspection
        selected: boolean
    }

    const [reportAreaSelectedList, setReportAreaSelectedList] = useState<
        AreaInsSelectionDict[]
    >([])

    useEffect(() => {
        if (unitInspection?.area_inspections) {
            const newSelectionArray: AreaInsSelectionDict[] = []
            unitInspection.area_inspections.forEach((area) => {
                newSelectionArray.push({ areaIns: area, selected: false })
            })

            setReportAreaSelectedList(newSelectionArray)
        }
    }, [unitInspection])

    const { inventoryInspectionMap } = useInspection(-1)

    const theme = useTheme()

    const unitConfigName = unitInspection
        ? unitConfigMap[unitInspection.unit.unit_config]?.name
        : ''

    const areaInspections: JSX.Element[] = []
    unitInspection?.area_inspections.map((areaInspection) => {
        const inventoryInspections = inventoryInspectionMap
            ? inventoryInspectionMap[areaInspection.area.id]
            : []

        const needsHandled: number[] = []
        const inventoryInspectionComponents = inventoryInspections?.reduce<
            JSX.Element[]
        >((prev, invIns) => {
            if (!isInventoryInspectionInFilter(invIns)) {
                return prev
            }

            if (!invIns.handled && invIns.status?.causes_flag) {
                needsHandled.push(invIns.id)
            }
            return prev.concat(
                <InventoryInspectionComponent
                    key={`INV_INS_${invIns.id}`}
                    inventoryInspection={invIns}
                    unitInspection={unitInspection}
                    onClick={() => {
                        setMessagesOpen(true)
                        setSelectedInvIns(invIns)
                    }}
                    inventoryConfigMap={inventoryConfigMap ?? {}}
                    editMode={editMode}
                />,
            )
        }, [])

        areaInspections.push(
            <Container
                style={{
                    ...theme.typography.subtitle1,
                    padding: theme.spacing(1),
                }}
                direction="column"
            >
                {/* Title Info */}
                <Container
                    style={{
                        fontWeight: theme.typography.fontWeightBold,
                        alignItems: 'center',
                        padding: theme.spacing(1),
                        height: 48,
                    }}
                >
                    <span>
                        {areaInspection.area.area_config.name}{' '}
                        {areaInspection.area.area_label}
                    </span>

                    <span
                        style={{
                            fontWeight: theme.typography.fontWeightLight,
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        {
                            areaStatusMap[areaInspection.area.id]
                                ?.area_status_config.name
                        }
                    </span>

                    <div style={{ flex: 1 }} />

                    {needsHandled.length > 0 && (
                        <IconButton
                            onClick={() => {
                                dispatch(
                                    bulkHandleInventoryInspections({
                                        body: {
                                            handled: true,
                                            inventory_inspections: needsHandled,
                                        },
                                    }),
                                )
                                // update the unit inspection
                                const updatedUnitIns: UnitInspection = {
                                    ...unitInspection,
                                    area_inspections: unitInspection.area_inspections.map(
                                        (areaIns) => {
                                            if (
                                                areaIns.id === areaInspection.id
                                            ) {
                                                return {
                                                    ...areaIns,
                                                    handled_count:
                                                        areaIns.handled_count +
                                                        needsHandled.length,
                                                }
                                            }
                                            return areaIns
                                        },
                                    ),
                                }
                                dispatch(updateUnitInspection(updatedUnitIns))
                            }}
                        >
                            <Badge
                                color="secondary"
                                badgeContent={needsHandled.length}
                            >
                                <Tooltip title="Handle All">
                                    <Flag />
                                </Tooltip>
                            </Badge>
                        </IconButton>
                    )}
                </Container>
                <Divider />
                {/* Inventory Inspections list */}
                <Container style={{ flexWrap: 'wrap' }}>
                    {inventoryInspectionComponents}
                </Container>
            </Container>,
        )
    })

    return (
        <>
            <SideDrawer
                open={unitInspection !== undefined}
                handleClose={() => {
                    setSelectedInvIns(undefined)
                    setMessagesOpen(false)
                    onClose()
                }}
                title={
                    <Container
                        direction="column"
                        style={{ ...theme.typography.subtitle1 }}
                    >
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {unitInspection?.unit.name} Inspection
                        </span>
                        <span>{unitConfigName}</span>
                    </Container>
                }
                headerRight={
                    <Container
                        style={{
                            flexDirection: 'row',
                            marginRight: theme.spacing(1),
                        }}
                    >
                        <Tooltip title={'Details and Reporting'}>
                            <IconButton
                                onClick={(e) => {
                                    openPopover(e)
                                }}
                            >
                                <Info />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            open={popOverOpen}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                horizontal: 'left',
                                vertical: 'bottom',
                            }}
                            onClose={closePopover}
                        >
                            <Container
                                style={{
                                    width: 300,
                                    backgroundColor: theme.palette.grey[100],
                                    flexDirection: 'column',
                                    padding: theme.spacing(1),
                                }}
                            >
                                <Container style={{ flexDirection: 'column' }}>
                                    <Container>
                                        <span
                                            style={{
                                                ...theme.typography.h6,
                                                marginRight: theme.spacing(1),
                                            }}
                                        >
                                            Assigned To:{' '}
                                        </span>
                                        <span
                                            style={{
                                                ...theme.typography.h6,
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                            }}
                                        >
                                            {' '}
                                            {unitInspection?.assigned_to
                                                ?.name ?? 'My Team'}
                                        </span>
                                    </Container>

                                    <Divider />
                                    <span
                                        style={{
                                            ...theme.typography.h6,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        Inspection Report
                                    </span>
                                    <div style={{ flex: 1 }} />
                                </Container>

                                {reportAreaSelectedList.map((areaDict) => (
                                    <FormControlLabel
                                        key={`REPORT_AREA_CHECKBOX_${areaDict.areaIns.id}`}
                                        control={
                                            <Checkbox
                                                checked={areaDict.selected}
                                                style={{
                                                    color:
                                                        theme.palette.primary
                                                            .main,
                                                }}
                                                onClick={() => {
                                                    setReportAreaSelectedList(
                                                        reportAreaSelectedList.map(
                                                            (old) => {
                                                                if (
                                                                    old.areaIns
                                                                        .id ===
                                                                    areaDict
                                                                        .areaIns
                                                                        .id
                                                                ) {
                                                                    return {
                                                                        ...old,
                                                                        selected: !old.selected,
                                                                    }
                                                                }
                                                                return old
                                                            },
                                                        ),
                                                    )
                                                }}
                                            />
                                        }
                                        label={`${areaDict.areaIns.area.area_config.name} ${areaDict.areaIns.area.area_label}`}
                                        labelPlacement="end"
                                        style={{ fontWeight: 'bold' }}
                                    />
                                ))}
                                <Divider />
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    style={{
                                        margin: theme.spacing(2, 2, 0, 0),
                                    }}
                                    onClick={() => {
                                        let selectedAreas = ''
                                        reportAreaSelectedList.forEach(
                                            (areaDict) => {
                                                if (areaDict.selected) {
                                                    selectedAreas += `${areaDict.areaIns.id},`
                                                }
                                            },
                                        )

                                        if (selectedAreas.length === 0) {
                                            toast.error(
                                                'Please select at least 1 area to generate a report',
                                            )
                                        } else {
                                            selectedAreas = selectedAreas.slice(
                                                0,
                                                -1,
                                            )
                                            axiosInstance.get(
                                                `inspection/unit-inspection/${unitInspection?.id}/get_unit_inspection_report/`,
                                                {
                                                    params: {
                                                        area_inspections: selectedAreas,
                                                        tz_offset: new Date().getTimezoneOffset(),
                                                    },
                                                },
                                            )
                                            toast.success(
                                                'You will receive your report via email shortly!',
                                            )
                                        }
                                    }}
                                >
                                    Create
                                </Button>
                            </Container>
                        </Popover>
                        <UnitInspectionStatusBadge
                            unitInspection={unitInspection}
                            removeAvatarCircle
                        />
                    </Container>
                }
            >
                <Container
                    style={{
                        margin: theme.spacing(2, 2, 0, 0),
                        fontWeight: 600,
                        fontSize: '18px',
                    }}
                >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={editMode}
                                color="primary"
                                onClick={() => setEditMode(!editMode)}
                            />
                        }
                        labelPlacement="start"
                        label="Edit Mode"
                        style={{ marginRight: theme.spacing(2) }}
                    />
                </Container>
                <Container flex={1} direction="column" scrollY>
                    {areaInspections}
                </Container>
                <Divider />
                <Container
                    style={{
                        padding: theme.spacing(2),
                        justifyContent: 'flex-end',
                    }}
                >
                    {unitInspection && editMode && (
                        <Container>
                            {unitInspection.complete_status ? (
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    style={{ marginRight: theme.spacing(2) }}
                                    onClick={() => {
                                        dispatch(
                                            updateUnitInspectionStatus({
                                                unitInspection: unitInspection,
                                                body: {
                                                    complete: false,
                                                },
                                            }),
                                        ).then(() => {
                                            if (setUnitInspection)
                                                setUnitInspection({
                                                    ...unitInspection,
                                                    complete_status: 0,
                                                })
                                        })
                                    }}
                                >
                                    Set Unit Incomplete
                                </Button>
                            ) : (
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    style={{ marginRight: theme.spacing(2) }}
                                    onClick={() => {
                                        dispatch(
                                            updateUnitInspectionStatus({
                                                unitInspection: unitInspection,
                                                body: {
                                                    complete: true,
                                                },
                                            }),
                                        ).then(() => {
                                            if (setUnitInspection)
                                                setUnitInspection({
                                                    ...unitInspection,
                                                    complete_status: 1,
                                                })
                                        })
                                    }}
                                >
                                    Set Unit Complete
                                </Button>
                            )}
                        </Container>
                    )}
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{ marginRight: theme.spacing(2) }}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                </Container>
            </SideDrawer>

            <MessageDrawer
                open={messagesOpen}
                onClose={() => {
                    setMessagesOpen(false)
                    setSelectedInvIns(undefined)
                }}
                onCreateMessage={() => {
                    if (selectedInvIns) {
                        dispatch(
                            updateInventoryInspection({
                                ...selectedInvIns,
                                message_count:
                                    (selectedInvIns.message_count ?? 0) + 1,
                            }),
                        )
                        // find the area ins for this inv ins
                        const updateAreaIns = unitInspection?.area_inspections.find(
                            (areaIns) => {
                                return selectedInvIns.area_ins_id === areaIns.id
                            },
                        )

                        if (updateAreaIns !== undefined && unitInspection) {
                            const updatedUnitIns = unitInspection
                            updatedUnitIns.area_inspections = unitInspection.area_inspections.map(
                                (areaIns) => {
                                    if (areaIns.id === updateAreaIns.id) {
                                        return {
                                            ...areaIns,
                                            message_count:
                                                areaIns.message_count + 1,
                                        }
                                    }
                                    return areaIns
                                },
                            )

                            dispatch(updateUnitInspection(updatedUnitIns))
                        }
                    }
                }}
            />
        </>
    )
}
