import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    IconButton,
    Modal,
    Paper,
    Radio,
    RadioGroup,
    Slide,
    Switch,
    TextField,
    Theme,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { Container } from '../../components'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { DropFiles } from '../../components/DropFiles'
import { DeleteForever } from '@material-ui/icons'
import { ReactComponent as MP4Logo } from '../../assets/mp4-file-icon.svg'
import {
    Company,
    ModelMap,
    VideoAdvertisement,
    convertListToMap,
    convertMapToList,
} from '../../models'
import { SearchField } from '../../components/SearchField'

interface Props {
    open: boolean
    selectedVideoAd: VideoAdvertisement | null
    apartmentList: Company[]
    createVideoAdvertisement: (request: FormData) => Promise<undefined>
    updateVideoAd: (request: FormData, id: number) => Promise<undefined>
    handleClose: () => void
}

export const CreateVideoAdModal = (props: Props) => {
    const {
        open,
        selectedVideoAd,
        apartmentList,
        handleClose,
        createVideoAdvertisement,
        updateVideoAd,
    } = props

    const [name, setName] = useState('')
    const [active, setActive] = useState(true)
    const [expirationDate, setExpirationDate] = useState(new Date())
    const [videoFile, setVideoFile] = useState<File | null>(null)
    const [videoFileName, setVideoFileName] = useState<string | null>(null)
    const [viewableToChoice, setViewableToChoice] = useState(ALL)
    const [selectedCompanyMap, setSelectedCompanyMap] = useState<
        ModelMap<Company>
    >({})

    const [loading, setLoading] = useState(false)

    const [doValidate, setDoValidate] = useState(false)

    const theme = useTheme()

    const buttonStyle: React.CSSProperties = {
        width: 75,
        textTransform: 'none',
    }

    useEffect(() => {
        if (selectedVideoAd) {
            setName(selectedVideoAd.name)
            setExpirationDate(new Date(selectedVideoAd.expiration_date))
            setActive(selectedVideoAd.active)
            setVideoFileName(selectedVideoAd.video_name)
            setViewableToChoice(
                selectedVideoAd.companies.length === 0 ? ALL : SELECT,
            )
            const tempCompanyMap: ModelMap<Company> = {}
            const apartmentMap = convertListToMap(apartmentList)
            selectedVideoAd.companies.forEach((id) => {
                tempCompanyMap[id] = apartmentMap[id]
            })
            setSelectedCompanyMap(tempCompanyMap)
        } else {
            setName('')
            setExpirationDate(new Date())
            setActive(true)
            setVideoFileName(null)
            setViewableToChoice(ALL)
            setSelectedCompanyMap({})
        }
        setDoValidate(false)
        setVideoFile(null)
    }, [open])

    const checkValidation = () => {
        if (name === '') {
            setDoValidate(true)
            return false
        }
        if (videoFile === null && videoFileName === null) {
            setDoValidate(true)
            return false
        }
        return true
    }

    const createRequestBody = () => {
        const formData = new FormData()

        formData.append('name', name)
        formData.append('expiration_date', expirationDate.toISOString())
        if (active) {
            formData.append('active', 'True')
        } else {
            formData.append('active', 'False')
        }
        if (videoFile) {
            formData.append('video', videoFile)
        }

        if (viewableToChoice === SELECT) {
            const companyIds: number[] = []
            const selectedCompanies = convertMapToList(selectedCompanyMap)
            selectedCompanies.forEach((apt) => {
                companyIds.push(apt.id)
            })
            formData.append('companies', JSON.stringify(companyIds))
        }

        return formData
    }

    return (
        <Modal
            open={open}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            onClose={handleClose}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        width: 600,
                    }}
                >
                    <Container
                        style={{
                            flexDirection: 'column',
                            flex: 1,
                        }}
                    >
                        <Container
                            style={{
                                flexDirection: 'column',
                                maxHeight: 'calc(100vh - 100px)',
                                overflow: 'auto',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: theme.typography.fontWeightBold,
                                    color: theme.palette.grey[800],
                                    margin: theme.spacing(1),
                                }}
                            >
                                {selectedVideoAd ? 'Edit' : 'Create'} Video
                                Advertisement
                            </span>
                            <TextField
                                label="Name"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                required
                                style={{ margin: theme.spacing(1) }}
                                variant="outlined"
                                error={doValidate && name === ''}
                                helperText={
                                    doValidate && name === '' && 'Required'
                                }
                            />
                            <KeyboardDatePicker
                                value={expirationDate}
                                onChange={(date) => {
                                    if (date) {
                                        setExpirationDate(date)
                                    }
                                }}
                                format="MM/dd/yyyy"
                                inputVariant="outlined"
                                label="Expiration Date"
                                style={{
                                    margin: theme.spacing(1),
                                }}
                            />
                            <FormControlLabel
                                labelPlacement="start"
                                label="Active"
                                style={{ alignSelf: 'flex-start' }}
                                control={
                                    <Switch
                                        checked={active}
                                        color="primary"
                                        onClick={() => {
                                            setActive(!active)
                                        }}
                                    />
                                }
                            />
                            <Container
                                style={{
                                    marginBottom: theme.spacing(2),
                                    flexDirection: 'column',
                                }}
                            >
                                <Container
                                    style={{
                                        padding: theme.spacing(0, 2, 0, 2),
                                        width: '100%',
                                    }}
                                >
                                    <DropFiles
                                        multipleFiles={false}
                                        fileTypes={{
                                            'video/mp4': [],
                                        }}
                                        displayString="Drag and Drop Your Advertisement Video Here"
                                        onDrop={(files) => {
                                            setVideoFile(files[0])
                                            setVideoFileName(files[0].name)
                                        }}
                                    />
                                </Container>
                                <Container style={{ height: 75 }}>
                                    {videoFileName && (
                                        <Container
                                            style={{
                                                margin: theme.spacing(
                                                    1,
                                                    0,
                                                    1,
                                                    2,
                                                ),
                                                border: `1px solid ${theme.palette.grey[400]}`,
                                                borderRadius:
                                                    theme.shape.borderRadius,
                                                padding: theme.spacing(1),
                                                alignItems: 'center',
                                            }}
                                        >
                                            <MP4Logo
                                                style={{
                                                    height: '30px',
                                                    width: '30px',
                                                    marginRight: theme.spacing(
                                                        1,
                                                    ),
                                                }}
                                            />
                                            {videoFileName}
                                            <IconButton
                                                onClick={() => {
                                                    setVideoFile(null)
                                                    setVideoFileName(null)
                                                }}
                                            >
                                                <DeleteForever color="secondary" />
                                            </IconButton>
                                        </Container>
                                    )}
                                </Container>
                            </Container>

                            <Container
                                direction="column"
                                style={{
                                    margin: theme.spacing(0, 2, 0, 2),
                                }}
                            >
                                <Container
                                    style={{
                                        ...theme.typography.subtitle1,
                                        fontWeight: 600,
                                    }}
                                >
                                    Viewable By:
                                </Container>
                                <RadioGroup value={viewableToChoice} row>
                                    <FormControlLabel
                                        value="all"
                                        control={
                                            <Radio
                                                style={{
                                                    color:
                                                        theme.palette.primary
                                                            .dark,
                                                }}
                                            />
                                        }
                                        label="All Companies"
                                        checked={viewableToChoice === ALL}
                                        onClick={() => {
                                            setViewableToChoice(ALL)
                                        }}
                                    />
                                    <FormControlLabel
                                        value="select"
                                        control={
                                            <Radio
                                                style={{
                                                    color:
                                                        theme.palette.primary
                                                            .dark,
                                                }}
                                            />
                                        }
                                        label="Select Companies"
                                        checked={viewableToChoice === SELECT}
                                        onClick={() => {
                                            setViewableToChoice(SELECT)
                                        }}
                                    />
                                </RadioGroup>
                            </Container>
                            {viewableToChoice === SELECT && (
                                <CompanySelection
                                    selectedCompanyMap={selectedCompanyMap}
                                    setSelectedCompanyMap={
                                        setSelectedCompanyMap
                                    }
                                    theme={theme}
                                    apartmentList={apartmentList}
                                />
                            )}
                        </Container>
                        <Container
                            style={{
                                borderTop: `1px solid ${theme.palette.grey[400]}`,
                                padding: theme.spacing(1),
                            }}
                        >
                            <Button
                                onClick={handleClose}
                                variant="contained"
                                style={buttonStyle}
                                color="secondary"
                            >
                                Cancel
                            </Button>
                            <div style={{ flex: 1 }} />
                            <Button
                                onClick={() => {
                                    if (checkValidation()) {
                                        setLoading(true)
                                        const requestData = createRequestBody()
                                        if (selectedVideoAd) {
                                            updateVideoAd(
                                                requestData,
                                                selectedVideoAd.id,
                                            )
                                                .then(() => handleClose())
                                                .finally(() =>
                                                    setLoading(false),
                                                )
                                        } else {
                                            createVideoAdvertisement(
                                                requestData,
                                            )
                                                .then(() => handleClose())
                                                .finally(() =>
                                                    setLoading(false),
                                                )
                                        }
                                    }
                                }}
                                variant="contained"
                                style={buttonStyle}
                                color="primary"
                                disabled={loading}
                            >
                                {loading ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

interface CompanySelectionProps {
    apartmentList: Company[]
    theme: Theme
    selectedCompanyMap: ModelMap<Company>
    setSelectedCompanyMap: (map: ModelMap<Company>) => void
}

const CompanySelection = (props: CompanySelectionProps) => {
    const {
        apartmentList,
        selectedCompanyMap,
        theme,
        setSelectedCompanyMap,
    } = props

    const [searchText, setSearchText] = useState('')

    let atLeastOneChecked = false

    Object.keys(selectedCompanyMap).forEach((keyStr) => {
        const key = Number(keyStr)
        const valueChecked = selectedCompanyMap[key] !== undefined
        atLeastOneChecked = atLeastOneChecked || valueChecked
    })

    const numberSelected = Object.keys(selectedCompanyMap).length
    const allChecked = numberSelected === apartmentList.length

    const filteredApartmentList = useMemo(() => {
        const apartmentSearch = searchText.toLocaleLowerCase()
        return apartmentList.filter((apt) =>
            apt.name.toLocaleLowerCase().includes(apartmentSearch),
        )
    }, [apartmentList, searchText])

    return (
        <Container
            style={{
                flexDirection: 'column',
                marginBottom: theme.spacing(2),
            }}
        >
            <Container style={{ alignItems: 'center' }}>
                <Checkbox
                    checked={allChecked}
                    style={{
                        color: theme.palette.primary.dark,
                    }}
                    indeterminate={!allChecked && atLeastOneChecked}
                    onChange={() => {
                        const newMap: ModelMap<Company> = {}
                        if (atLeastOneChecked) {
                            setSelectedCompanyMap({})
                        } else {
                            apartmentList.forEach((apartment) => {
                                newMap[apartment.id] = apartment
                            })
                            setSelectedCompanyMap(newMap)
                        }
                    }}
                />
                <span
                    style={{
                        ...theme.typography.body1,
                        marginLeft: theme.spacing(1),
                        marginRight: theme.spacing(1),
                    }}
                >
                    {numberSelected > 0 ? `${numberSelected} Selected` : ''}
                </span>
                <Container style={{ flex: 1 }} />
                <SearchField
                    value={searchText}
                    style={{ maxWidth: 300, marginRight: theme.spacing(1) }}
                    variant="outlined"
                    onChange={(e) => setSearchText(e.target.value)}
                    size="small"
                />
            </Container>
            <Container
                style={{
                    flexDirection: 'column',
                    maxHeight: 200,
                    minHeight: 200,
                    overflow: 'auto',
                }}
            >
                {filteredApartmentList.map((apt) => {
                    return (
                        <Container
                            key={`APARTMENT_${apt.id}`}
                            style={{
                                alignItems: 'center',
                                height: 30,
                            }}
                        >
                            <Checkbox
                                checked={
                                    selectedCompanyMap[apt.id] !== undefined
                                        ? true
                                        : false
                                }
                                style={{ color: theme.palette.primary.main }}
                                onClick={() => {
                                    const newCheckedMap: ModelMap<Company> = {
                                        ...selectedCompanyMap,
                                    }
                                    if (newCheckedMap[apt.id]) {
                                        delete newCheckedMap[apt.id]
                                    } else {
                                        newCheckedMap[apt.id] = apt
                                    }
                                    setSelectedCompanyMap(newCheckedMap)
                                }}
                            />

                            <span
                                style={{
                                    ...theme.typography.subtitle2,
                                    fontWeight: theme.typography.fontWeightBold,
                                    marginLeft: theme.spacing(2),
                                }}
                            >
                                {apt.name}
                            </span>
                        </Container>
                    )
                })}
            </Container>
        </Container>
    )
}

const ALL = 1
const SELECT = 2
