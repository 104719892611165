import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '.'
import {
    AreaInspection,
    BaseUnitInspection,
    ModelMap,
    UnitInspection,
} from '../models'

import {
    getInspectionDetail,
    RootState,
    setInspectionDetail,
    setInventoryInspectionList,
} from '../store'
import { axiosInstance } from '../helpers'

interface Options {
    getDetail?: boolean
    getInventoryInspections?: boolean
}

export const useInspection = (inspectionId: number, options?: Options) => {
    const dispatch = useAppDispatch()

    const inspectionDetail = useSelector(
        (state: RootState) => state.inspection.inspectionDetail,
    )

    const unitInspections = useSelector(
        (state: RootState) => state.inspection.unitInspectionList,
    )

    const inventoryInspections = useSelector(
        (state: RootState) => state.inspection.inventoryInspectionList,
    )

    const inventoryInspectionMap = useSelector(
        (state: RootState) => state.inspection.inventoryInspectionMap,
    )

    const [unitInspectionMap, setUnitInspectionMap] = useState<
        ModelMap<UnitInspection>
    >({})
    const [areaInspectionMap, setAreaInspectionMap] = useState<
        ModelMap<AreaInspection>
    >({})

    const [unitCompletionMap, setUnitCompletionMap] = useState<
        ModelMap<BaseUnitInspection>
    >({})

    const [inspectionScore, setInspectionScore] = useState({
        total: 0,
        max: 0,
    })

    useEffect(() => {
        if (options?.getDetail) {
            dispatch(getInspectionDetail(inspectionId))
        }

        return () => {
            if (options?.getDetail) {
                dispatch(setInspectionDetail(undefined))
            }

            if (options?.getInventoryInspections) {
                dispatch(setInventoryInspectionList(undefined))
            }
        }
    }, [])

    useEffect(() => {
        if (unitInspections) {
            // A map of Unit ids to the units UnitInspection
            const newUnitInspectionMap: ModelMap<UnitInspection> = {}

            // A map of Area id's to the AreaInspection
            const areaInspections: ModelMap<AreaInspection> = {}

            unitInspections.forEach((unitInspection) => {
                newUnitInspectionMap[unitInspection.unit.id] = unitInspection

                unitInspection.area_inspections.forEach((areaInspection) => {
                    areaInspections[areaInspection.area.id] = areaInspection
                })
            })

            setUnitInspectionMap(newUnitInspectionMap)
            setAreaInspectionMap(areaInspections)
        }
    }, [unitInspections])

    useEffect(() => {
        if (inspectionDetail) {
            // A map of Unit ids to the units UnitInspection
            const newUnitInspectionMap: ModelMap<BaseUnitInspection> = {}

            inspectionDetail.unit_inspections.forEach((unitInspection) => {
                newUnitInspectionMap[unitInspection.unit] = unitInspection
            })

            setUnitCompletionMap(newUnitInspectionMap)
        }
    }, [inspectionDetail])

    const fetchInspectionScore = async (inspectionId: number) => {
        const res = await axiosInstance.get(`inspection/${inspectionId}/score/`)

        setInspectionScore(res.data)
    }

    return {
        inspectionDetail: inspectionDetail,
        inventoryInspectionList: inventoryInspections,
        unitInspectionMap: unitInspectionMap,
        areaInspectionMap: areaInspectionMap,
        inventoryInspectionMap: inventoryInspectionMap,
        unitCompletionMap: unitCompletionMap,
        fetchInspectionScore,
        inspectionScore,
    }
}
