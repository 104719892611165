import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { ChangeOrder, ChangeOrderStatus, IdentifiableObject } from '../models'
import { axiosInstance } from '../helpers'
import { toast } from 'react-toastify'

export const useChangeOrder = () => {
    const [changeOrderList, setChangeOrderList] = useState<ChangeOrder[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const [
        changeOrderFilterState,
        setChangeOrderFilterState,
    ] = useState<ChangeOrderFilterState>({
        status: ChangeOrderStatus.PENDING,
        unitSearch: '',
    })

    const getChangeOrderList = async (params?: {
        lower_bound_date: string
    }) => {
        try {
            setIsLoading(true)
            const response = await axiosInstance.get(
                'workorder/simple-change-order/',
                { params: params },
            )
            const updatedChangeOrderList: ChangeOrder[] = response.data
            setChangeOrderList(updatedChangeOrderList)
            setIsLoading(false)
            return updatedChangeOrderList
        } catch (error) {
            setIsLoading(false)
            toast.error('Error getting change orders')
        }
    }

    const approveDenyChangeOrder = async (
        changeOrder: ChangeOrder,
        status: ChangeOrderStatus,
    ) => {
        try {
            const response = await axiosInstance.post(
                `workorder/change-order/${changeOrder.id}/change_status/`,
                {
                    status: status,
                },
            )

            const returnedChangeOrder: ChangeOrder = response.data.change_order

            updateChangeOrderState({
                ...changeOrder,
                status: returnedChangeOrder.status,
            })

            toast.success('Change Order updated successfully')
        } catch (error) {
            toast.error('Error updating Change Order')
        }
    }

    const deleteChangeOrder = async (changeOrder: ChangeOrder) => {
        try {
            const response = await axiosInstance.delete(
                `workorder/change-order/${changeOrder.id}/`,
            )
            _removeIdentifiable(changeOrder, setChangeOrderList)
            toast.success('Change Order deleted successfully!')
        } catch (error) {
            setIsLoading(false)
            toast.error('Error deleting change order')
        }
    }

    const updateChangeOrderState = (changeOrder: ChangeOrder) => {
        _insertOrUpdateIdentifiable(changeOrder, setChangeOrderList)
    }

    const _insertOrUpdateIdentifiable = <T extends IdentifiableObject>(
        updatedValue: T,
        dispatch: Dispatch<SetStateAction<T[]>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            let found = false
            for (let i = 0; i < safeOldValue.length; i++) {
                const current = safeOldValue[i]
                if (current.id === updatedValue.id) {
                    safeOldValue[i] = updatedValue
                    found = true
                    break
                }
            }

            if (!found) {
                safeOldValue.push(updatedValue)
            }

            return safeOldValue
        })
    }

    const _removeIdentifiable = <T extends IdentifiableObject>(
        deleteValue: T,
        dispatch: Dispatch<SetStateAction<T[]>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            return safeOldValue.filter((v) => v.id !== deleteValue.id)
        })
    }

    const filteredChangeOrders = useMemo(() => {
        const filters = changeOrderFilterState
        return changeOrderList.filter((co) => {
            const unitSearchValid =
                co.location?.unit_name
                    ?.toLocaleLowerCase()
                    .startsWith(filters.unitSearch) || filters.unitSearch === ''

            const statusValid = co.status === filters.status

            return unitSearchValid && statusValid
        })
    }, [changeOrderFilterState, changeOrderList])

    return {
        filteredChangeOrders,
        isLoading,
        changeOrderFilterState,
        getChangeOrderList,
        approveDenyChangeOrder,
        setChangeOrderFilterState,
        deleteChangeOrder,
    }
}

export interface ChangeOrderFilterState {
    status: ChangeOrderStatus
    unitSearch: string
}

export const changeOrderStatusOptions = [
    ChangeOrderStatus.PENDING,
    ChangeOrderStatus.APPROVED,
    ChangeOrderStatus.DENIED,
] as const
