import React from 'react'
import { Badge, IconButton, Tooltip, useTheme } from '@material-ui/core'
import { Container, LeaseStatusBadge } from '../../components'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import {
    Damage,
    DamageStatus,
    DamageTransaction,
    getIssueCount,
} from '../../models'
import { currencyFormatter, toMMDDYYYY } from '../../helpers'
import { FileCopy } from '@material-ui/icons'

interface Props {
    damageTransaction: DamageTransaction
    damage: Damage
    totalCost: number
    splitBy: number
    displayProblems: boolean
    removeDamageTransaction: () => void
    openMoveInModal: () => void
}

export const DamageTransactionRow = (props: Props) => {
    const {
        damageTransaction,
        totalCost,
        splitBy,
        damage,
        displayProblems,
    } = props

    const theme = useTheme()

    const areaName = `${damageTransaction.lease.area.area_config_name} (${damageTransaction.lease.area.area_label})`

    const leaseStartDate = new Date(damageTransaction.lease.start_date)
    const leaseEndDate = new Date(damageTransaction.lease.end_date)

    const today = new Date()
    const endCutoff = new Date()

    let startDateStyle: React.CSSProperties = {}
    let endDateStyle: React.CSSProperties = {}

    const problemTextStyle: React.CSSProperties = {
        backgroundColor: 'red',
        color: 'white',
        borderRadius: 4,
        fontWeight: 'bold',
    }

    if (displayProblems) {
        if (leaseStartDate > today) {
            startDateStyle = problemTextStyle
        }

        if (leaseEndDate < endCutoff) {
            endDateStyle = problemTextStyle
        }
    }

    const isTransactionSynced = damageTransaction.synced_id !== null
    let verifiedIcon: JSX.Element | null = null

    if (
        damage.status === DamageStatus.SYNC_FAILED ||
        damage.status === DamageStatus.ENTRATA_SYNCED ||
        damage.status === DamageStatus.SYNC_PENDING
    ) {
        verifiedIcon = isTransactionSynced ? (
            <Tooltip title={`Sync ID: ${damageTransaction.synced_id}`}>
                <CheckIcon fontSize="small" color="primary" />
            </Tooltip>
        ) : (
            <Tooltip title={'Sync Failed'}>
                <CloseIcon fontSize="small" color="secondary" />
            </Tooltip>
        )
    }

    const issueCount = getIssueCount(damageTransaction.lease)

    return (
        <Container
            style={{
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1),
                backgroundColor: theme.palette.grey[50],
                border: `1px solid ${theme.palette.grey[400]}`,
                padding: 4,
                borderRadius: theme.shape.borderRadius,
                width: 234,
                flexDirection: 'column',
                height: 100,
            }}
        >
            {/* Tenant + Area*/}
            <Container>
                <span
                    style={{
                        ...theme.typography.caption,
                        width: 150,
                        whiteSpace: 'nowrap',
                        display: 'block',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {damageTransaction.lease.user.name}
                </span>

                <div style={{ flex: 1 }} />
                <span
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {areaName}
                </span>
            </Container>

            {/* Lease Status + Dates */}
            <Container
                style={{
                    alignItems: 'center',
                    flex: 1,
                }}
            >
                {/* Legends */}
                <Container style={{ flexDirection: 'column' }}>
                    <span
                        style={{
                            ...theme.typography.caption,
                            fontWeight: theme.typography.fontWeightLight,
                        }}
                    >
                        Start
                    </span>
                    <span
                        style={{
                            ...theme.typography.caption,
                            fontWeight: theme.typography.fontWeightLight,
                        }}
                    >
                        End
                    </span>
                </Container>

                {/* Dates */}
                <Container
                    style={{
                        flexDirection: 'column',
                        marginLeft: 4,
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.caption,
                            ...startDateStyle,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {toMMDDYYYY(leaseStartDate)}
                    </span>
                    <span
                        style={{
                            ...theme.typography.caption,
                            ...endDateStyle,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {toMMDDYYYY(leaseEndDate)}
                    </span>
                </Container>

                <div style={{ flex: 1 }} />

                <LeaseStatusBadge status={damageTransaction.lease.status} />
            </Container>

            {/* Transaction Details */}
            <Container style={{ alignItems: 'center' }}>
                <span
                    style={{
                        ...theme.typography.caption,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {currencyFormatter.format(totalCost / splitBy)}
                </span>

                <div style={{ flex: 1 }} />
                {verifiedIcon}

                {issueCount > 0 && (
                    <Tooltip title="See Move In">
                        <IconButton
                            size="small"
                            onClick={props.openMoveInModal}
                        >
                            <Badge
                                badgeContent={issueCount}
                                color="secondary"
                                showZero={false}
                            >
                                <FileCopy fontSize="small" />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                )}

                <Tooltip title="Cancel Transaction">
                    <IconButton
                        size="small"
                        onClick={() => {
                            if (
                                confirm(
                                    'Would you like to remove this Transaction?',
                                )
                            ) {
                                props.removeDamageTransaction()
                            }
                        }}
                        disabled={
                            props.damage.status !== DamageStatus.PENDING &&
                            props.damage.status !== DamageStatus.PRE_APPROVED
                        }
                    >
                        <DeleteForeverIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Container>
        </Container>
    )
}
