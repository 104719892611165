import {
    AnalyticsActionTypes,
    AnalyticState,
    SET_ANALYTICS_LOADING,
    SET_APT_SERVICE_ANALYTICS,
    SET_PORTFOLIO_VENDOR_ANALYTICS,
    SET_VENDOR_ANALYTICS,
} from './types'

const initialState: AnalyticState = {
    isLoading: {
        GET_APT_SERVICE_ANALYTICS: false,
        GET_VENDOR_ANALYTICS_REQUEST: false,
    },
}

export const analyticReducer = (
    state = initialState,
    action: AnalyticsActionTypes,
): AnalyticState => {
    switch (action.type) {
        case SET_ANALYTICS_LOADING:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.request]: action.isLoading,
                },
            }
        case SET_APT_SERVICE_ANALYTICS:
            return {
                ...state,
                aptServiceAnalytics: action.analyticsList,
            }

        case SET_VENDOR_ANALYTICS:
            return {
                ...state,
                vendorAnalytics: action.analytics,
            }
        case SET_PORTFOLIO_VENDOR_ANALYTICS:
            return {
                ...state,
                portfolioVendorAnalytics: action.analytics,
            }
        default:
            return state
    }
}
