import React, { useState } from 'react'
import { Container } from '../../components'
import { Grid, TableSortLabel, useTheme } from '@material-ui/core'
import { AnalyticsRow } from './AnalyticsRow'
import {
    AnalyticsResponseData,
    VendorWorkTime,
    sortAnalyticDataCount,
} from './AnalyticsHome'
import { Pagination } from '@material-ui/lab'
import { ModelMap } from '../../models'

interface Props {
    analyticsData: AnalyticsResponseData[]
    showLastWeekArrow: boolean
    vendorWorkTimeMap: ModelMap<VendorWorkTime>
    setSelectedAnalyticData: (data: AnalyticsResponseData) => void
    setOpenAnalyticModal: (open: boolean) => void
    height?: string
}

const TotalWorkAsc = 'TotalWorkAsc'
const TotalWorkDesc = 'TotalWorkDesc'

export const AnalyticsTable = (props: Props) => {
    const {
        analyticsData,
        showLastWeekArrow,
        vendorWorkTimeMap,
        setSelectedAnalyticData,
        setOpenAnalyticModal,
        height,
    } = props

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(1)

    const [orderBy, setOrderBy] = useState('')
    const [sortedAnalytics, setSortedAnalytics] = useState(analyticsData)

    const theme = useTheme()

    const HeadCellStyle: React.CSSProperties = {
        fontWeight: 600,
        color: theme.palette.darkGreen.main,
        fontSize: '15px',
        flex: 2,
    }

    return (
        <Container
            scrollY
            style={{ marginTop: theme.spacing(2), fontSize: '16px' }}
        >
            {sortedAnalytics.length === 0 ? (
                <Container style={{ color: theme.palette.primary.dark }}>
                    No work has been completed during this timeframe!
                </Container>
            ) : (
                <Grid
                    container={true}
                    style={{
                        padding: theme.spacing(2, 0, 1, 0),
                    }}
                >
                    <Grid
                        container={true}
                        style={{ paddingLeft: theme.spacing(2) }}
                    >
                        <Container style={{ ...HeadCellStyle }}></Container>
                        <Container style={{ ...HeadCellStyle }}>Name</Container>
                        <Container style={{ ...HeadCellStyle }}>Type</Container>
                        <Container
                            style={{ ...HeadCellStyle }}
                            onClick={() => {
                                const newAnalyticsRows = [...sortedAnalytics]
                                if (orderBy !== TotalWorkAsc) {
                                    newAnalyticsRows.sort(sortAnalyticDataCount)
                                    setSortedAnalytics(newAnalyticsRows)
                                    setOrderBy(TotalWorkAsc)
                                } else {
                                    setSortedAnalytics(
                                        newAnalyticsRows.reverse(),
                                    )
                                    setOrderBy(TotalWorkDesc)
                                }
                            }}
                        >
                            <TableSortLabel
                                active={
                                    orderBy === TotalWorkAsc ||
                                    orderBy === TotalWorkDesc
                                }
                                direction={
                                    orderBy === TotalWorkAsc ? 'asc' : 'desc'
                                }
                            >
                                Work Completed
                            </TableSortLabel>
                        </Container>
                        <Container style={{ ...HeadCellStyle }}>
                            Work Hours
                        </Container>
                    </Grid>
                    <Grid
                        container={true}
                        style={{
                            overflowY: 'auto',
                            maxHeight: height ? height : 'calc(100vh - 325px)',
                        }}
                    >
                        {sortedAnalytics
                            .map((data) => {
                                return (
                                    <AnalyticsRow
                                        data={data}
                                        showLastWeekArrow={showLastWeekArrow}
                                        vendorWorkTimeMap={vendorWorkTimeMap}
                                        key={`ANALYTIC_DATA_${data.id}_${data.type}`}
                                        setSelectedAnalyticData={
                                            setSelectedAnalyticData
                                        }
                                        setOpenAnalyticModal={
                                            setOpenAnalyticModal
                                        }
                                    />
                                )
                            })
                            .slice(
                                (page - 1) * rowsPerPage,
                                (page - 1) * rowsPerPage + rowsPerPage,
                            )}
                    </Grid>
                    <Container style={{ flex: 1, justifyContent: 'flex-end' }}>
                        <span
                            style={{
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                color: theme.palette.lightGrey.main,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: '13px',
                                paddingTop: theme.spacing(1),
                            }}
                        >
                            {sortedAnalytics.length ?? 0} Rows
                        </span>
                        <Pagination
                            count={Math.ceil(
                                sortedAnalytics.length / rowsPerPage,
                            )}
                            page={page}
                            onChange={(_: unknown, newPage: number) => {
                                setPage(newPage)
                            }}
                            variant="outlined"
                            shape="rounded"
                            style={{ margin: theme.spacing(1, 0, 0, 0) }}
                        />
                    </Container>
                </Grid>
            )}
        </Container>
    )
}
