import {
    Chip,
    Divider,
    IconButton,
    Modal,
    Paper,
    Slide,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from '../../components'
import CloseIcon from '@material-ui/icons/Close'
import { Company, Service } from '../../models'
import { axiosInstance, currencyFormatter } from '../../helpers'

interface Props {
    open: boolean
    property: Company
    handleClose: () => void
}

export const PropertyDetailModal = (props: Props) => {
    const { open, handleClose, property } = props

    const [services, setServices] = useState<Service[]>([])
    const [units, setUnits] = useState<number>(0)
    const [totalSpent, setTotalSpent] = useState<number>(0)

    const theme = useTheme()

    let filePreview =
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQ5srpTZoAXWINllDurGscx_Pqg_foehqAkQ&usqp=CAU'

    if (property.profile_picture) {
        filePreview = property.profile_picture
    }

    useEffect(() => {
        const data = {
            apt: property.id,
        }
        axiosInstance
            .post(`company/apartment/get_apartment_data/`, data)
            .then((res) => {
                setServices(res.data.services)
                setUnits(res.data.units)
                setTotalSpent(res.data.total_spent)
            })
    }, [property])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'auto',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: '1100px',
                        height: '700px',
                        maxHeight: 'calc(100vh - 100px)',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <Container
                        style={{
                            alignItems: 'center',
                            marginBottom: theme.spacing(1),
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Tooltip title="Cancel">
                            <IconButton
                                onClick={() => {
                                    handleClose()
                                }}
                            >
                                <CloseIcon
                                    fontSize="inherit"
                                    style={{
                                        marginRight: theme.spacing(1),
                                        color: theme.palette.darkGreen.main,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Container>
                    <Container direction="column">
                        <Container
                            style={{
                                marginBottom: theme.spacing(3),
                                width: '100%',
                                alignItems: 'center',
                            }}
                        >
                            <Container
                                style={{
                                    width: property.profile_picture
                                        ? '300px'
                                        : '150px',
                                    height: property.profile_picture
                                        ? '200px'
                                        : '150px',
                                    display: 'inline-flex',
                                    position: 'relative',
                                    marginRight: theme.spacing(2),
                                }}
                            >
                                <img src={filePreview} alt="" width={'100%'} />
                                <input
                                    accept="image/png, image/jpeg, image/jpg"
                                    style={{ display: 'none' }}
                                    id="icon-button-file"
                                    type="file"
                                />
                            </Container>

                            <Container
                                direction="column"
                                style={{
                                    fontWeight: 500,
                                    fontSize: '16px',
                                }}
                            >
                                <Container
                                    style={{
                                        marginTop: theme.spacing(1),
                                    }}
                                >
                                    {property.name}
                                </Container>
                                {property.property_manager?.phone && (
                                    <Container
                                        style={{
                                            marginTop: theme.spacing(1),
                                        }}
                                    >
                                        Phone:{' '}
                                        {property.property_manager?.phone}
                                    </Container>
                                )}
                                {property.property_manager?.cell_phone && (
                                    <Container
                                        style={{
                                            marginTop: theme.spacing(1),
                                        }}
                                    >
                                        Cell Phone:{' '}
                                        {property.property_manager?.cell_phone}
                                    </Container>
                                )}
                                {property.property_manager?.email && (
                                    <Container
                                        style={{
                                            marginTop: theme.spacing(1),
                                        }}
                                    >
                                        Email:{' '}
                                        {property.property_manager?.email}
                                    </Container>
                                )}
                                {property.url && (
                                    <Container
                                        style={{
                                            marginTop: theme.spacing(1),
                                        }}
                                    >
                                        Website: {property.url}
                                    </Container>
                                )}
                            </Container>
                            <Container flex={1} />
                            <Container
                                style={{
                                    backgroundColor:
                                        theme.palette.lightGrey.light,
                                    border: `1px solid grey`,
                                    borderRadius: '5px',
                                    width: '400px',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    color: theme.palette.darkGreen.main,
                                    padding: theme.spacing(2),
                                    justifySelf: 'flex-end',
                                }}
                                direction="column"
                            >
                                <Container
                                    style={{
                                        fontWeight: 500,
                                        margin: theme.spacing(1, 0, 1, 0),
                                        color: 'black',
                                    }}
                                >
                                    About:
                                </Container>
                                <Container>Total Units: {units}</Container>
                                <Container>
                                    Total Spent in EZ Turn:{' '}
                                    {currencyFormatter.format(totalSpent)}
                                </Container>
                                <Container alignItems="center">
                                    <Container style={{ whiteSpace: 'nowrap' }}>
                                        Services Used:
                                    </Container>
                                    <Container
                                        flexWrap="wrap"
                                        style={{
                                            maxHeight: '100px',
                                            overflow: 'auto',
                                            marginLeft: theme.spacing(1),
                                        }}
                                    >
                                        {services.map((service) => {
                                            return (
                                                <Chip
                                                    key={`SERVICE_CHIP_${service.id}`}
                                                    label={service.name}
                                                    style={{
                                                        backgroundColor:
                                                            service.color,
                                                        margin: theme.spacing(
                                                            0.5,
                                                        ),
                                                        color: 'white',
                                                    }}
                                                />
                                            )
                                        })}
                                    </Container>
                                </Container>
                            </Container>
                        </Container>
                        <Divider />
                        <Container
                            style={{
                                width: '100%',
                                marginTop: theme.spacing(3),
                            }}
                        >
                            <iframe
                                width="750"
                                height="350"
                                loading="lazy"
                                src={`https://www.google.com/maps/embed/v1/place?key=${
                                    process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                                }
                                    &q=${convertAddress(property)}`}
                            ></iframe>
                            <Container flex={1} />
                            <Container
                                style={{
                                    backgroundColor:
                                        theme.palette.lightGrey.light,
                                    border: `1px solid grey`,
                                    borderRadius: '5px',
                                    width: '200px',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    color: theme.palette.darkGreen.main,
                                    padding: theme.spacing(2),
                                    justifySelf: 'flex-end',
                                    height: '300px',
                                    marginLeft: theme.spacing(2),
                                }}
                                direction="column"
                            >
                                <Container
                                    style={{
                                        fontWeight: 500,
                                        margin: theme.spacing(1, 0, 1, 0),
                                        color: 'black',
                                    }}
                                >
                                    Address:
                                </Container>
                                <Container>{property.address}</Container>
                                <Container>
                                    {property.city} {property.state}
                                </Container>
                                <Container>{property.zip_code}</Container>
                            </Container>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

export const convertAddress = (property: Company) => {
    let params = ''
    if (property.address) {
        params = params + property.address.replace(/ /g, '_') + ','
    }
    if (property.city) {
        params = params + property.city.replace(/ /g, '_') + ','
    }
    if (property.state) {
        params = params + property.state.replace(/ /g, '_') + ','
    }
    if (property.zip_code) {
        params = params + property.zip_code.replace(/ /g, '_')
    }

    return params
}
