import React, { useState, useEffect, useRef } from 'react'
import {
    Editor,
    EditorState,
    ContentState,
    getDefaultKeyBinding,
    Modifier,
    SelectionState,
} from 'draft-js'

import 'draft-js/dist/Draft.css'

// Components

import { KeyCommand } from './KeyCommands'
import ContextMenu, { Option } from './ContextMenu/ContextMenu'
import { EntityType } from './EntityType'
import { Container } from '..'
import { useTheme } from '@material-ui/core'

// Types / interfaces
interface CustomStyle {
    editor?: React.CSSProperties
}

interface Props {
    editorState: EditorState
    customStyle?: CustomStyle
    mentionOptions?: Option[]
    onChange: (editorState: EditorState) => void
    renderMentionOption?: (opt: Option, idx: number) => JSX.Element
    header?: () => JSX.Element
    footer?: () => JSX.Element
    left?: () => JSX.Element
    right?: () => JSX.Element
}

export const RichTextEditor = (props: Props) => {
    const { editorState, onChange } = props

    const [mentionMode, setMentionMode] = useState(false)
    const [selectedMentionOptionIdx, setSelectedMentionOptionIdx] = useState(0)
    const [createMentionStart, setCreateMentionStart] = useState(-1)

    const editorRef = useRef<any>()

    const theme = useTheme()

    const handleKeyCommand = (command: string) => {
        // Handle custom commands
        if (command === KeyCommand.OPEN_MENTION_CONTEXT_MENU) {
            openMentionOptions()
            return 'handled'
        }

        if (command === KeyCommand.CARRIAGE_RETURN) {
            return 'handled'
        }

        return 'not-handled'
    }

    const _keyBinding = (e: React.KeyboardEvent<{}>) => {
        if (e.key === '@') {
            return KeyCommand.OPEN_MENTION_CONTEXT_MENU
        }

        if (e.keyCode === 13) {
            return KeyCommand.CARRIAGE_RETURN
        }

        if (e.key === 'Backspace' && mentionMode) {
            // Check to see if the trigger char '@' which is located at createMentionStart is between the selection
            const selection = editorState.getSelection()
            let start = selection.getStartOffset()
            const end = selection.getEndOffset()

            if (start === end) {
                // The user is typing and the selection is a singularity.  The user expects the cursor to move back 1 space
                // If start !== end the user has selected multiple characters, and expects the cursor to end up at the begining of the selection
                start -= 1
            }

            if (createMentionStart >= start && createMentionStart <= end) {
                exitMentionMode()
            }
        }

        return getDefaultKeyBinding(e)
    }

    const openMentionOptions = () => {
        const currentContent = editorState.getCurrentContent()
        const currentSelection = editorState.getSelection()
        const anchorKey = currentSelection.getAnchorKey()
        const start = currentSelection.getStartOffset()
        const currentContentBlock = currentContent.getBlockForKey(anchorKey)

        // Is the first char the @ trigger?
        // This indicates the user is beginning the content block with a mention
        if (start === 0) {
            return enterMentionMode(currentContent, currentSelection)
        }

        // Is the character before the @ trigger an empty space?
        // This indicates the user is starting a new word and that word is a mention
        if (currentContentBlock.getText().charAt(start - 1) === ' ') {
            return enterMentionMode(currentContent, currentSelection)
        }
        return null
    }

    const enterMentionMode = (
        contentState: ContentState,
        selectionState: SelectionState,
    ) => {
        const stateWithText = Modifier.insertText(
            contentState,
            selectionState,
            '@',
        )
        const newEditorState = EditorState.push(
            editorState,
            stateWithText,
            'apply-entity',
        )
        onChange(newEditorState)

        setCreateMentionStart(selectionState.getStartOffset())

        // Enter mention mode
        return setMentionMode(true)
    }

    const exitMentionMode = () => {
        setMentionMode(false)
    }

    const getUserInputMention = () => {
        if (createMentionStart === -1) {
            return ''
        }

        // Get block for current selection
        const selection = editorState.getSelection()
        const anchorKey = selection.getAnchorKey()
        const currentContent = editorState.getCurrentContent()
        const currentBlock = currentContent.getBlockForKey(anchorKey)

        // Create a selection based on where the mention was started and where the user is right now
        let userInputRange = SelectionState.createEmpty(anchorKey)

        userInputRange = selection.merge({
            focusOffset: selection.getEndOffset(),
            anchorOffset: createMentionStart + 1,
        })

        // return the text in the user input range
        const start = userInputRange.getStartOffset()
        const end = userInputRange.getEndOffset()
        return currentBlock.getText().slice(start, end)
    }

    const deleteRange = (
        contentState: ContentState,
        selection: SelectionState,
        start?: number,
        end?: number,
    ) => {
        // Remove The text from createMentionStart to the currentSelection end point
        let replaceSelection = selection
        if (start !== undefined && end !== undefined) {
            const blockKey = selection.getAnchorKey()

            replaceSelection = SelectionState.createEmpty(blockKey)
            replaceSelection = selection.merge({
                focusOffset: end,
                anchorOffset: start,
            })
        }

        return EditorState.push(
            editorState,
            Modifier.removeRange(contentState, replaceSelection, 'forward'),
            'remove-range',
        )
    }

    const addMention = (option: Option) => {
        if (!mentionMode) {
            return
        }

        // Exit from mention mode
        exitMentionMode()

        const contentState = editorState.getCurrentContent()
        const selection = editorState.getSelection()

        // Remove The text from createMentionStart to the currentSelection end point
        const blockKey = selection.getAnchorKey()
        const editorStateRemovedText = deleteRange(
            contentState,
            selection,
            createMentionStart,
            selection.getEndOffset(),
        )

        // Create a MENTION entity that will be styled
        const contentStateWithEntity = editorStateRemovedText
            .getCurrentContent()
            .createEntity(EntityType.MENTION, 'IMMUTABLE', {
                mention: { id: option.id, name: option.name },
            })
        const mentionKey = contentStateWithEntity.getLastCreatedEntityKey()
        const stateWithText = Modifier.insertText(
            contentStateWithEntity,
            selection,
            `@${option.name}`,
            undefined,
            mentionKey,
        )
        const newEditorState = EditorState.push(
            editorState,
            stateWithText,
            'apply-entity',
        )

        // Move the cursor to the end
        const length = stateWithText.getBlockForKey(blockKey).getLength()
        const newSelection = new SelectionState({
            anchorKey: blockKey,
            anchorOffset: length,
            focusKey: blockKey,
            focusOffset: length,
        })

        onChange(EditorState.forceSelection(newEditorState, newSelection))
    }

    const mentionText = getUserInputMention()
    const options = props.mentionOptions ? props.mentionOptions : []
    const filteredOptions: Option[] = options.reduce<Option[]>(
        (accumulator, opt) => {
            if (mentionText) {
                // User has started typing the name of the option.  Filter the options array based on user input
                if (
                    opt.name.toLowerCase().startsWith(mentionText.toLowerCase())
                ) {
                    // Check for exact match
                    if (opt.name.toLowerCase() === mentionText.toLowerCase()) {
                        addMention(opt)
                    }

                    return accumulator.concat(opt)
                }

                return accumulator
            }

            return accumulator.concat(opt)
        },
        [],
    )

    useEffect(() => {
        if (
            filteredOptions.length === 0 &&
            mentionText.charAt(mentionText.length - 1) === ' '
        ) {
            // The user has typed an @ sign without the intent of mentioning someone
            exitMentionMode()
        }
    }, [filteredOptions])

    return (
        <Container direction="column" flex={1}>
            {/* Primary container */}

            <Container flex={1}>
                {/* Header Container */}
                {props.header && props.header()}
            </Container>

            <Container flex={1}>
                {/* Left / Rich Text Editor / Right */}

                <Container>
                    {/* Left Container */}
                    {props.left && props.left()}
                </Container>

                <div
                    style={{
                        border: `1px solid ${theme.palette.grey[400]}`,
                        borderRadius: '4px',
                        flex: 1,
                        ...(props.customStyle && props.customStyle.editor),
                        position: 'relative',
                    }}
                >
                    {/* Rich Text Editor Container.  Editor does not work nested inside flex */}
                    <ContextMenu
                        open={mentionMode}
                        options={filteredOptions}
                        renderOption={props.renderMentionOption}
                        focusedOptionIdx={selectedMentionOptionIdx}
                        setFocusedOptionIdx={setSelectedMentionOptionIdx}
                        mentionText={getUserInputMention()}
                        onSelectOption={addMention}
                    />

                    <Editor
                        ref={editorRef}
                        editorState={editorState}
                        onChange={onChange}
                        handleKeyCommand={handleKeyCommand}
                        keyBindingFn={_keyBinding}
                        onEscape={exitMentionMode}
                    />
                </div>

                <Container>
                    {/* Right Container */}
                    {props.right && props.right()}
                </Container>
            </Container>

            <Container flex={1}>
                {/* Footer Container */}
                {props.footer && props.footer()}
            </Container>
        </Container>
    )
}
