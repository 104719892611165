import React, { useState } from 'react'

import {
    Badge,
    IconButton,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { Check, Close, Message } from '@material-ui/icons'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'

import NumberFormat from 'react-number-format'

import { Container, EllipsisSpan, StatusBadge } from '../../components'
import {
    InventoryConfig,
    InventoryInspection,
    ModelMap,
    UnitInspection,
} from '../../models'
import { useStyles } from '../../styles'
import { AppDispatch, useAppDispatch } from '../../hooks'
import {
    createInventoryInspectionChannel,
    getChannelDetail,
    getMessageList,
    patchInventoryInspection,
    updateUnitInspection,
} from '../../store'
import Flag from '@material-ui/icons/Flag'
import { InvInspectionStatuses } from './InvInspectionStatuses'

interface Props {
    inventoryInspection: InventoryInspection
    unitInspection?: UnitInspection
    inventoryConfigMap: ModelMap<InventoryConfig>
    editMode: boolean
    onClick: () => void
}

export const InventoryInspectionComponent = (props: Props) => {
    const {
        inventoryInspection,
        inventoryConfigMap,
        unitInspection,
        editMode,
    } = props

    const dispatch = useAppDispatch()
    const theme = useTheme()
    const classes = useStyles()

    const inventoryConfig =
        inventoryConfigMap[inventoryInspection.inventory_config]

    let statusIcon: JSX.Element | null
    if (inventoryInspection.handled) {
        statusIcon = (
            <IconButton
                onClick={() => {
                    if (unitInspection !== undefined) {
                        dispatch(
                            patchInventoryInspection({
                                inventoryInspectionId: inventoryInspection.id,
                                body: {
                                    handled: false,
                                    config_index:
                                        inventoryInspection.config_index,
                                },
                            }),
                        )
                        const updatedUnitIns: UnitInspection = {
                            ...unitInspection,
                            area_inspections: unitInspection.area_inspections.map(
                                (areaIns) => {
                                    if (
                                        areaIns.id ===
                                        inventoryInspection.area_ins_id
                                    ) {
                                        return {
                                            ...areaIns,
                                            handled_count:
                                                areaIns.handled_count - 1,
                                        }
                                    }
                                    return areaIns
                                },
                            ),
                        }
                        dispatch(updateUnitInspection(updatedUnitIns))
                    }
                }}
                color="primary"
            >
                <Tooltip title="Mark Not Handled">
                    <AssignmentTurnedInIcon />
                </Tooltip>
            </IconButton>
        )
    } else if (inventoryInspection.status?.causes_flag) {
        statusIcon = (
            <IconButton
                onClick={() => {
                    if (unitInspection !== undefined) {
                        dispatch(
                            patchInventoryInspection({
                                inventoryInspectionId: inventoryInspection.id,
                                body: {
                                    handled: true,
                                    config_index:
                                        inventoryInspection.config_index,
                                },
                            }),
                        )
                        // TODO: find bug with handled count on unit ins
                        const updatedUnitIns: UnitInspection = {
                            ...unitInspection,
                            area_inspections: unitInspection.area_inspections.map(
                                (areaIns) => {
                                    if (
                                        areaIns.id ===
                                        inventoryInspection.area_ins_id
                                    ) {
                                        return {
                                            ...areaIns,
                                            handled_count:
                                                areaIns.handled_count + 1,
                                        }
                                    }
                                    return areaIns
                                },
                            ),
                        }
                        dispatch(updateUnitInspection(updatedUnitIns))
                    }
                }}
                color="secondary"
            >
                <Tooltip title="Mark Handled">
                    <Flag />
                </Tooltip>
            </IconButton>
        )
    } else {
        statusIcon = null
    }

    return (
        <Container
            style={{
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.grey[100],
                margin: theme.spacing(1),
                padding: theme.spacing(1),
                width: 320,
                minHeight: 130,
                flexDirection: 'column',
                border: `1px solid ${theme.palette.grey[500]}`,
                cursor: 'pointer',
            }}
        >
            <Container style={{ alignItems: 'center', flex: 1 }}>
                <Container direction="column" style={{ flex: 1 }}>
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {inventoryConfig?.name ?? 'Unknown'}
                    </span>

                    <span
                        style={{
                            ...theme.typography.caption,
                            fontWeight: theme.typography.fontWeightLight,
                        }}
                    >
                        (#{inventoryInspection.config_index + 1})
                    </span>
                </Container>

                <Container style={{ justifyContent: 'flex-end', flex: 1 }}>
                    {statusIcon}

                    <IconButton
                        onClick={(e) => {
                            //open the message drawer, stop propagation, get the channel details if the ii has one, and get messages
                            e.stopPropagation()
                            props.onClick()
                            if (inventoryInspection.channel) {
                                dispatch(
                                    getChannelDetail({
                                        channelId: inventoryInspection.channel,
                                    }),
                                )
                                dispatch(
                                    getMessageList({
                                        channelId: inventoryInspection.channel,
                                    }),
                                )
                            } else {
                                dispatch(
                                    createInventoryInspectionChannel(
                                        inventoryInspection.id,
                                    ),
                                )
                            }
                        }}
                    >
                        <Badge
                            badgeContent={inventoryInspection.message_count}
                            color={
                                inventoryInspection?.unread_count ?? 0 > 0
                                    ? 'secondary'
                                    : 'primary'
                            }
                        >
                            <Tooltip title="View Messages">
                                <Message
                                    className={classes.fontHoverGrey600}
                                    style={{ marginRight: 2 }}
                                />
                            </Tooltip>
                        </Badge>
                    </IconButton>
                </Container>
            </Container>
            <NumberFormat
                prefix="$"
                thousandSeparator
                value={inventoryInspection.status?.cost ?? 0}
                displayType="text"
                style={{
                    ...theme.typography.subtitle2,
                    fontWeight: theme.typography.fontWeightLight,
                }}
            />

            <Container style={{ flex: 1 }}></Container>

            <Container style={{ alignItems: 'center', flex: 1 }}>
                {inventoryConfig?.ins_has_note ? (
                    <InspectionNote
                        inventoryInspection={inventoryInspection}
                        editMode={editMode}
                        dispatch={dispatch}
                    />
                ) : (
                    <>
                        <InvInspectionStatuses
                            inventoryInspection={inventoryInspection}
                            editMode={editMode}
                        />
                    </>
                )}
            </Container>
        </Container>
    )
}

const InspectionNote = (props: {
    inventoryInspection: InventoryInspection
    editMode: boolean
    dispatch: AppDispatch
}) => {
    const { inventoryInspection, editMode, dispatch } = props

    const theme = useTheme()

    const [note, setNote] = useState(inventoryInspection.note ?? undefined)

    if (editMode) {
        return (
            <Container alignItems="center">
                <TextField
                    variant="outlined"
                    size="small"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                />
                <IconButton
                    size="small"
                    onClick={() => {
                        dispatch(
                            patchInventoryInspection({
                                inventoryInspectionId: inventoryInspection.id,
                                body: {
                                    config_index:
                                        inventoryInspection.config_index,
                                    note: note,
                                },
                            }),
                        )
                    }}
                >
                    <Check style={{ color: 'green' }} />
                </IconButton>
            </Container>
        )
    }

    return (
        <EllipsisSpan
            style={{
                width: 240,
                ...theme.typography.body1,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                backgroundColor: 'white',
                margin: theme.spacing(1),
                marginLeft: 0,
                paddingLeft: theme.spacing(1),
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.palette.grey[400]}`,
                fontStyle:
                    inventoryInspection.note === '' ? 'italic' : undefined,
            }}
        >
            {inventoryInspection.note === ''
                ? 'No Note'
                : String(inventoryInspection.note)}
        </EllipsisSpan>
    )
}
