import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useAssignModalStyle = makeStyles((theme) =>
    createStyles({
        columnHeaderStyle: {
            height: 32,
            padding: theme.spacing(1),
            ...theme.typography.subtitle2,
            fontWeight: theme.typography.fontWeightBold,
        },
    }),
)
