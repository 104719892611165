import {
    Button,
    IconButton,
    TableCell,
    TableRow,
    useTheme,
} from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { CellData, HeadCell, RowData, SimpleTable } from '../../components'
import { resolveRoute, Routes, toMMDDYYYY } from '../../helpers'
import { Inspection, InspectionType } from '../../models'
import { useStyles } from '../../styles'
import EditIcon from '@material-ui/icons/Edit'
import {
    getScheduleList,
    patchInspection,
    UpdateInspectionAxiosReq,
} from '../../store'
import { useAppDispatch } from '../../hooks'
import { toast } from 'react-toastify'

export interface InspectionRow extends RowData {
    name: CellData<string>
    inspection_type: CellData<InspectionType>
    start_date: CellData<string>
    end_date: CellData<string>
    progress: CellData<string>
    inspection: CellData<Inspection>
}

interface Props {
    inspectionList?: Inspection[]
    selectedInspection?: Inspection
    setSelectedInspection: (ins?: Inspection) => void
    setDrawerOpen: (open: boolean) => void
    active: boolean
}

export const InspectionListTable = (props: Props) => {
    const {
        inspectionList,
        selectedInspection,
        setSelectedInspection,
        setDrawerOpen,
        active,
    } = props

    const theme = useTheme()
    const classes = useStyles()
    const history = useHistory()

    const dispatch = useAppDispatch()

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    const headCells: HeadCell<InspectionRow>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'name',
            label: 'Name',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'inspection_type',
            label: 'Inspection Type',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'start_date',
            label: 'Start Date',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'end_date',
            label: 'End Date',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'progress',
            label: 'Progress',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: '',
            label: '',
            style: headCellStyle,
        },
    ]

    const activeInspections = inspectionList !== undefined && active

    if (activeInspections) {
        headCells.push({
            disablePadding: false,
            align: 'left',
            id: '',
            label: '',
            style: headCellStyle,
        })
    }

    const createInspectionRow = (inspection: Inspection): InspectionRow => {
        const uiTotal = inspection.ui_total ?? 0
        const uiComplete = inspection.ui_complete ?? 0

        const safeDenominator = uiTotal > 0 ? uiTotal : 1

        const progressPercent = (uiComplete / safeDenominator) * 100
        const progressStr = `${uiComplete} / ${uiTotal} (${progressPercent.toFixed(
            1,
        )}%)`

        return {
            name: { value: inspection.name, sortValue: inspection.name },
            inspection_type: {
                value: inspection.inspection_type,
                sortValue: inspection?.inspection_type?.name,
            },
            start_date: {
                value: inspection.start_date,
                sortValue: inspection.start_date,
            },
            end_date: {
                value: inspection.end_date,
                sortValue: inspection.end_date,
            },
            progress: {
                value: progressStr,
                sortValue: progressStr,
            },
            inspection: {
                value: inspection,
                sortValue: inspection.id,
            },
        }
    }

    return (
        <SimpleTable<InspectionRow>
            rows={
                inspectionList && inspectionList?.length > 0
                    ? inspectionList?.map((ins) => createInspectionRow(ins)) ??
                      []
                    : []
            }
            render={(row) => {
                const startDateStr = toMMDDYYYY(new Date(row.start_date.value))

                const endDateStr = toMMDDYYYY(new Date(row.end_date.value))

                const rowStyle: React.CSSProperties = {
                    cursor: 'pointer',
                }

                const selected =
                    row.inspection.value.id === selectedInspection?.id

                if (selected) {
                    rowStyle.backgroundColor = theme.palette.primary.light
                }

                return (
                    <TableRow
                        key={`INSPECTION_ROW_${row.inspection.value.id}`}
                        className={classes.hoverGrey200}
                        style={rowStyle}
                        onClick={() => {
                            history.push(
                                resolveRoute(
                                    Routes.inspectionDetail,
                                    ':id',
                                    row.inspection.value.id,
                                ),
                            )
                        }}
                    >
                        <TableCell>{row.name.value}</TableCell>
                        <TableCell>
                            {row.inspection_type?.value?.name}
                        </TableCell>
                        <TableCell>{startDateStr}</TableCell>
                        <TableCell>{endDateStr}</TableCell>
                        <TableCell>{row.progress.value}</TableCell>
                        <TableCell>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setSelectedInspection(row.inspection.value)
                                    setDrawerOpen(true)
                                }}
                            >
                                <EditIcon
                                    className={`${classes.fontSecondaryMain} ${classes.fontHoverSecondaryDark}`}
                                />
                            </IconButton>
                        </TableCell>
                        {activeInspections && (
                            <TableCell style={{ boxShadow: 'none', zIndex: 1 }}>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (
                                            confirm(
                                                `Do you want to archive ${row.name.value}`,
                                            )
                                        ) {
                                            const req: UpdateInspectionAxiosReq = {
                                                id: row.inspection.value.id,
                                                body: {
                                                    active: false,
                                                },
                                            }

                                            dispatch(patchInspection(req)).then(
                                                () => {
                                                    toast.success(
                                                        'Inspection archived',
                                                    )
                                                },
                                            )
                                        }
                                    }}
                                    variant="contained"
                                    style={{
                                        backgroundColor: '#008C85',
                                        color: 'white',
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Archive
                                </Button>
                            </TableCell>
                        )}
                    </TableRow>
                )
            }}
            orderByDefault="start_date"
            headCells={headCells}
        ></SimpleTable>
    )
}
