import { Grid, useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { Container } from '../../components'
import { UnitInspection } from '../../models'
import { Pagination } from '@material-ui/lab'
import { toMMDDYYYY } from '../../helpers'
import { UnitInspectionStatusBadge } from '../Inspection/UnitInspectionStatusBadge'

interface Props {
    unitInspections: UnitInspection[]
}

export const AnalyticsInspectionTable = (props: Props) => {
    const { unitInspections } = props

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(1)

    const theme = useTheme()

    const HeadCellStyle: React.CSSProperties = {
        fontWeight: 600,
        color: theme.palette.darkGreen.main,
        fontSize: '15px',
        flex: 2,
    }

    return (
        <Container scrollY style={{ marginTop: theme.spacing(2) }}>
            <Grid
                container={true}
                style={{
                    padding: theme.spacing(2, 0, 1, 0),
                }}
            >
                <Grid
                    container={true}
                    style={{ paddingLeft: theme.spacing(2) }}
                >
                    <Container style={{ ...HeadCellStyle }}>Unit</Container>
                    <Container style={{ ...HeadCellStyle }}>Folder</Container>
                    <Container style={{ ...HeadCellStyle }}>Status</Container>
                    <Container style={{ ...HeadCellStyle }}>
                        Completed By
                    </Container>
                    <Container style={{ ...HeadCellStyle }}>Dates</Container>
                </Grid>
                <Grid
                    container={true}
                    style={{
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 450px)',
                    }}
                >
                    {unitInspections
                        .map((unitInspection) => {
                            return (
                                <UnitInspectionRow
                                    unitInspection={unitInspection}
                                    key={`UNIT_INSPECTION_ROW_${unitInspection.id}`}
                                />
                            )
                        })
                        .slice(
                            (page - 1) * rowsPerPage,
                            (page - 1) * rowsPerPage + rowsPerPage,
                        )}
                </Grid>
                <Container
                    style={{
                        flex: 1,
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.lightGrey.main,
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '13px',
                            paddingTop: theme.spacing(1),
                        }}
                    >
                        {unitInspections.length ?? 0} Rows
                    </span>
                    <Pagination
                        count={Math.ceil(unitInspections.length / rowsPerPage)}
                        page={page}
                        onChange={(_: unknown, newPage: number) => {
                            setPage(newPage)
                        }}
                        variant="outlined"
                        shape="rounded"
                        style={{ margin: theme.spacing(1, 0, 0, 0) }}
                    />
                </Container>
            </Grid>
        </Container>
    )
}

interface UnitInspectionRowProps {
    unitInspection: UnitInspection
}

const UnitInspectionRow = (props: UnitInspectionRowProps) => {
    const { unitInspection } = props

    const theme = useTheme()

    const CellStyle: React.CSSProperties = {
        fontWeight: 400,
        fontSize: '15px',
        flex: 1,
    }

    return (
        <Grid
            container
            style={{
                margin: theme.spacing(1, 0, 1, 0),
                border: '1px solid',
                borderColor: theme.palette.grey[400],
                borderRadius: '20px 20px 20px 20px',
                flexDirection: 'row',
                padding: theme.spacing(3, 0, 3, 2),
            }}
        >
            <Container style={{ ...CellStyle }}>
                {unitInspection.unit.name}
            </Container>
            <Container style={{ ...CellStyle }}>
                {unitInspection.folder.path}
                {unitInspection.folder.name}
            </Container>
            <Container style={{ ...CellStyle }}>
                <UnitInspectionStatusBadge
                    unitInspection={unitInspection}
                    removeAvatarCircle
                />
            </Container>
            <Container style={{ ...CellStyle }}>
                {unitInspection.completed_by?.name}
            </Container>
            <Container style={{ ...CellStyle }}>
                {toMMDDYYYY(new Date(unitInspection.start_date))} -{' '}
                {toMMDDYYYY(new Date(unitInspection.end_date))}
            </Container>
        </Grid>
    )
}
