import { useTheme } from '@material-ui/core'
import React from 'react'
import { AvatarCircle, Container } from '../..'

// models
import { Option } from './ContextMenu'

// components

interface Props {
    option: Option
}

const MentionOption = (props: Props) => {
    const { option } = props

    const theme = useTheme()

    return (
        <Container alignItems="center">
            <AvatarCircle
                text={option.name}
                initials
                styles={{ container: { margin: theme.spacing(0.375) } }}
            />
            <span
                style={{
                    marginLeft: theme.spacing(2),
                    marginRight: theme.spacing(0.125),
                    ...theme.typography.body2,
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >
                {option.name}
            </span>
        </Container>
    )
}

export default MentionOption
