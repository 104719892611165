import React, { Fragment, useEffect, useState } from 'react'
import { Container, NumberInput, Selector } from '../../../components'
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Switch,
    TextField,
    useTheme,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import {
    RootState,
    addChangeOrderToWorkorder,
    getChangeOrderConfigListRequest,
} from '../../../store'
import {
    Area,
    BaseWorkorder,
    ChangeOrderConfig,
    IdBoolMap,
} from '../../../models'
import { VENDOR, axiosInstance } from '../../../helpers'
import { ReactComponent as AddImage } from '../../../assets/icons/add-image.svg'
import { toast } from 'react-toastify'
import LoopIcon from '@material-ui/icons/Loop'
import { useAppDispatch, useUser } from '../../../hooks'

interface Props {
    setAddChangeOrder: (addChangeOrder: boolean) => void
    unitId: number | null
    workorder: BaseWorkorder
    onClose: () => void
}

export const CreateChangeOrder = (props: Props) => {
    const { setAddChangeOrder, unitId, workorder, onClose } = props

    const dispatch = useAppDispatch()
    const { workspaceUser } = useUser()

    const changeOrderConfigList = useSelector(
        (state: RootState) => state.aptConfig.changeOrderConfigList,
    )

    const [changeOrderConfig, setChangeOrderConfig] = useState<
        ChangeOrderConfig | undefined
    >()
    const [description, setDescription] = useState('')
    const [quantity, setQuantity] = useState(1)
    const [areas, setAreas] = useState<Area[]>([])
    const [doValidate, setDoValidate] = useState(false)
    const [images, setImages] = useState<File[]>([])
    const [loading, setLoading] = useState(false)
    const [addCustomPrice, setAddCustomPrice] = useState(false)
    const [customPrice, setCustomPrice] = useState<number | undefined>()

    const [
        changeOfScopeAreaState,
        setChangeOfScopeAreaState,
    ] = useState<IdBoolMap>({})

    const theme = useTheme()

    let changeOfScopeAreas: JSX.Element | null = null

    useEffect(() => {
        if (unitId) {
            axiosInstance
                .get('infrastructure/area/', {
                    params: { unit: unitId },
                })
                .then((res) => {
                    setAreas(res.data)
                    const tempIdBoolMap = changeOfScopeAreaState
                    res.data.map((area: Area) => {
                        tempIdBoolMap[area.id] = false
                    })
                    setChangeOfScopeAreaState(tempIdBoolMap)
                })
        }
        if (workspaceUser?.active_workspace.company_type === VENDOR) {
            dispatch(
                getChangeOrderConfigListRequest({
                    params: { jobsite: workorder.apartment_id },
                }),
            )
        }
    }, [unitId])

    if (changeOrderConfig?.addon === false) {
        changeOfScopeAreas = (
            <Container
                direction="column"
                style={{ marginTop: theme.spacing(1) }}
            >
                <Container
                    style={{
                        fontWeight: 500,
                        fontSize: '16px',
                    }}
                >
                    Select Areas
                </Container>
                <Container>
                    <Container direction="column">
                        {areas.slice(0, areas.length / 2 + 1).map((area) => {
                            return (
                                <FormControlLabel
                                    key={`AREA_${area.id}`}
                                    control={
                                        <Checkbox
                                            onChange={() =>
                                                setChangeOfScopeAreaState({
                                                    ...changeOfScopeAreaState,
                                                    [area.id]: !changeOfScopeAreaState[
                                                        area.id
                                                    ],
                                                })
                                            }
                                            checked={
                                                changeOfScopeAreaState[area.id]
                                            }
                                        />
                                    }
                                    label={`${area.area_config_name} ${area.area_label}`}
                                />
                            )
                        })}
                    </Container>
                    <Container direction="column">
                        {areas.slice(areas.length / 2 + 1).map((area) => {
                            return (
                                <FormControlLabel
                                    key={`AREA_${area.id}`}
                                    control={
                                        <Checkbox
                                            onChange={() =>
                                                setChangeOfScopeAreaState({
                                                    ...changeOfScopeAreaState,
                                                    [area.id]: !changeOfScopeAreaState[
                                                        area.id
                                                    ],
                                                })
                                            }
                                            checked={
                                                changeOfScopeAreaState[area.id]
                                            }
                                        />
                                    }
                                    label={`${area.area_config_name} ${area.area_label}`}
                                />
                            )
                        })}
                    </Container>
                </Container>
            </Container>
        )
    }

    const validationCheck = () => {
        if (changeOrderConfig === undefined) {
            return false
        }

        if (description === '') {
            return false
        }

        if (!changeOrderConfig.addon) {
            let areaCount = 0
            areas.map((area) => {
                if (changeOfScopeAreaState[area.id] === true) {
                    areaCount = areaCount + 1
                }
            })
            if (areaCount === 0) {
                return false
            }
        }

        if (
            images.length === 0 &&
            workspaceUser?.active_workspace.company_type === VENDOR
        ) {
            return false
        }

        if (quantity < 1) {
            return false
        }

        if (addCustomPrice && !customPrice) {
            return false
        }

        return true
    }

    return (
        <Container direction="column" style={{ width: '100%' }}>
            <Container direction="column">
                <Selector
                    customStyle={{
                        formControl: {
                            margin: theme.spacing(1, 0),
                            flex: 1,
                        },
                    }}
                    currentValue={changeOrderConfig?.id ?? -1}
                    label="Type"
                    data={changeOrderConfigList ?? []}
                    getDisplayString={(type) => {
                        return `${type.name} (${
                            type.addon ? 'Addon' : 'Change of scope'
                        })`
                    }}
                    onChange={(e) => {
                        const typeId = Number(e.target.value)
                        if (typeof typeId === 'number') {
                            const newType = changeOrderConfigList?.find(
                                (type) => type.id === typeId,
                            )
                            if (newType !== undefined) {
                                setChangeOrderConfig(newType)
                            }
                        }
                    }}
                    error={doValidate && changeOrderConfig === undefined}
                />

                {doValidate && changeOrderConfig === undefined && (
                    <span
                        style={{
                            color: 'red',
                            margin: theme.spacing(0, 0, 1, 1.5),
                            fontSize: '12px',
                        }}
                    >
                        Required
                    </span>
                )}
            </Container>

            <Container style={{ marginBottom: theme.spacing(1) }}>
                <TextField
                    variant="outlined"
                    type="number"
                    size="medium"
                    title="Quantity"
                    placeholder="Quantity"
                    label="Quantity"
                    fullWidth
                    value={quantity}
                    onChange={(e) => {
                        setQuantity(Number(e.target.value))
                    }}
                    error={doValidate && quantity < 1}
                    helperText={
                        doValidate && quantity < 1 ? 'Invalid Quantity' : ''
                    }
                />
            </Container>

            <Container style={{ marginBottom: theme.spacing(1) }}>
                <TextField
                    variant="outlined"
                    size="medium"
                    multiline
                    title="Description"
                    placeholder="Description"
                    label="Description"
                    fullWidth
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }}
                    error={doValidate && description === ''}
                    helperText={
                        doValidate && description === '' ? 'Required' : ''
                    }
                />
            </Container>

            <Container
                style={{ marginBottom: theme.spacing(1), alignItems: 'center' }}
            >
                <Switch
                    color="primary"
                    value={addCustomPrice}
                    onClick={(e) => {
                        setAddCustomPrice(!addCustomPrice)
                    }}
                    checked={addCustomPrice}
                />
                <NumberInput
                    prefix={'$'}
                    variant="outlined"
                    label={'Custom Price'}
                    value={customPrice}
                    onChange={(e) => setCustomPrice(Number(e.target.value))}
                    fullWidth
                    error={doValidate && addCustomPrice && !customPrice}
                    helperText={
                        doValidate &&
                        addCustomPrice &&
                        !customPrice &&
                        'Required'
                    }
                    disabled={!addCustomPrice}
                />
            </Container>

            <Container
                direction="row"
                style={{
                    overflowX: 'auto',
                    alignItems: 'center',
                }}
            >
                {images.map((image) => {
                    return (
                        <img
                            key={`IMAGE_${image.name}`}
                            src={URL.createObjectURL(image)}
                            style={{
                                maxHeight: 100,
                                maxWidth: 100,
                                margin: theme.spacing(1, 2, 1, 0),
                            }}
                        />
                    )
                })}

                {images.length !== 3 && (
                    <Fragment>
                        <input
                            color="primary"
                            accept="image/*"
                            type="file"
                            onChange={(e) => {
                                if (e.target.files && e.target.files[0]) {
                                    setImages([...images, e.target.files[0]])
                                }
                            }}
                            id="icon-button-file"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="icon-button-file">
                            <Button component="span">
                                <AddImage />
                            </Button>
                        </label>
                    </Fragment>
                )}
            </Container>
            {doValidate &&
                images.length === 0 &&
                workspaceUser?.active_workspace.company_type === VENDOR && (
                    <span
                        style={{
                            color: 'red',
                            margin: theme.spacing(0, 0, 1, 1.5),
                            fontSize: '12px',
                        }}
                    >
                        Required
                    </span>
                )}

            {changeOfScopeAreas}

            <Container flex={1} />

            <Container
                style={{
                    marginTop: theme.spacing(2),
                    justifyContent: 'flex-end',
                }}
            >
                {!loading && (
                    <Button
                        variant="outlined"
                        size="small"
                        style={{
                            marginRight: theme.spacing(1),
                            textTransform: 'none',
                            backgroundColor: 'white',
                            color: 'red',
                            borderColor: 'red',
                        }}
                        onClick={() => setAddChangeOrder(false)}
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    variant="outlined"
                    size="small"
                    style={{
                        textTransform: 'none',
                        backgroundColor: theme.palette.primary.dark,
                        color: 'white',
                    }}
                    onClick={() => {
                        if (validationCheck()) {
                            setLoading(true)
                            setDoValidate(false)
                            const req = new FormData()
                            if (changeOrderConfig) {
                                req.append(
                                    'change_order_config',
                                    changeOrderConfig?.id.toString(),
                                )
                            }

                            req.append('quantity', quantity.toString())

                            req.append('description', description)

                            const areaIds: number[] = []
                            areas.map((area) => {
                                if (changeOfScopeAreaState[area.id] === true) {
                                    areaIds.push(area.id)
                                }
                            })
                            if (areaIds.length > 0) {
                                req.append('areas', JSON.stringify(areaIds))
                            }

                            images.map((img, idx) => {
                                req.append(`attachment${idx}`, img)
                                req.append(`attachment${idx}_uploaded`, 'False')
                            })

                            if (addCustomPrice && customPrice) {
                                req.append(
                                    'custom_price',
                                    customPrice.toString(),
                                )
                            }

                            axiosInstance
                                .post(
                                    `workorder/${workorder.id}/change_order/`,
                                    req,
                                    {
                                        params: { all_jobsites: true },
                                    },
                                )
                                .then((res) => {
                                    toast.success(
                                        `A change order has been added`,
                                    )
                                    dispatch(
                                        addChangeOrderToWorkorder(
                                            workorder,
                                            res.data,
                                        ),
                                    )
                                    onClose()
                                    setAddChangeOrder(false)
                                })
                                .catch((e) => {
                                    toast.error(e.response.data.message)
                                })
                                .finally(() => {
                                    setLoading(false)
                                })
                        } else {
                            setDoValidate(true)
                        }
                    }}
                >
                    {!loading ? (
                        'Create Change Order'
                    ) : (
                        <LoopIcon
                            style={{
                                animation: 'spin 01s linear infinite',
                            }}
                        />
                    )}
                </Button>
            </Container>
        </Container>
    )
}
