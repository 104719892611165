import React, { useEffect, useState } from 'react'
import { Container, Selector } from '../../../components'
import {
    IdBoolMap,
    Inspection,
    Unit,
    UnitInspectFreqConfig,
    User,
    getLineageUnits,
} from '../../../models'
import { AreaSelectorCollection } from '../../../components/AreaSelector'
import {
    useAppDispatch,
    useAptConfig,
    useAreaStatusTag,
    _useFinderSelection,
    useRootInfrastructure,
} from '../../../hooks'
import { SchedulerFinder } from '../../Scheduler_v2/SchedulerFinder'
import {
    ASSIGN_UNIT_INSPECTION_REQUEST,
    FinderLocationSelection,
    FinderSelectionMode,
    GET_INSPECTION_LIST,
    RootState,
    assignUnitInspections,
    getInspectionList,
} from '../../../store'
import { Button, CircularProgress, Theme } from '@material-ui/core'
import { InspectionAreaCard } from '../InspectionAreaCard'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

interface Props {
    theme: Theme
    inspection: Inspection
    userList: User[]
    onClose: () => void
}

export const AssignInspectionStep = (props: Props) => {
    const { theme, inspection, userList, onClose } = props

    const dispatch = useAppDispatch()

    const loadingState = useSelector(
        (state: RootState) => state.inspection.isLoading,
    )

    const [assignedTo, setAssignedTo] = useState(-1)

    const isLoading =
        loadingState[ASSIGN_UNIT_INSPECTION_REQUEST] ||
        loadingState[GET_INSPECTION_LIST]

    const [areaSelection, setAreaSelection] = useState<IdBoolMap>({})

    const { getAreaConfigMap } = useAptConfig({ areaConfigList: true })

    const { tree } = useRootInfrastructure(true)
    const finderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Recursive,
    })

    const areaConfigMap = getAreaConfigMap()

    const selectedUnits = finderSelection.getUnits()

    const { areaStatusTagList, areaStatusTagMap } = useAreaStatusTag({
        getListForSchedule: inspection.schedule?.id,
    })

    const handleAssign = () => {
        const areaList = Object.keys(areaSelection).reduce<number[]>(
            (prev, areaIdStr) => {
                const areaId = Number(areaIdStr)
                if (areaSelection[areaId]) {
                    return prev.concat(areaId)
                }
                return prev
            },
            [],
        )

        dispatch(
            assignUnitInspections({
                body: {
                    inspection: inspection.id,
                    areas: areaList,
                    assign_to: assignedTo !== -1 ? assignedTo : undefined,
                },
            }),
        ).then(() => {
            toast.success('Created!')

            dispatch(getInspectionList({ params: {} })).then(() => {
                onClose()
            })
        })
    }

    const buildAreaStateForUnit = (
        unit: Unit,
        selectedAreaState: IdBoolMap,
        mutableAreaState: IdBoolMap,
    ) => {
        unit.areas.forEach((area) => {
            if (selectedAreaState[area.id] !== undefined) {
                mutableAreaState[area.id] = selectedAreaState[area.id]
            } else if (
                !areaStatusTagMap ||
                areaStatusTagMap[area.id] === undefined
            ) {
                mutableAreaState[area.id] = true
            } else {
                mutableAreaState[area.id] = areaStatusTagMap[
                    area.id
                ]!.area_status_config.should_service
            }
        })
    }

    useEffect(() => {
        const mutableAreaState: IdBoolMap = {}
        const handledUnits: IdBoolMap = {}

        // Recursively get the units of the selected folders
        Object.keys(finderSelection.selection.folder).forEach((key) => {
            if (key === 'length') return

            const folder = finderSelection.selection.folder[parseInt(key)]
            if (folder === undefined) return

            const folderUnits = getLineageUnits(folder)

            // It is possible that folderUnits are already in handledUnits because
            // The user selected both ParentFolder and ChildFolder
            // Prevent units from being duplicated

            folderUnits.forEach((unit) => {
                if (handledUnits[unit.id]) return
                handledUnits[unit.id] = true
                buildAreaStateForUnit(unit, areaSelection, mutableAreaState)
            })
        })

        // Add in the individual units the user selected
        Object.keys(finderSelection.selection.unit).forEach((key) => {
            if (key === 'length') return
            const unit = finderSelection.selection.unit[parseInt(key)]
            if (unit === undefined) return
            if (handledUnits[unit.id]) return
            handledUnits[unit.id] = true
            buildAreaStateForUnit(unit, areaSelection, mutableAreaState)
        })

        setAreaSelection(mutableAreaState)
    }, [finderSelection.selection])

    return (
        <Container
            style={{
                flexDirection: 'column',
                overflowY: 'hidden',
                flex: 1,
            }}
        >
            {/* Header */}
            <Container
                style={{
                    padding: theme.spacing(1),
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                }}
            >
                <AreaSelectorCollection
                    areaConfigMap={areaConfigMap}
                    areaSelectionMap={areaSelection}
                    setAreaSelectionMap={setAreaSelection}
                    units={selectedUnits}
                />

                <div style={{ flex: 1 }} />

                <Selector
                    data={[{ id: -1, name: 'My Team' }, ...userList]}
                    getDisplayString={(u) => u.name}
                    onChange={(e) => {
                        const assignedToId = Number(e.target.value)
                        setAssignedTo(assignedToId)
                    }}
                    label="Assigned To"
                    currentValue={assignedTo}
                    customStyle={{
                        formControl: {
                            width: 200,
                            marginRight: theme.spacing(2),
                        },
                    }}
                    searchable
                />
            </Container>
            {/* Body */}
            <Container
                style={{
                    overflowY: 'hidden',
                    flex: 1,
                }}
            >
                {/* Left Column, Tree selection */}
                <Container
                    style={{
                        overflowY: 'scroll',
                        width: 300,
                    }}
                >
                    <SchedulerFinder
                        root={tree.root}
                        finderSelection={finderSelection}
                        areaConfigMap={areaConfigMap}
                        areaStatusTagMap={{}}
                        unitWorkorderMap={{}}
                    />
                </Container>
                {/* Right Column Unit selection */}
                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        overflowY: 'scroll',
                    }}
                >
                    {selectedUnits.map((unit) => {
                        return (
                            <Container
                                key={`UNIT-${unit.id}`}
                                style={{
                                    minHeight: 100,
                                    maxHeight: 100,
                                    border: `1px solid ${theme.palette.grey[400]}`,
                                    borderRadius: theme.shape.borderRadius,
                                    margin: theme.spacing(1),
                                }}
                            >
                                {/* Left Column Location Details */}
                                <Container
                                    style={{
                                        flexDirection: 'column',
                                        borderRight: `1px solid ${theme.palette.grey[400]}`,
                                        padding: theme.spacing(1),
                                        minWidth: 150,
                                        maxWidth: 150,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <span
                                        style={{
                                            ...theme.typography.subtitle2,
                                            color: theme.palette.grey[500],
                                        }}
                                    >
                                        {unit.folder.path}
                                        {unit.folder.name}
                                    </span>
                                    <span
                                        style={{
                                            ...theme.typography.subtitle1,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                            color: theme.palette.grey[700],
                                        }}
                                    >
                                        {unit.name}
                                    </span>
                                </Container>

                                {/* Right Column Area Selections */}
                                <Container
                                    style={{
                                        flex: 1,
                                        padding: theme.spacing(1),
                                        overflowX: 'scroll',
                                    }}
                                >
                                    {unit.areas.map((area) => {
                                        return (
                                            <InspectionAreaCard
                                                key={`ASSIGN_INSPECT_AREA_${area.id}`}
                                                area={area}
                                                areaConfig={
                                                    areaConfigMap[
                                                        area.area_config
                                                    ]
                                                }
                                                areaStatusTag={
                                                    areaStatusTagMap[area.id]
                                                }
                                                setSelectionState={() =>
                                                    setAreaSelection({
                                                        ...areaSelection,
                                                        [area.id]: !areaSelection[
                                                            area.id
                                                        ],
                                                    })
                                                }
                                                selectionState={areaSelection}
                                            />
                                        )
                                    })}
                                </Container>
                            </Container>
                        )
                    })}
                </Container>
            </Container>
            {/* Footer */}
            <Container
                style={{
                    justifyContent: 'flex-end',
                    padding: theme.spacing(2),
                }}
            >
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <Button
                        onClick={handleAssign}
                        variant="outlined"
                        color="primary"
                        style={{ textTransform: 'none' }}
                    >
                        Create Assignments
                    </Button>
                )}
            </Container>
        </Container>
    )
}
