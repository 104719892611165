import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    TextField,
    useTheme,
} from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons'
import { toast } from 'react-toastify'
import { Container } from '../../components'
import { useAppDispatch, useUser } from '../../hooks'
import {
    PRE_APPROVED_TO_APPROVED,
    PRE_APPROVED_TO_GO_BACK,
    ASSIGNED_TO_IN_PROGRESS,
    COMPLETE_TO_PRE_APPROVED,
    COMPLETE_TO_APPROVED,
    COMPLETE_TO_GO_BACK,
    CREATE_WORKORDERS,
    defaultPermissible,
    DELETE_WORKORDERS,
    EDIT_PERMISSION_ROLES,
    EDIT_USER,
    EDIT_USER_PERMISSIONS,
    EDIT_WORKORDERS,
    IN_PROGRESS_TO_ASSIGNED,
    IN_PROGRESS_TO_COMPLETE,
    IN_PROGRESS_TO_PAUSE,
    PAUSE_TO_IN_PROGRESS,
    Permissible,
    PermissionRole,
    SEE_ACTION_ITEMS,
    SEE_INSPECTION,
    SEE_VENDOR_FINANCES,
    User,
    USE_MOBILE,
    USE_WEB,
    GO_BACK_TO_IN_PROGRESS,
    APPROVE_DENY_CHANGE_ORDER,
    VIEW_INVOICE,
    VIEW_NATIONAL_VENDOR_LIST,
    RECEIVE_DAILY_REPORT,
    SEE_INCOME_FINANCES,
    DMG_PENDING_TO_PREAPPROVED,
    DMG_PENDING_TO_DENIED,
    DMG_PREAPPROVED_TO_APPROVED,
    DMG_PREAPPROVED_TO_DENIED,
    DMG_APPROVED_TO_SYNCED,
    BULK_TRANSITION,
    EDIT_VENDOR_SUBSCRIPTION_STATUS,
    INCIDENT_REPORTS,
    VENDOR_INVOICE_PERMISSION,
} from '../../models'
import { hasPermission } from '../../models/Users/services'
import {
    createPermissionRole,
    PermissionRoleRequestBody,
    updatePermissionRoleRequest,
} from '../../store'
import { isAccountManager } from '../../helpers'

interface Props {
    permissionRole?: PermissionRole
    handleClose: () => void
    open: boolean
    organization: number
}

export const PermissionRoleDrawer = (props: Props) => {
    const { permissionRole, handleClose, open, organization } = props

    const dispatch = useAppDispatch()
    const theme = useTheme()
    const { workspaceUser } = useUser()

    const [editedPermissions, setEditedPermissions] = useState<Permissible>(
        defaultPermissible,
    )
    const [name, setName] = useState('')
    const [doValidate, setDoValidate] = useState(false)

    // permissions ui management state
    const [generalPermissionsOpen, setGeneralPermissionOpen] = useState(false)
    const [workorderPermissionsOpen, setWorkorderPermissionsOpen] = useState(
        false,
    )
    const [
        workorderTransitionPermissionsOpen,
        setWorkorderTransitionPermissionsOpen,
    ] = useState(false)

    const [damageOpen, setDamageOpen] = useState(false)

    useEffect(() => {
        if (permissionRole) {
            setEditedPermissions(permissionRole)
            setName(permissionRole.name)
        } else {
            setEditedPermissions(defaultPermissible)
            setName('')
        }
        setDoValidate(false)
    }, [permissionRole])

    const onSave = () => {
        // validate name
        if (name === '') {
            setDoValidate(true)
            return
        }

        const requestBody: PermissionRoleRequestBody = {
            ...editedPermissions,
            name: name,
            organization: organization,
        }

        if (permissionRole) {
            // update
            dispatch(
                updatePermissionRoleRequest({
                    id: permissionRole.id,
                    body: requestBody,
                }),
            ).then(() => {
                toast.success(`${name} was updated!`)
                handleClose()
                setEditedPermissions(defaultPermissible)
                setName('')
            })
        } else {
            // create
            dispatch(createPermissionRole({ body: requestBody })).then(() => {
                toast.success(`${name} was created!`)
                handleClose()
                setEditedPermissions(defaultPermissible)
                setName('')
            })
        }
    }

    const CAN_NOT_EDIT_PERMISSION_ROLES = !hasPermission(
        workspaceUser,
        EDIT_PERMISSION_ROLES,
    )

    return (
        <Container
            direction="column"
            flex={1}
            style={{ overflowY: 'scroll', scrollbarWidth: 'thin' }}
        >
            {/* main content */}
            <Container direction="column" flex={1}>
                <TextField
                    error={doValidate && name === ''}
                    helperText={doValidate && name === '' ? 'Required' : ''}
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    //label="Name"
                    style={{ margin: theme.spacing(2) }}
                    disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                    size="small"
                />
                {/* permissions */}
                <Container direction="column">
                    {/* general permissions */}
                    <Container
                        direction="column"
                        style={{ marginLeft: theme.spacing(2) }}
                    >
                        <Container
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            General
                        </Container>
                        {/* use web */}
                        <PermissionCheckBox
                            editedPermissions={editedPermissions}
                            setEditedPermissions={setEditedPermissions}
                            permissibleField={USE_WEB}
                            permissibleFieldDisplayName={'Use Web'}
                            disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                        />

                        {/* edit user */}
                        <PermissionCheckBox
                            editedPermissions={editedPermissions}
                            setEditedPermissions={setEditedPermissions}
                            permissibleField={EDIT_USER}
                            permissibleFieldDisplayName={'Edit User'}
                            disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                        />
                    </Container>

                    {generalPermissionsOpen && (
                        <Container
                            direction="column"
                            style={{ marginLeft: theme.spacing(2) }}
                        >
                            {/* edit permission roles */}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={EDIT_PERMISSION_ROLES}
                                permissibleFieldDisplayName={
                                    'Edit Permission Roles'
                                }
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />
                            {/* edit users permission roles */}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={EDIT_USER_PERMISSIONS}
                                permissibleFieldDisplayName={
                                    'Edit Users Permission Roles'
                                }
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />
                            {/* See Schedule Finances */}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={SEE_VENDOR_FINANCES}
                                permissibleFieldDisplayName="See Schedule Finances"
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />

                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={SEE_INCOME_FINANCES}
                                permissibleFieldDisplayName="See Income Finances"
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />
                            {/* See Action Items */}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={SEE_ACTION_ITEMS}
                                permissibleFieldDisplayName="See Action Items"
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />
                            {/* See Inspections */}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={SEE_INSPECTION}
                                permissibleFieldDisplayName="See Inspections"
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />
                            {/* Approve deny change orders */}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={APPROVE_DENY_CHANGE_ORDER}
                                permissibleFieldDisplayName="Approve / Deny Change Order"
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={VIEW_INVOICE}
                                permissibleFieldDisplayName="View Invoice"
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={VIEW_NATIONAL_VENDOR_LIST}
                                permissibleFieldDisplayName="View National Vendor List"
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={RECEIVE_DAILY_REPORT}
                                permissibleFieldDisplayName="Receive Daily Report"
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={INCIDENT_REPORTS}
                                permissibleFieldDisplayName="Incident Reports"
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />
                            {workspaceUser?.active_workspace.company_type ===
                                'VENDOR' && (
                                <PermissionCheckBox
                                    editedPermissions={editedPermissions}
                                    setEditedPermissions={setEditedPermissions}
                                    permissibleField={
                                        EDIT_VENDOR_SUBSCRIPTION_STATUS
                                    }
                                    permissibleFieldDisplayName="Edit Vendor Subscription Status"
                                    disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                                />
                            )}
                            {workspaceUser?.active_workspace.company_type ===
                                'VENDOR' && (
                                <PermissionCheckBox
                                    editedPermissions={editedPermissions}
                                    setEditedPermissions={setEditedPermissions}
                                    permissibleField={VENDOR_INVOICE_PERMISSION}
                                    permissibleFieldDisplayName="Vendor Invoicing"
                                    disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                                />
                            )}
                        </Container>
                    )}
                    <Container
                        onClick={() => {
                            setGeneralPermissionOpen(!generalPermissionsOpen)
                        }}
                    >
                        <IconButton>
                            {generalPermissionsOpen ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowDown />
                            )}
                        </IconButton>
                    </Container>
                    <Container
                        style={{
                            margin: theme.spacing(0, 2, 2, 2),
                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                        }}
                    />

                    {/* Workorder Permissions */}
                    <Container
                        direction="column"
                        style={{ marginLeft: theme.spacing(2) }}
                    >
                        <Container
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            Workorder
                        </Container>
                        {/* create workorder */}
                        <PermissionCheckBox
                            editedPermissions={editedPermissions}
                            setEditedPermissions={setEditedPermissions}
                            permissibleField={CREATE_WORKORDERS}
                            permissibleFieldDisplayName={'Create Workorders'}
                            disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                        />
                        {/* edit workorder */}
                        <PermissionCheckBox
                            editedPermissions={editedPermissions}
                            setEditedPermissions={setEditedPermissions}
                            permissibleField={EDIT_WORKORDERS}
                            permissibleFieldDisplayName={'Edit Workorders'}
                            disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                        />

                        {/* delete workorder */}
                        <PermissionCheckBox
                            editedPermissions={editedPermissions}
                            setEditedPermissions={setEditedPermissions}
                            permissibleField={DELETE_WORKORDERS}
                            permissibleFieldDisplayName={'Delete Workorders'}
                            disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                        />
                    </Container>

                    <Container
                        style={{
                            margin: theme.spacing(1, 2, 2, 2),
                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                        }}
                    />

                    {/* Workorder Transitions */}
                    <Container
                        direction="column"
                        style={{ marginLeft: theme.spacing(2) }}
                    >
                        <Container
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            Workorder Transitions
                        </Container>
                        {/* assigned to in progress */}
                        <PermissionCheckBox
                            editedPermissions={editedPermissions}
                            setEditedPermissions={setEditedPermissions}
                            permissibleField={ASSIGNED_TO_IN_PROGRESS}
                            permissibleFieldDisplayName={
                                'Assigned - In Progress'
                            }
                            disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                        />

                        {/* in progress to complete*/}
                        <PermissionCheckBox
                            editedPermissions={editedPermissions}
                            setEditedPermissions={setEditedPermissions}
                            permissibleField={IN_PROGRESS_TO_COMPLETE}
                            permissibleFieldDisplayName={
                                'In Progress - Complete'
                            }
                            disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                        />

                        {/* in progress to pause*/}
                        <PermissionCheckBox
                            editedPermissions={editedPermissions}
                            setEditedPermissions={setEditedPermissions}
                            permissibleField={IN_PROGRESS_TO_PAUSE}
                            permissibleFieldDisplayName={'In Progress - Pause'}
                            disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                        />
                    </Container>

                    {workorderTransitionPermissionsOpen && (
                        <Container
                            direction="column"
                            style={{ marginLeft: theme.spacing(2) }}
                        >
                            {/* in progress to assigned*/}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={IN_PROGRESS_TO_ASSIGNED}
                                permissibleFieldDisplayName={
                                    'In Progress - Assigned'
                                }
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />
                            {/* pause to in progress */}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={PAUSE_TO_IN_PROGRESS}
                                permissibleFieldDisplayName={
                                    'Pause - In Progress'
                                }
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />

                            {/* complete to pre approved*/}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={COMPLETE_TO_PRE_APPROVED}
                                permissibleFieldDisplayName={
                                    'Complete - Preapproved'
                                }
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />

                            {/* complete to approved*/}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={COMPLETE_TO_APPROVED}
                                permissibleFieldDisplayName={
                                    'Complete - Approved'
                                }
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />

                            {/* complete to go back*/}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={COMPLETE_TO_GO_BACK}
                                permissibleFieldDisplayName={
                                    'Complete - Go Back'
                                }
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />

                            {/* go back to in progress*/}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={GO_BACK_TO_IN_PROGRESS}
                                permissibleFieldDisplayName={
                                    'Go Back - In Progress'
                                }
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />

                            {/* pre approved to go back*/}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={PRE_APPROVED_TO_GO_BACK}
                                permissibleFieldDisplayName={
                                    'Preapproved - Go Back'
                                }
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />

                            {/* pre approved to approved*/}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={PRE_APPROVED_TO_APPROVED}
                                permissibleFieldDisplayName={
                                    'Preapproved - Approved'
                                }
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />

                            {/* bulk transition */}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={BULK_TRANSITION}
                                permissibleFieldDisplayName={'Bulk Transition'}
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />
                        </Container>
                    )}
                    <Container
                        onClick={() => {
                            setWorkorderTransitionPermissionsOpen(
                                !workorderTransitionPermissionsOpen,
                            )
                        }}
                    >
                        <IconButton>
                            {workorderTransitionPermissionsOpen ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowDown />
                            )}
                        </IconButton>
                    </Container>

                    <Container
                        style={{
                            margin: theme.spacing(0, 2, 2, 2),
                            borderBottom: `1px solid ${theme.palette.grey[400]}`,
                        }}
                    />

                    {/* Damage Transitions */}
                    <Container
                        direction="column"
                        style={{ marginLeft: theme.spacing(2) }}
                    >
                        <Container
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            Damage Transitions
                        </Container>
                        <PermissionCheckBox
                            editedPermissions={editedPermissions}
                            setEditedPermissions={setEditedPermissions}
                            permissibleField={DMG_PENDING_TO_PREAPPROVED}
                            permissibleFieldDisplayName={
                                'Pending - Preapproved'
                            }
                            disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                        />

                        <PermissionCheckBox
                            editedPermissions={editedPermissions}
                            setEditedPermissions={setEditedPermissions}
                            permissibleField={DMG_PENDING_TO_DENIED}
                            permissibleFieldDisplayName={'Pending - Denied'}
                            disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                        />

                        <PermissionCheckBox
                            editedPermissions={editedPermissions}
                            setEditedPermissions={setEditedPermissions}
                            permissibleField={DMG_PREAPPROVED_TO_APPROVED}
                            permissibleFieldDisplayName={
                                'Preapproved - Approved'
                            }
                            disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                        />
                    </Container>
                    {damageOpen && (
                        <Container
                            direction="column"
                            style={{ marginLeft: theme.spacing(2) }}
                        >
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={DMG_PREAPPROVED_TO_DENIED}
                                permissibleFieldDisplayName={
                                    'Preapproved - Denied'
                                }
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />

                            {/* pause to in progress */}
                            <PermissionCheckBox
                                editedPermissions={editedPermissions}
                                setEditedPermissions={setEditedPermissions}
                                permissibleField={DMG_APPROVED_TO_SYNCED}
                                permissibleFieldDisplayName={
                                    'Approved - Synced'
                                }
                                disabled={CAN_NOT_EDIT_PERMISSION_ROLES}
                            />
                        </Container>
                    )}
                    <Container
                        onClick={() => {
                            setDamageOpen(!damageOpen)
                        }}
                    >
                        <IconButton>
                            {damageOpen ? (
                                <KeyboardArrowRight />
                            ) : (
                                <KeyboardArrowDown />
                            )}
                        </IconButton>
                    </Container>
                </Container>
            </Container>
            {/* footer */}
            <Divider />
            <Container
                style={{
                    padding: theme.spacing(2),
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                        handleClose()
                        setEditedPermissions(defaultPermissible)
                        setName('')
                    }}
                >
                    Close
                </Button>
                {!CAN_NOT_EDIT_PERMISSION_ROLES && (
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => onSave()}
                        style={{ marginLeft: theme.spacing(2) }}
                    >
                        {permissionRole ? 'Update' : 'Create'}
                    </Button>
                )}
            </Container>
        </Container>
    )
}

interface PermissionCheckBoxProps {
    editedPermissions: Permissible
    setEditedPermissions: (permissible: Permissible) => void
    permissibleField: keyof Permissible
    permissibleFieldDisplayName: string
    disabled: boolean
}

const PermissionCheckBox = (props: PermissionCheckBoxProps) => {
    const {
        editedPermissions,
        setEditedPermissions,
        permissibleField,
        permissibleFieldDisplayName,
        disabled,
    } = props

    const theme = useTheme()

    return (
        <FormControlLabel
            key={`ROLE_DRAWER_CHECKBOX_${permissibleField}`}
            control={
                <Checkbox
                    checked={editedPermissions[permissibleField] === 1}
                    //style={{ color: theme.palette.primary.main, }}
                    style={{ color: theme.palette.primary.dark }}
                    onClick={() => {
                        const newPermissible: Permissible = {
                            ...editedPermissions,
                            [permissibleField]:
                                editedPermissions[permissibleField] === 0
                                    ? 1
                                    : 0,
                        }
                        setEditedPermissions(newPermissible)
                    }}
                    disabled={disabled}
                />
            }
            label={permissibleFieldDisplayName}
            labelPlacement="end"
            style={{
                fontWeight: 'bold',
            }}
        />
    )
}
