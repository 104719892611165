import {
    Button,
    FormControl,
    FormLabel,
    MenuItem,
    TextField,
} from '@material-ui/core'
import React, { useState } from 'react'
import {
    createStyles,
    makeStyles,
    Theme,
    useTheme,
} from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import EzosLogo from '../../../../assets/ezos-logo-primary-dark.png'
import BackgroundImage from '../../../../assets/login_background.png'
import { Routes, axiosInstance, passwordValidator } from '../../../../helpers'
import { Container, PasswordRequirements } from '../../../../components'
import { toast } from 'react-toastify'

const useQuery = () => {
    const { search } = useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search])
}

export const ForgotPassword = () => {
    const query = useQuery()
    const serverCode = query.get('code')
    const serverEmail = query.get('email')

    const theme = useTheme()
    const [email, setEmail] = useState(serverEmail ?? '')
    const [code, setCode] = useState(serverCode ?? '')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [shownewPasswordBoxes, setShowNewPasswordBoxes] = useState(
        serverCode !== null,
    )

    const history = useHistory()

    const passwordValidResult = passwordValidator(newPassword, confirmPassword)

    const showInstructions = serverCode === null || serverEmail === null

    return (
        <Container
            style={{
                height: '100vh',
                width: '100vw',
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: 'cover',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <Container
                style={{
                    width: '100%',
                    position: 'fixed',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    zIndex: 99999,
                    top: 0,
                }}
            >
                <Container
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 800,
                    }}
                >
                    {/* Logo */}
                    <img
                        src={EzosLogo}
                        style={{
                            padding: theme.spacing(1),
                            height: 61,
                        }}
                    />
                    {/* Menu Items */}
                    <Container
                        style={{
                            justifyContent: 'flex-end',
                            marginRight: theme.spacing(2),
                            color: theme.palette.primary.dark,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        <MenuItem
                            style={{
                                backgroundColor: 'transparent',
                                justifyContent: 'center',
                            }}
                            onClick={() => {
                                history.push(Routes.nationalVendorListFinal)
                            }}
                            onMouseEnter={() => {
                                const nvlLine = document.querySelector<HTMLSpanElement>(
                                    '#nvlLine',
                                )
                                if (nvlLine) {
                                    nvlLine.style.width = '80%'
                                }
                            }}
                            onMouseLeave={() => {
                                const nvlLine = document.querySelector<HTMLSpanElement>(
                                    '#nvlLine',
                                )
                                if (nvlLine) {
                                    nvlLine.style.width = '0'
                                }
                            }}
                        >
                            National Vendor List
                            <span
                                id="nvlLine"
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: 0,
                                    height: '2px',
                                    backgroundColor: theme.palette.primary.dark,
                                    transition: 'width 0.3s ease',
                                }}
                            />
                        </MenuItem>
                        {/* Get Stated Button */}
                        <Button
                            style={{
                                color: 'white',
                                backgroundColor: theme.palette.primary.dark,
                                borderRadius: theme.shape.borderRadius,
                                fontWeight: theme.typography.fontWeightBold,
                                marginLeft: theme.spacing(2),
                                textTransform: 'none',
                                transition:
                                    'background-color 0.3s, color 0.3s, border-color 0.3s',
                                border: `2px solid ${theme.palette.primary.dark}`,
                            }}
                            onClick={() => {
                                history.push(Routes.login)
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = 'white'
                                e.currentTarget.style.color =
                                    theme.palette.primary.dark
                                e.currentTarget.style.borderColor =
                                    theme.palette.primary.dark
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor =
                                    theme.palette.primary.dark
                                e.currentTarget.style.color = 'white'
                            }}
                        >
                            Back to Log-in
                            <ArrowForwardIcon />
                        </Button>
                    </Container>
                </Container>
            </Container>
            <Container
                style={{
                    backgroundColor: 'white',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
                    height: 600,
                    maxWidth: 600,
                    borderRadius: '40px',
                    flexDirection: 'column',
                    padding: theme.spacing(2),
                }}
            >
                <span
                    style={{
                        fontWeight: theme.typography.fontWeightBold,
                        ...theme.typography.h4,
                        color: theme.palette.grey[800],
                    }}
                >
                    Reset Password
                </span>
                {showInstructions && (
                    <form
                        noValidate
                        autoComplete="off"
                        style={{ margin: theme.spacing(1) }}
                    >
                        <span
                            style={{
                                ...theme.typography.body1,
                                color: theme.palette.grey[800],
                            }}
                        >
                            Enter the email associated with your account and
                            we’ll send you an email with instructions to reset
                            your password.
                        </span>
                        <FormControl fullWidth>
                            <TextField
                                id="email"
                                placeholder="Email"
                                variant="outlined"
                                type="email"
                                size="small"
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                style={{ marginTop: theme.spacing(2) }}
                            />
                        </FormControl>
                        <Button
                            disabled={email.trim() === ''}
                            variant="contained"
                            style={{
                                backgroundColor:
                                    email.trim() === ''
                                        ? 'grey'
                                        : theme.palette.primary.dark,
                                color: 'white',
                                textTransform: 'inherit',
                                fontWeight: theme.typography.fontWeightBold,
                                borderRadius: theme.shape.borderRadius,
                                width: '100%',
                                boxShadow: 'none',
                                marginTop: theme.spacing(2),
                                transition:
                                    'background-color 0.3s, color 0.3s, border-color 0.3s',
                                border:
                                    email.trim() === ''
                                        ? 'grey'
                                        : `2px solid ${theme.palette.primary.dark}`,
                            }}
                            onClick={() => {
                                axiosInstance.post('user/forgot_password/', {
                                    set_code: true,
                                    email: email,
                                })
                                alert(
                                    'If the email you provided is registered within our system, a password reset code will be sent.',
                                )
                                setShowNewPasswordBoxes(true)
                            }}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = 'white'
                                e.currentTarget.style.color =
                                    theme.palette.primary.dark
                                e.currentTarget.style.borderColor =
                                    theme.palette.primary.dark
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor =
                                    theme.palette.primary.dark
                                e.currentTarget.style.color = 'white'
                            }}
                        >
                            Send Instructions
                        </Button>
                    </form>
                )}
                <form noValidate autoComplete="off">
                    {shownewPasswordBoxes && (
                        <Container
                            style={{
                                flex: 1,
                                flexDirection: 'column',
                            }}
                        >
                            <Container
                                style={{
                                    flex: 1,
                                }}
                            >
                                <Container
                                    direction="column"
                                    style={{
                                        flex: 1,
                                        marginTop: theme.spacing(2),
                                    }}
                                >
                                    <FormControl fullWidth>
                                        <TextField
                                            id="new-pass"
                                            placeholder="New Password"
                                            variant="outlined"
                                            type="email"
                                            size="small"
                                            onChange={(e) => {
                                                setNewPassword(e.target.value)
                                            }}
                                            style={{ margin: theme.spacing(1) }}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="new-pass-confirm"
                                            placeholder="Confirm Password"
                                            variant="outlined"
                                            type="email"
                                            size="small"
                                            onChange={(e) => {
                                                setConfirmPassword(
                                                    e.target.value,
                                                )
                                            }}
                                            style={{ margin: theme.spacing(1) }}
                                        />
                                    </FormControl>
                                </Container>
                                <PasswordRequirements
                                    passwordRequirementResult={
                                        passwordValidResult
                                    }
                                    containerStyle={{
                                        marginLeft: theme.spacing(2),
                                        justifyContent: 'center',
                                    }}
                                />
                            </Container>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor:
                                        code === '' ||
                                        !passwordValidResult.passwordValid
                                            ? 'grey'
                                            : theme.palette.primary.dark,
                                    color: 'white',
                                    textTransform: 'inherit',
                                    fontWeight: theme.typography.fontWeightBold,
                                    borderRadius: theme.shape.borderRadius,
                                    boxShadow: 'none',
                                    width: '100%',
                                    transition:
                                        'background-color 0.3s, color 0.3s, border-color 0.3s',
                                    border: `2px solid ${theme.palette.primary.dark}`,
                                    marginTop: theme.spacing(1),
                                }}
                                disabled={
                                    code === '' ||
                                    !passwordValidResult.passwordValid
                                }
                                onClick={() => {
                                    axiosInstance
                                        .post('user/forgot_password/', {
                                            set_code: false,
                                            code: code,
                                            email: email,
                                            new_password: newPassword,
                                        })
                                        .then(() => {
                                            history.push(Routes.login)
                                            toast.success('Password Updated')
                                        })
                                        .catch(() => {
                                            toast.error(
                                                'Unable to change your password.  Please make sure the code you entered is correct.',
                                            )
                                        })
                                }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor =
                                        'white'
                                    e.currentTarget.style.color =
                                        theme.palette.primary.dark
                                    e.currentTarget.style.borderColor =
                                        theme.palette.primary.dark
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor =
                                        theme.palette.primary.dark
                                    e.currentTarget.style.color = 'white'
                                }}
                            >
                                Reset Password
                            </Button>
                        </Container>
                    )}
                </form>
            </Container>
        </Container>
    )
}
