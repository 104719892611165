import React, { useEffect, useState } from 'react'
import { ApartmentVendor } from '../NationalPropertyList'
import {
    Button,
    Divider,
    IconButton,
    MenuItem,
    Modal,
    Paper,
    Slide,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { Container, Selector } from '../../components'
import CloseIcon from '@material-ui/icons/Close'
import { axiosInstance } from '../../helpers'
import { useCompany } from '../../hooks'
import { toast } from 'react-toastify'

interface Props {
    apartmentVendors: ApartmentVendor[]
    open: boolean
    entrataVendors: EntrataVendor[]
    entrataGLInfo: EntrataGLInfo[]
    handleClose: () => void
    setApartmentVendors: (apartmentVendors: ApartmentVendor[]) => void
}

export interface EntrataVendor {
    id: number
    vendorName: string
    vendorID: number
}

export interface EntrataLocation {
    id: number
    name: string
}

export interface EntrataGLInfo {
    id: number
    gl_account_id: number
    account_name: string
}

export const ConfigureEntrataDetails = (props: Props) => {
    const {
        apartmentVendors,
        open,
        handleClose,
        setApartmentVendors,
        entrataGLInfo,
        entrataVendors,
    } = props

    const [selectedApartmentVendor, setSelectedApartmentVendor] = useState<
        ApartmentVendor | undefined
    >()
    const [entrataGLID, setEntrataGLID] = useState<number | null>(null)
    const [selectedEntrataVendorID, setSelectedEntrataVendorID] = useState<
        number | null
    >(null)
    const [selectedEntrataLocationID, setSelectedEntrataLocationID] = useState<
        string | null
    >(null)

    const [entrataLocations, setEntrataLocations] = useState<EntrataLocation[]>(
        [],
    )

    const theme = useTheme()

    const { getVendorList } = useCompany()

    useEffect(() => {
        setSelectedApartmentVendor(undefined)
        setEntrataGLID(null)
        setSelectedEntrataVendorID(null)
        setSelectedEntrataLocationID(null)
    }, [open])

    useEffect(() => {
        setEntrataGLID(
            selectedApartmentVendor?.entrata_gl_account_id
                ? selectedApartmentVendor?.entrata_gl_account_id
                : null,
        )
        setSelectedEntrataVendorID(
            selectedApartmentVendor?.entrata_id
                ? selectedApartmentVendor?.entrata_id
                : null,
        )
        setSelectedEntrataLocationID(
            selectedApartmentVendor?.entrata_location_id
                ? selectedApartmentVendor?.entrata_location_id
                : null,
        )
    }, [selectedApartmentVendor])

    useEffect(() => {
        if (selectedEntrataVendorID) {
            const body = {
                entrata_ids: [selectedEntrataVendorID],
            }
            axiosInstance
                .post(
                    `company/vendor/get_entrata_vendor_details_from_ids/`,
                    body,
                )
                .then((res) => {
                    if (res.data.vendors_data[0]) {
                        if (res.data.vendors_data[0].defaultGLAccountID) {
                            setEntrataGLID(
                                res.data.vendors_data[0].defaultGLAccountID,
                            )
                        } else {
                            setEntrataGLID(null)
                        }
                        const tempLocations: EntrataLocation[] = []
                        res.data.vendors_data[0].locations.map(
                            (location: EntrataLocation) => {
                                tempLocations.push({
                                    id: location.id,
                                    name: location.name,
                                })
                                if (
                                    location.id ===
                                    Number(
                                        selectedApartmentVendor?.entrata_location_id,
                                    )
                                ) {
                                    setSelectedEntrataLocationID(
                                        String(location.id),
                                    )
                                }
                            },
                        )
                        tempLocations.sort((locationA, locationB) =>
                            locationA.name.localeCompare(locationB.name),
                        )
                        setEntrataLocations(tempLocations)
                    }
                })
                .catch((e) => toast.error(e.response.data.message))
        }
    }, [selectedEntrataVendorID])

    const saveDetails = () => {
        const body: any = {}
        body.vendor_id = selectedApartmentVendor?.vendor.id
        body.entrata_gl_account_id = entrataGLID
        body.entrata_id = selectedEntrataVendorID
        body.entrata_location_id = selectedEntrataLocationID

        axiosInstance
            .post(`company/apartment_vendors/set_entrata_sync_details/`, body)
            .then(() => {
                toast.success(
                    `${selectedApartmentVendor?.vendor.name} updated successfully!`,
                )
                const tempApartmentVendors: ApartmentVendor[] = []
                apartmentVendors?.forEach((apartmentVendor) => {
                    if (
                        apartmentVendor.vendor.id ===
                        selectedApartmentVendor?.vendor.id
                    ) {
                        tempApartmentVendors.push({
                            ...apartmentVendor,
                            entrata_gl_account_id: entrataGLID,
                            entrata_id: selectedEntrataVendorID,
                            entrata_location_id: selectedEntrataLocationID,
                        })
                    } else {
                        tempApartmentVendors.push(apartmentVendor)
                    }
                })
                if (setApartmentVendors)
                    setApartmentVendors(tempApartmentVendors)
                getVendorList({ params: { my_team: true } })
            })
            .catch((e) => toast.error(e.response.data.message))
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: '800px',
                        height: '500px',
                        maxHeight: 'calc(100vh - 100px)',
                        maxWidth: 'calc(100vh - 100px)',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <Container
                        style={{
                            padding: theme.spacing(3, 0, 4, 2),
                            flex: 1,
                        }}
                        direction="column"
                    >
                        <Container
                            style={{
                                alignItems: 'center',
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: 500,
                                    fontSize: '20px',
                                    flex: 1,
                                }}
                            >
                                Configure Vendors
                            </span>
                            <Tooltip title="Cancel">
                                <IconButton
                                    onClick={() => {
                                        handleClose()
                                    }}
                                >
                                    <CloseIcon
                                        fontSize="inherit"
                                        style={{
                                            marginRight: theme.spacing(1),
                                            color: theme.palette.darkGreen.main,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Container>
                        <Divider
                            style={{ margin: theme.spacing(0, 3, 0, 0) }}
                        />
                        <Container style={{ marginTop: theme.spacing(2) }}>
                            <Container direction="column" flex={1}>
                                <span
                                    style={{
                                        fontWeight: 400,
                                        fontSize: '15px',
                                        color: theme.palette.darkGreen.main,
                                    }}
                                >
                                    Please select a Vendor to edit
                                </span>
                                <Container>
                                    <TextField
                                        variant="outlined"
                                        value={
                                            selectedApartmentVendor?.vendor.name
                                                ? selectedApartmentVendor
                                                      ?.vendor.name
                                                : ''
                                        }
                                        label="Vendor"
                                        size="small"
                                        select
                                        style={{
                                            marginTop: theme.spacing(2.5),
                                            alignContent: 'center',
                                            width: '250px',
                                        }}
                                        onChange={(e) => {
                                            if (apartmentVendors) {
                                                const tempApartmentVendor = apartmentVendors.find(
                                                    (apartmentVendor) =>
                                                        apartmentVendor.vendor
                                                            .name ===
                                                        e.target.value,
                                                )
                                                setSelectedApartmentVendor(
                                                    tempApartmentVendor,
                                                )
                                            }
                                        }}
                                    >
                                        {apartmentVendors?.map(
                                            (apartmentVendor) => {
                                                return (
                                                    <MenuItem
                                                        key={
                                                            apartmentVendor
                                                                .vendor.id
                                                        }
                                                        value={
                                                            apartmentVendor
                                                                .vendor.name
                                                        }
                                                    >
                                                        {
                                                            apartmentVendor
                                                                .vendor.name
                                                        }
                                                        {isVendorSyncReady(
                                                            apartmentVendor,
                                                        ) && (
                                                            <Container
                                                                style={{
                                                                    marginLeft: theme.spacing(
                                                                        1,
                                                                    ),
                                                                    fontWeight: 400,
                                                                    fontSize: 13,
                                                                    color:
                                                                        theme
                                                                            .palette
                                                                            .darkGreen
                                                                            .main,
                                                                }}
                                                            >
                                                                (Sync Ready)
                                                            </Container>
                                                        )}
                                                    </MenuItem>
                                                )
                                            },
                                        )}
                                    </TextField>
                                </Container>
                            </Container>

                            {selectedApartmentVendor && (
                                <Container
                                    direction="column"
                                    flex={2}
                                    style={{ marginLeft: theme.spacing(5) }}
                                >
                                    <Container direction="column">
                                        <Selector
                                            data={[
                                                {
                                                    id: -1,
                                                    vendorName: 'None Selected',
                                                },
                                                ...entrataVendors,
                                            ]}
                                            currentValue={
                                                selectedEntrataVendorID
                                                    ? selectedEntrataVendorID
                                                    : -1
                                            }
                                            onChange={(e) => {
                                                setSelectedEntrataVendorID(
                                                    Number(e.target.value),
                                                )
                                            }}
                                            label="Entrata Vendor"
                                            variant="outlined"
                                            getDisplayString={(d) =>
                                                d.vendorName
                                            }
                                            searchable
                                            customStyle={{
                                                select: { width: '400px' },
                                            }}
                                        />
                                    </Container>

                                    <Container
                                        direction="column"
                                        style={{
                                            margin: theme.spacing(2, 0, 2, 0),
                                        }}
                                    >
                                        <Selector
                                            data={[
                                                {
                                                    id: -1,
                                                    account_name:
                                                        'None Selected',
                                                },
                                                ...entrataGLInfo,
                                            ]}
                                            currentValue={
                                                entrataGLID ? entrataGLID : -1
                                            }
                                            onChange={(e) => {
                                                setEntrataGLID(
                                                    Number(e.target.value),
                                                )
                                            }}
                                            label="Entrata GL Account"
                                            variant="outlined"
                                            getDisplayString={(d) =>
                                                d.account_name
                                            }
                                            searchable
                                            customStyle={{
                                                select: { width: '400px' },
                                            }}
                                        />
                                    </Container>

                                    <Container direction="column">
                                        <Selector
                                            data={[
                                                {
                                                    id: -1,
                                                    name: 'None Selected',
                                                },
                                                ...entrataLocations,
                                            ]}
                                            currentValue={
                                                selectedEntrataLocationID
                                                    ? Number(
                                                          selectedEntrataLocationID,
                                                      )
                                                    : -1
                                            }
                                            onChange={(e) => {
                                                setSelectedEntrataLocationID(
                                                    String(e.target.value),
                                                )
                                            }}
                                            label="Entrata Location"
                                            variant="outlined"
                                            getDisplayString={(d) => d.name}
                                            customStyle={{
                                                select: { width: '400px' },
                                            }}
                                            searchable
                                        />
                                    </Container>
                                </Container>
                            )}
                        </Container>
                        <Container flex={1} />
                        <Container justifyContent="flex-end">
                            {selectedApartmentVendor && (
                                <Button
                                    variant="contained"
                                    style={{
                                        margin: theme.spacing(2, 2, 2, 0),
                                        backgroundColor:
                                            theme.palette.primary.dark,
                                        color: 'white',
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                    }}
                                    disabled={!selectedApartmentVendor}
                                    onClick={() => saveDetails()}
                                >
                                    Save
                                </Button>
                            )}
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

const isVendorSyncReady = (aptVendor: ApartmentVendor) => {
    return (
        aptVendor.entrata_id &&
        aptVendor.entrata_gl_account_id &&
        aptVendor.entrata_location_id
    )
}
