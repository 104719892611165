import {
    Container,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    useTheme,
} from '@material-ui/core'
import { DeleteForever } from '@material-ui/icons'

import React from 'react'
import { statesList } from '../../helpers'
import { useUser } from '../../hooks'
import {
    APARTMENT_EXCLUDE_PARAM,
    APARTMENT_PARAM,
    CHECKLIST_ITEM_EXCLUDE_PARAM,
    CHECKLIST_ITEM_PARAM,
    DAYS_REMAINING_OPERATOR_PARAM,
    DAYS_REMAINING_PARAM,
    FORECASTED_BUDGET_OPERATOR_PARAM,
    FORECASTED_BUDGET_PARAM,
    GetPortfolioScheduleListParams,
    HAS_INSPECTION_PARAM,
    INSPECTION_ASSIGNED_OPERATOR_PARAM,
    INSPECTION_ASSIGNED_PARAM,
    INSPECTION_COMPLETE_OPERATOR_PARAM,
    INSPECTION_COMPLETE_PARAM,
    INSPECTION_HANDLED_OPERATOR_PARAM,
    INSPECTION_HANDLED_PARAM,
    NAME_PARAM,
    SCHEDULE_APPROVED_OPERATOR_PARAM,
    SCHEDULE_APPROVED_PARAM,
    SCHEDULE_COMPLETE_OPERATOR_PARAM,
    SCHEDULE_COMPLETE_PARAM,
    SCHEDULE_INVOICED_OPERATOR_PARAM,
    SCHEDULE_INVOICED_PARAM,
    SET_BUDGET_OPERATOR_PARAM,
    SET_BUDGET_PARAM,
    SET_OVER_FORECASTED_PARAM,
    STATE_EXCLUDE_PARAM,
    STATE_PARAM,
    ZIP_CODE_EXCLUDE_PARAM,
    ZIP_CODE_PARAM,
} from '../../store'
import { Filter } from './PortfolioAnalytics'
import { PreTurnConfigItem } from '../../models'

interface Props {
    filters: Filter[]
    removeFilter: () => void
    filterParam?: string
    setFilterParam: (
        param: keyof GetPortfolioScheduleListParams,
        operatorOptions?: NameValueDict[],
        operatorParam?: keyof GetPortfolioScheduleListParams,
    ) => void
    filterOperatorOptions?: NameValueDict[]
    filterOperatorValue?: string
    setFilterOperatorValue: (value?: string) => void
    filterValue?: string
    setFilterValue: (value?: string) => void
    checkListItemConfigs: PreTurnConfigItem[]
}

export interface NameValueDict {
    name: string
    value: string
}

type FilterOption = {
    name: string
    paramName: keyof GetPortfolioScheduleListParams
    operatorOptions?: NameValueDict[]
    operatorParam?: keyof GetPortfolioScheduleListParams
}
const greaterOrLesserOperatorOptions: NameValueDict[] = [
    {
        name: '>',
        value: 'gt',
    },
    {
        name: '<',
        value: 'lt',
    },
    {
        name: '≥',
        value: 'gte',
    },
    {
        name: '≤',
        value: 'lte',
    },
]

const excludeOperatorOptions: NameValueDict[] = [
    // the true and false may seem backwards, but they are for an exclude statement
    {
        name: '=',
        value: 'false',
    },
    {
        name: '≠',
        value: 'true',
    },
]

export const PortfolioScheduleFilters: FilterOption[] = [
    {
        name: 'Apartment',
        paramName: APARTMENT_PARAM,
        operatorOptions: excludeOperatorOptions,
        operatorParam: APARTMENT_EXCLUDE_PARAM,
    },
    {
        name: 'Zip Code',
        paramName: ZIP_CODE_PARAM,
        operatorOptions: excludeOperatorOptions,
        operatorParam: ZIP_CODE_EXCLUDE_PARAM,
    },
    {
        name: 'Schedule Name',
        paramName: NAME_PARAM,
    },
    {
        name: 'Has Inspection',
        paramName: HAS_INSPECTION_PARAM,
    },
    {
        name: 'State',
        paramName: STATE_PARAM,
        operatorOptions: excludeOperatorOptions,
        operatorParam: STATE_EXCLUDE_PARAM,
    },
    {
        name: 'Days Remaining',
        paramName: DAYS_REMAINING_PARAM,
        operatorOptions: greaterOrLesserOperatorOptions,
        operatorParam: DAYS_REMAINING_OPERATOR_PARAM,
    },
    {
        name: 'Schedule Complete %',
        paramName: SCHEDULE_COMPLETE_PARAM,
        operatorOptions: greaterOrLesserOperatorOptions,
        operatorParam: SCHEDULE_COMPLETE_OPERATOR_PARAM,
    },
    {
        name: 'Schedule Approved %',
        paramName: SCHEDULE_APPROVED_PARAM,
        operatorOptions: greaterOrLesserOperatorOptions,
        operatorParam: SCHEDULE_APPROVED_OPERATOR_PARAM,
    },
    {
        name: 'Schedule Invoiced %',
        paramName: SCHEDULE_INVOICED_PARAM,
        operatorOptions: greaterOrLesserOperatorOptions,
        operatorParam: SCHEDULE_INVOICED_OPERATOR_PARAM,
    },
    {
        name: 'Inspection Complete %',
        paramName: INSPECTION_COMPLETE_PARAM,
        operatorOptions: greaterOrLesserOperatorOptions,
        operatorParam: INSPECTION_COMPLETE_OPERATOR_PARAM,
    },
    {
        name: 'Inspection Assigned %',
        paramName: INSPECTION_ASSIGNED_PARAM,
        operatorOptions: greaterOrLesserOperatorOptions,
        operatorParam: INSPECTION_ASSIGNED_OPERATOR_PARAM,
    },
    {
        name: 'Inspection Handled %',
        paramName: INSPECTION_HANDLED_PARAM,
        operatorOptions: greaterOrLesserOperatorOptions,
        operatorParam: INSPECTION_HANDLED_OPERATOR_PARAM,
    },
    {
        name: 'Set Budget',
        paramName: SET_BUDGET_PARAM,
        operatorOptions: greaterOrLesserOperatorOptions,
        operatorParam: SET_BUDGET_OPERATOR_PARAM,
    },
    {
        name: 'Forecasted Budget',
        paramName: FORECASTED_BUDGET_PARAM,
        operatorOptions: greaterOrLesserOperatorOptions,
        operatorParam: FORECASTED_BUDGET_OPERATOR_PARAM,
    },
    {
        name: 'Set / Forecasted',
        paramName: SET_OVER_FORECASTED_PARAM,
    },
    {
        name: 'Checklist Item',
        paramName: CHECKLIST_ITEM_PARAM,
        operatorOptions: excludeOperatorOptions,
        operatorParam: CHECKLIST_ITEM_EXCLUDE_PARAM,
    },
]

export const PortfolioAnalyticsFilterRow = (props: Props) => {
    const {
        removeFilter,
        setFilterParam,
        setFilterOperatorValue,
        setFilterValue,
        filterParam,
        filterOperatorOptions: filterOperator,
        filterOperatorValue,
        filterValue,
    } = props

    const theme = useTheme()

    const { rootUser } = useUser()

    return (
        <Container>
            {/* filter param selector */}
            <Divider />
            <FormControl
                variant="outlined"
                style={{ margin: theme.spacing(2, 2, 2, 0), flex: 1 }}
            >
                <InputLabel id={`filter-param-select-label`}>Filter</InputLabel>
                <Select
                    labelId={`filter-param-select-label`}
                    id={`filter-param-select`}
                    value={filterParam}
                    onChange={(e) => {
                        const paramName = e.target
                            .value as keyof GetPortfolioScheduleListParams
                        const filter = PortfolioScheduleFilters.find(
                            (opt) => opt.paramName === paramName,
                        )
                        setFilterParam(
                            paramName,
                            filter?.operatorOptions,
                            filter?.operatorParam,
                        )
                    }}
                    label="Filter"
                    style={{ width: 210 }}
                >
                    {PortfolioScheduleFilters.map((option) => {
                        // only return if its not in filters UNLESS its the filterParam
                        const isFiltered = props.filters.find(
                            (filter) => filter.param === option.paramName,
                        )
                        if (
                            isFiltered === undefined ||
                            option.paramName === filterParam
                        ) {
                            return (
                                <MenuItem
                                    key={`FILTER_PARAM_SELECT__${option.paramName}`}
                                    value={option.paramName}
                                >
                                    <span>{option.name}</span>
                                </MenuItem>
                            )
                        }
                    })}
                </Select>
            </FormControl>
            {/* param operator selector */}
            <FormControl
                variant="outlined"
                style={{ margin: theme.spacing(2) }}
            >
                <InputLabel id={`filter-operator-select-label`}>
                    Operator
                </InputLabel>
                <Select
                    disabled={filterOperator === undefined}
                    labelId={`filter-operator-select-label`}
                    id={`filter-operator-select`}
                    value={filterOperatorValue}
                    onChange={(e) => {
                        const operatorValue = e.target.value as string
                        setFilterOperatorValue(operatorValue)
                    }}
                    label="Operator"
                    style={{ width: 110 }}
                >
                    {filterOperator?.map((option) => {
                        return (
                            <MenuItem
                                key={`FILTER_OPERATOR_PARAM_${
                                    filterParam ?? -1
                                }_${option.value} `}
                                value={option.value}
                            >
                                {option.name}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>

            {/* filter value */}
            {filterParam === STATE_PARAM ||
            filterParam === APARTMENT_PARAM ||
            filterParam === HAS_INSPECTION_PARAM ||
            filterParam === SET_OVER_FORECASTED_PARAM ||
            filterParam === CHECKLIST_ITEM_PARAM ? (
                <FormControl
                    variant="outlined"
                    style={{ width: 200, margin: theme.spacing(2) }}
                >
                    <InputLabel id="filter-selector-label">
                        {filterParam === STATE_PARAM && 'State'}
                        {filterParam === APARTMENT_PARAM && 'Apartment'}
                        {filterParam === HAS_INSPECTION_PARAM &&
                            'Has Inspection'}
                        {filterParam === SET_OVER_FORECASTED_PARAM &&
                            'Set / Forecasted'}
                        {filterParam === CHECKLIST_ITEM_PARAM &&
                            'Checklist Item'}
                    </InputLabel>
                    <Select
                        label="Set / Forecasted" //only used for length of area to display label so put lognest option
                        labelId="state-or-apartment-label"
                        value={filterValue}
                        onChange={(e) =>
                            setFilterValue(e.target.value as string)
                        }
                    >
                        {filterParam === STATE_PARAM &&
                            statesList.map((s) => {
                                return (
                                    <MenuItem key={s} value={s}>
                                        {s}
                                    </MenuItem>
                                )
                            })}
                        {filterParam === APARTMENT_PARAM &&
                            rootUser?.workspaces.map((wsUser) => {
                                if (
                                    wsUser.active_workspace.company_type ===
                                    'APARTMENT'
                                ) {
                                    return (
                                        <MenuItem
                                            key={`APARTMENT_SELECTOR_${wsUser.active_workspace.id}`}
                                            value={wsUser.active_workspace.id}
                                        >
                                            {wsUser.active_workspace.name}
                                        </MenuItem>
                                    )
                                }
                            })}
                        {(filterParam === HAS_INSPECTION_PARAM ||
                            filterParam === SET_OVER_FORECASTED_PARAM) && (
                            <MenuItem key={`HAS_INSPECTION_TRUE`} value="true">
                                True
                            </MenuItem>
                        )}
                        {(filterParam === HAS_INSPECTION_PARAM ||
                            filterParam === SET_OVER_FORECASTED_PARAM) && (
                            <MenuItem
                                key={`HAS_INSPECTION_FALSE`}
                                value="false"
                            >
                                False
                            </MenuItem>
                        )}
                        {filterParam === CHECKLIST_ITEM_PARAM &&
                            props.checkListItemConfigs.map((itemConfig) => {
                                return (
                                    <MenuItem
                                        key={`CHECK_LIST_ITEM_${itemConfig.id}_PORTFOLIO`}
                                        value={itemConfig.id}
                                    >
                                        {itemConfig.name}
                                    </MenuItem>
                                )
                            })}
                    </Select>
                </FormControl>
            ) : (
                <TextField
                    style={{ margin: theme.spacing(2) }}
                    variant="outlined"
                    label="Value"
                    value={filterValue}
                    onChange={(e) => setFilterValue(e.target.value)}
                />
            )}

            {/* remove filter button */}
            <IconButton
                style={{ margin: theme.spacing(2) }}
                onClick={removeFilter}
                size="medium"
                edge="start"
            >
                <DeleteForever />
            </IconButton>
        </Container>
    )
}
