import { useEffect, useState } from 'react'
import {
    Schedule,
    SmartScheduleDetails,
    TemplateService,
    TemplateServiceVendor,
    TemplateUnit,
} from '../../models'
import { axiosInstance, getDifferenceInDays, setMidnight } from '../../helpers'
import { toast } from 'react-toastify'

export const useSmartSchedule = (scheduleId: number, portfolio?: boolean) => {
    const [loadingState, setLoadingState] = useState({
        scheduleLoading: false,
        suggestionsLoading: false,
        unitsLoading: false,
    })

    const [schedule, setSchedule] = useState<SmartScheduleDetails | null>(null)

    useEffect(() => {
        getSchedule(scheduleId, portfolio)
    }, [scheduleId])

    const getSchedule = async (id: number, portfolio?: boolean) => {
        setLoadingState({ ...loadingState, scheduleLoading: true })
        return new Promise<Schedule>((resolve, reject) => {
            axiosInstance
                .get(`workorder/schedule/${id}/gantt/`, {
                    params: {
                        portfolio: portfolio,
                    },
                })
                .then((res) => {
                    const schedule = res.data
                    setSchedule(schedule)
                    return resolve(schedule)
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({
                        ...loadingState,
                        scheduleLoading: false,
                    })
                })
        })
    }

    const setTransferDate = async (transfer_date: Date | null) => {
        return new Promise<Schedule>((resolve, reject) => {
            if (schedule === null) {
                setLoadingState({
                    ...loadingState,
                    scheduleLoading: false,
                })
                return reject()
            }
            let date: string | null = null
            if (transfer_date) {
                setMidnight(transfer_date)
                date = transfer_date.toISOString()
            }
            axiosInstance
                .patch(`workorder/schedule/${scheduleId}/`, {
                    transfer_date: transfer_date,
                })
                .then((res) => {
                    setSchedule({
                        ...schedule,
                        transfer_date: date,
                    })
                    return resolve(res.data)
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({
                        ...loadingState,
                        scheduleLoading: false,
                    })
                })
        })
    }

    const setTemplateUnits = async (scheduleId: number, units: number[]) => {
        setLoadingState({ ...loadingState, unitsLoading: true })
        return new Promise<TemplateUnit[]>((resolve, reject) => {
            axiosInstance
                .post(`workorder/template-unit/`, {
                    schedule_id: scheduleId,
                    units: units,
                })
                .then((res) => {
                    const templateUnits = res.data.template_units
                    const service_area_count = res.data.total_service_areas

                    if (schedule) {
                        setSchedule({
                            ...schedule,
                            total_service_areas: service_area_count,
                            template_units: templateUnits,
                        })
                    }

                    return resolve(templateUnits)
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({
                        ...loadingState,
                        unitsLoading: false,
                    })
                })
        })
    }

    const addTemplateService = async (
        scheduleId: number,
        serviceId: number,
        portfolio?: boolean,
    ) => {
        setLoadingState({ ...loadingState, scheduleLoading: true })
        return new Promise((resolve, reject) => {
            axiosInstance
                .post(`workorder/template-service/`, {
                    service: serviceId,
                    schedule_id: scheduleId,
                    portfolio: portfolio,
                })
                .then((res) => {
                    if (schedule === null) return resolve(res.data)

                    setSchedule({
                        ...schedule,
                        template_services: [
                            ...schedule.template_services,
                            res.data,
                        ],
                    })

                    return resolve(res.data)
                })
                .catch((e) => {
                    if (e.response) {
                        if (e.response.status === 409) {
                            toast.error('That service already exists.')
                        }
                    }
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({
                        ...loadingState,
                        scheduleLoading: false,
                    })
                })
        })
    }

    const updateTemplateService = (
        templateServiceId: number,
        update: UpdateTemplateService,
        portfolio?: boolean,
    ) => {
        setLoadingState({ ...loadingState, scheduleLoading: true })
        return new Promise((resolve, reject) => {
            axiosInstance
                .patch(
                    `workorder/template-service/${templateServiceId}/`,
                    {
                        budget: update.budget,
                    },
                    {
                        params: {
                            portfolio: portfolio,
                        },
                    },
                )
                .then((res) => {
                    if (schedule === null) return resolve(res.data)

                    setSchedule((oldTs) => {
                        if (oldTs === null) return null

                        return {
                            ...oldTs,
                            template_services: oldTs.template_services.map(
                                (ts) => {
                                    if (ts.id === templateServiceId) {
                                        return res.data
                                    }

                                    return ts
                                },
                            ),
                        }
                    })

                    return resolve(res.data)
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({
                        ...loadingState,
                        scheduleLoading: false,
                    })
                })
        })
    }

    const deleteTemplateService = (
        templateServiceId: number,
        portfolio?: boolean,
    ) => {
        setLoadingState({ ...loadingState, scheduleLoading: true })
        return new Promise<void>((resolve, reject) => {
            axiosInstance
                .delete(`workorder/template-service/${templateServiceId}/`, {
                    params: {
                        portfolio: portfolio,
                    },
                })
                .then((res) => {
                    if (schedule === null) return resolve(res.data)

                    setSchedule({
                        ...schedule,
                        template_services: schedule.template_services.filter(
                            (ts) => ts.id !== templateServiceId,
                        ),
                    })

                    return resolve()
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({
                        ...loadingState,
                        scheduleLoading: false,
                    })
                })
        })
    }

    const addTemplateServiceVendor = async (
        templateServiceId: number,
        vendorId: number,
    ) => {
        setLoadingState({ ...loadingState, scheduleLoading: true })
        return new Promise((resolve, reject) => {
            axiosInstance
                .post(`workorder/template-service-vendor/`, {
                    vendor: vendorId,
                    template_service: templateServiceId,
                })
                .then((res) => {
                    if (schedule === null) return resolve(res.data)

                    setSchedule({
                        ...schedule,
                        template_services: schedule.template_services.map(
                            (ts) => {
                                if (ts.id === templateServiceId) {
                                    return {
                                        ...ts,
                                        template_service_vendors: [
                                            ...ts.template_service_vendors,
                                            res.data,
                                        ],
                                    }
                                }

                                return ts
                            },
                        ),
                    })
                    return resolve(res.data)
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({
                        ...loadingState,
                        scheduleLoading: false,
                    })
                })
        })
    }

    const updateTemplateServiceVendor = async (
        templateServiceId: number,
        templateServiceVendorId: number,
        body: any,
    ) => {
        setLoadingState({ ...loadingState, scheduleLoading: true })
        return new Promise((resolve, reject) => {
            axiosInstance
                .patch(
                    `workorder/template-service-vendor/${templateServiceVendorId}/`,
                    body,
                )
                .then((res) => {
                    setTemplateVendorState(
                        templateServiceId,
                        templateServiceVendorId,
                        res.data,
                    )
                    return resolve(res.data)
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({
                        ...loadingState,
                        scheduleLoading: false,
                    })
                })
        })
    }

    const setTemplateVendorState = (
        templateServiceId: number,
        templateServiceVendorId: number,
        newVendor: TemplateServiceVendor,
    ) => {
        if (schedule === null) return
        setSchedule({
            ...schedule,
            template_services: schedule.template_services.map((ts) => {
                if (ts.id === templateServiceId) {
                    return {
                        ...ts,
                        template_service_vendors: ts.template_service_vendors.map(
                            (tsv) => {
                                if (tsv.id === templateServiceVendorId) {
                                    return newVendor
                                }
                                return tsv
                            },
                        ),
                    }
                }

                return ts
            }),
        })
    }

    const deleteTemplateServiceVendor = async (
        templateServiceId: number,
        templateServiceVendorId: number,
    ) => {
        setLoadingState({ ...loadingState, scheduleLoading: true })
        return new Promise<void>((resolve, reject) => {
            axiosInstance
                .delete(
                    `workorder/template-service-vendor/${templateServiceVendorId}/`,
                )
                .then((res) => {
                    if (schedule === null) return resolve(res.data)

                    setSchedule({
                        ...schedule,
                        template_services: schedule.template_services.map(
                            (ts) => {
                                if (ts.id === templateServiceId) {
                                    return {
                                        ...ts,
                                        template_service_vendors: ts.template_service_vendors.filter(
                                            (tsv) =>
                                                tsv.id !==
                                                templateServiceVendorId,
                                        ),
                                    }
                                }

                                return ts
                            },
                        ),
                    })
                    return resolve()
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({
                        ...loadingState,
                        scheduleLoading: false,
                    })
                })
        })
    }

    const addGlobalDayOff = async (date: Date) => {
        setLoadingState({ ...loadingState, scheduleLoading: true })

        return new Promise((resolve, reject) => {
            if (schedule === null) {
                setLoadingState({
                    ...loadingState,
                    scheduleLoading: false,
                })
                return reject()
            }

            setMidnight(date)
            const dayOffMS = date.getTime()
            const exists = schedule.day_off_list.find((dayOff) => {
                const existingDayOffDate = new Date(dayOff.date)
                setMidnight(existingDayOffDate)
                const existingDayOffMS = existingDayOffDate.getTime()

                return dayOffMS === existingDayOffMS
            })

            if (exists) return resolve(exists)

            return axiosInstance
                .post(`workorder/day-off/`, {
                    schedule_id: schedule.id,
                    date: date.toISOString(),
                })
                .then((res) => {
                    setSchedule({
                        ...schedule,
                        day_off_list: [...schedule.day_off_list, res.data],
                    })

                    return resolve(res.data)
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({
                        ...loadingState,
                        scheduleLoading: false,
                    })
                })
        })
    }

    const removeGlobalDayOff = (date: Date) => {
        setLoadingState({ ...loadingState, scheduleLoading: true })

        return new Promise<void>((resolve, reject) => {
            if (schedule === null) {
                setLoadingState({
                    ...loadingState,
                    scheduleLoading: false,
                })
                return reject()
            }

            setMidnight(date)
            const dayOffMS = date.getTime()

            const foundDayOff = schedule.day_off_list.find((dayOff) => {
                const existingDayOffDate = new Date(dayOff.date)
                setMidnight(existingDayOffDate)
                const existingDayOffMS = existingDayOffDate.getTime()

                return dayOffMS === existingDayOffMS
            })

            if (!foundDayOff) return resolve()

            return axiosInstance
                .delete(`workorder/day-off/${foundDayOff.id}/`)
                .then(() => {
                    setSchedule({
                        ...schedule,
                        day_off_list: schedule.day_off_list.filter((dayOff) => {
                            return foundDayOff.id !== dayOff.id
                        }),
                    })

                    return resolve()
                })
                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({
                        ...loadingState,
                        scheduleLoading: false,
                    })
                })
        })
    }

    const toggleVendorDayOff = (
        templateServiceId: number,
        templateVendorId: number,
        date: Date,
    ) => {
        setLoadingState({ ...loadingState, scheduleLoading: true })

        return new Promise((resolve, reject) => {
            if (schedule === null) {
                setLoadingState({
                    ...loadingState,
                    scheduleLoading: false,
                })
                return reject()
            }

            setMidnight(date)
            const dateMs = date.getTime()

            const ts = schedule.template_services.find(
                (ts) => ts.id === templateServiceId,
            )
            if (ts === undefined) return reject()

            const tsv = ts.template_service_vendors.find(
                (tsv) => tsv.id === templateVendorId,
            )
            if (tsv === undefined) return reject()

            const foundDayOff = tsv.day_off_list.find((dayOff) => {
                const dayOffDate = new Date(dayOff.date)
                setMidnight(dayOffDate)
                const dayOffMs = dayOffDate.getTime()
                return dayOffMs === dateMs
            })

            if (foundDayOff === undefined) {
                // Handle Add
                return axiosInstance
                    .post(`workorder/day-off/`, {
                        template_service_vendor_id: templateVendorId,
                        date: date.toISOString(),
                    })
                    .then((res) => {
                        setSchedule({
                            ...schedule,
                            template_services: schedule.template_services.map(
                                (ts) => {
                                    if (ts.id === templateServiceId) {
                                        return {
                                            ...ts,
                                            template_service_vendors: ts.template_service_vendors.map(
                                                (tsv) => {
                                                    if (
                                                        tsv.id ===
                                                        templateVendorId
                                                    ) {
                                                        return {
                                                            ...tsv,
                                                            day_off_list: [
                                                                ...tsv.day_off_list,
                                                                res.data,
                                                            ],
                                                        }
                                                    }

                                                    return tsv
                                                },
                                            ),
                                        }
                                    }
                                    return ts
                                },
                            ),
                        })

                        return resolve(res.data)
                    })
                    .catch((e) => {
                        reject(e)
                    })
                    .finally(() => {
                        setLoadingState({
                            ...loadingState,
                            scheduleLoading: false,
                        })
                    })
            } else {
                // Handle Remove
                axiosInstance
                    .delete(`workorder/day-off/${foundDayOff.id}/`)
                    .then((res) => {
                        setSchedule({
                            ...schedule,
                            template_services: schedule.template_services.map(
                                (ts) => {
                                    if (ts.id === templateServiceId) {
                                        return {
                                            ...ts,
                                            template_service_vendors: ts.template_service_vendors.map(
                                                (tsv) => {
                                                    if (
                                                        tsv.id ===
                                                        templateVendorId
                                                    ) {
                                                        return {
                                                            ...tsv,
                                                            day_off_list: tsv.day_off_list.filter(
                                                                (dayOff) =>
                                                                    dayOff.id !==
                                                                    foundDayOff.id,
                                                            ),
                                                        }
                                                    }

                                                    return tsv
                                                },
                                            ),
                                        }
                                    }
                                    return ts
                                },
                            ),
                        })

                        return resolve(res.data)
                    })
                    .catch((e) => {
                        reject(e)
                    })
                    .finally(() => {
                        setLoadingState({
                            ...loadingState,
                            scheduleLoading: false,
                        })
                    })
            }
        })
    }

    const createProjectPlan = async (scheduleId: number) => {
        setLoadingState({ ...loadingState, suggestionsLoading: true })
        return new Promise<any>((resolve, reject) => {
            axiosInstance
                .post(`workorder/schedule/${scheduleId}/create_project_plan/`, {
                    template_schedule: scheduleId,
                })
                .then((res) => {
                    return resolve(res.data)
                })

                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({
                        ...loadingState,
                        suggestionsLoading: false,
                    })
                })
        })
    }

    const getDayOffMap = (): DayOffMap => {
        if (schedule === null) return {}

        const dayOffMap: DayOffMap = {}

        schedule.day_off_list.forEach((dayOff) => {
            const dayOffDate = new Date(dayOff.date)
            const dayOffMs = dayOffDate.getTime()
            dayOffMap[dayOffMs] = 'global'
        })

        return dayOffMap
    }

    const getVendorWorkdayCount = (
        vendor: TemplateServiceVendor,
        dayOffMap: DayOffMap,
    ) => {
        const vendorStartDate = new Date(vendor.start_date)
        const startMs = vendorStartDate.getTime()
        const vendorEndDate = new Date(vendor.end_date)
        const endMs = vendorEndDate.getTime()

        let vendorWorkDays =
            getDifferenceInDays(vendorEndDate, vendorStartDate, true) + 1

        schedule?.day_off_list.forEach((globalDayOff) => {
            const dayOff = new Date(globalDayOff.date)
            setMidnight(dayOff)
            const dayOffMs = dayOff.getTime()

            if (dayOffMs >= startMs && dayOffMs <= endMs) {
                vendorWorkDays -= 1
            }
        })

        vendor.day_off_list.forEach((dayOff) => {
            const vendorDayOff = new Date(dayOff.date)
            setMidnight(vendorDayOff)
            const dayOffMs = vendorDayOff.getTime()

            if (dayOffMap[dayOffMs] === undefined) {
                vendorWorkDays -= 1
            }
        })

        return vendorWorkDays
    }
    const addLineItem = async (
        scheduleId: number,
        description: string,
        cost: number,
    ) => {
        setLoadingState({ ...loadingState, scheduleLoading: true })
        return new Promise((resolve, reject) => {
            axiosInstance
                .post(`workorder/schedule-budget-item/`, {
                    schedule_id: scheduleId,
                    description: description,
                    cost: cost,
                })
                .then((res) => {
                    if (schedule === null) return resolve(res.data)

                    setSchedule({
                        ...schedule,
                        line_items: [...schedule.line_items, res.data],
                    })

                    return resolve(res.data)
                })
                .catch((e) => {
                    if (e.response) {
                        if (e.response.status === 409) {
                            toast.error('That line item already exists.')
                        }
                    }
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({
                        ...loadingState,
                        scheduleLoading: false,
                    })
                })
        })
    }

    const deleteLineItem = async (id: number) => {
        setLoadingState({ ...loadingState, scheduleLoading: true })
        return new Promise((resolve, reject) => {
            axiosInstance
                .delete(`workorder/schedule-budget-item/${id}`)
                .then((res) => {
                    if (schedule === null) return resolve(res.data)

                    setSchedule({
                        ...schedule,
                        line_items: schedule.line_items.filter(
                            (li) => li.id !== id,
                        ),
                    })

                    return resolve(res.data)
                })

                .catch((e) => {
                    reject(e)
                })
                .finally(() => {
                    setLoadingState({
                        ...loadingState,
                        scheduleLoading: false,
                    })
                })
        })
    }

    return {
        getSchedule,
        setTransferDate,
        setTemplateUnits,
        addTemplateService,
        deleteTemplateService,
        addTemplateServiceVendor,
        updateTemplateServiceVendor,
        setTemplateVendorState,
        deleteTemplateServiceVendor,
        schedule,
        loadingState,
        updateTemplateService,
        setSchedule,
        addGlobalDayOff,
        removeGlobalDayOff,
        toggleVendorDayOff,
        createProjectPlan,
        getDayOffMap,
        getVendorWorkdayCount,
        addLineItem,
        deleteLineItem,
    }
}

export type SmartScheduleController = ReturnType<typeof useSmartSchedule>

type DayOffType = 'global' | 'vendor'
type DayOffMap = {
    [dateMs: number]: DayOffType | undefined
}
type UpdateTemplateService = {
    budget: number | null
}
