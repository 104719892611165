import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    useTheme,
} from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'

interface Props {
    url: string
    uuid: string
    height: number
    width: number
    title: string
}

export const Vidyard = (props: Props) => {
    const { url, uuid, height, width, title } = props

    const theme = useTheme()

    const [expand, setExpand] = useState(false)

    useEffect(() => {
        const head = document.querySelector('head')
        const script = document.createElement('script')
        script.setAttribute('src', 'https://play.vidyard.com/embed/v4.js')
        script.async = true
        head?.appendChild(script)
    }, [])

    return (
        <Accordion style={{ width: '100%' }}>
            <AccordionSummary>
                <IconButton
                    onClick={() => setExpand(expand)}
                    size="medium"
                    edge="start"
                    style={{ marginRight: theme.spacing(1) }}
                >
                    {expand ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </IconButton>
                <span
                    style={{
                        ...theme.typography.button,
                        margin: theme.spacing(2),
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {title}
                </span>
            </AccordionSummary>
            <AccordionDetails>
                <img
                    className="vidyard-player-embed"
                    src={url}
                    data-v="4"
                    data-type="inline"
                    data-uuid={uuid}
                    data-playlist_start_open={1}
                    data-height={height}
                    data-width={width}
                />
            </AccordionDetails>
        </Accordion>
    )
}
