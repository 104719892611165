import React from 'react'
import { FormControlLabel, Switch, useTheme } from '@material-ui/core'
import { Container, EditSpan } from '../../components'
import { useAppDispatch } from '../../hooks'
import { UnitConfig } from '../../models'

import { useInfrastructureConfigStyles } from './useInfrastructureConfigStyles'
import { updateUnitConfigReq } from '../../store'

interface Props {
    unitConfig: UnitConfig
}

export const UnitConfigRow = (props: Props) => {
    const { unitConfig } = props

    const styles = useInfrastructureConfigStyles()
    const theme = useTheme()
    const dispatch = useAppDispatch()

    return (
        <Container
            style={{
                ...styles.rowStyle,
                ...styles.panelBorder,
                backgroundColor: theme.palette.grey[200],
                alignItems: 'center',
            }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    marginRight: theme.spacing(1),
                }}
            >
                <EditSpan
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                    editIconTooltip="Edit Name"
                    onSave={(name) => {
                        dispatch(
                            updateUnitConfigReq({
                                id: unitConfig.id,
                                body: {
                                    name: name,
                                },
                            }),
                        )
                    }}
                >
                    {unitConfig.name}
                </EditSpan>
                <FormControlLabel
                    labelPlacement="end"
                    label="Shows on Schedule"
                    style={{ marginRight: theme.spacing(2) }}
                    control={
                        <Switch
                            checked={unitConfig.shows_on_schedule}
                            color="primary"
                            onClick={() => {
                                dispatch(
                                    updateUnitConfigReq({
                                        id: unitConfig.id,
                                        body: {
                                            shows_on_schedule: !unitConfig.shows_on_schedule,
                                        },
                                    }),
                                )
                            }}
                        />
                    }
                />
            </Container>

            <div style={{ flex: 1 }} />
        </Container>
    )
}
