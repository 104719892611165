import React from 'react'

import {
    FormControl,
    InputLabel,
    Select,
    Input,
    MenuItem,
    Chip,
} from '@material-ui/core'
import { IdentifiableNamedObject } from '../../models'
import { useMultiSelectStyles } from './styles'

interface Props {
    label: string
    keyIdentifier: string
    options: IdentifiableNamedObject[]
    selectedOptions: number[]
    onChange: (newOptions: number[]) => void
    disabled?: boolean
    style?: {
        formControl: React.CSSProperties
    }
}

export const MultiSelector = (props: Props) => {
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedOptions = event.target.value as number[]
        props.onChange(selectedOptions)
    }

    const classes = useMultiSelectStyles()

    return (
        <FormControl
            className={classes.formControl}
            style={props.style?.formControl}
        >
            <InputLabel>{props.label}</InputLabel>
            <Select
                multiple
                value={props.selectedOptions}
                onChange={handleChange}
                input={<Input />}
                disabled={props.disabled}
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {(selected as number[]).map((value) => {
                            const option = props.options.find(
                                (opt) => opt.id === value,
                            )
                            return (
                                <Chip
                                    key={`${props.keyIdentifier}_CHIP_${
                                        option ? option.id : -1
                                    }`}
                                    label={option ? option.name : 'unknown'}
                                    className={classes.chip}
                                />
                            )
                        })}
                    </div>
                )}
            >
                {props.options.map((opt) => {
                    return (
                        <MenuItem
                            key={`${props.keyIdentifier}_MENU_ITEM_${opt.id}`}
                            value={opt.id}
                        >
                            {opt.name}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}
