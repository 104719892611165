import React, { useState } from 'react'

import {
    FormControlLabel,
    IconButton,
    Switch,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import AccountTreeIcon from '@material-ui/icons/AccountTree'

import { Container, EditSpan, Selector } from '../../components'
import { useAppDispatch } from '../../hooks'
import { InventoryConfig, StatusGroup } from '../../models'
import { updateInventoryConfigReq } from '../../store'

import { useInfrastructureConfigStyles } from './useInfrastructureConfigStyles'
import { PriorityHigh } from '@material-ui/icons'

interface Props {
    inventoryConfig: InventoryConfig
    statusGroupList?: StatusGroup[]
    selected: boolean
    onClickTree: (id: number) => void
    onClickCost: (invConfig: InventoryConfig) => void
    onClickInfrastructure: (id: Number) => void
    onClickIssue: (id: number) => void
}

export const _InventoryConfigRow = (props: Props) => {
    const { inventoryConfig, selected } = props

    const styles = useInfrastructureConfigStyles()
    const theme = useTheme()

    const [shortAnswer, setShortAnswer] = useState(inventoryConfig.ins_has_note)

    const [inspectionScore, setInspectionScore] = useState(
        inventoryConfig.inspection_score,
    )

    const dispatch = useAppDispatch()
    const [statusGroupId, setStatusGroupId] = useState(
        inventoryConfig.status_group?.id ?? -1,
    )

    let userFriendlySKU = 'Add SKU'
    if (inventoryConfig.sku !== '') {
        userFriendlySKU = `SKU: ${inventoryConfig.sku}`
    }

    let userFriendlyDescription = 'Add Description'
    if (inventoryConfig.description !== '') {
        userFriendlyDescription = inventoryConfig.description
    }

    const updateInventoryConfig = (key: string, value: string) => {
        const invConfigFormData = new FormData()
        invConfigFormData.append(key, value)
        dispatch(
            updateInventoryConfigReq({
                id: inventoryConfig.id,
                req: invConfigFormData,
            }),
        )
    }

    return (
        <Container
            style={{
                ...styles.rowStyle,
                ...styles.panelBorder,

                backgroundColor: selected
                    ? theme.palette.primary.light
                    : theme.palette.grey[200],
            }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    marginRight: theme.spacing(1),
                }}
            >
                <EditSpan
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                    maxLength={30}
                    editIconTooltip="Edit Name"
                    onSave={(name) => {
                        updateInventoryConfig('name', name)
                    }}
                >
                    {inventoryConfig.name}
                </EditSpan>

                <FormControlLabel
                    control={
                        <Switch
                            checked={shortAnswer}
                            color="primary"
                            onChange={() => {
                                setShortAnswer(!shortAnswer)
                                updateInventoryConfig(
                                    'ins_has_note',
                                    String(!shortAnswer),
                                )
                            }}
                        />
                    }
                    labelPlacement="end"
                    label="Short Answer Question"
                />
                {!shortAnswer && (
                    <EditSpan
                        style={{
                            ...theme.typography.body1,
                            marginTop: theme.spacing(1),
                        }}
                        editIconTooltip="Choose Status Group"
                        onSave={() => {
                            updateInventoryConfig(
                                'status_group',
                                statusGroupId.toString(),
                            )
                        }}
                        customPopoverContent={
                            <Container style={{ flexDirection: 'column' }}>
                                <Selector
                                    label="Status Group"
                                    currentValue={statusGroupId}
                                    data={props.statusGroupList ?? []}
                                    customStyle={{
                                        formControl: { margin: 0, width: 200 },
                                    }}
                                    onChange={(
                                        event: React.ChangeEvent<{
                                            value: unknown
                                        }>,
                                    ) => {
                                        const newDefaultGroup = props.statusGroupList?.find(
                                            (s) =>
                                                s.id ===
                                                (event.target.value as number),
                                        )
                                        setStatusGroupId(
                                            newDefaultGroup?.id ?? -1,
                                        )
                                    }}
                                    getDisplayString={(s: StatusGroup) =>
                                        `${s.name}`
                                    }
                                />
                            </Container>
                        }
                    >
                        {inventoryConfig.status_group?.name ??
                            'Add Status Group'}
                    </EditSpan>
                )}

                <EditSpan
                    style={{
                        ...theme.typography.body1,
                        marginTop: theme.spacing(1),
                    }}
                    editIconTooltip="Edit SKU"
                    maxLength={50}
                    onSave={(sku) => {
                        updateInventoryConfig('sku', sku)
                    }}
                >
                    {userFriendlySKU}
                </EditSpan>
            </Container>

            <Container style={{ flexDirection: 'column' }}>
                <EditSpan
                    style={{ ...theme.typography.body1 }}
                    onSave={(description) => {
                        updateInventoryConfig('description', description)
                    }}
                >
                    {userFriendlyDescription}
                </EditSpan>
            </Container>

            <Container style={{ flexDirection: 'column' }}>
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Max inspection score
                </span>
                <TextField
                    value={inspectionScore}
                    onChange={(e) => {
                        setInspectionScore(Number(e.target.value))
                    }}
                    onBlur={(e) => {
                        updateInventoryConfig(
                            'inspection_score',
                            inspectionScore.toString(),
                        )
                    }}
                />
            </Container>

            <div style={{ flex: 1 }} />

            <Container style={{ flexDirection: 'column' }}>
                <Tooltip title="Add to Areas">
                    <IconButton
                        onClick={() => props.onClickTree(inventoryConfig.id)}
                    >
                        <AccountTreeIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Configure Costs">
                    <IconButton
                        onClick={() => props.onClickCost(inventoryConfig)}
                    >
                        <LocalAtmIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Configure Issues">
                    <IconButton
                        onClick={() => props.onClickIssue(inventoryConfig.id)}
                    >
                        <PriorityHigh />
                    </IconButton>
                </Tooltip>
            </Container>
        </Container>
    )
}

export const InventoryConfigRow = React.memo(_InventoryConfigRow)
