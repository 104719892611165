import React from 'react'
import { Container } from '../../../components'
import { Inspection, InspectionType, Schedule } from '../../../models'
import {
    GetScheduleListActionThunk,
    GetScheduleListRequest,
} from '../../../store'
import { Divider, IconButton, Tooltip, useTheme } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { InspectionForm } from '../InspectionForm'

interface Props {
    open: boolean
    selectedInspection?: Inspection
    inspectionTypeList: InspectionType[]
    scheduleList: Schedule[]
    getScheduleList: (req: GetScheduleListRequest) => GetScheduleListActionThunk
    handleClose: () => void
}

export const CreateInspectionTab = (props: Props) => {
    const {
        open,
        selectedInspection,
        inspectionTypeList,
        scheduleList,
        getScheduleList,
        handleClose,
    } = props

    const theme = useTheme()

    return (
        <Container flex={1} direction="column">
            <Container
                alignItems="center"
                style={{ marginBottom: theme.spacing(2) }}
            >
                <Container
                    style={{
                        fontWeight: 600,
                        fontSize: '20px',
                        flex: 1,
                    }}
                >
                    Step 3: Create Inspection
                </Container>
                <Tooltip title="Close">
                    <IconButton
                        onClick={() => {
                            handleClose()
                        }}
                    >
                        <CloseIcon
                            fontSize="inherit"
                            style={{
                                color: theme.palette.darkGreen.main,
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </Container>

            <Divider />

            <Container
                direction="column"
                style={{
                    maxHeight: '720px',
                    height: 'calc(75vh)',
                    overflowY: 'auto',
                    paddingRight: theme.spacing(1),
                }}
            >
                <InspectionForm
                    open={open}
                    handleClose={handleClose}
                    inspectionTypeList={inspectionTypeList}
                    scheduleList={scheduleList}
                    getScheduleList={getScheduleList}
                    selectedInspection={selectedInspection}
                    modalMode
                />
            </Container>
        </Container>
    )
}
