import React, { useState } from 'react'
import clsx from 'clsx'

import {
    AppBar,
    IconButton,
    Toolbar,
    Typography,
    Badge,
    MenuItem,
    Menu,
    useTheme,
} from '@material-ui/core'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import {
    Menu as MenuIcon,
    Notifications as NotificationsIcon,
    MoreVert as MoreIcon,
    ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'

import { useUser } from '../../../../hooks'

import SwipeableSideBar from '../sidebar/SwipeableSideBar'
import WorkSpaceSelection from './WorkSpaceSelection'

import Profile from '../../../../assets/icons/profile.png'
import { useHistory } from 'react-router-dom'
import { Routes } from '../../../config/routes'
import { AvatarCircle } from '../../../../components'

interface Props {
    open: Boolean
    drawerWidth: number
}
const TopNavBar = (props: any) => {
    const { logout } = useUser()
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            menuToggleButton: {
                marginRight: 0,
                marginLeft: 0,
                [theme.breakpoints.up('sm')]: {
                    display: 'none',
                },
            },
            hide: {
                display: 'none',
            },
            appBar: {
                top: 0,
                left: 0,
                right: 0,
                position: 'fixed',
                height: '56px !important',
                // boxShadow: 'none',
            },
            appBarShift: {
                marginLeft: props.drawerWidth,
                width: `calc(100% - ${props.drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                [theme.breakpoints.down('xs')]: {
                    width: '100%',
                    marginLeft: 0,
                },
            },
            toolbar: {
                left: `${props.drawerWidth}px`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: theme.spacing(0, 1),
                // necessary for content to be below app bar
                ...theme.mixins.toolbar,
            },
            grow: {
                flexGrow: 1,
            },

            sectionDesktop: {
                display: 'none',
                [theme.breakpoints.up('md')]: {
                    display: 'flex',
                },
            },
            sectionMobile: {
                display: 'flex',
                [theme.breakpoints.up('md')]: {
                    display: 'none',
                },
            },
            profilePic: {
                display: 'flex',
            },
        }),
    )

    const history = useHistory()
    const classes = useStyles()

    const { workspaceUser, rootUser } = useUser()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const [
        mobileMoreAnchorEl,
        setMobileMoreAnchorEl,
    ] = React.useState<null | HTMLElement>(null)

    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget)
    }

    const menuId = 'primary-search-account-menu'
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
            {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
            <MenuItem
                onClick={() => {
                    logout()
                    history.push(Routes.login)
                }}
            >
                Logout
            </MenuItem>
        </Menu>
    )

    const mobileMenuId = 'primary-search-account-menu-mobile'
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/* <MenuItem>
                <IconButton
                    aria-label="show 11 new notifications"
                    color="inherit"
                >
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem> */}
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AvatarCircle
                        text={rootUser?.name}
                        initials
                        image={rootUser?.profile_picture}
                        styles={{
                            container: {
                                maxHeight: '30px',
                                maxWidth: '30px',
                                minHeight: 'auto',
                                minWidth: 'auto',
                                fontSize: '1rem',
                                padding: '16px',
                            },
                        }}
                    />
                    {/* <AccountCircle /> */}
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    )

    const [openSwipeDrawer, setOpenSwipeDrawer] = useState(false)

    const toggleSwipeDrawer = () => {
        setOpenSwipeDrawer(!openSwipeDrawer)
    }

    const theme = useTheme()

    return (
        <React.Fragment>
            <AppBar
                style={{
                    backgroundColor:
                        workspaceUser?.active_workspace.company_type ===
                        'VENDOR'
                            ? theme.palette.darkBlue.main
                            : '#008c85',
                }}
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar
                    style={{
                        minHeight: '56px',
                        maxHeight: '56px',
                        paddingLeft: 0,
                    }}
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleSwipeDrawer}
                        edge="start"
                        className={clsx(classes.menuToggleButton, {
                            [classes.hide]: props.open,
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <SwipeableSideBar
                        openSwipeDrawer={openSwipeDrawer}
                        toggleSwipeDrawer={toggleSwipeDrawer}
                    />
                    {rootUser?.workspaces.length !== 0 && (
                        <WorkSpaceSelection />
                    )}

                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        {/* <IconButton
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            <Badge badgeContent={17} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton> */}
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <Typography style={{ marginRight: '5px' }}>
                                {rootUser?.name}
                            </Typography>
                            <AvatarCircle
                                text={rootUser?.name}
                                initials
                                image={rootUser?.profile_picture}
                                styles={{
                                    container: {
                                        maxHeight: 45,
                                        maxWidth: 45,
                                        minHeight: 'auto',
                                        minWidth: 'auto',
                                        fontSize: '1rem',
                                    },
                                }}
                            />
                            {/* <img src={Profile} alt="Profile" /> */}
                            {/* <AccountCircle /> */}
                            <ExpandMoreIcon style={{ marginLeft: '5px' }} />
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </React.Fragment>
    )
}

export default TopNavBar
