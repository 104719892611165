import React, { useEffect, useState } from 'react'

import {
    useTheme,
    Modal,
    Slide,
    Paper,
    IconButton,
    Tooltip,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Container, LoadingButton, LocationForm } from '../../../../components'
import { TemplateScheduleGantt } from '../../../../components/TemplateScheduleGantt'
import { Folder, ListVendor, Schedule, Service } from '../../../../models'

import { SmartScheduleController, useFinderSelection } from '../../../../hooks'

interface Props {
    open: boolean
    serviceList: Service[]
    vendorList: ListVendor[]
    infrastructure: Folder
    scheduleController: SmartScheduleController
    reloadScheduleWorkorders: () => void
    onClose: () => void
}

export const SmartScheduleModal = (props: Props) => {
    const {
        open,
        onClose,
        infrastructure,
        scheduleController,
        reloadScheduleWorkorders,
    } = props

    const schedule = scheduleController.schedule

    const [dialogOpen, setDialogOpen] = useState(false)

    const theme = useTheme()

    const {
        finderSelection,
        selectLocation,
        setSelection,
    } = useFinderSelection()

    const loadPage = () => {
        if (schedule === null || schedule === undefined) return

        const selectedUnits = schedule.template_units
            .filter((tu) => tu.is_included)
            .map((filteredUnit) => filteredUnit.unit)
        setSelection(selectedUnits)
    }

    const createProjectPlan = () => {
        const schedule = scheduleController.schedule
        if (schedule) {
            scheduleController.createProjectPlan(schedule.id).then(() => {
                setDialogOpen(false)
                reloadScheduleWorkorders()
            })
        }
    }

    useEffect(() => {
        if (open) {
            loadPage()
        }
    }, [open])

    if (schedule === undefined) return null

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        borderRadius: theme.shape.borderRadius,
                        maxHeight: 'calc(100vh - 100px)',
                        minHeight: 'calc(100vh - 100px)',
                        maxWidth: 'calc(100vw - 100px)',
                        minWidth: 'calc(100vw - 100px)',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                    }}
                >
                    <Container
                        style={{
                            flex: 1,
                        }}
                        direction="column"
                    >
                        {/* Header */}
                        <Container
                            style={{
                                height: 100,
                                borderBottom: `1px solid ${theme.palette.grey[400]}`,
                                alignItems: 'center',

                                padding: theme.spacing(1),
                            }}
                        >
                            <div style={{ flex: 1 }} />

                            <LoadingButton
                                variant="contained"
                                color="primary"
                                style={{
                                    textTransform: 'none',
                                    width: 180,
                                }}
                                loading={
                                    scheduleController.loadingState
                                        .suggestionsLoading
                                }
                                onClick={() => {
                                    setDialogOpen(true)
                                }}
                            >
                                Create a project plan
                            </LoadingButton>

                            <div>
                                <Tooltip title="Close">
                                    <IconButton onClick={props.onClose}>
                                        <CloseIcon fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Container>

                        {/* Body */}
                        <Grid container>
                            {/* Finder */}
                            <Grid
                                xs={2}
                                item
                                style={{
                                    overflowY: 'scroll',
                                    maxHeight: 'calc(100vh - 200px)',
                                    minHeight: 'calc(100vh - 200px)',

                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Container
                                    style={{
                                        height: 50,
                                        padding: theme.spacing(2),
                                        paddingBottom: 0,
                                    }}
                                >
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            textTransform: 'none',
                                            width: '100%',
                                        }}
                                        loading={
                                            scheduleController.loadingState
                                                .unitsLoading
                                        }
                                        onClick={() => {
                                            if (
                                                scheduleController.schedule ===
                                                null
                                            )
                                                return

                                            const unitList: number[] = []
                                            Object.keys(
                                                finderSelection.unit,
                                            ).forEach((unitIdStr) => {
                                                if (unitIdStr === 'length')
                                                    return

                                                const unitId = Number(unitIdStr)
                                                if (
                                                    finderSelection.unit[unitId]
                                                ) {
                                                    unitList.push(
                                                        Number(unitIdStr),
                                                    )
                                                }
                                            })

                                            scheduleController.setTemplateUnits(
                                                scheduleController.schedule.id,
                                                unitList,
                                            )
                                        }}
                                    >
                                        Submit Units (
                                        {finderSelection.unit.length})
                                    </LoadingButton>
                                </Container>

                                <LocationForm
                                    root={infrastructure}
                                    locationSelection={finderSelection}
                                    setLocationSelection={selectLocation}
                                    useCheckbox
                                    style={{ padding: theme.spacing(2) }}
                                />
                            </Grid>

                            <Grid xs={10}>
                                <TemplateScheduleGantt
                                    {...props}
                                    theme={theme}
                                    scheduleController={scheduleController}
                                />
                            </Grid>
                        </Grid>

                        <Dialog
                            open={dialogOpen}
                            onClose={() => setDialogOpen(false)}
                        >
                            <DialogTitle>Warning!</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    This project already has a plan created. If
                                    you create a new plan, all workorders in the{' '}
                                    <span
                                        style={{
                                            fontStyle: 'italic',
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        Assigned
                                    </span>{' '}
                                    status will be removed.
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    style={{
                                        marginRight: theme.spacing(2),
                                        textTransform: 'none',
                                    }}
                                    onClick={() => setDialogOpen(false)}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    color="primary"
                                    variant="contained"
                                    style={{
                                        marginRight: theme.spacing(2),
                                        textTransform: 'none',
                                    }}
                                    loading={
                                        scheduleController.loadingState
                                            .suggestionsLoading
                                    }
                                    onClick={() => {
                                        createProjectPlan()
                                    }}
                                >
                                    Submit
                                </LoadingButton>
                            </DialogActions>
                        </Dialog>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
