import React from 'react'
import { Card, Checkbox, IconButton, useTheme } from '@material-ui/core'

import { Area, AreaConfig, AreaStatusTag, IdBoolMap } from '../../models'
import { Container } from '../../components'

interface Props {
    area: Area
    areaConfig?: AreaConfig
    selectionState: IdBoolMap
    setSelectionState: (areaId: number) => void
    disabled?: boolean
    areaStatusTag?: AreaStatusTag
}

export const InspectionAreaCard = (props: Props) => {
    const {
        area,
        selectionState,
        setSelectionState,
        disabled,
        areaConfig,
        areaStatusTag,
    } = props

    const theme = useTheme()

    return (
        <Card
            style={{
                padding: theme.spacing(0.5),
                margin: theme.spacing(0.5, 1),
                cursor: 'pointer',
                backgroundColor: theme.palette.grey[100],
                minWidth: 150,
                maxWidth: 150,
            }}
        >
            <Container>
                <Container
                    style={{
                        flexDirection: 'column',
                        marginRight: theme.spacing(1),
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body2,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {areaConfig?.name ?? '?'}
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightLight,
                                marginLeft: theme.spacing(1),
                            }}
                        >
                            ({area.area_label})
                        </span>
                    </span>
                    <span>{areaStatusTag?.area_status_config.name}</span>
                </Container>
                <IconButton
                    size="small"
                    disabled={disabled}
                    color={selectionState[area.id] ? 'primary' : 'secondary'}
                >
                    <Checkbox
                        disabled={disabled}
                        checked={selectionState[area.id] === true}
                        onClick={() => setSelectionState(area.id)}
                    />
                </IconButton>
            </Container>
        </Card>
    )
}
