import { AxiosResponse } from 'axios'
import { Action } from 'redux'
import {
    ListChannel,
    CurrentChannel,
    Message,
    User,
    MessageDetail,
} from '../../models'

// Requests

type channelTypes =
    | 'ASSIGNMENT'
    | 'UNIT'
    | 'UNIT_SCHEDULE_CHANNEL'
    | 'BUDGET_ITEM'
    | 'EZ_CHAT'
    | 'APARTMENT_VENDOR'

export interface GetChannelListRequest {
    params?: {
        in_ezchat?: boolean
        channel_type?: channelTypes
    }
}

export interface GetChannelDetailRequest {
    channelId: number
    params?: {}
}

export interface AddMemberRequest {
    channelId: number
    body: {
        users?: number[]
        company?: number[]
    }
}

export interface RemoveMemberRequest {
    channelId: number
    body: {
        user?: number
        company?: number
    }
}

export interface CreateChannelRequest {
    body: {
        name: string
        pair?: boolean
        users?: number[]
        companies?: number[]
    }
}

export interface GetMessageListRequest {
    channelId: number
    params?: {}
}

// no interface for CreateMessageRequest because we must use FormData to get all of our attachments to send properly

// Responses

// actions
export const GET_MESSAGING_USER_OPTION_LIST = 'GET_MESSAGING_USER_OPTION_LIST'
export const GET_CHANNEL_LIST_REQUEST = 'GET_CHANNEL_LIST_REQUEST'
export const GET_CHANNEL_LIST_RESPONSE = 'GET_CHANNEL_LIST_RESPONSE'
export const GET_CHANNEL_DETAIL_REQUEST = 'GET_CHANNEL_DETAIL_REQUEST'
export const GET_CHANNEL_DETAIL_RESPONSE = 'GET_CHANNEL_DETAIL_RESPONSE'
export const CREATE_CHANNEL_REQUEST = 'CREATE_CHANNEL_REQUEST'
export const CREATE_CHANNEL_RESPONSE = 'CREATE_CHANNEL_RESPONSE'
export const ADD_MEMBER_REQUEST = 'ADD_MEMBER_REQUEST'
export const ADD_MEMBER_RESPONSE = 'ADD_MEMBER_RESPONSE'
export const REMOVE_MEMBER_REQUEST = 'REMOVE_MEMBER_REQUEST'
export const REMOVE_MEMBER_RESPONSE = 'REMOVE_MEMBER_RESPONSE'
export const GET_MESSAGE_LIST_REQUEST = 'GET_MESSAGE_LIST_REQUEST'
export const GET_MESSAGE_LIST_RESPONSE = 'GET_MESSAGE_LIST_RESPONSE'
export const CREATE_MESSAGE_REQUEST = 'CREATE_MESSAGE_REQUEST'
export const CREATE_MESSAGE_RESPONSE = 'CREATE_MESSAGE_RESPONSE'
export const CLEAR_CHANNEL_UNREAD = 'CLEAR_CHANNEL_UNREAD'
export const RESET_MESSAGE_STATE = 'RESET_MESSAGE_STATE'
export const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL'
export const SET_MESSAGING_USER_OPTION_LIST = 'SET_MESSAGING_USER_OPTION_LIST'
export const SET_MESSAGING_LOADING = 'SET_MESSAGING_LOADING'

export type MessagingRequest =
    | typeof GET_CHANNEL_LIST_REQUEST
    | typeof GET_CHANNEL_DETAIL_REQUEST
    | typeof CREATE_CHANNEL_REQUEST
    | typeof ADD_MEMBER_REQUEST
    | typeof REMOVE_MEMBER_REQUEST
    | typeof GET_MESSAGE_LIST_REQUEST
    | typeof CREATE_MESSAGE_REQUEST
    | typeof CREATE_MESSAGE_REQUEST
    | typeof GET_MESSAGING_USER_OPTION_LIST

interface SetMessagingLoadingAction extends Action {
    type: typeof SET_MESSAGING_LOADING
    request: MessagingRequest
    loading: boolean
}
interface SetMessagingUserOptionList extends Action {
    type: typeof SET_MESSAGING_USER_OPTION_LIST
    messagingUserOptionList?: User[]
}
interface GetChannelListRequestAction extends Action {
    type: typeof GET_CHANNEL_LIST_REQUEST
}

interface GetChannelListResponseAction extends Action {
    type: typeof GET_CHANNEL_LIST_RESPONSE
    payload: ListChannel[]
}

interface GetChannelDetialRequestAction extends Action {
    type: typeof GET_CHANNEL_DETAIL_REQUEST
}

interface GetChannelDetailResponseAction extends Action {
    type: typeof GET_CHANNEL_DETAIL_RESPONSE
    payload: CurrentChannel
}

interface CreateChannelRequestAction extends Action {
    type: typeof CREATE_CHANNEL_REQUEST
}

interface CreateChannelResponseAction extends Action {
    type: typeof CREATE_CHANNEL_RESPONSE
    payload: CurrentChannel
}

interface AddMemberRequestAction extends Action {
    type: typeof ADD_MEMBER_REQUEST
}

interface AddMemberResponseAction extends Action {
    type: typeof ADD_MEMBER_RESPONSE
    payload: CurrentChannel
}

interface RemoveMemberRequestAction extends Action {
    type: typeof REMOVE_MEMBER_REQUEST
}

interface RemoveMemberResponseAction extends Action {
    type: typeof REMOVE_MEMBER_RESPONSE
    payload: CurrentChannel
}

interface GetMessageListRequestAction extends Action {
    type: typeof GET_MESSAGE_LIST_REQUEST
}

interface GetMessageListResponseAction extends Action {
    type: typeof GET_MESSAGE_LIST_RESPONSE
    payload: Message[]
}

interface CreateMessageRequestAction extends Action {
    type: typeof CREATE_MESSAGE_REQUEST
}

interface CreateMessageResponseAction extends Action {
    type: typeof CREATE_MESSAGE_RESPONSE
    payload: Message
}

interface ClearChannelUnreadAction extends Action {
    type: typeof CLEAR_CHANNEL_UNREAD
    channelId: number
}

interface ResetMessageStateAction extends Action {
    type: typeof RESET_MESSAGE_STATE
}

interface SetCurrentChannelAction extends Action {
    type: typeof SET_CURRENT_CHANNEL
    channel?: CurrentChannel
    endLoading?: MessagingRequest
}

type GetChannelDetailActionTypes =
    | GetChannelDetailResponseAction
    | GetChannelDetialRequestAction

type GetChannelListActionTypes =
    | GetChannelListRequestAction
    | GetChannelListResponseAction

type CreateChannelActionTypes =
    | CreateChannelRequestAction
    | CreateChannelResponseAction

type AddMemberActionTypes = AddMemberRequestAction | AddMemberResponseAction

type RemoveMemberActionTypes =
    | RemoveMemberRequestAction
    | RemoveMemberResponseAction

export type ChannelActionTypes =
    | GetChannelListActionTypes
    | GetChannelDetailActionTypes
    | CreateChannelActionTypes
    | AddMemberActionTypes
    | RemoveMemberActionTypes
    | ClearChannelUnreadAction
    | SetCurrentChannelAction

type GetMessageListActionTypes =
    | GetMessageListRequestAction
    | GetMessageListResponseAction

type CreateMessageActionTypes =
    | CreateMessageRequestAction
    | CreateMessageResponseAction

export type MessageActionTypes =
    | GetMessageListActionTypes
    | CreateMessageActionTypes
    | ResetMessageStateAction
    | SetMessagingUserOptionList
    | SetMessagingLoadingAction

export type MessagingActionTypes = ChannelActionTypes | MessageActionTypes

// thunks
export type GetChannelListActionThunk = Promise<AxiosResponse<ListChannel[]>>

export type ChannelDetailActionThunk = Promise<AxiosResponse<CurrentChannel>>

export type GetMessageListActionThunk = Promise<AxiosResponse<Message[]>>

export type MessageDetailActionThunk = Promise<AxiosResponse<MessageDetail>>

// reducers
export interface MessagingState {
    channelList: ListChannel[]
    currentChannel?: CurrentChannel
    isLoading: {
        [GET_CHANNEL_LIST_REQUEST]: boolean
        [GET_CHANNEL_DETAIL_REQUEST]: boolean
        [CREATE_CHANNEL_REQUEST]: boolean
        [ADD_MEMBER_REQUEST]: boolean
        [REMOVE_MEMBER_REQUEST]: boolean
        [GET_MESSAGE_LIST_REQUEST]: boolean
        [CREATE_MESSAGE_REQUEST]: boolean
        [GET_MESSAGING_USER_OPTION_LIST]: boolean
    }
    messages: Message[]
    messagingUserOptionList?: User[]
}
