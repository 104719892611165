import React from 'react'

// hooks
import { useTheme } from '@material-ui/core'

// types
import { Folder } from '../../models'

// components
import { Container } from '../Container'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'

interface Props {
    folder: Folder
    open: boolean
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    getFolderAdornment?: (folder: Folder) => JSX.Element | null
}

export const FinderFolder: React.FC<Props> = (props) => {
    const { folder, open, onClick } = props

    const theme = useTheme()

    const icon = open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />

    return (
        <Container
            direction="column"
            flex={1}
            onClick={(e) => {
                e.stopPropagation()
                onClick && onClick(e)
            }}
        >
            <Container alignItems="center">
                {icon}
                <span
                    style={{
                        ...theme.typography.body2,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {folder.name}
                </span>

                {props.getFolderAdornment && props.getFolderAdornment(folder)}
            </Container>
        </Container>
    )
}
