import {
    Button,
    Divider,
    MenuItem,
    TextField,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Container } from '../../components'
import { axiosInstance } from '../../helpers'
import { useAppDispatch, useUser } from '../../hooks'
import { Company, ListVendor } from '../../models'
import { statesList } from '../../helpers'
import { setRootUser } from '../../store'

interface Props {
    vendor: ListVendor
    setVendor: (company: ListVendor | undefined) => void
}

export const EditAccount = (props: Props) => {
    const { vendor, setVendor } = props

    const dispatch = useAppDispatch()

    const { workspaceUser, rootUser } = useUser()

    const theme = useTheme()

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [address, setAddress] = useState('')

    const [doValidate, setDoValidate] = useState(false)

    const spanStyle: React.CSSProperties = {
        fontWeight: 600,
        fontSize: '15px',
        marginBottom: theme.spacing(1),
    }

    useEffect(() => {
        setName(vendor.name)
        if (vendor.email) {
            setEmail(vendor.email)
        }
        if (vendor.phone) {
            setPhone(vendor.phone)
        }
        setState(vendor.state)
        setZipCode(vendor.zip_code)
        setCity(vendor.city)
        setAddress(vendor.address)
    }, [])

    const checkValidation = () => {
        if (name === '') {
            setDoValidate(true)
            return true
        }
        return false
    }

    return (
        <Container flex={1} direction="column">
            <Container
                direction="column"
                style={{ margin: theme.spacing(8, 5, 5, 5) }}
                flex={1}
            >
                <Container direction="row">
                    <Container
                        direction="column"
                        style={{ marginRight: theme.spacing(4) }}
                    >
                        <span style={{ ...spanStyle }}>Name:</span>
                        <TextField
                            error={doValidate && name === ''}
                            helperText={
                                doValidate && name === '' ? 'Required' : ''
                            }
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            style={{
                                width: '400px',
                            }}
                            size="small"
                        />
                    </Container>
                    <Container direction="column">
                        <span style={{ ...spanStyle }}>Email:</span>
                        <TextField
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            size="small"
                            style={{
                                width: '400px',
                            }}
                        />
                    </Container>
                </Container>
                <Container
                    style={{ marginTop: theme.spacing(3) }}
                    direction="column"
                >
                    <span style={{ ...spanStyle }}>Phone Number:</span>
                    <TextField
                        variant="outlined"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        size="small"
                        style={{
                            width: '600px',
                        }}
                    />
                </Container>
                <Container
                    style={{ marginTop: theme.spacing(3) }}
                    direction="column"
                >
                    <span style={{ ...spanStyle }}>Address:</span>
                    <TextField
                        variant="outlined"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        size="small"
                        style={{
                            width: '600px',
                        }}
                    />
                </Container>
                <Container
                    direction="row"
                    style={{ marginTop: theme.spacing(3) }}
                >
                    <Container
                        direction="column"
                        style={{ marginRight: theme.spacing(4) }}
                    >
                        <span style={{ ...spanStyle }}>City:</span>
                        <TextField
                            variant="outlined"
                            size="small"
                            style={{
                                width: '400px',
                            }}
                            value={city}
                            onChange={(e) => {
                                setCity(e.target.value)
                            }}
                        />
                    </Container>
                    <Container direction="column">
                        <span style={{ ...spanStyle }}>State:</span>
                        <TextField
                            variant="outlined"
                            value={state}
                            onChange={(e) => setState(e.target.value as string)}
                            style={{ width: '400px' }}
                            size="small"
                            select
                        >
                            {statesList.map((s) => {
                                return (
                                    <MenuItem key={s} value={s}>
                                        {s}
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    </Container>
                </Container>
                <Container
                    style={{ marginTop: theme.spacing(3) }}
                    direction="column"
                >
                    <span style={{ ...spanStyle }}>Zip Code:</span>
                    <TextField
                        variant="outlined"
                        size="small"
                        style={{
                            width: '600px',
                        }}
                        value={zipCode}
                        onChange={(e) => {
                            setZipCode(e.target.value)
                        }}
                    />
                </Container>
            </Container>
            <Divider />
            <Container style={{ margin: theme.spacing(4, 0, 4, 5) }}>
                <Button
                    variant="outlined"
                    style={{
                        backgroundColor: theme.palette.primary.dark,
                        textTransform: 'none',
                        color: 'white',
                        padding: theme.spacing(0.5, 4, 0.5, 4),
                    }}
                    onClick={() => {
                        if (checkValidation()) {
                            return
                        } else {
                            const body = {
                                name: name,
                                city: city,
                                state: state,
                                zip_code: zipCode,
                                phone: phone,
                                email: email,
                                address: address,
                            }
                            axiosInstance
                                .patch(`company/vendor/${vendor.id}/`, body)
                                .then((res) => {
                                    toast.success(
                                        `${res.data.vendor.name} updated`,
                                    )
                                    const tempVendor = JSON.parse(
                                        JSON.stringify(vendor),
                                    )
                                    tempVendor.name = res.data.vendor.name
                                    tempVendor.city = res.data.vendor.city
                                    tempVendor.phone = res.data.vendor.phone
                                    tempVendor.state = res.data.vendor.state
                                    tempVendor.address = res.data.vendor.address
                                    tempVendor.zip_code =
                                        res.data.vendor.zip_code
                                    tempVendor.email = res.data.vendor.email
                                    setVendor(tempVendor)
                                    const tempUser = JSON.parse(
                                        JSON.stringify(rootUser),
                                    )
                                    const index = rootUser?.workspaces.findIndex(
                                        (workspace) =>
                                            workspace.active_workspace.id ===
                                            tempVendor.id,
                                    )
                                    if (index) {
                                        tempUser.workspaces[
                                            index
                                        ].active_workspace.name =
                                            tempVendor.name
                                        tempUser.workspaces[
                                            index
                                        ].active_workspace.city =
                                            tempVendor.city
                                        tempUser.workspaces[
                                            index
                                        ].active_workspace.phone =
                                            tempVendor.phone
                                        tempUser.workspaces[
                                            index
                                        ].active_workspace.state =
                                            tempVendor.state
                                        tempUser.workspaces[
                                            index
                                        ].active_workspace.zip_code =
                                            tempVendor.zip_code
                                        tempUser.workspaces[
                                            index
                                        ].active_workspace.email =
                                            tempVendor.email
                                        tempUser.workspaces[
                                            index
                                        ].active_workspace.address =
                                            tempVendor.address
                                    }
                                    dispatch(setRootUser(tempUser))
                                })
                                .catch((e) => {
                                    toast.error(e.response.data.message)
                                })
                        }
                    }}
                >
                    Save
                </Button>
            </Container>
        </Container>
    )
}
