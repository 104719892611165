import React, { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core'
import { Attachment, AvatarCircle, Container } from '../../../../components'
import {
    AreaConfig,
    ChangeOrder,
    ChangeOrderConfig,
    ChangeOrderDetail,
    ModelMap,
    SEE_INCOME_FINANCES,
    SEE_VENDOR_FINANCES,
    WorkSpaceUser,
} from '../../../../models'
import { currencyFormatter } from '../../../../helpers'

import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { hasPermission } from '../../../../models/Users/services'

interface Props {
    areaConfigMap: ModelMap<AreaConfig>
    changeOrder?: ChangeOrder
    changeOrderConfig?: ChangeOrderConfig
    changeOrderDetail?: ChangeOrderDetail
    workspaceUser?: WorkSpaceUser
}

export const ChangeOrderDetailPanel = (props: Props) => {
    const {
        changeOrder,
        changeOrderConfig,
        areaConfigMap,
        changeOrderDetail,
        workspaceUser,
    } = props

    const [selectedImg, setSelectedImg] = useState(0)

    const theme = useTheme()

    const titleStyle: React.CSSProperties = {
        ...theme.typography.h5,
        fontWeight: 'bold',
        color: theme.palette.grey[700],
    }

    const subTitleStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: 'bold',
        color: theme.palette.grey[700],
    }

    const bodySyle: React.CSSProperties = {
        ...theme.typography.body1,
        fontWeight: 'bold',
        color: theme.palette.grey[700],
    }

    const imgStyle: React.CSSProperties = {
        objectFit: 'contain',
    }

    useEffect(() => {
        setSelectedImg(0)
    }, [changeOrder])

    let content = (
        <Container style={{ flex: 1 }}>
            <span style={titleStyle}>Select a Change Order</span>
        </Container>
    )

    if (changeOrder) {
        let price = changeOrderConfig?.suggested_add_price
        if (changeOrder.price) {
            price = changeOrder.price
        }

        const changeOrderTotalCost = changeOrder.quantity * (price ?? 0)

        const changeOrderIncome =
            changeOrder.quantity * (changeOrderConfig?.upstream_price ?? 0)

        let costString = ''
        if (hasPermission(workspaceUser, SEE_VENDOR_FINANCES)) {
            costString = `Cost: ${price} x ${
                changeOrder.quantity
            } = ${currencyFormatter.format(changeOrderTotalCost)}`
        }

        let incomeString = ''
        if (hasPermission(workspaceUser, SEE_INCOME_FINANCES)) {
            incomeString = `Income: ${changeOrderConfig?.upstream_price} x ${
                changeOrder.quantity
            } = ${currencyFormatter.format(changeOrderIncome)}`
        }

        let requestedAreas = (
            <Container style={{ flexDirection: 'column' }}>
                <span style={{ ...titleStyle, marginTop: theme.spacing(4) }}>
                    Addon
                </span>

                <span style={subTitleStyle}>{costString}</span>
                <span style={subTitleStyle}>{incomeString}</span>
            </Container>
        )

        if (!changeOrderConfig?.addon) {
            requestedAreas = (
                <>
                    <span
                        style={{
                            ...titleStyle,
                            marginTop: theme.spacing(1),
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        Change Of Scope
                    </span>
                    {changeOrderDetail?.areas.map((area) => {
                        return (
                            <Container
                                key={`CHANGE_ORDER_${changeOrder.id}_${area.id}`}
                            >
                                <span style={subTitleStyle}>
                                    - {areaConfigMap[area.area_config]?.name}{' '}
                                    {area.area_label}
                                </span>
                            </Container>
                        )
                    })}
                </>
            )
        }

        const images: JSX.Element[] = []
        if (changeOrder.image_0) {
            images.push(
                <Attachment
                    key="ATTACHMENT_0"
                    attachment={changeOrder.image_0}
                    theme={theme}
                    uploaded={changeOrder.image_0_uploaded}
                    autoSize
                />,
            )
        }
        if (changeOrder.image_1) {
            images.push(
                <Attachment
                    key="ATTACHMENT_1"
                    attachment={changeOrder.image_1}
                    theme={theme}
                    uploaded={changeOrder.image_1_uploaded}
                    autoSize
                />,
            )
        }
        if (changeOrder.image_2) {
            images.push(
                <Attachment
                    key="ATTACHMENT_2"
                    attachment={changeOrder.image_2}
                    theme={theme}
                    uploaded={changeOrder.image_2_uploaded}
                    autoSize
                />,
            )
        }

        content = (
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                }}
                scrollY
            >
                <span style={titleStyle}>Description</span>
                <span>{changeOrderConfig?.description ?? 'None'}</span>
                {/* Who requested */}
                <span style={{ ...titleStyle, marginTop: theme.spacing(1) }}>
                    Requested By
                </span>
                <Container
                    style={{
                        alignItems: 'center',
                        marginBottom: theme.spacing(4),
                        marginTop: theme.spacing(1),
                    }}
                >
                    <AvatarCircle
                        initials
                        text={changeOrderDetail?.created_by.name}
                    />
                    <Container
                        style={{
                            flexDirection: 'column',
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        <span style={titleStyle}>
                            {
                                changeOrderDetail?.created_by.active_workspace
                                    .name
                            }
                        </span>
                        <span style={bodySyle}>
                            {changeOrderDetail?.created_by.name}
                        </span>
                    </Container>
                </Container>

                <span style={{ ...titleStyle, marginTop: theme.spacing(1) }}>
                    User Note
                </span>
                <span>{changeOrder.description}</span>

                <Carousel
                    showThumbs={false}
                    selectedItem={selectedImg}
                    onChange={(idx) => setSelectedImg(idx)}
                >
                    {images}
                </Carousel>

                {requestedAreas}
            </Container>
        )
    }

    return (
        <Container
            style={{
                flex: 1,
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: theme.shape.borderRadius,
                marginTop: theme.spacing(1),
                padding: theme.spacing(1),
            }}
        >
            {content}
        </Container>
    )
}
