import { AxiosResponse } from 'axios'
import { Action } from 'redux'
import {
    Bid,
    PortfolioServiceContract,
    ServiceContract,
    ServiceContractType,
} from '../../models'

// Requests
export interface UpdateServiceContractsRequest {
    body: {
        vendor: number
        service_contracts: ServiceContractForUpdate[]
    }
}

export interface SetServiceContractsRequest {
    body: {
        vendor: number
        service_contracts: any[]
    }
}

export interface ServiceContractForUpdate {
    service_id: number
    contract_type: ServiceContractType
    config_id: number
    price: number
}

export interface GetServiceContractListRequest {
    params?: {
        vendor?: number
    }
}

export interface GetPortfolioServiceContractListRequest {
    params: {
        service: number
    }
}
// Responses

// actions
export const SET_SERVICE_CONTRACT_REQUEST_ACTION =
    'SET_SERVICE_CONTRACT_REQUEST_ACTION'
export const UPDATE_SERVICE_CONTRACT_REQUEST_ACTION =
    'UPDATE_SERVICE_CONTRACT_REQUEST_ACTION'
export const UPDATE_SERVICE_CONTRACT_RESPONSE_ACTION =
    'UPDATE_SERVICE_CONTRACT_RESPONSE_ACTION'
export const UPDATE_PENDING_BIDS_ACTION = 'UPDATE_PENDING_BIDS_ACTION'
export const UPDATE_BIDS_ACTION = 'UPDATE_BIDS_ACTION'

export const GET_SERVICE_CONTRACT_REQUEST_ACTION =
    'GET_SERVICE_CONTRACT_REQUEST_ACTION'
export const GET_SERVICE_CONTRACT_RESPONSE_ACTION =
    'GET_SERVICE_CONTRACT_RESPONSE_ACTION'
export const GET_PORTFOLIO_SERVICE_CONTRACT_REQUEST_ACTION =
    'GET_PORTFOLIO_SERVICE_CONTRACT_REQUEST_ACTION'
export const SET_PORTFOLIO_SERVICE_CONTRACTS = 'SET_PORTFOLIO_SERVICE_CONTRACTS'
export const SET_SERVICE_CONTRACT_LOADING = 'SET_SERVICE_CONTRACT_LOADING'
export const SET_SERVICE_CONTRACTS = 'SET_SERVCE_CONTRACTS'

export type ServiceContractRequests =
    | typeof UPDATE_SERVICE_CONTRACT_REQUEST_ACTION
    | typeof SET_SERVICE_CONTRACT_REQUEST_ACTION
    | typeof GET_SERVICE_CONTRACT_REQUEST_ACTION
    | typeof GET_PORTFOLIO_SERVICE_CONTRACT_REQUEST_ACTION

interface UpdateServiceContractRequestAction extends Action {
    type: typeof UPDATE_SERVICE_CONTRACT_REQUEST_ACTION
}

interface UpdateServiceContractResponseAction extends Action {
    type: typeof UPDATE_SERVICE_CONTRACT_RESPONSE_ACTION
    payload: ServiceContract[]
}

interface UpdatePendingBidsAction extends Action {
    type: typeof UPDATE_PENDING_BIDS_ACTION
    payload: number
}

interface UpdateBidsAction extends Action {
    type: typeof UPDATE_BIDS_ACTION
    payload: Bid[]
}

interface GetServiceContractRequestAction extends Action {
    type: typeof GET_SERVICE_CONTRACT_REQUEST_ACTION
}

interface GetServiceContractResponseAction extends Action {
    type: typeof GET_SERVICE_CONTRACT_RESPONSE_ACTION
    payload: ServiceContract[]
}

interface SetServiceContract extends Action {
    type: typeof SET_SERVICE_CONTRACTS
    payload: ServiceContract[]
}

interface SetServiceContractLoading extends Action {
    type: typeof SET_SERVICE_CONTRACT_LOADING
    request: ServiceContractRequests
    isLoading?: boolean
}

interface SetPortfolioServiceContracts extends Action {
    type: typeof SET_PORTFOLIO_SERVICE_CONTRACTS
    contracts?: PortfolioServiceContract[]
}

type UpdateServiceContractActionTypes =
    | UpdateServiceContractRequestAction
    | UpdateServiceContractResponseAction

type GetServiceContractActionTypes =
    | GetServiceContractRequestAction
    | GetServiceContractResponseAction

export type ServiceContractActionTypes =
    | UpdateServiceContractActionTypes
    | GetServiceContractActionTypes
    | SetServiceContract
    | SetServiceContractLoading
    | SetPortfolioServiceContracts
    | UpdatePendingBidsAction
    | UpdateBidsAction

// thunks

export type ServiceContractActionThunk = Promise<
    AxiosResponse<ServiceContract[]>
>

export type PortfolioServiceContractThunk = Promise<
    AxiosResponse<PortfolioServiceContract[]>
>

// reducers
export interface ServiceContractState {
    serviceContracts: ServiceContract[]
    pendingBids: number
    bids: Bid[]
    portfolioServiceContracts?: PortfolioServiceContract[]
    isLoading: {
        [UPDATE_SERVICE_CONTRACT_REQUEST_ACTION]: boolean
        [GET_SERVICE_CONTRACT_REQUEST_ACTION]: boolean
        [GET_PORTFOLIO_SERVICE_CONTRACT_REQUEST_ACTION]: boolean
        [SET_SERVICE_CONTRACT_REQUEST_ACTION]: boolean
    }
}
