export interface PasswordRequirementResult {
    lengthValid: boolean
    passwordsMatch: boolean
    passwordValid: boolean
    lowerCaseValid: boolean
    upperCaseValid: boolean
    numberValid: boolean
}

export const passwordValidator = (
    newPassword: string,
    confirmPassword: string,
): PasswordRequirementResult => {
    const lengthValid = newPassword.length >= 14
    const passwordsMatch = newPassword === confirmPassword && lengthValid

    let lowerCaseValid = false
    let upperCaseValid = false
    let numberValid = false

    for (let charIdx = 0; charIdx < newPassword.length; charIdx += 1) {
        const charCode = newPassword.charCodeAt(charIdx)

        if (charCode >= 65 && charCode <= 90) {
            upperCaseValid = true
        }

        if (charCode >= 97 && charCode <= 122) {
            lowerCaseValid = true
        }

        if (charCode >= 48 && charCode <= 57) {
            numberValid = true
        }
    }

    const passwordValid =
        lengthValid &&
        passwordsMatch &&
        lowerCaseValid &&
        upperCaseValid &&
        numberValid

    return {
        lengthValid,
        passwordsMatch,
        passwordValid,
        lowerCaseValid,
        upperCaseValid,
        numberValid,
    }
}
