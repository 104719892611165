import { Delete } from '@material-ui/icons'
import { useTheme } from '@material-ui/core'
import React from 'react'
import { Container, NumberInput, Selector } from '../../components'
import {
    AreaConfig,
    IdentifiableNamedObject,
    InventoryConfig,
    ListInventoryAreaConfig,
} from '../../models'
import { useStyles } from '../../styles'

export const SELECT_AREA_MODE = 0
export const SELECT_INVENTORY_MODE = 1

interface Props {
    configOptions: IdentifiableNamedObject[]
    selectMode: typeof SELECT_AREA_MODE | typeof SELECT_INVENTORY_MODE
    remove?: () => void
    iac: ListInventoryAreaConfig
    updateIAC: (iac: ListInventoryAreaConfig) => void
    size?: 'small' | 'medium' | undefined
}

export const ConfigSelectionRow = (props: Props) => {
    const { size, configOptions, remove, iac, updateIAC, selectMode } = props

    const classes = useStyles()
    const theme = useTheme()

    return (
        <Container style={{ alignItems: 'center', margin: theme.spacing(1) }}>
            <Selector
                label={`${
                    selectMode === SELECT_INVENTORY_MODE ? 'Inventory' : 'Area'
                } Config`}
                customStyle={{ formControl: { minWidth: 175, flex: 1 } }}
                getDisplayString={(config) => config?.name ?? ''}
                data={configOptions}
                currentValue={
                    selectMode === SELECT_INVENTORY_MODE
                        ? iac.inventory_config?.id ?? -1
                        : iac.area_config?.id ?? -1
                }
                onChange={(
                    event: React.ChangeEvent<{
                        value: unknown
                    }>,
                ) => {
                    if (selectMode === SELECT_INVENTORY_MODE) {
                        const inventoryConfig = configOptions.find(
                            (config) => config.id === event.target.value,
                        ) as InventoryConfig
                        updateIAC({ ...iac, inventory_config: inventoryConfig })
                    } else {
                        const areaConfig = configOptions.find(
                            (config) => config.id === event.target.value,
                        ) as AreaConfig
                        updateIAC({ ...iac, area_config: areaConfig })
                    }
                }}
                size={size}
            />

            <NumberInput
                value={iac.amount}
                onChange={(event) => {
                    updateIAC({ ...iac, amount: Number(event.target.value) })
                }}
                label="Amount"
                style={{ maxWidth: 50, margin: theme.spacing(0, 1, 0, 1) }}
            />
            <Delete
                color="secondary"
                className={classes.highlightCircleOnHover}
                onClick={remove}
                style={{ marginLeft: theme.spacing(1) }}
            />
        </Container>
    )
}
