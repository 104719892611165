import React, { useEffect, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import {
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'

import {
    getActionItemList,
    RootState,
    updateActionItemReq,
} from '../../../../store'
import { useAppDispatch, useUser } from '../../../../hooks'
import { useSelector } from 'react-redux'
import { toMMDDYYYY } from '../../../../helpers'
import { BasicDataTable } from '../../../components/data-table'
import { ActionItemStatus } from '../../../../models'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            tableLayout: 'fixed',
            // minWidth: 650,
            '& .MuiTableRow-root .MuiTableCell-root': {
                fontSize: '0.813rem',
                color: '#8A8A8A',
                fontWeight: 400,
            },
            '& .MuiTableRow-root .MuiTableCell-root.elipse-text': {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '100%',
            },
            '& .MuiTableHead-root .MuiTableCell-root': {
                fontWeight: 500,
                fontSize: '0.75rem',
                /* line-height: 12px; */
                color: '#000',
                backgroundColor: '#FFF',
            },
        },
        // statusDropdown: {
        //     width: '60px',
        //     '& .MuiAutocomplete-inputRoot': {
        //         paddingRight: '0px',
        //     },
        //     '& .MuiInputBase-input': {
        //         color: '#000',
        //         fontSize: '0.75rem',
        //         padding: '4px 12px!important',
        //         borderRadius: '36px',
        //     },
        //     '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
        //         borderBottom: 0,
        //     },
        //     '& .MuiAutocomplete-endAdornment': {
        //         display: 'none',
        //     },
        // },
        // assigned: {
        //     '& .MuiInputBase-input': {
        //         color: '#FFF',
        //         backgroundColor: '#9E9E9E',
        //     },
        // },
        // complete: {
        //     '& .MuiInputBase-input': {
        //         color: '#FFF',
        //         backgroundColor: 'green',
        //     },
        // },
        // inProgress: {
        //     '& .MuiInputBase-input': {
        //         color: '#FFF',
        //         backgroundColor: '#FFBF00',
        //     },
        // },
        itemTitle: {
            display: 'block',
            width: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: '#000',
            fontSize: '0.813rem',
            fontWeight: 700,
            // fontFamily: 'Roboto'
        },
        itemDate: {
            color: '#8A8A8A',
            fontSize: '0.625rem',
            fontStyle: 'italic',
            // fontFamily: 'Roboto'
        },
        assigned: {
            color: '#FFF',
            backgroundColor: '#9E9E9E',
            borderRadius: '36px',
            fontSize: '0.625rem',
            fontWeight: 700,
            padding: '2px 5px',
            display: 'block',
            width: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            cursor: 'pointer',
        },
        complete: {
            color: '#FFF',
            backgroundColor: 'green',
            borderRadius: '36px',
            fontSize: '0.625rem',
            fontWeight: 700,
            padding: '2px 5px',
            display: 'block',
            width: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            cursor: 'pointer',
        },
        inProgress: {
            display: 'block',
            color: '#FFF',
            padding: '2px 5px',
            fontSize: '0.625rem',
            fontWeight: 700,
            borderRadius: '36px',
            backgroundColor: '#FFBF00',
            width: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            cursor: 'pointer',
        },
        pause: {
            color: '#FFF',
            backgroundColor: '#9E9E9E',
            borderRadius: '36px',
            fontSize: '0.625rem',
            fontWeight: 700,
            padding: '2px 5px',
            display: 'block',
            width: '100%',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            cursor: 'pointer',
        },
        statusMenu: {
            '& .MuiList-root': {
                width: 100,
            },
            '& .MuiMenuItem-root': {
                fontSize: '0.75rem',
                fontWeight: 400,
                color: '#000',
            },
        },
    }),
)

const ActionItemList = (props: any) => {
    const classes = useStyles()

    // function createData(title: string, date: any, status: string) {
    //     return { title, date, status }
    // }
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getActionItemList({}))
    }, [])

    const actionItemList = useSelector(
        (state: RootState) => state.actionItem.actionItemList,
    )

    const columns = [
        {
            name: 'Title',
            selector: (row: any) => row.title,
            sortable: false,
        },
        {
            name: 'Status',
            selector: (row: any) => row.status,
            sortable: false,
        },
    ]

    console.log('action Items are.....', actionItemList)
    // const [actionItems, setActionItems] = useState([
    //     createData('Paint', '9/7', 'Assigned'),
    //     createData('Trash out', '9/8', 'Complete'),
    //     createData('Maintenance', '9/6', 'In Progress'),
    //     createData('Carpet', '7/9', 'Assigned'),
    //     createData('Paint', '9/7', 'Assigned'),
    //     createData('Carpet R.', '9/7', 'Assigned'),
    //     createData('Paint', '9/7', 'Assigned'),
    //     createData('Paint', '9/7', 'Assigned'),
    //     createData('Trash out', '9/8', 'Assigned'),
    //     createData('Maintenance', '9/6', 'Assigned'),
    //     createData('Carpet', '7/9', 'Assigned'),
    //     createData('Paint', '9/7', 'Assigned'),
    //     createData('Carpet R.', '9/7', 'Assigned'),
    //     createData('Paint', '9/7', 'Assigned'),
    // ])

    // const handleMenuItemClick = (status: string, index: number) => {
    //     console.log('status is...', status)
    //     console.log('index is...', index)
    //     actionItems[index] = { ...actionItems[0], status: status }
    //     setActionItems([...actionItems])
    // }

    const getStatusClass = (status: string) => {
        switch (status) {
            case 'ASSIGNED':
                return classes.assigned
            case 'COMPLETE':
                return classes.complete
            case 'IN_PROGRESS':
                return classes.inProgress
            case 'PAUSE':
                return classes.pause
            default:
                return '#FFF'
        }
    }
    const statusConfig: any = {
        ASSIGNED: 'Assigned',
        COMPLETE: 'Complete',
        IN_PROGRESS: 'In Progress',
        PAUSE: 'Paused',
    }

    const updateItemStatus = (status: string, itemId: number) => {
        if (status === 'In Progress') {
            dispatch(
                updateActionItemReq({
                    params: {
                        id: itemId,
                    },
                    body: {
                        status: ActionItemStatus.IN_PROGRESS,
                    },
                }),
            )
        } else if (status === 'Complete') {
            dispatch(
                updateActionItemReq({
                    params: {
                        id: itemId,
                    },
                    body: {
                        status: ActionItemStatus.COMPLETE,
                    },
                }),
            )
        }
    }

    const tableRowData = () => {
        const newActionItemData: any = []

        actionItemList?.map((value: any) => {
            if (value.status !== 'COMPLETE') {
                newActionItemData.push({
                    title: (
                        <>
                            <Tooltip title={value.title}>
                                <span className={classes.itemTitle}>
                                    {value.title}
                                </span>
                            </Tooltip>
                            <span className={classes.itemDate}>
                                {value.start_date
                                    ? toMMDDYYYY(new Date(value.start_date))
                                    : ''}
                            </span>
                        </>
                    ),
                    status:
                        value.status === 'COMPLETE' ? (
                            <span className={getStatusClass(value.status)}>
                                {statusConfig[value.status]}
                            </span>
                        ) : (
                            <PopupState
                                variant="popover"
                                popupId="demo-popup-menu"
                            >
                                {(popupState) => {
                                    const statusOptions =
                                        value.status === 'ASSIGNED' ||
                                        value.status === 'PAUSE'
                                            ? ['In Progress']
                                            : ['Complete']
                                    return (
                                        <React.Fragment>
                                            <span
                                                className={getStatusClass(
                                                    value.status,
                                                )}
                                                {...bindTrigger(popupState)}
                                            >
                                                {statusConfig[value.status]}
                                            </span>
                                            <Menu
                                                {...bindMenu(popupState)}
                                                className={classes.statusMenu}
                                            >
                                                {statusOptions.map(
                                                    (option, i) => (
                                                        <MenuItem
                                                            key={option}
                                                            selected={
                                                                option ===
                                                                value.status
                                                            }
                                                            onClick={() => {
                                                                updateItemStatus(
                                                                    option,
                                                                    value.id,
                                                                )
                                                                popupState.close()
                                                            }}
                                                            // onClick={() => {
                                                            //     popupState.close()
                                                            //     handleMenuItemClick(
                                                            //         option,
                                                            //         index,
                                                            //     )
                                                            // }}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                    ),
                                                )}
                                            </Menu>
                                        </React.Fragment>
                                    )
                                }}
                            </PopupState>
                        ),
                })
            }
        })

        return newActionItemData
    }

    return (
        <>
            <BasicDataTable
                data={tableRowData()}
                columns={columns}
                class="actionItem"
                pagination={false}
                rowClickable={false}
                rowClicked={null}
            />
            {/* <TableContainer
                className="ez-custom-scroll"
                style={{ maxHeight: '250px' }}
            >
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {actionItemList && actionItemList.length > 0 ? (
                            actionItemList.map((row: any, index: number) => (
                                <TableRow key={`msg-${index}`}>
                                    <TableCell className="elipse-text">
                                        {row.title}
                                    </TableCell>
                                    <TableCell>
                                        {row.start_date
                                            ? toMMDDYYYY(
                                                  new Date(row.start_date),
                                              )
                                            : ''}
                                    </TableCell>
                                    <TableCell>
                                        <PopupState
                                            variant="popover"
                                            popupId="demo-popup-menu"
                                        >
                                            {(popupState) => (
                                                <React.Fragment>
                                                    <span
                                                        className={getStatusClass(
                                                            row.status,
                                                        )}
                                                        {...bindTrigger(
                                                            popupState,
                                                        )}
                                                    >
                                                        {
                                                            statusConfig[
                                                                row.status
                                                            ]
                                                        }
                                                    </span>
                                                    <Menu
                                                        {...bindMenu(
                                                            popupState,
                                                        )}
                                                        className={
                                                            classes.statusMenu
                                                        }
                                                    >
                                                        {statusOptions.map(
                                                            (option, i) => (
                                                                <MenuItem
                                                                    key={option}
                                                                    selected={
                                                                        option ===
                                                                        row.status
                                                                    }
                                                                    // onClick={() => {
                                                                    //     popupState.close()
                                                                    //     handleMenuItemClick(
                                                                    //         option,
                                                                    //         index,
                                                                    //     )
                                                                    // }}
                                                                >
                                                                    {option}
                                                                </MenuItem>
                                                            ),
                                                        )}
                                                    </Menu>
                                                </React.Fragment>
                                            )}
                                        </PopupState>
                                        {/* <span
                                        className={getStatusClass(row.status)}
                                        onClick={handleClickListItem}
                                    >
                                        {row.status}
                                    </span> */}
            {/* <Menu
                                        id="lock-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                        className={classes.statusMenu}
                                    >
                                        {statusOptions.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                disabled={index === 0}
                                                selected={option === row.status}
                                                onClick={(event) =>
                                                    handleMenuItemClick(
                                                        event,
                                                        option,
                                                        index,
                                                    )
                                                }
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu> */}
            {/* <Autocomplete
                                        className={`${getStatusClass(
                                            row.status,
                                        )} ${classes.statusDropdown}`}
                                        disableClearable
                                        id="combo-box-demo"
                                        options={statusOptions}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                // variant="outlined"
                                            />
                                        )}
                                        PaperComponent={({ children }) => (
                                            <Paper
                                                style={{
                                                    fontSize: '0.75rem',
                                                    fontWeight: 400,
                                                    color: '#000',
                                                    width: 100
                                                }}
                                            >
                                                {children}
                                            </Paper>
                                        )}
                                        value={row.status}
                                        onChange={(
                                            event: any,
                                            newValue: any,
                                        ) => {
                                            console.log("hello")
                                            actionItems[index] = {...actionItems[0], status : newValue}
                                            console.log(actionItems)
                                            setActionItems(actionItems)
                                        }}
                                    /> */}
            {/* </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <></>
                        )}
                    </TableBody>
                </Table>
            // </TableContainer> */}
        </>
    )
}

export default ActionItemList
