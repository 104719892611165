import {
    GET_INFRASTRUCTURE_REQUEST,
    GET_INFRASTRUCTURE_RESPONSE,
    InfrastructureActionTypes,
    InfrastructureState,
    SET_INFRASTRUCTURE_LOADING,
    SET_INVENTORY,
    SET_MANAGER_INVENTORY_LIST,
    SET_MANAGER_WORKORDER_LIST,
    UPDATE_AREA_STATUS_REQUEST,
    UPDATE_AREA_STATUS_RESPONSE,
    ADD_FOLDER,
    FolderRes,
    SET_UNITS,
    DELETE_FOLDER,
} from './types'

const initialState: InfrastructureState = {
    isLoading: {
        GET_INFRASTRUCTURE_REQUEST: false,
        UPDATE_AREA_STATUS_REQUEST: false,
        GET_INVENTORY_REQUEST: false,
        GET_INFRASTRUCTURE_MANAGER_REQUEST: false,
        CREATE_FOLDER_REQUEST: false,
        MOVE_UNITS_REQUEST: false,
        DELETE_FOLDER_REQUEST: false,
    },
}

export const infrastructureReducer = (
    state = initialState,
    action: InfrastructureActionTypes,
): InfrastructureState => {
    switch (action.type) {
        case SET_INFRASTRUCTURE_LOADING:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.request]: action.loading,
                },
            }
        case SET_INVENTORY:
            return {
                ...state,
                inventoryList: action.inventoryList,
            }
        case GET_INFRASTRUCTURE_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_INFRASTRUCTURE_REQUEST: true,
                },
            }
        case GET_INFRASTRUCTURE_RESPONSE:
            return {
                ...state,
                infrastructure: action.payload,
                isLoading: {
                    ...state.isLoading,
                    GET_INFRASTRUCTURE_REQUEST: false,
                },
            }

        case UPDATE_AREA_STATUS_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    UPDATE_AREA_STATUS_REQUEST: true,
                },
            }
        case UPDATE_AREA_STATUS_RESPONSE:
            return {
                ...state,
                infrastructure: state.infrastructure
                    ? {
                          folders: state.infrastructure.folders,
                          units: state.infrastructure.units.map((u) => {
                              if (u.id === action.unitId) {
                                  return {
                                      ...u,
                                      areas: u.areas.map((a) => {
                                          //   if (a.id === action.payload.area.id) {
                                          //       let key = 'non_occ_status'
                                          //       if (a.area_config.occupiable) {
                                          //           key = 'occ_status'
                                          //       }
                                          //       return {
                                          //           ...a,
                                          //           [key]:
                                          //               action.payload.area
                                          //                   .status,
                                          //       }
                                          //   }
                                          return a
                                      }),
                                  }
                              }
                              return u
                          }),
                      }
                    : undefined,
                isLoading: {
                    ...state.isLoading,
                    UPDATE_AREA_STATUS_REQUEST: false,
                },
            }

        case SET_MANAGER_WORKORDER_LIST:
            return {
                ...state,
                manager_workorders: action.workorderList,
            }

        case SET_MANAGER_INVENTORY_LIST:
            return {
                ...state,
                manager_inventory: action.inventoryList,
            }

        case ADD_FOLDER:
            return {
                ...state,
                infrastructure: {
                    ...state.infrastructure,
                    folders: addFolder(
                        action.folder,
                        state.infrastructure?.folders ?? [],
                    ),
                    units: state.infrastructure?.units ?? [],
                },
            }

        case SET_UNITS:
            return {
                ...state,
                infrastructure: {
                    ...state.infrastructure,
                    folders: [...(state.infrastructure?.folders ?? [])],
                    units: action.units,
                },
            }

        case DELETE_FOLDER:
            return {
                ...state,
                infrastructure: {
                    ...state.infrastructure,
                    folders: [
                        ...(state.infrastructure?.folders.filter((f) => {
                            return f.id !== action.folderId
                        }) ?? []),
                    ],
                    units: [...(state.infrastructure?.units ?? [])],
                },
            }

        default:
            return state
    }
}

const addFolder = (newFolder: FolderRes, folderList: FolderRes[]) => {
    const newFolders: FolderRes[] = [newFolder, ...folderList]

    for (let i = 0; i < newFolders.length - 1; i++) {
        const currentFolder = newFolders[i]
        const nextFolder = newFolders[i + 1]

        const currentPath = currentFolder.path + currentFolder.name
        const nextPath = nextFolder.path + nextFolder.name
        // Should we swap currentFolder and nextFolder?
        if (currentPath.localeCompare(nextPath) === 1) {
            newFolders[i] = nextFolder
            newFolders[i + 1] = currentFolder
        }
    }

    return newFolders
}
