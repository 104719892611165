import React from 'react'
import { useTheme } from '@material-ui/core'
import { UnitInspection } from '../../models'
import { AvatarCircle, Container, StatusBadge } from '../../components'

interface Props {
    unitInspection?: UnitInspection
    customStyle?: React.CSSProperties
    removeAvatarCircle?: boolean
}

export const UnitInspectionStatusBadge = (props: Props) => {
    const { unitInspection, removeAvatarCircle } = props

    const theme = useTheme()

    const BADGE_HEIGHT = 32

    let statusBadge: JSX.Element

    if (unitInspection) {
        statusBadge = (
            <Container
                style={{
                    alignItems: 'center',
                }}
            >
                {removeAvatarCircle ? (
                    <></>
                ) : (
                    <AvatarCircle
                        text={unitInspection.assigned_to?.name ?? 'My Team'}
                        initials
                        styles={{
                            container: {
                                marginRight: theme.spacing(1),
                                minHeight: 30,
                                minWidth: 30,
                                maxHeight: 30,
                                maxWidth: 30,
                            },
                        }}
                    />
                )}

                <StatusBadge
                    text={'Assigned'}
                    customStyle={{
                        container: {
                            height: BADGE_HEIGHT,
                            backgroundColor: theme.palette.secondary.main,
                            maxWidth: 90,
                            ...props.customStyle,
                        },
                        text: {
                            fontWeight: theme.typography.fontWeightBold,
                        },
                    }}
                />
            </Container>
        )

        if (unitInspection.complete_status) {
            statusBadge = (
                <Container
                    style={{
                        alignItems: 'center',
                    }}
                >
                    {removeAvatarCircle ? (
                        <></>
                    ) : (
                        <AvatarCircle
                            text={unitInspection.assigned_to?.name ?? 'My Team'}
                            initials
                            styles={{
                                container: {
                                    marginRight: theme.spacing(1),
                                    minHeight: 30,
                                    minWidth: 30,
                                    maxHeight: 30,
                                    maxWidth: 30,
                                },
                            }}
                        />
                    )}
                    <StatusBadge
                        text={'Complete'}
                        customStyle={{
                            container: {
                                height: BADGE_HEIGHT,
                                ...props.customStyle,
                            },
                            text: {
                                fontWeight: theme.typography.fontWeightBold,
                            },
                        }}
                    />
                </Container>
            )
        }
    } else {
        statusBadge = <></>
    }

    return statusBadge
}
