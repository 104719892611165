import {
    GET_SERVICE_LIST_REQUEST,
    GET_SERVICE_LIST_RESPONSE,
    ServiceActionTypes,
    ServiceState,
    SET_SERVICE_LIST,
} from './types'

const initialState: ServiceState = {
    serviceList: [],
    isLoading: {
        GET_SERVICE_LIST_REQUEST: false,
    },
}

export const serviceReducer = (
    state = initialState,
    action: ServiceActionTypes,
): ServiceState => {
    switch (action.type) {
        case GET_SERVICE_LIST_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_SERVICE_LIST_REQUEST: true,
                },
            }
        case GET_SERVICE_LIST_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_SERVICE_LIST_REQUEST: false,
                },
                serviceList: action.payload,
            }

        case SET_SERVICE_LIST:
            return {
                ...state,
                serviceList: action.payload,
            }

        default:
            return state
    }
}
