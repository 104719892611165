import React from 'react'
import { useTheme } from '@material-ui/core'
import { Container } from '../'

interface Props {
    name: string
    path: string
    unitLength: number
}

const FolderC: React.FC<Props> = (props) => {
    const { name, path, unitLength } = props

    const theme = useTheme()

    if (unitLength === 0) {
        return null
    }

    return (
        <Container
            style={{ margin: theme.spacing(1), padding: theme.spacing(1) }}
            direction="column"
        >
            <span
                style={{
                    ...theme.typography.h6,
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >{`${path}${name}`}</span>

            {props.children}
        </Container>
    )
}

export const FolderComponent: React.FC<Props> = React.memo(FolderC)
