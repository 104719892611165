import { Theme } from '@material-ui/core'
import React from 'react'
import { Container } from '..'

import { StagedAttachment } from './StagedAttachment'

interface Props {
    fileList?: File[]
    setFileList?: (files: File[]) => void
    theme: Theme
    type?: 'image' | 'excel'
    multiple?: boolean
}

export const AttachmentContainer = React.forwardRef<HTMLInputElement, Props>(
    (props, ref) => {
        const { fileList, setFileList, theme } = props

        const attachments =
            fileList &&
            fileList.map((attachment, idx) => {
                return (
                    <StagedAttachment
                        key={`STAGED_ATTACHMENT_${idx}`}
                        attachment={attachment}
                        onRemove={() => {
                            // Remove the selected file
                            const newFileList = fileList.filter(
                                (_, i) => i !== idx,
                            )
                            setFileList && setFileList(newFileList)
                        }}
                    />
                )
            })

        let accept = 'image/*'
        if (props.type === 'excel') {
            accept = '.xlsx'
        }

        return (
            <Container
                flex={1}
                style={{ margin: theme.spacing(1, 0) }}
                flexWrap="wrap"
                alignItems="flex-end"
            >
                {attachments}

                <input
                    ref={ref}
                    type="file"
                    style={{ display: 'none' }}
                    accept={accept}
                    onChange={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        const fileArray: File[] = []
                        const files = e.target.files
                        if (files === null) {
                            return
                        }
                        for (const k in files) {
                            const img = files[k]
                            if (img instanceof File) {
                                fileArray.push(img)
                            }
                        }
                        setFileList && setFileList(fileArray)
                    }}
                    multiple={
                        props.multiple === undefined || props.multiple === true
                    }
                />
            </Container>
        )
    },
)
