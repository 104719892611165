import { Theme } from '@material-ui/core'
import React from 'react'
import { DDLease } from '../../../../../models'
import { Container } from '../../../../../components'
import { toMMDDYYYY } from '../../../../../helpers'

interface Props {
    theme: Theme
    lease: DDLease
    tenantMatch: boolean
    locationMatch: boolean
    startDateMatch: boolean
    endDateMatch: boolean
    rentMatch: boolean
}

export const DDLeaseInfo = (props: Props) => {
    const {
        theme,
        lease,
        tenantMatch,
        locationMatch,
        startDateMatch,
        endDateMatch,
        rentMatch,
    } = props

    const textStyle: React.CSSProperties = {
        ...theme.typography.body2,
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.grey[700],
        flex: 1,
    }

    let startDate = null
    if (lease.lease_start) {
        startDate = new Date(lease.lease_start)
        startDate.setTime(
            startDate.getTime() + startDate.getTimezoneOffset() * 60 * 1000,
        )
    }

    let endDate = null
    if (lease.lease_end) {
        endDate = new Date(lease.lease_end)
        endDate.setTime(
            endDate.getTime() + endDate.getTimezoneOffset() * 60 * 1000,
        )
    }

    return (
        <>
            <Container
                style={{
                    flexDirection: 'column',
                    padding: theme.spacing(0, 3, 0, 2),
                    alignItems: 'flex-start',
                    flex: 1,
                }}
            >
                <span
                    style={{
                        ...textStyle,
                        color: tenantMatch ? theme.palette.grey[700] : 'red',
                    }}
                >
                    {lease.tenant ?? ''}
                </span>
                <span
                    style={{
                        ...textStyle,
                        color: rentMatch ? theme.palette.grey[700] : 'red',
                    }}
                >
                    ${lease.rent ?? ''}
                </span>
            </Container>

            <Container
                style={{
                    flexDirection: 'column',
                    padding: theme.spacing(0, 3, 0, 2),
                    alignItems: 'flex-start',
                    flex: 1,
                }}
            >
                <span
                    style={{
                        ...textStyle,
                        color: startDateMatch ? theme.palette.grey[700] : 'red',
                    }}
                >
                    {startDate && toMMDDYYYY(startDate)}
                </span>

                <span
                    style={{
                        ...textStyle,
                        color: endDateMatch ? theme.palette.grey[700] : 'red',
                    }}
                >
                    {endDate && toMMDDYYYY(endDate)}
                </span>
            </Container>

            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    paddingRight: theme.spacing(1),
                }}
            >
                <span
                    style={{
                        ...textStyle,
                        color: locationMatch ? theme.palette.grey[700] : 'red',
                    }}
                >
                    {lease.unit ?? ''}
                </span>
            </Container>
        </>
    )
}
