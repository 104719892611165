import React from 'react'
import { Badge, IconButton, Popover, Theme, Tooltip } from '@material-ui/core'
import MessageIcon from '@material-ui/icons/Message'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'

import { AvatarCircle, Container } from '../../../../components'
import { ApartmentDeadline, DeadlineTransitionLog } from '../../../../models'
import { toMMDDYYYY, toUiTime } from '../../../../helpers'
import { usePopover } from '../../../../hooks/usePopover'
import { DeadlineTransitionCard } from './DeadlineTransitionCard'
import { DeadlineStatusButton } from './DeadlineStatusButton'

interface Props {
    apartmentDeadline: ApartmentDeadline
    workspaceName: string
    theme: Theme
    selectedChannel: number | null
    transitionDeadline?: (deadlineId: number, complete: boolean) => void
    handleClickMessage: (channelId: number) => void
}

export const DeadlineRow = (props: Props) => {
    const {
        apartmentDeadline,
        theme,
        transitionDeadline,
        handleClickMessage,
        selectedChannel,
        workspaceName,
    } = props

    const complete = apartmentDeadline.complete_status

    return (
        <Container
            style={{
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(1),
                margin: theme.spacing(1, 0),
            }}
        >
            <Container style={{ flexDirection: 'column', width: 200 }}>
                <span
                    style={{
                        ...theme.typography.subtitle1,
                        color: theme.palette.grey[800],
                        fontWeight: 'bold',
                    }}
                >
                    {workspaceName}
                </span>
                <DeadlineStatusButton
                    theme={theme}
                    onClick={() =>
                        transitionDeadline &&
                        transitionDeadline(apartmentDeadline.id, !complete)
                    }
                    complete={complete}
                />
            </Container>

            <DeadlineTransitionCard
                theme={theme}
                transitions={apartmentDeadline.transitions}
            />

            <div style={{ flex: 1 }} />

            <IconButton
                onClick={() => handleClickMessage(apartmentDeadline.channel)}
            >
                <Badge
                    badgeContent={apartmentDeadline.message_count}
                    color="secondary"
                    style={{ alignSelf: 'center' }}
                >
                    <MessageIcon
                        color={
                            selectedChannel === apartmentDeadline.channel
                                ? 'primary'
                                : undefined
                        }
                    />
                </Badge>
            </IconButton>
        </Container>
    )
}
