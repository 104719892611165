import { Button, Divider, Switch, TextField, useTheme } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { Container } from '../../components'
import { ReactComponent as AddImage } from '../../assets/icons/add-image.svg'
import { ListVendor } from '../../models'
import { axiosInstance } from '../../helpers'
import { toast } from 'react-toastify'

interface Props {
    vendor: ListVendor
    setVendor: (vendor: ListVendor | undefined) => void
}

export const PremiumPage = (props: Props) => {
    const { vendor, setVendor } = props

    const [willingTravel, setWillingTravel] = useState(vendor.will_travel)
    const [featuredImage, setFeaturedImage] = useState<File | undefined>()
    const [featuredImageString, setFeaturedImageString] = useState<
        string | undefined
    >()

    const theme = useTheme()

    const headerStyle: React.CSSProperties = {
        fontWeight: 600,
        fontSize: '20px',
    }

    const bodyStyle: React.CSSProperties = {
        fontWeight: 400,
        fontSize: '15px',
        margin: theme.spacing(2, 0, 3, 0),
    }

    if (featuredImage) {
        const imageFile = featuredImage
        const fileReader = new FileReader()
        fileReader.onload = () => {
            const srcData = fileReader.result
            setFeaturedImageString(String(srcData))
        }
        fileReader.readAsDataURL(imageFile)
    }

    const renderFeaturedImage = vendor.featured_image || featuredImageString

    return (
        <Container flex={1} direction="column">
            <Container flex={1} style={{ margin: theme.spacing(8, 5, 5, 5) }}>
                <Container
                    flex={1}
                    style={{ marginRight: theme.spacing(2), maxWidth: '500px' }}
                    direction="column"
                >
                    <Container direction="column">
                        <Container
                            style={{ ...headerStyle, alignItems: 'center' }}
                        >
                            Are you a vendor willing to travel?
                            <Switch
                                color="primary"
                                value={willingTravel}
                                onChange={() =>
                                    setWillingTravel(!willingTravel)
                                }
                                checked={willingTravel}
                            />
                        </Container>
                        <Container style={{ ...bodyStyle }}>
                            Traveling for work? Click the toggle up above and
                            let everyone know you are willing to take your
                            services on the road!
                        </Container>
                    </Container>
                    <Container direction="column">
                        <span style={{ ...headerStyle }}>Featured Image</span>
                        <span style={{ ...bodyStyle }}>
                            Add a featured image that will be displayed to
                            apartments
                        </span>
                        {renderFeaturedImage && (
                            <img
                                src={
                                    featuredImageString
                                        ? featuredImageString
                                        : vendor.featured_image
                                }
                                style={{
                                    maxHeight: 200,
                                    maxWidth: 300,
                                }}
                            />
                        )}
                        <Container
                            direction="row"
                            style={{ alignItems: 'center' }}
                        >
                            <Fragment>
                                <input
                                    color="primary"
                                    accept="image/*"
                                    type="file"
                                    onChange={(e) => {
                                        if (
                                            e.target.files &&
                                            e.target.files[0]
                                        ) {
                                            setFeaturedImage(e.target.files[0])
                                        }
                                    }}
                                    id="featured-image-button-file"
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="featured-image-button-file">
                                    <Button component="span">
                                        <AddImage />
                                    </Button>
                                </label>
                            </Fragment>
                            <Container
                                style={{
                                    fontSize: '12px',
                                    color: theme.palette.grey[500],
                                }}
                            >
                                Recommend Image Size: 1920 x 1080 px
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>
            <Divider />
            <Container style={{ margin: theme.spacing(4, 0, 4, 5) }}>
                <Button
                    variant="outlined"
                    style={{
                        backgroundColor: theme.palette.primary.dark,
                        textTransform: 'none',
                        color: 'white',
                        padding: theme.spacing(0.5, 4, 0.5, 4),
                    }}
                    onClick={() => {
                        const body = new FormData()
                        if (willingTravel === true) {
                            body.append('will_travel', 'True')
                        } else {
                            body.append('will_travel', 'False')
                        }
                        if (featuredImage) {
                            body.append('featured_image', featuredImage)
                        }
                        axiosInstance
                            .patch(`company/vendor/${vendor.id}/`, body)
                            .then((res) => {
                                toast.success(`${res.data.vendor.name} updated`)
                                const tempVendor = JSON.parse(
                                    JSON.stringify(vendor),
                                )
                                tempVendor.will_travel =
                                    res.data.vendor.will_travel
                                tempVendor.featured_image =
                                    res.data.vendor.featured_image
                                setVendor(tempVendor)
                                setFeaturedImageString(undefined)
                            })
                    }}
                >
                    Save
                </Button>
            </Container>
        </Container>
    )
}
