import React from 'react'
import { Container, StatusBadge } from '../../components'
import { useSelector } from 'react-redux'
import { RootState, patchInventoryInspection } from '../../store'
import { useTheme } from '@material-ui/core'
import { InventoryInspection } from '../../models'
import { useAppDispatch } from '../../hooks'

interface Props {
    inventoryInspection: InventoryInspection
    editMode: boolean
}

export const InvInspectionStatuses = (props: Props) => {
    const { inventoryInspection, editMode } = props

    const statusGroupList = useSelector(
        (state: RootState) => state.aptConfig.statusGroupList,
    )

    const dispatch = useAppDispatch()

    const statusGroup = statusGroupList?.find(
        (statusGroup) => statusGroup.id === inventoryInspection.status_group_id,
    )

    const theme = useTheme()

    return (
        <Container flexWrap="wrap">
            {statusGroup?.custom_statuses?.map((status) => {
                let textColor = theme.palette.lightGrey.main
                if (editMode || status.id === inventoryInspection.status?.id) {
                    textColor = 'black'
                }

                return (
                    <StatusBadge
                        key={`STATUS_${status.id}`}
                        text={status.name ?? 'Un-inspected'}
                        customStyle={{
                            container: {
                                backgroundColor: 'transparent',
                                height: 20,
                                minWidth: 75,
                                border: `2px solid ${
                                    status.id === inventoryInspection.status?.id
                                        ? status.color
                                        : theme.palette.lightGrey.main
                                }`,
                                margin: theme.spacing(0, 2, 1, 0),
                            },
                            text: {
                                fontWeight: theme.typography.fontWeightBold,
                                color: textColor,
                            },
                        }}
                        onClick={() => {
                            if (!editMode) {
                                return
                            }
                            dispatch(
                                patchInventoryInspection({
                                    inventoryInspectionId:
                                        inventoryInspection.id,
                                    body: {
                                        status: status.id,
                                        config_index:
                                            inventoryInspection.config_index,
                                    },
                                }),
                            )
                        }}
                    />
                )
            })}
        </Container>
    )
}
