import React, { useState } from 'react'
import { Button, Divider, TextField, useTheme } from '@material-ui/core'
import { Range } from 'react-date-range'

import { Container, NumberInput } from '../../../../components'
import { isEmailValid, toMMDDYYYY } from '../../../../helpers'
import { Area, AreaConfig, Unit } from '../../../../models'
import { _useLease } from '../../../../hooks'

interface Props {
    dateRange: Range
    area?: Area
    areaConfig?: AreaConfig
    unit?: Unit
    onCreate: () => void
    onCancel: () => void
}

export const CreateLeaseConfirm = (props: Props) => {
    const { dateRange, area, areaConfig, unit } = props

    const { bulkCreateLeases } = _useLease()

    const theme = useTheme()

    const [rent, setRent] = useState<string>()
    const [tenantEmail, setTenantEmail] = useState('')
    const [tenantName, setTenantName] = useState('')
    const [doValidate, setDoValidate] = useState(false)

    return (
        <Container style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Container
                style={{
                    width: 500,
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    border: `1px solid ${theme.palette.grey[400]}`,
                    margin: theme.spacing(4),
                    padding: theme.spacing(4),
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: theme.shadows[3],
                    ...theme.typography.h6,
                }}
            >
                <Container
                    style={{
                        marginBottom: theme.spacing(1),
                        marginRight: theme.spacing(2),
                        justifyContent: 'space-between',
                    }}
                >
                    <span>Lease Term:</span>
                    <div style={{ flex: 1 }} />
                    <span>{toMMDDYYYY(dateRange.startDate)}</span>
                    <span style={{ margin: theme.spacing(0, 2) }}>-</span>
                    <span>{toMMDDYYYY(dateRange.endDate)}</span>
                </Container>

                <Container
                    style={{
                        margin: theme.spacing(1, 0),
                        justifyContent: 'space-between',
                    }}
                >
                    <span>Location: </span>
                    <span style={{ margin: theme.spacing(0, 2) }}>
                        {unit?.folder.path}
                        {unit?.folder.name}
                    </span>
                </Container>

                <Container
                    style={{
                        margin: theme.spacing(1, 0),
                        justifyContent: 'space-between',
                    }}
                >
                    <span>Unit: </span>
                    <span
                        style={{
                            margin: theme.spacing(0, 2),
                        }}
                    >
                        {unit?.name}
                    </span>
                </Container>

                <Container
                    style={{
                        margin: theme.spacing(1, 0),
                        justifyContent: 'space-between',
                    }}
                >
                    <span>Area: </span>
                    <span
                        style={{
                            margin: theme.spacing(0, 2),
                        }}
                    >
                        <span>
                            {areaConfig?.name} {area?.area_label}
                        </span>
                    </span>
                </Container>

                <Container
                    style={{
                        margin: theme.spacing(1, 0),
                        justifyContent: 'space-between',
                    }}
                >
                    <span>Tenant Email:</span>
                    <TextField
                        variant="outlined"
                        size="medium"
                        value={tenantEmail}
                        onChange={(e) => {
                            setTenantEmail(e.target.value)
                        }}
                        error={doValidate && !isEmailValid(tenantEmail)}
                        helperText={
                            doValidate && !isEmailValid(tenantEmail)
                                ? 'Invalid Email'
                                : ''
                        }
                    />
                </Container>
                <Container
                    style={{
                        margin: theme.spacing(1, 0),
                        justifyContent: 'space-between',
                    }}
                >
                    <span>Tenant Name:</span>
                    <TextField
                        variant="outlined"
                        size="medium"
                        value={tenantName}
                        onChange={(e) => {
                            setTenantName(e.target.value)
                        }}
                        error={doValidate && tenantName === ''}
                        helperText={
                            doValidate && tenantName === '' ? 'Required' : ''
                        }
                    />
                </Container>

                <Container
                    style={{
                        margin: theme.spacing(1, 0),
                        justifyContent: 'space-between',
                    }}
                >
                    <span>Rent (optional):</span>
                    <NumberInput
                        prefix={'$'}
                        variant="outlined"
                        value={rent}
                        onChange={(event) => {
                            setRent(event.target.value)
                        }}
                    />
                </Container>
                <Divider />

                <Container style={{ marginTop: theme.spacing(2) }}>
                    <div style={{ flex: 1 }} />
                    <Button
                        variant="outlined"
                        onClick={props.onCancel}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        style={{ marginLeft: theme.spacing(1) }}
                        variant="outlined"
                        onClick={() => {
                            if (
                                area &&
                                dateRange &&
                                dateRange.startDate &&
                                dateRange.endDate
                            ) {
                                if (
                                    !isEmailValid(tenantEmail) ||
                                    tenantName === ''
                                ) {
                                    setDoValidate(true)
                                    return
                                }
                                bulkCreateLeases({
                                    body: {
                                        leases: [
                                            {
                                                area: area.id,
                                                start_date: dateRange.startDate.toISOString(),
                                                end_date: dateRange.endDate.toISOString(),
                                                rent: isNaN(Number(rent))
                                                    ? undefined
                                                    : Number(rent),
                                                tenant: {
                                                    email: tenantEmail,
                                                    name: tenantName,
                                                },
                                            },
                                        ],
                                    },
                                })
                            }

                            props.onCreate()
                        }}
                        color="primary"
                    >
                        Create Lease
                    </Button>
                </Container>
            </Container>
        </Container>
    )
}
