import React from 'react'

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'

import { Container } from '../../../../../components'
import { BaseProps } from '../types'
import { ApartmentDeadline, WorkorderCluster } from '../../../../../models'
import { toMMDDYYYY } from '../../../../../helpers'

interface Props extends BaseProps {
    workorderCluster: WorkorderCluster
    onClick: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        workorderCluster: WorkorderCluster,
    ) => void
}

export const EventTypeWorkorderCluster = (props: Props) => {
    const { theme, onClick, workorderCluster } = props

    const workorderIdArray = workorderCluster.workorder_ids.split(',')

    const timelineLabel = (
        <Container>
            <span
                style={{
                    ...theme.typography.body2,
                    fontWeight: theme.typography.fontWeightBold,
                    marginRight: theme.spacing(1),
                }}
            >
                {toMMDDYYYY(new Date(workorderCluster.start_date))}
            </span>
            {workorderCluster.end_date && (
                <>
                    <span
                        style={{
                            ...theme.typography.body2,
                            fontWeight: theme.typography.fontWeightLight,
                            marginRight: theme.spacing(1),
                        }}
                    >
                        -
                    </span>

                    <span
                        style={{
                            ...theme.typography.body2,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {toMMDDYYYY(new Date(workorderCluster.end_date))}
                    </span>
                </>
            )}
        </Container>
    )

    return (
        <Container
            style={{
                paddingLeft: theme.spacing(1),
                alignItems: 'center',
            }}
            onClick={(e) => {
                onClick(e, props.workorderCluster)
            }}
        >
            <span
                style={{
                    ...theme.typography.body1,
                    fontWeight: theme.typography.fontWeightBold,
                    marginRight: theme.spacing(2),
                }}
            >
                {workorderCluster.service?.name ?? 'No Service'} (
                {workorderIdArray.length})
            </span>
            {timelineLabel}
        </Container>
    )
}
