import React, { useEffect, useState } from 'react'

import {
    Button,
    Divider,
    useTheme,
    Tooltip,
    Checkbox,
    FormControlLabel,
    Grid,
    Switch,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation'
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled'
import EventBusyIcon from '@material-ui/icons/EventBusy'
import InfoIcon from '@material-ui/icons/Info'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import {
    Container,
    DetailedToolTip,
    NumberInput,
    Selector,
    SideDrawer,
} from '../../../components'
import {
    convertMapToList,
    ListVendor,
    ModelMap,
    BaseWorkorder,
    ServiceContractMap,
    BULK_TRANSITION,
    AreaConfig,
    IdBoolMap,
    convertListToMap,
} from '../../../models'
import { isAccountManager, toMMDDYYYY } from '../../../helpers'
import {
    FinderSelection,
    useAnimationQueue,
    useAppDispatch,
    useUser,
} from '../../../hooks'

import { RemoveListItem } from '../../../animations'
import { toast } from 'react-toastify'
import {
    BulkUpdateWorkOrderRequest,
    UnitRes,
    WorkorderAreaDict,
    bulkDeleteWorkorderRequest,
    bulkUpdateWorkorders,
} from '../../../store'
import { BulkTransitionEditor } from './BulkTransitionEditor'
import { hasPermission } from '../../../models/Users/services'
import { AreaSelectorCollection } from '../../../components/AreaSelector'

interface Props {
    open: boolean
    selectedWorkorders: ModelMap<BaseWorkorder>
    workordersInFilter: BaseWorkorder[]
    vendorList: ListVendor[]
    removeWorkorder: (id: number) => void
    handleClose: () => void
    setSelectedWorkorders: (selectedWorkorders: ModelMap<BaseWorkorder>) => void
    upStreamContractMap: ServiceContractMap
    areaConfigMap: ModelMap<AreaConfig>
    unitList: UnitRes[]
    areaSelection: IdBoolMap
    setAreaSelection: (map: IdBoolMap) => void
}

const ROW_HEIGHT = 100
const ROW_MARGIN = 1

const NEGATIVE_CHANGE = 0
const POSITIVE_CHANGE = 1
const NO_CHANGE = 2

type ToggleSelection =
    | typeof NEGATIVE_CHANGE
    | typeof NO_CHANGE
    | typeof POSITIVE_CHANGE

const _BulkEditSelectionList = (props: Props) => {
    const {
        open,
        selectedWorkorders,
        removeWorkorder,
        workordersInFilter,
        setSelectedWorkorders,
        vendorList,
        upStreamContractMap,
        handleClose,
        areaConfigMap,
        unitList,
        areaSelection,
        setAreaSelection,
    } = props

    const { workspaceUser } = useUser()

    const theme = useTheme()
    const dispatch = useAppDispatch()

    const [dataFormOpen, setDataFormOpen] = useState(false)

    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [priority, setPriority] = useState<ToggleSelection>(NO_CHANGE)
    const [
        bedStatusSubscription,
        setBedStatusSubscription,
    ] = useState<ToggleSelection>(NO_CHANGE)
    const [addPrice, setAddPrice] = useState<number | undefined>()
    const [customPrice, setCustomPrice] = useState<number | undefined>()
    const [vendorId, setVendorId] = useState(-1)
    const [transitionModalOpen, setTransitionModalOpen] = useState(false)

    const [editAreas, setEditAreas] = useState(false)

    const workorders = open ? convertMapToList(selectedWorkorders) : []
    const { enqueueAnimation, currentAnimation } = useAnimationQueue()

    const unitMap = convertListToMap(unitList)
    const selectedUnits: UnitRes[] = []
    workorders.forEach((wo) => {
        const unit = unitMap[wo.unit_id ?? -1]
        if (unit) {
            selectedUnits.push(unit)
        }
    })

    useEffect(() => {
        setStartDate(null)
        setEndDate(null)
        setPriority(NO_CHANGE)
        setAddPrice(undefined)
        setCustomPrice(undefined)
        setVendorId(-1)
    }, [open])

    const isIntermediate =
        workorders.length > 0 && workorders.length < workordersInFilter.length

    const readyToSave =
        addPrice !== undefined ||
        customPrice !== undefined ||
        startDate !== null ||
        endDate !== null ||
        priority !== NO_CHANGE ||
        vendorId !== -1 ||
        bedStatusSubscription !== NO_CHANGE ||
        editAreas

    let servicesMatch = true
    let allUnlocked = true
    const sId = workorders.length > 0 ? workorders[0]?.service_id : -1
    // Get the first services id
    for (let i = 0; i < workorders.length; i++) {
        if (workorders[i]?.service_id !== sId) {
            // We found a service that does not match the first one
            // The user cannot update the vendor
            servicesMatch = false
        }
        if (workorders[i]?.price_locked) {
            allUnlocked = false
        }
    }

    const canUpdateVendor = servicesMatch && allUnlocked

    useEffect(() => {
        if (!canUpdateVendor) {
            setVendorId(-1)
        }
    }, [canUpdateVendor])

    useEffect(() => {
        if (!allUnlocked) {
            setStartDate(null)
        }
    }, [allUnlocked])

    useEffect(() => {
        if (!open) {
            setDataFormOpen(false)
        }
    }, [open])

    const createBulkUpdateBody = () => {
        const request: BulkUpdateWorkOrderRequest = {
            body: {
                workorders: workorders.map((wo) => wo.id),
                priority:
                    priority !== NO_CHANGE ? Boolean(priority) : undefined,
                start_date:
                    startDate !== null ? startDate.toISOString() : undefined,
                end_date: endDate !== null ? endDate.toISOString() : undefined,
                add_price: addPrice,
                custom_price: customPrice,
                vendor: vendorId !== -1 ? vendorId : undefined,
                subscribe_to_bed_status_change:
                    bedStatusSubscription !== NO_CHANGE
                        ? Boolean(bedStatusSubscription)
                        : undefined,
            },
        }
        if (editAreas) {
            const workorderAreaDict: WorkorderAreaDict[] = []
            // Go through the workorders
            workorders.forEach((wo) => {
                const serviceAreas: number[] = []
                const unit = unitMap[wo.unit_id ?? -1]
                unit?.areas.forEach((area) => {
                    if (areaSelection[area.id] === true) {
                        serviceAreas.push(area.id)
                    }
                })
                workorderAreaDict.push({
                    workorder_id: wo.id,
                    service_areas: serviceAreas,
                })
            })
            request.body.workorder_areas = workorderAreaDict
        }

        return request
    }

    return (
        <>
            <SideDrawer
                open={open}
                handleClose={handleClose}
                title={
                    <DrawerTitle
                        title={`Select (${workorders.length})`}
                        allUnlocked={allUnlocked}
                        canUpdateVendor={canUpdateVendor}
                    />
                }
                headerRight={
                    <FormControlLabel
                        style={{ marginRight: theme.spacing(1) }}
                        labelPlacement="start"
                        control={
                            <Checkbox
                                checked={
                                    workorders.length ===
                                    workordersInFilter.length
                                }
                                indeterminate={isIntermediate}
                                onChange={() => {
                                    if (workorders.length > 0) {
                                        setSelectedWorkorders({})
                                        setAreaSelection({})
                                    } else {
                                        setSelectedWorkorders(
                                            workordersInFilter.reduce(
                                                (map, wo) => {
                                                    return {
                                                        ...map,
                                                        [wo.id]: wo,
                                                    }
                                                },
                                                {},
                                            ),
                                        )
                                        const tempAreaSelection = {
                                            ...areaSelection,
                                        }
                                        workordersInFilter.forEach((wo) => {
                                            wo.service_area_list.forEach(
                                                (area) => {
                                                    tempAreaSelection[
                                                        area.area_id
                                                    ] = true
                                                },
                                            )
                                        })
                                        setAreaSelection(tempAreaSelection)
                                    }
                                }}
                            />
                        }
                        label={
                            workorders.length > 0 ? 'Remove All' : 'Select All'
                        }
                    />
                }
            >
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        overflowX: 'hidden',
                    }}
                    scrollY
                >
                    {workorders.map((wo, idx) => {
                        return (
                            <WorkorderRow
                                key={`EDIT_WORKORDER_${wo.id}`}
                                workorder={wo}
                                allServicesMatch={servicesMatch}
                                animationClassName={
                                    currentAnimation?.effectMap[wo.id]
                                        ?.animationCssClass
                                }
                                animationStyle={{
                                    ...currentAnimation?.effectMap[wo.id]
                                        ?.animationStyles,
                                }}
                                removeWorkorder={(id) => {
                                    enqueueAnimation(
                                        RemoveListItem(
                                            250,
                                            wo,
                                            workorders.slice(idx + 1),
                                            ROW_HEIGHT +
                                                theme.spacing(ROW_MARGIN) * 2,
                                            () => {
                                                removeWorkorder(id)
                                            },
                                        ),
                                    )
                                }}
                            />
                        )
                    })}
                </Container>

                <Divider />
                <Container
                    style={{
                        padding: theme.spacing(2),
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        color="secondary"
                        disabled={
                            !allUnlocked && !isAccountManager(workspaceUser)
                        }
                        variant="outlined"
                        style={{ marginRight: theme.spacing(2) }}
                        onClick={() => {
                            if (
                                confirm(
                                    `Are you sure you want to delete ${workorders.length} workorders?\n\nThis cannot be reversed!`,
                                )
                            ) {
                                dispatch(
                                    bulkDeleteWorkorderRequest({
                                        body: {
                                            workorders: workorders.map(
                                                (wo) => wo.id,
                                            ),
                                        },
                                    }),
                                ).then(() => {
                                    toast.success(
                                        `${workorders.length} Deleted`,
                                    )
                                    props.handleClose()
                                })
                            }
                        }}
                    >
                        Delete
                    </Button>

                    <div style={{ flex: 1 }} />

                    <Button
                        color="default"
                        variant="outlined"
                        style={{ marginRight: theme.spacing(2) }}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        style={{ marginRight: theme.spacing(2) }}
                        onClick={() => {
                            setDataFormOpen(true)
                        }}
                        disabled={workorders.length === 0}
                    >
                        Next
                    </Button>
                </Container>
            </SideDrawer>

            <SideDrawer
                open={dataFormOpen}
                handleClose={() => setDataFormOpen(false)}
                title={
                    <DrawerTitle
                        title={`Select (${workorders.length})`}
                        allUnlocked={allUnlocked}
                        canUpdateVendor={canUpdateVendor}
                    />
                }
            >
                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        overflowY: 'hidden',
                    }}
                >
                    <Container
                        style={{
                            flex: 1,
                            padding: theme.spacing(1),
                            flexDirection: 'column',
                        }}
                        scrollY
                    >
                        <Container style={{ alignItems: 'center' }}>
                            <Selector
                                disabled={!canUpdateVendor}
                                getDisplayString={(vendor) => vendor.name}
                                label="Vendor"
                                data={vendorList.filter((v) => {
                                    return (
                                        v.services.find((s) => s.id === sId) !==
                                        undefined
                                    )
                                })}
                                currentValue={vendorId}
                                onChange={(
                                    event: React.ChangeEvent<{
                                        value: unknown
                                    }>,
                                ) => {
                                    setVendorId(event.target.value as number)
                                }}
                                customStyle={{
                                    formControl: {
                                        margin: theme.spacing(2, 0),
                                        flex: 1,
                                    },
                                }}
                            />
                            <DetailedToolTip
                                headline="Can only be edited when:"
                                customStyle={{
                                    container: { marginLeft: theme.spacing(2) },
                                }}
                                messages={[
                                    {
                                        message:
                                            '1. All work orders are Assigned',
                                        warning: !allUnlocked,
                                    },
                                    {
                                        message: '2. All Services Match',
                                        warning: !servicesMatch,
                                    },
                                ]}
                            >
                                <InfoIcon
                                    fontSize="large"
                                    color={canUpdateVendor ? 'action' : 'error'}
                                />
                            </DetailedToolTip>
                        </Container>

                        <Container style={{ alignItems: 'center' }}>
                            <KeyboardDatePicker
                                disabled={!allUnlocked}
                                value={startDate}
                                onChange={(date) => {
                                    setStartDate(date)
                                }}
                                format="MM/dd/yyyy"
                                inputVariant="outlined"
                                label="Start Date"
                                style={{ margin: theme.spacing(2, 0) }}
                                fullWidth
                                clearable
                            />

                            <DetailedToolTip
                                headline="Can only be edited when:"
                                customStyle={{
                                    container: { marginLeft: theme.spacing(2) },
                                }}
                                messages={[
                                    {
                                        message:
                                            '1. All work orders are Assigned',
                                        warning: !allUnlocked,
                                    },
                                ]}
                            >
                                <InfoIcon
                                    fontSize="large"
                                    color={allUnlocked ? 'action' : 'error'}
                                />
                            </DetailedToolTip>
                        </Container>

                        <KeyboardDatePicker
                            value={endDate}
                            onChange={(date) => {
                                setEndDate(date)
                            }}
                            format="MM/dd/yyyy"
                            inputVariant="outlined"
                            label="End Date"
                            style={{ margin: theme.spacing(2, 0) }}
                            fullWidth
                            clearable
                        />

                        <NumberInput
                            prefix={'$'}
                            variant="outlined"
                            label="Add Price"
                            value={addPrice ?? ''}
                            style={{ margin: theme.spacing(2, 0) }}
                            onChange={(e) => {
                                if (e.target.value === '') {
                                    setAddPrice(undefined)
                                } else {
                                    setAddPrice(Number(e.target.value))
                                }
                            }}
                        />

                        <NumberInput
                            prefix={'$'}
                            variant="outlined"
                            label="Custom Price"
                            value={customPrice ?? ''}
                            style={{ margin: theme.spacing(2, 0) }}
                            onChange={(e) => {
                                if (e.target.value === '') {
                                    setCustomPrice(undefined)
                                } else {
                                    setCustomPrice(Number(e.target.value))
                                }
                            }}
                        />

                        <Container style={{ alignItems: 'center' }}>
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeightBold,
                                    margin: theme.spacing(1, 0),
                                }}
                            >
                                Edit Areas
                            </span>
                            <Switch
                                checked={editAreas}
                                color="primary"
                                onClick={() => {
                                    setEditAreas(!editAreas)
                                }}
                            />
                        </Container>
                        {editAreas && (
                            <AreaSelectorCollection
                                areaConfigMap={areaConfigMap}
                                areaSelectionMap={areaSelection}
                                setAreaSelectionMap={setAreaSelection}
                                units={selectedUnits}
                                maxHeight="500px"
                                ignoreScroll
                            />
                        )}

                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                                margin: theme.spacing(1, 0),
                            }}
                        >
                            Edit Priority
                        </span>
                        <ToggleButtonGroup
                            value={priority}
                            onChange={(_, priority) => {
                                setPriority(priority)
                            }}
                            aria-label="Priority Select"
                            exclusive
                        >
                            <ToggleButton
                                value={NEGATIVE_CHANGE}
                                aria-label="not-priority"
                            >
                                None
                            </ToggleButton>
                            <ToggleButton
                                value={NO_CHANGE}
                                aria-label="no-change"
                            >
                                No Changes
                            </ToggleButton>
                            <ToggleButton
                                value={POSITIVE_CHANGE}
                                aria-label="priority"
                            >
                                All
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                                margin: theme.spacing(1, 0),
                            }}
                        >
                            Bed Status Subscription
                        </span>
                        <ToggleButtonGroup
                            value={bedStatusSubscription}
                            onChange={(_, subscription) => {
                                setBedStatusSubscription(subscription)
                            }}
                            aria-label="Subscription Select"
                            exclusive
                        >
                            <ToggleButton
                                value={NEGATIVE_CHANGE}
                                aria-label="unsubscribe"
                            >
                                Unsubscribe
                            </ToggleButton>
                            <ToggleButton
                                value={NO_CHANGE}
                                aria-label="no-change"
                            >
                                No Changes
                            </ToggleButton>
                            <ToggleButton
                                value={POSITIVE_CHANGE}
                                aria-label="subscribe"
                            >
                                Subscribe
                            </ToggleButton>
                        </ToggleButtonGroup>

                        {/* Add transition button */}
                        <Container
                            style={{
                                justifyContent: 'flex-start',
                                marginTop: theme.spacing(2),
                            }}
                        >
                            <Button
                                color="primary"
                                variant="outlined"
                                disabled={
                                    !hasPermission(
                                        workspaceUser,
                                        BULK_TRANSITION,
                                    )
                                }
                                onClick={() =>
                                    setTransitionModalOpen(!transitionModalOpen)
                                }
                            >
                                Transition
                            </Button>
                        </Container>

                        <BulkTransitionEditor
                            workorders={workorders}
                            isOpen={transitionModalOpen}
                            handleBulkTransitionClose={() =>
                                setTransitionModalOpen(false)
                            }
                            upStreamContractMap={upStreamContractMap}
                            setSelectedWorkorders={setSelectedWorkorders}
                            handleBulkEditClose={handleClose}
                            handleDataFormClose={() => setDataFormOpen(false)}
                        />
                    </Container>

                    <Divider />
                    <Container
                        style={{
                            padding: theme.spacing(2),
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            color="secondary"
                            variant="outlined"
                            style={{ marginRight: theme.spacing(2) }}
                            onClick={() => setDataFormOpen(false)}
                        >
                            Previous
                        </Button>

                        <Button
                            disabled={!readyToSave}
                            color="primary"
                            variant="outlined"
                            style={{ marginRight: theme.spacing(2) }}
                            onClick={() => {
                                let message = `You are bulk editing ${workorders.length} workorders \n\n`
                                if (addPrice !== undefined) {
                                    message += `-Add Price: $${addPrice}\n`
                                }
                                if (customPrice !== undefined) {
                                    message += `-Custom Price: $${customPrice}\n`
                                }
                                if (startDate !== null) {
                                    message += `-Start Date: ${toMMDDYYYY(
                                        startDate,
                                    )}\n`
                                }
                                if (endDate !== null) {
                                    message += `-End Date: ${toMMDDYYYY(
                                        endDate,
                                    )}\n`
                                }

                                if (priority !== NO_CHANGE) {
                                    message += `-Priority: ${
                                        priority === NEGATIVE_CHANGE
                                            ? 'None'
                                            : 'All'
                                    }\n`
                                }

                                if (vendorId !== -1) {
                                    message += `-Vendor: ${
                                        vendorList.find(
                                            (v) => v.id === vendorId,
                                        )?.name
                                    }\n`
                                }

                                if (bedStatusSubscription !== NO_CHANGE) {
                                    message += `-Bed Status Subscription: ${
                                        priority === NEGATIVE_CHANGE
                                            ? 'Unsubscribe'
                                            : 'Subscribe'
                                    }\n`
                                }

                                if (editAreas) {
                                    message +=
                                        '-Service Areas: Confirm the correct areas are selected'
                                }

                                if (confirm(message)) {
                                    const request = createBulkUpdateBody()

                                    dispatch(
                                        bulkUpdateWorkorders(request),
                                    ).then(() => {
                                        toast.success(
                                            `${workorders.length} Workorders updated!`,
                                        )
                                        props.handleClose()
                                    })
                                }
                            }}
                        >
                            Next
                        </Button>
                    </Container>
                </Container>
            </SideDrawer>
        </>
    )
}

interface RowProps {
    workorder: BaseWorkorder
    allServicesMatch: boolean
    animationClassName?: string
    animationStyle?: React.CSSProperties
    removeWorkorder: (id: number) => void
}

const WorkorderRow = (props: RowProps) => {
    const {
        workorder,
        allServicesMatch,
        removeWorkorder,
        animationClassName,
        animationStyle,
    } = props

    const theme = useTheme()

    return (
        <Container
            style={{
                minHeight: ROW_HEIGHT,
                maxHeight: ROW_HEIGHT,
                margin: theme.spacing(ROW_MARGIN),
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.grey[100],
                boxSizing: 'border-box',
                ...animationStyle,
            }}
            className={animationClassName}
        >
            {/* Col 1 */}
            <Container
                style={{
                    padding: theme.spacing(1),
                    flex: 1,
                    borderTopLeftRadius: theme.shape.borderRadius,
                    borderBottomLeftRadius: theme.shape.borderRadius,
                    border: `1px solid ${theme.palette.grey[400]}`,
                    borderRight: 0,
                }}
            >
                <Container style={{ flexDirection: 'column' }}>
                    {/* Row 1 */}
                    <Container style={{ alignItems: 'center' }}>
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {workorder.service_name ?? ''}
                        </span>

                        <span
                            style={{
                                ...theme.typography.subtitle2,
                                marginLeft: theme.spacing(1),
                            }}
                        >
                            {/* {toMMDDYYYY(new Date(workorder.start_date))} */}
                        </span>
                    </Container>

                    {/* Row 2 */}
                    <span style={{ ...theme.typography.subtitle2 }}>
                        {workorder.vendor_name}
                    </span>

                    <div style={{ flex: 1 }} />

                    {/* Row 3 */}
                    <Container>
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {workorder.path ?? ''}
                        </span>
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {workorder.folder_name ?? ''}
                            {workorder.folder_name && '/'}
                        </span>
                        <span style={{ marginLeft: theme.spacing(1) }}>
                            {workorder.unit_name ?? ''}
                        </span>
                    </Container>
                </Container>
                {/* ICONS */}
                <Grid container alignContent="center">
                    <Grid
                        item
                        container
                        xs={6}
                        alignContent="center"
                        justify="center"
                    >
                        {workorder.price_locked ? (
                            <Tooltip title="Start Date Locked">
                                <EventBusyIcon
                                    fontSize="large"
                                    color="action"
                                />
                            </Tooltip>
                        ) : null}
                    </Grid>

                    <Grid
                        item
                        container
                        xs={6}
                        alignContent="center"
                        justify="center"
                    >
                        {workorder.price_locked || !allServicesMatch ? (
                            <Tooltip title="Vendor Locked">
                                <PersonAddDisabledIcon
                                    fontSize="large"
                                    color="action"
                                />
                            </Tooltip>
                        ) : null}
                    </Grid>
                </Grid>
            </Container>
            {/* End Col 1 */}

            {/* End Col 2 */}

            {/* Col 3 Close Button*/}
            <Container
                style={{
                    padding: theme.spacing(1),
                    borderTopRightRadius: theme.shape.borderRadius,
                    borderBottomRightRadius: theme.shape.borderRadius,
                    backgroundColor: theme.palette.secondary.light,
                    alignItems: 'flex-end',
                    flexDirection: 'column',
                    boxShadow: `inset 2px 0px 5px ${theme.palette.secondary.dark}`,
                    cursor: 'pointer',
                }}
                onClick={() => removeWorkorder(workorder.id)}
            >
                <div style={{ flex: 1 }} />
                <div>
                    <Tooltip title="Remove from selection">
                        <CancelPresentationIcon fontSize="large" />
                    </Tooltip>
                </div>

                <div style={{ flex: 1 }} />
            </Container>
            {/* End Col 3 */}
        </Container>
    )
}

interface DrawerTitleProps {
    title: string
    allUnlocked: boolean
    canUpdateVendor: boolean
}

const DrawerTitle = (props: DrawerTitleProps) => {
    const { title, allUnlocked, canUpdateVendor } = props

    const theme = useTheme()

    return (
        <Container style={{ flexDirection: 'column' }}>
            <Container>
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightMedium,
                    }}
                >
                    {title}
                </span>
            </Container>

            <Container>
                {!allUnlocked ? (
                    <Tooltip title="Start Date Locked">
                        <EventBusyIcon fontSize="small" color="action" />
                    </Tooltip>
                ) : null}
                {!canUpdateVendor ? (
                    <Tooltip title="Vendor Locked">
                        <PersonAddDisabledIcon
                            fontSize="small"
                            color="action"
                        />
                    </Tooltip>
                ) : null}
            </Container>
        </Container>
    )
}

export const BulkEditSelectionList = React.memo(_BulkEditSelectionList)
