import React, { useEffect, useState, useRef } from 'react'
import { useTheme } from '@material-ui/core'

interface Props {
    size: number
    progress: number
    strokeWidth?: number
    circleOneStroke?: string
    circleTwoStroke?: string
    containerStyle?: React.CSSProperties
}

export const Progress = (props: Props) => {
    const theme = useTheme()

    const [offset, setOffset] = useState(0)
    const circleRef = useRef(null)

    const {
        size,
        progress,
        strokeWidth,
        circleOneStroke,
        circleTwoStroke,
    } = props

    const center = size / 2
    const radius = size / 2 - (strokeWidth ?? 2) / 2
    const circumference = 2 * Math.PI * radius

    useEffect(() => {
        const progressOffset = (progress / 100) * circumference
        setOffset(progressOffset)
    }, [circumference, progress])

    return (
        <svg height={size} width={size} style={props.containerStyle}>
            <circle
                className="progress-ring__circle"
                strokeWidth={strokeWidth}
                stroke={circleTwoStroke ?? theme.palette.primary.main}
                fill="transparent"
                r={radius}
                cx={center}
                cy={center}
            />

            <circle
                className="svg-circle"
                stroke={circleOneStroke ?? theme.palette.grey[400]}
                cx={center}
                cy={center}
                r={radius}
                fill="transparent"
                strokeWidth={strokeWidth}
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                ref={circleRef}
            />
        </svg>
    )
}
