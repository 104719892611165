import React from 'react'

export const SupportRequestForm = () => {
    return (
        <iframe
            id="JotFormIFrame-220725897018159"
            title="EZ Turn Support Request Form"
            // allowTransparency={true}
            allowFullScreen={true}
            src="https://form.jotform.com/220725897018159"
            frameBorder="0"
            style={{
                minHeight: '100%',
                minWidth: '100%',
                border: 'none',
            }}
            scrolling="yes"
        />
    )
}
