import React, { useEffect, useMemo, useState } from 'react'
import { Attachment, Container, GenericTransitionLog, TransitionLogPopver } from '../../components'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import {
    ArrowDownward,
    Assignment,
    Note,
    Search,
    Tune,
    YoutubeSearchedFor,
} from '@material-ui/icons'
import {
    AreaConfig,
    Attachment as AttachmentType,
    InspectionStatus,
    InventoryInspection,
    Lease,
    LeaseStatusType,
    ModelMap,
    Unit,
    getInspectionStatusLabel,
    getIssueCount,
    traverse,
} from '../../models'
import { axiosInstance, toMMDDYYYY } from '../../helpers'
import {
    FinderSelection,
    useAptConfig,
    useLeaseSingleStatusRequest,
    useRootInfrastructure,
} from '../../hooks'
import { MoveInModal } from './MoveInModal'
import { LeaseFilterDrawer } from '../Leases/Forms/LeaseFilterDrawer'
import {
    AutoCompleteFilterValue,
    DateFilterState,
    IssueFilterValue,
    LeaseStatusFilterState,
    TenantInspectionStatusFilterState,
    useLeaseFilter,
} from '../../hooks/useLeaseFilter'
import { VersionedTree } from '../../hooks/useInfrastructure/types'
import { Pagination } from '@material-ui/lab'
import { LeaseHomeChips } from '../Leases/Tabs/LeaseHome/LeaseHomeChips'
import { toast } from 'react-toastify'

interface Props {
    getFilteredLeases: (leaseList: Lease[], unitMap: ModelMap<Unit>) => Lease[]
    filterDrawerOpen: boolean
    setFilterDrawerOpen: (open: boolean) => void
    searchValue: string
    setSearchValue: (val: string) => void
    tree: VersionedTree
    leaseList: Lease[]
    isLoading: boolean
    dateFilter: DateFilterState
    setDateFilter: (dateFilter: DateFilterState) => void
    locationFinderSelection: FinderSelection
    statusFilter: LeaseStatusFilterState
    setStatusFilter: (sf: LeaseStatusFilterState) => void
    hasIssue: IssueFilterValue
    setHasIssue: (hasIssue: IssueFilterValue) => void
    tenantInspectionStatusFilter: TenantInspectionStatusFilterState
    setTenantInspectionStatusFilter: (
        newFilter: TenantInspectionStatusFilterState,
    ) => void
    autoCompleteFilter: AutoCompleteFilterValue
    setAutoCompleteFilter: (val: AutoCompleteFilterValue) => void
}

export const MoveInspectionContainer = (props: Props) => {
    const {
        getFilteredLeases,
        filterDrawerOpen,
        setFilterDrawerOpen,
        searchValue,
        setSearchValue,
        tree,
        leaseList,
        isLoading,
        dateFilter,
        setDateFilter,
        locationFinderSelection,
        statusFilter,
        setStatusFilter,
        hasIssue,
        setHasIssue,
        tenantInspectionStatusFilter,
        setTenantInspectionStatusFilter,
        autoCompleteFilter,
        setAutoCompleteFilter,
    } = props
    const { getInventoryConfigMap, getAreaConfigMap } = useAptConfig({
        inventoryConfigList: true,
        areaConfigList: true,
    })
    const inventoryConfigMap = getInventoryConfigMap()
    const areaConfigMap = getAreaConfigMap()

    const [unitMap, setUnitMap] = useState<ModelMap<Unit>>({})


    const filteredLeases = getFilteredLeases(leaseList, unitMap)

    const [comparisonModalLease, setComparisonModalLease] = useState<Lease>()

    const [resendEmailOpen, setResendEmailOpen] = useState(false)

    const [pageSize, setPageSize] = useState(25)
    const [page, setPage] = useState(0)
    const numPages = Math.ceil((filteredLeases?.length ?? 0) / pageSize)
    // When the filters change, make sure that the users selected page still exists
    // If it does not select the the last valid page
    useEffect(() => {
        if (page + 1 > numPages) {
            setPage(Math.max(0, numPages - 1))
        }
    }, [filteredLeases])

    const paginatedList = useMemo(() => {
        const start = page * pageSize
        const end = start + pageSize

        return filteredLeases?.slice(start, end)
    }, [page, filteredLeases])

    const theme = useTheme()

    // Build a unit map
    useEffect(() => {
        const newUnitMap: ModelMap<Unit> = {}

        traverse(tree.root, (folder) => {
            folder.units.forEach((u) => {
                newUnitMap[u.id] = u
            })
        })
        setUnitMap(newUnitMap)
    }, [tree.version])

    return (
        <Container direction="column" style={{ overflowY: 'scroll' }}>
            {/* Header */}
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    padding: theme.spacing(1),
                }}
            >
                <Container style={{ alignItems: 'center', height: 50 }}>
                    <TextField
                        size="small"
                        autoFocus
                        placeholder="Type to search tenants..."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                        value={searchValue}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key !== 'Escape') {
                                // Prevents autoselecting item while typing (default Select behaviour)
                                e.stopPropagation()
                            }
                        }}
                        style={{ width: 600 }}
                    />
                    <div style={{ flex: 1 }} />
                    {isLoading && (
                        <CircularProgress
                            color="primary"
                            style={{ marginLeft: theme.spacing(2) }}
                        />
                    )}
                    {/*
                    <PaginationController
                        page={page}
                        numPages={numPages}
                        listLength={filteredLeases.length ?? 0}
                        setPage={setPage}
                    /> */}
                    {/* <Button
                        style={{
                            color: '#fff',
                            backgroundColor: theme.palette.primary.dark,
                            width: '106px',
                            borderRadius: '5px',
                            borderWidth: 0,
                            marginLeft: '9px',
                            fontSize: '14px',
                            lineHeight: '17px',
                            fontWeight: 500,
                            cursor: 'pointer',
                            textTransform: 'none',
                        }}
                        onClick={() => setResendEmailOpen(true)}
                    >
                        Resend RCR Emails
                    </Button> */}

                    <Tooltip
                        title="Filtered XLSX"
                        style={{ marginLeft: theme.spacing(4) }}
                    >
                        <IconButton
                            onClick={() => {
                                axiosInstance
                                    .post('lease/email_move_inspection_xlsx/', {
                                        leases: filteredLeases.map(
                                            (lease) => lease.id,
                                        ),
                                    })
                                    .then((res) => {
                                        toast.success(
                                            "We are working on your report. You will recieve an email when it's complete.",
                                        )
                                    })
                            }}
                        >
                            <Note fontSize="large" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip
                        title="Filter"
                        style={{ marginLeft: theme.spacing(4) }}
                    >
                        <IconButton
                            onClick={() =>
                                setFilterDrawerOpen(!filterDrawerOpen)
                            }
                        >
                            <Tune fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Container>
                <LeaseHomeChips
                    dateFilter={dateFilter}
                    setDateFilter={setDateFilter}
                    locationFinderSelection={locationFinderSelection}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                    hasIssue={hasIssue}
                    setHasIssue={setHasIssue}
                    tenantInspectionStatusFilter={tenantInspectionStatusFilter}
                    setTenantInspectionStatusFilter={
                        setTenantInspectionStatusFilter
                    }
                    autoCompleteFilter={autoCompleteFilter}
                    setAutoCompleteFilter={setAutoCompleteFilter}
                />
            </Container>
            <Divider />

            {/* Body */}
            <Container style={{ overflowY: 'scroll', flexDirection: 'column' }}>
                {paginatedList.map((lease) => {
                    return (
                        <MoveInspectionRow
                            lease={lease}
                            unitMap={unitMap}
                            areaConfigMap={areaConfigMap}
                            key={`INS_${lease.id}`}
                            setMoveComparisonInspections={
                                setComparisonModalLease
                            }
                        />
                    )
                })}
            </Container>

            {/* Footer */}
            <Container>
                <div style={{ flex: 1 }} />
                <PaginationController
                    page={page}
                    numPages={numPages}
                    listLength={filteredLeases.length ?? 0}
                    setPage={setPage}
                />
            </Container>

            <MoveInModal
                open={comparisonModalLease !== undefined}
                handleClose={() => setComparisonModalLease(undefined)}
                lease={comparisonModalLease}
                unitMap={unitMap}
                areaConfigMap={areaConfigMap}
                invConfigMap={inventoryConfigMap}
            />

            <Dialog
                open={resendEmailOpen}
                onClose={() => setResendEmailOpen(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    Resend move in emails to the selected Leases?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        test
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setResendEmailOpen(false)}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                            const idList = filteredLeases.reduce<number[]>(
                                (idList, lease) => {
                                    if (lease.tenant_move_in_inspection) {
                                        return idList.concat(
                                            lease.tenant_move_in_inspection.id,
                                        )
                                    }

                                    return idList
                                },
                                [],
                            )

                            axiosInstance.post(
                                'inspection/area-inspection/bulk_reset_move_in_inspections/',
                                { move_in_inspection_ids: idList },
                            )
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

interface MoveRowProps {
    lease: Lease
    unitMap: ModelMap<Unit>
    areaConfigMap: ModelMap<AreaConfig>
    setMoveComparisonInspections: (lease: Lease) => void
}

const MoveInspectionRow = (props: MoveRowProps) => {
    const { lease, unitMap, areaConfigMap } = props

    const leaseCount = (lease.children?.length ?? 0) + 1

    const theme = useTheme()
    const attachmentList: AttachmentType[] = []
    lease.tenant_move_in_inspection?.inventory_inspection?.forEach((invIns) => {
        invIns.issue?.forEach((issue) => {
            if (issue.message.attachments.length > 0) {
                attachmentList.push(...issue.message.attachments)
            }
        })
    })

    let displayStatus = getInspectionStatusLabel(
        lease.tenant_move_in_inspection?.status,
    )

    if (lease.tenant_move_in_inspection?.status === InspectionStatus.COMPLETE) {
        displayStatus = lease.tenant_move_in_inspection.auto_completed
            ? 'Auto Complete'
            : 'Tenant Completed'
    }

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
    const [transitionLogs, setTransitionLogs] = useState<GenericTransitionLog[] | null>(null)

    return (
        <Container
            style={{
                margin: theme.spacing(1),
                backgroundColor: theme.palette.grey[100],
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.palette.grey[600]}`,
                minHeight: 200,
                overflowX: 'auto',
            }}
        >
            {/* Section 1 */}
            <Container
                style={{
                    flex: 1,
                    borderRight: `1px solid ${theme.palette.grey[600]}`,
                    flexDirection: 'column',
                    marginLeft: theme.spacing(2),
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                }}
            >
                <span style={{ ...theme.typography.body1 }}>
                    {unitMap[lease.area.unit]?.name}
                </span>
                <span style={{ ...theme.typography.h6, fontWeight: 'bold' }}>
                    {areaConfigMap[lease.area.area_config]?.name} (
                    {lease.area.area_label})
                </span>
                <span style={{ ...theme.typography.body1 }}>
                    Leases: {leaseCount}
                </span>
                {lease.tenant_move_in_inspection && (

                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation()
                            setAnchorEl(e.currentTarget)
                            axiosInstance.get(`inspection/area-inspection/${lease.tenant_move_in_inspection?.id}/get_transition_logs/`).then((res) => {
                                setTransitionLogs(res.data)
                            })
                        }}

                    >
                        <YoutubeSearchedFor />
                    </IconButton>

                )}
            </Container>
            {/* Section 2 */}

            <Container
                style={{
                    flex: 1,
                    borderRight: `1px solid ${theme.palette.grey[600]}`,
                    justifyContent: 'center',
                }}
            >
                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        margin: theme.spacing(1, 0, 0, 1),
                    }}
                >
                    <Container style={{ flexDirection: 'column', flex: 1 }}>
                        <span
                            style={{
                                ...theme.typography.body1,
                                fontWeight: 'bold',
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            {lease.user.name}
                        </span>
                        <span
                            style={{
                                ...theme.typography.body1,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            {lease.user.email}
                        </span>
                        {lease.tenant_move_in_inspection && (
                            <span
                                style={{
                                    ...theme.typography.body2,
                                    marginBottom: theme.spacing(1),
                                }}
                            >
                                Tenant Inspection: {displayStatus}
                            </span>
                        )}
                    </Container>

                    <Container style={{ flexDirection: 'row', flex: 1 }}>
                        <Container
                            style={{
                                flexDirection: 'column',
                                flex: 1,
                                justifyContent: 'center',
                            }}
                        >
                            <span style={{ ...theme.typography.body1 }}>
                                {toMMDDYYYY(new Date(lease.start_date))}
                            </span>
                            <span style={{ ...theme.typography.body1 }}>
                                {toMMDDYYYY(new Date(lease.end_date))}
                            </span>
                        </Container>
                        <Container style={{ flex: 1, alignItems: 'center' }}>
                            <span
                                style={{
                                    ...theme.typography.body1,
                                }}
                            >
                                {getIssueCount(lease)} Issue Found
                            </span>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    props.setMoveComparisonInspections(lease)
                                }}
                            >
                                <Assignment />
                            </IconButton>
                            <Tooltip title="Download PDF">
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        axiosInstance
                                            .get(
                                                `lease/${lease.id}/get_move_inspection_pdf/`,
                                            )
                                            .then(() => {
                                                toast.success(
                                                    'We are working on your PDF. You will recieve an email when its complete.',
                                                )
                                            })
                                    }}
                                >
                                    <ArrowDownward />
                                </IconButton>
                            </Tooltip>
                        </Container>
                    </Container>
                </Container>

                {/* navigation bottom */}
            </Container>
            {/* Section 3 */}
            <Container
                style={{
                    flex: 3,
                    padding: theme.spacing(1),
                    paddingBottom: 0,
                    paddingRight: 0,
                    alignItems: 'center',
                    overflowX: 'auto',
                }}
                flexWrap="wrap"
            >
                {attachmentList.map((attachment) => {
                    return (
                        <div key={`ATTACHMENT_${attachment.id}`} style={{}}>
                            <Attachment
                                attachment={attachment.file}
                                uploaded={attachment.uploaded}
                                theme={theme}
                            />
                        </div>
                    )
                })}
            </Container>

            <TransitionLogPopver 
                open={transitionLogs !== null && anchorEl !== null}
                anchorEl={anchorEl}
                transitionLogs={transitionLogs}
                closePopover={() => {setTransitionLogs(null)
                    setAnchorEl(null)
                }}
            />
        </Container>
    )
}

interface InventoryProps {
    inventoryInspection: InventoryInspection
    name: string
}

export const InventoryInspectionBox = (props: InventoryProps) => {
    const theme = useTheme()

    return (
        <Container
            style={{
                border: `2px solid ${props.inventoryInspection.status?.color}`,
                backgroundColor: 'white',
                borderRadius: theme.shape.borderRadius,
                boxShadow: `2px 2px 6px #ffffff`,
                padding: theme.spacing(1),
                margin: theme.spacing(1),
                flexDirection: 'row',
                height: 70,
                minWidth: 200,
                maxWidth: 200,
                cursor: 'pointer',
            }}
        >
            <Container style={{ flexDirection: 'column' }}>
                <span style={{ ...theme.typography.body1, fontWeight: 'bold' }}>
                    {props.name}
                </span>
                <span style={{ ...theme.typography.body2 }}>
                    {props.inventoryInspection.status?.name}
                </span>
            </Container>
            <div style={{ flex: 1 }} />
        </Container>
    )
}

export interface PaginationControllerProps {
    page: number
    numPages: number
    listLength: number
    setPage: (page: number) => void
}

export const PaginationController = (props: PaginationControllerProps) => {
    const { page, setPage, numPages, listLength } = props

    return (
        <Container style={{ marginTop: 8 }}>
            <Pagination
                page={page + 1}
                onChange={(_, newPage) => {
                    setPage(newPage - 1)
                }}
                count={numPages}
            />

            <span
                style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#C4C4C4',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '13px',
                    lineHeight: '15px',
                }}
            >
                {listLength} Rows
            </span>
        </Container>
    )
}
