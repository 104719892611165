import React, { useState } from 'react'
import {
    Button,
    Divider,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { Container } from '../../components'
import { useAppDispatch } from '../../hooks'
import { createInventoryConfig } from '../../store'
import { toast } from 'react-toastify'
import InfoIcon from '@material-ui/icons/Info'

interface Props {
    onClose: () => void
}

export const CreateInventoryConfigForm = (props: Props) => {
    const theme = useTheme()

    const [name, setName] = useState('')
    const [sku, setSku] = useState('')
    const [description, setDescription] = useState('')
    const [validate, setValidate] = useState(false)

    const dispatch = useAppDispatch()

    return (
        <Container style={{ height: 500, width: 500, flexDirection: 'column' }}>
            {/* Title area */}
            <Container
                style={{ padding: theme.spacing(1), alignItems: 'center' }}
            >
                <span
                    style={{
                        ...theme.typography.h5,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Create Inventory Config
                </span>
                <Tooltip
                    title="All new Inspection Items must have a Name, Status, and
                be assigned to an Area"
                >
                    <InfoIcon fontSize="small" color="action" />
                </Tooltip>
            </Container>

            <Divider />

            <Container
                style={{ flexDirection: 'column', padding: theme.spacing(1) }}
            >
                <TextField
                    inputProps={{ maxLength: 30 }}
                    variant="outlined"
                    label="Name (Required)"
                    error={validate && name === ''}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    style={{ marginBottom: theme.spacing(1) }}
                />

                <TextField
                    inputProps={{ maxLength: 50 }}
                    variant="outlined"
                    label={'SKU'}
                    value={sku}
                    onChange={(e) => {
                        setSku(e.target.value)
                    }}
                    style={{ marginBottom: theme.spacing(1) }}
                />

                <TextField
                    variant="outlined"
                    label={'Description'}
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value)
                    }}
                    style={{ marginBottom: theme.spacing(1) }}
                />
            </Container>
            <div style={{ flex: 1 }} />
            <Divider />
            <Container style={{ padding: theme.spacing(1) }}>
                <div style={{ flex: 1 }} />
                <Button
                    variant="outlined"
                    color="secondary"
                    style={{ marginRight: theme.spacing(1) }}
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        if (name === '') {
                            setValidate(true)
                            return
                        }
                        const invConfigFormData = new FormData()
                        invConfigFormData.append('name', name)
                        invConfigFormData.append('description', description)
                        invConfigFormData.append('sku', sku)

                        dispatch(createInventoryConfig(invConfigFormData)).then(
                            () => {
                                toast.success(`${name} Created.`)
                                setName('')
                                setSku('')
                                setDescription('')
                                props.onClose()
                            },
                        )
                    }}
                >
                    Create
                </Button>
            </Container>
        </Container>
    )
}
