import React, { useState } from 'react'

import { Container, Selector } from '../../components'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    TextField,
    Theme,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { AreaConfig, UnitConfig } from '../../models'
import {
    UpdateOccMappingRequest,
    useUnitConfigOccMapping,
} from '../../hooks/useUnitConfigOccMapping'
import { toast } from 'react-toastify'

interface MappingState {
    occAreaConfig: number
    occAreaConfigLabel: string
    nonOccAreas: {
        nonOccAreaConfig: number
        nonOccAreaConfigLabel: string
    }[]
}

interface Props {
    unitConfigList: UnitConfig[]
    areaConfigList: AreaConfig[]
}

export const UnitAreaConfigMapForm = (props: Props) => {
    const { unitConfigList, areaConfigList } = props

    const [selectedUnitConfigId, setSelectedUnitConfigId] = useState(-1)
    const [mappingStateList, setMappingStateList] = useState<MappingState[]>([])

    const [email, setEmail] = useState('')

    const [doValidate, setDoValidate] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const { updateUnitConfigOccMapping } = useUnitConfigOccMapping()

    const theme = useTheme()

    const onChangeUnitConfig = (id: number) => {
        setSelectedUnitConfigId(id)
        setMappingStateList([])
        setDoValidate(false)
    }

    const occAreaConfigList = areaConfigList.filter(
        (areaConfig) => areaConfig.occupiable,
    )
    const nonOccAreaConfigList = areaConfigList.filter(
        (areaConfig) => !areaConfig.occupiable,
    )

    const checkValidation = () => {
        let valid = true
        let hasOccAreaCopy = false
        let hasNonOccAreaCopy = false
        let hasEmptyConfig = false

        if (selectedUnitConfigId === -1) {
            valid = false
            hasEmptyConfig = true
        }

        const occAreaDict: UsedAreaConfigMap = {}

        mappingStateList.forEach((mappingState) => {
            const nonOccAreaDict: UsedAreaConfigMap = {}

            if (mappingState.occAreaConfig === -1) {
                hasEmptyConfig = true
            } else if (mappingState.occAreaConfigLabel === '') {
                hasEmptyConfig = true
            }

            if (occAreaDict[mappingState.occAreaConfig]) {
                if (
                    occAreaDict[mappingState.occAreaConfig][
                        mappingState.occAreaConfigLabel
                    ]
                ) {
                    hasOccAreaCopy = true
                } else {
                    occAreaDict[mappingState.occAreaConfig][
                        mappingState.occAreaConfigLabel
                    ] = true
                }
            } else {
                occAreaDict[mappingState.occAreaConfig] = {}
                occAreaDict[mappingState.occAreaConfig][
                    mappingState.occAreaConfigLabel
                ] = true
            }

            mappingState.nonOccAreas.forEach((nonOcc) => {
                if (nonOcc.nonOccAreaConfig === -1) {
                    hasEmptyConfig = true
                } else if (nonOcc.nonOccAreaConfigLabel === '') {
                    hasEmptyConfig = true
                }

                if (nonOccAreaDict[nonOcc.nonOccAreaConfig]) {
                    if (
                        nonOccAreaDict[nonOcc.nonOccAreaConfig][
                            nonOcc.nonOccAreaConfigLabel
                        ]
                    ) {
                        hasNonOccAreaCopy = true
                    } else {
                        nonOccAreaDict[nonOcc.nonOccAreaConfig][
                            nonOcc.nonOccAreaConfigLabel
                        ] = true
                    }
                } else {
                    nonOccAreaDict[nonOcc.nonOccAreaConfig] = {}
                    nonOccAreaDict[nonOcc.nonOccAreaConfig][
                        nonOcc.nonOccAreaConfigLabel
                    ] = true
                }
            })
        })

        valid = !hasEmptyConfig && !hasOccAreaCopy && !hasNonOccAreaCopy

        if (valid) {
            setDoValidate(false)
        } else {
            if (!hasEmptyConfig) {
                if (hasOccAreaCopy) {
                    toast.error(
                        'One or more of the entered Occupiable Areas are the same. Make sure that the Occ Area and Occ Area Label are unique',
                    )
                } else if (hasNonOccAreaCopy) {
                    toast.error(
                        'One or more of the entered Non Occ Areas are the same for an Occ Area. Make sure that the Non Occ Area and Non Occ Area Label are unique for a given Occ Area',
                    )
                }
            }

            setDoValidate(true)
        }

        return valid
    }

    return (
        <Container
            style={{
                maxHeight: 'calc(100vh - 300px)',
                minHeight: 'calc(100vh - 100px)',
                maxWidth: 'calc(100vw - 100px)',
                minWidth: 'calc(100vw - 300px)',
                flexDirection: 'column',
                overflow: 'auto',
            }}
        >
            {/* Title area */}
            <Container style={{ padding: theme.spacing(1) }}>
                <span
                    style={{
                        ...theme.typography.h5,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Unit Config Mapping
                </span>
            </Container>
            <Divider />

            <Container
                style={{
                    padding: theme.spacing(1, 2, 1, 2),
                }}
            >
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    1. Select your unit type. 2. Add the occupiable and non
                    occupiable mapping for the chosen unit type. (This will be
                    how the unit is set up, ensure every relationship is here
                    before submitting. If a relationship between areas already
                    exists and is not included in this mapping then that
                    relationship will be deleted.) 3. Add an email to know when
                    the mapping is complete.
                </span>
            </Container>

            <Container
                style={{
                    alignItems: 'center',
                    margin: theme.spacing(2),
                }}
            >
                <Selector
                    label="Unit Config"
                    data={unitConfigList}
                    searchable
                    currentValue={selectedUnitConfigId}
                    onChange={(e) => {
                        onChangeUnitConfig(e.target.value as number)
                    }}
                    getDisplayString={(service) => service.name}
                    error={doValidate && selectedUnitConfigId === -1}
                    customStyle={{
                        formControl: {
                            marginRight: theme.spacing(2),
                            width: '100%',
                        },
                    }}
                />
                <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    variant="outlined"
                />
            </Container>
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    width: '100%',
                    padding: theme.spacing(0, 2, 2, 2),
                    overflowY: 'scroll',
                }}
            >
                {mappingStateList.map((mappingState, index) => {
                    return (
                        <MappingContainer
                            key={`MAPPING_STATE_${index}`}
                            mappingState={mappingState}
                            mappingStateList={mappingStateList}
                            occAreaConfigList={occAreaConfigList}
                            nonOccAreaConfigList={nonOccAreaConfigList}
                            theme={theme}
                            index={index}
                            doValidate={doValidate}
                            setMappingStateList={setMappingStateList}
                        />
                    )
                })}

                <Button
                    variant="contained"
                    style={{
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                        width: '200px',
                    }}
                    onClick={() => {
                        setMappingStateList([
                            ...mappingStateList,
                            {
                                occAreaConfig: -1,
                                occAreaConfigLabel: '',
                                nonOccAreas: [],
                            },
                        ])
                    }}
                >
                    + Add Mapping
                </Button>
            </Container>
            <Container
                style={{
                    alignSelf: 'flex-end',
                }}
            >
                <Button
                    variant="contained"
                    style={{
                        margin: theme.spacing(2, 2, 3, 0),
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                        width: '200px',
                    }}
                    onClick={() => {
                        if (checkValidation()) {
                            setOpenDialog(true)
                        }
                    }}
                >
                    Save
                </Button>
            </Container>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Save Config Mapping</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to save the selected mapping for
                        the occupiable and non occupiable area types for the
                        selected unit type. (This will be how the unit is set
                        up, ensure every relationship is here before submitting.
                        If a relationship between areas already exists and is
                        not included in this mapping then that relationship will
                        be deleted.)
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{
                            marginRight: theme.spacing(2),
                            textTransform: 'none',
                        }}
                        onClick={() => setOpenDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(2, 2, 3, 0),
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            width: '200px',
                        }}
                        onClick={() => {
                            const request: UpdateOccMappingRequest = {
                                unit_config: selectedUnitConfigId,
                                mappings: [],
                            }

                            mappingStateList.forEach((mappingState) => {
                                mappingState.nonOccAreas.forEach((nonOcc) => {
                                    request.mappings.push({
                                        occ_area_config:
                                            mappingState.occAreaConfig,
                                        occ_area_label:
                                            mappingState.occAreaConfigLabel,
                                        non_occ_area_config:
                                            nonOcc.nonOccAreaConfig,
                                        non_occ_area_label:
                                            nonOcc.nonOccAreaConfigLabel,
                                    })
                                })
                            })

                            if (email !== '') {
                                request.to_email = email
                            }

                            updateUnitConfigOccMapping(request).then(() => {
                                setSelectedUnitConfigId(-1)
                                setMappingStateList([])
                                toast.success(
                                    'Request sent successfully, an email will be sent once the mapping is complete',
                                )
                            })

                            setOpenDialog(false)
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

interface MappingContainerProps {
    mappingState: MappingState
    mappingStateList: MappingState[]
    occAreaConfigList: AreaConfig[]
    nonOccAreaConfigList: AreaConfig[]
    theme: Theme
    index: number
    doValidate: boolean
    setMappingStateList: (mappingStateList: MappingState[]) => void
}

const MappingContainer = (props: MappingContainerProps) => {
    const {
        mappingState,
        mappingStateList,
        occAreaConfigList,
        nonOccAreaConfigList,
        theme,
        index,
        doValidate,
        setMappingStateList,
    } = props

    return (
        <Container
            style={{
                padding: theme.spacing(2, 0, 2, 0),
                borderTop: `1px solid ${theme.palette.grey[400]}`,
                alignItems: 'center',
            }}
        >
            <Container
                style={{
                    flex: 1,
                    alignItems: 'center',
                    alignSelf: 'flex-start',
                    marginRight: theme.spacing(2),
                }}
            >
                <Selector
                    label="Occupiable Area"
                    data={occAreaConfigList}
                    searchable
                    currentValue={mappingState.occAreaConfig}
                    onChange={(e) => {
                        const newSelectedAreaConfig = e.target.value as number
                        const newMappingStateList = [...mappingStateList]
                        newMappingStateList[
                            index
                        ].occAreaConfig = newSelectedAreaConfig
                        setMappingStateList(newMappingStateList)
                    }}
                    getDisplayString={(areaConfig) => `${areaConfig.name}`}
                    customStyle={{
                        formControl: {
                            margin: theme.spacing(1),
                            width: '100%',
                        },
                    }}
                    error={doValidate && mappingState.occAreaConfig === -1}
                />

                <TextField
                    label="Occupiable Area Label"
                    value={mappingState.occAreaConfigLabel}
                    onChange={(e) => {
                        const newMappingStateList = [...mappingStateList]
                        newMappingStateList[index].occAreaConfigLabel =
                            e.target.value
                        setMappingStateList(newMappingStateList)
                    }}
                    fullWidth
                    variant="outlined"
                    error={doValidate && mappingState.occAreaConfigLabel === ''}
                />

                <Tooltip title="Remove Mapping">
                    <IconButton
                        onClick={() => {
                            const newMappingStateList = mappingStateList.filter(
                                (mappingState, idx) => idx !== index,
                            )
                            setMappingStateList(newMappingStateList)
                        }}
                        size="small"
                    >
                        <DeleteForeverIcon color="secondary" />
                    </IconButton>
                </Tooltip>
            </Container>
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                {mappingState.nonOccAreas.map((nonOcc, idx) => {
                    return (
                        <NonOccAreaContainer
                            key={`NON_OCC_AREA_${index}_${idx}`}
                            nonOcc={nonOcc}
                            mappingStateList={mappingStateList}
                            nonOccAreaConfigList={nonOccAreaConfigList}
                            theme={theme}
                            nonOccIndex={idx}
                            parentIndex={index}
                            doValidate={doValidate}
                            setMappingStateList={setMappingStateList}
                        />
                    )
                })}

                <Button
                    variant="contained"
                    style={{
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                        width: '200px',
                        margin: theme.spacing(1, 0, 0, 1),
                    }}
                    onClick={() => {
                        const newMappingStateList = [...mappingStateList]
                        newMappingStateList[index].nonOccAreas.push({
                            nonOccAreaConfig: -1,
                            nonOccAreaConfigLabel: '',
                        })
                        setMappingStateList(newMappingStateList)
                    }}
                >
                    + Add Non Occ Area
                </Button>
            </Container>
        </Container>
    )
}

interface NonOccAreaContainerProps {
    nonOcc: {
        nonOccAreaConfig: number
        nonOccAreaConfigLabel: string
    }
    mappingStateList: MappingState[]
    nonOccAreaConfigList: AreaConfig[]
    theme: Theme
    nonOccIndex: number
    parentIndex: number
    doValidate: boolean
    setMappingStateList: (mappingStateList: MappingState[]) => void
}

const NonOccAreaContainer = (props: NonOccAreaContainerProps) => {
    const {
        nonOcc,
        mappingStateList,
        nonOccAreaConfigList,
        theme,
        nonOccIndex,
        parentIndex,
        doValidate,
        setMappingStateList,
    } = props

    return (
        <Container
            style={{
                alignItems: 'center',
            }}
        >
            <Selector
                label="Non Occupiable Area"
                data={nonOccAreaConfigList}
                searchable
                currentValue={nonOcc.nonOccAreaConfig}
                onChange={(e) => {
                    const newSelectedAreaConfig = e.target.value as number
                    const newMappingStateList = [...mappingStateList]

                    const newNonOccAreas =
                        newMappingStateList[parentIndex].nonOccAreas

                    newNonOccAreas[
                        nonOccIndex
                    ].nonOccAreaConfig = newSelectedAreaConfig

                    setMappingStateList(newMappingStateList)
                }}
                getDisplayString={(areaConfig) => `${areaConfig.name}`}
                customStyle={{
                    formControl: {
                        margin: theme.spacing(1),
                        width: '100%',
                    },
                }}
                error={doValidate && nonOcc.nonOccAreaConfig === -1}
            />

            <TextField
                label="Non Occupiable Area Label"
                value={nonOcc.nonOccAreaConfigLabel}
                onChange={(e) => {
                    const newMappingStateList = [...mappingStateList]

                    newMappingStateList[parentIndex].nonOccAreas[
                        nonOccIndex
                    ].nonOccAreaConfigLabel = e.target.value
                    setMappingStateList(newMappingStateList)
                }}
                fullWidth
                variant="outlined"
                error={doValidate && nonOcc.nonOccAreaConfigLabel === ''}
            />

            <Tooltip title="Remove Non Occ Area">
                <IconButton
                    onClick={() => {
                        const newMappingStateList = [...mappingStateList]

                        const newNonOccAreas = newMappingStateList[
                            parentIndex
                        ].nonOccAreas.filter((id, idx) => idx !== nonOccIndex)
                        newMappingStateList[
                            parentIndex
                        ].nonOccAreas = newNonOccAreas

                        setMappingStateList(newMappingStateList)
                    }}
                    size="small"
                >
                    <DeleteForeverIcon color="secondary" />
                </IconButton>
            </Tooltip>
        </Container>
    )
}

interface UsedAreaConfigMap {
    [areaConfigId: number]: {
        [areaConfigLabel: string]: boolean
    }
}
