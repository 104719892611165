import { Location, LocationSelection } from '../../store'

export const getFinderChips = (
    locationSelection: LocationSelection,
    type: keyof LocationSelection,
    render: (location: Location) => JSX.Element,
) => {
    return Object.keys(locationSelection[type]).reduce<JSX.Element[]>(
        (prev, k) => {
            const typedFilter = locationSelection[type]
            const key = k === 'length' ? k : parseInt(k)

            if (key === 'length' || typedFilter[key] === undefined) return prev

            const loc = typedFilter[key]

            return prev.concat(render({ type: type, location: loc }))
        },
        [],
    )
}
