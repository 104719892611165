import React from 'react'

import { IconButton } from '@material-ui/core'
import { BaseWorkorder, Service } from '../../../models'
import { Container } from '../../../components'
import { usePopover } from '../../../hooks/usePopover'
import { SelectorPopover } from '../../../components/TemplateScheduleGantt/Components/SelectorPopover/SelectorPopover'

import { ServiceTableRow } from './ServiceTableRow'
import { formatTotals } from '../services'
import { BaseProps } from '../../TemplateScheduleGantt/types'
import AddIcon from '@material-ui/icons/Add'
import { getTotalPriceExpense } from '../../../models/WorkOrder/services'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import {
    HeaderValue,
    IconValue,
    TextValue,
    TotalsMoneyValue,
    TotalsTextValue,
} from '../styles'

interface Props extends BaseProps {
    serviceList: Service[]
    workorders: BaseWorkorder[]
    portfolio?: boolean
}

export const ServiceTable = (props: Props) => {
    const {
        theme,
        scheduleController,
        serviceList,
        workorders,
        portfolio,
    } = props

    const schedule = scheduleController.schedule
    const popoverState = usePopover()

    const templateServiceIds =
        schedule?.template_services.map((ts) => ts.service.id) || []

    const filteredServiceList = React.useMemo(() => {
        const workorderServiceIds = new Set(
            workorders.map((wo) => wo.service_id).filter(Boolean),
        )
        return serviceList.filter(
            (service) =>
                workorderServiceIds.has(service.id) &&
                !templateServiceIds.includes(service.id),
        )
    }, [serviceList, workorders, templateServiceIds])

    const forecastedServiceTotals = React.useMemo(() => {
        const totals: { [serviceId: number]: number } = {}
        workorders.forEach((wo) => {
            if (wo.service_id && templateServiceIds.includes(wo.service_id)) {
                totals[wo.service_id] =
                    (totals[wo.service_id] || 0) + getTotalPriceExpense(wo)
            }
        })
        return totals
    }, [workorders, templateServiceIds])

    const invoicedServiceTotals = React.useMemo(() => {
        const totals: { [serviceId: number]: number } = {}
        workorders.forEach((wo) => {
            if (
                wo.service_id &&
                templateServiceIds.includes(wo.service_id) &&
                wo.invoiced_price !== undefined &&
                wo.invoiced_price !== null
            ) {
                totals[wo.service_id] =
                    (totals[wo.service_id] || 0) + wo.invoiced_price
            }
        })
        return totals
    }, [workorders, templateServiceIds])

    const totalForecast = React.useMemo(
        () =>
            Object.values(forecastedServiceTotals).reduce(
                (acc, val) => acc + val,
                0,
            ),
        [forecastedServiceTotals],
    )

    const totalInvoiced = React.useMemo(
        () =>
            Object.values(invoicedServiceTotals).reduce(
                (acc, val) => acc + val,
                0,
            ),
        [invoicedServiceTotals],
    )

    const totalBudget = React.useMemo(
        () =>
            schedule?.template_services.reduce(
                (acc, ts) => acc + Number(ts.budget),
                0,
            ),
        [schedule?.template_services],
    )

    const totalVariance = React.useMemo(
        () => (totalBudget || 0) - totalForecast,
        [totalBudget, totalForecast],
    )

    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Container
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    minHeight: 40,
                }}
            >
                {/* Title */}
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                        display: 'flex',
                    }}
                >
                    Services
                </span>
            </Container>
            <Grid container spacing={0}>
                {/* Header Row */}
                <Grid item xs={0.5}>
                    <HeaderValue>&nbsp;</HeaderValue>
                </Grid>
                <Grid item xs={3.5}>
                    <HeaderValue
                        style={{
                            justifyContent: 'flex-start',
                        }}
                    >
                        Service
                    </HeaderValue>
                </Grid>
                <Grid item xs={2}>
                    <HeaderValue>Budget</HeaderValue>
                </Grid>
                <Grid item xs={2}>
                    <HeaderValue>Forecast</HeaderValue>
                </Grid>
                <Grid item xs={2}>
                    <HeaderValue>Variance</HeaderValue>
                </Grid>
                <Grid item xs={2}>
                    <HeaderValue>Invoiced</HeaderValue>
                </Grid>

                {/* Data Rows */}
                {schedule?.template_services.map((ts) => (
                    <ServiceTableRow
                        {...props}
                        templateService={ts}
                        key={`TEMPLATE-SERVICE-${ts.id}`}
                        forecastedServiceTotal={
                            forecastedServiceTotals[ts.service.id] || 0
                        }
                        invoicedServiceTotal={
                            invoicedServiceTotals[ts.service.id] || 0
                        }
                    ></ServiceTableRow>
                ))}
                {/* Add Service Row */}
                <Grid item xs={0.5}>
                    <IconValue>
                        <IconButton onClick={popoverState.handleOpen}>
                            <AddIcon />
                        </IconButton>
                    </IconValue>
                    <SelectorPopover
                        {...props}
                        popoverState={popoverState}
                        data={filteredServiceList}
                        label="Select Service"
                        onSubmit={(selectedId) => {
                            if (scheduleController.schedule === null) return
                            scheduleController.addTemplateService(
                                schedule?.id ?? -1,
                                selectedId,
                                portfolio,
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={3.5}>
                    <TextValue></TextValue>
                </Grid>
                <Grid item xs={2}>
                    <TextValue></TextValue>
                </Grid>
                <Grid item xs={2}>
                    <TextValue></TextValue>
                </Grid>
                <Grid item xs={2}>
                    <TextValue></TextValue>
                </Grid>
                <Grid item xs={2}>
                    <TextValue></TextValue>
                </Grid>

                {/* Totals Row */}
                <Grid item xs={0.5}>
                    <TotalsTextValue>&nbsp;</TotalsTextValue>
                </Grid>
                <Grid item xs={3.5}>
                    <TotalsTextValue>Totals</TotalsTextValue>
                </Grid>
                <Grid item xs={2}>
                    <TotalsMoneyValue>
                        {formatTotals(totalBudget)}
                    </TotalsMoneyValue>
                </Grid>
                <Grid item xs={2}>
                    <TotalsMoneyValue>
                        {formatTotals(totalForecast)}
                    </TotalsMoneyValue>
                </Grid>
                <Grid item xs={2}>
                    <TotalsMoneyValue>
                        {formatTotals(totalVariance)}
                    </TotalsMoneyValue>
                </Grid>
                <Grid item xs={2}>
                    <TotalsMoneyValue>
                        {formatTotals(totalInvoiced)}
                    </TotalsMoneyValue>
                </Grid>
            </Grid>
        </Box>
    )
}
