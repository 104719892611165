import { AxiosError } from 'axios'

import { AppThunk } from '../../'

import { axiosInstance } from '../../../helpers'
import {
    BaseWorkorder,
    ChangeOrder,
    createWorkorderFromResponse,
    _Workorder,
} from '../../../models'
import { setNetworkError } from '../../error'
import { ChannelDetailActionThunk, setCurrentChannel } from '../../messaging'
import { ErrorResponse } from '../../types'

import {
    CreateWorkorderActionThunk,
    CreateWorkOrderRequest,
    CREATE_WORKORDER_REQUEST,
    CREATE_WORKORDER_RESPONSE,
    DELETE_WORKORDER,
    GetWorkorderListActionThunk,
    _GetWorkOrderListRequest,
    GET_WORKORDER_LIST_REQUEST,
    GET_WORKORDER_LIST_RESPONSE,
    SET_WORKORDER_LIST,
    TransitionWorkorderStatusRequest,
    TRANSITION_WORKORDER_REQUEST,
    UpdateWorkOrderRequest,
    UPDATE_BULK_WORKORDERS,
    UPDATE_WORKORDER,
    UPDATE_WORKORDER_REQUEST,
    WorkorderActionTypes,
    WorkorderDetailActionThunk,
    UPDATE_CHANGE_ORDER_STATUS,
    ADD_NEW_WORKORDER,
    BulkUpdateWorkOrderRequest,
    BULK_DELETE_WORKORDERS,
    BULK_DELETE_WORKORDER_REQUEST,
    GET_OR_CREATE_WORKORDER_CHANNEL,
    SET_WORKORDER_IS_LOADING,
    UPDATE_BULK_WORKORDERS_REQUEST,
    WorkorderRequest,
    SET_PORTFOLIO_WORKORDER_LIST,
    ADD_NEW_CHANGE_ORDER,
} from './types'

export const getOrCreateWorkorderChannel = (
    workorderId: number,
): AppThunk<ChannelDetailActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setWorkorderIsLoading(GET_OR_CREATE_WORKORDER_CHANNEL, true))

        const url = `workorder/${workorderId}/get_wo_channel/`

        try {
            // success
            const response = await axiosInstance.post(url)

            dispatch(setCurrentChannel(response.data))

            dispatch(
                setWorkorderIsLoading(GET_OR_CREATE_WORKORDER_CHANNEL, false),
            )

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(
                setWorkorderIsLoading(GET_OR_CREATE_WORKORDER_CHANNEL, false),
            )

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getWorkOrderList = (
    req: _GetWorkOrderListRequest,
): AppThunk<GetWorkorderListActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: GET_WORKORDER_LIST_REQUEST,
        })

        const url = `workorder/`

        try {
            // success
            const response = await axiosInstance.get(url, {
                params: req.params,
            })
            if (req.isForPortfolioSchedule) {
                dispatch(
                    setWorkorderIsLoading(GET_WORKORDER_LIST_REQUEST, false),
                )
                dispatch(
                    setPortfolioWorkOrderList(
                        response.data.map(createWorkorderFromResponse),
                    ),
                )
            } else {
                dispatch({
                    type: GET_WORKORDER_LIST_RESPONSE,
                    payload: response.data.map(createWorkorderFromResponse),
                })
            }

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createWorkorder = (
    req: CreateWorkOrderRequest,
): AppThunk<CreateWorkorderActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: CREATE_WORKORDER_REQUEST,
        })

        const url = `workorder/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch({
                type: CREATE_WORKORDER_RESPONSE,
                payload: response.data,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const updateWorkorder = (
    req: UpdateWorkOrderRequest,
): AppThunk<WorkorderDetailActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: UPDATE_WORKORDER_REQUEST,
        })

        const url = `workorder/${req.workorderId}/`

        try {
            // success
            const response = await axiosInstance.patch(url, req.body)
            dispatch({
                type: UPDATE_WORKORDER,
                workorder: createWorkorderFromResponse(response.data),
                endLoading: UPDATE_WORKORDER_REQUEST,
                workorderId: req.workorderId,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const transitionWorkorderStatus = (
    req: TransitionWorkorderStatusRequest,
): AppThunk<WorkorderDetailActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch({
            type: TRANSITION_WORKORDER_REQUEST,
        })

        const url = `workorder/${req.workorderId}/transition/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)
            dispatch({
                type: UPDATE_WORKORDER,
                workorder: createWorkorderFromResponse(response.data),
                endLoading: TRANSITION_WORKORDER_REQUEST,
                workorderId: req.workorderId,
            })

            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            // Return a promise thats executor always calls reject.  This is done because
            // the above try block failed.  The caller wants to be able to use then / catch and returning
            // e or error would not allow the caller to do so.
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const bulkUpdateWorkorders = (
    req: BulkUpdateWorkOrderRequest,
): AppThunk<GetWorkorderListActionThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setWorkorderIsLoading(UPDATE_BULK_WORKORDERS_REQUEST, true))

        const url = `workorder/bulk_update/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)
            dispatch(
                updateBulkWorkorders(
                    response.data.map(createWorkorderFromResponse),
                ),
            )
            dispatch(
                setWorkorderIsLoading(UPDATE_BULK_WORKORDERS_REQUEST, false),
            )
            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(
                setWorkorderIsLoading(UPDATE_BULK_WORKORDERS_REQUEST, false),
            )

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const bulkDeleteWorkorderRequest = (
    req: BulkUpdateWorkOrderRequest,
): AppThunk<any> => {
    return async (dispatch) => {
        // begin request
        dispatch(setWorkorderIsLoading(BULK_DELETE_WORKORDER_REQUEST, true))

        const url = `workorder/bulk_delete/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch(bulkDeleteWorkorders(response.data))
            dispatch(
                setWorkorderIsLoading(BULK_DELETE_WORKORDER_REQUEST, false),
            )
            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))
            dispatch(
                setWorkorderIsLoading(BULK_DELETE_WORKORDER_REQUEST, false),
            )

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const setWorkOrderList = (
    workorderList: BaseWorkorder[],
): WorkorderActionTypes => {
    return {
        type: SET_WORKORDER_LIST,
        workorderList: workorderList,
    }
}

export const setPortfolioWorkOrderList = (
    workorderList: BaseWorkorder[],
): WorkorderActionTypes => {
    return {
        type: SET_PORTFOLIO_WORKORDER_LIST,
        workorderList: workorderList,
    }
}

export const updateBulkWorkorders = (
    workorders: _Workorder[],
): WorkorderActionTypes => {
    return {
        type: UPDATE_BULK_WORKORDERS,
        workorders: workorders,
    }
}

export const deleteWorkorder = (workorderId: number): WorkorderActionTypes => {
    return {
        type: DELETE_WORKORDER,
        workorderId: workorderId,
    }
}

export const setWorkorderIsLoading = (
    request: WorkorderRequest,
    isLoading: boolean,
): WorkorderActionTypes => {
    return {
        type: SET_WORKORDER_IS_LOADING,
        request: request,
        isLoading: isLoading,
    }
}

export const bulkDeleteWorkorders = (
    workorderIds: number[],
): WorkorderActionTypes => {
    return {
        type: BULK_DELETE_WORKORDERS,
        workorderIds: workorderIds,
    }
}

export const updateChangeOrderStatus = (
    updatedWorkorder: BaseWorkorder,
    newWorkorder?: BaseWorkorder,
): WorkorderActionTypes => {
    return {
        type: UPDATE_CHANGE_ORDER_STATUS,
        updatedWorkorder: updatedWorkorder,
        newWorkOrder: newWorkorder,
    }
}

export const updateStateWorkorder = (
    workorder: BaseWorkorder,
): WorkorderActionTypes => {
    return {
        type: UPDATE_WORKORDER,
        workorder: workorder,
        endLoading: UPDATE_WORKORDER_REQUEST,
        workorderId: workorder.id,
    }
}

export const addWorkorderToState = (
    workorder: BaseWorkorder,
): WorkorderActionTypes => {
    return {
        type: ADD_NEW_WORKORDER,
        workorder: workorder,
    }
}

export const addChangeOrderToWorkorder = (
    workorder: BaseWorkorder,
    changeOrder: ChangeOrder,
): WorkorderActionTypes => {
    return {
        type: ADD_NEW_CHANGE_ORDER,
        workorder: workorder,
        changeOrder: changeOrder,
    }
}
