import React, { useState } from 'react'
import { Container, NumberInput, Selector } from '../../components'
import {
    Button,
    Chip,
    Divider,
    Input,
    MenuItem,
    Select,
    TextField,
    useTheme,
} from '@material-ui/core'
import { useMultiSelectStyles, useStyles } from '../../styles'
import { ReactComponent as CheckCircle } from '../../assets/Check.svg'
import { ReactComponent as EmptyCircle } from '../../assets/Empty_Circle.svg'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { IdBoolMap, RFP, RFPType, Service } from '../../models'
import { SCOPE_OF_WORK_STEP } from './CreateAndEditRFP'
import { CreateOrUpdateRFPRequest, rfpApi } from '../../contexts'

interface Props {
    name: string
    type: RFPType
    startDate: Date | null
    endDate: Date | null
    projectDeadline: Date | null
    selectedServicesMap: IdBoolMap
    serviceList: Service[]
    budget?: number
    viewMode?: boolean
    setActiveStep: (step: number) => void
    setName: (name: string) => void
    setType: (type: RFPType) => void
    setStartDate: (date: Date | null) => void
    setEndDate: (date: Date | null) => void
    setProjectDeadline: (date: Date | null) => void
    setSelectedServicesMap: (map: IdBoolMap) => void
    setBudget: (budget?: number) => void
    createRFPBody: () => CreateOrUpdateRFPRequest
}

export const GetStartedForm = (props: Props) => {
    const {
        setActiveStep,
        name,
        type,
        startDate,
        projectDeadline,
        selectedServicesMap,
        budget,
        serviceList,
        endDate,
        viewMode,
        setName,
        setBudget,
        setType,
        setProjectDeadline,
        setSelectedServicesMap,
        setStartDate,
        setEndDate,
        createRFPBody,
    } = props

    const [doValidate, setDoValidate] = useState(false)

    const { selectedRFP, createRFP, updateRFP } = rfpApi()

    const theme = useTheme()
    const classes = useStyles()
    const selectClasses = useMultiSelectStyles()

    const selectedServicesLength = Object.keys(selectedServicesMap).length

    const checkValidation = () => {
        if (name === '') {
            setDoValidate(true)
            return false
        }
        if (!startDate || !endDate || !projectDeadline) {
            setDoValidate(true)
            return false
        }
        if (selectedServicesLength === 0) {
            setDoValidate(true)
            return false
        }
        return true
    }

    return (
        <Container
            direction="column"
            style={{
                padding: theme.spacing(0, 2, 2, 2),
                width: 'calc(100vw - 550px)',
            }}
        >
            <Container
                direction="column"
                style={{ overflow: 'auto', height: 'calc(100vh - 280px)' }}
            >
                {/* Type */}
                <Container justifyContent="center">
                    <Container
                        direction="column"
                        style={{
                            width: '300px',
                            height: '260px',
                            border: `1px solid ${theme.palette.lightGrey.light}`,
                            borderRadius: '5px',
                            padding: theme.spacing(3),
                            marginRight: theme.spacing(3),
                            borderColor:
                                type === RFPType.INVITE_ONLY
                                    ? theme.palette.primary.dark
                                    : theme.palette.lightGrey.light,
                            cursor: 'pointer',
                        }}
                        className={classes.hoverGrey200}
                        onClick={() => setType(RFPType.INVITE_ONLY)}
                    >
                        <Container
                            style={{ alignSelf: 'center', fontSize: '24px' }}
                        >
                            Invite Only
                            {type === RFPType.INVITE_ONLY ? (
                                <CheckCircle
                                    style={{ marginLeft: theme.spacing(2) }}
                                />
                            ) : (
                                <EmptyCircle
                                    style={{ marginLeft: theme.spacing(2) }}
                                />
                            )}
                        </Container>
                        <Divider
                            style={{ margin: theme.spacing(2, 0, 2, 0) }}
                        />
                        <Container
                            style={{
                                flexWrap: 'wrap',
                                fontSize: '16px',
                                marginBottom: theme.spacing(2),
                                textAlign: 'center',
                            }}
                        >
                            Select specific vendors you think would be great for
                            this job!
                        </Container>
                        <Container
                            style={{
                                flexWrap: 'wrap',
                                fontSize: '16px',
                                textAlign: 'center',
                            }}
                        >
                            Only vendors you invite can provide a bid.
                        </Container>
                    </Container>
                    <Container
                        direction="column"
                        style={{
                            width: '300px',
                            height: '260px',
                            border: `1px solid ${theme.palette.lightGrey.light}`,
                            borderRadius: '5px',
                            padding: theme.spacing(3),
                            borderColor:
                                type === RFPType.PUBLIC
                                    ? theme.palette.primary.dark
                                    : theme.palette.lightGrey.light,
                            cursor: 'pointer',
                        }}
                        className={classes.hoverGrey200}
                        onClick={() => setType(RFPType.PUBLIC)}
                    >
                        <Container
                            style={{ alignSelf: 'center', fontSize: '24px' }}
                        >
                            Public
                            {type === RFPType.PUBLIC ? (
                                <CheckCircle
                                    style={{ marginLeft: theme.spacing(2) }}
                                />
                            ) : (
                                <EmptyCircle
                                    style={{ marginLeft: theme.spacing(2) }}
                                />
                            )}
                        </Container>
                        <Divider
                            style={{ margin: theme.spacing(2, 0, 2, 0) }}
                        />
                        <Container
                            style={{
                                flexWrap: 'wrap',
                                fontSize: '16px',
                                marginBottom: theme.spacing(2),
                                textAlign: 'center',
                            }}
                        >
                            Make your RFP public so that all vendors that meet
                            your requirements can submit a bid.
                        </Container>
                        <Container
                            style={{
                                flexWrap: 'wrap',
                                fontSize: '16px',
                                textAlign: 'center',
                            }}
                        >
                            You can also invite specific vendors to submit a
                            bid.
                        </Container>
                    </Container>
                </Container>
                {/* Name */}
                <Container
                    style={{
                        margin: theme.spacing(3, 0, 2, 0),
                        justifyContent: 'space-between',
                    }}
                    direction="column"
                >
                    <Container
                        style={{
                            ...theme.typography.subtitle1,
                            color: theme.palette.grey[700],
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        <span>RFP Name</span>
                        <span
                            style={{
                                color: 'red',
                                marginLeft: theme.spacing(0.5),
                            }}
                        >
                            *
                        </span>
                    </Container>
                    <TextField
                        variant="outlined"
                        size="small"
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                        style={{ width: '100%' }}
                        error={doValidate && name === ''}
                        helperText={doValidate && name === '' ? 'Required' : ''}
                    />
                </Container>
                {/* Services */}
                <Container direction="column">
                    <Container
                        style={{
                            ...theme.typography.subtitle1,
                            color: theme.palette.grey[700],
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        <span>Services Needed</span>
                        <span
                            style={{
                                color: 'red',
                                marginLeft: theme.spacing(0.5),
                            }}
                        >
                            *
                        </span>
                    </Container>
                    <Selector
                        data={serviceList}
                        size="small"
                        customStyle={{
                            formControl: {
                                width: '100%',
                                marginBottom: theme.spacing(2),
                            },
                        }}
                        label=""
                        maxItems={10}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            const selectedServices = event.target
                                .value as number[]

                            const newSelectedServices = selectedServices.reduce<IdBoolMap>(
                                (prev, srvId) => {
                                    return {
                                        ...prev,
                                        [srvId]: true,
                                    }
                                },
                                {},
                            )
                            setSelectedServicesMap(newSelectedServices)
                        }}
                        currentValue={Object.keys(selectedServicesMap).map(
                            Number,
                        )}
                        getDisplayString={(v) => v.name}
                        searchable
                        multiple
                    />
                    {/* <Select
                        labelId="service-chip-label"
                        id="mutiple-chip-service"
                        style={{
                            width: '100%',
                            marginBottom: theme.spacing(2),
                        }}
                        multiple
                        value={Object.keys(selectedServicesMap).map(Number)}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            const selectedServices = event.target
                                .value as number[]

                            const newSelectedServices = selectedServices.reduce<IdBoolMap>(
                                (prev, srvId) => {
                                    return {
                                        ...prev,
                                        [srvId]: true,
                                    }
                                },
                                {},
                            )
                            setSelectedServicesMap(newSelectedServices)
                        }}
                        input={<Input id="select-multiple-chip-service" />}
                        renderValue={(selected) => (
                            <div className={selectClasses.chips}>
                                {(selected as number[]).map((value) => {
                                    const srv = serviceList.find(
                                        (s) => s.id === value,
                                    )
                                    return (
                                        <Chip
                                            key={`SERVICE_CHIP_${
                                                srv ? srv.id : -1
                                            }`}
                                            label={srv ? srv.name : 'unknown'}
                                            className={selectClasses.chip}
                                        />
                                    )
                                })}
                            </div>
                        )}
                        error={doValidate && selectedServicesLength === 0}
                    >
                        {serviceList.map((srv) => {
                            return (
                                <MenuItem
                                    key={`SERVICE_MENU_ITEM_${srv.id}`}
                                    value={srv.id}
                                >
                                    {srv.name}
                                </MenuItem>
                            )
                        })}
                    </Select> */}
                </Container>
                {/* Dates */}
                <Container direction="column">
                    <Container
                        style={{
                            ...theme.typography.subtitle1,
                            color: theme.palette.grey[700],
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        <span>Proposal Submission Deadline</span>
                        <span
                            style={{
                                color: 'red',
                                marginLeft: theme.spacing(0.5),
                            }}
                        >
                            *
                        </span>
                    </Container>
                    <KeyboardDatePicker
                        value={projectDeadline}
                        onChange={(date) => setProjectDeadline(date)}
                        format="MM/dd/yyyy"
                        inputVariant="outlined"
                        style={{
                            marginBottom: theme.spacing(2),
                            width: '100%',
                        }}
                        size="small"
                        error={doValidate && !projectDeadline}
                        helperText={
                            doValidate && !projectDeadline && 'Required'
                        }
                    />
                    <Container
                        style={{
                            ...theme.typography.subtitle1,
                            color: theme.palette.grey[700],
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        <span>Project Start Date</span>
                        <span
                            style={{
                                color: 'red',
                                marginLeft: theme.spacing(0.5),
                            }}
                        >
                            *
                        </span>
                    </Container>
                    <KeyboardDatePicker
                        value={startDate}
                        onChange={(date) => setStartDate(date)}
                        format="MM/dd/yyyy"
                        inputVariant="outlined"
                        style={{
                            marginBottom: theme.spacing(2),
                            width: '100%',
                        }}
                        size="small"
                        error={doValidate && !startDate}
                        helperText={doValidate && !startDate && 'Required'}
                    />
                    <Container
                        style={{
                            ...theme.typography.subtitle1,
                            color: theme.palette.grey[700],
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        <span>Project End Date</span>
                        <span
                            style={{
                                color: 'red',
                                marginLeft: theme.spacing(0.5),
                            }}
                        >
                            *
                        </span>
                    </Container>
                    <KeyboardDatePicker
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        format="MM/dd/yyyy"
                        inputVariant="outlined"
                        style={{
                            marginBottom: theme.spacing(2),
                            width: '100%',
                        }}
                        size="small"
                        error={doValidate && !endDate}
                        helperText={doValidate && !endDate && 'Required'}
                    />
                </Container>
                {/* Budget */}
                <Container direction="column">
                    <span
                        style={{
                            ...theme.typography.subtitle1,
                            color: theme.palette.grey[700],
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        What is your budget? (Enter Dollar Amount)
                    </span>
                    <NumberInput
                        prefix={'$'}
                        variant="outlined"
                        value={budget}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setBudget(undefined)
                            } else {
                                setBudget(Number(e.target.value))
                            }
                        }}
                        size="small"
                    />
                </Container>
            </Container>
            <Container flex={1} />
            <Container style={{ marginTop: theme.spacing(2) }}>
                <Container flex={1} />
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                        alignSelf: 'flex-end',
                    }}
                    onClick={() => {
                        if (!viewMode) {
                            const body = createRFPBody()
                            if (checkValidation()) {
                                if (selectedRFP) {
                                    updateRFP(body)
                                } else {
                                    createRFP(body)
                                }
                            } else {
                                return
                            }
                        }
                        setActiveStep(SCOPE_OF_WORK_STEP)
                    }}
                >
                    Next: Scope of Work
                </Button>
            </Container>
        </Container>
    )
}
