import {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useState,
} from 'react'
import { axiosInstance } from '../helpers'
import {
    DwollaBankAccountType,
    DwollaBeneficialOwner,
    DwollaCustomer,
    DwollaFundingSource,
    DwollaTransfer,
    DwollaTransferStatus,
    IdentifiableObject,
    Invoice,
} from '../models'
import { toast } from 'react-toastify'

export interface IDwollaContext {
    balance: any | null
    setBalance: Dispatch<SetStateAction<any | null>>
    dwollaCustomer: DwollaCustomer | null
    setDwollaCustomer: Dispatch<SetStateAction<DwollaCustomer | null>>
    fundingSources: DwollaFundingSource[] | null
    setFundingSources: Dispatch<SetStateAction<DwollaFundingSource[] | null>>
    beneficialOwners: DwollaBeneficialOwner[] | null
    setBeneficialOwners: Dispatch<
        SetStateAction<DwollaBeneficialOwner[] | null>
    >
    invoiceList: Invoice[] | null
    setInvoiceList: Dispatch<SetStateAction<Invoice[] | null>>
    clearState: () => void
}

export const DwollaContext = createContext<IDwollaContext>(
    (null as unknown) as IDwollaContext,
)

export const dwollaApi = () => {
    const state = useContext(DwollaContext)

    // ********* CUSTOMER *********

    const createBusinessVerifiedCustomer = async (
        body: CreateBusinessVerifiedCustomerBody,
    ) => {
        axiosInstance
            .post('ezpay/customer/', body)
            .then((res) => {
                state.setDwollaCustomer(res.data)
            })
            .catch((err) => {
                toast.error(err.response.data.message)
            })
    }

    const getBalance = async () => {
        const res = await axiosInstance.get('ezpay/customer/balance/')
        state.setBalance(res.data)
    }

    const getDwollaCustomer = async () => {
        await axiosInstance
            .get('ezpay/customer/get_customer/')
            .then((customerRes) => {
                const customer = customerRes.data
                state.setDwollaCustomer(customer)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const certifyCustomer = async () => {
        try {
            const res = await axiosInstance.post('ezpay/customer/certify/')
            state.setDwollaCustomer(res.data)
            return Promise.resolve()
        } catch (e) {
            toast.error(`Certification Error ${e}`)
            return Promise.reject(e)
        }
    }

    const updateCustomer = async (
        customer: DwollaCustomer,
        body: { name: string },
    ) => {
        try {
            const res = await axiosInstance.patch(
                `ezpay/customer/${customer.id}/`,
                body,
            )
            state.setDwollaCustomer(res.data)
            return Promise.resolve()
        } catch (e) {
            toast.error(`Certification Error ${e}`)
            return Promise.reject(e)
        }
    }

    // ********* FUNDING SOURCE *********
    const getFundingSources = async () => {
        const res = await axiosInstance.get('ezpay/funding-source/')
        state.setFundingSources(res.data)
    }

    const createFundingSource = async (body: CreateFundingSourceBody) => {
        try {
            const res = await axiosInstance.post('ezpay/funding-source/', body)

            _insertOrUpdateIdentifiable(res.data, state.setFundingSources)

            return Promise.resolve()
        } catch (e) {
            toast.error(`Error creating funding source ${e}`)
            return Promise.reject(e)
        }
    }

    const initiateMicroDeposits = async (
        fundingSource: DwollaFundingSource,
    ) => {
        if (fundingSource.micro_deposit_initiated) {
            toast.error('Micro-Deposits already initiated')
            return Promise.reject()
        }

        try {
            const res = await axiosInstance.post(
                `ezpay/funding-source/${fundingSource.id}/micro_deposit_initiate/`,
            )
            _insertOrUpdateIdentifiable(res.data, state.setFundingSources)

            return Promise.resolve()
        } catch (e) {
            toast.error(`Error initiating micro deposits ${e}`)
            return Promise.reject(e)
        }
    }

    const setDefaultFundingSource = async (
        fundingSource: DwollaFundingSource,
        dwollaCustomer: DwollaCustomer | null,
    ) => {
        try {
            const res = await axiosInstance.post(
                `ezpay/customer/${dwollaCustomer?.id}/set_default_funding_source/`,
                { funding_source: fundingSource.id },
            )

            console.log(res.data)
            state.setDwollaCustomer(res.data)

            return Promise.resolve()
        } catch (e) {
            toast.error(`Error setting default funding source ${e}`)
            return Promise.reject(e)
        }
    }

    const verifyFundingSource = async (
        fundingSource: DwollaFundingSource,
        amount1: string,
        amount2: string,
    ) => {
        try {
            const res = await axiosInstance.post(
                `ezpay/funding-source/${fundingSource.id}/micro_deposit_verify/`,
                {
                    amount1: amount1,
                    amount2: amount2,
                },
            )

            _insertOrUpdateIdentifiable(res.data, state.setFundingSources)
            return Promise.resolve()
        } catch (e) {
            toast.error(`Error validating your funding source ${e}`)
            return Promise.reject(e)
        }
    }

    const deleteFundingSource = async (fundingSource: DwollaFundingSource) => {
        try {
            const res = await axiosInstance.delete(
                `ezpay/funding-source/${fundingSource.id}/`,
            )

            _removeIdentifiable(fundingSource, state.setFundingSources)
            return Promise.resolve()
        } catch (e) {
            toast.error(`Error deleting your funding source ${e}`)
            return Promise.reject(e)
        }
    }

    const editFundingSource = async (
        fundingSource: DwollaFundingSource,
        body: { name: string },
    ) => {
        try {
            const res = await axiosInstance.patch(
                `ezpay/funding-source/${fundingSource.id}/`,
                body,
            )

            _insertOrUpdateIdentifiable(res.data, state.setFundingSources)
            return Promise.resolve()
        } catch (e) {
            toast.error(`Error editing your funding source ${e}`)
            return Promise.reject(e)
        }
    }

    // ********* BENEFICIAL OWNERS *********
    const getBeneficialOwners = async () => {
        try {
            const res = await axiosInstance.get(`ezpay/beneficial-owner/`)

            state.setBeneficialOwners(res.data)
            return Promise.resolve()
        } catch (e) {
            toast.error(`Error getting beneficial owners ${e}`)
            return Promise.reject(e)
        }
    }

    const createBeneficialOwner = async (
        request: CreateBeneficialOwnerBody,
    ) => {
        try {
            const res = await axiosInstance.post(
                `ezpay/beneficial-owner/`,
                request,
            )
            _insertOrUpdateIdentifiable(res.data, state.setBeneficialOwners)

            return Promise.resolve()
        } catch (e) {
            toast.error(`Error getting beneficial owners ${e}`)
            return Promise.reject(e)
        }
    }

    const deleteBeneficialOwner = async (owner: DwollaBeneficialOwner) => {
        try {
            await axiosInstance.delete(`ezpay/beneficial-owner/${owner.id}/`)

            _removeIdentifiable(owner, state.setBeneficialOwners)

            return Promise.resolve()
        } catch (e) {
            toast.error(`Error deleting ${e}`)
            return Promise.reject(e)
        }
    }

    // ********* TRANSFERS *********
    const getInvoiceList = async (filters?: {
        statusList: DwollaTransferStatus[]
    }) => {
        const params: any = {
            enable_ezpay: true,
        }

        if (filters?.statusList) {
            // statusStr = params.statusList.join(',')
            params.status_list = filters.statusList.join(',')
        }

        const res = await axiosInstance.get('invoice/', {
            params: params,
        })

        state.setInvoiceList(res.data)
    }

    const transitionTransferRequest = async (
        transfer: DwollaTransfer,
        sourceFundingSourceId: number,
        action: 'pay' | 'cancel',
    ) => {
        try {
            const res = await axiosInstance.patch(
                `ezpay/transfer/${transfer.id}/`,
                {
                    source_funding_source: sourceFundingSourceId,
                    action: action,
                },
            )

            const updatedTransfer: DwollaTransfer = res.data

            console.log('updated value', updatedTransfer)

            state.setInvoiceList((prev) => {
                return (
                    prev?.map((oldInv) => {
                        if (oldInv.dwolla_transfer?.id === updatedTransfer.id) {
                            return {
                                ...oldInv,
                                dwolla_transfer: updatedTransfer,
                            }
                        }

                        return oldInv
                    }) ?? null
                )
            })

            return Promise.resolve(updatedTransfer)
        } catch (e) {
            toast.error(`Error initiating payment ${e}`)
            return Promise.reject(e)
        }
    }

    // ********* Private state setting functions *********
    const _insertOrUpdateIdentifiable = <T extends IdentifiableObject>(
        updatedValue: T,
        dispatch: Dispatch<SetStateAction<T[] | null>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            let found = false
            for (let i = 0; i < safeOldValue.length; i++) {
                const current = safeOldValue[i]
                if (current.id === updatedValue.id) {
                    safeOldValue[i] = updatedValue
                    found = true
                    break
                }
            }

            if (!found) {
                safeOldValue.push(updatedValue)
            }

            return safeOldValue
        })
    }

    const _removeIdentifiable = <T extends IdentifiableObject>(
        deleteValue: T,
        dispatch: Dispatch<SetStateAction<T[] | null>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            return safeOldValue.filter((v) => v.id !== deleteValue.id)
        })
    }

    return {
        // Expose state variables
        balance: state.balance,
        dwollaCustomer: state.dwollaCustomer,
        fundingSources: state.fundingSources,
        beneficialOwners: state.beneficialOwners,
        invoiceList: state.invoiceList,
        clearState: state.clearState,

        // Customer
        getDwollaCustomer,
        certifyCustomer,
        updateCustomer,
        createBusinessVerifiedCustomer,

        // Beneficial Owner
        getBeneficialOwners,
        createBeneficialOwner,
        deleteBeneficialOwner,

        // Funding Sources
        getBalance,
        getFundingSources,
        createFundingSource,
        setDefaultFundingSource,
        initiateMicroDeposits,
        verifyFundingSource,
        deleteFundingSource,
        editFundingSource,

        // transfers
        getInvoiceList,
        transitionTransferRequest,
    }
}

export const useDwollaState = (): IDwollaContext => {
    const [balance, setBalance] = useState<any | null>(null)
    const [dwollaCustomer, setDwollaCustomer] = useState<DwollaCustomer | null>(
        null,
    )
    const [fundingSources, setFundingSources] = useState<
        DwollaFundingSource[] | null
    >(null)

    const [beneficialOwners, setBeneficialOwners] = useState<
        DwollaBeneficialOwner[] | null
    >(null)

    const [invoiceList, setInvoiceList] = useState<Invoice[] | null>(null)

    const clearState = () => {
        setBalance(null)
        setDwollaCustomer(null)
        setFundingSources(null)
        setBeneficialOwners(null)
        setInvoiceList(null)
    }

    return {
        balance,
        setBalance,
        dwollaCustomer,
        setDwollaCustomer,
        fundingSources,
        setFundingSources,
        beneficialOwners,
        setBeneficialOwners,
        clearState,
        invoiceList,
        setInvoiceList,
    }
}

export interface CreateFundingSourceBody {
    account_number: string
    routing_number: string
    account_name: string
    account_type: DwollaBankAccountType
}

export interface CreateBeneficialOwnerBody {
    first_name: string
    last_name: string
    date_of_birth: string
    ssn: string
    address: string
    city: string
    state: string
    country: string
    zip_code: string
}

export interface CreateBusinessVerifiedCustomerBody {
    business_name: string
    doing_business_as: string
    website: string
    ein: string
    business_type: string
    job_title: string
    date_of_birth: string
    ssn: string
    address: string
    city: string
    state: string
    zip_code: string
    first_name: string
    last_name: string
}
