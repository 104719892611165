import { useTheme } from '@material-ui/core'
import React, { useState } from 'react'
import { useRef } from 'react'
import { SketchPicker } from 'react-color'
import { useWindowSize } from '../../hooks'
import { Container } from '../Container'

interface Props {
    size?: number
    color: string
    setColor: (c: string) => void
    style?: React.CSSProperties
    customTopPlacement?: number
    customLeftPlacement?: number
}

export const ColorPicker = (props: Props) => {
    const {
        size,
        color,
        setColor,
        customLeftPlacement,
        customTopPlacement,
    } = props

    const theme = useTheme()
    const windowSize = useWindowSize()

    const [displayPicker, setDisplayPicker] = useState(false)

    const circleRef = useRef<HTMLDivElement>(null)

    const ColorIconSize = size ?? 30

    const windowHeight = windowSize.height ?? Number.MAX_SAFE_INTEGER
    const windowWidth = windowSize.width ?? Number.MAX_SAFE_INTEGER
    //distance from the top/left of the window to the color icons top left pixel
    const anchorY = circleRef.current
        ? circleRef.current?.getBoundingClientRect().y
        : 0
    const anchorX = circleRef.current
        ? circleRef.current?.getBoundingClientRect().x
        : 0

    // NOTE: to use other variants we would need to manually calculate height and width of the picker
    const pickerHeight = 301
    const pickerWidth = 220

    const contentHeight = anchorY + ColorIconSize + pickerHeight
    const contentWidth = anchorX + ColorIconSize + pickerWidth

    const offsetY = contentHeight - windowHeight
    const offsetX = contentWidth - windowWidth

    const topPopup =
        contentHeight > windowHeight ? ColorIconSize - offsetY : ColorIconSize

    const leftPopup =
        contentWidth > windowWidth ? ColorIconSize - offsetX : ColorIconSize
    const defaultPopupPlacement: React.CSSProperties = {
        top: customTopPlacement ?? topPopup,
        left: customLeftPlacement ?? leftPopup,
    }

    return (
        <Container style={{ position: 'relative' }} ref={circleRef}>
            <svg
                height={ColorIconSize}
                width={ColorIconSize}
                onClick={() => setDisplayPicker(!displayPicker)}
                style={{ cursor: 'pointer' }}
            >
                <circle
                    style={{ position: 'absolute' }}
                    fill={theme.palette.grey[300]}
                    r={ColorIconSize / 2}
                    cx={ColorIconSize / 2}
                    cy={ColorIconSize / 2}
                />
                <circle
                    r={(ColorIconSize - ColorIconSize * 0.2) / 2}
                    cx={ColorIconSize / 2}
                    cy={ColorIconSize / 2}
                    fill={color}
                />
            </svg>
            {displayPicker && (
                <Container
                    style={{
                        position: 'absolute',
                        ...defaultPopupPlacement,
                        zIndex: theme.zIndex.tooltip,
                    }}
                >
                    {/* div lets us click to close the modal */}
                    <div
                        style={{
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        }}
                        onClick={() => setDisplayPicker(false)}
                    />

                    <SketchPicker
                        color={color}
                        onChange={(c) => setColor(c.hex)}
                    />
                </Container>
            )}
        </Container>
    )
}
