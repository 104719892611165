import React, { useState } from 'react'
import {
    Button,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    useTheme,
} from '@material-ui/core'
import { Container } from '../../components'
import { useAppDispatch } from '../../hooks'
import { createAreaConfigReq, CreateAreaConfigRequest } from '../../store'
import { toast } from 'react-toastify'
import { AreaType } from '../../models'
import { useMultiSelectStyles } from '../../styles'

interface Props {
    onClose: () => void
}

export const CreateAreaConfigForm = (props: Props) => {
    const theme = useTheme()

    const [name, setName] = useState('')
    const [validate, setValidate] = useState(false)
    const [areaType, setAreaType] = useState<AreaType>(AreaType.COM)

    const dispatch = useAppDispatch()

    return (
        <Container style={{ height: 500, width: 500, flexDirection: 'column' }}>
            {/* Title area */}
            <Container style={{ padding: theme.spacing(1) }}>
                <span
                    style={{
                        ...theme.typography.h5,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Create Area Config
                </span>
            </Container>
            <Divider />

            <Container
                style={{ flexDirection: 'column', padding: theme.spacing(1) }}
            >
                <TextField
                    variant="outlined"
                    label="Name (Required)"
                    error={validate && name === ''}
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    style={{ marginBottom: theme.spacing(1) }}
                />

                <FormControl
                    style={{ margin: theme.spacing(2, 0), width: '100%' }}
                >
                    <InputLabel id="area-type-label">Area Type</InputLabel>
                    <Select
                        id={`area-type-select`}
                        value={areaType}
                        onChange={(e) => {
                            setAreaType(e.target.value as AreaType)
                        }}
                        label="Area Type"
                        title="Area Type"
                        style={{ width: '100%' }}
                    >
                        {Object.values(AreaType).map((value) => {
                            return (
                                <MenuItem key={value} value={value}>
                                    {value.charAt(0) +
                                        value.substring(1).toLocaleLowerCase()}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Container>
            <div style={{ flex: 1 }} />
            <Divider />
            <Container style={{ padding: theme.spacing(1) }}>
                <div style={{ flex: 1 }} />
                <Button
                    variant="outlined"
                    color="secondary"
                    style={{ marginRight: theme.spacing(1) }}
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        if (name === '') {
                            setValidate(true)
                            return
                        }

                        const req: CreateAreaConfigRequest = {
                            body: {
                                name: name,
                                area_type: areaType,
                            },
                        }

                        dispatch(createAreaConfigReq(req)).then(() => {
                            toast.success(`${name} Created.`)
                            setName('')
                            props.onClose()
                        })
                    }}
                >
                    Create
                </Button>
            </Container>
        </Container>
    )
}
