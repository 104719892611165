import {
    Button,
    ButtonGroup,
    FormControlLabel,
    Switch,
    useTheme,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useHistory, useLocation } from 'react-router'
import { Container } from '../../components'
import { getNumFromURLSegment, resolveRoute, Routes } from '../../helpers'
import { useAppDispatch, useUser } from '../../hooks'
import { getDateRange } from '../../models'
import {
    getJobsiteLocationList,
    getJobsiteServiceList,
    RootState,
    setJobsiteLocation,
    setJobsiteService,
    setPriorityFilter,
} from '../../store'

import { DateFilter } from './dateFilter'
import { StatusFilter } from './statusFilter'

let goHome = false //defined outside of function for scoping

export const VendorSchedulerLocation = () => {
    const jobsite = useSelector((state: RootState) => state.jobsite)

    const { workspaceUser } = useUser()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const history = useHistory()

    const url = useLocation()
    useEffect(() => {
        // if service is not here we are here from a refresh
        if (!jobsite.service) {
            // url.pathname is the part after ezturn.co, for ex: /scheduler/1/location
            const urlSegment = url.pathname.substring(11) // the characters where the number is and beyond
            const serviceId = getNumFromURLSegment(urlSegment).number
            let found = false
            const dateRange = getDateRange(jobsite.dateFilter)
            dispatch(getJobsiteServiceList({ params: { ...dateRange } })).then(
                (res) => {
                    //find the service where its id is the url service id
                    res.data.forEach((service) => {
                        if (service.id === serviceId) {
                            found = true
                            dispatch(setJobsiteService(service))
                        }
                    })
                    goHome = !found
                },
            )
        }
    }, [])

    useEffect(() => {
        const dateRange = getDateRange(jobsite.dateFilter)
        if (jobsite.service) {
            dispatch(
                getJobsiteLocationList({
                    params: {
                        ...dateRange,
                        service: jobsite.service.id,
                        wo_status: jobsite.workorderStatusFilter,
                        priority: jobsite.priorityFilter,
                    },
                }),
            )
        }

        dispatch(
            getJobsiteServiceList({
                params: {
                    ...dateRange,
                    wo_status: jobsite.workorderStatusFilter,
                },
            }),
        )
    }, [
        jobsite.dateFilter,
        jobsite.workorderStatusFilter,
        jobsite.service,
        jobsite.priorityFilter,
    ])

    const services = useSelector(
        (state: RootState) => state.jobsite.serviceList,
    )

    const selectedService = services?.find(
        (service) => service.id === jobsite.service?.id,
    )

    return (
        <Container
            direction="column"
            style={{ margin: theme.spacing(1, 0, 0, 1) }}
            flex={1}
        >
            {goHome ? <Redirect to="/scheduler" /> : null}
            {/* Header */}
            <Container
                style={{ marginBottom: theme.spacing(3) }}
                direction="row"
            >
                <Container flex={1}>
                    <span
                        style={{
                            ...theme.typography.h5,
                            fontWeight: theme.typography.fontWeightBold,
                            marginRight: theme.spacing(2),
                        }}
                    >
                        {jobsite.service ? jobsite.service.name : 'Loading... '}
                    </span>
                    <span style={{ alignSelf: 'center' }}>{`${
                        selectedService?.count ?? 0
                    } Workorders`}</span>
                </Container>

                <Container
                    style={{ margin: theme.spacing(0, 2, 1, 0) }}
                    alignItems="flex-end"
                >
                    <span
                        style={{ fontWeight: theme.typography.fontWeightBold }}
                    >
                        Jobsite:{' '}
                    </span>
                    <span>{workspaceUser?.jobsite?.name}</span>
                </Container>
            </Container>
            {/* Filters */}
            <Container>
                <DateFilter
                    style={{
                        formControl: {
                            width: 150,
                            marginLeft: theme.spacing(3),
                        },
                    }}
                />
                <StatusFilter
                    style={{
                        formControl: {
                            width: 150,
                            marginLeft: theme.spacing(3),
                        },
                    }}
                />
                <FormControlLabel
                    labelPlacement="top"
                    label="Priority"
                    style={{
                        marginLeft: theme.spacing(2),
                    }}
                    control={
                        <Switch
                            checked={jobsite.priorityFilter}
                            color="primary"
                            onClick={() =>
                                dispatch(
                                    setPriorityFilter(!jobsite.priorityFilter),
                                )
                            }
                        />
                    }
                />
            </Container>

            {/* Body */}
            <Container
                direction="column"
                style={{ marginTop: theme.spacing(2) }}
                scrollY
            >
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Select A Location
                </span>
                <ButtonGroup
                    orientation="vertical"
                    color="primary"
                    style={{ marginRight: theme.spacing(1) }}
                >
                    {jobsite.locationList?.map((location) => {
                        return (
                            <Button
                                key={`TESTING_SPAN_${location.id}`}
                                onClick={() => {
                                    dispatch(setJobsiteLocation(location))
                                    history.push(
                                        resolveRoute(
                                            resolveRoute(
                                                Routes.vendorScheduleDetail,
                                                ':id',
                                                jobsite.service!.id,
                                            ),
                                            ':locationId',
                                            location.id,
                                        ),
                                    )
                                }}
                                size="large"
                            >
                                <span
                                    style={{
                                        color: theme.palette.common.black,
                                    }}
                                >
                                    {`${location.path}${location.name}`}
                                </span>
                                <span
                                    style={{
                                        color: theme.palette.common.black,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        marginLeft: theme.spacing(1),
                                    }}
                                >
                                    {` (${location.count}) ${
                                        location.count === 1 ? 'Unit' : 'Units'
                                    }`}
                                </span>
                            </Button>
                        )
                    })}
                </ButtonGroup>
            </Container>
        </Container>
    )
}
