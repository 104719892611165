import { Button, useTheme } from '@material-ui/core'
import React, { Dispatch, SetStateAction } from 'react'
import { Container } from '../../components'
import { VendorModalServices } from '../Workforce/VendorModal/VendorModalServices'
import { axiosInstance } from '../../helpers'
import { toast } from 'react-toastify'
import { ListVendor, Service } from '../../models'

interface Props {
    vendor: ListVendor
    selectedServices: Service[]
    setSelectedServices: Dispatch<SetStateAction<Service[]>>
    serviceList: Service[]
    setServiceList: (newServiceList: Service[]) => void
    setVendor: (vendor: ListVendor) => void
}

export const VendorServices = (props: Props) => {
    const theme = useTheme()

    const {
        vendor,
        selectedServices,
        setSelectedServices,
        serviceList,
        setServiceList,
        setVendor,
    } = props

    const sortedServiceList = serviceList.sort((serviceA, serviceB) =>
        serviceA.name.localeCompare(serviceB.name),
    )

    return (
        <Container
            direction="column"
            style={{
                width: '500px',
                height: '500px',
                margin: theme.spacing(3),
            }}
        >
            <Container
                style={{
                    margin: theme.spacing(1, 1, 2, 0),
                    fontWeight: 400,
                    fontSize: '14px',
                }}
            >
                Click on a service to add that service to your service list.
                Please contact our support team to remove services.
            </Container>
            <Container
                style={{
                    border: `1px solid ${theme.palette.lightGrey.main}`,
                    borderRadius: '10px',
                    padding: theme.spacing(1),
                }}
            >
                <VendorModalServices
                    selectedServices={selectedServices}
                    serviceList={sortedServiceList}
                    setSelectedServices={setSelectedServices}
                    setServiceList={setServiceList}
                    open={true}
                />
            </Container>
            <Container style={{ marginTop: theme.spacing(1) }}>
                <Button
                    variant="contained"
                    style={{
                        margin: theme.spacing(2, 2, 2, 0),
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        const services: {
                            id: number
                            offers: boolean
                        }[] = []
                        serviceList.forEach((service) => {
                            services.push({
                                id: service.id,
                                offers: service.checked ?? false,
                            })
                        })
                        const body = {
                            services: services,
                        }
                        axiosInstance
                            .patch(`company/vendor/${vendor.id}/`, body)
                            .then((res) => {
                                toast.success(
                                    `${res.data.vendor.name} services updated`,
                                )
                                const tempVendor = vendor
                                tempVendor.services = res.data.vendor.services
                                setVendor(tempVendor)
                            })
                    }}
                >
                    Save
                </Button>
            </Container>
        </Container>
    )
}
