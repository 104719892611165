import React, { useRef } from 'react'
import { Tooltip } from '@material-ui/core'

import { useTheme } from '@material-ui/core/styles'
import { useStyles } from '../../styles'
import { getInitials } from '../../helpers'
import { AddAPhoto } from '@material-ui/icons'

interface CustomStyles {
    container?: React.CSSProperties
    text?: React.CSSProperties
    image?: React.CSSProperties
}

interface IAvatarCircleProps {
    styles?: CustomStyles
    text?: string
    onClick?: () => void
    image?: url
    initials?: boolean
    changePicMode?: boolean
    onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    addImageIcon?: boolean
    tooltip?: string
}

export const AvatarCircle = React.forwardRef<
    HTMLDivElement,
    IAvatarCircleProps
>((props, ref) => {
    const theme = useTheme()
    const classes = useStyles()
    const addPictureInputRef = useRef<HTMLInputElement>(null)
    const containerStyle: React.CSSProperties = {
        borderRadius: '100%',
        backgroundColor: theme.palette.info.light,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.common.white,
        position: 'relative',
        cursor: 'pointer',
        ...props.styles?.container,
    }

    const imageStyle: React.CSSProperties = {
        borderRadius: '100%',
        height: '100%',
        width: '100%',
        ...props.styles?.image,
    }

    let text = props.text ? props.text : ''
    if (text !== '' && props.initials) {
        //parse the string to get just the initials
        text = getInitials(text)
    }
    const onClick = props.changePicMode
        ? () =>
              // excecute the on click for the input component
              addPictureInputRef.current && addPictureInputRef.current.click()
        : props.onClick

    return (
        <Tooltip title={props.tooltip ?? props.text ?? ''}>
            <div
                {...props}
                style={{
                    minHeight: 60,
                    maxHeight: 60,
                    minWidth: 60,
                    maxWidth: 60,
                    overflow: 'hidden',
                    ...containerStyle,
                }}
                onClick={onClick}
                ref={ref}
            >
                {props.image ? (
                    <img
                        src={props.image}
                        style={imageStyle}
                        className={classes.box60}
                    />
                ) : props.addImageIcon ? (
                    <AddAPhoto />
                ) : (
                    <span style={props.styles?.text}>{text}</span>
                )}
                <input
                    ref={addPictureInputRef}
                    type="file"
                    style={{ display: 'none' }}
                    accept={'image/*'}
                    onChange={props.onInputChange}
                    multiple={false}
                />
            </div>
        </Tooltip>
    )
})
