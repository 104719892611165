import { Checkbox, FormControlLabel, useTheme } from '@material-ui/core'
import React from 'react'
import { Range, DateRange } from 'react-date-range'
import { Container, Selector } from '../../../../components'
import {
    ChangeOrder,
    ChangeOrderConfig,
    ListVendor,
    Service,
} from '../../../../models'
import { CO_WO_RANGE_KEY, ServiceAreaChangeState } from './ChangeOrderModal'

interface Props {
    serviceAreaState: ServiceAreaChangeState
    changeOrder?: ChangeOrder
    changeOrderConfig?: ChangeOrderConfig
    vendorList: ListVendor[]
    serviceList: Service[]
    selectedServiceId: number
    selectedVendorId: number
    dateRange: Range
    disableVendor?: boolean
    disableService?: boolean
    disableDate?: boolean
    headLine: string
    showInitialServiceAreas?: boolean
    onChangeRange: (range: Range) => void
    onSelectService: (id: number) => void
    onSelectVendor: (id: number) => void
    onSelectArea: (areaId: number) => void
}

export const ChangeOrderEditWorkorderPanel = (props: Props) => {
    const theme = useTheme()

    const {
        serviceAreaState,
        onSelectArea,
        changeOrder,
        changeOrderConfig,
        vendorList,
        selectedServiceId,
        onSelectService,
        serviceList,
        selectedVendorId,
        onSelectVendor,
        dateRange,
        onChangeRange,
        disableDate,
        disableService,
        disableVendor,
    } = props

    const titleStyle: React.CSSProperties = {
        ...theme.typography.h5,
        fontWeight: 'bold',
        color: theme.palette.grey[700],
    }

    let body = (
        <Container
            style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}
        >
            <span style={titleStyle}>Change of scope only</span>
        </Container>
    )
    if (changeOrder && changeOrderConfig && !changeOrderConfig.addon) {
        body = (
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    padding: theme.spacing(2),
                }}
            >
                <Container style={{ height: 70 }}>
                    <Selector
                        label="Service"
                        data={serviceList}
                        disabled={disableService}
                        currentValue={selectedServiceId}
                        onChange={(e) =>
                            onSelectService(e.target.value as number)
                        }
                        getDisplayString={(service) => service.name}
                        customStyle={{
                            formControl: {
                                width: 200,
                                marginLeft: 0,
                                marginTop: 0,
                            },
                        }}
                        noSelectionLabel="Select a Service"
                    />

                    <Selector
                        label="Vendor"
                        disabled={disableVendor}
                        data={vendorList}
                        currentValue={selectedVendorId}
                        onChange={(e) =>
                            onSelectVendor(e.target.value as number)
                        }
                        getDisplayString={(vendor) => vendor.name}
                        customStyle={{
                            formControl: { width: 200, marginTop: 0 },
                        }}
                        noSelectionLabel="Select a vendor"
                    />
                </Container>

                <div>
                    <DateRange
                        ranges={[dateRange]}
                        fixedHeight
                        onChange={(range) => {
                            if (disableDate) {
                                return
                            }
                            const key = CO_WO_RANGE_KEY as keyof typeof range

                            const newRange = range[key] as {
                                startDate: Date
                                endDate: Date
                            }

                            onChangeRange({
                                startDate: newRange.startDate,
                                endDate: newRange.endDate,
                                key: key,
                                color: theme.palette.primary.main,
                            })
                        }}
                    />
                </div>

                {/* Select Service Areas */}
                <span style={titleStyle}>Service Areas</span>
                <Container>
                    {props.showInitialServiceAreas && (
                        <Container
                            style={{
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                marginRight: theme.spacing(2),
                            }}
                        >
                            {/* Initial Values */}
                            {Object.keys(serviceAreaState).map((strKey) => {
                                const areaId = Number(strKey)
                                return (
                                    <FormControlLabel
                                        key={`EXISTING_SA_${strKey}`}
                                        control={
                                            <Checkbox
                                                checked={
                                                    serviceAreaState[areaId]
                                                        .initial
                                                }
                                                disabled
                                            />
                                        }
                                        labelPlacement="start"
                                        label={serviceAreaState[areaId].label}
                                        style={{ marginLeft: 0 }}
                                    />
                                )
                            })}
                        </Container>
                    )}

                    <Container
                        style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        {Object.keys(serviceAreaState).map((strKey) => {
                            const areaId = Number(strKey)
                            return (
                                <FormControlLabel
                                    key={`NEW_WORKORDER_SA_${strKey}`}
                                    control={
                                        <Checkbox
                                            checked={
                                                serviceAreaState[areaId].current
                                            }
                                            onChange={() => {
                                                onSelectArea(areaId)
                                            }}
                                        />
                                    }
                                    labelPlacement="start"
                                    label={
                                        !props.showInitialServiceAreas
                                            ? serviceAreaState[areaId].label
                                            : ''
                                    }
                                    style={{ marginLeft: 0 }}
                                />
                            )
                        })}
                    </Container>
                </Container>
            </Container>
        )
    }

    return (
        <Container
            style={{
                flex: 2,
                flexDirection: 'column',
                margin: theme.spacing(1),
            }}
        >
            <span style={titleStyle}>{props.headLine}</span>

            <Container
                style={{
                    flex: 2,
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: theme.shape.borderRadius,
                    flexDirection: 'column',
                }}
            >
                {body}
            </Container>
        </Container>
    )
}
