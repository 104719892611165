import { IconButton, Modal, Paper, Slide, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import {
    Container,
    MessageForm,
    SideDrawer,
    SideDrawerContainer,
} from '../../components'
import { ReactComponent as BidImage1 } from '../../assets/bid-image-1.svg'
import { ReactComponent as BidImage2 } from '../../assets/bid-image-2.svg'
import { ReactComponent as BidImage3 } from '../../assets/bid-image-3.svg'
import { rfpApi } from '../../contexts'
import { BidStatus, RFPType } from '../../models'
import { VendorBidList } from './VendorBidList'
import { VendorViewRFPDetail } from './VendorViewRFPDetail'
import { useService } from '../../hooks'
import { BidFilterForm } from './BidFilterForm'
import TuneIcon from '@material-ui/icons/Tune'

export const VendorMyBidsPage = () => {
    const {
        getBidList,
        bidList,
        selectedBid,
        setSelectedBid,
        filteredBidList,
        bidFilterState,
        setBidFilterState,
        updateBid,
        updateBidState,
    } = rfpApi()

    const [openRFPDetail, setOpenRFPDetail] = useState(false)
    const [openFilterDrawer, setOpenFilterDrawer] = useState(false)
    const [channelId, setChannelId] = useState(-1)

    useEffect(() => {
        getBidList({
            params: {
                rfp_type: `${RFPType.INVITE_ONLY},${RFPType.PUBLIC}`,
            },
        })
        setBidFilterState({ ...bidFilterState, includeUnsolicited: true })
    }, [])

    const theme = useTheme()

    const { serviceList } = useService({ getServiceList: true, cleanUp: true })

    const statusFilterStyle: React.CSSProperties = {
        border: `1px solid ${theme.palette.lightGrey.main}`,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        cursor: 'pointer',
    }

    const newRequestsCount = bidList?.filter(
        (bid) => bid.status === BidStatus.INVITATION,
    ).length
    const pendingCount = bidList?.filter(
        (bid) =>
            bid.status === BidStatus.SUBMITTED ||
            bid.status === BidStatus.REVIEWED,
    ).length
    const acceptedCount = bidList?.filter(
        (bid) => bid.status === BidStatus.ACCEPTED,
    ).length

    return (
        <SideDrawerContainer
            open={openFilterDrawer || channelId !== -1}
            width={420}
        >
            <Container
                flex={1}
                style={{
                    margin: theme.spacing(1),
                }}
                direction="column"
            >
                <Container
                    style={{
                        ...theme.typography.h5,
                        fontWeight: 600,
                    }}
                >
                    Managing Your Bids
                </Container>
                <Container
                    style={{
                        marginTop: theme.spacing(2),
                    }}
                >
                    <Container
                        alignItems="center"
                        style={{
                            ...statusFilterStyle,
                            backgroundColor: bidFilterState.statusFilter?.includes(
                                BidStatus.INVITATION,
                            )
                                ? theme.palette.grey[300]
                                : '',
                        }}
                        onClick={() => {
                            setBidFilterState({
                                ...bidFilterState,
                                statusFilter: [BidStatus.INVITATION],
                            })
                        }}
                    >
                        <Container direction="column">
                            <Container
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: 600,
                                }}
                            >
                                New Requests
                            </Container>
                            <BidImage1 />
                        </Container>
                        <Container
                            style={{
                                ...theme.typography.h6,
                                fontWeight: 600,
                                marginLeft: theme.spacing(2),
                            }}
                        >
                            {newRequestsCount}
                        </Container>
                    </Container>
                    <Container
                        style={{
                            ...statusFilterStyle,
                            margin: theme.spacing(0, 10),
                            backgroundColor:
                                bidFilterState.statusFilter?.includes(
                                    BidStatus.SUBMITTED,
                                ) &&
                                bidFilterState.statusFilter?.includes(
                                    BidStatus.REVIEWED,
                                )
                                    ? theme.palette.grey[300]
                                    : '',
                        }}
                        alignItems="center"
                        onClick={() => {
                            setBidFilterState({
                                ...bidFilterState,
                                statusFilter: [
                                    BidStatus.SUBMITTED,
                                    BidStatus.REVIEWED,
                                ],
                            })
                        }}
                    >
                        <Container direction="column">
                            <Container
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: 600,
                                }}
                            >
                                Pending Requests
                            </Container>
                            <BidImage3 />
                        </Container>
                        <Container
                            style={{
                                ...theme.typography.h6,
                                fontWeight: 600,
                                marginLeft: theme.spacing(2),
                            }}
                        >
                            {pendingCount}
                        </Container>
                    </Container>
                    <Container
                        alignItems="center"
                        style={{
                            ...statusFilterStyle,
                            backgroundColor: bidFilterState.statusFilter?.includes(
                                BidStatus.ACCEPTED,
                            )
                                ? theme.palette.grey[300]
                                : '',
                        }}
                        onClick={() => {
                            setBidFilterState({
                                ...bidFilterState,
                                statusFilter: [BidStatus.ACCEPTED],
                            })
                        }}
                    >
                        <Container direction="column">
                            <Container
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: 600,
                                }}
                            >
                                Approved/Active Projects
                            </Container>
                            <BidImage2 />
                        </Container>
                        <Container
                            style={{
                                ...theme.typography.h6,
                                fontWeight: 600,
                                marginLeft: theme.spacing(2),
                            }}
                        >
                            {acceptedCount}
                        </Container>
                    </Container>
                    <Container flex={1} />
                    <IconButton
                        onClick={() => setOpenFilterDrawer(true)}
                        style={{ alignSelf: 'flex-end' }}
                    >
                        <TuneIcon fontSize="large" />
                    </IconButton>
                </Container>
                <VendorBidList
                    bidList={filteredBidList}
                    selectedBid={selectedBid}
                    setSelectedBid={setSelectedBid}
                    setOpenDetail={setOpenRFPDetail}
                    setChannelId={setChannelId}
                />
                <Modal
                    open={openRFPDetail}
                    onClose={() => setOpenRFPDetail(false)}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={openRFPDetail}>
                        <Paper
                            style={{
                                width: 1500,
                                maxWidth: 'calc(100vw - 100px)',
                                height: 1100,
                                maxHeight: 'calc(100vh - 100px)',
                            }}
                        >
                            <VendorViewRFPDetail
                                bid={selectedBid}
                                updateBid={updateBid}
                                handleClose={() => setOpenRFPDetail(false)}
                            />
                        </Paper>
                    </Slide>
                </Modal>
                <SideDrawer
                    open={openFilterDrawer}
                    width={420}
                    handleClose={() => setOpenFilterDrawer(false)}
                    title={'Bid Filters'}
                >
                    <BidFilterForm
                        bidList={bidList}
                        theme={theme}
                        serviceList={serviceList}
                        bidFilterState={bidFilterState}
                        setBidFilterState={setBidFilterState}
                    />
                </SideDrawer>
                <SideDrawer
                    open={channelId !== -1}
                    width={420}
                    handleClose={() => {
                        setSelectedBid(null)
                        setChannelId(-1)
                    }}
                >
                    <MessageForm
                        theme={theme}
                        channelId={channelId}
                        afterCreateMessage={() => {
                            if (selectedBid) {
                                updateBidState({
                                    ...selectedBid,
                                    message_count:
                                        selectedBid.message_count + 1,
                                })
                            }
                        }}
                    />
                </SideDrawer>
            </Container>
        </SideDrawerContainer>
    )
}
