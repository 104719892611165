import React, { useEffect, useState } from 'react'
import { Container } from '../../../../../components'
import {
    AuditPromptConfig,
    LeaseAudit,
    LeaseAuditStep,
} from '../../../../../models'
import { LeaseAuditLoadingState, UpdateLeaseAuditRequest } from '../../../Hooks'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Modal,
    Paper,
    Slide,
    Theme,
    useTheme,
} from '@material-ui/core'
import { DropFiles } from '../../../../../components/DropFiles'
import { ReactComponent as PDFLogo } from '../../../../../assets/pdf_icon.svg'
import { DeleteForever } from '@material-ui/icons'
import { PromptResults } from './PromptResults'
import { usePopover } from '../../../../../hooks/usePopover'
import { FeatureSelectorPopover } from './FeatureSelectorPopover'
import { FeatureChip } from './FeatureChip'
import { BaseLineResults } from './BaseLineResults'

interface Props {
    leaseAudit: LeaseAudit
    promptConfigs: AuditPromptConfig[]
    loadingState: LeaseAuditLoadingState
    updateLeaseAudit: (request: UpdateLeaseAuditRequest) => Promise<LeaseAudit>
    removeAuditBasePDF: (id: number) => void
    selectedConfigs: number[]
    setSelectedConfigs: (selectedConfigs: number[]) => void
}

export const LeaseAuditBaseLine = (props: Props) => {
    const {
        leaseAudit,
        promptConfigs,
        loadingState,
        updateLeaseAudit,
        removeAuditBasePDF,
        selectedConfigs,
        setSelectedConfigs,
    } = props

    const [openDialog, setOpenDialog] = useState(false)
    const [openPromptModal, setOpenPromptResultsModal] = useState(false)
    const [baselineAuditComplete, setBaselineAuditComplete] = useState(false)

    const theme = useTheme()
    const popoverState = usePopover()

    return (
        <Container
            style={{
                flexDirection: 'column',
                flex: 1,
                padding: theme.spacing(2),
                overflow: 'scroll',
            }}
        >
            {/* button grouping */}
            <Container
                style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                }}
            >
                {/* select features button */}
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.primary.contrastText,
                        textTransform: 'none',
                        margin: theme.spacing(1),
                        maxWidth: 260,
                    }}
                    onClick={popoverState.handleOpen}
                >
                    Select Features
                </Button>

                <FeatureSelectorPopover
                    {...props}
                    popoverState={popoverState}
                    promptConfigs={promptConfigs}
                />

                {/* complete baseline audit button */}
                <Button
                    style={{
                        backgroundColor:
                            leaseAudit.baseline_lease_pdf &&
                            selectedConfigs.length > 0
                                ? theme.palette.primary.dark
                                : theme.palette.grey[600],
                        color: theme.palette.primary.contrastText,
                        textTransform: 'none',
                        margin: theme.spacing(1),
                        maxWidth: 260,
                    }}
                    onClick={() => {
                        const selectedPromptList: number[] = []
                        promptConfigs.forEach((config) => {
                            if (selectedConfigs[config.id]) {
                                selectedPromptList.push(config.id)
                            }
                        })
                        setBaselineAuditComplete(true)
                        const request: UpdateLeaseAuditRequest = {
                            lease_audit: leaseAudit.id,
                            prompts: selectedConfigs,
                            send_open_ai_request: true,
                        }
                        updateLeaseAudit(request).then(() => {
                            setOpenPromptResultsModal(true)
                        })
                    }}
                    disabled={leaseAudit.baseline_lease_pdf === null}
                >
                    Complete Baseline Audit
                </Button>

                {/* move on to lease audit step button */}
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: baselineAuditComplete
                            ? theme.palette.primary.dark
                            : theme.palette.grey[600],
                        color: theme.palette.primary.contrastText,
                        textTransform: 'none',
                        margin: theme.spacing(1),
                        maxWidth: 260,
                    }}
                    onClick={() => {
                        const selectedPromptList: number[] = []
                        selectedConfigs.forEach((config) => {
                            selectedPromptList.push(config)
                        })
                        const request: UpdateLeaseAuditRequest = {
                            lease_audit: leaseAudit.id,
                            prompts: selectedPromptList,
                            step: LeaseAuditStep.UPLOAD_LEASES,
                        }
                        updateLeaseAudit(request)
                        setOpenDialog(false)
                    }}
                    disabled={!baselineAuditComplete}
                >
                    Lease Uploads
                </Button>
            </Container>

            <SelectPromptConfigs
                promptConfigs={promptConfigs}
                selectedConfigs={selectedConfigs}
                theme={theme}
                setSelectedConfigs={setSelectedConfigs}
            />

            <AttachBasePDF
                leaseAudit={leaseAudit}
                promptConfigs={promptConfigs}
                selectedConfigs={selectedConfigs}
                theme={theme}
                updateLeaseAudit={updateLeaseAudit}
                removeAuditBasePDF={removeAuditBasePDF}
            />

            {leaseAudit.baseline_lease_pdf && (
                <BaseLineResults
                    openPromptModal={openPromptModal}
                    setOpenPromptResultsModal={setOpenPromptResultsModal}
                    loadingState={loadingState}
                    leaseAudit={leaseAudit}
                    lease={leaseAudit.baseline_lease_pdf}
                    promptConfigs={promptConfigs}
                    selectedConfigs={selectedConfigs}
                />
            )}

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Go to Lease Upload</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you finished configuring your audit? <br></br> If
                        you continue, you will have to start a new audit to
                        create a different configuration.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{
                            marginRight: theme.spacing(2),
                            textTransform: 'none',
                        }}
                        onClick={() => setOpenDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            const selectedPromptList: number[] = []
                            selectedConfigs.forEach((config) => {
                                selectedPromptList.push(config)
                            })
                            const request: UpdateLeaseAuditRequest = {
                                lease_audit: leaseAudit.id,
                                prompts: selectedPromptList,
                                step: LeaseAuditStep.UPLOAD_LEASES,
                            }
                            updateLeaseAudit(request)
                            setOpenDialog(false)
                        }}
                    >
                        Lease Uploads
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

interface SelectPromptConfigsProps {
    promptConfigs: AuditPromptConfig[]
    selectedConfigs: number[]
    theme: Theme
    setSelectedConfigs: (selectedConfigs: number[]) => void
}

const SelectPromptConfigs = (props: SelectPromptConfigsProps) => {
    const { promptConfigs, selectedConfigs, theme, setSelectedConfigs } = props
    const popoverState = usePopover()

    return (
        <Container
            style={{
                flexDirection: 'column',
                borderBottom: `1px solid ${theme.palette.primary.dark}`,
            }}
        >
            {/* HEADER */}
            <Container
                style={{
                    flexDirection: 'column',
                    flex: 1,
                    borderBottom: `1px solid ${theme.palette.primary.dark}`,
                }}
            >
                <FeatureSelectorPopover
                    {...props}
                    popoverState={popoverState}
                    promptConfigs={promptConfigs}
                />
            </Container>

            {/* Chips */}
            <Container
                style={{
                    flex: 1,
                    padding: theme.spacing(1),
                    flexDirection: 'column',
                }}
            >
                <Container
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                        alignItems: 'center',
                        marginLeft: theme.spacing(1),
                    }}
                >
                    Features Selected: {selectedConfigs.length}
                </Container>
                <FeatureChip
                    promptConfigs={promptConfigs}
                    selectedConfigs={selectedConfigs}
                    setSelectedConfigs={setSelectedConfigs}
                />
            </Container>
        </Container>
    )
}

interface AttachBasePDFProps {
    leaseAudit: LeaseAudit
    promptConfigs: AuditPromptConfig[]
    selectedConfigs: number[]
    theme: Theme
    updateLeaseAudit: (request: UpdateLeaseAuditRequest) => Promise<LeaseAudit>
    removeAuditBasePDF: (id: number) => void
}

const AttachBasePDF = (props: AttachBasePDFProps) => {
    const {
        leaseAudit,
        theme,
        promptConfigs,
        selectedConfigs,
        removeAuditBasePDF,
        updateLeaseAudit,
    } = props

    return (
        <Container
            style={{ flexDirection: 'column', marginTop: theme.spacing(1) }}
        >
            <Container
                style={{
                    ...theme.typography.subtitle1,
                    fontWeight: theme.typography.fontWeightBold,
                    color: theme.palette.grey[500],
                    marginBottom: theme.spacing(1),
                }}
            >
                Attach one Lease PDF to use as a baseline:
            </Container>

            <DropFiles
                multipleFiles={false}
                fileTypes={{ 'application/pdf': [] }}
                displayString="Drag and Drop Your Base Lease PDF Here"
                onDrop={(files) => {
                    const selectedPromptList: number[] = []
                    selectedConfigs.forEach((config) => {
                        selectedPromptList.push(config)
                    })
                    const request: UpdateLeaseAuditRequest = {
                        lease_audit: leaseAudit.id,
                        baseline_lease_pdf: files[0],
                        prompts: selectedConfigs,
                    }
                    updateLeaseAudit(request)
                }}
            />

            {leaseAudit.baseline_lease_pdf &&
                leaseAudit.baseline_lease_pdf.file_name && (
                    <Container
                        style={{
                            margin: theme.spacing(1, 1, 1, 0),
                            border: `1px solid ${theme.palette.grey[400]}`,
                            borderRadius: theme.shape.borderRadius,
                            padding: theme.spacing(1),
                            alignItems: 'center',
                            width: 'fit-content',
                        }}
                    >
                        <PDFLogo
                            style={{
                                height: '30px',
                                width: '30px',
                                marginRight: theme.spacing(1),
                            }}
                        />
                        {leaseAudit.baseline_lease_pdf.file_name}
                        <IconButton
                            onClick={() => {
                                removeAuditBasePDF(leaseAudit.id)
                            }}
                        >
                            <DeleteForever color="secondary" />
                        </IconButton>
                    </Container>
                )}
        </Container>
    )
}
