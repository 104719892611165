import React, { useEffect, useState } from 'react'
import { Container } from '../../components'
import {
    Badge,
    Button,
    Chip,
    Divider,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { Routes, axiosInstance, statesList } from '../../helpers'
import NationalVendorList, {
    NVLVendor,
} from '../../main/containers/national-vendor-list'
import Image from '../../assets/NVL_Pic.jpg'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { ReactComponent as RibbonAirplaneDarkIcon } from '../../assets/icons/fluent_ribbon-dark.svg'
import { ReactComponent as RibbonThumbGreenIcon } from '../../assets/icons/ribbon-thumb_green.svg'
import { ReactComponent as FeaturedVendorRibbon } from '../../assets/Featured_Vendor_Badge.svg'
import EzosLogo from '../../assets/ezos-logo-primary-dark.png'
import { PlanStatus, Service, getServiceImageDetails } from '../../models'
import { useService, useUser } from '../../hooks'
import { Pagination } from '@material-ui/lab'
import SearchIcon from '@material-ui/icons/Search'
import { Tune as TuneIcon } from '@material-ui/icons'
import { useMultiSelectStyles, useStyles } from '../../styles'
import { useHistory } from 'react-router-dom'
import { EZVendorModal } from './EZVendorModal'
import LoadingGIF from '../../assets/Loading_Animation_2.gif'
import SkillCatLogoSmall from '../../assets/SkillCatLogo_small.png'
import { useStripe } from '../../hooks/useStripe'
import { CreateAndEditRFP } from '../RFP/CreateAndEditRFP'
import { rfpApi } from '../../contexts'
import EZVendorLogo1 from '../../assets/EZVendorLogo1.png'

interface ServiceWOCount {
    count: number
    id: number
    name: string
}

export const HOME_PAGE = 1
export const NVL_TAB = 2

const EZVENDOR = 'EZVENDOR'

export const NVLHomePage = () => {
    const { workspaceUser, rootUser } = useUser()

    const [vendorsWithPlan, setVendorsWithPlan] = useState<NVLVendor[]>([])

    const [loading, setLoading] = useState(true)

    const [vendorsPerPage, setVendorsPerPage] = useState(
        window.innerWidth > 1700 ? 6 : 5,
    )
    const [vendorPage, setVendorPage] = useState(1)

    const [mostUsedServices, setMostUsedServices] = useState<ServiceWOCount[]>(
        [],
    )
    const [servicesPerPage, setServicesPerPage] = useState(
        window.innerWidth > 1700 ? 8 : 6,
    )
    const [servicePage, setServicePage] = useState(1)

    const [selectedService, setSelectedService] = useState<
        Service | undefined
    >()
    const [openServiceDropDown, setOpenServiceDropDown] = useState(false)
    const [searchText, setSearchText] = useState('')

    const [openFilter, setOpenFilter] = useState(false)

    const defaultLocation = `${workspaceUser?.active_workspace.address} ${workspaceUser?.active_workspace.city}, ${workspaceUser?.active_workspace.state}`

    const [location, setLocation] = useState(defaultLocation)

    const [selectedLocations, setSelectedLocations] = useState<string[]>([])

    const [tab, setTab] = useState(HOME_PAGE)

    const [hasEZVendor, setHasEZVendor] = useState(false)
    const [openEZVendorModal, setOpenEZVendorModal] = useState(false)

    const [createRFP, setCreateRFP] = useState(false)

    const theme = useTheme()

    const multiSelectClasses = useMultiSelectStyles()
    const classes = useStyles()

    const history = useHistory()

    const { serviceList, getServiceList } = useService()

    const rfpState = rfpApi()

    const {
        createStripeCheckoutSession,
        createStripePortalSession,
    } = useStripe()

    useEffect(() => {
        if (rootUser) {
            const VendorPromise = axiosInstance.get(
                `company/national_vendor_list?has_plan=True`,
            )
            const ServicePromise = getServiceList({})
            const ServiceCountPromise = axiosInstance.get(
                `workorder/get_wo_service_counts/`,
            )
            const PlanPromise = axiosInstance.get(
                `company/stripe_sessions/get_plan_status/`,
            )

            Promise.all([
                VendorPromise,
                ServiceCountPromise,
                ServicePromise,
                PlanPromise,
            ])
                .then((values) => {
                    setVendorsWithPlan(values[0].data)
                    const tempMostUsedServices: ServiceWOCount[] = values[1].data.slice(
                        0,
                        8,
                    )
                    setMostUsedServices(tempMostUsedServices)
                    if (
                        values[3].data === EZVENDOR ||
                        workspaceUser?.active_workspace.has_infrastructure
                    ) {
                        setHasEZVendor(true)
                    }
                })
                .finally(() => setLoading(false))
        } else {
            const VendorPromise = axiosInstance.get(
                `company/national_vendor_list?has_plan=True`,
            )
            const ServiceCountPromise = axiosInstance.get(
                `workorder/get_wo_service_counts/`,
            )

            Promise.all([VendorPromise, ServiceCountPromise])
                .then((values) => {
                    setVendorsWithPlan(values[0].data)
                    const tempMostUsedServices: ServiceWOCount[] = values[1].data.slice(
                        0,
                        8,
                    )
                    setMostUsedServices(tempMostUsedServices)
                })
                .finally(() => setLoading(false))
        }
    }, [tab])

    const menuItemStyle: React.CSSProperties = {
        color: theme.palette.darkGreen.main,
        fontWeight: 500,
        fontSize: 16,
    }

    const ServiceSearch = (
        <Container
            direction="column"
            style={{
                width: '500px',
                backgroundColor: 'white',
                zIndex: 1000,
                alignSelf: 'center',
                overflow: 'auto',
                marginRight: theme.spacing(9),
            }}
        >
            {serviceList
                .filter((service) =>
                    service.name
                        .toLocaleLowerCase()
                        .includes(searchText.toLocaleLowerCase()),
                )
                .map((service) => {
                    return (
                        <MenuItem
                            key={service.id}
                            value={service.name}
                            onClick={() => {
                                setSearchText(service.name)
                                setSelectedService(service)
                            }}
                            style={{
                                padding: theme.spacing(2),
                            }}
                        >
                            {service.name}
                        </MenuItem>
                    )
                })}
        </Container>
    )

    const FilterComponent = (
        <Container
            style={{
                width: '500px',
                backgroundColor: 'white',
                zIndex: 1000,
                alignSelf: 'center',
                overflow: 'auto',
                fontWeight: 500,
                fontSize: 16,
                marginRight: theme.spacing(9),
            }}
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <FormControl className={multiSelectClasses.formControl}>
                <InputLabel id="property-location-label">Location</InputLabel>
                <Select
                    style={{ width: '400px' }}
                    labelId="property-location-label"
                    id="mutiple-chip-property-location"
                    fullWidth
                    multiple
                    value={selectedLocations}
                    MenuProps={{
                        getContentAnchorEl: () => {
                            return (null as unknown) as Element
                        },
                    }}
                    onChange={(
                        event: React.ChangeEvent<{
                            value: unknown
                        }>,
                    ) => {
                        const selectedStates = event.target.value as string[]
                        setSelectedLocations(selectedStates)
                    }}
                    input={
                        <Input id="select-multiple-chip-property-location" />
                    }
                    renderValue={(selected) => (
                        <div className={multiSelectClasses.chips}>
                            {(selected as string[]).map((value) => {
                                const vnd = statesList.find((s) => s === value)
                                return (
                                    <Chip
                                        key={`LOCATION_CHIP_${vnd ? vnd : -1}`}
                                        label={vnd ? vnd : 'unknown'}
                                        className={multiSelectClasses.chip}
                                    />
                                )
                            })}
                        </div>
                    )}
                >
                    {statesList.map((state) => {
                        return (
                            <MenuItem
                                key={`SLOCATION_MENU_ITEM_${state}`}
                                value={state}
                            >
                                {state}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </Container>
    )

    const CreateStripeInteraction = (yearly: boolean = false) => {
        if (hasEZVendor) {
            const body = {
                return_url: window.location.href,
            }
            createStripePortalSession(body)
        } else {
            const body = {
                plan_type: PlanStatus.EZVENDOR,
                success_url: window.location.href,
                yearly: yearly,
            }
            createStripeCheckoutSession(body)
        }
    }

    if (loading) {
        return (
            <Container
                flex={1}
                alignItems="center"
                justifyContent="center"
                style={{ height: '100%' }}
            >
                <img src={LoadingGIF} />
            </Container>
        )
    } else if (createRFP) {
        return (
            <CreateAndEditRFP
                onExit={() => {
                    setCreateRFP(false)
                }}
                serviceList={serviceList}
            />
        )
    } else if (tab === NVL_TAB) {
        return (
            <NationalVendorList
                selectedService={selectedService}
                location={location}
                selectedLocations={selectedLocations}
                setTab={setTab}
                setSelectedLocations={setSelectedLocations}
                setCreateRFP={setCreateRFP}
            />
        )
    }

    return (
        <Container
            flex={1}
            direction="column"
            style={{
                overflow: 'auto',
                minWidth: '700px',
            }}
            onClick={() => {
                setOpenServiceDropDown(false)
                setOpenFilter(false)
            }}
        >
            <Container alignItems="center">
                <img
                    src={EzosLogo}
                    style={{
                        height: 60,
                        padding: theme.spacing(1),
                        margin: theme.spacing(1),
                    }}
                />
                <Container flex={1} />
                {!rootUser ? (
                    <Container>
                        <Button
                            style={{
                                color: theme.palette.primary.dark,
                                textTransform: 'none',
                                borderRadius: theme.shape.borderRadius,
                                fontWeight: theme.typography.fontWeightBold,
                                margin: theme.spacing(2),
                                position: 'relative',
                                backgroundColor: 'transparent',
                            }}
                            onClick={() => history.push(Routes.login)}
                            onMouseEnter={() => {
                                const nvlLine = document.querySelector<HTMLSpanElement>(
                                    '#nvlLine',
                                )
                                if (nvlLine) {
                                    nvlLine.style.width = '80%'
                                }
                            }}
                            onMouseLeave={() => {
                                const nvlLine = document.querySelector<HTMLSpanElement>(
                                    '#nvlLine',
                                )
                                if (nvlLine) {
                                    nvlLine.style.width = '0'
                                }
                            }}
                        >
                            Log-in
                            <span
                                id="nvlLine"
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: 0,
                                    height: '2px',
                                    backgroundColor: theme.palette.primary.dark,
                                    transition: 'width 0.3s ease',
                                }}
                            />
                        </Button>
                        <Button
                            style={{
                                color: 'white',
                                backgroundColor: theme.palette.primary.dark,
                                textTransform: 'none',
                                borderRadius: theme.shape.borderRadius,
                                fontWeight: theme.typography.fontWeightBold,
                                margin: theme.spacing(2),
                                transition:
                                    'background-color 0.3s, color 0.3s, border-color 0.3s',
                                border: `2px solid ${theme.palette.primary.dark}`,
                            }}
                            onClick={() => history.push(Routes.register)}
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = 'white'
                                e.currentTarget.style.color =
                                    theme.palette.primary.dark
                                e.currentTarget.style.borderColor =
                                    theme.palette.primary.dark
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor =
                                    theme.palette.primary.dark
                                e.currentTarget.style.color = 'white'
                            }}
                        >
                            Get Started
                            <ArrowForwardIcon />
                        </Button>
                    </Container>
                ) : (
                    rootUser &&
                    !workspaceUser?.active_workspace.has_infrastructure && (
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: theme.palette.darkBlue.main,
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                                marginRight: theme.spacing(2),
                            }}
                            onClick={() => {
                                if (hasEZVendor) {
                                    CreateStripeInteraction()
                                } else if (rootUser) {
                                    setOpenEZVendorModal(true)
                                } else {
                                    history.push(Routes.login)
                                }
                            }}
                        >
                            {hasEZVendor
                                ? 'Update Subscription'
                                : 'Upgrade To EZVendor'}
                        </Button>
                    )
                )}
            </Container>
            <Container
                direction="column"
                style={{
                    height: '550px',
                    minHeight: '525px',
                    width: '100%',
                    minWidth: '850px',
                    backgroundImage: `url(${Image})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionY: '-80px',
                }}
            >
                <Container
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    direction="column"
                >
                    <Container
                        style={{
                            fontWeight: 600,
                            fontSize: '20px',
                            color: 'white',
                            paddingTop: theme.spacing(2),
                        }}
                    >
                        {`Here to make your search a little  EZ'ier`}
                    </Container>
                    <Container
                        style={{
                            fontWeight: 700,
                            fontSize: '30px',
                            color: 'white',
                            paddingTop: theme.spacing(5),
                        }}
                        flexWrap="wrap"
                    >
                        Find Certified and top-rated vendors in your area
                    </Container>
                </Container>
                <Container
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: theme.spacing(2),
                    }}
                >
                    <TextField
                        variant="outlined"
                        placeholder="What service are you looking for?"
                        value={searchText}
                        onChange={(e) => {
                            setOpenServiceDropDown(true)
                            setSearchText(e.target.value)
                            if (searchText === '') {
                                setSelectedService(undefined)
                            }
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpenFilter(false)
                            setOpenServiceDropDown(true)
                        }}
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon
                                        style={{
                                            color: theme.palette.darkGreen.main,
                                        }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        style={{
                            margin: theme.spacing(1, 0, 2, 0),
                            backgroundColor: 'white',
                            color: theme.palette.darkGreen.main,
                            width: '400px',
                            borderColor: theme.palette.primary.dark,
                        }}
                    />
                    <Button
                        variant="contained"
                        style={{
                            marginBottom: theme.spacing(1),
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                            width: '100px',
                        }}
                        onClick={() => {
                            if (hasEZVendor) {
                                setTab(NVL_TAB)
                            } else if (rootUser) {
                                setOpenEZVendorModal(true)
                            } else {
                                history.push(Routes.login)
                            }
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            margin: theme.spacing(0, 0, 1, 1),
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpenServiceDropDown(false)
                            setOpenFilter(!openFilter)
                        }}
                    >
                        <TuneIcon />
                    </Button>
                </Container>
                {openServiceDropDown && ServiceSearch}

                {openFilter && FilterComponent}
            </Container>
            <Container direction="column">
                <Container
                    style={{
                        padding: theme.spacing(4, 10, 4, 10),
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Container style={{ flexDirection: 'column' }}>
                        <img src={EZVendorLogo1} height={20} width={150} />
                        <Container
                            style={{
                                fontWeight: 600,
                                fontSize: 24,
                            }}
                        >
                            Discover Our Featured Vendors
                        </Container>
                    </Container>
                    <Container flex={1} />
                    <Container>
                        {workspaceUser && hasEZVendor && (
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: theme.palette.primary.dark,
                                    color: 'white',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                    marginRight: theme.spacing(2),
                                }}
                                onClick={() => {
                                    rfpState.setSelectedRFP(null)
                                    setCreateRFP(true)
                                }}
                            >
                                Create RFP
                            </Button>
                        )}
                        {workspaceUser && hasEZVendor && (
                            <Button
                                variant="contained"
                                style={{
                                    marginRight: theme.spacing(2),
                                    backgroundColor: theme.palette.primary.dark,
                                    color: 'white',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                    history.push(Routes.rfpDashboard)
                                }
                            >
                                RFP Dashboard
                            </Button>
                        )}
                        {hasEZVendor && (
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor:
                                        theme.palette.darkBlue.main,
                                    color: 'white',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    if (hasEZVendor) {
                                        setLocation('')
                                        setSelectedService(undefined)
                                        setTab(NVL_TAB)
                                    } else if (rootUser) {
                                        setOpenEZVendorModal(true)
                                    } else {
                                        history.push(Routes.login)
                                    }
                                }}
                            >
                                View All Vendors
                            </Button>
                        )}
                    </Container>
                </Container>

                <Container
                    style={{
                        margin: theme.spacing(0, 10, 4, 10),
                        paddingTop: theme.spacing(2),
                    }}
                    scrollY
                >
                    {vendorsWithPlan
                        .map((vendor) => {
                            return (
                                <FeaturedVendor
                                    key={`FEATURED_VENDOR_${vendor.id}`}
                                    featuredVendor={vendor}
                                />
                            )
                        })
                        .slice(
                            (vendorPage - 1) * vendorsPerPage,
                            (vendorPage - 1) * vendorsPerPage + vendorsPerPage,
                        )}
                </Container>

                <Container style={{ alignSelf: 'center' }}>
                    <Pagination
                        count={Math.ceil(
                            vendorsWithPlan.length / vendorsPerPage,
                        )}
                        page={vendorPage}
                        onChange={(_: unknown, newPage: number) => {
                            setVendorPage(newPage)
                        }}
                        variant="outlined"
                        shape="rounded"
                        style={{ margin: theme.spacing(1, 0, 0, 0) }}
                    />
                </Container>
            </Container>

            <Container
                direction="column"
                style={{ padding: theme.spacing(6, 10, 4, 10) }}
            >
                <Container
                    style={{
                        fontWeight: 600,
                        fontSize: 24,
                    }}
                >
                    Most Scheduled Out Services
                </Container>
                <Container style={{ marginBottom: theme.spacing(4) }} scrollY>
                    {mostUsedServices
                        .map((serviceWoCount) => {
                            return (
                                <ServiceBox
                                    key={`MOST_USED_SERVICE_${serviceWoCount.id}`}
                                    serviceWoCount={serviceWoCount}
                                />
                            )
                        })
                        .slice(
                            (servicePage - 1) * servicesPerPage,
                            (servicePage - 1) * servicesPerPage +
                                servicesPerPage,
                        )}
                </Container>
                <Container style={{ alignSelf: 'center' }}>
                    <Pagination
                        count={Math.ceil(
                            mostUsedServices.length / servicesPerPage,
                        )}
                        page={servicePage}
                        onChange={(_: unknown, newPage: number) => {
                            setServicePage(newPage)
                        }}
                        variant="outlined"
                        shape="rounded"
                        style={{ margin: theme.spacing(1, 0, 0, 0) }}
                    />
                </Container>
            </Container>

            <EZVendorModal
                open={openEZVendorModal}
                handleClose={() => setOpenEZVendorModal(false)}
                createStripeInteraction={CreateStripeInteraction}
            />
        </Container>
    )
}

interface FeaturedVendorProps {
    featuredVendor: NVLVendor
}

const FeaturedVendor = (props: FeaturedVendorProps) => {
    const { featuredVendor } = props

    const theme = useTheme()

    const image = featuredVendor.featured_image
        ? featuredVendor.featured_image
        : Image

    return (
        <Container
            style={{
                height: '300px',
                width: '255px',
                border: `1px solid ${theme.palette.lightGrey.main}`,
                borderRadius: '5px',
                marginRight: theme.spacing(3),
            }}
            direction="column"
        >
            <Badge
                badgeContent={<FeaturedVendorRibbon />}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                style={{
                    zIndex: 100,
                    flexDirection: 'column',
                    marginLeft: theme.spacing(6),
                }}
            />
            <img
                style={{
                    height: '150px',
                    width: '254px',
                    borderTopRightRadius: '5px',
                    borderTopLeftRadius: '5px',
                    zIndex: 2,
                }}
                src={image}
                onError={(event) => {
                    event.currentTarget.src = Image
                }}
            />
            <Container direction="column" style={{ height: '125px' }} scrollY>
                <Container
                    style={{
                        fontWeight: 600,
                        fontSize: 16,
                        margin: theme.spacing(1, 1, 0.5, 1),
                    }}
                >
                    {featuredVendor.name}
                </Container>
                <Container
                    style={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: theme.palette.darkGreen.main,
                        margin: theme.spacing(0.5, 1, 0.5, 1),
                    }}
                    flexWrap="wrap"
                >
                    {featuredVendor.address} {featuredVendor.city},{' '}
                    {featuredVendor.state}
                </Container>
            </Container>
            <Divider />
            <Container style={{ margin: theme.spacing(1) }}>
                {featuredVendor.is_trained && (
                    <Container
                        style={{
                            fontWeight: 600,
                            fontSize: '13px',
                            alignItems: 'center',
                            marginRight: theme.spacing(2),
                        }}
                    >
                        <Tooltip title="EZ trained">
                            <RibbonThumbGreenIcon />
                        </Tooltip>
                    </Container>
                )}

                {featuredVendor.will_travel && (
                    <Container
                        style={{
                            fontWeight: 600,
                            fontSize: '13px',
                            alignItems: 'center',
                            marginRight: theme.spacing(2),
                        }}
                    >
                        <Tooltip title="Will travel">
                            <RibbonAirplaneDarkIcon />
                        </Tooltip>
                    </Container>
                )}

                {featuredVendor.is_skillcat && (
                    <Container
                        style={{
                            alignItems: 'center',
                            marginRight: theme.spacing(2),
                        }}
                    >
                        <Tooltip title="SkillCat">
                            <img src={SkillCatLogoSmall} alt="Skill Cat Logo" />
                        </Tooltip>
                    </Container>
                )}

                {!featuredVendor.will_travel &&
                    !featuredVendor.is_trained &&
                    !featuredVendor.is_skillcat && (
                        <Container>
                            <Container
                                style={{
                                    fontWeight: 500,
                                    fontSize: 16,
                                    marginRight: theme.spacing(1),
                                }}
                            >
                                {featuredVendor.complete_count}
                            </Container>
                            <Container
                                style={{
                                    fontWeight: 400,
                                    fontSize: 16,
                                    color: theme.palette.darkGreen.main,
                                }}
                            >
                                Work Orders Completed
                            </Container>
                        </Container>
                    )}
            </Container>
        </Container>
    )
}

interface ServiceBoxProps {
    serviceWoCount: ServiceWOCount
}

const ServiceBox = (props: ServiceBoxProps) => {
    const { serviceWoCount } = props

    const theme = useTheme()

    const icon = getServiceImageDetails(serviceWoCount.id)

    return (
        <Container
            style={{
                height: '260px',
                width: '200px',
                border: `1px solid ${theme.palette.lightGrey.main}`,
                borderRadius: '5px',
                marginRight: theme.spacing(3),
                marginTop: theme.spacing(2),
            }}
            direction="column"
        >
            <Container>
                <img
                    style={{
                        height: '200px',
                        width: '200px',
                        borderTopRightRadius: '5px',
                        borderTopLeftRadius: '5px',
                        zIndex: 2,
                    }}
                    src={icon.src}
                />
            </Container>

            <Container
                style={{
                    margin: theme.spacing(2),
                }}
            >
                <Container
                    style={{
                        fontWeight: 500,
                        fontSize: 20,
                    }}
                >
                    {serviceWoCount.name}
                </Container>
                <Container flex={1} />
                <Container
                    style={{
                        color: theme.palette.darkGreen.main,
                        fontWeight: 400,
                        fontSize: 16,
                    }}
                >
                    {`(${Math.round(serviceWoCount.count / 1000)}K)`}
                </Container>
            </Container>
        </Container>
    )
}
