import React, { useContext, useEffect, useState } from 'react'
import { Container, Selector } from '../../components'
import { Button, Chip, TableSortLabel, useTheme } from '@material-ui/core'
import {
    IdentifiableNamedObject,
    IncidentReport,
    IncidentSeverity,
    IncidentStatus,
    IncidentStatusNumbers,
    TenantNegligibleFilter,
    getIncidentReoprtStatusColor,
    getIncidentStatusFromNumber,
    getUiString,
} from '../../models'
import { useStyles } from '../../styles/useStyles'
import { IncidentReportModal } from './IncidentReportModal'
import { toMMDDYYYY } from '../../helpers'
import {
    IncidentReportFilterType,
    IncidentReportFilters,
} from './IncidentReportFilters'
import { Pagination } from '@material-ui/lab'
import { IncidentReportCreateForm } from './IncidentReportCreateModal'
import { _useFinderSelection, useRootInfrastructure } from '../../hooks'
import { FinderLocationSelection, FinderSelectionMode } from '../../store'
import { IncidentReportContext } from './IncidentReportRoot'

interface Props {
    onIncidentSelect: () => void
    portfolio?: boolean
    incidentReportFilters: IncidentReportFilterType
    setFilters: (filters: IncidentReportFilterType) => void
}

const PAGE_SIZE = 10

export const IncidentReportTable = (props: Props) => {
    const theme = useTheme()

    const {
        incidentReports,
        getIncidentReports,
        setIncidentReports,
        setSelectedIncidentReport,
        selectedIncidentReport,
        updateIncidentReport,
        incidentTypes,
        getIncidentTypes,
        organization,
        getOrganization,
    } = useContext(IncidentReportContext)

    const [orderBy, setOrderBy] = useState('')
    const [createModalOpen, setCreateModalOpen] = useState(false)

    const [page, setPage] = useState(1)

    const filteredRows = incidentReports.reduce<IncidentReport[]>(
        (prev, report) => {
            if (props.incidentReportFilters.incidentType !== -1) {
                if (
                    props.incidentReportFilters.incidentType !==
                    report.incident_type.id
                ) {
                    return prev
                }
            }

            if (props.incidentReportFilters.status !== -1) {
                let value = IncidentStatus.PENDING
                if (
                    props.incidentReportFilters.status ===
                    IncidentStatusNumbers.REVIEWED
                ) {
                    value = IncidentStatus.REVIEWED
                }
                if (
                    props.incidentReportFilters.status ===
                    IncidentStatusNumbers.SUBMITTED
                ) {
                    value = IncidentStatus.SUBMITTED
                }
                if (
                    props.incidentReportFilters.status ===
                    IncidentStatusNumbers.NOT_SUBMITTED
                ) {
                    value = IncidentStatus.NOT_SUBMITTED
                }
                if (value !== report.status) {
                    return prev
                }
            }

            if (
                props.incidentReportFilters.tenantNegligible !==
                TenantNegligibleFilter.NO_FILTER
            ) {
                if (
                    props.incidentReportFilters.tenantNegligible ===
                        TenantNegligibleFilter.YES &&
                    !report.tenant_negligible
                ) {
                    return prev
                }
                if (
                    props.incidentReportFilters.tenantNegligible ===
                        TenantNegligibleFilter.NO &&
                    report.tenant_negligible
                ) {
                    return prev
                }
            }
            return prev.concat(report)
        },
        [],
    )

    const { tree } = useRootInfrastructure(true)
    const locationFinderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Single,
    })

    useEffect(() => {
        getIncidentReports()
        getIncidentTypes()
        getOrganization()
    }, [])

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.body1,
        fontWeight: theme.typography.fontWeightBold,
        flex: 1,
        margin: theme.spacing(1),
    }

    const SeverityASC = 'SeverityASC'
    const SeverityDESC = 'SeverityDESC'
    const LocationASC = 'LocationASC'
    const LocationDESC = 'LocationDESC'
    const TypeASC = 'TypeASC'
    const TypeDESC = 'TypeDESC'
    const ReportedDateASC = 'ReportedDateASC'
    const ReportedDateDESC = 'ReportedDateDESC'
    const IncidentDateASC = 'IncidentDateASC'
    const IncidentDateDESC = 'IncidentDateDESC'
    const StatusASC = 'StatusASC'
    const StatusDESC = 'StatusDESC'

    return (
        <Container
            style={{
                flexDirection: 'column',
                overflowY: 'scroll',
                height: props.portfolio ? 'calc(80vh - 120px)' : undefined,
            }}
        >
            <Container>
                {props.incidentReportFilters.incidentType !== -1 && (
                    <Chip
                        clickable
                        label={
                            incidentTypes.find(
                                (type) =>
                                    type.id ===
                                    props.incidentReportFilters.incidentType,
                            )?.name
                        }
                        style={{
                            margin: theme.spacing(1),
                            fontWeight: theme.typography.fontWeightBold,
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                        }}
                        onClick={() => {
                            props.setFilters({
                                ...props.incidentReportFilters,
                                incidentType: -1,
                            })
                        }}
                    />
                )}
                {props.incidentReportFilters.status !== -1 && (
                    <Chip
                        clickable
                        label={getUiString(
                            getIncidentStatusFromNumber(
                                props.incidentReportFilters.status,
                            ),
                        )}
                        style={{
                            margin: theme.spacing(1),
                            fontWeight: theme.typography.fontWeightBold,
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                        }}
                        onClick={() => {
                            props.setFilters({
                                ...props.incidentReportFilters,
                                status: -1,
                            })
                        }}
                    />
                )}

                {props.incidentReportFilters.tenantNegligible !==
                    TenantNegligibleFilter.NO_FILTER && (
                    <Chip
                        clickable
                        label={
                            props.incidentReportFilters.tenantNegligible ===
                            TenantNegligibleFilter.YES
                                ? 'Tenant Negligible'
                                : 'Tenant Not Negligible'
                        }
                        style={{
                            margin: theme.spacing(1),
                            fontWeight: theme.typography.fontWeightBold,
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                        }}
                        onClick={() => {
                            props.setFilters({
                                ...props.incidentReportFilters,
                                tenantNegligible:
                                    TenantNegligibleFilter.NO_FILTER,
                            })
                        }}
                    />
                )}
                <div style={{ flex: 1 }} />
                <Container
                    style={{
                        minHeight: 20,
                        justifyContent: 'flex-end',
                        marginTop: theme.spacing(1),
                    }}
                >
                    <Button
                        onClick={() => {
                            setCreateModalOpen(true)
                        }}
                        style={{
                            marginRight: theme.spacing(1),
                            backgroundColor: theme.palette.primary.main,
                            color: 'white',
                        }}
                    >
                        + Incident Report
                    </Button>
                    <span
                        style={{
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.lightGrey.main,
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '13px',
                            paddingTop: theme.spacing(1),
                        }}
                    >
                        {incidentReports.length ?? 0} Rows
                    </span>
                    <Pagination
                        count={Math.ceil(incidentReports.length / PAGE_SIZE)}
                        page={page}
                        onChange={(_: unknown, newPage: number) => {
                            setPage(newPage)
                        }}
                        variant="outlined"
                        shape="rounded"
                        style={{ margin: theme.spacing(1, 0, 0, 0) }}
                    />
                </Container>
            </Container>
            <Container style={{}}>
                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        const newRows = [...incidentReports]
                        if (orderBy !== SeverityASC) {
                            newRows.sort((RowA, RowB) =>
                                RowA.severity.localeCompare(RowB.severity),
                            )
                            setIncidentReports(newRows)
                            setOrderBy(SeverityASC)
                        } else {
                            setIncidentReports(newRows.reverse())
                            setOrderBy(SeverityDESC)
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === SeverityASC || orderBy === SeverityDESC
                        }
                        direction={orderBy === SeverityASC ? 'asc' : 'desc'}
                    >
                        Severity
                    </TableSortLabel>
                </Container>

                <Container
                    style={{
                        ...headCellStyle,
                    }}
                    onClick={() => {
                        const newRows = [...incidentReports]
                        if (orderBy !== LocationASC) {
                            newRows.sort((RowA, RowB) => {
                                if (props.portfolio) {
                                    return RowA.apartment.name.localeCompare(
                                        RowB.apartment.name,
                                    )
                                }
                                const rowALocation = getLocation(RowA)
                                const rowBLocation = getLocation(RowB)
                                return rowALocation.localeCompare(rowBLocation)
                            })
                            setIncidentReports(newRows)
                            setOrderBy(LocationASC)
                        } else {
                            setIncidentReports(newRows.reverse())
                            setOrderBy(LocationDESC)
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === LocationASC || orderBy === LocationDESC
                        }
                        direction={orderBy === LocationASC ? 'asc' : 'desc'}
                    >
                        {props.portfolio ? 'Property' : 'Location'}
                    </TableSortLabel>
                </Container>

                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        const newRows = [...incidentReports]
                        if (orderBy !== TypeASC) {
                            newRows.sort((RowA, RowB) =>
                                RowA.incident_type.name.localeCompare(
                                    RowB.incident_type.name,
                                ),
                            )
                            setIncidentReports(newRows)
                            setOrderBy(TypeASC)
                        } else {
                            setIncidentReports(newRows.reverse())
                            setOrderBy(TypeDESC)
                        }
                    }}
                >
                    <TableSortLabel
                        active={orderBy === TypeASC || orderBy === TypeDESC}
                        direction={orderBy === TypeASC ? 'asc' : 'desc'}
                    >
                        Incident Type
                    </TableSortLabel>
                </Container>

                <Container style={{ ...headCellStyle }}>Photo</Container>

                <Container
                    style={{ ...headCellStyle }}
                    onClick={() => {
                        const newRows = [...incidentReports]
                        if (orderBy !== ReportedDateASC) {
                            newRows.sort((RowA, RowB) => {
                                const aDate = new Date(RowA.created_date)
                                const bDate = new Date(RowB.created_date)
                                return aDate.getTime() - bDate.getTime()
                            })
                            setIncidentReports(newRows)
                            setOrderBy(ReportedDateASC)
                        } else {
                            setIncidentReports(newRows.reverse())
                            setOrderBy(ReportedDateDESC)
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === ReportedDateASC ||
                            orderBy === ReportedDateDESC
                        }
                        direction={orderBy === ReportedDateASC ? 'asc' : 'desc'}
                    >
                        Reported Date
                    </TableSortLabel>
                </Container>

                <Container
                    style={{
                        ...headCellStyle,
                    }}
                    onClick={() => {
                        const newRows = [...incidentReports]
                        if (orderBy !== IncidentDateASC) {
                            newRows.sort((RowA, RowB) => {
                                const aDate = new Date(RowA.date)
                                const bDate = new Date(RowB.date)
                                return aDate.getTime() - bDate.getTime()
                            })
                            setIncidentReports(newRows)
                            setOrderBy(IncidentDateASC)
                        } else {
                            setIncidentReports(newRows.reverse())
                            setOrderBy(IncidentDateDESC)
                        }
                    }}
                >
                    <TableSortLabel
                        active={
                            orderBy === IncidentDateASC ||
                            orderBy === IncidentDateDESC
                        }
                        direction={orderBy === IncidentDateASC ? 'asc' : 'desc'}
                    >
                        Incident Date
                    </TableSortLabel>
                </Container>

                <Container
                    style={{
                        ...headCellStyle,
                        justifyContent: 'center',
                        flex: 1,
                    }}
                    onClick={() => {
                        const newRows = [...incidentReports]
                        if (orderBy !== StatusASC) {
                            newRows.sort((RowA, RowB) =>
                                RowA.status.localeCompare(RowB.status),
                            )
                            setIncidentReports(newRows)
                            setOrderBy(StatusASC)
                        } else {
                            setIncidentReports(newRows.reverse())
                            setOrderBy(StatusDESC)
                        }
                    }}
                >
                    <TableSortLabel
                        active={orderBy === StatusASC || orderBy === StatusDESC}
                        direction={orderBy === StatusASC ? 'asc' : 'desc'}
                    >
                        Status
                    </TableSortLabel>
                </Container>
            </Container>
            {filteredRows
                .map((incidentReport) => {
                    return (
                        <IncidentReportRow
                            onIncidentSelect={() => {
                                setSelectedIncidentReport(incidentReport)
                            }}
                            portfolio={props.portfolio}
                            key={`INCIDIENT_REPORT_ROW_${incidentReport.id}`}
                            incidentReport={incidentReport}
                        />
                    )
                })
                .slice(
                    (page - 1) * PAGE_SIZE,
                    (page - 1) * PAGE_SIZE + PAGE_SIZE,
                )}

            <IncidentReportModal
                open={selectedIncidentReport !== undefined}
                onClose={() => {
                    setSelectedIncidentReport(undefined)
                }}
                incidentReport={selectedIncidentReport}
            />
            <IncidentReportCreateForm
                open={createModalOpen}
                onClose={() => {
                    setCreateModalOpen(false)
                }}
                addNewIncidentReport={(newIncident) => {
                    setIncidentReports([...incidentReports, newIncident])
                }}
                tree={tree}
                locationFinderSelection={locationFinderSelection}
            />
        </Container>
    )
}

interface IncidentReportRowProps {
    onIncidentSelect: () => void
    portfolio?: boolean
    incidentReport: IncidentReport
}

const IncidentReportRow = (props: IncidentReportRowProps) => {
    const { incidentReport, portfolio, onIncidentSelect } = props
    const theme = useTheme()
    const [pendingOrResolved, setPendingOrResolved] = useState(
        incidentReport.status === 'Pending' ? 0 : 1,
    )

    const classes = useStyles()
    let src = ''
    if (incidentReport.images.length > 0) {
        src = incidentReport.images[0].file
    }

    let severityColor = theme.palette.error.main

    if (incidentReport.severity === IncidentSeverity.LOW) {
        severityColor = theme.palette.primary.main
    }
    if (incidentReport.severity === IncidentSeverity.MID) {
        severityColor = theme.palette.warning.main
    }

    const location = getLocation(incidentReport)

    return (
        <Container
            style={{
                flex: 1,
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: '5px',
                margin: theme.spacing(3, 0, 3, 0),
                cursor: 'pointer',
                maxHeight: 150,
                justifyContent: 'space-between',
            }}
            onClick={props.onIncidentSelect}
        >
            <Container
                style={{
                    margin: theme.spacing(2),
                    alignItems: 'center',
                    flex: 1,
                }}
            >
                <span
                    style={{
                        border: `2px solid ${severityColor}`,
                        padding: theme.spacing(1),
                        borderRadius: theme.shape.borderRadius,
                        color: severityColor,
                        fontWeight: 'bold',
                    }}
                >
                    {incidentReport.severity === '1'
                        ? 'High'
                        : incidentReport.severity[0] +
                          incidentReport.severity
                              .substring(1)
                              .toLocaleLowerCase()}
                </span>
            </Container>

            <Container
                style={{
                    fontWeight: 600,
                    fontSize: 20,
                    marginBottom: theme.spacing(1),
                    alignItems: 'center',

                    flex: 1,
                }}
            >
                {portfolio ? incidentReport.apartment.name : location}
            </Container>

            <Container
                style={{
                    margin: theme.spacing(2),
                    alignItems: 'center',
                    flex: 1,
                }}
            >
                <span
                    style={{
                        border: `2px solid ${theme.palette.primary.main}`,
                        padding: theme.spacing(1),
                        borderRadius: theme.shape.borderRadius,
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                    }}
                >
                    {incidentReport.incident_type.name}
                </span>
            </Container>

            <Container
                style={{
                    fontWeight: 500,
                    fontSize: 16,
                    alignItems: 'center',
                    margin: theme.spacing(0, 2, 0, 2),
                    flex: 1,
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    if (src !== '') {
                        window.open(src)
                    }
                }}
            >
                {src !== '' && (
                    <img
                        src={src}
                        style={{
                            height: '100%',
                            width: '100%',
                            borderRadius: theme.shape.borderRadius,
                        }}
                        className={classes.box60}
                    />
                )}
            </Container>

            <Container
                style={{
                    fontWeight: 500,
                    fontSize: 16,
                    alignItems: 'center',
                    margin: theme.spacing(0, 2, 0, 2),
                    flex: 1,
                }}
            >
                {toMMDDYYYY(new Date(incidentReport.created_date))}
            </Container>

            <Container
                style={{
                    alignItems: 'center',
                    fontWeight: 500,
                    fontSize: 16,
                    margin: theme.spacing(0, 2, 0, 2),
                    flex: 1,
                }}
            >
                {toMMDDYYYY(new Date(incidentReport.date))}
            </Container>

            <Container
                style={{
                    alignItems: 'center',
                    fontWeight: 500,
                    fontSize: 16,
                    marginRight: theme.spacing(1),
                    flex: 1,
                }}
            >
                <span
                    style={{
                        border: `2px solid ${getIncidentReoprtStatusColor(
                            incidentReport.status,
                            theme,
                        )}`,
                        padding: theme.spacing(1),
                        borderRadius: theme.shape.borderRadius,
                        color: getIncidentReoprtStatusColor(
                            incidentReport.status,
                            theme,
                        ),
                        fontWeight: 'bold',
                        marginRight: theme.spacing(1),
                        height: 40,
                    }}
                >
                    {getUiString(incidentReport.status)}
                </span>
            </Container>
        </Container>
    )
}

export const getLocation = (incidentReport?: IncidentReport) => {
    if (incidentReport === undefined) {
        return ''
    }
    let location = incidentReport.folder?.name ?? ''
    if (incidentReport.unit) {
        location += `/${incidentReport.unit.name}`
    }
    if (incidentReport.area) {
        location += ` ${incidentReport.area.area_config_name} ${incidentReport.area.area_label}`
    }
    return location
}
