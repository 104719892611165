import {
    ADD_MEMBER_REQUEST,
    ADD_MEMBER_RESPONSE,
    CLEAR_CHANNEL_UNREAD,
    CREATE_CHANNEL_REQUEST,
    CREATE_CHANNEL_RESPONSE,
    CREATE_MESSAGE_REQUEST,
    CREATE_MESSAGE_RESPONSE,
    GET_CHANNEL_DETAIL_REQUEST,
    GET_CHANNEL_DETAIL_RESPONSE,
    GET_CHANNEL_LIST_REQUEST,
    GET_CHANNEL_LIST_RESPONSE,
    GET_MESSAGE_LIST_REQUEST,
    GET_MESSAGE_LIST_RESPONSE,
    MessagingActionTypes,
    MessagingState,
    REMOVE_MEMBER_REQUEST,
    REMOVE_MEMBER_RESPONSE,
    RESET_MESSAGE_STATE,
    SET_CURRENT_CHANNEL,
    SET_MESSAGING_USER_OPTION_LIST,
    SET_MESSAGING_LOADING,
} from './types'

const initialState: MessagingState = {
    channelList: [],
    messages: [],
    isLoading: {
        GET_CHANNEL_LIST_REQUEST: false,
        GET_CHANNEL_DETAIL_REQUEST: false,
        CREATE_CHANNEL_REQUEST: false,
        ADD_MEMBER_REQUEST: false,
        REMOVE_MEMBER_REQUEST: false,
        GET_MESSAGE_LIST_REQUEST: false,
        CREATE_MESSAGE_REQUEST: false,
        GET_MESSAGING_USER_OPTION_LIST: false,
    },
}

export const messagingReducer = (
    state = initialState,
    action: MessagingActionTypes,
): MessagingState => {
    switch (action.type) {
        case CREATE_CHANNEL_REQUEST:
        case ADD_MEMBER_REQUEST:
        case REMOVE_MEMBER_REQUEST:
        case GET_CHANNEL_DETAIL_REQUEST:
        case GET_CHANNEL_LIST_REQUEST:
        case GET_MESSAGE_LIST_REQUEST:
        case CREATE_MESSAGE_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.type.valueOf()]: true,
                },
            }
        case SET_MESSAGING_LOADING:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.request]: action.loading,
                },
            }
        case SET_MESSAGING_USER_OPTION_LIST:
            return {
                ...state,
                messagingUserOptionList: action.messagingUserOptionList,
            }
        case GET_CHANNEL_LIST_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_CHANNEL_LIST_REQUEST: false,
                },
                channelList: action.payload,
            }

        case GET_CHANNEL_DETAIL_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_CHANNEL_DETAIL_REQUEST: false,
                },
                currentChannel: action.payload,
                channelList: state.channelList.map((channel) => {
                    if (channel.id === action.payload.id) {
                        return {
                            id: channel.id,
                            name: action.payload.name,
                            unread_count: channel.unread_count,
                            pair: action.payload.pair,
                            pair_user: action.payload.pair_user,
                        }
                    }
                    return {
                        id: channel.id,
                        name: channel.name,
                        unread_count: channel.unread_count,
                        pair: channel.pair,
                        pair_user: channel.pair_user,
                    }
                }),
            }
        case CREATE_CHANNEL_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    CREATE_CHANNEL_REQUEST: false,
                },
                currentChannel: action.payload,
                channelList: [
                    ...state.channelList,
                    {
                        id: action.payload.id,
                        name: action.payload.name,
                        unread_count: 0,
                        pair: action.payload.pair,
                        pair_user: action.payload.pair_user,
                    },
                ],
            }
        case REMOVE_MEMBER_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    REMOVE_MEMBER_REQUEST: false,
                },
                currentChannel: action.payload,
            }
        case ADD_MEMBER_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    ADD_MEMBER_REQUEST: false,
                },
                currentChannel: action.payload,
            }
        case GET_MESSAGE_LIST_RESPONSE:
            //set the message list and also set the unread count for the channel they belong to to 0
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_MESSAGE_LIST_REQUEST: false,
                },
                messages: action.payload,
                channelList: state.channelList.map((channel) => {
                    if (
                        action.payload.length > 0 &&
                        channel.id === action.payload[0].channel
                    ) {
                        return {
                            ...channel,
                            unread_count: 0,
                        }
                    }
                    return channel
                }),
            }
        case CREATE_MESSAGE_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    CREATE_MESSAGE_REQUEST: false,
                },
                messages: [...state.messages, action.payload],
            }
        case CLEAR_CHANNEL_UNREAD:
            return {
                ...state,
                channelList: state.channelList.map((c) => {
                    if (c.id === action.channelId) {
                        return {
                            id: c.id,
                            name: c.name,
                            unread_count: 0,
                            pair: c.pair,
                            pair_user: c.pair_user,
                        }
                    }
                    return c
                }),
            }

        case SET_CURRENT_CHANNEL:
            return {
                ...state,
                currentChannel: action.channel,
                isLoading: {
                    ...state.isLoading,
                    [action.endLoading ?? -1]: false,
                },
            }

        case RESET_MESSAGE_STATE:
            return {
                ...state,
                messages: [],
            }

        default:
            return state
    }
}
