import React, { PropsWithChildren, useState } from 'react'

interface Props {
    onDrop: React.DragEventHandler<HTMLDivElement>
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    onDrop: React.DragEventHandler<HTMLDivElement>
    dragOverStyle?: React.CSSProperties
}

export const Droppable = (props: PropsWithChildren<Props>) => {
    const [isDragOver, setIsDragOver] = useState(false)

    let droppableStyle = { ...props.style }
    if (isDragOver) {
        droppableStyle = { ...droppableStyle, ...props.dragOverStyle }
    }

    return (
        <div
            {...props}
            style={droppableStyle}
            onDragOver={(e) => {
                e.preventDefault()
                setIsDragOver(true)
            }}
            onDragEnter={(e) => {
                e.preventDefault()
            }}
            onDragLeave={(e) => {
                setIsDragOver(false)
            }}
            onDrop={(e) => {
                setIsDragOver(false)
                props.onDrop(e)
            }}
        >
            {props.children}
        </div>
    )
}
