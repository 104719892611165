import { AxiosResponse } from 'axios'
import { Action } from 'redux'
import { Permissible, PermissionRole } from '../../models'

export interface GetPermissionRoleListRequest {}

export interface PermissionRoleRequestBody extends Permissible {
    name: string
    organization: number
}

export interface AddPermissionRoleRequest {
    body: PermissionRoleRequestBody
}

export interface UpdatePermissionRoleRequest {
    id: number
    body: PermissionRoleRequestBody
}

export const GET_PERMISSION_ROLE_LIST_REQUEST =
    'GET_PERMISSION_ROLE_LIST_REQUEST'
export const ADD_PERMISSION_ROLE_REQUEST = 'ADD_PERMISSION_ROLE_REQUEST'
export const EDIT_PERMISSION_ROLE_REQUEST = 'EDIT_PERMISSION_ROLE_REQUEST'

export const SET_PERMISSION_ROLE_LIST = 'SET_PERMISSION_ROLE_LIST'
export const ADD_PERMISSION_ROLE = 'ADD_PERMISSION_ROLE'
export const UPDATE_PERMISSION_ROLE = 'UPDATE_PERMISSION_ROLE'
export const SET_PERMISSION_LOADING = 'SET_PERMISSION_LOADING'

export type PermissionRequest =
    | typeof GET_PERMISSION_ROLE_LIST_REQUEST
    | typeof ADD_PERMISSION_ROLE_REQUEST
    | typeof EDIT_PERMISSION_ROLE_REQUEST

interface SetPermissionLoadingAction extends Action {
    type: typeof SET_PERMISSION_LOADING
    request: PermissionRequest
    loading: boolean
}

interface SetPermissionRoleListAction extends Action {
    type: typeof SET_PERMISSION_ROLE_LIST
    permissionRoles?: PermissionRole[]
}

interface AddPermissionRoleAction extends Action {
    type: typeof ADD_PERMISSION_ROLE
    permissionRole: PermissionRole
}

interface UpdatePermissionRoleAction extends Action {
    type: typeof UPDATE_PERMISSION_ROLE
    permissionRole: PermissionRole
}

export type PermissionActionTypes =
    | SetPermissionLoadingAction
    | SetPermissionRoleListAction
    | AddPermissionRoleAction
    | UpdatePermissionRoleAction

export type PermissionRoleListThunk = Promise<AxiosResponse<PermissionRole[]>>
export type PermissionRoleThunk = Promise<AxiosResponse<PermissionRole>>

// the name 'PermissionState' already means something to ts so this name makes it easier to import this type
export interface PermissionReduxState {
    permissionRoleList?: PermissionRole[]
    isLoading: {
        [GET_PERMISSION_ROLE_LIST_REQUEST]: boolean
        [ADD_PERMISSION_ROLE_REQUEST]: boolean
        [EDIT_PERMISSION_ROLE_REQUEST]: boolean
    }
}
