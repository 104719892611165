import { TableCell, TableRow, Tooltip, useTheme } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
    AvatarCircle,
    CellData,
    Container,
    HeadCell,
    RenderRow,
    RowData,
    SideDrawerContainer,
    SimpleTable,
} from '../../components'
import { useAppDispatch } from '../../hooks'
import { InventoryConfig } from '../../models'
import {
    getInventoryConfigList,
    getStatusGroupList,
    RootState,
} from '../../store'
import { useStyles } from '../../styles'
import { InventoryConfigDrawer } from './InventoryConfigDrawer'

export const InventoryConfigHome = () => {
    const theme = useTheme()
    const classes = useStyles()
    const dispatch = useAppDispatch()
    const inventoryConfigList = useSelector(
        (state: RootState) => state.aptConfig.inventoryConfigList,
    )

    const [drawerOpen, setDrawerOpen] = useState(false)
    const [selectedConfig, setSelectedConfig] = useState<InventoryConfig>()

    useEffect(() => {
        dispatch(getInventoryConfigList({}))
        dispatch(getStatusGroupList({}))
    }, [])
    //table setup
    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    interface InventoryConfigRowType extends RowData {
        name: CellData<string>
        sku: CellData<string>
        description: CellData<string>
        picture: CellData<JSX.Element>
        invConf: CellData<InventoryConfig>
    }

    const headCells: HeadCell<InventoryConfigRowType>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'picture',
            label: 'Picture',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'name',
            label: 'Name',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'sku',
            label: 'Sku',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'center',
            id: 'description',
            label: 'Description',
            style: headCellStyle,
        },
    ]

    const createRow = (invConf: InventoryConfig): InventoryConfigRowType => {
        const picture = (
            <AvatarCircle image={invConf.file} text={invConf.name} initials />
        )
        return {
            picture: { value: picture, sortValue: invConf.file ?? -1 },
            name: { value: invConf.name, sortValue: invConf.name },
            sku: { value: invConf.sku, sortValue: invConf.sku },
            description: {
                value: invConf.description,
                sortValue: invConf.description,
            },
            invConf: { value: invConf, sortValue: 1 },
        }
    }

    interface InvConfRowProps {
        row: InventoryConfigRowType
    }

    const InvConfRow: RenderRow<InvConfRowProps> = (props) => {
        const { row } = props
        const cellStyle: React.CSSProperties = {
            ...theme.typography.body2,
            fontWeight: theme.typography.fontWeightRegular,
        }

        return (
            <TableRow
                key={`INV_CONF_ROW_${row.invConf.value.id}`}
                style={{
                    cursor: 'pointer',
                    backgroundColor:
                        selectedConfig?.id === row.invConf.value.id
                            ? theme.palette.grey[300]
                            : undefined,
                }}
                className={classes.hoverGrey300}
                onClick={() => {
                    setSelectedConfig(row.invConf.value)
                    setDrawerOpen(true)
                }}
            >
                <TableCell style={cellStyle}>{row.picture.value}</TableCell>
                <TableCell style={cellStyle}>{row.name.value}</TableCell>
                <TableCell align="center" style={cellStyle}>
                    {row.sku.value}
                </TableCell>
                <TableCell align="center" style={cellStyle}>
                    {row.description.value}
                </TableCell>
            </TableRow>
        )
    }

    return (
        <SideDrawerContainer open={drawerOpen}>
            <Container
                direction={'column'}
                flex={1}
                style={{ margin: theme.spacing(2) }}
            >
                {/* Header */}
                <Container>
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            marginBottom: theme.spacing(1),
                            flex: 1,
                        }}
                    >
                        Inventory Config
                    </span>
                    <Tooltip title="New Inventory Config">
                        <Add
                            fontSize="large"
                            onClick={() => {
                                setSelectedConfig(undefined)
                                setDrawerOpen(true)
                            }}
                            color="primary"
                            className={classes.highlightCircleOnHover}
                        />
                    </Tooltip>
                </Container>

                {/* table */}
                <SimpleTable<InventoryConfigRowType>
                    rows={
                        inventoryConfigList?.map((invConf: InventoryConfig) =>
                            createRow(invConf),
                        ) ?? []
                    }
                    render={(row) => <InvConfRow row={row} />}
                    orderByDefault={'name'}
                    headCells={headCells}
                />
            </Container>
            <InventoryConfigDrawer
                inventoryConfig={selectedConfig}
                drawerOpen={drawerOpen}
                handleClose={() => setDrawerOpen(false)}
            />
        </SideDrawerContainer>
    )
}
