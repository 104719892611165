import React from 'react'
import { AvatarCircle, Container, Attachment } from '..'
import { Message as MessageType } from '../../models'
import { Tooltip, useTheme } from '@material-ui/core'
import { getMessageTime } from '../../models/Messaging/services'
import InfoIcon from '@material-ui/icons/Info'

interface IMessageProps {
    message: MessageType
    preview?: boolean
    attachmentSize?: number
    isSecondary?: boolean
    secondaryTitle?: string
}

export const Message: React.FC<IMessageProps> = ({
    message,
    preview,
    attachmentSize,
    isSecondary,
    secondaryTitle,
}) => {
    const theme = useTheme()

    const attachments = message.attachments
        ? message.attachments.map((a, idx) => (
              <Attachment
                  key={`ATTACHMENT_${idx}`}
                  attachment={a.file}
                  theme={theme}
                  size={attachmentSize}
                  uploaded={a.uploaded}
              />
          ))
        : undefined

    //for styling the message box
    const messageStyle: React.CSSProperties = {
        margin: theme.spacing(0, 1, 1, 1),
        padding: theme.spacing(0.5),
        backgroundColor: theme.palette.grey[200],
        boxShadow: theme.shadows[1],
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    }

    if (preview) {
        messageStyle.maxHeight = 100
        messageStyle.overflow = 'hidden'
    }

    const displayDate = getMessageTime(new Date(message.created_date))
    return (
        <Container style={messageStyle}>
            <AvatarCircle
                image={message.sender_profile_picture}
                styles={{ container: { alignSelf: 'flex-start' } }}
                text={message.sender_name}
                initials
            />

            <Container
                direction="column"
                alignItems="flex-start"
                style={{ marginLeft: theme.spacing(1) }}
            >
                <Container style={{ marginRight: theme.spacing(2) }}>
                    <span
                        style={{
                            marginRight: theme.spacing(1),
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {message.sender_name}
                    </span>
                    <span
                        style={{
                            ...theme.typography.caption,
                            marginTop: theme.spacing(0.25),
                        }}
                    >
                        {displayDate}
                    </span>
                </Container>
                <Container
                    style={{ marginRight: theme.spacing(1), flexWrap: 'wrap' }}
                >
                    {attachments}
                </Container>
                <Container
                    style={{ alignSelf: 'flex-start', textAlign: 'left' }}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: message.content,
                        }}
                        style={{ ...theme.typography.subtitle1 }}
                    />
                </Container>
            </Container>

            <Container style={{ flex: 1 }} />

            {isSecondary && (
                <Tooltip
                    title={
                        secondaryTitle ? secondaryTitle : 'From linked channel'
                    }
                >
                    <InfoIcon
                        style={{
                            alignSelf: 'flex-start',
                            justifySelf: 'flex-end',
                            color: theme.palette.primary.dark,
                        }}
                    />
                </Tooltip>
            )}
        </Container>
    )
}
