import React from 'react'
import { Button, Popover } from '@material-ui/core'
import { BaseProps } from '../../types'
import { PopoverState } from '../../../../hooks/usePopover'
import { Container } from '../../../Container'
import { toMMDDYYYY } from '../../../../helpers'

interface Props extends BaseProps {
    popoverState: PopoverState<any>
    selectedDate: Date
    handleChooseTransferDate: (clickX: number) => void
}

export const HeaderPopover = (props: Props) => {
    const { theme, popoverState, selectedDate } = props

    const buttonStyle: React.CSSProperties = {
        textTransform: 'none',
        marginBottom: theme.spacing(1),
        width: 175,
    }

    return (
        <Popover
            open={popoverState.isOpen}
            anchorEl={popoverState.anchorEl}
            onClose={popoverState.handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    padding: theme.spacing(1),
                }}
            >
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    {toMMDDYYYY(selectedDate)}
                </span>

                <Container>
                    {/*  */}
                    <Container
                        style={{
                            flexDirection: 'column',
                            marginRight: theme.spacing(1),
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                const anchorEl = popoverState.anchorEl
                                if (anchorEl) {
                                    props.handleChooseTransferDate(
                                        anchorEl.getBoundingClientRect().x,
                                    )
                                }

                                popoverState.handleClose()
                            }}
                            style={{ ...buttonStyle }}
                        >
                            Set Transfer Date
                        </Button>

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                props.handleChooseTransferDate(-1)
                                popoverState.handleClose()
                            }}
                            style={{ ...buttonStyle }}
                        >
                            Clear Transfer Date
                        </Button>
                    </Container>

                    <Container style={{ flexDirection: 'column' }}>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => {
                                props.scheduleController.addGlobalDayOff(
                                    selectedDate,
                                )
                                popoverState.handleClose()
                            }}
                            style={{ ...buttonStyle }}
                        >
                            Set day off
                        </Button>

                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => {
                                props.scheduleController.removeGlobalDayOff(
                                    selectedDate,
                                )
                                popoverState.handleClose()
                            }}
                            style={{ ...buttonStyle }}
                        >
                            Clear day off
                        </Button>
                    </Container>
                </Container>

                <Button
                    variant="contained"
                    color="default"
                    onClick={popoverState.handleClose}
                    style={{
                        textTransform: 'none',
                        marginBottom: theme.spacing(1),
                    }}
                >
                    Close
                </Button>
            </Container>
        </Popover>
    )
}
