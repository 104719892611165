import { useState } from 'react'
import { ListVendor, Service } from '../models'

const NO_ID_SELECTED = -1

export const useSelectVendorService = (vendorList: ListVendor[]) => {
    const [selectedVendorId, setSelectedVendorId] = useState(NO_ID_SELECTED)
    const [selectedServiceId, setSelectedServiceId] = useState(NO_ID_SELECTED)

    const selectService = (serviceId: number) => {
        setSelectedVendorId(NO_ID_SELECTED)
        setSelectedServiceId(serviceId)
    }

    const filteredVendorList = vendorList.filter((vendor) => {
        return vendor.services.find((srv) => srv.id === selectedServiceId)
    })

    return {
        selectedVendorId,
        selectedServiceId,
        filteredVendorList,
        selectVendor: setSelectedVendorId,
        selectService,
    }
}
