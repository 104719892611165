import React, { useState } from 'react'

import { IconButton, Menu, MenuItem, ListItemText } from '@material-ui/core'

import { Schedule } from '../../../models'
import { currencyFormatterNoDecimals } from '../../../helpers/CurrencyFormatter'
import { BaseProps } from '../../TemplateScheduleGantt/types'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import Grid from '@mui/material/Grid'
import { IconValue, MoneyValue, TextValue } from '../styles'
import { LineItem } from '../../../models/WorkOrder/types'
import { toast } from 'react-toastify'

interface Props extends BaseProps {
    lineItem: LineItem
    key: string
}

export const LineItemRow = (props: Props) => {
    const { scheduleController, lineItem, key } = props

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <React.Fragment key={key}>
            <Grid item xs={0.5}>
                <IconValue>
                    <IconButton onClick={openMenu}>
                        <DeleteForeverIcon />
                    </IconButton>
                </IconValue>
            </Grid>
            <Grid item xs={9.5}>
                <TextValue>{lineItem.description}</TextValue>
            </Grid>
            <Grid item xs={2}>
                <MoneyValue>
                    {currencyFormatterNoDecimals.format(lineItem.cost)}
                </MoneyValue>
            </Grid>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        scheduleController
                            .deleteLineItem(lineItem.id)
                            .then(() => {
                                toast.success('Deleted!')
                            })
                            .catch(() => {
                                toast.error(
                                    'There was a problem with your request',
                                )
                            })
                            .finally(() => {
                                handleClose()
                            })
                    }}
                >
                    <ListItemText>Remove {lineItem.description}</ListItemText>
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}
