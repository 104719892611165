import React, { useState } from 'react'
import { NVLVendor } from '../../main/containers/national-vendor-list'
import { Avatar, Button, Tooltip, useTheme } from '@material-ui/core'
import { Container } from '../../components'
import { Pagination } from '@material-ui/lab'
import { getInitials } from '../../helpers'
import { ReactComponent as MapIcon } from '../../assets/map-pin.svg'
import { ReactComponent as RibbonThumbGreenIcon } from '../../assets/icons/thumbs_ribbon-32-filled-big.svg'
import { ReactComponent as RibbonAirplaneDarkIcon } from '../../assets/icons/airplane_ribbon-32-filled-big.svg'
import { ModelMap, SimpleVendor } from '../../models'
import { Close } from '@material-ui/icons'
import SkillCatLogoSmall from '../../assets/SkillCatLogo_small.png'

interface Props {
    vendorList: (NVLVendor | SimpleVendor)[]
    selectedVendorsMap: ModelMap<NVLVendor | SimpleVendor>
    viewMode?: boolean
    setSelectedVendorsMap: (map: ModelMap<NVLVendor | SimpleVendor>) => void
    setSelectedVendor: (vendor: NVLVendor | SimpleVendor) => void
}

export const RFPVendorList = (props: Props) => {
    const {
        vendorList,
        selectedVendorsMap,
        viewMode,
        setSelectedVendorsMap,
        setSelectedVendor,
    } = props

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(1)

    const theme = useTheme()

    const invitedVendorList: (NVLVendor | SimpleVendor)[] = []
    Object.keys(selectedVendorsMap).forEach((keyStr) => {
        const tempVendor = selectedVendorsMap[Number(keyStr)]
        if (tempVendor) {
            invitedVendorList.push(tempVendor)
        }
    })

    const venList = viewMode ? invitedVendorList : vendorList

    const vendorListLength = venList.length

    return (
        <Container
            direction="column"
            flex={4}
            style={{ height: 'calc(100vh - 370px)' }}
        >
            <Container
                direction="column"
                style={{
                    overflow: 'auto',
                    paddingRight: theme.spacing(2),
                    maxHeight: 'calc(100vh - 350px)',
                }}
            >
                {venList
                    .sort((vendorA, vendorB) => {
                        if (
                            selectedVendorsMap[vendorA.id] &&
                            selectedVendorsMap[vendorB.id]
                        ) {
                            return 0
                        } else if (
                            selectedVendorsMap[vendorA.id] &&
                            !selectedVendorsMap[vendorB.id]
                        ) {
                            return -1
                        } else if (
                            !selectedVendorsMap[vendorA.id] &&
                            selectedVendorsMap[vendorB.id]
                        ) {
                            return 1
                        }
                        return -2
                    })
                    .map((nvlVendor) => {
                        return (
                            <RFPVendorRow
                                vendor={nvlVendor}
                                selectedVendorsMap={selectedVendorsMap}
                                setSelectedVendorsMap={setSelectedVendorsMap}
                                setSelectedVendor={setSelectedVendor}
                                key={`RFP_VENDOR_ROW_${nvlVendor.id}`}
                                viewMode={viewMode}
                            />
                        )
                    })
                    .slice(
                        (page - 1) * rowsPerPage,
                        (page - 1) * rowsPerPage + rowsPerPage,
                    )}
            </Container>

            <Container style={{ alignItems: 'center' }}>
                <span
                    style={{
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        color: theme.palette.lightGrey.main,
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '13px',
                        paddingTop: theme.spacing(1),
                    }}
                >
                    {vendorListLength ?? 0} Rows
                </span>
                <Pagination
                    count={Math.ceil(vendorListLength / rowsPerPage)}
                    page={page}
                    onChange={(_: unknown, newPage: number) => {
                        setPage(newPage)
                    }}
                    variant="outlined"
                    shape="rounded"
                    style={{ margin: theme.spacing(1, 0, 0, 0) }}
                />
            </Container>
        </Container>
    )
}

interface VendorRowProps {
    vendor: NVLVendor | SimpleVendor
    selectedVendorsMap: ModelMap<NVLVendor | SimpleVendor>
    viewMode?: boolean
    setSelectedVendorsMap: (map: ModelMap<NVLVendor | SimpleVendor>) => void
    setSelectedVendor?: (vendor: NVLVendor | SimpleVendor) => void
}

export const RFPVendorRow = (props: VendorRowProps) => {
    const {
        vendor,
        selectedVendorsMap,
        viewMode,
        setSelectedVendorsMap,
        setSelectedVendor,
    } = props

    const theme = useTheme()

    const displayAddress =
        vendor.primary_address?.state !== '' ||
        vendor.primary_address?.address1 !== '' ||
        vendor.primary_address?.city !== ''

    return (
        <Container
            style={{
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: '5px',
                margin: theme.spacing(1, 0, 1, 0),
                cursor: 'pointer',
                borderColor: selectedVendorsMap[vendor.id]
                    ? theme.palette.darkBlue.main
                    : theme.palette.grey[400],
                minHeight: '75px',
                maxHeight: '120px',
                overflow: 'auto',
                backgroundColor: selectedVendorsMap[vendor.id]
                    ? theme.palette.grey[200]
                    : '',
            }}
            onClick={() => setSelectedVendor && setSelectedVendor(vendor)}
        >
            <Container
                style={{
                    margin: theme.spacing(0, 2, 0, 2),
                    alignSelf: 'center',
                }}
            >
                {vendor.profile_picture !== '' &&
                vendor.profile_picture !== null ? (
                    <img
                        src={vendor.profile_picture}
                        style={{
                            width: '45px',
                            height: '45px',
                        }}
                    />
                ) : (
                    <Avatar
                        variant="square"
                        style={{
                            backgroundColor: theme.palette.primary.dark,
                            width: '45px',
                            height: '45px',
                            fontSize: 20,
                        }}
                    >
                        {getInitials(vendor.name)}
                    </Avatar>
                )}
            </Container>

            <Container
                style={{
                    margin: theme.spacing(0, 2, 0, 2),
                    justifyContent: 'center',
                    width: '350px',
                }}
                direction="column"
            >
                <Container
                    style={{
                        ...theme.typography.subtitle1,
                        fontWeight: 600,
                    }}
                >
                    {vendor.name}
                </Container>

                {displayAddress && (
                    <Container
                        style={{
                            ...theme.typography.body2,
                            color: theme.palette.darkGreen.main,
                            alignItems: 'center',
                        }}
                    >
                        <MapIcon
                            style={{
                                marginRight: theme.spacing(1),
                                padding: theme.spacing(0.5),
                            }}
                        />
                        {vendor.primary_address?.address1}{' '}
                        {vendor.primary_address?.city},{' '}
                        {vendor.primary_address?.state}
                    </Container>
                )}
            </Container>

            <Container
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    alignItems: 'center',
                    margin: theme.spacing(0, 2, 0, 2),
                    width: '300px',
                }}
            >
                {vendor.is_trained && (
                    <Container
                        style={{
                            marginRight: theme.spacing(3),
                            alignItems: 'center',
                        }}
                    >
                        <Tooltip title="EZ Trained">
                            <RibbonThumbGreenIcon
                                style={{
                                    marginLeft: theme.spacing(1),
                                    padding: theme.spacing(0.5),
                                }}
                            />
                        </Tooltip>
                    </Container>
                )}

                {vendor.will_travel && (
                    <Container
                        style={{
                            marginRight: theme.spacing(3),
                            alignItems: 'center',
                        }}
                    >
                        <Tooltip title="Will Travel">
                            <RibbonAirplaneDarkIcon
                                style={{
                                    marginLeft: theme.spacing(1),
                                    padding: theme.spacing(0.5),
                                }}
                            />
                        </Tooltip>
                    </Container>
                )}

                {vendor.is_skillcat && (
                    <Container style={{ alignItems: 'center' }}>
                        <Tooltip title="SkillCat">
                            <img
                                src={SkillCatLogoSmall}
                                alt="Skill Cat Logo"
                                style={{
                                    marginLeft: theme.spacing(1),
                                }}
                            />
                        </Tooltip>
                    </Container>
                )}
            </Container>

            <Container flex={1} />
            {!viewMode && (
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: 'white',
                        color: selectedVendorsMap[vendor.id] ? 'red' : 'black',
                        border: `1px solid ${theme.palette.primary.dark}`,
                        borderRadius: theme.shape.borderRadius,
                        borderColor: selectedVendorsMap[vendor.id]
                            ? 'red'
                            : theme.palette.darkGreen.main,
                        textTransform: 'none',
                        cursor: 'pointer',
                        alignSelf: 'center',
                        marginRight: theme.spacing(2),
                    }}
                    disabled={viewMode}
                    onClick={() => {
                        const newSelectedVendorsMap: ModelMap<
                            NVLVendor | SimpleVendor
                        > = {}
                        if (selectedVendorsMap[vendor.id]) {
                            Object.keys(selectedVendorsMap).forEach((key) => {
                                if (Number(key) !== vendor.id) {
                                    newSelectedVendorsMap[Number(key)] =
                                        selectedVendorsMap[Number(key)]
                                }
                            })
                        } else {
                            Object.keys(selectedVendorsMap).forEach(
                                (key) =>
                                    (newSelectedVendorsMap[Number(key)] =
                                        selectedVendorsMap[Number(key)]),
                            )
                            newSelectedVendorsMap[vendor.id] = vendor
                        }

                        setSelectedVendorsMap(newSelectedVendorsMap)
                    }}
                >
                    {selectedVendorsMap[vendor.id] ? (
                        <Container alignItems="center">
                            <Close
                                fontSize="small"
                                style={{ marginRight: theme.spacing(1) }}
                            />{' '}
                            Remove
                        </Container>
                    ) : (
                        '+ Invite to Bid'
                    )}
                </Button>
            )}
        </Container>
    )
}
