import React, { useEffect, useState } from 'react'
import { Container } from '../../../../components'
import {
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    useTheme,
} from '@material-ui/core'
import { WorkSpaceUser } from '../../../../models'
import {
    axiosInstance,
    lastCharacterIsNumber,
    stateAbbreviationMap,
    statesList,
} from '../../../../helpers'
import { toast } from 'react-toastify'
import { DatePicker } from '@material-ui/pickers'
import { CompanyForm } from '../../Forms'
import { dwollaApi } from '../../../../contexts'

interface Props {
    user: WorkSpaceUser
    onSubmit: () => void
}

export const CompanyVerificationStep = (props: Props) => {
    const theme = useTheme()

    const { createBusinessVerifiedCustomer } = dwollaApi()

    const [checked, setChecked] = useState(false)

    const [businessType, setBusinessType] = useState('llc')
    const [businessName, setBusinessName] = useState(
        props.user.active_workspace.name,
    )
    const [doingBusinessAs, setDoingBusinessAs] = useState('')
    const [
        employerIdentificationNumber,
        setEmployerIdentificationNumber,
    ] = useState('')
    const [website, setWebsite] = useState('')
    const [jobTitle, setJobTitle] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [birthdate, setBirthdate] = useState<Date | null>(new Date())
    const [ssn, setSsn] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [state, setState] = useState('')

    const [doValidate, setDoValidate] = useState(false)

    const eighteenYearsAgo = new Date()
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18)
    const userIsAdult = birthdate && birthdate < eighteenYearsAgo

    const dataIsValid = () => {
        if (businessName === '') {
            return false
        }
        if (employerIdentificationNumber.length !== 10) {
            return false
        }
        if (jobTitle === '') {
            return false
        }
        if (ssn.length !== 4 && ssn.length !== 9) {
            return false
        }
        if (address === '') {
            return false
        }
        if (city === '') {
            return false
        }
        if (state === '') {
            return false
        }
        if (zipCode.length !== 5) {
            return false
        }
        if (!userIsAdult) {
            return false
        }

        return true
    }

    return (
        <Container
            style={{
                padding: theme.spacing(2),
                flexDirection: 'column',
            }}
        >
            {/* Info form */}

            <Container>
                {/* Company column */}
                <Container style={{ flex: 1, flexDirection: 'column' }}>
                    <span style={theme.typography.body1}>Company Info</span>
                    <FormControl>
                        <InputLabel>Business Type</InputLabel>
                        <Select
                            // fullWidth
                            displayEmpty
                            value={businessType}
                            onChange={(e) => {
                                const value = e.target.value as string
                                setBusinessType(value)
                            }}
                        >
                            <MenuItem value="llc">LLC</MenuItem>
                            <MenuItem value="corporation">Corporation</MenuItem>
                            <MenuItem value="partnership">Partnership</MenuItem>
                        </Select>
                    </FormControl>

                    <Container style={{ marginTop: theme.spacing(2) }}>
                        <TextField
                            label="Business Name"
                            value={businessName}
                            fullWidth
                            onChange={(e) => setBusinessName(e.target.value)}
                            error={doValidate && businessName === ''}
                            helperText={
                                doValidate && businessName === '' && 'Required'
                            }
                        />

                        <TextField
                            label="Doing Business As"
                            value={doingBusinessAs}
                            fullWidth
                            onChange={(e) => setDoingBusinessAs(e.target.value)}
                            style={{ marginLeft: theme.spacing(2) }}
                        />
                    </Container>

                    <TextField
                        label="Employer Identification Number (EIN)"
                        value={employerIdentificationNumber}
                        onChange={(e) => {
                            const newInput = e.target.value
                            if (!lastCharacterIsNumber(newInput)) {
                                // fix backspace edgecase that would prevent user from backspacing due to the -
                                if (
                                    newInput.length === 3 &&
                                    newInput[2] === '-'
                                ) {
                                    setEmployerIdentificationNumber(
                                        e.target.value,
                                    )
                                }
                                return
                            }

                            // if the user is entering in the 3rd number, insert a tack to ensure xx-xxxxxxx format
                            if (newInput.length === 3 && newInput[2] !== '-') {
                                const idWithTack =
                                    newInput.substring(0, 2) +
                                    '-' +
                                    newInput.substring(2)
                                setEmployerIdentificationNumber(idWithTack)
                            } else {
                                setEmployerIdentificationNumber(e.target.value)
                            }
                        }}
                        style={{ marginTop: theme.spacing(2) }}
                        inputProps={{ maxLength: 10 }}
                        error={
                            doValidate &&
                            employerIdentificationNumber.length !== 10
                        }
                        helperText={
                            doValidate &&
                            employerIdentificationNumber.length !== 10 &&
                            'full EIN required'
                        }
                    />

                    <TextField
                        label="Website"
                        value={website}
                        onChange={(e) => {
                            setWebsite(e.target.value)
                        }}
                        style={{ marginTop: theme.spacing(2) }}
                    />
                </Container>

                {/* Controller column */}
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        marginLeft: theme.spacing(2),
                    }}
                >
                    <span style={theme.typography.body1}>Controller Info</span>

                    <TextField
                        label="Job Title"
                        value={jobTitle}
                        onChange={(e) => {
                            setJobTitle(e.target.value)
                        }}
                        error={doValidate && jobTitle === ''}
                        helperText={doValidate && jobTitle === '' && 'Required'}
                    />
                    <Container style={{ marginTop: theme.spacing(2) }}>
                        <TextField
                            label="First Name"
                            fullWidth
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value)
                            }}
                            error={doValidate && firstName === ''}
                            helperText={
                                doValidate && firstName === '' && 'Required'
                            }
                        />
                        <TextField
                            label="Last Name"
                            fullWidth
                            style={{ marginLeft: theme.spacing(1) }}
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.target.value)
                            }}
                            error={doValidate && lastName === ''}
                            helperText={
                                doValidate && lastName === '' && 'Required'
                            }
                        />
                    </Container>
                    <DatePicker
                        style={{ marginTop: theme.spacing(2) }}
                        label="Birthdate"
                        value={birthdate}
                        openTo="year"
                        error={doValidate && !userIsAdult}
                        helperText={
                            doValidate &&
                            !userIsAdult &&
                            'User must be 18 or older'
                        }
                        onChange={(date) => {
                            setBirthdate(date)
                        }}
                        format="MM/dd/yyyy"
                    />
                    <TextField
                        label="SSN"
                        fullWidth
                        style={{ marginTop: theme.spacing(2) }}
                        value={ssn}
                        error={
                            doValidate && ssn.length !== 4 && ssn.length !== 9
                        }
                        helperText={
                            doValidate &&
                            ssn.length !== 4 &&
                            ssn.length !== 9 &&
                            'Either last 4 of SSN or full number'
                        }
                        onChange={(e) => {
                            const newSsn = e.target.value
                            if (newSsn.length > 9) {
                                return
                            }
                            if (lastCharacterIsNumber(newSsn)) {
                                setSsn(newSsn)
                            }
                        }}
                    />
                    <TextField
                        label="Address"
                        fullWidth
                        style={{ marginTop: theme.spacing(2) }}
                        value={address}
                        onChange={(e) => {
                            setAddress(e.target.value)
                        }}
                        error={doValidate && address === ''}
                        helperText={doValidate && address === '' && 'Required'}
                    />
                    <TextField
                        label="City"
                        fullWidth
                        style={{ marginTop: theme.spacing(2) }}
                        value={city}
                        onChange={(e) => {
                            setCity(e.target.value)
                        }}
                        error={doValidate && city === ''}
                        helperText={doValidate && city === '' && 'Required'}
                    />
                    <Container style={{ marginTop: theme.spacing(1) }}>
                        <TextField
                            error={doValidate && state === ''}
                            helperText={
                                doValidate && state === '' ? 'Required' : ''
                            }
                            // variant="outlined"
                            value={state}
                            onChange={(e) => setState(e.target.value as string)}
                            label="State"
                            fullWidth
                            style={{ marginRight: theme.spacing(1) }}
                            select
                        >
                            {statesList.map((s) => {
                                return (
                                    <MenuItem key={s} value={s}>
                                        {
                                            stateAbbreviationMap[
                                                s as keyof typeof stateAbbreviationMap
                                            ]
                                        }
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                        <TextField
                            label="Zip Code"
                            fullWidth
                            value={zipCode}
                            onChange={(e) => {
                                if (lastCharacterIsNumber(e.target.value)) {
                                    setZipCode(e.target.value)
                                }
                            }}
                        />
                    </Container>
                </Container>
            </Container>

            <Container style={{ marginTop: theme.spacing(4) }}>
                <FormControlLabel
                    labelPlacement="end"
                    control={
                        <Checkbox
                            color="primary"
                            checked={checked}
                            onChange={() => setChecked((c) => !c)}
                        />
                    }
                    label={
                        <span
                            style={{
                                ...theme.typography.caption,
                                fontWeight: theme.typography.fontWeightLight,
                                color: theme.palette.grey[600],
                            }}
                        >
                            By checking this box you agree to{' '}
                            <a
                                href="https://www.ezos.co/terms"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                our Terms of Service
                            </a>{' '}
                            and{' '}
                            <a
                                href="https://www.ezos.co/privacy"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>
                            , as well as our partner{' '}
                            <a
                                href="https://www.dwolla.com/legal/tos/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Dwollas Terms of Service
                            </a>{' '}
                            and{' '}
                            <a
                                href="https://www.dwolla.com/legal/privacy/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>
                            .
                        </span>
                    }
                />

                <Button
                    disabled={!checked}
                    onClick={() => {
                        if (!dataIsValid()) {
                            setDoValidate(true)
                            return
                        }

                        let dateString = birthdate?.getFullYear() + '-'
                        const month = birthdate?.getMonth()
                        if ((month ?? 1) < 10) {
                            dateString = dateString + '0'
                        }
                        dateString = dateString + month + '-'
                        const day = birthdate?.getDate()
                        if ((day ?? 1) < 10) {
                            dateString = dateString + '0'
                        }
                        dateString = dateString + day
                        let reqWebsite = website
                        if (
                            reqWebsite.length > 0 &&
                            !reqWebsite.startsWith('http')
                        ) {
                            reqWebsite = 'http://' + reqWebsite
                        }

                        createBusinessVerifiedCustomer({
                            business_name: businessName,
                            doing_business_as: doingBusinessAs,
                            website: reqWebsite,
                            ein: employerIdentificationNumber,
                            business_type: businessType,
                            job_title: jobTitle,
                            date_of_birth: dateString,
                            ssn: ssn,
                            address: address,
                            city: city,
                            state: state,
                            zip_code: zipCode,
                            first_name: firstName,
                            last_name: lastName,
                        }).then(() => {
                            setDoValidate(false)
                            props.onSubmit()
                        })
                    }}
                    color="primary"
                    variant="outlined"
                    style={{ textTransform: 'none', minWidth: 300 }}
                >
                    Agree and Continue
                </Button>
            </Container>
        </Container>
    )
}
