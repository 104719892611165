import React, { Dispatch, SetStateAction, useState } from 'react'
import { Container, Selector } from '../../../../../components'
import { Button, IconButton, Theme, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { useCompany, useService } from '../../../../../hooks'
import {
    ListVendor,
    RegisterStep,
    Service,
    getServiceImageDetails,
} from '../../../../../models'

interface Props {
    theme: Theme
    vendor: ListVendor | undefined
    onNextStep: () => void
    selectedServices: Service[]
    setSelectedServices: Dispatch<SetStateAction<Service[]>>
}

export const StepQuestionnaireServices = (props: Props) => {
    const { theme, vendor, selectedServices, setSelectedServices } = props

    const { updateVendorServices, updateVendor } = useCompany()

    const { serviceList } = useService({ getServiceList: true, cleanUp: true })
    const [error, setError] = useState<string>('')

    const shortServiceList = serviceList
        .slice(0, 14)
        .filter((service) => !selectedServices.includes(service))

    const handleServiceSelect = (selectedService: Service | null) => {
        if (selectedService) {
            if (
                !selectedServices.some(
                    (service) => service.id === selectedService.id,
                )
            ) {
                setSelectedServices([...selectedServices, selectedService])
            }
        }
    }

    const handleRemoveService = (serviceToRemove: Service) => {
        setSelectedServices(
            selectedServices.filter(
                (service) => service.id !== serviceToRemove.id,
            ),
        )
    }

    const handleAddToSelected = (selectedService: Service) => {
        if (
            !selectedServices.some(
                (service) => service.id === selectedService.id,
            )
        ) {
            setSelectedServices([...selectedServices, selectedService])
            setError('')
        }
    }

    const handleSaveAndContinue = () => {
        if (selectedServices.length === 0) {
            setError('Please select at least one service.')
        } else if (vendor) {
            const savedServices = selectedServices.map((srv) => ({
                id: srv.id,
                offers: true,
            }))
            updateVendorServices({
                params: { id: vendor.id },
                body: {
                    services: savedServices,
                },
            }).then(() => {
                updateVendor({
                    params: { id: vendor.id },
                    body: {
                        register_step: RegisterStep.AGREEMENTS_STEP,
                    },
                })
                props.onNextStep()
            })
        }
    }

    return (
        <Container
            style={{ flex: 1, flexDirection: 'column', alignItems: 'center' }}
        >
            <Container
                style={{
                    flexDirection: 'column',
                    width: '70%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    padding: theme.spacing(2),
                }}
            >
                <Container
                    style={{
                        flexDirection: 'column',
                        margin: theme.spacing(2, 0, 2, 0),
                        color: theme.palette.grey[800],
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.h4,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        Select the services that you offer
                    </span>
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        Choose from our most used services or search for more
                        below!
                    </span>
                </Container>
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                        margin: theme.spacing(2, 0, 2, 0),
                    }}
                >
                    Most used services:
                </span>

                <Container style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {shortServiceList.map((service) => (
                        <ServiceCard
                            key={service.id}
                            service={service}
                            theme={theme}
                            isSelected={false}
                            onAdd={() => handleAddToSelected(service)}
                        />
                    ))}
                </Container>
                <Selector
                    data={serviceList}
                    label="Service"
                    searchable
                    maxItems={10}
                    getDisplayString={(srv) => srv.name}
                    currentValue={-1}
                    onChange={(e) =>
                        handleServiceSelect(
                            serviceList.find(
                                (srv) => srv.id === e.target.value,
                            ) ?? null,
                        )
                    }
                    customStyle={{
                        formControl: {
                            marginTop: theme.spacing(2),
                            marginBottom: theme.spacing(2),
                        },
                    }}
                />
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                        margin: theme.spacing(2, 0, 2, 0),
                    }}
                >
                    Selected services:
                </span>
                <Container>
                    {selectedServices.length === 0 ? (
                        <Container
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: 200,
                                backgroundColor: theme.palette.grey[200],
                                borderRadius: theme.shape.borderRadius,
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: theme.typography.fontWeightBold,
                                    color: theme.palette.text.disabled,
                                }}
                            >
                                No services selected
                            </span>
                        </Container>
                    ) : (
                        <Container
                            style={{ display: 'flex', flexWrap: 'wrap' }}
                        >
                            {selectedServices.map((service) => (
                                <ServiceCard
                                    key={service.id}
                                    service={service}
                                    theme={theme}
                                    isSelected={true}
                                    onDelete={() =>
                                        handleRemoveService(service)
                                    }
                                />
                            ))}
                        </Container>
                    )}
                </Container>
            </Container>
            {error && (
                <span
                    style={{
                        color: 'red',
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        marginBottom: theme.spacing(2),
                    }}
                >
                    {error}
                </span>
            )}
            <Button
                style={{
                    backgroundColor: theme.palette.primary.dark,
                    color: 'white',
                    borderRadius: theme.shape.borderRadius,
                    textTransform: 'none',
                    margin: theme.spacing(2, 0, 1, 0),
                }}
                onClick={handleSaveAndContinue}
            >
                Save & Continue
            </Button>
        </Container>
    )
}

interface ServiceCardProps {
    service: Service
    theme: Theme
    isSelected: boolean
    onDelete?: () => void
    onAdd?: (service: Service) => void
}

const ServiceCard = (props: ServiceCardProps) => {
    const { service, theme, isSelected, onDelete } = props

    const handleAddToSelected = () => {
        if (props.onAdd) {
            props.onAdd(service)
        }
    }

    const handleDelete = () => {
        if (onDelete) {
            onDelete()
        }
    }

    return (
        <Container
            style={{
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(1),
                marginBottom: theme.spacing(1),
                marginRight: theme.spacing(1),
                alignItems: 'center',
                minWidth: CARD_W,
                maxWidth: CARD_W,
                minHeight: CARD_H,
                maxHeight: CARD_H,
            }}
        >
            <img
                src={getServiceImageDetails(service.id).icon}
                style={{
                    maxHeight: 30,
                    maxWidth: 32,
                    marginRight: theme.spacing(1),
                }}
            />

            <span
                style={{
                    ...theme.typography.body1,
                    fontWeight: theme.typography.fontWeightMedium,
                    color: theme.palette.grey[800],
                }}
            >
                {service.name}
            </span>

            <div style={{ flex: 1 }} />

            {isSelected ? (
                <Tooltip title="Delete">
                    <IconButton onClick={handleDelete}>
                        <DeleteForeverIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Add">
                    <IconButton onClick={handleAddToSelected}>
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Container>
    )
}

const CARD_W = 200
const CARD_H = 70

const CARDS_PER_ROW = 4
