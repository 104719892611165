import React, { useEffect, useState } from 'react'

import {
    FormControlLabel,
    Switch,
    useTheme,
    IconButton,
    Tooltip,
} from '@material-ui/core'
import ClearAllIcon from '@material-ui/icons/ClearAll'

import { Container, Selector } from '../../../../components'
import { FinderSelection } from '../../../../hooks'
import {
    AreaConfig,
    AreaStatusTag,
    BaseWorkorder,
    Folder,
    IdBoolMap,
    IdentifiableNamedObject,
    ListVendor,
    ModelListMap,
    ModelMap,
    Service,
} from '../../../../models'
import { useAssignModalStyle } from './useAssignModalStyle'
import { DateRange, Range } from 'react-date-range'
import { SchedulerFinder } from '../../SchedulerFinder'

interface Props {
    prunedTree: Folder
    assignmentFinderSelection: FinderSelection
    includeDates: boolean
    dateRange: Range
    selectedServiceId: number
    rangeKey: string
    serviceList?: Service[]
    vendorList?: ListVendor[]
    selectedVendorId: number
    unitWorkorderMap: ModelListMap<BaseWorkorder>
    areaConfigMap: ModelMap<AreaConfig>
    areaStatusTagMap: ModelMap<AreaStatusTag>
    clearLocationSelection: () => void
    setSelectedVendorId: (id: number) => void
    setSelectedServiceId: (id: number) => void
    setIncludeDates: (include: boolean) => void
    setDateRange: (range: Range) => void
}

export const SelectDetails = (props: Props) => {
    const {
        prunedTree,
        assignmentFinderSelection,
        includeDates,
        setIncludeDates,
        dateRange,
        setDateRange,
        serviceList,
        selectedServiceId,
        setSelectedServiceId,
        rangeKey,
        selectedVendorId,
        setSelectedVendorId,
        vendorList,
        unitWorkorderMap,
        areaConfigMap,
        areaStatusTagMap,
    } = props

    const theme = useTheme()
    const styles = useAssignModalStyle()

    const [srvFilteredVndList, setSrvFilteredVndList] = useState<
        ListVendor[] | undefined
    >()

    useEffect(() => {
        setSrvFilteredVndList(
            vendorList?.filter((vnd) => {
                return vnd.services.find((srv) => srv.id === selectedServiceId)
            }),
        )
        setSelectedVendorId(-1)
    }, [selectedServiceId])

    const selectServiceOption = { id: -1, name: 'Select Service' }
    const selectVendorOption = { id: -1, name: 'Select Vendor' }

    let serviceOptionList: IdentifiableNamedObject[]
    let vendorOptionList: IdentifiableNamedObject[]

    if (serviceList) {
        const goodServiceMap: IdBoolMap = {}
        vendorList?.forEach((vendor) => {
            vendor.services.forEach((service) => {
                goodServiceMap[service.id] = true
            })
        })

        const validServiceList: Service[] = []
        serviceList.forEach((service) => {
            if (goodServiceMap[service.id]) {
                validServiceList.push(service)
            }
        })

        serviceOptionList = [selectServiceOption, ...validServiceList]
    } else {
        serviceOptionList = [selectServiceOption]
    }

    if (srvFilteredVndList) {
        vendorOptionList = [selectVendorOption, ...srvFilteredVndList]
    } else {
        vendorOptionList = [selectVendorOption]
    }

    return (
        <Container>
            {/* Column 1 */}
            <Container
                style={{
                    minWidth: 320,
                    maxWidth: 320,
                    padding: theme.spacing(2),
                    flexDirection: 'column',
                }}
            >
                <Container
                    className={styles.columnHeaderStyle}
                    style={{ alignItems: 'center' }}
                >
                    <span
                        style={{
                            ...theme.typography.subtitle2,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        Locations
                    </span>

                    <div style={{ flex: 1 }} />

                    <IconButton
                        size="small"
                        onClick={props.clearLocationSelection}
                    >
                        <Tooltip title="Clear Location Selection">
                            <ClearAllIcon fontSize="small" />
                        </Tooltip>
                    </IconButton>
                </Container>
                {/* <Finder
                    defaultOpen
                    root={prunedTree}
                    onClickUnit={(unit) =>
                        assignmentFinderSelection.setFinderSelection(unit)
                    }
                    onClickFolder={(folder) =>
                        assignmentFinderSelection.setFinderSelection(folder)
                    }
                    locationSelection={assignmentFinderSelection.selection}
                    selectedIcon={<></>}
                    getUnitAdornment={(unit) => {
                        return (
                            <Container style={{ flex: 1 }}>
                                <ServiceDots
                                    workorders={unitWorkorderMap[unit.id] ?? []}
                                />
                                <div style={{ flex: 1 }} />
                                <Checkbox
                                    checked={
                                        assignmentFinderSelection.selection
                                            .unit[unit.id] !== undefined
                                    }
                                    onClick={() => {
                                        assignmentFinderSelection.setFinderSelection(
                                            {
                                                type: 'unit',
                                                location: unit,
                                            },
                                        )
                                    }}
                                />
                            </Container>
                        )
                    }}
                    getFolderAdornment={(folder) => {
                        return (
                            <Container style={{ flex: 1 }}>
                                <div style={{ flex: 1 }} />
                                <Checkbox
                                    color={'primary'}
                                    checked={
                                        assignmentFinderSelection.selection
                                            .folder[folder.id] !== undefined
                                    }
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        assignmentFinderSelection.setFinderSelection(
                                            {
                                                type: 'folder',
                                                location: folder,
                                            },
                                        )
                                    }}
                                />
                            </Container>
                        )
                    }}
                /> */}

                <SchedulerFinder
                    root={prunedTree}
                    finderSelection={assignmentFinderSelection}
                    areaConfigMap={areaConfigMap}
                    areaStatusTagMap={areaStatusTagMap}
                    unitWorkorderMap={unitWorkorderMap}
                />
            </Container>

            {/* Column 2  */}
            <Container
                style={{
                    padding: theme.spacing(2),
                    flexDirection: 'column',
                    borderRight: `1px solid ${theme.palette.grey[300]}`,
                }}
            >
                <Container className={styles.columnHeaderStyle}>
                    <span
                        style={{
                            ...theme.typography.subtitle2,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        Date
                    </span>

                    <div style={{ flex: 1 }} />

                    <Container style={{ alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={includeDates}
                                    color="primary"
                                    onChange={() => {
                                        setDateRange({
                                            ...dateRange,
                                            color: !includeDates
                                                ? theme.palette.primary.main
                                                : theme.palette.grey[500],
                                        })
                                        setIncludeDates(!includeDates)
                                    }}
                                />
                            }
                            labelPlacement="start"
                            label={
                                <span
                                    style={{
                                        ...theme.typography.subtitle2,
                                    }}
                                >
                                    Ghost
                                </span>
                            }
                        />
                        <span
                            style={{
                                ...theme.typography.subtitle2,
                                verticalAlign: 'center',
                                justifyContent: 'center',
                                marginLeft: theme.spacing(1),
                            }}
                        >
                            Smart
                        </span>
                    </Container>
                </Container>

                <Container
                    style={{
                        backgroundColor: theme.palette.grey[50],
                        borderRadius: theme.shape.borderRadius,
                        padding: theme.spacing(0.5),
                        border: `1px solid ${theme.palette.grey[300]}`,
                    }}
                >
                    <DateRange
                        ranges={[dateRange]}
                        fixedHeight
                        onChange={(range) => {
                            const key = rangeKey as keyof typeof range
                            const newRange = range[key] as {
                                startDate: Date
                                endDate: Date
                            }
                            setDateRange(newRange)
                        }}
                    />
                </Container>

                <Container style={{ marginTop: theme.spacing(2) }}>
                    <Selector
                        label={'Service'}
                        searchable
                        currentValue={selectedServiceId}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            setSelectedServiceId(event.target.value as number)
                        }}
                        data={serviceOptionList}
                        getDisplayString={(service: IdentifiableNamedObject) =>
                            service.name
                        }
                        customStyle={{
                            formControl: {
                                margin: 0,
                                width: '100%',
                            },
                        }}
                    />
                </Container>

                <Container style={{ marginTop: theme.spacing(2) }}>
                    <Selector
                        label={'Vendor'}
                        searchable
                        disabled={selectedServiceId === -1}
                        currentValue={selectedVendorId}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            setSelectedVendorId(event.target.value as number)
                        }}
                        data={vendorOptionList}
                        getDisplayString={(vendor: IdentifiableNamedObject) =>
                            vendor.name
                        }
                        customStyle={{
                            formControl: {
                                margin: 0,
                                width: '100%',
                            },
                        }}
                    />
                </Container>
            </Container>
        </Container>
    )
}
