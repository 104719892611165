import React from 'react'
import { IconButton, useTheme } from '@material-ui/core'
import { Container } from '../Container'
import { useFinderStyles } from './styles'
import VisibilityIcon from '@material-ui/icons/Visibility'

interface Props {
    level: number
    myKey: string
    selected: boolean
    selectedIcon?: JSX.Element
    onClickIcon?: () => void
}

export const FinderItem: React.FC<Props> = (props) => {
    /**
     * FinderItem has 2 primary responsibilities
     * 1.   Creates spacer components to create the visual indintation that makes it clear to the
     *      user what level a given item is at
     * 2.   On hover displays the visibility button that can be clicked to toggle finder filtering
     */

    const theme = useTheme()
    const classes = useFinderStyles()

    const spacers: JSX.Element[] = []
    for (let curLevel = 1; curLevel < props.level + 1; curLevel++) {
        spacers.push(
            <div
                key={`${props.myKey}_${curLevel}`}
                style={{
                    width: 25,
                    height: '100%',
                    borderLeft: `1px solid ${theme.palette.secondary.main}`,
                }}
            />,
        )
    }

    let icon: JSX.Element

    if (props.selected) {
        icon = (
            <IconButton
                size="small"
                color="primary"
                onClick={props.onClickIcon}
            >
                {props.selectedIcon ? props.selectedIcon : <VisibilityIcon />}
            </IconButton>
        )
    } else {
        icon = (
            <IconButton
                size="small"
                className={classes.icon}
                color="secondary"
                onClick={props.onClickIcon}
            >
                {props.selectedIcon ? props.selectedIcon : <VisibilityIcon />}
            </IconButton>
        )
    }

    return (
        <Container className={classes.infrastructure} alignItems="center">
            {spacers}
            {props.children}
            {icon}
        </Container>
    )
}
