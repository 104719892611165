/**
 * A Blanket is a component that can be toggled on / off.
 * When a blanket is in the on position it completely covers
 * a given element.  When it is in the off position it is hidden
 */

import React from 'react'
import clsx from 'clsx'

import styles from './Blanket.module.css'

import { Container } from '../Container'
import { useTheme } from '@material-ui/core'

export interface BlanketRect {
    anchorTop: number
    anchorLeft: number
    height: number
    width: number
}

export interface BlanketProps {
    open: boolean
    rect: BlanketRect
}

export const Blanket: React.FC<BlanketProps> = (props) => {
    const { open, rect } = props
    const theme = useTheme()

    return (
        <Container
            className={clsx(styles.Blanket, {
                [styles.Close]: !open,
            })}
            style={{
                position: 'fixed',
                top: rect.anchorTop,
                left: rect.anchorLeft,
                height: rect.height,
                width: rect.width,
                flexDirection: 'column',
                zIndex: theme.zIndex.mobileStepper,
            }}
        >
            {props.children}
        </Container>
    )
}
