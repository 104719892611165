import {
    Avatar,
    Button,
    FormControl,
    Icon,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    useTheme,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { Container, SideDrawer } from '../../../components'

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import SendIcon from '@material-ui/icons/Send'

import ImageIcon from '@material-ui/icons/Image'

import { ReactComponent as AddSignIcon } from '../../../assets/icons/at-sign.svg'
import { ReactComponent as AttachIcon } from '../../../assets/icons/paperclip.svg'
import { ReactComponent as SendMessageIcon } from '../../../assets/icons/send.svg'
import { MessageTextEditor } from '../messageTextEditor'
import { useMessage } from '../../../hooks/useMessage'

import { useUser } from '../../../hooks'
import { useHistory } from 'react-router-dom'

import { ChatHistory } from '../chat-history'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                width: '100%',
            },
        },
        messageBoxDiv: {
            position: 'absolute',
            width: '100%',
            left: 0,
            bottom: '20px',
            padding: '16px',
        },
        messageListDiv: {
            maxHeight: 'calc(100vh - 285px)',
            overflowY: 'auto',
        },
    }),
)

interface Props {
    channelId: number | null
    open: boolean
    handleClose: () => void
    width: number
}

export const MessageDrawer = (props: Props) => {
    const classes = useStyles()
    const theme = useTheme()

    const history = useHistory()

    const { messageList, getMessageList, createMessage } = useMessage()
    const { workspaceUser } = useUser()

    useEffect(() => {
        if (props.channelId) {
            getMessageList({
                channelId: props.channelId,
            })
        }
    }, [props.channelId])

    console.log('work space user....', workspaceUser)
    console.log('message list is.....', messageList)

    return (
        <SideDrawer
            open={props.open}
            handleClose={props.handleClose}
            width={props.width}
            title={
                <>
                    <Button
                        onClick={() => history.push('/ez-chat')}
                        style={{
                            color: '#000',
                            fontSize: '0.875rem',
                            textTransform: 'inherit',
                        }}
                    >
                        Go to EZ Chat
                    </Button>
                </>
            }
        >
            <Container
                direction="column"
                style={{ padding: theme.spacing(2) }}
                flex={1}
                scrollY
            >
                <div className={classes.messageListDiv}>
                    <div
                        id="chatMessageArea"
                        className={classes.messageListDiv}
                    >
                        <ChatHistory
                            messageList={messageList}
                            workspaceUser={workspaceUser}
                            // toggleProfileDrawer={props.toggleProfileDrawer}
                        />
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                    }}
                >
                    <MessageTextEditor
                        // toggleProfileDrawer={props.toggleProfileDrawer}
                        channelId={props.channelId}
                    />
                </div>
                {/* <div className={classes.messageBoxDiv}>
                    <form
                        className={classes.root}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField
                            id="outlined-multiline-flexible"
                            // label="Multiline"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            placeholder="Type Message Here..."
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position="start"
                                        style={{
                                            position: 'absolute',
                                            left: 0,
                                            bottom: '15px',
                                        }}
                                    >
                                        <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="span"
                                            style={{
                                                padding: '8px',
                                                borderRadius: '5px',
                                            }}
                                            onClick={() => alert('assign')}
                                        >
                                            <AddSignIcon />
                                        </IconButton>
                                        <span
                                            style={{
                                                color: '#D9D9D9',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            |
                                        </span>
                                        <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="span"
                                            onClick={() => alert('attach')}
                                            style={{
                                                padding: '8px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            <AttachIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        style={{
                                            position: 'absolute',
                                            right: 0,
                                            bottom: '15px',
                                        }}
                                    >
                                        <IconButton
                                            color="primary"
                                            aria-label="upload picture"
                                            component="span"
                                            onClick={() => alert('send')}
                                            style={{
                                                padding: '8px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            <SendMessageIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </form>
                </div> */}
            </Container>
        </SideDrawer>
    )
}
