import { Modal, Paper, Slide, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from '../../../components'
import { ConfigureInspectionType } from './ConfigureInspectionType'
import { PreviewInspectionType } from './PreviewInspectionType'
import { Frequency, IdBoolMap, InspectionType, Schedule } from '../../../models'
import { useAppDispatch } from '../../../hooks'
import {
    GetScheduleListActionThunk,
    GetScheduleListRequest,
    RootState,
    getStatusGroupList,
    getStatusList,
} from '../../../store'
import { useSelector } from 'react-redux'
import { CreateInspectionTab } from './CreateInspectionTab'
import { CreateInspectionFrequencyTab } from './CreateInspectionFrequencyTab'

interface Props {
    open: boolean
    inspectionTypeList: InspectionType[]
    scheduleList: Schedule[]
    frequencyList: Frequency[]
    setFrequencyList: (list: Frequency[]) => void
    getScheduleList: (req: GetScheduleListRequest) => GetScheduleListActionThunk
    handleClose: () => void
    inspectionType?: InspectionType
    setMoveOutInspection?: (val: number) => void
}

export const CONGIFURE_INSPECTION_TYPE_STEP = 0
export const PREVIEW_INSPECTION_TYPE_STEP = 1
export const CREATE_INSPECTION_TAB = 2
export const CREATE_FREQUENCY_TAB = 3

export const InspectionConfigurationModal = (props: Props) => {
    const {
        open,
        handleClose,
        inspectionTypeList,
        scheduleList,
        getScheduleList,
        frequencyList,
        setFrequencyList,
        inspectionType,
        setMoveOutInspection,
    } = props

    const [tab, setTab] = useState(CONGIFURE_INSPECTION_TYPE_STEP)
    const [inspectionTypeName, setInspectionTypeName] = useState('')
    const [invItemsMap, setInvItemsMap] = useState<IdBoolMap>({})

    const [openCreateFrequencyForm, setOpenCreateFrequencyForm] = useState(
        false,
    )

    const inventoryConfigList = useSelector(
        (state: RootState) => state.aptConfig.inventoryConfigList,
    )

    const theme = useTheme()

    const dispatch = useAppDispatch()

    useEffect(() => {
        setOpenCreateFrequencyForm(false)
        dispatch(getStatusGroupList({}))
        dispatch(getStatusList({}))
    }, [])

    useEffect(() => {
        setTab(CONGIFURE_INSPECTION_TYPE_STEP)
        setOpenCreateFrequencyForm(false)
        if (!open) setInspectionTypeName('')

        const newCheckedMap: IdBoolMap =
            inventoryConfigList?.reduce<IdBoolMap>((prev, iC, idx) => {
                return { ...prev, [iC.id]: false }
            }, {}) ?? {}
        if (inspectionType) {
            inspectionType.inventory_configs.forEach((iC) => {
                newCheckedMap[iC.id] = true
            })
        }
        setInvItemsMap(newCheckedMap)
    }, [open])

    const SubHeaderCellStyle: React.CSSProperties = {
        fontWeight: 500,
        fontSize: '15px',
        marginBottom: theme.spacing(2),
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'auto',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: openCreateFrequencyForm ? '95%' : '700px',
                        height: openCreateFrequencyForm ? '85%' : '820px',
                        maxHeight: openCreateFrequencyForm
                            ? '95%'
                            : 'calc(100vh - 100px)',
                        maxWidth: openCreateFrequencyForm
                            ? '95%'
                            : 'calc(100vh - 100px)',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    <Container
                        style={{
                            padding: theme.spacing(1, 0, 4, 0),
                            flex: 1,
                        }}
                        direction="column"
                    >
                        {/* Step 1 */}
                        {tab === CONGIFURE_INSPECTION_TYPE_STEP && (
                            <ConfigureInspectionType
                                inspectionTypeName={inspectionTypeName}
                                setInspectionTypeName={setInspectionTypeName}
                                handleClose={handleClose}
                                setTab={setTab}
                                invItemsMap={invItemsMap}
                                setInvItemsMap={setInvItemsMap}
                                SubHeaderStyle={SubHeaderCellStyle}
                                inspectionType={inspectionType}
                                setMoveOutInspection={setMoveOutInspection}
                            />
                        )}

                        {/* Step 2 */}
                        {tab === PREVIEW_INSPECTION_TYPE_STEP && (
                            <Slide
                                direction="left"
                                in={tab === PREVIEW_INSPECTION_TYPE_STEP}
                            >
                                <Paper elevation={0} style={{ flex: 1 }}>
                                    <PreviewInspectionType
                                        handleClose={handleClose}
                                        setTab={setTab}
                                        inspectionTypeName={inspectionTypeName}
                                        invItemsMap={invItemsMap}
                                        SubHeaderStyle={SubHeaderCellStyle}
                                        inspectionType={inspectionType}
                                    />
                                </Paper>
                            </Slide>
                        )}

                        {tab === CREATE_INSPECTION_TAB && (
                            <Slide
                                direction="left"
                                in={tab === CREATE_INSPECTION_TAB}
                            >
                                <Paper elevation={0} style={{ flex: 1 }}>
                                    <CreateInspectionTab
                                        open={true}
                                        scheduleList={scheduleList}
                                        inspectionTypeList={inspectionTypeList}
                                        getScheduleList={getScheduleList}
                                        handleClose={handleClose}
                                    />
                                </Paper>
                            </Slide>
                        )}

                        {tab === CREATE_FREQUENCY_TAB && (
                            <Slide
                                direction="left"
                                in={tab === CREATE_FREQUENCY_TAB}
                            >
                                <Paper elevation={0} style={{ flex: 1 }}>
                                    <CreateInspectionFrequencyTab
                                        openCreateFrequencyForm={
                                            openCreateFrequencyForm
                                        }
                                        setOpenCreateFrequencyForm={
                                            setOpenCreateFrequencyForm
                                        }
                                        handleClose={handleClose}
                                        frequencyList={frequencyList}
                                        setFrequencyList={setFrequencyList}
                                    />
                                </Paper>
                            </Slide>
                        )}
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
