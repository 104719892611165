import React from 'react'
import Dropzone, { Accept } from 'react-dropzone'
import { Container } from '../Container'
import { CloudUpload } from '@material-ui/icons'
import { useTheme } from '@material-ui/core'
import { useStyles } from '../../styles'

interface DropFilesProps {
    displayString?: string
    fileTypes?: Accept
    multipleFiles?: boolean
    onDrop: (files: File[]) => void
}

export const DropFiles = (props: DropFilesProps) => {
    const { onDrop, displayString, fileTypes, multipleFiles } = props

    const theme = useTheme()
    const classes = useStyles()

    const generalFilesAccepted = {
        'image/*': ['.jpeg', '.jpg', '.png'],
        'application/msword': [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
        'application/pdf': [],
        'application/vnd.ms-excel': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    }

    return (
        <Dropzone
            onDrop={onDrop}
            accept={fileTypes ? fileTypes : generalFilesAccepted}
            multiple={multipleFiles}
        >
            {({ getRootProps, getInputProps }) => (
                <section style={{ width: '100%' }}>
                    <div {...getRootProps()} style={{ width: '100%' }}>
                        <input {...getInputProps()} type="file" />
                        <Container
                            style={{
                                padding: theme.spacing(3),
                                border: `1px solid ${theme.palette.grey[400]}`,
                                borderRadius: theme.shape.borderRadius,
                                flexDirection: 'column',
                                alignItems: 'center',
                                cursor: 'pointer',
                                width: '100%',
                            }}
                            className={classes.hoverGrey200}
                        >
                            <CloudUpload />
                            <span>
                                {displayString
                                    ? displayString
                                    : 'Drag and Drop your files here'}
                            </span>
                        </Container>
                    </div>
                </section>
            )}
        </Dropzone>
    )
}
