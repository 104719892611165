import { Grid, TableSortLabel, useTheme } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import { Container } from '../../components'
import { PermissionRole } from '../../models'
import { PermissionRoleRowType, WorkforceRowProps } from './types'
import { PermissionRow } from './PermissionRow'

interface Props {
    permissionRoleList: PermissionRole[]
    cellStyle: React.CSSProperties
    headCellStyle: React.CSSProperties
    setOpenDrawer: (open: boolean) => void
    setEditPermissionRole: (role: PermissionRole) => void
}

const RoleASC = 'RoleASC'
const RoleDESC = 'RoleDESC'
const NumPermissionsASC = 'NumPermissionsASC'
const NumPermissionsDESC = 'NumPermissionsDESC'

export const WorkforceNewPermissionTable = (props: Props) => {
    const {
        permissionRoleList,
        cellStyle,
        setOpenDrawer,
        setEditPermissionRole,
        headCellStyle,
    } = props

    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(1)
    const [orderBy, setOrderBy] = useState('')

    const theme = useTheme()

    const createPermissionRoleRow = (
        role: PermissionRole,
    ): PermissionRoleRowType => {
        const permissionCount = Object.entries(role).reduce(
            (prev, [key, value]) => {
                if (key !== 'name' && key !== 'organization' && key !== 'id') {
                    return prev + value
                }
                return prev
            },
            0,
        )
        return {
            name: role.name,
            numberOfPermissions: permissionCount,
            permissionRole: role,
        }
    }

    const [sortedRows, setSortedRows] = useState<PermissionRoleRowType[]>([])

    useEffect(() => {
        const rows = permissionRoleList.map((role: PermissionRole) =>
            createPermissionRoleRow(role),
        )
        setSortedRows(rows)
    }, [permissionRoleList])

    return (
        <Container scrollY style={{ flex: 1 }}>
            <Grid
                container={true}
                style={{
                    padding: theme.spacing(2, 1, 1, 0),
                }}
            >
                <Grid container={true}>
                    <Container
                        style={{
                            ...headCellStyle,
                            flex: 1,
                            marginLeft: theme.spacing(3),
                        }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== RoleASC) {
                                newRows.sort((RowA, RowB) =>
                                    RowA.name.localeCompare(RowB.name),
                                )
                                setSortedRows(newRows)
                                setOrderBy(RoleASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(RoleDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={orderBy === RoleASC || orderBy === RoleDESC}
                            direction={orderBy === RoleASC ? 'asc' : 'desc'}
                        >
                            Role
                        </TableSortLabel>
                    </Container>
                    <Container
                        style={{
                            ...headCellStyle,
                            flex: 4,
                            justifyContent: 'center',
                        }}
                        onClick={() => {
                            const newRows = [...sortedRows]
                            if (orderBy !== NumPermissionsASC) {
                                newRows.sort(
                                    (RowA, RowB) =>
                                        RowA.numberOfPermissions -
                                        RowB.numberOfPermissions,
                                )
                                setSortedRows(newRows)
                                setOrderBy(NumPermissionsASC)
                            } else {
                                setSortedRows(newRows.reverse())
                                setOrderBy(NumPermissionsDESC)
                            }
                        }}
                    >
                        <TableSortLabel
                            active={
                                orderBy === NumPermissionsASC ||
                                orderBy === NumPermissionsDESC
                            }
                            direction={
                                orderBy === NumPermissionsASC ? 'asc' : 'desc'
                            }
                        >
                            Number of Permissions
                        </TableSortLabel>
                    </Container>
                    <Container
                        style={{ ...headCellStyle, flex: 8 }}
                    ></Container>
                </Grid>
                <Grid
                    container={true}
                    style={{
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 400px)',
                    }}
                >
                    {sortedRows
                        .map((row, index) => {
                            return (
                                <PermissionRow
                                    row={row}
                                    cellStyle={cellStyle}
                                    setEditPermissionRole={
                                        setEditPermissionRole
                                    }
                                    setOpenDrawer={setOpenDrawer}
                                    key={index}
                                    index={index}
                                />
                            )
                        })
                        .slice(
                            (page - 1) * rowsPerPage,
                            (page - 1) * rowsPerPage + rowsPerPage,
                        )}
                </Grid>
                <Container style={{ flex: 1, justifyContent: 'flex-end' }}>
                    <Pagination
                        count={Math.ceil(sortedRows.length / rowsPerPage)}
                        page={page}
                        onChange={(_: unknown, newPage: number) => {
                            setPage(newPage)
                        }}
                        variant="outlined"
                        shape="rounded"
                        style={{ margin: theme.spacing(1, 0, 0, 0) }}
                    />
                </Container>
            </Grid>
        </Container>
    )
}
