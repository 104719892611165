import React, { useRef } from 'react'
import { Container } from '../Container'
import { Company } from '../../models'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import GetAppIcon from '@material-ui/icons/GetApp'
import PublishIcon from '@material-ui/icons/Publish'
import {
    Button,
    Divider,
    IconButton,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { axiosInstance } from '../../helpers'
import FileDownload from 'js-file-download'
import { toast } from 'react-toastify'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

interface Props {
    apartment?: Company
    onUpdateApartment: (updatedApt: Company) => void
    onPressContinue: () => void
}

export const OnboardApartmentFileForm = (props: Props) => {
    const theme = useTheme()

    const { apartment } = props

    if (apartment === undefined) {
        return (
            <Container style={{ flex: 1 }}>
                <span>Error</span>
            </Container>
        )
    }

    const uploadOnboardingFile = (file: File, file_key: string) => {
        const data = new FormData()
        data.append(file_key, file)

        axiosInstance
            .post(`company/apartment/${apartment.id}/onboard/`, data)
            .then((res) => {
                const updatedApt = res.data['company']

                console.log(res.data)

                if (res.data['errors'].length > 0) {
                    let errorMsg = ''
                    res.data['errors'].forEach((e: any) => {
                        errorMsg += `${e.message}\n`
                    })
                    toast.error(errorMsg)
                    return
                }

                props.onUpdateApartment(updatedApt)
                toast.success('Uploaded')
            })
            .catch((e) => {
                toast.error('Problem uploading the file')
            })
    }

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'column',
                padding: theme.spacing(2),
            }}
        >
            <Container>
                <span
                    style={{
                        ...theme.typography.h5,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Upload {apartment.name} Onboarding Files
                </span>

                <div style={{ flex: 1 }} />

                <Tooltip title="Reset Configuration">
                    <IconButton
                        onClick={() => {
                            axiosInstance
                                .post(
                                    `company/apartment/${apartment.id}/reset_config/`,
                                )
                                .then((res) => {
                                    props.onUpdateApartment(res.data)
                                })
                                .catch((e) => {
                                    toast.error(`error reseting config ${e}`)
                                })
                        }}
                    >
                        <DeleteForeverIcon />
                    </IconButton>
                </Tooltip>
            </Container>

            <Divider />

            <span
                style={{
                    ...theme.typography.h6,
                    marginTop: theme.spacing(1),
                }}
            >
                Optional
            </span>

            <Card
                title="1. Status Sheet"
                disabled={
                    apartment.inv_sheet_uploaded ||
                    apartment.area_sheet_uploaded
                }
                uploaded={apartment.status_group_sheet_uploaded}
                onUpload={(file) => {
                    const data = new FormData()
                    data.append('sheet', file)
                    axiosInstance
                        .post(
                            `company/apartment/${apartment.id}/status_sheet/`,
                            data,
                        )
                        .then((res) => {
                            if (res.data.errors.length > 0) {
                                console.log(res.data.errors)
                                let errorStr = ''
                                res.data.errors.forEach(
                                    (e: any) => (errorStr += `${e}\n`),
                                )
                                toast.error(errorStr, { autoClose: 5000 })
                                return
                            }

                            props.onUpdateApartment(res.data.company)
                            toast.success('Uploaded')
                        })
                }}
                onDownload={() => {
                    axiosInstance.get('').then((response) => {
                        FileDownload(
                            new Blob([response.data]),
                            `area-sheet-template.xlsx`,
                        )
                    })
                }}
            />

            <Card
                title="2. Inventory Sheet"
                uploaded={apartment.inv_sheet_uploaded}
                disabled={apartment.area_sheet_uploaded}
                onUpload={(file) => {
                    uploadOnboardingFile(file, 'inventory_sheet')
                }}
                onDownload={() => {
                    axiosInstance.get('').then((response) => {
                        FileDownload(
                            new Blob([response.data]),
                            `area-sheet-template.xlsx`,
                        )
                    })
                }}
            />

            <Divider />

            <span
                style={{
                    ...theme.typography.h6,
                    marginTop: theme.spacing(1),
                }}
            >
                Required
            </span>

            <Card
                title="1. Area Sheet"
                uploaded={apartment.area_sheet_uploaded}
                onUpload={(file) => {
                    uploadOnboardingFile(file, 'area_sheet')
                }}
                onDownload={() => {
                    axiosInstance.get('').then((response) => {
                        FileDownload(
                            new Blob([response.data]),
                            `area-sheet-template.xlsx`,
                        )
                    })
                }}
            />

            <Card
                title="2. Unit Sheet"
                disabled={!apartment.area_sheet_uploaded}
                uploaded={apartment.unit_sheet_uploaded}
                onUpload={(file) => {
                    uploadOnboardingFile(file, 'unit_sheet')
                }}
                onDownload={() => {
                    axiosInstance.get('').then((response) => {
                        FileDownload(
                            new Blob([response.data]),
                            `unit-sheet-template.xlsx`,
                        )
                    })
                }}
            />

            <Card
                title="3. Infrastructure Sheet"
                disabled={!apartment.unit_sheet_uploaded}
                uploaded={apartment.infrastructure_sheet_uploaded}
                onUpload={(file) => {
                    uploadOnboardingFile(file, 'infrastructure_sheet')
                }}
                onDownload={() => {
                    axiosInstance.get('').then((response) => {
                        FileDownload(
                            new Blob([response.data]),
                            `infrastructure-sheet-template.xlsx`,
                        )
                    })
                }}
            />

            <div style={{ flex: 1 }} />

            <Container style={{ justifyContent: 'flex-end' }}>
                <Button
                    disabled={
                        !apartment.area_sheet_uploaded ||
                        !apartment.unit_sheet_uploaded ||
                        !apartment.infrastructure_sheet_uploaded
                    }
                    variant="outlined"
                    color="primary"
                    onClick={props.onPressContinue}
                >
                    Continue
                </Button>
            </Container>
        </Container>
    )
}

interface CardProps {
    title: string
    uploaded: boolean
    disabled?: boolean
    onUpload: (file: File) => void
    onDownload: () => void
}

const Card = (props: CardProps) => {
    const theme = useTheme()

    const ref = useRef<HTMLInputElement>(null)

    return (
        <Container
            style={{
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
                alignItems: 'center',
            }}
        >
            <span style={{ ...theme.typography.subtitle1, width: 200 }}>
                {props.title}
            </span>

            {props.uploaded ? (
                <Tooltip title="">
                    <IconButton disabled>
                        <CheckCircleIcon color="primary" />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Upload File">
                    <IconButton
                        onClick={() => ref.current?.click()}
                        disabled={props.disabled}
                    >
                        <PublishIcon />
                    </IconButton>
                </Tooltip>
            )}

            {/* <Tooltip title="Download Template">
                <IconButton>
                    <GetAppIcon />
                </IconButton>
            </Tooltip> */}

            <input
                ref={ref}
                type="file"
                style={{ display: 'none' }}
                accept=".xlsx"
                onChange={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    if (e.target.files) {
                        const file = e.target.files[0]
                        props.onUpload(file)
                    }

                    // clear the input value so that onChange will trigger even if the user selects the same file
                    e.target.value = ''
                }}
            />
        </Container>
    )
}
