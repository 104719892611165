import {
    Button,
    Chip,
    CircularProgress,
    FormControl,
    FormControlLabel,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container, MessageDrawer } from '../../components'
import SearchIcon from '@material-ui/icons/Search'
import { useCompany, useUser } from '../../hooks'
import { NatPropListTable } from './NatPropListTable'
import {
    Company,
    EDIT_VENDOR_SUBSCRIPTION_STATUS,
    IdBoolMap,
    ListChannel,
    ListVendor,
    PlanStatus,
    Service,
} from '../../models'
import { axiosInstance, statesList } from '../../helpers'
import { useServiceContract } from '../../hooks/useServiceContract'
import { useMultiSelectStyles } from '../../styles'
import { ReactComponent as EZToken } from '../../assets/EZToken.svg'
import { EZTokenModal } from './EZTokenModal'
import { BuyPropertyModal } from './BuyPropertyModal'
import { hasPermission } from '../../models/Users/services'
import { PropertyDetailModal } from './PropertyDetailModal'

const PENDING = 'PENDING'
const INCOMPLETE = 'INCOMPLETE'

export interface ApartmentVendorChannel {
    channel: ListChannel
    apartment?: Company
    vendor?: ListVendor
}

export interface ApartmentVendor {
    channel_id: number
    apartment: Company
    vendor: ListVendor
    vendor_see_unit_notes: boolean
    entrata_id: number | null
    entrata_gl_account_id: number | null
    entrata_location_id: string | null
}

export const NationalPropertyList = () => {
    const theme = useTheme()

    const classes = useMultiSelectStyles()

    const [searchText, setSearchText] = useState('')
    const [loadingState, setLoadingState] = useState(true)
    const [selectedProperty, setSelectedProperty] = useState<
        Company | undefined
    >()

    const [serviceList, setServiceList] = useState<Service[]>([])

    const [vendorPlan, setVendorPlan] = useState(PlanStatus.PREMIUM)
    const [availableTokens, setAvailableTokens] = useState<number>(0)
    const [unlockedPropertyMap, setUnlockedPropertyMap] = useState<IdBoolMap>(
        {},
    )

    const [goodServiceList, setGoodServiceList] = useState<
        Service[] | undefined
    >([])

    const [selectedLocations, setSelectedLocations] = useState<string[]>([])

    const [messageDrawerOpen, setMessageDrawerOpen] = useState(false)

    const [apartmentVendorChannels, setApartmentVendorChannels] = useState<
        ApartmentVendorChannel[]
    >([])

    const [openEZTokenModal, setOpenEZTokenModal] = useState(false)
    const [openBuyPropertyModal, setOpenBuyPropertyModal] = useState(false)
    const [openPropertyDetail, setOpenPropertyDetail] = useState(false)

    const [displayUnlockedProperties, setDisplayUnlockedProperties] = useState(
        false,
    )

    const { workspaceUser } = useUser()
    const { getApartmentList, apartmentList } = useCompany()

    const disabled = !hasPermission(
        workspaceUser,
        EDIT_VENDOR_SUBSCRIPTION_STATUS,
    )

    useEffect(() => {
        const apartmentListPromise = getApartmentList({
            params: { all_apartments: true },
        })
        const vendorPromise = axiosInstance.get(
            `company/vendor/${workspaceUser?.active_workspace.id}`,
        )
        const channelListPromise = axiosInstance.get('messaging/channel/', {
            params: {
                channel_type: 'APARTMENT_VENDOR',
            },
        })
        const apartmentVendorPromise = axiosInstance.get(
            'company/apartment_vendors/',
        )
        const unlockedPropertiesPromise = axiosInstance.get(
            `company/apartment/`,
            {
                params: {
                    unlocked_properties: true,
                },
            },
        )

        const VENDOR_INDEX = 1
        const CHANNEL_INDEX = 2
        const APARTMENT_VENDOR_INDEX = 3
        const UNLOCKED_PROPERTY_INDEX = 4

        Promise.all([
            apartmentListPromise,
            vendorPromise,
            channelListPromise,
            apartmentVendorPromise,
            unlockedPropertiesPromise,
        ])
            .then((values) => {
                setServiceList(values[VENDOR_INDEX].data.services)
                setVendorPlan(values[VENDOR_INDEX].data.plan)
                setAvailableTokens(values[VENDOR_INDEX].data.tokens)
                const tempChannelIds: number[] = []
                const tempApartmentVendorChannels: ApartmentVendorChannel[] = []
                values[CHANNEL_INDEX].data.map((channel: ListChannel) => {
                    tempApartmentVendorChannels.push({
                        channel: channel,
                    })
                })
                values[APARTMENT_VENDOR_INDEX].data.map(
                    (apartmentVendor: ApartmentVendor) => {
                        tempApartmentVendorChannels.map((aptVendorChannel) => {
                            if (
                                aptVendorChannel.channel.id ===
                                apartmentVendor.channel_id
                            ) {
                                aptVendorChannel.apartment =
                                    apartmentVendor.apartment
                                aptVendorChannel.vendor = apartmentVendor.vendor
                            }
                        })
                    },
                )
                setApartmentVendorChannels(tempApartmentVendorChannels)
                const tempUnlockedPropertyMap: IdBoolMap = {}
                values[UNLOCKED_PROPERTY_INDEX].data.map(
                    (property: Company) => {
                        tempUnlockedPropertyMap[property.id] = true
                    },
                )
                setUnlockedPropertyMap(tempUnlockedPropertyMap)
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                setLoadingState(false)
            })
    }, [])

    const apartmentFilter = (property: Company) => {
        let inFilter = property.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
        if (selectedLocations.length !== 0) {
            inFilter = inFilter && selectedLocations.includes(property.state)
        }

        if (displayUnlockedProperties) {
            inFilter = inFilter && unlockedPropertyMap[property.id]
        }

        return inFilter
    }

    return vendorPlan === PlanStatus.PREMIUM ? (
        <Container
            direction="column"
            style={{ padding: theme.spacing(3), flex: 1 }}
        >
            <Container
                style={{
                    fontSize: '15px',
                    fontWeight: 400,
                    color: theme.palette.darkGreen.main,
                }}
            >
                <Container>National Property List</Container>
                <Container flex={1} />
                <Container justifyContent="flex-end">
                    {availableTokens} : EZ Tokens
                </Container>
            </Container>
            <Container
                style={{
                    fontSize: '40px',
                    fontWeight: 700,
                    marginTop: theme.spacing(2),
                }}
            >
                Property List
            </Container>
            <Container>
                <TextField
                    variant="outlined"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    style={{
                        margin: theme.spacing(1, 0, 2, 0),
                    }}
                />
                <FormControlLabel
                    labelPlacement="start"
                    label="Unlocked Properties"
                    style={{ marginRight: theme.spacing(2) }}
                    control={
                        <Switch
                            checked={displayUnlockedProperties}
                            color="primary"
                            onClick={() =>
                                setDisplayUnlockedProperties(
                                    !displayUnlockedProperties,
                                )
                            }
                        />
                    }
                />
                <Container style={{ flex: 1 }} />
                <FormControl className={classes.formControl}>
                    <InputLabel id="property-location-label">
                        Location
                    </InputLabel>
                    <Select
                        labelId="property-location-label"
                        id="mutiple-chip-property-location"
                        fullWidth
                        style={{ minWidth: '100px', maxWidth: '250px' }}
                        multiple
                        value={selectedLocations}
                        MenuProps={{
                            getContentAnchorEl: () => {
                                return (null as unknown) as Element
                            },
                        }}
                        onChange={(
                            event: React.ChangeEvent<{
                                value: unknown
                            }>,
                        ) => {
                            const selectedStates = event.target
                                .value as string[]
                            setSelectedLocations(selectedStates)
                        }}
                        input={
                            <Input id="select-multiple-chip-property-location" />
                        }
                        renderValue={(selected) => (
                            <div className={classes.chips}>
                                {(selected as string[]).map((value) => {
                                    const vnd = statesList.find(
                                        (s) => s === value,
                                    )
                                    return (
                                        <Chip
                                            key={`LOCATION_CHIP_${
                                                vnd ? vnd : -1
                                            }`}
                                            label={vnd ? vnd : 'unknown'}
                                            className={classes.chip}
                                        />
                                    )
                                })}
                            </div>
                        )}
                    >
                        {statesList.map((state) => {
                            return (
                                <MenuItem
                                    key={`SLOCATION_MENU_ITEM_${state}`}
                                    value={state}
                                >
                                    {state}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <Container alignItems="center">
                    <Container>
                        <IconButton
                            onClick={() => setOpenEZTokenModal(true)}
                            disabled={disabled}
                        >
                            <EZToken />
                        </IconButton>
                    </Container>
                    <Container
                        style={{
                            fontSize: '15px',
                            color: theme.palette.primary.dark,
                        }}
                    >
                        : EZ Tokens
                    </Container>
                </Container>
            </Container>
            <Container style={{ flex: 1 }}>
                {loadingState ? (
                    <Container
                        style={{
                            paddingTop: theme.spacing(20),
                            justifyContent: 'center',
                            flex: 1,
                        }}
                    >
                        <CircularProgress color="primary" size="5rem" />
                    </Container>
                ) : (
                    <NatPropListTable
                        properties={apartmentList.filter(apartmentFilter)}
                        setSelectedProperty={setSelectedProperty}
                        vendorPlan={vendorPlan}
                        setMessageDrawerOpen={setMessageDrawerOpen}
                        apartmentVendorChannels={apartmentVendorChannels}
                        setApartmentVendorChannels={setApartmentVendorChannels}
                        unlockedPropertyMap={unlockedPropertyMap}
                        setOpenBuyProperty={setOpenBuyPropertyModal}
                        setOpenPropertyDetail={setOpenPropertyDetail}
                    />
                )}
            </Container>
            <MessageDrawer
                title={`${selectedProperty?.name}`}
                open={messageDrawerOpen}
                onClose={() => setMessageDrawerOpen(false)}
            />
            <EZTokenModal
                open={openEZTokenModal}
                handleClose={() => setOpenEZTokenModal(false)}
            />
            {selectedProperty && (
                <BuyPropertyModal
                    open={openBuyPropertyModal}
                    handleClose={() => setOpenBuyPropertyModal(false)}
                    property={selectedProperty}
                    availableTokens={availableTokens}
                    unlockedPropertyMap={unlockedPropertyMap}
                    setUnlockedPropertyMap={setUnlockedPropertyMap}
                    setAvailableTokens={setAvailableTokens}
                />
            )}
            {selectedProperty && (
                <PropertyDetailModal
                    open={openPropertyDetail}
                    property={selectedProperty}
                    handleClose={() => setOpenPropertyDetail(false)}
                />
            )}
        </Container>
    ) : (
        <Container>
            You do not have access to this page. Please upgrade your plan to
            gain access.
        </Container>
    )
}
