import 'react-responsive-carousel/lib/styles/carousel.min.css'
import React, { useEffect, useState } from 'react'

import { Carousel } from 'react-responsive-carousel'

import { Modal, Paper, Slide, Tab, Tabs, Theme } from '@material-ui/core'

import { LeaseModalFormState } from '../../types'
import { useLease } from '../../../../hooks'
import { useSelectionMap } from '../../../../hooks/useSelectionMap'
import { headerH, modalH, modalW } from './constants'
import { LeaseListPage } from './LeaseListPage'
import { CreateMoveInEventPage } from './CreateMoveInEventPage'
import { Container } from '../../../../components'
import { MoveInEventList } from './MoveInEventList'

import { useMoveInInspection } from '../../../../hooks/useMoveInInspection'
import { toast } from 'react-toastify'
import { MoveInEventDetail } from './MoveInEventDetail'

interface Props {
    theme: Theme
    open: LeaseModalFormState | null
    handleClose: () => void
}

export const LeaseModal = (props: Props) => {
    const { theme, open, handleClose } = props

    const selectionController = useSelectionMap()
    const leaseController = useLease()

    const moveInController = useMoveInInspection()

    const [selectedPage, setSelectedPage] = useState(0)

    let selectedDate = new Date()

    if (open) {
        const dateStr = open.leaseCluster.date
        selectedDate = new Date(dateStr)
        selectedDate.setTime(
            selectedDate.getTime() +
                selectedDate.getTimezoneOffset() * 60 * 1000,
        )
    }

    useEffect(() => {
        selectionController.clearSelections()
        if (open && open.leaseCluster.count > 0) {
            leaseController.getLeaseList({
                params: {
                    move_in_date: selectedDate.toISOString(),
                    is_child: false,
                    exclude_children: true,
                    home: true,
                },
            })

            moveInController.getMoveInInspectionEvents({
                date: selectedDate.toISOString(),
            })
        } else {
            leaseController.setLeaseList([])
        }
    }, [open])

    return (
        <Modal
            open={open !== null}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open !== null}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: theme.shape.borderRadius,
                        minHeight: modalH,
                        maxHeight: modalH,
                        minWidth: modalW,
                        maxWidth: modalW,
                    }}
                >
                    <Container
                        style={{
                            minHeight: headerH,
                            maxHeight: headerH,
                        }}
                    >
                        <Tabs
                            style={{}}
                            value={selectedPage}
                            onChange={(_, v) => {
                                setSelectedPage(v)
                            }}
                        >
                            <Tab label="Leases" />
                            <Tab label="Scheduled move in inspections" />
                        </Tabs>
                    </Container>
                    <Carousel
                        swipeable={false}
                        showThumbs={false}
                        showArrows={false}
                        showIndicators={false}
                        showStatus={false}
                        selectedItem={selectedPage}
                    >
                        <LeaseListPage
                            theme={theme}
                            handleClose={handleClose}
                            leaseController={leaseController}
                            selectionController={selectionController}
                            selectedDate={selectedDate}
                            onClickScheduleMoveIn={() => {
                                setSelectedPage(2)
                            }}
                        />

                        <MoveInEventList
                            theme={theme}
                            moveInController={moveInController}
                            selectedDate={selectedDate}
                            selectEvent={(moveInEvent) => {
                                moveInController.setSelectedMoveInEventId(
                                    moveInEvent.id,
                                )
                                setSelectedPage(3)
                            }}
                        />

                        <CreateMoveInEventPage
                            theme={theme}
                            onConfirm={async (daysToComplete, selectedDate) => {
                                const selectedLeases = selectionController.getSelectedItems(
                                    leaseController.leaseList,
                                )

                                await moveInController.queueLeaseMoveIns(
                                    selectedLeases,
                                    daysToComplete,
                                    selectedDate,
                                )
                                selectionController.clearSelections()
                                setSelectedPage(0)
                                toast.success('Move in inspection scheduled')
                            }}
                            onClickBack={() => {
                                setSelectedPage(0)
                            }}
                            disabled={
                                selectionController.selectionMap.length === 0
                            }
                            selectedDate={selectedDate}
                        />
                        <MoveInEventDetail
                            theme={theme}
                            onClickBack={() => setSelectedPage(1)}
                            moveInController={moveInController}
                        />
                    </Carousel>
                </Paper>
            </Slide>
        </Modal>
    )
}
