import React, { useEffect, useRef, useState } from 'react'

import {
    Button,
    Modal,
    Paper,
    Slide,
    Theme,
    IconButton,
} from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { Container, MessageForm } from '../../../../components'
import { FormState } from '../../types'
import { ApartmentDeadline, DeadlineDetail } from '../../../../models'
import { toMMDDYYYY } from '../../../../helpers'
import { DeadlineRow } from './DeadlineRow'
import { useUser } from '../../../../hooks'

interface Props {
    theme: Theme
    formState: FormState
    deadlineList: ApartmentDeadline[]
    onClose: () => void
    getDeadlineDetail: (deadlineId: number) => Promise<DeadlineDetail>
    transitionDeadline: (
        deadlineId: number,
        complete: boolean,
    ) => Promise<DeadlineDetail>
    incrementMessageCount: (deadline: ApartmentDeadline) => void
}

export const DeadlineDetailModal = (props: Props) => {
    const {
        theme,
        onClose,
        formState,
        getDeadlineDetail,
        transitionDeadline,
        incrementMessageCount,
        deadlineList,
    } = props

    const [deadlineDetail, setDeadlineDetail] = useState<DeadlineDetail | null>(
        null,
    )

    const [openMessageChannel, setOpenMessageChannel] = useState<number | null>(
        null,
    )

    const { workspaceUser } = useUser()

    const Ref = useRef<any>(null)

    // The state for our timer
    const [timer, setTimer] = useState({
        total: 0,
        seconds: 0,
        minutes: 0,
        hours: 0,
        days: 0,
    })

    const getTimeRemaining = (deadlineDate: Date) => {
        const total = deadlineDate.getTime() - new Date().getTime()
        const seconds = Math.floor((total / 1000) % 60)
        const minutes = Math.floor((total / 1000 / 60) % 60)
        const hours = Math.floor((total / 1000 / 60 / 60) % 24)
        const days = Math.floor(total / 1000 / 60 / 60 / 24)
        return {
            total,
            hours,
            minutes,
            seconds,
            days,
        }
    }

    const tickTimer = (deadlineDate: Date) => {
        const remaining = getTimeRemaining(deadlineDate)
        if (remaining.total >= 0) {
            setTimer(remaining)
        }
    }

    const startTimer = (date: Date) => {
        tickTimer(date)
        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current)
        const id = setInterval(() => {
            tickTimer(date)
        }, 1000)
        Ref.current = id
    }

    // const open = formState.popovers.deadlinePopover.open
    const open = formState.modals.deadlineModal.open
    const apartmentDeadline = deadlineList.find(
        (dl) => dl.id === formState.modals.deadlineModal.deadlineId,
    )

    useEffect(() => {
        if (open && apartmentDeadline) {
            getDeadlineDetail(apartmentDeadline.deadline.id).then(
                (deadlineDetail) => {
                    setDeadlineDetail(deadlineDetail)
                },
            )
            startTimer(deadlineDate)
        }

        if (!open) {
            setDeadlineDetail(null)
            setOpenMessageChannel(null)
        }
    }, [formState])

    const deadlineDate = new Date(apartmentDeadline?.deadline.date ?? '')

    let descriptionLabel = apartmentDeadline?.deadline.description

    if (descriptionLabel === '' || descriptionLabel === undefined) {
        descriptionLabel = 'N/A'
    }

    let activeAptDetail: ApartmentDeadline | null = null
    const otherAptDetails: ApartmentDeadline[] = []

    deadlineDetail?.apartment_deadlines.forEach((aptDeadline) => {
        if (workspaceUser?.active_workspace.id === aptDeadline.apartment.id) {
            activeAptDetail = apartmentDeadline ?? null
        } else {
            otherAptDetails.push(aptDeadline)
        }
    })

    const setMessageDrawerOpen = (channelId: number) => {
        if (openMessageChannel === channelId) {
            setOpenMessageChannel(null)
        } else {
            setOpenMessageChannel(channelId)
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        minHeight: h,
                        maxHeight: h,
                        minWidth: w,
                        maxWidth: w,
                    }}
                >
                    {/* Content */}
                    <Container
                        style={{
                            flexDirection: 'column',
                            flex: 1,
                            overflowY: 'scroll',
                        }}
                    >
                        {/* Title */}
                        <Container
                            style={{
                                minHeight: 100,
                                maxHeight: 100,
                                borderBottom: `1px solid ${theme.palette.grey[700]}`,
                                flex: 1,
                                padding: theme.spacing(1),
                                alignItems: 'center',
                            }}
                        >
                            <Container style={{ flexDirection: 'column' }}>
                                <span
                                    style={{
                                        ...theme.typography.h6,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        color: theme.palette.grey[800],
                                    }}
                                >
                                    {apartmentDeadline?.deadline.name}
                                </span>
                                <span
                                    style={{
                                        ...theme.typography.subtitle2,
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                        color: theme.palette.grey[700],
                                    }}
                                >
                                    Due: {toMMDDYYYY(deadlineDate)}
                                </span>
                            </Container>
                            <div style={{ flex: 1 }} />
                            <Container
                                style={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <span
                                    style={{
                                        ...theme.typography.subtitle2,
                                        fontWeight:
                                            theme.typography.fontWeightLight,
                                        color: theme.palette.grey[700],
                                    }}
                                >
                                    Time remaining
                                </span>
                                <span
                                    style={{
                                        ...theme.typography.subtitle1,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        color: theme.palette.grey[800],
                                    }}
                                >
                                    {timer.days} Days {timer.hours} Hours{' '}
                                    {timer.minutes} Minutes {timer.seconds}{' '}
                                    Seconds
                                </span>
                            </Container>
                            <div style={{ flex: 1 }} />
                        </Container>
                        {/* Body */}

                        <Container style={{ flex: 1 }}>
                            <Container
                                style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    overflow: 'hidden',
                                }}
                            >
                                {/* Row 1, Description */}
                                <Container
                                    style={{
                                        padding: theme.spacing(1),
                                        flexDirection: 'column',
                                    }}
                                >
                                    <span
                                        style={{
                                            ...theme.typography.subtitle2,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                            color: theme.palette.grey[800],
                                        }}
                                    >
                                        Description
                                    </span>
                                    <span
                                        style={{
                                            ...theme.typography.body1,
                                            fontWeight:
                                                theme.typography
                                                    .fontWeightLight,
                                            color: theme.palette.grey[700],
                                        }}
                                    >
                                        {descriptionLabel}
                                    </span>
                                </Container>

                                {/* Row 2 Active Workspace */}
                                <Container
                                    style={{
                                        padding: theme.spacing(1),
                                        flexDirection: 'column',
                                    }}
                                >
                                    <span
                                        style={{
                                            ...theme.typography.subtitle2,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                            color: theme.palette.grey[800],
                                        }}
                                    >
                                        Your Workspace
                                    </span>
                                    {activeAptDetail && (
                                        <DeadlineRow
                                            theme={theme}
                                            workspaceName={
                                                workspaceUser?.active_workspace
                                                    .name ?? ''
                                            }
                                            apartmentDeadline={activeAptDetail}
                                            selectedChannel={openMessageChannel}
                                            handleClickMessage={
                                                setMessageDrawerOpen
                                            }
                                            transitionDeadline={(
                                                id,
                                                status,
                                            ) => {
                                                transitionDeadline(
                                                    id,
                                                    status,
                                                ).then((deadline) => {
                                                    setDeadlineDetail(deadline)
                                                })
                                            }}
                                        />
                                    )}
                                </Container>

                                {/* Row 3 Other workspace deadlines */}
                                <Container
                                    style={{
                                        padding: theme.spacing(1),
                                        flexDirection: 'column',
                                    }}
                                >
                                    {otherAptDetails.length > 0 && (
                                        <span
                                            style={{
                                                ...theme.typography.subtitle2,
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                                color: theme.palette.grey[800],
                                            }}
                                        >
                                            Additional Workspaces
                                        </span>
                                    )}
                                    {otherAptDetails.map((deadline) => {
                                        return (
                                            <DeadlineRow
                                                key={`DEADLINE-${deadline.id}`}
                                                workspaceName={
                                                    deadline.apartment.name
                                                }
                                                handleClickMessage={
                                                    setMessageDrawerOpen
                                                }
                                                theme={theme}
                                                apartmentDeadline={deadline}
                                                selectedChannel={
                                                    openMessageChannel
                                                }
                                            />
                                        )
                                    })}
                                </Container>
                            </Container>

                            {openMessageChannel !== null && (
                                <Container
                                    style={{
                                        width: 400,
                                        borderLeft: `1px solid ${theme.palette.grey[700]}`,
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Container
                                        style={{
                                            padding: theme.spacing(1),
                                            alignItems: 'center',
                                        }}
                                    >
                                        <IconButton
                                            onClick={() => {
                                                setOpenMessageChannel(null)
                                            }}
                                        >
                                            <ChevronRightIcon />
                                        </IconButton>

                                        <span
                                            style={{
                                                ...theme.typography.h5,
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                                marginLeft: theme.spacing(1),
                                            }}
                                        >
                                            Messages
                                        </span>
                                    </Container>
                                    <MessageForm
                                        theme={theme}
                                        channelId={openMessageChannel}
                                        afterCreateMessage={() => {
                                            if (apartmentDeadline) {
                                                incrementMessageCount(
                                                    apartmentDeadline,
                                                )
                                            }
                                        }}
                                    />
                                </Container>
                            )}
                        </Container>

                        {/* Footer */}
                        <Container
                            style={{
                                minHeight: 100,
                                maxHeight: 100,
                                borderTop: `1px solid ${theme.palette.grey[700]}`,
                                flex: 1,
                                padding: theme.spacing(1),
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{
                                    width: 150,
                                    textTransform: 'none',
                                    height: 50,
                                    marginRight: theme.spacing(1),
                                }}
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <div style={{ flex: 1 }} />
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{
                                    width: 150,
                                    textTransform: 'none',
                                    height: 50,
                                    marginRight: theme.spacing(1),
                                }}
                                onClick={() => {
                                    if (open && apartmentDeadline) {
                                        getDeadlineDetail(
                                            apartmentDeadline.deadline.id,
                                        ).then((deadlineDetail) => {
                                            console.log(deadlineDetail)
                                        })
                                    }
                                }}
                            >
                                Refresh
                            </Button>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

const DESIRED_SIZE_PERCENT = 0.8

const screenH = window.innerHeight
const screenW = window.innerWidth
const aspectRatio = screenH / screenW

const h = screenH * DESIRED_SIZE_PERCENT
const w = screenW * DESIRED_SIZE_PERCENT * aspectRatio
