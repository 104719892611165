import {
    CircularProgress,
    Divider,
    IconButton,
    InputAdornment,
    Modal,
    Paper,
    Slide,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { getRFPListRequest, rfpApi, useRFPState } from '../../contexts'
import { Container } from '../../components'
import CloseIcon from '@material-ui/icons/Close'
import { usePagination, useService } from '../../hooks'
import { Pagination } from '@material-ui/lab'
import SearchIcon from '@material-ui/icons/Search'
import { Tune } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { RFPFilterDrawer } from './RFPFilterDrawer'
import { RFPFilterChips } from './RFPFilterChips'
import { RFPCard } from './RFPCard'

interface Props {
    open: boolean
    handleClose: () => void
}

export const ManagerRFPModal = (props: Props) => {
    const { open, handleClose } = props

    const {
        getRFPList,
        loadingState,
        filteredRFPList,
        rfpFilterState,
        setRFPFilterState,
    } = rfpApi()

    const [openFilterDrawer, setOpenFilterDrawer] = useState(false)

    const theme = useTheme()

    const { page, setPage, pageData, numPages, start, end } = usePagination(
        filteredRFPList ?? [],
    )

    const serviceList = useSelector(
        (state: RootState) => state.service.serviceList,
    )

    const { getServiceList } = useService()

    useEffect(() => {
        if (open) {
            const request: getRFPListRequest = {
                params: {
                    all_published_rfps: true,
                },
            }
            getRFPList(request)
            getServiceList({})
        }
    }, [open])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper>
                    <Container
                        style={{
                            width: 1500,
                            maxWidth: 'calc(100vw - 100px)',
                            height: 1100,
                            maxHeight: 'calc(100vh - 100px)',
                            display: 'flex',
                            overflow: 'hidden',
                            flexDirection: 'column',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            padding: theme.spacing(2),
                        }}
                    >
                        <Container>
                            <Container
                                style={{
                                    alignItems: 'center',
                                    fontWeight: 600,
                                    fontSize: '20px',
                                }}
                            >
                                Published RFPs
                            </Container>
                            <div style={{ flex: 1 }} />
                            <Container justifyContent="flex-end">
                                <Tooltip title="Close">
                                    <IconButton
                                        onClick={() => {
                                            handleClose()
                                        }}
                                    >
                                        <CloseIcon
                                            fontSize="inherit"
                                            style={{
                                                marginRight: theme.spacing(1),
                                                color:
                                                    theme.palette.darkGreen
                                                        .main,
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Container>
                        </Container>
                        <Divider
                            style={{ margin: theme.spacing(1, 0, 1, 0) }}
                        />
                        <Container
                            alignItems="center"
                            style={{ margin: theme.spacing(2, 0) }}
                        >
                            <TextField
                                variant="outlined"
                                placeholder="Search"
                                value={rfpFilterState.searchText}
                                onChange={(e) => {
                                    setRFPFilterState({
                                        ...rfpFilterState,
                                        searchText: e.target.value,
                                    })
                                }}
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Container
                                style={{
                                    alignItems: 'center',
                                    marginLeft: theme.spacing(2),
                                }}
                            >
                                <RFPFilterChips
                                    serviceList={serviceList}
                                    rfpFilterState={rfpFilterState}
                                    setRFPFilterState={setRFPFilterState}
                                />
                            </Container>
                            <Container flex={1} />
                            <Tooltip title="Filter">
                                <IconButton
                                    onClick={() => setOpenFilterDrawer(true)}
                                >
                                    <Tune fontSize="large" color={'primary'} />
                                </IconButton>
                            </Tooltip>
                        </Container>
                        <Container direction="column">
                            {loadingState.getRFPList ? (
                                <Container
                                    alignItems="center"
                                    justifyContent="center"
                                    style={{
                                        height: 850,
                                        maxHeight: 'calc(100vh - 350px)',
                                    }}
                                >
                                    <CircularProgress size={100} />
                                </Container>
                            ) : (
                                <Container
                                    flexWrap="wrap"
                                    style={{
                                        overflowY: 'auto',
                                        height: 850,
                                        maxHeight: 'calc(100vh - 350px)',
                                    }}
                                >
                                    {pageData.map((rfp) => {
                                        return (
                                            <RFPCard
                                                rfp={rfp}
                                                key={`MANAGER_RFP_CARD_${rfp.id}`}
                                                theme={theme}
                                                managerView
                                            />
                                        )
                                    })}
                                </Container>
                            )}
                            <Container
                                style={{
                                    borderTop: `1px solid ${theme.palette.grey[400]}`,
                                    padding: theme.spacing(1),
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Pagination
                                    count={numPages}
                                    page={page}
                                    onChange={(_: unknown, newPage: number) => {
                                        setPage(newPage)
                                    }}
                                />
                                <span>
                                    {start} - {end} of{' '}
                                    {filteredRFPList.length ?? 0}
                                </span>
                            </Container>
                        </Container>
                        <RFPFilterDrawer
                            open={openFilterDrawer}
                            serviceList={serviceList}
                            onClose={() => setOpenFilterDrawer(false)}
                        />
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
