import React, { useState } from 'react'

import { toast } from 'react-toastify'

import NumberFormat from 'react-number-format'

import {
    Grid,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import { BaseProps } from '../../types'
import { TemplateServiceVendor } from '../../../../models'
import { VENDOR_BAR_H } from '../../constants'
import { Container } from '../../../Container'

interface Props extends BaseProps {
    templateVendor: TemplateServiceVendor
    expectedThroughput: number
}

export const TemplateVendorRow = (props: Props) => {
    const {
        theme,
        templateVendor,
        scheduleController: tsController,
        expectedThroughput,
    } = props

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const [capacity, setCapacity] = useState(templateVendor.capacity)

    return (
        <Grid
            xs={12}
            container
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: theme.spacing(1),
                height: VENDOR_BAR_H,
                boxSizing: 'border-box',
                borderTop: `1px solid ${theme.palette.grey[100]}`,
            }}
        >
            <Grid item xs={4} style={{ overflow: 'hidden' }}>
                <span style={{ ...theme.typography.h5 }}>
                    {templateVendor.vendor.name}
                </span>
            </Grid>
            <Grid item xs={3}>
                <Container
                    style={{
                        flexDirection: 'column',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.caption,
                            fontWeight: theme.typography.fontWeightLight,
                        }}
                    >
                        Daily
                    </span>

                    <NumberFormat
                        value={capacity}
                        onBlur={() => {
                            tsController
                                .updateTemplateServiceVendor(
                                    templateVendor.template_service,
                                    templateVendor.id,
                                    { capacity: capacity },
                                )
                                .catch(() => {
                                    toast.error(
                                        'There was a problem with your request',
                                    )
                                })
                        }}
                        onChange={(e) => {
                            const value = Number(e.target.value)
                            if (isNaN(value)) {
                                return
                            }
                            setCapacity(value)
                        }}
                        style={{
                            ...theme.typography.subtitle2,
                            fontWeight: theme.typography.fontWeightBold,
                            textAlign: 'center',
                            border: `1px solid ${theme.palette.grey[400]}`,
                            borderRadius: theme.shape.borderRadius,
                            padding: theme.spacing(0.25, 1),
                            width: 60,
                        }}
                    />
                </Container>
            </Grid>

            <Grid item xs={4}>
                <Container
                    style={{
                        flexDirection: 'column',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.caption,
                            fontWeight: theme.typography.fontWeightLight,
                        }}
                    >
                        Avg Output
                    </span>
                    <NumberFormat
                        value={expectedThroughput}
                        readOnly
                        decimalScale={1}
                        displayType="text"
                        style={{
                            ...theme.typography.subtitle2,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    />
                </Container>
            </Grid>

            <Grid xs={1} container>
                <IconButton
                    size="small"
                    style={{ marginLeft: theme.spacing(1) }}
                    onClick={openMenu}
                >
                    <MoreVertIcon />
                </IconButton>
            </Grid>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        tsController
                            .deleteTemplateServiceVendor(
                                templateVendor.template_service,
                                templateVendor.id,
                            )
                            .then(() => {
                                toast.success('Deleted!')
                            })
                            .catch(() => {
                                toast.error(
                                    'There was a problem with your request',
                                )
                            })
                            .finally(() => {
                                handleClose()
                            })
                    }}
                >
                    <ListItemIcon>
                        <DeleteForeverIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                        Remove {templateVendor.vendor.name}
                    </ListItemText>
                </MenuItem>
            </Menu>
        </Grid>
    )
}
