import React, { useEffect, useMemo, useState } from 'react'
import {
    BreadCrumbs,
    Container,
    NumberInput,
    Selector,
    SideDrawer,
    Swiper,
} from '../../components'
import {
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    useTheme,
} from '@material-ui/core'

import {
    ActionItemInspectionStatusTrigger,
    CustomStatus,
    EntrataWorkOrderConfig,
    InventoryConfig,
    ListVendor,
    Service,
    User,
    emptyEntrataWorkorderConfig,
} from '../../models'
import { axiosInstance } from '../../helpers'
import { toast } from 'react-toastify'
import { useEntrataWorkOrderPickList } from '../../hooks'

interface Props {
    open: boolean
    userList: User[]
    vendorList: ListVendor[]
    serviceList: Service[]
    inventoryConfigList: InventoryConfig[]
    customStatusList: CustomStatus[]
    handleClose: () => void
    trigger?: ActionItemInspectionStatusTrigger
    handleSave?: (trigger: ActionItemInspectionStatusTrigger) => void
    handleDelete?: (trigger: ActionItemInspectionStatusTrigger) => void
}

export const ActionItemTriggerDrawer = (props: Props) => {
    const { open, handleClose, trigger } = props
    const theme = useTheme()

    const [editMode, setEditMode] = useState(false)

    const [title, setTitle] = useState('')
    const [serviceId, setServiceId] = useState<number>(-1)
    const [startDateBuffer, setStartDateBuffer] = useState(0)
    const [duration, setDuration] = useState(1)
    const [priority, setPriority] = useState(false)

    const [assignToMode, setAssignToMode] = useState(ASSIGN_TO_USER)
    const [assignToUser, setAssignToUser] = useState<number>(-1)
    const [assignToVendor, setAssignToVendor] = useState<number>(-1)
    const [selectedInventory, setSelectedInventory] = useState<number>(-1)
    const [selectedStatus, setSelectedStatus] = useState<number>(-1)

    const { pickList } = useEntrataWorkOrderPickList()

    const [entrataDrawerOpen, setEntrataDrawerOpen] = useState(false)

    const [
        entrataWorkorderConfig,
        setEntrataWorkorderConfig,
    ] = useState<EntrataWorkOrderConfig>({ ...emptyEntrataWorkorderConfig })

    const clearState = () => {
        setTitle('')
        setServiceId(-1)
        setStartDateBuffer(0)
        setDuration(1)
        setPriority(false)
        setEditMode(false)
        setAssignToUser(-1)
        setAssignToVendor(-1)
        setSelectedInventory(-1)
        setSelectedStatus(-1)
        setEntrataWorkorderConfig({ ...emptyEntrataWorkorderConfig })
    }

    useEffect(() => {
        if (trigger) {
            setTitle(trigger.title ?? '')
            setServiceId(trigger.service?.id ?? -1)
            setStartDateBuffer(trigger.start_date_buffer_days)
            setDuration(trigger.days_to_complete)
            setPriority(trigger.priority)
            setAssignToUser(trigger.assign_to_user?.id ?? -1)
            setAssignToVendor(trigger.assign_to_company?.id ?? -1)
            setSelectedInventory(trigger.inventory_config?.id ?? -1)
            setSelectedStatus(trigger.trigger_status.id)

            setEntrataWorkorderConfig(
                trigger.entrata_workorder_config
                    ? { ...trigger.entrata_workorder_config }
                    : { ...emptyEntrataWorkorderConfig },
            )
        } else {
            clearState()
            setEditMode(true)
        }

        return clearState
    }, [trigger])

    const filteredInventory = useMemo(() => {
        return props.inventoryConfigList.filter((inv) => {
            if (selectedStatus === -1) {
                return true
            }

            return inv.custom_status_list?.find((status) => {
                return status.id === selectedStatus
            })
        })
    }, [selectedStatus])

    const propertyDict = pickList?.result?.Property ?? {}
    const propertyIds = Object.keys(propertyDict)
    const property = propertyDict[Number(propertyIds[0])]
    const maintenanceLocations = property?.Locations
    const maintenanceCategories = property?.Categories?.Category
    const maintenanceProblems = property?.Problems?.Problem
    const maintenancePriorities = property?.Priorities?.Priority

    const selectorStyle = {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }

    return (
        //Assign Drawer
        <>
            <SideDrawer
                open={open}
                handleClose={handleClose}
                title="Action Item Trigger"
            >
                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        padding: theme.spacing(1),
                    }}
                    scrollY
                >
                    <TextField
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        label="Action Item Title"
                    />

                    <Selector
                        data={props.customStatusList}
                        currentValue={selectedStatus}
                        onChange={(e) => {
                            setSelectedStatus(Number(e.target.value))
                            setSelectedInventory(-1)
                        }}
                        label="Status (Required)"
                        getDisplayString={(d) => d.name}
                        variant="standard"
                        customStyle={{
                            formControl: {
                                margin: 0,
                                marginTop: theme.spacing(8),
                            },
                        }}
                        searchable
                    />

                    <Selector
                        data={[{ id: -1, name: 'All' }, ...filteredInventory]}
                        currentValue={selectedInventory}
                        onChange={(e) =>
                            setSelectedInventory(Number(e.target.value))
                        }
                        label="Inventory"
                        getDisplayString={(d) => d.name}
                        variant="standard"
                        customStyle={{
                            formControl: {
                                margin: 0,
                                marginTop: theme.spacing(8),
                                marginBottom: theme.spacing(8),
                            },
                        }}
                        searchable
                    />

                    <BreadCrumbs
                        options={['My Team', 'My Vendors']}
                        selectedItem={assignToMode ? 1 : 0}
                        onChange={() => setAssignToMode(!assignToMode)}
                    />

                    <Swiper
                        position={assignToMode}
                        onChangePosition={setAssignToMode}
                        leftItem={
                            <FormControl fullWidth>
                                <Select
                                    value={assignToUser}
                                    label="My Team"
                                    placeholder="My Team"
                                    required={assignToMode === ASSIGN_TO_USER}
                                    onChange={(e) =>
                                        setAssignToUser(Number(e.target.value))
                                    }
                                >
                                    <MenuItem
                                        key={`NO_USER_SELECTED`}
                                        value={-1}
                                    >
                                        Not Assigned
                                    </MenuItem>
                                    {props.userList.map((user) => {
                                        return (
                                            <MenuItem
                                                key={`SELECT_USER_${user.id}`}
                                                value={user.id}
                                            >
                                                {user.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        }
                        rightItem={
                            <FormControl fullWidth>
                                <Select
                                    required={
                                        assignToMode === ASSIGN_TO_COMPANY
                                    }
                                    value={assignToVendor}
                                    placeholder="My Vendors"
                                    label="My Vendors"
                                    onChange={(e) =>
                                        setAssignToVendor(
                                            Number(e.target.value),
                                        )
                                    }
                                >
                                    <MenuItem
                                        key={`NO_VENDOR_SELECTED`}
                                        value={-1}
                                    >
                                        Not Assigned
                                    </MenuItem>
                                    {props.vendorList.map((vnd) => {
                                        return (
                                            <MenuItem
                                                key={`SELECT_VENDOR_${vnd.id}`}
                                                value={vnd.id}
                                            >
                                                {vnd.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        }
                    />

                    <Container>
                        <FormControlLabel
                            style={{ margin: 0, marginTop: theme.spacing(8) }}
                            labelPlacement="top"
                            control={
                                <Switch
                                    color="primary"
                                    checked={priority}
                                    onChange={() => setPriority((p) => !p)}
                                />
                            }
                            label="Priority"
                        />
                    </Container>

                    <Container style={{ marginTop: theme.spacing(8) }}>
                        <NumberInput
                            style={{
                                width: 100,
                                marginRight: theme.spacing(2),
                            }}
                            value={startDateBuffer}
                            onChange={(e) =>
                                setStartDateBuffer(Number(e.target.value))
                            }
                            label="Buffer"
                        />

                        <NumberInput
                            style={{ width: 100 }}
                            value={duration}
                            onChange={(e) =>
                                setDuration(Number(e.target.value))
                            }
                            label="Duration"
                        />
                    </Container>

                    <Selector
                        data={[
                            { id: -1, name: 'None Selected' },
                            ...props.serviceList,
                        ]}
                        currentValue={serviceId}
                        onChange={(e) => setServiceId(Number(e.target.value))}
                        label="Service"
                        getDisplayString={(d) => d.name}
                        variant="standard"
                        customStyle={{
                            formControl: {
                                margin: 0,
                                marginTop: theme.spacing(8),
                            },
                        }}
                        searchable
                    />

                    <Button
                        variant="outlined"
                        style={{ marginTop: theme.spacing(1) }}
                        onClick={() => setEntrataDrawerOpen(true)}
                    >
                        Edit Entrata Sync Config
                    </Button>
                </Container>

                <Container
                    style={{
                        borderTop: `1px solid ${theme.palette.grey[300]}`,
                        height: 100,

                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        padding: theme.spacing(1),
                    }}
                >
                    {trigger && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            style={{ marginRight: theme.spacing(1) }}
                            onClick={() => {
                                props.handleDelete &&
                                    props.handleDelete(trigger)
                                props.handleClose()
                            }}
                        >
                            Delete
                        </Button>
                    )}

                    <div style={{ flex: 1 }} />

                    <Button
                        variant="outlined"
                        color="secondary"
                        style={{ marginRight: theme.spacing(1) }}
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            let assignToUserValue: number | null = null
                            let assignToVendorValue: number | null = null
                            if (
                                assignToMode === ASSIGN_TO_COMPANY &&
                                assignToVendor !== -1
                            ) {
                                assignToVendorValue = assignToVendor
                            }

                            if (
                                assignToMode === ASSIGN_TO_USER &&
                                assignToUser !== -1
                            ) {
                                assignToUserValue = assignToUser
                            }

                            const serviceValue =
                                serviceId === -1 ? null : serviceId

                            const inventoryConfigId =
                                selectedInventory === -1
                                    ? null
                                    : selectedInventory

                            if (selectedStatus === -1) {
                                console.log('error')
                                return
                            }

                            let axiosMethod = axiosInstance.post
                            let url =
                                'apt_config/action-item-inspection-status-trigger/'

                            if (trigger) {
                                axiosMethod = axiosInstance.patch
                                url += `${trigger.id}/`
                            }

                            axiosMethod(url, {
                                assign_to_user: assignToUserValue,
                                assign_to_company: assignToVendorValue,
                                priority: priority,
                                service: serviceValue,
                                inventory_config: inventoryConfigId,
                                title: title,
                                trigger_status: selectedStatus,
                                start_date_buffer_days: startDateBuffer,
                                days_to_complete: duration,
                            }).then((res) => {
                                props.handleSave && props.handleSave(res.data)
                                toast.success('Success')
                                props.handleClose()
                            })
                        }}
                    >
                        Save
                    </Button>
                </Container>
            </SideDrawer>

            <SideDrawer
                open={entrataDrawerOpen}
                handleClose={() => setEntrataDrawerOpen(false)}
                title="Entrata Sync Config"
            >
                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        padding: theme.spacing(1),
                    }}
                    scrollY
                >
                    <TextField
                        value={entrataWorkorderConfig?.buildingName}
                        placeholder="Building Name"
                        onChange={(e) => {
                            setEntrataWorkorderConfig((oldConfig) => {
                                return {
                                    ...oldConfig,
                                    buildingName: e.target.value,
                                }
                            })
                        }}
                    />
                    <TextField
                        value={entrataWorkorderConfig?.unitNumber}
                        placeholder="Unit Number"
                        onChange={(e) => {
                            setEntrataWorkorderConfig((oldConfig) => {
                                return {
                                    ...oldConfig,
                                    unitNumber: e.target.value,
                                }
                            })
                        }}
                    />

                    <TextField
                        value={entrataWorkorderConfig?.customerFirstName}
                        placeholder="Customer First Name"
                        onChange={(e) => {
                            setEntrataWorkorderConfig((oldConfig) => {
                                return {
                                    ...oldConfig,
                                    customerFirstName: e.target.value,
                                }
                            })
                        }}
                    />

                    <TextField
                        value={entrataWorkorderConfig?.customerLastName}
                        placeholder="Customer Last Name"
                        onChange={(e) => {
                            setEntrataWorkorderConfig((oldConfig) => {
                                return {
                                    ...oldConfig,
                                    customerLastName: e.target.value,
                                }
                            })
                        }}
                    />

                    <TextField
                        value={entrataWorkorderConfig?.emailAddress}
                        placeholder="Email"
                        onChange={(e) => {
                            setEntrataWorkorderConfig((oldConfig) => {
                                return {
                                    ...oldConfig,
                                    emailAddress: e.target.value,
                                }
                            })
                        }}
                    />

                    <TextField
                        value={entrataWorkorderConfig?.problemDescription}
                        placeholder="Problem Description"
                        onChange={(e) => {
                            setEntrataWorkorderConfig((oldConfig) => {
                                return {
                                    ...oldConfig,
                                    problemDescription: e.target.value,
                                }
                            })
                        }}
                    />

                    <TextField
                        value={entrataWorkorderConfig?.petsDescription}
                        placeholder="Pet Description"
                        onChange={(e) => {
                            setEntrataWorkorderConfig((oldConfig) => {
                                return {
                                    ...oldConfig,
                                    petsDescription: e.target.value,
                                }
                            })
                        }}
                    />

                    <FormControl style={selectorStyle}>
                        <InputLabel id="_1">Maintenance Location</InputLabel>
                        <Select
                            label="Maintenance Location"
                            fullWidth
                            displayEmpty
                            labelId="_1"
                            value={
                                entrataWorkorderConfig.maintenanceLocationId ??
                                -1
                            }
                            onChange={(e) => {
                                console.log(e.target.value)
                                setEntrataWorkorderConfig((oldConfig) => {
                                    return {
                                        ...oldConfig,
                                        maintenanceLocationId:
                                            Number(e.target.value) ?? null,
                                    }
                                })
                            }}
                        >
                            <MenuItem key="-1" value={-1}>
                                None
                            </MenuItem>

                            {maintenanceLocations?.Location?.map((mLoc) => {
                                return (
                                    <MenuItem
                                        key={`${mLoc['@attributes'].Id}`}
                                        value={mLoc['@attributes'].Id}
                                    >
                                        {mLoc['@attributes'].Type}
                                        {' - '}
                                        {mLoc['@attributes'].Name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <FormControl style={selectorStyle}>
                        <InputLabel id="_2">Maintenance Category</InputLabel>
                        <Select
                            labelId="_2"
                            displayEmpty
                            fullWidth
                            label="Maintenance Category"
                            value={
                                entrataWorkorderConfig.maintenanceCategoryId ??
                                -1
                            }
                            onChange={(e) => {
                                setEntrataWorkorderConfig((oldConfig) => {
                                    return {
                                        ...oldConfig,
                                        maintenanceCategoryId:
                                            Number(e.target.value) ?? null,
                                    }
                                })
                            }}
                        >
                            <MenuItem key="-1" value={-1}>
                                None
                            </MenuItem>

                            {maintenanceCategories?.map((mCat) => {
                                return (
                                    <MenuItem
                                        key={`${mCat['@attributes'].Id}`}
                                        value={mCat['@attributes'].Id}
                                    >
                                        {mCat['@attributes'].Name}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <FormControl style={selectorStyle}>
                        <InputLabel>Maintenance Problem</InputLabel>
                        <Select
                            fullWidth
                            displayEmpty
                            value={
                                entrataWorkorderConfig.maintenanceProblemId ??
                                -1
                            }
                            onChange={(e) => {
                                setEntrataWorkorderConfig((oldConfig) => {
                                    return {
                                        ...oldConfig,
                                        maintenanceProblemId:
                                            Number(e.target.value) ?? null,
                                    }
                                })
                            }}
                        >
                            <MenuItem key="-1" value={-1}>
                                None
                            </MenuItem>

                            {maintenanceProblems?.map((mProblem) => {
                                return (
                                    <MenuItem
                                        key={`${mProblem['@attributes'].Id}`}
                                        value={mProblem['@attributes'].Id}
                                    >
                                        {mProblem['@attributes'].Name} {' - '}
                                        {mProblem['@attributes'].CategoryId}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <FormControl style={selectorStyle}>
                        <InputLabel>Priority</InputLabel>
                        <Select
                            fullWidth
                            displayEmpty
                            value={
                                entrataWorkorderConfig.maintenancePriorityId ??
                                -1
                            }
                            onChange={(e) => {
                                setEntrataWorkorderConfig((oldConfig) => {
                                    return {
                                        ...oldConfig,
                                        maintenancePriorityId:
                                            Number(e.target.value) ?? null,
                                    }
                                })
                            }}
                        >
                            <MenuItem key="-1" value={-1}>
                                None
                            </MenuItem>

                            {maintenancePriorities?.map((mPriority) => {
                                return (
                                    <MenuItem
                                        key={`${mPriority['@attributes'].Id}`}
                                        value={mPriority['@attributes'].Id}
                                    >
                                        {mPriority['@attributes'].Name} {' - '}
                                        {mPriority['@attributes'].Code}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <div style={{ flex: 1 }} />

                    <Container
                        style={{
                            borderTop: `1px solid ${theme.palette.grey[300]}`,
                            height: 100,

                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            padding: theme.spacing(1),
                        }}
                    >
                        <div style={{ flex: 1 }} />

                        <Button
                            variant="outlined"
                            color="secondary"
                            style={{ marginRight: theme.spacing(1) }}
                            onClick={() => setEntrataDrawerOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                if (trigger === undefined) {
                                    return
                                }

                                axiosInstance
                                    .post(
                                        `apt_config/action-item-inspection-status-trigger/${trigger.id}/set_entrata_workorder_config/`,
                                        {
                                            entrata_workorder_config: entrataWorkorderConfig,
                                        },
                                    )
                                    .then((res) => {
                                        props.handleSave &&
                                            props.handleSave(res.data)
                                        setEntrataDrawerOpen(false)
                                    })
                            }}
                        >
                            Save
                        </Button>
                    </Container>
                </Container>
            </SideDrawer>
        </>
    )
}

const ASSIGN_TO_USER = false
const ASSIGN_TO_COMPANY = true
