import React from 'react'

import clsx from 'clsx'
import {
    createStyles,
    makeStyles,
    useTheme,
    Theme,
} from '@material-ui/core/styles'
import { Drawer } from '@material-ui/core'

import Logo from '../../../../assets/ezos-logo.png'

import SideBarNavigationItems from './SideBarNavigationItems'
import { useUser } from '../../../../hooks'

interface Props {
    drawerWidth: number
    open: Boolean
}
const SideBar = (props: Props) => {
    const { workspaceUser } = useUser()

    const theme = useTheme()

    const sideBarColor =
        workspaceUser?.active_workspace.company_type === 'VENDOR'
            ? theme.palette.lightGrey.main
            : theme.palette.darkBlue.main
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            menuButton: {
                marginRight: 36,
            },
            hide: {
                display: 'none',
            },
            drawer: {
                width: props.drawerWidth,
                flexShrink: 0,
                whiteSpace: 'nowrap',
                backgroundColor: sideBarColor,
                color: '#737378',
                zIndex: 0,
                [theme.breakpoints.down('xs')]: {
                    display: 'none',
                },
            },
            drawerOpen: {
                width: props.drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                backgroundColor: sideBarColor,
                color: '#737378',
            },
            drawerClose: {
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                overflowX: 'hidden',
                width: `${props.drawerWidth}px !important`,
                // width: theme.spacing(7) + 1,
                [theme.breakpoints.up('sm')]: {
                    width: `${props.drawerWidth}px !important`,
                },
                backgroundColor: sideBarColor,
                color: '#737378',
            },
            toolbar: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: theme.spacing(0, 1),
                // necessary for content to be below app bar
                ...theme.mixins.toolbar,
            },
        }),
    )
    const classes = useStyles()

    return (
        <React.Fragment>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: props.open,
                    [classes.drawerClose]: !props.open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: props.open,
                        [classes.drawerClose]: !props.open,
                    }),
                }}
            >
                <div className={classes.toolbar}>
                    {/* {props.open ? (
                        <IconButton
                            onClick={props.handleDrawerClose}
                            style={{ color: '#FFF', float: 'right' }}
                        >
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    ) : (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={props.handleDrawerOpen}
                            // className={clsx(classes.menuButton, {
                            //     [classes.hide]: props.open,
                            // })}
                            style={{ color: '#FFF', float: 'right' }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )} */}
                </div>
                <img
                    src={Logo}
                    alt="EZ Turn"
                    style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '100px',
                        marginBottom: '20px',
                    }}
                />
                {/* <Divider /> */}
                <SideBarNavigationItems open={props.open} />
            </Drawer>
        </React.Fragment>
    )
}

export default SideBar
