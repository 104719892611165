import React, { Dispatch, SetStateAction, useState } from 'react'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    Modal,
    Paper,
    Slide,
    Step,
    StepButton,
    Stepper,
    Switch,
    TextField,
    Theme,
    Tooltip,
    useTheme,
} from '@material-ui/core'

import { Container } from '../../../../components'
import {
    LeaseAuditFilterState,
    LeaseAuditLoadingState,
    LeaseAuditModalState,
    MatchLeasesRequest,
    UpdateDDLeaseRequest,
    UpdateLeaseAuditRequest,
} from '../../Hooks'
import SearchIcon from '@material-ui/icons/Search'
import {
    AuditPromptConfig,
    DDLease,
    LeaseAudit,
    LeaseAuditStep,
    MatchedLease,
} from '../../../../models'
import { DropFiles } from '../../../../components/DropFiles'
import { ReactComponent as XLSXLogo } from '../../../../assets/xlsx_icon.svg'
import { ReactComponent as EmptyEditIcon } from '../../../../assets/edit-icon-empty.svg'
import {
    ArrowBack,
    BarChart,
    DeleteForever,
    FileCopy,
    GetApp,
    GetAppOutlined,
    PictureAsPdf,
    TableChart,
} from '@material-ui/icons'
import {
    PurchaseLeaseAuditRequest,
    useStripe,
} from '../../../../hooks/useStripe'
import { axiosInstance, getDifferenceInDays } from '../../../../helpers'
import { LeaseAuditRentVariance } from './Components/LeaseAuditRentVariance'
import { LeaseAuditPDFUpload } from './Components/LeaseAuditPDFUpload'
import { LeaseAuditBaseLine } from './Components/LeaseAuditBaseLine'
import { PromptResults } from './Components/PromptResults'
import { TableModal } from './Modals/TableModal/TableModal'
import { DDLeaseEditForm } from './Components/DDLeaseEditForm'
import { LeaseAuditAnalytics } from './Components/LeaseAuditAnalytics'
import { MatchedLeaseRow } from './Components/DDMatchedLeaseRow'
import { UnmatchedPDFLeaseCard } from './Components/UnmatchedPDFLeaseCard'
import { PDFLeaseUploadStatusModal } from './Components/PDFLeaseUploadStatusModal'

interface Props {
    unmatchedPdfLeases: DDLease[]
    matchedLeases: MatchedLease[]
    loadingState: LeaseAuditLoadingState
    leaseAuditFilterState: LeaseAuditFilterState
    filteredMatchedLeases: MatchedLease[]
    filteredUnmatchedPDFLeases: DDLease[]
    leaseAudit: LeaseAudit
    pendingCount: number
    selectedDDLease: DDLease | null
    numberUploaded: number
    promptConfigs: AuditPromptConfig[]
    modalState: LeaseAuditModalState
    setLeaseAuditFilterState: Dispatch<SetStateAction<LeaseAuditFilterState>>
    uploadRentRoll: (file: File, leaseAuditID: number) => void
    uploadLeasePDF: (files: File[], leaseAuditID: number) => Promise<void>
    matchLeases: (request: MatchLeasesRequest) => void
    unmatchLeases: (leaseMatchId: number) => void
    deletePDFLease: (pdfLeaseId: number) => void
    setLeaseAudit: Dispatch<SetStateAction<LeaseAudit | null>>
    getLeaseAuditList: () => void
    setSelectedDDLease: Dispatch<SetStateAction<DDLease | null>>
    updateDDLease: (request: UpdateDDLeaseRequest) => Promise<DDLease>
    getLeaseAuditSummary: (id: number) => Promise<undefined>
    getRentRollTemplate: () => Promise<undefined>
    getRentRollExample: () => Promise<undefined>
    removeAuditBasePDF: (id: number) => void
    updateLeaseAudit: (request: UpdateLeaseAuditRequest) => Promise<LeaseAudit>
    setModalState: Dispatch<SetStateAction<LeaseAuditModalState>>
    getSelectedConfigs: () => AuditPromptConfig[]
    getLeaseAudit: (leaseAuditID: number) => void
    getAllPDFLeases: (leaseAuditId: number) => Promise<DDLease[]>
    generateExcelReport: (leaseAuditId: number) => Promise<undefined>
}

export const LeaseAuditDetailPage = (props: Props) => {
    const {
        unmatchedPdfLeases,
        matchedLeases,
        loadingState,
        leaseAuditFilterState,
        filteredMatchedLeases,
        filteredUnmatchedPDFLeases,
        leaseAudit,
        pendingCount,
        selectedDDLease,
        numberUploaded,
        promptConfigs,
        modalState,
        setLeaseAuditFilterState,
        uploadRentRoll,
        uploadLeasePDF,
        matchLeases,
        unmatchLeases,
        deletePDFLease,
        setLeaseAudit,
        getLeaseAuditList,
        setSelectedDDLease,
        updateDDLease,
        getLeaseAuditSummary,
        getRentRollExample,
        getRentRollTemplate,
        removeAuditBasePDF,
        updateLeaseAudit,
        setModalState,
        getSelectedConfigs,
        getLeaseAudit,
        getAllPDFLeases,
        generateExcelReport,
    } = props

    const [selectedConfigs, setSelectedConfigs] = useState<number[]>([])

    const { purchaseLeaseAudit } = useStripe()

    const [rentRoll, setRentRoll] = useState<File | null>(null)
    const [pdfFiles, setPDFFiles] = useState<File[]>([])

    const theme = useTheme()

    const stepButtonStyle: React.CSSProperties = {
        ...theme.typography.caption,
        color: theme.palette.grey[600],
        fontWeight: theme.typography.fontWeightMedium,
    }
    const activeStepButtonStyle: React.CSSProperties = {
        ...theme.typography.body2,
        color: theme.palette.grey[600],
        fontWeight: theme.typography.fontWeightMedium,
    }

    let pendingUpload = 0
    if (pdfFiles.length - numberUploaded > 0) {
        pendingUpload = pdfFiles.length - numberUploaded
    }

    if (loadingState.getLeaseAuditData || !leaseAudit) {
        return (
            <Container
                style={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress
                    size={100}
                    style={{ marginTop: theme.spacing(10) }}
                />
            </Container>
        )
    }

    let daysRemaining = 0
    if (
        leaseAudit.pdf_upload_deadline &&
        leaseAudit.pdf_upload_deadline !== ''
    ) {
        const today = new Date()
        daysRemaining = getDifferenceInDays(
            new Date(leaseAudit.pdf_upload_deadline),
            today,
        )
    }
    const displayPDFUpload =
        !leaseAudit.pdf_upload_deadline || daysRemaining > 0

    return (
        <Container
            style={{
                flexDirection: 'column',
                overflowY: 'hidden',
                flex: 1,
            }}
        >
            {/* Header */}
            <Container
                style={{
                    padding: theme.spacing(1),
                    height:
                        leaseAudit.steps === LeaseAuditStep.MATCHING ? 30 : 80,
                    borderBottom:
                        leaseAudit.steps !== LeaseAuditStep.MATCHING
                            ? `1px solid ${theme.palette.grey[400]}`
                            : '',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: theme.spacing(2),
                }}
            >
                <IconButton
                    style={{
                        ...theme.typography.body2,
                        cursor: 'pointer',
                        color: theme.palette.darkGreen.main,
                        justifyContent: 'flex-start',
                    }}
                    onClick={() => {
                        setLeaseAudit(null)
                        getLeaseAuditList()
                    }}
                    size="small"
                >
                    <ArrowBack style={{ marginRight: theme.spacing(0.5) }} />{' '}
                    Back
                </IconButton>
                {leaseAudit.steps !== LeaseAuditStep.MATCHING && (
                    <Stepper
                        nonLinear
                        activeStep={leaseAudit.steps - 1}
                        style={{ backgroundColor: 'inherit' }}
                    >
                        <Step completed={leaseAudit.steps > LeaseAuditStep.PAY}>
                            <StepButton disabled>
                                <span style={stepButtonStyle}>Payment</span>
                            </StepButton>
                        </Step>
                        <Step
                            completed={
                                leaseAudit.steps >
                                LeaseAuditStep.UPLOAD_RENT_ROLL
                            }
                        >
                            <StepButton disabled>
                                <span style={stepButtonStyle}>
                                    Rent Roll Upload
                                </span>
                            </StepButton>
                        </Step>
                        <Step
                            completed={
                                leaseAudit.steps > LeaseAuditStep.BASELINE
                            }
                        >
                            <StepButton disabled>
                                <span style={stepButtonStyle}>
                                    Audit Configuration
                                </span>
                            </StepButton>
                        </Step>
                        <Step
                            completed={
                                leaseAudit.steps > LeaseAuditStep.UPLOAD_LEASES
                            }
                        >
                            <StepButton disabled>
                                <span style={stepButtonStyle}>
                                    Lease Upload
                                </span>
                            </StepButton>
                        </Step>
                        <Step
                            completed={
                                leaseAudit.steps >
                                LeaseAuditStep.PROCESSING_LEASES
                            }
                        >
                            <StepButton disabled>
                                <span style={stepButtonStyle}>Processing</span>
                            </StepButton>
                        </Step>
                        <Step>
                            <StepButton disabled>
                                <span style={stepButtonStyle}>Audit</span>
                            </StepButton>
                        </Step>
                    </Stepper>
                )}
            </Container>

            {/* Body */}

            {leaseAudit.steps === LeaseAuditStep.PAY && (
                <PayStep
                    leaseAudit={leaseAudit}
                    theme={theme}
                    purchaseLeaseAudit={purchaseLeaseAudit}
                />
            )}

            {leaseAudit.steps === LeaseAuditStep.UPLOAD_RENT_ROLL && (
                <RentRollStep
                    leaseAudit={leaseAudit}
                    rentRoll={rentRoll}
                    rentRollUploading={loadingState.rentRollUploading}
                    theme={theme}
                    setRentRoll={setRentRoll}
                    uploadRentRoll={uploadRentRoll}
                    getRentRollExample={getRentRollExample}
                    getRentRollTemplate={getRentRollTemplate}
                />
            )}

            {leaseAudit.steps === LeaseAuditStep.BASELINE && (
                <LeaseAuditBaseLine
                    leaseAudit={leaseAudit}
                    promptConfigs={promptConfigs}
                    loadingState={loadingState}
                    removeAuditBasePDF={removeAuditBasePDF}
                    updateLeaseAudit={updateLeaseAudit}
                    selectedConfigs={selectedConfigs}
                    setSelectedConfigs={setSelectedConfigs}
                />
            )}

            {leaseAudit.steps === LeaseAuditStep.UPLOAD_LEASES && (
                <LeaseAuditPDFUpload
                    displayPDFUpload={displayPDFUpload}
                    pdfsUploading={loadingState.pdfsUploading}
                    numberUploaded={numberUploaded}
                    leaseAudit={leaseAudit}
                    pdfFiles={pdfFiles}
                    uploadLeasePDF={uploadLeasePDF}
                    setPDFFiles={setPDFFiles}
                />
            )}

            {leaseAudit.steps >= LeaseAuditStep.PROCESSING_LEASES && (
                <MatchingStep
                    leaseAudit={leaseAudit}
                    getSelectedConfigs={getSelectedConfigs}
                    getLeaseAudit={getLeaseAudit}
                    selectedConfigs={selectedConfigs}
                    matchedLeases={matchedLeases}
                    unmatchedPdfLeases={unmatchedPdfLeases}
                    theme={theme}
                    openAnalytics={modalState.openAnalytics}
                    openLeaseTable={modalState.openLeaseTable}
                    pendingCount={pendingCount}
                    leaseAuditFilterState={leaseAuditFilterState}
                    filteredMatchedLeases={filteredMatchedLeases}
                    displayPDFUpload={displayPDFUpload}
                    filteredUnmatchedPDFLeases={filteredUnmatchedPDFLeases}
                    pendingUpload={pendingUpload}
                    getAllPDFLeases={getAllPDFLeases}
                    getLeaseAuditSummary={getLeaseAuditSummary}
                    setLeaseAuditFilterState={setLeaseAuditFilterState}
                    updateDDLease={updateDDLease}
                    matchLeases={matchLeases}
                    unmatchLeases={unmatchLeases}
                    setSelectedDDLease={setSelectedDDLease}
                    deletePDFLease={deletePDFLease}
                    setOpenAnalytics={(open) => {
                        setModalState({
                            ...modalState,
                            openAnalytics: open,
                        })
                    }}
                    setOpenLeaseTable={(open) => {
                        setModalState({
                            ...modalState,
                            openLeaseTable: open,
                        })
                    }}
                    setOpenEditLeaseModal={(open) => {
                        setModalState({
                            ...modalState,
                            openEditLease: open,
                        })
                    }}
                    setOpenLeasePDFModal={(open) => {
                        setModalState({
                            ...modalState,
                            openLeasePDFView: open,
                        })
                    }}
                    setOpenPDFUploadModal={(open) => {
                        setModalState({
                            ...modalState,
                            openPDFUpload: open,
                        })
                    }}
                    setOpenPromptResultsModal={(open) => {
                        setModalState({
                            ...modalState,
                            openPromptResults: open,
                        })
                    }}
                    updateLeaseAudit={updateLeaseAudit}
                    loadingState={loadingState}
                    generateExcelReport={generateExcelReport}
                />
            )}

            {selectedDDLease && (
                <Modal
                    open={modalState.openEditLease}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClose={() => {
                        setModalState({
                            ...modalState,
                            openEditLease: false,
                        })
                        setSelectedDDLease(null)
                    }}
                >
                    <Slide direction="up" in={modalState.openEditLease}>
                        <Paper
                            style={{
                                display: 'flex',
                                width: 600,
                                flexDirection: 'column',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: theme.typography.fontWeightBold,
                                    color: theme.palette.grey[800],
                                    margin: theme.spacing(1),
                                }}
                            >
                                Edit Lease
                            </span>
                            <DDLeaseEditForm
                                lease={selectedDDLease}
                                updateDDLease={updateDDLease}
                                handleClose={() => {
                                    setModalState({
                                        ...modalState,
                                        openEditLease: false,
                                    })
                                    setSelectedDDLease(null)
                                }}
                            />
                        </Paper>
                    </Slide>
                </Modal>
            )}

            {selectedDDLease && selectedDDLease.lease && (
                <Modal
                    open={modalState.openLeasePDFView}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClose={() => {
                        setModalState({
                            ...modalState,
                            openLeasePDFView: false,
                        })
                        setSelectedDDLease(null)
                    }}
                >
                    <Slide direction="up" in={modalState.openLeasePDFView}>
                        <Paper
                            style={{
                                display: 'flex',
                                height: '80%',
                                width: '90%',
                                flexDirection: 'column',
                            }}
                        >
                            <iframe
                                width="100%"
                                height="100%"
                                loading="lazy"
                                src={`${selectedDDLease.lease}`}
                            ></iframe>
                        </Paper>
                    </Slide>
                </Modal>
            )}

            <Modal
                open={modalState.openPDFUpload}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClose={() => {
                    setModalState({
                        ...modalState,
                        openPDFUpload: false,
                    })
                }}
            >
                <Slide direction="up" in={modalState.openPDFUpload}>
                    <Paper
                        style={{
                            display: 'flex',
                            width: 800,
                            flexDirection: 'column',
                            maxHeight: 'calc(100vh - 200px)',
                            minHeight: 'calc(100vh - 200px)',
                        }}
                    >
                        <LeaseAuditPDFUpload
                            displayPDFUpload={displayPDFUpload}
                            pdfsUploading={loadingState.pdfsUploading}
                            numberUploaded={numberUploaded}
                            leaseAudit={leaseAudit}
                            pdfFiles={pdfFiles}
                            uploadLeasePDF={uploadLeasePDF}
                            handleAfterUpload={() => {
                                setModalState({
                                    ...modalState,
                                    openPDFUpload: false,
                                })
                            }}
                            setPDFFiles={setPDFFiles}
                        />
                    </Paper>
                </Slide>
            </Modal>

            {selectedDDLease && (
                <Modal
                    open={modalState.openPromptResults}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClose={() => {
                        setModalState({
                            ...modalState,
                            openPromptResults: false,
                        })
                    }}
                >
                    <Slide direction="up" in={modalState.openPromptResults}>
                        <Paper
                            style={{
                                display: 'flex',
                                maxWidth: 'calc(100vw - 300px)',
                                minWidth: '600px',
                                flexDirection: 'column',
                                maxHeight: 'calc(100vh - 100px)',
                            }}
                        >
                            <PromptResults
                                lease={selectedDDLease}
                                leaseAudit={leaseAudit}
                                theme={theme}
                                promptConfigs={promptConfigs}
                                selectedConfigs={selectedConfigs}
                            />
                        </Paper>
                    </Slide>
                </Modal>
            )}
        </Container>
    )
}

interface PayStepProps {
    leaseAudit: LeaseAudit
    theme: Theme
    purchaseLeaseAudit: (request: PurchaseLeaseAuditRequest) => void
}

const PayStep = (props: PayStepProps) => {
    const { leaseAudit, theme, purchaseLeaseAudit } = props

    return (
        <Container
            style={{
                padding: theme.spacing(2),
                border: `1px solid ${theme.palette.grey[500]}`,
                borderRadius: theme.shape.borderRadius,
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: theme.spacing(5),
                width: '500px',
                alignSelf: 'center',
            }}
        >
            <Container
                style={{
                    ...theme.typography.h6,
                    fontWeight: theme.typography.fontWeightMedium,
                    color: theme.palette.grey[600],
                }}
            >
                Purchase Your Lease Audit
            </Container>
            <Container
                style={{
                    ...theme.typography.h5,
                    color: theme.palette.grey[700],
                    fontWeight: theme.typography.fontWeightBold,
                    margin: theme.spacing(2, 0),
                }}
            >
                $150
            </Container>
            <Container
                style={{
                    ...theme.typography.subtitle2,
                    color: theme.palette.grey[600],
                    fontWeight: theme.typography.fontWeightMedium,
                    marginBottom: theme.spacing(4),
                    textAlign: 'center',
                }}
            >
                Upon purchase of a Lease Audit you will have 30 days to upload
                your Lease PDFs. After the 30 day timeframe, the ability to
                upload Lease PDFs will be restricted. The ability to compare
                uploaded rent roll leases and uploaded pdf leases is always
                available after purchase.
            </Container>
            <Container>
                <Button
                    variant="contained"
                    style={{
                        ...theme.typography.subtitle1,
                        margin: theme.spacing(2),
                        backgroundColor: theme.palette.primary.dark,
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        const body: PurchaseLeaseAuditRequest = {
                            success_url: window.location.href,
                            lease_audit: leaseAudit.id,
                        }
                        purchaseLeaseAudit(body)
                    }}
                >
                    Purchase
                </Button>
            </Container>
        </Container>
    )
}

interface RentRollStepProps {
    leaseAudit: LeaseAudit
    rentRoll: File | null
    rentRollUploading: boolean
    theme: Theme
    uploadRentRoll: (file: File, leaseAuditID: number) => void
    getRentRollExample: () => Promise<undefined>
    getRentRollTemplate: () => Promise<undefined>
    setRentRoll: (value: React.SetStateAction<File | null>) => void
}

const RentRollStep = (props: RentRollStepProps) => {
    const {
        leaseAudit,
        rentRoll,
        rentRollUploading,
        theme,
        uploadRentRoll,
        getRentRollExample,
        getRentRollTemplate,
        setRentRoll,
    } = props

    return (
        <Container
            style={{
                flexDirection: 'column',
                overflowY: 'scroll',
                padding: theme.spacing(2),
            }}
        >
            <Container
                style={{
                    ...theme.typography.subtitle1,
                    color: theme.palette.grey[700],
                }}
            >
                Use our Rent Roll Template below to ensure that your Rent Roll
                sheet is uploaded correctly!
            </Container>

            <Container style={{ marginBottom: theme.spacing(5) }}>
                <Button
                    style={{
                        border: `1px solid ${theme.palette.grey[400]}`,
                        borderRadius: theme.shape.borderRadius,
                        marginRight: theme.spacing(2),
                        textTransform: 'none',
                    }}
                    onClick={() => getRentRollTemplate()}
                >
                    Rent Roll Template{' '}
                    <GetAppOutlined style={{ marginLeft: theme.spacing(1) }} />
                </Button>
                <Button
                    style={{
                        border: `1px solid ${theme.palette.grey[400]}`,
                        borderRadius: theme.shape.borderRadius,
                        textTransform: 'none',
                    }}
                    onClick={() => getRentRollExample()}
                >
                    Rent Roll Example{' '}
                    <GetAppOutlined style={{ marginLeft: theme.spacing(1) }} />
                </Button>
            </Container>

            <DropFiles
                multipleFiles={false}
                fileTypes={{
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
                }}
                displayString="Drag and Drop Your Rent Roll XLSX Here"
                onDrop={(files) => {
                    setRentRoll(files[0])
                }}
            />

            {rentRoll && (
                <Container style={{ flexDirection: 'column' }}>
                    <Container
                        style={{
                            flexWrap: 'wrap',
                            marginTop: theme.spacing(1),
                        }}
                    >
                        <Container
                            style={{
                                margin: theme.spacing(1, 1, 1, 0),
                                border: `1px solid ${theme.palette.grey[400]}`,
                                borderRadius: theme.shape.borderRadius,
                                padding: theme.spacing(1),
                                alignItems: 'center',
                            }}
                        >
                            <XLSXLogo
                                style={{
                                    height: '30px',
                                    width: '30px',
                                    marginRight: theme.spacing(1),
                                }}
                            />
                            {rentRoll.name}
                            <IconButton
                                onClick={() => {
                                    setRentRoll(null)
                                }}
                            >
                                <DeleteForever color="secondary" />
                            </IconButton>
                        </Container>
                    </Container>
                    <Container style={{ alignItems: 'flex-end' }}>
                        {rentRollUploading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                style={{
                                    color: '#fff',
                                    backgroundColor: theme.palette.primary.dark,
                                    height: '33px',
                                    maxWidth: '150px',
                                    minWidth: '150px',
                                    borderRadius: '5px',
                                    borderWidth: 0,
                                    fontSize: '14px',
                                    lineHeight: '17px',
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    textTransform: 'none',
                                    marginTop: theme.spacing(1),
                                }}
                                onClick={() => {
                                    uploadRentRoll(rentRoll, leaseAudit.id)
                                }}
                                disabled={rentRoll === null}
                            >
                                Upload
                            </Button>
                        )}
                    </Container>
                </Container>
            )}
        </Container>
    )
}

interface MatchingStepProps {
    leaseAudit: LeaseAudit
    unmatchedPdfLeases: DDLease[]
    matchedLeases: MatchedLease[]
    theme: Theme
    openAnalytics: boolean
    openLeaseTable: boolean
    pendingCount: number
    leaseAuditFilterState: LeaseAuditFilterState
    filteredMatchedLeases: MatchedLease[]
    filteredUnmatchedPDFLeases: DDLease[]
    displayPDFUpload: boolean
    selectedConfigs: number[]
    pendingUpload: number
    getAllPDFLeases: (leaseAuditId: number) => Promise<DDLease[]>
    loadingState: LeaseAuditLoadingState
    getSelectedConfigs: () => AuditPromptConfig[]
    setOpenAnalytics: (open: boolean) => void
    setOpenLeaseTable: (open: boolean) => void
    getLeaseAuditSummary: (id: number) => Promise<undefined>
    setLeaseAuditFilterState: (filterState: LeaseAuditFilterState) => void
    matchLeases: (request: MatchLeasesRequest) => void
    unmatchLeases: (leaseMatchId: number) => void
    setSelectedDDLease: (lease: DDLease | null) => void
    setOpenEditLeaseModal: (open: boolean) => void
    setOpenLeasePDFModal: (open: boolean) => void
    setOpenPDFUploadModal: (open: boolean) => void
    setOpenPromptResultsModal: (open: boolean) => void
    deletePDFLease: (pdfLeaseId: number) => void
    updateDDLease: (request: UpdateDDLeaseRequest) => Promise<DDLease>
    getLeaseAudit: (leaseAuditID: number) => void
    updateLeaseAudit: (request: UpdateLeaseAuditRequest) => void
    generateExcelReport: (leaseAuditId: number) => Promise<undefined>
}

const MatchingStep = (props: MatchingStepProps) => {
    const {
        leaseAudit,
        matchedLeases,
        unmatchedPdfLeases,
        theme,
        openAnalytics,
        openLeaseTable,
        pendingCount,
        leaseAuditFilterState,
        filteredMatchedLeases,
        displayPDFUpload,
        filteredUnmatchedPDFLeases,
        selectedConfigs,
        pendingUpload,
        getAllPDFLeases,
        loadingState,
        setOpenAnalytics,
        setOpenLeaseTable,
        getLeaseAuditSummary,
        setLeaseAuditFilterState,
        matchLeases,
        unmatchLeases,
        setSelectedDDLease,
        setOpenEditLeaseModal,
        setOpenLeasePDFModal,
        setOpenPDFUploadModal,
        setOpenPromptResultsModal,
        deletePDFLease,
        getSelectedConfigs,
        updateDDLease,
        getLeaseAudit,
        updateLeaseAudit,
        generateExcelReport,
    } = props

    const [openPDFLeaseStatusModal, setOpenPDFLeaseStatusModal] = useState(
        false,
    )

    const [name, setName] = useState(leaseAudit.name)
    const [editNameOpen, setEditNameOpen] = useState(false)
    const handleOpen = (e: React.MouseEvent) => {
        setEditNameOpen(true)
    }
    const handleClose = () => setEditNameOpen(false)
    const handleSave = (leaseAuditId: number, name: string) => {
        updateLeaseAudit({ lease_audit: leaseAuditId, name: name })
        handleClose()
    }

    return (
        <Container style={{ flexDirection: 'column', overflowY: 'scroll' }}>
            <Accordion expanded={openAnalytics} elevation={0}>
                <AccordionSummary>
                    <Container style={{ width: '100%' }}>
                        <Container
                            style={{
                                ...theme.typography.h6,
                                display: 'flex',
                                flex: 1,
                                justifyContent: 'center',
                            }}
                        >
                            <IconButton
                                onClick={(e) => {
                                    handleOpen(e)
                                }}
                                size="small"
                                style={{
                                    marginTop: theme.spacing(0.3),
                                    alignItems: 'flex-start',
                                }}
                            >
                                <Tooltip title="Edit">
                                    <EmptyEditIcon
                                        style={{ height: 20, width: 20 }}
                                    />
                                </Tooltip>
                            </IconButton>

                            {name}
                            <Modal open={editNameOpen} onClose={handleClose}>
                                <Slide direction="up" in={editNameOpen}>
                                    <Paper
                                        style={{
                                            display: 'flex',
                                            overflowY: 'scroll',
                                            flexDirection: 'column',
                                            height: '90%',
                                            width: '80%',
                                            padding: theme.spacing(2),
                                        }}
                                    >
                                        <h2>Edit Lease Audit Name</h2>
                                        <TextField
                                            fullWidth
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                            label="Lease Audit Name"
                                            variant="outlined"
                                            margin="normal"
                                        />
                                        <Button
                                            style={{
                                                color: '#fff',
                                                backgroundColor:
                                                    theme.palette.primary.dark,
                                                borderRadius: '5px',
                                                borderWidth: 0,
                                                fontSize: '14px',
                                                cursor: 'pointer',
                                                textTransform: 'none',
                                            }}
                                            onClick={() =>
                                                handleSave(leaseAudit.id, name)
                                            }
                                        >
                                            Save
                                        </Button>
                                    </Paper>
                                </Slide>
                            </Modal>
                        </Container>

                        <LeaseAuditRentVariance matchedLeases={matchedLeases} />
                        <Container style={{ flex: 1 }} />
                        <Tooltip title="Download Excel Report">
                            <IconButton
                                onClick={() => {
                                    generateExcelReport(leaseAudit.id)
                                }}
                                color={'default'}
                            >
                                <GetApp fontSize="large" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="PDF Lease Statuses">
                            <IconButton
                                onClick={() => {
                                    setOpenPDFLeaseStatusModal(true)
                                }}
                                color={
                                    openPDFLeaseStatusModal
                                        ? 'primary'
                                        : 'default'
                                }
                            >
                                <PictureAsPdf fontSize="large" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Analytics">
                            <IconButton
                                onClick={() => {
                                    setOpenAnalytics(!openAnalytics)
                                }}
                                color={openAnalytics ? 'primary' : 'default'}
                            >
                                <BarChart fontSize="large" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Data Table">
                            <IconButton
                                onClick={() => {
                                    setOpenLeaseTable(!openLeaseTable)
                                }}
                            >
                                <TableChart fontSize="large" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Get Lease Audit Summary">
                            <IconButton
                                onClick={() => {
                                    getLeaseAuditSummary(leaseAudit.id)
                                }}
                            >
                                <FileCopy fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Container>
                </AccordionSummary>
                <AccordionDetails>
                    <LeaseAuditAnalytics
                        theme={theme}
                        matchedLeases={matchedLeases}
                        unmatchedPDFLeases={unmatchedPdfLeases}
                        pendingCount={pendingCount}
                        pendingUpload={pendingUpload}
                    />
                </AccordionDetails>
            </Accordion>
            <Container
                style={{
                    padding: theme.spacing(1),
                    overflowY: 'scroll',
                    flex: 1,
                }}
            >
                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 2,
                    }}
                >
                    <Container>
                        <span
                            style={{
                                ...theme.typography.h6,
                                color: theme.palette.grey[600],
                                fontWeight: theme.typography.fontWeightBold,
                                margin: theme.spacing(0, 2, 1, 0),
                                flex: 1,
                            }}
                        >
                            Rent Roll Leases
                        </span>
                        <span
                            style={{
                                ...theme.typography.h6,
                                color: theme.palette.grey[600],
                                fontWeight: theme.typography.fontWeightBold,
                                marginBottom: theme.spacing(1),
                                flex: 1,
                            }}
                        >
                            Matched PDF Leases
                        </span>
                    </Container>
                    <Container style={{ alignItems: 'center' }}>
                        <TextField
                            variant="outlined"
                            placeholder="Search"
                            value={
                                leaseAuditFilterState.matchedLeasesSearchText
                            }
                            onChange={(e) => {
                                setLeaseAuditFilterState({
                                    ...leaseAuditFilterState,
                                    matchedLeasesSearchText: e.target.value,
                                })
                            }}
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            style={{
                                margin: theme.spacing(1, 2, 1, 0),
                            }}
                        />
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                color: theme.palette.grey[700],
                                fontWeight: theme.typography.fontWeightMedium,
                            }}
                        >
                            Hide Matched Leases
                        </span>
                        <Switch
                            checked={leaseAuditFilterState.hideMatchedLeases}
                            color="primary"
                            onChange={() => {
                                setLeaseAuditFilterState({
                                    ...leaseAuditFilterState,
                                    hideMatchedLeases: !leaseAuditFilterState.hideMatchedLeases,
                                })
                            }}
                        />
                    </Container>
                    <Container
                        style={{
                            flexDirection: 'column',
                            overflowY: 'scroll',
                            flex: 1,
                        }}
                    >
                        {filteredMatchedLeases.map((matchedLease) => {
                            return (
                                <MatchedLeaseRow
                                    key={`MATCHED_LEASE_${matchedLease.id}`}
                                    theme={theme}
                                    onDrop={(id) => {
                                        const request: MatchLeasesRequest = {
                                            lease_match: matchedLease.id,
                                            pdf_lease: id,
                                        }
                                        matchLeases(request)
                                    }}
                                    matchedLease={matchedLease}
                                    unmatchLeases={unmatchLeases}
                                    onOpenLeaseModal={(lease) => {
                                        setSelectedDDLease(lease)
                                        setOpenEditLeaseModal(true)
                                    }}
                                    onOpenLeasePDFModal={(lease) => {
                                        setSelectedDDLease(lease)
                                        setOpenLeasePDFModal(true)
                                    }}
                                    onOpenPromptResultsModal={(lease) => {
                                        setSelectedDDLease(lease)
                                        setOpenPromptResultsModal(true)
                                    }}
                                />
                            )
                        })}
                        <TableModal
                            open={openLeaseTable}
                            onClose={() => setOpenLeaseTable(false)}
                            matchedLeases={matchedLeases}
                            leaseAudit={leaseAudit}
                            updateDDLease={updateDDLease}
                            getLeaseAudit={getLeaseAudit}
                            loadingState={loadingState}
                        />
                    </Container>
                </Container>

                <Container
                    style={{
                        flexDirection: 'column',
                        flex: 1,
                        marginLeft: theme.spacing(1),
                    }}
                >
                    <Container>
                        <span
                            style={{
                                ...theme.typography.h6,
                                color: theme.palette.grey[600],
                                fontWeight: theme.typography.fontWeightBold,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Unmatched PDF Leases
                        </span>
                        {displayPDFUpload && (
                            <Button
                                style={{
                                    color: '#fff',
                                    backgroundColor: theme.palette.primary.dark,
                                    height: '33px',
                                    maxWidth: '150px',
                                    minWidth: '150px',
                                    borderRadius: '5px',
                                    borderWidth: 0,
                                    fontSize: '14px',
                                    lineHeight: '17px',
                                    fontWeight: 500,
                                    cursor: 'pointer',
                                    textTransform: 'none',
                                    marginLeft: theme.spacing(2),
                                }}
                                onClick={() => {
                                    setOpenPDFUploadModal(true)
                                }}
                            >
                                Upload Lease PDF
                            </Button>
                        )}
                    </Container>
                    <Container>
                        <TextField
                            variant="outlined"
                            placeholder="Search"
                            value={leaseAuditFilterState.unmatchedPDFSearchText}
                            onChange={(e) => {
                                setLeaseAuditFilterState({
                                    ...leaseAuditFilterState,
                                    unmatchedPDFSearchText: e.target.value,
                                })
                            }}
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            style={{
                                margin: theme.spacing(1, 2, 1, 0),
                            }}
                        />
                    </Container>
                    {unmatchedPdfLeases.length === 0 && (
                        <span
                            style={{
                                ...theme.typography.body1,
                                color: theme.palette.grey[600],
                                fontWeight: theme.typography.fontWeightMedium,
                            }}
                        >
                            All PDF Leases have been matched!
                        </span>
                    )}
                    <Container
                        style={{
                            flexDirection: 'column',
                            overflowY: 'scroll',
                            flex: 1,
                        }}
                    >
                        {filteredUnmatchedPDFLeases.map((pdfLease) => {
                            return (
                                <UnmatchedPDFLeaseCard
                                    key={`PDF-LEASE-${pdfLease.id}`}
                                    lease={pdfLease}
                                    theme={theme}
                                    deletePDFLease={deletePDFLease}
                                    onOpenLeaseModal={() => {
                                        setSelectedDDLease(pdfLease)
                                        setOpenEditLeaseModal(true)
                                    }}
                                    onOpenLeasePDFModal={() => {
                                        setSelectedDDLease(pdfLease)
                                        setOpenLeasePDFModal(true)
                                    }}
                                    onOpenPromptResultsModal={() => {
                                        setSelectedDDLease(pdfLease)
                                        setOpenPromptResultsModal(true)
                                    }}
                                />
                            )
                        })}
                    </Container>
                </Container>
            </Container>
            <PDFLeaseUploadStatusModal
                open={openPDFLeaseStatusModal}
                leaseAudit={leaseAudit}
                handleClose={() => setOpenPDFLeaseStatusModal(false)}
                getAllPDFLeases={getAllPDFLeases}
            />
        </Container>
    )
}
