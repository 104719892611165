import React from 'react'
import clsx from 'clsx'

import { Container } from '../Container'
import { useDrawerStyles } from './styles'

interface Props {
    open: boolean
    width?: number
}

export const SideDrawerContainer: React.FC<Props> = (props) => {
    const drawerClasses = useDrawerStyles(props.width)

    return (
        <Container
            className={clsx(drawerClasses.content, {
                [drawerClasses.contentShift]: props.open,
            })}
            flex={1}
        >
            {props.children}
        </Container>
    )
}
