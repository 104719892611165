import React, { useEffect } from 'react'

import { Container } from '../../../../components'
import {
    Area,
    AreaConfig,
    IdBoolMap,
    Lease,
    ModelListMap,
    ModelMap,
    Schedule,
    Timeline,
    UnitConfig,
    BaseWorkorder,
    Unit,
    AreaStatusTag,
    AreaStatusConfig,
    getLineageUnits,
    isServiceArea,
} from '../../../../models'
import { AssignUnit } from './AssignUnit'
import { FinderSelection } from '../../../../hooks'
import { FolderRow } from '../../Body/FolderRow'
import { useTheme } from '@material-ui/core'
import { AreaSelectorCollection } from '../../../../components/AreaSelector'

interface Props {
    unitConfigMap: ModelMap<UnitConfig>
    unitWorkorderMap: ModelListMap<BaseWorkorder>
    areaLeaseMap: ModelListMap<Lease>
    areaConfigMap: ModelMap<AreaConfig>
    schedule?: Schedule
    serviceAreaMap: IdBoolMap
    assignTimeline: Timeline
    includeTimeline: boolean
    areaStatusTagMap: ModelMap<AreaStatusTag>
    areaStatusConfigList: AreaStatusConfig[]
    useLeasesToCalculateServiceArea: boolean
    assignmentFinderSelection: FinderSelection
    isAreaInFilter: (area: Area) => boolean
    areaSelection: IdBoolMap
    setAreaSelection: (map: IdBoolMap) => void
}

export const ChooseServiceAreas = (props: Props) => {
    const {
        assignmentFinderSelection,
        unitConfigMap,
        unitWorkorderMap,
        areaLeaseMap,
        schedule,
        areaConfigMap,
        serviceAreaMap,
        assignTimeline,
        isAreaInFilter,
        includeTimeline,
        areaStatusTagMap,
        areaStatusConfigList,
        useLeasesToCalculateServiceArea,
        areaSelection,
        setAreaSelection,
    } = props

    const theme = useTheme()

    const selectedUnits = assignmentFinderSelection.getUnits()

    const unitKeys = Object.keys(assignmentFinderSelection.selection.unit)
    const folderMapping: { [folderId: number]: number } = {}
    const folderUnits: { folderName: string; unitList: Unit[] }[] = []

    unitKeys.forEach((unitKey) => {
        if (unitKey === 'length') {
            return
        }

        const unit = assignmentFinderSelection.selection.unit[Number(unitKey)]

        if (unit === undefined) return

        if (folderMapping[unit.folder.id] === undefined) {
            folderMapping[unit.folder.id] = folderUnits.length
            folderUnits.push({
                folderName: unit.folder.path + unit.folder.name,
                unitList: [],
            })
        }
        const idx = folderMapping[unit.folder.id]
        folderUnits[idx].unitList.push(unit)
    })

    const buildAreaStateForUnit = (
        unit: Unit,
        selectedAreaState: IdBoolMap,
        mutableAreaState: IdBoolMap,
    ) => {
        unit.areas.forEach((area) => {
            const shouldBeServicedDuringSchedule = serviceAreaMap[area.id]

            const shouldBeChecked = isServiceArea(
                area,
                areaLeaseMap,
                useLeasesToCalculateServiceArea,
                shouldBeServicedDuringSchedule,
                areaStatusTagMap,
                areaConfigMap[area.area_config],
                assignTimeline,
            )

            if (selectedAreaState[area.id] !== undefined) {
                mutableAreaState[area.id] = selectedAreaState[area.id]
            } else {
                mutableAreaState[area.id] = shouldBeChecked
            }
        })
    }

    useEffect(() => {
        const mutableAreaState: IdBoolMap = {}
        const handledUnits: IdBoolMap = {}

        // Recursively get the units of the selected folders
        Object.keys(assignmentFinderSelection.selection.folder).forEach(
            (key) => {
                if (key === 'length') return

                const folder =
                    assignmentFinderSelection.selection.folder[parseInt(key)]
                if (folder === undefined) return

                const folderUnits = getLineageUnits(folder)

                // It is possible that folderUnits are already in handledUnits because
                // The user selected both ParentFolder and ChildFolder
                // Prevent units from being duplicated

                folderUnits.forEach((unit) => {
                    if (handledUnits[unit.id]) return
                    handledUnits[unit.id] = true
                    buildAreaStateForUnit(unit, areaSelection, mutableAreaState)
                })
            },
        )

        // Add in the individual units the user selected
        Object.keys(assignmentFinderSelection.selection.unit).forEach((key) => {
            if (key === 'length') return
            const unit = assignmentFinderSelection.selection.unit[parseInt(key)]
            if (unit === undefined) return
            if (handledUnits[unit.id]) return
            handledUnits[unit.id] = true
            buildAreaStateForUnit(unit, areaSelection, mutableAreaState)
        })

        setAreaSelection(mutableAreaState)
    }, [assignmentFinderSelection.selection])

    return (
        <Container direction="column">
            <AreaSelectorCollection
                areaConfigMap={areaConfigMap}
                areaSelectionMap={areaSelection}
                setAreaSelectionMap={setAreaSelection}
                units={selectedUnits}
            />
            <Container style={{ flexDirection: 'column', flex: 1 }} scrollY>
                {assignmentFinderSelection.selection.unit.length === 0 ? (
                    <Container
                        style={{
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h5,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            Make a selection to get started
                        </span>
                    </Container>
                ) : (
                    folderUnits.map((folder) => {
                        return (
                            <>
                                <FolderRow
                                    key={folder.folderName}
                                    name={folder.folderName}
                                />
                                {folder.unitList.map((unit) => {
                                    return (
                                        <AssignUnit
                                            key={`ASSIGN_UNIT_${unit.id}`}
                                            areaStatusConfigList={
                                                areaStatusConfigList
                                            }
                                            unit={unit}
                                            areaStatusTagMap={areaStatusTagMap}
                                            unitConfig={
                                                unitConfigMap[unit.unit_config]
                                            }
                                            areaLeaseMap={areaLeaseMap}
                                            schedule={schedule}
                                            areaConfigMap={areaConfigMap}
                                            assignTimeline={assignTimeline}
                                            isAreaInFilter={isAreaInFilter}
                                            useLeasesToCalculateServiceArea={
                                                useLeasesToCalculateServiceArea
                                            }
                                            areaSelection={areaSelection}
                                            setAreaSelection={setAreaSelection}
                                        />
                                    )
                                })}
                            </>
                        )
                    })
                )}
            </Container>
        </Container>
    )
}
