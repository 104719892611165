import {
    Divider,
    IconButton,
    Modal,
    Paper,
    Slide,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React from 'react'
import { CreateChangeOrder } from '../Scheduler_v2/Drawers/CreateChangeOrder'
import { WorkorderResponse } from '../../store'
import { Container } from '../../components'
import CloseIcon from '@material-ui/icons/Close'

interface Props {
    open: boolean
    workorder: WorkorderResponse
    handleClose: () => void
}

export const CreateChangeOrderModal = (props: Props) => {
    const { open, handleClose, workorder } = props

    const theme = useTheme()

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        width: '600px',
                        height: '600px',
                    }}
                >
                    <Container
                        style={{
                            alignItems: 'center',
                            marginBottom: theme.spacing(1),
                            padding: theme.spacing(1, 1, 0, 2),
                        }}
                    >
                        <span
                            style={{
                                fontWeight: 500,
                                fontSize: '20px',
                                flex: 1,
                            }}
                        >
                            Create Change Order
                        </span>
                        <Tooltip title="Cancel">
                            <IconButton
                                onClick={() => {
                                    handleClose()
                                }}
                            >
                                <CloseIcon
                                    fontSize="inherit"
                                    style={{
                                        marginRight: theme.spacing(1),
                                        color: theme.palette.darkGreen.main,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Container>
                    <Divider style={{ margin: theme.spacing(0, 3, 0, 0) }} />
                    <Container
                        style={{
                            padding: theme.spacing(2),
                            flex: 1,
                            height: '520px',
                        }}
                    >
                        <CreateChangeOrder
                            setAddChangeOrder={handleClose}
                            unitId={workorder.unit_id}
                            workorder={workorder}
                            onClose={handleClose}
                        />
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
