import { useEffect, useState } from 'react'
import { useWindowSize } from '.'
import { BlanketRect } from '../components'

export const useBlanket = (
    open: boolean,
    contentRef: React.RefObject<HTMLDivElement>,
) => {
    const windowSize = useWindowSize()

    const [rect, setRect] = useState<BlanketRect>({
        anchorTop: 0,
        anchorLeft: 0,
        height: 0,
        width: 0,
    })

    useEffect(() => {
        if (contentRef?.current && open) {
            const bounds = contentRef.current.getBoundingClientRect()

            setRect({
                anchorTop: bounds.top,
                anchorLeft: bounds.left,
                height: bounds.height,
                width: bounds.width,
            })
        }
    }, [contentRef.current, windowSize, open])

    return rect
}
