import React, { useState } from 'react'
import { Container } from '../../../components'
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    useTheme,
} from '@material-ui/core'
import { ADD_NEW_ITEM_FORM } from './ConfigureInspectionType'
import { CreateAreaConfigRequest, createAreaConfigReq } from '../../../store'
import { useAppDispatch } from '../../../hooks'
import { toast } from 'react-toastify'
import { AreaConfig, AreaType } from '../../../models'

interface Props {
    subHeaderStyle: React.CSSProperties
    setFormPage: (form: number) => void
    updateIAC: (newIAC: AreaConfig) => void
}

export const CreateNewAreaForm = (props: Props) => {
    const { subHeaderStyle, setFormPage, updateIAC } = props

    const [areaName, setAreaName] = useState('')
    const [doValidate, setDoValidate] = useState(false)
    const [showOnSchedule, setShowOnSchedule] = useState(true)
    const [areaType, setAreaType] = useState<AreaType>(AreaType.COM)

    const dispatch = useAppDispatch()

    const theme = useTheme()

    const onSave = () => {
        if (areaName === '') {
            setDoValidate(true)
            return
        }

        const req: CreateAreaConfigRequest = {
            body: {
                name: areaName,
                shows_on_schedule: showOnSchedule,
                area_type: areaType,
            },
        }

        dispatch(createAreaConfigReq(req)).then((res) => {
            toast.success(`${areaName} Created.`)
            setAreaName('')
            updateIAC(res.data)
            setFormPage(ADD_NEW_ITEM_FORM)
        })
    }

    return (
        <Container direction="column" flex={1}>
            <Container
                direction="column"
                style={{
                    paddingRight: theme.spacing(1),
                    flex: 1,
                }}
            >
                <Container style={{ ...subHeaderStyle, fontSize: '20px' }}>
                    Create New Area
                </Container>
                <Container style={{ ...subHeaderStyle }}>Name:</Container>
                <Container style={{ marginBottom: theme.spacing(2) }}>
                    <TextField
                        error={doValidate && areaName === ''}
                        helperText={
                            doValidate && areaName === '' ? 'Required' : ''
                        }
                        variant="outlined"
                        value={areaName}
                        onChange={(e) => setAreaName(e.target.value)}
                        style={{
                            width: '100%',
                        }}
                        size="small"
                    />
                </Container>
                <FormControl
                    style={{ marginBottom: theme.spacing(1), width: '100%' }}
                >
                    <InputLabel id="area-type-label">Area Type</InputLabel>
                    <Select
                        id={`area-type-select`}
                        value={areaType}
                        onChange={(e) => {
                            setAreaType(e.target.value as AreaType)
                        }}
                        label="Area Type"
                        title="Area Type"
                        style={{ width: '100%' }}
                    >
                        {Object.values(AreaType).map((value) => {
                            return (
                                <MenuItem key={value} value={value}>
                                    {value.charAt(0) +
                                        value.substring(1).toLocaleLowerCase()}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <Container style={{ alignItems: 'center' }}>
                    <Container
                        style={{
                            fontWeight: 500,
                            fontSize: '16px',
                            marginRight: theme.spacing(1),
                        }}
                    >
                        Show On Schedule:
                    </Container>
                    <Switch
                        color="primary"
                        onChange={() => setShowOnSchedule(!showOnSchedule)}
                        checked={showOnSchedule}
                    />
                </Container>
            </Container>
            <Container flex={1} />
            <Container style={{ justifyContent: 'flex-end' }}>
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    style={{
                        textTransform: 'none',
                        margin: theme.spacing(1, 0, 1, 2),
                    }}
                    onClick={() => {
                        setFormPage(ADD_NEW_ITEM_FORM)
                    }}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    style={{
                        margin: theme.spacing(1, 0, 1, 2),
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => onSave()}
                >
                    Confirm
                </Button>
            </Container>
        </Container>
    )
}
