import React, { ReactNode, useState } from 'react'

import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'

import { currencyFormatter, toMMDDYYYY } from '../../../../../../helpers'
import { DDLeaseMatchStatus, MatchedLease } from '../../../../../../models'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox'
import { Button, useTheme } from '@material-ui/core'
import { Style } from '@material-ui/icons'

export const formatCurrency = (value: any) => {
    if (value === undefined) {
        return undefined
    } else if (value === null) {
        return null
    } else {
        return currencyFormatter.format(Number(value))
    }
}

export const formatDate = (value: any) => {
    if (value === null) {
        return null
    }
    const [year, month, day] = value.split('T')[0].split('-')
    return `${month}-${day}-${year}`
}

const displayStatusIcon = (status: any) => {
    if (status === DDLeaseMatchStatus.SUCCESS) {
        return <DoneAllIcon />
    }
    if (status === DDLeaseMatchStatus.DISCREPANCY) {
        return <IndeterminateCheckBoxIcon />
    }
    if (status === DDLeaseMatchStatus.PARTIAL_MATCH) {
        return <CheckIcon />
    }
    return <CloseIcon />
}

const StyleCell = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
}))

interface Props {
    format: string
    value: ReactNode
    backgroundColor: string
    lease?: MatchedLease
    handleOpenSignatureModal?: (lease: MatchedLease) => void
    handleOpenEditLeaseModal?: (lease: MatchedLease) => void
}

export const CellStyle: React.FC<Props> = (props) => {
    const {
        format,
        value,
        backgroundColor,
        lease,
        handleOpenSignatureModal,
        handleOpenEditLeaseModal,
    } = props
    const theme = useTheme()
    if (format === 'text') {
        return <StyleCell style={{ backgroundColor }}>{value}</StyleCell>
    } else if (format === 'currency') {
        return (
            <StyleCell style={{ backgroundColor }}>
                {formatCurrency(value)}
            </StyleCell>
        )
    } else if (format === 'date') {
        return (
            <StyleCell style={{ backgroundColor }}>
                {formatDate(value)}
            </StyleCell>
        )
    } else if (format === 'bool') {
        if (value === null) {
            return <StyleCell>Audit Required</StyleCell>
        } else if (value === true) {
            return <StyleCell>Yes</StyleCell>
        } else if (value === false) {
            return <StyleCell>No</StyleCell>
        } else {
            return <StyleCell>Audit Required</StyleCell>
        }
    } else if (format === 'icon') {
        return <StyleCell>{displayStatusIcon(value)}</StyleCell>
    } else if (format === 'audit') {
        if (lease && lease.pdf_lease && handleOpenSignatureModal) {
            if (!lease.pdf_lease.signature_audit) {
                return (
                    <StyleCell>
                        <Button
                            style={{
                                color: '#fff',
                                borderTop: theme.spacing(3),
                                backgroundColor: theme.palette.primary.dark,
                                height: '38px',
                                maxWidth: '85px',
                                minWidth: '85px',
                                borderRadius: '5px',
                                borderWidth: 0,
                                fontSize: '14px',
                                lineHeight: '17px',
                                fontWeight: 500,
                                cursor: 'pointer',
                                textTransform: 'none',
                            }}
                            onClick={() => handleOpenSignatureModal(lease)}
                        >
                            Verify
                        </Button>
                    </StyleCell>
                )
            } else if (lease.pdf_lease.signature_audit) {
                return (
                    <StyleCell>
                        <Button
                            style={{
                                color: '#fff',
                                borderTop: theme.spacing(3),
                                backgroundColor: theme.palette.grey[600],
                                height: '38px',
                                maxWidth: '85px',
                                minWidth: '85px',
                                borderRadius: '5px',
                                borderWidth: 0,
                                fontSize: '14px',
                                lineHeight: '17px',
                                fontWeight: 500,
                                cursor: 'pointer',
                                textTransform: 'none',
                            }}
                            onClick={() => handleOpenSignatureModal(lease)}
                        >
                            Edit
                        </Button>
                    </StyleCell>
                )
            }
        } else {
            return (
                <StyleCell>
                    <Button
                        disabled
                        style={{
                            color: '#fff',
                            backgroundColor: theme.palette.grey[600],
                            height: '100%',
                            maxHeight: '40px',
                            maxWidth: '85px',
                            minWidth: '85px',
                            borderRadius: '5px',
                            borderWidth: 0,
                            fontSize: '14px',
                            lineHeight: '17px',
                            fontWeight: 500,
                            cursor: 'pointer',
                            textTransform: 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        N/A
                    </Button>
                </StyleCell>
            )
        }
    } else if (format === 'edit') {
        if (lease && lease.pdf_lease && handleOpenEditLeaseModal) {
            if (!lease.pdf_lease.signature_audit) {
                return (
                    <StyleCell>
                        <Button
                            style={{
                                color: '#fff',
                                borderTop: theme.spacing(3),
                                backgroundColor: theme.palette.primary.dark,
                                height: '38px',
                                maxWidth: '85px',
                                minWidth: '85px',
                                borderRadius: '5px',
                                borderWidth: 0,
                                fontSize: '14px',
                                lineHeight: '17px',
                                fontWeight: 500,
                                cursor: 'pointer',
                                textTransform: 'none',
                            }}
                            onClick={() => handleOpenEditLeaseModal(lease)}
                        >
                            Edit
                        </Button>
                    </StyleCell>
                )
            } else if (lease.pdf_lease.signature_audit) {
                return (
                    <StyleCell>
                        <Button
                            style={{
                                color: '#fff',
                                borderTop: theme.spacing(3),
                                backgroundColor: theme.palette.grey[600],
                                height: '38px',
                                maxWidth: '85px',
                                minWidth: '85px',
                                borderRadius: '5px',
                                borderWidth: 0,
                                fontSize: '14px',
                                lineHeight: '17px',
                                fontWeight: 500,
                                cursor: 'pointer',
                                textTransform: 'none',
                            }}
                            onClick={() => handleOpenEditLeaseModal(lease)}
                        >
                            Edit
                        </Button>
                    </StyleCell>
                )
            }
        } else {
            return (
                <StyleCell>
                    <Button
                        disabled
                        style={{
                            color: '#fff',
                            backgroundColor: theme.palette.grey[600],
                            height: '100%',
                            maxHeight: '40px',
                            maxWidth: '85px',
                            minWidth: '85px',
                            borderRadius: '5px',
                            borderWidth: 0,
                            fontSize: '14px',
                            lineHeight: '17px',
                            fontWeight: 500,
                            cursor: 'pointer',
                            textTransform: 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        N/A
                    </Button>
                </StyleCell>
            )
        }
    } else {
        return <StyleCell>{value}</StyleCell>
    }
    return <StyleCell>N/A</StyleCell>
}
