import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const DEFAULT_DRAWER_WIDTH = 360

export const useDrawerStyles = (width: number = DEFAULT_DRAWER_WIDTH) => {
    return makeStyles((theme: Theme) =>
        createStyles({
            drawer: {
                flexShrink: 0,
                willChange: 'margin',
                borderBottom: theme.spacing(30),
            },
            drawerPaper: {
                width: width,
                marginTop: 60,
                marginBottom: 4,
                marginRight: 8,
                height: 'calc(100vh - 64px)',
                borderRadius: '20px',
                border: `1px solid ${theme.palette.grey[500]}`,
                boxShadow: theme.shadows[3],
            },
            drawerHeader: {
                display: 'flex',
                alignItems: 'center',
                padding: theme.spacing(0, 1),
                ...theme.mixins.toolbar,
                justifyContent: 'flex-start',
            },
            content: {
                flexGrow: 1,
                padding: theme.spacing(3),
                willChange: 'margin',
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
            contentShift: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginRight: width,
            },
        }),
    )()
}
