import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Container } from '../../components'
import { axiosInstance } from '../../helpers'
import {
    useAppDispatch,
    useCompany,
    useSchedule,
    useUser,
    useWindowSize,
} from '../../hooks'
import { EndOfTurnReport, IdBoolMap, ListVendor } from '../../models'
import { ArchivedScheduleBody } from './ArchivedScheduleBody'
import { ArchivedScheduleHeader } from './ArchivedScheduleHeader'
import { getInfrastructure, setWorkSpaceUser } from '../../store'

export const ArchivedSchedule = () => {
    const [loadData, setLoadData] = useState(false)

    const { rootUser } = useUser()
    const dispatch = useAppDispatch()
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search)
    const apartment = queryParams.get('apartment')

    useEffect(() => {
        if (apartment) {
            const apartmentId = parseInt(apartment)
            const newWsUser = rootUser?.workspaces.find(
                (wsUser) => wsUser.active_workspace.id === apartmentId,
            )

            if (newWsUser) {
                dispatch(setWorkSpaceUser(newWsUser))
                dispatch(
                    getInfrastructure({
                        apartmentId: newWsUser.active_workspace.id,
                    }),
                )
            }
        }
        setLoadData(true)
    }, [apartment])

    if (loadData) {
        return <_ArchivedSchedule />
    } else {
        return <div />
    }
}

const _ArchivedSchedule = () => {
    const { id } = useParams<{ id?: string }>()

    const { scheduleDetail } = useSchedule({
        cleanUp: true,
        getScheduleDetail: parseInt(id ?? '-1'),
    })

    const [endOfTurnReportList, setEndOfTurnReportList] = useState<
        EndOfTurnReport[]
    >([])

    const [vendorList, setVendorList] = useState<ListVendor[]>([])

    useEffect(() => {
        if (scheduleDetail) {
            axiosInstance
                .get(`workorder/end-of-turn-report/`, {
                    params: { schedule: scheduleDetail.id },
                })
                .then((res) => {
                    setEndOfTurnReportList(res.data)
                    const vendorIdMap: IdBoolMap = {}
                    const newVendorList: ListVendor[] = []
                    res.data.forEach((eotReport: EndOfTurnReport) => {
                        if (!(eotReport.vendor.id in vendorIdMap)) {
                            vendorIdMap[eotReport.vendor.id] = true
                            newVendorList.push(eotReport.vendor)
                        }
                    })

                    setVendorList(newVendorList)
                })
        }
    }, [scheduleDetail])

    return (
        <Container
            style={{
                flexDirection: 'column',
                flex: 1,
                overflowX: 'auto',
            }}
        >
            <ArchivedScheduleHeader
                scheduleDetail={scheduleDetail}
                endOfTurnReportList={endOfTurnReportList}
                id={id}
            />

            <ArchivedScheduleBody
                vendorList={vendorList}
                schedule={scheduleDetail}
                endOfTurnReportList={endOfTurnReportList}
            />
        </Container>
    )
}
