import React from 'react'
import { WorkorderResponse } from '../../../store'
import { Badge, Theme } from '@material-ui/core'
import { Container, StatusBadge } from '../../../components'
import {
    ChangeOrderStatus,
    getServiceImageDetails,
    getTotalPriceExpense,
} from '../../../models'
import { WorkorderAreaCard } from '../../../components/WorkorderLib/WorkorderRow/WorkorderAreaCard'
import { currencyFormatter, toMMDDYYYY } from '../../../helpers'
import { useStyles } from '../../../styles'
import { usePopover } from '../../../hooks/usePopover'
import { WorkorderCostPopover } from './WorkorderCostPopover'

interface Props {
    workorder: WorkorderResponse
    theme: Theme
}

export const InvoiceWorkorderCard = (props: Props) => {
    const { workorder, theme } = props

    const classes = useStyles()

    const popoverState = usePopover()

    let titleLabel = ''
    if (
        workorder.schedule_name !== null &&
        workorder.schedule_name !== undefined &&
        workorder.title !== ''
    ) {
        titleLabel = `${workorder.schedule_name} / ${workorder.title}`
    } else {
        titleLabel = workorder.schedule_name ?? workorder.title
    }

    let dateLabel = ''
    if (workorder.start_date && workorder.end_date) {
        dateLabel = `${toMMDDYYYY(
            new Date(workorder.start_date),
        )} - ${toMMDDYYYY(new Date(workorder.end_date))}`
    } else if (workorder.start_date) {
        dateLabel = toMMDDYYYY(new Date(workorder.start_date))
    } else if (workorder.end_date) {
        dateLabel = toMMDDYYYY(new Date(workorder.end_date))
    }

    const approvedChangeOrders =
        workorder.changeorder_set?.filter(
            (co) => co.status === ChangeOrderStatus.APPROVED,
        ) ?? []

    return (
        <Container
            style={{
                border: `1px solid ${theme.palette.grey[400]}`,
                width: '650px',
                marginBottom: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
            }}
            direction="column"
        >
            <Container
                style={{
                    height: '160px',
                    borderBottom: `1px solid ${theme.palette.grey[400]}`,
                }}
            >
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        padding: theme.spacing(1),
                    }}
                >
                    {/* Title */}
                    <Container style={{ alignItems: 'center' }}>
                        <span style={{ ...theme.typography.subtitle1 }}>
                            {titleLabel}
                        </span>
                    </Container>

                    <Container>
                        {/* Location */}
                        <Container
                            style={{
                                flexDirection: 'column',
                                maxWidth: 150,
                                minWidth: 150,
                                overflow: 'hidden',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.body2,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {workorder.path}
                                {workorder.folder_name}
                            </span>

                            <span
                                style={{
                                    ...theme.typography.h6,
                                    color: theme.palette.primary.dark,
                                }}
                            >
                                {workorder.unit_name ?? ''}
                            </span>

                            <span
                                style={{
                                    ...theme.typography.body2,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                {workorder.unit_config_name ?? ''}
                            </span>
                        </Container>
                    </Container>

                    <Container style={{ flex: 1 }} />

                    <Container>
                        <span
                            style={{
                                ...theme.typography.subtitle2,
                                color: theme.palette.grey[700],
                            }}
                        >
                            {dateLabel}
                        </span>
                    </Container>
                </Container>

                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                    }}
                >
                    <Container
                        style={{
                            flexDirection: 'column',
                            flex: 1,
                            overflow: 'hidden',
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        <Container
                            style={{
                                overflow: 'hidden',
                                alignItems: 'center',
                                marginTop: theme.spacing(3),
                            }}
                        >
                            <img
                                src={
                                    getServiceImageDetails(
                                        workorder.service_id ?? -1,
                                    ).icon
                                }
                                style={{
                                    maxHeight: 35,
                                    maxWidth: 32,
                                    marginRight: theme.spacing(1),
                                }}
                            />
                            <span
                                style={{
                                    ...theme.typography.body1,
                                    fontWeight: 600,
                                }}
                            >
                                {workorder.service_name ?? 'No Service'}
                            </span>
                        </Container>

                        <Container
                            style={{
                                marginTop: theme.spacing(2),
                                paddingLeft: theme.spacing(1),
                            }}
                        >
                            <Badge
                                badgeContent={approvedChangeOrders.length}
                                color="primary"
                            >
                                <span
                                    style={{
                                        ...theme.typography.h6,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                        cursor: 'pointer',
                                    }}
                                    className={classes.hoverGrey300}
                                    onClick={popoverState.handleOpen}
                                >
                                    {currencyFormatter.format(
                                        getTotalPriceExpense(workorder),
                                    )}
                                </span>
                            </Badge>
                        </Container>
                    </Container>
                </Container>

                <Container
                    style={{
                        flexDirection: 'column',
                        marginRight: theme.spacing(2),
                    }}
                >
                    <Container
                        style={{
                            height: 40,
                            padding: theme.spacing(1, 0, 2, 0),
                            alignSelf: 'flex-end',
                        }}
                    >
                        {workorder.fully_vacant && (
                            <StatusBadge
                                text="Vacant"
                                customStyle={{
                                    container: {
                                        height: 30,
                                        width: 80,
                                        backgroundColor:
                                            theme.palette.common.black,
                                    },
                                    text: { ...theme.typography.caption },
                                }}
                            />
                        )}
                    </Container>
                    <WorkorderAreaCard workorder={workorder} theme={theme} />
                </Container>
            </Container>

            <WorkorderCostPopover
                workorder={workorder}
                popoverState={popoverState}
                approvedChangeOrders={approvedChangeOrders}
                theme={theme}
            />
        </Container>
    )
}
