import { User } from '../../models'
import {
    SET_ROOT_USER,
    UserState,
    UserActionTypes,
    CREATE_USER_REQUEST,
    CREATE_USER_RESPONSE,
    SET_USER_LOADING,
    SET_WORKSPACE_USER,
    SET_USER_LIST,
    UPDATE_USER_LIST,
    REMOVE_USER_FROM_LIST,
} from './types'

const initialState: UserState = {
    userList: [],
    isLoading: {
        LOGIN_REQUEST: false,
        GET_USER_LIST_REQUEST: false,
        CREATE_USER_REQUEST: false,
        UPDATE_USER_REQUEST: false,
        GET_MY_WORKFORCE_REQUEST: false,
        GET_ROOT_USER_REQUEST: false,
        ARCHIVE_USER_REQUEST: false,
    },
}

export const userReducer = (
    state = initialState,
    action: UserActionTypes,
): UserState => {
    switch (action.type) {
        case CREATE_USER_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.type.valueOf()]: true,
                },
            }
        case REMOVE_USER_FROM_LIST:
            return {
                ...state,
                userList: state.userList.reduce<User[]>((prev, user) => {
                    if (user.id === action.user.id) {
                        return prev
                    }
                    return prev.concat(user)
                }, []),
            }
        case SET_ROOT_USER:
            return {
                ...state,
                rootUser: action.user,
                workspaceUser: undefined,
            }

        case SET_WORKSPACE_USER:
            return {
                ...state,
                workspaceUser: action.user,
            }

        case SET_USER_LIST:
            return {
                ...state,
                userList: action.userList,
            }

        case UPDATE_USER_LIST:
            return {
                ...state,
                userList: state.userList.map((user) => {
                    if (user.id === action.user.id) {
                        return action.user
                    }
                    return user
                }),
            }

        case CREATE_USER_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    CREATE_USER_REQUEST: false,
                },
                userList: state.userList
                    ? [...state.userList, action.payload]
                    : [action.payload],
            }

        case SET_USER_LOADING:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.request]: action.isLoading,
                },
            }

        default:
            return state
    }
}
