import React, { useEffect, useState } from 'react'
import { Container } from '../Container'
import {
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    Slide,
    Switch,
    TextField,
    useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useEntrataWorkOrderPickList, useUser } from '../../hooks'
import { ActionItem, emptyEntrataWorkorderConfig } from '../../models'
import { axiosInstance } from '../../helpers'
import { toast } from 'react-toastify'

interface Props {
    open: boolean
    handleClose: () => void
    actionItem?: ActionItem
    onSync: (actionItem: ActionItem) => void
}

export const SyncActionItemModal = (props: Props) => {
    const { open, handleClose, actionItem } = props
    const theme = useTheme()
    const { workspaceUser } = useUser()

    const [doValidate, setDoValidate] = useState(false)

    const validate = () => {
        setDoValidate(true)

        return (
            petDescriptionValid() &&
            locationValid() &&
            categoryValid() &&
            problemValid() &&
            emailValid() &&
            phoneValid() &&
            problemDescriptionValid()
        )
    }

    const emailValid = () => {
        return (
            syncConfig.emailAddress !== null && syncConfig.emailAddress !== ''
        )
    }

    const phoneValid = () => {
        return (
            syncConfig.mainPhoneNumber !== null &&
            syncConfig.mainPhoneNumber >= 1000000000 &&
            syncConfig.mainPhoneNumber <= 9999999999
        )
    }

    const problemDescriptionValid = () => {
        console.info(
            'DING',
            syncConfig.problemDescription,
            syncConfig.problemDescription?.length,
        )
        return (
            syncConfig.problemDescription !== null &&
            syncConfig.problemDescription.length > 0
        )
    }

    const petDescriptionValid = () => {
        const petDescriptionRequired = syncConfig.permissionToEnter === false

        return !(
            (petDescriptionRequired && syncConfig.petsDescription === null) ||
            syncConfig.petsDescription === ''
        )
    }

    const locationValid = () => {
        return !(
            syncConfig.maintenanceLocationId === null ||
            syncConfig.maintenanceLocationId === -1
        )
    }

    const categoryValid = () => {
        return !(
            syncConfig.maintenanceCategoryId === null ||
            syncConfig.maintenanceCategoryId === -1
        )
    }

    const problemValid = () => {
        return !(
            syncConfig.maintenanceProblemId === null ||
            syncConfig.maintenanceProblemId === -1
        )
    }

    const {
        getPlMaintenanceProblems,
        getPlLocations,
        getPlMaintenancePriorities,
        getPlMaintenanceCategories,
    } = useEntrataWorkOrderPickList()

    const [syncConfig, setSyncConfig] = useState(emptyEntrataWorkorderConfig)

    const clearForm = () => {
        setSyncConfig(emptyEntrataWorkorderConfig)
        setDoValidate(false)
    }

    useEffect(() => {
        const aiSyncConfig = actionItem?.entrata_workorder_config
            ? { ...actionItem.entrata_workorder_config }
            : emptyEntrataWorkorderConfig

        const folderName = actionItem?.folder_location?.name ?? ''
        const email = aiSyncConfig.emailAddress ?? workspaceUser?.email ?? ''
        aiSyncConfig.buildingName = folderName
        aiSyncConfig.unitNumber = actionItem?.unit_location?.name ?? ''
        aiSyncConfig.emailAddress = email

        setSyncConfig(aiSyncConfig)

        return clearForm
    }, [actionItem])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
            }}
        >
            <Slide direction="up" in={open} style={{}}>
                <Paper
                    style={{
                        height: MODAL_HEIGHT,
                        width: MODAL_WIDTH,
                        display: 'flex',
                    }}
                >
                    {/* Modal Content */}
                    <Container style={{ flexDirection: 'column', flex: 1 }}>
                        {/* Header */}
                        <Container
                            style={{
                                padding: theme.spacing(1),
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h5,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Sync Action Item
                            </span>
                            <div style={{ flex: 1 }} />
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Container>
                        <Divider />

                        {/* Main Content */}

                        <Container style={{ flex: 1 }}>
                            {/* Left Column */}
                            <Container
                                style={{
                                    flexDirection: 'column',
                                    flex: 1,
                                    borderRight: `1px solid ${theme.palette.grey[300]}`,
                                    padding: theme.spacing(1),
                                }}
                            >
                                <Selector
                                    title="Maintenance Location"
                                    isValid={!doValidate || locationValid()}
                                    data={getPlLocations()?.Location ?? []}
                                    value={syncConfig.maintenanceLocationId}
                                    idGetter={(item) => {
                                        const id = item['@attributes'].Id
                                        return Number(id)
                                    }}
                                    labelGetter={(item) => {
                                        return `${item['@attributes'].Type} - ${item['@attributes'].Name}`
                                    }}
                                    onChange={(e) => {
                                        console.log(e.target.value)
                                        setSyncConfig((oldConfig) => {
                                            return {
                                                ...oldConfig,
                                                maintenanceLocationId:
                                                    Number(e.target.value) ??
                                                    null,
                                            }
                                        })
                                    }}
                                />

                                <Selector
                                    title="Maintenance Category"
                                    isValid={!doValidate || categoryValid()}
                                    data={getPlMaintenanceCategories() ?? []}
                                    value={syncConfig.maintenanceCategoryId}
                                    idGetter={(item) => {
                                        const id = item['@attributes'].Id
                                        return Number(id)
                                    }}
                                    labelGetter={(item) => {
                                        return item['@attributes'].Name
                                    }}
                                    onChange={(e) => {
                                        setSyncConfig((oldConfig) => {
                                            return {
                                                ...oldConfig,
                                                maintenanceCategoryId:
                                                    Number(e.target.value) ??
                                                    null,
                                            }
                                        })
                                    }}
                                />

                                <Selector
                                    title="Maintenance Problem"
                                    isValid={!doValidate || problemValid()}
                                    data={getPlMaintenanceProblems() ?? []}
                                    value={syncConfig.maintenanceProblemId}
                                    idGetter={(item) => {
                                        const id = item['@attributes'].Id
                                        return Number(id)
                                    }}
                                    labelGetter={(item) => {
                                        return `${item['@attributes'].Name}`
                                    }}
                                    onChange={(e) => {
                                        setSyncConfig((oldConfig) => {
                                            return {
                                                ...oldConfig,
                                                maintenanceProblemId:
                                                    Number(e.target.value) ??
                                                    null,
                                            }
                                        })
                                    }}
                                />

                                <Selector
                                    title="Priority"
                                    isValid={true}
                                    required={false}
                                    data={getPlMaintenancePriorities() ?? []}
                                    value={syncConfig.maintenancePriorityId}
                                    idGetter={(item) => {
                                        const id = item['@attributes'].Id
                                        return Number(id)
                                    }}
                                    labelGetter={(item) => {
                                        return item['@attributes'].Name
                                    }}
                                    onChange={(e) => {
                                        setSyncConfig((oldConfig) => {
                                            return {
                                                ...oldConfig,
                                                maintenancePriorityId:
                                                    Number(e.target.value) ??
                                                    null,
                                            }
                                        })
                                    }}
                                />
                            </Container>

                            {/* Right Column */}
                            <Container
                                style={{
                                    flexDirection: 'column',
                                    flex: 1,
                                    padding: theme.spacing(1),
                                }}
                            >
                                <TextField
                                    value={syncConfig.buildingName}
                                    style={{ marginBottom: 8 }}
                                    label="Building Name"
                                    placeholder="Building Name"
                                    onChange={(e) => {
                                        setSyncConfig((oldConfig) => {
                                            return {
                                                ...oldConfig,
                                                buildingName: e.target.value,
                                            }
                                        })
                                    }}
                                />

                                <TextField
                                    value={syncConfig.unitNumber}
                                    style={{ marginBottom: 8 }}
                                    label="Unit Name"
                                    placeholder="Unit Name"
                                    onChange={(e) => {
                                        setSyncConfig((oldConfig) => {
                                            return {
                                                ...oldConfig,
                                                unitNumber: e.target.value,
                                            }
                                        })
                                    }}
                                />

                                <TextField
                                    value={syncConfig.problemDescription}
                                    style={{ marginBottom: 8 }}
                                    label="Problem Description"
                                    placeholder="Problem Description"
                                    error={
                                        doValidate && !problemDescriptionValid()
                                    }
                                    required
                                    onChange={(e) => {
                                        setSyncConfig((oldConfig) => {
                                            return {
                                                ...oldConfig,
                                                problemDescription:
                                                    e.target.value,
                                            }
                                        })
                                    }}
                                />

                                <TextField
                                    value={syncConfig.emailAddress}
                                    style={{ marginBottom: 8 }}
                                    label="Email"
                                    placeholder="Email"
                                    required
                                    error={doValidate && !emailValid()}
                                    onChange={(e) => {
                                        setSyncConfig((oldConfig) => {
                                            return {
                                                ...oldConfig,
                                                emailAddress: e.target.value,
                                            }
                                        })
                                    }}
                                />

                                <TextField
                                    value={syncConfig.mainPhoneNumber}
                                    style={{ marginBottom: 8 }}
                                    label="Phone"
                                    placeholder="Phone"
                                    required
                                    error={doValidate && !phoneValid()}
                                    onChange={(e) => {
                                        setSyncConfig((oldConfig) => {
                                            let newPhone:
                                                | number
                                                | null = Number(e.target.value)
                                            if (isNaN(newPhone)) {
                                                newPhone =
                                                    oldConfig.mainPhoneNumber
                                            }

                                            return {
                                                ...oldConfig,
                                                mainPhoneNumber: newPhone,
                                            }
                                        })
                                    }}
                                />

                                <TextField
                                    value={syncConfig.customerFirstName}
                                    style={{ marginBottom: 8 }}
                                    label="Customer First Name"
                                    placeholder="Customer First Name"
                                    onChange={(e) => {
                                        setSyncConfig((oldConfig) => {
                                            return {
                                                ...oldConfig,
                                                customerFirstName:
                                                    e.target.value,
                                            }
                                        })
                                    }}
                                />

                                <TextField
                                    value={syncConfig.customerLastName}
                                    style={{ marginBottom: 8 }}
                                    label="Customer Last Name"
                                    placeholder="Customer Last Name"
                                    onChange={(e) => {
                                        setSyncConfig((oldConfig) => {
                                            return {
                                                ...oldConfig,
                                                customerLastName:
                                                    e.target.value,
                                            }
                                        })
                                    }}
                                />

                                <TextField
                                    value={syncConfig.petsDescription}
                                    style={{ marginBottom: 8 }}
                                    label="Pets Description"
                                    placeholder="Pets Description"
                                    error={doValidate && !petDescriptionValid()}
                                    required={
                                        syncConfig.permissionToEnter === false
                                    }
                                    onChange={(e) => {
                                        setSyncConfig((oldConfig) => {
                                            return {
                                                ...oldConfig,
                                                petsDescription: e.target.value,
                                            }
                                        })
                                    }}
                                />

                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={
                                                syncConfig.permissionToEnter
                                            }
                                            color="primary"
                                            onChange={(e) =>
                                                setSyncConfig((oldConfig) => {
                                                    return {
                                                        ...oldConfig,
                                                        permissionToEnter:
                                                            e.target.checked,
                                                    }
                                                })
                                            }
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Permission to enter"
                                    style={{ marginTop: theme.spacing(2) }}
                                />
                            </Container>
                        </Container>

                        <Divider />

                        {/* Footer */}
                        <Container
                            style={{
                                height: 50,
                                padding: theme.spacing(1),
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                style={{ marginRight: theme.spacing(1) }}
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={actionItem === undefined}
                                onClick={() => {
                                    if (actionItem === undefined) {
                                        return
                                    }

                                    if (validate()) {
                                        axiosInstance
                                            .post(
                                                `action-item/${actionItem.id}/entrata_sync/`,
                                                syncConfig,
                                            )
                                            .then((res) => {
                                                handleClose()
                                                props.onSync(res.data)
                                                toast.success('Sync success')
                                            })
                                            .catch((e) => {
                                                toast.error(
                                                    `sync fail \n${e.response.data}`,
                                                )
                                            })
                                    }
                                }}
                            >
                                Sync
                            </Button>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

interface SelectorProps<T> {
    title: string
    value: number | null
    onChange: (e: any) => void
    data: T[]
    idGetter: (item: T) => number
    labelGetter: (item: T) => string
    isValid: boolean
    required?: boolean
}
const Selector = <T extends Object>(props: SelectorProps<T>) => {
    return (
        <FormControl
            style={{ marginBottom: 32 }}
            required={props.required ?? true}
        >
            <InputLabel>{props.title}</InputLabel>
            <Select
                fullWidth
                displayEmpty
                value={props.value}
                onChange={props.onChange}
                error={!props.isValid}
            >
                <MenuItem key="-1" value={-1}>
                    None
                </MenuItem>

                {props.data.map((item) => {
                    return (
                        <MenuItem
                            key={`${props.title}-${props.idGetter(item)}`}
                            value={props.idGetter(item)}
                        >
                            {props.labelGetter(item)}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

const MODAL_HEIGHT = 700
const MODAL_WIDTH = 700
