import React, { Fragment, useEffect, useState } from 'react'
import { Container } from '../../components'
import { Button, IconButton, TextField, useTheme } from '@material-ui/core'
import { ListVendor } from '../../models'
import { axiosInstance } from '../../helpers'
import { toast } from 'react-toastify'
import { DeleteForever } from '@material-ui/icons'

interface Props {
    vendor: ListVendor
    setVendor: (company: ListVendor | undefined) => void
}

export const EditVendorDetails = (props: Props) => {
    const { vendor, setVendor } = props

    const [description, setDescription] = useState('')
    const [referenceOne, setReferenceOne] = useState('')
    const [referenceTwo, setReferenceTwo] = useState('')
    const [referenceThree, setReferenceThree] = useState('')
    const [insuranceOne, setInsuranceOne] = useState('')
    const [insuranceTwo, setInsuranceTwo] = useState('')
    const [W9Document, setW9Document] = useState('')

    const theme = useTheme()

    const spanStyle: React.CSSProperties = {
        fontWeight: 600,
        fontSize: 18,
        marginBottom: theme.spacing(1),
    }

    useEffect(() => {
        setDescription(vendor?.description ? vendor.description : '')
        setReferenceOne(
            vendor.documents?.reference_1?.name
                ? vendor.documents.reference_1.name
                : '',
        )
        setReferenceTwo(
            vendor.documents?.reference_2?.name
                ? vendor.documents.reference_2.name
                : '',
        )
        setReferenceThree(
            vendor.documents?.reference_3?.name
                ? vendor.documents.reference_3.name
                : '',
        )
        setInsuranceOne(
            vendor.documents?.insurance_1?.name
                ? vendor.documents.insurance_1.name
                : '',
        )
        setInsuranceTwo(
            vendor.documents?.insurance_2?.name
                ? vendor.documents.insurance_2.name
                : '',
        )
        setW9Document(
            vendor.documents?.w9_document?.name
                ? vendor.documents.w9_document.name
                : '',
        )
    }, [])

    return (
        <Container
            flex={1}
            direction="column"
            style={{ margin: theme.spacing(8, 5, 5, 5) }}
        >
            <Container
                direction="column"
                style={{ marginRight: theme.spacing(4) }}
            >
                <span style={{ ...spanStyle }}>About:</span>
                <TextField
                    variant="outlined"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    size="small"
                    multiline
                />
                <Button
                    variant="outlined"
                    style={{
                        backgroundColor: theme.palette.primary.dark,
                        textTransform: 'none',
                        color: 'white',
                        padding: theme.spacing(0.5, 4, 0.5, 4),
                        width: '30px',
                        marginTop: theme.spacing(1),
                    }}
                    onClick={() => {
                        const body = {
                            description: description,
                        }
                        axiosInstance
                            .patch(`company/vendor/${vendor.id}/`, body)
                            .then((res) => {
                                toast.success(`${res.data.vendor.name} updated`)
                                const tempVendor = JSON.parse(
                                    JSON.stringify(vendor),
                                )
                                tempVendor.description =
                                    res.data.vendor.description
                                setVendor(tempVendor)
                            })
                    }}
                >
                    Save
                </Button>
            </Container>
            <Container
                direction="column"
                style={{ margin: theme.spacing(2, 0, 2, 0) }}
            >
                <span style={{ ...spanStyle }}>References:</span>
                <DocumentComponent
                    vendor={vendor}
                    setVendor={setVendor}
                    fileName={referenceOne}
                    setFileName={setReferenceOne}
                    fileIdentifier="Reference 1"
                    backendIdentifier="reference_1"
                    fragmentID="reference-1-button-file"
                />
                <DocumentComponent
                    vendor={vendor}
                    setVendor={setVendor}
                    fileName={referenceTwo}
                    setFileName={setReferenceTwo}
                    fileIdentifier="Reference 2"
                    backendIdentifier="reference_2"
                    fragmentID="reference-2-button-file"
                />
                <DocumentComponent
                    vendor={vendor}
                    setVendor={setVendor}
                    fileName={referenceThree}
                    setFileName={setReferenceThree}
                    fileIdentifier="Reference 3"
                    backendIdentifier="reference_3"
                    fragmentID="reference-3-button-file"
                />
            </Container>
            <Container
                direction="column"
                style={{ marginRight: theme.spacing(2) }}
            >
                <span style={{ ...spanStyle }}>Company Docs:</span>
                <DocumentComponent
                    vendor={vendor}
                    setVendor={setVendor}
                    fileName={insuranceOne}
                    setFileName={setInsuranceOne}
                    fileIdentifier="Insurance Document 1"
                    backendIdentifier="insurance_1"
                    fragmentID="insurance-1-button-file"
                />
                <DocumentComponent
                    vendor={vendor}
                    setVendor={setVendor}
                    fileName={insuranceTwo}
                    setFileName={setInsuranceTwo}
                    fileIdentifier="Insurance Document 2"
                    backendIdentifier="insurance_2"
                    fragmentID="insurance-2-button-file"
                />
                <DocumentComponent
                    vendor={vendor}
                    setVendor={setVendor}
                    fileName={W9Document}
                    setFileName={setW9Document}
                    fileIdentifier="W9"
                    backendIdentifier="w9_document"
                    fragmentID="w9-button-file"
                />
            </Container>
        </Container>
    )
}

interface DocumentComponentProps {
    fileName: string
    setFileName: (name: string) => void
    vendor: ListVendor
    setVendor: (vendor: ListVendor) => void
    fileIdentifier: string
    backendIdentifier: string
    fragmentID: string
}

const DocumentComponent = (props: DocumentComponentProps) => {
    const {
        fileName,
        setFileName,
        fileIdentifier,
        backendIdentifier,
        fragmentID,
        vendor,
        setVendor,
    } = props

    const theme = useTheme()

    const SaveFile = (
        file: File,
        setFileName: (name: string) => void,
        fileIdentifier: string,
        backendIdentifier: string,
    ) => {
        const body = new FormData()
        body.append(backendIdentifier, file)
        axiosInstance
            .patch(`company/vendor/${vendor.id}/update_vendor_documents/`, body)
            .then((res) => {
                toast.success(`${fileIdentifier} updated successfully`)
                setFileName(file.name)
                const tempVendor = JSON.parse(JSON.stringify(vendor))
                tempVendor.documents = res.data.documents
                setVendor(tempVendor)
            })
            .catch((e) => toast.error(e.response.data))
    }

    const RemoveFile = (
        setFileName: (name: string) => void,
        fileIdentifier: string,
        backendIdentifier: string,
    ) => {
        const body = new FormData()
        body.append(backendIdentifier, '')
        axiosInstance
            .patch(`company/vendor/${vendor.id}/update_vendor_documents/`, body)
            .then((res) => {
                toast.success(`${fileIdentifier} removed successfully`)
                setFileName('')
                const tempVendor = JSON.parse(JSON.stringify(vendor))
                tempVendor.documents = res.data.documents
                setVendor(tempVendor)
            })
            .catch((e) => toast.error(e.response.data))
    }

    return (
        <Fragment>
            <Container
                alignItems="center"
                style={{
                    fontSize: 16,
                    fontWeight: 500,
                    marginTop: theme.spacing(2),
                }}
            >
                <Container style={{ marginRight: theme.spacing(1) }}>
                    {fileIdentifier}:
                </Container>
                {fileName && (
                    <Container
                        style={{
                            marginRight: theme.spacing(1),
                            color: theme.palette.darkGreen.main,
                            alignItems: 'center',
                        }}
                    >
                        {fileName}
                        <IconButton
                            onClick={() =>
                                RemoveFile(
                                    setFileName,
                                    fileIdentifier,
                                    backendIdentifier,
                                )
                            }
                            style={{ width: '30px' }}
                            size="small"
                        >
                            <DeleteForever color="secondary" />
                        </IconButton>
                    </Container>
                )}
                <input
                    color="primary"
                    type="file"
                    onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                            SaveFile(
                                e.target.files[0],
                                setFileName,
                                fileIdentifier,
                                backendIdentifier,
                            )
                        }
                    }}
                    id={fragmentID}
                    style={{ display: 'none' }}
                />
                {!fileName && (
                    <label htmlFor={fragmentID}>
                        <Button
                            component="span"
                            style={{
                                backgroundColor: theme.palette.darkBlue.main,
                                textTransform: 'none',
                                color: 'white',
                                padding: theme.spacing(0.5, 1, 0.5, 1),
                                fontSize: 10,
                            }}
                        >
                            Choose File
                        </Button>
                    </label>
                )}
            </Container>
        </Fragment>
    )
}
