import { currencyFormatterNoDecimals } from '../../../helpers/CurrencyFormatter'
import {
    BaseWorkorder,
    SmartScheduleDetails,
    WorkorderStatus,
} from '../../../models'
import { getTotalPriceExpense } from '../../../models/WorkOrder/services'

export const calculateInvoiceForecastPerService = (
    workorders: BaseWorkorder[],
) => {
    const invoiceForecastService: InvoiceForecastServiceMap = {}

    workorders.forEach((wo) => {
        if (wo.service_id && wo.service_name) {
            if (invoiceForecastService[wo.service_id] === undefined) {
                invoiceForecastService[wo.service_id] = {
                    forecast: 0,
                    invoice: 0,
                }
            }
            invoiceForecastService[
                wo.service_id
            ].forecast += getTotalPriceExpense(wo)

            if (wo.status === WorkorderStatus.INVOICED) {
                invoiceForecastService[wo.service_id].invoice +=
                    wo.invoiced_price ?? 0
            }
        }
    })
    return invoiceForecastService
}

export const formatTotals = (value: number | undefined) => {
    if (value === undefined) {
        return undefined
    }
    return currencyFormatterNoDecimals.format(value)
}

export interface InvoiceForecastServiceMap {
    [service_id: number]: { invoice: number; forecast: number }
}

export const calculateTotalInvoiceForecast = (workorders: BaseWorkorder[]) => {
    const totalInvoiceForecast: {
        totalForecast: number
        totalInvoice: number
    } = { totalForecast: 0, totalInvoice: 0 }

    workorders.forEach((wo) => {
        totalInvoiceForecast.totalForecast += getTotalPriceExpense(wo)

        if (wo.status === WorkorderStatus.INVOICED) {
            totalInvoiceForecast.totalInvoice += wo.invoiced_price ?? 0
        }
    })
    return totalInvoiceForecast
}

// export const calculateServiceTotalsRow = (
//     schedule: SmartScheduleDetails | null,
// ) => {
//     const totals = schedule?.template_services.reduce(
//         (acc, row) => {
//             acc.budget += Number(row.budget)
//             acc.variance += Number(row.variance)
//             acc.forecast += Number(row.forecast)
//             acc.invoice += Number(row.invoice)
//             return acc
//         },
//         { budget: 0, variance: 0, forecast: 0, invoice: 0 },
//     )
//     return totals
// }

const numTemplateServices = (schedule: SmartScheduleDetails | null) => {
    if (schedule) {
        return schedule.template_services.length
    }
    return 0
}
