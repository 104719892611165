import React, { useState } from 'react'
import { toast } from 'react-toastify'
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    TextField,
    useTheme,
} from '@material-ui/core'
import LoopIcon from '@material-ui/icons/Loop'
import { axiosInstance, passwordValidator } from '../../../../helpers'
import { useUser } from '../../../../hooks'
import { AddressForm, AddressState, Container } from '../../../../components'
import { PasswordField } from '../../../../components/PasswordField'
import { PasswordRequirements } from '../../../../components/PasswordRequirements'

interface State {
    name: string
    address?: string
    city?: string
    state?: string
    zip_code?: string
    phone: string
    email: string
    password: string
    confirmPassword: string
    showPassword: boolean
    showConfirmPassword: boolean
}

export const RegisterForm = () => {
    const theme = useTheme()

    const { login } = useUser()

    const [values, setValues] = React.useState<State>({
        name: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        phone: '',
        email: '',
        password: '',
        showPassword: false,
        showConfirmPassword: false,
        confirmPassword: '',
    })

    const [companyName, setCompanyName] = useState('')

    const [duplicateEmail, setDuplicateEmail] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [addressState, setAddressState] = useState<AddressState>(
        defaultAddressState,
    )

    const passwordValidResult = passwordValidator(
        values.password,
        values.confirmPassword,
    )
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword })
    }

    const handleClickShowConfirmPassword = () => {
        setValues({
            ...values,
            showConfirmPassword: !values.showConfirmPassword,
        })
    }

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault()
    }

    const handleMouseDownConfirmPassword = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault()
    }

    const onSubmit = () => {
        if (!passwordValidResult.passwordValid) {
            setIsLoading(false)
            toast.error('Password does not meet the requirements.')
            return
        }

        setIsLoading(true)

        setIsLoading(true)
        const body = {
            name: values.name,
            company_name: companyName,
            email: values.email,
            password: values.password,
            phone: values.phone,
            address1: addressState.addressLine1,
            zip_code: addressState.zip,
            city: addressState.city,
            state: addressState.state,
        }
        axiosInstance
            .post('user/ezvendor_user_signup/', body)
            .then((res) => {
                login({
                    email: values.email,
                    password: values.password,
                }).then(() => {
                    setIsLoading(false)
                })
            })
            .catch((e) => {
                setIsLoading(false)
                toast.error(e.response.data.message)
            })
    }

    return (
        <Container
            style={{ flexDirection: 'column', marginTop: theme.spacing(2) }}
        >
            <Container
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: 300,
                    margin: theme.spacing(2, 0, 2, 0),
                }}
            >
                <form autoComplete="off">
                    <FormControl fullWidth>
                        <TextField
                            id="name"
                            label="Name"
                            value={values.name}
                            variant="outlined"
                            type="text"
                            onChange={(e) => {
                                setValues({
                                    ...values,
                                    name: e.target.value,
                                })
                            }}
                            required={true}
                            style={{ marginBottom: theme.spacing(2) }}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            id="companyName"
                            label="Company Name"
                            value={companyName}
                            variant="outlined"
                            type="text"
                            onChange={(e) => {
                                setCompanyName(e.target.value)
                            }}
                            required={true}
                            style={{ marginBottom: theme.spacing(2) }}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            id="phone"
                            label="Phone"
                            value={values.phone}
                            onChange={(e) => {
                                setValues({ ...values, phone: e.target.value })
                            }}
                            variant="outlined"
                            type="text"
                            required={true}
                            style={{ marginBottom: theme.spacing(2) }}
                        />
                    </FormControl>
                    <Container style={{ flexDirection: 'column' }}>
                        <AddressForm
                            addressState={addressState}
                            setAddressState={setAddressState}
                            inputStyle={{
                                marginBottom: theme.spacing(2),
                            }}
                        />
                    </Container>

                    <FormControl fullWidth>
                        <TextField
                            id="email"
                            label="Email"
                            value={values.email}
                            variant="outlined"
                            type="email"
                            onChange={(e) => {
                                setValues({ ...values, email: e.target.value })
                            }}
                            required={true}
                            style={{ marginBottom: theme.spacing(2) }}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <PasswordField
                            value={values.password}
                            label="Password"
                            variant="outlined"
                            showPassword={values.showPassword}
                            handleClickShowPassword={handleClickShowPassword}
                            handleMouseDownPassword={handleMouseDownPassword}
                            onChange={(e) => {
                                setValues({
                                    ...values,
                                    password: e.target.value,
                                })
                            }}
                            required={true}
                            style={{ marginBottom: theme.spacing(2) }}
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <PasswordField
                            value={values.confirmPassword}
                            label="Confirm Password"
                            variant="outlined"
                            showPassword={values.showConfirmPassword}
                            handleClickShowPassword={
                                handleClickShowConfirmPassword
                            }
                            handleMouseDownPassword={
                                handleMouseDownConfirmPassword
                            }
                            onChange={(e) => {
                                setValues({
                                    ...values,
                                    confirmPassword: e.target.value,
                                })
                            }}
                            required={true}
                            error={passwordValidResult.passwordValid}
                            helperText={'Passwords do not match'}
                            style={{ marginBottom: theme.spacing(2) }}
                        />
                    </FormControl>
                    <PasswordRequirements
                        passwordRequirementResult={passwordValidResult}
                        containerStyle={{
                            justifyContent: 'center',
                            margin: theme.spacing(1, 0),
                        }}
                    />
                    <FormControlLabel
                        control={<Checkbox name="checkedB" color="primary" />}
                        label="Remember Password?"
                    />
                    <Button
                        variant="contained"
                        style={{
                            background: theme.palette.primary.dark,
                            color: 'white',
                            textTransform: 'none',
                            fontWeight: theme.typography.fontWeightBold,
                            borderRadius: theme.shape.borderRadius,
                            boxShadow: 'none',
                            width: '100%',
                            height: '50px',
                        }}
                        onClick={onSubmit}
                    >
                        {isLoading ? (
                            <LoopIcon
                                style={{
                                    animation: 'spin 01s linear infinite',
                                }}
                            />
                        ) : (
                            'Sign Up'
                        )}
                    </Button>
                </form>
            </Container>
        </Container>
    )
}

const defaultAddressState: AddressState = {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
}
