import {
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    MenuItem,
    Modal,
    Paper,
    Slide,
    Step,
    StepButton,
    Stepper,
    TextField,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from '../Container'
import { axiosInstance, isEmailValid, statesList } from '../../helpers'
import { ApartmentDetailForm } from './ApartmentDetailForm'
import { Selector } from '../Selector'
import { toast } from 'react-toastify'
import { Company, IdBoolMap, IdentifiableNamedObject } from '../../models'
import { OnboardApartmentFileForm } from './OnboardApartmentFileForm'
import { Pagination } from '@material-ui/lab'
import { usePagination } from '../../hooks'

interface Props {
    open: boolean
    onClose: () => void
}

export const CopyConfigsModal = (props: Props) => {
    const { open, onClose } = props

    const theme = useTheme()

    const [allApartments, setAllApartments] = useState<Company[]>([])
    const [selectedAptId, setSelectedAptId] = useState(-1)
    const [organizationList, setOrganizationList] = useState<
        IdentifiableNamedObject[] | null
    >(null)
    const [checkedMap, setCheckedMap] = useState<IdBoolMap>({})

    const [sourceLoading, setSourceLoading] = useState(false)
    const [orgLoading, setOrgLoading] = useState(false)

    const [selectedOrgId, setSelecetedOrgId] = useState(-1)
    const [orgApartments, setOrgApartments] = useState<Company[]>([])
    const [confirmationEmail, setConfirmationEmail] = useState('')

    const [makeUnits, setMakeUnits] = useState(false)

    useEffect(() => {
        setSourceLoading(true)
        setOrgLoading(true)
        axiosInstance
            .get('company/apartment/', {
                params: { all_apartments: true },
            })
            .then((res) => setAllApartments(res.data))
            .catch((e) => toast.error(e))
            .finally(() => setSourceLoading(false))

        axiosInstance
            .get('company/organization/')
            .then((res) => {
                setOrganizationList(res.data)
            })
            .catch((e) => toast.error(e))
            .finally(() => setOrgLoading(false))
    }, [])

    const selectedWorkspaceIds = Object.keys(checkedMap).reduce<number[]>(
        (prev, id) => {
            if (id === 'length') {
                return prev
            }
            const numID = Number(id)
            if (checkedMap[numID] === true) {
                return prev.concat(numID)
            }
            return prev
        },
        [],
    )
    const allChecked = selectedWorkspaceIds.length === orgApartments.length
    const atLeastOneChecked = selectedWorkspaceIds.length > 0 && !allChecked

    const rowsPerPage = 16
    const { page, setPage, pageData, numPages, start, end } = usePagination(
        orgApartments,
        rowsPerPage,
    )

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper>
                    <Container
                        style={{
                            height: 600,
                            width: 750,
                            display: 'flex',
                            overflow: 'hidden',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            flexDirection: 'column',
                            flex: 1,
                            padding: theme.spacing(2),
                        }}
                    >
                        {/* Header */}
                        <Container>
                            <Container
                                style={{
                                    width: '50%',
                                    justifyContent: 'center',
                                }}
                            >
                                {sourceLoading ? (
                                    <CircularProgress size={50} />
                                ) : (
                                    <Selector
                                        label="Source Apt"
                                        currentValue={selectedAptId}
                                        onChange={(
                                            event: React.ChangeEvent<{
                                                value: unknown
                                            }>,
                                        ) => {
                                            setSelectedAptId(
                                                event.target.value as number,
                                            )
                                        }}
                                        searchable
                                        maxItems={100}
                                        data={allApartments}
                                        getDisplayString={(apt) => apt.name}
                                        customStyle={{
                                            formControl: {
                                                width: '100%',
                                                marginRight: theme.spacing(1),
                                            },
                                        }}
                                    />
                                )}
                            </Container>

                            <Container
                                style={{
                                    width: '50%',
                                    justifyContent: 'center',
                                }}
                            >
                                {orgLoading ? (
                                    <CircularProgress size={50} />
                                ) : (
                                    <Selector
                                        label="Organization"
                                        data={organizationList ?? []}
                                        currentValue={selectedOrgId}
                                        onChange={(
                                            event: React.ChangeEvent<{
                                                value: unknown
                                            }>,
                                        ) => {
                                            const newSelectedOrgId = event
                                                .target.value as number
                                            setSelecetedOrgId(newSelectedOrgId)
                                            const newOrgApartments = allApartments
                                                .filter(
                                                    (apt) =>
                                                        apt.organization ===
                                                        newSelectedOrgId,
                                                )
                                                .sort((apt1, apt2) =>
                                                    apt1.name.localeCompare(
                                                        apt2.name,
                                                    ),
                                                )
                                            setOrgApartments(newOrgApartments)
                                            const newCheckedMap: IdBoolMap =
                                                newOrgApartments?.reduce<IdBoolMap>(
                                                    (prev, iC, idx) => {
                                                        return {
                                                            ...prev,
                                                            [iC.id]: false,
                                                        }
                                                    },
                                                    {},
                                                ) ?? {}
                                            setCheckedMap(newCheckedMap)
                                        }}
                                        getDisplayString={(org) => org.name}
                                        customStyle={{
                                            formControl: {
                                                width: '100%',
                                            },
                                        }}
                                        maxItems={100}
                                        searchable
                                    />
                                )}
                            </Container>
                        </Container>

                        {/* Body */}
                        <Container style={{ flexDirection: 'column' }}>
                            <FormControlLabel
                                style={{ marginLeft: theme.spacing(1) }}
                                control={
                                    <Checkbox
                                        checked={allChecked}
                                        style={{
                                            color: theme.palette.secondary.main,
                                        }}
                                        // XOR
                                        indeterminate={atLeastOneChecked}
                                        onChange={() => {
                                            const newMap: IdBoolMap = {}
                                            Object.keys(checkedMap).forEach(
                                                (keyStr) => {
                                                    const key = Number(keyStr)
                                                    newMap[key] = !allChecked
                                                },
                                            )
                                            setCheckedMap(newMap)
                                        }}
                                    />
                                }
                                label={
                                    atLeastOneChecked
                                        ? 'Remove All'
                                        : 'Select All'
                                }
                            />
                            <Container>
                                <Container
                                    style={{
                                        flexDirection: 'column',
                                        width: '50%',
                                    }}
                                >
                                    {pageData
                                        .filter((apt, idx) => idx % 2 === 0)
                                        .map((apt) => {
                                            return (
                                                <CopyConfigsAptRow
                                                    key={`ORG_${selectedOrgId}_APARTMENT_LEFT_${apt.id}`}
                                                    apt={apt}
                                                    setCheckedMap={
                                                        setCheckedMap
                                                    }
                                                    checkedMap={checkedMap}
                                                />
                                            )
                                        })}
                                </Container>
                                <Container
                                    style={{
                                        flexDirection: 'column',
                                        width: '50%',
                                    }}
                                >
                                    {pageData
                                        .filter((apt, idx) => idx % 2 === 1)
                                        .map((apt) => {
                                            return (
                                                <CopyConfigsAptRow
                                                    key={`ORG_${selectedOrgId}_APARTMENT_RIGHT_${apt.id}`}
                                                    apt={apt}
                                                    setCheckedMap={
                                                        setCheckedMap
                                                    }
                                                    checkedMap={checkedMap}
                                                />
                                            )
                                        })}
                                </Container>
                            </Container>
                        </Container>
                        <div style={{ flex: 1 }} />
                        <Container>
                            <div style={{ flex: 1 }} />
                            <TextField
                                placeholder="Confirmation Email"
                                value={confirmationEmail}
                                onChange={(e) =>
                                    setConfirmationEmail(e.target.value)
                                }
                                style={{ marginBottom: 8 }}
                                variant="outlined"
                            />
                        </Container>
                        {/* Footer */}
                        <Container>
                            <Container
                                style={{ flex: 1, justifyContent: 'flex-end' }}
                            >
                                <span
                                    style={{
                                        textAlign: 'center',
                                        alignItems: 'center',
                                        color: theme.palette.lightGrey.main,
                                        paddingTop: theme.spacing(1),
                                    }}
                                >
                                    {start} - {end} of {orgApartments.length}
                                </span>
                                <Pagination
                                    count={numPages}
                                    page={page}
                                    onChange={(_: unknown, newPage: number) => {
                                        setPage(newPage)
                                    }}
                                    variant="outlined"
                                    shape="rounded"
                                    style={{ flex: 1 }}
                                />
                            </Container>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={makeUnits}
                                        style={{
                                            color: theme.palette.primary.main,
                                        }}
                                        onClick={() => {
                                            setMakeUnits(!makeUnits)
                                        }}
                                    />
                                }
                                label={'Copy Units'}
                                labelPlacement="end"
                                style={{
                                    fontWeight: 'bold',
                                    marginLeft: theme.spacing(1),
                                }}
                            />

                            <Button
                                onClick={() => {
                                    if (selectedAptId === -1) {
                                        toast.error(
                                            'Please Select A Source Apt',
                                        )
                                        return
                                    }

                                    if (selectedWorkspaceIds.length === 0) {
                                        toast.error(
                                            'Please Select at least one Target Company',
                                        )
                                        return
                                    }

                                    if (!isEmailValid(confirmationEmail)) {
                                        toast.error('Please enter your email')
                                        return
                                    }

                                    axiosInstance
                                        .post(
                                            `company/apartment/${selectedAptId}/copy_apt_configs_and_infrastructure/`,
                                            {
                                                targets: selectedWorkspaceIds,
                                                create_infrastructure: makeUnits,
                                                email: confirmationEmail,
                                            },
                                        )
                                        .then((res) => {
                                            toast.success(
                                                'Your Request is being Processed, you will recieve an email when done',
                                            )
                                        })
                                        .catch((e) => toast.error(e))
                                }}
                                style={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: 'white',
                                }}
                            >
                                Copy Data
                            </Button>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

interface AptRowProps {
    checkedMap: IdBoolMap
    setCheckedMap: (newCheckedMap: IdBoolMap) => void
    apt: Company
}

const CopyConfigsAptRow = (props: AptRowProps) => {
    const { checkedMap, setCheckedMap, apt } = props

    const theme = useTheme()

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={checkedMap[apt.id] ?? false}
                    style={{ color: theme.palette.primary.main }}
                    onClick={() => {
                        const newCheckedMap: IdBoolMap = {
                            ...checkedMap,
                        }
                        newCheckedMap[apt.id] = !checkedMap[apt.id]
                        setCheckedMap(newCheckedMap)
                    }}
                />
            }
            label={<Container>{apt.name}</Container>}
            labelPlacement="end"
            style={{
                fontWeight: 'bold',
                marginLeft: theme.spacing(1),
            }}
        />
    )
}
