import React from 'react'
import { Tooltip, useTheme } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { Container, NestedProgress } from '../../components'
import InfoIcon from '@material-ui/icons/Info'
import FlagIcon from '@material-ui/icons/Flag'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

interface Props {
    totalUnitCount: number
}

const PROGRESS_WIDTH = 150

export const InspectionAnalyticsHeadline = (props: Props) => {
    const { totalUnitCount } = props
    const inspectionAnalytics = useSelector(
        (state: RootState) => state.inspection.inspectionAnalytics,
    )
    const assignedCount = inspectionAnalytics?.assigned ?? 0
    const totalFlagCount = inspectionAnalytics?.total_flag_count ?? 0
    const completeCount = inspectionAnalytics?.complete_count ?? 0
    const totalHandled = inspectionAnalytics?.total_handled ?? 0
    const filterFlagCount = inspectionAnalytics?.filtered_flag_count ?? 0
    const uninspectedCount = inspectionAnalytics?.uninspected ?? 0
    const filteredUnitCount = inspectionAnalytics?.filtered_unit_count ?? 0
    const filteredHandled = inspectionAnalytics?.filtered_handled
    let filteredCost = 0
    let filteredInventory = 0
    let filteredCostCount = 0
    if (inspectionAnalytics) {
        Object.keys(inspectionAnalytics?.inventory_config_analytics).forEach(
            (inv_conf) => {
                Object.keys(
                    inspectionAnalytics.inventory_config_analytics[inv_conf],
                ).forEach((status) => {
                    filteredCost +=
                        inspectionAnalytics.inventory_config_analytics[
                            inv_conf
                        ][status]['cost'] ?? 0
                    filteredInventory +=
                        inspectionAnalytics.inventory_config_analytics[
                            inv_conf
                        ][status]['count'] ?? 0
                    if (
                        inspectionAnalytics.inventory_config_analytics[
                            inv_conf
                        ][status]['cost'] !== null
                    ) {
                        filteredCostCount +=
                            inspectionAnalytics.inventory_config_analytics[
                                inv_conf
                            ][status]['count'] ?? 0
                    }
                })
            },
        )
    }

    const theme = useTheme()

    const safeUnitCount = totalUnitCount > 0 ? totalUnitCount : 1
    const safeAssignedCount = assignedCount > 0 ? assignedCount : 1
    const safetotalFlagCount = totalFlagCount > 0 ? totalFlagCount : 1

    const assignedProgress = (assignedCount / safeUnitCount) * 100
    const completeProgress = (completeCount / safeAssignedCount) * 100
    const handledProgress = (totalHandled / safetotalFlagCount) * 100

    return (
        <Container
            style={{
                ...theme.typography.subtitle2,
                fontWeight: theme.typography.fontWeightBold,
                width: '100%',
            }}
        >
            <Container
                style={{ flexDirection: 'column', alignItems: 'center' }}
            >
                <Container
                    style={{
                        marginRight: theme.spacing(2),
                    }}
                >
                    <Container
                        style={{
                            flexDirection: 'column',
                            width: PROGRESS_WIDTH,
                            height: 120,
                            alignItems: 'center',
                        }}
                    >
                        <span>Assigned / Total</span>
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightRegular,
                            }}
                        >
                            {assignedCount} / {totalUnitCount}
                        </span>

                        <NestedProgress
                            centerText={assignedProgress}
                            suffix="%"
                            data={[
                                {
                                    progress: assignedProgress,
                                    size: 70,
                                    barKey: `ASSIGN`,
                                    primaryStroke: theme.palette.secondary.main,
                                    backdropStroke: theme.palette.grey[400],
                                },
                            ]}
                        />
                    </Container>

                    <Container
                        style={{
                            flexDirection: 'column',
                            width: PROGRESS_WIDTH,
                            height: 120,
                            alignItems: 'center',
                        }}
                    >
                        <span>Complete / Assigned</span>
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightRegular,
                            }}
                        >
                            {completeCount} / {assignedCount}
                        </span>

                        <NestedProgress
                            centerText={completeProgress}
                            suffix="%"
                            data={[
                                {
                                    progress: completeProgress,
                                    size: 70,
                                    barKey: `COMPLETE`,
                                    primaryStroke: theme.palette.primary.main,
                                    backdropStroke: theme.palette.grey[400],
                                },
                            ]}
                        />
                    </Container>

                    <Container
                        style={{
                            flexDirection: 'column',
                            width: PROGRESS_WIDTH,
                            height: 120,
                            alignItems: 'center',
                        }}
                    >
                        <span>Handled / Flagged</span>
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightRegular,
                            }}
                        >
                            {totalHandled} / {totalFlagCount}
                        </span>

                        <NestedProgress
                            centerText={handledProgress}
                            suffix="%"
                            data={[
                                {
                                    progress: handledProgress,
                                    size: 70,
                                    barKey: `HANDLED`,
                                    primaryStroke: theme.palette.primary.dark,
                                    backdropStroke: theme.palette.grey[400],
                                },
                            ]}
                        />
                    </Container>
                </Container>
            </Container>

            <div style={{ flex: 1 }} />

            <Container
                style={{ flexDirection: 'column', alignItems: 'center' }}
            >
                <span
                    style={{
                        ...theme.typography.h5,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Filter Analytics
                </span>
                <Container>
                    <Container style={{ flexDirection: 'column' }}>
                        <Container style={{ alignItems: 'center' }}>
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Total Cost:
                            </span>

                            <NumberFormat
                                prefix="$"
                                thousandSeparator
                                value={filteredCost}
                                displayType="text"
                                style={{
                                    ...theme.typography.subtitle2,
                                    marginLeft: theme.spacing(1),
                                }}
                            />
                        </Container>

                        <Container style={{ alignItems: 'center' }}>
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Cost Drivers
                            </span>

                            <Tooltip title="Number of items that have a cost > $0">
                                <InfoIcon fontSize="small" color="action" />
                            </Tooltip>
                            <NumberFormat
                                thousandSeparator
                                value={filteredCostCount}
                                displayType="text"
                                style={{
                                    ...theme.typography.subtitle2,
                                    marginLeft: theme.spacing(1),
                                }}
                            />
                        </Container>

                        <Container style={{ alignItems: 'center' }}>
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Flagged
                            </span>

                            <Tooltip title="Number of items who's status causes a flag">
                                <FlagIcon fontSize="small" color="action" />
                            </Tooltip>

                            <NumberFormat
                                thousandSeparator
                                value={filterFlagCount}
                                displayType="text"
                                style={{
                                    ...theme.typography.subtitle2,
                                    marginLeft: theme.spacing(1),
                                }}
                            />
                        </Container>

                        <Container style={{ alignItems: 'center' }}>
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Uninspected
                            </span>
                            <Tooltip title="Number of items where the inspector did not select a status.">
                                <InfoIcon fontSize="small" color="action" />
                            </Tooltip>

                            <NumberFormat
                                thousandSeparator
                                value={uninspectedCount}
                                displayType="text"
                                style={{
                                    ...theme.typography.subtitle2,
                                    marginLeft: theme.spacing(1),
                                }}
                            />
                        </Container>
                    </Container>

                    <Container
                        style={{
                            ...theme.typography.subtitle1,
                            fontWeight: theme.typography.fontWeightBold,
                            flexDirection: 'column',
                            marginLeft: theme.spacing(2),
                        }}
                    >
                        <Container style={{ alignItems: 'center' }}>
                            <span>Units In filter:</span>

                            <span
                                style={{
                                    ...theme.typography.subtitle2,
                                    marginLeft: theme.spacing(1),
                                }}
                            >
                                {filteredUnitCount}
                            </span>
                        </Container>

                        <Container style={{ alignItems: 'center' }}>
                            <span>Inventory In filter:</span>

                            <span
                                style={{
                                    ...theme.typography.subtitle2,
                                    marginLeft: theme.spacing(1),
                                }}
                            >
                                {filteredInventory}
                            </span>
                        </Container>

                        <Container style={{ alignItems: 'center' }}>
                            <span>Handled In filter:</span>

                            <Tooltip title="Number of flagged items that have been handled">
                                <AssignmentTurnedInIcon
                                    fontSize="small"
                                    color="action"
                                />
                            </Tooltip>

                            <span
                                style={{
                                    ...theme.typography.subtitle2,
                                    marginLeft: theme.spacing(1),
                                }}
                            >
                                {filteredHandled}
                            </span>
                        </Container>
                    </Container>
                </Container>
            </Container>

            <div style={{ flex: 1 }} />
        </Container>
    )
}
