import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { axiosInstance, LocalStorage } from '../helpers'
import { useAppDispatch, useChannelList, useCompany } from '../hooks'
import { User, WorkSpaceUser } from '../models'

import {
    login,
    LoginActionThunk,
    LoginRequest,
    setRootUser,
    getUserList,
    BaseUserListThunk,
    GetUserListRequest,
    CreateUserRequest,
    UserDetailActionThunk,
    createUser,
    UpdateUserRequest,
    updateUser,
    getRootUser,
    setWorkSpaceUser,
    RootState,
    getInfrastructure,
    UpdateUserRequestFormData,
} from '../store'

type UseUser = {
    rootUser?: User
    workspaceUser?: WorkSpaceUser
    userList: User[]
    showSplash: boolean
    login: (req: LoginRequest) => LoginActionThunk
    saveUserToState: (user: User) => void
    getUserList: (req: GetUserListRequest) => BaseUserListThunk
    createUser: (req: CreateUserRequest) => UserDetailActionThunk
    updateUser: (
        req: UpdateUserRequest | UpdateUserRequestFormData,
    ) => UserDetailActionThunk
    logout: () => void
    loginWithToken: (token: string) => void
}

interface Options {
    shouldAuthenticate?: boolean
    shouldSetup?: boolean
}

export const useUser = (options?: Options): UseUser => {
    const dispatch = useAppDispatch()

    const rootUser = useSelector((state: RootState) => state.user.rootUser)
    const workspaceUser = useSelector(
        (state: RootState) => state.user.workspaceUser,
    )
    const userList = useSelector((state: RootState) => state.user.userList)

    const shouldAuthenticate = options?.shouldAuthenticate

    let shouldSetup = options?.shouldSetup
    const queryParams = new URLSearchParams(location.search)
    const setup = queryParams.get('setup')
    if (setup === 'false') {
        shouldSetup = false
    }

    const { getChannelList, getChannelDetail } = useChannelList()
    const { getApartmentList } = useCompany()

    const _logout = () => {
        dispatch(setRootUser(undefined))
        localStorage.removeItem(LocalStorage.EZTURN4_TOKEN)
    }

    useEffect(() => {
        if (shouldAuthenticate) {
            const token = localStorage.getItem(LocalStorage.EZTURN4_TOKEN)
            // check local storage for a token

            if (token) {
                //set the token for axios
                axiosInstance.defaults.headers.common[
                    'Authorization'
                ] = `token ${token}`
                // make user detail request
                dispatch(getRootUser({})).catch(() => {
                    _logout()
                    location.reload()
                })
            }
        }
    }, [shouldAuthenticate])

    useEffect(() => {
        // if there is a root user, set the workspace user
        if (
            shouldAuthenticate &&
            rootUser &&
            rootUser.workspaces.length !== 0
        ) {
            const token = localStorage.getItem(LocalStorage.EZTURN4_TOKEN)
            // see if there is a token in local storage
            let ws_user: WorkSpaceUser | undefined = rootUser.workspaces[0]
            if (token) {
                // if there is a token, try to find a workspace user with that token
                ws_user = rootUser.workspaces.find(
                    (u: any) => u.token === token,
                )
            }

            dispatch(setWorkSpaceUser(ws_user))
        }
    }, [rootUser, shouldAuthenticate])

    useEffect(() => {
        if (workspaceUser && shouldSetup) {
            // Apartment Specific Setup
            if (workspaceUser.active_workspace.company_type === 'APARTMENT') {
                dispatch(
                    getInfrastructure({
                        apartmentId: workspaceUser.active_workspace.id,
                    }),
                )
            }
            // Vendor Specific Setup
            else if (workspaceUser.active_workspace.company_type === 'VENDOR') {
                getApartmentList({ params: {} })
            }

            // Generic Setup
            getChannelList({
                params: {
                    in_ezchat: true,
                },
            }).then((res) => {
                if (res.data.length > 0) {
                    getChannelDetail({ channelId: res.data[0].id })
                }
            })
        }
    }, [workspaceUser])

    const loginWithToken = (token: string) => {
        const axiosConfig: any = {
            headers: {
                common: {
                    Authorization: `token ${token}`,
                },
            },
        }
        axiosInstance.get('user/get_root_user/', axiosConfig).then((res) => {
            const rootUser = res.data
            dispatch(setRootUser(rootUser))
            dispatch(setWorkSpaceUser(rootUser.workspaces[0]))
        })
    }

    const token = localStorage.getItem(LocalStorage.EZTURN4_TOKEN)
    let unauthenticated = workspaceUser === undefined
    if (unauthenticated && rootUser?.workspaces.length === 0) {
        unauthenticated = false
    }
    const tokenExists = token !== null

    const showSplash = unauthenticated && tokenExists

    return {
        rootUser: rootUser,
        workspaceUser: workspaceUser,
        userList: userList,
        login: (req) => dispatch(login(req)),
        saveUserToState: (user) => dispatch(setRootUser(user)),
        getUserList: (req) => dispatch(getUserList(req)),
        createUser: (req) => dispatch(createUser(req)),
        updateUser: (req) => dispatch(updateUser(req)),
        logout: _logout,
        showSplash: showSplash,
        loginWithToken: loginWithToken,
    }
}
