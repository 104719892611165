import { useTheme } from '@material-ui/core'
import React from 'react'
import { Container } from '../Container'

interface CustomStyle {
    container?: React.CSSProperties
    text?: React.CSSProperties
}

interface Props {
    text: string
    customStyle?: CustomStyle
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const SB: React.FC<Props> = (props) => {
    const theme = useTheme()

    return (
        <Container
            alignItems="center"
            style={{
                justifyContent: 'center',
                backgroundColor: theme.palette.primary.main,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(0.75),
                ...props.customStyle?.container,
            }}
            onClick={props.onClick}
        >
            <span
                style={{
                    ...theme.typography.body2,
                    fontWeight: theme.typography.fontWeightLight,
                    color: theme.palette.primary.contrastText,
                    textAlign: 'center',
                    ...props.customStyle?.text,
                }}
            >
                {props.text}
            </span>
        </Container>
    )
}

export const StatusBadge: React.FC<Props> = React.memo(SB)
