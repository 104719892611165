import React, { useState } from 'react'

import { Blanket, BlanketProps, Container } from '../../../components'
import { BaseWorkorder, Folder, ModelListMap, Unit } from '../../../models'
import { Theme, Tabs, Tab } from '@material-ui/core'
import { AnalyticsBlanket } from './AnalyticsBlanket'
import { BurnDownBlanket } from './BurndownBlanket'

interface Props extends BlanketProps {
    theme: Theme
    infrastructure: Folder
    unitWorkorderMap: ModelListMap<BaseWorkorder>
    workorders: BaseWorkorder[]
    onClose: () => void
    isWorkorderInFilter: (workorder: BaseWorkorder, unit: Unit) => boolean
}

export const ScheduleBlanketRoot = (props: Props) => {
    const { theme } = props

    const [selectedTab, setSelectedTab] = useState(0)

    return (
        <Blanket {...props}>
            <Container
                style={{
                    flex: 1,
                    backgroundColor: props.theme.palette.grey[100],
                    boxShadow: theme.shadows[5],
                    flexDirection: 'column',
                }}
                scrollY
            >
                <Tabs
                    value={selectedTab}
                    onChange={(_, v) => {
                        if (v !== selectedTab) {
                            setSelectedTab(v)
                        }
                    }}
                >
                    <Tab
                        label="Burn Down"
                        value={0}
                        style={{ textTransform: 'none' }}
                    />
                    <Tab
                        label="Assignment Details"
                        value={1}
                        style={{ textTransform: 'none' }}
                    />
                </Tabs>

                {selectedTab === 0 && <BurnDownBlanket {...props} />}
                {selectedTab === 1 && <AnalyticsBlanket {...props} open />}
            </Container>
        </Blanket>
    )
}
