import React, { useEffect, useState } from 'react'

import {
    CircularProgress,
    IconButton,
    Popover,
    Theme,
    Tooltip,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import CloseIcon from '@material-ui/icons/Close'

import NumberFormat from 'react-number-format'

import { CalendarPopoverState, FormState } from '../types'
import { Container, StyledProgressBar } from '../../../components'
import {
    CreateScheduleRequest,
    ScheduleDetailActionThunk,
    UpdateScheduleRequest,
} from '../../../store'
import { ScheduleForm } from './ScheduleForm'
import { axiosInstance, isHex, toMMDDYYYY } from '../../../helpers'
import { Schedule, WorkSpaceUser } from '../../../models'
import { ScheduleReportPopOver } from './ScheduleReportPopover/ScheduleReportPopover'

interface Props {
    formState: FormState
    theme: Theme
    loading?: boolean
    workspaceUser?: WorkSpaceUser
    onClose: () => void
    createSchedule: (req: CreateScheduleRequest) => ScheduleDetailActionThunk
    updateSchedule: (req: UpdateScheduleRequest) => ScheduleDetailActionThunk
    navigateToScheduleDetail: (scheduleId: number) => void
}
export const ScheduleDetailPopover = (props: Props) => {
    const { formState, onClose, theme, navigateToScheduleDetail } = props

    const popoverState = formState.popovers

    const schedule = popoverState.projectPopover.schedule

    const [color, setColor] = useState(
        schedule?.color ?? theme.palette.primary.main,
    )

    const contrastColor = isHex(color)
        ? theme.palette.getContrastText(color)
        : '#000'

    const [displayMode, setDisplayMode] = useState(DisplayMode.DETAIL)

    useEffect(() => {
        // Determine what view we are going to display
        // 1. Schedule detail
        // 2. Edit / create
        if (schedule === null || schedule === undefined) {
            // always display edit create
            setDisplayMode(DisplayMode.CREATE_EDIT)
        } else {
            setColor(schedule.color)
            setDisplayMode(DisplayMode.DETAIL)
        }
    }, [schedule])

    const popoverW = displayMode === DisplayMode.CREATE_EDIT ? FORM_W : DETAIL_W

    return (
        <Popover
            anchorReference="anchorPosition"
            anchorPosition={{
                top: formState.meta.yPos,
                left: formState.meta.xPos,
            }}
            open={popoverState.projectPopover.open}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
            <Container
                style={{
                    minHeight: POPOVER_H,
                    maxHeight: POPOVER_H,
                    minWidth: popoverW,
                    maxWidth: popoverW,
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                {/* Header */}
                <Container
                    style={{
                        padding: theme.spacing(1),
                        borderBottom: `1px solid ${theme.palette.grey[400]}`,
                        backgroundColor: color,
                        minHeight: HEADER_H,
                        maxHeight: HEADER_H,
                    }}
                >
                    {schedule && (
                        <Tooltip title={`Go to ${schedule.name}`}>
                            <IconButton
                                style={{
                                    marginRight: theme.spacing(1),
                                }}
                                onClick={() => {
                                    navigateToScheduleDetail(schedule.id)
                                }}
                            >
                                <ExitToAppIcon htmlColor={contrastColor} />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Container
                        style={{
                            flexDirection: 'column',
                            flex: 1,
                            color: contrastColor,
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {schedule?.name ?? 'New Project'}
                        </span>

                        <span
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {schedule?.schedule_id}
                        </span>
                    </Container>

                    <IconButton onClick={onClose}>
                        <CloseIcon htmlColor={contrastColor} />
                    </IconButton>
                </Container>
                {/* Body */}

                {displayMode === DisplayMode.DETAIL && (
                    <ScheduleDetail
                        {...props}
                        schedule={schedule ?? null}
                        color={color}
                        setColor={setColor}
                    />
                )}

                {displayMode === DisplayMode.CREATE_EDIT && (
                    <ScheduleForm
                        {...props}
                        schedule={schedule ?? null}
                        color={color}
                        setColor={setColor}
                        initialStartDate={formState.meta.date}
                    />
                )}
            </Container>
        </Popover>
    )
}

interface DetailProps {
    theme: Theme
    schedule: Schedule | null
    loading?: boolean
    formState: FormState
    color: string
    workspaceUser?: WorkSpaceUser
    setColor: (color: string) => void
    onClose: () => void
    createSchedule: (req: CreateScheduleRequest) => ScheduleDetailActionThunk
    updateSchedule: (req: UpdateScheduleRequest) => ScheduleDetailActionThunk
}
const ScheduleDetail = (props: DetailProps) => {
    const { schedule, color, setColor, formState, theme, workspaceUser } = props

    const popoverState = formState.popovers

    const [editFormOpen, setEditFormOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [summary, setSummary] = useState<ScheduleSummary | null>(null)

    useEffect(() => {
        if (schedule) {
            setLoading(true)

            axiosInstance
                .get(`workorder/schedule/${schedule.id}/summary/`)
                .then((res) => {
                    setSummary(res.data)
                })
                .finally(() => setLoading(false))
        }
    }, [schedule])

    let startDate = new Date()
    let endDate = new Date()

    let completePercent = 0
    let approvedPercent = 0
    let invoicedPercent = 0
    if (schedule) {
        startDate = new Date(schedule.start_date)
        endDate = new Date(schedule.end_date)

        if (schedule.assigned_count > 0) {
            completePercent =
                (schedule.complete_count / schedule.assigned_count) * 100
            approvedPercent =
                (schedule.approved_count / schedule.assigned_count) * 100
            invoicedPercent =
                (schedule.invoiced_count / schedule.assigned_count) * 100
        }
    }

    const barStyle: React.CSSProperties = {
        marginTop: 5,
        marginBottom: 5,
    }

    const labelStyle: React.CSSProperties = {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightLight,
        width: 100,
    }

    const valueStyle: React.CSSProperties = {
        ...theme.typography.body2,
        fontWeight: theme.typography.fontWeightBold,
    }

    return (
        <Container style={{ flexDirection: 'column', flex: 1 }}>
            {loading && (
                <Container
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1,
                    }}
                >
                    <CircularProgress size={100} />
                </Container>
            )}

            {!loading && (
                <Container style={{ flexDirection: 'column' }}>
                    {/* Date range & edit button */}
                    <Container
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: theme.spacing(1),
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h6,
                                fontWeight: theme.typography.fontWeightBold,
                                color: theme.palette.grey[700],
                                textAlign: 'center',
                            }}
                        >
                            {toMMDDYYYY(startDate)} - {toMMDDYYYY(endDate)}
                        </span>
                        <div style={{ flex: 1 }} />

                        <ScheduleReportPopOver
                            schedule={schedule ?? undefined}
                            workspaceUser={workspaceUser}
                        />

                        <Tooltip title="Edit">
                            <IconButton onClick={() => setEditFormOpen(true)}>
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Container>

                    {/* Summary details */}

                    <Container
                        style={{
                            padding: theme.spacing(1),
                            flexDirection: 'column',
                        }}
                    >
                        {/* Row 1 Assignment info */}
                        <span
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                                color: theme.palette.grey[700],
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Assignment Analytics
                        </span>

                        <Container
                            style={{
                                marginBottom: theme.spacing(3),
                            }}
                        >
                            {/* Column 1 */}
                            <Container
                                style={{ flexDirection: 'column', width: 200 }}
                            >
                                <Container>
                                    <span style={labelStyle}>Assigned</span>
                                    <span style={valueStyle}>
                                        {summary?.total_workorder_count}
                                    </span>
                                </Container>
                                <Container>
                                    <span style={labelStyle}>Behind</span>
                                    <span style={valueStyle}>
                                        {summary?.behind}
                                    </span>
                                </Container>
                                <Container>
                                    <span style={labelStyle}>Not Started</span>
                                    <span style={valueStyle}>
                                        {summary?.not_started}
                                    </span>
                                </Container>
                                <Container>
                                    <span style={labelStyle}>In Progress</span>
                                    <span style={valueStyle}>
                                        {summary?.in_progress}
                                    </span>
                                </Container>
                            </Container>

                            {/* Column 2 */}
                            <Container style={{ flexDirection: 'column' }}>
                                <Container>
                                    <span style={labelStyle}>Complete</span>
                                    <span style={valueStyle}>
                                        {summary?.complete}
                                    </span>
                                </Container>
                                <Container>
                                    <span style={labelStyle}>Approved</span>
                                    <span style={valueStyle}>
                                        {summary?.approved}
                                    </span>
                                </Container>
                                <Container>
                                    <span style={labelStyle}>Invoiced</span>
                                    <span style={valueStyle}>
                                        {summary?.invoiced}
                                    </span>
                                </Container>
                            </Container>
                        </Container>

                        {/* Row 2 Service Info */}
                        <span
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                                color: theme.palette.grey[700],
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Services
                        </span>
                        <Container
                            style={{
                                marginBottom: theme.spacing(3),
                                overflowX: 'scroll',
                            }}
                        >
                            {summary?.service_list.map((serviceDetail: any) => {
                                return (
                                    <Container
                                        key={`SERVICE-${serviceDetail.id}`}
                                        style={{
                                            marginRight: theme.spacing(2),
                                            minWidth: 200,
                                            maxWidth: 200,
                                        }}
                                    >
                                        <span>
                                            {serviceDetail.name} (
                                            {serviceDetail.count})
                                        </span>
                                    </Container>
                                )
                            })}
                        </Container>

                        {/* Row 3 Vendor Info */}
                        <span
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                                color: theme.palette.grey[700],
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Vendors
                        </span>
                        <Container
                            style={{
                                marginBottom: theme.spacing(3),
                                overflowX: 'scroll',
                            }}
                        >
                            {summary?.vendor_list.map((vendorDetail: any) => {
                                return (
                                    <Container
                                        key={`VENDOR-${vendorDetail.id}`}
                                        style={{
                                            marginRight: theme.spacing(2),
                                            minWidth: 200,
                                            maxWidth: 200,
                                        }}
                                    >
                                        <span>
                                            {vendorDetail.name} (
                                            {vendorDetail.count})
                                        </span>
                                    </Container>
                                )
                            })}
                        </Container>

                        {/* Row 4 Budget Info */}
                        <span
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                                color: theme.palette.grey[700],
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Budget Details
                        </span>

                        <Container
                            style={{
                                flexDirection: 'column',
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            <Container>
                                <span style={labelStyle}>Set</span>
                                <NumberFormat
                                    style={valueStyle}
                                    prefix="$"
                                    value={schedule?.baseline_budget}
                                    displayType="text"
                                    fixedDecimalScale
                                    thousandSeparator
                                    decimalScale={2}
                                />
                            </Container>
                            <Container>
                                <span style={labelStyle}>Forecast</span>
                                <NumberFormat
                                    style={valueStyle}
                                    prefix="$"
                                    value={summary?.budget_forecast}
                                    displayType="text"
                                    fixedDecimalScale
                                    thousandSeparator
                                    decimalScale={2}
                                />
                            </Container>
                            <Container>
                                <span style={labelStyle}>Invoiced</span>
                                <NumberFormat
                                    style={valueStyle}
                                    prefix="$"
                                    value={summary?.budget_invoiced}
                                    displayType="text"
                                    fixedDecimalScale
                                    thousandSeparator
                                    decimalScale={2}
                                />
                            </Container>
                        </Container>
                    </Container>

                    {/* Progress Bars */}
                    <Container
                        style={{
                            flex: 1,
                            flexDirection: 'column',
                            padding: theme.spacing(1),
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                                color: theme.palette.grey[700],
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Progress
                        </span>
                        <Container>
                            <span style={{ width: 100 }}>Complete</span>
                            <StyledProgressBar
                                customStyle={barStyle}
                                value={completePercent}
                                variant="determinate"
                                barColor={theme.palette.info.main}
                            />
                        </Container>

                        <Container>
                            <span style={{ width: 100 }}>Approved</span>

                            <StyledProgressBar
                                customStyle={barStyle}
                                value={approvedPercent}
                                variant="determinate"
                                barColor={theme.palette.secondary.main}
                            />
                        </Container>

                        <Container>
                            <span style={{ width: 100 }}>Invoiced</span>

                            <StyledProgressBar
                                customStyle={barStyle}
                                value={invoicedPercent}
                                variant="determinate"
                                barColor={theme.palette.primary.main}
                            />
                        </Container>
                    </Container>
                </Container>
            )}

            <Popover
                anchorReference="anchorPosition"
                open={editFormOpen}
                onClose={() => setEditFormOpen(false)}
                anchorPosition={{
                    top: formState.meta.yPos + HEADER_H,
                    left: formState.meta.xPos + DETAIL_W,
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                <Container
                    style={{
                        minHeight: POPOVER_H - HEADER_H,
                        maxHeight: POPOVER_H - HEADER_H,
                        minWidth: FORM_W,
                        maxWidth: FORM_W,
                    }}
                >
                    <ScheduleForm
                        {...props}
                        initialStartDate={formState.meta.date}
                        color={color}
                        setColor={setColor}
                    />
                </Container>
            </Popover>
        </Container>
    )
}

const POPOVER_H = 750

const DETAIL_W = 600
const FORM_W = 400

const HEADER_H = 75

enum DisplayMode {
    DETAIL,
    CREATE_EDIT,
}

type ServiceSummary = {
    id: number
    name: string
    count: number
    color: string
}

type VendorSummary = {
    id: number
    name: string
    count: number
}

type ScheduleSummary = {
    total_workorder_count: number
    behind: number
    not_started: number
    in_progress: number
    complete: number
    approved: number
    invoiced: number
    budget_forecast: number
    budget_invoiced: number
    service_list: ServiceSummary[]
    vendor_list: VendorSummary[]
}
