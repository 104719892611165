import {
    Button,
    IconButton,
    Modal,
    Paper,
    Slide,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React from 'react'
import { Container } from '../../components'
import CloseIcon from '@material-ui/icons/Close'
import { ReactComponent as LockIcon } from '../../assets/Lock.svg'

interface Props {
    open: boolean
    handleClose: () => void
    createStripeInteraction: (yearly?: boolean) => void
}

export const EZVendorModal = (props: Props) => {
    const { open, handleClose, createStripeInteraction } = props

    const theme = useTheme()

    return (
        <Modal
            open={open}
            onClose={handleClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'auto',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: '600px',
                        height: '550px',
                        maxHeight: 'calc(100vh - 100px)',
                        maxWidth: 'calc(100vh - 100px)',
                        padding: theme.spacing(1, 4, 1, 4),
                    }}
                >
                    {/* Header */}
                    <Container
                        style={{
                            alignItems: 'center',
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        <div style={{ flex: 1 }} />
                        <Tooltip title="Cancel">
                            <IconButton
                                onClick={() => {
                                    handleClose()
                                }}
                            >
                                <CloseIcon
                                    fontSize="inherit"
                                    style={{
                                        marginRight: theme.spacing(1),
                                        color: theme.palette.darkGreen.main,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Container>
                    {/* Body */}
                    <Container direction="column" flex={1} alignItems="center">
                        <Container
                            style={{
                                fontSize: '20px',
                                fontWeight: 700,
                            }}
                        >
                            EZVendor
                        </Container>
                        <Container
                            style={{
                                fontSize: '18px',
                                fontWeight: 500,
                                margin: theme.spacing(3, 0, 3, 0),
                            }}
                        >
                            Upgrade now to unlock
                        </Container>
                        <Container>
                            <LockIcon />
                        </Container>
                        <Container
                            style={{
                                margin: theme.spacing(3, 0, 3, 0),
                                fontSize: '18px',
                                fontWeight: 400,
                                flexWrap: 'wrap',
                                display: 'table-cell',
                                verticalAlign: 'middle',
                                textAlign: 'center',
                            }}
                        >
                            {`Get access to EZTurn's National Vendor List and a range of features,
                            including the ability to create RFPs and request bids from vendors.`}
                        </Container>
                    </Container>
                    {/* Footer */}
                    <Container justifyContent="center">
                        <Button
                            variant="contained"
                            style={{
                                margin: theme.spacing(2),
                                backgroundColor: '#008C85',
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                                fontSize: '20px',
                            }}
                            onClick={() => createStripeInteraction()}
                        >
                            Purchase Monthly
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                margin: theme.spacing(2),
                                backgroundColor: '#008C85',
                                color: 'white',
                                textTransform: 'none',
                                cursor: 'pointer',
                                fontSize: '20px',
                            }}
                            onClick={() => createStripeInteraction(true)}
                        >
                            Purchase Yearly
                        </Button>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
