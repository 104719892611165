import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch, useAreaStatusTag } from '.'
import { timelinesOverlap, enumKeys } from '../helpers'
import {
    Area,
    CustomStatus,
    FilterableUnit,
    Folder,
    IdBoolMap,
    Inventory,
    InventoryInspection,
    _Workorder,
    Unit,
    ModelListMap,
    Lease,
    serviceAreasMatchUnit,
    ModelMap,
    AreaStatusTag,
    BaseWorkorder,
    isWorkorderSafe,
    WorkorderStatus,
    ChangeOrderStatus,
    getStatusComparisonValue,
    getLeaseTimeline,
    AreaConfig,
    getUnitOccupancyCountMap,
    getUnitOccupancyType,
    UnitOccupanyType,
    Suggestion,
    UnitConfig,
    getHasGoBack,
} from '../models'
import {
    defaultFilterState,
    InfrastructureFilterOptions,
    InspectionCompletionFilter,
    InspectionHandledFilter,
    RootState,
    setInfrastructureFilter,
    AssignmentFilter,
    CalendarFilterMode,
    ChangeOrderFilterMode,
    PartialFilterMode,
    KeysFilterMode,
} from '../store'

interface Options {
    cleanUp?: boolean
}

export const useFilter = (options?: Options) => {
    const dispatch = useAppDispatch()
    const filter = useSelector(
        (state: RootState) => state.infrastructureFilter.filter,
    )

    const resetFilters = () => {
        dispatch(setInfrastructureFilter())
    }

    const setCustomStatusFilter = (customStatus: CustomStatus) => {
        const newCsFilter = {
            ...filter.customStatusFilter,
            [customStatus.id]: true,
        }

        if (filter.customStatusFilter[customStatus.id]) {
            delete newCsFilter[customStatus.id]
        }

        dispatch(
            setInfrastructureFilter({
                ...filter,
                customStatusFilter: newCsFilter,
            }),
        )
    }

    const toggleUnreadFilter = () => {
        dispatch(
            setInfrastructureFilter({
                ...filter,
                unreadFilter: !filter.unreadFilter,
            }),
        )
    }

    const toggleDamageFilter = () => {
        dispatch(
            setInfrastructureFilter({
                ...filter,
                hasDamages: !filter.hasDamages,
            }),
        )
    }

    const toggleHasMessages = () => {
        dispatch(
            setInfrastructureFilter({
                ...filter,
                hasMessages: !filter.hasMessages,
            }),
        )
    }

    const toggleHasFlagged = () => {
        dispatch(
            setInfrastructureFilter({
                ...filter,
                hasFlaggedItem: !filter.hasFlaggedItem,
            }),
        )
    }

    const toggleHasCost = () => {
        dispatch(
            setInfrastructureFilter({
                ...filter,
                hasCostDriver: !filter.hasCostDriver,
            }),
        )
    }

    const handleChangeMultiSelect = (
        newOptions: number[],
        key: keyof InfrastructureFilterOptions,
    ) => {
        const newFilterVal = newOptions.reduce<IdBoolMap>((prev, ucId) => {
            return { ...prev, [ucId]: true }
        }, {})

        dispatch(
            setInfrastructureFilter({
                ...filter,
                [key]: newFilterVal,
            }),
        )
    }

    const setInspectionStatusFilter = (
        newStatus: InspectionCompletionFilter,
    ) => {
        if (newStatus === null) {
            // This occurs when the user clicks the same value twice.
            // Returning here prevents the completion filter from becoming un-selected.
            return
        }

        let newFilter = {
            ...filter,
        }

        if (newStatus !== InspectionCompletionFilter.Complete) {
            // We need to reset all of the filters that are going to be disabled
            // Leave all filters that are still applicable alone.
            newFilter = {
                ...defaultFilterState,
                unitConfigFilter: filter.unitConfigFilter,
                unitSearch: filter.unitSearch,
            }
        }

        dispatch(
            setInfrastructureFilter({
                ...newFilter,
                inspectionCompletionFilter: newStatus,
            }),
        )
    }

    const setUnitSearch = (newVal: string) => {
        dispatch(
            setInfrastructureFilter({
                ...filter,
                unitSearch: newVal,
            }),
        )
    }

    const setHandled = (handledFilter: InspectionHandledFilter) => {
        if (handledFilter === null) {
            // This occurs when the user clicks the same value twice.
            // Returning here prevents the completion filter from becoming un-selected.
            return
        }

        dispatch(
            setInfrastructureFilter({
                ...filter,
                handled: handledFilter,
            }),
        )
    }

    const _setInfrastructureFilter = (
        key: keyof InfrastructureFilterOptions,
        value: unknown,
    ) => {
        dispatch(
            setInfrastructureFilter({
                ...filter,
                [key]: value,
            }),
        )
    }

    useEffect(() => {
        return () => {
            if (options?.cleanUp) {
                resetFilters()
            }
        }
    }, [])

    return {
        filter: filter,
        resetFilters: resetFilters,
        setCustomStatusFilter: setCustomStatusFilter,
        toggleUnreadFilter: toggleUnreadFilter,
        toggleHasMessages: toggleHasMessages,
        toggleHasCost: toggleHasCost,
        toggleHasFlagged: toggleHasFlagged,
        handleChangeMultiSelect: handleChangeMultiSelect,
        setInspectionStatusFilter: setInspectionStatusFilter,
        setUnitSearch: setUnitSearch,
        setHandled: setHandled,
        setInfrastructureFilter: _setInfrastructureFilter,
        toggleDamageFilter,
    }
}

export const useInfrastructureFilter = (options?: Options) => {
    const rootFilter = useFilter(options)

    const filters = rootFilter.filter

    const isUnitInFilter = (unit?: FilterableUnit) => {
        if (unit === undefined) return true
        // Unit type filter

        let nameFilterValid = true
        if (filters.unitSearch.length !== 0) {
            if (
                !unit.name
                    .toLocaleLowerCase()
                    .startsWith(filters.unitSearch.toLocaleLowerCase())
            ) {
                nameFilterValid = false
            }
        }

        let unitConfigValid = Object.keys(filters.unitConfigFilter).length === 0

        if (filters.unitConfigFilter[unit.unit_config]) {
            unitConfigValid = true
        }

        return unitConfigValid && nameFilterValid
    }

    const isAreaInFilter = (area: Area) => {
        return true
    }

    const isInventoryInFilter = (inventory: Inventory) => {
        return (
            Object.keys(filters.invConfigFilter).length === 0 ||
            filters.invConfigFilter[inventory.inventory_config]
        )
    }

    const isFolderVisible = (folder: Folder, renderedUnitCount: number) => {
        return renderedUnitCount > 0
    }

    return {
        ...rootFilter,
        isUnitInFilter: isUnitInFilter,
        isAreaInFilter: isAreaInFilter,
        isInventoryInFilter: isInventoryInFilter,
        isFolderVisible: isFolderVisible,
        unitSearch: filters.unitSearch,
        setUnitSearch: rootFilter.setUnitSearch,
    }
}

export const useInspectionFilter = (options?: Options) => {
    /**
     * If the inspection detail page begins having performance issues
     * there are some optimizations available in this hook
     *
     * Convert the forEach's found in the filters into traditional for loops and break
     * the loop early when a true condition is found.
     */

    const rootFilter = useFilter(options)

    const isInventoryInspectionInFilter = (
        inventoryInspection: InventoryInspection,
    ) => {
        const filter = rootFilter.filter
        const configValid =
            Object.keys(filter.invConfigFilter).length === 0 ||
            filter.invConfigFilter[inventoryInspection.inventory_config]

        const status = inventoryInspection.status
        let statusValid = Object.keys(filter.customStatusFilter).length === 0
        if (!statusValid) {
            // The user has a custom status filter aplied
            if (status !== null) {
                // The status filter is valid if this inventory inspection status is set to true in the status filter
                statusValid = filter.customStatusFilter[status.id]
            }
        }

        // TODO: Damage count?

        const hasUnreadValid =
            !filter.unreadFilter || (inventoryInspection.unread_count ?? 0) > 0

        const hasMessageValid =
            !filter.hasMessages || (inventoryInspection.message_count ?? 0) > 0

        const hasFlaggedValid =
            !filter.hasFlaggedItem ||
            (inventoryInspection.status?.causes_flag as any) === 1

        const hasCostDriverValid =
            !filter.hasCostDriver || (inventoryInspection.status?.cost ?? 0) > 0

        let handledFilterValid = true
        switch (filter.handled) {
            case InspectionHandledFilter.All:
                handledFilterValid = true
                break
            case InspectionHandledFilter.Handled:
                handledFilterValid = inventoryInspection.handled
                break
            case InspectionHandledFilter.NotHandled:
                handledFilterValid = !inventoryInspection.handled
                break
        }

        return (
            configValid &&
            statusValid &&
            hasUnreadValid &&
            hasMessageValid &&
            hasFlaggedValid &&
            hasCostDriverValid &&
            handledFilterValid
        )
    }

    return {
        ...rootFilter,
        isInventoryInspectionInFilter,
    }
}

export const useScheduleFilter = (
    unitWorkorderMap: ModelListMap<BaseWorkorder>,
    areaLeaseMap: ModelListMap<Lease>,
    areaStatusTagMap: ModelMap<AreaStatusTag>,
    serviceAreaMap: IdBoolMap,
    areaConfigMap: ModelMap<AreaConfig>,
    unitConfigMap: ModelMap<UnitConfig>,
    vacantAreas?: Area[],
    options?: Options,
) => {
    const rootFilter = useInfrastructureFilter(options)

    const filters = rootFilter.filter

    const isWorkorderInFilter = (workorder: BaseWorkorder, unit: Unit) => {
        const isWorkorder = isWorkorderSafe(workorder)

        if (filters.isGhost && isWorkorder) {
            return false
        }

        if (filters.hasGoBack) {
            if (!getHasGoBack(workorder)) {
                return false
            }
        }

        if (filters.priorityFilter) {
            if (!isWorkorder || !workorder.priority) {
                return false
            }
        }

        const hasMessageValid =
            !filters.hasMessages || workorder.message_count > 0
        if (!hasMessageValid) {
            return false
        }

        const hasUnreadMessageValid =
            !filters.unreadFilter || (workorder.unread_count ?? 0) > 0
        if (!hasUnreadMessageValid) {
            return false
        }

        const serviceValid =
            Object.keys(filters.serviceFilter).length === 0 ||
            filters.serviceFilter[workorder.service_id ?? -1]

        if (!serviceValid) {
            return false
        }

        if (Object.keys(filters.vendorFilter).length !== 0) {
            // User is filtering by vendor
            if (
                !isWorkorder ||
                filters.vendorFilter[workorder.vendor_id] !== true
            ) {
                return false
            }
        }

        let hasEditedServiceAreaValid = !filters.manualServiceAreas
        if (filters.manualServiceAreas) {
            hasEditedServiceAreaValid = !serviceAreasMatchUnit(
                workorder,
                unit,
                serviceAreaMap,
                areaStatusTagMap,
            )
        }

        // Begin Change order filters
        if (filters.changeOrder !== ChangeOrderFilterMode.All) {
            if (filters.changeOrder === ChangeOrderFilterMode.HasAny) {
                // User only wants to see workorders with at least 1 change order, in any status
                if (workorder.changeorder_set?.length === 0) {
                    return false
                }
            } else {
                // User is filtering for a specific change order status
                let pendingCount = 0
                let approvedCount = 0
                let deniedCount = 0
                workorder.changeorder_set?.forEach((co) => {
                    switch (co.status) {
                        case ChangeOrderStatus.APPROVED:
                            approvedCount += 1
                            return
                        case ChangeOrderStatus.PENDING:
                            pendingCount += 1
                            return
                        case ChangeOrderStatus.DENIED:
                            deniedCount += 1
                            return
                    }
                })

                if (
                    filters.changeOrder === ChangeOrderFilterMode.HasApproved &&
                    approvedCount === 0
                ) {
                    return false
                }

                if (
                    filters.changeOrder === ChangeOrderFilterMode.HasPending &&
                    pendingCount === 0
                ) {
                    return false
                }

                if (
                    filters.changeOrder === ChangeOrderFilterMode.HasDenied &&
                    deniedCount === 0
                ) {
                    return false
                }
            }
        }
        // End Change Order filters

        if (!hasEditedServiceAreaValid) {
            return false
        }

        let allStatusFilterFalse = true
        enumKeys(filters.statusFilter).forEach((key) => {
            if (filters.statusFilter[key]) allStatusFilterFalse = false
        })
        if (!allStatusFilterFalse && !filters.statusFilter[workorder.status]) {
            return false
        }

        let dateValid = true
        if (filters.dateFilter.enable && filters.dateFilter.filterServices) {
            // Date filter is enabled and filtering workorders

            if (!isWorkorder) {
                return false
            }

            // set date valid to false by default, the workorder must prove its in the filter
            dateValid = false

            if (filters.dateFilter.filterMode === CalendarFilterMode.Overlap) {
                // the date becomes valid if the workorder timeline overlaps with the users requested timeline
                dateValid = timelinesOverlap(
                    workorder,
                    filters.dateFilter.range,
                )
            } else {
                // Gap mode should be handled at the unit level.
                // When the user is looking for a gap in services they need to check all of the units workorders
                // A single workorder during the timeline will invalidate the unit
                dateValid = true
            }
        }

        if (!dateValid) {
            return false
        }

        if (filters.behindWorkorders) {
            if (
                workorder.status === WorkorderStatus.APPROVED ||
                workorder.status === WorkorderStatus.PRE_APPROVED ||
                workorder.status === WorkorderStatus.INVOICED ||
                workorder.status === WorkorderStatus.COMPLETE
            ) {
                return false
            } else {
                if (workorder.end_date) {
                    const endDate = new Date(workorder.end_date)
                    endDate.setHours(23, 59, 59, 999)
                    if (endDate > new Date()) {
                        return false
                    }
                } else {
                    return false
                }
            }
        }

        return true
    }

    const isSuggestionInFilter = (suggestion: Suggestion) => {
        const serviceValid =
            Object.keys(filters.serviceFilter).length === 0 ||
            filters.serviceFilter[suggestion.service.id]

        const vendorValid =
            Object.keys(filters.vendorFilter).length === 0 ||
            filters.vendorFilter[suggestion.vendor.id]

        return serviceValid && vendorValid
    }

    const isAreaInFilter = (area: Area) => {
        const areaLeases = areaLeaseMap[area.id]

        // When filtering for gaps, it should be assumed the filter is valid by default
        // When filtering by intersection the filter is invalid by default

        let areaLeaseDatesValid = true

        if (filters.dateFilter.enable) {
            areaLeaseDatesValid =
                filters.dateFilter.filterMode === CalendarFilterMode.Gaps

            areaLeases?.forEach((lease) => {
                const timeline = getLeaseTimeline(lease)
                const overlap = timelinesOverlap(
                    timeline,
                    filters.dateFilter.range,
                )

                if (filters.dateFilter.filterMode === CalendarFilterMode.Gaps) {
                    // Area is only valid if every lease does not intersect the time range
                    areaLeaseDatesValid = areaLeaseDatesValid && !overlap
                } else {
                    // Area is valid if any lease intersects
                    areaLeaseDatesValid = areaLeaseDatesValid || overlap
                }
            })
        }

        return areaLeaseDatesValid
    }

    const isUnitInFilter = (unit: Unit) => {
        // if unit config for this unit does not show on schedule it is not in filter
        if (!unitConfigMap[unit.unit_config]?.shows_on_schedule) {
            return false
        }
        const workorders = unitWorkorderMap[unit.id]

        let assignmentFilterValid = true
        let visibleWorkorderCount = 0

        // If there are no workorders the unit will not show as ready,
        // otherwise default the unit readiness to true
        let unitReadyValid = workorders && workorders.length > 0

        // The minimum accepted value is an integer where complete, pre-approved, approved, and invoiced are
        // ranked numerically.  The user specifies the status they care about which is the minimum acceptance value
        const minAcceptedValue = getStatusComparisonValue(
            filters.readyUnitFilter.value,
        )

        workorders?.forEach((workorder) => {
            if (isWorkorderInFilter(workorder, unit)) {
                visibleWorkorderCount += 1
            }

            // The unit is ready if every workorder status is >= minAcceptedValue
            unitReadyValid =
                unitReadyValid &&
                getStatusComparisonValue(workorder.status) >= minAcceptedValue
        })

        if (filters.readyUnitFilter.enabled && !unitReadyValid) {
            return false
        }

        if (filters.assignmentFilter === AssignmentFilter.All) {
            assignmentFilterValid = true
        } else if (filters.assignmentFilter === AssignmentFilter.Assigned) {
            assignmentFilterValid = visibleWorkorderCount > 0
        } else if (filters.assignmentFilter === AssignmentFilter.NotAssigned) {
            assignmentFilterValid = visibleWorkorderCount === 0
        }

        if (!assignmentFilterValid) {
            return false
        }

        // If the partial filter mode is "All" do not exclude any units
        let partialFilterValid =
            filters.partialUnitFilter === PartialFilterMode.All

        if (!partialFilterValid) {
            // We need to count the number of "Vacants" and "Renewals"

            // Vacants are defined as any area where the tag dictates the area should be serviced
            // Renewals are defined as any area where the tag dictates the area should not be serviced
            // The absense of an area status tag is assumed "Vacant"
            const unitOccupancyMap = getUnitOccupancyCountMap(
                unit,
                areaConfigMap,
                areaStatusTagMap,
            )

            const unitOccType = getUnitOccupancyType(unitOccupancyMap)

            switch (filters.partialUnitFilter) {
                case PartialFilterMode.Partial:
                    partialFilterValid =
                        unitOccType === UnitOccupanyType.PARTIAL
                    break
                case PartialFilterMode.Renewed:
                    partialFilterValid =
                        unitOccType === UnitOccupanyType.FULL_RENEW
                    break
                default:
                    partialFilterValid =
                        unitOccType === UnitOccupanyType.FULL_VACANT
            }
        }

        if (!partialFilterValid) {
            return false
        }

        const dateFilter = filters.dateFilter
        let leaseDateValid = true
        let serviceDateValid = true

        if (filters.areaStatusFilter.filterLength !== 0) {
            let areaStatusValid = false
            unit.areas.forEach((area) => {
                const areaStatusTag: AreaStatusTag | undefined =
                    areaStatusTagMap[area.id]

                if (
                    areaStatusTag !== undefined &&
                    filters.areaStatusFilter[
                        areaStatusTag.area_status_config.id
                    ]?.inFilter === true
                ) {
                    areaStatusValid = true
                }
            })

            if (!areaStatusValid) {
                return false
            }
        }

        // Filter by which units have an area that is vacant
        if (filters.displayVacantAreas && vacantAreas) {
            let unitValid = false
            unit.areas.map((area) => {
                vacantAreas?.map((va) => {
                    if (va.id === area.id) {
                        unitValid = true
                    }
                })
            })
            if (unitValid === false) {
                return false
            }
        }

        // if (filters.keysFilter !== KeysFilterMode.All) {
        //     let keysIn = 0
        //     let keysOut = 0
        //     unit.areas.map((area) => {
        //         if (
        //             areaConfigMap[area.area_config]?.occupiable &&
        //             areaStatusTagMap[area.id]?.key
        //         ) {
        //             keysIn = keysIn + 1
        //         } else if (
        //             areaConfigMap[area.area_config]?.occupiable &&
        //             !areaStatusTagMap[area.id]?.key
        //         ) {
        //             keysOut = keysOut + 1
        //         }
        //     })

        //     switch (filters.keysFilter) {
        //         case KeysFilterMode.AllKeysIn: {
        //             return keysIn !== 0 && keysOut === 0
        //         }
        //         case KeysFilterMode.AllKeysOut: {
        //             return keysIn === 0 && keysOut !== 0
        //         }
        //         case KeysFilterMode.PartialKeysIn: {
        //             return keysIn !== 0 && keysOut !== 0
        //         }
        //     }
        // }

        if (dateFilter.enable) {
            if (dateFilter.filterLeases) {
                // Date filter is enabled and the user wants to only include units where the area leases
                // match the specified mode

                // When filtering for gaps, it should be assumed the filter is valid by default
                // When filtering by intersection the filter is invalid by default
                leaseDateValid =
                    dateFilter.filterMode === CalendarFilterMode.Gaps

                unit.areas.forEach((area) => {
                    const areaValid = isAreaInFilter(area)
                    if (
                        filters.dateFilter.filterMode ===
                        CalendarFilterMode.Gaps
                    ) {
                        // Unit is valid only if none of the areas intersect
                        leaseDateValid = leaseDateValid && areaValid
                    } else {
                        leaseDateValid = leaseDateValid || areaValid
                    }
                })
            }
            if (dateFilter.filterServices) {
                // The user is looking for units where there is no work in a time span
                const unitWorkorders = unitWorkorderMap[unit.id]
                unitWorkorders?.forEach((baseWorkorder) => {
                    if (!isWorkorderSafe(baseWorkorder)) {
                        return
                    }

                    const workorder = baseWorkorder as _Workorder

                    if (dateFilter.filterMode === CalendarFilterMode.Gaps) {
                        serviceDateValid =
                            serviceDateValid &&
                            !timelinesOverlap(
                                workorder,
                                filters.dateFilter.range,
                            )
                    } else {
                        serviceDateValid =
                            serviceDateValid ||
                            timelinesOverlap(
                                workorder,
                                filters.dateFilter.range,
                            )
                    }
                })
            }
        }

        if (!leaseDateValid || !serviceDateValid) {
            return false
        }

        return rootFilter.isUnitInFilter(unit)
    }

    return {
        ...rootFilter,
        isWorkorderInFilter,
        isUnitInFilter,
        isAreaInFilter,
        isSuggestionInFilter,
    }
}

export type ScheduleFilterController = ReturnType<typeof useScheduleFilter>
