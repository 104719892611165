import React from 'react'

import { Theme } from '@material-ui/core'
import { StatusBadge } from '../../components'
import { ActionItemStatus, getUiString, WorkorderStatus } from '../../models'

interface WoStatusBadgeProps {
    theme: Theme
    status: WorkorderStatus | ActionItemStatus
    style?: { container?: React.CSSProperties; text?: React.CSSProperties }
}

export const WoStatusBadge = (props: WoStatusBadgeProps) => {
    const { status, theme, style } = props
    const text = getUiString(status)

    return (
        <StatusBadge
            text={text}
            customStyle={{
                container: {
                    backgroundColor: theme.palette[status].main,
                    // max height so they do not try to strech
                    maxHeight: '30px',
                    //min width or else in progress gets put on two lines
                    minWidth: 80,
                    ...style?.container,
                },
                text: {
                    ...theme.typography.caption,
                    ...style?.text,
                },
            }}
        />
    )
}
