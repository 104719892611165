import { createMuiTheme } from '@material-ui/core/styles'
import {
    ActionItemStatus,
    BidStatus,
    RFPStatus,
    WorkorderStatus,
} from '../models'

declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        [WorkorderStatus.ASSIGNED]: Palette['primary']
        [WorkorderStatus.IN_PROGRESS]: Palette['primary']
        [WorkorderStatus.PAUSE]: Palette['primary']
        [WorkorderStatus.COMPLETE]: Palette['primary']
        [WorkorderStatus.APPROVED]: Palette['primary']
        [WorkorderStatus.PRE_APPROVED]: Palette['primary']
        [WorkorderStatus.GO_BACK]: Palette['primary']
        [WorkorderStatus.INVOICED]: Palette['primary']

        [ActionItemStatus.ASSIGNED]: Palette['primary']
        [ActionItemStatus.IN_PROGRESS]: Palette['primary']
        [ActionItemStatus.PAUSE]: Palette['primary']
        [ActionItemStatus.COMPLETE]: Palette['primary']
        [ActionItemStatus.REJECTED]: Palette['primary']
        [ActionItemStatus.STAGED]: Palette['primary']

        [RFPStatus.DRAFT]: Palette['primary']
        [RFPStatus.PUBLISHED]: Palette['primary']
        [RFPStatus.CLOSED]: Palette['primary']
        [RFPStatus.ARCHIVED]: Palette['primary']
        [RFPStatus.FAILED]: Palette['primary']
        [RFPStatus.SELECT_VENDOR]: Palette['primary']

        [BidStatus.INVITATION]: Palette['primary']
        [BidStatus.ACCEPTED]: Palette['primary']
        [BidStatus.DECLINED]: Palette['primary']
        [BidStatus.SUBMITTED]: Palette['primary']
        [BidStatus.REVIEWED]: Palette['primary']

        brand1: Palette['primary']
        veryLightSecondary: Palette['primary']
        darkGreen: Palette['primary']
        darkBlue: Palette['primary']
        lightGrey: Palette['primary']
        silver: Palette['primary']
        blue: Palette['primary']
        gold: Palette['primary']
        purple: Palette['primary']
        noService: Palette['primary']
        white: Palette['primary']
    }
    interface PaletteOptions {
        [WorkorderStatus.ASSIGNED]: PaletteOptions['primary']
        [WorkorderStatus.IN_PROGRESS]: PaletteOptions['primary']
        [WorkorderStatus.PAUSE]: PaletteOptions['primary']
        [WorkorderStatus.COMPLETE]: PaletteOptions['primary']
        [WorkorderStatus.APPROVED]: PaletteOptions['primary']
        [WorkorderStatus.PRE_APPROVED]: PaletteOptions['primary']
        [WorkorderStatus.GO_BACK]: PaletteOptions['primary']
        [WorkorderStatus.INVOICED]: PaletteOptions['primary']

        [ActionItemStatus.ASSIGNED]: PaletteOptions['primary']
        [ActionItemStatus.IN_PROGRESS]: PaletteOptions['primary']
        [ActionItemStatus.COMPLETE]: PaletteOptions['primary']
        [ActionItemStatus.PAUSE]: PaletteOptions['primary']
        [ActionItemStatus.REJECTED]: PaletteOptions['primary']
        [ActionItemStatus.STAGED]: PaletteOptions['primary']

        [RFPStatus.DRAFT]: PaletteOptions['primary']
        [RFPStatus.PUBLISHED]: PaletteOptions['primary']
        [RFPStatus.CLOSED]: PaletteOptions['primary']
        [RFPStatus.ARCHIVED]: PaletteOptions['primary']
        [RFPStatus.FAILED]: PaletteOptions['primary']
        [RFPStatus.SELECT_VENDOR]: PaletteOptions['primary']

        [BidStatus.INVITATION]: PaletteOptions['primary']
        [BidStatus.ACCEPTED]: PaletteOptions['primary']
        [BidStatus.DECLINED]: PaletteOptions['primary']
        [BidStatus.SUBMITTED]: PaletteOptions['primary']
        [BidStatus.REVIEWED]: PaletteOptions['primary']

        brand1: PaletteOptions['primary']
        veryLightSecondary: PaletteOptions['primary']
        darkGreen: PaletteOptions['primary']
        darkBlue: PaletteOptions['primary']
        lightGrey: PaletteOptions['primary']
        silver: PaletteOptions['primary']
        blue: PaletteOptions['primary']
        gold: PaletteOptions['primary']
        purple: PaletteOptions['primary']
        noService: PaletteOptions['primary']
        white: PaletteOptions['primary']
    }
}
export const defaultTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#46b083',
            contrastText: '#f4f7f6',
            light: '#CDE9DD',
            dark: '#008C85',
        },
        secondary: { main: '#ff7459', contrastText: '#f4f7f6' },
        error: { main: '#e73535', contrastText: '#f4f7f6' },
        warning: { main: '#e0c23a', contrastText: '#f4f7f6' },
        [WorkorderStatus.ASSIGNED]: {
            main: '#9e9e9e',
            contrastText: '#f4f7f6',
        },
        [WorkorderStatus.IN_PROGRESS]: {
            main: '#ffbf00',
            contrastText: '#f4f7f6',
        },
        [WorkorderStatus.PAUSE]: { main: '#9e9e9e' },
        [WorkorderStatus.COMPLETE]: {
            main: '#46b083',
            contrastText: '#f4f7f6',
        },
        [WorkorderStatus.PRE_APPROVED]: { main: '#016fb9' },
        [WorkorderStatus.APPROVED]: { main: '#016fb9' },
        [WorkorderStatus.GO_BACK]: { main: '#ff7459', contrastText: '#f4f7f6' },
        [WorkorderStatus.INVOICED]: { main: '#38f5ff' },
        [ActionItemStatus.REJECTED]: { main: '#e73535' },
        [ActionItemStatus.STAGED]: { main: '#9e9e9e' },
        [RFPStatus.DRAFT]: { main: '#ff7459' },
        [RFPStatus.PUBLISHED]: {
            main: '#ffbf00',
            contrastText: '#f4f7f6',
        },
        [RFPStatus.CLOSED]: { main: '#46b083' },
        [RFPStatus.ARCHIVED]: { main: '#46b083' },
        [RFPStatus.FAILED]: { main: '#e73535' },
        [RFPStatus.SELECT_VENDOR]: {
            main: '#ffbf00',
            contrastText: '#f4f7f6',
        },
        [BidStatus.INVITATION]: { main: '#9e9e9e' },
        [BidStatus.SUBMITTED]: {
            main: '#ffbf00',
            contrastText: '#f4f7f6',
        },
        [BidStatus.REVIEWED]: {
            main: '#ffbf00',
            contrastText: '#f4f7f6',
        },
        [BidStatus.ACCEPTED]: { main: '#46b083' },
        [BidStatus.DECLINED]: { main: '#e73535' },

        brand1: { main: '#275e60' },
        veryLightSecondary: { main: '#FFE8E3' },
        darkGreen: { main: '#8A8A8A', light: '#46af84' },
        darkBlue: { main: '#2F2E41', dark: '#1b2e39' },
        lightGrey: { main: '#C4C4C4', dark: '#C2C2C2', light: '#D3D3D3' },
        silver: { main: '#E5E4E2' },
        blue: { main: '#0182F9' },
        gold: { main: '#FFC107' },
        purple: { main: '#6772ef' },
        noService: { main: '#46b083' },
        white: { main: '#F4F4F4' },
    },
})
