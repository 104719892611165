import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { useAppDispatch } from '../hooks'
import { Schedule } from '../models'

import {
    createSchedule,
    CreateScheduleRequest,
    getScheduleList,
    GetScheduleListActionThunk,
    ScheduleDetailActionThunk,
    GetScheduleListRequest,
    RootState,
    updateSchedule,
    UpdateScheduleRequest,
    GetScheduleDetailRequest,
    getScheduleDetail,
    GET_SCHEDULE_DETAIL_REQUEST,
    setScheduleDetail,
    CREATE_SCHEDULE_REQUEST,
    UPDATE_SCHEDULE_REQUEST,
} from '../store'

type UseSchedule = {
    scheduleList: Schedule[]
    scheduleDetail?: Schedule
    loadingDetail: boolean
    loadingCreate: boolean
    loadingUpdate: boolean
    getScheduleList: (req: GetScheduleListRequest) => GetScheduleListActionThunk
    getScheduleDetail: (
        req: GetScheduleDetailRequest,
    ) => ScheduleDetailActionThunk
    createSchedule: (req: CreateScheduleRequest) => ScheduleDetailActionThunk
    updateSchedule: (
        req: UpdateScheduleRequest,
        params?: { portfolio?: boolean },
    ) => ScheduleDetailActionThunk
}

interface Options {
    getScheduleDetail?: number
    cleanUp?: boolean
}

export const useSchedule = (options?: Options): UseSchedule => {
    const dispatch = useAppDispatch()

    const scheduleList = useSelector(
        (state: RootState) => state.workorder.schedule.scheduleList,
        shallowEqual,
    )

    const scheduleDetail = useSelector(
        (state: RootState) => state.workorder.schedule.scheduleDetail,
    )

    const loadingState = useSelector(
        (state: RootState) => state.workorder.schedule.isLoading,
    )

    useEffect(() => {
        if (options?.getScheduleDetail !== undefined) {
            dispatch(
                getScheduleDetail({ scheduleId: options.getScheduleDetail }),
            )
        }

        return () => {
            if (options?.cleanUp) {
                dispatch(setScheduleDetail())
            }
        }
    }, [])

    return {
        scheduleList: scheduleList,
        scheduleDetail: scheduleDetail,
        loadingDetail: loadingState[GET_SCHEDULE_DETAIL_REQUEST],
        loadingCreate: loadingState[CREATE_SCHEDULE_REQUEST],
        loadingUpdate: loadingState[UPDATE_SCHEDULE_REQUEST],
        getScheduleList: (req) => dispatch(getScheduleList(req)),
        getScheduleDetail: (req) => dispatch(getScheduleDetail(req)),
        createSchedule: (req) => dispatch(createSchedule(req)),
        updateSchedule: (req, params?) => dispatch(updateSchedule(req, params)),
    }
}
