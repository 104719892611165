import React from 'react'
import { Container, Finder } from '../'
import { Folder, Unit } from '../../models'
import { Checkbox, useTheme } from '@material-ui/core'
import { LocationSelection, Location } from '../../store'

interface Props {
    root: Folder
    locationSelection: LocationSelection
    selectedIcon?: JSX.Element
    setLocationSelection: (location: Location) => void
    getUnitAdornment?: (unit: Unit) => JSX.Element | null
    getFolderAdornment?: (folder: Folder) => JSX.Element | null
    style?: React.CSSProperties
    defaultOpen?: boolean
    useCheckbox?: boolean
}

export const LocationForm = (props: Props) => {
    const theme = useTheme()
    const { setLocationSelection, locationSelection } = props

    let { selectedIcon, getUnitAdornment, getFolderAdornment } = props
    if (props.useCheckbox) {
        selectedIcon = <></>
        getUnitAdornment = (unit) => {
            return (
                <Container style={{ flex: 1 }}>
                    <div style={{ flex: 1 }} />
                    <Checkbox
                        checked={locationSelection.unit[unit.id] !== undefined}
                        onClick={() => {
                            setLocationSelection({
                                type: 'unit',
                                location: unit,
                            })
                        }}
                    />
                </Container>
            )
        }

        getFolderAdornment = (folder) => {
            return (
                <Container style={{ flex: 1 }}>
                    <div style={{ flex: 1 }} />
                    <Checkbox
                        color={'primary'}
                        checked={
                            locationSelection.folder[folder.id] !== undefined
                        }
                        onClick={(e) => {
                            e.stopPropagation()
                            setLocationSelection({
                                type: 'folder',
                                location: folder,
                            })
                        }}
                    />
                </Container>
            )
        }
    }

    return (
        <Container
            flex={1}
            direction="column"
            style={{
                padding: theme.spacing(2),
                overflow: 'hidden',
                ...props.style,
            }}
        >
            <Finder
                {...props}
                onClickUnit={(unit) => setLocationSelection(unit)}
                onClickFolder={(folder) => setLocationSelection(folder)}
                selectedIcon={selectedIcon}
                getUnitAdornment={getUnitAdornment}
                getFolderAdornment={getFolderAdornment}
                // selectedIcon={props.selectedIcon}
                // getUnitAdornment={props.getUnitAdornment}
                // getFolderAdornment={props.getFolderAdornment}
                // defaultOpen={props.defaultOpen}
                // selectedIcon={<></>}
                // getUnitAdornment={(unit) => {
                //     return (
                //         <Container style={{ flex: 1 }}>
                //             <div style={{ flex: 1 }} />
                //             <Checkbox
                //                 checked={
                //                     locationFinderSelection.selection
                //                         .unit[unit.id] !== undefined
                //                 }
                //                 onClick={() => {
                //                     locationFinderSelection.setFinderSelection(
                //                         {
                //                             type: 'unit',
                //                             location: unit,
                //                         },
                //                     )
                //                 }}
                //             />
                //         </Container>
                //     )
                // }}
                // getFolderAdornment={(folder) => {
                //     return (
                //         <Container style={{ flex: 1 }}>
                //             <div style={{ flex: 1 }} />
                //             <Checkbox
                //                 color={'primary'}
                //                 checked={
                //                     locationFinderSelection.selection
                //                         .folder[folder.id] !== undefined
                //                 }
                //                 onClick={(e) => {
                //                     e.stopPropagation()
                //                     locationFinderSelection.setFinderSelection(
                //                         {
                //                             type: 'folder',
                //                             location: folder,
                //                         },
                //                     )
                //                 }}
                //             />
                //         </Container>
                //     )
                // }}
            />
        </Container>
    )
}
