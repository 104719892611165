import React, { useMemo } from 'react'

import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'

import {
    Area,
    AreaConfig,
    AreaStatusConfig,
    AreaStatusTag,
    BaseWorkorder,
    Damage,
    Folder,
    getFlatInfrastructure,
    IdBoolMap,
    ModelListMap,
    ModelMap,
    Schedule,
    ServiceContractMap,
    Unit,
    UnitConfig,
    WorkSpaceUser,
} from '../../../models'

import { FolderRow } from './FolderRow'

import { Container } from '../../../components'
import { SchedulerUnit } from './'

import { ScheduleFilterController } from '../../../hooks/useFilter'
import { usePagination } from '../../../hooks'
import { Pagination } from '@material-ui/lab'
import { Theme } from '@material-ui/core'

interface Props {
    prunedTree: Folder
    unitConfigMap: ModelMap<UnitConfig>
    areaConfigMap: ModelMap<AreaConfig>
    unitWorkorderMap: ModelListMap<BaseWorkorder>
    workspaceUser: WorkSpaceUser | undefined
    schedule?: Schedule
    serviceAreaMap: IdBoolMap
    selectedWorkorder?: BaseWorkorder
    editMode: boolean
    editWorkorderSelection: ModelMap<BaseWorkorder>
    contentRef?: React.RefObject<HTMLDivElement>
    areaStatusTagMap: ModelMap<AreaStatusTag>
    areaStatusConfigList: AreaStatusConfig[]
    selectedUnit?: Unit
    unitDamageMap: ModelListMap<Damage>
    upstreamContractMap: ServiceContractMap
    vacantAreas: Area[]
    filterController: ScheduleFilterController
    theme: Theme
    onSelectWorkorder: (workorder: BaseWorkorder, unit: Unit) => void
    clickAssign: (unit: Unit) => void
    clickDamage: (unit: Unit) => void
    onClickChangeOrder: (workorder: BaseWorkorder) => void
    clickUnitNotes: (unit: Unit) => void
}

export const SchedulerBody = (props: Props) => {
    const { prunedTree, contentRef } = props

    const flatInfrastructure = useMemo(() => {
        return getFlatInfrastructure(prunedTree)
    }, [prunedTree])

    const pagination = usePagination(flatInfrastructure, 50)

    return (
        <Container
            style={{
                flexDirection: 'column',
                flex: 1,
                overflow: 'hidden',
                overflowY: 'auto',
            }}
            scrollY
            ref={contentRef}
        >
            <Container
                style={{
                    overflowY: 'scroll',
                    flexDirection: 'column',
                    height: 'calc(100vh - 240px)',
                }}
            >
                {pagination.pageData.map((item) => {
                    return (
                        <Row
                            key={`SCHEDULE_ROW_${item.id}`}
                            item={item}
                            {...props}
                        />
                    )
                })}
            </Container>

            <Container
                style={{
                    height: 40,
                    borderTop: `1px solid ${props.theme.palette.divider}`,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Pagination
                    page={pagination.page}
                    onChange={(_, newPage) => {
                        pagination.setPage(newPage)
                    }}
                    count={pagination.numPages}
                />
                <span>
                    {pagination.start} - {pagination.end} of{' '}
                    {flatInfrastructure.length}
                </span>
            </Container>
        </Container>
    )
}

interface RowProps extends Props {
    item: Folder | Unit
}

const _Row = (props: RowProps) => {
    const { item } = props

    const isUnit = (item as any).parent === undefined
    if (isUnit) {
        const unit = item as Unit
        return (
            <SchedulerUnit
                key={`SCHEDULE_UNIT_COMPONENT_${unit.id}`}
                {...props}
                unit={unit}
                unitConfig={props.unitConfigMap[unit.unit_config]}
                style={{}}
                selected={props.selectedUnit?.id === unit.id}
                damageCount={props.unitDamageMap[unit.id]?.length ?? 0}
            />
        )
    } else {
        const folder = item as Folder
        return <FolderRow style={{}} name={folder.path + folder.name} />
    }
}

const Row = React.memo(_Row)
