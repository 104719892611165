import React from 'react'
import { Card, Divider, IconButton, Theme, Tooltip } from '@material-ui/core'
import { Container, StatusBadge } from '../../../components'
import {
    AreaStatusTag,
    BaseWorkorder,
    getDuration,
    getTotalPriceExpense,
    getTotalPriceIncome,
    getUiString,
    IdBoolMap,
    ModelMap,
    Schedule,
    SEE_INCOME_FINANCES,
    SEE_VENDOR_FINANCES,
    serviceAreasMatchUnit,
    ServiceContractMap,
    Unit,
    _Workorder,
    WorkSpaceUser,
    WorkorderStatus,
    getHasGoBack,
} from '../../../models'

import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import { currencyFormatter, mix_hexes, toMMDDYYYY } from '../../../helpers'
import { useWorkorderStyles } from '../styles'
import MessageIcon from '@material-ui/icons/Message'
import Badge from '@material-ui/core/Badge'
import SyncProblemIcon from '@material-ui/icons/SyncProblem'
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled'
import SyncIcon from '@material-ui/icons/Sync'
import Link from '@material-ui/icons/Link'
import { useTheme } from '@material-ui/core'
import { useUser, _useWorkorder } from '../../../hooks'
import { hasPermission } from '../../../models/Users/services'
import { ArrowBack } from '@material-ui/icons'

interface Props {
    workorder: _Workorder
    unit: Unit
    serviceAreaMap: IdBoolMap
    selected?: boolean
    inEditSelection?: boolean
    areaStatusTagMap: ModelMap<AreaStatusTag>
    upstreamContractMap: ServiceContractMap
    onClick: (workorder: _Workorder, unit: Unit) => void
    onClickChangeOrder: (workorder: BaseWorkorder) => void
    workspaceUser: WorkSpaceUser | undefined
    theme: Theme
}

const _WorkorderComponent = (props: Props) => {
    const {
        workorder,
        unit,
        onClick,
        selected,
        inEditSelection,
        serviceAreaMap,
        onClickChangeOrder,
        areaStatusTagMap,
        upstreamContractMap,
        theme,
        workspaceUser,
    } = props

    const shadowColor = mix_hexes(workorder.service_color, '#ffffff')

    const classes = useWorkorderStyles(
        workorder.service_color ?? theme.palette.noService.main,
        shadowColor,
    )

    const { updateWorkorder } = _useWorkorder()

    const serviceAreasMatch = serviceAreasMatchUnit(
        workorder,
        unit,
        serviceAreaMap,
        areaStatusTagMap,
    )

    let backgroundColor = theme.palette.grey[100]
    if (selected) {
        backgroundColor = theme.palette.primary.light
    }
    if (inEditSelection) {
        backgroundColor = mix_hexes(theme.palette.secondary.main, '#ffffff')
    }

    const hasGoBack = getHasGoBack(workorder)

    return (
        <Card
            className={classes.root}
            style={{
                backgroundColor: backgroundColor,
            }}
            onClick={() => onClick(workorder, unit)}
        >
            {/* Service + Status */}
            <Container alignItems="center" flex={1}>
                <Container
                    direction="column"
                    flex={1}
                    style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.caption,
                            // fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        (#{workorder.id})
                    </span>
                    <Container style={{ alignItems: 'center' }}>
                        <span
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {workorder.service_name ?? ''}
                        </span>
                        <span
                            style={{
                                ...theme.typography.body2,
                                marginLeft: theme.spacing(1),
                                marginRight: 'auto',
                            }}
                        >
                            ({workorder.service_area_list.length})
                        </span>
                    </Container>
                    <span
                        className={classes.lightCaption}
                        style={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {workorder.vendor_name}
                    </span>

                    <Container>
                        {hasPermission(workspaceUser, SEE_VENDOR_FINANCES) && (
                            <span
                                style={{
                                    ...theme.typography.caption,
                                    fontWeight: 'bold',
                                    marginRight: theme.spacing(1),
                                }}
                            >
                                (
                                {currencyFormatter.format(
                                    getTotalPriceExpense(workorder),
                                )}
                                )
                            </span>
                        )}

                        {hasPermission(workspaceUser, SEE_INCOME_FINANCES) && (
                            <span
                                style={{
                                    ...theme.typography.caption,
                                    fontWeight: 'bold',
                                    color: 'green',
                                }}
                            >
                                {currencyFormatter.format(
                                    getTotalPriceIncome(
                                        workorder,
                                        upstreamContractMap,
                                    ),
                                )}
                            </span>
                        )}
                    </Container>
                </Container>
                <Container direction="column" alignItems="flex-end">
                    <StatusBadge
                        text={getUiString(workorder.status)}
                        customStyle={{
                            container: {
                                backgroundColor:
                                    theme.palette[workorder.status].main,
                            },
                        }}
                    />

                    <span className={classes.lightCaption}>
                        {getDuration(workorder)}
                    </span>
                </Container>
            </Container>
            <Divider />

            <Container alignItems="center" flex={1}>
                <span className={classes.lightCaption}>
                    {toMMDDYYYY(workorder.startDate)} -{' '}
                    {toMMDDYYYY(workorder.endDate)}
                </span>

                <div style={{ flex: 1 }} />

                {workorder.message_count > 0 && (
                    <Badge
                        badgeContent={workorder.unread_count}
                        color="secondary"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MessageIcon fontSize="small" />
                    </Badge>
                )}

                {workorder.priority && (
                    <IconButton
                        onClick={() => console.log('thing')}
                        size="small"
                    >
                        <ReportProblemIcon color="secondary" fontSize="small" />
                    </IconButton>
                )}

                {!serviceAreasMatch && (
                    <Tooltip title="Edited Service Areas">
                        <SyncProblemIcon
                            style={{ color: theme.palette.brand1.main }}
                            fontSize="small"
                        />
                    </Tooltip>
                )}

                {hasGoBack && (
                    <Tooltip title="Has Go Back">
                        <ArrowBack
                            style={{ color: theme.palette.error.main }}
                            fontSize="small"
                        />
                    </Tooltip>
                )}

                {(workorder.changeorder_set?.length ?? 0) > 0 && (
                    <Tooltip
                        title={`${workorder.changeorder_set?.length} Change Orders`}
                    >
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                onClickChangeOrder(workorder)
                            }}
                            size="small"
                        >
                            <Link
                                style={{ color: theme.palette.brand1.main }}
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>
                )}

                {!workorder.subscribe_to_bed_status_change ? (
                    <Tooltip title={'Unsubscribed from bed status changes'}>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()

                                updateWorkorder({
                                    workorderId: workorder.id,
                                    body: {
                                        subscribe_to_bed_status_change: true,
                                    },
                                })
                            }}
                            size="small"
                        >
                            <SyncDisabledIcon
                                style={{ color: '#FFC107' }}
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title={'Subscribed to bed status changes'}>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()

                                updateWorkorder({
                                    workorderId: workorder.id,
                                    body: {
                                        subscribe_to_bed_status_change: false,
                                    },
                                })
                            }}
                            size="small"
                        >
                            <SyncIcon
                                style={{ color: '#2C80FF' }}
                                fontSize="small"
                            />
                        </IconButton>
                    </Tooltip>
                )}
            </Container>
        </Card>
    )
}

export const WorkorderComponent = React.memo(_WorkorderComponent)
