import { AxiosError } from 'axios'

import { AppThunk, ErrorResponse, setNetworkError } from '..'
import { axiosInstance } from '../../helpers'
import {
    AreaConfig,
    DamageConfig,
    InventoryConfig,
    CustomStatus,
    StatusGroup,
    InspectionType,
    AreaStatusConfig,
    UnitConfig,
    ChangeOrderConfig,
} from '../../models'
import {
    AptConfigActionTypes,
    AptConfigRequest,
    GetAreaConfigListActionThunk,
    GetAreaConfigListRequest,
    GetUnitConfigListActionThunk,
    GetUnitConfigListRequest,
    GET_AREA_CONFIG_LIST_REQUEST,
    GET_AREA_CONFIG_LIST_RESPONSE,
    GET_UNIT_CONFIG_LIST_REQUEST,
    GET_UNIT_CONFIG_LIST_RESPONSE,
    SET_APT_CONFIG_LOADING,
    GetDamageConfigListRequest,
    DamageConfigListThunk,
    GET_DAMAGE_CONFIG_LIST_REQUEST,
    SET_DAMAGE_CONFIG_LIST,
    CreateDamageConfigRequest,
    DamageConfigThunk,
    CREATE_DAMAGE_CONFIG_REQUEST,
    ADD_DAMAGE_CONFIG,
    UpdateDamageConfigRequest,
    UPDATE_DAMAGE_CONFIG_REQUET,
    UPDATE_DAMAGE_CONFIG,
    GetInventoryConfigListRequest,
    GET_INVENTORY_CONFIG_LIST,
    SET_INVENTORY_CONFIG_LIST,
    ADD_INVENTORY_CONFIG,
    InventoryConfigThunk,
    CREATE_INVENTORY_CONFIG_REQUEST,
    SET_STATUS_GROUP_LIST,
    GetStatusGroupListRequest,
    InventoryConfigListThunk,
    StatusGroupListThunk,
    GET_STATUS_GROUP_LIST_REQUEST,
    ADD_STATUS_GROUP,
    CreateStatusGroupRequest,
    StatusGroupThunk,
    CREATE_STATUS_GROUP_REQUEST,
    SET_STATUS_LIST,
    GetStatusListRequest,
    StatusListThunk,
    GET_STATUS_LIST_REQUEST,
    UPDATE_STATUS_GROUP,
    UpdateStatusGroupRequest,
    UPDATE_STATUS_GROUP_REQUEST,
    UPDATE_INVENTORY_CONFIG,
    UpdateInventoryConfigRequest,
    ADD_AREA_CONFIG,
    CreateAreaConfigRequest,
    AreaConfigActionThunk,
    CREATE_AREA_CONFIG_REQUEST,
    UPDATE_AREA_CONFIG,
    UpdateAreaConfigRequest,
    UPDATE_AREA_CONFIG_REQUEST,
    SetInventoryStatusCostRequest,
    SET_INVENTORY_STATUS_COST_REQUEST,
    CreateInspectionTypeRequest,
    EditInspectionTypeRequest,
    EDIT_INSPECTION_TYPE,
    ADD_INSPECTION_TYPE,
    AreaStatusConfigListThunk,
    CREATE_INSPECTION_TYPE,
    GET_INSPECTION_TYPE_LIST,
    InspectionTypeThunk,
    SET_INSPECTION_TYPE_LIST,
    GET_AREA_STATUS_CONFIG_REQUEST,
    SET_AREA_STATUS_CONFIG_LIST,
    GetChangeOrderConfigRequest,
    ChangeOrderConfigListThunk,
    GET_CHANGE_ORDER_CONFIG_LIST_REQUEST,
    SET_CHANGE_ORDER_CONFIG_LIST,
    ADD_CHANGE_ORDER_CONFIG,
    UPDATE_CHANGE_ORDER_CONFIG,
    CreateChangeOrderConfigRequest,
    ChangeOrderConfigThunk,
    CREATE_CHANGE_ORDER_CONFIG_REQUEST,
    EditChangeOrderConfigRequest,
    EDIT_CHANGE_ORDER_CONFIG_REQUEST,
    UpdateUnitConfigRequest,
    UnitConfigThunk,
    UPDATE_UNIT_CONFIG_REQUEST,
    UPDATE_UNIT_CONFIG,
    RequestCustomStatus,
    CreateInventoryConfigRequest,
    ArchiveDamageConfigsRequest,
} from './types'

export const getAreaConfigList = (
    req: GetAreaConfigListRequest,
): AppThunk<GetAreaConfigListActionThunk> => {
    return async (dispatch) => {
        dispatch({
            type: GET_AREA_CONFIG_LIST_REQUEST,
        })

        const url = 'apt_config/area_config/'

        try {
            const response = await axiosInstance.get(url, { params: req })
            dispatch(setAreaConfigList(response.data))
            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createAreaConfigReq = (
    req: CreateAreaConfigRequest,
): AppThunk<AreaConfigActionThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(CREATE_AREA_CONFIG_REQUEST, true))

        const url = 'apt_config/area_config/'

        try {
            const response = await axiosInstance.post(url, req.body)

            dispatch(addAreaConfig(response.data))
            dispatch(setAptConfigLoading(CREATE_AREA_CONFIG_REQUEST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setAptConfigLoading(CREATE_AREA_CONFIG_REQUEST, false))

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const updateAreaConfigReq = (
    req: UpdateAreaConfigRequest,
): AppThunk<AreaConfigActionThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(UPDATE_AREA_CONFIG_REQUEST, true))

        const url = `apt_config/area_config/${req.params.id}/`

        try {
            const response = await axiosInstance.patch(url, req.body)

            dispatch(updateAreaConfig(response.data))
            dispatch(setAptConfigLoading(UPDATE_AREA_CONFIG_REQUEST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setAptConfigLoading(UPDATE_AREA_CONFIG_REQUEST, false))

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const updateUnitConfigReq = (
    req: UpdateUnitConfigRequest,
): AppThunk<UnitConfigThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(UPDATE_UNIT_CONFIG_REQUEST, true))

        const url = `apt_config/unit_config/${req.id}/`

        try {
            const response = await axiosInstance.patch(url, req.body)

            dispatch(updateUnitConfig(response.data))
            dispatch(setAptConfigLoading(UPDATE_UNIT_CONFIG_REQUEST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setAptConfigLoading(UPDATE_UNIT_CONFIG_REQUEST, false))

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getAreaStatusConfigList = (): AppThunk<AreaStatusConfigListThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(GET_AREA_STATUS_CONFIG_REQUEST, true))

        const url = `apt_config/area-status/`

        try {
            const response = await axiosInstance.get(url)
            dispatch(setAptConfigLoading(GET_AREA_STATUS_CONFIG_REQUEST, false))
            dispatch(setAreaStatusConfigList(response.data))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setAptConfigLoading(GET_AREA_STATUS_CONFIG_REQUEST, false))

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getUnitConfigList = (
    req: GetUnitConfigListRequest,
): AppThunk<GetUnitConfigListActionThunk> => {
    return async (dispatch) => {
        dispatch({
            type: GET_UNIT_CONFIG_LIST_REQUEST,
        })

        const url = 'apt_config/unit_config/'

        try {
            const response = await axiosInstance.get(url, { params: req })

            dispatch(setUnitConfigList(response.data))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getDamageConfigList = (
    req: GetDamageConfigListRequest,
): AppThunk<DamageConfigListThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(GET_DAMAGE_CONFIG_LIST_REQUEST, true))

        const url = 'apt_config/damage_config/'

        try {
            const response = await axiosInstance.get(url, req)

            dispatch(setDamageConfigList(response.data))
            dispatch(setAptConfigLoading(GET_DAMAGE_CONFIG_LIST_REQUEST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setAptConfigLoading(GET_DAMAGE_CONFIG_LIST_REQUEST, false))

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createDamageConfig = (
    req: CreateDamageConfigRequest,
): AppThunk<DamageConfigThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(CREATE_DAMAGE_CONFIG_REQUEST, true))

        const url = 'apt_config/damage_config/'

        try {
            const response = await axiosInstance.post(url, req.body)

            dispatch(addDamageConfig(response.data))
            dispatch(setAptConfigLoading(CREATE_DAMAGE_CONFIG_REQUEST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setAptConfigLoading(CREATE_DAMAGE_CONFIG_REQUEST, false))

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const updateDamageConfig = (
    req: UpdateDamageConfigRequest,
): AppThunk<DamageConfigThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(UPDATE_DAMAGE_CONFIG_REQUET, true))

        const url = `apt_config/damage_config/${req.id}/`

        try {
            const response = await axiosInstance.patch(url, req.body)

            dispatch(replaceDamageConfig(req.id, response.data))
            dispatch(setAptConfigLoading(UPDATE_DAMAGE_CONFIG_REQUET, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setAptConfigLoading(UPDATE_DAMAGE_CONFIG_REQUET, false))

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const archiveDamageConfigs = (
    req: ArchiveDamageConfigsRequest,
): AppThunk<DamageConfigThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(UPDATE_DAMAGE_CONFIG_REQUET, true))

        const url = `apt_config/damage_config/archive_damage_configs/`

        try {
            const response = await axiosInstance.post(url, req)

            dispatch(setDamageConfigList(response.data))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setAptConfigLoading(UPDATE_DAMAGE_CONFIG_REQUET, false))

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getInventoryConfigList = (
    req: GetInventoryConfigListRequest,
): AppThunk<InventoryConfigListThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(GET_INVENTORY_CONFIG_LIST, true))

        const url = `apt_config/inventory_config/`

        try {
            const response = await axiosInstance.get(url, req)

            dispatch(setInventoryConfigList(response.data))
            dispatch(setAptConfigLoading(GET_INVENTORY_CONFIG_LIST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setAptConfigLoading(GET_INVENTORY_CONFIG_LIST, false))

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}
export const setInventoryStatusCost = (
    req: SetInventoryStatusCostRequest,
): AppThunk<InventoryConfigThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(SET_INVENTORY_STATUS_COST_REQUEST, true))

        const url = `apt_config/inventory_config/${req.params.id}/set_status_cost_list/`

        try {
            const response = await axiosInstance.post(url, req.body)

            dispatch(updateInventoryConfig(response.data))
            dispatch(
                setAptConfigLoading(SET_INVENTORY_STATUS_COST_REQUEST, false),
            )

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(
                setAptConfigLoading(SET_INVENTORY_STATUS_COST_REQUEST, false),
            )

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getStatusGroupList = (
    req: GetStatusGroupListRequest,
): AppThunk<StatusGroupListThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(GET_STATUS_GROUP_LIST_REQUEST, true))

        const url = `apt_config/status_group/`

        try {
            const response = await axiosInstance.get(url, req)

            dispatch(setStatusGroupList(response.data))
            dispatch(setAptConfigLoading(GET_STATUS_GROUP_LIST_REQUEST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setAptConfigLoading(GET_STATUS_GROUP_LIST_REQUEST, false))

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getStatusList = (
    req: GetStatusListRequest,
): AppThunk<StatusListThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(GET_STATUS_LIST_REQUEST, true))

        const url = `apt_config/custom_status/`

        try {
            const response = await axiosInstance.get(url, req)

            dispatch(setStatusList(response.data))
            dispatch(setAptConfigLoading(GET_STATUS_LIST_REQUEST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setAptConfigLoading(GET_STATUS_LIST_REQUEST, false))

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createInventoryConfig = (
    req: FormData | CreateInventoryConfigRequest,
): AppThunk<InventoryConfigThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(CREATE_INVENTORY_CONFIG_REQUEST, true))

        const url = `apt_config/inventory_config/`

        try {
            const response = await axiosInstance.post(url, req)

            dispatch(addInventoryConfig(response.data))
            dispatch(
                setAptConfigLoading(CREATE_INVENTORY_CONFIG_REQUEST, false),
            )

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(
                setAptConfigLoading(CREATE_INVENTORY_CONFIG_REQUEST, false),
            )

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createStatusGroup = (
    req: CreateStatusGroupRequest,
): AppThunk<StatusGroupThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(CREATE_STATUS_GROUP_REQUEST, true))

        const url = `apt_config/status_group/`

        try {
            const response = await axiosInstance.post(url, req)

            dispatch(addStatusGroup(response.data))
            dispatch(setAptConfigLoading(CREATE_STATUS_GROUP_REQUEST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setAptConfigLoading(CREATE_STATUS_GROUP_REQUEST, false))

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const updateStatusGroupReq = (
    req: UpdateStatusGroupRequest,
): AppThunk<StatusGroupThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(UPDATE_STATUS_GROUP_REQUEST, true))

        const url = `apt_config/status_group/${req.params.id}/`

        try {
            const response = await axiosInstance.patch(url, req.body)

            dispatch(updateStatusGroup(response.data))
            dispatch(setAptConfigLoading(UPDATE_STATUS_GROUP_REQUEST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setAptConfigLoading(UPDATE_STATUS_GROUP_REQUEST, false))

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}
export const updateInventoryConfigReq = (
    req: UpdateInventoryConfigRequest,
): AppThunk<StatusGroupThunk> => {
    return async (dispatch) => {
        dispatch(setAptConfigLoading(UPDATE_STATUS_GROUP_REQUEST, true))

        const url = `apt_config/inventory_config/${req.id}/`

        try {
            const response = await axiosInstance.patch(url, req.req)

            dispatch(updateInventoryConfig(response.data))
            dispatch(setAptConfigLoading(UPDATE_STATUS_GROUP_REQUEST, false))

            return response
        } catch (e) {
            const error = e as AxiosError<ErrorResponse>
            dispatch(setAptConfigLoading(UPDATE_STATUS_GROUP_REQUEST, false))

            dispatch(setNetworkError(error, true))

            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getInspectionTypeList = (): AppThunk => {
    return async (dispatch) => {
        // begin request
        dispatch(setAptConfigLoading(GET_INSPECTION_TYPE_LIST, true))

        const url = `apt_config/inspection_type/`

        try {
            // success
            const response = await axiosInstance.get(url)

            dispatch(setInspectionTypeList(response.data))
            dispatch(setAptConfigLoading(GET_INSPECTION_TYPE_LIST, false))
            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(setAptConfigLoading(GET_INSPECTION_TYPE_LIST, false))
            dispatch(setNetworkError(error, true))
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const createInspectionType = (
    req: CreateInspectionTypeRequest,
): AppThunk<InspectionTypeThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setAptConfigLoading(CREATE_INSPECTION_TYPE, true))

        const url = `apt_config/inspection_type/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)

            dispatch(addInspectionType(response.data))
            dispatch(setAptConfigLoading(CREATE_INSPECTION_TYPE, false))
            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(setAptConfigLoading(CREATE_INSPECTION_TYPE, false))
            dispatch(setNetworkError(error, true))
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const editInspectionTypeReq = (
    req: EditInspectionTypeRequest,
): AppThunk<InspectionTypeThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setAptConfigLoading(EDIT_INSPECTION_TYPE, true))

        const url = `apt_config/inspection_type/${req.id}/`

        try {
            // success
            const response = await axiosInstance.patch(url, req.body)
            dispatch(editInspectionType(response.data))
            dispatch(setAptConfigLoading(EDIT_INSPECTION_TYPE, false))
            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(setAptConfigLoading(EDIT_INSPECTION_TYPE, false))
            dispatch(setNetworkError(error, true))
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const getChangeOrderConfigListRequest = (
    req: GetChangeOrderConfigRequest,
): AppThunk<ChangeOrderConfigListThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(
            setAptConfigLoading(GET_CHANGE_ORDER_CONFIG_LIST_REQUEST, true),
        )

        const url = `apt_config/change-order-config/`

        try {
            // success
            const response = await axiosInstance.get(url, req)
            dispatch(setChangeOrderConfigList(response.data))
            dispatch(
                setAptConfigLoading(
                    GET_CHANGE_ORDER_CONFIG_LIST_REQUEST,
                    false,
                ),
            )
            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(
                setAptConfigLoading(
                    GET_CHANGE_ORDER_CONFIG_LIST_REQUEST,
                    false,
                ),
            )
            dispatch(setNetworkError(error, true))
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}
export const createChangeOrderConfigRequest = (
    req: CreateChangeOrderConfigRequest,
): AppThunk<ChangeOrderConfigThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setAptConfigLoading(CREATE_CHANGE_ORDER_CONFIG_REQUEST, true))

        const url = `apt_config/change-order-config/`

        try {
            // success
            const response = await axiosInstance.post(url, req.body)
            dispatch(addChangeOrderConfig(response.data))
            dispatch(
                setAptConfigLoading(CREATE_CHANGE_ORDER_CONFIG_REQUEST, false),
            )
            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(
                setAptConfigLoading(CREATE_CHANGE_ORDER_CONFIG_REQUEST, false),
            )
            dispatch(setNetworkError(error, true))
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}
export const editChangeOrderConfigRequest = (
    req: EditChangeOrderConfigRequest,
): AppThunk<ChangeOrderConfigListThunk> => {
    return async (dispatch) => {
        // begin request
        dispatch(setAptConfigLoading(EDIT_CHANGE_ORDER_CONFIG_REQUEST, true))

        const url = `apt_config/change-order-config/${req.id}/`

        try {
            // success
            const response = await axiosInstance.patch(url, req.body)
            dispatch(updateChangeOrderConfig(response.data))
            dispatch(
                setAptConfigLoading(EDIT_CHANGE_ORDER_CONFIG_REQUEST, false),
            )
            return response
        } catch (e) {
            // error handling
            const error = e as AxiosError<ErrorResponse>

            dispatch(
                setAptConfigLoading(EDIT_CHANGE_ORDER_CONFIG_REQUEST, false),
            )
            dispatch(setNetworkError(error, true))
            return new Promise((_, reject) => {
                reject(error)
            })
        }
    }
}

export const setChangeOrderConfigList = (
    changeOrderConfigList?: ChangeOrderConfig[],
): AptConfigActionTypes => {
    return {
        type: SET_CHANGE_ORDER_CONFIG_LIST,
        changeOrderConfigList: changeOrderConfigList,
    }
}

export const addChangeOrderConfig = (
    changeOrderConfig: ChangeOrderConfig,
): AptConfigActionTypes => {
    return {
        type: ADD_CHANGE_ORDER_CONFIG,
        changeOrderConfig: changeOrderConfig,
    }
}

export const updateChangeOrderConfig = (
    changeOrderConfig: ChangeOrderConfig,
): AptConfigActionTypes => {
    return {
        type: UPDATE_CHANGE_ORDER_CONFIG,
        changeOrderConfig: changeOrderConfig,
    }
}

export const addInspectionType = (
    inspectionType: InspectionType,
): AptConfigActionTypes => {
    return {
        type: ADD_INSPECTION_TYPE,
        inspectionType: inspectionType,
    }
}

export const editInspectionType = (
    inspectionType: InspectionType,
): AptConfigActionTypes => {
    return {
        type: EDIT_INSPECTION_TYPE,
        inspectionType: inspectionType,
    }
}

export const updateAreaConfig = (
    areaConfig: AreaConfig,
): AptConfigActionTypes => {
    return {
        type: UPDATE_AREA_CONFIG,
        areaConfig: areaConfig,
    }
}

export const updateUnitConfig = (
    unitConfig: UnitConfig,
): AptConfigActionTypes => {
    return {
        type: UPDATE_UNIT_CONFIG,
        unitConfig: unitConfig,
    }
}

export const addAreaConfig = (areaConfig: AreaConfig): AptConfigActionTypes => {
    return {
        type: ADD_AREA_CONFIG,
        areaConfig: areaConfig,
    }
}

export const updateInventoryConfig = (
    invConfig: InventoryConfig,
): AptConfigActionTypes => {
    return {
        type: UPDATE_INVENTORY_CONFIG,
        inventoryConfig: invConfig,
    }
}

export const updateStatusGroup = (
    statusGroup: StatusGroup,
): AptConfigActionTypes => {
    return {
        type: UPDATE_STATUS_GROUP,
        statusGroup: statusGroup,
    }
}

export const setStatusList = (
    statusList?: CustomStatus[],
): AptConfigActionTypes => {
    return {
        type: SET_STATUS_LIST,
        statusList: statusList,
    }
}

export const addStatusGroup = (
    statusGroup: StatusGroup,
): AptConfigActionTypes => {
    return {
        type: ADD_STATUS_GROUP,
        statusGroup: statusGroup,
    }
}

export const setStatusGroupList = (
    statusGroups?: StatusGroup[],
): AptConfigActionTypes => {
    return {
        type: SET_STATUS_GROUP_LIST,
        statusGroupList: statusGroups,
    }
}

export const addInventoryConfig = (
    inventoryConfig: InventoryConfig,
): AptConfigActionTypes => {
    return {
        type: ADD_INVENTORY_CONFIG,
        invConfig: inventoryConfig,
    }
}

export const setInventoryConfigList = (
    inventoryConfigList?: InventoryConfig[],
): AptConfigActionTypes => {
    return {
        type: SET_INVENTORY_CONFIG_LIST,
        inventoryConfigList: inventoryConfigList,
    }
}

export const replaceDamageConfig = (
    id: number,
    damageConfig?: DamageConfig,
): AptConfigActionTypes => {
    return {
        type: UPDATE_DAMAGE_CONFIG,
        id: id,
        damageConfig: damageConfig,
    }
}

export const addDamageConfig = (
    damageConfig: DamageConfig,
): AptConfigActionTypes => {
    return {
        type: ADD_DAMAGE_CONFIG,
        damageConfig: damageConfig,
    }
}

export const setDamageConfigList = (
    damageConfigList?: DamageConfig[],
): AptConfigActionTypes => {
    return {
        type: SET_DAMAGE_CONFIG_LIST,
        damageConfigList: damageConfigList,
    }
}

export const setInspectionTypeList = (
    inspectionTypeList?: InspectionType[],
): AptConfigActionTypes => {
    return {
        type: SET_INSPECTION_TYPE_LIST,
        inspectionTypeList: inspectionTypeList,
    }
}

export const setAreaStatusConfigList = (
    areaStatusConfigList?: AreaStatusConfig[],
): AptConfigActionTypes => {
    return {
        type: SET_AREA_STATUS_CONFIG_LIST,
        areaStatusConfigList: areaStatusConfigList,
    }
}

export const setAptConfigLoading = (
    request: AptConfigRequest,
    isLoading: boolean,
): AptConfigActionTypes => {
    return {
        type: SET_APT_CONFIG_LOADING,
        request: request,
        isLoading: isLoading,
    }
}

export const setUnitConfigList = (
    unitConfigList: UnitConfig[],
): AptConfigActionTypes => {
    return {
        type: GET_UNIT_CONFIG_LIST_RESPONSE,
        payload: unitConfigList,
    }
}

export const setAreaConfigList = (
    areaConfigList: AreaConfig[],
): AptConfigActionTypes => {
    return {
        type: GET_AREA_CONFIG_LIST_RESPONSE,
        payload: areaConfigList,
    }
}
