import { useTheme } from '@material-ui/core'
import React, { useRef, useEffect } from 'react'
import OptionHoc from './OptionHoc'

export interface Option {
    id: React.Key
    name: string
}

interface Props {
    open: boolean
    options: Option[]
    focusedOptionIdx: number
    mentionText?: string
    setFocusedOptionIdx: (idx: number) => void
    renderOption?: (opt: Option, idx: number) => JSX.Element
    onSelectOption: (opt: Option) => void
}

const ContextMenu = (props: Props) => {
    const {
        open,
        options,
        focusedOptionIdx,
        mentionText,
        setFocusedOptionIdx,
        onSelectOption,
        renderOption,
    } = props

    const contextMenuRef = useRef<HTMLDivElement>(null)

    const theme = useTheme()

    useEffect(() => {
        // Each time keydown is bound, the current component state is bound to "handleKeyDown"
        // Anytime the state changes rebind the "handleKeyDown"
        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [options, focusedOptionIdx, open, mentionText])

    useEffect(() => {
        if (focusedOptionIdx > options.length) {
            // User changed changed the filtered options.
            // The old selection index would now be out of bounds of the new selection
            // Reset the selection index to 0
            setFocusedOptionIdx(0)

            if (contextMenuRef.current) {
                contextMenuRef.current.scrollTo({ top: 0, behavior: 'auto' })
            }
        }
    }, [mentionText])

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            return handleArrowKeys(e)
        }

        if (e.key === 'Enter' && options && focusedOptionIdx < options.length) {
            onSelectOption(options[focusedOptionIdx])
        }
        return null
    }

    const handleArrowKeys = (e: KeyboardEvent) => {
        let newIndex = focusedOptionIdx
        const maxIndex = options.length - 1

        if (e.key === 'ArrowUp') {
            e.preventDefault()
            newIndex = focusedOptionIdx === 0 ? 0 : focusedOptionIdx - 1
            if (newIndex > maxIndex) {
                newIndex = 0
            }
            setFocusedOptionIdx(newIndex)
        } else if (e.key === 'ArrowDown' && options.length > 0) {
            e.preventDefault()
            newIndex =
                focusedOptionIdx >= maxIndex ? maxIndex : focusedOptionIdx + 1
            setFocusedOptionIdx(newIndex)
        }

        // The new selected option may be above or below the context menu bounds
        // Adjust the context menu scroll to keep the selected option in view
        if (contextMenuRef.current) {
            const currentOption = contextMenuRef.current.children[newIndex]
            const optRect = currentOption.getBoundingClientRect()
            const menuRect = contextMenuRef.current.getBoundingClientRect()

            if (optRect.top < menuRect.top) {
                contextMenuRef.current.scrollBy(0, -optRect.height)
            }

            if (optRect.bottom > menuRect.bottom) {
                contextMenuRef.current.scrollBy(0, optRect.height)
            }
        }
    }

    return (
        <div
            style={{
                position: 'absolute',
                minHeight: '100px',
                maxHeight: '250px',
                minWidth: '100px',
                backgroundColor: theme.palette.grey[300],
                borderRadius: '4px',
                border: `1px solid ${theme.palette.grey[200]}`,
                boxShadow: theme.shadows[3],
                top: '-2px',
                left: 0,
                transform: 'translate(0, -100%)',
                padding: theme.spacing(1),
                display: open ? 'flex' : 'none',
                flexDirection: 'column',
                overflowY: 'scroll',
                outline: 'none',
            }}
            ref={contextMenuRef}
        >
            {renderOption &&
                options.map((opt, idx) => {
                    return (
                        <OptionHoc
                            key={`C_MENU_OPT_${idx}`}
                            active={idx === focusedOptionIdx}
                            theme={theme}
                            onSelect={() => {
                                setFocusedOptionIdx(idx)
                                onSelectOption(opt)
                            }}
                        >
                            {renderOption(opt, idx)}
                        </OptionHoc>
                    )
                })}
        </div>
    )
}

export default ContextMenu
