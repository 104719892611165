import {
    CompanyActionTypes,
    CompanyState,
    CREATE_VENDOR_REQUEST,
    CREATE_VENDOR_RESPONSE,
    GET_APARTMENT_LIST_REQUEST,
    GET_APARTMENT_LIST_RESPONSE,
    GET_VENDOR_LIST_REQUEST,
    GET_VENDOR_LIST_RESPONSE,
    SET_COMPANY_DETAIL,
    SET_COMPANY_LOADING,
    SET_PORTFOLIO_VENDOR_LIST,
    SET_VENDOR_LIST,
    UPDATE_VENDOR_REQUEST,
    UPDATE_VENDOR_RESPONSE,
    UPDATE_VENDOR_SERVICE_REQUEST,
    UPDATE_VENDOR_SERVICE_RESPONSE,
} from './types'

const initialState: CompanyState = {
    vendorList: [],
    apartmentList: [],
    isLoading: {
        GET_APARTMENT_LIST_REQUEST: false,
        GET_VENDOR_LIST_REQUEST: false,
        CREATE_VENDOR_REQUEST: false,
        UPDATE_VENDOR_REQUEST: false,
        UPDATE_VENDOR_SERVICE_REQUEST: false,
        ADD_VENDOR_TO_APT_REQUEST: false,
        GET_COMPANY_DETAIL_REQUEST: false,
        REMOVE_VENDOR_APT_REQUEST: false,
    },
}

export const companyReducer = (
    state = initialState,
    action: CompanyActionTypes,
): CompanyState => {
    switch (action.type) {
        case UPDATE_VENDOR_SERVICE_REQUEST:
        case UPDATE_VENDOR_REQUEST:
        case CREATE_VENDOR_REQUEST:
        case GET_APARTMENT_LIST_REQUEST:
        case GET_VENDOR_LIST_REQUEST:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.type]: true,
                },
            }
        case GET_APARTMENT_LIST_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_APARTMENT_LIST_REQUEST: false,
                },
                apartmentList: action.payload,
            }

        case GET_VENDOR_LIST_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    GET_VENDOR_LIST_REQUEST: false,
                },
                vendorList: action.payload,
            }

        case CREATE_VENDOR_RESPONSE:
            return {
                ...state,
                vendorList: state.vendorList
                    ? [...state.vendorList, action.payload]
                    : [action.payload],
            }
        case UPDATE_VENDOR_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    UPDATE_VENDOR_REQUEST: false,
                },
                vendorList: state.vendorList.map((vendor) => {
                    if (action.payload.id === vendor.id) return action.payload
                    return vendor
                }),
            }
        case UPDATE_VENDOR_SERVICE_RESPONSE:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    UPDATE_VENDOR_SERVICE_REQUEST: false,
                },
                vendorList: state.vendorList.map((vendor) => {
                    if (action.payload.id === vendor.id) return action.payload
                    return vendor
                }),
            }
        case SET_COMPANY_LOADING:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    [action.action]: action.isLoading,
                },
            }
        case SET_VENDOR_LIST:
            return {
                ...state,
                vendorList: action.vendorList ?? [],
            }

        case SET_COMPANY_DETAIL:
            return {
                ...state,
                companyDetail: action.company,
            }
        case SET_PORTFOLIO_VENDOR_LIST:
            return {
                ...state,
                portfolioVendorList: action.vendorList,
            }

        default:
            return state
    }
}
