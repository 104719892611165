import { useEffect, useState } from 'react'

import { InfrastructureFilterOptions, LocationSelection } from '../../store'

import { _filterInfrastructure, filterInfrastructure, Unit } from '../../models'
import { INITIAL_ROOT_ID, VersionedTree } from './types'

export const _usePrunedInfrastructure = (
    tree: VersionedTree,
    locationFilter: LocationSelection,
    filter?: InfrastructureFilterOptions,
    isUnitInFilter?: (unit: Unit) => boolean,
): VersionedTree => {
    const [prunedTree, setPrunedTree] = useState<VersionedTree>({
        root: tree.root,
        version: 0,
    })

    useEffect(() => {
        if (tree.root.id !== INITIAL_ROOT_ID) {
            console.log('prune tree 2', tree.version)

            const prunedRoot = _filterInfrastructure(
                tree.root,
                locationFilter,
                isUnitInFilter,
            )

            // Tree has been pruned to match the location filters.
            setPrunedTree({
                root: prunedRoot,
                version: prunedTree.version + 1,
            })
        }
    }, [tree.version, locationFilter, filter])

    return prunedTree
}

export const usePrunedInfrastructure = (
    tree: VersionedTree,
    locationFilter: LocationSelection,
    filter?: InfrastructureFilterOptions,
    isUnitInFilter?: (unit: Unit) => boolean,
): VersionedTree => {
    const [prunedTree, setPrunedTree] = useState<VersionedTree>({
        root: tree.root,
        version: 0,
    })

    useEffect(() => {
        if (tree.root.id !== INITIAL_ROOT_ID) {
            console.log('prune tree', tree.version)

            const prunedRoot = filterInfrastructure(
                tree.root,
                locationFilter,
                isUnitInFilter,
            )

            // Tree has been pruned to match the location filters.
            setPrunedTree({
                root: prunedRoot,
                version: prunedTree.version + 1,
            })
        }
    }, [tree.version, locationFilter, filter])

    return prunedTree
}
