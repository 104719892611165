import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    AreaConfig,
    convertListToMap,
    IdentifiableObject,
    InventoryConfig,
    InventoryGroup,
    UNINSPECTED_STATUS,
} from '../models'
import {
    getAreaConfigList,
    GetAreaConfigListRequest,
    getAreaStatusConfigList,
    getChangeOrderConfigListRequest,
    getDamageConfigList,
    getInspectionTypeList,
    getInventoryConfigList,
    getStatusGroupList,
    getStatusList,
    getUnitConfigList,
    RootState,
    setAreaConfigList,
    setAreaStatusConfigList,
    setDamageConfigList,
    setInventoryConfigList,
    setStatusGroupList,
    setUnitConfigList,
    updateAreaConfig,
} from '../store'
import { useAppDispatch } from './useAppDispatch'
import { axiosInstance } from '../helpers'
import { toast } from 'react-toastify'

interface Options {
    areaConfigList?: boolean
    unitConfigList?: boolean
    customStatusList?: boolean
    inspectionTypeList?: boolean
    inventoryConfigList?: boolean
    areaStatusConfigList?: boolean
    damageConfigList?: boolean
    changeOrderConfigList?: boolean
    statusGroupList?: boolean
    minimizeReqeusts?: boolean
    cleanUp?: boolean
}

export const useAptConfig = (options: Options) => {
    const dispatch = useAppDispatch()

    const inventoryConfigList = useSelector(
        (state: RootState) => state.aptConfig.inventoryConfigList,
    )

    const areaConfigList = useSelector(
        (state: RootState) => state.aptConfig.areaConfigList,
    )

    const statusGroupList = useSelector(
        (state: RootState) => state.aptConfig.statusGroupList,
    )
    const unitConfigList = useSelector(
        (state: RootState) => state.aptConfig.unitConfigList,
    )

    const customStatusList = useSelector(
        (state: RootState) => state.aptConfig.statusList,
    )

    const inspectionTypeList = useSelector(
        (state: RootState) => state.aptConfig.inspectionTypeList,
    )

    const areaStatusConfigList = useSelector(
        (state: RootState) => state.aptConfig.areaStatusConfigList,
    )

    const damageConfigList = useSelector(
        (state: RootState) => state.aptConfig.damageConfigList,
    )

    const changeOrderConfigList = useSelector(
        (state: RootState) => state.aptConfig.changeOrderConfigList,
    )

    const loadingState = useSelector(
        (state: RootState) => state.aptConfig.isLoading,
    )

    const makeRequestIfNecessary = (
        request: () => void,
        dataset: IdentifiableObject[] | undefined,
    ) => {
        if (
            options.minimizeReqeusts &&
            dataset !== undefined &&
            dataset.length > 0
        ) {
            return
        }

        request()
    }

    useEffect(() => {
        if (options.inventoryConfigList) {
            makeRequestIfNecessary(
                () => dispatch(getInventoryConfigList({})),
                inventoryConfigList,
            )
        }
        if (options.areaConfigList) {
            makeRequestIfNecessary(
                () => dispatch(getAreaConfigList({})),
                areaConfigList,
            )
        }

        if (options.statusGroupList) {
            makeRequestIfNecessary(
                () => dispatch(getStatusGroupList({})),
                statusGroupList,
            )
        }

        if (options.customStatusList) {
            makeRequestIfNecessary(
                () => dispatch(getStatusList({})),
                customStatusList,
            )
        }
        if (options.unitConfigList) {
            makeRequestIfNecessary(
                () => dispatch(getUnitConfigList({})),
                unitConfigList,
            )
        }
        if (options.inspectionTypeList) {
            makeRequestIfNecessary(
                () => dispatch(getInspectionTypeList()),
                inspectionTypeList,
            )
        }
        if (options.inventoryConfigList) {
            makeRequestIfNecessary(
                () => dispatch(getInventoryConfigList({})),
                inventoryConfigList,
            )
        }

        if (options.areaStatusConfigList) {
            makeRequestIfNecessary(
                () => dispatch(getAreaStatusConfigList()),
                areaStatusConfigList,
            )
        }

        if (options.damageConfigList) {
            makeRequestIfNecessary(
                () => dispatch(getDamageConfigList({})),
                damageConfigList,
            )
        }

        if (options.changeOrderConfigList) {
            dispatch(getChangeOrderConfigListRequest({}))
        }

        if (options.cleanUp) {
            return () => {
                dispatch(setInventoryConfigList())
                dispatch(setDamageConfigList())
                dispatch(setUnitConfigList([]))
                dispatch(setAreaConfigList([]))
                dispatch(setAreaStatusConfigList())
                dispatch(setStatusGroupList())
            }
        }

        return () => {}
    }, [])

    return {
        inventoryConfigList: inventoryConfigList,
        areaConfigList: areaConfigList,
        unitConfigList: unitConfigList,
        customStatusList: [...(customStatusList ?? []), UNINSPECTED_STATUS],
        inspectionTypeList: inspectionTypeList,
        areaStatusConfigList: areaStatusConfigList,
        damageConfigList: damageConfigList,
        changeOrderConfigList: changeOrderConfigList,
        statusGroupList: statusGroupList,
        getAreaConfigList: getAreaConfigList,
        loadingState,
        getUnitConfigMap: () => convertListToMap(unitConfigList ?? []),
        getAreaConfigMap: () => convertListToMap(areaConfigList ?? []),
        getInventoryConfigMap: () =>
            convertListToMap(inventoryConfigList ?? []),
    }
}

export type AptConfigController = ReturnType<typeof useAptConfig>

export const useInventoryGroup = () => {
    const dispatch = useAppDispatch()

    const [inventoryGroups, setInventoryGroups] = useState<
        InventoryGroup[] | null
    >(null)

    const areaConfigList = useSelector(
        (state: RootState) => state.aptConfig.areaConfigList,
    )

    const onMount = async () => {
        try {
            const res = await axiosInstance.get('apt_config/inventory-group/')
            setInventoryGroups(res.data)
        } catch (e) {
            console.log(e)
            toast.error(`ERROR: ${e}`)
        }
    }

    useEffect(() => {
        onMount()

        return () => {
            setInventoryGroups(null)
        }
    }, [])

    const _addOrUpdateGroup = (group: InventoryGroup) => {
        setInventoryGroups((oldGroups) => {
            const safeOldGroups = oldGroups ?? []
            const newGroups = [...safeOldGroups]

            let found = false
            for (let i = 0; i < safeOldGroups.length; i++) {
                if (safeOldGroups[i].id === group.id) {
                    found = true
                    newGroups[i] = group
                }
            }

            if (!found) {
                newGroups.push(group)
            }

            return newGroups
        })
    }

    const _removeGroup = (group: InventoryGroup) => {
        setInventoryGroups((oldGroups) => {
            return oldGroups?.filter((g) => g.id !== group.id) ?? null
        })
    }

    const createInventoryGroup = async (request: InventoryGroupRequest) => {
        try {
            const res = await axiosInstance.post(
                'apt_config/inventory-group/',
                request,
            )
            _addOrUpdateGroup(res.data)
        } catch (e) {
            toast.error(`Error adding group: ${e}`)
            console.error(`ERROR: ${e}`)
        }
    }

    const updateInventoryGroup = async (
        request: InventoryGroupRequest,
        groupId: number,
    ) => {
        try {
            const res = await axiosInstance.patch(
                `apt_config/inventory-group/${groupId}/`,
                request,
            )
            _addOrUpdateGroup(res.data)
        } catch (e) {
            toast.error(`Error updating group: ${e}`)
            console.error(`ERROR: ${e}`)
        }
    }

    const deleteInventoryGroup = async (inventoryGroup: InventoryGroup) => {
        try {
            await axiosInstance.delete(
                `apt_config/inventory-group/${inventoryGroup.id}/`,
            )
            _removeGroup(inventoryGroup)
        } catch (e) {
            toast.error(`Error deleting group: ${e}`)
            console.error(`ERROR: ${e}`)
        }
    }

    const addGroupToAreaConfig = async (
        inventoryGroupId: number,
        areaConfigId: number,
    ) => {
        const res = await axiosInstance.post(
            `apt_config/area_config/${areaConfigId}/add_inventory_group/`,
            {
                inventory_group: inventoryGroupId,
            },
        )

        const areaConfig: AreaConfig = res.data
        dispatch(updateAreaConfig(areaConfig))
        return areaConfig
    }

    return {
        inventoryGroups,
        createInventoryGroup,
        updateInventoryGroup,
        deleteInventoryGroup,
        addGroupToAreaConfig,
    }
}

export const useAreaConfig = () => {
    const [loading, setLoading] = useState(false)

    const dispatch = useAppDispatch()

    const areaConfigList = useSelector(
        (state: RootState) => state.aptConfig.areaConfigList,
    )

    const getAreaConfigs = (req: GetAreaConfigListRequest) => {
        setLoading(true)

        dispatch(getAreaConfigList(req)).finally(() => setLoading(false))
    }

    const areaConfigMap = useMemo(() => {
        return convertListToMap(areaConfigList)
    }, [areaConfigList])

    return {
        loading,
        areaConfigList,
        getAreaConfigs,
        areaConfigMap,
    }
}

export interface InventoryGroupRequest {
    name: string
    inventory_configs: {
        id: number
        amount: number
    }[]
}

export const useInventoryConfig = () => {
    const [loading, setLoading] = useState(false)

    const [
        inventoryConfig,
        setInventoryConfig,
    ] = useState<InventoryConfig | null>(null)

    const [inventoryStatusScores, setInventoryStatusScores] = useState<
        InventoryStatusScore[]
    >([])

    const fetchInventoryStatusScores = async (inventoryConfigId?: number) => {
        setLoading(true)

        const res = await axiosInstance
            .get(`apt_config/inventory_config/status_scores/`, {
                params: { inventory_config: inventoryConfigId },
            })
            .catch((e) => {
                toast.error(`Error fetching inventory status scores: ${e}`)
                console.error(`ERROR: ${e}`)
            })
        setLoading(false)

        if (res) {
            const inventoryStatusScores: InventoryStatusScore[] = res.data
            setInventoryStatusScores(inventoryStatusScores)
            return inventoryStatusScores
        }

        return null
    }

    const updateInventoryStatusScore = async (
        inventoryConfigId: number,
        statusId: number,
        score: number,
    ) => {
        setLoading(true)
        const res = await axiosInstance
            .post(
                `apt_config/inventory_config/${inventoryConfigId}/set_status_scores/`,
                {
                    custom_status_id: statusId,
                    score,
                },
            )
            .catch((e) => {
                toast.error(`Error updating inventory status score: ${e}`)
                console.error(`ERROR: ${e}`)
            })
            .finally(() => setLoading(false))

        if (res) {
            const inventoryStatusScore: InventoryStatusScore = res.data
            setInventoryStatusScores((oldScores) => {
                const newScores = [...oldScores]
                let found = false

                for (let i = 0; i < newScores.length; i++) {
                    if (newScores[i].id === inventoryStatusScore.id) {
                        found = true
                        newScores[i] = inventoryStatusScore
                    }
                }

                if (!found) {
                    newScores.push(inventoryStatusScore)
                }

                return newScores
            })
            return inventoryStatusScore
        }

        return null
    }

    return {
        loading,
        inventoryStatusScores,
        fetchInventoryStatusScores,
        updateInventoryStatusScore,
    }
}

export interface InventoryStatusScore {
    id: number
    created_date: string
    inventory_config: number
    custom_status: number
    score: number
}
