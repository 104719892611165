import { BaseUser } from '../Users'
import { Address, BaseModel, IdentifiableObject } from '../types'

export interface BusinessSubClassification {
    id: string
    name: string
}

export interface BusinessClassification {
    _embedded: {
        'industry-classifications': BusinessSubClassification[]
    }
    id: string
    name: string
}

interface Embedded {
    'business-classifications': BusinessClassification[]
}

export interface BusinessClassificationResponse {
    _embedded: Embedded
    total: number
}

export enum DwollaCustomerRegistrationStatus {
    NOT_INITIATED = 'not-initiated',
    VERIFIED = 'verified',
    RETRY = 'retry',
    KBA = 'kba',
    DOCUMENT = 'document',
    SUSPENDED = 'suspended',
}

export enum DwollaBusinessType {
    llc = 'llc',
}

export enum DwollaCustomerType {
    business = 'business',
}

export interface DwollaCustomer extends BaseModel {
    account_admin: BaseUser
    businessClassification: string
    businessName: string
    business_type: DwollaBusinessType
    customer_type: DwollaCustomerType
    doingBusinessAs: string
    dwolla_id: string
    ein: string
    ezturn_company: number
    modified_date: string
    created_date: string
    verification_status: DwollaCustomerRegistrationStatus
    website: string
    default_funding_source: number | null
    certification_status?: 'certified' | 'uncertified'
}

export interface BalanceRes {
    _links: {
        self: {
            href: string
            type: string
            'resource-type': 'string'
        }
        'funding-source': {
            href: string
            type: string
            'resource-type': string
        }
    }
    balance: {
        value: number
        currency: string
    }
    total: {
        value: number
        currency: string
    }
    lastUpdated: string
}

export enum DwollaTransferStatus {
    REQUEST = 'request',
    PROCESSED = 'processed',
    PENDING = 'pending',
    CANCELLED = 'cancelled',
    FAILED = 'failed',
}

export enum DwollaTransferCurrency {
    USD = 'USD',
}

export enum DwollaBankAccountType {
    CHECKING = 'checking',
    SAVINGS = 'savings',
}

export enum DwollaFundingSourceStatus {
    VERIFIED = 'verified',
    UNVERIFIED = 'unverified',
}

export interface DwollaTransfer extends IdentifiableObject {
    dwolla_id: string
    amount_value: number
    amount_currency: DwollaTransferCurrency
    status: DwollaTransferStatus
    source: string
    destination: string
    source_funding_source: number
    destination_funding_source: number
    transfer_logs: DwollaTransferTransitionLog[]
}

export interface DwollaFundingSource extends BaseModel {
    name: string
    dwolla_id: string
    account_type: DwollaBankAccountType
    dwolla_customer: number
    status: DwollaFundingSourceStatus
    micro_deposit_initiated: boolean
}

export interface DwollaBeneficialOwner extends BaseModel {
    dwolla_customer: number
    first_name: string
    last_name: string
    date_of_birth: string
    address: Address
}

export interface DwollaTransferTransitionLog extends BaseModel {
    transfer: number
    from_status: DwollaTransferStatus | null
    to_status: DwollaTransferStatus
    initiated_by: BaseUser | null
}
