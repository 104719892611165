import { Tooltip } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
    children?: string | string[]
}

export const EllipsisSpan = (props: React.PropsWithChildren<Props>) => {
    const ref = useRef<HTMLSpanElement>(null)
    const [truncated, setTruncated] = useState(false)
    useEffect(() => {
        setTruncated(
            (ref.current?.offsetWidth ?? 0) < (ref.current?.scrollWidth ?? 0),
        )
    }, [ref])

    return (
        <Tooltip title={truncated ? props.children ?? '' : ''}>
            <span ref={ref} {...props}>
                {props.children}
            </span>
        </Tooltip>
    )
}
