import React from 'react'
import { Theme } from '@material-ui/core'

interface Props {
    active: boolean
    theme: Theme
    onSelect: () => void
}

const OptionHoc: React.FC<Props> = (props) => {
    return (
        <div
            onClick={props.onSelect}
            style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: props.active
                    ? props.theme.palette.primary.main
                    : 'transparent',
                cursor: 'pointer',
            }}
        >
            {props.children}
        </div>
    )
}

export default OptionHoc
