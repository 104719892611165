import {
    Button,
    Divider,
    FormControlLabel,
    IconButton,
    InputAdornment,
    ListItem,
    Modal,
    Paper,
    Radio,
    RadioGroup,
    Slide,
    Switch,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { AvatarCircle, Container } from '../../components'
import CloseIcon from '@material-ui/icons/Close'
import { axiosInstance } from '../../helpers'
import { ListVendor } from '../../models'
import { toast } from 'react-toastify'
import { createServicesString } from '../Workforce/VendorRow'
import { ListPopover } from '../../components/ListPopover/ListPopover'
import { ReactComponent as Logo } from '../../assets/EZ_Circle_Logo_Icon.svg'
import SearchIcon from '@material-ui/icons/Search'
import { useAppDispatch } from '../../hooks'
import { setVendorList } from '../../store'

interface Props {
    open: boolean
    handleClose: () => void
    vendors: ListVendor[]
}

const SELECT_KEEP_VENDOR = 1
const SELECT_REMOVE_VENDOR = 2

export const MergeVendorsModal = (props: Props) => {
    const { open, handleClose, vendors } = props

    const dispatch = useAppDispatch()

    const theme = useTheme()

    const [vendorToKeep, setVendorToKeep] = useState<ListVendor | null>(null)
    const [vendorToRemove, setVendorToRemove] = useState<ListVendor | null>(
        null,
    )

    const [vendorSelectionStep, setVendorSelectionStep] = useState(
        SELECT_KEEP_VENDOR,
    )

    const [searchText, setSearchText] = useState('')
    const [importedVendorFilter, setImportedVendorFilter] = useState(false)

    useEffect(() => {
        setVendorToKeep(null)
        setVendorToRemove(null)
        setVendorSelectionStep(SELECT_KEEP_VENDOR)
    }, [open])

    const compareVendors = (vendorA: ListVendor, vendorB: ListVendor) => {
        if (vendorA.name > vendorB.name) {
            return 1
        } else if (vendorA.name < vendorB.name) {
            return -1
        } else {
            return 0
        }
    }

    const isDisabled = (vendor: ListVendor) => {
        return (
            (vendorSelectionStep === SELECT_KEEP_VENDOR &&
                vendor === vendorToRemove) ||
            (vendorSelectionStep === SELECT_REMOVE_VENDOR &&
                vendor === vendorToKeep)
        )
    }

    const filterVendors = (vendor: ListVendor) => {
        if (importedVendorFilter) {
            return (
                vendor.name
                    .toLocaleLowerCase()
                    .includes(searchText.toLocaleLowerCase()) && vendor.imported
            )
        }

        return vendor.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
    }

    const VendorCheckbox = (props: { vendor: ListVendor }) => {
        const { vendor } = props

        const label = (
            <Container>
                <Container>{vendor.name}</Container>
                {vendor.imported ? (
                    <Container
                        style={{
                            backgroundColor: 'gold',
                            borderRadius: '5px',
                            padding: theme.spacing(0.5),
                            marginLeft: theme.spacing(1),
                            fontSize: '10px',
                        }}
                    >
                        Imported
                    </Container>
                ) : (
                    <Container
                        style={{
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        <Logo
                            fill={theme.palette.primary.main}
                            style={{
                                height: 25,
                                width: 25,
                            }}
                        />
                    </Container>
                )}
            </Container>
        )

        return (
            <FormControlLabel
                checked={vendorToKeep === vendor || vendorToRemove === vendor}
                disabled={isDisabled(vendor)}
                value={vendor}
                control={<Radio />}
                label={label}
                key={`VENDOR_${vendor.id}`}
                onClick={() => {
                    if (vendorSelectionStep === SELECT_KEEP_VENDOR) {
                        setVendorToKeep(vendor)
                        setVendorSelectionStep(SELECT_REMOVE_VENDOR)
                    } else {
                        setVendorToRemove(vendor)
                    }
                }}
            />
        )
    }

    return (
        <Modal
            open={open}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            onClose={() => {
                handleClose()
            }}
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        display: 'flex',
                        overflow: 'auto',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        outline: 'none',
                        borderRadius: '20px',
                        width: '1200px',
                        padding: theme.spacing(1, 4, 1, 4),
                        height: 'calc(100vh - 150px)',
                    }}
                >
                    <Container
                        style={{
                            padding: theme.spacing(3, 0, 4, 2),
                            flex: 1,
                        }}
                        direction="column"
                    >
                        <Container
                            style={{
                                alignItems: 'center',
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: 500,
                                    fontSize: '20px',
                                    flex: 1,
                                }}
                            >
                                Merge Vendors
                            </span>
                            <Tooltip title="Cancel">
                                <IconButton
                                    onClick={() => {
                                        handleClose()
                                    }}
                                >
                                    <CloseIcon
                                        fontSize="inherit"
                                        style={{
                                            marginRight: theme.spacing(1),
                                            color: theme.palette.darkGreen.main,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Container>
                        <Divider
                            style={{ margin: theme.spacing(0, 3, 0, 0) }}
                        />
                        <Container
                            style={{
                                fontWeight: 500,
                                fontSize: '14px',
                                color: theme.palette.darkGreen.main,
                                margin: theme.spacing(1, 0, 1, 0),
                            }}
                        >
                            {vendorSelectionStep === SELECT_KEEP_VENDOR
                                ? 'Select a vendor to keep'
                                : 'Select a vendor to remove'}
                        </Container>
                        <Container
                            alignItems="center"
                            style={{ margin: theme.spacing(1, 0, 1, 0) }}
                        >
                            <TextField
                                variant="outlined"
                                placeholder="Search"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                style={{
                                    margin: theme.spacing(0, 0, 0, 0),
                                }}
                            />

                            <Container
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginLeft: theme.spacing(1),
                                }}
                            >
                                <Container
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '15px',
                                    }}
                                >
                                    Imported Vendors
                                </Container>
                                <Switch
                                    color="primary"
                                    value={importedVendorFilter}
                                    onChange={() =>
                                        setImportedVendorFilter(
                                            !importedVendorFilter,
                                        )
                                    }
                                    checked={importedVendorFilter}
                                />
                            </Container>
                        </Container>
                        <Container flex={1}>
                            <Container
                                flex={1}
                                style={{
                                    overflowY: 'auto',
                                    maxHeight: 'calc(50vh)',
                                    marginRight: theme.spacing(2),
                                }}
                                direction="row"
                            >
                                <Container direction="column" flex={1}>
                                    <span
                                        style={{
                                            fontWeight: 800,
                                            fontSize: '15px',
                                        }}
                                    >
                                        Select Vendors
                                    </span>

                                    <RadioGroup>
                                        {vendors
                                            .filter(filterVendors)
                                            .sort(compareVendors)
                                            .map((vendor) => (
                                                <VendorCheckbox
                                                    vendor={vendor}
                                                    key={`VENDOR_CHECKBOX_${vendor.id}`}
                                                />
                                            ))}
                                    </RadioGroup>
                                </Container>
                            </Container>
                            <Divider
                                orientation="vertical"
                                flexItem
                                style={{ margin: theme.spacing(0, 2, 0, 0) }}
                            />
                            <Container
                                style={{
                                    flex: 1,
                                    overflowY: 'auto',
                                    maxHeight: 'calc(100vh - 300px)',
                                }}
                                direction="column"
                                alignItems="center"
                            >
                                <Container
                                    direction="column"
                                    style={{ marginBottom: theme.spacing(2) }}
                                >
                                    <Container
                                        style={{
                                            fontWeight: 500,
                                            fontSize: '15px',
                                        }}
                                    >
                                        Vendor To Keep:
                                    </Container>
                                    <VendorCard
                                        vendor={vendorToKeep}
                                        remove={() => setVendorToKeep(null)}
                                        highLight={
                                            vendorSelectionStep ===
                                            SELECT_KEEP_VENDOR
                                        }
                                        onClick={() =>
                                            setVendorSelectionStep(
                                                SELECT_KEEP_VENDOR,
                                            )
                                        }
                                    />
                                </Container>

                                <Container direction="column">
                                    <Container
                                        style={{
                                            fontWeight: 500,
                                            fontSize: '15px',
                                        }}
                                    >
                                        Vendor To Remove:
                                    </Container>
                                    <VendorCard
                                        vendor={vendorToRemove}
                                        remove={() => setVendorToRemove(null)}
                                        highLight={
                                            vendorSelectionStep ===
                                            SELECT_REMOVE_VENDOR
                                        }
                                        onClick={() =>
                                            setVendorSelectionStep(
                                                SELECT_REMOVE_VENDOR,
                                            )
                                        }
                                    />
                                </Container>
                            </Container>
                        </Container>
                        <Container justifyContent="flex-end">
                            <Button
                                variant="outlined"
                                style={{
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                    margin: theme.spacing(2, 0, 2, 0),
                                }}
                                color="secondary"
                                size="small"
                                onClick={() => {
                                    handleClose()
                                }}
                            >
                                Cancel
                            </Button>

                            {vendorToKeep && vendorToRemove && (
                                <Button
                                    variant="contained"
                                    style={{
                                        margin: theme.spacing(2),
                                        backgroundColor: '#008C85',
                                        color: 'white',
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        const message = `Are you sure you want to merge ${vendorToKeep.name} and ${vendorToRemove.name}. ${vendorToRemove.name} will permanently deleted!`

                                        if (confirm(message)) {
                                            const body = {
                                                target: vendorToKeep.id,
                                                source: vendorToRemove.id,
                                            }
                                            axiosInstance
                                                .post(
                                                    'company/vendor/merge_vendors/',
                                                    body,
                                                )
                                                .then(() => {
                                                    toast.success(
                                                        `${vendorToRemove.name} and ${vendorToKeep.name} have been merged`,
                                                    )
                                                    const tempVendorList = vendors.filter(
                                                        (vendor) =>
                                                            vendor.id !==
                                                            vendorToRemove.id,
                                                    )
                                                    dispatch(
                                                        setVendorList(
                                                            tempVendorList,
                                                        ),
                                                    )
                                                    handleClose()
                                                })
                                                .catch((e) => {
                                                    toast.error(
                                                        'Unable to merge vendors',
                                                    )
                                                })
                                        }
                                    }}
                                >
                                    Merge Vendors
                                </Button>
                            )}
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}

interface VendorCardProps {
    vendor: ListVendor | null
    remove: () => void
    highLight?: boolean
    onClick: () => void
}

const VendorCard = (props: VendorCardProps) => {
    const { vendor, remove, highLight, onClick } = props

    const theme = useTheme()

    const [
        anchorElServices,
        setAnchorElServices,
    ] = React.useState<HTMLElement | null>(null)

    const popOverOpenServices = Boolean(anchorElServices)

    const openPopoverServices = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElServices(event.currentTarget)
    }

    const closePopoverServices = () => {
        setAnchorElServices(null)
    }

    return (
        <Container
            style={{
                border: `1px solid black`,
                borderRadius: '5px',
                height: '230px',
                width: '500px',
                borderColor: highLight ? theme.palette.primary.dark : '',
                borderWidth: highLight ? '2px' : '1px',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            {vendor ? (
                <Container
                    direction="column"
                    flex={1}
                    style={{ margin: theme.spacing(1, 1, 2, 2) }}
                >
                    <Container alignItems="center">
                        <AvatarCircle
                            text={vendor.name}
                            initials={true}
                            styles={{
                                container: {
                                    backgroundColor: theme.palette.primary.dark,
                                    marginRight: theme.spacing(1),
                                    height: '40px',
                                    width: '40px',
                                    minHeight: 'auto',
                                    minWidth: 'auto',
                                    fontSize: '14px',
                                },
                            }}
                        />

                        <span
                            style={{
                                fontWeight: 600,
                                fontSize: '18px',
                                flex: 1,
                            }}
                        >
                            {vendor.name}
                        </span>

                        <Tooltip title="Remove">
                            <IconButton
                                onClick={() => {
                                    remove()
                                }}
                            >
                                <CloseIcon
                                    fontSize="inherit"
                                    style={{
                                        marginRight: theme.spacing(1),
                                        color: theme.palette.darkGreen.main,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Container>
                    <Container flex={1} direction="column">
                        <Container
                            flex={1}
                            direction="row"
                            style={{ marginTop: theme.spacing(1) }}
                        >
                            <Container
                                style={{
                                    marginBottom: theme.spacing(1),
                                    fontWeight: 500,
                                    fontSize: '16px',
                                }}
                                direction="column"
                                flex={1}
                            >
                                <Container
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '17px',
                                    }}
                                >
                                    Contact Info:
                                </Container>
                                <Container>{vendor.email}</Container>
                                <Container>{vendor.phone}</Container>
                            </Container>

                            <Container
                                style={{
                                    margin: theme.spacing(1, 3, 0, 0),
                                    fontWeight: 500,
                                    fontSize: '16px',
                                }}
                                direction="column"
                                alignItems="flex-end"
                                flex={1}
                            >
                                <Container
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '17px',
                                    }}
                                >
                                    Address:
                                </Container>
                                <Container>{vendor.address}</Container>
                                <Container>
                                    {vendor.city}, {vendor.state}{' '}
                                    {vendor.zip_code}
                                </Container>
                            </Container>
                        </Container>

                        <Container
                            flex={1}
                            style={{ margin: theme.spacing(1, 3, 0, 0) }}
                        >
                            <Container direction="column" flex={1}>
                                <Container
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '17px',
                                    }}
                                >
                                    Services:
                                </Container>
                                <Container
                                    style={{
                                        alignItems: 'center',
                                    }}
                                >
                                    <span style={{ flex: 1 }}>
                                        {createServicesString(vendor)}
                                    </span>

                                    {vendor.services.length > 3 ? (
                                        <>
                                            <Container>
                                                <Button
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        openPopoverServices(e)
                                                    }}
                                                    size="small"
                                                    style={{
                                                        marginRight: theme.spacing(
                                                            2,
                                                        ),
                                                    }}
                                                >
                                                    +
                                                    {vendor.services.length - 3}
                                                </Button>
                                            </Container>
                                            <ListPopover
                                                open={popOverOpenServices}
                                                anchorEl={anchorElServices}
                                                onClose={closePopoverServices}
                                                anchorOrigin={{
                                                    horizontal: -50,
                                                    vertical: 'bottom',
                                                }}
                                            >
                                                {vendor.services
                                                    .slice(3)
                                                    .map((service) => {
                                                        return (
                                                            <ListItem
                                                                key={`SERVICE_ID_${service.id}`}
                                                            >
                                                                {service.name}
                                                            </ListItem>
                                                        )
                                                    })}
                                            </ListPopover>
                                        </>
                                    ) : (
                                        <div />
                                    )}
                                </Container>
                            </Container>

                            <Container
                                style={{
                                    marginBottom: theme.spacing(1),
                                    fontWeight: 500,
                                    fontSize: '16px',
                                }}
                                alignItems="flex-end"
                                flex={1}
                                direction="column"
                            >
                                <Container
                                    style={{
                                        fontWeight: 600,
                                        fontSize: '17px',
                                    }}
                                >
                                    Type:
                                </Container>
                                <Container>
                                    {vendor.in_house
                                        ? 'In House'
                                        : 'Contractor'}
                                </Container>
                            </Container>
                        </Container>
                    </Container>
                </Container>
            ) : (
                <Container
                    alignItems="center"
                    justifyContent="center"
                    flex={1}
                    style={{ fontWeight: 500, fontSize: '15px' }}
                >
                    No Selection
                </Container>
            )}
        </Container>
    )
}
