import { DateFilters } from '../../containers/VendorScheduler/types'
import { WorkorderResponse } from '../../store'
import { _Workorder } from '../WorkOrder'

export const getDateRange = (dateFilter: DateFilters) => {
    // takes in a date filter and returns the lte and the gte for the filter
    //declare our dates
    const today = new Date()
    const tomorrow = new Date(today)
    const gteDate = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    let lte: string | undefined = undefined
    let gte: string | undefined = undefined
    switch (dateFilter) {
        case 'Today':
            // make lte end of today
            today.setHours(23, 59, 59, 999)
            lte = today.toISOString()
            gteDate.setMonth(gteDate.getMonth() - 6)
            gte = gteDate.toISOString()
            break
        case 'Tomorrow':
            // make lte end of tomorrow and gte start of tomorrow
            tomorrow.setHours(0, 0, 0, 0)
            gte = tomorrow.toISOString()
            tomorrow.setHours(23, 59, 59, 999)
            lte = tomorrow.toISOString()
            break
        case 'Upcoming':
            // make gte end of tomorrow
            tomorrow.setHours(0, 0, 0, 0)
            gte = tomorrow.toISOString()
    }

    return { lte_date: lte, gte_date: gte }
}

export const sortVendorWorkorder = (woList: WorkorderResponse[]) => {
    // sorts a list of workorders first by priority, then date
    const finalWoList: WorkorderResponse[] = []

    const woListNoPriority: WorkorderResponse[] = []

    //seperate the priority wo's and the non priority wo's
    woList.forEach((wo) => {
        if (wo.priority) {
            finalWoList.push(wo)
        } else {
            woListNoPriority.push(wo)
        }
    })

    // the js .sort function preforms quicksort on any date set larger than 10, other wise it preforms an insertion sort

    //sort the priority by date
    finalWoList.sort((a, b) =>
        new Date(a.start_date) < new Date(b.start_date) ? -1 : 1,
    )
    //sort the non priority by date
    woListNoPriority.sort((a, b) =>
        new Date(a.start_date) < new Date(b.start_date) ? -1 : 1,
    )

    //append the list of non-priority to the list of priority wo's
    woListNoPriority.forEach((wo) => finalWoList.push(wo))

    return finalWoList
}
