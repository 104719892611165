import React, { useEffect, useState } from 'react'
import { Container, HeadCell, RowData, SimpleTable } from '../../components'
import { axiosInstance } from '../../helpers'
import { TableCell, TableRow, useTheme } from '@material-ui/core'
import { useStyles } from '../../styles'
import { useHistory } from 'react-router-dom'
import { useAppDispatch } from '../../hooks'
import { ActionItemInspectionStatusTrigger } from '../../models'
import { StringLiteral } from 'typescript'

interface Props {
    triggerList: ActionItemInspectionStatusTrigger[] | null
    selectedTriggerId: number
    onSelectTrigger: (id: number) => void
}

export const ActionItemTriggerTable = (props: Props) => {
    const { triggerList } = props

    const theme = useTheme()
    const classes = useStyles()

    const dispatch = useAppDispatch()

    const headCellStyle: React.CSSProperties = {
        ...theme.typography.h6,
        fontWeight: theme.typography.fontWeightMedium,
    }

    const headCells: HeadCell<TriggerRow>[] = [
        {
            disablePadding: false,
            align: 'left',
            id: 'status',
            label: 'Status',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'inventory',
            label: 'Inventory',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'title',
            label: 'Title',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'service',
            label: 'Service',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'assign_to',
            label: 'Assign To',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'buffer',
            label: 'Buffer (days)',
            style: headCellStyle,
        },
        {
            disablePadding: false,
            align: 'left',
            id: 'days_to_complete',
            label: 'Duration (days)',
            style: headCellStyle,
        },
    ]

    const createRow = (
        trigger: ActionItemInspectionStatusTrigger,
    ): TriggerRow => {
        let assignToStr = 'Assign Later'
        let assignToSortVal = '-1'
        console.log(trigger)
        if (trigger.assign_to_company) {
            assignToStr = trigger.assign_to_company.name
            assignToSortVal = assignToStr
        }

        if (trigger.assign_to_user) {
            assignToStr = trigger.assign_to_user.name
            assignToSortVal = assignToStr
        }

        return {
            status: {
                value: trigger.trigger_status.name,
                sortValue: trigger.title ?? '',
            },
            inventory: {
                value: trigger.inventory_config?.name ?? 'All',
                sortValue: trigger.title ?? '-1',
            },
            title: {
                value: trigger.title ?? '',
                sortValue: trigger.title ?? '',
            },
            service: {
                value: trigger.service?.name ?? 'None',
                sortValue: trigger.service?.name ?? '-1',
            },
            assign_to: {
                value: assignToStr,
                sortValue: assignToSortVal,
            },
            buffer: {
                value: trigger.start_date_buffer_days,
                sortValue: trigger.start_date_buffer_days,
            },
            days_to_complete: {
                value: trigger.days_to_complete,
                sortValue: trigger.days_to_complete,
            },
            trigger: {
                value: trigger,
                sortValue: '',
            },
        }
    }

    return (
        <SimpleTable<TriggerRow>
            rows={triggerList?.map(createRow) ?? []}
            render={(row) => {
                const rowStyle: React.CSSProperties = {
                    cursor: 'pointer',
                }

                if (row.trigger.value.id === props.selectedTriggerId) {
                    rowStyle.backgroundColor = theme.palette.primary.light
                }

                return (
                    <TableRow
                        className={classes.hoverGrey200}
                        style={rowStyle}
                        key={`TRIGGER_ROW_${row.trigger.value.id}`}
                        onClick={() => {
                            props.onSelectTrigger(row.trigger.value.id)
                        }}
                    >
                        <TableCell>{row.status.value}</TableCell>
                        <TableCell>{row.inventory.value}</TableCell>
                        <TableCell>{row.title.value}</TableCell>
                        <TableCell>{row.service.value}</TableCell>
                        <TableCell>{row.assign_to.value}</TableCell>
                        <TableCell>{row.buffer.value}</TableCell>
                        <TableCell>{row.days_to_complete.value}</TableCell>
                    </TableRow>
                )
            }}
            orderByDefault="status"
            headCells={headCells}
        />
    )
}

interface TriggerRow extends RowData {
    status: {
        value: string
        sortValue: string
    }
    inventory: {
        value: string
        sortValue: string
    }
    title: {
        value: string
        sortValue: string
    }
    service: {
        value: string
        sortValue: string
    }
    assign_to: {
        value: string
        sortValue: string
    }
    buffer: {
        value: number
        sortValue: number
    }
    days_to_complete: {
        value: number
        sortValue: number
    }
    trigger: {
        value: ActionItemInspectionStatusTrigger
        sortValue: string
    }
}
