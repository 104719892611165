import React, { useEffect, useState } from 'react'
import { Container } from '../../../components'
import {
    IconButton,
    Modal,
    Paper,
    Slide,
    Step,
    StepLabel,
    Stepper,
    useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { InspectionDetailStep } from './InspectionDetailStep'
import { Inspection, InspectionType, Schedule, User } from '../../../models'
import { CreateInspectionAxiosReq, createInspection } from '../../../store'
import { useAppDispatch } from '../../../hooks'
import { toast } from 'react-toastify'
import { AssignInspectionStep } from './AssignInspectionStep'

interface Props {
    open: boolean
    scheduleList: Schedule[]
    inspectionTypeList: InspectionType[]
    userList: User[]
    onClose: () => void
}

export const CreateInspectionModal = (props: Props) => {
    const { open, onClose, scheduleList, inspectionTypeList, userList } = props

    const [
        createdInspection,
        setCreatedInspection,
    ] = useState<Inspection | null>(null)

    const activeStep = createdInspection === null ? 0 : 1

    const theme = useTheme()

    const dispatch = useAppDispatch()

    const handleCreate = (
        name: string,
        startDate: Date,
        endDate: Date,
        inspectionTypeId: number,
        scheduleId: number,
    ) => {
        const req: CreateInspectionAxiosReq = {
            body: {
                name: name,
                start_date: startDate.toISOString(),
                end_date: endDate.toISOString(),
                inspection_type: inspectionTypeId,
            },
        }
        if (scheduleId !== -1) {
            req.body.schedule = scheduleId
        }

        dispatch(createInspection(req))
            .then((res) => {
                setCreatedInspection(res.data)
            })
            .catch(() => {
                toast.error('There was a problem creating your inspection')
            })
    }

    useEffect(() => {
        if (!open) {
            setCreatedInspection(null)
        }
    }, [open])

    return (
        <Modal
            open={open}
            onClose={onClose}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Slide direction="up" in={open}>
                <Paper style={{}}>
                    {/* Modal Root */}
                    <Container
                        style={{
                            flexDirection: 'column',
                            width: 'calc(100vw - 200px)',
                            maxWidth: 'calc(100vw - 200px)',
                            height: 'calc(100vw - 200px)',
                            maxHeight: 'calc(100vh - 200px)',
                            flex: 1,
                            overflow: 'hidden',
                        }}
                    >
                        {/* Header */}
                        <Container
                            style={{
                                padding: theme.spacing(2),
                                borderBottom: `1px solid ${theme.palette.grey[400]}`,
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.h5,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Create a new Inspection
                            </span>

                            <div style={{ flex: 1 }} />

                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Container>
                        {/* Body */}

                        <Container
                            style={{
                                flex: 1,
                                overflow: 'hidden',
                                borderBottom: `1px solid ${theme.palette.grey[400]}`,
                            }}
                        >
                            {createdInspection === null && (
                                <InspectionDetailStep
                                    theme={theme}
                                    scheduleList={scheduleList}
                                    inspectionTypeList={inspectionTypeList}
                                    completeStep={(
                                        name,
                                        startDate,
                                        endDate,
                                        inspectionTypeId,
                                        scheduleId,
                                    ) => {
                                        handleCreate(
                                            name,
                                            startDate,
                                            endDate,
                                            inspectionTypeId,
                                            scheduleId,
                                        )
                                    }}
                                />
                            )}

                            {createdInspection !== null && (
                                <AssignInspectionStep
                                    theme={theme}
                                    inspection={createdInspection}
                                    userList={userList}
                                    onClose={onClose}
                                />
                            )}
                        </Container>

                        {/* Footer */}
                        <Container
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Stepper activeStep={activeStep}>
                                <Step completed={activeStep > 0}>
                                    <StepLabel>Inspection Details</StepLabel>
                                </Step>

                                <Step completed={activeStep > 1}>
                                    <StepLabel>Assign Units</StepLabel>
                                </Step>
                            </Stepper>
                        </Container>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
