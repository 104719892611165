import React from 'react'
import { Container } from '../../components'
import { Button, useTheme } from '@material-ui/core'
import { RecommendedVendor } from './CreateEZNowModal'
import { BidStatus, Bid, ModelMap, RFP, convertListToMap } from '../../models'
import { AccessTime } from '@material-ui/icons'
import { CountdownTimer } from '../../components/CountdownTimer'
import moment from 'moment'

interface Props {
    recommendedVendors: RecommendedVendor[]
    rfp: RFP
    selectedBid: Bid | null
    requireSelection: boolean
    onSelectBid: (bid: Bid) => void
    closeEZNowRequest: () => void
}

export const EZNowVendorList = (props: Props) => {
    const {
        recommendedVendors,
        rfp,
        onSelectBid,
        selectedBid,
        requireSelection,
        closeEZNowRequest,
    } = props

    const theme = useTheme()

    const recommendedVendorsMap = convertListToMap(recommendedVendors)

    let numberResponsesReceived = 0
    rfp.bids.forEach((bid) => {
        if (bid.status !== BidStatus.INVITATION) {
            numberResponsesReceived = numberResponsesReceived + 1
        }
    })

    const numberOfVendors = rfp.bids.length ?? 0

    const endDate = moment(new Date(rfp.created_date)).add(30, 'm').toDate()

    return (
        <Container
            style={{ margin: theme.spacing(0, 1, 0, 1), height: '80%' }}
            flex={1}
            direction="column"
        >
            <Container style={{ marginBottom: theme.spacing(1) }}>
                <Container
                    style={{
                        ...theme.typography.h5,
                    }}
                >
                    {numberResponsesReceived} of {numberOfVendors} Received
                </Container>
                <Container flex={1} />
                {requireSelection ? (
                    <Container>
                        <Container
                            style={{
                                ...theme.typography.subtitle1,
                                color: 'red',
                                marginRight: theme.spacing(2),
                            }}
                        >
                            Time for Vendor responses has expired! Please select
                            a vendor or close the EZNow request.
                        </Container>
                        <Container flex={1} />
                        <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            style={{ textTransform: 'none' }}
                            onClick={() => closeEZNowRequest()}
                        >
                            Close Request
                        </Button>
                    </Container>
                ) : (
                    <Container style={{ alignItems: 'center' }}>
                        <Container
                            style={{
                                ...theme.typography.h6,
                                fontWeight: 600,
                                alignItems: 'center',
                            }}
                        >
                            <AccessTime
                                style={{ marginRight: theme.spacing(1) }}
                            />
                            Time Left:
                        </Container>
                        <Container style={{ marginLeft: theme.spacing(1) }}>
                            <CountdownTimer
                                endDate={endDate.toISOString()}
                                setExpired={() => {}}
                            />
                        </Container>
                    </Container>
                )}
            </Container>
            <Container
                style={{
                    padding: theme.spacing(0, 2, 1, 2),
                }}
            >
                <Container
                    flex={3}
                    style={{
                        ...theme.typography.subtitle1,
                    }}
                >
                    Vendor
                </Container>
                <Container
                    flex={2}
                    style={{
                        ...theme.typography.subtitle1,
                    }}
                >
                    Compatibility Rating
                </Container>
                <Container
                    flex={2}
                    style={{
                        ...theme.typography.subtitle1,
                    }}
                >
                    Price Rating
                </Container>
                <Container
                    flex={1}
                    style={{
                        ...theme.typography.subtitle1,
                    }}
                >
                    Price
                </Container>
                <Container
                    flex={1}
                    style={{
                        ...theme.typography.subtitle1,
                    }}
                >
                    Status
                </Container>
                <Container
                    flex={1}
                    style={{
                        ...theme.typography.subtitle1,
                    }}
                />
            </Container>
            <Container
                direction="column"
                style={{
                    overflowY: 'auto',
                    maxHeight: '80%',
                    height: '80%',
                }}
            >
                {rfp.bids
                    .sort((bidA, bidB) =>
                        sortVendors(bidA, bidB, recommendedVendorsMap),
                    )
                    .map((bid) => {
                        return (
                            <EZNowVendorRow
                                key={`EZNOW_VENDOR_ROW_${bid.id}`}
                                bid={bid}
                                recommendedVendorsMap={recommendedVendorsMap}
                                isSelectedBid={bid.id === selectedBid?.id}
                                onSelectBid={onSelectBid}
                            />
                        )
                    })}
            </Container>
        </Container>
    )
}

interface VendorRowProps {
    bid: Bid
    recommendedVendorsMap: ModelMap<RecommendedVendor>
    isSelectedBid: boolean
    onSelectBid: (bid: Bid) => void
}

const EZNowVendorRow = (props: VendorRowProps) => {
    const { recommendedVendorsMap, bid, isSelectedBid, onSelectBid } = props

    const theme = useTheme()

    let pricing = 'NA'
    const priceRating = recommendedVendorsMap[bid.vendor.id]?.price_rating
    if (priceRating) {
        pricing = '$'
        if (priceRating === 2) {
            pricing = '$$'
        } else if (priceRating === 3) {
            pricing = '$$$'
        } else if (priceRating === 4) {
            pricing = '$$$$'
        }
    }

    let status = 'Invited'
    if (bid.status === BidStatus.DECLINED) {
        status = 'Declined'
    } else if (
        bid.status === BidStatus.SUBMITTED ||
        bid.status === BidStatus.REVIEWED
    ) {
        status = 'Ready'
    }

    return (
        <Container
            style={{
                padding: theme.spacing(2),
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: '20px',
                marginBottom: theme.spacing(2),
                backgroundColor: isSelectedBid
                    ? theme.palette.grey[300]
                    : theme.palette.grey[100],
                alignItems: 'center',
                minHeight: '65px',
            }}
        >
            <Container
                flex={3}
                style={{
                    ...theme.typography.body2,
                }}
            >
                {bid.vendor.name}
            </Container>
            <Container
                flex={2}
                style={{
                    ...theme.typography.body2,
                }}
            >
                {recommendedVendorsMap[bid.vendor.id]?.performance_rating ??
                    'NA'}
            </Container>
            <Container
                flex={2}
                style={{
                    ...theme.typography.body2,
                }}
            >
                {pricing}
            </Container>
            <Container
                flex={1}
                style={{
                    ...theme.typography.body2,
                }}
            >
                {bid.group_bid_price ? `$${bid.group_bid_price}` : ''}
            </Container>
            <Container
                flex={1}
                style={{
                    ...theme.typography.body2,
                }}
            >
                {status}
            </Container>
            <Container
                flex={1}
                style={{
                    ...theme.typography.body2,
                }}
            >
                {(bid.status === BidStatus.SUBMITTED ||
                    bid.status === BidStatus.REVIEWED) && (
                    <Button
                        variant="outlined"
                        size="small"
                        style={{
                            textTransform: 'none',
                            backgroundColor: theme.palette.primary.dark,
                            color: 'white',
                            alignSelf: 'flex-end',
                        }}
                        disabled={isSelectedBid}
                        onClick={() => onSelectBid(bid)}
                    >
                        {isSelectedBid ? 'Selected' : 'Select'}
                    </Button>
                )}
            </Container>
        </Container>
    )
}

const sortVendors = (
    bidA: Bid,
    bidB: Bid,
    recommendedVendorsMap: ModelMap<RecommendedVendor>,
) => {
    const vendorAPerformanceRating =
        recommendedVendorsMap[bidA.vendor.id]?.performance_rating
    const vendorBPerformanceRating =
        recommendedVendorsMap[bidB.vendor.id]?.performance_rating
    if (vendorAPerformanceRating && vendorBPerformanceRating) {
        if (vendorAPerformanceRating > vendorBPerformanceRating) {
            return -1
        } else if (vendorAPerformanceRating < vendorBPerformanceRating) {
            return 1
        }
        return 0
    } else if (vendorAPerformanceRating) {
        return -1
    } else if (vendorBPerformanceRating) {
        return 1
    }
    return 2
}
