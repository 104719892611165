import React, { useState } from 'react'
import {
    ButtonPopover,
    Container,
    StatusBadge,
    IOption,
} from '../../../components'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Button,
    IconButton,
    Tooltip,
    useTheme,
    TextField,
} from '@material-ui/core'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import { DwollaFundingSource, DwollaFundingSourceStatus } from '../../../models'
import { toMMDDYYYY } from '../../../helpers'
import { usePopover } from '../../../hooks/usePopover'
import StarIcon from '@material-ui/icons/Star'
import { validateTextFieldNum } from '../../../helpers/TextInputHelpers'
import { dwollaApi } from '../../../contexts'

interface Props {
    fundingSource: DwollaFundingSource
    isDefault?: boolean
}

const setAmount = (
    value: string,
    dispatch: React.Dispatch<React.SetStateAction<string>>,
) => {
    if (validateTextFieldNum(value, 2)) {
        dispatch(value)
    }
}

export const FundingSourceRow = (props: Props) => {
    const theme = useTheme()

    const {
        dwollaCustomer,
        initiateMicroDeposits,
        setDefaultFundingSource,
        verifyFundingSource,
        deleteFundingSource,
        editFundingSource,
    } = dwollaApi()

    const [validateAmount1, setValidateAmount1] = useState('0.00')
    const [validateAmount2, setValidateAmount2] = useState('0.00')

    const [editName, setEditName] = useState('')

    const [verifyDialogOpen, setVerifyDialogOpen] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [editDialogOpen, setEditDialogOpen] = useState(false)

    const { fundingSource } = props

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    const createdDate = new Date(fundingSource.created_date)

    const popoverOptions: IOption[] = [
        {
            title: (
                <Container
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                            marginRight: theme.spacing(1),
                        }}
                    >
                        Edit
                    </span>
                    <EditIcon />
                </Container>
            ),
            color: 'primary',
            onClick: () => {
                handleClose()
                setEditDialogOpen(true)
            },
        },
        {
            title: (
                <Container
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                            marginRight: theme.spacing(1),
                        }}
                    >
                        Default
                    </span>
                    <StarIcon />
                </Container>
            ),
            color: 'warning',
            onClick: () => {
                handleClose()
                setDefaultFundingSource(fundingSource, dwollaCustomer)
            },
        },
        {
            title: (
                <Container
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                            marginRight: theme.spacing(1),
                        }}
                    >
                        Delete
                    </span>
                    <DeleteForeverIcon />
                </Container>
            ),
            color: 'danger',
            onClick: () => {
                handleClose()
                setDeleteDialogOpen(true)
            },
        },
        {
            title: 'Close',
            color: 'secondary',
            onClick: () => {
                handleClose()
            },
        },
    ]

    if (fundingSource.status !== DwollaFundingSourceStatus.VERIFIED) {
        if (fundingSource.micro_deposit_initiated) {
            popoverOptions.unshift({
                title: 'Verify micro-deposits',
                color: 'success',
                onClick: () => {
                    handleClose()
                    setVerifyDialogOpen(true)
                },
            })
        } else {
            popoverOptions.unshift({
                title: 'Initiate micro-deposits',
                color: 'primary',
                onClick: () => {
                    handleClose()
                    initiateMicroDeposits(fundingSource)
                },
            })
        }
    }

    let statusText: string = fundingSource.status as string

    const badgeContainerStyle: React.CSSProperties = {
        backgroundColor: 'transparent',
    }

    const badgeTextStyle: React.CSSProperties = {
        backgroundColor: 'transparent',
    }

    if (fundingSource.status === DwollaFundingSourceStatus.UNVERIFIED) {
        badgeContainerStyle.border = `1px solid red`
        badgeTextStyle.color = 'red'

        if (fundingSource.micro_deposit_initiated) {
            statusText = 'pending'
            badgeContainerStyle.border = `1px solid #fcbe03`
            badgeTextStyle.color = '#fcbe03'
        }
    } else if (fundingSource.status === DwollaFundingSourceStatus.VERIFIED) {
        badgeContainerStyle.border = `1px solid green`
        badgeTextStyle.color = 'green'
    }

    return (
        <Container
            style={{
                border: `1px solid ${theme.palette.grey[700]}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(2),
                margin: 8,
                height: 100,
                width: 'calc(50% - 16px)',
                alignItems: 'center',
            }}
        >
            <Container style={{ flex: 1, flexDirection: 'column' }}>
                <Container>
                    <span
                        style={{
                            ...theme.typography.body1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {fundingSource.name}
                    </span>

                    {props.isDefault && (
                        <Tooltip title="Default">
                            <StarIcon
                                style={{ marginLeft: theme.spacing(2) }}
                                htmlColor="#fcbe03"
                            />
                        </Tooltip>
                    )}
                </Container>

                <span>
                    {fundingSource.account_type} | Added:{' '}
                    {toMMDDYYYY(createdDate)}
                </span>
            </Container>

            <Container
                style={{
                    // flex: 1,
                    justifyContent: 'center',
                }}
            >
                <StatusBadge
                    text={statusText}
                    customStyle={{
                        container: badgeContainerStyle,
                        text: badgeTextStyle,
                    }}
                />
            </Container>

            <Container>
                <IconButton onClick={handleOpen}>
                    <MoreVertIcon fontSize="small" />
                </IconButton>
            </Container>

            <ButtonPopover
                open={isOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                onClose={() => {
                    handleClose()
                }}
                options={popoverOptions}
                containerSyle={{ width: 200 }}
            />

            <Dialog
                open={verifyDialogOpen}
                onClose={() => setVerifyDialogOpen(false)}
            >
                <DialogTitle>Verify {fundingSource.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Enter the amounts deposited in the entered account
                    </DialogContentText>

                    <TextField
                        label="Amount 1"
                        fullWidth
                        prefix="$"
                        value={validateAmount1}
                        style={{ marginTop: theme.spacing(2) }}
                        onChange={(e) => {
                            setAmount(e.target.value, setValidateAmount1)
                        }}
                    />
                    <TextField
                        label="Amount 2"
                        fullWidth
                        prefix="$"
                        value={validateAmount2}
                        style={{ marginTop: theme.spacing(2) }}
                        onChange={(e) => {
                            setAmount(e.target.value, setValidateAmount2)
                        }}
                    />
                </DialogContent>
                <DialogActions style={{ marginTop: theme.spacing(2) }}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => setVerifyDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            verifyFundingSource(
                                fundingSource,
                                validateAmount1,
                                validateAmount2,
                            ).then(() => {
                                handleClose()
                                setVerifyDialogOpen(false)
                            })
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Delete {fundingSource.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete {fundingSource.name}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ marginTop: theme.spacing(2) }}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => setDeleteDialogOpen(false)}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            setDeleteDialogOpen(false)
                            deleteFundingSource(fundingSource)
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={editDialogOpen}
                onClose={() => {
                    setEditName('')
                    setEditDialogOpen(false)
                }}
            >
                <DialogTitle>Edit {fundingSource.name}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name"
                        fullWidth
                        value={editName}
                        style={{ marginTop: theme.spacing(2) }}
                        onChange={(e) => {
                            setEditName(e.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions style={{ marginTop: theme.spacing(2) }}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                            setEditName('')
                            setEditDialogOpen(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <div style={{ flex: 1 }} />
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            setEditDialogOpen(false)
                            editFundingSource(fundingSource, { name: editName })
                            setEditName('')
                        }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}
