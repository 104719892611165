import { Theme } from '@material-ui/core'
import React from 'react'
import {
    DDLease,
    DDLeaseMatchStatus,
    MatchedLease,
} from '../../../../../models'
import { Container, Droppable } from '../../../../../components'
import { DDLeaseCard } from './DDLeaseCard'
import { toMMDDYYYY } from '../../../../../helpers'
import { MatchedPDFLeaseCard } from './MatchedPDFLeaseCard'

interface MatchedLeaseRowProps {
    theme: Theme
    matchedLease: MatchedLease
    onDrop: (droppedId: number) => void
    unmatchLeases: (leaseMatchId: number) => void
    onOpenLeaseModal: (lease: DDLease) => void
    onOpenLeasePDFModal: (lease: DDLease) => void
    onOpenPromptResultsModal: (lease: DDLease) => void
}

export const MatchedLeaseRow = (props: MatchedLeaseRowProps) => {
    const {
        matchedLease,
        theme,
        unmatchLeases,
        onOpenLeaseModal,
        onOpenLeasePDFModal,
        onOpenPromptResultsModal,
    } = props

    let border = `1px solid ${theme.palette.lightGrey.main}`
    if (matchedLease.status === DDLeaseMatchStatus.SUCCESS) {
        border = `2px solid ${theme.palette.primary.main}`
    } else if (matchedLease.status === DDLeaseMatchStatus.DISCREPANCY) {
        border = `2px solid ${theme.palette.error.main}`
    }

    let tenantMatch = !matchedLease.pdf_lease
    if (matchedLease.rent_roll_lease.tenant && matchedLease.pdf_lease?.tenant) {
        tenantMatch =
            matchedLease.rent_roll_lease.tenant
                .toLocaleLowerCase()
                .includes(matchedLease.pdf_lease.tenant.toLocaleLowerCase()) ||
            matchedLease.pdf_lease.tenant
                .toLocaleLowerCase()
                .includes(
                    matchedLease.rent_roll_lease.tenant.toLocaleLowerCase(),
                )
    }

    let locationMatch = !matchedLease.pdf_lease
    if (matchedLease.rent_roll_lease.unit && matchedLease.pdf_lease?.unit) {
        locationMatch =
            matchedLease.rent_roll_lease.unit
                .toLocaleLowerCase()
                .includes(matchedLease.pdf_lease.unit.toLocaleLowerCase()) ||
            matchedLease.pdf_lease.unit
                .toLocaleLowerCase()
                .includes(matchedLease.rent_roll_lease.unit.toLocaleLowerCase())
    }

    const rentMatch =
        !matchedLease.pdf_lease ||
        matchedLease.rent_roll_lease.rent === matchedLease.pdf_lease.rent

    let startDateMatch = true
    if (
        matchedLease.pdf_lease?.lease_start &&
        matchedLease.rent_roll_lease.lease_start
    ) {
        const rentRollStartDate = new Date(
            matchedLease.rent_roll_lease.lease_start,
        )
        rentRollStartDate.setTime(
            rentRollStartDate.getTime() +
                rentRollStartDate.getTimezoneOffset() * 60 * 1000,
        )
        const pdfStartDate = new Date(matchedLease.pdf_lease.lease_start)
        pdfStartDate.setTime(
            pdfStartDate.getTime() +
                pdfStartDate.getTimezoneOffset() * 60 * 1000,
        )

        startDateMatch =
            toMMDDYYYY(rentRollStartDate) === toMMDDYYYY(pdfStartDate)
    }

    let endDateMatch = true
    if (
        matchedLease.pdf_lease?.lease_end &&
        matchedLease.rent_roll_lease.lease_end
    ) {
        const rentRollEndDate = new Date(matchedLease.rent_roll_lease.lease_end)
        rentRollEndDate.setTime(
            rentRollEndDate.getTime() +
                rentRollEndDate.getTimezoneOffset() * 60 * 1000,
        )
        const pdfEndDate = new Date(matchedLease.pdf_lease.lease_end)
        pdfEndDate.setTime(
            pdfEndDate.getTime() + pdfEndDate.getTimezoneOffset() * 60 * 1000,
        )

        endDateMatch = toMMDDYYYY(rentRollEndDate) === toMMDDYYYY(pdfEndDate)
    }

    return (
        <Container
            style={{
                border: `1px solid ${theme.palette.lightGrey.main}`,
                borderRadius: theme.shape.borderRadius,
                margin: theme.spacing(1),
            }}
        >
            <Container
                style={{
                    flex: 1,
                    border: border,
                    borderRadius: theme.shape.borderRadius,
                    margin: theme.spacing(1),
                }}
            >
                <DDLeaseCard
                    theme={theme}
                    lease={matchedLease.rent_roll_lease}
                    tenantMatch={tenantMatch}
                    rentMatch={rentMatch}
                    startDateMatch={startDateMatch}
                    endDateMatch={endDateMatch}
                    locationMatch={locationMatch}
                    onOpenLeaseModal={onOpenLeaseModal}
                />
            </Container>
            {matchedLease.pdf_lease ? (
                <Container
                    style={{
                        flex: 1,
                        border: border,
                        borderRadius: theme.shape.borderRadius,
                        margin: theme.spacing(1),
                    }}
                >
                    <MatchedPDFLeaseCard
                        matchedLease={matchedLease}
                        theme={theme}
                        pdfLease={matchedLease.pdf_lease}
                        tenantMatch={tenantMatch}
                        rentMatch={rentMatch}
                        startDateMatch={startDateMatch}
                        endDateMatch={endDateMatch}
                        locationMatch={locationMatch}
                        unmatchLeases={unmatchLeases}
                        onOpenLeaseModal={onOpenLeaseModal}
                        onOpenLeasePDFModal={onOpenLeasePDFModal}
                        onOpenPromptResultsModal={onOpenPromptResultsModal}
                    />
                </Container>
            ) : (
                <Droppable
                    onDrop={(e) => {
                        const droppedIdStr = e.dataTransfer.getData(
                            'text/plain',
                        )
                        const droppedId = Number(droppedIdStr)
                        props.onDrop(droppedId)
                    }}
                    dragOverStyle={{
                        border: `1px solid ${theme.palette.primary.main}`,
                        boxShadow: theme.shadows[1],
                    }}
                    style={{
                        border: `1px solid ${theme.palette.lightGrey.main}`,
                        borderRadius: theme.shape.borderRadius,
                        margin: theme.spacing(1),
                        flex: 1,
                    }}
                >
                    <Container
                        style={{
                            flex: 1,
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.subtitle2,
                                color: theme.palette.grey[700],
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            Drag and Drop Your Unmatched PDF Lease here
                        </span>
                    </Container>
                </Droppable>
            )}
        </Container>
    )
}
