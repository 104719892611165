import { Button, MenuItem, TextField, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { axiosInstance, statesList } from '../../helpers'
import { Container } from '../Container'
import { Company, IdentifiableNamedObject } from '../../models'
import { toast } from 'react-toastify'
import { Selector } from '../Selector'

interface SubmitData {
    name: string
    address: string
    city: string
    state: string
    zip: string
    organization_id: number
}

interface Props {
    apartment?: Company
    submitButtonText?: string
    onSubmit: (data: SubmitData) => void
}

export const ApartmentDetailForm = (props: Props) => {
    const theme = useTheme()

    const inputStyle: React.CSSProperties = {
        marginBottom: theme.spacing(2),
    }

    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [zip, setZip] = useState('')
    const [organizationList, setOrganizationList] = useState<
        IdentifiableNamedObject[] | null
    >(null)

    const [selectedOrgId, setSelecetedOrgId] = useState(-1)

    useEffect(() => {
        axiosInstance
            .get('company/organization/')
            .then((res) => {
                setOrganizationList(res.data)
            })
            .catch((e) => toast.error(e))

        if (props.apartment) {
            setName(props.apartment.name)
            setAddress(props.apartment.address)
            setCity(props.apartment.city)
            setState(props.apartment.state)
            setZip(props.apartment.zip_code)

            console.log(props.apartment.organization)
            setSelecetedOrgId(props.apartment.organization)
        } else {
            setName('')
            setAddress('')
            setCity('')
            setState('')
            setZip('')
            setSelecetedOrgId(-1)
        }
    }, [props.apartment])

    let title = 'Onboard new apartment'
    if (props.apartment) {
        title = `Resume ${props.apartment.name}`
    }

    return (
        <Container
            style={{
                flex: 1,
                padding: theme.spacing(2),
                flexDirection: 'column',
                overflow: 'scroll',
            }}
        >
            <span
                style={{
                    ...theme.typography.h5,
                    fontWeight: theme.typography.fontWeightBold,
                }}
            >
                {title}
            </span>
            <Selector
                label="Organization"
                data={organizationList ?? []}
                currentValue={selectedOrgId}
                onChange={(
                    event: React.ChangeEvent<{
                        value: unknown
                    }>,
                ) => {
                    setSelecetedOrgId(event.target.value as number)
                }}
                noSelectionLabel="New Organization"
                getDisplayString={(org) => org.name}
                customStyle={{
                    formControl: {
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                    },
                }}
                maxItems={100}
                searchable
            />

            <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                label="Name"
                fullWidth
                style={inputStyle}
            />

            <TextField
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                label="Address"
                fullWidth
                style={inputStyle}
            />

            <TextField
                value={city}
                onChange={(e) => setCity(e.target.value)}
                label="City"
                fullWidth
                style={inputStyle}
            />

            <Container style={{ marginBottom: theme.spacing(2) }}>
                <TextField
                    style={{
                        marginRight: theme.spacing(2),
                    }}
                    value={state}
                    onChange={(e) => setState(e.target.value as string)}
                    label="State"
                    size="small"
                    select
                    fullWidth
                >
                    {statesList.map((s) => {
                        return (
                            <MenuItem key={s} value={s}>
                                {s}
                            </MenuItem>
                        )
                    })}
                </TextField>

                <TextField
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    label="Zip Code"
                    fullWidth
                    style={inputStyle}
                />
            </Container>
            <div style={{ flex: 1 }} />
            <Container style={{ justifyContent: 'flex-end' }}>
                <Button
                    onClick={() =>
                        props.onSubmit({
                            name: name,
                            address: address,
                            city: city,
                            state: state,
                            zip: zip,
                            organization_id: selectedOrgId,
                        })
                    }
                    variant="outlined"
                    color="primary"
                >
                    {props.submitButtonText ?? 'Save'}
                </Button>
            </Container>
        </Container>
    )
}
