import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'

export const MoneyValue = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    boxShadow: 'none',
    borderBottom: '1px solid #e0e0e0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
}))

export const TextValue = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    boxShadow: 'none',
    borderBottom: '1px solid #e0e0e0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
}))

export const MoneyInputValue = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    boxShadow: 'none',
    borderBottom: '1px solid #e0e0e0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
}))

export const IconValue = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    boxShadow: 'none',
    borderBottom: '1px solid #e0e0e0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
}))

export const TotalsTextValue = styled(Paper)(({ theme }) => ({
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary,
    borderBottom: '2px solid #e0e0e0',
    borderTop: '1px solid #000000',
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
}))

export const TotalsMoneyValue = styled(Paper)(({ theme }) => ({
    ...theme.typography.body1,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary,
    borderBottom: '2px solid #e0e0e0',
    borderTop: '1px solid #000000',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
}))

export const HeaderValue = styled(Paper)(({ theme }) => ({
    ...theme.typography.body1,
    borderBottom: '2px solid #e0e0e0',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
}))
