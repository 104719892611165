import React, { useState } from 'react'
import { Container, StatusBadge } from '../../../components'
import {
    Button,
    IconButton,
    Paper,
    Slide,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import {
    AreaConfig,
    IdBoolMap,
    IdentifiableNamedObject,
    ListInventoryAreaConfig,
    StatusGroup,
} from '../../../models'
import {
    RenderIAC,
    ReqIAC,
} from '../../InfrastructureConfig/InvAreaConfigDrawer'
import { useAppDispatch } from '../../../hooks'
import { Add } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import {
    CreateInventoryConfigRequest,
    RootState,
    createInventoryConfig,
} from '../../../store'
import {
    ADD_NEW_AREA_FORM,
    ADD_NEW_ITEM_FORM,
    ADD_NEW_STATUS_GROUP_FORM,
    INSPECTION_TYPE_FORM,
} from './ConfigureInspectionType'
import { toast } from 'react-toastify'
import { AreaSelection } from './AreaSelection'
import { StatusGroupForm } from '../../StatusGroupConfig'
import { CreateNewAreaForm } from './CreateNewAreaForm'

interface Props {
    subHeaderStyle: React.CSSProperties
    invItemsMap: IdBoolMap
    formPage: number
    setInvItemsMap: (map: IdBoolMap) => void
    setFormPage: (form: number) => void
}

export const NewInvItemForm = (props: Props) => {
    const {
        subHeaderStyle,
        setFormPage,
        invItemsMap,
        setInvItemsMap,
        formPage,
    } = props

    const theme = useTheme()

    const [itemName, setItemName] = useState('')
    const [sku, setSKU] = useState('')
    const [description, setDescription] = useState('')

    const [IACList, setIACList] = useState<ListInventoryAreaConfig[]>([])
    const [IACIndex, setIACIndex] = useState<number | undefined>()

    const [doValidate, setDoValidate] = useState(false)

    const [selectedStatusGroup, setSelectedStatusGroup] = useState<
        StatusGroup | undefined
    >()

    const statusGroupList = useSelector(
        (state: RootState) => state.aptConfig.statusGroupList,
    )

    const areaConfigList = useSelector(
        (state: RootState) => state.aptConfig.areaConfigList,
    )

    const dispatch = useAppDispatch()

    const updateIAC = (newAreaConfig: AreaConfig) => {
        const newIACList: ListInventoryAreaConfig[] = []
        IACList.forEach((innerIAC, innerIDX) => {
            if (IACIndex !== innerIDX) {
                newIACList.push(innerIAC)
            } else {
                newIACList.push({ ...innerIAC, area_config: newAreaConfig })
            }
        })
        setIACList(newIACList)
    }

    const statusGroupSelector = (
        <Container
            direction="column"
            style={{
                border: `1px solid ${theme.palette.lightGrey.dark}`,
                borderRadius: '5px',
                marginBottom: theme.spacing(2),
            }}
        >
            <Container>
                <Container
                    direction="column"
                    style={{
                        height: '200px',
                        overflowY: 'auto',
                        borderRight: `1px solid ${theme.palette.lightGrey.dark}`,
                        flex: 1,
                    }}
                >
                    {statusGroupList?.map((statusGroup) => {
                        return (
                            <Container
                                key={`STATUS_GROUP_SELECTION_${statusGroup.id}`}
                                style={{
                                    fontSize: '15px',
                                    fontWeight: 500,
                                    padding: theme.spacing(1),
                                    backgroundColor:
                                        statusGroup.id ===
                                        selectedStatusGroup?.id
                                            ? theme.palette.lightGrey.dark
                                            : '',
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                    setSelectedStatusGroup(statusGroup)
                                }
                            >
                                {statusGroup.name}
                            </Container>
                        )
                    })}
                </Container>
                <Container
                    justifyContent="flex-start"
                    style={{
                        flexWrap: 'wrap',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {selectedStatusGroup?.custom_statuses?.map((s) => (
                        <StatusBadge
                            key={s.id}
                            text={s.name}
                            customStyle={{
                                container: {
                                    margin: theme.spacing(0, 1, 1, 0),
                                    backgroundColor: s.color,
                                    width: 70,
                                    height: 40,
                                },
                                text: {
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                },
                            }}
                        />
                    ))}

                    {!selectedStatusGroup && (
                        <Container>Select a status group</Container>
                    )}
                </Container>
            </Container>

            <Container
                style={{
                    borderTop: `1px solid ${theme.palette.lightGrey.dark}`,
                }}
            >
                <Button
                    variant="contained"
                    style={{
                        margin: theme.spacing(1, 0, 1, 2),
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => setFormPage(ADD_NEW_STATUS_GROUP_FORM)}
                >
                    + New Status Group
                </Button>
            </Container>
        </Container>
    )

    const createInvItem = () => {
        const req: CreateInventoryConfigRequest = {
            name: itemName,
            sku: sku,
            description: description,
        }

        if (IACList.length !== 0) {
            const newIACList: ListInventoryAreaConfig[] = []
            IACList.forEach((innerIAC, innerIDX) => {
                if (innerIAC.amount !== 0) {
                    newIACList.push(innerIAC)
                }
            })

            let reqIACS = []
            reqIACS = newIACList.reduce<ReqIAC[]>((prev, iac) => {
                if (iac.area_config && iac.amount > 0) {
                    return prev.concat({
                        inventory_config_id: -1,
                        area_config_id: iac.area_config?.id ?? -1,
                        amount: iac.amount,
                    })
                }
                return prev
            }, [])

            req['iacs'] = reqIACS
        }

        if (selectedStatusGroup) {
            req['status_group'] = selectedStatusGroup.id
        }

        dispatch(createInventoryConfig(req)).then((res) => {
            const tempInvItemsMap = invItemsMap
            tempInvItemsMap[res.data.id] = true
            setInvItemsMap(tempInvItemsMap)
            toast.success('Inventory Config successfully created!')
            setFormPage(INSPECTION_TYPE_FORM)
        })
    }

    if (formPage === ADD_NEW_STATUS_GROUP_FORM) {
        return (
            <Slide direction="left" in={formPage === ADD_NEW_STATUS_GROUP_FORM}>
                <Paper elevation={0} style={{ flex: 1 }}>
                    <Container
                        direction="column"
                        style={{
                            maxHeight: '700px',
                            height: 'calc(70vh)',
                            overflowY: 'auto',
                            paddingRight: theme.spacing(1),
                            flex: 1,
                        }}
                    >
                        <StatusGroupForm
                            handleClose={() => setFormPage(ADD_NEW_ITEM_FORM)}
                            modalMode
                            setSelectedStatusGroup={setSelectedStatusGroup}
                            customLeftColorPlacement={-250}
                            customTopColorPlacement={0}
                        />
                    </Container>
                </Paper>
            </Slide>
        )
    } else if (formPage === ADD_NEW_AREA_FORM) {
        return (
            <Slide direction="left" in={true}>
                <Paper elevation={0} style={{ flex: 1 }}>
                    <Container
                        direction="column"
                        style={{
                            maxHeight: '700px',
                            height: 'calc(70vh)',
                            overflowY: 'auto',
                            paddingRight: theme.spacing(1),
                            flex: 1,
                        }}
                    >
                        <CreateNewAreaForm
                            subHeaderStyle={subHeaderStyle}
                            setFormPage={setFormPage}
                            updateIAC={updateIAC}
                        />
                    </Container>
                </Paper>
            </Slide>
        )
    }

    return (
        <Container direction="column" flex={1}>
            <Container
                direction="column"
                style={{
                    maxHeight: '650px',
                    height: 'calc(65vh)',
                    overflowY: 'auto',
                    paddingRight: theme.spacing(1),
                }}
            >
                <Container style={{ ...subHeaderStyle, fontSize: '20px' }}>
                    Adding An Item
                </Container>
                <Container
                    style={{
                        fontSize: '13px',
                        color: theme.palette.darkGreen.main,
                        marginBottom: theme.spacing(2),
                    }}
                >
                    Create a new inventory item
                </Container>
                <Container style={{ ...subHeaderStyle }}>Name Item:</Container>
                <Container style={{ marginBottom: theme.spacing(2) }}>
                    <TextField
                        error={doValidate && itemName === ''}
                        helperText={
                            doValidate && itemName === '' ? 'Required' : ''
                        }
                        variant="outlined"
                        value={itemName}
                        onChange={(e) => setItemName(e.target.value)}
                        style={{
                            width: '100%',
                        }}
                        size="small"
                    />
                </Container>
                <Container style={{ ...subHeaderStyle }}>SKU:</Container>
                <Container style={{ marginBottom: theme.spacing(2) }}>
                    <TextField
                        variant="outlined"
                        value={sku}
                        onChange={(e) => setSKU(e.target.value)}
                        style={{
                            width: '100%',
                        }}
                        size="small"
                    />
                </Container>
                <Container style={{ ...subHeaderStyle }}>
                    Description:
                </Container>
                <Container style={{ marginBottom: theme.spacing(2) }}>
                    <TextField
                        variant="outlined"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        style={{
                            width: '100%',
                        }}
                        size="small"
                    />
                </Container>
                <Container style={{ ...subHeaderStyle }}>Areas:</Container>
                <Container
                    style={{
                        fontSize: '13px',
                        color: theme.palette.darkGreen.main,
                        marginBottom: theme.spacing(2),
                    }}
                >
                    Inventory will be add to areas across the property
                </Container>
                <Container
                    direction="column"
                    style={{ marginBottom: theme.spacing(2) }}
                >
                    {IACList.map((iac, idx) => {
                        let configList: IdentifiableNamedObject[] = []
                        configList = areaConfigList.reduce<AreaConfig[]>(
                            (prev, areaConfig) => {
                                // if it is the config for this iac, return it
                                if (areaConfig.id === iac.area_config?.id) {
                                    return prev.concat(areaConfig)
                                }

                                // if this is the config for any other iac, do not return it
                                const isInOtherIAC = IACList.find(
                                    (iac) =>
                                        iac.area_config?.id === areaConfig.id,
                                )
                                if (isInOtherIAC) {
                                    return prev
                                }
                                return prev.concat(areaConfig)
                            },
                            [],
                        )

                        return (
                            <AreaSelection
                                key={idx}
                                iac={iac}
                                updateIAC={(newIac) => {
                                    const newIACList: RenderIAC[] = []
                                    IACList.forEach((innerIAC, innerIDX) => {
                                        if (idx !== innerIDX) {
                                            newIACList.push(innerIAC)
                                        } else {
                                            newIACList.push(newIac)
                                        }
                                    })
                                    setIACList(newIACList)
                                }}
                                configOptions={configList}
                                remove={() => {
                                    const newIACList: RenderIAC[] = []
                                    IACList.forEach((innerIAC, innerIDX) => {
                                        if (idx !== innerIDX) {
                                            newIACList.push(innerIAC)
                                        }
                                    })
                                    setIACList(newIACList)
                                }}
                                onClick={() => {
                                    setFormPage(ADD_NEW_AREA_FORM)
                                    setIACIndex(idx)
                                }}
                            />
                        )
                    })}
                    <Tooltip title="Add Inventory to Area">
                        <IconButton
                            onClick={() => {
                                setIACList([...IACList, { amount: 1 }])
                            }}
                        >
                            <Add color={'primary'} fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Container>
                <Container style={{ ...subHeaderStyle }}>
                    Status Group: {selectedStatusGroup?.name}
                </Container>
                {statusGroupSelector}
            </Container>
            <Container flex={1} />
            <Container style={{ justifyContent: 'flex-end' }}>
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    style={{
                        textTransform: 'none',
                        margin: theme.spacing(1, 0, 1, 2),
                    }}
                    onClick={() => {
                        setFormPage(INSPECTION_TYPE_FORM)
                        setSelectedStatusGroup(undefined)
                    }}
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    style={{
                        margin: theme.spacing(1, 0, 1, 2),
                        backgroundColor: '#008C85',
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        if (itemName === '') {
                            setDoValidate(true)
                        } else {
                            setDoValidate(false)
                            createInvItem()
                            setSelectedStatusGroup(undefined)
                        }
                    }}
                >
                    Confirm
                </Button>
            </Container>
        </Container>
    )
}
