import React from 'react'
import { Chip, useTheme } from '@material-ui/core'
import { AuditPromptConfig } from '../../../../../models/Lease/types'
import { Container } from '../../../../../components'

interface Props {
    promptConfigs: AuditPromptConfig[]
    selectedConfigs: number[]
    setSelectedConfigs: (selectedConfigs: number[]) => void
}

export const FeatureChip = (props: Props) => {
    const { promptConfigs, selectedConfigs, setSelectedConfigs } = props
    const theme = useTheme()

    const chipStyle: React.CSSProperties = {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    }
    const handleClick = (id: number) => {
        const newSelectedConfigs = selectedConfigs.filter(
            (configId) => configId !== id,
        )
        setSelectedConfigs(newSelectedConfigs)
    }

    return (
        <Container
            style={{
                flex: 1,
                flexDirection: 'row',
                flexWrap: 'wrap',
            }}
        >
            {selectedConfigs.map((id) => {
                const foundConfig = promptConfigs.find(
                    (config) => config.dd_lease_col_id === id,
                )
                if (foundConfig) {
                    return (
                        <Chip
                            style={chipStyle}
                            key={id}
                            label={foundConfig.display_name}
                            clickable
                            onClick={() => {
                                handleClick(id)
                            }}
                        />
                    )
                }
            })}
        </Container>
    )
}
