import { TextField, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Container } from '../../../components'
import { VendorModalServiceProps } from './types'
import CloseIcon from '@material-ui/icons/Close'
import { useUser } from '../../../hooks'
import { isAccountManager } from '../../../helpers'

export const VendorModalServices = (props: VendorModalServiceProps) => {
    const {
        vendor,
        selectedServices,
        setSelectedServices,
        open,
        serviceList,
        setServiceList,
    } = props

    const theme = useTheme()

    const { workspaceUser } = useUser()

    const [searchValue, setSearchValue] = useState('')

    const SELECTED_COLOR = '#D9D9D9'

    useEffect(() => {
        if (vendor) {
            serviceList.map((s, idx) => {
                return { ...s, checked: selectedServices.includes(s) }
            })
        }
    }, [selectedServices, open])

    const canRemoveService = isAccountManager(workspaceUser) || !vendor

    const searchServiceList = serviceList.filter((service) =>
        service.name
            .toLocaleLowerCase()
            .startsWith(searchValue.toLocaleLowerCase()),
    )
    const serviceCheckBoxes = searchServiceList.map((s, idx) => {
        s.checked = selectedServices.includes(s)

        return (
            <Container
                key={`SERVICE_CHECK_BOX_${idx}`}
                style={{
                    alignItems: 'center',
                    cursor: 'pointer',
                    fontSize: '16px',
                    padding: theme.spacing(0.5, 0, 0.5, 0),
                    backgroundColor: s.checked ? SELECTED_COLOR : '',
                }}
                onClick={() => {
                    const updatedServiceList = serviceList.map((old) => {
                        if (old.id === s.id) {
                            if (canRemoveService || !old.checked) {
                                return {
                                    ...old,
                                    checked: !old.checked,
                                }
                            }
                            return {
                                ...old,
                            }
                        }
                        return old
                    })
                    setServiceList(updatedServiceList)
                    setSelectedServices(
                        updatedServiceList.filter((service) => service.checked),
                    )
                }}
            >
                {s.name}
                <span style={{ flex: 1 }} />
            </Container>
        )
    })

    return (
        <Container
            flexWrap="wrap"
            style={{ maxHeight: '350px', flex: 1 }}
            scrollY
        >
            <Container style={{ width: '280px', marginTop: theme.spacing(2) }}>
                <TextField
                    value={searchValue}
                    placeholder="Search"
                    onChange={(e) => setSearchValue(e.target.value)}
                    size="small"
                    variant="outlined"
                    fullWidth
                />
            </Container>
            <Container
                style={{
                    display: 'block',
                    width: '280px',
                    marginTop: theme.spacing(1),
                }}
            >
                {selectedServices.map((s, idx) => {
                    return (
                        <span
                            style={{
                                backgroundColor: '#EFEFEF',
                                marginRight: theme.spacing(1),
                                fontSize: '13px',
                                padding: theme.spacing(0.25),
                                cursor: 'pointer',
                            }}
                            key={`SERVICED_SERVICES_${idx}`}
                            onClick={() => {
                                const updatedServiceList = serviceList.map(
                                    (old) => {
                                        if (old.id === s.id) {
                                            if (
                                                canRemoveService ||
                                                !old.checked
                                            ) {
                                                return {
                                                    ...old,
                                                    checked: !old.checked,
                                                }
                                            }
                                            return {
                                                ...old,
                                            }
                                        }
                                        return old
                                    },
                                )
                                setServiceList(updatedServiceList)
                                setSelectedServices(
                                    updatedServiceList.filter(
                                        (service) => service.checked,
                                    ),
                                )
                            }}
                        >
                            {s.name}
                            {canRemoveService && (
                                <CloseIcon
                                    fontSize="inherit"
                                    style={{
                                        marginLeft: theme.spacing(0.5),
                                        paddingTop: theme.spacing(0.25),
                                    }}
                                />
                            )}
                        </span>
                    )
                })}
            </Container>
            <Container style={{ width: '280px' }} direction="column">
                {serviceCheckBoxes}
            </Container>
        </Container>
    )
}
