import { useTheme } from '@material-ui/core'
import { Remove } from '@material-ui/icons'
import React from 'react'
import { useStyles } from '../../styles'
import { Container } from '../Container'

interface Props {
    elements: JSX.Element[]
    remove: (index: number) => void
}

export const ModularList = (props: Props) => {
    const { elements, remove } = props

    const classes = useStyles()
    const theme = useTheme()

    // takes in a list of jsx elements and renders them, with a minus icon next to it
    // if minus is clicked, a remove function passed from the caller is excecuted
    return (
        <Container direction={'column'}>
            {elements.map((e, idx) => {
                return (
                    <Container
                        key={`MODULAR_LIST_ITEM_INDEX_${idx}`}
                        alignItems="center"
                    >
                        {e}
                        <Remove
                            color="secondary"
                            className={classes.highlightCircleOnHover}
                            style={{ marginLeft: theme.spacing(1) }}
                            onClick={() => remove(idx)}
                        />
                    </Container>
                )
            })}
        </Container>
    )
}
