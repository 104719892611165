import React from 'react'
import { Container } from './Container'
import {
    AreaConfig,
    Lease,
    ModelMap,
    Unit,
    getLeaseStatusString,
} from '../models'
import { useTheme } from '@material-ui/core'
import { AvatarCircle } from './AvatarCircle'
import { EllipsisSpan } from './EllipsisSpan'
import { toMMDDYYYY } from '../helpers'

interface Props {
    lease: Lease
    containerStyle?: React.CSSProperties
    unitMap: ModelMap<Unit>
    areaConfigMap: ModelMap<AreaConfig>
    highLightArea?: boolean
}

export const LeaseCard = (props: Props) => {
    const theme = useTheme()

    const { lease, unitMap, areaConfigMap, highLightArea } = props

    const unit: Unit | undefined = unitMap[lease.area.unit]
    const areaConfig: AreaConfig | undefined =
        areaConfigMap[lease.area.area_config]

    return (
        <Container
            style={{
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.palette.grey[700]}`,
                height: 150,
                width: CARD_WIDTH,
                ...props.containerStyle,
            }}
        >
            {/* Left col */}
            <Container
                style={{
                    flexDirection: 'column',
                    width: CARD_WIDTH / 2,
                    padding: theme.spacing(1),
                }}
            >
                <Container style={{ alignItems: 'center' }}>
                    <AvatarCircle text={lease.user.name} initials />
                    <Container
                        style={{
                            flexDirection: 'column',
                            marginLeft: theme.spacing(1),
                            // justifyContent: 'center',
                        }}
                    >
                        <span style={{ width: '100%' }}>
                            {toMMDDYYYY(new Date(lease.start_date))}
                        </span>

                        <span style={{ width: '100%' }}>
                            {toMMDDYYYY(new Date(lease.end_date))}
                        </span>
                    </Container>
                </Container>

                <Container
                    style={{
                        flexDirection: 'column',
                        marginTop: theme.spacing(2),
                    }}
                >
                    <EllipsisSpan
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: CARD_WIDTH / 2,
                        }}
                    >
                        {lease.user.name}
                    </EllipsisSpan>
                    <EllipsisSpan
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            maxWidth: CARD_WIDTH / 2,
                        }}
                    >
                        {lease.user.email}
                    </EllipsisSpan>
                </Container>
            </Container>

            {/* Right Col */}
            <Container
                style={{
                    flexDirection: 'column',
                    width: CARD_WIDTH / 2,
                    padding: theme.spacing(1),
                }}
            >
                <Container
                    style={{
                        height: 60,
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: `1px solid ${theme.palette.grey[300]}`,
                        borderRadius: theme.shape.borderRadius,
                    }}
                >
                    <span
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            ...theme.typography.subtitle1,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {getLeaseStatusString(lease.status)}
                    </span>
                </Container>

                <Container
                    style={{
                        flexDirection: 'column',
                        border: highLightArea ? `1px solid orange` : undefined,
                        padding: 2,
                        borderRadius: theme.shape.borderRadius,
                        marginTop: theme.spacing(1),
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.body1,
                            wordWrap: 'break-word',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            lineHeight: '18px',
                            maxHeight: '36px',
                            minHeight: '36px',
                            marginTop: theme.spacing(2),
                        }}
                    >
                        {unit?.folder.path}
                        {unit?.folder.name}

                        <span
                            style={{
                                marginLeft: theme.spacing(1),
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {unit?.name}
                        </span>
                    </span>

                    <span>
                        {areaConfig?.name ?? '?'} {lease.area.area_label}
                    </span>
                </Container>
                {/*  */}
            </Container>
        </Container>
    )
}

const CARD_WIDTH = 300
