import React, { useState } from 'react'
import { Container, MfaModal, PasswordRequirements } from '../../../components'
import { axiosInstance, passwordValidator } from '../../../helpers'
import {
    Button,
    Modal,
    Paper,
    Slide,
    TextField,
    useTheme,
} from '@material-ui/core'

import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import SecurityIcon from '@material-ui/icons/Security'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useUser } from '../../../hooks'
import { toast } from 'react-toastify'

interface Props {
    onSubmit: () => void
}

export const AccountVerificationStep = (props: Props) => {
    const [passwordModalOpen, setPasswordModalOpen] = useState(false)

    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const theme = useTheme()

    const { rootUser, saveUserToState } = useUser()

    const passwordValidResult = passwordValidator(newPassword, confirmPassword)

    return (
        <Container
            style={{
                padding: theme.spacing(2),
                flexDirection: 'column',
                flex: 1,
            }}
        >
            <Container
                style={{
                    flex: 1,
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    overflowY: 'auto',
                }}
            >
                <RequirementCard
                    isComplete={rootUser?.email_verified}
                    Icon={
                        <MailOutlineIcon
                            fontSize="large"
                            htmlColor={theme.palette.grey[700]}
                        />
                    }
                    title="Email Verification"
                    description="Send a verification email"
                    onClickSetup={() => {
                        axiosInstance
                            .post('user/send_verification_email/')
                            .then(() => {
                                toast.success('Check your email!')
                            })
                    }}
                />
                <RequirementCard
                    isComplete={rootUser?.has_secure_password}
                    Icon={
                        <SecurityIcon
                            fontSize="large"
                            htmlColor={theme.palette.grey[700]}
                        />
                    }
                    title="Set a strong password"
                    description="Secure your password"
                    onClickSetup={() => setPasswordModalOpen(true)}
                />
            </Container>

            <Modal
                open={passwordModalOpen}
                onClose={() => setPasswordModalOpen(false)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Slide direction="up" in={passwordModalOpen}>
                    <Paper>
                        <Container
                            style={{
                                width: 600,
                                height: 300,
                                padding: theme.spacing(2),
                                flexDirection: 'column',
                            }}
                        >
                            <span style={{ ...theme.typography.h6 }}>
                                Setup a secure password
                            </span>
                            <div style={{ flex: 1 }} />

                            <Container>
                                <Container
                                    style={{
                                        flexDirection: 'column',
                                        flex: 1,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <form>
                                        <TextField
                                            InputProps={{
                                                autoComplete: 'new-password',
                                                required: true,
                                                id: 'password',
                                                name: 'password',
                                            }}
                                            label="New Password"
                                            variant="outlined"
                                            type="password"
                                            size="small"
                                            value={newPassword}
                                            onChange={(e) => {
                                                setNewPassword(e.target.value)
                                            }}
                                            fullWidth
                                        />

                                        <TextField
                                            InputProps={{
                                                autoComplete: 'new-password',
                                                required: true,
                                                id: 'confirm-password',
                                                name: 'confirm-password',
                                            }}
                                            label="Confirm Password"
                                            variant="outlined"
                                            type="password"
                                            size="small"
                                            value={confirmPassword}
                                            style={{
                                                marginTop: theme.spacing(2),
                                            }}
                                            onChange={(e) => {
                                                setConfirmPassword(
                                                    e.target.value,
                                                )
                                            }}
                                            fullWidth
                                        />
                                    </form>
                                </Container>

                                <PasswordRequirements
                                    passwordRequirementResult={
                                        passwordValidResult
                                    }
                                    containerStyle={{
                                        marginLeft: theme.spacing(2),
                                    }}
                                />
                            </Container>

                            <div style={{ flex: 1 }} />
                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={!passwordValidResult.passwordValid}
                                onClick={() => {
                                    axiosInstance
                                        .post('user/change_password/', {
                                            new_password: newPassword,
                                        })
                                        .then((res) => {
                                            saveUserToState(res.data)
                                            setPasswordModalOpen(false)
                                        })
                                        .catch((e) => {
                                            toast.error(e.response.data.message)
                                        })
                                }}
                            >
                                Confirm
                            </Button>
                        </Container>
                    </Paper>
                </Slide>
            </Modal>

            {/* <MfaModal
                open={mfaModalOpen}
                onClose={() => setMfaModalOpen(false)}
            /> */}
        </Container>
    )
}

interface RequirementCardProps {
    Icon: JSX.Element
    title: string
    description: string
    isComplete?: boolean
    onClickSetup: () => void
}

const RequirementCard = (props: RequirementCardProps) => {
    const theme = useTheme()

    const { Icon, title, description } = props

    const isComplete = props.isComplete === true

    let completionIcon = <ErrorOutlineIcon color="secondary" />
    let descriptionText = description
    if (isComplete) {
        completionIcon = <CheckCircleIcon color="primary" />
        descriptionText = 'Done'
    }

    return (
        <div
            style={{
                width: '50%',
                padding: theme.spacing(1),
                height: '50%',
            }}
        >
            <Paper
                style={{
                    height: '100%',
                    backgroundColor: '#efefee',
                    display: 'flex',
                }}
            >
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(1),
                    }}
                >
                    {Icon}
                    <span
                        style={{
                            marginTop: theme.spacing(1),
                            ...theme.typography.body1,
                            textAlign: 'center',
                        }}
                    >
                        {title}
                    </span>
                </Container>

                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderLeft: `1px solid ${theme.palette.grey[400]}`,
                        backgroundColor: theme.palette.grey[300],
                        cursor: isComplete ? undefined : 'pointer',
                    }}
                    onClick={() => {
                        if (isComplete) {
                            return
                        }

                        props.onClickSetup()
                    }}
                >
                    {completionIcon}
                    <span
                        style={{
                            marginTop: theme.spacing(1),
                            ...theme.typography.caption,
                        }}
                    >
                        {descriptionText}
                    </span>
                </Container>
            </Paper>
        </div>
    )
}
