import { useTheme } from '@material-ui/core'
import React, { useEffect } from 'react'

interface Props {
    url: string
    uuid: string
    height: number
    width: number
}

export const DisplayVideo = (props: Props) => {
    const { url, uuid, height, width } = props

    const theme = useTheme()

    useEffect(() => {
        const head = document.querySelector('head')
        const script = document.createElement('script')
        script.setAttribute('src', 'https://play.vidyard.com/embed/v4.js')
        script.async = true
        head?.appendChild(script)
    }, [])

    return (
        <img
            className="vidyard-player-embed"
            src={url}
            data-v="4"
            data-type="inline"
            data-uuid={uuid}
            data-playlist_start_open={1}
            data-height={height}
            data-width={width}
        />
    )
}
