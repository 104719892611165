import React from 'react'
import { Card, Divider, IconButton, Tooltip, useTheme } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import MessageIcon from '@material-ui/icons/Message'

import SyncProblemIcon from '@material-ui/icons/SyncProblem'
import Badge from '@material-ui/core/Badge'

import { Container } from '../../../components'
import {
    AreaStatusTag,
    BaseWorkorder,
    IdBoolMap,
    ModelMap,
    serviceAreasMatchUnit,
    Unit,
} from '../../../models'

import { mix_hexes, toMMDDYYYY } from '../../../helpers'
import { useWorkorderStyles } from '../styles'
import { WORKORDER_HEIGHT, WORKORDER_WIDTH } from '../constants'

interface Props {
    workorder: BaseWorkorder
    unit: Unit
    serviceAreaMap: IdBoolMap
    selected?: boolean
    inEditSelection?: boolean
    areaStatusTagMap: ModelMap<AreaStatusTag>
    onClick: (workorder: BaseWorkorder, unit: Unit) => void
}

const _GhostWorkorderComponent = (props: Props) => {
    const {
        workorder,
        unit,
        onClick,
        selected,
        inEditSelection,
        serviceAreaMap,
        areaStatusTagMap,
    } = props
    const theme = useTheme()

    const shadowColor = mix_hexes(
        workorder.service_color ?? theme.palette.noService.main,
        '#ffffff',
    )

    let backgroundColor = theme.palette.grey[100]
    if (selected) {
        backgroundColor = theme.palette.primary.light
    }
    if (inEditSelection) {
        backgroundColor = mix_hexes(theme.palette.secondary.main, '#ffffff')
    }

    let timelineLabel = 'Needs Timeline'
    if (workorder.start_date && workorder.end_date) {
        const startDate = new Date(workorder.start_date)
        const endDate = new Date(workorder.end_date)
        timelineLabel = `${toMMDDYYYY(startDate)} - ${toMMDDYYYY(endDate)}`
    }

    const borderStyle: React.CSSProperties = {
        border: `${BORDER_WIDTH}px solid ${
            workorder.service_color ?? theme.palette.noService.main
        }`,
        borderRadius: theme.shape.borderRadius,
    }

    const serviceAreasMatch = serviceAreasMatchUnit(
        workorder,
        unit,
        serviceAreaMap,
        areaStatusTagMap,
    )

    return (
        <Card
            style={{
                ...borderStyle,
                backgroundColor: theme.palette.grey[300],
                height: WORKORDER_HEIGHT,
                minWidth: WORKORDER_WIDTH,
                maxWidth: WORKORDER_WIDTH,
                boxShadow: `2px 2px 6px ${shadowColor}`,
                margin: theme.spacing(1),
                flexDirection: 'column',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            onClick={() => onClick(workorder, unit)}
        >
            <Container
                style={{
                    ...borderStyle,
                    height: WORKORDER_HEIGHT,
                    minWidth: INNER_WIDTH,
                    maxWidth: INNER_WIDTH,
                    margin: MARGIN,
                    padding: MARGIN,
                    backgroundColor: backgroundColor,
                }}
            >
                <Container style={{ flexDirection: 'column', flex: 1 }}>
                    <Container style={{ alignItems: 'center' }}>
                        <span
                            style={{
                                ...theme.typography.body1,
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {workorder.service_name ?? ''}
                        </span>
                        <span
                            style={{
                                ...theme.typography.body2,
                                marginLeft: theme.spacing(1),
                            }}
                        >
                            ({workorder.service_area_list.length})
                        </span>
                    </Container>

                    <span
                        style={{
                            ...theme.typography.caption,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {workorder.vendor_name ?? 'Needs Vendor'}
                    </span>

                    <span
                        style={{
                            ...theme.typography.caption,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        {timelineLabel}
                    </span>
                </Container>

                <Container
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: theme.spacing(1),
                        flexDirection: 'column',
                    }}
                >
                    <Badge
                        badgeContent={workorder.message_count}
                        color="secondary"
                    >
                        <MessageIcon fontSize="large" />
                    </Badge>

                    {!serviceAreasMatch && (
                        <Tooltip title="Edited Service Areas">
                            <SyncProblemIcon
                                style={{ color: theme.palette.brand1.main }}
                                fontSize="small"
                            />
                        </Tooltip>
                    )}
                </Container>
            </Container>
        </Card>
    )
}

export const GhostWorkorderComponent = React.memo(_GhostWorkorderComponent)

const MARGIN = 4
const BORDER_WIDTH = 2
const INNER_WIDTH = WORKORDER_WIDTH - 2 * MARGIN - 2 * BORDER_WIDTH
