import { Button, useTheme } from '@material-ui/core'
import React from 'react'
import { Container } from '../../components'
import { Check, Clear } from '@material-ui/icons'
import { axiosInstance } from '../../helpers'
import {
    EDIT_VENDOR_SUBSCRIPTION_STATUS,
    ListVendor,
    PlanStatus,
} from '../../models'
import { hasPermission } from '../../models/Users/services'
import { useUser } from '../../hooks'
import { useStripe } from '../../hooks/useStripe'

interface Props {
    vendor: ListVendor
}

export const VendorPlans = (props: Props) => {
    const { vendor } = props

    const { workspaceUser } = useUser()

    const {
        createStripePortalSession,
        createStripeCheckoutSession,
    } = useStripe()

    const theme = useTheme()

    const PlanStyle: React.CSSProperties = {
        height: '560px',
        width: '250px',
        border: `1px solid ${theme.palette.lightGrey.main}`,
        borderRadius: '10px',
    }

    const IconStyle: React.CSSProperties = {
        padding: theme.spacing(0.3),
        marginRight: theme.spacing(1),
    }

    const disabled = !hasPermission(
        workspaceUser,
        EDIT_VENDOR_SUBSCRIPTION_STATUS,
    )

    const hasNoPlan = vendor.plan === PlanStatus.NO_PLAN || vendor.plan === null
    const hasPremium = vendor.plan === PlanStatus.PREMIUM

    return (
        <Container flex={1} style={{ margin: theme.spacing(2, 3, 3, 5) }}>
            <Container
                style={{
                    ...PlanStyle,
                    backgroundColor: theme.palette.primary.dark,
                    alignItems: 'center',
                    marginTop: theme.spacing(5),
                }}
                direction="column"
            >
                <Container
                    style={{
                        fontWeight: 700,
                        fontSize: '22px',
                        color: 'white',
                        marginTop: theme.spacing(10),
                    }}
                >
                    Choose your Plan!
                </Container>
                <Container
                    style={{
                        fontWeight: 500,
                        fontSize: '15px',
                        marginTop: theme.spacing(6),
                        color: 'white',
                        textAlign: 'center',
                    }}
                >
                    Welcome to EZ Turn plans! Here you can choose which plan
                    best suits you.
                </Container>
            </Container>
            <Container
                style={{
                    ...PlanStyle,
                    alignItems: 'center',
                    margin: theme.spacing(5, 4, 0, 4),
                    borderColor: hasNoPlan
                        ? theme.palette.primary.dark
                        : theme.palette.lightGrey.main,
                    borderWidth: hasNoPlan ? '3px' : '1px',
                }}
                direction="column"
            >
                <Container
                    style={{
                        fontWeight: 600,
                        fontSize: '20px',
                        marginTop: theme.spacing(2),
                    }}
                >
                    Basic
                </Container>
                <Container
                    style={{
                        marginTop: theme.spacing(2),
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            fontWeight: 600,
                            fontSize: '18px',
                        }}
                    >
                        $
                    </span>
                    <span
                        style={{
                            fontWeight: 600,
                            fontSize: '40px',
                            margin: theme.spacing(0, 0.5, 0, 0.5),
                        }}
                    >
                        0
                    </span>
                    <span
                        style={{
                            fontWeight: 600,
                            fontSize: '18px',
                        }}
                    >
                        /mo.
                    </span>
                </Container>
                <Container
                    style={{
                        fontWeight: 400,
                        fontSize: '15px',
                        color: theme.palette.darkGreen.main,
                        textAlign: 'center',
                        marginTop: theme.spacing(3),
                        padding: theme.spacing(0, 2, 0, 2),
                    }}
                >
                    This is your EZ Turn basic plan. For vendors that only need
                    a few things out of a plan
                </Container>
                <Container
                    style={{
                        fontWeight: 400,
                        fontSize: '14px',
                        color: theme.palette.darkGreen.main,
                        marginTop: theme.spacing(2),
                    }}
                    direction="column"
                >
                    <Container
                        style={{
                            alignItems: 'center',
                        }}
                    >
                        <Check style={{ ...IconStyle }} /> Unlimited Users
                    </Container>
                    <Container
                        style={{
                            margin: theme.spacing(2, 0, 2, 0),
                            alignItems: 'center',
                        }}
                    >
                        <Check style={{ ...IconStyle }} /> 24/7 Support
                    </Container>
                    <Container
                        style={{
                            alignItems: 'center',
                        }}
                    >
                        <Check style={{ ...IconStyle }} /> Name Listed on NVL
                    </Container>
                    <Container
                        style={{
                            color: theme.palette.lightGrey.main,
                            alignItems: 'center',
                            margin: theme.spacing(2, 0, 2, 0),
                        }}
                    >
                        <Clear style={{ ...IconStyle }} /> Vendor Verification
                        on NVL
                    </Container>
                    <Container
                        style={{
                            color: theme.palette.lightGrey.main,
                            alignItems: 'center',
                        }}
                    >
                        <Clear style={{ ...IconStyle }} /> Featured Vendor
                    </Container>
                    <Container
                        style={{
                            margin: theme.spacing(2, 0, 2, 0),
                            color: theme.palette.lightGrey.main,
                            alignItems: 'center',
                        }}
                    >
                        <Clear style={{ ...IconStyle }} /> Access to National
                        Property List
                    </Container>
                    <Container
                        style={{
                            color: theme.palette.lightGrey.main,
                            alignItems: 'center',
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        <Clear style={{ ...IconStyle }} /> Unsolicited Bids
                    </Container>
                </Container>
                <Container flex={1} />
                <Container style={{ marginBottom: theme.spacing(3) }}>
                    <Button
                        variant="contained"
                        style={{
                            color: hasNoPlan
                                ? 'white'
                                : theme.palette.primary.dark,
                            textTransform: 'none',
                            border: `1px solid ${theme.palette.primary.dark}`,
                            backgroundColor: hasNoPlan
                                ? theme.palette.primary.dark
                                : 'white',
                            width: '150px',
                        }}
                        size="large"
                        onClick={() => {
                            if (!hasNoPlan) {
                                const body = {
                                    return_url: window.location.href,
                                }
                                createStripePortalSession(body)
                            }
                        }}
                        disabled={disabled}
                    >
                        {hasNoPlan ? 'Current' : 'Select'}
                    </Button>
                </Container>
            </Container>
            <Container
                style={{
                    ...PlanStyle,
                    alignItems: 'center',
                    marginTop: hasNoPlan ? theme.spacing(0) : theme.spacing(5),
                    borderColor: hasPremium
                        ? theme.palette.primary.dark
                        : hasNoPlan
                        ? theme.palette.darkBlue.main
                        : theme.palette.lightGrey.main,
                    borderWidth: hasPremium ? '3px' : '1px',
                    height: hasNoPlan ? '600px' : '560px',
                }}
                direction="column"
            >
                {hasNoPlan && (
                    <Container
                        style={{
                            fontWeight: 500,
                            fontSize: '15px',
                            backgroundColor: theme.palette.darkBlue.main,
                            color: 'white',
                            flex: 1,
                            padding: theme.spacing(1, 0, 1, 0),
                            width: '100%',
                            justifyContent: 'center',
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                        }}
                    >
                        Recommended
                    </Container>
                )}
                <Container
                    style={{
                        fontWeight: 600,
                        fontSize: '20px',
                        marginTop: theme.spacing(2),
                    }}
                >
                    Premium
                </Container>
                <Container
                    style={{
                        marginTop: theme.spacing(2),
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            fontWeight: 600,
                            fontSize: '18px',
                        }}
                    >
                        $
                    </span>
                    <span
                        style={{
                            fontWeight: 600,
                            fontSize: '40px',
                            margin: theme.spacing(0, 0.5, 0, 0.5),
                        }}
                    >
                        19.99
                    </span>
                    <span
                        style={{
                            fontWeight: 600,
                            fontSize: '18px',
                        }}
                    >
                        /mo.
                    </span>
                </Container>
                <Container
                    style={{
                        fontWeight: 400,
                        fontSize: '15px',
                        color: theme.palette.darkGreen.main,
                        textAlign: 'center',
                        marginTop: theme.spacing(3),
                        padding: theme.spacing(0, 2, 0, 2),
                    }}
                >
                    This is your EZ Turn Premium plan. For those vendors who
                    need a little more.
                </Container>
                <Container
                    style={{
                        fontWeight: 400,
                        fontSize: '14px',
                        color: theme.palette.darkGreen.main,
                        marginTop: theme.spacing(2),
                    }}
                    direction="column"
                >
                    <Container
                        style={{
                            alignItems: 'center',
                        }}
                    >
                        <Check style={{ ...IconStyle }} /> Unlimited Users
                    </Container>
                    <Container
                        style={{
                            margin: theme.spacing(2, 0, 2, 0),
                            alignItems: 'center',
                        }}
                    >
                        <Check style={{ ...IconStyle }} /> 24/7 Support
                    </Container>
                    <Container
                        style={{
                            alignItems: 'center',
                        }}
                    >
                        <Check style={{ ...IconStyle }} /> Name Listed on NVL
                    </Container>
                    <Container
                        style={{
                            alignItems: 'center',
                            margin: theme.spacing(2, 0, 2, 0),
                        }}
                    >
                        <Check style={{ ...IconStyle }} /> Vendor Verification
                        on NVL
                    </Container>

                    <Container
                        style={{
                            alignItems: 'center',
                        }}
                    >
                        <Check style={{ ...IconStyle }} /> Featured Vendor
                    </Container>

                    <Container
                        style={{
                            margin: theme.spacing(2, 0, 2, 0),
                            alignItems: 'center',
                        }}
                    >
                        <Check style={{ ...IconStyle }} /> Access to National
                        Property List
                    </Container>
                    <Container
                        style={{
                            marginBottom: theme.spacing(1),
                            alignItems: 'center',
                        }}
                    >
                        <Check style={{ ...IconStyle }} /> Unsolicited Bids
                    </Container>
                </Container>
                <Container flex={1} />
                <Container style={{ marginBottom: theme.spacing(3) }}>
                    <Button
                        variant="contained"
                        style={{
                            color: hasPremium
                                ? 'white'
                                : theme.palette.primary.dark,
                            textTransform: 'none',
                            border: `1px solid ${theme.palette.primary.dark}`,
                            backgroundColor: hasPremium
                                ? theme.palette.primary.dark
                                : 'white',
                            width: '150px',
                        }}
                        size="large"
                        onClick={() => {
                            if (!hasNoPlan) {
                                const body = {
                                    return_url: window.location.href,
                                }
                                createStripePortalSession(body)
                            } else {
                                const body = {
                                    plan_type: PlanStatus.PREMIUM,
                                    success_url: window.location.href,
                                }
                                createStripeCheckoutSession(body)
                            }
                        }}
                        disabled={disabled}
                    >
                        {hasPremium ? 'Current' : 'Select'}
                    </Button>
                </Container>
            </Container>
        </Container>
    )
}
