import React from 'react'
import { Container, Selector, SideDrawer } from '../../../components'
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    useTheme,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import {
    EZPayTransactionFilterState,
    defaultTransactionFilterState,
} from './InvoiceTab'
import { DwollaTransferStatus, ListVendor } from '../../../models'
import { useCompany } from '../../../hooks'
import { dwollaApi } from '../../../contexts'

interface Props {
    open: boolean
    onClose: () => void
    filterState: EZPayTransactionFilterState
    onChangeFilter: React.Dispatch<
        React.SetStateAction<EZPayTransactionFilterState>
    >
    vendorList: ListVendor[]
}

export const InvoiceFilterDrawer = (props: Props) => {
    const { open, onClose, filterState, onChangeFilter, vendorList } = props

    const theme = useTheme()

    const { fundingSources } = dwollaApi()

    const setStatusState = (status: DwollaTransferStatus, checked: boolean) => {
        onChangeFilter((prev) => {
            return {
                ...prev,
                status: {
                    ...prev.status,
                    [status]: checked,
                },
            }
        })
    }

    const resetFilter = () => {
        onChangeFilter(defaultTransactionFilterState)
    }

    return (
        <SideDrawer open={open} handleClose={onClose}>
            <Container
                style={{
                    padding: theme.spacing(1),
                    flexDirection: 'column',
                    flex: 1,
                }}
            >
                <span
                    style={{
                        ...theme.typography.h6,
                        fontWeight: theme.typography.fontWeightBold,
                        marginBottom: theme.spacing(1),
                    }}
                >
                    Transaction Filters
                </span>
                <FormLabel component="legend">Status</FormLabel>

                <Container>
                    <Container style={{ flexDirection: 'column' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={filterState.status.request}
                                    onChange={(_, checked) => {
                                        setStatusState(
                                            DwollaTransferStatus.REQUEST,
                                            checked,
                                        )
                                    }}
                                />
                            }
                            label="Payment Request"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={filterState.status.pending}
                                    onChange={(_, checked) => {
                                        setStatusState(
                                            DwollaTransferStatus.PENDING,
                                            checked,
                                        )
                                    }}
                                />
                            }
                            label="Pending"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={filterState.status.processed}
                                    onChange={(_, checked) => {
                                        setStatusState(
                                            DwollaTransferStatus.PROCESSED,
                                            checked,
                                        )
                                    }}
                                />
                            }
                            label="Processed"
                        />
                    </Container>

                    <Container style={{ flexDirection: 'column' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={filterState.status.cancelled}
                                    onChange={(_, checked) => {
                                        setStatusState(
                                            DwollaTransferStatus.CANCELLED,
                                            checked,
                                        )
                                    }}
                                />
                            }
                            label="Cancelled"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={filterState.status.failed}
                                    onChange={(_, checked) => {
                                        setStatusState(
                                            DwollaTransferStatus.FAILED,
                                            checked,
                                        )
                                    }}
                                />
                            }
                            label="Failed"
                        />
                    </Container>
                </Container>

                <Container>
                    <Selector
                        searchable
                        customStyle={{
                            formControl: {
                                marginTop: theme.spacing(2),
                                marginRight: theme.spacing(1),
                                flex: 1,
                            },
                        }}
                        data={vendorList}
                        currentValue={filterState.vendorId}
                        getDisplayString={(v) => v.name}
                        onChange={(e) => {
                            onChangeFilter((prev) => {
                                return {
                                    ...prev,
                                    vendorId: e.target.value as number,
                                }
                            })
                        }}
                        label="Vendor"
                    />

                    <IconButton
                        onClick={() => {
                            onChangeFilter((prev) => {
                                return {
                                    ...prev,
                                    vendorId: -1,
                                }
                            })
                        }}
                    >
                        <ClearIcon fontSize="large" />
                    </IconButton>
                </Container>

                <Container>
                    <Selector
                        searchable
                        customStyle={{
                            formControl: {
                                marginTop: theme.spacing(2),
                                marginRight: theme.spacing(1),
                                flex: 1,
                            },
                        }}
                        data={fundingSources ?? []}
                        currentValue={filterState.fundingSourceId}
                        getDisplayString={(fs) => fs.name}
                        onChange={(e) => {
                            onChangeFilter((prev) => {
                                return {
                                    ...prev,
                                    fundingSourceId: e.target.value as number,
                                }
                            })
                        }}
                        label="Funding Source"
                    />

                    <IconButton
                        onClick={() => {
                            onChangeFilter((prev) => {
                                return {
                                    ...prev,
                                    fundingSourceId: -1,
                                }
                            })
                        }}
                    >
                        <ClearIcon fontSize="large" />
                    </IconButton>
                </Container>

                <div style={{ flex: 1 }} />
                <Divider />
                <Container style={{ padding: theme.spacing(1) }}>
                    <div style={{ flex: 1 }} />
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: theme.spacing(1) }}
                        onClick={onClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={resetFilter}
                    >
                        Reset
                    </Button>
                </Container>
            </Container>
        </SideDrawer>
    )
}
