import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { useAppDispatch, useUser, useCompany } from '../../../../hooks'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import { setWorkSpaceUser } from '../../../../store'
import { Routes } from '../../../config/routes'
import { APARTMENT } from '../../../../helpers'

const WorkSpaceSelection = () => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            workspaceDropdown: {
                width: 252,
                color: '#FFF',
                borderRight: '2px solid #FFF',
                height: '56px',
                '& .MuiFormControl-root, .MuiInputBase-root': {
                    height: '100%',
                    color: '#FFF',
                },
                '& .MuiInputBase-root': {
                    paddingLeft: '12px',
                },
                '& .MuiButtonBase-root': {
                    color: '#FFF',
                },
                '& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before': {
                    borderBottom: 0,
                },
            },
        }),
    )
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useAppDispatch()

    const { workspaceUser, rootUser, updateUser } = useUser()
    const { apartmentList } = useCompany()

    const [selectedJobSite, setSelectedJobSite] = useState<any | undefined>(
        workspaceUser?.jobsite ? workspaceUser?.jobsite : undefined,
    )

    //Reset the selected jobsite every time the workspace user is changes the apartment or vendor
    useEffect(() => {
        setSelectedJobSite(undefined)
    }, [workspaceUser?.active_workspace.name])

    return (
        <>
            <Autocomplete
                disableClearable
                id="combo-box-demo"
                options={rootUser?.workspaces ?? []}
                getOptionLabel={(option) =>
                    option.active_workspace ? option.active_workspace.name : ''
                }
                className={classes.workspaceDropdown}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        style={{ color: '#FFF' }}
                        placeholder="Search"
                    />
                )}
                value={workspaceUser}
                onChange={(event: React.ChangeEvent<{}>, newValue: any) => {
                    console.log('event is...', newValue)
                    const newWsUser = rootUser?.workspaces.find(
                        (wsUser) => wsUser.id === newValue.id,
                    )
                    if (newWsUser) {
                        setSelectedJobSite(undefined)
                        if (
                            newWsUser?.active_workspace.company_type !==
                            'VENDOR'
                        ) {
                            history.push(Routes.dashboard)
                        } else {
                            history.push(Routes.ezwork)
                        }
                        dispatch(setWorkSpaceUser(newWsUser))
                        toast.success(
                            `Switched to ${newWsUser.active_workspace.name}`,
                        )
                    } else {
                        toast.error('Workspace not found')
                    }
                }}
                style={{ zIndex: Number.MAX_SAFE_INTEGER }}
            />
        </>
    )
}

export default WorkSpaceSelection
