import { Theme } from '@material-ui/core'
import { BaseWorkorder } from '.'
import {
    AreaConfig,
    AreaStatusTag,
    ASSIGNED_TO_IN_PROGRESS,
    COMPLETE_TO_APPROVED,
    COMPLETE_TO_GO_BACK,
    COMPLETE_TO_PRE_APPROVED,
    GO_BACK_TO_IN_PROGRESS,
    IdBoolMap,
    IN_PROGRESS_TO_ASSIGNED,
    IN_PROGRESS_TO_COMPLETE,
    IN_PROGRESS_TO_PAUSE,
    PAUSE_TO_IN_PROGRESS,
    PRE_APPROVED_TO_APPROVED,
    PRE_APPROVED_TO_GO_BACK,
    User,
} from '..'
import { WorkorderResponse } from '../../store'
import { IsTimelineConvertable, timelinesOverlap } from '../../helpers'

import { Area, Unit } from '../Infrastructure'
import { getLeaseTimeline, Lease } from '../Lease'
import { hasPermission } from '../Users/services'
import {
    ChangeOrderStatus,
    _Workorder,
    WorkorderStatus,
    WorkorderTransition,
} from './types'
import { ModelListMap, ModelMap } from '../types'
import { ServiceContractMap } from '../../models/ServiceContract'
import {
    ACFILTER_IMAGE,
    ACVACUUM_IMAGE,
    ALT_CLEAN_SERVICE_IMAGE,
    APPLIANCE_SERVICE_IMAGE,
    BADGE_IMAGE,
    BLINDS_SERVICE_IMAGE,
    CARPET_C_SERVICE_IMAGE,
    CARPET_R_SERVICE_IMAGE,
    CARPET_SERVICE_IMAGE,
    CHARGE_BACK_SERVICE_IMAGE,
    CLEAN_SERVICE_IMAGE,
    COMPUTER_IMAGE,
    DRYWALL_PAINT_TOUCHUP_IMAGE,
    ELECTRICAL_SERVICE_IMAGE,
    ELEVATOR_IMAGE,
    ESG_IMAGE,
    FIRE_ALARM_IMAGE,
    FIRE_EXTINGUISHER_SERVICE_IMAGE,
    FLOORING_REMOVAL_IMAGE,
    FULL_PAINT_IMAGE,
    FURNITURE_INSTALL_IMAGE,
    FURNITURE_REMOVAL_IMAGE,
    FURNITURE_SERVICE_IMAGE,
    GRASS_IMAGE,
    HEAVY_CLEAN_SERVICE_IMAGE,
    HVAC_SERVICE_IMAGE,
    INSPECTION_ROOM_CA_IMAGE,
    INSPECTION_ROOM_WGA_IMAGE,
    INSPECTION_SERVICE_IMAGE,
    INTERNET_IMAGE,
    IRRIGATION_IMAGE,
    KEY_SERVICE_IMAGE,
    KITCHEN_REPLACEMENT_IMAGE,
    LANDSCAPING_IMAGE,
    LAUNDRY_IMAGE,
    LIGHT_LOG_SERVICE_IMAGE,
    MAINTENANCE_SERVICE_IMAGE,
    MISC_SERVICE_IMAGE,
    OCC_WALK_SERVICE_IMAGE,
    ODOR_SERVICE_IMAGE,
    PAINT_SERVICE_IMAGE,
    PARTIAL_PAINT_IMAGE,
    PEST_CONTROL_SERVICE_IMAGE,
    PET_SERVICE_IMAGE,
    PLUMBING_SERVICE_IMAGE,
    POOL_IMAGE,
    PREP_ROOM_CA_IMAGE,
    PREP_ROOM_WGA_IMAGE,
    PRESSURE_WASH_SERVICE_IMAGE,
    SIGNATURE_IMAGE,
    SNOW_IMAGE,
    SPARKLE_IMAGE,
    STAIN_SERVICE_IMAGE,
    TOILET_INSTALL_IMAGE,
    TOILET_REMOVAL_IMAGE,
    TOUCH_CLEAN_IMAGE,
    TOUCH_PAINT_IMAGE,
    TRASHOUT_SERVICE_IMAGE,
    TREE_IMAGE,
    UPGRADE_SERVICE_IMAGE,
    VANITY_REPLACEMENT_IMAGE,
    VINYL_SERVICE_IMAGE,
    WATER_IMAGE,
} from '../../assets/service-images'
import {
    ALT_CLEAN_SERVICE_ICON,
    APPLIANCE_SERVICE_ICON,
    BADGE_ICON,
    BLINDS_SERVICE_ICON,
    CARPET_C_SERVICE_ICON,
    CARPET_R_SERVICE_ICON,
    CARPET_SERVICE_ICON,
    CHARGE_BACK_SERVICE_ICON,
    CLEAN_SERVICE_ICON,
    COMPUTER_ICON,
    DRYWALL_PAINT_TOUCHUP_ICON,
    ELECTRICAL_SERVICE_ICON,
    ESG_ICON,
    FIRE_ALARM_ICON,
    FIRE_EXTINGUISHER_SERVICE_ICON,
    FLOORING_REMOVAL_ICON,
    FURNITURE_INSTALL_ICON,
    FURNITURE_REMOVAL_ICON,
    FURNITURE_SERVICE_ICON,
    GRASS_ICON,
    HVAC_SERVICE_ICON,
    INSPECTION_SERVICE_ICON,
    INTERNET_ICON,
    IRRIGATION_ICON,
    KEY_SERVICE_ICON,
    KITCHEN_REPLACEMENT_ICON,
    LAUNDRY_ICON,
    LIGHT_LOG_SERVICE_ICON,
    MAINTENANCE_SERVICE_ICON,
    MISC_SERVICE_ICON,
    OCC_WALK_SERVICE_ICON,
    ODOR_SERVICE_ICON,
    PAINT_SERVICE_ICON,
    PEST_CONTROL_SERVICE_ICON,
    PET_SERVICE_ICON,
    PLUMBING_SERVICE_ICON,
    POOL_ICON,
    PRESSURE_WASH_SERVICE_ICON,
    ROOM_CHECK_SERVICE_ICON,
    SIGNATURE_ICON,
    SNOW_ICON,
    SPARKLE_ICON,
    STAIN_SERVICE_ICON,
    TOILET_INSTALL_ICON,
    TOILET_REMOVAL_ICON,
    TRASHOUT_SERVICE_ICON,
    TREE_ICON,
    UPGRADE_SERVICE_ICON,
    VANITY_REPLACEMENT_ICON,
    VINYL_SERVICE_ICON,
    WATER_ICON,
} from '../../assets/service-icons'

export const createWorkorderFromResponse = (
    workorderRes: WorkorderResponse,
): BaseWorkorder | _Workorder => {
    if (
        workorderRes.start_date === null ||
        workorderRes.end_date === null ||
        workorderRes.vendor_id === null ||
        workorderRes.vendor_name === null
    ) {
        return workorderRes
    }
    const startDate = new Date(workorderRes.start_date)
    startDate.setHours(0, 0, 0, 0)

    const endDate = new Date(workorderRes.end_date)
    endDate.setHours(23, 59, 59, 999)

    return {
        ...workorderRes,
        priority: workorderRes.priority === 1 ? true : false,
        startDate: startDate,
        endDate: endDate,
    }
}

export const getUiString = (status: string) => {
    const strStatus = status.toString().toLocaleLowerCase()
    const splitStatus = strStatus.split('_')

    let uiStatus = ''
    splitStatus.forEach((s) => {
        uiStatus += s[0].toLocaleUpperCase() + s.slice(1) + ' '
    })
    return uiStatus
}

export const getServiceContractPrice = (workorder: BaseWorkorder): number => {
    let totalPrice = 0
    if (workorder.fully_vacant) {
        // Workorder is fully vacant, use the vacant service contract
        totalPrice = workorder.fv_price ?? 0
    } else {
        // Workorder has partial occupancy.  Sum the service contracts for each service area
        workorder.service_area_list.forEach((sa) => {
            if (sa.part_price) {
                totalPrice += sa.part_price
            }
        })
    }

    return totalPrice
}

export const getChangeOrderPrice = (workorder: BaseWorkorder): number => {
    let coPrice = 0
    workorder.changeorder_set?.forEach((changeOrder) => {
        if (changeOrder.status === ChangeOrderStatus.APPROVED) {
            coPrice += changeOrder.price * changeOrder.quantity
        }
    })
    return coPrice
}

export const getTotalPriceExpense = (workorder: BaseWorkorder): number => {
    if (workorder.status === WorkorderStatus.INVOICED) {
        return workorder.invoiced_price ?? -1
    }
    let totalPrice = 0
    if (
        workorder.custom_price !== null &&
        workorder.custom_price !== undefined
    ) {
        // Custom Price is set.  Always use custom price instead of service contracts
        totalPrice = workorder.custom_price
    } else {
        // Workorder does not have a custom price.  Use pricing from service contracts
        totalPrice = getServiceContractPrice(workorder)
    }

    totalPrice += Number(workorder.add_price)
    totalPrice += getChangeOrderPrice(workorder)

    return totalPrice
}

export const getTotalPriceIncome = (
    workorder: BaseWorkorder,
    serviceContractMap: ServiceContractMap,
): number => {
    if (serviceContractMap[workorder.service_id ?? -1] === undefined) {
        return 0
    }

    const serviceDict = serviceContractMap[workorder.service_id ?? -1]

    let totalPrice = 0

    if (workorder.fully_vacant) {
        // Workorder is fully vacant, use the vacant service contract
        const fvPrice = serviceDict.unitConfig[workorder.unit_config_id ?? -1]
        if (fvPrice === undefined) {
            return 0
        }

        if (typeof fvPrice === 'string') {
            return parseFloat(fvPrice)
        }
        totalPrice = fvPrice
    } else {
        // Workorder has partial occupancy.  Sum the service contracts for each service area
        totalPrice = workorder.service_area_list.reduce((prev, serviceArea) => {
            const areaPrice = serviceDict.areaConfig[serviceArea.area_config_id]

            if (areaPrice === undefined) {
                return prev
            }

            if (typeof areaPrice === 'string') {
                return prev + parseFloat(areaPrice)
            }

            return prev + areaPrice
        }, 0)
    }

    workorder.changeorder_set?.forEach((changeOrder) => {
        if (changeOrder.status === ChangeOrderStatus.APPROVED) {
            totalPrice += (changeOrder.income_price ?? 0) * changeOrder.quantity
        }
    })

    return totalPrice
}

interface MatchingServiceAreaDict {
    [areaId: number]: {
        expected: boolean
        isServiceArea: boolean
    }
}

export const serviceAreasMatchUnit = (
    workorder: BaseWorkorder,
    unit: Unit,
    serviceAreaDict: IdBoolMap,
    areaStatusTagMap: ModelMap<AreaStatusTag>,
) => {
    const expectedServiceAreaDict = unit.areas.reduce<MatchingServiceAreaDict>(
        (prev, area) => {
            return {
                ...prev,
                [area.id]: {
                    expected:
                        areaStatusTagMap[area.id]?.area_status_config
                            .should_service === true,
                    isServiceArea: false,
                },
            }
        },
        {},
    )

    workorder.service_area_list.forEach((serviceArea) => {
        if (expectedServiceAreaDict[serviceArea.area_id]) {
            expectedServiceAreaDict[serviceArea.area_id].isServiceArea = true
        }
    })

    let isValid = true
    unit.areas.forEach((area) => {
        if (expectedServiceAreaDict[area.id]) {
            isValid =
                isValid &&
                expectedServiceAreaDict[area.id].expected ===
                    expectedServiceAreaDict[area.id].isServiceArea
        }
    })

    return isValid
}

export const getDuration = (workorder: _Workorder) => {
    // get a string that displays the number of seconds, mintues, and hours a wo took
    let time = 0
    let toInProgressTime = new Date()
    let lastWasToInprogress = false
    workorder.transition_logs.forEach((tl) => {
        if (lastWasToInprogress) {
            // add the difference in times to time

            time += new Date(tl.date).getTime() - toInProgressTime.getTime()
            lastWasToInprogress = false
        }
        if (tl.to_status === WorkorderStatus.IN_PROGRESS) {
            toInProgressTime = new Date(tl.date)
            lastWasToInprogress = true
        }
    })
    if (lastWasToInprogress) {
        const tzOffsetMS = toInProgressTime.getTimezoneOffset() * 60 * 1000
        const nowUTC = new Date(new Date().getTime() + tzOffsetMS)

        time += nowUTC.getTime() - toInProgressTime.getTime()
    }

    // convert to seconds
    let sec = Math.floor(time / 1000)
    //extract hours
    const hours = sec / 3600 // get the number of hours
    sec = sec % 3600 // mod the number of seconds in an hour to remove the hours
    const minutes = sec / 60 //get the number of minuts in the time
    sec = sec % 60 // mod the number of seconds in a minute to remove the minute

    const hourString = hours > 9 ? hours.toFixed(0) : `0${hours.toFixed(0)}`
    const minuteString =
        minutes > 9 ? minutes.toFixed(0) : `0${minutes.toFixed(0)}`
    const secString = sec > 9 ? sec.toFixed(0) : `0${sec.toFixed(0)}`

    return `${hourString}:${minuteString}:${secString}`
}

export const isWorkorderApproved = (workorder: BaseWorkorder) => {
    return (
        workorder.status === WorkorderStatus.APPROVED ||
        workorder.status === WorkorderStatus.INVOICED
    )
}

export const isWorkorderComplete = (workorder: BaseWorkorder) => {
    return (
        isWorkorderApproved(workorder) ||
        workorder.status === WorkorderStatus.COMPLETE ||
        workorder.status === WorkorderStatus.PRE_APPROVED
    )
}

export const getStatusComparisonValue = (status: WorkorderStatus) => {
    switch (status) {
        case WorkorderStatus.ASSIGNED:
            return 0
        case WorkorderStatus.IN_PROGRESS:
        case WorkorderStatus.PAUSE:
        case WorkorderStatus.GO_BACK:
            return 1
        case WorkorderStatus.COMPLETE:
            return 2
        case WorkorderStatus.PRE_APPROVED:
            return 3
        case WorkorderStatus.APPROVED:
            return 4
        case WorkorderStatus.INVOICED:
            return 5
        default:
            return 0
    }
}

export const isWorkorderSafe = (
    baseWorkorder: BaseWorkorder,
): baseWorkorder is _Workorder => {
    const safeWorkorder = baseWorkorder as any
    return (
        safeWorkorder.startDate instanceof Date &&
        safeWorkorder.endDate instanceof Date &&
        safeWorkorder.vendor_id !== null &&
        safeWorkorder.vendor_name !== null
    )
}

interface TransitionInfo {
    transition: WorkorderTransition
    displayString: string
    permission: boolean
    to_status: WorkorderStatus
}
export interface Transitions {
    positive?: TransitionInfo
    negative?: TransitionInfo
    neutral?: TransitionInfo
}

export const getTransitions = (workorder: BaseWorkorder, user?: User) => {
    const transitions: Transitions = {
        positive: undefined,
        negative: undefined,
        neutral: undefined,
    }

    switch (workorder.status) {
        case WorkorderStatus.ASSIGNED:
            transitions['positive'] = {
                displayString: 'Start',
                permission: hasPermission(user, ASSIGNED_TO_IN_PROGRESS),
                transition: WorkorderTransition.ASSIGNED_TO_IN_PROGRESS,
                to_status: WorkorderStatus.IN_PROGRESS,
            }
            break

        case WorkorderStatus.IN_PROGRESS:
            transitions['positive'] = {
                displayString: 'Complete',
                permission: hasPermission(user, IN_PROGRESS_TO_COMPLETE),
                transition: WorkorderTransition.IN_PROGRESS_TO_COMPLETE,
                to_status: WorkorderStatus.COMPLETE,
            }
            transitions['negative'] = {
                displayString: 'Reset',
                permission: hasPermission(user, IN_PROGRESS_TO_ASSIGNED),
                transition: WorkorderTransition.IN_PROGRESS_TO_ASSIGNED,
                to_status: WorkorderStatus.ASSIGNED,
            }
            if (user?.active_workspace?.company_type === 'VENDOR') {
                transitions['neutral'] = {
                    displayString: 'Pause',
                    permission: hasPermission(user, IN_PROGRESS_TO_PAUSE),
                    transition: WorkorderTransition.IN_PROGRESS_TO_PAUSE,
                    to_status: WorkorderStatus.PAUSE,
                }
            }
            break

        case WorkorderStatus.PAUSE:
            transitions['positive'] = {
                displayString: 'Resume',
                permission: hasPermission(user, PAUSE_TO_IN_PROGRESS),
                transition: WorkorderTransition.PAUSE_TO_IN_PROGESS,
                to_status: WorkorderStatus.IN_PROGRESS,
            }
            break

        case WorkorderStatus.COMPLETE:
            // only show them options if they are an apartment user
            if (user?.active_workspace?.company_type === 'APARTMENT') {
                // if they can complete to approved, we can ignore complete to pre approved
                if (hasPermission(user, COMPLETE_TO_APPROVED)) {
                    transitions['positive'] = {
                        displayString: 'Approve',
                        permission: true,
                        transition: WorkorderTransition.COMPLETE_TO_APPROVED,
                        to_status: WorkorderStatus.APPROVED,
                    }
                } else {
                    transitions['positive'] = {
                        displayString: 'Approve',
                        permission: hasPermission(
                            user,
                            COMPLETE_TO_PRE_APPROVED,
                        ),
                        transition:
                            WorkorderTransition.COMPLETE_TO_PRE_APPROVED,
                        to_status: WorkorderStatus.PRE_APPROVED,
                    }
                }
                // always have complete to go back if they are an apartment user
                transitions['negative'] = {
                    displayString: 'Go Back',
                    permission: hasPermission(user, COMPLETE_TO_GO_BACK),
                    transition: WorkorderTransition.COMPLETE_TO_GO_BACK,
                    to_status: WorkorderStatus.GO_BACK,
                }
            }
            break
        case WorkorderStatus.GO_BACK:
            transitions['positive'] = {
                displayString: 'Start',
                permission: hasPermission(user, GO_BACK_TO_IN_PROGRESS),
                transition: WorkorderTransition.GO_BACK_TO_IN_PROGRESS,
                to_status: WorkorderStatus.IN_PROGRESS,
            }
            break
        case WorkorderStatus.PRE_APPROVED:
            if (user?.active_workspace?.company_type === 'APARTMENT') {
                transitions['positive'] = {
                    displayString: 'Approve',
                    permission: hasPermission(user, PRE_APPROVED_TO_APPROVED),
                    transition: WorkorderTransition.PRE_APPROVED_TO_APPROVED,
                    to_status: WorkorderStatus.APPROVED,
                }
                transitions['negative'] = {
                    displayString: 'Go Back',
                    permission: hasPermission(user, PRE_APPROVED_TO_GO_BACK),
                    transition: WorkorderTransition.PRE_APPROVED_TO_GO_BACK,
                    to_status: WorkorderStatus.GO_BACK,
                }
            }
    }

    return transitions
}

export const getChangeOrderColor = (
    status: ChangeOrderStatus,
    theme: Theme,
) => {
    switch (status) {
        case ChangeOrderStatus.APPROVED:
            return theme.palette.COMPLETE.main
        case ChangeOrderStatus.PENDING:
            return theme.palette.PRE_APPROVED.main
        case ChangeOrderStatus.DENIED:
            return theme.palette.GO_BACK.main
        default:
            return 'black'
    }
}

export const isAreaAvailableDuringTimeline = (
    assignmentTimeline?: IsTimelineConvertable,
    leases?: Lease[],
) => {
    let assignmentTimeValid = true

    if (assignmentTimeline) {
        leases?.forEach((lease) => {
            assignmentTimeValid =
                assignmentTimeValid &&
                !timelinesOverlap(getLeaseTimeline(lease), assignmentTimeline)
        })
    }

    return assignmentTimeValid
}

export const isServiceArea = (
    area: Area,
    areaLeaseMap: ModelListMap<Lease>,
    useLeases: boolean = true,
    defaultValue: boolean = true,
    areaStatusTagMap: ModelMap<AreaStatusTag>,
    areaConfig?: AreaConfig,
    assignmentTimeline?: IsTimelineConvertable,
) => {
    if (!areaConfig?.shows_on_schedule) {
        return false
    }

    if (useLeases) {
        const areaLeases = areaLeaseMap[area.id]
        const isChecked = assignmentTimeline
            ? isAreaAvailableDuringTimeline(assignmentTimeline, areaLeases)
            : defaultValue

        return isChecked
    }

    const areaStatus = areaStatusTagMap[area.id]
    const isChecked = areaStatus?.area_status_config.should_service ?? true

    return isChecked
}

export const getServiceImageDetails = (serviceID: number) => {
    switch (serviceID) {
        case ServiceIdMap.PAINT:
            return {
                src: PAINT_SERVICE_IMAGE,
                color: '#016fb9',
                icon: PAINT_SERVICE_ICON,
            }
        case ServiceIdMap.CARPET:
            return {
                src: CARPET_SERVICE_IMAGE,
                color: '#422040',
                icon: CARPET_SERVICE_ICON,
            }
        case ServiceIdMap.CLEAN:
            return {
                src: CLEAN_SERVICE_IMAGE,
                color: '#e9190f',
                icon: CLEAN_SERVICE_ICON,
            }
        case ServiceIdMap.TOUCH_PAINT:
            return {
                src: TOUCH_PAINT_IMAGE,
                color: '#016fb9',
                icon: PAINT_SERVICE_ICON,
                name: 'Touch P.',
            }
        case ServiceIdMap.CARPET_REPLACE:
            return {
                src: CARPET_R_SERVICE_IMAGE,
                color: '#867185',
                icon: CARPET_R_SERVICE_ICON,
            }
        case ServiceIdMap.TRASHOUT:
            return {
                src: TRASHOUT_SERVICE_IMAGE,
                color: '#A6A6A6',
                icon: TRASHOUT_SERVICE_ICON,
            }
        case ServiceIdMap.MAINTENANCE:
            return {
                src: MAINTENANCE_SERVICE_IMAGE,
                color: '#ffbf00',
                icon: MAINTENANCE_SERVICE_ICON,
                name: 'Mtn.',
            }
        case ServiceIdMap.CARPET_CLEAN:
            return {
                src: CARPET_C_SERVICE_IMAGE,
                color: '#422040',
                icon: CARPET_C_SERVICE_ICON,
            }
        case ServiceIdMap.TOUCH_CLEAN:
            return {
                src: TOUCH_CLEAN_IMAGE,
                color: '#e9190f',
                icon: CLEAN_SERVICE_ICON,
                name: 'Touch C.',
            }
        case ServiceIdMap.FURNITURE:
            return {
                src: FURNITURE_SERVICE_IMAGE,
                color: '#e3d985',
                icon: FURNITURE_SERVICE_ICON,
            }
        case ServiceIdMap.APPLIANCE:
            return {
                src: APPLIANCE_SERVICE_IMAGE,
                color: '#e57a44',
                icon: APPLIANCE_SERVICE_ICON,
            }
        case ServiceIdMap.PEST_CONTROL:
            return {
                src: PEST_CONTROL_SERVICE_IMAGE,
                color: '#abcee6',
                icon: PEST_CONTROL_SERVICE_ICON,
            }
        case ServiceIdMap.INSPECTION:
            return {
                src: INSPECTION_SERVICE_IMAGE,
                color: '#25283d',
                icon: INSPECTION_SERVICE_ICON,
            }
        case ServiceIdMap.PRESSURE_WASH:
            return {
                src: PRESSURE_WASH_SERVICE_IMAGE,
                color: '#52d1dc',
                icon: PRESSURE_WASH_SERVICE_ICON,
            }
        case ServiceIdMap.OCCUPANCY_WALK:
            return {
                src: OCC_WALK_SERVICE_IMAGE,
                color: '#6d676e',
                icon: OCC_WALK_SERVICE_ICON,
            }
        case ServiceIdMap.CHARGEBACKS:
            return {
                src: CHARGE_BACK_SERVICE_IMAGE,
                color: '#7e6551',
                icon: CHARGE_BACK_SERVICE_ICON,
            }
        case ServiceIdMap.LIGHT_LOGS:
            return {
                src: LIGHT_LOG_SERVICE_IMAGE,
                color: '#918b55',
                icon: LIGHT_LOG_SERVICE_ICON,
            }
        case ServiceIdMap.ELECTRICAL:
            return {
                src: ELECTRICAL_SERVICE_IMAGE,
                color: '#f7aef8',
                icon: ELECTRICAL_SERVICE_ICON,
            }
        case ServiceIdMap.PLUMBING:
            return {
                src: PLUMBING_SERVICE_IMAGE,
                color: '#b388eb',
                icon: PLUMBING_SERVICE_ICON,
            }
        case ServiceIdMap.HVAC:
            return {
                src: HVAC_SERVICE_IMAGE,
                color: '#ff6b6b',
                icon: HVAC_SERVICE_ICON,
            }
        case ServiceIdMap.INSPECT_CLEAN:
            return {
                src: CLEAN_SERVICE_IMAGE,
                color: '#25283d',
                icon: CLEAN_SERVICE_ICON,
            }
        case ServiceIdMap.INSPECT_PAINT:
            return {
                src: TOUCH_PAINT_IMAGE,
                color: '#25283d',
                icon: PAINT_SERVICE_ICON,
            }
        case ServiceIdMap.VINYL:
            return {
                src: VINYL_SERVICE_IMAGE,
                color: '#4fd886',
                icon: VINYL_SERVICE_ICON,
            }
        case ServiceIdMap.FLOORING:
            return {
                src: VINYL_SERVICE_IMAGE,
                color: '#e8dbc5',
                icon: VINYL_SERVICE_ICON,
            }
        case ServiceIdMap.MISC:
            return {
                src: MISC_SERVICE_IMAGE,
                color: '#cabac8',
                icon: MISC_SERVICE_ICON,
            }
        case ServiceIdMap.PREP_ROOM_WGA:
            return {
                src: PREP_ROOM_WGA_IMAGE,
                color: '#994640',
                icon: ROOM_CHECK_SERVICE_ICON,
            }
        case ServiceIdMap.PREP_ROOM_CA:
            return {
                src: PREP_ROOM_CA_IMAGE,
                color: '#5c2926',
                icon: ROOM_CHECK_SERVICE_ICON,
            }
        case ServiceIdMap.INSPECT_ROOM_WGA:
            return {
                src: INSPECTION_ROOM_WGA_IMAGE,
                color: '#37b6c4',
                icon: ROOM_CHECK_SERVICE_ICON,
            }
        case ServiceIdMap.INSPECT_ROOM_CA:
            return {
                src: INSPECTION_ROOM_CA_IMAGE,
                color: '#4dd9e8',
                icon: ROOM_CHECK_SERVICE_ICON,
            }
        case ServiceIdMap.CREATE_KEY_PACKET:
            return {
                src: KEY_SERVICE_IMAGE,
                color: '#7b4de8',
                icon: KEY_SERVICE_ICON,
            }
        case ServiceIdMap.CHANGE_AC_FILTER:
            return {
                src: ACFILTER_IMAGE,
                color: '#c94de8',
                icon: HVAC_SERVICE_ICON,
            }
        case ServiceIdMap.VACUUM_AC_CLOSET:
            return {
                src: ACVACUUM_IMAGE,
                color: '#a4d689',
                icon: HVAC_SERVICE_ICON,
            }
        case ServiceIdMap.CHANGE_BLINDS:
            return {
                src: BLINDS_SERVICE_IMAGE,
                color: '#8c89d6',
                icon: BLINDS_SERVICE_ICON,
            }
        case ServiceIdMap.PARTIAL_PAINT:
            return {
                src: PARTIAL_PAINT_IMAGE,
                color: '#016fb9',
                icon: PAINT_SERVICE_ICON,
            }
        case ServiceIdMap.FULL_PAINT:
            return {
                src: FULL_PAINT_IMAGE,
                color: '#016fb9',
                icon: PAINT_SERVICE_ICON,
            }
        case ServiceIdMap.HEAVY_CLEAN:
            return {
                src: HEAVY_CLEAN_SERVICE_IMAGE,
                color: '#46b083',
                icon: ALT_CLEAN_SERVICE_ICON,
            }
        case ServiceIdMap.DRYWALL_LESS_6_IN:
            return {
                src: PRESSURE_WASH_SERVICE_IMAGE,
                color: '#FF9F9A',
                icon: PRESSURE_WASH_SERVICE_ICON,
            }
        case ServiceIdMap.DRYWALL_GR_6_IN:
            return {
                src: PRESSURE_WASH_SERVICE_IMAGE,
                color: '#FF8753',
                icon: PRESSURE_WASH_SERVICE_ICON,
            }
        case ServiceIdMap.STAIN_REMOVAL:
            return {
                src: STAIN_SERVICE_IMAGE,
                color: '#b78900',
                icon: STAIN_SERVICE_ICON,
            }
        case ServiceIdMap.ODOR_TREATMENT:
            return {
                src: ODOR_SERVICE_IMAGE,
                color: '#ACE5CE',
                icon: ODOR_SERVICE_ICON,
            }
        case ServiceIdMap.PET_TREATMENT:
            return {
                src: PET_SERVICE_IMAGE,
                color: '#FF7A00',
                icon: PET_SERVICE_ICON,
            }
        case ServiceIdMap.UPGRADE:
            return {
                src: UPGRADE_SERVICE_IMAGE,
                color: '#EEB200',
                icon: UPGRADE_SERVICE_ICON,
            }
        case ServiceIdMap.BASIC_CLEAN:
            return {
                src: ALT_CLEAN_SERVICE_IMAGE,
                color: '#322D92',
                icon: ALT_CLEAN_SERVICE_ICON,
            }
        case ServiceIdMap.DETAIL_CLEAN:
            return {
                src: ALT_CLEAN_SERVICE_IMAGE,
                color: '#FF00F0',
                icon: ALT_CLEAN_SERVICE_ICON,
            }
        case ServiceIdMap.PTAC_CLEAN:
            return {
                src: ALT_CLEAN_SERVICE_IMAGE,
                color: '#8F0092',
                icon: ALT_CLEAN_SERVICE_ICON,
            }
        case ServiceIdMap.FIRE_EXT_CHECK:
            return {
                src: FIRE_EXTINGUISHER_SERVICE_IMAGE,
                color: '#E35650',
                icon: FIRE_EXTINGUISHER_SERVICE_ICON,
            }
        case ServiceIdMap.STRIP_AND_WAX:
            return {
                src: PRESSURE_WASH_SERVICE_IMAGE,
                color: '#A4D689',
                icon: PRESSURE_WASH_SERVICE_ICON,
            }
        case ServiceIdMap.TOP_SCRUB_AND_WAX:
            return {
                src: PRESSURE_WASH_SERVICE_IMAGE,
                color: '#276A4D',
                icon: PRESSURE_WASH_SERVICE_ICON,
            }
        case ServiceIdMap.FURNITURE_REMOVAL:
            return {
                src: FURNITURE_REMOVAL_IMAGE,
                color: '#A39526',
                icon: FURNITURE_REMOVAL_ICON,
            }
        case ServiceIdMap.TOILET_REMOVAL:
            return {
                src: TOILET_REMOVAL_IMAGE,
                color: '#325E7B',
                icon: TOILET_REMOVAL_ICON,
            }
        case ServiceIdMap.FLOORING_REMOVAL:
            return {
                src: FLOORING_REMOVAL_IMAGE,
                color: '#988B76',
                icon: FLOORING_REMOVAL_ICON,
            }
        case ServiceIdMap.TOILET_INSTALL:
            return {
                src: TOILET_INSTALL_IMAGE,
                color: '#5190BA',
                icon: TOILET_INSTALL_ICON,
            }
        case ServiceIdMap.FURNITURE_INSTALL:
            return {
                src: FURNITURE_INSTALL_IMAGE,
                color: '#999259',
                icon: FURNITURE_INSTALL_ICON,
            }
        case ServiceIdMap.DRYWALL_PAINT_TOUCH_UP:
            return {
                src: DRYWALL_PAINT_TOUCHUP_IMAGE,
                color: '#143043',
                icon: DRYWALL_PAINT_TOUCHUP_ICON,
            }
        case ServiceIdMap.KITCHEN_REPLACEMENT:
            return {
                src: KITCHEN_REPLACEMENT_IMAGE,
                color: '#8B614B',
                icon: KITCHEN_REPLACEMENT_ICON,
            }
        case ServiceIdMap.VANITY_REPLACEMENT:
            return {
                src: VANITY_REPLACEMENT_IMAGE,
                color: '#9D2A9E',
                icon: VANITY_REPLACEMENT_ICON,
            }
        case ServiceIdMap.SPARKLE:
            return { src: SPARKLE_IMAGE, color: '#DFA904', icon: SPARKLE_ICON }
        case ServiceIdMap.APPLIANCE_REPAIR:
            return {
                src: APPLIANCE_SERVICE_IMAGE,
                color: '#6D9E55',
                icon: APPLIANCE_SERVICE_ICON,
            }
        case ServiceIdMap.ELECTRICITY_AND_GAS:
            return {
                src: ELECTRICAL_SERVICE_IMAGE,
                color: '#C472C6',
                icon: ELECTRICAL_SERVICE_ICON,
            }
        case ServiceIdMap.ELEVATOR_MTN_REPAIR:
            return {
                src: ELEVATOR_IMAGE,
                color: '#707CFF',
                icon: ELECTRICAL_SERVICE_ICON,
            }
        case ServiceIdMap.ESG_ENERGY_AUDIT:
            return { src: ESG_IMAGE, color: '#009658', icon: ESG_ICON }
        case ServiceIdMap.ESG_GREEN_BUILDING_ASSESSMENT:
            return { src: ESG_IMAGE, color: '#0A6741', icon: ESG_ICON }
        case ServiceIdMap.ESG_WATER_AUDIT:
            return { src: ESG_IMAGE, color: '#0A4BAD', icon: ESG_ICON }
        case ServiceIdMap.FIRE_ALARTM_MTN_INSPECT:
            return {
                src: FIRE_ALARM_IMAGE,
                color: '#463A17',
                icon: FIRE_ALARM_ICON,
            }
        case ServiceIdMap.GARBAGE_COLLECT:
            return {
                src: TRASHOUT_SERVICE_IMAGE,
                color: '#686868',
                icon: TRASHOUT_SERVICE_ICON,
            }
        case ServiceIdMap.HANDYMAN:
            return {
                src: MAINTENANCE_SERVICE_IMAGE,
                color: '#A37270',
                icon: MAINTENANCE_SERVICE_ICON,
            }
        case ServiceIdMap.INTERNET_CABLE:
            return {
                src: INTERNET_IMAGE,
                color: '#3933A9',
                icon: INTERNET_ICON,
            }
        case ServiceIdMap.IRRIGATION_MTN:
            return {
                src: IRRIGATION_IMAGE,
                color: '#0A4BAD',
                icon: IRRIGATION_ICON,
            }
        case ServiceIdMap.LANDSCAPING_AND_GROUNDS_MTN:
            return {
                src: LANDSCAPING_IMAGE,
                color: '#500A4C',
                icon: GRASS_ICON,
            }
        case ServiceIdMap.LAUNDRY_ROOM_MTN_REPAIR:
            return { src: LAUNDRY_IMAGE, color: '#431254', icon: LAUNDRY_ICON }
        case ServiceIdMap.LAWN_CARE_AND_MOWING:
            return { src: GRASS_IMAGE, color: '#9F2B99', icon: GRASS_ICON }
        case ServiceIdMap.LEASING_AND_TENANT_SCREENING:
            return {
                src: COMPUTER_IMAGE,
                color: '#CF6D19',
                icon: COMPUTER_ICON,
            }
        case ServiceIdMap.POOL_MTN_AND_CLEAN:
            return { src: POOL_IMAGE, color: '#005188', icon: POOL_ICON }
        case ServiceIdMap.PRINTING_SERVICES:
            return {
                src: SIGNATURE_IMAGE,
                color: '#8B1C17',
                icon: SIGNATURE_ICON,
            }
        case ServiceIdMap.RENT_COLLECTION:
            return {
                src: CHARGE_BACK_SERVICE_IMAGE,
                color: '#3F352C',
                icon: CHARGE_BACK_SERVICE_ICON,
            }
        case ServiceIdMap.SECURITY_PERSONNEL:
            return { src: BADGE_IMAGE, color: '#009DA6', icon: BADGE_ICON }
        case ServiceIdMap.SNOW_ICE_REMOVAL:
            return { src: SNOW_IMAGE, color: '#73BCF5', icon: SNOW_ICON }
        case ServiceIdMap.TERMITE_INS_TREATMENT:
            return {
                src: PEST_CONTROL_SERVICE_IMAGE,
                color: '#4280AB',
                icon: PEST_CONTROL_SERVICE_ICON,
            }
        case ServiceIdMap.TREE_AND_SHRUB_MTN:
            return { src: TREE_IMAGE, color: '#257554', icon: TREE_ICON }
        case ServiceIdMap.WATER_AND_SEWER:
            return { src: WATER_IMAGE, color: '#0A4BAD', icon: WATER_ICON }
    }

    return { src: null, color: '#000', icon: null }
}

export enum ServiceIdMap {
    PAINT = 1,
    CARPET = 2,
    CLEAN = 3,
    TOUCH_PAINT = 4,
    CARPET_REPLACE = 5,
    TRASHOUT = 6,
    MAINTENANCE = 7,
    CARPET_CLEAN = 8,
    TOUCH_CLEAN = 9,
    FURNITURE = 10,
    APPLIANCE = 11,
    PEST_CONTROL = 12,
    INSPECTION = 13,
    PRESSURE_WASH = 14,
    OCCUPANCY_WALK = 15,
    CHARGEBACKS = 16,
    LIGHT_LOGS = 17,
    ELECTRICAL = 18,
    PLUMBING = 19,
    HVAC = 20,
    INSPECT_CLEAN = 21,
    INSPECT_PAINT = 22,
    VINYL = 23,
    FLOORING = 24,
    MISC = 25,
    PREP_ROOM_WGA = 26,
    PREP_ROOM_CA = 27,
    INSPECT_ROOM_WGA = 28,
    INSPECT_ROOM_CA = 29,
    CREATE_KEY_PACKET = 30,
    CHANGE_AC_FILTER = 31,
    VACUUM_AC_CLOSET = 32,
    CHANGE_BLINDS = 33,
    PARTIAL_PAINT = 34,
    FULL_PAINT = 35,
    HEAVY_CLEAN = 36,
    DRYWALL_LESS_6_IN = 37,
    DRYWALL_GR_6_IN = 38,
    STAIN_REMOVAL = 39,
    ODOR_TREATMENT = 40,
    PET_TREATMENT = 41,
    UPGRADE = 42,
    BASIC_CLEAN = 43,
    DETAIL_CLEAN = 44,
    PTAC_CLEAN = 45,
    FIRE_EXT_CHECK = 46,
    STRIP_AND_WAX = 47,
    TOP_SCRUB_AND_WAX = 48,
    FURNITURE_REMOVAL = 49,
    TOILET_REMOVAL = 50,
    FLOORING_REMOVAL = 51,
    TOILET_INSTALL = 52,
    FURNITURE_INSTALL = 53,
    DRYWALL_PAINT_TOUCH_UP = 54,
    KITCHEN_REPLACEMENT = 55,
    VANITY_REPLACEMENT = 56,
    SPARKLE = 57,
    APPLIANCE_REPAIR = 58,
    ELECTRICITY_AND_GAS = 59,
    ELEVATOR_MTN_REPAIR = 60,
    ESG_ENERGY_AUDIT = 61,
    ESG_GREEN_BUILDING_ASSESSMENT = 62,
    ESG_WATER_AUDIT = 63,
    FIRE_ALARTM_MTN_INSPECT = 64,
    GARBAGE_COLLECT = 65,
    HANDYMAN = 66,
    INTERNET_CABLE = 67,
    IRRIGATION_MTN = 68,
    LANDSCAPING_AND_GROUNDS_MTN = 69,
    LAUNDRY_ROOM_MTN_REPAIR = 70,
    LAWN_CARE_AND_MOWING = 71,
    LEASING_AND_TENANT_SCREENING = 72,
    POOL_MTN_AND_CLEAN = 73,
    PRINTING_SERVICES = 74,
    RENT_COLLECTION = 75,
    SECURITY_PERSONNEL = 76,
    SNOW_ICE_REMOVAL = 77,
    TERMITE_INS_TREATMENT = 78,
    TREE_AND_SHRUB_MTN = 79,
    WATER_AND_SEWER = 80,
}
export const getUiStatus = (status: WorkorderStatus) => {
    switch (status) {
        case WorkorderStatus.APPROVED:
            return 'Approved'
        case WorkorderStatus.ASSIGNED:
            return 'Assigned'
        case WorkorderStatus.COMPLETE:
            return 'Complete'
        case WorkorderStatus.INVOICED:
            return 'Invoiced'
        case WorkorderStatus.IN_PROGRESS:
            return 'In Progress'
        case WorkorderStatus.GO_BACK:
            return 'Go Back'
        case WorkorderStatus.PAUSE:
            return 'Paused'
        case WorkorderStatus.PRE_APPROVED:
            return 'Pre-Approved'
    }
    return '?'
}

export const getHasGoBack = (workorder: BaseWorkorder) => {
    return workorder.transition_logs.find(
        (log) => log.to_status === WorkorderStatus.GO_BACK,
    )
}
