import React, { useState } from 'react'

import {
    Divider,
    Modal,
    Paper,
    Slide,
    Step,
    StepButton,
    Stepper,
    useTheme,
} from '@material-ui/core'
import { Range } from 'react-date-range'

import { Container } from '../../../../components'
import { Area, AreaConfig, ModelMap, Unit } from '../../../../models'
import {
    CreateLeaseDateSelect,
    CreateLeaseLocationSelect,
    CreateLeaseConfirm,
} from '.'
import Carousel from 'react-material-ui-carousel'
import { toast } from 'react-toastify'

interface Props {
    open: boolean
    unitMap: ModelMap<Unit>
    areaConfigMap: ModelMap<AreaConfig>
    onClose: () => void
}

const RANGE_KEY = 'RANGE_KEY'

export const DATE_STEP = 0
export const AREA_STEP = 1
export const CONFIRM_STEP = 2

export type CreateLeaseStep =
    | typeof DATE_STEP
    | typeof AREA_STEP
    | typeof CONFIRM_STEP

export const CreateLeaseModal = (props: Props) => {
    const { open, onClose, unitMap, areaConfigMap } = props

    const theme = useTheme()

    const [dateRange, setDateRange] = useState<Range>({
        startDate: new Date(),
        endDate: new Date(),
        color: theme.palette.primary.main,
        key: RANGE_KEY,
    })

    const [area, setArea] = useState<Area | undefined>()

    const [activeStep, setActiveStep] = useState<CreateLeaseStep>(DATE_STEP)
    const steps = ['Lease Term', 'Select an area', 'Confirmation']
    const selectStep = (step: CreateLeaseStep) => {
        // Use this method to go backwards to a previous step
        if (step <= activeStep) {
            setActiveStep(step)
        }
    }

    const onChangeRange = (range: Range) => {
        setArea(undefined)
        setDateRange(range)
    }

    const confirmDateRange = () => {
        setActiveStep(AREA_STEP)
    }

    const confirmArea = (area: Area) => {
        setArea(area)
        setActiveStep(CONFIRM_STEP)
    }

    const closeForm = () => {
        setActiveStep(DATE_STEP)
        setArea(undefined)
        onClose()
    }

    return (
        <Modal
            open={open}
            onClose={closeForm}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            keepMounted
        >
            <Slide direction="up" in={open}>
                <Paper
                    style={{
                        height: '95%',
                        width: '95%',
                        display: 'flex',
                        overflow: 'hidden',
                        flexDirection: 'column',
                    }}
                >
                    <Container
                        style={{
                            padding: theme.spacing(2),
                            flex: 1,
                            flexDirection: 'column',
                        }}
                    >
                        <span
                            style={{
                                ...theme.typography.h4,
                                fontWeight: theme.typography.fontWeightBold,
                                margin: theme.spacing(2),
                            }}
                        >
                            Create Lease
                        </span>
                        <Divider />
                        {/* Main Content */}
                        <Container
                            style={{
                                flex: 1,
                                flexDirection: 'column',
                                backgroundColor: theme.palette.grey[100],
                                height: '100%',
                            }}
                        >
                            <Carousel
                                index={activeStep}
                                animation="slide"
                                indicators={false}
                                navButtonsAlwaysInvisible
                                cycleNavigation={false}
                                autoPlay={false}
                            >
                                <CreateLeaseDateSelect
                                    dateRange={dateRange}
                                    rangeKey={RANGE_KEY}
                                    setDateRange={onChangeRange}
                                    activeStep={activeStep}
                                    confirmRange={confirmDateRange}
                                />
                                <CreateLeaseLocationSelect
                                    unitMap={unitMap}
                                    areaConfigMap={areaConfigMap}
                                    activeStep={activeStep}
                                    startDate={dateRange.startDate}
                                    endDate={dateRange.endDate}
                                    onSelectArea={confirmArea}
                                />

                                <CreateLeaseConfirm
                                    dateRange={dateRange}
                                    area={area}
                                    areaConfig={
                                        areaConfigMap[area?.area_config ?? -1]
                                    }
                                    unit={unitMap[area?.unit ?? -1]}
                                    onCreate={() => {
                                        closeForm()
                                        toast.success('Lease Created')
                                    }}
                                    onCancel={closeForm}
                                />
                            </Carousel>
                        </Container>

                        <Divider />

                        <Stepper nonLinear activeStep={activeStep}>
                            {steps.map((label, index) => (
                                <Step
                                    key={label}
                                    completed={activeStep > index}
                                >
                                    <StepButton
                                        color="inherit"
                                        onClick={() => {
                                            selectStep(index as CreateLeaseStep)
                                        }}
                                    >
                                        {label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Container>
                </Paper>
            </Slide>
        </Modal>
    )
}
