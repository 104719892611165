import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import Moment from 'react-moment'

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { resolveRoute, Routes } from '../../../../helpers'
import { Inspection } from '../../../../models'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            tableLayout: 'fixed',
            // minWidth: 650,
            '& .MuiTableRow-root .MuiTableCell-root': {
                fontWeight: 400,
                fontSize: '0.625rem',
                /* line-height: 12px; */
                color: '#8A8A8A',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '100%',
            },
            '& .MuiTableHead-root .MuiTableCell-root': {
                fontWeight: 400,
                fontSize: '0.75rem',
                /* line-height: 12px; */
                color: '#000',
                backgroundColor: '#FFF',
            },
            '& .MuiTableRow-root:hover': {
                cursor: 'pointer',
                background: '#eeee',
            },
        },
    }),
)

interface Props {
    inspectionList: Inspection[] | undefined
}

const InspectionsList = (props: Props) => {
    const classes = useStyles()

    const history = useHistory()

    console.log('inspection list....', props.inspectionList)

    const getProgress = (data: Inspection) => {
        const uiTotal = data.ui_total ?? 0
        const uiComplete = data.ui_complete ?? 0

        const safeDenominator = uiTotal > 0 ? uiTotal : 1

        const progressPercent = (uiComplete / safeDenominator) * 100
        const progressStr = `${uiComplete} / ${uiTotal} (${progressPercent.toFixed(
            1,
        )}%)`
        return progressStr
    }
    return (
        <>
            <TableContainer
                className="ez-custom-scroll"
                style={{ maxHeight: '250px' }}
            >
                <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Inventory Types</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Progress</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.inspectionList &&
                        props.inspectionList.length > 0 ? (
                            props.inspectionList.map(
                                (row: Inspection, index: number) => (
                                    <TableRow
                                        key={`inspection-${index}`}
                                        onClick={() => {
                                            history.push(
                                                resolveRoute(
                                                    Routes.inspectionDetail,
                                                    ':id',
                                                    row.id,
                                                ),
                                            )
                                        }}
                                    >
                                        <TableCell>
                                            <Tooltip title={row.name}>
                                                <span>{row.name}</span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            {row.inspection_type?.name}
                                        </TableCell>
                                        <TableCell>
                                            <Moment format="MM/DD/YYYY">
                                                {row.start_date}
                                            </Moment>
                                        </TableCell>
                                        <TableCell>
                                            <Moment format="MM/DD/YYYY">
                                                {row.end_date}
                                            </Moment>
                                        </TableCell>
                                        <TableCell>
                                            {getProgress(row)}
                                        </TableCell>
                                    </TableRow>
                                ),
                            )
                        ) : (
                            <></>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default InspectionsList
