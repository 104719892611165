import { useState, useEffect, useMemo, Dispatch, SetStateAction } from 'react'
import {
    AuditPromptConfig,
    DDLease,
    DDLeaseMatchStatus,
    Feature,
    IdentifiableObject,
    LeaseAudit,
    LeaseAuditStep,
    MatchedLease,
    ModelMap,
} from '../../../models'
import { axiosInstance } from '../../../helpers'
import { toast } from 'react-toastify'
import FileDownload from 'js-file-download'

export const useLeaseAudit = () => {
    const [leaseAuditList, setLeaseAuditList] = useState<LeaseAudit[]>([])
    const [leaseAudit, setLeaseAudit] = useState<LeaseAudit | null>(null)
    const [matchedLeases, setMatchedLeases] = useState<MatchedLease[]>([])
    const [unmatchedPdfLeases, setUnmatchedPdfLeases] = useState<DDLease[]>([])
    const [
        leaseAuditFilterState,
        setLeaseAuditFilterState,
    ] = useState<LeaseAuditFilterState>(initialLeaseAuditFilterState)
    const [loadingState, setLoadingState] = useState<LeaseAuditLoadingState>({
        getLeaseAuditList: false,
        getLeaseAuditData: false,
        pdfsUploading: false,
        rentRollUploading: false,
        baseLineLoading: false,
    })
    const [pendingCount, setPendingCount] = useState<number>(0)
    const [lastUpdateTime, setLastUpdateTime] = useState<Date>(new Date())
    const [
        leaseAuditSortMethod,
        setLeaseAuditSortMethod,
    ] = useState<LeaseAuditSortMethods | null>(null)
    const [selectedDDLease, setSelectedDDLease] = useState<DDLease | null>(null)
    const [numberUploaded, setNumberUploaded] = useState(0)
    const [promptConfigs, setPromptConfigs] = useState<AuditPromptConfig[]>([])
    const [modalState, setModalState] = useState<LeaseAuditModalState>({
        openEditLease: false,
        openLeasePDFView: false,
        openAnalytics: false,
        openPDFUpload: false,
        openPromptResults: false,
        openLeaseTable: false,
    })

    const [pollBaseline, setPollBaseline] = useState(false)

    const getLeaseAuditList = () => {
        setLoadingState((prev) => {
            return {
                ...prev,
                getLeaseAuditList: true,
            }
        })
        axiosInstance
            .get('lease/dd-lease/get_lease_audit_list/')
            .then((res) => {
                setLeaseAuditList(res.data)
            })
            .catch((e) => toast.error(e.response.data.message))
            .finally(() => {
                setLoadingState((prev) => {
                    return {
                        ...prev,
                        getLeaseAuditList: false,
                    }
                })
            })
    }

    const getLeaseAudit = (leaseAuditID: number) => {
        const body = {
            lease_audit: leaseAuditID,
        }
        setLoadingState((prev) => {
            return {
                ...prev,
                getLeaseAuditData: true,
            }
        })
        axiosInstance
            .post('/lease/dd-lease/get_lease_audit/', body)
            .then((res) => {
                setLeaseAudit(res.data['lease_audit'])
                setMatchedLeases(res.data['matches'] ?? [])
                setUnmatchedPdfLeases(res.data['unmatched_pdf_leases'] ?? [])
            })
            .catch((e) => toast.error(e.response.data.message))
            .finally(() => {
                setLoadingState((prev) => {
                    return {
                        ...prev,
                        getLeaseAuditData: false,
                    }
                })
            })
    }

    const uploadRentRoll = (file: File, leaseAuditID: number) => {
        const formData = new FormData()
        formData.append('rent_roll', file)
        formData.append('lease_audit', String(leaseAuditID))

        setLoadingState((prev) => {
            return {
                ...prev,
                rentRollUploading: true,
            }
        })

        axiosInstance
            .post('lease/dd-lease/create_from_rent_roll_xlsx/', formData)
            .then((res) => {
                setMatchedLeases(res.data ?? [])
                setLeaseAudit({
                    ...leaseAudit!,
                    steps: LeaseAuditStep.BASELINE,
                })
                toast.success('Rent Roll uploaded successfully')
            })
            .finally(() => {
                setLoadingState((prev) => {
                    return {
                        ...prev,
                        rentRollUploading: false,
                    }
                })
            })
            .catch((e) => {
                toast.error(e.response.data.message)
            })
    }

    const uploadLeasePDF = async (files: File[], leaseAuditID: number) => {
        // Go through the list of files
        // Create array of array of files
        // When size of subarray is going to exceed 5MB start new subarray and add file to new subarray

        setLoadingState((prev) => {
            return {
                ...prev,
                pdfsUploading: true,
            }
        })

        setNumberUploaded(0)

        const handleUpdateUploaded = (fileLength: number) => {
            setNumberUploaded((numberUploaded) => numberUploaded + fileLength)
        }

        const fileLength = files.length
        let currentByteSize = 0
        const arrayOfFileList: Array<File[]> = []

        let tempFileList: File[] = []
        files.forEach((file, idx) => {
            const combinedByteSize = file.size + currentByteSize
            if (combinedByteSize >= MAX_BYTES) {
                arrayOfFileList.push(tempFileList)
                tempFileList = []
                tempFileList.push(file)
                currentByteSize = 0
            } else {
                tempFileList.push(file)
                currentByteSize += file.size
            }
        })

        if (tempFileList.length !== 0) {
            arrayOfFileList.push(tempFileList)
        }

        const promiseList: Promise<void>[] = []
        const formDataList: FormData[] = []

        arrayOfFileList.forEach((fileList) => {
            const formData = new FormData()
            formData.append('lease_count', String(fileList.length))
            fileList.forEach((file, idx) => {
                const leaseNum = `lease${idx}`
                formData.append(leaseNum, fileList[idx])
            })
            formData.append('lease_audit', String(leaseAuditID))

            formDataList.push(formData)
            const newPromise = axiosInstance
                .post('lease/dd-lease/create_lease_from_pdf/', formData, {
                    timeout: 1000000,
                })
                .then((res) => {
                    handleUpdateUploaded(fileList.length)
                })

            promiseList.push(newPromise)
        })

        if (leaseAudit && leaseAudit?.steps !== LeaseAuditStep.MATCHING) {
            setLeaseAudit({
                ...leaseAudit,
                steps: LeaseAuditStep.PROCESSING_LEASES,
            })
        }

        let hasError = false
        const responseList = await Promise.allSettled(promiseList)
        responseList.forEach((response) => {
            if (response.status === 'rejected') {
                hasError = true
            }
        })

        if (hasError) {
            toast.error(
                `Error uploading lease pdfs. ${numberUploaded} uploaded successfully`,
            )
        } else {
            toast.success(`PDFs uploaded successfully!`)
        }

        setLoadingState((prev) => {
            return {
                ...prev,
                pdfsUploading: false,
            }
        })

        setPendingCount(pendingCount + fileLength)
    }

    const matchLeases = (request: MatchLeasesRequest) => {
        axiosInstance
            .post('lease/dd-lease/match_leases/', request)
            .then((res) => {
                _insertOrUpdateIdentifiable(res.data, setMatchedLeases)
                removepdfLease(request.pdf_lease)
            })
            .catch((e) => {
                toast.error(`Error matching leases ${e}`)
            })
    }

    const unmatchLeases = (leaseMatchId: number) => {
        axiosInstance
            .post('lease/dd-lease/unmatch_leases/', {
                lease_match: leaseMatchId,
            })
            .then((res) => {
                const leaseMatch: MatchedLease = res.data['lease_match']
                const pdfLease: DDLease = res.data['pdf_lease']
                _insertOrUpdateIdentifiable(leaseMatch, setMatchedLeases)
                _insertOrUpdateIdentifiable(pdfLease, setUnmatchedPdfLeases)
            })
            .catch((e) => {
                toast.error(`error unmatching leases ${e}`)
            })
    }

    const deletePDFLease = (pdfLeaseId: number) => {
        axiosInstance
            .delete(`lease/dd-lease/${pdfLeaseId}/`)
            .then(() => {
                removepdfLease(pdfLeaseId)
                toast.success('PDF Lease deleted successfully!')
            })
            .catch((e) => {
                toast.error(`Error deleting lease ${e}`)
            })
    }

    const getPendingLeaseCount = (leaseAuditID: number) => {
        axiosInstance
            .post('lease/dd-lease/get_pending_count/', {
                lease_audit: leaseAuditID,
            })
            .then((res) => {
                getUpdatedLeaseData(lastUpdateTime.toISOString(), leaseAuditID)
                setPendingCount(res.data)
            })
            .catch((e) => toast.error(e.response.data.message))
    }

    const getUpdatedLeaseData = (
        lastUpdateTime: string,
        leaseAuditID: number,
    ) => {
        setLastUpdateTime(new Date())
        axiosInstance
            .post(`lease/dd-lease/get_updated_data/`, {
                last_update_time: lastUpdateTime,
                lease_audit: leaseAuditID,
            })
            .then((res) => {
                const updatedMatchedLeases: MatchedLease[] =
                    res.data['lease_match']
                const updatedPDFLeases: DDLease[] = res.data['pdf_lease']

                updatedMatchedLeases.forEach((matchedLease) => {
                    _insertOrUpdateIdentifiable(matchedLease, setMatchedLeases)
                })
                updatedPDFLeases.forEach((lease) => {
                    _insertOrUpdateIdentifiable(lease, setUnmatchedPdfLeases)
                })
            })
            .catch((e) => toast.error(e.response.data.message))
    }

    const createLeaseAudit = () => {
        axiosInstance
            .post('lease/dd-lease/create_lease_audit/')
            .then((res) => {
                _insertOrUpdateIdentifiable(res.data, setLeaseAuditList)
                setLeaseAudit(res.data)
                toast.success('Lease Audit Created!')
            })
            .catch((e) => toast.error(e.response.data.message))
    }

    const updateDDLease = async (request: UpdateDDLeaseRequest) => {
        try {
            const res = await axiosInstance.post(
                `lease/dd-lease/${request.id}/update_dd_lease/`,
                request,
            )
            toast.success('Lease updated successfully!')
            const lease: DDLease = res.data['lease']
            if (res.data['lease_match']) {
                _insertOrUpdateIdentifiable(
                    res.data['lease_match'],
                    setMatchedLeases,
                )
            } else {
                _insertOrUpdateIdentifiable(
                    res.data['lease'],
                    setUnmatchedPdfLeases,
                )
            }
            return lease
        } catch (e) {
            toast.error('Error updated Lease')
            return Promise.reject(e)
        }
    }

    const getLeaseAuditSummary = async (id: number) => {
        try {
            const res = await axiosInstance.get(
                'lease/dd-lease/create_lease_audit_summary/',
                {
                    responseType: 'blob',
                    params: {
                        lease_audit: id,
                    },
                },
            )

            FileDownload(new Blob([res.data]), 'LeaseAuditSummary.pdf')
        } catch (e) {
            toast.error('Error getting Lease Audit Summary')
            return Promise.reject(e)
        }
    }

    const getRentRollTemplate = async () => {
        try {
            const res = await axiosInstance.get(
                'lease/dd-lease/get_rent_roll_template/',
                {
                    responseType: 'blob',
                },
            )

            FileDownload(new Blob([res.data]), 'rent-roll-template.xlsx')
        } catch (e) {
            toast.error('Error getting Rent Roll Template')
            return Promise.reject(e)
        }
    }

    const getRentRollExample = async () => {
        try {
            const res = await axiosInstance.get(
                'lease/dd-lease/get_rent_roll_example/',
                {
                    responseType: 'blob',
                },
            )

            FileDownload(new Blob([res.data]), 'rent-roll-example.xlsx')
        } catch (e) {
            toast.error('Error getting Rent Roll Example')
            return Promise.reject(e)
        }
    }

    const getSelectedConfigs = () => {
        axiosInstance
            .get(`lease/dd-lease/get_all_prompts/`)
            .then((res) => {
                setPromptConfigs(res.data)
            })
            .catch((e) => toast.error(e.response.data.message))
        return promptConfigs
    }

    const removeAuditBasePDF = (id: number) => {
        const request = {
            lease_audit: id,
        }
        axiosInstance
            .post(`lease/dd-lease/remove_baseline_lease_pdf/`, request)
            .then((res) => {
                setLeaseAudit(res.data)
            })
            .catch((e) => toast.error(e.response.data.message))
    }

    const updateLeaseAudit = async (request: UpdateLeaseAuditRequest) => {
        try {
            if (request.baseline_lease_pdf) {
                const formData = new FormData()
                formData.append('lease_audit', String(request.lease_audit))
                formData.append(
                    'baseline_lease_pdf',
                    request.baseline_lease_pdf,
                )
                if (request.prompts) {
                    formData.append('prompts', String(request.prompts))
                }
                const res = await axiosInstance.post(
                    `lease/dd-lease/update_lease_audit/`,
                    formData,
                )
                const updatedLeaseAudit: LeaseAudit = res.data
                setLeaseAudit(updatedLeaseAudit)
                return updatedLeaseAudit
            } else {
                if (request.send_open_ai_request) {
                    setLoadingState((prev) => {
                        return {
                            ...prev,
                            baseLineLoading: true,
                        }
                    })
                }
                const res = await axiosInstance.post(
                    `lease/dd-lease/update_lease_audit/`,
                    request,
                )
                const updatedLeaseAudit: LeaseAudit = res.data
                if (
                    updatedLeaseAudit?.baseline_lease_pdf &&
                    request.send_open_ai_request
                ) {
                    setPollBaseline(true)
                }
                setLeaseAudit(updatedLeaseAudit)
                return updatedLeaseAudit
            }
        } catch (e) {
            toast.error('Error Sending Request')
            return Promise.reject(e)
        }
    }

    const getUpdatedBaselineLease = async (lease: number) => {
        try {
            const res = await axiosInstance.post(
                `lease/dd-lease/get_dd_lease/`,
                {
                    dd_lease: lease,
                },
            )
            const baselineLease: DDLease = res.data
            if (
                leaseAudit?.baseline_lease_pdf &&
                baselineLease.modified_date >
                    leaseAudit.baseline_lease_pdf.modified_date
            ) {
                setLeaseAudit({
                    ...leaseAudit,
                    baseline_lease_pdf: baselineLease,
                })
                setLoadingState((prev) => {
                    return {
                        ...prev,
                        baseLineLoading: false,
                    }
                })
                setPollBaseline(false)
            }
        } catch (e) {
            toast.error('Error Getting Updated Lease')
            return Promise.reject(e)
        }
    }

    const getAllPDFLeases = async (leaseAuditId: number) => {
        try {
            const res = await axiosInstance.post(
                `lease/dd-lease/get_all_pdf_leases/`,
                {
                    lease_audit: leaseAuditId,
                },
            )

            const pdfLeases: DDLease[] = res.data
            return pdfLeases
        } catch (e) {
            toast.error('Error Getting Lease PDFs')
            return Promise.reject(e)
        }
    }

    const generateExcelReport = async (leaseAuditId: number) => {
        try {
            await axiosInstance.post(`lease/dd-lease/get_excel_report/`, {
                lease_audit: leaseAuditId,
            })
            toast.success(
                'Generating Excel report, an email will be sent once the report is complete!',
            )
        } catch (e) {
            toast.error('Error Generating Report')
            return Promise.reject(e)
        }
    }

    useEffect(() => {
        getLeaseAuditList()
        getSelectedConfigs()
    }, [])

    useEffect(() => {
        if (pollBaseline && leaseAudit?.baseline_lease_pdf) {
            const interval = setInterval(
                getUpdatedBaselineLease,
                5000,
                leaseAudit.baseline_lease_pdf.id,
            )
            return () => clearInterval(interval)
        }
    }, [pollBaseline])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (
                leaseAudit &&
                leaseAudit.steps >= LeaseAuditStep.PROCESSING_LEASES
            ) {
                getPendingLeaseCount(leaseAudit.id)
            }
        }, 25000)

        return () => {
            clearTimeout(timer)
        }
    }, [lastUpdateTime, leaseAudit])

    const removepdfLease = (id: number) => {
        setUnmatchedPdfLeases((prev) => {
            return prev.filter((lease) => lease.id !== id)
        })
    }

    const filteredUnmatchedPDFLeases = useMemo(() => {
        return unmatchedPdfLeases.filter((unmatchedPDFLease) => {
            const searchValid =
                leaseAuditFilterState.unmatchedPDFSearchText === '' ||
                unmatchedPDFLease.tenant
                    ?.toLocaleLowerCase()
                    .includes(
                        leaseAuditFilterState.unmatchedPDFSearchText.toLocaleLowerCase(),
                    ) ||
                unmatchedPDFLease.unit
                    ?.toLocaleLowerCase()
                    .includes(
                        leaseAuditFilterState.unmatchedPDFSearchText.toLocaleLowerCase(),
                    )

            return searchValid
        })
    }, [unmatchedPdfLeases, leaseAuditFilterState])

    const filteredMatchedLeases = useMemo(() => {
        const filter = leaseAuditFilterState
        return matchedLeases.filter((matchedLease) => {
            const searchValid =
                filter.matchedLeasesSearchText === '' ||
                matchedLease.rent_roll_lease.tenant
                    ?.toLocaleLowerCase()
                    .includes(
                        leaseAuditFilterState.matchedLeasesSearchText.toLocaleLowerCase(),
                    ) ||
                matchedLease.rent_roll_lease.unit
                    ?.toLocaleLowerCase()
                    .includes(
                        leaseAuditFilterState.matchedLeasesSearchText.toLocaleLowerCase(),
                    )

            const hideMatchedLeasesValid =
                !filter.hideMatchedLeases ||
                matchedLease.status === DDLeaseMatchStatus.NO_MATCH

            return searchValid && hideMatchedLeasesValid
        })
    }, [matchedLeases, leaseAuditFilterState])

    const filteredLeaseAuditList = useMemo(() => {
        const sortedLeaseAuditList = [...leaseAuditList]
        if (!leaseAuditSortMethod) {
            return sortedLeaseAuditList
        } else if (
            leaseAuditSortMethod === LeaseAuditSortMethods.CREATED_DATE_ASC
        ) {
            return sortedLeaseAuditList.sort((auditA, auditB) =>
                auditA.created_date.localeCompare(auditB.created_date),
            )
        } else if (
            leaseAuditSortMethod === LeaseAuditSortMethods.CREATED_DATE_DESC
        ) {
            return sortedLeaseAuditList
                .sort((auditA, auditB) =>
                    auditA.created_date.localeCompare(auditB.created_date),
                )
                .reverse()
        } else if (
            leaseAuditSortMethod === LeaseAuditSortMethods.MATCHED_COUNT_ASC
        ) {
            return sortedLeaseAuditList.sort(
                (auditA, auditB) =>
                    auditA.matched_pdf_count - auditB.matched_pdf_count,
            )
        } else if (
            leaseAuditSortMethod === LeaseAuditSortMethods.MATCHED_COUNT_DESC
        ) {
            return sortedLeaseAuditList
                .sort(
                    (auditA, auditB) =>
                        auditA.matched_pdf_count - auditB.matched_pdf_count,
                )
                .reverse()
        } else if (
            leaseAuditSortMethod === LeaseAuditSortMethods.RENT_ROLL_ASC
        ) {
            return sortedLeaseAuditList.sort(
                (auditA, auditB) =>
                    auditA.rent_roll_count - auditB.rent_roll_count,
            )
        } else if (
            leaseAuditSortMethod === LeaseAuditSortMethods.RENT_ROLL_DESC
        ) {
            return sortedLeaseAuditList
                .sort(
                    (auditA, auditB) =>
                        auditA.rent_roll_count - auditB.rent_roll_count,
                )
                .reverse()
        } else if (
            leaseAuditSortMethod ===
            LeaseAuditSortMethods.UNMATCHED_PDF_COUNT_ASC
        ) {
            return sortedLeaseAuditList.sort(
                (auditA, auditB) =>
                    auditA.unmatched_pdf_count - auditB.unmatched_pdf_count,
            )
        } else if (
            leaseAuditSortMethod ===
            LeaseAuditSortMethods.UNMATCHED_PDF_COUNT_DESC
        ) {
            return sortedLeaseAuditList
                .sort(
                    (auditA, auditB) =>
                        auditA.unmatched_pdf_count - auditB.unmatched_pdf_count,
                )
                .reverse()
        }
        return sortedLeaseAuditList
    }, [leaseAuditList, leaseAuditSortMethod])

    // ********* Private state setting functions *********
    const _insertOrUpdateIdentifiable = <T extends IdentifiableObject>(
        updatedValue: T,
        dispatch: Dispatch<SetStateAction<T[]>>,
    ) => {
        dispatch((old) => {
            const safeOldValue = old ? [...old] : []

            let found = false
            for (let i = 0; i < safeOldValue.length; i++) {
                const current = safeOldValue[i]
                if (current.id === updatedValue.id) {
                    safeOldValue[i] = updatedValue
                    found = true
                    break
                }
            }

            if (!found) {
                safeOldValue.push(updatedValue)
            }

            return safeOldValue
        })
    }

    return {
        // State
        unmatchedPdfLeases,
        matchedLeases,
        loadingState,
        filteredMatchedLeases,
        filteredUnmatchedPDFLeases,
        leaseAuditFilterState,
        leaseAudit,
        pendingCount,
        leaseAuditList,
        filteredLeaseAuditList,
        leaseAuditSortMethod,
        selectedDDLease,
        numberUploaded,
        promptConfigs,
        modalState,

        // Set State
        setLeaseAudit,
        setLeaseAuditSortMethod,
        setLeaseAuditFilterState,
        setSelectedDDLease,
        setModalState,

        // Requests
        uploadRentRoll,
        removepdfLease,
        uploadLeasePDF,
        matchLeases,
        unmatchLeases,
        deletePDFLease,
        getLeaseAudit,
        createLeaseAudit,
        getLeaseAuditList,
        updateDDLease,
        getLeaseAuditSummary,
        getRentRollExample,
        getRentRollTemplate,
        updateLeaseAudit,
        removeAuditBasePDF,
        getUpdatedBaselineLease,
        getSelectedConfigs,
        getAllPDFLeases,
        generateExcelReport,
    }
}

export interface LeaseAuditFilterState {
    unmatchedPDFSearchText: string
    matchedLeasesSearchText: string
    hideMatchedLeases: boolean
}

const initialLeaseAuditFilterState: LeaseAuditFilterState = {
    unmatchedPDFSearchText: '',
    matchedLeasesSearchText: '',
    hideMatchedLeases: false,
}

export interface MatchLeasesRequest {
    lease_match: number
    pdf_lease: number
}

export enum LeaseAuditSortMethods {
    CREATED_DATE_ASC = 'CREATED_DATE_ASC',
    CREATED_DATE_DESC = 'CREATED_DATE_DESC',
    RENT_ROLL_ASC = 'RENT_ROLL_ASC',
    RENT_ROLL_DESC = 'RENT_ROLL_DESC',
    UNMATCHED_PDF_COUNT_ASC = 'UNMATCHED_PDF_COUNT_ASC',
    UNMATCHED_PDF_COUNT_DESC = 'UNMATCHED_PDF_COUNT_DESC',
    MATCHED_COUNT_ASC = 'MATCHED_COUNT_ASC',
    MATCHED_COUNT_DESC = 'MATCHED_COUNT_DESC',
}
export interface Addendum {
    promptConfig: AuditPromptConfig
    selected: boolean | null
}

export interface UpdateDDLeaseRequest {
    id: number
    signature_audit?: boolean
    signatures?: Addendum[]
    features?: Feature[]
}

export interface UpdateLeaseAuditRequest {
    lease_audit: number
    prompts?: number[]
    baseline_lease_pdf?: File
    send_open_ai_request?: boolean
    step?: LeaseAuditStep
    name?: string
}

export interface LeaseAuditLoadingState {
    getLeaseAuditList: boolean
    getLeaseAuditData: boolean
    pdfsUploading: boolean
    rentRollUploading: boolean
    baseLineLoading: boolean
}

export interface LeaseAuditModalState {
    openEditLease: boolean
    openLeasePDFView: boolean
    openAnalytics: boolean
    openPDFUpload: boolean
    openPromptResults: boolean
    openLeaseTable: boolean
}

const statusSortOrder: DDLeaseMatchStatus[] = [
    DDLeaseMatchStatus.SUCCESS,
    DDLeaseMatchStatus.PARTIAL_MATCH,
    DDLeaseMatchStatus.DISCREPANCY,
    DDLeaseMatchStatus.NO_MATCH,
]

const MAX_BYTES = 5000000
