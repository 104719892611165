import {
    Badge,
    Button,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    Paper,
    Switch,
    TextField,
    Typography,
    useTheme,
} from '@material-ui/core'
import { Check, Message } from '@material-ui/icons'
import { KeyboardDatePicker } from '@material-ui/pickers'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { WoStatusBadge } from '../../../containers'
import {
    // Container,
    MessageDrawer,
    Selector,
    Finder,
} from '../../../components'
import {
    useAppDispatch,
    useCompany,
    _useFinderSelection,
    useRootInfrastructure,
    useService,
    useUser,
} from '../../../hooks'
import {
    ActionItem,
    Area,
    Folder,
    User,
    Inventory,
    Service,
    traverse,
    Unit,
    UserOrCompanyString,
    ListVendor,
    ActionItemStatus,
} from '../../../models'
import {
    createActionItem,
    CreateActionItemRequest,
    FinderLocationSelection,
    FinderSelectionMode,
    getChannelDetail,
    getInventory,
    getMessageList,
    RootState,
    setFinderSelection,
    setInventoryList,
    updateActionItem,
    updateActionItemReq,
    UpdateActionItemRequest,
    Location,
} from '../../../store'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import { SideDrawer } from '../SideDrawer'

interface Props {
    open: boolean
    handleClose: () => void
    actionItem?: ActionItem
    width: number
    serviceList: any
    vendorList: any
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        actionItemHeader: {
            color: '#000',
            fontSize: '1.25rem',
            fontWeight: 700,
            position: 'relative',
        },
        closeBtn: {
            background: '#FFF',
            color: '#E9190F',
            border: '1px solid #E9190F',
            fontSize: '0.875rem',
            textTransform: 'inherit',
            fontWeight: 500,
            borderRadius: '5px',
            marginRight: '5px',
        },
        //Old Styles To be updated
        fontLarge: {
            fontSize: '40px',
        },
        fontPrimaryMain: {
            color: theme.palette.primary.main,
        },
        fontPrimaryDark: {
            color: theme.palette.primary.dark,
        },
        fontHoverPrimaryDark: {
            '&:hover': {
                color: theme.palette.primary.dark,
            },
        },
        fontHoverPrimary: {
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        fontHoverContrast: {
            '&:hover': {
                color: theme.palette.primary.contrastText,
            },
        },
        fontBrand1: {
            color: theme.palette.brand1.main,
        },
        fontHoverBrand1: {
            '&:hover': {
                color: theme.palette.brand1.main,
            },
        },
        fontSecondaryMain: {
            color: theme.palette.secondary.main,
        },
        fontHoverSecondaryMain: {
            '&:hover': {
                color: theme.palette.secondary.main,
            },
        },
        fontHoverSecondaryDark: {
            '&:hover': {
                color: theme.palette.secondary.dark,
            },
        },
        fontHoverGrey600: {
            '&:hover': {
                color: theme.palette.grey[600],
            },
        },
        hoverGrey200: {
            '&:hover': {
                backgroundColor: theme.palette.grey[200],
            },
        },
        hoverGrey300: {
            '&:hover': {
                backgroundColor: theme.palette.grey[300],
            },
        },
        hoverPrimary: {
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
        },
        hoverDarkPrimary: {
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
        },
        hoverDarkSecondary: {
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
        },
        circle: {
            borderRadius: '50%',
        },
        boxShadow: {
            '&:hover': {
                boxShadow: theme.shadows[5],
            },
        },
        highlightCircle: {
            backgroundColor: theme.palette.grey[300],
            borderRadius: '50%',
        },
        highlightCircleOnHover: {
            '&:hover': {
                backgroundColor: theme.palette.grey[300],
                borderRadius: '50%',
            },
        },
        box60: {
            minHeight: '60px',
            maxHeight: '60px',
            minWidth: '60px',
            maxWidth: '60px',
        },
        box50: {
            minHeight: '50px',
            maxHeight: '50px',
            minWidth: '50px',
            maxWidth: '50px',
        },
        box100: {
            height: '100px',
            width: '100px',
        },
        box120: {
            height: '120px',
            width: '120px',
        },
        centerBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textDecoration: 'none',
        },
        wrap: {
            flexWrap: 'wrap',
        },
        table: {
            minWidth: 650,
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        checkBox: {
            color: theme.palette.common.black,
            '&$checked': {
                color: theme.palette.primary.main,
            },
        },
        showOnHover: {
            opacity: 0,
            '&:hover': {
                opacity: 1,
            },
        },
        hoverShadow: {
            '&:hover': {
                boxShadow: theme.shadows[5],
            },
        },
        priorityCheckbox: {
            width: '100%',
            padding: '16px 16px 0 16px',
            '& .MuiSwitch-root': {
                position: 'absolute',
                right: '16px',
            },
        },
    }),
)

export const ActionItemDrawer = (props: Props) => {
    const { open, actionItem, handleClose } = props

    const dispatch = useAppDispatch()
    const theme = useTheme()
    const classes = useStyles()
    const myTeam = useSelector((state: RootState) => state.user.userList)
    // const myVendors = useSelector(
    //     (state: RootState) => state.company.vendorList,
    // )
    // const serviceList = useSelector(
    //     (state: RootState) => state.service.serviceList,
    // )

    const { serviceList } = useService({ getServiceList: true, cleanUp: true })
    const { vendorList } = useCompany({ getVendorList: true, cleanUp: true })

    const locationFinderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Single,
    })

    const inventoryList = useSelector(
        (state: RootState) => state.infrastructure.inventoryList,
    )

    const { workspaceUser } = useUser()

    const { tree } = useRootInfrastructure(true)

    const root = tree.root

    const noService: Service = {
        id: -1,
        name: ' ',
        color: '',
        order: 0,
    }

    const uiServiceList: Service[] = [noService, ...serviceList]
    //TODO: if action item is passed, switch to edit mode
    const [title, setTitle] = useState(actionItem?.title ?? '')
    const [serviceId, setServiceId] = useState(
        actionItem?.service?.id ?? noService.id,
    )
    const [assignUserId, setAssignUserId] = useState(
        actionItem?.who_type === 'WHO_USER' ? actionItem.assigned_to_id : -1,
    )
    const [assignCompId, setAssignCompId] = useState(
        actionItem?.who_type === 'WHO_COMPANY' ? actionItem.assigned_to_id : -1,
    )
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [priority, setPriority] = useState(actionItem?.priority ?? false)
    const [areaId, setAreaId] = useState(-1)
    const [invId, setInvId] = useState(-1)
    const [doValidation, setDoValidation] = useState(false)
    const [openFinder, setOpenFinder] = useState(false)
    const [openMessages, setOpenMessages] = useState(false)

    useEffect(() => {
        //reset the vertical finder when we open the drawer
        dispatch(
            setFinderSelection(
                FinderLocationSelection.RootSelection,
                FinderSelectionMode.Single,
            ),
        )
    }, [])

    useEffect(() => {
        //when area id changes, get inventroy if it is not -1
        if (areaId !== -1) {
            dispatch(getInventory({ params: { area: areaId } }))
        } else if (invId !== -1) {
            //if it is -1, amke sure that the selected inventory is -1
            setInvId(-1)
        }
    }, [areaId])

    //get display value for location and set the unit or folder
    let locationDisplay = ''
    //default values for when no folder or inventory is selected
    let selectedFolder: Folder = {
        id: -1,
        name: '',
        path: '',
        pathIds: '',
        parent: null,
        apartmentOwner: null,
        units: [],
        children: [],
        lineageUnitCount: 0,
    }
    let selectedUnit: Unit = {
        id: -1,
        name: '',
        areas: [],
        workorders: [],
        inspectionDamages: [],
        inventoryInspections: [],
        folder: selectedFolder,
        unit_config: -1,
    }

    if (locationFinderSelection.selection['folder'].length !== 0) {
        //get the folder and its path
        const keys = Object.keys(locationFinderSelection.selection['folder'])
        // there will only be 2: length and the key for the folder we need
        keys.forEach((key) => {
            if (key !== 'length') {
                // it is the key for the folder we need
                selectedFolder =
                    locationFinderSelection.selection['folder'][Number(key)]
                locationDisplay = `${selectedFolder.path}${selectedFolder.name}`
            }
        })
    }
    if (locationFinderSelection.selection['unit'].length !== 0) {
        //get the units folder path, followed by the unit
        const keys = Object.keys(locationFinderSelection.selection['unit'])
        // there will only be 2: length and the key for the unit we need
        keys.forEach((key) => {
            if (key !== 'length') {
                // it is the key for the unit we need
                selectedUnit =
                    locationFinderSelection.selection['unit'][Number(key)]

                locationDisplay = `${selectedUnit.folder.path}${selectedUnit.folder.name}/${selectedUnit.name}`
            }
        })
    }

    // bools used for setting disabled
    const EDIT_MODE = actionItem !== undefined
    const USER_IS_NOT_ASSIGNED_BY =
        actionItem?.assigned_by.id !== workspaceUser?.id
    const ACTION_ITEM_HAS_BEEN_STARTED =
        actionItem?.status !== ActionItemStatus.ASSIGNED
    const USER_IS_ASSIGNED_TO =
        actionItem?.who_type === 'WHO_USER'
            ? actionItem.assigned_to_id === workspaceUser?.id
            : actionItem?.assigned_to_id === workspaceUser?.active_workspace.id

    //when we change action item, update drawer contents
    useEffect(() => {
        setDoValidation(false)
        if (EDIT_MODE) {
            setTitle(actionItem?.title ?? '')
            setServiceId(actionItem?.service?.id ?? -1)
            setAssignUserId(
                actionItem?.who_type === 'WHO_USER'
                    ? actionItem.assigned_to_id
                    : -1,
            )
            setAssignCompId(
                actionItem?.who_type === 'WHO_COMPANY'
                    ? actionItem.assigned_to_id
                    : -1,
            )
            if (actionItem?.start_date) {
                setStartDate(new Date(actionItem?.start_date))
            }
            setPriority(actionItem?.priority ?? false)

            //set location selector
            let type: 'folder' | 'unit' | 'none' = 'none'
            let location: Folder | Unit | undefined

            //traverse through the infrastructure trying to find our infrastructure or unit
            traverse(root, (currentFolder) => {
                // check if the folder is the same one from the action item
                if (actionItem?.folder_location?.id === currentFolder.id) {
                    // see if we have a unit or not
                    if (actionItem.unit_location) {
                        // we have a unit, set type to unit
                        type = 'unit'
                        //loop through the units, find the matching one
                        currentFolder.units.forEach((u) => {
                            if (u.id === actionItem.unit_location?.id) {
                                //found the matching one, set it to location
                                location = u
                            }
                        })
                    } else {
                        //set type to folder and location to the curernt folder
                        type = 'folder'
                        location = currentFolder
                    }
                }
            })

            let stateLocation: Location | undefined
            if (location && type !== 'none') {
                //create a new location selection
                stateLocation = { type: type, location: location }
            }

            dispatch(
                setFinderSelection(
                    FinderLocationSelection.RootSelection,
                    FinderSelectionMode.Single,
                    stateLocation,
                ),
            )

            setAreaId(actionItem?.area?.id ?? -1)
            setInvId(actionItem?.inventory?.id ?? -1)
        } else {
            setOpenMessages(false)
            dispatch(setInventoryList([]))
            setTitle('')
            setServiceId(noService.id)
            setAssignUserId(-1)
            setAssignCompId(-1)
            setStartDate(null)
            setPriority(false)
            setAreaId(-1)
            setInvId(-1)
            dispatch(
                setFinderSelection(
                    FinderLocationSelection.RootSelection,
                    FinderSelectionMode.Single,
                ),
            )
        }
    }, [actionItem])

    const saveButtonPress = () => {
        //see if we are in edit or create mode
        if (actionItem && title !== '') {
            // we will update the action item
            const req: UpdateActionItemRequest = {
                params: {
                    id: actionItem?.id,
                },
                body: {
                    title: title,
                    priority: priority,
                },
            }
            if (
                actionItem?.service?.id !== serviceId &&
                serviceId !== noService.id
            ) {
                req.body.service = serviceId
            }
            if (startDate !== null) {
                req.body.start_date = startDate.toISOString()
            }
            if (invId !== -1) {
                req.body.inventory = invId
            } else if (areaId !== -1) {
                req.body.area = areaId
            } else if (selectedUnit.id !== -1) {
                req.body.unit = selectedUnit.id
            } else if (selectedFolder.id !== -1) {
                req.body.folder = selectedFolder.id
            }
            dispatch(updateActionItemReq(req)).then(() => {
                toast.success('Action Item successfully updated!')
                handleClose()
            })
        }
        //make sure that title and assigned to are valid
        else if (title !== '' && (assignUserId !== -1 || assignCompId !== -1)) {
            //create the action item
            if (myTeam && vendorList) {
                const whoType: UserOrCompanyString =
                    assignUserId !== -1 ? 'USER' : 'COMPANY'
                const assignedTo =
                    assignUserId !== -1 ? assignUserId : assignCompId

                const req: CreateActionItemRequest = {
                    body: {
                        who_type: whoType,
                        assigned_to: assignedTo,
                        title: title,
                        priority: priority,
                    },
                }
                if (serviceId !== noService.id) {
                    req.body.service = serviceId
                }
                if (startDate !== null) {
                    req.body.start_date = startDate.toISOString()
                }
                if (invId !== -1) {
                    req.body.inventory = invId
                } else if (areaId !== -1) {
                    req.body.area = areaId
                } else if (selectedUnit.id !== -1) {
                    req.body.unit = selectedUnit.id
                } else if (selectedFolder.id !== -1) {
                    req.body.folder = selectedFolder.id
                }

                dispatch(createActionItem(req)).then(() => {
                    toast.success('Action Item successfullty created!')
                    handleClose()
                })
            }
        } else {
            setDoValidation(true)
        }
    }

    return (
        <SideDrawer
            width={props.width}
            open={open}
            handleClose={handleClose}
            title={actionItem ? 'Edit Action Item' : 'Create Action Item'}
        >
            <Container style={{ overflowY: 'scroll', height: '100%' }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={priority}
                            color="primary"
                            onChange={() => setPriority(!priority)}
                            disabled={EDIT_MODE && USER_IS_NOT_ASSIGNED_BY}
                        />
                    }
                    labelPlacement="end"
                    label="Priority"
                    className={classes.priorityCheckbox}
                />
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Title (Required)"
                    value={title}
                    error={doValidation && title === ''}
                    onChange={(e) => {
                        setTitle(e.target.value)
                    }}
                    disabled={EDIT_MODE && USER_IS_NOT_ASSIGNED_BY}
                    style={{ margin: '15px 0' }}
                />
                {EDIT_MODE &&
                    (!USER_IS_NOT_ASSIGNED_BY || USER_IS_ASSIGNED_TO) && (
                        <Container style={{ marginLeft: theme.spacing(2) }}>
                            <WoStatusBadge
                                status={
                                    actionItem?.status ??
                                    ActionItemStatus.ASSIGNED
                                }
                                theme={theme}
                                style={{
                                    container: {
                                        marginRight: theme.spacing(2),
                                    },
                                }}
                            />

                            {(actionItem?.status ===
                                ActionItemStatus.ASSIGNED ||
                                actionItem?.status ===
                                    ActionItemStatus.PAUSE) && (
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() =>
                                        dispatch(
                                            updateActionItemReq({
                                                params: {
                                                    id: actionItem.id,
                                                },
                                                body: {
                                                    status:
                                                        ActionItemStatus.IN_PROGRESS,
                                                },
                                            }),
                                        )
                                    }
                                    style={{
                                        marginRight: theme.spacing(1),
                                    }}
                                >
                                    Start
                                </Button>
                            )}
                            {actionItem?.status ===
                                ActionItemStatus.IN_PROGRESS && (
                                <Container>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={() =>
                                            dispatch(
                                                updateActionItemReq({
                                                    params: {
                                                        id: actionItem.id,
                                                    },
                                                    body: {
                                                        status:
                                                            ActionItemStatus.COMPLETE,
                                                    },
                                                }),
                                            )
                                        }
                                        style={{
                                            marginRight: theme.spacing(1),
                                        }}
                                    >
                                        Complete
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        onClick={() =>
                                            dispatch(
                                                updateActionItemReq({
                                                    params: {
                                                        id: actionItem.id,
                                                    },
                                                    body: {
                                                        status:
                                                            ActionItemStatus.PAUSE,
                                                    },
                                                }),
                                            )
                                        }
                                    >
                                        Pause
                                    </Button>
                                </Container>
                            )}
                        </Container>
                    )}
                {actionItem && (
                    <Container
                        className={`${classes.hoverGrey300} ${classes.circle} `}
                    >
                        <Badge
                            badgeContent={actionItem?.unread_count}
                            color="secondary"
                            overlap="circle"
                        >
                            <Message
                                style={{
                                    color: theme.palette.grey[700],
                                    margin: 10,
                                }}
                                fontSize="default"
                                onClick={() => {
                                    dispatch(
                                        getChannelDetail({
                                            channelId: actionItem?.id,
                                        }),
                                    )
                                    dispatch(
                                        getMessageList({
                                            channelId: actionItem?.id,
                                        }),
                                    )
                                    dispatch(
                                        updateActionItem({
                                            ...actionItem,
                                            unread_count: 0,
                                        }),
                                    )
                                    setOpenMessages(true)
                                }}
                            />
                        </Badge>
                    </Container>
                )}
                <Autocomplete
                    id="service"
                    size="small"
                    options={[...(serviceList ?? [])]}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select Service"
                        />
                    )}
                    PaperComponent={({ children }) => (
                        <Paper
                            style={{
                                fontSize: '0.75rem',
                                fontWeight: 400,
                                color: '#000',
                            }}
                        >
                            {children}
                        </Paper>
                    )}
                    onChange={(event: React.ChangeEvent<{}>, newValue) => {
                        if (newValue) {
                            setServiceId(newValue.id)
                        }
                    }}
                    disabled={
                        EDIT_MODE &&
                        (USER_IS_NOT_ASSIGNED_BY ||
                            ACTION_ITEM_HAS_BEEN_STARTED)
                    }
                />
                <div style={{ margin: '16px 0' }}>
                    <Divider style={{ marginBottom: '10px' }} />
                    <Typography
                        component="span"
                        className={classes.actionItemHeader}
                    >
                        Assign to (One Required)
                    </Typography>
                    <Autocomplete
                        id="service"
                        size="small"
                        options={[...(myTeam ?? [])]}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="My Team"
                            />
                        )}
                        PaperComponent={({ children }) => (
                            <Paper
                                style={{
                                    fontSize: '0.75rem',
                                    fontWeight: 400,
                                    color: '#000',
                                }}
                            >
                                {children}
                            </Paper>
                        )}
                        onChange={(event: React.ChangeEvent<{}>, newValue) => {
                            if (newValue) {
                                setAssignUserId(newValue.id)
                                setAssignCompId(-1)
                            }
                        }}
                        disabled={
                            EDIT_MODE &&
                            (USER_IS_NOT_ASSIGNED_BY ||
                                ACTION_ITEM_HAS_BEEN_STARTED)
                        }
                        style={{ margin: '10px 0' }}
                    />

                    {/* assign to company */}
                    <Autocomplete
                        id="service"
                        size="small"
                        options={[...(vendorList ?? [])]}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Vendors"
                            />
                        )}
                        PaperComponent={({ children }) => (
                            <Paper
                                style={{
                                    fontSize: '0.75rem',
                                    fontWeight: 400,
                                    color: '#000',
                                }}
                            >
                                {children}
                            </Paper>
                        )}
                        onChange={(event: React.ChangeEvent<{}>, newValue) => {
                            if (newValue) {
                                setAssignCompId(newValue.id)
                                setAssignUserId(-1)
                            }
                        }}
                        disabled={
                            EDIT_MODE &&
                            (USER_IS_NOT_ASSIGNED_BY ||
                                ACTION_ITEM_HAS_BEEN_STARTED)
                        }
                        style={{ margin: '10px 0' }}
                    />
                    <Divider style={{ marginTop: '10px' }} />
                </div>

                {/* start date and priority */}
                <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Start Date"
                    value={startDate}
                    onChange={(date) => setStartDate(date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    size="small"
                    disabled={EDIT_MODE && USER_IS_NOT_ASSIGNED_BY}
                />

                {/* location selector */}
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Location"
                    value={locationDisplay}
                    onClick={() => {
                        if (!EDIT_MODE || !USER_IS_NOT_ASSIGNED_BY) {
                            setOpenFinder(true)
                        }
                    }}
                    disabled={EDIT_MODE && USER_IS_NOT_ASSIGNED_BY}
                />
                {/* area and inventory selectors */}
                <Autocomplete
                    id="service"
                    size="small"
                    options={[...(selectedUnit.areas ?? [])]}
                    getOptionLabel={(option) =>
                        `TODO OS-150 ${option.area_config} ${option.area_label}`
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Area"
                        />
                    )}
                    PaperComponent={({ children }) => (
                        <Paper
                            style={{
                                fontSize: '0.75rem',
                                fontWeight: 400,
                                color: '#000',
                            }}
                        >
                            {children}
                        </Paper>
                    )}
                    onChange={(event: React.ChangeEvent<{}>, newValue) => {
                        if (newValue) {
                            const newArea = selectedUnit.areas.find(
                                (a) => a.id === (newValue.id as number),
                            )
                            setAreaId(newArea?.id ?? -1)
                        }
                    }}
                    disabled={
                        selectedUnit.areas.length === 0 ||
                        (EDIT_MODE && USER_IS_NOT_ASSIGNED_BY)
                    }
                    style={{ margin: '10px 0' }}
                />

                <Autocomplete
                    id="service"
                    size="small"
                    options={[...(inventoryList ?? [])]}
                    getOptionLabel={(i: Inventory) =>
                        `${i.inventory_config} ${i.id}`
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Inventory"
                        />
                    )}
                    PaperComponent={({ children }) => (
                        <Paper
                            style={{
                                fontSize: '0.75rem',
                                fontWeight: 400,
                                color: '#000',
                            }}
                        >
                            {children}
                        </Paper>
                    )}
                    onChange={(event: React.ChangeEvent<{}>, newValue) => {
                        if (newValue) {
                            setInvId(newValue.id ?? -1)
                        }
                    }}
                    disabled={
                        inventoryList === undefined ||
                        (EDIT_MODE && USER_IS_NOT_ASSIGNED_BY)
                    }
                    style={{ margin: '10px 0' }}
                />
                <SideDrawer
                    width={props.width}
                    open={openFinder}
                    title={'Location Selector'}
                    handleClose={() => {
                        setOpenFinder(false)
                        //if the area is still selected but the unit is not, clear area
                        if (selectedUnit.id === -1 && areaId !== -1) {
                            setAreaId(-1)
                        }
                    }}
                >
                    <Finder
                        root={root}
                        locationSelection={locationFinderSelection.selection}
                        selectedIcon={<Check />}
                        onClickFolder={
                            locationFinderSelection.setFinderSelection
                        }
                        onClickUnit={locationFinderSelection.setFinderSelection}
                    />
                </SideDrawer>
            </Container>
            <div style={{ textAlign: 'right', padding: '24px' }}>
                <Button
                    className={classes.closeBtn}
                    onClick={() => props.handleClose()}
                >
                    Close
                </Button>
                <Button
                    variant="contained"
                    style={{
                        background: '#008C85',
                        color: '#FFF',
                        fontSize: '0.875rem',
                        textTransform: 'inherit',
                        fontWeight: 500,
                        borderRadius: '5px',
                        boxShadow: 'none',
                    }}
                    onClick={saveButtonPress}
                >
                    Save
                </Button>
            </div>
            <MessageDrawer
                title={`${actionItem?.title} Messages`}
                open={openMessages}
                onClose={() => setOpenMessages(false)}
            />
        </SideDrawer>
    )
}
