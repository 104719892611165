import React from 'react'
import { Tooltip, useTheme } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

import { Container } from '..'

interface CustomStyle {
    container?: React.CSSProperties
    warningStyle?: React.CSSProperties
}

interface WarnableMessage {
    message: string
    warning: boolean
}

interface Props {
    headline?: string
    messages?: WarnableMessage[]
    customStyle?: CustomStyle
    children: JSX.Element
}

export const DetailedToolTip = (props: React.PropsWithChildren<Props>) => {
    const theme = useTheme()

    const warningStyle = props.customStyle?.warningStyle ?? {
        color: theme.palette.secondary.light,
    }

    const toolTipTitle = (
        <Container
            style={{
                ...theme.typography.body2,
                flexDirection: 'column',
            }}
        >
            <span>{props.headline}</span>
            {props.messages?.map((msg, idx) => (
                <span
                    key={`ToolTip_${idx}_${msg.message}`}
                    style={msg.warning ? warningStyle : undefined}
                >
                    {msg.message}
                </span>
            ))}
        </Container>
    )

    const icon = props.children ?? <InfoIcon fontSize="large" color="action" />

    return (
        <Tooltip title={toolTipTitle} style={props.customStyle?.container}>
            {icon}
        </Tooltip>
    )
}
