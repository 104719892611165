import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Popover,
    Theme,
    Tooltip,
} from '@material-ui/core'
import React, { useState } from 'react'
import { DDLease } from '../../../../../models'
import { Container, Draggable } from '../../../../../components'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { DDLeaseInfo } from './DDLeaseInfo'
import { MoreVert } from '@material-ui/icons'
import { usePopover } from '../../../../../hooks/usePopover'
import { ReactComponent as EmptyEditIcon } from '../../../../../assets/edit-icon-empty.svg'
import { ReactComponent as FilledEditIcon } from '../../../../../assets/edit-icon-filled.svg'

interface UnmatchedPDFLeaseRowProps {
    theme: Theme
    lease: DDLease
    deletePDFLease: (pdfLeaseId: number) => void
    onOpenLeaseModal: () => void
    onOpenLeasePDFModal: () => void
    onOpenPromptResultsModal: () => void
}

export const UnmatchedPDFLeaseCard = (props: UnmatchedPDFLeaseRowProps) => {
    const {
        lease,
        theme,
        deletePDFLease,
        onOpenLeaseModal,
        onOpenLeasePDFModal,
        onOpenPromptResultsModal,
    } = props

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

    return (
        <Draggable
            onDragStart={() => {}}
            payload={lease}
            style={{
                border: `1px solid ${theme.palette.lightGrey.main}`,
                borderRadius: theme.shape.borderRadius,
                margin: theme.spacing(1),
            }}
        >
            <Container
                style={{
                    flex: 1,
                    minHeight: 120,
                    backgroundColor: theme.palette.white.main,
                    cursor: 'pointer',
                    alignItems: 'center',
                }}
            >
                <DragIndicatorIcon style={{ cursor: 'grab' }} />

                <DDLeaseInfo
                    theme={theme}
                    lease={lease}
                    tenantMatch
                    rentMatch
                    startDateMatch
                    endDateMatch
                    locationMatch
                />

                {lease.edited ? (
                    <IconButton
                        onClick={(e) => {
                            onOpenLeaseModal()
                        }}
                        size="small"
                    >
                        <Tooltip title="Edit">
                            <FilledEditIcon style={{ height: 30, width: 30 }} />
                        </Tooltip>
                    </IconButton>
                ) : (
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            onOpenLeaseModal()
                        }}
                    >
                        <Tooltip title="Edit">
                            <EmptyEditIcon style={{ height: 30, width: 30 }} />
                        </Tooltip>
                    </IconButton>
                )}

                <IconButton
                    onClick={(e) => {
                        e.stopPropagation()
                        handleOpen(e)
                    }}
                    disableRipple={true}
                    size="small"
                    style={{
                        marginLeft: theme.spacing(1),
                    }}
                >
                    <MoreVert />
                </IconButton>

                <Popover
                    anchorEl={anchorEl}
                    open={isOpen}
                    onClose={() => {
                        handleClose()
                    }}
                >
                    <Container
                        style={{
                            flexDirection: 'column',
                            padding: theme.spacing(1),
                        }}
                    >
                        {/* <Button
                            onClick={(e) => {
                                onOpenPromptResultsModal()
                            }}
                            disableRipple={true}
                            style={{
                                textTransform: 'none',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    color: theme.palette.grey[700],
                                }}
                            >
                                View Prompt Results
                            </span>
                        </Button> */}
                        <Button
                            onClick={(e) => {
                                onOpenLeasePDFModal()
                            }}
                            disableRipple={true}
                            style={{
                                textTransform: 'none',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    color: theme.palette.grey[700],
                                }}
                            >
                                View Lease PDF
                            </span>
                        </Button>
                        <Button
                            onClick={(e) => {
                                setOpenDeleteDialog(true)
                            }}
                            disableRipple={true}
                            style={{
                                textTransform: 'none',
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    color: theme.palette.grey[700],
                                }}
                            >
                                Delete PDF Lease
                            </span>
                        </Button>
                    </Container>
                </Popover>
            </Container>

            <Dialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
            >
                <DialogTitle>Delete Lease PDF</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this lease pdf?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="secondary"
                        variant="outlined"
                        style={{
                            marginRight: theme.spacing(2),
                            textTransform: 'none',
                        }}
                        onClick={() => setOpenDeleteDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            deletePDFLease(lease.id)
                            handleClose()
                        }}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Draggable>
    )
}
