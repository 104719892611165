import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { FolderRes, RootState } from '../../store'
import { buildInfrastructure } from '../../models'
import { INITIAL_ROOT_ID, NEW_FOLDER_ID, ROOT_ID, VersionedTree } from './types'

export const useRootInfrastructure = (readyToBuild: boolean) => {
    const folders = useSelector(
        (state: RootState) => state.infrastructure.infrastructure?.folders,
    )

    const units = useSelector(
        (state: RootState) => state.infrastructure.infrastructure?.units,
    )

    const [modFolders, setModFolders] = useState(folders)

    useEffect(() => {
        setModFolders(folders)
    }, [folders, units])

    const addFolder = (parentId: number) => {
        const safeFolders = folders ?? []
        const newFolders: FolderRes[] = []

        if (parentId === ROOT_ID) {
            newFolders.push({
                id: NEW_FOLDER_ID,
                name: '',
                path: '/',
                path_ids: '',
                parent: ROOT_ID,
                apartment_owner: {
                    id: -1,
                    name: '',
                    address: '',
                    city: '',
                    state: '',
                    zip_code: '',
                    phone: null,
                    company_type: 'APARTMENT',
                },
            })
        }
        for (let i = 0; i < safeFolders.length; i++) {
            const currentFolder = safeFolders[i]
            newFolders.push(currentFolder)
            if (currentFolder.id === parentId) {
                newFolders.push({
                    id: NEW_FOLDER_ID,
                    name: '',
                    path: currentFolder.path + currentFolder.name + '/',
                    path_ids: '',
                    parent: parentId,
                    apartment_owner: {
                        id: -1,
                        name: '',
                        address: '',
                        city: '',
                        state: '',
                        zip_code: '',
                        phone: null,
                        company_type: 'APARTMENT',
                    },
                })
            }
        }
        setModFolders(newFolders)
    }

    const removeNewFolders = () => {
        setModFolders(folders)
    }

    const [versionedTree, setVersionedTree] = useState<VersionedTree>({
        root: {
            id: INITIAL_ROOT_ID,
            name: 'Root',
            path: '-1',
            pathIds: '-1',
            parent: null,
            apartmentOwner: null,
            units: [],
            children: [],
            lineageUnitCount: 0,
        },
        version: 0,
    })

    // Build the root any time the stored folders and units change
    // This typically occurs when the user loads the application
    useEffect(() => {
        if (readyToBuild && modFolders && units) {
            console.log('build the master 2')
            setVersionedTree({
                root: buildInfrastructure(modFolders, units),
                version: versionedTree.version + 1,
            })
        }
    }, [modFolders, units, readyToBuild])

    return {
        tree: versionedTree,
        unitCount: units?.length ?? 0,
        addFolder: addFolder,
        removeNewFolders: removeNewFolders,
        unitList: units,
    }
}
