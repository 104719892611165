import { Action } from 'redux'
import { Folder, Unit } from '../../models'

export enum FinderLocationSelection {
    RootSelection = 'RootSelection',
    PrunedSelection = 'PrunedSelection',
}

export enum FinderSelectionMode {
    Multi = 'Multi',
    Single = 'Single',
    Recursive = 'Recursive',
}

export const SET_FINDER_SELECTION = 'SET_FINDER_SELECTION'
export const COPY_FINDER_SELECTION = 'COPY_FINDER_SELECTION'

export interface SetFinderSelectionAction extends Action {
    type: typeof SET_FINDER_SELECTION
    option: FinderLocationSelection
    mode: FinderSelectionMode
    location?: Location
    excluder?: (location: Location) => boolean
    resultCallback?: (result: LocationSelection) => void
}

export interface CopyFinderSelectionAction extends Action {
    type: typeof COPY_FINDER_SELECTION
    option: FinderLocationSelection
    locationSelection: LocationSelection
}

export type FinderActionTypes =
    | SetFinderSelectionAction
    | CopyFinderSelectionAction

export interface Location {
    type: 'folder' | 'unit'
    location: Folder | Unit
}

export interface LocationSelection {
    folder: {
        [id: number]: Folder
        length: number
    }
    unit: {
        [id: number]: Unit
        length: number
    }
}

export interface FinderState {
    [FinderLocationSelection.RootSelection]: LocationSelection
    [FinderLocationSelection.PrunedSelection]: LocationSelection
}
