import React from 'react'
import { Container } from './Container'
import { Theme } from '@material-ui/core'

export interface Section<T> {
    identifier: number | string
    title: string
    data: T[]
}

interface Props<T> {
    sections: Section<T>[]
    renderItem: (data: T) => JSX.Element
    renderSectionHeader: (section: Section<T>) => JSX.Element
    isLoading?: boolean
    theme: Theme
}

export const SectionList = <T extends object>(props: Props<T>) => {
    const {
        sections,
        renderItem,
        renderSectionHeader,
        isLoading,
        theme,
    } = props

    return (
        <Container direction="column" style={{ position: 'relative' }}>
            {isLoading && (
                <Container
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: 'rgba(0, 0, 0, .2)',
                        zIndex: theme.zIndex.modal + 1,
                    }}
                />
            )}
            {sections.map((section, section_idx) => {
                return (
                    <Container
                        key={`SECTION_${section_idx}`}
                        direction="column"
                    >
                        <Container
                            style={{
                                position: 'sticky',
                                top: 0,
                                zIndex: 1000,
                            }}
                        >
                            {renderSectionHeader(section)}
                        </Container>
                        <Container direction="column" style={{ top: 5 }}>
                            {section.data.map((data, data_idx) => {
                                return (
                                    <Container key={`DATA_${data_idx}`}>
                                        {renderItem(data)}
                                    </Container>
                                )
                            })}
                        </Container>
                    </Container>
                )
            })}
        </Container>
    )
}
